import React, { useMemo } from 'react';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { ScoutingGradeBoxes } from 'components/PlayersV2/Scouting/ScoutingGrades';
import { HighlightBox } from 'components/bdd/warcomponents';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HoverInteractive } from 'components/bdd';
import { useScoutingModelDataStore, useScoutingRanks } from '../hooks';
import { ScoutReportSummary } from './ScoutReportSummary';
import { PlayerViewStats } from './PlayerViewStats';
import { DraftPlayerChecks } from './DraftPlayerChecks';
import { SimilarScoutingPlayersLinkButton } from 'components/PlayersV2/Scouting/Scouting';
import { DraftListPlayerMadeItResult } from 'components/DraftApp/DraftListPlayerMadeItResult';
import { DraftListPlayerExpectedPickRange } from 'components/DraftApp/DraftListPlayerExpectedPickRange';

const scoutingModels = [
  {
    title: 'Scout',
    outcomeSlug: 'draft-scouting-outcome',
    nhlSlug: 'draft-scouting-nhl',
    pickSlug: 'draft-scouting-to-pick-number',
  },
  {
    title: 'Prod',
    outcomeSlug: 'draft-production-outcome',
    nhlSlug: 'draft-production-nhl',
    pickSlug: 'draft-production-to-pick-number',
  },
  {
    title: 'OVR',
    outcomeSlug: 'draft-overall-outcome',
    nhlSlug: 'draft-overall-nhl',
    pickSlug: 'draft-overall-to-pick-number',
  },
];

const scoutingModelHover = (modelToScore, outcomeSlug, nhlSlug, pickSlug) => {
  const outcomeModelDetails = !!modelToScore[outcomeSlug]?.details
    ? JSON.parse(modelToScore[outcomeSlug]?.details)
    : null;
  const pNotNHL = !!modelToScore[nhlSlug]?.modelScore
    ? modelToScore[nhlSlug].modelScore
    : null;
  const expectedPick = !!modelToScore[pickSlug]?.modelScore
    ? modelToScore[pickSlug].modelScore
    : null;
  if (!outcomeModelDetails) {
    return <></>;
  }
  const probabilities = [
    { key: 'pT6', title: 'Top' },
    { key: 'pM6', title: 'Middle' },
    { key: 'pB6', title: 'Bottom' },
    { key: 'pDep', title: 'Depth' },
    { key: 'pDW', title: 'Not NHL' },
  ];
  const probs = probabilities.filter((prob) => !!outcomeModelDetails[prob.key]);
  const maxScore = probs
    .map(({ key, title }) => outcomeModelDetails[key])
    .sort((a, b) => b - a)[0];
  return (
    <Container>
      <Row columnGap={1}>
        <div>
          {probabilities
            .filter((prob) => !!outcomeModelDetails[prob.key])
            .map(({ key, title }) => (
              <HighlightBox
                key={key}
                title={
                  <Typography variant="body1" textAlign="center">
                    {title}
                  </Typography>
                }
                content={
                  <Typography variant="stat">
                    {roundToX(outcomeModelDetails[key], 2, true)}
                  </Typography>
                }
                color={getRedGreenColor(
                  outcomeModelDetails[key],
                  0,
                  maxScore
                )} /* highlight according to max score. Every model score has red and green */
              />
            ))}
        </div>
      </Row>
      <Row columnGap={1}>
        <div>
          <HighlightBox
            key={'nhl'}
            title={
              <Typography variant="body1" textAlign="center">
                {'NHL'}
              </Typography>
            }
            content={
              <Typography variant="stat">{roundToX(1 - pNotNHL, 2, true)}</Typography>
            }
            color={getRedGreenColor(1 - pNotNHL, 0, 1)}
          />
          <HighlightBox
            key={'not-nhl'}
            title={
              <Typography variant="body1" textAlign="center">
                {'Not NHL'}
              </Typography>
            }
            content={<Typography variant="stat">{roundToX(pNotNHL, 2, true)}</Typography>}
            color={getRedGreenColor(pNotNHL, 0, 1, true)}
          />
          <HighlightBox
            key={'expected-pick'}
            title={
              <Typography variant="body1" textAlign="center">
                {'Pick #'}
              </Typography>
            }
            content={
              <Typography variant="stat">{roundToX(expectedPick, 0, true)}</Typography>
            }
            color={getRedGreenColor(expectedPick, 0, 224, true)}
          />
        </div>
      </Row>
    </Container>
  );
};

export const ScoutingRanks = ({
  rinknetId,
  rinknetIds,
  posFDG,
  playerName,
  compact,
  hideScoutReportModal,
  draftYear,
  season,
  listId,
  hideExpectedPickRange,
}) => {
  const { modelToScore } = useScoutingModelDataStore();
  const { ratingToRank, split } = useScoutingRanks({ rinknetId, splitFilters: { position: posFDG.toLowerCase() }});
  const { ratingToRank: predictedRatingToRank, split: predictedSplit } = useScoutingRanks(
    { rinknetId, splitFilters: { predictedGrades: true, position: posFDG.toLowerCase() } }
  );

  if (!ratingToRank) {
    return (
      <Container>
        <Typography variant="stat">Loading...</Typography>
      </Container>
    );
  }

  let scoutingRankBoxes;
  let predictedScoutingRankBoxes;
  if (!ratingToRank || Object.keys(ratingToRank).length == 0) {
    scoutingRankBoxes = (
      <Container>
        <Typography variant="stat">
          <em>No scouting data found</em>
        </Typography>
      </Container>
    );
  } else {
    scoutingRankBoxes = (
      <ScoutingGradeBoxes
        rankings={Object.values(ratingToRank)}
        split={split}
        position={posFDG}
        rinknetId={rinknetId}
        playerName={playerName}
        noSplitDescription={compact}
        predictedRankings={predictedRatingToRank && Object.values(predictedRatingToRank)}
        hideScoutReportModal={hideScoutReportModal}
      />
    );
  }

  if (!!predictedScoutingRankBoxes) {
    scoutingRankBoxes = (
      <HoverInteractive
        content={<Container padding={1}>{predictedScoutingRankBoxes}</Container>}
      >
        {scoutingRankBoxes}
      </HoverInteractive>
    );
  }

  const viewStats = (
    <PlayerViewStats
      playerName={playerName}
      rinknetId={rinknetId}
      rinknetIds={rinknetIds}
      horizontal={compact}
      season={season}
    />
  );

  const modelScores = modelToScore && modelToScore(rinknetId);
  let modelScoreBoxes;
  if (!modelScores || Object.keys(modelScores).length == 0) {
    modelScoreBoxes = null;
  } else {
    modelScoreBoxes = (
      <Row columnGap={2} justifyContent="space-between">
        {scoutingModels.map(({ title, outcomeSlug, nhlSlug, pickSlug }) =>
          compact ? (
            <HoverInteractive
              key={outcomeSlug}
              renderContent={() =>
                scoutingModelHover(modelScores, outcomeSlug, nhlSlug, pickSlug)
              }
            >
              <Row columnGap={1}>
                <Typography variant="stat" textAlign="center">
                  {title}
                </Typography>
                <Border>
                  <Container
                    padding={0.5}
                    paddingLeft={1}
                    paddingRight={1}
                    style={{
                      backgroundColor: getRedGreenColor(
                        modelScores[outcomeSlug]?.percentile,
                        0,
                        1
                      ),
                    }}
                  >
                    <Typography variant="stat">
                      {roundToX(modelScores[outcomeSlug]?.percentile * 100, 0)}
                    </Typography>
                  </Container>
                </Border>
              </Row>
            </HoverInteractive>
          ) : (
            <HoverInteractive
              key={outcomeSlug}
              renderContent={() =>
                scoutingModelHover(modelScores, outcomeSlug, nhlSlug, pickSlug)
              }
            >
              <Column gap={1}>
                <Typography variant="stat" textAlign="center">
                  {title}
                </Typography>
                <Border>
                  <Container
                    padding={0.5}
                    paddingLeft={1}
                    paddingRight={1}
                    style={{
                      backgroundColor: getRedGreenColor(
                        modelScores[outcomeSlug]?.percentile,
                        0,
                        1
                      ),
                    }}
                  >
                    <Typography variant="stat">
                      {roundToX(modelScores[outcomeSlug]?.percentile * 100, 0)}
                    </Typography>
                  </Container>
                </Border>
              </Column>
            </HoverInteractive>
          )
        )}
      </Row>
    );
  }

  return (
    <Container paddingLeft={1}>
      <Column gap={1}>
        <Row columnGap={4} alignItems="start">
          {viewStats}
          {!!modelScoreBoxes && (
            <Container minWidth={compact ? 150 : 0}>{modelScoreBoxes}</Container>
          )}
          {scoutingRankBoxes}
        </Row>

        <Row gap={8}>
          <ScoutReportSummary rinknetId={rinknetId} />
          <Typography variant="body2">|</Typography>
          <DraftPlayerChecks
            rinknetId={rinknetId}
            rinknetIds={rinknetIds}
            draftYear={draftYear}
            pos={posFDG}
          />
          <Typography variant="body2">|</Typography>
          <SimilarScoutingPlayersLinkButton
            name={playerName}
            rinknetId={rinknetId}
            draftYear={draftYear}
            text={
              <DraftListPlayerMadeItResult
                rinknetId={rinknetId}
                rinknetIds={rinknetIds}
              />
            }
          />
          {!!draftYear && !hideExpectedPickRange && (
            <>
              <Typography variant="body2">|</Typography>
              <DraftListPlayerExpectedPickRange listId={listId} rinknetId={rinknetId} />
            </>
          )}
        </Row>
      </Column>
    </Container>
  );
};
