import React, { useEffect, useState } from 'react';
import { getNHLTeamLogoUrl } from '../../helpers/logos';
import { getNHLPlayerHeadshot, playerHeadshotDefault } from '../../helpers/nhlcontent';
import { theme } from '../../constants';
import styled from 'styled-components';

const HeadshotContainer = styled.div({
  position: 'relative',
  height: theme.spacing[20],
  width: theme.spacing[20],
});

const HeadshotImage = styled.img({
  ...theme.borders.thin,
  ...theme.borders.light,
  height: '100%',
  borderRadius: '50%',
});

const TeamLogo = styled.img({
  position: 'absolute',
  bottom: '-5%',
  right: '-10%',
  height: theme.spacing[8],
});

export default function PlayerHeadshot({ nhlPlayerStats, playerNhlId, teamShorthand, teamId }) {
  const src = !!nhlPlayerStats 
    ? nhlPlayerStats.headshot 
    : !!playerNhlId && !!teamShorthand
    ? getNHLPlayerHeadshot(playerNhlId, teamShorthand)
    : playerHeadshotDefault; 
  return (
    <HeadshotContainer>
      <HeadshotImage
        src={src}
        // onError={(ev) => (ev.target.src = playerHeadshotDefault)}
      />
      {teamId && <TeamLogo src={getNHLTeamLogoUrl(teamId)} />}
    </HeadshotContainer>
  );
};
