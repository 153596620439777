import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { theme } from 'constants';
import BDDEditInline from 'components/bdd/bddeditinline';
import { UPDATE_PLAYER_LIST_NOTE } from 'apollo/queries/playerlist.queries';

const Container = styled.div({
  ...theme.typography.body2,
  textAlign: 'left',
  width: '100%',
  padding: '5px'
});

export const ListPlayerNotes = ({ notes }) => {
  const [updateListNote] = useMutation(UPDATE_PLAYER_LIST_NOTE, {});
  const handleUpdatePlayerListNote = (noteId, note) => {
    updateListNote({ variables: { input: { id: noteId, note } } });
  };

  return (
    <Container>
      {notes.length === 0 ? <em>No list notes for player</em>
      : <ul style={{ margin: 0 }}>
        {notes.map((n, i) => (<li key={n.id} onClick={(e) => e.stopPropagation()}>
          <BDDEditInline
            name="notes"
            defaultValue={n.note}
            onChange={(name, val) => handleUpdatePlayerListNote(n.id, val)}
            addQuotes
            editingElement="textarea"
            editingProps={{ style: { width: '100%' } }}
          />
        </li>
        ))}
      </ul>}
    </Container>
  );
};
