import { createContext } from 'react';

export const EventFilterContext = createContext();

export const EventFilterController = ({ filters, setEventFilters, children }) => {
  return (
    <EventFilterContext.Provider value={{ filters, setEventFilters }}>
      {children}
    </EventFilterContext.Provider>
  );
};
