import { getCurrentSeason } from 'helpers/hockeyutils';
import { getSeasonChoices } from 'helpers/filters';
import { useSelect } from '.';

const useSeasonSelect = ({ selectProps, onChange, variant='outlined', initialSelectedValue } = {}) => {
  const seasonOptions = getSeasonChoices();
  const { selectedValue, select } = useSelect({
    options: seasonOptions,
    initialSelectedValue: !!initialSelectedValue ? initialSelectedValue : selectProps?.isMulti ? [getCurrentSeason()] : getCurrentSeason(),
    variant,
    onChange, // (val, setSelectedValue) => 
    selectProps,
  });

  return {
    season: selectedValue,
    seasonSelect: select,
  };
};

export default useSeasonSelect;
