import React, { useMemo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import { CSVLink } from "react-csv"
import { Link, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp, faSortAmountDown, faPaintBrush, faBan, faList, faDownload, faChartLine, faTable, faFilter } from '@fortawesome/free-solid-svg-icons'

import { roundToX } from '../../helpers/data'
import { bruinsGold } from "../../helpers/plotting"
import { TooltipSpan } from '../reports'
import { Row, Col, Dropdown, Button, ButtonGroup } from 'react-bootstrap'
import { getRedGreenColor, sortNumeric } from '../../helpers/tables'
import { MinToiField } from '../bdd/bddcomponents'
import BDDSelect from '../bdd/bddselect'
import { getNHLTeamLogoUrl } from '../../helpers/logos'
import ListManagerModal from '../PlayerLists/listmanagermodal'
import StatGraphicalView from './statgraphicalview'
import StatTableFiltersModal from './stattablefilters'
import BDDPlayerModal from "../player/bddplayermodal"
import { buildPlayerRoute, buildTeamRoute } from '../../helpers/routing'
import { heightToNum, isTargetGroup, nameToLastName } from '../../helpers/hockeyutils'
import { BDDLoader } from '../bdd/bddloader'
import BDDPagination from '../bdd/bddpagination'
import PlayerHover from '../PlayersV2/Hover/playerhover'
import { formatBddStat } from 'helpers/format'
import { PLAYERS_V2_ROUTE } from 'constants'
import { useButtonGroup } from 'components/bdd/ButtonGroup'
import { useModal } from 'components/bdd/Modal'
import { ListsModal } from './ListsModal'


const SORT_HIGHLIGHT = bruinsGold

const StatTableContainer = styled.div`
    height: ${props => props.autoHeight ? 'auto' : '95vh'};
`

const TopControlsContainer = styled.div`
    // max-height: 8vh;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 2px;
`

const ShadowContainer = styled.div`
//   border: 1px solid darken(#333, 8%);
//   background-image: (linear-gradient(top, #fff, rgba(255, 255, 255, 0)), 
//     linear-gradient(bottom, #fff, rgba(255, 255, 255, 0)), 
//     linear-gradient(top, darken(#333, 20%), rgba(255, 255, 255, 0)), 
//     linear-gradient(bottom, darken(#333, 20%), rgba(255, 255, 255, 0))
//   );
//   background-position: 0 0, 0 100%, 0 0, 0 100%;
//   background-repeat: no-repeat;
//   background-color: white;
//   background-size: 100% 5em, 100% 5em, 100% 1em, 100% 1em;
//   background-attachment: local, local, scroll, scroll; 
`
const ScrollContainer = styled.div`
    max-height: 90vh;
    overflow: auto;
    padding-bottom: 5px;
    padding-right: 2px;
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    // ::-webkit-scrollbar {
    //     display: none;
    // }

    /* Hide scrollbar for IE, Edge and Firefox */

    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */  
`
const PaginationContainer = styled.div`
    max-height: 2vh;
    padding-left: 20px;
    padding-right: 20px;
`

// <td> padding defined outside of styles because it's applied to div inside of cell
const cellPadding = "5px 25px 5px 10px" 
export const StyledStickyTable = styled.div`
    table {
        width: 100%;
        padding-bottom: 10px;
        white-space: nowrap;
        text-align: center;
        font-weight: normal;
    
        th {
            cursor: pointer;
            padding: 5px 10px 5px 10px;
        }

        // ${props => props.fixHeader && `thead {
        //     position: fixed;
        //     top: 0;
        //     z-index: 4;
        // }`}
    
        thead th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            top: 0;
            background: #FFF;
            color: #333;
            box-shadow: 0px 2px 3px -1px #333;
        }
    
        // Style links
        a {
            color: black;
        }

        tbody td:first-child {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 0;
            box-shadow: 2px 0px 3px #333;
            min-width: ${props => !!props.firstColumnMinWidth ? props.firstColumnMinWidth : '200px'};
        }
    
        thead th:first-child {
            left: 0;
            z-index: 1;
            box-shadow: 2px 0px 3px #333;
        }
    
        ${props => props.striped ? `
            tbody tr:nth-child(even) td {
                background: #f9f9f9;
            }
            tbody tr:nth-child(odd) td {
                background: #ffffff;
            }
        ` : `th, td {
            background: #FFF;
        }`}

        tbody tr {
            :hover {
                border: 1px solid ${bruinsGold};
            }
        }

    }
`


export const Table = React.memo(({ 
    columns, 
    data, 
    defaultSort, 
    defaultPageSize=35,
    highlightSort,
    showNumbers=true,
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Page has only the rows for the active page    
        // Pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: defaultSort,
            pageSize: defaultPageSize
        },
        autoResetSortBy: false
    }, useSortBy, usePagination)
    return <>
        <ShadowContainer>
        <ScrollContainer>
            <StyledStickyTable striped highlight fixHeader={false}>
                <table id='stat-table' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={highlightSort && column.isSorted ? { background: SORT_HIGHLIGHT } : null}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <FontAwesomeIcon icon={faSortAmountDown} />
                                                    : <FontAwesomeIcon icon={faSortAmountUp} />
                                                : null}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, cellIdx) => {
                                        const hightlightColor = SORT_HIGHLIGHT + ((i % 2 == 0) ? '15' : '33')
                                        return <td {...cell.getCellProps()}
                                            style={highlightSort && cell.column.isSorted ? { background: hightlightColor } : null}
                                        >
                                            {showNumbers && cellIdx === 0 ? <span style={{float: 'right', marginTop: "-8px", fontSize: "0.7em"}}>
                                                {pageIndex*pageSize + i + 1}
                                            </span>: null}
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </StyledStickyTable>
        </ScrollContainer>
        </ShadowContainer>
        <PaginationContainer>

            <BDDPagination
                    currPage={pageIndex+1}
                    setCurrPage={pageNum => gotoPage(pageNum-1)}
                    pageLen={pageSize}
                    setPageLen={setPageSize}
                    totalItems={data.length}
                    itemName='rows'
                    pageLenOptions={[10, 20, 35, 50, 100]}
            />
        </PaginationContainer>
    </>
})

const Highlighting = ({ disabled, level, setHighlightLevel }) => {
    const handleClick = val => { setHighlightLevel(val) }
    return <Dropdown as={ButtonGroup} size="sm">
        <Button 
            variant="outline-dark" 
            size="sm" 
            disabled={disabled}
            onClick={() => level ? setHighlightLevel(0) : setHighlightLevel(2)}
        >
            <FontAwesomeIcon icon={faPaintBrush}/>
        </Button>
        <Dropdown.Toggle disabled={disabled} split variant="outline-dark" id="dropdown-split-basic" />
        <Dropdown.Menu style={{width: "60px", minWidth: "60px", padding: 0}}>
            <Dropdown.Item onClick={() => handleClick(1)}><span style={{fontSize: "0.7em"}}><FontAwesomeIcon icon={faPaintBrush}/></span></Dropdown.Item>
            <Dropdown.Item onClick={() => handleClick(2)}><span style={{fontSize: "1.0em"}}><FontAwesomeIcon icon={faPaintBrush}/></span></Dropdown.Item>
            <Dropdown.Item onClick={() => handleClick(3)}><span style={{fontSize: "1.3em"}}><FontAwesomeIcon icon={faPaintBrush}/></span></Dropdown.Item>
            <Dropdown.Item onClick={() => handleClick(0)}><span style={{fontSize: "1.0em"}}><FontAwesomeIcon icon={faBan}/></span></Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

const PlayerSearch = ({ isGroupTarget, playerSlugs, onPlayerSelect, onPlayerModeChange, namesAndSlugs }) => {
  const options = namesAndSlugs.map(ns => ({
    label: ns.name,
    value: ns.slug, 
    subLabel: ns.currentteam_shorthand}))

  const { buttonGroup } = useButtonGroup({
    options: [
      { label: 'Any', value: 'any', hover: 'Match on any player being present' },
      { label: 'All', value: 'all', hover: 'Match on all players being present' },
    ],
    initialSelectedValue: 'all',
    onClick: option => onPlayerModeChange(option.value)
  });
  return <Row>
    <Col>
      <BDDSelect 
          name='players'
          placeholder='Players'
          isMulti
          isSearchable
          isClearable
          options={options}
          value={playerSlugs.slugs}
          onChange={(n,v) => onPlayerSelect(v)}
      />
    </Col>
    {isGroupTarget && <Col sm='auto'>
      {buttonGroup}
    </Col>}
  </Row>
}

const TopControls = ({ 
    target,
    viewType,
    toggleViewType,
    anyFilterActive,
    setShowFilterModal,
    downloadData,
    disableHighlighting,
    highlightLevel, 
    setHighlightLevel, 
    comparisonPosition,
    playerSlugs,
    namesAndSlugs,
    onPlayerSelect, 
    onPlayerModeChange,
    onSetMinToi,
    hiddenControls=[],
    downloadFilename
}) => {
    
    // const [showListModal, setShowListModal] = useState(false)
    const { renderModal, setShowModal } = useModal({
      title: 'Lists'
    })
    return <>
    {renderModal(<ListsModal setSelectedPlayers={onPlayerSelect} />)}
    <TopControlsContainer>
    <Row>
        {target !== 'teams' && !hiddenControls.includes('minToi') ? <>
            <Col md="auto" className='mr-auto'>
                <MinToiField 
                    defaultValue={0} 
                    minValue={0} 
                    onBlur={onSetMinToi}
                />
            </Col>
        </> : null }
        {!hiddenControls.includes('filters') && <Col md={1}>
            <TooltipSpan show={0} hide={0} content="Filter Data">
            <Button size="sm" variant="outline-dark" 
                style={{boxShadow: anyFilterActive ? `0 0 10px ${bruinsGold}` : null}} 
                onClick={() => setShowFilterModal(true)}
            >
                <FontAwesomeIcon icon={faFilter} />
            </Button>
            </TooltipSpan>
        </Col> }
        {!hiddenControls.includes('graphicalView') && <Col md={1} >
            <TooltipSpan show={0} hide={0} content={`Switch to ${viewType === 'graphical' ? 'Table' : 'Graphical'} View`}>
            <Button size="sm" variant="outline-dark" onClick={() => toggleViewType()}>
                <FontAwesomeIcon icon={viewType === 'table' ? faChartLine : faTable} />
            </Button>
            </TooltipSpan>
        </Col> }
        {target !== 'teams' ? <>
            {!hiddenControls.includes('playerlists') && <Col md="auto">
                <TooltipSpan show={0} hide={0} content="Player Lists">
                <Button size="sm" variant="outline-dark" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faList}/>
                </Button>
                </TooltipSpan>
            </Col> }
            {!hiddenControls.includes('playersearch') && <Col md={6}>
                <PlayerSearch 
                    playerSlugs={playerSlugs} 
                    onPlayerSelect={onPlayerSelect} 
                    onPlayerModeChange={onPlayerModeChange}
                    namesAndSlugs={namesAndSlugs}
                    isGroupTarget={isTargetGroup(target)}
                />
            </Col> }
        </> : null }
        <Col md={1} >
            <TooltipSpan 
                show={0} 
                hide={0} 
                content={`Highlighting based on ${
                    comparisonPosition === 'f' ? 'Forward' 
                    : comparisonPosition === 'd' ? 'Defensemen' : 'league'
                } averages ${disableHighlighting ? '(not available)' : ''}`}
            >
            <Highlighting disabled={disableHighlighting} 
                level={highlightLevel}
                setHighlightLevel={val => setHighlightLevel(val)}
            />
            </TooltipSpan>
        </Col>
        <Col md="auto" className='ml-auto'>
            <CSVLink data={downloadData} filename={downloadFilename || 'stats.csv'}>
                <FontAwesomeIcon icon={faDownload}/>
            </CSVLink>
        </Col>
    </Row>
    </TopControlsContainer>
    </>
}

/* Filter column Data */
const filterColumnData = (data, tableFilters, playerSlugs=[], minToi=0) => {
    if (!tableFilters) return data

    const filterCols = Object.keys(tableFilters)
    const slugs = playerSlugs.slugs;
    return data.filter(d => {

        if ('toi' in d && parseFloat(d.toi) < minToi) return false

        if ('slug' in d && slugs.length > 0 && !slugs.includes(d.slug)) {
          return false
        } else if ('slugs' in d && slugs.length > 0) {
          if (playerSlugs.mode === 'any') { // any player matches
            return d.slugs.some(s => slugs.includes(s))
          } else { // all players must match
            return slugs.every(s => d.slugs.includes(s))
          }
        }

        for (let i=0; i<filterCols.length; i++) {
            const col = filterCols[i]
            if (tableFilters[col].type === 'MultiSelect') {
                if (tableFilters[col].values.length && !tableFilters[col].values.includes(d[col])) return false
            } else if (tableFilters[col].type === 'RangeFilter') {
                const val = col === 'height' ? heightToNum(d[col]) : d[col]
                if (!!tableFilters[col].min && val < parseFloat(tableFilters[col].min)) return false
                if (!!tableFilters[col].max && val > parseFloat(tableFilters[col].max)) return false
            }
        }
        
        return true
    })
}
      
const FilterIcon = () =><span style={{fontSize: "0.7em", color: '#A11'}}><FontAwesomeIcon icon={faFilter}/></span>

export default function StatTable({ 
    loading, 
    target, 
    league, 
    index, // list of names of index.  It's a good idea to pass a directly mappable index even if using indexColumns
    indexColumns, // list of column definitions for table (Header, accessor)
    uniqueIds, // slugs are out (because they can be repeated) unique ids are in
    statDefinitions, 
    data, 
    highlightCallback, // takes value, statDef, highlightLevl, row
    metastats, 
    metastatsPosition,
    hiddenControls=[],
    defaultSort: defaultSortArg = [{id: 'toi', desc: true}],
    extraDownloadColumns,
    downloadFilename,
    autoHeight
}) {
    const [viewType, setViewType] = useState('table')
    const [highlightLevel, setHighlightLevel] = useState(0) // 0 (none), 1 (little), 2, 3 (lots)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [playerModal, setPlayerModal] = useState({show: false, slug: null})
    const [minToi, setMinToi] = useState(0)
    const [playerSlugs, setPlayerSlugs] = useState({ mode: 'all', slugs: [] })
    const [tableFilters, setTableFilters] = useState(null)

    const statInfo = {}
    statDefinitions.forEach(s => {
        statInfo[s.unique_id] = s
    })    

    const sortHeight = useMemo(() => (a, b, colId) => {
        return heightToNum(a.values[colId]) - heightToNum(b.values[colId])
    })
    const sortTypeFunc = useMemo(() => sortNumeric)

    const [colToActive, anyFilterActive] = useMemo(() => {
        var cTA = {}
        var aFA = false
        if (tableFilters) {
            Object.keys(tableFilters).forEach(k => {
                if (tableFilters[k].active) aFA = true
                cTA[k] = tableFilters[k].active
            })    
        }
        return [cTA, aFA]
    }, [tableFilters])

    const highlightFunc = useMemo(() => !!metastats ? (value, statDef, highlightLevel, row) => {
        const zScore = (value - metastats[statDef.unique_id].mean) / metastats[statDef.unique_id].stddev
        const color = getRedGreenColor(zScore, -1, 1, statInfo[statDef.unique_id].negative, 2*(3-highlightLevel))
        return color
    } : !!highlightCallback ? highlightCallback
     : null, [metastats, highlightCallback])

    const columns = useMemo(() => 
        (!!indexColumns ? indexColumns
        : index.map(i => {
            const ret = { 
                id: i,
                Header: () => <TooltipSpan content={i}>
                    {colToActive[i] ? <em>
                        <FilterIcon/>
                        {i}
                    </em> : i}
                </TooltipSpan>,
                accessor: d => d[i],
                Cell: ({ cell, value, row, ...x }) => {
                    if (target === 'teams' && cell.column.id === 'team') {
                        const route = buildTeamRoute(row.original.slug)
                        return <span style={{ padding: cellPadding }}>
                            <Link to={route}>
                                {value}
                            </Link>
                        </span>
                    } else if ((target === 'skaters' || target === 'goalies') && cell.column.id === 'Player') {
                        return <span style={{ padding: cellPadding }}>
                            <PlayerHover playerSlug={row.original.slug}>
                                {league === 'NHL' ? <span style={{float: 'left', marginRight: '3px'}}>
                                    <img src={getNHLTeamLogoUrl(row.original.currentteam_nhlid)} height="15px"/>
                                </span> : null}
                              <Link to={`${PLAYERS_V2_ROUTE}/${row.original.slug}/overview`}>
                                  {value}
                              </Link>
                            </PlayerHover>
                        </span>
                    } else if (isTargetGroup(target) && cell.column.id === 'Players' && Array.isArray(value)) {
                      return <span style={{ padding: cellPadding }}>{value.map(nameToLastName).join('-')}</span>
                    }
                    return <span style={{ padding: cellPadding }}>{`${value}`}</span>
                }
            }
            if (i === 'height') ret.sortType = sortHeight
            return ret
        })).concat(uniqueIds.map(s => ({
            id: statInfo[s].label,
            Header: () => <TooltipSpan content={statInfo[s].description}>
                {colToActive[statInfo[s].label] ? <em>
                    <FilterIcon/>
                    {statInfo[s].label}
                </em> : statInfo[s].label}
            </TooltipSpan>,
            accessor: d => roundToX(d[statInfo[s].label], 2),
            Cell: ({ value, row }) => {
                const formattedStat = formatBddStat(value, statInfo[s])
                if (!highlightFunc || highlightLevel === 0 || s === 'toi') return <div style={{padding: cellPadding}}>{formattedStat}</div>
                try {
                    const color = highlightFunc(value, statInfo[s], highlightLevel, row)
                    return <div style={{ background: color, padding: cellPadding }}>
                      {formattedStat}
                    </div>
                } catch {
                    return <div style={{padding: cellPadding}}>
                      {formattedStat}
                    </div>
                }
            },
            sortType: sortTypeFunc,
            sortDescFirst: true
        }))), [highlightFunc, highlightLevel, colToActive, loading])
    const defaultSort = useMemo(() => defaultSortArg, [])
    
    const filteredData = useMemo(() => filterColumnData(data, tableFilters, playerSlugs, minToi), [loading, data, tableFilters, playerSlugs, minToi])

    const dataToDownload = (data, index, uniqueIds, extraDownloadColumns) => {
        if (data.length === 0) return []
        var columnNames = [...index]
        if (extraDownloadColumns) columnNames = columnNames.concat(extraDownloadColumns)
        columnNames = columnNames.concat(uniqueIds.map(s => statInfo[s].label))
        const ret = []
        ret.push(columnNames)
        data.forEach(d => ret.push(columnNames.map(c => d[c])))
        return ret
    }

    const namesAndSlugs = []
    const distinctSlugs = new Set()
    data.forEach(d => {
        if (isTargetGroup(target)) {
            d.slugs.forEach((s, i) => {
                if (!distinctSlugs.has(s)) namesAndSlugs.push({name: d['Players'][d.slugs.indexOf(s)], slug: s})
                distinctSlugs.add(s)    
            })
        } else {
            if (!distinctSlugs.has(d.slug)) namesAndSlugs.push({name: d.Player, slug: d.slug, currentteam_shorthand: d.currentteam_shorthand})
            distinctSlugs.add(d.slug)    
        }
    })

    const highlightSort = useMemo(() => highlightLevel  === 0, [highlightLevel])

    return <>
    <StatTableContainer autoHeight={autoHeight}>
        <TopControls 
            target={target}
            viewType={viewType}
            setShowFilterModal={setShowFilterModal}
            anyFilterActive={anyFilterActive}
            toggleViewType={() => setViewType(viewType === 'table' ? 'graphical' : 'table')}
            downloadData={dataToDownload(filteredData, index, uniqueIds, extraDownloadColumns)}
            onSetMinToi={val => setMinToi(val)}
            disableHighlighting={!metastats && !highlightCallback}
            highlightLevel={highlightLevel}
            setHighlightLevel={val => setHighlightLevel(val)}
            comparisonPosition={metastatsPosition}
            playerSlugs={playerSlugs}
            namesAndSlugs={namesAndSlugs}
            onPlayerSelect={slugs => setPlayerSlugs({ ...playerSlugs, slugs })}
            onPlayerModeChange={mode => setPlayerSlugs({ ...playerSlugs, mode })}
            hiddenControls={hiddenControls}
            downloadFilename={downloadFilename}
        />
        {loading ? <BDDLoader />
        : viewType === 'table' ? <>
            {/* Table and Pagination controls contained here */}
            <Table 
                columns={columns}
                data={filteredData} 
                defaultSort={defaultSort}
                highlightSort={highlightSort} />
        </> : <StatGraphicalView
            target={target}
            index={index}
            uniqueIds={uniqueIds}
            statInfo={statInfo}
            data={filteredData}
            metastats={metastats}
        />}
    </StatTableContainer>
    {!loading ? <StatTableFiltersModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
        index={index}
        statLabels={uniqueIds.map(s => statInfo[s].label)}
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        data={data}
    /> : null }
    {playerModal.show ? 
        <BDDPlayerModal 
            show={playerModal.show} 
            handleClose={() => setPlayerModal({show: false, slug: null})}
            slug={playerModal.slug}
        />
    : null}
    </>
}