import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_BDD_DEPTH_CHART_KEY,
  GET_BDD_DEPTH_CHART_KEYS,
} from 'apollo/queries/depthchart.queries';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDInputField from 'components/form/bddinputfield';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';

export const DepthChartSnapshotForm = ({ onSubmit }) => {
  const initialValues = {};
  const [createSnapshotKey, { loading }] = useMutation(CREATE_BDD_DEPTH_CHART_KEY);

  const { data } = useQuery(GET_BDD_DEPTH_CHART_KEYS);
  const existingKeys = data?.bddDepthChartKeys.map((k) => k.key);

  const schema = Yup.object({
    key: Yup.string()
      .required()
      .test('unique', 'Depth chart key already exists', (value) =>
        value ? !existingKeys.includes(value) : true
      ),
    name: Yup.string().required('Name is a required field'),
    description: Yup.string(),
  });

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        createSnapshotKey({
          variables: {
            input: {
              key: values.key,
              name: values.name,
              description: values.description,
            },
          },
          onCompleted: (data) => {
            onSubmit && onSubmit();
            setSubmitting(false);
          },
          refetchQueries: [GET_BDD_DEPTH_CHART_KEYS],
        });
      }}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors, setFieldValue }) => {
        console.log(values);
        return (
          <Form>
            <Container padding={3}>
              <Column gap={2}>
                <Field
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder="Name"
                  component={BDDInputField}
                  onChange={(ev) => {
                    let key = ev.target.value.replace(/\W/g, '');
                    if (key.length > 0) {
                      key = key[0].toLowerCase() + key.slice(1);
                    }

                    setFieldValue('name', ev.target.value);
                    setFieldValue('key', key);
                  }}
                />
                <ErrorMessage name="name" />
                <Field
                  autoComplete="off"
                  type="text"
                  name="key"
                  placeholder="Key"
                  disabled
                  component={BDDInputField}
                />
                <ErrorMessage name="key" />
                <Field
                  autoComplete="off"
                  type="text"
                  name="description"
                  placeholder="Description"
                  component={BDDInputField}
                />
                <Container marginLeft="auto">
                  <Button
                    type="submit"
                    variant="outline-dark"
                    disabled={isSubmitting || loading}
                  >
                    <Typography variant="body1">
                      {isSubmitting || loading ? 'Submitting...' : 'Submit'}
                    </Typography>
                  </Button>
                </Container>
              </Column>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
