import { buildQueryFilters } from 'components/bdd/Filters';
import { useEffect, useMemo } from 'react';
import { buildIdToQueryFilters } from './helpers';
import { buildPaginatedBFPlayerContractQuery } from 'apollo/queries/bruinsfriendly.queries';
import { usePaginated } from 'components/bdd/Paginated';
import { useContractSearchContext } from './context';

export const buildFilters = (filters) => {
  const allQueryFilters = buildQueryFilters(filters, buildIdToQueryFilters);

  const queryFilters = allQueryFilters.filter(
    (f) => !filters[f.col]?.meta?.isStatFilter && !filters[f.col]?.meta?.ignoreOnQuery
  );

  const statFilters = allQueryFilters.filter((f) => !!filters[f.col]?.meta?.isStatFilter);

  return {
    queryFilters,
    statFilters,
  };
};

export const useContractSearchData = ({ variables, paginatedProps, skip }) => {
  const { data, previousData, placeholder, loading, pageControls } = usePaginated({
    key: 'paginatedBfPlayerContracts',
    limitOptions: [20, 50, 100],
    query: buildPaginatedBFPlayerContractQuery({}),
    skip,
    variables,
    ...paginatedProps
  });

  const tableData = data?.paginatedBfPlayerContracts.data || [];
  const statDataToUse = (data || previousData)?.paginatedBfPlayerContracts.extraData;

  return { tableData, statDataToUse, loading, pageControls, placeholder };
};

export const ContractSearchData = ({ variables: variablesArg, paginatedProps, children }) => {
  const { setData } = useContractSearchContext((state) => state.actions);
  const variables = variablesArg || useContractSearchContext((state) => state.variables);
  const data = useContractSearchData({ variables, paginatedProps });

  useEffect(() => {
    setData(data);
  }, [data]);

  return children;
};
