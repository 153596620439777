import React from "react"
import { useMutation, useQuery } from "@apollo/client"

import { UPDATE_PLAYER_LIST } from "../../apollo/queries/playerlist.queries"
import { BDDLoader } from "../bdd/bddloader"
import { toastBddApiError } from "../bdd/bddtoasts"
import SelectPlayersBank from "./selectplayersbank"

export const AddListPlayersCustom = ({
    listId,
    players,
    numCols,
    onPlayersAdded,
    renderPlayer,
    idKey='id'
}) => {
    // This component takes in a list of players to use as options
    // It handles "onSubmit", given a listId
    const [updatePlayerlist, { loading: updateLoading }] = useMutation(UPDATE_PLAYER_LIST, {
        onError: error => toastBddApiError(error),
        onCompleted: () => {
            if (onPlayersAdded) onPlayersAdded()
        }
    })

    const handleSubmitPlayers = playerIds => {
        updatePlayerlist({ variables: {
            input: { id: listId }, 
            playersToAdd: playerIds.map(pid => ({ id: pid })) } 
        })
    }

    return <SelectPlayersBank
        players={players}
        renderPlayer={renderPlayer}
        numCols={numCols}
        onSubmit={handleSubmitPlayers}
        idKey={idKey}
    />
}