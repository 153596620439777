import { useLazyQuery, useQuery } from '@apollo/client';
import { buildPlayerListQuery } from 'apollo/queries/playerlist.queries';
import { IconButton } from 'components/bdd/Button';
import { COMPARE_PLAYERS_ROUTE } from 'constants';
import { theme } from 'constants/theme';
import { ClipboardData } from 'react-bootstrap-icons';
import { LIST_BUILDER_PLAYER_FIELDS } from '../ListBuilder';

const COMPARE_PLAYER_RANGE = 2;

export const ListComparePlayersLink = ({ listPlayer, ...rest }) => {
  const [getPlayerList, { loading }] = useLazyQuery(
    buildPlayerListQuery(LIST_BUILDER_PLAYER_FIELDS[listPlayer.idType])
  );

  return (
    <IconButton
      padding={0}
      tooltip="Compare Player"
      loading={loading}
      icon={<ClipboardData />}
      onClick={(event) => {
        event.stopPropagation();

        getPlayerList({
          variables: {
            id: listPlayer.listId,
          },
          onCompleted: (data) => {
            const playerSlugs = data.playerList.players.map((p) =>
              listPlayer.idType == 'bdd_player_slug'
                ? p.bddPlayer.slug
                : p.rinknetPlayer.bddPlayerSlug.slug
            );

            const playerIndex = data.playerList.players.findIndex(
              (p) => p.playerId == listPlayer.playerId
            );

            const selectedPlayerSlugs = playerSlugs.slice(
              Math.max(playerIndex - COMPARE_PLAYER_RANGE, 0),
              Math.min(playerIndex + COMPARE_PLAYER_RANGE + 1, playerSlugs.length)
            );

            window.open(
              `${COMPARE_PLAYERS_ROUTE}?selectedPlayerSlugs=${selectedPlayerSlugs || ''}`,
              '_blank'
            );
          },
        });
      }}
      hoverColor={theme.colors.teams.bos.primary}
    />
  );
};
