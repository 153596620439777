import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Field } from './Field';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';

export const SelectRangeFilter = ({
  id,
  label,
  subLabel,
  width,
  options,
  isClearable,
  isReverse,
  onChange,
  meta,
  hide,
  renderTagLabel,
}) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  if (options.length < 2) {
    return (
      <Typography variant="body1" state="danger">
        Error: select range filter only works with a predefined list of options
      </Typography>
    );
  }

  // Value is a list of values that is computed based on a min/max value set
  // We need to take a list of options, and transform it into two selects, one for min and one for max
  // The difference of index between the two defines the set of values to use
  // Filter value should be: min, max, values

  const tagLabel =
    filter &&
    (renderTagLabel
      ? renderTagLabel(options, filter.value)
      : `${options?.find((o) => o.value == filter.value.min)?.label.slice(2, 4)}-${options
          ?.find((o) => o.value == filter.value.max)
          ?.label.slice(7)}`);

  useRegisterFilterTag(id, filter && <FilterTag name={label} label={tagLabel} />);

  const minIndex = isReverse ? options.length - 1 : 0;
  const maxIndex = isReverse ? 0 : options.length - 1;
  const min = filter?.value.min || options[minIndex].value;
  const max = filter?.value.max || options[maxIndex].value;

  const getValues = (min, max) => {
    console.log(min, max);
    const minIndex = options.findIndex((o) => o.value == min);
    const maxIndex = options.findIndex((o) => o.value == max);

    const handleFilter = (_, index) =>
      isReverse
        ? index <= minIndex && index >= maxIndex
        : index >= minIndex && index <= maxIndex;

    return options.filter(handleFilter).map((o) => o.value);
  };

  return (
    !hide && (
      <Field
        id={id}
        isClearable={isClearable}
        onChange={onChange}
        width={width}
        label={label}
        subLabel={subLabel}
      >
        <Row columnGap={2}>
          <Container width="100%">
            <StyledBDDSelect
              selectedValue={min}
              size="sm"
              selectProps={{
                isSearchable: true,
              }}
              options={options}
              onChange={(n, v) => {
                setFilters(id, {
                  id,
                  type: 'selectRange',
                  value: {
                    min: v,
                    max,
                    values: getValues(v, max),
                  },
                  meta,
                });

                onChange && onChange(v);
              }}
            />
          </Container>
          <Container width="100%">
            <StyledBDDSelect
              selectedValue={max}
              size="sm"
              selectProps={{
                isSearchable: true,
              }}
              options={options}
              onChange={(n, v) => {
                setFilters(id, {
                  id,
                  type: 'selectRange',
                  value: {
                    min,
                    max: v,
                    values: getValues(min, v),
                  },
                  meta,
                });

                onChange && onChange(v);
              }}
            />
          </Container>
        </Row>
      </Field>
    )
  );
};
