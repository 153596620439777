import React, { useMemo } from 'react';
import styled from 'styled-components';

import { currentRoleToText, proProjectionToText } from '../playerformhelpers';
import { positionToFDG } from '../../../helpers/hockeyutils';
import { formatDate } from '../../../helpers/helpers';
import {
  getMetastatsAliasMapper,
  getMetastatsHover,
  getRatingHighlight,
  metastatsToCompStr,
} from './metastatshelpers';
import { HoverInfo } from '../../bdd';
import { Col, Row } from 'react-bootstrap';
import { ReportStats } from 'components/ScoutingV2/components/reports/ReportStats';
import { Container } from 'components/bdd/Layout';

const defaultRatingOrder = [
  'Current Role',
  'NHL Current',
  'Pro Projection',
  'NHL Projection',
  'Draft Round',
  'Draft',
  'Game Impact',
  'Player Type',
  'Special Teams',
];

const defaultSkillRatingOrder = [
  // Goalie
  'Positioning',
  'Foot Speed',
  'Hands',
  'Post Tracking',
  'Rebound Control',

  //Skater
  'Skating/Mobility',
  'Awareness/Hockey Sense',
  'Defending',
  'Puck Handling',
  'Outlets/Passing',
  'Offensive Involvement',
  'Shooting Ability',
  'Compete Level',
];

const Styles = styled.div`
  .top-row {
    font-size: 0.8em;
    font-weight: bold;
  }
  .player-name {
    font-family: Oswald;
    font-size: 1.2em;
  }
  table {
    width: 100%;
    font-size: 0.8em;
    text-align: center;
    th {
      font-size: 0.7em;
      background: #333;
      color: white;
      padding: 0px 5px;
    }
    td {
      font-size: 0.8em;
    }
  }
  .notes {
    font-size: 0.8em;
  }
  .comp-description {
    text-align: center;
    font-size: 0.7em;
    color: #777;
  }
`;

const HeaderRow = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  fontFamily: 'Oswald',
  rowGap: '0px',
  columnGap: '15px',
});
const Author = styled.div({});
const Title = styled.div({});
const Position = styled.div({});

const orderSort = (list, accessor, order) => {
  return list?.sort((a, b) => {
    const aidx = order.indexOf(accessor(a));
    const bidx = order.indexOf(accessor(b));
    if (aidx < 0) return 1;
    if (bidx < 0) return -1;
    return aidx - bidx;
  });
};

export default function RinknetReport({
  report,
  metastats,
  statsData,
  showName = false,
  showHeader = true,
  showAuthor = true,
}) {
  const aliasToMetas = !!metastats ? getMetastatsAliasMapper(metastats) : {};
  const ratingsRaw =
    typeof report.ratings === 'string' ? JSON.parse(report.ratings) : report.ratings;
  const ratings = orderSort(ratingsRaw, (a) => a.Type, defaultRatingOrder);
  // const ratings = useMemo(() => JSON.parse(report.ratings).sort((a,b) =>
  //     defaultRatingOrder.indexOf(a.Type) - defaultRatingOrder.indexOf(b.Type)))
  const skillratingssections = useMemo(() => {
    if (!report.skillcategoryratings) return null;

    var srRaw =
      typeof report.skillcategoryratings === 'string'
        ? JSON.parse(report.skillcategoryratings)
        : report.skillcategoryratings;
    srRaw = srRaw.filter((r) => r.Rating.Name !== '--');
    const sr = orderSort(srRaw, (a) => a.Type.Name, defaultSkillRatingOrder);
    if (sr.length > 5) {
      const half = Math.floor(sr.length / 2);
      var ret = [sr.slice(0, half), sr.slice(half)];
    } else {
      var ret = [sr];
    }
    return ret;
  });
  const hasSkillRatings = !!skillratingssections && skillratingssections[0].length > 0;
  const rawNotes = 'reportNotes' in report ? report.reportNotes : report.notes;
  var [notes, sections] = [[], []];
  if (rawNotes) {
    notes = rawNotes.split(/[A-Z/ ]{3,}:/g);
    sections = rawNotes.match(/[A-Z/ ]{3,}:/g);
  }
  if (!sections) {
    sections = ['OVERALL COMMENTS:\n'];
  } else {
    notes = notes.slice(1);
  }

  const getRatingValue = (type, name) => {
    // Get the "value" for a rating, mapping certain number values to text
    // "Current Role", "Pro Projection"
    try {
      if (!name) return name;
      if (type === 'Pro Projection' || type === 'NHL Projection') {
        const posFDG = positionToFDG(report.position);
        const label = proProjectionToText(name, posFDG);
        if (!label) return name;
        return label;
      } else if (type === 'Current Role' || type == 'NHL Current') {
        const posFDG = positionToFDG(report.position);
        const label = currentRoleToText(name, posFDG);
        if (!label) return name;
        return label;
      }
    } catch {
      console.warn(`Error translating rating value for ${type}, ${name}.`);
      return name;
    }
    return name;
  };

  const rg = report.rinknetGame;
  const opponent = rg
    ? rg.hometeam.longname === report.reportteam
      ? rg.awayteam.longname
      : rg.hometeam.longname
    : null;
  return (
    <Styles>
      <div className="top-row">
        {showName && (
          <Row className="player-name">
            <Col>
              {report.rinknetPlayer.firstname} {report.rinknetPlayer.lastname}
            </Col>
          </Row>
        )}
        {showHeader && (
          <HeaderRow>
            <Author>
              {showAuthor && <>{report.author} - </>}
              {formatDate(report.date)}
            </Author>
            <Title>
              {report.reportteam} {opponent && <>vs. {opponent}</>}{' '}
              {rg ? `(${rg.leagueshorthand})` : null}
            </Title>
            <Position>{report.position}</Position>
          </HeaderRow>
        )}
        {!!statsData && (
          <Container paddingTop={1} paddingBottom={1}>
            <ReportStats report={report} statsData={statsData} />
          </Container>
        )}
      </div>
      <table>
        <tbody>
          <tr>
            {ratings?.map((r, i) => (
              <th key={`${r.Type}-${i}`}>{r.Type}</th>
            ))}
          </tr>
          <tr>
            {ratings?.map((r, i) => (
              <td key={`${r.Type}-${i}`}>{getRatingValue(r.Type, r.Rating.Name)}</td>
            ))}
          </tr>
        </tbody>
      </table>

      {skillratingssections &&
        skillratingssections.map((skillratings, i) => (
          <table key={i}>
            <tbody>
              <tr>
                {skillratings.map((r, i) => (
                  <th key={`${r.Type.Name}-${i}`}>{r.Type.Name}</th>
                ))}
              </tr>
              <tr>
                {skillratings.map((r, i) => (
                  <td
                    key={`${r.Type.Name}-${i}`}
                    style={{
                      background: getRatingHighlight(
                        r.Type.Name,
                        r.Rating.Name,
                        aliasToMetas
                      ),
                    }}
                  >
                    <HoverInfo
                      content={getMetastatsHover(
                        r.Type.Name,
                        r.Rating.Name,
                        aliasToMetas
                      )}
                    >
                      {r.Rating.Name}
                    </HoverInfo>
                  </td>
                ))}
                {/* {skillratings.map(r => <td key={r.Type.Name}>{r.Rating.Name}</td>)} */}
              </tr>
            </tbody>
          </table>
        ))}

      {!!metastats && hasSkillRatings && (
        <div className="comp-description">{metastatsToCompStr(metastats)}</div>
      )}

      <div className="notes">
        {sections.map((s, i) => (
          <div key={s}>
            {s}
            {notes[i]}
          </div>
        ))}
      </div>
    </Styles>
  );
}
