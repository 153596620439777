import { useContextMenuWrapper } from "./useContextMenuWrapper";



export const ContextMenuWrapper = ({ 
  children,
  ...props
}) => {

  const { render } = useContextMenuWrapper(props);

  return render(children);
}
