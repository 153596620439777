const LOCAL_API_PORT = !!process.env.REACT_APP_LOCAL_API_PORT ? process.env.REACT_APP_LOCAL_API_PORT : '80'
export const BRUINS_API_URL = 
    process.env.REACT_APP_BDD_API_TYPE === 'production' ? 'https://api.bruinsdataden.com/api/v1'
    : process.env.REACT_APP_BDD_API_TYPE === 'development' ? 'https://dev-api.bruinsdataden.com/api/v1'
    : process.env.REACT_APP_BDD_API_TYPE === 'local' ? `http://localhost:${LOCAL_API_PORT}/api/v1`
    : null

    if (!BRUINS_API_URL) {
    throw new Error('No REACT_APP_BDD_API_TYPE specified')
}

export const BRUINS_ASSETS_URL = 'https://app.bruinsdataden.com/assets'