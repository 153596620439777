import { ModalContextProvider } from 'components/bdd/Modal/ModalContextProvider';
import { ShiftVideoController } from 'components/bdd/Video';

export const GameShiftVideoProvider = ({ children }) => {
  return (
    <ShiftVideoController>
      <ModalContextProvider>{children}</ModalContextProvider>
    </ShiftVideoController>
  );
};
