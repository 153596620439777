import { useBddStatQueryV2 } from 'apollo/queries';
import { useMemo } from 'react';

const getLeaguesWithGamesPlayed = (stats) => {
  if (!stats) {
    return null;
  }

  const gamesPlayed = stats.reduce((gamesPlayed, s) => {
    const existing = gamesPlayed.find((gp) => gp.leagueSlug === s.league_slug);

    if (!existing) {
      gamesPlayed.push({
        leagueSlug: s.league_slug,
        gamesPlayed: s.gamesPlayed,
      });
    } else {
      existing.gamesPlayed += s.gamesPlayed;
    }

    return gamesPlayed;
  }, []);

  return gamesPlayed.sort((a, b) => b.gamesPlayed - a.gamesPlayed);
};

export const usePlayerSLGames = ({ slug, slugs, isGoalie=false }) => {
  const { data: stats, loading } = useBddStatQueryV2({
    target: isGoalie ? 'goalies' : 'skaters',
    filters: [
      {
        col: 'slug',
        cond: 'isin',
        val: slug ? [slug] : slugs,
      },
    ],
    index: ['league_slug'],
    statSlugs: ['gamesPlayed'],
  });

  const leaguesWithGamesPlayed = getLeaguesWithGamesPlayed(stats?.data);
  const leagueOptions = useMemo(
    () =>
      !!leaguesWithGamesPlayed
        ? leaguesWithGamesPlayed.map((league) => {
            return {
              value: league.leagueSlug,
              label: league.leagueSlug,
              caption: `${league.gamesPlayed} SL GP`,
              hover: `Set default league to ${league.leagueSlug}`,
            };
          })
        : [],
    [leaguesWithGamesPlayed]
  );

  return { stats, leaguesWithGamesPlayed, leagueOptions, loading };
};
