import { useMutation } from '@apollo/client';
import { ARCHIVE_PLAYLIST, GET_PLAYLISTS } from 'apollo/queries/video.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { IconButton } from 'components/bdd/Button';
import { ModalContext, useArchiveModal } from 'components/bdd/Modal';
import { theme } from 'constants';
import { Archive, Download } from 'react-bootstrap-icons';
import { usePlaylistActions } from '.';
import { Typography } from 'components/bdd/Typography';
import { DownloadPlaylistForm } from './DownloadPlaylistForm';
import { useContext, useEffect } from 'react';
import { useSearchParamsState } from 'components/bdd/hooks';

export const DownloadPlaylistIconButton = ({ playlist, showDownload }) => {
  const { renderModal, hideModal } = useContext(ModalContext);

  const handleRenderModal = () =>
    renderModal({
      size: 'lg',
      title: <Typography variant="h6">Download Playlist</Typography>,
      body: <DownloadPlaylistForm playlist={playlist} onSubmit={hideModal} />,
    });

  useEffect(() => {
    if (!!playlist && !!showDownload) {
      handleRenderModal();
    }
  }, [showDownload, playlist]);

  if (!playlist) return null;

  return (
    <IconButton
      icon={<Download />}
      hoverColor={theme.colors.teams.bos.primary}
      tooltip="Download this playlist"
      onClick={handleRenderModal}
    />
  );
};
