import { getCurrentSeason, getNextOrCurrentSeason } from 'helpers/hockeyutils';

export const defaultSLStatReport = (position) =>
  position == 'G' ? 'goaliesOverview' : 'skatersOverview';

export const defaultFilters = {
  browse: {
    id: 'browse',
    value: 'active-players',
  },
  season: {
    id: 'season',
    value: getNextOrCurrentSeason(),
  },
  position: {
    id: 'position',
    value: 'All Skaters',
  },
};

export const defaultColumnIds = [
  'player',
  'index',
  'age',
  'position',
  'signing_team_slug',
  'signing_status',
  'expiry_status',
  'signing_age',
  'cap_hit',
  'num_years',
  'platform_season',
  'GP',
  'TOI/GP',
  'G',
  'A',
  'P',
  'P/GP',
  'PBR',
  'PIM/GP',
  'SV%',
  'GBR',
  'XPGF',
  'XPGA',
  'XPG %',
];