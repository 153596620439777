import { useState } from 'react';
import { NoteCommentForm, useNotes } from 'components/Notes';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import LeftRightArrowButtons from 'components/bdd/Button/LeftRightArrowButtons';
import { Button } from 'react-bootstrap';
import Icon from 'components/bdd/Icon';
import { Typography } from 'components/bdd/Typography';
import { PlusLg, Stickies } from 'react-bootstrap-icons';
import { HoverInteractive } from 'components/bdd';
import { IconButton } from 'components/bdd/Button';
import NotificationIndicator, {
  NotificationWrapper,
} from 'components/bdd/NotificationIndicator';
import { theme } from 'constants/theme';

export const CompactNotes = ({
  entity,
  defaultAddNote,
  onCancel,
  useHoverIconButton,
}) => {
  const [noteIdx, setNoteIdx] = useState(0);
  const [showAddNote, setShowAddNote] = useState(defaultAddNote);

  const { noteItems, placeholder, createNote, updateNote } = useNotes({
    ...entity,
    entityIds: [entity.entityId],
  });

  return (
    <Container>
      <HoverIconButton useHoverIconButton={useHoverIconButton} noteItems={noteItems}>
        {({ forceClose }) => {
          return (
            <Column gap={1}>
              <Row columnGap={3}>
                <Typography variant="subtitle1">Notes</Typography>
                <LeftRightArrowButtons
                  leftOnClick={(ev) => {
                    setNoteIdx(noteIdx - 1);
                    ev.stopPropagation();
                  }}
                  rightOnClick={(ev) => {
                    setNoteIdx(noteIdx + 1);
                    ev.stopPropagation();
                  }}
                  disabled={!noteItems?.length}
                  leftDisabled={noteIdx <= 0}
                  rightDisabled={noteIdx >= noteItems?.length - 1}
                />
                <Typography variant="body2">
                  {noteItems?.length > 0
                    ? `${noteIdx + 1} of ${noteItems?.length} note(s)`
                    : 'No notes available'}
                </Typography>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    size="sm"
                    variant="outline-dark"
                    onClick={() => {
                      setShowAddNote(!showAddNote);
                    }}
                  >
                    <Row columnGap={1}>
                      {!showAddNote && <Icon icon={<PlusLg />}></Icon>}
                      <Typography variant="body2">
                        <b>{showAddNote ? 'View Notes' : 'Add Note'}</b>
                      </Typography>
                    </Row>
                  </Button>
                </div>
              </Row>
              {!!noteItems?.[noteIdx] && !showAddNote && (
                <Border>
                  <NoteCommentForm
                    key={noteItems?.[noteIdx]?.note?.note}
                    {...entity}
                    existingNote={noteItems[noteIdx].note}
                    onSubmit={(values) => {
                      updateNote({ variables: values });
                      setShowAddNote(false);
                    }}
                    onCancel={onCancel}
                    // onSubmit={(values) => updateNote({ variables: values })}
                  />
                </Border>
              )}
              {showAddNote && (
                <NoteCommentForm
                  hidePinToggle
                  {...entity}
                  onSubmit={(values) => {
                    createNote({ variables: values });
                    setShowAddNote(false);
                  }}
                  onCancel={onCancel}
                />
              )}
            </Column>
          );
        }}
      </HoverIconButton>
    </Container>
  );
};

const HoverIconButton = ({ children, noteItems, useHoverIconButton }) => {
  if (!useHoverIconButton) {
    return children({ forceClose: () => {} });
  }

  return (
    <Container>
      <HoverInteractive
        renderAsFullScreenMobile
        renderContent={(forceClose) => (
          <div
            style={{
              maxWidth: '400px',
              maxHeight: '800px',
              overflow: 'auto',
              paddingRight: '8px',
            }}
          >
            {children({ forceClose })}
          </div>
        )}
      >
        <IconButton
          icon={
            <NotificationWrapper>
              <Stickies />
              {noteItems?.length > 0 && (
                <NotificationIndicator>{noteItems?.length}</NotificationIndicator>
              )}
            </NotificationWrapper>
          }
          onClick={(event) => {
            event.stopPropagation();
          }}
          hoverColor={theme.colors.teams.bos.primary}
        />
      </HoverInteractive>
    </Container>
  );
};
