import React from 'react';
import styled from 'styled-components';
import { theme } from 'constants';
import BDDStatTable from 'components/bdd/bddstattable';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { multiSeason8to4 } from 'helpers/hockeyutils';
import { Container, Row } from 'components/bdd/Layout';
import { roundToX } from 'helpers/data';

const highlight = {
  boxShadow: `
    inset 0px 1px 0px 0px ${theme.colors.teams.bos.primary},
    inset 0px -1px 0px 0px ${theme.colors.teams.bos.primary}`,
};

const stickyPosition = {
  position: 'sticky',
  left: 0,
};

const TableStyles = styled.div({
  thead: {
    'tr:hover': {
      border: 0,
      boxShadow: 'none',
    },
    'th:first-child': {
      ...stickyPosition,
      backgroundColor: theme.colors.light.background,
    },
  },
  tfoot: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.top,
  },
  th: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.bottom,
    paddingRight: theme.spacing[3],
  },
  tr: {
    ':last-child': {
      td: {
        borderBottom: 0,
      },
    },
  },
  'td:first-child': {
    ...stickyPosition,
    backgroundColor: theme.colors.light.background,
  },
  'tr.subRow': {
    color: theme.colors.light.text.disabled,
  },
  'tr:nth-of-type(even)': {
    backgroundColor: theme.colors.light.secondary,
    'td:first-child': {
      ...stickyPosition,
      backgroundColor: theme.colors.light.secondary,
    },
  },
  'tr:hover': {
    ...highlight,
    'td:first-child': {
      ...highlight,
    },
  },
});

const indexColumns = [
  {
    id: 'player',
    accessor: (row) => (
      <PlayerHover rinknetId={row['player_rinknet_id']}>{row['player']}</PlayerHover>
    ),
    Header: <div>Player</div>,
  },
  {
    id: 'season',
    accessor: (row) => multiSeason8to4(row['season'], true, true),
    Header: <div>Season</div>,
  },
  {
    id: 'league',
    accessor: (row) => row['league'],
    Header: <div>League</div>,
  },
  {
    id: 'team',
    accessor: (row) => row['team'],
    Header: <div>Team</div>,
  },
  {
    id: 'age',
    accessor: (row) => row['age'],
    Header: <div>Age</div>,
  },
  {
    id: 'pos',
    accessor: (row) => row['position'],
    Header: <div>Pos</div>,
  },
  // {
  //   id: 'compact-season-league-team',
  //   accessor: (row) => (
  //     <Container minWidth={150}>
  //       <Row columnGap={1} flexWrap>
  //         <div>{row['season']}</div>
  //         <b>{row['league']}</b>
  //         <div>{row['team']}</div>
  //       </Row>
  //     </Container>
  //   ),
  //   Header: <div />,
  // },
];

export const StatTable = ({
  stats,
  highlightCallback,
  formatStatByZScore,
  statDefFilterValues = [],
  playerFilterValues = [],
  showPagination,
  indexes,
}) => {
  if (!stats) return null;

  let filteredData = Object.keys(statDefFilterValues).reduce((data, slug) => {
    return data.filter(
      (d) =>
        d[slug] >= statDefFilterValues[slug].min &&
        d[slug] <= statDefFilterValues[slug].max
    );
  }, stats.data);

  if (playerFilterValues && playerFilterValues.length > 0) {
    filteredData = filteredData.filter((d) =>
      playerFilterValues.includes(d['player_id'])
    );
  }

  const filteredIndexColumns = indexes
    ? indexColumns.filter((ic) => indexes.includes(ic.id))
    : indexColumns;

  const formatStat = formatStatByZScore || ((value) => roundToX(value, 1));

  console.log(filteredData)

  return (
    <TableStyles>
      <BDDStatTable
        data={filteredData}
        format={stats.format}
        indexColumns={filteredIndexColumns}
        highlight={true}
        highlightCallback={highlightCallback}
        pagination={showPagination}
        defaultPageLenArg={20}
        formatStat={formatStat}
        sortWithFormat={true}
        defaultSort={[{ id: 'season' }]}
        scroll={false}
      />
    </TableStyles>
  );
};
