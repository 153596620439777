import React, { useCallback, useState } from 'react';

import { useUser } from 'helpers/user';
import LinkRow from './components/LinkRow';

export default function LinkList({
  onLinkSelected, // callback when a link or row is selected
  isSelectedCallback, // a callback, takes a link row or sublink and returns true if it is selected
  initialExpanded, // optionally, you can pass in the id of a row to initially expand
  linkRows, // link rows object: [{id, title, icon, url, links, permission}, ...]
  color,
  size,
  variant,
}) {
  if (!isSelectedCallback) throw Error('Must supply an "isSelectedCallback"');
  // Only one LinkRow can be expanded at a time
  const [expanded, setExpanded] = useState(initialExpanded);
  const { userHasPermission } = useUser();

  const handleRowSelection = useCallback(
    (event, row) => {
      // setSelected(row.id)
      if (expanded === row.id) {
        setExpanded(null);
      } else {
        setExpanded(row.id);
      }
      onLinkSelected && onLinkSelected(row);
    },
    [expanded]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLinkSelection = useCallback(
    (link) => {
      onLinkSelected && onLinkSelected(link);
    },
    [onLinkSelected]
  );

  return (
    <>
      {linkRows
        .filter((row) => !row.permission || userHasPermission(row.permission))
        .map((row) => (
          <LinkRow
            key={row.id}
            id={row.id}
            icon={row.icon}
            title={row.title}
            customSidebar={!!row.renderSidebarComponent}
            expanded={row.id === expanded}
            selected={isSelectedCallback(row)}
            isSelectedCallback={isSelectedCallback}
            // selected={row.id === selected}
            // selectedId={selectedId}
            url={row.url}
            onClick={(event) => handleRowSelection(event, row)}
            color={color}
            size={size}
            links={row.links}
            variant={variant}
            handleLinkSelection={handleLinkSelection}
          />
        ))}
    </>
  );
}
