import { formatDate } from 'helpers/helpers';

export const getPayloadDateRangeFormat = (payload) => {
  const startDate = payload.start_date || payload.startDate;
  const endDate = payload.end_date || payload.endDate;
  const options = { format: '%B %d, %Y' };

  if (startDate == endDate) {
    return `on ${formatDate(startDate, options)}`;
  }

  if (!endDate) {
    return `starting on ${formatDate(startDate, options)}`;
  }

  return `from ${formatDate(startDate, options)} to ${formatDate(endDate, options)}`;
};
