import { useQuery } from "@apollo/client"
import React, { useState, useMemo, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { getDefaultGameType } from "../../helpers/filters"
import { getCurrentSeason, positionToAbbrev, positionToFDG } from "../../helpers/hockeyutils"
import BDDApiError from "../bdd/bddapierror"
import { BDDLoader } from "../bdd/bddloader"
import GameTypeSelect from "../filters/gametypeselect"
import SeasonSelect from "../filters/seasonselect"
import { GET_PLAYER_LINEUP_SPOTS } from "apollo/queries/lineup.queries"

const Styles = styled.div`
    .col {
        padding: 50px;
    }
    table {
        margin: auto;
        font-size: 0.8em;
        text-align: center;
        td {
            padding: 0px 10px;
            border-bottom: 1px solid #ddd;
        }
    }
`
export default function PlayerLineupSpots({
  slUid,
  leagueSlug,
  leagueButtonGroupSm
}) {
  const [season, setSeason] = useState(getCurrentSeason())
  const [gameType, setGameType] = useState(getDefaultGameType(leagueSlug))

  const { data, loading, error } = useQuery(GET_PLAYER_LINEUP_SPOTS, {
    variables: { slUid, season, gameType, leagueSlug },
    skip: !leagueSlug
  })

  useEffect(() => {
    setGameType(getDefaultGameType(leagueSlug))
  }, [leagueSlug])

  const spotToCount = useMemo(() => {
    if (!data) return {}
    return data.slPlayerMaster?.lineupSpotsV3.reduce((acc, curr) => {
      acc[curr.lineupSpot] = !!acc.hasOwnProperty(curr.lineupSpot)
        ? {
          ...acc[curr.lineupSpot],
          games: acc[curr.lineupSpot].games.concat([curr.lineup.game]),
          linemates: acc[curr.lineupSpot].linemates.concat([curr.linemates]),
          count: acc[curr.lineupSpot].count + 1
        } : {
          games: [curr.lineup.game],
          linemates: [curr.linemates],
          count: 1,
          lineupSpot: curr
        }
      return acc
    }, {})
  }, [data])

  if (!leagueSlug) {
    return <div style={{padding: '20px', fontSize: '0.8em'}}>
      <em>No SL data found</em>
    </div>
  }

  if (!!data) {
    var lineupSpots = data.slPlayerMaster.lineupSpotsV3
    const forwardSpots = lineupSpots.filter(ls => ls.isF)
    const isForward = forwardSpots.length > lineupSpots.length / 2

    var fPositions = ['leftwing', 'center', 'rightwing']
    var dPositions = ['leftdefence', 'rightdefence']
    var fPos2d = [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]]
    var dPos2d = [[0, 0], [0, 0], [0, 0]]

    Object.values(spotToCount).forEach(obj => {
      const ls = obj.lineupSpot
      const lineNum = ls.lineNum - 1
      try {
        if (isForward) {
          fPos2d[lineNum][fPositions.indexOf(ls.position)] = obj.count
        } else {
          dPos2d[lineNum][dPositions.indexOf(ls.position)] = obj.count
        }
      } catch { }
    })
    const positions = isForward ? fPositions : dPositions
    const posArr = isForward ? fPos2d : dPos2d
    var positionsTable = <table>
      <tbody>
        <tr>
          <th></th>
          {positions.map(p => <th key={p}>
            {positionToAbbrev(p)}
          </th>)}
        </tr>
        {posArr.map((arr, i) => <tr key={i}>
          <td className='line-num'>{isForward ? 'LINE' : 'PAIR'} {i + 1}</td>
          {posArr[i].map((count, j) => {
            return <td key={j} style={count > 0 ? { fontWeight: 'bold' } : {}}>
              {count}
            </td>
          })}
        </tr>)}
      </tbody>
    </table>
  }
  return <Styles>
    <Row className='justify-content-sm-center' noGutters>
      <Col sm='auto' style={{ textAlign: 'center', paddingRight: '10px' }}>
        {leagueButtonGroupSm}
      </Col>
      <Col sm='auto' style={{ textAlign: 'center', paddingRight: '10px' }}>
        <SeasonSelect value={season} onChange={(n, v) => setSeason(v)} seasonRange={[-2, 0]} style={{ fontSize: '0.9em' }} />
      </Col>
      <Col sm='auto' style={{ textAlign: 'center', paddingRight: '10px' }}>
        <GameTypeSelect disabled={leagueSlug !== 'NHL'} value={gameType} onChange={(n, v) => setGameType(v)} style={{ fontSize: '0.9em' }} />
      </Col>
    </Row>
    <Row className='justify-content-sm-center' noGutters>
      {loading ? <BDDLoader />
        : error ? <BDDApiError error={error} />
          : <>
            {positionsTable}
          </>}
    </Row>
  </Styles>
}