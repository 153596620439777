import { Container } from 'components/bdd/Layout';
import { getGameTypeChoices } from 'helpers/filters';
import { useContext } from 'react';
import { SelectFilter } from '.';
import { VideoContext } from '../..';

export const GameTypeFilter = ({}) => {
  const { sourceFilters } = useContext(VideoContext);
  if (!sourceFilters) return;

  const options = getGameTypeChoices(sourceFilters['league_slug']?.val);

  return (
    <Container minWidth={150}>
      <SelectFilter col={'game_type'} options={options} label={'Game Type'} />
    </Container>
  );
};
