import React, { createContext } from 'react';
import { useSLStatContext } from './hooks';

export const SLStatContext = createContext();

export const SLStatContextProvider = ({
  context: contextArg,
  defaults,
  children,
}) => {
  let context = contextArg;

  if (!context) {
    context = useSLStatContext({ defaults });
  }

  return (
    <SLStatContext.Provider value={context}>{children}</SLStatContext.Provider>
  );
};
