import { Container } from "components/bdd/Layout"
import BDDDepthChart from "../bdddepthchart"
import { useState } from "react"
import { bruinsGold } from "helpers/plotting";
import { Typography } from "components/bdd/Typography";
import PlayerHover from "components/PlayersV2/Hover/playerhover";


export const useInteractiveDC = ({
  teamSlug,
  uniqueKey,
  depthChartId,
  initialSelectedPlayerSlugs=[],
  allowHover=true,
  selectedPlayerStyles: selectedPlayerStylesArg,

  // the following can override the default state mgmt in this hook
  handlePlayerClick, // (slug, bddPlayer) if supplied, overrides default selected players state
  onPlayerClick, // doesn't override select management, does get called back
  playerIsSelected: playerIsSelectedArg, // called with bddPlayer, if supplied (usually along with handlePlayerClick) will use this to determine selection shadow
  playerToStyle: playerToStyleArg, // if supplied, overrides default styling

  depthChartProps={}, // overrides
}) => {
  const [selected, setSelected] = useState(initialSelectedPlayerSlugs);

  const handleTogglePlayer = (slug, bddPlayer) => {
    if (!!selected.find(s => s === slug)) {
      if (!!onPlayerClick) onPlayerClick(slug, bddPlayer, false);
      setSelected(selected.filter(s => s !== slug))
    } else {
      if (!!onPlayerClick) onPlayerClick(slug, bddPlayer, true);
      setSelected(selected.concat([slug]))
    }
  }

  const selectedPlayerStyles = !!selectedPlayerStylesArg
    ? selectedPlayerStylesArg
    : { boxShadow: `0 0 5px 2px ${bruinsGold}` };
  
  const playerIsSelected = !!playerIsSelectedArg
    ? playerIsSelectedArg
    : (bddPlayer) => selected.find(s => s === bddPlayer.slug);

  const playerToStyle = !!playerToStyleArg
    ? playerToStyleArg
    : (bddPlayer) => {
      return playerIsSelected(bddPlayer) 
        ? selectedPlayerStyles 
        : {};
    };

  const depthChart = <BDDDepthChart 
    depthChartId={depthChartId}
    activeTeamSlug={!depthChartId ? teamSlug : null} 
    uniqueKey={!depthChartId ? uniqueKey : null}
    editable={false}
    showButtons={false}
    showProspects={true}
    showLists={false}
    showChanges={false}     
    playerSize={'sm'}
    renderPlayer={({ bddPlayer }) => {
      if (!bddPlayer) return 
      return <Container 
        onClick={() => !!handlePlayerClick ? handlePlayerClick(bddPlayer.slug, bddPlayer) : handleTogglePlayer(bddPlayer.slug, bddPlayer)} 
        style={playerToStyle(bddPlayer)}

      >
        <Typography variant='body2' textAlign='center'>
          <PlayerHover disabled={!allowHover} playerSlug={bddPlayer.slug} delay={{ show: 500, hide: 50 }}>
            {bddPlayer.firstname} {bddPlayer.lastname}
          </PlayerHover>
        </Typography>
      </Container>
    }}
    nonNHLDisplayMode={'expandable'} // either visible, expandable or hidden
    {...depthChartProps}
  />

  return {
    depthChart,
    selected,
    setSelected
  }
}