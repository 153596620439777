import React from 'react';
import { ErrorMessage } from 'formik';

import BDDFormError from '../bdd/bddformerror';
import { BDDInput } from '../bdd';
import { Typography } from 'components/bdd/Typography';
import { Label } from 'components/bdd/Label/Label';

export default function BDDInputField({ title, noTitle, field, ...rest }) {
  const { name, value, onChange } = field; // dont know why error isn't in here
  const error = rest.form.errors[name];
  const touched = rest.form.touched[name];
  return (
    <div>
      {noTitle || title == undefined ? null : <Label>{title}</Label>}
      <BDDInput
        type={rest.type || 'text'}
        name={name}
        placeholder={title}
        value={value}
        onChange={onChange}
        isInvalid={!!error}
        {...rest}
      />
      <Typography variant='error'>
        {error}
      </Typography>
    </div>
  );
}
