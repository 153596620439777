import { Container } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import React, { useContext, useState } from 'react';
import { Button, Collapse, Modal, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { bruinsBlack, bruinsGold } from '../../helpers/plotting';
import { SectionHeader } from '../reports';

const CTable = styled.table`
  .contract {
    padding: 0 10px;
  }
  .xsmall {
    font-size: 0.5em;
  }
  .future-contract {
    border-left: ${(props) =>
      props.orientation == 'horizontal' ? '2px solid #333' : 'none'};
    border-top: ${(props) =>
      props.orientation == 'vertical' ? '2px solid #333' : 'none'};
  }
`;
const ContractBubble = styled.div`
  display: inline-block;
  background: ${(props) => props.background || bruinsBlack};
  color: ${(props) => props.color || bruinsGold};
  border-color: ${(props) => props.borderColor || 'transparent'};
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 2px 5px;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 10px ${(props) => props.color || bruinsGold};
  }
  transition: all 0.2s ease;
`;
const GROUP_TO_STYLE = {
  'GROUP II - NOT ELIGIBLE': { background: bruinsGold, color: bruinsBlack },
  'GROUP II - ELIGIBLE': {
    background: `repeating-linear-gradient(
            -45deg, ${bruinsGold}, ${bruinsGold} 2px,
            ${bruinsGold}77 2px, ${bruinsGold}77 4px
        )`,
    color: bruinsBlack,
  },
  'GROUP III': { background: bruinsBlack, color: bruinsGold },
  'GROUP VI': { background: 'white', color: 'black', borderColor: 'black' },
  '10.2.c': { background: 'lightblue', color: 'black' },
};

export default function PlayerContract({
  activeContract,
  allContracts,
  futureContract,
  isLoading,
  // 'vertical' or 'horizontal'
  orientation,
  noContractContent = (
    <Typography variant="body2">
      <em>No contract</em>
    </Typography>
  ),
  onClick: onClickArg, // by default, show modal, but allow override
}) {
  const [show, setShow] = useState(false);
  const { renderModal, hideModal } = useContext(ModalContext);

  if (isLoading) return <em>Loading contract...</em>;

  const handleClick = () => {
    if (!!onClickArg) onClickArg();
    else {
      const name = allContracts && allContracts[0].name;

      setShow(true);
      renderModal({
        size: 'xl',
        title: <SectionHeader>{name} Contracts</SectionHeader>,
        body: <PlayerContractsModalBody allContracts={allContracts} />,
        footer: (
          <Button variant="secondary" onClick={() => hideModal()}>
            Close
          </Button>
        ),
      });
    }
  };

  if (!activeContract && !!allContracts) {
    return (
      <>
        {noContractContent}
        <div>
          <a
            href=""
            style={{ fontSize: '0.6em' }}
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            See all contracts
          </a>
        </div>
      </>
    );
  }
  if (!activeContract && !allContracts) {
    return noContractContent;
  }

  const renderContract = (contract) => {
    if (contract.expirationContractGroup in GROUP_TO_STYLE) {
      var style = GROUP_TO_STYLE[contract.expirationContractGroup];
    } else {
      console.warn(
        `Unknown expiration group: ${contract.expirationContractGroup}`
      );
    }
    return (
      <div onClick={handleClick}>
        <div className="xsmall">
          {contract.startSeason}-{contract.expirationSeason} w/{' '}
          {contract.signedWithTeamShort}
        </div>
        <ContractBubble {...style}>{contract.averagedAmountStr}</ContractBubble>{' '}
        <span className="term">x{contract.remainingTerm}</span>
        {contract.hasNmcOrNtc && <div className="xsmall">NMC/NTC*</div>}
        {contract.isActive && !futureContract && (
          <div className="xsmall">
            Expiry: {parseInt(contract.expirationAge)}y.o.
          </div>
        )}
      </div>
    );
  };

  const futureContractCell = !!futureContract && (
    <td className="contract future-contract">
      {renderContract(futureContract)}
    </td>
  );

  return (
    <>
      <CTable orientation={orientation || 'horizontal'}>
        <tbody>
          <tr>
            <td className="contract active-contract">
              {renderContract(activeContract)}
            </td>
            {orientation == 'horizontal' && futureContractCell}
          </tr>
          {orientation == 'vertical' && <tr>{futureContractCell}</tr>}
        </tbody>
      </CTable>
    </>
  );
}

const ContractStyles = styled.div`
  border: 2px solid #333;
  padding: 20px;
  margin-top: 10px;
  overflow: scroll;

  .contract-header {
    font-size: 1.1em;
  }

  table.cap-table {
    width: 100%;
    font-size: 0.7em;
    text-align: center;

    th {
      color: #333;
      padding: 0;
      margin: 0;
      border-bottom: 2px solid #333;
    }

    td {
      padding: 5px;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
`;
const PlayerContractsModalBody = ({ allContracts }) => {
  if (!allContracts || allContracts.length == 0) return null;
  const [currContract, setCurrContract] = useState(
    allContracts[allContracts.length - 1].startSeason
  );

  return (
    <Container padding={3} overflow="auto">
      <div className="bubbles-key">
        <center style={{ fontSize: '0.8em' }}>CONTRACTS KEY</center>
        <Row style={{ textAlign: 'center' }}>
          {Object.entries(GROUP_TO_STYLE).map(([group, style]) => (
            <Col key={group}>
              <ContractBubble style={{ fontSize: '0.8em' }} {...style}>
                {group}
              </ContractBubble>
            </Col>
          ))}
        </Row>
      </div>
      {allContracts.map((contract) => {
        const contractClauses = JSON.parse(contract.contractClauses);
        const contractYears = JSON.parse(contract.contractYears);
        const contractComments = contract.contractComments;
        return (
          <ContractStyles
            key={contract.startSeason}
            onClick={() => setCurrContract(contract.startSeason)}
          >
            <div>
              <Row className="contract-header">
                <Col md={6}>
                  {contract.signingContractGroup === 'ENTRY LEVEL'
                    ? 'Entry Level '
                    : 'Standard '}
                  Contract:{' '}
                  <strong>
                    {contract.startSeason}-{contract.expirationSeason}
                  </strong>
                </Col>
                <Col md={3}>
                  <small>Signed with {contract.signedWithTeamShort}</small>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {contract.startSeason != currContract && (
                    <small>(click to expand)</small>
                  )}
                </Col>
              </Row>
              <Collapse in={currContract === contract.startSeason}>
                <div>
                  <table className="cap-table ct-modal">
                    <tbody>
                      <tr>
                        <th>Season</th>
                        <th>League</th>
                        <th>AAV</th>
                        <th>Tot.</th>
                        <th>Sign.</th>
                        <th>Perf.</th>
                      </tr>
                      {contractYears.map((year) =>
                        ['NHL', 'AHL'].map((league) =>
                          league in year.contracts ? (
                            <tr key={year + league}>
                              <td>{year.season}</td>
                              <td>{league}</td>
                              <td>
                                {year.contracts[league]['averaged_amount']}
                              </td>
                              <td>{year.contracts[league]['total_salary']}</td>
                              <td>{year.contracts[league]['signing_bonus']}</td>
                              <td>
                                {year.contracts[league]['performance_bonus']}
                              </td>
                            </tr>
                          ) : null
                        )
                      )}
                    </tbody>
                  </table>
                  <table className="cap-table ct-modal">
                    <tbody>
                      <tr>
                        <th>Season</th>
                        <th>Clause</th>
                        <th>Condition</th>
                        <th>Bonus Amt.</th>
                        <th>Comments</th>
                      </tr>
                      {!!contractClauses ? (
                        contractClauses.map((clause, index) => (
                          <tr key={index}>
                            <td>{clause.season}</td>
                            <td>{clause.clause}</td>
                            <td>{clause.condition}</td>
                            <td>{clause.bonus_amount}</td>
                            <td>{clause.comments}</td>
                          </tr>
                        ))
                      ) : !contractClauses || contractClauses.length === 0 ? (
                        <tr>
                          <td colSpan={5}>
                            <em>None</em>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <table className="cap-table ct-modal">
                    <tbody>
                      <tr>
                        <th>Comments</th>
                      </tr>
                      <tr style={{ background: 'white' }}>
                        {contractComments.length > 0 ? (
                          <td style={{ textAlign: 'left' }}>
                            {contractComments}
                          </td>
                        ) : (
                          <td colSpan={5}>
                            <em>None</em>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
            </div>
          </ContractStyles>
        );
      })}
    </Container>
  );
};
