import React, { useEffect, useState } from 'react'
import { SectionHeader } from '../reports'
import { useFormik } from "formik"
import * as Yup from "yup"
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap"

import { BDDFormControl } from "../bdd"
import styled from 'styled-components'
import PlayerListWrapper from './playerlistwrapper'
import BDDSelect from '../bdd/bddselect'
import { useQuery } from '@apollo/client'
import { buildPlayerListQuery } from '../../apollo/queries/playerlist.queries'
import { formatDate } from '../../helpers/helpers'

const schema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string()
})

const Styles = styled.div`
    label {
        font-size: 0.8em;
        text-transform: uppercase;
        color: #aaa;
        margin-top: 10px;
    }
`
// const updateCacheFxn = {
//     update(cache, { data: { createPlayerList } }) {
//         cache.modify({
//             fields: {
//                 playerLists(existing=[]) {
//                     return existing.concat(createPlayerList.playerList)
//                 }
//             }
//         });
//     }
// }

const CreateCheckpointForm = ({
    handleSubmit
}) => {
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            handleSubmit(values)
        }
    })

    return <Form onSubmit={formik.handleSubmit}>
        <Row>
            <Col>
                <BDDFormControl
                    formik={formik}
                    title='Name'
                    name='name'
                    label
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <BDDFormControl
                    formik={formik}
                    title='Description'
                    name='description'
                    as='textarea'
                    rows={3}
                    label
                />
            </Col>
        </Row>

        <Row style={{marginTop: "5px", textAlign: 'center'}}>
            <Col>
                <Button type="submit" variant="warning">
                    Submit
                </Button>
            </Col>
        </Row>
        
    </Form>
}

export const CreateCheckpointModal = ({
    show,
    handleClose,
    handleSubmit
}) => {
    return <Modal
        show={show}
        onHide={handleClose}
    >
        <Modal.Header closeButton><SectionHeader>Create Checkpoint</SectionHeader></Modal.Header>
        <Modal.Body>
            <Styles>
                <CreateCheckpointForm handleSubmit={values => {
                    handleSubmit(values)
                    setTimeout(handleClose, 1000)
                }} />
            </Styles>
        </Modal.Body>  
    </Modal>
}

export const ViewCheckpointModal = ({
    show,
    handleClose,
    handleDelete,
    handleRevert,
    listId,
    renderPlayer,
    playerFields,
    editMode,
    jumpToCreate
}) => {
    const [checkpointId, setCheckpointId] = useState(null)
    const { data, loading, error } = useQuery(buildPlayerListQuery(), { 
        variables: { id: listId }
    })
    useEffect(() => {
        if (!checkpointId && data.playerList.checkpoints.length > 0) {
            setCheckpointId(data.playerList.checkpoints[0].id)
        }
    }, [data])

    const options = !!data ? 
        data.playerList.checkpoints.map(c => 
            ({ label: <>{c.name} <em><small>({formatDate(c.dateCreated)})</small></em></>, value: c.id}))    
        : []
    return <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body>
            <Row className='justify-content-md-center'>
                <Col sm={3}></Col>
                <Col>
                    <BDDSelect
                        value={checkpointId}
                        options={options}
                        onChange={(n,v) => setCheckpointId(v)}
                    />
                </Col>
                <Col sm={3} style={{textAlign: 'right'}}>
                    {editMode && <Button size='sm' variant='outline-dark' onClick={jumpToCreate}>
                        Create Checkpoint
                    </Button>}
                </Col>
            </Row>
            <hr></hr>
            {!!checkpointId && <PlayerListWrapper
                listId={checkpointId} 
                defaultEditMode={false} 
                showEditButton={false} 
                showDeleteButton={false}
                showDetailsButton={false}
                showAddPlayers={false}
                showCheckpointButtons={false}
                showUserAccessButton={false}
                showCreateTierButton={false}
                showPrimaryDraftListButton={false}
                showDownloadButton={true}
                showMenu={true}
                renderPlayer={renderPlayer}
                playerFields={playerFields}
                pollInterval={null}
            />}
        </Modal.Body>
        <Modal.Footer>
            {editMode && <Button variant='danger' onClick={() => {
                if (confirm('Are you sure you want to delete this checkpoint?')) {
                    handleDelete(checkpointId)
                    handleClose()
                }
            }}>
                Delete Checkpoint
            </Button>}

            {editMode && <Button variant='primary' onClick={() => {
                if (confirm('Are you sure you want to revert to this checkpoint?')) {
                    handleRevert(checkpointId)
                    handleClose()
                }
            }}>
                Revert to Checkpoint
            </Button>}
        
            <Button variant='secondary' onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
}