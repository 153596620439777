import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { numberToOrdinal } from '../../helpers/helpers'
import { BDDOptionGroup } from '../bdd'
import { SectionHeader, TooltipSpan } from '../reports'
import SearchSelect from '../Search/searchselect'
import { buildLineupSpot, parseLineupSpot } from './helpers'

export default function DCSetPlayerForSpotModal({
    show,
    handleClose,
    depthChartTitle,
    onAddPlayer,
    maxLineupSpot=null
}) { 
    const [league, setLeague] = useState('National Hockey League')
    const [lineNum, setLineNum] = useState(null)
    const selectRef = useRef()

    if (!!show && !maxLineupSpot) {
        console.warn('DCSetPlayerForSpotModal needs a "maxLineupSpot"')
    }

    const handleSubmit = playerSlug => {
        const { depthLeague, position } = parseLineupSpot(maxLineupSpot)
        const destLineupSpot = buildLineupSpot(depthLeague, position, lineNum)
        onAddPlayer(destLineupSpot, playerSlug)
        setTimeout(() => handleClose(), 100)
    }

    useEffect(() => {
        selectRef.current.focus()
    }, [])
    
    useEffect(() => {
        var { lineNum: maxLineNum } = parseLineupSpot(maxLineupSpot)
        setLineNum(parseInt(maxLineNum))
    }, [maxLineupSpot])

    var { lineNum: maxLineNum } = parseLineupSpot(maxLineupSpot)
    const lineOptions = [...Array(parseInt(maxLineNum))].map((_, i) => ({ value: i+1, label: numberToOrdinal(i+1) }))

    return <Modal
        show={show}
        onHide={handleClose}
    >
        <Modal.Header>
            <SectionHeader>Add Player to {depthChartTitle ? `"${depthChartTitle}"` : 'Depth Chart' }</SectionHeader>
        </Modal.Header>
        <Modal.Body>
            <div>
                <BDDOptionGroup
                    options={[
                        { label: 'NHL', value: 'National Hockey League' },
                        { label: 'AHL', value: 'American Hockey League' },
                        { label: 'All', value: 'None' },
                    ]}
                    selected={league}
                    onClick={val => setLeague(val)}
                />
            </div>
            <div style={{ marginTop: '10px'}} >
                <SearchSelect
                    placeholder={`Select player to add`}
                    resultTypes={['BDDPLAYERS']}
                    handleSelect={obj => handleSubmit(obj.value)}
                    league={league === 'None' ? undefined : league}
                    selectRef={selectRef}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <b>Line:</b>
                {' '}
                <BDDOptionGroup
                    style={{ display: 'inline-block' }}
                    options={lineOptions}
                    selected={lineNum}
                    onClick={val => {
                        setLineNum(val)
                        selectRef.current.focus()
                    }}
                />
            </div>
            <div>
                <small><em>n.b. Set "Line" before selecting player</em></small>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}