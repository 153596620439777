// Array Prototype -- add flat if not defined!
if (!Array.prototype.flat) {
    Object.defineProperty(Array.prototype, 'flat', {
        value: function (depth = 1) {
            return this.reduce(function (flat, toFlatten) {
                return flat.concat((Array.isArray(toFlatten) && (depth > 1)) ? toFlatten.flat(depth - 1) : toFlatten);
            }, []);
        }
    });
}

export const compareObjects = (obj1, obj2) => {
    // Performs a simple JSON stringify comparison after ordering keys
    // Won't work with complex objects
    const ordered1 = Object.keys(obj1).sort().reduce(
        (obj, key) => {
            obj[key] = obj1[key];
            return obj;
        }, {}
    )
    const ordered2 = Object.keys(obj2).sort().reduce(
        (obj, key) => {
            obj[key] = obj2[key];
            return obj;
        }, {}
    )
    return JSON.stringify(ordered1) == JSON.stringify(ordered2)
}