import React from 'react';
import { formatDate } from '../../helpers/helpers';
import { getRedGreenColor } from '../../helpers/tables';
import BDDPlotly from '../bdd/bddplotly';

const MAX_GREEN = getRedGreenColor(1, 31, 0);
const MAX_RED = getRedGreenColor(31, 31, 0);

export default function MiniTrendLine({
  xs,
  ys,
  n,
  avgLine,
  stddev = null,
  negative,
  type = 'scatter',
  opponents = null,
  gameDates = null,
  stddevThreshMultiplier = 2,
  thresholds: thresholdsArg,
  width = 110,
}) {
  const len = xs.length;
  const numGames = n ? n : len;
  const start = Math.max(0, len - numGames);
  // const halfPoint = start + Math.ceil((len - start)/2)
  // const firstHalf = ys.slice(start, halfPoint)
  // const firstHalfAvg = firstHalf.reduce((a, b) => a+b) / firstHalf.length
  // const secondHalf = ys.slice(halfPoint, len)
  // const secondHalfAvg = secondHalf.reduce((a, b) => a+b) / secondHalf.length
  // const compLine = 0.5 * avgLine
  // if (firstHalfAvg - secondHalfAvg > compLine) {
  //     var color = negative ? MAX_GREEN : MAX_RED
  // } else if (secondHalfAvg - firstHalfAvg > compLine) {
  //     var color = negative ? MAX_RED : MAX_GREEN
  // } else {
  //     var color = 'black'
  // }
  const mean = avgLine;
  const stddevThreshold = stddevThreshMultiplier * stddev;
  const thresholds = thresholdsArg
    ? thresholdsArg.length == 1
      ? [thresholdsArg[0], thresholdsArg[0]]
      : thresholdsArg
    : [mean - stddevThreshold, mean + stddevThreshold];

  const colorCallback =
    !isNaN(mean) && mean != null && !isNaN(stddev) && stddev != null
      ? (val) => {
          if (negative) {
            return val > thresholds[1] ? 'red' : val <= thresholds[0] ? 'green' : 'black';
          } else {
            return val > thresholds[1] ? 'green' : val <= thresholds[0] ? 'red' : 'black';
          }
        }
      : () => 'black';

  // const customdata = !!opponents && !!gameDates ?
  //     opponents.map((opp, i) => `${opp}, ${formatDate(gameDates[i], { format: '%m/%d' })}`)
  //     : null

  const dashTrace = {
    type: 'scatter',
    mode: 'lines',
    x: [xs[start], xs[len - 1]],
    y: [avgLine, avgLine],
    opacity: 0.5,
    line: { color: '#555', width: 1, dash: 'dot' },
    hoverinfo: 'skip',
  };
  const ySlice = ys.slice(start, len);
  const trendTrace =
    type === 'scatter'
      ? {
          type: 'scatter',
          mode: 'lines',
          x: xs.slice(start, len),
          y: ySlice,
          opacity: 0.5,
          line: { color: color },
          hoverinfo: 'skip',
        }
      : {
          type: 'bar',
          x: xs.slice(start, len),
          y: ySlice,
          opacity: 0.5,
          marker: { color: ySlice.map((v) => colorCallback(v)) },
          hoverinfo: 'skip',
        };

  return (
    <BDDPlotly
      data={[dashTrace, trendTrace]}
      layout={{
        margin: { t: 0, b: 0, l: 0, r: 0 },
        height: 20,
        width: width,
        showlegend: false,
        xaxis: {
          autorange: true,
          showgrid: false,
          zeroline: false,
          showline: false,
          autotick: true,
          ticks: '',
          showticklabels: false,
        },
        yaxis: {
          autorange: true,
          showgrid: false,
          zeroline: false,
          showline: false,
          autotick: true,
          ticks: '',
          showticklabels: false,
        },
        annotations:
          type === 'scatter'
            ? [
                {
                  x: xs[len - 1], // arrows' head
                  y: ys[len - 1], // arrows' head
                  ax: xs[len - 2], // arrows' tail
                  ay: ys[len - 2], // arrows' tail
                  xref: 'x',
                  yref: 'y',
                  axref: 'x',
                  ayref: 'y',
                  text: '',
                  showarrow: true,
                  arrowhead: 1,
                  arrowsize: 1,
                  arrowwidth: 2,
                  arrowcolor: color,
                  opacity: 0.5,
                },
              ]
            : [],
      }}
      config={{ displayModeBar: false, staticPlot: true }}
      // config={{ displayModeBar: false }}
    />
  );
}
