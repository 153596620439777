import { gql } from '@apollo/client';

export const GET_PLAYER_HOCKEY_MODEL_RESULTS = gql`
  query GetHockeyModelResults($modelSlugs: [ID!]!, $rinknetId: ID!) {
    hockeyModelResults(modelSlugs: $modelSlugs, rinknetId: $rinknetId) {
      modelRunId
      modelSlug
      targetType
      targetId
      modelScore
      percentile
      details
      parsedDetails
    }
  }
`;

export const GET_SIMILAR_PLAYER_HOCKEY_MODEL_RESULTS = gql`
  query GetHockeyModelResults($modelSlugs: [ID!]!, $rinknetId: ID, $rinknetIds: [ID]) {
    hockeyModelResults(
      modelSlugs: $modelSlugs
      rinknetId: $rinknetId
      rinknetIds: $rinknetIds
    ) {
      modelRunId
      modelSlug
      targetType
      targetId
      modelScore
      percentile
      parsedInputVal
      parsedDetails
      similarPlayersLeagueAbbreviations
    }
  }
`;

export const GET_SIMILAR_PLAYER_MADE_IT_PCT = gql`
  query GetSimilarPlayersMadeItPct($rinknetIds: [ID!]!) {
    similarPlayersMadeItPct(rinknetIds: $rinknetIds) {
      rinknetId
      madeItPct
    }
  }
`;

export const GET_PLAYERS_SCOUTING_MODEL_RESULTS = gql`
  query GetScoutingModelResults($modelSlugs: [ID!]!, $rinknetIds: [ID!]!) {
    scoutingModelResults(modelSlugs: $modelSlugs, rinknetIds: $rinknetIds) {
      hockeyModelResult {
        modelRunId
        modelSlug
        targetType
        targetId
        modelScore
        details
        percentile
      }
      rinknetPlayer {
        id
        realId
      }
    }
  }
`;
