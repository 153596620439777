import { useQuery } from '@apollo/client';
import { GET_EP_STAT_FILTERS } from 'apollo/queries/stat.queries';
import { useEffect } from 'react';
import { useEPStatsContext } from '../context';

export const FilterOptionsLoader = ({}) => {
  const { setFilterOptions } = useEPStatsContext((state) => state.actions);
  const { data } = useQuery(GET_EP_STAT_FILTERS, {
    variables: { target: 'ep_player_season_stats' },
  });

  useEffect(() => {
    if (!data) return;

    const {
      mapping,
      dynamic_filters: { league_season_team: leagueSeasonTeamFilters },
      dynamic_filters: { position_group_by: positionGroupByFilters },
      static_filters: {
        game_type: gameTypeFilters,
        draft_eligible_years: draftEligibleYearsRange,
      },
    } = data.epStatsFilters;

    setFilterOptions({
      mapping,
      leagueSeasonTeamFilters,
      positionGroupByFilters,
      draftEligibleYearsRange,
      gameTypeFilters,
    });
  }, [data]);
};
