import { onError } from "apollo-link-error";
import React from "react"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export function toastBddApiError(error) {
  try {
    var message = error.message
    message = message.replace('400 Bad Request: ', '')
  } catch {
    var message = 'Details not found'
  }

  toast.error(message)
}

export function toastError(msg) {
  toast.error(msg)
}
export function toastInfo(msg) {
  toast.info(msg)
}
export function toastCustom(msg, props) {
  toast.info(msg, props)
}

export default function BDDToast(props) {
  return <>
    <ToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={true}
      draggable
      pauseOnHover
      {...props}
    />
  </>
}