import { useState, useEffect } from 'react';

const initialPlayerState = {
  isPlaying: false,
  isReady: false, // ready to play at least a couple of frames
  isFullyReady: false, // ready state of 4 -- should have no buffering issues
  isInitialized: false, // Have we set the video start, autoplay, etc?
  currentTime: 0,
  progress: 0,
  speed: 1,
  isMuted: true,
  buffering: false,
  isFinished: false, // true after video has finished
  frontPad: 5,
  backPad: 5,
  // Trimming values are based off progress (0-100)
  leadTrim: 0,
  trailTrim: 100,
};

const getPlaybackTimes = (
  targetTime,
  targetDuration,
  frontPad,
  backPad,
  playbackMode,
  videoRef
) => {
  // Keep track of start and end time -- either it's the full clip length OR its supplied as a target with offsets
  let startTime;
  let endTime;

  if (playbackMode === 'offsets') {
    startTime = !!targetTime ? targetTime - frontPad : null;
    endTime = !!targetTime ? targetTime + targetDuration + backPad : null;
  } else if (playbackMode === 'fullclip') {
    startTime = 0;
    endTime = videoRef.current?.duration;
  }

  const duration = endTime - startTime;
  // const trimmedStartTime = startTime + (duration * leadTrim) / 100;
  // const trimmedEndTime = endTime - (duration * trailTrim) / 100;
  // const trimmedDuration = trimmedEndTime - trimmedStartTime;

  return {
    startTime,
    endTime,
    duration,
    // trimmedStartTime,
    // trimmedEndTime,
    // trimmedDuration,
  };
};

export default function useBddVideoPlayer({
  clip,
  videoRef,
  autoPlay = false,
  playbackMode = 'fullclip', // "fullclip" (play full clip) or "offsets" (pass in targetTime, frontPad, backPad)
  targetTime,
  targetDuration = 0,
  frontPad = 5,
  backPad = 5,
}) {
  const getReadyStates = () => {
    if (!videoRef || !videoRef.current) {
      return {
        isReady: false,
        isFullyReady: false,
        buffering: false,
      };
    }

    return {
      isReady: videoRef.current.readyState > 2,
      isFullyReady: videoRef.current.readyState === 4,
      buffering: videoRef.current.networkState === 2,
    };
  };


  const getInitialPlayerState = () => {
    return {
      playbackMode,
      frontPad,
      backPad,
      targetTime,
      targetDuration,
      ...initialPlayerState,
      ...getReadyStates(), // if this is a 2nd clip, video state might already be ready
      isPlaying: autoPlay,
    };
  };

  const [playerState, setPlayerState] = useState(getInitialPlayerState());

  const togglePlay = () => {
    setPlayerState((playerState) => ({
      ...playerState,
      isPlaying: !playerState.isPlaying,
      targetTime,
      targetDuration,
    }));
  };

  const applyTrim = (progress) =>
    playerState.leadTrim > progress
      ? playerState.leadTrim
      : playerState.trailTrim < progress
      ? playerState.trailTrim
      : progress;

  const handleOnTimeUpdate = () => {
    const { startTime, duration } = getPlaybackTimes(
      targetTime,
      targetDuration,
      clip.frontPad || playerState.frontPad,
      clip.backPad || playerState.backPad,
      playerState.playbackMode,
      videoRef
    );

    const currentTime = videoRef.current.currentTime;
    const progress = applyTrim(((currentTime - startTime) / duration) * 100);

    const isFinished = progress >= Math.min(100, playerState.trailTrim);

    setPlayerState((playerState) => ({
      ...playerState,
      progress,
      currentTime,
      isFinished,
    }));
  };

  const handleVideoProgress = (progress) => {
    const { startTime, duration } = getPlaybackTimes(
      targetTime,
      targetDuration,
      clip.frontPad || playerState.frontPad,
      clip.backPad || playerState.backPad,
      playerState.playbackMode,
      videoRef
    );

    const trimmedProgress = applyTrim(progress);

    videoRef.current.currentTime = startTime + (duration / 100) * trimmedProgress;
    setPlayerState((playerState) => ({
      ...playerState,
      progress: trimmedProgress,
    }));
  };

  const seekToSeconds = (seconds) => {
    const { startTime, duration } = getPlaybackTimes(
      targetTime,
      targetDuration,
      clip.frontPad || playerState.frontPad,
      clip.backPad || playerState.backPad,
      playerState.playbackMode,
      videoRef
    );

    videoRef.current.currentTime = seconds;
    setPlayerState((playerState) => ({
      ...playerState,
      progress: applyTrim(((seconds - startTime) / duration) * 100),
    }));
  };

  const handleVideoSpeed = (speed) => {
    videoRef.current.playbackRate = speed;
    setPlayerState((playerState) => ({
      ...playerState,
      speed,
    }));
  };

  const toggleMute = () => {
    setPlayerState((playerState) => ({
      ...playerState,
      isMuted: !playerState.isMuted,
    }));
  };

  const handleReadyStateChange = () => {
    const readyStates = getReadyStates();
    setPlayerState((playerState) => ({
      ...playerState,
      ...readyStates,
    }));
  };

  const setFrontPad = (val) => {
    const pad = parseInt(val);
    const currentTime = videoRef.current.currentTime;
    const newDuration = playerState.backPad + pad;
    const startTime = playerState.targetTime - pad;
    const progress = applyTrim(((currentTime - startTime) / newDuration) * 100);
    setPlayerState((playerState) => ({
      ...playerState,
      frontPad: pad,
      progress,
      currentTime,
    }));
  };

  const setBackPad = (val) => {
    const pad = parseInt(val);
    const currentTime = videoRef.current.currentTime;
    const newDuration = playerState.frontPad + pad;
    const startTime = playerState.targetTime - playerState.frontPad;
    const progress = applyTrim(((currentTime - startTime) / newDuration) * 100);
    setPlayerState((playerState) => ({
      ...playerState,
      backPad: pad,
      progress,
      currentTime,
    }));
  };

  // On "isPlaying" change
  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    playerState.isPlaying ? videoRef.current.play() : videoRef.current.pause();
  }, [playerState.isPlaying, videoRef]);

  // On isMuted change
  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    playerState.isMuted
      ? (videoRef.current.muted = true)
      : (videoRef.current.muted = false);
  }, [playerState.isMuted, videoRef]);

  useEffect(() => {
    setPlayerState((playerState) => ({
      ...playerState,
      targetTime,
      targetDuration,
    }));

    if (
      targetTime !== playerState.targetTime ||
      targetDuration !== playerState.targetDuration
    ) {
      setPlayerState((playerState) => ({
        ...playerState,
        targetTime,
        targetDuration,
      }));
    }
  }, [targetTime, targetDuration]);

  // On change of clip Id, reset playerState
  useEffect(() => {
    if (!clip) return;

    const { startTime } = getPlaybackTimes(
      targetTime,
      targetDuration,
      clip.frontPad || playerState.frontPad,
      clip.backPad || playerState.backPad,
      playerState.playbackMode,
      videoRef
    );

    videoRef.current.currentTime = startTime;
    if (playerState.isPlaying) videoRef.current.play();

    setPlayerState((playerState) => ({
      ...getInitialPlayerState(),
      frontPad: clip.frontPad || playerState.frontPad,
      backPad: clip.backPad || playerState.backPad,
      isMuted: playerState.isMuted,
      isPlaying: playerState.isPlaying,
    }));
  }, [clip]);

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    videoRef.current.addEventListener('loadstart', handleReadyStateChange);
    videoRef.current.addEventListener('loadeddata', handleReadyStateChange);

    return () => {
      if (!videoRef || !videoRef.current) {
        return;
      }

      videoRef.current.removeEventListener('loadstart', handleReadyStateChange);
      videoRef.current.removeEventListener('loadeddata', handleReadyStateChange);
    };
  });

  const handleVideoTrim = (trim) => {
    setPlayerState((playerState) => ({
      ...playerState,
      leadTrim: trim.lead,
      trailTrim: trim.trail,
    }));
  };

  const videoControls = {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoTrim,
    toggleMute,
    seekToSeconds,
    setFrontPad,
    setBackPad,
    autoPlay,
  };

  return videoControls;
}
