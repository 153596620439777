import { IconButton, LinkButton } from 'components/bdd/Button';
import { Divider } from 'components/bdd/Divider';
import { Dropdown } from 'components/bdd/Dropdown';
import { Column, Container, Grid, Row } from 'components/bdd/Layout';
import { useUserGroupSelect, useUserSelect } from 'components/bdd/Select';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import BDDInputField from 'components/form/bddinputfield';
import BDDTextAreaField from 'components/form/bddtextareafield';
import { Field, Form, Formik } from 'formik';
import produce from 'immer';
import React from 'react';
import { Button } from 'react-bootstrap';
import { PersonCircle, XLg } from 'react-bootstrap-icons';
import * as Yup from 'yup';

export const PlaylistForm = ({
  defaultValues,
  onSubmit,
  submitting,
  disabled,
  children,
}) => {
  const schema = Yup.object({
    name: Yup.string().required('Name is a required field'),
    description: Yup.string(),
    users: Yup.array(),
  });

  const initialValues = defaultValues || {
    name: '',
    description: '',
    users: [],
  };

  const { userOptions, userId: userToAdd, userSelect } = useUserSelect({});
  const { userIds: usersToAdd, userGroupSelect } = useUserGroupSelect({});

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
      {({ isSubmitting, values, errors, setFieldValue }) => {
        const handleAddUser = (userToAdd) => {
          handleAddUsers([userToAdd]);
        };

        const handleAddUsers = (userIdsToAdd) => {
          const usersToAdd = userIdsToAdd
            .map((userIdToAdd) => {
              const userExists = values.users.find((u) => u.userId == userIdToAdd);

              return (
                !userExists && {
                  userId: userIdToAdd,
                  username: userOptions.find((u) => u.value == userIdToAdd).label,
                  scoutingRegionSlugs: [],
                }
              );
            })
            .filter((userToAdd) => userToAdd);

          setFieldValue('users', [...values.users, ...usersToAdd]);
        };

        return (
          <Form>
            <Container padding={3}>
              <Column gap={2}>
                <Field
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder="Name"
                  component={BDDInputField}
                />
                <Field
                  autoComplete="off"
                  type="text"
                  name="description"
                  placeholder="Description"
                  component={BDDTextAreaField}
                />
                <Row columnGap={3}>
                  <Container width={200}>{userSelect}</Container>
                  <Button variant="outline-dark" onClick={() => handleAddUser(userToAdd)}>
                    <Typography variant="body1">Add</Typography>
                  </Button>
                </Row>
                <Row columnGap={3}>
                  <Container width={200}>{userGroupSelect}</Container>
                  <Button
                    variant="outline-dark"
                    onClick={() => handleAddUsers(usersToAdd)}
                  >
                    <Typography variant="body1">Add</Typography>
                  </Button>
                </Row>
                <Grid gridTemplateColumns="1fr 70px 70px" rowGap={2}>
                  <Typography variant="body1">Users</Typography>
                  <LinkButton
                    onClick={() =>
                      setFieldValue(
                        'users',
                        values.users.map((u) => ({ ...u, canEdit: true }))
                      )
                    }
                  >
                    Can Edit
                  </LinkButton>
                  <LinkButton>Remove</LinkButton>
                  {values.users.map((u) => (
                    <React.Fragment key={u.username}>
                      <Row columnGap={3}>
                        <PersonCircle />
                        <Typography variant="body2">{u.username}</Typography>
                      </Row>
                      <input
                        id={`${u.userId}-can-edit`}
                        type="checkbox"
                        checked={u.canEdit}
                        onChange={(event) => {
                          setFieldValue(
                            'users',
                            produce(values.users, (users) => {
                              users.forEach((vu) => {
                                if (vu.userId == u.userId) {
                                  vu.canEdit = event.currentTarget.checked;
                                }
                              });
                            })
                          );
                        }}
                      />
                      <IconButton
                        key={u.userId}
                        icon={<XLg />}
                        onClick={() =>
                          setFieldValue(
                            'users',
                            values.users.filter((vu) => vu.userId != u.userId)
                          )
                        }
                      />
                    </React.Fragment>
                  ))}
                </Grid>
              </Column>
            </Container>
            <Divider />
            <Container padding={3}>
              <Row justifyContent="space-between">
                <Container>{children}</Container>
                <Button type="submit" variant="outline-dark" disabled={disabled}>
                  <Typography variant="body1">
                    {submitting ? 'Submitting...' : 'Submit'}
                  </Typography>
                </Button>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
