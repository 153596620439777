// Dynamic query that is built off a list of options

import { theme } from 'constants/theme';
import { Border, Container, Row } from '../Layout';
import { StyledBDDSelect } from '../Select/StyledBDDSelect';
import { numberOperatorOptions, selectOperatorOptions } from './constants';
import { FilterOptionSelect } from './FilterOptionSelect';
import { NumberInput } from '../NumberInput/NumberInput';
import React, { useState } from 'react';
import produce from 'immer';
import { Typography } from '../Typography';
import { useQueryContext } from './context';
import { buildNewFilter, getOperatorOptions } from './helpers';
import { Button } from 'react-bootstrap';
import { IconButton, LinkButton } from '../Button';
import { ChevronDown, Trash, XLg } from 'react-bootstrap-icons';
import Icon from '../Icon';
import BDDDatePicker from '../bdddatepicker';
import { formatDate } from 'helpers/helpers';
import ReactDatePicker from 'react-datepicker';

export const FilterContainer = ({ label, width = 100, children }) => {
  return (
    <Container>
      <Typography variant="label">{label}</Typography>
      <Container width={width}>{children}</Container>
    </Container>
  );
};

export const Filter = ({ filter }) => {
  const options = useQueryContext((state) => state.options);
  const { updateFilter, removeFilter } = useQueryContext((state) => state.actions);

  const filterOptions = options.find((o) => o.col == filter.col);
  const extraFilters = filterOptions.extraFilters?.map((c) =>
    getFilter({
      config: c,
      parentFilter: filter,
      filter: filter.extraFilters.find((ef) => ef.col == c.col),
      onChange: (value) => {
        updateFilter(
          produce(filter, (draft) => {
            const updatedFilter = {
              col: c.col,
              cond: c.cond,
              val: value,
            };

            if (!draft.extraFilters) {
              filter.extraFilters = [updatedFilter];
            } else {
              const extraFilter = draft.extraFilters?.find((ef) => ef.col == c.col);

              if (!extraFilter) {
                draft.extraFilters.push(updatedFilter);
              } else {
                extraFilter.val = value;
              }
            }
          })
        );
      },
    })
  );

  return (
    <Border>
      <Container
        key={filter.key}
        padding={1}
        backgroundColor={theme.colors.light.secondary}
      >
        <Row justifyContent="space-between">
          <Row columnGap={2} rowGap={2} flexWrap alignItems="start">
            <FilterContainer label={'Filter'} width={125}>
              <FilterOptionSelect
                filter={filter}
                options={options}
                onChange={(col) =>
                  updateFilter(
                    produce(filter, () => buildNewFilter(col, filter.key, options))
                  )
                }
              />
            </FilterContainer>
            {getOperatorOptions(filterOptions).length > 0 && (
              <FilterContainer label="Condition" width={125}>
                <StyledBDDSelect
                  size="sm"
                  options={getOperatorOptions(filterOptions)}
                  selectedValue={filter.cond}
                  onChange={(n, v) => updateFilter({ ...filter, cond: v })}
                />
              </FilterContainer>
            )}
            {getFilter({
              config: filterOptions,
              filter,
              onChange: (v) => updateFilter({ ...filter, val: v }),
            })}
            {extraFilters}
          </Row>
          <Container paddingLeft={2}>
            <IconButton
              icon={<XLg />}
              hoverColor={theme.colors.states.danger}
              tooltip={'Remove filter'}
              onClick={() => removeFilter(filter.key)}
            />
          </Container>
        </Row>
      </Container>
    </Border>
  );
};

export const getFilter = ({ config, filter, parentFilter, onChange }) => {
  if (config.hide && config.hide(parentFilter || filter)) {
    return;
  }

  const key = filter?.key || filter?.col || config?.col;

  let component;
  switch (config.type) {
    case 'custom':
      component = config.renderFilter(filter, onChange);
      break;
    case 'select':
      component = (
        <FilterContainer
          label={config.valueLabel || config.label}
          width={config.width || 100}
        >
          <StyledBDDSelect
            size="sm"
            options={config.options}
            selectedValue={filter?.val || config?.defaultValue}
            onChange={(n, v) => {
              onChange(v);
            }}
            selectProps={{
              isSearchable: true,
            }}
          />
        </FilterContainer>
      );
      break;
    case 'inputRange':
      component = (
        <FilterContainer label={config.valueLabel || config.label} width={null}>
          <NumberInput
            min={config.scale?.min || 0}
            max={config.scale?.max || 1000}
            value={filter?.val}
            step={config.step || 1}
            onBlur={(e) => {
              if (!e.target.value) {
                onChange(0);
              }
            }}
            onChange={(e) => {
              onChange(parseFloat(e.target.value));
            }}
            variant="label"
          />
        </FilterContainer>
      );
      break;
    case 'date':
      component = (
        <FilterContainer label={config.valueLabel || config.label} width={null}>
          <Container style={{ zIndex: 10 }}>
            <ReactDatePicker
              placeholderText="Select Date"
              value={filter?.val}
              onChange={onChange}
              startDate={config.defaultValue}
              shouldCloseOnSelect
              showMonthDropdown
              showYearDropdown
              customInput={
                <Border>
                  <Container
                    variant="button"
                    padding={1}
                    paddingLeft={2}
                    paddingRight={2}
                    height={28}
                    backgroundColor={theme.colors.light.background}
                  >
                    <Row columnGap={1}>
                      <Typography variant="stat">
                        <b>{formatDate(filter?.val, { format: '%B %d %Y' })}</b>
                      </Typography>
                      <Icon icon={<ChevronDown />} />
                    </Row>
                  </Container>
                </Border>
              }
            />
          </Container>
        </FilterContainer>
      );
      break;
    case 'boolean':
      return null;
    case 'number':
    default:
      component = (
        <FilterContainer label={config.valueLabel || config.label} width={null}>
          <NumberInput
            min={0}
            max={config.max || 1000}
            value={filter?.val || config.defaultValue || 0}
            step={config.step || 1}
            onBlur={(e) => {
              if (!e.target.value) {
                onChange(0);
              }
            }}
            onChange={(e) => {
              onChange(parseFloat(e.target.value));
            }}
            variant="label"
          />
        </FilterContainer>
      );
      break;
  }

  if (!filter) {
    onChange(config.defaultValue);
  }

  return <React.Fragment key={key}>{component}</React.Fragment>;
};
