import { theme } from "constants"
import styled from "styled-components"

const FixedStyles = styled.div(({ background='#555', color='#DDD' }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  background,
  color,
  padding: '5px 10px',
  borderRadius: '2px'
}));

export const FixedIndicator = ({ children, background, color }) => {
  return <FixedStyles background={background} color={color}>
    {children}
  </FixedStyles>
}