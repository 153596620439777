import { roundToX } from './data';

const defaultOpts = { decimalPlaces: 2, truncate: true, trailingZeros: 0 };
export function formatDollars(dollars, opts) {
  const { decimalPlaces, truncate, trailingZeros } = { ...defaultOpts, ...opts };
  if (dollars == null) return null;
  dollars = parseInt(dollars);
  const isNegative = dollars < 0;
  dollars = Math.abs(dollars);

  var val;
  if (truncate) {
    if (dollars >= 1e6) {
      val = `${roundToX(dollars / 1e6, decimalPlaces, trailingZeros)}M`;
    } else if (dollars > 1e3) {
      val = `${roundToX(dollars / 1e3, 0)}K`;
    } else {
      val = `${dollars}`;
    }
  } else {
    val = `${dollars.toLocaleString()}`;
  }
  return `${isNegative ? '-' : ''}$${val}`;
}
