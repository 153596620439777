import { useState } from "react"
import { BDDDateRangePicker } from "../bdddatepicker"

export const useBddDateRangePicker = ({
  initialStartDate,
  initialEndDate,
  pickerProps={},
  onChange
}) => {
  const [dates, setDates] = useState([initialStartDate, initialEndDate]);

  const datePicker = (
    <BDDDateRangePicker
      zIndex={1}
      selected={dates[0]}
      startDate={dates[0]}
      endDate={dates[1]}
      onChange={(dates) => {
        if (!dates[0] && !dates[1]) {
          setDates([null, null]);
          if (!!onChange) onChange([null, null]);
        }
        if (!!dates[0] && !!dates[1]) {
          setDates(dates);
          if (!!onChange) onChange(dates);
        }
        else if (!!dates[0]) {
          setDates([dates[0], null]);
        }
      }}
      {...pickerProps}
    />
  )

  return {
    dates,
    startDate: dates[0],
    endDate: dates[1],
    datePicker
  }
}