import React from 'react';
import styled from 'styled-components';
import { Star, StarFill } from 'react-bootstrap-icons';
import { theme } from 'constants';

// This icon class is only really necessary
// due to the alignment issues of the svg icons
const StarStyles = {
  marginTop: -theme.spacing[0.5],
};

const StyledStar = styled(Star)(StarStyles);
const StyledStarFill = styled(StarFill)(StarStyles);

export const StarIcon = ({ enabled }) =>
  enabled ? <StyledStarFill /> : <StyledStar />;
