import React, { Component } from 'react'
import update from 'immutability-helper'
import ListTier from './listtier';
import { PlayerListPlayer } from './playerlistplayer';
import { ListDragLayer } from './listdraglayer';
import { TooltipSpan } from '../reports';
import { Col, Row } from 'react-bootstrap';

// Helper function compares tiers from newList, oldList creates update objects
function findTiersToUpdate(newTierDefs, oldTierDefs) {
    var tiersToUpdate = []
    if (newTierDefs.length != oldTierDefs.length) throw Error('newTiers and oldTiers must have same number of tiers')
    for (let i=0; i<newTierDefs.length; i++) {
        const [td1, td2] = [newTierDefs[i], oldTierDefs[i]]
        if (td1.id != td2.id) throw Error(`Tier order cannot change in player-move-tier-update`)
        if (td1.startsWithListNumber != td2.startsWithListNumber ||
        td1.endsWithListNumber != td2.endsWithListNumber) {
            tiersToUpdate.push({
                id: td1.id,
                startsWithListNumber: td1.startsWithListNumber,
                endsWithListNumber: td1.endsWithListNumber
            })    
        }
    }
    return tiersToUpdate
}


// Helper function transforms players, tiers into tierOrderedPlayers
function buildTierOrderedPlayers(players, tiers) {
    if (players.length != tiers.length) throw Error(`"players" (len=${players.length}) and "tiers" (len=${tiers.length}) must have same length`)

    var tierOrderedPlayers = []
    var prevTierId = null
    var currTierPlayers = { tierId: null, players: [] }
    for (let i=0; i<players.length; i++) {
        const pid = players[i]
        const tierId = tiers[i]
        if (tierId != prevTierId) { // Entering a tier
            if (!!currTierPlayers && currTierPlayers.players.length > 0) {
                tierOrderedPlayers.push(currTierPlayers)
            }
            currTierPlayers = { tierId: tierId, players: [] }
        }
        currTierPlayers.players.push(pid)
        prevTierId = tierId
    }
    if (!!currTierPlayers && currTierPlayers.players.length > 0) {
        tierOrderedPlayers.push(currTierPlayers)
    }
    return tierOrderedPlayers
}

function buildTierIdList(tiers, numPlayers) {
    var ret = []
    const addRows = (list, tierId, numRows) => {
        for (let i=0; i<numRows; i++) {
            list.push(tierId)
        } 
    }
    var curr = 1
    tiers.forEach(t => {
        if (t.startsWithListNumber > curr) {
            addRows(ret, null, t.startsWithListNumber-curr)
        }
        addRows(ret, t.id, t.endsWithListNumber - t.startsWithListNumber + 1)
        curr = t.endsWithListNumber + 1
    })
    if (curr < numPlayers+1) {
        addRows(ret, null, numPlayers+1-curr)
    }
    return ret
}

function tierIdListToTiers(tierIdList) {
    var ret = []
    var currTier = null
    var i = 0;
    for (i=0; i<tierIdList.length; i++) {
        const currTierId = tierIdList[i]
        if (!!currTierId) { // we're in a tier (non null tierId)
            if (!currTier) { // start of new tier
                currTier = { id: currTierId, startsWithListNumber: i+1 }
            } else if (currTierId != currTier.id) { // one tier ending, another beginning
                currTier.endsWithListNumber = i
                ret.push(currTier)
                currTier = { id: currTierId, startsWithListNumber: i+1 }
            }
        } else { // we're not in a tier
            if (!!currTier) { // we have a tier to commit
                currTier.endsWithListNumber = i
                ret.push(currTier)
                currTier = null
            }
        }
    }
    if (!!currTier) {
        currTier.endsWithListNumber = i
        ret.push(currTier)
    }
    return ret
}

export default class PlayerListRenderer extends Component {
    constructor(props) {
        super(props)

        this.drawFrame = () => {
          const nextState = update(this.state, this.pendingUpdateFn);
          this.pendingUpdateFn = undefined;
          this.requestedFrame = undefined;
          // check for duplicate player Ids before we set state
          if (new Set(nextState.players).size !== nextState.players.length) {
            console.warn('While in "drawFrame" duplicate playerIds detected.  Are updateFns being called in the wrong order?');
            return;
          }
          this.setState(nextState);
        }

        this.movePlayerByIdx = (movingPlayerId, destIdx, destTierId=null) => {
            const currIdx = this.state.players.indexOf(movingPlayerId)
            const tierIdList = this.state.tiers
            if (!destTierId) {
                // if destTierId not supplied, copy tier id at the destination spot
                destTierId = tierIdList[destIdx]
            }

            const updateFn = {
              players: {
                $splice: [
                  [currIdx, 1],
                  [destIdx, 0, movingPlayerId],
                ],
              },
              tiers: {
                $splice: [
                  [currIdx, 1],
                  [destIdx, 0, destTierId],
                ],
              },
              listNumberInMotion: { $set: destIdx + 1 },
            };
            this.scheduleUpdate(updateFn)
        }
        this.movePlayer = (listId, movingPlayerId, destPlayerId) => {
            if (listId != this.props.listId) {
                console.log('moving player between lists')
                return
            }
            const destIdx = this.state.players.indexOf(destPlayerId)
            this.movePlayerByIdx(movingPlayerId, destIdx)
        }
            
        // keep track of most recent func call, don't need to make the same call 2x in a row
        this.lastTierMoveArgs = null
        this.moveTierByIdx = (tierId, destIdx, startEnd) => {
            // check if we just saw these args, in which case can skip
            const incomingTierMoveArgs = [tierId, destIdx, startEnd]
            // compare these args to last args
            if (!!this.lastTierMoveArgs && incomingTierMoveArgs.every((val, i) => val === this.lastTierMoveArgs[i])) {
                return
            }
            this.lastTierMoveArgs = incomingTierMoveArgs

            
            const isStart = startEnd === 'start'
            const tierDefs = tierIdListToTiers(this.state.tiers)
            const tierDef = tierDefs.filter(t => t.id == tierId)[0]
            const oldStart = tierDef.startsWithListNumber - 1
            const oldEnd = tierDef.endsWithListNumber - 1
            // We shift our edges by one for smoother performance
            var newStart = isStart ? destIdx : tierDef.startsWithListNumber - 1
            var newEnd = isStart ? tierDef.endsWithListNumber - 1 : destIdx
            const splices = []
            
            // If no change, exit early
            if (newStart == oldStart && newEnd == oldEnd) return
            // No 0-length tiers allowed (need to delete tier)
            if (newStart > newEnd) return
            // We need to make sure you're not bumping into any other tier
            for (let i=newStart; i<=newEnd; i++) {
                if (this.state.tiers[i] != tierId && this.state.tiers[i] != null) {
                    return
                }
            }

            if (newStart > oldStart) { // shrinking tier, replace id with null
                // console.log('shrinking top')
                const tierIds = new Array(newStart-oldStart).fill(null)
                splices.push([oldStart, newStart-oldStart, ...tierIds])
            } else if (newStart < oldStart) { // growing tier
                // console.log('growing top')
                const tierIds = new Array(oldStart-newStart).fill(tierId)
                splices.push([newStart, oldStart-newStart, ...tierIds])
            }
            if (newEnd > oldEnd) { //growing tier
                // console.log('growing end')
                const tierIds = new Array(newEnd-oldEnd).fill(tierId)
                splices.push([oldEnd+1, newEnd-oldEnd, ...tierIds])
            } else if (newEnd < oldEnd) { // shrinking tier
                // console.log('shrinking end')
                const tierIds = new Array(oldEnd-newEnd).fill(null)
                splices.push([oldEnd, oldEnd-newEnd, ...tierIds])
            }

            if (splices.length) {
                var updateFn = { tiers: {
                    $splice: splices
                }}
                this.scheduleUpdate(updateFn)            
            }
        }

        this.moveTier = (listId, tierId, destPlayerId, startEnd) => {
            // This function is triggered when a tier edge hovers over a player in the list
            // If the edge has expanded out, we grow, if it's come towards it's opp edge, we shrink
            if (listId != this.props.listId) {
                console.warn('Attempting to move a tier between lists.')
                return
            }
            if (!!this.pendingUpdateFn) return // don't run if we're pending an update
            const destIdx = this.state.players.indexOf(destPlayerId)
            this.moveTierByIdx(tierId, destIdx, startEnd)
        }

        // this.moveTierEdges = (tierId, startsWithListNumber, endsWithListNumber) => {
        //     // unlike the other move tier functions, this doesnt schedule an update
        //     // straight to API
        //     this.props.submitTierMove([{
        //         id: tierId,
        //         startsWithListNumber,
        //         endsWithListNumber
        //     }])
        // }

        this.removePlayer = (playerId) => {
            const idx = this.state.players.indexOf(playerId)
            
            // Need to figure out how this effects tiers to post to API
            const tiersCopy = this.state.tiers.map(t => t)
            tiersCopy.splice(idx, 1)
            const tiersToUpdate = findTiersToUpdate(tierIdListToTiers(tiersCopy), this.props.tiers)
            
            // As well as updating in state
            this.scheduleUpdate({
                players: { $splice: [[idx, 1]] },
                tiers: { $splice: [[idx, 1]] }
            })
            
            this.props.handleRemovePlayer(playerId, tiersToUpdate)
        }

        this.resetList = () => {
            this.setState({
                ...this.state,
                players: this.props.players.map(p => p.playerId),
                tiers: buildTierIdList(this.props.tiers, this.props.players.length),
                listNumberInMotion: null 
            })
        }

        this.submitPlayerMove = (listId, playerId, toListNumber) => {
            if (listId != this.props.listId) {
                console.warn('submitting player move from different list')
                this.props.movePlayerBetweenLists([{
                    fromListId: listId, id: playerId, toListNumber
                }])
                return
            }
            const fromListNumber = this.props.players.findIndex(p => p.playerId === playerId) + 1
            const playersToReorder = [{ id: playerId, fromListNumber, toListNumber }]
            const tiersToUpdate = findTiersToUpdate(tierIdListToTiers(this.state.tiers), this.props.tiers)
            this.props.submitPlayerMove(playersToReorder, tiersToUpdate, this.state.players)
        }

        this.submitTierMove = () => {
            const tiersToUpdate = findTiersToUpdate(tierIdListToTiers(this.state.tiers), this.props.tiers)
            this.props.submitTierMove(tiersToUpdate)
        }

        this.state = { 
            players: props.players.map(p => p.playerId), // Ordered list of player ids
            tiers: buildTierIdList(props.tiers, props.players.length), // Ordered list of tier ids (e.g. [null, null, 14, 14, 14 ...])
            listNumberInMotion: null 
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.updateLoading) {
          return;
        }

        if (prevProps.lastModified != this.props.lastModified) {
          this.resetList()
          return;
        }

        if (prevProps.players != this.props.players) {
          this.resetList()
          return;
        }
    }

    componentWillUnmount() {
        if (this.requestedFrame !== undefined) {
            cancelAnimationFrame(this.requestedFrame);
        }
    }

    render() {
        const { 
            listId, 
            editMode, 
            idToPlayer,
            idToTier,
            tieredColumns
        } = this.props

        // Reconstruct a tierOrderedPlayers 
        // (easier to reconstruct this at render than to splice the heirerarchical)
        const tierOrderedPlayers = buildTierOrderedPlayers(this.state.players, this.state.tiers)
        var listNumber = 1
        return <>
            {!this.props.disableDragLayer && <ListDragLayer listNumberInMotion={this.state.listNumberInMotion} idToPlayer={idToPlayer} />}
            <Row className={!!tieredColumns ? 'flex-nowrap' : ''} style={{overflow: !!tieredColumns ? 'auto' : 'none'}}>
            {tierOrderedPlayers.map((tp, tierIdx) => {
                const tierLen = tp.players.length
                return <Col key={tp.tierId ? tp.tierId : `idx=${tierIdx}`} 
                    xs={tieredColumns ? 'auto' : 12}
                >
                <ListTier 
                    tier={idToTier[tp.tierId]} 
                    listId={listId} 
                    editMode={editMode}
                    finishedMovingTier={this.submitTierMove}
                    hideNotes={tieredColumns}
                > 
                    {tp.players.map(pid => {
                        const player = idToPlayer[pid]
                        if (!player) {
                            console.warn(`Player ${pid} not found in list`)
                            return null
                        }
                        var ln = listNumber++
                        const isLastPlayerInTier = !(!tp.tierId || tierLen > 1)
                        const isSelected = this.props.selectedPlayers.indexOf(pid) >= 0
                        return <div 
                            key={pid} 
                            onClick={ev => {
                              this.props.handlePlayerClick(ev, ln, pid, player)
                            }}
                        >
                        <TooltipSpan content={editMode && isLastPlayerInTier ? 'Can\'t remove last player from tier (delete tier first)' : null}>
                        <PlayerListPlayer 
                            id={pid}
                            player={player} 
                            renderPlayer={this.props.renderPlayer}
                            playerSize={this.props.playerSize}
                            hideRightSide={this.props.hideRightSide}
                            listNumber={editMode ? ln : player.listNumber} 
                            editMode={editMode}
                            movePlayer={this.movePlayer}
                            movePlayerByIdx={this.movePlayerByIdx} // need this for moving player without drag
                            finishedMovingPlayer={this.submitPlayerMove}
                            moveTier={this.moveTier}
                            moveTierByListNumber={this.moveTierByListNumber}
                            resetList={this.resetList}
                            removePlayer={this.removePlayer}
                            isLastPlayerInTier={isLastPlayerInTier} // can't remove the last player in a tier 
                            isSelected={isSelected}
                            selectedClassName={this.props.selectedClassName ? this.props.selectedClassName : 'selected-player'}
                            addPlayerNote={this.props.handleAddPlayerNote}
                            deletePlayerNote={this.props.handleRemovePlayerNote}
                            allowAddPlayerNote={editMode}
                            showPlayerNotes={this.props.showPlayerNotes}
                            listLength={this.state.players.length}
                            tiers={Object.values(idToTier)}
                            noDragPreview={!this.props.disableDragLayer}
                            disableRemovePlayer={this.props.disableRemovePlayer}
                            disableContextMenu={this.props.disablePlayerContextMenu}
                            extraContextMenuOptions={this.props.extraPlayerContextMenuOptions}
                        />
                        </TooltipSpan>
                        </div>})}
                </ListTier>
                </Col>
            })}         
            </Row>
        </>
    }
    scheduleUpdate(updateFn) {
        this.pendingUpdateFn = updateFn;
        if (!this.requestedFrame) {
            this.requestedFrame = requestAnimationFrame(this.drawFrame);
        }
    }
}

