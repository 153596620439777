import { LinkButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import useModal from 'components/bdd/Modal/useModal';
import { Typography } from 'components/bdd/Typography';
import {
  currentRoleToText,
  proProjectionToText,
} from 'components/Scouting/playerformhelpers';
import { ReportModal } from 'components/ScoutingV2';
import { formatDate } from 'helpers/helpers';
import { positionToFDG } from 'helpers/hockeyutils';

export const GameReport = ({ report, showGameInfo }) => {
  const { modal, setShowModal } = useModal({
    title: 'Report',
    body: (
      <ReportModal
        playerRinknetId={report.playerrinknetid}
        scoutId={report.scoutid}
        gameId={report.gameid || report.rinknetGame?.gameId}
      />
    ),
  });

  const ratings =
    typeof report.ratings === 'string' ? JSON.parse(report.ratings) : report.ratings;

  const posFDG = positionToFDG(report.position);
  const currentRoleRating = ratings?.find(
    (report) => report.Type == 'Current Role' || report.Type == 'NHL Current'
  );

  const proProjectionRating = ratings?.find(
    (report) => report.Type === 'Pro Projection' || report.Type === 'NHL Projection'
  );

  const draftRoundRating = ratings?.find((report) => report.Type === 'Draft Round');

  const currentRole = currentRoleRating && (
    <Row columnGap={1}>
      <Typography variant="stat">Current Role:</Typography>
      <Typography variant="stat">
        <b>{currentRoleToText(currentRoleRating.Rating.Name, posFDG)}</b>
      </Typography>
    </Row>
  );

  const proProjection = proProjectionRating && (
    <Row columnGap={1}>
      <Typography variant="stat">Pro Projection:</Typography>
      <Typography variant="stat">
        <b>{proProjectionToText(proProjectionRating.Rating.Name, posFDG)}</b>
      </Typography>
    </Row>
  );

  const draftRound = draftRoundRating && (
    <Row columnGap={1}>
      <Typography variant="stat">Draft Round:</Typography>
      <Typography variant="stat">
        <b>{draftRoundRating.Rating.Name}</b>
      </Typography>
    </Row>
  );

  return (
    <Column>
      {modal}
      <Row columnGap={1}>
        <LinkButton onClick={() => setShowModal(true)} style={{ width: 'fit-content' }}>
          <Typography variant="body1">{report.author}:</Typography>
        </LinkButton>
        {showGameInfo && (
          <Row columnGap={1}>
            <Typography variant="body1">{formatDate(report.rinknetGame.date)}</Typography>
            <Typography variant="body1">{report.rinknetGame.leagueshorthand}</Typography>
            <Typography variant="body2">
              {report.rinknetGame.awayteam.longname} @{' '}
              {report.rinknetGame.hometeam.longname}
            </Typography>
          </Row>
        )}
      </Row>
      <Row columnGap={2}>
        {currentRole}
        {proProjection}
        {draftRound}
      </Row>
      <Typography variant="body2">{report.notes || report.reportNotes}</Typography>
    </Column>
  );
};

export const GameReports = ({ gameInfo }) => {
  return (
    <Row columnGap={1}>
      {gameInfo.reports.map((report) => (
        <GameReport key={`report-${report.author}-${gameInfo.game.id}`} report={report} />
      ))}
    </Row>
  );
};
