import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Typography } from 'components/bdd/Typography';
import { Modal } from 'react-bootstrap';
import ByScoutViz from './ByScoutViz';
import ReportsByScout from './ReportsByScout';
import useReportOptions from './useReportOptions';

export default function ReportsByScoutModal({
  show,
  handleClose,
  rinknetId,
  bddPlayer,
  playerName,
}) {
  const { buttonGroup, component } = useReportOptions({
    rinknetId,
    rinknetPlayer: bddPlayer?.rinknetPlayer,
  });

  return (
    <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Typography variant="h4">{playerName} Reports by Scout</Typography>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: '10px' }}>{buttonGroup}</div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {component}
        </div>
      </Modal.Body>
    </Modal>
  );
}
