import { Column, Container, Row } from 'components/bdd/Layout';
import useStatReports from 'components/StatReports/hooks/useStatReports';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const StatReportFilter = ({ storageKeyPrefix, hideEditStatsButton }) => {
  const { target, setFilters, filters, refetch } = useContext(SLStatContext);

  const { statReportsButtons, statObj, editStatsButton } = useStatReports({
    target,
    showMoreButton: true,
    storageKeyPrefix: storageKeyPrefix,
    refetchStats: refetch, // useful when a stat report changes and needs to be refetched
  });

  useEffect(() => {
    if (!statObj) return;

    setFilters({ statDefs: null, ...statObj });
  }, [statObj]);

  return (
    <Container>
      <Column>
        {statReportsButtons}
        <Row justifyContent="end">
          {!hideEditStatsButton && editStatsButton}
        </Row>
      </Column>
    </Container>
  );
};
