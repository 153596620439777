import { Container } from "components/bdd/Layout"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import { useContext } from "react";
import { ProObjectiveContext } from "../ProObjectiveContextProvider";
import { tradeAssetsToPicks, tradeAssetsToPlayers } from "components/ProScouting/BDDTrades/helpers";
import { useBDDTradeForm } from "components/ProScouting/BDDTrades/useBDDTradeForm";
import { ScenarioContext } from "../Scenarios/ScenarioContextProvider";


export const AddObjectiveTradeVariant = ({ transaction, onCompleted, defaultTxIdx=0 }) => {

  const objectiveCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { proObjectiveSlug, addPOTransactionVariant, addPOTransactionVariantLoading } = !!objectiveCtx ? objectiveCtx : scenarioCtx;

  const handleSubmit = (teams, assets) => {
    addPOTransactionVariant({
      onCompleted: () => {
        toastInfo('Trade variant added');
        if (!!onCompleted) onCompleted();
      },
      variables: {
        proObjectiveSlug,
        transactionId: transaction.id,
        trade: {
          tradedPlayers: tradeAssetsToPlayers(assets),
          tradedPicks: tradeAssetsToPicks(assets),
        }
      }
    })
  }

  const bddTrade = transaction.bddTrades[defaultTxIdx];
  const tradeFormProps = {
    defaultTeams: bddTrade.teamSummaries.map(t => t.teamSlug),
    defaultAssets: bddTrade.tradedPlayers.map(p => ({
      assetType: "PLAYER",
      assetId: p.playerSlug,
      fromTeamSlug: p.fromTeamSlug,
      toTeamSlug: p.toTeamSlug,
      bddPlayer: p.bddPlayer,
      retainedSalary: p.retainedSalary,
      retainedSalaryPct: p.retainedSalaryPct
    })).concat(bddTrade.tradedDraftPicks.map(p => ({
      assetType: "DRAFT_PICK",
      assetId: p.pickId,
      fromTeamSlug: p.fromTeamSlug,
      toTeamSlug: p.toTeamSlug,
      bfDraftPick: p.bfDraftPick,
    })))
  }

  const { form } = useBDDTradeForm({
    showSubmit: true,
    handleSubmit,
    submitting: addPOTransactionVariantLoading,
    ...tradeFormProps
  });
  
  return <Container>
    {form}
  </Container>
}