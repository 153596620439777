import { CheckboxGroupFilter, EventFilterContext } from '.';
import { useContext } from 'react';
import { eventNameToMapping } from '../helpers';

export const LocationFilter = () => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name']?.val;
  const options = eventNames?.flatMap((e) => eventNameToMapping[e]?.location || null);
  if (!options || options.length <= 1) return null;

  return (
    <CheckboxGroupFilter
      col="location"
      id="location"
      label={'Event Location'}
      condAny="includes_substr"
      options={options}
    />
  );
};
