import { useQuery } from '@apollo/client';
import { GET_SL_EVENT_SHIFTS } from 'apollo/queries/events.queries';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { toiSecToString } from 'helpers/data';

const positionSortOrder = {
  F: 2,
  D: 1,
  G: 0,
};

export const EventShiftContext = ({ event }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_SL_EVENT_SHIFTS, {
      skip: !event,
      variables: {
        eventId: event?.id,
      },
    })
  );

  if (!data) return placeholder;

  const shiftsByTeam = data.slEventShifts.reduce((shiftsByTeam, shift) => {
    if (shift.teamSlug in shiftsByTeam) {
      shiftsByTeam[shift.teamSlug].push(shift);
    } else {
      shiftsByTeam[shift.teamSlug] = [shift];
    }

    return shiftsByTeam;
  }, {});

  return (
    <BDDErrorBoundary>
      <Container padding={2}>
        <Row justifyContent="space-between">
          {Object.keys(shiftsByTeam)
            .sort((a, b) => (b == event.teamSlug) - (a == event.teamSlug))
            .map((key) => {
              return (
                <Column key={key}>
                  {shiftsByTeam[key]
                    .filter(shift => !!shift.player)
                    .sort(
                      (a, b) =>
                        positionSortOrder[b.player.primaryposition] -
                        positionSortOrder[a.player.primaryposition]
                    )
                    .map((shift) => {
                      const player = shift.player.slPlayerMaster.slPlayers.find(
                        (sp) => sp.leagueSlug == shift.leagueSlug
                      );

                      const timeInShift = event.gameTime - shift.gameTimeIn;

                      return (
                        <Container key={`${shift.gameTimeIn}-${player.slUid}`}>
                          <Row columnGap={1}>
                            <Container minWidth={20}>
                              <Typography variant="stat">
                                <b>#{player.jerseynumber}</b>
                              </Typography>
                            </Container>

                            <Typography variant="stat">
                              {player.firstname} {player.lastname}
                            </Typography>

                            <Typography variant="stat">
                              TOI: {toiSecToString(timeInShift)}
                            </Typography>
                          </Row>
                        </Container>
                      );
                    })}
                </Column>
              );
            })}
        </Row>
      </Container>
    </BDDErrorBoundary>
  );
};
