import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';

import { theme } from 'constants';
import { GET_EP_STAT_FILTERS } from 'apollo/queries/stat.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import BDDSelectField from 'components/form/bddselectfield';
import { InputRange } from 'components/bdd/Range/InputRange';
import { useStatSearchParams } from './hooks/useStatSearchParams';
import RangeInputBoxes from 'components/bdd/Range/RangeInputBoxes';

const StyledForm = styled(Form)({
  marginTop: theme.spacing[3],
  marginBottom: theme.spacing[3],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.spacing[3],
});

const FieldLabel = styled.div({
  ...theme.typography.body1,
});

const FieldContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing[3],
  width: '100%',
});

const MultiSelectField = styled.div({
  minWidth: '30%',
  flexGrow: 1,
});

const SingleSelectField = styled.div({
  minWidth: '10%',
  flexGrow: 1,
});

const schema = Yup.object({
  leagues: Yup.array().min(1, 'At least one league must be selected').required(),
  seasons: Yup.array().min(1, 'At least one season must be selected').required(),
  gameType: Yup.string().nullable(),
  age: Yup.object().nullable(),
  draftEligibleYears: Yup.object().nullable(),
  teams: Yup.array(),
  positions: Yup.string().nullable(),
  groupBy: Yup.string().required(),
});

export const StatFilters = () => {
  const { store, serverFilterValues } = useStatSearchParams();

  const handleSubmit = (values, actions) => {
    const newValues = {
      ...values,
      ...{ agemin: values.age.min, agemax: values.age.max },
      ...{
        drafteligibleyearsmin: values.draftEligibleYears.min,
        drafteligibleyearsmax: values.draftEligibleYears.max,
      },
    };

    delete newValues.draftEligibleYears;
    delete newValues.age;
    store.setSearchParams(newValues);
    actions.setSubmitting(false);
  };

  const { data: filterOptionsData, placeholder: filterOptionsPlaceholder } =
    usePlaceholder(
      useQuery(GET_EP_STAT_FILTERS, {
        variables: { target: 'ep_player_season_stats' },
      })
    );

  return filterOptionsData ? (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={serverFilterValues}
        validationSchema={schema}
      >
        {({ isSubmitting, values, errors }) => {
          let options = {};
          const filters = filterOptionsData.epStatsFilters;
          const leagueSeasonTeamFilters = filters.dynamic_filters.league_season_team;
          const positionGroupByFilters = filters.dynamic_filters.position_group_by;

          options.leagues = [
            ...new Set(leagueSeasonTeamFilters.map((df) => df.league_slug)),
          ].map((l) => ({
            value: l,
            label: l.toUpperCase(),
          }));

          options.seasons = [
            ...new Set(
              leagueSeasonTeamFilters
                .filter((df) => values.leagues.includes(df.league_slug))
                .map((df) => df.season)
            ),
          ]
            .map((s) => ({
              value: s,
              label: s,
            }))
            .reverse();

          options.teams = [
            ...new Set(
              leagueSeasonTeamFilters
                .filter(
                  (df) =>
                    values.leagues.includes(df.league_slug) &&
                    values.seasons.includes(df.season)
                )
                .map((df) => df.team_id)
                .flat()
            ),
          ].map((t) => ({
            value: t,
            label: filters.mapping.team_id[t],
          }));

          options.ageRange = filters.static_filters.age;
          options.draftEligibleYearsRange = filters.static_filters.draft_eligible_years;

          options.gameType = filters.static_filters.game_type.map((gt) => ({
            value: gt,
            label: filters.mapping.game_type[gt],
          }));

          options.groupBy = [
            ...new Set(
              positionGroupByFilters
                .filter((df) => values.positions == df.position)
                .map((df) => df.group_by)
            ),
          ].map((gb) => ({
            value: gb,
            label: filters.mapping.group_by[gb],
          }));

          options.positions = [
            ...new Set(
              positionGroupByFilters
                .filter((df) => values.groupBy === df.group_by)
                .map((df) => df.position)
            ),
          ].map((p) => ({
            value: p,
            label: filters.mapping.position[p],
          }));

          return (
            <StyledForm>
              <FieldContainer>
                <MultiSelectField>
                  <Field
                    name="leagues"
                    titleComponent={<FieldLabel>Leagues</FieldLabel>}
                    placeholder="Filter leagues"
                    options={options.leagues}
                    isMulti={true}
                    isSearchable={true}
                    component={BDDSelectField}
                  />
                </MultiSelectField>
                <MultiSelectField>
                  <Field
                    name="seasons"
                    titleComponent={<FieldLabel>Seasons</FieldLabel>}
                    placeholder="Filter seasons"
                    options={options.seasons}
                    isMulti={true}
                    isSearchable={true}
                    component={BDDSelectField}
                  />
                </MultiSelectField>
                <MultiSelectField>
                  <Field
                    name="teams"
                    titleComponent={<FieldLabel>Teams</FieldLabel>}
                    placeholder="Filter teams"
                    options={options.teams}
                    isMulti={true}
                    isSearchable={true}
                    component={BDDSelectField}
                  />
                </MultiSelectField>
                <SingleSelectField>
                  <Field
                    titleComponent={<FieldLabel>Game Type</FieldLabel>}
                    name="gameType"
                    options={options.gameType}
                    component={BDDSelectField}
                    isClearable={false}
                  />
                </SingleSelectField>
                <SingleSelectField>
                  <Field
                    name="groupBy"
                    titleComponent={<FieldLabel>Group by</FieldLabel>}
                    placeholder="Group by"
                    options={options.groupBy}
                    isClearable={false}
                    component={BDDSelectField}
                  />
                </SingleSelectField>
                <SingleSelectField>
                  <Field
                    name="positions"
                    titleComponent={<FieldLabel>Position</FieldLabel>}
                    placeholder="Position filters"
                    options={options.positions}
                    isClearable={false}
                    component={BDDSelectField}
                  />
                </SingleSelectField>
                <SingleSelectField>
                  <Field
                    name="age"
                    label={<FieldLabel>Age</FieldLabel>}
                    type="input"
                    min={options.ageRange.min}
                    max={options.ageRange.max}
                    formatLabel={(value, type) => (type == 'value' ? value : null)}
                    component={RangeInputBoxes}
                    inputStyles={{
                      maxWidth: '100px',
                    }}
                  />
                </SingleSelectField>
                <SingleSelectField>
                  <Field
                    name="draftEligibleYears"
                    label={<FieldLabel>Draft Eligible Years</FieldLabel>}
                    type="input"
                    min={options.draftEligibleYearsRange.min}
                    max={options.draftEligibleYearsRange.max}
                    formatLabel={(value, type) => (type == 'value' ? value : null)}
                    component={RangeInputBoxes}
                    inputStyles={{
                      maxWidth: '100px',
                    }}
                  />
                </SingleSelectField>
              </FieldContainer>
              <Button type="submit" variant="warning" disabled={isSubmitting}>
                {isSubmitting ? 'Fetching...' : 'Fetch Data'}
              </Button>
            </StyledForm>
          );
        }}
      </Formik>
    </div>
  ) : (
    filterOptionsPlaceholder
  );
};
