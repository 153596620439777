import React, { useState } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { VerticalDivider } from 'components/bdd/Divider';
import { Journal } from 'react-bootstrap-icons';
import { Column, Container, Row } from 'components/bdd/Layout';
import Icon from '../Icon';

const Box = styled(Container)({
  ...theme.borders.light,
  ...theme.borders.thin,
  position: 'relative',
  marginLeft: theme.spacing[12],
});

const Indicator = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  borderRadius: '50%',
  overflow: 'hidden',
  minWidth: theme.spacing[7],
  minHeight: theme.spacing[7],
  backgroundColor: theme.colors.light.background,
  marginTop: theme.spacing[2],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TimelineIndicator = ({ lastItem, icon }) => {
  return (
    <Container
      position="absolute"
      width={theme.spacing[10]}
      height={lastItem ? '100%' : '120%'}
    >
      <Column gap={1} alignItems="center">
        <Indicator>
          <Icon
            color={theme.colors.light.text.secondary}
            icon={icon || <Journal />}
          />
        </Indicator>
        <VerticalDivider />
      </Column>
    </Container>
  );
};

export const TimelineItem = ({ item, lastItem, icon }) => {
  return (
    <div style={{ position: 'relative' }}>
      <TimelineIndicator lastItem={lastItem} icon={icon} />
      <Box>{item}</Box>
    </div>
  );
};
