import { CheckboxGroupFilter } from '.';
import { shotLocationOptions } from '../helpers';

export const ShotLocationFilter = ({ readonly }) => {
  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="shot_location"
      label={'On Net Location'}
      options={shotLocationOptions}
      readonly={readonly}
    />
  );
};
