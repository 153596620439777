import React from 'react'
import { getRedGreenColor } from "../../../helpers/tables"


export const metastatsToCompStr = split => {
    return <>
        Highlighting based on {split.ratingsMaxCount} reports 
        {' '}
        {!!split.scoutId ? 
            !!split.scoutName ? `by ${split.scoutName}`
            : 'unknown scout'
        : ''}
        {' '}
        on
        {' '}
        {!!split.ageGroup ? `${split.ageGroup} y.o.` : ''}
        {' '}
        {!!split.league ? split.league.split(' ').map(word => word[0])
        : !!split.leagueGroup ? split.leagueGroup
        : null}
        {' '}
        {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'players'} 
    </>
}


export const getMetastatsAliasMapper = metastats => {
    const ret = {}
    metastats.scoutingMetastats.forEach(ratingMetas => {
        ratingMetas.rating.aliases.forEach(alias => ret[alias.alias] = ratingMetas)
    })
    return ret
}

export const getRatingHighlight = (field, value, aliasToMetas) => {
    try {
        const val = parseFloat(value)
        if (field in aliasToMetas) {
            const { mean, stddev } = aliasToMetas[field]
            return getRedGreenColor((val-mean)/stddev, -2, 2)
        }
    } catch {}
    return null
}

export const getMetastatsHover = (field, value, aliasToMetas) => {
    try {
        const val = parseFloat(value)
        if (field in aliasToMetas) {
            const { mean, stddev, count } = aliasToMetas[field]
            return <div style={{fontSize: '0.8em'}}>
                <div>
                    <b>Mean:</b> {mean.toFixed(2)}
                </div>
                <div>
                    <b>Std Dev:</b> {stddev.toFixed(2)}
                </div>
                <div>
                    <b>Z-Score:</b> {((val-mean) / stddev).toFixed(2)}
                </div>
                <div>
                    <b>Views:</b> {count}
                </div>
            </div>
        }
    } catch {}
    return null
}