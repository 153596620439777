import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Icon from '../Icon';
import { Download } from 'react-bootstrap-icons';
import { TooltipSpan } from 'components/reports';
import { Typography } from '../Typography';

const getHeader = (col) => col.header || col.Header;

export const ExportDataButton = ({ columns = [], data }) => {
  const columnsToUse = columns.filter((col) => !col.ignoreExport);

  const downloadData = useMemo(() => {
    if (!data) return [];
    const ret = [];
    const columnNames = [];
    columnsToUse.forEach((col) => {
      if ('columns' in col) {
        col.columns.forEach((c) => columnNames.push(getHeader(c)));
      } else {
        columnNames.push(getHeader(col));
      }
    });
    ret.push(columnNames);
    data.forEach((d) => {
      const newRow = [];
      columnsToUse.forEach((col) => {
        if ('columns' in col) {
          col.columns.forEach((c) => newRow.push(c.accessor(d)));
        } else {
          const value = col.exportValue
            ? col.exportValue(d, col.accessor(d))
            : col.accessor(d);

          newRow.push(value);
        }
      });
      ret.push(newRow);
    });
    return ret;
  }, [data]);

  return (
    downloadData.length > 0 && (
      <CSVLink data={downloadData} filename={'stats.csv'}>
        <TooltipSpan
          content={<Typography variant="body2">Export data to CSV</Typography>}
        >
          <Button variant="outline-dark" size="sm">
            <Icon padding={2} icon={<Download />} />
          </Button>
        </TooltipSpan>
      </CSVLink>
    )
  );
};
