import React, { useState } from 'react'
import { Col, Row, Button, Collapse } from 'react-bootstrap'
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { GET_FSETS_V2, GET_STAT_DEFINITIONS_V2 } from '../../../apollo/queries/statsv2.queries'
import { BDDSelectSmall, ClickableIcon, HoverInfo } from '../../bdd'
import BDDSelect from '../../bdd/bddselect'
import { NULL_FILTER_VALUE } from "../../../helpers/filters";
import styled from 'styled-components'
import BDDEditInline from '../../bdd/bddeditinline'
import useDebounce from '../../bdd/hooks/usedebounce'
import { TooltipSpan } from '../../reports'
import Draggable from '../../bdd/draggable'
import { itemTypes } from '../../../constants'
import { statToKey } from './statsselect'
import { useQuery } from '@apollo/client'
import { bruinsBlack, bruinsGold } from '../../../helpers/plotting'


export const StatStyles = styled.div`
border-radius: 2px;
box-shadow: 0 0 2px ${bruinsBlack};
margin: 5px;
padding: 1%;
min-width: 70px;
min-height: 30px;
margin-bottom: 5px;
background: white;

select {
    outline: none;
    font-size: 12px;
}

label {
    font-size: 8px;
    font-weight: bold;
    margin: 0;
}

.stat-display {
    font-size: 0.8em;
}

.stat-features-row {
    text-align: center;
}

.add-stat-prompt {
    font-family: Oswald;
    cursor: pointer;
    color: #777;
    :hover {
        color: black;
    }
    transition: all 0.2s;
}

.row {
    transition: all 0.1s;
}
.col {
    transition: all 0.1s;
}

input {
    padding: 0;
    margin: 0;
    text-align: center;
}
`

const normValueToLabel = n => {
  if (n === 'totals') return 'totals'
  if (n === NULL_FILTER_VALUE) return ''
  return `per ${n}`
}

const getLabelTips = (stat, label, fsetToLabel) => {
  // Helper funciton, given a stat definition
  // Return a label "tip" if label doesn't describe norm/situation
  // if label is fine, return null
  if (!!stat.filterSetId && stat.filterSetId != NULL_FILTER_VALUE) {
    const sitLab = fsetToLabel[stat.filterSetId]
    if (!label.toLocaleLowerCase().startsWith(sitLab?.toLocaleLowerCase())) {
      return `This is a fixed ${sitLab} stat, would "${sitLab} ${label}" be a better label?`
    }
  }
  if (!!stat.norm && stat.norm != NULL_FILTER_VALUE && stat.norm != 'totals') {
    if (!label.toLocaleLowerCase().endsWith(`/${stat.norm}`)) {
      return `This is a fixed ${normValueToLabel(stat.norm)} stat, would "${label}/${stat.norm}" be a better label?`
    }
  }
  return null
}


// Component that can be in "edit" mode or "display"
const PickAStat = ({
  stat, // obj { slug, label, filterSetId, norm }
  focused, // if true, will be in edit mode
  handleFocus, // called when stat is clicked
  ...rest
}) => {
  const mode = focused && !!stat ? 'edit' : 'display'

  const { data: fsData, loading: fsLoading } = useQuery(GET_FSETS_V2)
  const filterSets = !!fsData ? fsData.slStatsFilterSetsV2 : null
  var filterSetOptions = !!filterSets ?
    filterSets.map(fs => ({
      label: !fs.manpowerSituation && !fs.strength ? 'All Sit.' : `${fs.manpowerSituation || ''}${fs.strength || ''}`,
      value: fs.id
    }))
    : []
  filterSetOptions = [{ label: 'None', value: NULL_FILTER_VALUE }].concat(filterSetOptions)
  const fsetToLabel = filterSetOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {})

  return <Draggable
    itemType={itemTypes.STAT_REPORT_STAT}
    data={{ statKey: statToKey(stat), stat }}
    draggable={!!stat.slug && mode == 'display'}
  >
    <StatStyles style={{ minWidth: mode === 'edit' ? '250px' : '70px' }} onClick={handleFocus}>
      {mode === 'edit' ?
        <PickAStatInternals
          stat={stat}
          filterSetOptions={filterSetOptions}
          fsetToLabel={fsetToLabel}
          {...rest}
        />
        : mode === 'display' ? <div className='stat-display'>
          <b>
            {!!stat.filterSetId && stat.filterSetId != NULL_FILTER_VALUE && fsetToLabel[stat.filterSetId]}
            {' '}
            {stat.slug}
            {' '}
            {!!stat.norm && stat.norm != NULL_FILTER_VALUE && normValueToLabel(stat.norm)}
          </b>
          {' '}-{' '}
          "<em>{stat.label}</em>"
          {' '}
          {!!getLabelTips(stat, !!stat.label ? stat.label : stat.slug, fsetToLabel) &&
            <TooltipSpan style={{ color: '#EED202' }} content={getLabelTips(stat, !!stat.label ? stat.label : stat.slug, fsetToLabel)}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </TooltipSpan>
          }
        </div>
          : null}
    </StatStyles>
  </Draggable>
}
export default PickAStat


const PickAStatInternals = ({
  stat,
  setStat,
  handleUnfocus,
  handleRemove,
  filterSetOptions,
  fsetToLabel,
  hideFilterSet = true,
  hideNorm = true
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selected, setSelected] = useState(null)


  const debounceSearch = useDebounce(searchTerm, 300);
  const { loading, error, data, previousData } = useQuery(GET_STAT_DEFINITIONS_V2, {
    variables: {
      limit: 20,
      search: debounceSearch,
      sort: [{ id: 'slug' }]
    }
  })

  const statDefs = !!data ? data.statDefinitionsV2.data : []
  var statOptions = statDefs.map(sd => ({
    value: sd.slug,
    label: sd.slug,
    subLabel: sd.description,
    primaryName: sd.primaryName,
    shortName: sd.shortName
  }))
  if (!!stat.slug && statOptions.filter(s => s.value == stat.slug).length == 0) {
    statOptions = [{ value: stat.slug, label: stat.slug }].concat(statOptions)
  }


  const normOptions = [
    { value: NULL_FILTER_VALUE, label: 'None' },
    { value: 'totals', label: 'Totals' },
    { value: '20', label: '20' },
    { value: '60', label: '60' },
    { value: 'gp', label: 'gp' },
  ]

  const statIsPercentage = statDef => {
    if (statDef.statType == 'RateState') return true
    if (JSON.parse(statDef.kwargs).is_percentage) return true
    return false
  }

  return <>
    <Row>
      <Col>
        <BDDSelect
          autoFocus
          name="slug"
          placeholder='Search for a stat...'
          isSearchable={true}
          isLoading={loading || (searchTerm !== debounceSearch)}
          value={stat.slug}
          options={statOptions}
          filterOption={() => true}
          onInputChange={t => setSearchTerm(t)}
          onChange={(name, statSlug) => {
            const def = statDefs.filter(sd => sd.slug == statSlug)[0]
            const defaultLabel = !!def.primaryName ? def.primaryName
              : !!def.shortName ? def.shortName
                : def.slug
            setStat({ slug: statSlug, label: defaultLabel })
            setSelected(def)
          }}
          styles={{
            option: (provided, state) => ({
              ...provided,
              fontSize: '0.8em'
            })
          }}
          noDropdownIndicator
        />
      </Col>
      <Col xs="auto">
        {/* <label>Stat Label</label> */}
        {/* {' '} */}
        <div style={{ fontSize: '0.8em' }}>
          {!!stat.slug &&
            <BDDEditInline
              defaultValue={stat.label}
              onChange={(n, v) => setStat({ label: v })}
              editingProps={{ style: { width: '80%' } }}
              addQuotes
            />}
          {' '}
          {!!getLabelTips(stat, !!stat.label ? stat.label : stat.slug, fsetToLabel) &&
            <TooltipSpan style={{ color: '#EED202' }} content={getLabelTips(stat, !!stat.label ? stat.label : stat.slug, fsetToLabel)}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </TooltipSpan>
          }
        </div>
      </Col>
      <Col xs='auto'>
        <Button size='sm'
          style={{ padding: '4px 10px' }}
          variant='outline-danger'
          onClick={e => {
            e.stopPropagation()
            handleRemove()
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Col>
    </Row>
    <Collapse in={!!stat.slug}>
      <Row className='stat-features-row'>
        {!hideFilterSet && <Col>
          <TooltipSpan content={'Used to create "PPG" or "SH TOI" in a mixed-situation report.  If set, will override manpower/strength in any query.'}>
            <label>Situation</label>
          </TooltipSpan>
          <BDDSelectSmall
            value={stat.filterSetId}
            options={filterSetOptions}
            onChange={(n, v) => {
              const statSetter = { filterSetId: v };
              if (v !== NULL_FILTER_VALUE) {
                const sitLabel = filterSetOptions.find(o => o.value === v).label
                statSetter.label = `${sitLabel} ${stat.label}`
              }
              setStat(statSetter)
            }}
          />
        </Col>}
        {!hideNorm && <Col>
          <TooltipSpan content={'Used to create "G/20" or "Shots Against/60" in a mixed-normalization report.  If set, will override norm in any query.'}>
            <label>Norm</label>
          </TooltipSpan>
          <BDDSelectSmall
            disabled={!!selected && statIsPercentage(selected)}
            value={stat.norm}
            options={normOptions}
            onChange={(n, v) => setStat({ norm: v })}
          />
        </Col>}
        <Col xs='auto' className='ml-auto'>
          <Button size='sm'
            disabled={!stat.slug}
            variant='outline-success'
            onClick={e => {
              e.stopPropagation()
              if (!!stat.slug) {
                handleUnfocus()
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </Col>
      </Row>
    </Collapse>
  </>
}