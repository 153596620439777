import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_SIMILAR_PLAYER_HOCKEY_MODEL_RESULTS } from 'apollo/queries/models.queries';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { roundToX } from 'helpers/data';
import { getRedGreenColor, sortNumeric } from 'helpers/tables';
import { useSimilarPlayerColumns } from './useSimilarPlayerColumns';
import useToggle from 'components/bdd/Toggle/useToggle';
import { formatDollars } from 'helpers/contracts';

const similarPlayerModeToLabel = {
  all: 'All',
  // drafted: 'Drafted',
  made_it: 'Made It',
  nhl: '>1GP NHL',
  // draft_checks: "Same Checks",
  // eligible: 'Draft Eligible',
  could_made_it: "Could have made it"
};

function median(numbers) {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export const DraftSimilarPlayers = ({ rinknetId, draftYear }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_SIMILAR_PLAYER_HOCKEY_MODEL_RESULTS, {
      variables: {
        rinknetId,
        modelSlugs: ['draft-similar-players', 'draft-similar-players-sl'],
      },
    })
  );

  const { buttonGroup: sourceButtonGroup, selectedValue: selectedSource } = useButtonGroup({
    variant: 'link',
    initialSelectedValue: 'draft-similar-players',
    options: [
      {
        value: 'draft-similar-players',
        label: <Typography variant="subtitle1">
          Scouting
          </Typography>
      },
      {
        value: 'draft-similar-players-sl',
        label: <Typography variant="subtitle1">
          SportLogiq
        </Typography>
      },
    ],
  });

  const season = draftYear - 1 + '-' + draftYear;
  const hockeyModelResult = data && data.hockeyModelResults.find(results => results.modelSlug === selectedSource);

  const { buttonGroup: metricButtonGroup, selectedValue: selectedMode } = useButtonGroup({
    initialSelectedValue: 'all',
    options: hockeyModelResult
      ? Object.keys(similarPlayerModeToLabel).map((mode) => {
          const draftPicks = hockeyModelResult?.parsedDetails[mode]?.filter((s) => !!s.draft_pick)
            .filter((s) => s.season != season)
            .map((s) => s.draft_pick);

          const noDraftCount = hockeyModelResult?.parsedDetails[mode]?.filter((s) => !s.draft_pick)
            .filter((s) => s.season != season).length;

          return {
            value: mode,
            label: (
              <Container padding={3} height={120}>
                <Column>
                  <Typography variant="subtitle1">
                    {similarPlayerModeToLabel[mode]}
                  </Typography>
                  <Row columnGap={2}>
                    <Row columnGap={1}>
                      <Typography variant="body2" noWrap>
                        Very Close:
                      </Typography>
                      <Typography variant="body1">
                        {hockeyModelResult?.parsedDetails.metrics[mode]?.count_very_close}
                      </Typography>
                    </Row>
                    <Row columnGap={1}>
                      <Typography variant="body2" noWrap>
                        Close:
                      </Typography>
                      <Typography variant="body1">
                        {hockeyModelResult?.parsedDetails.metrics[mode]?.count_close}
                      </Typography>
                    </Row>
                  </Row>
                  <Row columnGap={1}>
                    <Typography variant="body2" noWrap>
                      Uniqueness:
                    </Typography>
                    <Typography variant="body1">
                      {roundToX(
                        hockeyModelResult?.parsedDetails.metrics[mode]?.uniqueness_score,
                        1
                      )}
                    </Typography>
                  </Row>
                  <Row columnGap={2}>
                  
                      <Row columnGap={1}>
                        <Typography variant="body2" noWrap>
                          Avg Pick:
                        </Typography>
                        {draftPicks && draftPicks.length > 0 && (
                          <Typography variant="body1" noWrap>
                            {median(draftPicks)} ({Math.min(...draftPicks)} - {Math.max(...draftPicks)})
                          </Typography>
                        )}
                      </Row>
                  </Row>
                  <Row columnCap={1}>
                      <Row columnGap={1}>
                        <Typography variant="body2">ND: </Typography>
                        <Typography variant="body1">{noDraftCount}</Typography>
                      </Row>
                  </Row>
                </Column>
              </Container>
            ),
          };
        })
      : [],
  });


  const results = hockeyModelResult?.parsedDetails[selectedMode].map((s) => ({
    ...s,
    leagueAbbreviation: hockeyModelResult?.similarPlayersLeagueAbbreviations.find(
      (la) => la[0] == s.most_played_league
    )[1],
  }));

  const { columns, targetColumns, columnSelect } = useSimilarPlayerColumns();

  return (
    <Container>
      {data ? (
        hockeyModelResult ? (
          <Container>
            <Container padding={1} overflow="auto">
                {sourceButtonGroup}
              </Container>
            <Column gap={4}>
              {selectedSource == 'draft-similar-players' ?  
                <Container>
                  <Row columnGap={3}>
                    <Typography variant="subtitle1">Made it:</Typography>
                    <Border>
                      <Container
                        padding={1}
                        style={{
                          backgroundColor: getRedGreenColor(
                            hockeyModelResult.parsedDetails.metrics.made_it_pct,
                            0,
                            40,
                            false,
                            1.5
                          ),
                        }}
                      >
                        <Typography variant="body2">
                          {roundToX(hockeyModelResult.parsedDetails.metrics.made_it_pct, 2)}
                          %
                        </Typography>
                      </Container>
                    </Border>
                    <Typography variant="body2">
                      {`*Percent of closest 50 neighbors that "could have made it"`}
                    </Typography>
                  </Row>
                  <Row columnGap={3}>
                    <Typography variant="subtitle1">Made it AAV:</Typography>
                    <Border>
                      <Container
                        padding={1}
                        style={{
                          backgroundColor: getRedGreenColor(
                            hockeyModelResult.parsedDetails.metrics.made_it_cap_pct,
                            0,
                            5,
                            false,
                            1.5
                          ),
                        }}
                      >
                        <Typography variant="body2">
                          {formatDollars(hockeyModelResult.parsedDetails.metrics.made_it_adj_aav)}
                        </Typography>
                      </Container>
                    </Border>
                    <Typography variant="body2">
                      {`*Average AAV of highest value contract for similar players that "made it". AAV is adjusted to current salary cap.`}
                    </Typography>
                  </Row>
                  <Row columnGap={3}>
                    <Typography variant="body2">
                      {`*A player has "made it" if they have played >100 NHL games and were draft
                    eligible at least 5 years ago.`}
                    </Typography>
                  </Row>
                </Container> : 
                <Container>
                  <Typography variant="subtitle2">
                      No outcome metrics from SportLoqiq
                  </Typography>
                </Container>
              }
              <Container padding={1} overflow="auto">
                {metricButtonGroup}
              </Container>
              <Column gap={2}>
                {columnSelect}
                <BDDSortableTable
                  columns={targetColumns}
                  data={[
                    {
                      ...hockeyModelResult.parsedInputVal,
                      leagueAbbreviation:
                        hockeyModelResult.similarPlayersLeagueAbbreviations.find(
                          (la) =>
                            la[0] == hockeyModelResult.parsedInputVal.most_played_league
                        )[1],
                    },
                  ]}
                  flexLayout
                  columnJustify="start"
                />
                <Divider />
                <Container overflow="auto">
                  <BDDSortableTable
                    columns={columns}
                    data={results}
                    flexLayout
                    columnJustify="start"
                  />
                </Container>
              </Column>
            </Column>
          </Container>
        ) : (
          <Typography variant="body1">No similarity results found for player</Typography>
        )
      ) : (
        placeholder
      )}
    </Container>
  );
};
