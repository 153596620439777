import { Formik, Form, Field } from 'formik';
import BDDSelectField from 'components/form/bddselectfield';
import BDDInputField from 'components/form/bddinputfield';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Label } from 'components/bdd/Label/Label';
import { Typography } from 'components/bdd/Typography';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { Divider } from 'components/bdd/Divider';
import { Button } from 'react-bootstrap';
import { seasonToStartYear } from 'helpers/hockeyutils';
import { DecoratedBDDInput } from 'components/bdd';
import styled from 'styled-components';
import { theme } from 'constants';
import * as Yup from 'yup';
import useBDDPlayerSearch from 'components/Search/useBDDPlayerSearch';
import { useQuery } from '@apollo/client';
import { GET_BF_TEAMS } from 'apollo/queries/bruinsfriendly.queries';
import { toastError } from 'components/bdd/bddtoasts'
import useToggle from 'components/bdd/Toggle/useToggle';
import BDDDatePicker from 'components/bdd/bdddatepicker';
import { formatDateGQL, parseDateNoTimezone } from 'helpers/helpers';


const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[2],
});

const deadCapCategories = [
  { value: 'RETAINED', label: 'RETAINED' },
  { value: 'BUYOUT', label: 'BUY OUT' },
  { value: 'TERMINATED', label: 'TERMINATED' },
  { value: 'PERF_OVERAGE', label: 'PERFORMANCE OVERAGE' },
  { value: 'AGREEMENT', label: 'AGREEMENT' },
]


export const DeadCapForm = ({
  existing,
  forceTeamSlug,
  updateMutation,
  createMutation,
  handleDelete,
  onCancel,
  hideTeam=false
}) => {
  const schema = Yup.object({
    teamSlug: Yup.string().required('Team is a required field'),
    category: Yup.string().required('Category is a required field'),
    startSeason: Yup.string().trim().matches(/^[0-9]{8}$/, 'Season must be an 8-digit number').required('Start season is a required field'),
    endSeason: Yup.string().trim().matches(/^[0-9]{8}$/, 'Season must be an 8-digit number').required('End season is a required field'),
  });

  const initialValues = !!existing 
    ? {
      teamSlug: existing.teamSlug,
      category: existing.category,
      startSeason: existing.startSeason,
      endSeason: existing.endSeason,
      seasons: JSON.parse(existing.seasons).map(s => ({ season: s.season, capHit: s.cap_hit, retainedPct: s.retained_pct, startDate: !!s.start_date ? parseDateNoTimezone(s.start_date) : null })),
    } : {
      teamSlug: forceTeamSlug || '',
      category: '',
      startSeason: '',
      endSeason: '',
      seasons: []
    }

  const { data } = useQuery(GET_BF_TEAMS, {
    variables: { activeOnly: true },
  });

  const { searchComponent, selectedOption } = useBDDPlayerSearch({
    initialSelectedValue: existing?.bfPlayer?.bddPlayer?.slug,
    defaultOptions: !!existing?.bfPlayer ? [
      { 
        label: `${existing.bfPlayer.firstName} ${existing.bfPlayer.lastName}`, 
        value: existing.bfPlayer.bddPlayer.slug, 
        bddPlayer: { slug: existing.bfPlayer.bddPlayer.slug, bfSlug: existing.bfPlayer.slug } },
    ] : [],
  });

  const playerIsMissing = (category) => !selectedOption && ['RETAINED', 'TERMINATED', 'BUYOUT'].includes(category);
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
    console.log('handling submit')
    if (playerIsMissing(values.category)) {
      toastError('You must select a player');
      return;
    };
    // filter out seasons outside of start year / end year
    // and format dates correctly
    let seasons = values.seasons
      .filter(s => seasonToStartYear(s.season) >= seasonToStartYear(values.startSeason) && seasonToStartYear(s.season) <= seasonToStartYear(values.endSeason))
      .map(s => {
        if (!!s.startDate) {
          return { ...s, startDate: formatDateGQL(s.startDate) }
        }
        return s;
      });
    if (seasons.some(s => !s.capHit)) {
      toastError('Some seasons are missing cap hits!');
      return;
    }
    if (values.category === 'RETAINED' && seasons.some(s => !s.capHit)) {
      toastError('Some seasons are missing retained pcts!');
      return;
    }
    if (!!existing) {
      updateMutation({ variables: { 
        input: {
          ...values, 
          id: existing.id,
          playerSlug: selectedOption?.bddPlayer?.bfSlug,
          seasons
        }
      }});
    } else {
      console.log('calling create')
      createMutation({ variables: { 
        input: {
          ...values,
          playerSlug: selectedOption?.bddPlayer?.bfSlug,
          seasons
        }
      }});
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors, handleChange }) => {
        let years = [];
        if (!errors.startSeason && !errors.endSeason) {
          for (let i = seasonToStartYear(values.startSeason); i <= seasonToStartYear(values.endSeason); i++) {
            years.push(i)
          }
        }
        if (years > 10) {
          years = years.slice(0, 10);
        }

        return (
          <StyledForm>
            <Column gap={4}>
              <Container>

                {!hideTeam && <Container width={300}>
                  <Field
                    type="text"
                    name="teamSlug"
                    title="Team"
                    placeholder="Select a team..."
                    component={BDDSelectField}
                    options={data?.bfTeams?.map(t => ({ 
                      value: t.slug, 
                      label: <Container>
                        <Row gap={2}>
                          <img src={getNHLTeamLogoUrl(t.nhlid)} alt={t.abbreviation} height={20} />
                          <Typography variant='body1'>{t.name}</Typography>
                        </Row>
                      </Container> }))}
                  />
                </Container>}
                
                <Container width={300}>
                  <Field
                    type="text"
                    name="category"
                    title="Category"
                    placeholder="Select a dead cap category..."
                    component={BDDSelectField}
                    options={deadCapCategories}
                  />
                </Container>
                <Container width={300}>
                  <Label>Player</Label>
                  {searchComponent}
                  {playerIsMissing(values.category) && (
                    <Typography variant='error'>Player is a required field when category is {values.category}</Typography>
                  )}
                </Container>
                <Container width={300}>
                  <Field
                    type="text"
                    name="startSeason"
                    title='Start Season'
                    placeholder="Enter start season..."
                    component={BDDInputField}
                  />
                </Container>
                <Container width={300}>
                  <Field
                    type="text"
                    name="endSeason"
                    title='End Season'
                    placeholder="Enter end season..."
                    component={BDDInputField}
                  />
                </Container>
                <Container paddingTop={2}>
                  <Row gap={16} alignItems='stretch' flexWrap>
                    {years.map((y, i) => {
                      const season = values.seasons?.find(s => s.season == `${y}${y+1}`);
                      return (
                        <DeadCapSeason 
                          key={y} 
                          year={y} 
                          yearIndex={i}
                          payload={season} 
                          cateogry={values.category}
                          onChange={(n, v) => {
                            const newSeasons = years.map(year => {
                              const s = values.seasons.find(s => s.season == `${year}${year+1}`);
                              if (year == y) {
                                return { ...s, season: parseInt(`${year}${year+1}`), [n]: v };
                              } else {
                                return { ...s, season: parseInt(`${year}${year+1}`) };
                              }
                            });
                            handleChange({ 
                              target: { 
                                name: 'seasons', 
                                value: newSeasons
                              }});
                            }
                          }
                        />
                      )
                    })}
                  </Row>
                </Container>
              </Container>

              <Divider/>

              <Container>
                <Row justifyContent='space-between'>
                  {!!existing ? <Button variant='danger' onClick={handleDelete}>
                    <Typography variant='body1'>Delete Entry</Typography>
                  </Button> : <div></div>}
                  <Container>
                    <Row gap={8}>
                      <Button
                        variant='secondary'
                        onClick={onCancel}
                      >
                        <Typography variant='body1'>Cancel</Typography>
                      </Button>

                      <Button
                        variant='primary'
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <Typography variant='body1'>Save Entry</Typography>
                      </Button>
                    </Row>
                  </Container>
                </Row>
              </Container>
            </Column>
          </StyledForm>
        );
      }}

    </Formik>
  );
}

const DeadCapSeason = ({ yearIndex, year, payload, category, onChange }) => {
  const season = parseInt(`${year}${year+1}`);
  const capHit = payload?.capHit;
  const retainedPct = payload?.retainedPct;
  const startDate = payload?.startDate;
  const { toggleComponent: inSeasonToggle, toggled: isInSeason } = useToggle({
    label: 'In Season',
    initialToggled: !!payload?.startDate,
    onToggled: (toggled) => {
      if (!toggled) {
        onChange('startDate', null);
      }
    }
  })
  return (
    <Border>
      <Container padding={2}>
        <Typography variant='body1'>{season}</Typography>
        <Label>{year}-{year+1} Cap Hit</Label>
        <DecoratedBDDInput
          type="text"
          name={`${year}_cap_hit`}
          placeholder={`Enter cap hit...`}
          value={capHit}
          frontDecoration={'$'}
          onChange={ev => onChange('capHit', ev.target.value)}
        />
        {!capHit && <Typography variant='error'>
          Cap hit required
        </Typography>}

        {category === 'RETAINED' && (
          <Container>
            <Label>{year}-{year+1} Retention %</Label>
            <DecoratedBDDInput
              type="text"
              name={`${year}_retention`}
              placeholder={`Enter retained % (e.g. 0.3)...`}
              value={retainedPct}
              onChange={ev => onChange('retainedPct', ev.target.value)}
            />
            {!retainedPct && <Typography variant='error'>
              Retained % required
            </Typography>}
          </Container>
        )}

        {yearIndex === 0 && inSeasonToggle}
        {isInSeason && (
          <Container>
            <Label>Start Date</Label>
            <BDDDatePicker
              name={`${year}_start_date`}
              placeholderText={`First date on cap...`}
              value={startDate}
              selected={startDate}
              onChange={date => onChange('startDate', date)}
              popperPlacement="right-end"
            />
          </Container>
        )}
      </Container>                    
    </Border>
  )
}