import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { FilterGroupContextProvider, useFilterGroupContext } from './context';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import React, { useEffect } from 'react';
import { useFilterContext } from '../context';
import styled from 'styled-components';

export const CollapsibleContainer = styled(Container)(({ visible }) => ({
  display: visible ? 'auto' : 'none',
}));

export const FilterGroup = ({ name, children, tags }) => {
  const showGroup = useFilterContext((state) => state.showGroups[name] || false);
  const { setShowGroup } = useFilterContext((state) => state.actions);

  useEffect(() => {
    setShowGroup(name, false);
  }, []);

  return (
    <Container>
      <FilterGroupContextProvider>
        <Container variant="button" onClick={() => setShowGroup(name, !showGroup)}>
          <Typography variant="body1">
            <Row columnGap={2}>
              {name}
              <Icon icon={!showGroup ? <ChevronDown /> : <ChevronUp />} />
              {!showGroup && <FilterTags />}
              {tags}
            </Row>
          </Typography>
        </Container>
        <CollapsibleContainer visible={!!showGroup}>{children}</CollapsibleContainer>
      </FilterGroupContextProvider>
    </Container>
  );
};

export const FilterTags = ({}) => {
  const tags = useFilterGroupContext((state) => state.tags);

  if (Object.values(tags).length == 0) return null;

  return (
    <Row columnGap={2}>
      {Object.values(tags).map((tag, index) => (
        <React.Fragment key={index}>{tag}</React.Fragment>
      ))}
    </Row>
  );
};
