import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import { theme } from 'constants';
import { GET_ALL_REPORTS_FOR_PLAYER } from 'apollo/queries/scouting.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import {
  getGradeOptions,
  nameToOptions,
} from 'components/Scouting/playerformhelpers';
import BDDPlotly from 'components/bdd/bddplotly';
import { useSelect } from 'components/bdd/Select';
import { formatDate } from 'helpers/helpers';
const ControlsContainer = styled.div({
  display: 'flex',
  gap: theme.spacing[3],
  marginTop: theme.spacing[3],
});

const SelectContainer = styled.div({
  width: 200,
});

const FullSelectContainer = styled.div({
  flexGrow: 1,
});

export const ScoutingHistogram = ({ bddPlayer, scoutId }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
      variables: { rinknetId: bddPlayer.rinknetId, scoutId },
    })
  );

  // TODO: Figure out multiple y-axis and better hover text on the plot
  // before supporting multiselect
  const { selected: selectedGrade, select: gradeSelect } = useSelect({
    options: getGradeOptions(bddPlayer.rinknetPlayer.posFDG),
    initialSelectedValue: 'gameScore',
  });

  const { selected: selectedScouts, select: scoutSelect } = useSelect({
    options: [
      ...new Set(data?.allPlayerReports.reports.map((report) => report.author)),
    ].map((author) => ({
      value: author,
      label: author,
    })),
    selectProps: {
      isMulti: true,
      isSearchable: true,
      placeholder: 'Filter scouts...',
    },
  });

  if (!data) return placeholder;

  let reports = data.allPlayerReports.reports;
  if (selectedScouts && selectedScouts.length > 0) {
    reports = reports.filter((report) =>
      selectedScouts.find((scout) => scout.value == report.author)
    );
  }
  
  const options = nameToOptions(selectedGrade.value, bddPlayer.rinknetPlayer.posFDG.toLowerCase());
  let x = options.map((o) => o.label);
  let y = x.map(_ => 0);
  let customdata = x.map(_ => '');

  reports.forEach((report) => {
    const ratings = JSON.parse(
      selectedGrade.skillCategory
        ? report.skillcategoryratings
        : report.ratings
    );

    const reportRating =
      ratings &&
      ratings.length &&
      ratings.find(
        (r) =>
          selectedGrade.ratings.includes(r.Type) ||
          selectedGrade.ratings.includes(r.Type.Name)
      );

    const grade = reportRating && parseFloat(reportRating.Rating.Name);

    // we've got an "x" array of our sorted options for the selected grade
    // now lets increment the corresponding bucket in our "y"
    const gradeIdx = options.findIndex((opt) => opt.value == grade);
    if (grade && gradeIdx >= 0) {
      y[gradeIdx] += 1;
      customdata[gradeIdx] += `${report.author}, ${formatDate(report.date)}<br>`
    }

  });

  const pdata = [{
    type: 'bar',
    x,
    y,
    customdata,
    hovertemplate: '%{customdata}<extra></extra>',
    name: selectedGrade.label,
    marker: {
      color: theme.colors.teams.bos.primary,
      line: {
        color: theme.colors.light.text.primary,
        width: 1,
      },
    },
  }];
  
  return (
    <div>
      <ControlsContainer>
        <SelectContainer>{gradeSelect}</SelectContainer>
        <FullSelectContainer>{scoutSelect}</FullSelectContainer>
      </ControlsContainer>
      <BDDPlotly
        data={pdata}
        layout={{
          hovermode: 'closest',
          yaxis: {
            fixedrange: true,
          },
          xaxis: {
            type: 'category',
          },
        }}
      />
    </div>
  );
};
