import { LinkButton } from "components/bdd/Button";
import { Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { getCurrentSeason } from "helpers/hockeyutils";
import { useState } from "react";
import styled from "styled-components";
import { DraftPickIcon } from "../../ProScouting/Draft/DraftPickIcon";
import useBFDraftCapital from "./useBFDraftCapital"
import { bruinsGold } from "helpers/plotting";
import { theme } from "constants";

const StyledTable = styled.table({
  minWidth: '100%',
  tableLayout: 'fixed',
  'th:nth-child(1)': {  
    width: '50px'
  },
  'td': {
    border: '1px solid #ddd'
  }
})

export default function TeamDraftCaptal({
  teamSlug,
  showAllButton=true,
  showFutureOnly=false,
  onClick, // called with (pick, isOwned)
  selectedPicks=[],
  picksIn=[],
  picksOut=[],
  highlightMovedPicks=true,
  showPlaceholder=true
}) {
  const [showMore, setShowMore] = useState(false);
  const { bfTeam, picks, placeholder } = useBFDraftCapital({ teamSlug });

  // default to current season, but if these are selected, bump forward
  let currentYear = parseInt(getCurrentSeason().toString().slice(4, 8));
  const currentYearPicks = picks?.find(y => y.year == currentYear);
  const picksFromCurrent = currentYearPicks?.tradedPicks?.concat(currentYearPicks?.currentPicks);
  if (!!picksFromCurrent && picksFromCurrent.some(p => !!p.playerSlug)) {
    currentYear += 1;
  }
  const years = [currentYear, currentYear+1, currentYear+2];
  const rounds = [...Array(7).keys()].map(r => r + 1);

  const currTeam = picks?.flatMap(pby => pby.currentPicks.find(p => p.currentTeam.slug === p.originalTeam.slug))?.[0]?.currentTeam;
  
  // Put in/take out any picks
  const editedPicks = picks?.map(picksForYear => {
    const yearPicksIn = picksIn.filter(p => picksForYear.year == p.year);
    const yearPicksOut = picksOut.filter(p => picksForYear.year == p.year);
    return {
      ...picksForYear,
      tradedPicks: picksForYear.tradedPicks
        .filter(p => !yearPicksIn.find(ypi => ypi.id == p.id))
        .concat(yearPicksOut),
      currentPicks: picksForYear.currentPicks
        .filter(p => !yearPicksOut.find(po => po.id == p.id) && !yearPicksIn.find(pi => pi.id == p.id))
        .concat(yearPicksIn.map(pi => ({ ...pi, currentTeam: currTeam })))
    }
  });

  const filteredPicksByYear = editedPicks?.filter(p => {
    if (showFutureOnly) {
      return p.year >= currentYear;
    } else {
      return showMore || years.includes(p.year)
    }
  });

  const pickIsSelected = (pickId) => !!selectedPicks && !!selectedPicks.find(pid => pid === pickId);

  if (placeholder && showPlaceholder) return placeholder;
  return <Container>
    {showAllButton && <Row>
      <Container>
        <LinkButton 
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show less' : 'Show all'}
        </LinkButton>
      </Container>
    </Row>}
    <Container maxHeight='150px' style={{ overflowY: 'auto' }}>
    <StyledTable>
      <thead>
        <tr>
          <th></th>
          {rounds.map(r => <th key={r}>
            <Typography variant='body1' textAlign='center'>
              R{r}
            </Typography>
          </th>)}
        </tr>
      </thead>
      <tbody>
        {filteredPicksByYear?.map(year => {
          const currentPicks = year.currentPicks;
          const tradedPicks = year.tradedPicks;
          return <tr key={year.year}>
            <th>
              <Typography variant='body1' textAlign='center'>
                {year.year}
              </Typography>
            </th>
            {rounds.map(round => {
              const picks = currentPicks
                .filter(p => p.round == round)
                .map(p => ({ ...p, state: 'current' }))
              .concat(tradedPicks
                .filter(p => p.round == round)
                .map(p => ({ ...p, state: 'traded' })));
              return <td key={round}>
                <Row justifyContent='center'>
                  {picks.map(p => (
                    <div key={p.overall}
                      onClick={() => {
                        if (!!onClick) onClick(p, bfTeam.slug === p.currentTeamSlug)
                      }}
                      style={(highlightMovedPicks && p.source === 'PRO_OBJECTIVE')
                        ? { 
                          borderRadius: '10px',
                          background: (p.state === 'current' ? theme.colors.states.success : theme.colors.states.danger)+'77', 
                          boxShadow: '0 0 5px ' + (p.state === 'current' ? theme.colors.states.success : theme.colors.states.danger)
                        } : {}}
                    >
                      <DraftPickIcon 
                        draftPick={p} 
                        isTraded={p.currentTeam.slug !== bfTeam.slug || pickIsSelected(p.id)} 
                      />
                    </div>
                  ))}
                </Row>
              </td>
            })}
          </tr>
        })}
      </tbody>
    </StyledTable>
    </Container>
  </Container>
}