import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from 'constants/theme';
import Icon, { LoadingIcon } from 'components/bdd/Icon/Icon';
import { TooltipSpan } from 'components/reports';
import { ArrowClockwise } from 'react-bootstrap-icons';

const IconButtonStyles = styled.button(({ disabled }) => ({
  ...theme.buttons.base,
  cursor: disabled ? 'default' : 'pointer',
}));

const IconButton = ({
  icon,
  color,
  hoverColor = theme.colors.teams.bos.primary,
  size,
  padding,
  url,
  tooltip,
  disabledTooltip,
  tooltipPlacement,
  onClick,
  loading,
  disabled,
  ...styles
}) => {
  const disabledStyles = disabled
    ? {
        color: theme.colors.states.neutral,
        hoverColer: theme.colors.states.neutral,
      }
    : {};

  const iconComponent = (
    <Icon
      icon={icon}
      color={color}
      hoverColor={hoverColor}
      size={size}
      padding={padding}
      {...disabledStyles}
    />
  );

  return loading ? (
    <LoadingIcon color={color} hoverColor={hoverColor} size={size} padding={padding} />
  ) : (
    <TooltipSpan
      content={disabled ? disabledTooltip : tooltip}
      placement={tooltipPlacement || 'auto'}
    >
      {url ? (
        <IconButtonStyles type="button" {...styles} disabled={disabled}>
          <Link to={url}>{iconComponent}</Link>
        </IconButtonStyles>
      ) : (
        <IconButtonStyles
          type="button"
          {...styles}
          onClick={onClick}
          disabled={disabled}
          style={{ padding }}
        >
          {iconComponent}
        </IconButtonStyles>
      )}
    </TooltipSpan>
  );
};

export default IconButton;
