import { Button } from 'react-bootstrap';

import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { Archive, CheckCircle, PauseCircle, XCircle } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { Dropdown } from 'components/bdd/Dropdown';

const DropdownOption = ({ label, icon }) => {
  return (
    <Typography variant="body1">
      <Row columnGap={2}>
        <Icon icon={icon} />
        {label}
      </Row>
    </Typography>
  );
};

export const IssueStatusButton = ({ issue, hasComment, onClick }) => {
  let dropdownOptions;

  if (['OPEN', 'IN-PROGRESS'].includes(issue.status)) {
    dropdownOptions = [
      {
        value: 'RESOLVED',
        label: (
          <DropdownOption
            label={hasComment ? 'Resolve issue with comment' : 'Resolve issue'}
            icon={<CheckCircle />}
          />
        ),
      },
      {
        value: 'PAUSED',
        label: (
          <DropdownOption
            label="Close issue as paused"
            icon={<PauseCircle />}
          />
        ),
      },
      {
        value: 'IGNORED',
        label: (
          <DropdownOption label="Close issue as ignored" icon={<XCircle />} />
        ),
      },
      // ...(isUserAdmin
      //   ? [
      //       {
      //         value: 'ARCHIVED',
      //         label: (
      //           <DropdownOption
      //             label="Close issue as archived"
      //             icon={<Archive />}
      //           />
      //         ),
      //       },
      //     ]
      //   : []),
    ];
  } else {
    dropdownOptions = [
      {
        value: 'OPEN',
        label: (
          <DropdownOption
            label={hasComment ? 'Re-open issue with comment' : 'Re-open issue'}
            icon={<CheckCircle />}
          />
        ),
      },
    ];
  }

  return (
    <Dropdown
      size="sm"
      options={dropdownOptions}
      initialSelected="RESOLVED"
      onSelected={(option) => onClick(option.value)}
      splitButton={
        <Button
          size="sm"
          variant="outline-dark"
          onClick={() => onClick(dropdownOptions[0].value)}
        >
          {dropdownOptions[0].label}
        </Button>
      }
    />
  );
};
