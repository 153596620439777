import { useQuery } from "@apollo/client";
import { GET_BDD_NOTIFICATION_FILTERS } from "apollo/queries/notification.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";


export default function useNotificationFilters({
  userId,
  skip
}) {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BDD_NOTIFICATION_FILTERS, {
      variables: { userId },
      skip
    })
  )
  const createdByOptions = data?.notificationFilters?.createdByUsers?.map(u => ({ label: u.name, value: u.id }));
  const sourceOptions = data?.notificationFilters?.sources?.map(s => ({ label: s, value: s }));
  const notificationTypeOptions = data?.notificationFilters?.notificationTypes?.map(t => ({ label: t, value: t }));

  return {
    placeholder,
    data,
    createdByOptions,
    sourceOptions,
    notificationTypeOptions,
  }
}