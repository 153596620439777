import { Container } from 'components/bdd/Layout';
import { buildColumns } from 'components/bdd/Table';
import { Typography } from 'components/bdd/Typography';
import BDDSortableTable from 'components/bdd/bddsortabletable';

const parseHeightInches = (height) => {
  if (!height) return null;
  if (height.length > 2 && height[1] == '.') {
    // a height in the form of "ft.inches" could look like 5.11.75
    const feet = Number(height[0]);
    var inches = height.slice(2);
    // catch the case where trailing zeros are cut off so a 5 foot 10 inch player has 5.1
    if (inches.length === 1) {
      inches += '0';
    }
    return feet * 12 + Number(inches);
  } else if (!isNaN(Number(height))) {
    // if we can parse height as a number and it doesn't have a decimal point in the 2nd pos, assume its inches
    return Number(height);
  } else {
    // we've got a string  like 6'0" or 6' 1.5"
    const feet = Number(height.slice(0, height.indexOf("'")));
    const inches = Number(height.slice(height.indexOf("'") + 1, height.indexOf('"')));
    return feet * 12 + inches;
  }
  return null;
};

const parseHeight = (height) => {
  const inches = parseHeightInches(height);
  if (!inches && !!height) return height;
  if (!inches) return null;
  const feet = Math.floor(inches / 12);
  const inchesRemainder = inches % 12;
  const heightStr = `${feet}' ${inchesRemainder}"`;
  return heightStr;
};

export const PlayerHeightWeightChart = ({ data }) => {
  const columns = [
    {
      id: 'Date',
      Header: <Typography variant="body1">Date</Typography>,
      accessor: (d) => d.Date,
      sortType: (a, b) => {
        return new Date(a.original.Date) - new Date(b.original.Date);
      },
      sortDescFirst: true,
      renderCell: (d) => (
        <Typography variant="body2" textAlign="center">
          {d.Date}
        </Typography>
      ),
    },
    {
      id: 'Source',
      Header: <Typography variant="body1">Source</Typography>,
      accessor: (d) => (
        <Typography variant="body2" textAlign="center">
          {d.Source}
        </Typography>
      ),
    },
    {
      id: 'Height',
      Header: <Typography variant="body1">Height</Typography>,
      accessor: (d) => (
        <Typography variant="body2" textAlign="center">
          {parseHeight(d.Height)}
        </Typography>
      ),
      sortType: (a, b) =>
        parseHeightInches(a.original.Height) - parseHeightInches(b.original.Height),
    },
    {
      id: 'Weight',
      Header: <Typography variant="body1">Weight</Typography>,
      accessor: (d) => (
        <Typography variant="body2" textAlign="center">
          {d.Weight}
        </Typography>
      ),
    },
  ];
  return (
    <Container>
      <BDDSortableTable
        data={data}
        columns={buildColumns(columns)}
        defaultSort={[{ id: 'Date', desc: true }]}
      />
    </Container>
  );
};
