import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { InputRange } from 'components/bdd/Range/InputRange';
import { useDropdown } from 'components/bdd/Dropdown';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useStatSearchParams } from './hooks/useStatSearchParams';
import useToggle from 'components/bdd/Toggle/useToggle';

const ControlContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing[3],
  gap: theme.spacing[3],
});

export const StatChartControls = ({}) => {
  const { store } = useStatSearchParams();
  const chartType = store.getValue('chartType');

  const { dropdown } = useDropdown({
    options: [
      {
        value: 'table',
        label: 'Table',
      },
      {
        value: 'scatter',
        label: 'Scatter Chart',
      },
    ],
    initialSelectedValue: 'table',
    handleSelected: (option) => {
      store.setValue('chartType', option.value);
    },
  });

  const { toggleComponent: highlightAgeToggle } = useToggle({
    label: 'Highlight based on age',
    initialToggled: false,
    onToggled: (toggled) => store.setValue('highlightAgeToggled', toggled),
  });

  const { toggleComponent: zScoreToggle } = useToggle({
    label: 'Use z-score values',
    initialToggled: false,
    onToggled: (toggled) => store.setValue('useZScore', toggled),
  });

  return (
    <ControlContainer>
      {dropdown}
      {(!chartType || chartType == 'table') && (
        <>
          {highlightAgeToggle}
          {zScoreToggle}
        </>
      )}
    </ControlContainer>
  );
};
