import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';

const ToggleStyles = styled.div({
  '.custom-control-input:checked~.custom-control-label::before': {
    borderColor: theme.colors.light.text.primary,
    backgroundColor: theme.colors.teams.bos.primary,
  },
});

const Label = styled.div({
  ...theme.typography.body2,
  userSelect: 'none',
});

export const Toggle = ({
  id,
  label,
  labelToggled,
  toggled,
  setToggled,
  disabled,
  field,
  tooltip
}) => {
  // Injected by Formik
  const { value, onChange, name } = field || {};

  return (
    <ToggleStyles>
      <Form.Check
        type="switch"
        id={id || `toggle-${label}`}
        disabled={disabled}
      >
        <Form.Check.Input
          name={name}
          type="checkbox"
          checked={value || toggled || false}
          onChange={onChange || (() => setToggled && setToggled(!toggled))}
          disabled={disabled}
        />
        <Form.Check.Label>
          <TooltipSpan content={tooltip}>
            <Label>{!toggled ? label : labelToggled ?? label}</Label>
          </TooltipSpan>
        </Form.Check.Label>
      </Form.Check>
    </ToggleStyles>
  );
};

export default Toggle;
