import { theme } from 'constants';
import styled from 'styled-components';
import { Border, Column, Container, Row } from '../Layout';
import { Typography } from '../Typography';

const Input = styled.input({
  padding: theme.spacing[1],
  ...theme.borders.light,
  ...theme.borders.thin,
  borderRadius: theme.spacing[1],
});

export const NumberInput = ({
  value,
  min,
  max,
  step,
  label,
  onChange,
  onBlur,
  horizontal,
  placeholder,
  variant = 'body1',
}) => {
  const numberInput = (
    <>
      {label && <Typography variant={variant}>{label}</Typography>}
      <Typography variant={variant}>
        <Input
          type="number"
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </Typography>
    </>
  );

  return (
    <Container>
      {horizontal ? (
        <Row columnGap={2}>{numberInput}</Row>
      ) : (
        <Column>{numberInput}</Column>
      )}
    </Container>
  );
};
