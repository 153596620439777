import React, { createContext } from 'react';

export const VideoContext = createContext();

export const VideoContextProvider = ({
  videoContext,
  children,
}) => {
  return (
    <VideoContext.Provider value={videoContext}>
      {children}
      {/* {videoContext.modal} */}
    </VideoContext.Provider>
  );
};
