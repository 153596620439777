import { BDDTradeDraftPick } from "components/ProScouting/BDDTrades/BDDTradeAsset"
import { Container, Row } from "components/bdd/Layout"
import { scenarioPickToWarning } from "./helpers";
import { HoverInfo } from "components/bdd";
import Icon from "components/bdd/Icon";
import { ExclamationTriangle } from "react-bootstrap-icons";

export const ScenarioPickWarning = ({ scenarioPick }) => {
  const warning = scenarioPickToWarning(scenarioPick);

  return warning && <HoverInfo content={(
    <Container>
      {warning}
    </Container>
  )}>
    <Icon style={{ color: 'red' }} icon={<ExclamationTriangle />}/>
  </HoverInfo>
}
export const ScenarioPick = ({ pick }) => {
  return <Container>
    <Row gap={8} justifyContent='space-between'>
      <BDDTradeDraftPick key={pick.pickId} variant='caption' bfDraftPick={pick.bfDraftPick} allowEdit={false} />
      <ScenarioPickWarning scenarioPick={pick} />
    </Row>
  </Container>
}