import { Filters, useFilterContext, useFilterValues } from 'components/bdd/Filters';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useContractSearchContext, useContractSearchFilterValues } from './context';
import {
  getTimeRangeOptions,
  statValueFilters,
  slStatValueFilters,
  seasonOptions,
} from './constants';
import { SLStatContext, SLStatContextProvider } from 'components/SLStats';
import {
  DateRangeFilter,
  GameTypeFilter,
  SeasonsFilter,
  StrengthFilter,
} from 'components/SLStats/filters';
import { useSLStatContext } from 'components/SLStats/hooks';
import { useContext, useEffect } from 'react';
import { Query } from 'components/bdd/Query';
import { QueryBuilder } from 'components/bdd/Query/Query';
import { nanoid } from 'nanoid';
import { Typography } from 'components/bdd/Typography';
import { ignorePriorSeasonsForTimeRange } from './StatFilters';
import { getNextOrCurrentSeason } from 'helpers/hockeyutils';

const defaults = [
  // {
  //   key: nanoid(),
  //   col: 'GP',
  //   cond: '>=',
  //   val: 1,
  //   extraFilters: [
  //     {
  //       col: 'time-range',
  //       cond: '=',
  //       val: 'platform-stats',
  //     },
  //   ],
  // },
];

const stats = [
  {
    stat: 'GP',
    label: 'Games Played',
  },
  {
    stat: 'G',
    label: 'Goals',
  },
  {
    stat: 'A',
    label: 'Assists',
  },
  {
    stat: 'P',
    label: 'Points',
  },
  {
    stat: 'TOI/GP',
    label: 'TOI / GP',
  },
  {
    stat: 'XPGF',
    label: 'XPGF',
  },
  {
    stat: 'PBR',
    label: 'PBR',
  },
  {
    stat: 'Win',
    label: 'Wins',
  },
  {
    stat: 'GBR',
    label: 'GBR',
  },
  {
    stat: 'GSAA',
    label: 'GSAA',
  },
];

export const options = stats.map((s) => ({
  col: `${s.stat}`,
  label: s.label,
  type: 'number',
  extraFilters: [
    {
      col: 'time-range',
      label: 'Time Range',
      type: 'select',
      options: getTimeRangeOptions(),
      defaultValue: 'platform-stats',
    },
    {
      col: 'platform-prior-seasons',
      label: 'Prior Seasons',
      type: 'number',
      defaultValue: 0,
      hide: (filter) => {
        return !filter?.extraFilters?.find(
          (f) => f.col == 'time-range' && !ignorePriorSeasonsForTimeRange(f.val)
        );
      },
    },
    {
      col: 'season',
      label: 'Target Season',
      type: 'select',
      options: seasonOptions,
      defaultValue: getNextOrCurrentSeason(),
      hide: (filter) => {
        return !filter?.extraFilters?.find(
          (f) => f.col == 'time-range' && f.val == 'multiple-season-stats'
        );
      },
    },
    {
      col: 'custom_sl_filters',
      type: 'custom',
      hide: (filter) =>
        !filter?.extraFilters?.find(
          (f) => f.col == 'time-range' && f.val == 'custom-sl-stats'
        ),
      renderFilter: (filter, onChange) => {
        return <CustomStatQueryFilters filter={filter?.val} onChange={onChange} />;
      },
      // options: getTimeRangeOptions(),
    },
  ],
}));

export const StatQuery = ({}) => {
  const statFilters = useContractSearchContext((state) => state.statFilters);
  const { setStatFilters } = useContractSearchContext((state) => state.actions);

  return (
    <Container>
      <Typography variant="body1">Stat Filters</Typography>
      <Query defaults={statFilters} options={options}>
        <QueryBuilder onChange={setStatFilters} />
      </Query>
    </Container>
  );
};

const CustomStatQueryFilters = ({ filter, onChange }) => {
  const { season } = useContractSearchFilterValues();
  const defaults = {
    target: 'skaters',
    filters: !!filter
      ? filter.filters
      : {
          season,
          league: 'NHL',
          game_type: 'nhl_regular',
        },
  };

  const context = useSLStatContext({
    defaults,
    skip: true,
  });

  return (
    <SLStatContextProvider context={context}>
      <Row columnGap={3} rowGap={2} flexWrap>
        <SeasonsFilter selectProps={{ isClearable: false }} minWidth={200} />
        <DateRangeFilter />
        <GameTypeFilter />
        <StrengthFilter />
      </Row>
      <SLQueryListener onChange={onChange} />
    </SLStatContextProvider>
  );
};

const SLQueryListener = ({ onChange }) => {
  const { query, filters, target } = useContext(SLStatContext);
  const queryFilters = query?.filters;

  useEffect(() => {
    onChange({ queryFilters, filters });
  }, [JSON.stringify(queryFilters)]);
};
