import { useQuery } from "@apollo/client"
import { ANALYZE_TRADE } from "apollo/queries/bddtransactions.queries"
import usePlaceholder from "components/Placeholder/usePlaceholder"
import { tradeAssetsToPicks, tradeAssetsToPlayers } from "./helpers"


export const useTradeAnalysis = ({
  teams,
  assets,
  skip
}) => {
  console.log(assets)
  const { data, placeholder } = usePlaceholder(useQuery(ANALYZE_TRADE, {
    variables: {
      trade: {
        tradedPlayers: tradeAssetsToPlayers(assets),
        tradedPicks: tradeAssetsToPicks(assets),
      },
    },
    skip
  }))

  return {
    teams,
    assets
  }
}