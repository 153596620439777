import { useButtonGroup } from "components/bdd/ButtonGroup"
import { Column, Container } from "components/bdd/Layout"
import { CreateSigning } from "./CreateSigning";
import { CreateObjectiveTrade } from "./CreateObjectiveTrade";


export const CreateTransactionModal = ({ 
  proObjectiveSlug, 
  defaultTab='trade', 
  tradeFormProps,
  onCompleted,
  canSign=true,
  canTrade=true,
  defaultSigningBDDPlayerSlug
}) => {
  let options = []
  if(canTrade) options.push({ label: 'Trade', value: 'trade'})
  if(canSign) options.push({ label: 'Signing', value: 'signing'})

  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: defaultTab,
    options,
    variant: 'link'
  });

  return <Column gap={4}>
    {buttonGroup}
    <Container>
      {selectedValue === 'trade'
        ? <CreateObjectiveTrade 
          proObjectiveSlug={proObjectiveSlug}
          tradeFormProps={tradeFormProps}
          onCompleted={onCompleted}
        />
        : <CreateSigning
          proObjectiveSlug={proObjectiveSlug}
          onCompleted={onCompleted}
          defaultBDDPlayerSlug={defaultSigningBDDPlayerSlug}
          showSimilar
        />}
    </Container>
  </Column>
}