import { Filters } from 'components/bdd/Filters';

export const ScoutingColumnSettings = ({ defaults, onChange }) => {
  return (
    <Filters onChange={onChange} defaults={defaults}>
      {/* <Filters.Toggle id={'sortExportPredicted'} label={'Sort/Export by predicted ranks'} /> */}
      <Filters.Toggle id={'showPredicted'} label={'Show predicted ranks'} />
    </Filters>
  );
};
