import { useQuery } from '@apollo/client';
import { GET_BF_TEAMS } from 'apollo/queries/bruinsfriendly.queries';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useContext } from 'react';
import { PlayerSearchContext } from '..';

export const useTeamFilter = () => {
  const {
    store: { team },
    setStore,
  } = useContext(PlayerSearchContext);

  const { data: teamData, loading: teamLoading } = useQuery(GET_BF_TEAMS, {
    variables: { activeOnly: true },
  });

  const bfTeams = teamData?.bfTeams;

  const teamSelect = (
    <StyledBDDSelect
      selectedValue={team}
      options={
        bfTeams?.map((t) => ({
          value: t.slug,
          label: `${t.name}`,
        })) || []
      }
      selectProps={{
        isClearable: true,
        placeholder: 'Filter to team',
        loading: teamLoading,
      }}
      onChange={(n, v) => {
        setStore((store) => ({
          ...store,
          team: v,
        }));
      }}
    />
  );

  return {
    getTeamFilter: (statQuery) =>
      team && {
        col: statQuery ? 'bf_player_team_slug' : 'team_slug',
        val: team,
      },
    teamSelect,
  };
};
