import { ModalContextProvider } from 'components/bdd/Modal';
import React, { createContext } from 'react';

export const ScenarioContext = createContext();

export const ScenarioContextProvider = ({ context, children }) => {
  return (
    <ScenarioContext.Provider value={context}>
      <ModalContextProvider>
        {children}
      </ModalContextProvider>
    </ScenarioContext.Provider>
  );
};
