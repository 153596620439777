import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { season8to4 } from 'helpers/hockeyutils';
import TruncatedText from 'components/bdd/Truncated/TruncatedText';
import { Column } from 'components/bdd/Layout';
import { roundToX } from 'helpers/data';

const Container = styled.div({
  // display: 'flex',
  // flexWrap: 'wrap',
  // columnGap: theme.spacing[3],
  // alignItems: 'center',
});

const Header = styled.div({
  ...theme.typography.body2,
  fontSize: '9pt',
  display: 'flex',
  gap: theme.spacing[1],
});

const HighlightBoxes = styled.div({
  display: 'flex',
  gap: theme.spacing[1],
});

const HighlightBoxContainer = styled.div(({ backgroundColor }) => ({
  ...theme.typography.stat,
  // display: 'flex',
  // gap: theme.spacing[1],
  backgroundColor,
  padding: theme.spacing[1],
  minWidth: theme.spacing[4],
}));

const HighlightBox = ({
  playerStatsToHighlight,
  slug,
  highlightCallback,
  formatStatByZScore,
}) => {
  const statKey = `stats_${slug}`;
  const value = formatStatByZScore
    ? formatStatByZScore(playerStatsToHighlight[statKey], slug, playerStatsToHighlight)
    : roundToX(playerStatsToHighlight[statKey], 2);
 
  return (
    <HighlightBoxContainer
      style={{ backgroundColor: highlightCallback(slug, value, playerStatsToHighlight) }}
    >
      <Column>
        <div>
          <b>{slug}</b>
        </div>
        <div>{value}</div>
      </Column>
    </HighlightBoxContainer>
  );
};

export const StatHighlightBoxes = ({
  playerStatsToHighlight,
  slugs,
  highlightCallback,
  formatStatByZScore,
  maxLeagueWidth = null,
}) => {
  if (!playerStatsToHighlight) return null;

  const header = (
    <Header>
      <b>{season8to4(playerStatsToHighlight.season, true, true)}</b>
      <b>
        {maxLeagueWidth ? (
          <TruncatedText maxWidth={maxLeagueWidth}>
            {playerStatsToHighlight.league}
          </TruncatedText>
        ) : (
          playerStatsToHighlight.league
        )}
      </b>
      <b>Reg:</b>
    </Header>
  );

  const highlightBoxes = (
    <HighlightBoxes>
      {slugs.map((slug) => (
        <HighlightBox
          key={`highlight-box-${slug}-${playerStatsToHighlight.player_id}`}
          slug={slug}
          playerStatsToHighlight={playerStatsToHighlight}
          highlightCallback={highlightCallback}
          formatStatByZScore={formatStatByZScore}
        />
      ))}
    </HighlightBoxes>
  );

  return (
    <Container>
      <div>{header}</div>
      <div>{highlightBoxes}</div>
    </Container>
  );
};
