import { Filters, useFilterContext } from 'components/bdd/Filters';
import { Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import React, { useContext } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useContractSearchContext } from './context';
import { QueryTags } from 'components/bdd/Query/QueryTags';
import { options } from './StatQuery';
import { RecentFiltersLinkButton } from './RecentFilters';

export const FiltersButton = ({ children, hideTitle, showRecent }) => {
  const tags = useFilterContext((state) => state.tags);
  const statFilters = useContractSearchContext((state) => state.statFilters);
  const { applyFilters } = useContractSearchContext((state) => state.actions);
  const { renderModal, hideModal } = useContext(ModalContext);
  const recentFilters = useContractSearchContext((state) => state.recentFilters);

  return (
    <Container
      variant="button"
      width="fit-content"
      onClick={() =>
        renderModal({
          size: 'xl',
          title: (
            <Row columnGap={3} alignItems="baseline">
              <Typography variant="subtitle1">Filters</Typography>
              <Filters.Controls />
            </Row>
          ),
          body: <Container padding={3}>{children}</Container>,
          footer: (
            <Container>
              <Button
                variant="primary"
                onClick={() => {
                  applyFilters();
                  hideModal();
                }}
              >
                <Typography variant="body1">Apply Filters</Typography>
              </Button>
            </Container>
          ),
        })
      }
    >
      <Row columnGap={2} rowGap={2} flexWrap>
        {!hideTitle && (
          <Typography variant="subtitle1" style={{ alignSelf: 'baseline' }}>
            Filters:
          </Typography>
        )}
        {showRecent && recentFilters && recentFilters.length > 0 && (
          <Container
            style={{ alignSelf: 'baseline' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RecentFiltersLinkButton />
          </Container>
        )}
        <Filters.Reset onReset={applyFilters} />
        {Object.values(tags).map((tag, index) => (
          <React.Fragment key={index}>{tag}</React.Fragment>
        ))}
        <QueryTags filters={statFilters} options={options} />
      </Row>
      <div style={{ display: 'none' }}>{children}</div>
    </Container>
  );
};
