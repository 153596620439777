import styled from 'styled-components';
import { theme } from 'constants/theme';
import { setSpacing } from './Container';

export const Border = styled.div(
  ({
    selected,
    borderRadius,
    onlyBottom,
    onlyRight,
    borderColor,
    borderStyle,
    flexGrow,
    flexBasis,
  }) => {
    return {
      ...theme.borders.thin,
      ...(onlyBottom ? theme.borders.bottom : {}),
      ...(onlyRight ? theme.borders.right : {}),
      ...(selected ? theme.borders.selected : theme.borders.light),
      ...(borderColor ? { borderColor } : {}),
      ...(borderStyle ? { borderStyle } : {}),
      borderRadius: setSpacing(borderRadius),
      flexGrow,
      flexBasis,
    };
  }
);
