import BFSignings from "components/ProScouting/Transactions/BFSignings";
import BFTrades from "components/ProScouting/Transactions/BFTrades";
import { useButtonGroup } from "components/bdd/ButtonGroup";
import { Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography";


export const RecentTranscations = ({}) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'trades',
    options: [
      { label: 'Trades', value: 'trades' },
      { label: 'Signings', value: 'signings' },
    ],
    variant: 'link'
  })

  return <Container>
    <Typography variant='subtitle1'>
      Recent Transactions
    </Typography>
    <Typography variant='caption'>
      These transactions are sourced from CapFriendly and are refreshed every morning.
    </Typography>

    {buttonGroup}

    {selectedValue === 'trades'
      ? <BFTrades collapsedFilters={true} />
      : <BFSignings collapsedFilters={true} />
    }

  </Container>
}