import { useQuery } from "@apollo/client";
import { GET_RANKINGS_V2 } from "apollo/queries/statsv2.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { Container } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography";
import BDDPlotly from "components/bdd/bddplotly";
import StillBuilding from "components/bdd/stillbuilding"
import { season8to4 } from "helpers/hockeyutils";
import { bruinsBlack, bruinsGold } from "helpers/plotting";


export const SkillTrendChart = ({
  playerId,
  leagueSlug,
  gameType,
  statDefinition, // [{ slug, strength, manpowerSituation, negative }]
  posFDG,
  showTitle=true,
  height=80
}) => {
  const { data, loading, error } = useQuery(GET_RANKINGS_V2, {
    skip: !playerId || !leagueSlug,
    variables: {
      leagueSlug,
      gameType,
      playerId,
      statDefinitions: [{
        statSlug: statDefinition.slug, 
        strength: statDefinition.strength, 
        manpowerSituation: statDefinition.manpowerSituation
      }],
      position: posFDG,
      norm: posFDG == 'g' ? '60' : '20',
    },
  });

  const rankings = data?.slPlayerRankingsV2;
  const getValue = (r) => !!statDefinition.negative
    ? r.percentile * 100
    : 100 * (1 - r.percentile);

  const pdata = [{
    type: 'scatter',
    mode: 'lines+markers',
    name: 'trend',
    x: rankings?.map(r => season8to4(r.season, true)),
    y: rankings?.map(r => getValue(r)),
    line: { color: bruinsGold },
    marker: { color: bruinsBlack },
    hovertemplate: '<b>Season</b>: %{x}<br><b>%{y:.2f}%</b><extra></extra>',
  }];

  const layout = {
    margin: { t: 5, b: 30, l: 20, r: 20 },
    height,
    yaxis: {
      title: '',
      range: [0, 100],
      size: 6
    },
    xaxis: {
      tickangle: 45,
      font: { size: 6 }
    },
    shapes: [{
      type: 'line',
      x0: 0,
      x1: 1,
      xref: 'paper',
      y0: 50,
      y1: 50,
      line: { color: '#777', width: 1, dash: 'dot' }
    }]
  };

  if (loading) return null;
  if (error) {
    console.error(error);
    return null;
  }
  return (
    <Container>
      {showTitle && <Typography textAlign='center' variant='stat'>{statDefinition.label} Year-to-Year</Typography>}
      <BDDPlotly
        data={pdata}
        layout={layout}
        displayModeBar={false}
      />
    </Container>
  )
}