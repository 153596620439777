import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { SimilarFreeAgentSignings } from '../PlayerSearch';
import BDDInputField from 'components/form/bddinputfield';
import { useMutation } from '@apollo/client';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { useContext } from 'react';
import { ProObjectiveContext } from '../ProObjectiveContextProvider';
import { UPDATE_BDD_SIGNING } from 'apollo/queries/bddtransactions.queries';
import useToggle from 'components/bdd/Toggle/useToggle';
import { theme } from 'constants';
import Icon from 'components/bdd/Icon';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { ScenarioContext } from '../Scenarios/ScenarioContextProvider';

export const SigningForm = ({ 
  bddPlayerSlug, 
  proObjectiveSlug, 
  existingSigning, 
  addToPOTransactionId, // if supplied (as an ID) will call "addPOTransactionVariant" instead of "createPOTransaction"
  onCompleted 
}) => {

  const initialValues = existingSigning
    ? {
      id: existingSigning.id,
      term: existingSigning.term,
      capHitNhl: existingSigning.capHitNhl / 1e6,
      capHitAhl: existingSigning.capHitAhl / 1e6,
    } : {};
  

  const objectiveCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { createPOTransaction, addPOTransactionVariant } = !!objectiveCtx ? objectiveCtx : scenarioCtx;
  const [updateBDDSigning] = useMutation(UPDATE_BDD_SIGNING, {
    onError: toastBddApiError
  });


  const { toggleComponent, toggled } = useToggle({
    label: '2-Way Deal'
  });

  const handleCreateSigning = signing => {
    if (!!addToPOTransactionId) {
      addPOTransactionVariant({
        onCompleted: ({addProObjectiveTransactionVariant: { proObjectiveTransactionVariant }}) => {
          toastInfo(`Signing variant added`);
          if (!!onCompleted) onCompleted(proObjectiveTransactionVariant);
        },
        variables: {
          proObjectiveSlug,
          transactionId: addToPOTransactionId,
          signing: {
            ...signing,
            bddPlayerSlug: bddPlayerSlug,
            capHitNhl: signing.capHitNhl*1e6,
            capHitAhl: (!!toggled && !!signing.capHitAhl) ? signing.capHitAhl*1e6 : undefined,
          }
        }
      })
      return;
    } else {
      createPOTransaction({
        onCompleted: ({createProObjectiveTransaction: { proObjectiveTransaction }}) => {
          toastInfo(`Signing created`);
          if (!!onCompleted) onCompleted(proObjectiveTransaction);
        },
        variables: {
          proObjectiveSlug: proObjectiveSlug,
          transactionType: 'SIGNING',
          signing: {
            ...signing,
            capHitNhl: signing.capHitNhl*1e6,
            capHitAhl: (!!toggled && !!signing.capHitAhl) ? signing.capHitAhl*1e6 : undefined,
            bddPlayerSlug: bddPlayerSlug,
          }
        }
      })
    }
  }

  const handleUpdateSigning = (signing) => {
    updateBDDSigning({
      onCompleted: () => {
        toastInfo('Signing updated!')
        if (!!onCompleted) onCompleted();
      },
      variables: {
        input: {
          id: existingSigning.id,
          term: signing.term,
          capHitNhl: signing.capHitNhl*1e6,
          capHitAhl: !!signing.capHitAhl ? signing.capHitAhl*1e6 : undefined,

        },
      }
    })

  }

  const handleSubmit = (values) => {
    if (!!existingSigning && !addToPOTransactionId) {
      handleUpdateSigning(values);
    } else {
      handleCreateSigning({ ...values });
    }
  }

  if (!bddPlayerSlug) throw Error('Must supply bddPlayerSlug')

  const renderError = (msg) => {}

  return (
    <Container>
      <Column gap={2}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, touched }) => {
            return (
              <Form>
                <Row columnGap={2} alignItems="center">
                  <Column>
                    <Typography variant="body1">Set term</Typography>
                    <Field
                      type="number"
                      name="term"
                      placeholder="Term"
                      autoComplete="off"
                      component={BDDInputField}
                      min={0}
                    />
                  </Column>
                  <Column>
                    <Typography variant="body1">NHL cap hit (Millions)</Typography>
                    <Field
                      type="number"
                      name="capHitNhl"
                      placeholder="e.g. 1.5, 0.875, 8.66..."
                      autoComplete="off"
                      component={BDDInputField}
                      min={0}
                    />
                  </Column>
                  <Column justifyContent='start' height='100%'>
                    {toggleComponent}
                  </Column>
                  {toggled && <Column>
                    <Typography variant="body1">AHL cap hit (millions)</Typography>
                    <Field
                      type="number"
                      name="capHitAhl"
                      placeholder="e.g. 0.2, 0.875, 1.1..."
                      autoComplete="off"
                      component={BDDInputField}
                      min={0}
                    />
                  </Column>}
                  <Button type="submit" variant="warning" disabled={!values?.term || values.term < 0 || values.capHitNhl < 0}>
                    <Typography variant="body1">Sign Player</Typography>
                  </Button>
                </Row>
                <Row>
                  <Column>
                    {(values.term < 1 || values.term > 8) && (
                      <Row gap={4}>
                        <Icon icon={<ExclamationTriangle/>} color={theme.colors.states.danger} />
                        <Typography variant="body1" color={theme.colors.states.danger}>Term must be between 1 and 8</Typography>
                      </Row>
                    )}
                    {(values.capHitNhl < 0) && (
                      <Row gap={4}>
                        <Icon icon={<ExclamationTriangle/>} color={theme.colors.states.danger} />
                        <Typography variant="body1" color={theme.colors.states.danger}>NHL cap hit must be greater than 0</Typography>
                      </Row>
                    )}
                    {(values.capHitNhl < 0.775) && (
                      <Row gap={4}>
                        <Icon icon={<ExclamationTriangle/>} color={theme.colors.states.warning} />
                        <Typography variant="body1" color={theme.colors.states.warning}>Warning: the NHL minimum salary is 0.775M</Typography>
                      </Row>
                    )}

                    {(values.capHitNhl > 10) && (
                      <Row gap={4}>
                        <Icon icon={<ExclamationTriangle/>} color={theme.colors.states.warning} />
                        <Typography variant="body1" color={theme.colors.states.warning}>Warning: that's a big contract, are you sure you meant to enter {values.capHitNhl} MILLION dollars</Typography>
                      </Row>
                    )}
                  </Column>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
