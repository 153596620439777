import React from "react"
import { useQuery, gql } from "@apollo/client"
import { Link, useRouteMatch } from "react-router-dom"
import { BDDLoader } from "../bdd/bddloader"
import { UniqueVariableNamesRule } from "graphql"
import { getCurrentSeason, season8to4 } from "../../helpers/hockeyutils"
import styled from "styled-components"
import { numberWithCommas } from "../../helpers/helpers"
import { bruinsBlack, bruinsGold } from "../../helpers/plotting"
import { buildPlayerRoute } from "../../helpers/routing"
import BDDApiError from "../bdd/bddapierror"
import { Container } from "react-bootstrap"

const GET_TEAM_DEPTH_CHART = gql`query GetTeam($slug: String!) {
    slTeam(slug: $slug) {
        id
        slug
        nhlid
        leagueSlug
        longname
        shorthand
        depthChart {
            id
            nhlid
            firstname
            lastname
            age
            posFDG
            position
            currentteam
            currentleague
            nhlContracts {
                isActive
                isFuture
                startSeason
                expirationSeason
                averagedAmount
            }
            masterdepthchartposition
            reservelistteam
            bddPlayerSlug {
                slug
            }
        }
    }
}`

const StyledTable = styled.table`
    width: 100%;
    margin-bottom: 20px;
    th {
        background: #333;
        color: white;
        font-weight: normal;
        padding: 0 5px;
    }
    td {
        font-size: 0.8em;
        padding: 5px;
        text-align: center;
    }
    td.nowrap {
        white-space: nowrap;
    }
    td.lalign {
        text-align: left;
    }
    
    .totals {
        font-weight: bold;
    }

    tr:nth-child(odd) {
        background-color: #aaddff77;
    }
`
export const CapTable = ({title, seasons, playerIds, playerToInfo, playerToYearToContract, salaryCapCeiling}) => {
    // "seasons" lists the first year of the season (e.g. 2020 means the 20-21 season)
    const formatDollars = amt => !!amt ? `$${numberWithCommas(amt)}` : ''
    const seasonToTotal = {}
    const averageAge = playerIds.reduce((acc, curr) => acc + playerToInfo[curr].age, 0) / playerIds.length
    return <StyledTable>
        <tbody>
        <tr>
            <th>{title}</th>
            <th>Pos</th>
            <th>Age</th>
            {!!salaryCapCeiling && <th>Cap%</th>}
            {seasons.map(s => <th key={s}>{s.toString().slice(2, 4)}-{(s+1).toString().slice(2,4)}</th>)}
        </tr>
        {playerIds.map(pid => <tr key={pid} className={!!playerToInfo[pid].className ? playerToInfo[pid].className : ''}>
            <td className='nowrap lalign'>
                <Link to={buildPlayerRoute(playerToInfo[pid]?.bddPlayerSlug?.slug)}>
                    {playerToInfo[pid].firstname} {playerToInfo[pid].lastname}
                </Link>
            </td>
            <td className='nowrap'>{playerToInfo[pid].position}</td>
            <td>{playerToInfo[pid].age.toFixed(1)}</td>
            {!!salaryCapCeiling && <td>
                {!!playerToInfo[pid].activeContract ?
                    (100*playerToInfo[pid].activeContract.averagedAmount / salaryCapCeiling).toFixed(1)
                : 'n/a'}
            </td>}
            {seasons.map(s => {
                if (!(s in seasonToTotal)) seasonToTotal[s] = 0
                if (!(pid in playerToYearToContract) || !(seasons[0] in playerToYearToContract[pid])) {
                    if (s === seasons[0]) {
                        return <td key={s}>
                            <small>N/A</small>
                        </td>
                    }
                    return <td key={s}></td>
                }
                if (s in playerToYearToContract[pid]) {
                    seasonToTotal[s] += playerToYearToContract[pid][parseInt(s)].contract.averagedAmount
                    return <td key={s}>
                        {formatDollars(playerToYearToContract[pid][parseInt(s)].contract.averagedAmount)}
                    </td>
                } else {
                    return <td key={s}></td>
                }
            })}
        </tr>)}
        <tr className="totals">
            <td>Total</td><td></td><td>{averageAge.toFixed(1)}</td>
            {!!salaryCapCeiling && <td>
                {(100 * seasonToTotal[parseInt(seasons[0])] / salaryCapCeiling).toFixed(1)}
            </td>}
            {seasons.map(s => <td key={s}>{formatDollars(seasonToTotal[parseInt(s)])}</td>)}
        </tr>
    </tbody>
    </StyledTable>
}


export default function TeamCapInfo({teamSlug: tSlugProp}) {
    const { params } = useRouteMatch()
    const teamSlug = !!tSlugProp ? tSlugProp : params.teamslug
    const { data, loading, error } = useQuery(GET_TEAM_DEPTH_CHART, {variables: { slug: teamSlug }})

    const firstSeason2 = getCurrentSeason()
    const firstSeason = parseInt(firstSeason2.toString().slice(4)) - 1
    const playersAAV = []
    var seasons = []
    if (!!data) {
        var playerToYearToContract = {}
        var playerToInfo = {}
        data.slTeam.depthChart.forEach(p => {
            if (!(p in playerToInfo)) {
                playerToInfo[p.id] = p
            }
            if (p.nhlContracts) {
                p.nhlContracts.filter(c => c.isActive || c.isFuture).forEach(c => {
                    if (!(p.id in playerToYearToContract)) {
                        playerToYearToContract[p.id] = {}
                    }
                    for (let s=c.startSeason; s<c.expirationSeason; s++) {
                        if (!seasons.includes(s) && s >= firstSeason) {
                            seasons.push(parseInt(s))
                        }
                        if (s === firstSeason) {
                            playersAAV.push({id: p.id, aav: c.averagedAmount})
                        }
                        const playerContract = {...p}
                        delete playerContract.nhlContracts
                        playerContract.contract = c
                        playerToYearToContract[p.id][s] = playerContract
                    }
                })    
            }
        })   
    }
    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error}/>

    const playerOrder = playersAAV.sort((a,b) => b.aav - a.aav).map(p => p.id)
    seasons = seasons.sort((a,b) => a - b)
    const positions = [{short: 'F', long: 'Forwards'}, {short: 'D', long: 'Defensemen'}, {short: 'G', long: 'Goalies'}]

    return <>
        {positions.map(pos => {
            const playerIds = playerOrder.filter(pid => playerToInfo[pid].posFDG === pos.short)
            return <Container key={pos.short}>
                <CapTable
                    title={pos.long}
                    playerIds={playerIds}
                    playerToInfo={playerToInfo}
                    seasons={seasons}
                    playerToYearToContract={playerToYearToContract}
                />
            </Container>
        })}
        <div style={{height: "200px"}}></div>
    </>
}