import usePlaceholder from "components/Placeholder/usePlaceholder";
import useBDDPlayerSearch from "components/Search/useBDDPlayerSearch"
import { Column, Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography";
import { useQuery } from "@apollo/client";
import { GET_CF_DRAFT_PICKS_FOR_YEAR } from "apollo/queries/capfriendly.queries";
import { useSelect } from "components/bdd/Select";
import { getCurrentDraftYear } from "helpers/hockeyutils";
import { Button } from "react-bootstrap";
import { BDD_ASSET_TYPES } from "./constants";


export const TradeAssetSearch = ({ teamSlug, handleToggleAsset }) => {

  const { searchComponent, selected, selectedOption } = useBDDPlayerSearch({
    onSelect: console.log
  });

  const currYear = getCurrentDraftYear();
  let options = [];
  for (let i=0; i<4; i++) {
    options.push({ label: currYear+i, value: currYear+i })
  }
  const { select: yearSelect, selectedValue: year } = useSelect({
    initialSelectedValue: getCurrentDraftYear(),
    options
  })
  const { data, loading } = useQuery(GET_CF_DRAFT_PICKS_FOR_YEAR, {
    variables: {
      year
    }
  })
  const { select: pickSelect, selected: selectedPick } = useSelect({
    options: !!data ? data?.cfDraftPicksForYear?.map(d => ({ 
      label: `R${d.round} ${d.overall} OVR (${d.currentTeam.abbreviation}${d.currentTeamSlug != d.originalTeamSlug ? ` from ${d.originalTeam.abbreviation}` : ''})`, 
      value: d.id,
      cfDraftPick: d
    })) : [],
    selectProps: {
      isSearchable: true,
      isLoading: loading
    }
  })

  return <Container>
    <Column gap={2}>
      <Typography variant='body2'>
        Search for any player or draft pick.  This form won't stop you from adding assets this team doesn't controll
      </Typography>

      <Typography variant='label'>
        Search for a player
      </Typography>
      <Row gap={8}>
        {searchComponent}
        <Button 
          size='sm' 
          variant='primary' 
          onClick={() => handleToggleAsset(BDD_ASSET_TYPES.PLAYER, selected, { bddPlayer: selectedOption.bddPlayer })}
          disabled={!selected}
        >
          Add
        </Button>
      </Row>

      <Typography variant='label'>
        Search for a draft pick
      </Typography>
      <Row gap={8}>
        <Container flexGrow={1} minWidth={100}>
          {yearSelect}
        </Container>
        <Container flexGrow={1} minWidth={150}>
          {pickSelect}
        </Container>
        <Button 
          size='sm' 
          variant='primary'
          onClick={() => handleToggleAsset(BDD_ASSET_TYPES.DRAFT_PICK, selectedPick.value, { cfDraftPick: selectedPick.cfDraftPick })}
          disabled={!selectedPick}
        >
          Add
        </Button>
      </Row>
    </Column>
  </Container>
}