import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { InputRange } from 'components/bdd/Range/InputRange';
import { useDropdown } from 'components/bdd/Dropdown';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useStatSearchParams } from './hooks/useStatSearchParams';
import { Button } from 'react-bootstrap';

const FieldLabel = styled.div({
  ...theme.typography.body1,
});

const FieldContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing[3],
  width: '100%',
});

const FilterControlContainer = styled(FieldContainer)({
  alignItems: 'center',
  marginBottom: theme.spacing[3],
});

const PlayerSelectField = styled.div({
  width: 500,
});

export const StatClientFilters = ({ stats }) => {
  const { store, getStatDefFilterValues, playerFilterValues } =
    useStatSearchParams();

  const dropdownOptions =
    stats?.format.stat_definitions.map((sd) => ({
      value: sd.slug,
      label: sd.label,
      min: Math.min(...stats.data.map((stat) => stat[sd.slug])),
      max: Math.max(...stats.data.map((stat) => stat[sd.slug])),
    })) || [];

  const { dropdown } = useDropdown({
    options: [
      {
        value: 'add',
        label: 'Add Filter',
      },
      ...dropdownOptions,
    ],
    initialSelectedValue: 'add',
    handleSelected: (option) => {
      store.setRange(option.value, option.min, option.max);
    },
    firstOptionLabel: true,
  });

  const statDefFilterValues = getStatDefFilterValues(stats);
  const filteredDropdownOptions = dropdownOptions?.filter(
    (d) => !!statDefFilterValues[d.value]
  );

  const statDefOptions = filteredDropdownOptions.map((d) => (
    <InputRange
      field={{
        value: statDefFilterValues[d.value],
        onChange: (event) => {
          const value = event.target.value;
          store.setRange(d.value, value.min, value.max);
        },
      }}
      label={<FieldLabel>{d.label}</FieldLabel>}
      min={d.min}
      max={d.max}
      formatLabel={(value, type) => (type == 'value' ? value : null)}
    />
  ));

  const resetFilters = () =>
    store.deleteRangeKeys(filteredDropdownOptions.map((d) => d.value));

  const uniquePlayerIds = [...new Set(stats?.data.map((d) => d['player_id']))];
  const playerFilterOption = (
    <StyledBDDSelect
      selectedValue={playerFilterValues}
      onChange={(_, values) => {
        store.setArray('player_ids', values);
      }}
      selectProps={{
        placeholder: 'Filter players',
        isMulti: true,
        isSearchable: true,
      }}
      options={uniquePlayerIds.map((p) => {
        const player = stats?.data.find((d) => d['player_id'] == p);
        return {
          value: player['player_id'],
          label: player['player'],
        };
      })}
    />
  );

  return (
    <>
      <FilterControlContainer>
        <PlayerSelectField>{playerFilterOption}</PlayerSelectField>
        {dropdown}
        {filteredDropdownOptions.length > 0 && (
          <Button variant="outline-danger" onClick={resetFilters}>
            Reset Filters
          </Button>
        )}
      </FilterControlContainer>
      <FieldContainer>{statDefOptions}</FieldContainer>
    </>
  );
};
