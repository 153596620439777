import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const ScatterChartOptions = ({}) => {
  const { data, setChartOptions } = useContext(SLStatContext);

  const options = data?.format?.stats.map((s) => ({
    label: s,
    value: s,
  }));

  const { select: xAxisSelect, selected: xAxisStat } = useSelect({
    options: options || [],
  });

  const { select: yAxisSelect, selected: yAxisStat } = useSelect({
    options: options || [],
  });

  const { toggled: invertXAxis, toggleComponent: invertXAxisToggle } =
    useToggle({ id: 'invert-x-axis', label: 'Invert' });

  const { toggled: invertYAxis, toggleComponent: invertYAxisToggle } =
    useToggle({ id: 'invert-y-axis', label: 'Invert' });

  const { toggled: showLabel, toggleComponent: showLabelToggle } = useToggle({
    label: 'Show Labels',
  });

  const { toggled: showLeagueAvg, toggleComponent: leagueAvgToggle } =
    useToggle({
      label: 'Show League Avg',
    });

  const { toggled: showStandardRange, toggleComponent: standardRangeToggle } =
    useToggle({
      label: 'Show Standard Range',
    });
  const { toggled: showBestFit, toggleComponent: bestFitToggle } = useToggle({
    label: 'Show Best Fit (OLS)',
  });

  useEffect(() => {
    console.log({
      xAxisStat,
      yAxisStat,
      showLabel,
      showLeagueAvg,
      showStandardRange,
      showBestFit,
      invertXAxis,
      invertYAxis,
    });

    setChartOptions({
      type: 'scatter',
      options: {
        xAxisStat,
        yAxisStat,
        showLabel,
        showLeagueAvg,
        showStandardRange,
        showBestFit,
        invertXAxis,
        invertYAxis,
      },
    });
  }, [
    xAxisStat?.value,
    yAxisStat?.value,
    showLabel,
    showLeagueAvg,
    showStandardRange,
    showBestFit,
    invertXAxis,
    invertYAxis,
  ]);

  if (!data) {
    return <Typography variant="body1">Loading options...</Typography>;
  }

  return (
    <>
      <Row columnGap={2}>
        <Column>
          <Row justifyContent="space-between">
            <Typography variant="body1">X-Axis</Typography>
            {invertXAxisToggle}
          </Row>
          <Container width={150}>{xAxisSelect}</Container>
        </Column>
        <Column>
          <Row justifyContent="space-between">
            <Typography variant="body1">Y-Axis</Typography>
            {invertYAxisToggle}
          </Row>
          <Container width={150}>{yAxisSelect}</Container>
        </Column>
      </Row>
      <Row flexWrap columnGap={3}>
        {showLabelToggle}
        {leagueAvgToggle}
        {standardRangeToggle}
        {bestFitToggle}
      </Row>
    </>
  );
};
