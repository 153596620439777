
export function throttle(callback, limit) {
    // Initially, we're not waiting
    var wait = false;       
    // We return a throttled function
    return function () {               
        // If we're not waiting, execute
        if (!wait) {     
            callback.call();           
            wait = true;               
            // Prevent future invocations
            setTimeout(function () {   
                wait = false;          
            }, limit);
        }
    }
}

// untested
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this
        var args = arguments
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};