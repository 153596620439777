import { theme } from 'constants/theme';
import styled from 'styled-components';

export const LinkButton = styled.div(
  ({ alignItems, justifyContent, underline = 'text' }) => ({
    ...theme.buttons.link(theme.colors.light.text.primary, underline),
    cursor: 'pointer',
    userSelect: 'none',
    alignItems: alignItems || 'center',
    justifyContent: justifyContent || 'start',
  })
);
