import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { TooltipSpan } from 'components/reports';
import { formatDollars } from 'helpers/contracts';
import { formatStats, roundToX } from 'helpers/data';
import { formatDate, formatMinutes } from 'helpers/helpers';
import { season8to4, seasonToEndYear, seasonToStartYear } from 'helpers/hockeyutils';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { getRedGreenColor, sortDate, sortNumeric } from 'helpers/tables';

export const SORTABLE_COLUMN_MAPPER = {
  // maps table col id to sortable column
  signedDate: 'signedDate',
};

export const platformStatDefs = [];

const gameTypeToString = (gt) => {
  if (gt === 'nhl_regular') return 'REG';
  if (gt === 'nhl_postseason') return 'PLYFS';
  return gt.toUpperCase();
};

const getPlatformStatAccessor = (fieldAccessor) => {
  return (d) => {
    if (!d.slStats) return null;
    const stats = JSON.parse(d.slStats);
    const platformStats = stats?.allsit?.filter(
      (s) =>
        s.season === d.platformSeason &&
        s.gamesPlayed > 5 &&
        s.game_type != 'nhl_preseason'
    );
    return (
      <Column>
        {platformStats.map((s) => (
          <Typography key={s.game_type} variant="body2">
            {fieldAccessor(s)}
          </Typography>
        ))}
      </Column>
    );
  };
};

export const signingsTableColumns = [
  {
    id: 'signedDate',
    Header: <Typography variant="body1">Date</Typography>,
    accessor: (s) => new Date(s.signedDate),
    sortType: (a, b, colId) => sortDate(a, b, colId, 'signedDate'),
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => {
      return <Typography variant="body2">{formatDate(s.signedDate)}</Typography>;
    },
    export: (s) => s.signedDate,
  },
  {
    id: 'player',
    Header: <Typography variant="body1">Player</Typography>,
    accessor: (s) => (
      <PlayerLinkHover
        name={`${s.bfPlayer.firstName} ${s.bfPlayer.lastName}`}
        slug={s.bfPlayer.bddPlayer?.slug}
      />
    ),
    export: (s) => `${s.bfPlayer.firstName} ${s.bfPlayer.lastName}`,
  },
  {
    id: 'position',
    Header: <Typography variant="body1">Position</Typography>,
    tooltip: 'Position',
    accessor: (s) => <Typography variant="body2">{`${s.bfPlayer.position}`}</Typography>,
    export: (s) => s.bfPlayer.position,
  },
  {
    id: 'height',
    Header: <Typography variant="body1">Height</Typography>,
    tooltip: 'Current height (according to CapFriendly)',
    accessor: (s) => <Typography variant="body2">{s.bfPlayer.height}</Typography>,
    export: (s) => `${s.bfPlayer.height}"`,
  },
  {
    id: 'weight',
    Header: <Typography variant="body1">Weight</Typography>,
    tooltip: 'Current weight (according to CapFriendly)',
    accessor: (s) => <Typography variant="body2">{s.bfPlayer.weight}lbs</Typography>,
    export: (s) => s.bfPlayer.weight,
  },
  {
    id: 'team',
    Header: <Typography variant="body1">Team</Typography>,
    accessor: (s) => (
      <Row>
        <img alt="" height="20px" src={getNHLTeamLogoUrl(s?.signingTeam?.nhlid)} />
        <Typography variant="body2">{s.signingTeam?.abbreviation}</Typography>
      </Row>
    ),
    export: (s) => s.signingTeam?.abbreviation,
  },
  {
    id: 'contractType',
    Header: <Typography variant="body1">Contract Type</Typography>,
    accessor: (s) => <Typography variant="body2">{s.contractType}</Typography>,
    export: (s) => s.contractType,
  },
  {
    id: 'aav',
    Header: <Typography variant="body1">AAV</Typography>,
    tooltip: 'Average Annual Value (hover for breakdown)',
    accessor: (s) => (
      <Typography variant="body2">
        <TooltipSpan
          content={
            <Container>
              <Typography variant="caption">
                Salary: {formatDollars(s.totalSalary)}
              </Typography>
              <Typography variant="caption">
                Perf: {formatDollars(s.performanceBonus)}
              </Typography>
            </Container>
          }
        >
          {formatDollars(s.aav, { truncate: false })}
        </TooltipSpan>
      </Typography>
    ),
    sortType: sortNumeric,
    export: (s) => s.totalSalary,
  },
  {
    id: 'totalSalary',
    Header: <Typography variant="body1">Tot.</Typography>,
    tooltip: 'Total Salary',
    accessor: (s) => (
      <Typography variant="body2">
        {formatDollars(s.totalSalary, { truncate: true })}
      </Typography>
    ),
    sortType: sortNumeric,
  },
  {
    id: 'performanceBonus',
    Header: <Typography variant="body1">Perf.</Typography>,
    tooltip: 'Performance Bonuses',
    accessor: (s) => (
      <Typography variant="body2">
        {formatDollars(s.performanceBonus, { truncate: true })}
      </Typography>
    ),
    sortType: sortNumeric,
    export: (s) => s.performanceBonus,
  },
  {
    id: 'numYears',
    Header: <Typography variant="body1">Term</Typography>,
    accessor: (s) => <Typography variant="body2">{s.numYears}</Typography>,
    sortType: sortNumeric,
    export: (s) => s.numYears,
  },
  {
    id: 'seasons',
    Header: <Typography variant="body1">Seasons</Typography>,
    accessor: (s) => (
      <Typography variant="body2">
        {seasonToStartYear(s.startSeason)} - {seasonToEndYear(s.endSeason)}
      </Typography>
    ),
  },
  {
    id: 'isExtension',
    Header: <Typography variant="body1">Extension</Typography>,
    accessor: (s) => (
      <Typography variant="body2">{s.isExtension ? 'Yes' : ''}</Typography>
    ),
    export: (s) => (s.isExtension ? 'Yes' : ''),
  },
  {
    id: 'expiryStatus',
    Header: <Typography variant="body1">Expiry Status</Typography>,
    accessor: (s) => <Typography variant="body2">{s.expiryStatus}</Typography>,
    export: (s) => s.expiryStatus,
  },
  {
    id: 'signingStatus',
    Header: <Typography variant="body1">Signing Status</Typography>,
    accessor: (s) => <Typography variant="body2">{s.signingStatus}</Typography>,
    export: (s) => s.signingStatus,
  },
  {
    id: 'platformSeason',
    Header: <Typography variant="body1">Platform Season</Typography>,
    accessor: (s) => (
      <Typography variant="body2">{season8to4(s.platformSeason, true)}</Typography>
    ),
    export: (s) => season8to4(s.platformSeason, true),
  },
  {
    id: 'signingAge',
    Header: <Typography variant="body1">Age</Typography>,
    tooltip: 'Signing Age',
    accessor: (s) => <Typography variant="body2">{roundToX(s.signingAge, 1)}</Typography>,
    sortType: sortNumeric,
    export: (s) => roundToX(s.signingAge, 1),
  },
  {
    id: 'expirationAge',
    Header: <Typography variant="body1">Expiration Age</Typography>,
    accessor: (s) => (
      <Typography variant="body2">{roundToX(s.expirationAge, 1)}</Typography>
    ),
    sortType: sortNumeric,
    export: (s) => roundToX(s.expirationAge, 1),
  },
  {
    id: 'signingBonus',
    Header: <Typography variant="body1">Signing Bonus</Typography>,
    accessor: (s) => (
      <Typography variant="body2">
        {formatDollars(s.signingBonus, { truncate: false })}
      </Typography>
    ),
    sortType: sortNumeric,
    export: (s) => s.signingBonus,
  },
  {
    id: 'hasTradeClause',
    Header: <Typography variant="body1">Trade Clause</Typography>,
    accessor: (s) => (
      <Typography variant="body2">
        {s.hasTradeClause ? s.tradeClauseDetails : ''}
      </Typography>
    ),
    export: (s) => s.hasTradeClause,
  },
  {
    id: 'platformStats-game_type',
    Header: <Typography variant="body1">Platform</Typography>,
    accessor: getPlatformStatAccessor((s) => (
      <>
        {season8to4(s.season)} {gameTypeToString(s.game_type)}
      </>
    )),
    sortType: sortNumeric,
  },
  {
    id: 'platformStats-gamesPlayed',
    Header: <Typography variant="body1">GP</Typography>,
    accessor: getPlatformStatAccessor((s) => s.gamesPlayed),
    sortType: sortNumeric,
  },
  {
    id: 'platformStats-toiPerGP',
    Header: <Typography variant="body1">TOI/GP</Typography>,
    accessor: getPlatformStatAccessor((s) => formatMinutes(s.toiPerGP)),
    sortType: sortNumeric,
  },
  {
    id: 'platformStats-goals',
    Header: <Typography variant="body1">G</Typography>,
    accessor: getPlatformStatAccessor((s) => s.goals),
    sortType: sortNumeric,
  },
  {
    id: 'platformStats-assists',
    Header: <Typography variant="body1">A</Typography>,
    accessor: getPlatformStatAccessor((s) => s.assists),
    sortType: sortNumeric,
  },
  {
    id: 'platformStats-points',
    Header: <Typography variant="body1">P</Typography>,
    accessor: getPlatformStatAccessor((s) => s.points),
    sortType: sortNumeric,
  },
  {
    id: 'similarity',
    Header: <Typography variant="body1">Similarity</Typography>,
    accessor: (s) => s.distance,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => {
      return (
        <Container
          style={{ backgroundColor: getRedGreenColor(1 - s.distance, 0, 1, false, 1.5) }}
        >
          <Typography variant="body2">{roundToX((1 - s.distance) * 100, 2)}</Typography>
        </Container>
      );
    },
    sortType: (a, b, colId) => sortNumeric(b, a, 'distance'),
    export: (s) => roundToX((1 - s.distance) * 100, 2),
  },
];

export const platformStatColumnIds = signingsTableColumns
  .filter((c) => c.id.startsWith('platformStats'))
  .map((c) => c.id);
