import React, { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Button, Col, Modal, Row } from "react-bootstrap"
import update from 'immutability-helper'
import styled from "styled-components"
import { UPDATE_PLAYER_LIST } from "../../apollo/queries/playerlist.queries"
import { SectionHeader, TooltipSpan } from "../reports"
import { BDDLoader } from "../bdd/bddloader"
import BDDApiError from "../bdd/bddapierror"
import BDDSelect from "../bdd/bddselect"
import { useUser } from "../../helpers/user"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { toastBddApiError } from "components/bdd/bddtoasts"

const QUERY_USERS_AND_PLAYER_LIST = gql`query UsersAndPlayerList($id: ID!) {
    playerList(id: $id) {
        id
        name
        isPublic
        isPublicEdit
        accessList {
            userId
            user { id username }
            canEdit
            canView
        }
    }
    users {
        id
        username
    }
}`

const Styles = styled.div`
    .users-tbl {
        text-align: center;
        width: 100%;
        table-layout: fixed;
    }
`
export const UserAccessModal = ({
    show,
    handleClose,
    listId
}) => {
    const { user } = useUser();
    const [completed, setCompleted] = useState(false)
    const { data, loading, error } = useQuery(QUERY_USERS_AND_PLAYER_LIST, { 
        variables: { id: listId },
    })
    const [updatePlayerlist, { loading: updateLoading }] = useMutation(UPDATE_PLAYER_LIST, {
        onError: error => toastBddApiError(error),
        onCompleted: () => {
            setCompleted(true)
            setTimeout(() => handleClose(), 500)
        }
    })
    const [isPublic, setIsPublic] = useState(false)
    const [isPublicEdit, setIsPublicEdit] = useState(false)
    const [users, setUsers] = useState([])
    const [userToAdd, setUserToAdd] = useState(null)

    useEffect(() => {
        if (!!data) {
            setIsPublic(data.playerList.isPublic)
            setIsPublicEdit(data.playerList.isPublicEdit)
            setUsers(data.playerList.accessList)
        }
    }, [data])

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error}/>

    const handleChange = (uid, attr, val) => {
        const idx = users.findIndex(u => u.userId == uid)
        const nextUsers = update(users, {
            [idx]: { [attr]: { $set: val }  }
        })
        setUsers(nextUsers)
    }
    const handleRemoveUser = uid => {
        const idx = users.findIndex(u => u.userId == uid)
        const nextUsers = update(users, {
            $splice: [[idx, 1]]
        })
        setUsers(nextUsers)    
    }

    const handleAddUser = () => {
        setUsers([...users, { userId: userToAdd, canView: true, canEdit: false }])
        setUserToAdd(null)
    }

    const handleSubmit = () => {
        const variables = {
            input: { id: listId }
        }
        if (isPublic != data.playerList.isPublic) {
            variables.input['isPublic'] = isPublic
        }
        if (isPublicEdit != data.playerList.isPublicEdit) {
            variables.input['isPublicEdit'] = isPublicEdit
        }
        variables['setUserAccessList'] = users.map(u => ({ userId: u.userId, canView: u.canView, canEdit: u.canEdit }))
        updatePlayerlist({ variables })
    }

    const idToUser = data.users.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
    }, {})
    
    const usersWithAccess = users.map(u => u.userId)
    const userOptions = data.users.filter(u => u.id != user?.id && usersWithAccess.indexOf(u.id) < 0
        ).map(u => ({ label: u.username, value: u.id }))

    return <Modal
        show={show}
        onHide={handleClose}
    >
        <Modal.Header>
            <SectionHeader>User Access</SectionHeader>
        </Modal.Header>
        <Modal.Body>
        <Styles>
            <Row>
                <Col>
                    <label htmlFor='isPublic'>Is public to view: </label>
                    {' '}
                    <input type='checkbox' id='isPublic'
                        checked={!!isPublic}
                        onChange={ev => setIsPublic(ev.target.checked)}
                    />
                </Col>
                <Col>
                    <label htmlFor='isPublicEdit'>Is public to edit: </label>
                    {' '}
                    <input type='checkbox' id='isPublicEdit'
                        checked={!!isPublicEdit}
                        onChange={ev => setIsPublicEdit(ev.target.checked)}
                    />
                </Col>
            </Row>
            <hr></hr>
            <table className='users-tbl'>
            <tbody>
                <tr>
                    <th width='40%'>User</th>
                    <th style={isPublic ? { background: "#ddd" } : null}>
                        <TooltipSpan content={isPublic ? 'Public to view, user permissions overwritten' : null}>
                            View
                        </TooltipSpan>
                    </th>
                    <th style={isPublicEdit ? { background: "#ddd" } : null}>
                        <TooltipSpan content={isPublicEdit ? 'Public to edit, user permissions overwritten' : null}>
                            Edit
                        </TooltipSpan>
                    </th>
                    <th></th>
                </tr>
                {users.map(u => <tr key={u.userId}>
                    <td>{idToUser[u.userId].username}</td>
                    <td style={isPublic ? { background: "#ddd" } : null}>
                        <input id='canView' name='canView' type='checkbox' 
                            disabled={true}
                            checked={true} 
                            onChange={e => handleChange(u.userId, 'canView', e.target.checked)}
                        />
                    </td>
                    <td style={isPublicEdit ? { background: "#ddd" } : null}>
                        <input id='canEdit' name='canEdit' type='checkbox' 
                            disabled={isPublicEdit}
                            checked={u.canEdit} 
                            onChange={e => handleChange(u.userId, 'canEdit', e.target.checked)}
                        />
                    </td>
                    <td>
                        <a href="" onClick={ev => {
                            ev.preventDefault()
                            handleRemoveUser(u.userId)
                        }}>
                            Remove
                        </a>
                    </td>
                </tr>)}
            </tbody>
            </table>
            <hr></hr>
            <Row>
                <Col sm='8'>
                    <BDDSelect placeholder='Add user...'
                        value={userToAdd}
                        onChange={(n,v) => setUserToAdd(v)}
                        options={userOptions}
                        isSearchable={true}
                    />
                </Col>
                <Col sm='4'>
                    <Button block 
                        disabled={!userToAdd} 
                        variant='outline-primary' 
                        onClick={handleAddUser}
                    >
                        Add
                    </Button>
                </Col>
            </Row>

        </Styles>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={handleClose} variant='secondary'>
                Close
            </Button>
            <Button variant='primary' onClick={handleSubmit}>
                {completed ? <><FontAwesomeIcon icon={faCheck}/> Submitted</>
                : updateLoading ? 'Submitting...'
                : 'Submit'}
            </Button>
        </Modal.Footer>
    </Modal>
}