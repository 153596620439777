import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FULL_SCOUTED_RANKING_SET, GET_SCOUTED_PLAYER_RANKINGS, LIST_SCOUTING_RATINGS } from '../../../apollo/queries/scouting.queries'
import BDDPlotly from '../../bdd/bddplotly'
import { BDDLoader } from '../../bdd/bddloader'
import BDDApiError from '../../bdd/bddapierror'
import { numberToOrdinal } from '../../../helpers/helpers'
import { bruinsBlack } from '../../../helpers/plotting'
import { SectionHeader, SubSectionHeader, TooltipSpan } from '../../reports'
import BDDLinkButton from '../../bdd/bddlinkbutton'
import { Button, Container, Modal, Row } from 'react-bootstrap'

import BDDSelect from '../../bdd/bddselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ChooseSplitTable } from '../AggregatedRatings/choosesplittable'
import RankingsTable from '../AggregatedRatings/rankingstable'


const posToRatings = position => position === 'f' ? [
        'skating', 
        'hockeySense', 
        'scoring', 
        'intensity',
        'puckHandling',
        'creativity'
    ] : position === 'd' ? [
        'skating', 
        'hockeySense', 
        'scoring', 
        'intensity',
        'puckHandling',
        'passing'
    ] : position === 'g' ? [
        'positioning',
        'reboundControl',
        'postTracking',
        'trackingPucks',
        'handsGloveBlocker'
    ] : [
        'skating'
    ]

export default function SkillRatingsViz({ 
    rinknetId,
    scoutId,
    position=null, 
    height='150px', 
    author,
    showOptions=true
}) {
    const [showModal, setShowModal] = useState(false)
    const [showRankingsModal, setShowRankingsModal] = useState(false)
    const [splitId, setSplitId] = useState(null)

    // Define variables for request. Use split if we've selected one
    var variables = !!splitId ? { splitId } : { useDefaultSplit: true }
    variables.rinknetId = rinknetId
    variables.scoutId = scoutId
    const { data, loading, error } = useQuery(GET_SCOUTED_PLAYER_RANKINGS, {
        variables
    })
    const rankings = !!data ? data.scoutedPlayerRankings.rankings : []

    const handleSetSplitId = sid => {
        // function called with new split to use for building plot
        setSplitId(sid)
        setShowModal(false)
    }
    
    const ratingToRank = useMemo(() => {
        if (!!data) {
            return rankings.reduce((acc, curr) => ({ ...acc, [curr.rating.slug]: curr }), {})
        }
    }, [data])

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error} />

    if (!position && rankings.length > 0) {
        position = rankings[0].rinknetPlayer.posFDG.toLocaleLowerCase()
    }

    const ratingsToPlot = posToRatings(position)
    
    var x = []
    var y = []
    var labels = []
    var customdata = []
    ratingsToPlot.forEach(r => {
        if (r in ratingToRank) {
            const rank = ratingToRank[r]
            const pctile = 100 * (1-rank.percentile)
            x.push(rank.rating.shortName)
            y.push(pctile)
            labels.push(pctile.toFixed(0))
            // labels.push(`${numberToOrdinal(rank.rank)}`)
            customdata.push(`<b>${rank.rating.primaryName}</b>` + 
                `<br>${numberToOrdinal(parseInt(pctile))} percentile` +
                `<br>${numberToOrdinal(rank.rank)} out of ${rank.total}` +
                `<br><i>Based on ${rank.count} reports</i>`)
        }
    })
    const pdata = [{
        type: 'bar',
        x, 
        y,
        text: labels,
        textposition: 'auto',
        customdata,
        hovertemplate: "%{customdata}<extra></extra>",
        marker: { color: bruinsBlack }
    }]

    // this is the split in the current data, not to be confused with the split to request
    const currentSplit = data.scoutedPlayerRankings.split
    const totalPlayers = data.scoutedPlayerRankings.totalPlayers
    const rp = data.scoutedPlayerRankings.rinknetPlayer
    const playerName = `${rp.firstname} ${rp.lastname}`
    return <>
        <center><SubSectionHeader>{author}{!!author && "'s"} Skill Ratings</SubSectionHeader></center>
        <div style={{ textAlign: 'center', fontSize: '0.7em' }}>
            <div>
                {!!currentSplit ? <>
                    Ranked against {totalPlayers} other
                    {' '}
                    {!!currentSplit.league ? <TooltipSpan content={currentSplit.league}>
                        {currentSplit.league.split(' ').map(word => word[0])}
                    </TooltipSpan>
                    : !!currentSplit.leagueGroup ? currentSplit.leagueGroup
                    : null}
                    {' '}
                    {currentSplit.isDraftEligible ? 'draft elig.' : ''}
                    {' '}
                    {!!currentSplit.position ? `${currentSplit.position.toLocaleUpperCase()}s` : 'players'} 
                    {' '}
                    {currentSplit.timeRange == 'PAST_YEAR' ? 'from past year' : null} 
                    {' '}
                    {!!currentSplit.scoutName && `based on ${currentSplit.scoutName}'s views`}
                </> : <em>No data found</em>}
            </div>
            {showOptions && <div>
                <BDDLinkButton color='#007BFF' onClick={() => setShowModal(true)}>
                    Change comp group
                </BDDLinkButton>
                {' '}|{' '}
                <BDDLinkButton color='#007BFF' onClick={() => setShowRankingsModal(true)}>
                    See full list
                </BDDLinkButton>
            </div>}
        </div>
        <BDDPlotly
            data={pdata}
            layout={{
                xaxis: { fixedrange: true },
                yaxis: { fixedrange: true, range: [0, 100] },
                margin: { t: 10, r: 10, l: 20, b: 20 }
            }}
            style={{ height }}
        /> 
        <ChooseSplitModal 
            show={showModal} 
            handleClose={() => setShowModal(false)} 
            splits={data.scoutedPlayerRankings.otherValidSplits}
            playerName={playerName}
            handleSetSplitId={handleSetSplitId}
            currentSplitId={currentSplit.id}
        />
        {showRankingsModal && <FullRankingsModal
            show={showRankingsModal}
            handleClose={() => setShowRankingsModal(false)}
            currentSplitId={currentSplit.id}
            handleSetSplitId={handleSetSplitId}
            position={position}
        />}
    </>
}


const ChooseSplitModal = ({ show, handleClose, playerName, splits, handleSetSplitId, currentSplitId }) => {

    return <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton><SectionHeader>Valid Splits for {playerName}</SectionHeader></Modal.Header>
        <Modal.Body>
            <ChooseSplitTable showScout splits={splits} currentSplitId={currentSplitId} handleSetSplitId={handleSetSplitId}/>
        </Modal.Body>
    </Modal>
}

const FullRankingsModal = ({ show, handleClose, currentSplitId, handleSetSplitId }) => {
    return <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton><SectionHeader>Rankings List</SectionHeader></Modal.Header>
        <Modal.Body>
            <div>
                <div>
                    <ChooseSplitTable 
                        showScout
                        currentSplitId={currentSplitId}
                        handleSetSplitId={handleSetSplitId}
                        maxHeight='200px'
                    />
                </div>
                <hr></hr>
                <div>
                    {!!currentSplitId ? <RankingsTable splitId={currentSplitId} handleSetSplitId={handleSetSplitId}/>
                    : <center><b>Select a split above</b></center>}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={() => handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
}
