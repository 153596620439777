import { useMutation } from '@apollo/client';
import {
  GET_PAGINATED_NOTIFICATIONS,
  UPDATE_BDD_NOTIFICATION,
} from 'apollo/queries/notification.queries';
import { toastBddApiError } from 'components/bdd/bddtoasts';

export const useEditNotification = ({ onCompleted }) => {
  return useMutation(UPDATE_BDD_NOTIFICATION, {
    onError: (error) => toastBddApiError(error),
    onCompleted,
    refetchQueries: [GET_PAGINATED_NOTIFICATIONS],
  });
};
