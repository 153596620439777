import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup"
import { useMutation } from '@apollo/client'

import { Row, Col, Button } from 'react-bootstrap'
import BDDSelectField from '../form/bddselectfield'
import BDDInputField from '../form/bddinputfield'
import BDDFormError from "../bdd/bddformerror"
import { BDDInput } from '../bdd'
import { CREATE_BDD_TAG_REQUEST } from '../../apollo/queries/bddtags.queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import BDDTag from './bddtag'
import { toastBddApiError } from '../bdd/bddtoasts'
import StillBuilding from '../bdd/stillbuilding'

const Styles = styled.div`
    .row {
        margin-top: 10px;
    }
    label {
        font-weight: bold;
        margin-right: 10px;
    }
`
const MAX_TAG_LENGTH = 30
export default function RequestPlayerTagForm({
    bddPlayerSlug,
    onCompleted
}) {
    const [completed, setCompleted] = useState(false)
    const [createBddTagRequest] = useMutation(CREATE_BDD_TAG_REQUEST, {
        // update: (cache, { data: { createBddTag: { bddTag }}}) => {
        //     cache.modify({
        //         fields: {
        //             bddTags(existing=[]) {
        //                 return [bddTag].concat(existing)
        //             }
        //         }
        //     })
        // },
        onCompleted: () => {
            setCompleted(true)
            if (onCompleted) onCompleted()
        },
        onError: toastBddApiError
    })

    const schema = Yup.object({
        slug: Yup.string().required('You must supply a player'),
        tagsToAdd: Yup.array().of(Yup.number()),
        tagsToRemove: Yup.array().of(Yup.number()),
        notes: Yup.string(),
    })

    const initialValues = {
        slug: bddPlayerSlug,
        tagsToAdd: [],
        tagsToRemove: [],
        notes: ''
    }

    const handleSubmit = values => {
        console.log(values)
        createBddTagRequest({ variables: {
            input: values
        }})
    }

    return <Styles>
        <StillBuilding/>
        {/* <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={schema}
        > 
            {({ values, handleChange, isSubmitting }) => {
                return <Form>
                <Row>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field type='textarea' 
                            name="notes"
                            placeholder='Notes...'
                            component={BDDInputField}
                        /> 
                    </Col>
                </Row>
                <Row>
                    <Col md='auto' className='ml-auto'>
                        <Button variant='primary' type="submit">
                            {completed ? <><FontAwesomeIcon icon={faCheck}/> Submitted</>
                            : isSubmitting ? 'Submitting...'
                            : 'Submit' }
                        </Button>
                    </Col>
                </Row>
                </Form>
            }}
        </Formik> */}
    </Styles>
}