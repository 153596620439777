import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { CommentForm, CommentReadonlyForm } from 'components/bdd/TextEditor';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import { useEffect, useState } from 'react';
import {
  getPermissions,
  getPermissionValue,
  permissionOptions,
} from 'components/PlayerNotes/helpers';
import { getMentionEntities } from 'components/bdd/TextEditor/components/Mentions';
import { buildNoteHeader } from './helpers';
import { isFormPersisted } from 'components/bdd/Form';

export const NoteCommentForm = ({
  entityId,
  entityType,
  entityName,
  subEntityId,
  subEntityType,
  existingNote,
  formData,
  onSubmit,
  onCancel,
  hidePinToggle,
  disableAutoFocus,
  noteType = 'GENERAL',
  defaultEditMode,
  variant,
}) => {
  const { toggleComponent, toggled: isPinned } = useToggle({
    label: 'Pin note to the overview page',
    initialToggled: false,
  });

  const [editMode, setEditMode] = useState(defaultEditMode);
  useEffect(() => {
    setEditMode(defaultEditMode);
  }, [defaultEditMode]);

  const header = buildNoteHeader(existingNote);
  const persistKey = existingNote && `note-${existingNote.id}`;
  const requiresPermission = {
    options: permissionOptions,
  };

  const handleSubmit = (values) => {
    onSubmit({
      id: existingNote?.id,
      noteType: noteType,
      subEntityId,
      subEntityType,
      entityId: entityId,
      entityType: entityType,
      note: !!values.comment
        ? JSON.stringify({ ...values, files: null, userAccess: null, mentions: null })
        : null,
      files: values.files?.map((file) => ({
        name: file.name,
        url: file.s3Key ? null : file.url,
        s3Key: file.s3Key,
      })),
      mentions: getMentionEntities(values.comment),
      isArchived: values.isArchived,
      isPinned: values.isPinned,
      ...getPermissions(values.requiresPermission),
      userAccess: values.userAccess,
    });

    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    !!onCancel && onCancel();
  };

  if (!existingNote) {
    return (
      <Column gap={2}>
        {/* {!hidePinToggle && <Row justifyContent="end">{toggleComponent}</Row>} */}
        <Border>
          <CommentForm
            requiresPermission={requiresPermission}
            onSubmit={(values) => handleSubmit({ ...values, isPinned })}
            onCancel={handleCancel}
            placeholder={'Leave a note'}
            extraForm={{
              initialValues: formData?.getInitialValues(),
              ...formData,
            }}
            includeFileUpload
            disableAutoFocus={disableAutoFocus}
          />
        </Border>
      </Column>
    );
  }

  const existingNoteData = JSON.parse(existingNote.note);
  requiresPermission.value = getPermissionValue(existingNote);

  if (editMode) {
    return (
      <CommentForm
        existingNote={existingNoteData}
        persistKey={persistKey}
        requiresPermission={requiresPermission}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        placeholder={'Leave a note'}
        extraForm={{
          initialValues: formData?.getInitialValues(existingNoteData),
          ...formData,
        }}
        files={existingNote.files}
        userAccess={existingNote.userAccess}
        includeFileUpload
      />
    );
  }

  const formComponents = formData && (
    <Container paddingLeft={3} padding={2}>
      <Typography variant="stat">
        <Row columnGap={2} flexWrap>
          {formData.components.map((c) => (
            <Row key={`readonly-c-${c.title}`} columnGap={1}>
              {c.title}
              {c.getValue(existingNoteData)}
            </Row>
          ))}
        </Row>
      </Typography>
    </Container>
  );

  return (
    <CommentReadonlyForm
      userId={existingNote.user.id}
      header={header}
      formComponents={formComponents}
      comment={existingNoteData.comment}
      isPinned={existingNote.isPinned}
      files={existingNote.files}
      onEdit={() => setEditMode(true)}
      requiresPermission={requiresPermission}
      onArchive={() => handleSubmit({ isArchived: true })}
      onPin={() => handleSubmit({ isPinned: !existingNote.isPinned })}
      userAccess={existingNote.userAccess}
      hasUnsavedChanges={isFormPersisted(persistKey)}
      variant={variant}
      invalid={existingNote.isEdited || existingNote.isArchived}
    />
  );
};
