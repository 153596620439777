import styled from 'styled-components';
import { theme } from 'constants/theme';

export const Typography = styled.div(
  ({
    variant,
    textAlign,
    noUserSelect,
    color,
    state,
    textDecoration,
    noWrap,
    disabled,
  }) => ({
    ...theme.typography[variant],
    ...(!!color ? { color } : {}), // dont overwrite color unless specified
    ...(!!state ? { color: theme.colors.states[state] } : {}), // dont overwrite color unless specified
    marginBottom: 0,
    textAlign: textAlign || 'start',
    userSelect: noUserSelect ? 'none' : null,
    whiteSpace: noWrap ? 'nowrap' : null,
    textDecoration,
    ...(disabled ? { color: theme.colors.light.text.disabled } : {}),
  })
);
