import { useQuery } from '@apollo/client';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_BDD_TAGS_FOR_PLAYER } from 'apollo/queries/bddtags.queries';
import { HoverInfo, LinkUnstyled, OverflowEllipsisCol } from 'components/bdd';
import BDDDropdownMenu from 'components/bdd/bdddropdownmenu';
import { IconButton, LinkButton } from 'components/bdd/Button';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HighlightBox } from 'components/bdd/warcomponents';
import BFPlayerContractsSmall from 'components/PlayersV2/Contract/BFPlayerContractsSmall';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import PlayerWarBoxes from 'components/PlayersV2/PlayerWar/playerwarboxes';
import BDDTag from 'components/PlayerTagging/bddtag';
import { formatMinutes } from 'helpers/helpers';
import { positionToAbbrev } from 'helpers/hockeyutils';
import { buildPlayerRoute } from 'helpers/routing';
import { getRedGreenColor } from 'helpers/tables';
import { PencilSquare } from 'react-bootstrap-icons';
import styled from 'styled-components';
import DCPlayerSLData from './DCPlayerSLData';
import { VerticalDivider } from 'components/bdd/Divider';

const PTable = styled.table({
  width: '100% !important',
  maxWidth: '100% !important',
  textAlign: 'center',
  tableLayout: 'fixed',
  '.subheader': {
    fontSize: '0.9em',
  },
  '.small-info': {
    fontSize: '0.6em',
  },
  '.side-info': {
    width: '17%',
    maxWidth: '150px',
  },
  '.nooverflow': {
    overflow: 'hidden',
  },
});
export const DCPlayerLarge = ({
  playerSlug,
  playerSlugs,
  bddPlayer,
  rinknetPlayer,
  lineupSpot,
  editMode,
  showEditMenu = true,
  playerRenderMode,
  dropdownOptions,
  playerToSlData,
  isDragging,
}) => {
  // Read BDD Tags from cache (in case they change), but grab initially from the player
  const { data } = useQuery(GET_BDD_TAGS_FOR_PLAYER, {
    variables: { slug: playerSlug },
    fetchPolicy: 'cache-only',
  });
  const managedTags =
    !!data && !!data.bddPlayer
      ? data.bddPlayer.bddManagedTags
      : !!bddPlayer
      ? bddPlayer.bddManagedTags
      : [];
  const roleTagFilt = !!managedTags
    ? managedTags.filter((t) => t.tag.tagType === 'ROLE')
    : [];
  const nonRoleTags = !!managedTags
    ? managedTags.filter((t) => t.tag.tagType !== 'ROLE')
    : [];
  const roleTag = roleTagFilt.length === 1 ? roleTagFilt[0] : null;
  if (!bddPlayer) {
    console.warn('Unknown player');
    return null;
  }

  return (
    <Container>
      <PTable>
        <tbody>
          <tr>
            <td className="side-info" />
            <td>
              <Container paddingTop={1} height={18}>
                <Row columnGap={1} justifyContent="center">
                  <Typography variant="body1" textAlign="center">
                    <LinkUnstyled to={buildPlayerRoute(bddPlayer.slug)}>
                      <PlayerHover playerSlug={bddPlayer.slug} disabled={isDragging}>
                        {bddPlayer.firstname} {bddPlayer.lastname}
                      </PlayerHover>
                    </LinkUnstyled>
                  </Typography>
                  {showEditMenu && (
                    <div className="icon">
                      <BDDDropdownMenu
                        trigger={
                          <IconButton
                            icon={<PencilSquare />}
                            color="#ddd"
                            hoverColor="#333"
                          />
                        }
                        items={dropdownOptions}
                      />
                    </div>
                  )}
                </Row>
              </Container>
            </td>
            <td rowSpan={21} className="side-info nooverflow">
              {/* If we have <= 2 nonRoleTags stack them, if more than 2, need a hover link */}
              {nonRoleTags.length <= 2 ? (
                nonRoleTags.map((t) => (
                  <div key={t.id}>
                    <BDDTag isProjection={t.isProjection} tag={t.tag} />
                  </div>
                ))
              ) : (
                <>
                  <div>
                    <BDDTag
                      isProjection={nonRoleTags[0].isProjection}
                      tag={nonRoleTags[0].tag}
                    />
                  </div>
                  <HoverInfo
                    content={
                      <center>
                        {nonRoleTags.slice(1).map((t) => (
                          <div key={t.id}>
                            <BDDTag isProjection={t.isProjection} tag={t.tag} />
                          </div>
                        ))}
                      </center>
                    }
                  >
                    <LinkButton style={{ whiteSpace: 'nowrap' }}>
                      {nonRoleTags.length - 1} more
                    </LinkButton>
                  </HoverInfo>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <OverflowEllipsisCol>
                {playerRenderMode === 'info' ? (
                  <Row justifyContent="center" columnGap={1}>
                    <Typography variant="body2" color="#555">
                      {!!rinknetPlayer?.position &&
                        positionToAbbrev(rinknetPlayer.position)}
                    </Typography>
                    <Typography variant="body2">|</Typography>
                    <Typography variant="body2" color="#555">
                      {!!rinknetPlayer.jerseynumber && `#${rinknetPlayer.jerseynumber}`}
                    </Typography>
                    {!!rinknetPlayer.jerseynumber && (
                      <Typography variant="body2">|</Typography>
                    )}
                    <Typography variant="body2">
                      <BFPlayerContractsSmall
                        name={`${bddPlayer.firstname} ${bddPlayer.lastname}`}
                        bfCapHit={bddPlayer.bfCapHit}
                        bfPlayerSlug={bddPlayer.bfSlug}
                      />
                    </Typography>
                    {!!roleTag && <Typography variant="body2">|</Typography>}
                    {roleTag ? (
                      <BDDTag isProjection={roleTag.isProjection} tag={roleTag.tag} />
                    ) : (
                      <Container width={30} />
                    )}
                  </Row>
                ) : playerRenderMode === 'warOvr' ? (
                  <>
                    {!!bddPlayer && (
                      <PlayerWarBoxes
                        slUid={bddPlayer.slUid}
                        showHeader={false}
                        showSplitOptions={false}
                        statTypes={['totalImpact55', 'totalImpactPP', 'totalImpactSH']}
                        fontSize="10px"
                      />
                    )}
                  </>
                ) : playerRenderMode === 'war55' ? (
                  <>
                    {!!bddPlayer && (
                      <PlayerWarBoxes
                        slUid={bddPlayer.slUid}
                        showHeader={false}
                        showSplitOptions={false}
                        statTypes={[
                          'offImpactPct55',
                          'defImpactPct55',
                          'shotImpactPct55',
                        ]}
                        fontSize="10px"
                      />
                    )}
                  </>
                ) : (
                  playerRenderMode === 'usage' && (
                    <DCPlayerSLData playerSlug={playerSlug} playerSlugs={playerSlugs} />
                  )
                )}
              </OverflowEllipsisCol>
            </td>
          </tr>
        </tbody>
      </PTable>
    </Container>
  );
};
