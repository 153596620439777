import { useContext } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from 'constants';
import { VideoContext } from '../..';

const DropdownStyles = styled.div({
  '.dropdown-toggle::after': {
    display: 'none',
  },
});

export const ColumnDropdownFilter = ({ id, col, label, options }) => {
  const { filters, setEventFilters } = useContext(EventFilterContext);
  const filter = filters[id];

  const handleChange = (val) => {
    setEventFilters({
      [id]: {
        id,
        col,
        val,
      },
    });
  };

  if (!options || options.length == 0) return null;

  return (
    <DropdownFilter
      value={filter?.val}
      label={label}
      options={options}
      onChange={handleChange}
    />
  );
};

export const DropdownFilter = ({ label, value, options, onChange }) => {
  const valueLabel =
    options.find((o) => o.value == value)?.label || options[0].label;

  return (
    <DropdownStyles>
      <Dropdown>
        <Border borderRadius={1}>
          <Dropdown.Toggle variant="outline-neutral" size="sm">
            <Row columnGap={2} justifyContent="space-between">
              <Row columnGap={1} flexWrap>
                <Container padding={1}>
                  <Typography variant="stat">
                    <b>{label}:</b>
                  </Typography>
                </Container>
                <Container
                  padding={1}
                  backgroundColor={theme.colors.light.secondary}
                >
                  <Typography variant="stat" noUserSelect>
                    {valueLabel}
                  </Typography>
                </Container>
              </Row>
              <Icon icon={<ChevronDown />} />
            </Row>
          </Dropdown.Toggle>
        </Border>
        <Dropdown.Menu>
          <Container>
            <Column>
              {options.map((o) => (
                <Dropdown.Item
                  key={`cb-${o.value}`}
                  onClick={() => onChange(o.value)}
                >
                  <Typography variant="body2" noUserSelect>
                    {o.label}
                  </Typography>
                </Dropdown.Item>
              ))}
            </Column>
          </Container>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownStyles>
  );
};
