import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons';

import { theme } from 'constants';
import useInputRange from 'components/bdd/Range/useInputRange';
import { getGradeOptions, nameToOptions } from '../playerformhelpers';

const Sublabel = styled.div({
  ...theme.typography.body2,
  paddingLeft: theme.spacing[2],
});

export const useScoutingGradeFilter = ({ posFDG }) => {
  if (!posFDG) return {};

  const [sorted, setSorted] = useState(null);
  const gradeOptions = getGradeOptions(posFDG);

  // Build filter components with sortable labels
  const filterComponents = gradeOptions.map((grade) => {
    const gradeFilterOptions = nameToOptions(grade.value, posFDG.toLowerCase());

    const scale = {
      min: Math.min(...gradeFilterOptions.map((option) => option.value)),
      max: Math.max(...gradeFilterOptions.map((option) => option.value)),
    };

    const { value, inputRange, inputChanged, resetInputRange } = useInputRange({
      key: 'input-range-' + grade.label,
      ...scale,
      label: (
        <>
          {sorted?.label == grade.label && (
            <>
              {sorted.direction == 'asc' && <ArrowUpShort size={24} />}
              {sorted.direction == 'desc' && <ArrowDownShort size={24} />}
            </>
          )}
          {grade.label}
          {sorted?.label != grade.label && <Sublabel>(Click to Sort)</Sublabel>}
        </>
      ),
      formatLabel: (value, type='value') =>
        type == 'value'
          ? gradeFilterOptions.find((option) => option.value == value).label
          : null,
      onLabelSelected: () => {
        sorted?.label == grade.label && sorted.direction == 'desc'
          ? setSorted(null)
          : setSorted({
              label: grade.label,
              direction: !sorted ? 'asc' : 'desc',
              grade: grade,
            });
      },
    });

    return {
      grade,
      value,
      inputRange,
      inputChanged,
      resetInputRange,
    };
  });

  const sortAndFilterReports = (reports) => {
    const getRating = (report, grade) => {
      const ratings = JSON.parse(
        grade.skillCategory ? report.skillcategoryratings : report.ratings
      );

      const reportRating =
        ratings &&
        ratings.length &&
        ratings.find(
          (r) => grade.ratings.includes(r.Type) || grade.ratings.includes(r.Type.Name)
        );

      return reportRating && parseInt(reportRating.Rating.Name);
    };

    const filteredReports = reports.filter((report) => {
      return filterComponents.every((fc) => {
        if (!fc.inputChanged) {
          return true;
        }

        const filterValue = getRating(report, fc.grade);

        return filterValue >= fc.value.min && filterValue <= fc.value.max;
      });
    });

    let sortedReports = sorted
      ? filteredReports.sort(
          (a, b) => getRating(a, sorted.grade) - getRating(b, sorted.grade)
        )
      : filteredReports;

    if (sorted?.direction == 'desc') {
      sortedReports = sortedReports.reverse();
    }

    return sortedReports;
  };

  const resetFilters = () => {
    filterComponents.forEach((fc) => fc.resetInputRange());
    setSorted(null);
  };

  return {
    sortAndFilterReports,
    inputFilters: filterComponents.map((filter) => filter.inputRange),
    filtersChanged: filterComponents.some((fc) => fc.inputChanged) || sorted,
    resetFilters,
  };
};
