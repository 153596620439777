import { gql } from '@apollo/client';

export const BDDLeagueFragment = gql`
  fragment bddLeagueFragment on BDDLeague {
    slug
    name
    shorthand
    country
    level
  }
`;

export const BDDTeamFragment = gql`
  fragment bddTeamFragment on BDDTeam {
    id
    longname
    shorthand
    nhlid
    epTeamId
    rinknetTeamId
    slTeamSlug
    cfTeamSlug
    bfTeamSlug
  }
`;

export const GET_BDD_LEAGUES = gql`
  query GetBDDLeagues($withSlGames: Boolean, $withPriority: Boolean) {
    bddLeagues(withSlGames: $withSlGames, withPriority: $withPriority) {
      ...bddLeagueFragment
    }
  }
  ${BDDLeagueFragment}
`;

export const GET_BDD_TEAMS = gql`
  query GetBDDTeams($bddLeagueSlug: ID!) {
    bddTeams(bddLeagueSlug: $bddLeagueSlug) {
      ...bddTeamFragment
    }
  }
  ${BDDTeamFragment}
`;

export const GET_BDD_GAMES = gql`
  query GetBDDGames($gameIds: [ID]!) {
    bddTeams(gameIds: $gameIds) {
      ...bddTeamFragment
    }
  }
  ${BDDTeamFragment}
`;
