import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';
import { CurrencyDollar } from 'react-bootstrap-icons';

export const BFPerformanceBonusTag = ({ contract, ...tagProps }) => {
  if (!contract || !contract.performanceBonus || contract.performanceBonus == 0) {
    return null;
  }

  return (
    <BDDTag
      {...tagProps}
      tag={{
        color: theme.colors.states.warning,
        textColor: theme.colors.light.text.primary,
        tag: (
          <Typography variant="body1">
            <Icon color={theme.colors.light.text.primary} icon={<CurrencyDollar />} />
          </Typography>
        ),
        description: 'Performance Bonuses',
      }}
    />
  );
};
