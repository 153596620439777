import {
  Calendar,
  ExclamationTriangle,
  List,
  People,
  Person,
  Shield,
  Star,
} from 'react-bootstrap-icons';
import { useFilterContext } from './context';
import { Field } from './Field';
import { Border, Container, Row } from '../Layout';
import { LinkPopover } from '../Popover';
import Icon from '../Icon';

export const DEFAULT_ICONS = {
  Calendar: <Calendar />,
  ExclamationTriangle: <ExclamationTriangle />,
  List: <List />,
  People: <People />,
  Person: <Person />,
  Star: <Star />,
  Shield: <Shield />
};

export const IconFilter = ({ id, label, onChange, ...fieldProps }) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);
  const value = filter?.value ?? 'Calendar';

  return (
    <Field id={id} onChange={onChange} label={label} {...fieldProps}>
      <LinkPopover
        hideTransition
        renderLink={(setShow, target) => (
          <Container
            ref={target}
            onClick={(e) => {
              e.stopPropagation();
              setShow((show) => !show);
            }}
          >
            <IconBox iconKey={value} icon={DEFAULT_ICONS[value]} onChange={onChange} />
          </Container>
        )}
        renderContent={(close) => (
          <IconPicker
            onChange={(icon) => {
              setFilters(id, { id, value: icon, cond: '=' });
              onChange && onChange(icon);
              close();
            }}
          />
        )}
      />
    </Field>
  );
};

const IconPicker = ({ onChange }) => {
  return (
    <Container>
      <Row columnGap={2} rowGap={1} flexWrap>
        {Object.entries(DEFAULT_ICONS).map(([iconKey, icon]) => (
          <IconBox key={iconKey} icon={icon} iconKey={iconKey} onChange={onChange} />
        ))}
      </Row>
    </Container>
  );
};

const IconBox = ({ iconKey, icon, onChange }) => (
  <Border
    borderRadius={1}
    onClick={() => onChange && onChange(iconKey)}
    style={{ overflow: 'hidden' }}
  >
    <Container height={28} width={28} variant="button">
      <Icon padding={7} icon={icon} />
    </Container>
  </Border>
);
