import { useContext, useEffect, useState } from 'react';
import { Camera, ChevronDown, ChevronUp } from 'react-bootstrap-icons';

import { theme } from 'constants';
import useToggle from 'components/bdd/Toggle/useToggle';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import produce from 'immer';
import { VideoContext } from '../..';
import { IconButton, LinkButton } from 'components/bdd/Button';
import { Divider } from 'components/bdd/Divider';
import { EventFilterContext } from '.';

const DropdownStyles = styled.div({
  '.dropdown-toggle::after': {
    display: 'none',
  },
});

const CheckboxValueTag = ({ label }) => (
  <Container padding={1} backgroundColor={theme.colors.light.secondary}>
    <Typography variant="stat">{label}</Typography>
  </Container>
);

export const CheckboxGroupFilter = ({
  id,
  col,
  condAny = 'includes_any',
  condAll = 'includes_all',
  label,
  options,
}) => {
  const [showCheckboxGroup, setShowCheckboxGroup] = useState(false);
  const { toggled, toggleComponent } = useToggle({
    label: 'Event must include all',
  });

  const [includeAll, setIncludeAll] = useState(false);

  const { filters, setEventFilters } = useContext(EventFilterContext);
  const filter = filters[id];

  const handleChange = (value) => {
    const newFilterValues = !filter
      ? [value]
      : filter?.val?.find((fv) => fv == value)
      ? filter.val?.filter((fv) => fv != value)
      : [...(filter.val || []), value];

    setEventFilters({
      [id]: {
        id,
        col,
        val: newFilterValues.length == 0 ? null : newFilterValues,
        cond: includeAll ? condAll : condAny,
      },
    });
  };

  if (!options || options.length == 0) return null;
  // if (readonly && !filter?.val) return null;

  return (
    <DropdownStyles>
      <Dropdown>
        <Border borderRadius={1}>
          <Dropdown.Toggle variant="outline-neutral" size="sm">
            <Row columnGap={2} justifyContent="space-between">
              <Row columnGap={1} flexWrap>
                <Container padding={1}>
                  <Typography variant="stat">
                    <Row justifyContent="space-between">
                      <b>{label}:</b>
                    </Row>
                  </Typography>
                </Container>
                {filter?.val?.map((value) => {
                  const label = options.find((o) => o.value == value)?.label;

                  return (
                    label && (
                      <CheckboxValueTag
                        key={`cvt-${label}`}
                        label={options.find((o) => o.value == value)?.label}
                      />
                    )
                  );
                })}
              </Row>
              <Icon icon={showCheckboxGroup ? <ChevronUp /> : <ChevronDown />} />
            </Row>
          </Dropdown.Toggle>
        </Border>
        <Dropdown.Menu>
          <Container paddingLeft={3} width={250} maxHeight={400} overflowY="auto">
            <Column gap={1}>
              <label style={{ margin: 0 }}>
                <Row columnGap={2}>
                  <input
                    type="checkbox"
                    checked={includeAll || false}
                    onChange={() => {
                      setIncludeAll(!includeAll);

                      setEventFilters({
                        [id]: {
                          id,
                          col,
                          val: filter.val,
                          cond: includeAll ? condAny : condAll,
                        },
                      });
                    }}
                  />
                  <Typography variant="body2" noUserSelect>
                    Event must include all
                  </Typography>
                </Row>
              </label>
              <Divider />
              <Column>
                {options.map((o) => (
                  <label key={`cb-${o.value}`} style={{ margin: 0 }}>
                    <Row columnGap={2} alignItems={'baseline'}>
                      <input
                        type="checkbox"
                        checked={filter?.val?.find((fv) => o.value == fv) || false}
                        onChange={() => {
                          handleChange(o.value);
                        }}
                      />
                      <Typography variant="body2" noUserSelect>
                        {o.label}
                      </Typography>
                    </Row>
                  </label>
                ))}
              </Column>
            </Column>
          </Container>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownStyles>
  );
};
