import { gql } from "@apollo/client"

export const NHLPlayerStatsFragment = gql`fragment nhlPlayerStatsFragment on NHLPlayerStats {
  id
  fullName
  firstname
  lastname
  primaryNumber
  birthDate
  birthCity
  birthCountry
  nationality
  height
  heightInches
  weight
  active
  shootsCatches
  currentTeam
  currentTeamNhlid
  primaryPosition
  primaryPositionFdg
  stats
  nhlCareerTotals
  playerSlug
  teamLogo
  last5Games
  draftDetails
  awards
  headshot
  heroImage
}`

export const GET_NHL_STANDINGS = gql`query GetNHLStandings {
    nhlStandings
}`

export const GET_PLAYER_CAREER_NHL_DATA = gql`
  query GetPlayerCareerNHLData($slug: ID!) {
    bddPlayer(slug: $slug) {
      slug
      rinknetId
      rinknetPlayer {
        eliteprospectsid
        position
      }
      nhlPlayerStats {
        ...nhlPlayerStatsFragment
      }
    }
  }
${NHLPlayerStatsFragment}
`