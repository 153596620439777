import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import regression from 'regression';

import { theme } from 'constants';
import BDDPlotly from 'components/bdd/bddplotly';
import BDDSelect from 'components/bdd/bddselect';
import { isTargetGroup, nameToLastName } from 'helpers/hockeyutils';
import styled from 'styled-components';
import { useSelect } from 'components/bdd/Select';

const AxisGroup = styled(Col)`
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #ddd;
  margin-bottom: 5px;
`;

const SelectContainer = styled.div({
  marginTop: theme.spacing[3],
  marginBottom: theme.spacing[3],
});

const SKIP_INDEX_COLUMNS = ['player'];
export default function StatScatterChart({
  target = 'player',
  index,
  statDefinitions,
  data,
  metastats,
}) {
  const [xCol, setXCol] = useState(null);
  const [yCol, setYCol] = useState(null);
  const [showLabels, setShowLabels] = useState(false);
  const [showStddevs, setShowStddevs] = useState(false);
  const [showMeans, setShowMeans] = useState(true);
  const [showBestFit, setShowBestFit] = useState(false);
  const [invertAxis, setInvertAxis] = useState({ x: false, y: false });
  const columnOptions = index
    .filter((i) => !SKIP_INDEX_COLUMNS.includes(i))
    .map((i) => ({ label: i, value: i }))
    .concat(statDefinitions.map((s) => ({ label: s.label, value: s.slug })));

  const highlightOptions = data.map((d) => ({
    label: d['player'],
    value: d.player_id,
  }));

  const { selected, select } = useSelect({
    initialSelectedValue: [],
    options: highlightOptions,
    selectProps: {
      placeholder: 'Highlight data points',
      isMulti: true,
      isSearchable: true,
    },
  });

  var pData = [];
  var layout = {};
  const getRangeValues = (metastats, col) => {
    const { mean, stddev, quantiles } = metastats[col];
    var low = Math.max(mean - stddev, quantiles[0]);
    var high = Math.min(mean + stddev, quantiles[quantiles.length - 1]);
    // if (statInfo[col].is_percentage) {
    //     low *= 100
    //     high *= 100
    // }
    return [low, high];
  };
  const getMean = (metastats, col) => {
    // if (statInfo[col].is_percentage) return metastats[col].mean * 100
    // else if (col === 'toi') return metastats[col].mean / 60
    return metastats[col].mean;
  };

  const getVal = (d, lab) => {
    if (lab === 'height') {
      const hs = d[lab];
      const [ft, inches] = hs.split("'");
      return parseFloat(ft) + parseFloat(inches) / 12.0;
    } else {
      return d[lab];
    }
  };

  const targetName = target.charAt(0).toUpperCase() + target.slice(1);
  if (xCol && yCol) {
    const x = [];
    const y = [];
    const xyPairs = [];
    const names = [];
    const labels = [];
    var pData = [];
    const xLabel = index.includes(xCol)
      ? xCol
      : columnOptions.find((o) => o.value == xCol).label;
    const yLabel = index.includes(yCol)
      ? yCol
      : columnOptions.find((o) => o.value == yCol).label;
    const nameKey = 'player';
    const dataToLabel = (d) => nameToLastName(d['player']);

    const isSelected = (d) => selected?.find((s) => s.value == d.player_id);
    const selectedX = [];
    const selectedY = [];
    const selectedLabels = [];
    const selectedNames = [];

    data.forEach((d) => {
      x.push(getVal(d, xLabel));
      y.push(getVal(d, yLabel));
      xyPairs.push([getVal(d, xLabel), getVal(d, yLabel)]);
      if (isTargetGroup(target)) {
        names.push(`${d['Players']}<br>${d['jerseynumbers']}`);
      } else {
        names.push(d[nameKey]);
      }
      labels.push(dataToLabel(d));

      if (isSelected(d)) {
        selectedX.push(getVal(d, xLabel));
        selectedY.push(getVal(d, yLabel));
        selectedLabels.push(dataToLabel(d));
        selectedNames.push(names[names.length - 1]);
      }
    });

    // Define layout obj
    var layout = {
      title: {
        text: `${yLabel} vs. ${xLabel}`,
        font: { family: 'Oswald' },
      },
      showLegend: true,
      legend: { orientation: 'h' },
      hovermode: 'closest',
      xaxis: { title: xLabel },
      yaxis: { title: yLabel },
      // margin: { t: 30 },
      shapes: [],
    };

    if (invertAxis.x) {
      layout.xaxis['autorange'] = 'reversed';
      layout.xaxis.title = `${xLabel} (inverted)`;
    }
    if (invertAxis.y) {
      layout.yaxis['autorange'] = 'reversed';
      layout.yaxis.title = `${yLabel} (inverted)`;
    }

    // If toggled, add best fit line
    if (showBestFit) {
      const regResult = regression.linear(xyPairs);
      const minX = Math.min(...x);
      const maxX = Math.max(...x);
      pData.push({
        mode: 'lines',
        name: 'Best Fit',
        x: [minX, maxX],
        y: [regResult.predict(minX)[1], regResult.predict(maxX)[1]],
        line: { dash: 'dot', color: 'black' },
      });

      layout.title.text +=
        `<br>` +
        `OLS Best Fit: ${regResult.string}<br>` +
        `r<sup>2</sup>: ${regResult.r2}`;
    }

    // Add Skater Scatter
    pData.push({
      name: targetName,
      mode: showLabels ? 'markers+text' : 'markers',
      type: 'scatter',
      x: x,
      y: y,
      text: labels,
      customdata: names,
      marker: {
        size: 10,
        color:
          selected?.length > 0
            ? theme.colors.states.neutral
            : theme.colors.teams.bos.primary,
        opacity: showLabels ? 0.5 : 1.0,
      },
      hovertemplate:
        '<b>%{customdata}</b><br><br>' +
        '%{yaxis.title.text}: %{y:.2f}<br>' +
        '%{xaxis.title.text}: %{x:.2f}<br>' +
        '<extra></extra>',
    });

    // Add Selected Scatter
    pData.push({
      name: `Selected ${targetName}`,
      mode: 'markers+text',
      type: 'scatter',
      x: selectedX,
      y: selectedY,
      text: selectedLabels,
      customdata: selectedNames,
      marker: {
        size: 20,
        color: theme.colors.light.highlight,
        opacity: 1,
      },
      hovertemplate:
        '<b>%{customdata}</b><br><br>' +
        '%{yaxis.title.text}: %{y:.2f}<br>' +
        '%{xaxis.title.text}: %{x:.2f}<br>' +
        '<extra></extra>',
    });

    // Add means/ranges if toggled
    if (!!metastats) {
      if (showMeans) {
        const meansShapesDefaults = {
          type: 'line',
          line: {
            color: '#f33',
            opacity: 0.5,
            width: 1,
            dash: 'dash',
          },
          layer: 'below',
        };
        if (yCol in metastats && metastats[yCol].mean) {
          layout.shapes.push({
            ...meansShapesDefaults,
            xref: 'paper',
            x0: 0,
            x1: 1,
            y0: getMean(metastats, yCol),
            y1: getMean(metastats, yCol),
          });
        }
        if (xCol in metastats && metastats[xCol].mean) {
          layout.shapes.push({
            ...meansShapesDefaults,
            yref: 'paper',
            y0: 0,
            y1: 1,
            x0: getMean(metastats, xCol),
            x1: getMean(metastats, xCol),
          });
        }
      }
      if (showStddevs) {
        const stddevsShapesDefaults = {
          type: 'rect',
          layer: 'below',
          fillcolor: '#DDD',
          opacity: 0.4,
          line: { width: 0 },
        };
        if (
          yCol in metastats &&
          metastats[yCol].mean &&
          metastats[yCol].stddev
        ) {
          layout.shapes.push({
            ...stddevsShapesDefaults,
            xref: 'paper',
            x0: 0,
            y0: getRangeValues(metastats, yCol)[0],
            x1: 1,
            y1: getRangeValues(metastats, yCol)[1],
          });
        }
        if (
          xCol in metastats &&
          metastats[xCol].mean &&
          metastats[xCol].stddev
        ) {
          layout.shapes.push({
            ...stddevsShapesDefaults,
            yref: 'paper',
            x0: getRangeValues(metastats, xCol)[0],
            y0: 0,
            x1: getRangeValues(metastats, xCol)[1],
            y1: 1,
          });
        }
      }
    }
  }
  return (
    <Container fluid>
      <hr></hr>
      <Row className="justify-content-center">
        <AxisGroup md={5}>
          <Row>
            <Col md={12}>
              <BDDSelect
                name="yCol"
                placeholder="Y Axis"
                value={yCol}
                onChange={(n, v) => setYCol(v)}
                options={columnOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                name="invertAxis"
                label="Invert Axis"
                checked={invertAxis.y}
                onChange={() =>
                  setInvertAxis({ ...invertAxis, y: !invertAxis.y })
                }
              />
            </Col>
          </Row>
        </AxisGroup>
        <Col md="auto">
          <b>vs.</b>
        </Col>
        <AxisGroup md={5}>
          <Row>
            <Col md={12}>
              <BDDSelect
                name="xCol"
                placeholder="X Axis"
                value={xCol}
                onChange={(n, v) => setXCol(v)}
                options={columnOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                name="invertAxis"
                label="Invert Axis"
                checked={invertAxis.x}
                onChange={() =>
                  setInvertAxis({ ...invertAxis, x: !invertAxis.x })
                }
              />
            </Col>
          </Row>
        </AxisGroup>
      </Row>
      <Row className="justify-content-center" style={{ textAlign: 'center' }}>
        <Col md={10}>
          <SelectContainer>{select}</SelectContainer>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ textAlign: 'center' }}>
        <Col md={3}>
          <Form.Check
            name="showLabels"
            label="Show Labels"
            checked={showLabels}
            onChange={() => setShowLabels(!showLabels)}
          />
        </Col>
        <Col md={3}>
          <Form.Check
            name="showMeans"
            label="Show League Avg"
            checked={showMeans && !!metastats}
            disabled={!metastats}
            onChange={() => setShowMeans(!showMeans)}
          />
        </Col>
        <Col md={3}>
          <Form.Check
            name="showStddevs"
            label="Show Standard Range"
            checked={showStddevs && !!metastats}
            disabled={!metastats}
            onChange={() => setShowStddevs(!showStddevs)}
          />
        </Col>
        <Col md={3}>
          <Form.Check
            name="showBestFit"
            label="Show Best Fit (OLS)"
            checked={showBestFit}
            onChange={() => setShowBestFit(!showBestFit)}
          />
        </Col>
      </Row>
      {!xCol || !yCol ? (
        <>
          <center>
            <em>Please select an X Column and a Y Column</em>
          </center>
        </>
      ) : (
        <>
          <br></br>
          <BDDPlotly
            data={pData}
            layout={layout}
            divStyle={{ height: '75vh' }}
          />
          <div style={{ height: '200px' }}></div>
        </>
      )}
    </Container>
  );
}
