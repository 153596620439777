import { useQuery } from '@apollo/client';
import { GET_BF_TRADE } from 'apollo/queries/bruinsfriendly.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import BFDraftPick from 'components/ProScouting/Draft/BFDraftPick';
import { DraftPickIcon } from 'components/ProScouting/Draft/DraftPickIcon';
import { Card } from 'components/bdd/Card';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import { dateToSeason, seasonToEndYear, seasonToStartYear } from 'helpers/hockeyutils';
import { getNHLTeamLogoUrl } from 'helpers/logos';

export const getTermForTradedPlayer = (tradedPlayer, tradeDate) => {
  if (!!tradedPlayer.bfPlayerContract && !!tradeDate) {
    const tradedSeason = dateToSeason(tradeDate, true);
    const contractEndSeason = tradedPlayer.bfPlayerContract.endSeason;
    return seasonToEndYear(contractEndSeason) - seasonToStartYear(tradedSeason);
  }
};

export const BFTradedPlayer = ({
  bfTradedPlayer,
  tradeDate,
  justifyContent = 'start',
  isTradedAgain = false,
  highlightPlayer,
}) => {
  const p = bfTradedPlayer;
  const termAtTrade = getTermForTradedPlayer(p, tradeDate);
  const color = isTradedAgain ? '#aaa' : 'black';
  return (
    <Row columnGap={2} justifyContent={justifyContent} style={{ color }}>
      <Row gap={1}>
        <PlayerLinkHover
          key={p.bfPlayer.slug}
          name={`${p.bfPlayer.firstName} ${p.bfPlayer.lastName}`}
          slug={p.bfPlayer.bddPlayer?.slug}
          style={{ color }}
          variant={highlightPlayer ? 'body1' : 'body2'}
        />
        {bfTradedPlayer.isMinor && (
          <Typography variant="caption" color={color}>
            (minors)
          </Typography>
        )}
      </Row>
      {'@'}
      <Typography variant={highlightPlayer ? 'body1' : 'body2'} color={color}>
        {formatDollars(p.capHitAffecting, { truncate: true })}{' '}
        {!!p.retainedSalaryPct && `(${roundToX(p.retainedSalaryPct, 1)}%)`}
        {!!p.bfPlayerContract && !!tradeDate && `x${termAtTrade}`}
      </Typography>
    </Row>
  );
};

export const BFTradeBreakdown = ({
  bfTrade: bfTradeArg, // bfTrade object w/ all relationships loaded
  tradeId, // trade id (to load bfTrade)
}) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_TRADE, {
      variables: { id: tradeId },
      skip: !!bfTradeArg,
    })
  );

  if (!tradeId && !bfTradeArg) {
    throw Error('Must supply either tradeId or bfTrade');
  }

  const bfTrade = !!data ? data?.bfTrade : bfTradeArg;
  if (!bfTradeArg && !!placeholder) return placeholder;

  const renderDollars = (dollars, truncate = false, color = true, negative = true) => {
    return (
      <span
        style={{
          color: negative
            ? dollars > 0
              ? 'red'
              : 'green'
            : dollars > 0
            ? 'green'
            : 'red',
        }}
      >
        {formatDollars(dollars, { truncate: false })}
      </span>
    );
  };

  const teamFields = [
    [
      {
        id: 'caphitdelta',
        label: <>Cap Hit &Delta;</>,
        accessor: (t) => renderDollars(t.capHitChange),
      },
      {
        id: 'salarydelta',
        label: <>Salary &Delta;</>,
        accessor: (t) => renderDollars(t.salaryChange),
      },
    ],
    [
      { id: 'playersadded', label: 'Players In', accessor: (t) => t.numPlayersIn },
      { id: 'playersremoved', label: 'Players Out', accessor: (t) => t.numPlayersOut },
    ],
    [
      { id: 'picksin', label: 'Picks In', accessor: (t) => t.numPicksIn },
      { id: 'picksout', label: 'Picks Out', accessor: (t) => t.numPicksOut },
    ],
  ];

  const renderPicks = (picks) => (
    <Column gap={0}>
      {picks.map((p) => (
        <BFDraftPick key={p.bfDraftPick.id} draftPick={p.bfDraftPick} />
      ))}
    </Column>
  );

  const renderPlayers = (teamSlug, sending = true) => (
    <Column gap={0}>
      {bfTrade.tradedPlayers
        .filter((p) =>
          sending ? p.fromTeamSlug === teamSlug : p.toTeamSlug === teamSlug
        )
        .map((p) => (
          <BFTradedPlayer
            key={p.playerSlug}
            bfTradedPlayer={p}
            tradeDate={bfTrade.date}
            isTradedAgain={
              !sending &&
              bfTrade.tradedPlayers.find(
                (tp) => tp.playerSlug === p.playerSlug && tp.fromTeamSlug === teamSlug
              )
            }
          />
        ))}
    </Column>
  );

  return (
    <Container>
      <Row justifyContent="start" columnGap={8}>
        {bfTrade.teamSummaries.map((t) => (
          <Container key={t.teamSlug} flexGrow="1">
            <Card flexGrow="1">
              <Column gap={2}>
                <Row gap={1} justifyContent="center">
                  <img alt="" src={getNHLTeamLogoUrl(t.bfTeam.nhlid)} height="30px" />
                  <Typography variant="h5">{t.bfTeam.name}</Typography>
                </Row>
                <Container>
                  <Row justifyContent="center" gap={2} flexWrap>
                    {teamFields.map((fieldGroup, i) => (
                      <table key={i} style={{ margin: 'auto' }}>
                        <tbody>
                          {fieldGroup.map(({ id, label, accessor }) => (
                            <tr key={id}>
                              <td>
                                <Typography variant="body1" textAlign="right">
                                  {label}:
                                </Typography>
                              </td>
                              <td style={{ paddingLeft: '5px' }}>
                                <Typography variant="body2">{accessor(t)}</Typography>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ))}
                  </Row>
                </Container>

                <Container>
                  <Typography variant="h6">Sending away</Typography>
                  {renderPicks(
                    bfTrade.tradedDraftPicks.filter((p) => p.fromTeamSlug === t.teamSlug)
                  )}
                  {renderPlayers(t.teamSlug, true)}
                </Container>

                <Container>
                  <Typography variant="h6">Receiving</Typography>
                  {t.isFutureConsiderations && (
                    <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                      Future considerations
                    </Typography>
                  )}
                  {renderPicks(
                    bfTrade.tradedDraftPicks.filter((p) => p.toTeamSlug === t.teamSlug)
                  )}
                  {renderPlayers(t.teamSlug, false)}
                </Container>
              </Column>
            </Card>
          </Container>
        ))}
      </Row>
    </Container>
  );
};
