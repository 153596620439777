import { Container } from 'components/bdd/Layout';
import { useContext } from 'react';
import { VideoPlayerContext } from '..';

export const Video = ({ height }) => {
  const { video } = useContext(VideoPlayerContext);

  return (
    <Container backgroundColor="black" height={height}>
      {video}
    </Container>
  );
};
