import { CheckboxGroupFilter } from '.';
import { shotChanceOptions } from '../helpers';

export const ShotChanceTypeFilter = ({}) => {
  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="shot_chance"
      label={'Chance Type'}
      options={shotChanceOptions}
    />
  );
};
