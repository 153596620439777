import React from 'react'
import { Modal } from 'react-bootstrap'

import styled from 'styled-components'
import FGVideoController from './FGVideoController'

const Styles = styled.div({
  minHeight: '50vh'
})

export default function FGVideoModal({
  show,
  handleClose,
  gamePeriod // [{ gameUid, period }]
}) {

  return <Modal dialogClassName="xl-modal" size='lg' show={show} onHide={handleClose}>
    <Styles>
      <FGVideoController gameUid={gamePeriod.gameUid} period={gamePeriod.period}/>
    </Styles>
  </Modal>
}