import { useSearchParamsState } from 'components/bdd/hooks';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useContext } from 'react';
import { PlayerSearchContext } from '..';

const expiryStatusOptions = [
  {
    value: 'UFA',
    label: 'UFA',
  },
  {
    value: 'RFA',
    label: 'RFA',
  },
];

export const useExpiryStatusFilter = ({ initialExpiryStatus }) => {
  const { store, setStore } = useContext(PlayerSearchContext);
  const expiryStatus = store.expiryStatus || [initialExpiryStatus];

  return {
    expiryStatusFilter: expiryStatus
      ? {
          col: 'expiry_status',
          val: expiryStatus,
          cond: 'isin',
        }
      : null,
    expiryStatusSelect: (
      <StyledBDDSelect
        selectedValue={expiryStatus}
        options={expiryStatusOptions}
        selectProps={{
          isMulti: true,
          isClearable: true,
          placeholder: 'Filter to expiry status',
        }}
        onChange={(n, v) => {
          setStore((store) => ({
            ...store,
            expiryStatus: v,
          }));
        }}
      />
    ),
  };
};
