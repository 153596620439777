import BDDSortableTable from 'components/bdd/bddsortabletable';
import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import useBDDPlayerSearch from 'components/Search/useBDDPlayerSearch';
import { theme } from 'constants';
import { getCurrentSeason, seasonToEndYear } from 'helpers/hockeyutils';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlayerSearch, PlayerSearchContext, SimilarFreeAgentSignings } from '.';
import {
  usePlayerSearchColumns,
  usePlayerRoleSelect,
  useExpiryStatusFilter,
  useFreeAgentYearFilter,
  useTeamFilter,
  usePositionFilter,
} from './hooks';
import { useSearchParamsState } from 'components/bdd/hooks';
import { usePaginated } from 'components/bdd/Paginated';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { ModalContext } from 'components/bdd/Modal';
import { formatBddStat } from 'helpers/format';
import { searchMetastatsSplitsForStat } from 'helpers/stats';
import { stddevColor } from 'helpers/tables';
import { BDDPlayerSearch } from './components';
import { mapSort } from './helpers';
import { Header } from 'components/bdd/Table';
import { buildPaginatedBFPlayerQuery } from 'apollo/queries/bruinsfriendly.queries';

const Styles = styled.div({
  table: {
    tbody: {
      tr: {
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '1px 1px 15px #ddd',
        },
        borderBottom: '1px solid #ddd',
        minHeight: 40,
      },
      'tr.selected': {
        backgroundColor: theme.colors.light.focus,
      },
    },
  },
});

export const PlayerSearchTable = ({
  hideTitle = false,
  onPlayerSelect = null, // when not supplied, launched similar player modal
  useStateParams = true,
}) => {
  const { store, setStore } = useContext(PlayerSearchContext);
  const [sort, setSort] = useState([{ id: 'P', desc: true }]);
  const { buttonGroup: skatersGoaliesGroup, selectedValue: selectedTarget } =
    useButtonGroup({
      initialSelectedValue: 'skaters',
      options: [
        {
          value: 'skaters',
          label: 'Skaters',
        },
        {
          value: 'goalies',
          label: 'Goalies',
        },
      ],
    });

  const { expiryStatusSelect, expiryStatusFilter } = useExpiryStatusFilter({
    initialExpiryStatus: 'UFA',
  });
  const { freeAgentYearSelect, freeAgentYearFilter } = useFreeAgentYearFilter({
    initialYear: seasonToEndYear(getCurrentSeason()),
  });

  const { playerRoleSelect, lineupSpotFilters } = usePlayerRoleSelect();
  const { teamSelect, getTeamFilter } = useTeamFilter();
  const { positionSelect, getPositionFilter } = usePositionFilter();

  const getPlayerFilters = (statQuery) => [
    ...(!!expiryStatusFilter ? [expiryStatusFilter] : []),
    ...(!!freeAgentYearFilter ? [freeAgentYearFilter] : []),
    ...(!!store.selected
      ? [
          {
            col: statQuery ? 'bf_player_slug' : 'slug',
            val: store.selected,
          },
        ]
      : []),
    ...(getTeamFilter(statQuery) ? [getTeamFilter(statQuery)] : []),
    getPositionFilter(statQuery, selectedTarget),
  ];

  const { data, previousData, placeholder, pageControls } = usePaginated({
    defaultLimit: 20,
    key: 'paginatedBfPlayers',
    limitOptions: [20, 50, 100],
    query: buildPaginatedBFPlayerQuery({
      loadBddPlayerSlugs: true,
      loadTeams: true,
      loadContracts: true,
      loadStats: true,
      loadLineupSpots: true,
    }),
    variables: {
      lineupSpotFilters,
      sort,
      playerFilters: getPlayerFilters(false),
      statQ: {
        extras: { metastats: true },
        format: { percentages: { mult100: true } },
        target: selectedTarget,
        index: ['slug', 'bf_player_slug', 'league_slug', 'season', 'game_type'],
        filters: [
          {
            col: 'league',
            val: 'NHL',
          },
          {
            col: 'game_type',
            val: 'nhl_regular',
          },
          {
            col: 'season',
            val: getCurrentSeason(),
          },
          ...getPlayerFilters(true),
        ],
        statReport:
          selectedTarget == 'skaters' ? 'skatersOverview' : 'goaliesOverview',
      },
    },
  });

  const paginatedData = data?.paginatedBfPlayers.data;
  const statData = data?.paginatedBfPlayers.extraData;
  const bfPlayers =
    paginatedData &&
    paginatedData.map((p) => ({
      ...p,
    }));

  // Use previous data to define column headers to reduce header flicker
  const statDataToUse = statData || previousData?.paginatedBfPlayers.extraData;
  const findMetastats = (statDef, row) => {
    return searchMetastatsSplitsForStat(statDataToUse?.extras?.metastats, statDef, row, {
      targetGroup: 'league',
      targetPosition: 'all',
      matchOn: 'slug',
    });
  };

  const highlightCallback = (label, row, statDef) => {
    const value = row?.[statDef.label];
    if (isNaN(value) || value === null) return null;
    const [_, metastats] = findMetastats(statDef, row);

    if (!metastats) return null;

    return stddevColor(value, metastats, statDef.negative, 2, 4);
  };

  const statDefColumns =
    statDataToUse?.format?.stat_definitions.map((s) => {
      return {
        id: s.label,
        sortDescFirst: true,
        Header: <Header>{s.label}</Header>,
        accessor: (d) => `${d.stats?.[s.label]}`,
        width: s.is_time_stat || s.is_percentage ? 75 : 50,
        Cell: ({
          cell: {
            row: { original: d },
          },
        }) => {
          const value = formatBddStat(d.stats?.[s.label], s, {
            showPercent: true,
          });

          return (
            <Container
              padding={2}
              style={{ backgroundColor: highlightCallback(s.label, d.stats, s) }}
            >
              <Typography variant="body2">
                {!value.includes('NaN') ? value : ''}
              </Typography>
            </Container>
          );
        },
      };
    }) || [];

  const { columnSelect, columns } = usePlayerSearchColumns({
    target: selectedTarget,
  });

  const [selectedPlayerSlug, setSelectedPlayer] = useStateParams
    ? useSearchParamsState(null, 'selectedPlayer')
    : useState(null);

  const selectedPlayer = bfPlayers?.find((p) => p.slug == selectedPlayerSlug);
  const { renderModal } = useContext(ModalContext);

  useEffect(() => {
    if (!selectedPlayer) return;

    if (!onPlayerSelect) {
      renderModal({
        title: (
          <Typography variant="h6">
            Explore similar signings for {selectedPlayer.firstName}{' '}
            {selectedPlayer.lastName}
          </Typography>
        ),
        size: 'xxl',
        body: (
          <SimilarFreeAgentSignings
            cfPlayerSlug={selectedPlayer.slug}
            signingAge={selectedPlayer.age}
            expiryStatus={selectedPlayer.expiryStatus}
            positionFdg={selectedPlayer.positionFdg}
          />
        ),
        onRenderHide: () => {
          setSelectedPlayer();
        },
      });
    }
  }, [selectedPlayer?.slug]);

  const handleSetSort = (sort) => {
    setSort(mapSort(sort));
  };

  return (
    <Container padding={2}>
      <Column gap={2}>
        {!hideTitle && (
          <>
            <Typography variant="h4">Player Search</Typography>
            <Divider />
          </>
        )}
        <Container padding={3}>
          <Column gap={6}>
            <Container>
              <Column gap={3}>
                <Container>{skatersGoaliesGroup}</Container>
                <Row columnGap={3} rowGap={3} flexWrap>
                  <Container width={200}>
                    <BDDPlayerSearch />
                  </Container>
                  <Container width={200}>{freeAgentYearSelect}</Container>
                  <Container width={200}>{expiryStatusSelect}</Container>
                  <Container width={200}>{teamSelect}</Container>
                  {selectedTarget == 'skaters' && (
                    <>
                      <Container width={150}>{positionSelect}</Container>
                      {playerRoleSelect}
                    </>
                  )}
                </Row>
                <Row alignItems="end" justifyContent="space-between">
                  {columnSelect}
                  {pageControls}
                </Row>
              </Column>
            </Container>
            <Styles>
              <BDDSortableTable
                flexLayout
                loading={placeholder}
                data={bfPlayers || []}
                columns={[...columns, ...statDefColumns]}
                columnJustify="start"
                defaultSort={sort}
                manualSortBy={true}
                onSortChange={handleSetSort}
                onRowClick={(row) => {
                  setSelectedPlayer(row.original.slug);
                  !!onPlayerSelect && onPlayerSelect(row.original);
                }}
                trClassCallback={(row) =>
                  selectedPlayerSlug == row.original.slug ? 'selected' : ''
                }
                scroll={true}
              />
              {placeholder}
            </Styles>
          </Column>
        </Container>
      </Column>
    </Container>
  );
};

PlayerSearch.Table = PlayerSearchTable;
