import React from 'react'
import { useQuery } from '@apollo/client'

import { GET_TEAM_ACTIVE_DEPTH_CHART } from '../../apollo/queries/depthchart.queries'
import BDDErrorBoundary from '../bdd/bdderrorboundary'
import { BDDLoader } from '../bdd/bddloader'
import BDDApiError from '../bdd/bddapierror'
import DepthChartProspectRankings from './depthchartprospectrankings'

export default function BDDDepthProspectLists({
    depthChartId,
    activeTeamSlug,
    ...rest
}) {
    if ((!!depthChartId && !!activeTeamSlug) || (!depthChartId && !activeTeamSlug)) {
        throw Error('One of "depthChartId" OR "activeTeamSlug" required (not both)')
    }
    return <BDDErrorBoundary>
        <DepthChartProspectRankings
            depthChartId={depthChartId}
            activeTeamSlug={activeTeamSlug}
            showAll={false}
            {...rest}
        />
    </BDDErrorBoundary>

}