import { Container } from 'components/bdd/Layout';
import { useContext } from 'react';
import { VideoContext } from '../..';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { useTeamGamePlayerData } from '../../hooks';

export const TeamPlayerFilter = ({ isMulti = false, target = 'skaters' }) => {
  const { sourceFilters, slPlayer, setSlPlayer } = useContext(VideoContext);

  const { players, loading } = useTeamGamePlayerData({
    teamSlug:
      sourceFilters['team_slug']?.val || sourceFilters['opp_team_slug']?.val,
    leagueSlug: sourceFilters['league_slug']?.val,
    season: sourceFilters['season']?.val,
    gameType: sourceFilters['game_type']?.val,
    target,
  });

  const options = players?.map((d) => ({
    label: `${d.Player}`,
    value: d.player_id,
  }));

  const label = target == 'skaters' ? 'Player' : 'Goalie';

  return (
    <Container minWidth={150}>
      <Typography variant="body1">{label}s</Typography>
      <StyledBDDSelect
        options={options || []}
        selectedValue={slPlayer?.playerId}
        onChange={(n, v) => {
          const player = players.find((d) => d.player_id == v);

          if (!player) {
            setSlPlayer(null);
          } else {
            setSlPlayer({
              playerId: player.player_id,
              primaryposition: player.position,
              teamSlug: player.team_slug,
            });
          }
        }}
        selectProps={{
          isSearchable: true,
          isClearable: true,
          isLoading: loading,
          isMulti,
          placeholder: `Select ${label}...`,
        }}
      />
    </Container>
  );
};
