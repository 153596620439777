import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { GET_NHL_SALARY_CAP } from '../../apollo/queries/contract.queries'
import { buildBDDDepthChartQuery } from '../../apollo/queries/depthchart.queries'
import { numberWithCommas } from '../../helpers/helpers'
import { getCurrentSeason, getSeasonPlusMinusYears, positionToAbbrev } from '../../helpers/hockeyutils'
import { BDDOptionGroup } from '../bdd'
import BDDApiError from '../bdd/bddapierror'
import { BDDLoader } from '../bdd/bddloader'
import { CapTable } from '../Team/teamcapinfo'

const Styles = styled.div`
    .buried-player {
        color: #777;
        font-style: italic;
    }
`
export default function DepthChartAnalyzer({
    depthChartId
}) {
    const { data, loading, error } = useQuery(buildBDDDepthChartQuery({}), { variables: { id: depthChartId }})
    const { data: capData } = useQuery(GET_NHL_SALARY_CAP)
    const [tab, setTab] = useState('cap')
    console.log(capData)

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error} />
    const players = data.bddDepthChart.depthChartPlayers.filter(p => p.depthLeague === 'NHL')
    const playersCapInfo = players.filter(p => !!p.bddPlayer).map(p => ({
        ...p.bddPlayer, 
        ...p.bddPlayer.rinknetPlayer,
    }))
    // Identify guys who's contracts are buried
    if (!!capData && !!capData.salaryCap) {
        const maxBuriable = capData.nhlSalaryCap.buriedContractMaximum
        const buriedPlayers = data.bddDepthChart.depthChartPlayers.filter(p => {
           if (p.depthLeague === 'NHL') return false
            try {
                var aav = p.bddPlayer.rinknetPlayer.activeContract.averagedAmount
            } catch {
                return false
            }
            return aav > maxBuriable
        })
        buriedPlayers.forEach(p => {
            playersCapInfo.push({
                className: 'buried-player',
                ...p.bddPlayer,
                ...p.bddPlayer.rinknetPlayer,
                nhlContracts: p.bddPlayer.rinknetPlayer.nhlContracts.map(c => 
                    ({ ...c, averagedAmount: c.averagedAmount - maxBuriable })),
            })
        })
    }

    
    return <Styles>
        <BDDOptionGroup
            selected={tab}
            options={[{ label: 'Cap Table', value: 'cap' }]}
            onChange={v => setTab(v)}
        />
        <hr></hr>
        <CapAnalysis players={playersCapInfo} salaryCap={!!capData ? capData.nhlSalaryCap : null} />
    </Styles>
}


const CapStyles = styled.div`
    font-size: 0.9em;
    .cap-totals-tbl {
        width: 100%;
        font-size: 0.8em;
        th {
            text-align: right;
            padding-right: 5px;
        }
        td {
            padding-left: 5px;
            text-align: left;
        }
    }
`
const CapAnalysis = ({ players, salaryCap }) => {
    const [numYears, setNumYears] = useState(99)


    var playerToYearToContract = {}
    var playerToInfo = {}
    var seasons = []
    
    const firstSeason = parseInt(getCurrentSeason().toString().slice(4))
    players.forEach(p => {
        if (!(p in playerToInfo)) {
            playerToInfo[p.slug] = { 
                ...p, 
                bddPlayerSlug: { slug: p.slug }, // hacky for captable
                position: positionToAbbrev(p.position)
            } 
        }
        if (p.nhlContracts) {
            p.nhlContracts.filter(c => c.isActive || c.isFuture).forEach(c => {
                if (!(p.slug in playerToYearToContract)) {
                    playerToYearToContract[p.slug] = {}
                }
                for (let s=c.startSeason; s<c.expirationSeason; s++) {
                    if (!seasons.includes(s) && s >= firstSeason) {
                        seasons.push(parseInt(s))
                    }
                    const playerContract = {...p}
                    delete playerContract.nhlContracts
                    playerContract.contract = c
                    playerToYearToContract[p.slug][s] = playerContract
                }
            })    
        }
    })   
    seasons = seasons.sort((a,b) => a - b).slice(0, numYears)

    const playerOrder = players.filter(p => !!p.slug).sort((a,b) => b.averagedAmount - a.averagedAmount).map(p => p.slug)
    const positions = [{short: 'F', long: 'Forwards'}, {short: 'D', long: 'Defensemen'}, {short: 'G', long: 'Goalies'}]
    
    const currYear = getCurrentSeason().toString().slice(4)
    var totalCapHit = 0
    var numUnsigned = 0
    playerOrder.forEach(slug => {
        if ((slug in playerToYearToContract) && (currYear in playerToYearToContract[slug])) {
            totalCapHit += playerToYearToContract[slug][currYear].contract.averagedAmount
        } else {
            numUnsigned++
        }
    })

    const capRoom = !!salaryCap ? salaryCap.salaryCapCeiling - totalCapHit : null
    return <CapStyles>
        <Row>
            <Col>
                <table className='cap-totals-tbl'>
                <tbody>
                    <tr>
                        <th>Projected Salary Total</th>
                        <td>${numberWithCommas(totalCapHit)} {numUnsigned > 0 && <small><em>({numUnsigned} unsigned)</em></small>}</td>
                    </tr>
                    <tr>
                        <th>Projected Salary Cap</th>
                        <td>{!!salaryCap && `$${numberWithCommas(salaryCap.salaryCapCeiling)}`}</td>
                    </tr>
                    <tr>
                        <th>Projected Cap Room</th>
                        <td>{!!capRoom && <span style={{ color: capRoom < 0 ? 'red' : 'black' }}>
                                ${numberWithCommas(capRoom)}
                            </span>}
                        </td>
                    </tr>
                </tbody>
                </table>
            </Col>
        </Row>
        {positions.map(pos => {
            const playerIds = playerOrder.filter(pid => playerToInfo[pid].posFDG === pos.short
                ).sort((a,b) => {
                    const aAAV = !!playerToInfo[a].activeContract ? playerToInfo[a].activeContract.averagedAmount : 0
                    const bAAV = !!playerToInfo[b].activeContract ? playerToInfo[b].activeContract.averagedAmount : 0
                    return bAAV - aAAV
                })
            return <Container key={pos.short}>
                <CapTable
                    title={pos.long}
                    playerIds={playerIds}
                    playerToInfo={playerToInfo}
                    seasons={seasons}
                    playerToYearToContract={playerToYearToContract}
                    salaryCapCeiling={!!salaryCap ? salaryCap.salaryCapCeiling : null}
                />
            </Container>
        })}
        <div style={{height: "200px"}}></div>
    </CapStyles>
    return 
}