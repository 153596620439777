import { buildQueryFilters } from 'components/bdd/Filters';
import { useContractSearchContext } from '../context';
import { useSimilarContracts } from '../useSimilarContracts';
import { useSimilarContractsContext } from './context';
import { buildIdToQueryFilters } from '../helpers';
import { ContractSearchData } from '../ContractSearchData';
import { ContractSearchTable } from '../ContractSearchTable';
import { Cell, buildColumns } from 'components/bdd/Table';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { Column, Container } from 'components/bdd/Layout';
import { useEffect } from 'react';
import { ModalContextProvider } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { getTargetVectorWeights } from './helpers';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GET_BF_CONTRACT } from 'apollo/queries/bruinsfriendly.queries';
import { useQuery } from '@apollo/client';

export const SimilarContractsData = ({ children }) => {
  const targetDetails = useSimilarContractsContext((state) => state.targetDetails);
  const targetVector = useSimilarContractsContext((state) => state.targetVector);
  const targetWeights = useSimilarContractsContext((state) => state.targetWeights);

  const variables = useContractSearchContext((state) => state.variables);
  const statFilters = useContractSearchContext((state) => state.statFilters);
  const { applyFilters } = useContractSearchContext((state) => state.actions);

  const timeRange = [
    {
      col: 'time-range',
      cond: '=',
      val: 'platform-stats',
    },
    {
      col: 'platform-prior-seasons',
      cond: '=',
      val: targetDetails.priorSeasons.value || 0,
    },
  ];

  useEffect(() => {
    applyFilters();
  }, []);

  const { data, placeholder } = useSimilarContracts({
    nNeighbours: targetDetails?.nNeighbours?.value || 20,
    skip: !targetVector || !targetWeights || !variables,
    timeRange,
    targetVector,
    filters: variables?.filters,
    statFilters: buildQueryFilters(statFilters, buildIdToQueryFilters),
    statMode: targetDetails?.statsMode?.value,
    weights: targetWeights,
  });

  const extraColumns = buildColumns([
    {
      id: 'similarity',
      header: 'Similarity',
      accessor: (d) => {
        const similarContract = similarContracts.find((sc) => sc.contractId == d.id);
        return roundToX((1 - similarContract?.distance) * 100, 2);
      },
      renderCell: (d, accessorValue) => {
        return (
          <Container
            style={{
              backgroundColor: getRedGreenColor(accessorValue, 50, 100, false, 4),
            }}
          >
            <Cell>{accessorValue}</Cell>
          </Container>
        );
      },
    },
  ]);

  const targetVectorWeights =
    targetVector && getTargetVectorWeights(targetVector, targetWeights);

  const { data: contractData } = usePlaceholder(
    useQuery(GET_BF_CONTRACT, {
      skip: !targetDetails?.id?.value && !targetDetails?.bddPlayerSlug?.value,
      variables: {
        id: targetDetails?.id?.value,
        bddPlayerSlug: targetDetails?.bddPlayerSlug?.value,
      },
    })
  );

  const bfPlayer = contractData?.bfContract.bfPlayer;

  const applyColumnAdjustment = (column) => {
    const columnTargetVector = targetVectorWeights.find(
      (tvw) => tvw.header == column.header
    );

    if (!columnTargetVector && column.id != 'player') return column;

    return {
      ...column,
      Header: (
        <Column alignItems="center">
          {column.Header || column.header}
          <Container
            key={`${column.header}-tw`}
            padding={1}
            backgroundColor={theme.colors.light.secondary}
          >
            <Typography variant="body2">
              {column.id == 'player'
                ? bfPlayer && `${bfPlayer.firstName} ${bfPlayer.lastName}`
                : columnTargetVector.value}
            </Typography>
          </Container>
        </Column>
      ),
    };
  };

  const similarContracts = data?.bfSimilarContracts.contracts.map((c, index) => ({
    contractId: c.id,
    distance: data?.bfSimilarContracts.distances[index],
  }));

  const similarContractFilters = [
    ...(variables?.filters || []),
    ...(similarContracts
      ? [{ col: 'id', val: similarContracts.map((sc) => sc.contractId), cond: 'isin' }]
      : []),
  ];

  return (
    <Container height="100%" overflow="auto">
      {placeholder || (
        <ContractSearchData
          variables={{
            ...variables,
            sort: null,
            filters: similarContractFilters,
            timeRange,
          }}
        >
          <ModalContextProvider>
            <ContractSearchTable
              disableSort
              sort={[{ id: 'similarity', desc: true }]}
              extraColumns={extraColumns}
              showAnalysisByDefault
              applyColumnAdjustment={applyColumnAdjustment}
            />
          </ModalContextProvider>
        </ContractSearchData>
      )}
    </Container>
  );
};
