import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { TooltipSpan } from 'components/reports';
import { numberToOrdinal } from 'helpers/helpers';
import { Asterisk } from 'react-bootstrap-icons';

export default function BFDraftPick({
  draftPick,
  showYear = true,
  justifyContent = 'start',
  variant = 'body2',
}) {
  const player = draftPick.player;
  const isTraded = draftPick.originalTeamSlug !== draftPick.currentTeamSlug;
  const isSelected = !!player;
  const isConditional = !!draftPick.isConditional;

  const tooltipContent = (
    <>
      <div>
        R{draftPick.round} P{draftPick.overall}
      </div>
      <div>{isTraded && `Currently owned by ${draftPick.currentTeam.abbreviation}`}</div>
      <div>{isSelected && `Selected ${player.firstName} ${player.lastName}`}</div>
      <div>{!!draftPick.conditionalDetails && `${draftPick.conditionalDetails}`}</div>
    </>
  );

  return (
    <Row justifyContent={justifyContent}>
      <TooltipSpan content={tooltipContent}>
        <Typography variant={variant}>
          <Row columnGap={1}>
            <Container>{showYear && `'${draftPick.year.toString().slice(2)}`}</Container>
            <Container>{draftPick.originalTeam.abbreviation}</Container>
            <Container>
              {numberToOrdinal(draftPick.overall)} ({numberToOrdinal(draftPick.round)}{' '}
              round)
            </Container>
            <Container>
              {!!isConditional && <Icon icon={<Asterisk />} size="8px" />}
            </Container>
          </Row>
        </Typography>
      </TooltipSpan>
    </Row>
  );
}
