import React from 'react'
import { ErrorMessage, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import BDDFormError from "../bdd/bddformerror";
import { positionToCategories, SelectInput } from "./playerformhelpers";
import { BDDErrorColor, BDDTextArea, ItemGrid } from '../bdd';
import styled from 'styled-components';

const Styles = styled.div`
    label {
        margin-top: 10px;
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 0.9em;
    }
    
    textarea {
        margin-top: 5px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
`

function CoreReportForm({
    errors,
    reportType, // draft/underage/others...
    position, // f/d/g
    includeGameScore=true,
    includePosition=false,
    textPerField=false
}) {
    return <Styles>       
        <Row>
            <Col>
                <Field name="reportType" title='Report Type' position={position} component={SelectInput} /> 
            </Col>
            {includePosition && <Col>
                <Field name="positionDetail" title='Position' placeholder="Position" position={position} component={SelectInput} /> 
            </Col>}
        </Row>
        {includeGameScore && <Row>
            <Col>
                <Field name="gameScore" title='Game Impact' position={position} component={SelectInput} /> 
            </Col>
        </Row>}
        <Row>
            <Col>
                <Field name="currentRole" title='Current Role' position={position} component={SelectInput} /> 
                <Field name="proProjection" title='Pro Projection' position={position} component={SelectInput} /> 
                {position !== 'g' ? <div>
                    <label htmlFor='powerPlay'>Power Play:</label>{' '}
                    <Field id='powerPlay'  type='checkbox' name='powerPlay'/>
                </div> : null }
            </Col>
            <Col>
                <Field name="playerType" title='Player Type' position={position} component={SelectInput} /> 
                <span style={reportType === 'draft' || reportType === 'underage' ? {} : { visibility: 'hidden' }}>
                    <Field name="draftRound" title='Draft Round' position={position} reportType={reportType} component={SelectInput} />
                </span>
                {position !== 'g' ? <div>
                    <label htmlFor='penaltyKill'>Penalty Kill:</label>{' '}
                    <Field id='penaltyKill' type='checkbox' name='penaltyKill'/> 
                </div> : null }
            </Col>
        </Row>
        <hr></hr>
        <ItemGrid
            items={positionToCategories[position].map(cat => ({cat: cat}))}
            numCols={2}
            keyField={'cat'}
            render={c => <>
                <Field 
                    name={c.cat} 
                    title={c.cat}
                    position={position}
                    component={SelectInput} 
                />
                {textPerField && <Field
                    as={BDDTextArea}
                    rows={3}
                    name={`${c.cat}_Notes`}
                />}
            </>}
        />
        <Row>
            <Col>
                <label>Overall Notes</label>
                <Field
                    as={BDDTextArea}
                    rows={3}
                    name={`Overall_Notes`}
                    style={!!errors['Overall_Notes'] ? { boxShadow: `0px 0px 5px ${BDDErrorColor}` } : null}
                />
                <BDDFormError>
                    <ErrorMessage name={'Overall_Notes'}/>
                </BDDFormError>
            </Col>
        </Row>
    </Styles>
}

export default React.memo(CoreReportForm)