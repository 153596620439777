import { GET_STAT_DEFINITIONS_V2 } from "apollo/queries/statsv2.queries";
import useBDDSearchSelect from "components/Search/useBDDSearchSelect";


export default function useStatDefinitionSearchSelect(props) {

  const { searchComponent, selected, selectedOption } = useBDDSearchSelect({
    query: GET_STAT_DEFINITIONS_V2,
    resultToOptions: data => data?.statDefinitionsV2?.data.map(s => ({
      ...s,
      label: s.primaryName || s.slug,
      value: s.slug,
      subLabel: s.description
    })),
    placeholder: 'Search for stat',
    isClearable: true,
    ...props
  })

  return {
    searchComponent,
    selected,
    selectedOption
  }
}