import { useQuery } from '@apollo/client';
import { useEPStatsContext } from '../context';
import { GET_PLAYER_WATCH_LIST_POSITIONS } from 'apollo/queries/playerlist.queries';
import { useEffect } from 'react';
import { Cell, buildColumns } from 'components/bdd/Table';
import { LinkPopover } from 'components/bdd/Popover';
import { Container, Row } from 'components/bdd/Layout';
import { AddToWatchList } from './AddToWatchList';
import { LinkButton } from 'components/bdd/Button';
import { Typography } from 'components/bdd/Typography';
import { HoverInteractive } from 'components/bdd';

const WATCH_LIST_COLUMN_ID = 'watch_list';

export const WatchListData = ({}) => {
  const indexColumnIds = useEPStatsContext((state) => state.indexColumnIds);
  const results = useEPStatsContext((state) => state.results);
  const { addColumns } = useEPStatsContext((state) => state.actions);

  const playerSlugs = results?.stats?.data.map((s) => s.slug).filter((s) => !!s);
  const { data: watchListData } = useQuery(GET_PLAYER_WATCH_LIST_POSITIONS, {
    skip:
      !playerSlugs ||
      playerSlugs.length == 0 ||
      !indexColumnIds.includes(WATCH_LIST_COLUMN_ID),
    variables: {
      slugs: playerSlugs,
    },
  });

  const watchListPositions = watchListData?.playerWatchListPositions;
  const watchListColumn = buildColumns([
    {
      id: WATCH_LIST_COLUMN_ID,
      disableSort: true,
      accessor: (row) => {
        if (!watchListPositions) {
          return <Cell textAlign="center">Loading...</Cell>;
        }

        const playerPositions = watchListPositions.listPositions.filter(
          (lp) => lp.bddPlayerSlug == row['slug']
        );

        if (playerPositions.length == 0)
          return (
            <Row justifyContent="center">
              <LinkPopover
                renderContent={(forceClose) => (
                  <AddToWatchList
                    watchLists={watchListPositions.lists}
                    rinknetId={row['player_rinknet_id']}
                    league={row['league']}
                    onClose={forceClose}
                  />
                )}
                hideTransition
              >
                <LinkButton>Not Listed</LinkButton>
              </LinkPopover>
            </Row>
          );

        const listNames = playerPositions
          .map((playerPosition) => {
            const list = watchListPositions.lists.find(
              (l) => l.id == playerPosition.listId
            );
            if (!list) return null;

            return (
              <Typography
                variant="body2"
                noWrap
              >{`#${playerPosition.listNumber} on ${list.name}`}</Typography>
            );
          })
          .filter((ln) => !!ln);

        return (
          <HoverInteractive
            placement="right"
            content={<Container>{listNames}</Container>}
          >
            <Cell textAlign="center">Listed</Cell>
          </HoverInteractive>
        );
      },
      header: 'Watch List',
    },
  ]);

  useEffect(() => {
    addColumns(watchListColumn);
  }, [watchListData]);
};
