import BDDSortableTable from "components/bdd/bddsortabletable";
import { toastError } from "components/bdd/bddtoasts";
import { Container } from "components/bdd/Layout";
import { theme } from "constants";
import { useContext } from "react";
import styled from "styled-components";
import useBFSigningModal from "../hooks/useBFSigningModal";
import { SigningsContext } from "../BFSignings";
import { SORTABLE_COLUMN_MAPPER, signingsTableColumns } from "./constants";

const Styles = styled.div({
  table: {
    td: {
      ...theme.typography.body2
    },
    tbody: {
      tr: {
        cursor: 'pointer',
        ':hover': {
          boxShadow: '1px 1px 15px #ddd'
        },
        borderBottom: '1px solid #ddd'
      }
    }
  }
})


export default function SigningsTable({ hidePaginationControls=false }) {
  const { signings, columns, placeholder, pageControls, sort, setSort } = useContext(SigningsContext);

  const { handleRenderModal } = useBFSigningModal({ useSearchParams: true });

  const handleSortChange = (newSort) => {
    const unsortable = newSort.filter(s => 
      !SORTABLE_COLUMN_MAPPER.hasOwnProperty(s.id) && !signings?.[0]?.hasOwnProperty(s.id)
    );
    if (unsortable.length > 0) {
      toastError(`Can't sort on column(s) ${unsortable.map(s => s.id).join(', ')}`);
      setSort(sort);
    } else {
      setSort(newSort);
    }
  }

  if (!!placeholder) return placeholder;
  console.log(signings)
  return <Container>
    {!hidePaginationControls && pageControls}
    <Styles>
      <BDDSortableTable
        data={signings}
        columns={signingsTableColumns.filter(c => columns.includes(c.id))}
        columnJustify='start'
        manualSortBy={true}
        onSortChange={handleSortChange}
        defaultSort={sort}
        onRowClick={(row) => {
          handleRenderModal({ bfContract: row.original })
        }}
      />
    </Styles>

  </Container>
}