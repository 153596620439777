import React from 'react';
import { theme } from 'constants';
import styled from 'styled-components';
import RinknetReport from 'components/Scouting/PlayerReports/rinknetreport';
import BDDReport from 'components/Scouting/PlayerReports/bddreport';

const ReportContainer = styled.div({
  ...theme.cards.medium,
  border: '1px solid #eaeaea',
  padding: theme.spacing[1],
  marginTop: theme.spacing[5],
});

export const Report = ({ report, statsData, containerStyles = {} }) => {
  return (
    <ReportContainer key={report.id} style={containerStyles}>
      {report.__typename === 'RinknetReports' ? (
        <RinknetReport
          report={report}
          metastats={report.metastatsSplit}
          showName={true}
          statsData={statsData}
        />
      ) : report.__typename === 'BDDPlayerScoutingReport' ? (
        <BDDReport
          report={report}
          metastats={report.metastatsSplit}
          expandable={false}
          noBorder={true}
        />
      ) : null}
    </ReportContainer>
  );
};
