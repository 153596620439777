import { StatFilters } from './filters/Filters';
import { IssuesData } from './issues/IssuesData';
import { StatsControls } from './StatsControls';
import { StatsData } from './StatsData';
import { StatsTable } from './StatsTable';
import { TargetGroupToggle } from './filters/TargetGroupToggle';
import { WatchListData } from './watchLists/WatchListData';
import { ZScoreToggle } from './filters/ZScoreToggle';
import { EPStatsContextProvider } from './context';
import { ScoutingReportData } from './scouting/ScoutingReportData';

export const EPStatsRoot = ({ children, persistEnabled, ...defaults }) => {
  return (
    <EPStatsContextProvider defaults={defaults} persistEnabled={persistEnabled}>
      <StatsData />
      <WatchListData />
      <IssuesData />
      <ScoutingReportData />
      {children}
    </EPStatsContextProvider>
  );
};

export const EPStats = Object.assign(EPStatsRoot, {
  Controls: StatsControls,
  Table: StatsTable,
  Filters: StatFilters,
  TargetGroupToggle: TargetGroupToggle,
  ZScoreToggle: ZScoreToggle,
});
