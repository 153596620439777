import { theme } from 'constants';
import { useContext } from 'react';
import { WindowContext } from '../context';
import { Column, Container } from '../Layout';
import PageHeader from './PageHeader';
import { PageBody } from './PageBody';
import { PageRouter } from './PageRouter';
import { PageTabs } from './PageTabs';

const PageComponent = ({ children }) => {
  const { width, height } = useContext(WindowContext);

  return (
    <Container
      height={
        width <= theme.breakpoints.md
          ? `calc(100vh - ${theme.heights.topbar}px)`
          :  height <= theme.breakpoints.xs ? '100vh' : '100vh'
      }
    >
      <Column>{children}</Column>
    </Container>
  );
};

export const Page = Object.assign(PageComponent, {
  Header: PageHeader,
  Body: PageBody,
  Router: PageRouter,
  Tabs: PageTabs,
});
