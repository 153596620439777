import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import PlayerListController from '../PlayerLists/playerlistcontroller';
import styled from 'styled-components';
import { SectionHeader, SubSectionHeader } from '../reports';
import SelectPlayersBank from '../PlayerLists/selectplayersbank';
import { strContains } from '../../helpers/data';
import { AddListPlayersCustom } from '../PlayerLists/addlistplayerscustom';
import { BddTagFragment } from '../../apollo/queries/bddtags.queries';
import BDDTag from '../PlayerTagging/bddtag';
import { positionToAbbrev } from 'helpers/hockeyutils';
import { parseLineupSpot } from './helpers';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { LinkButton } from 'components/bdd/Button';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Row as LayoutRow } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';

const Styles = styled.div`
  font-size: 0.7em;
`;

// GQL query
const GET_DEPTHCHART_PROSPECT_RANKINGS = gql`
  query GetDCPlayerRankLists($id: ID, $teamSlug: String, $key: String) {
    bddDepthChartRankedLists(id: $id, teamSlug: $teamSlug, key: $key) {
      id
      isEditable
      depthChartPlayers {
        id
        playerSlug
        lineupSpot
        bddPlayer {
          slug
          firstname
          lastname
          roleTag {
            id
            isProjection
            tag {
              ...bddTagFragment
            }
          }
          rinknetPlayer {
            id
            progamesplayed
            nhlgamesplayed
          }
        }
      }
      rankedPlayerLists {
        id
        title
        listType
        playerList {
          id
          length
          isEditable
          players {
            id
            bddPlayerSlug
          }
        }
      }
    }
  }
  ${BddTagFragment}
`;

export default function DepthChartProspectRankings({
  depthChartId,
  activeTeamSlug,
  key = 'active',
  editable = true,
  showHeader = true,
  onPlayerClick,
  playerFields,
  showAll: showAllArg = true,
}) {
  if ((!!depthChartId && !!activeTeamSlug) || (!depthChartId && !activeTeamSlug)) {
    throw Error('One of "depthChartId" OR "activeTeamSlug" required (not both)');
  }

  const [showUnranked, setShowUnranked] = useState(false);
  const [showAll, setShowAll] = useState(showAllArg);

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_DEPTHCHART_PROSPECT_RANKINGS, {
      variables: { id: depthChartId, teamSlug: activeTeamSlug, key },
    })
  );

  const depthChart = data?.bddDepthChartRankedLists;
  const dcPlayers = depthChart?.depthChartPlayers;
  let rankedPlayerLists = depthChart?.rankedPlayerLists;
  rankedPlayerLists = !!rankedPlayerLists
    ? [...rankedPlayerLists].sort((a, b) => (a.listType === 'PRO' ? -1 : 1))
    : [];
  const playerToTag = useMemo(() => {
    const ret = {};
    if (!!data) {
      dcPlayers.forEach((p) => {
        ret[p.playerSlug] = p.bddPlayer?.roleTag;
      });
    }
    return ret;
  }, [data]);

  const playerToDepthPosition = useMemo(() => {
    const ret = {};
    if (!!data) {
      dcPlayers.forEach((p) => {
        const { depthLeague, position } = parseLineupSpot(p.lineupSpot);
        const shortPosition = positionToAbbrev(position);

        ret[p.playerSlug] = `${depthLeague} ${shortPosition}`;
      });
    }
    return ret;
  }, [data]);

  if (placeholder) return placeholder;
  const listedPlayersSet = new Set();
  rankedPlayerLists.forEach((rpl) => {
    rpl.playerList.players.forEach((p) => listedPlayersSet.add(p.bddPlayerSlug));
  });
  const unListedPlayers = dcPlayers
    .map((p) => p.bddPlayer)
    .filter((p) => !listedPlayersSet.has(p.slug));
  const proPlayers = unListedPlayers.filter(
    (p) => p.rinknetPlayer.progamesplayed >= 50 && p.rinknetPlayer.nhlgamesplayed < 150
  );
  const amateurPlayers = unListedPlayers.filter(
    (p) => p.rinknetPlayer.progamesplayed < 50
  );

  return (
    <Styles>
      {showHeader && (
        <>
          <center>
            <SectionHeader>Prospect Rankings</SectionHeader>
          </center>
          <center>
            <small>
              <em>Hot Tip: players can be dragged between lists</em>
            </small>
          </center>
        </>
      )}
      <Row>
        {rankedPlayerLists.map((rpl) => {
          const players = rpl.listType === 'PRO' ? proPlayers : amateurPlayers;
          return (
            <Col key={rpl.id}>
              <SubSectionHeader>{rpl.title}</SubSectionHeader>
              {rpl.playerList.length == 0 && <em>No players added yet</em>}
              <PlayerListController
                listId={rpl.playerList.id}
                editMode={editable}
                playerSize="sm"
                hideRightSide={true}
                renderPlayer={(player, list) => {
                  return (
                    <div
                      onClick={() => {
                        if (!!onPlayerClick) onPlayerClick(player.playerId, player);
                      }}
                    >
                      <ProspectPlayer
                        player={player}
                        roleTag={playerToTag[player.playerId]}
                        depthChartPosition={playerToDepthPosition[player.playerId]}
                      />
                    </div>
                  );
                }}
                showTitle={false}
                showDescription={false}
                showEditButton={false}
                showMenu={false}
                showAddPlayers={false}
                showCreatedBy={false}
                showChangeStatus={false}
                defaultEditMode={depthChart.isEditable}
                disableDragLayer={true} // must disable drag layer because viewing multiple lists at once
                playerFields={playerFields}
                sortAndFilterPlayers={
                  showAll
                    ? null
                    : (players) => {
                        return players.slice(0, 5).map((p) => p.player);
                      }
                }
              />
              {editable && rpl.playerList.isEditable && players.length > 0 && (
                <>
                  <LinkButton onClick={() => setShowAll(!showAll)}>
                    <Typography
                      variant="stat"
                      textAlign="center"
                      style={{ width: '100%' }}
                    >
                      <b>{!showAll ? 'Show all' : 'Hide all'}</b>
                    </Typography>
                  </LinkButton>
                  <LinkButton onClick={() => setShowUnranked(!showUnranked)}>
                    <Typography
                      variant="stat"
                      textAlign="center"
                      style={{ width: '100%' }}
                    >
                      <b>
                        {showUnranked ? 'Hide unranked players' : 'Add unranked players'}
                      </b>
                    </Typography>
                  </LinkButton>
                  {showUnranked && (
                    <AddListPlayersCustom
                      idKey="slug"
                      listId={rpl.playerList.id}
                      renderPlayer={(p) => `${p.firstname} ${p.lastname}`}
                      players={players}
                      numCols={1}
                    />
                  )}
                </>
              )}
            </Col>
          );
        })}
      </Row>
    </Styles>
  );
}

const ProspectPlayer = ({ player, roleTag, depthChartPosition }) => {
  return (
    <>
      <PlayerHover playerSlug={player.playerId}>
        <b>
          {player.firstname} {player.lastname}
        </b>{' '}
        {depthChartPosition}
      </PlayerHover>
      {!!roleTag && (
        <span style={{ float: 'right' }}>
          <BDDTag tag={roleTag.tag} isProjection={roleTag.isProjection} />
        </span>
      )}
    </>
  );
};
