import { IconButton } from 'components/bdd/Button';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { useContext } from 'react';
import { PencilSquare } from 'react-bootstrap-icons';
import { UpdatePlaylistForm } from '.';

export const UpdatePlaylistIconButton = ({ playlist }) => {
  const { renderModal, hideModal } = useContext(ModalContext);

  if (!playlist) return null;

  return (
    <IconButton
      icon={<PencilSquare />}
      hoverColor={theme.colors.light.highlight}
      onClick={() =>
        renderModal({
          size: 'xl',
          title: <Typography variant="h6">Edit Playlist</Typography>,
          body: <UpdatePlaylistForm playlist={playlist} onSubmit={hideModal} />,
        })
      }
    />
  );
};
