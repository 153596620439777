import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { BDDSigning } from './BDDSigning';
import { BDDTrade } from './BDDTrade';
import { IconButton } from 'components/bdd/Button';
import {
  BoxArrowInUpRight,
  CaretDown,
  Fullscreen,
  PlusCircle,
  Puzzle,
  Trash,
} from 'react-bootstrap-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { ModalContext, useModal } from 'components/bdd/Modal';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { buildObjectiveTransactionPageRoute } from './TransactionsPage';
import { txToVariants } from './helpers';
import { capitalize, capitalizeFirstLetter } from 'helpers/string';
import BDDEditInline from 'components/bdd/bddeditinline';
import { ProObjectiveContext } from '../ProObjectiveContextProvider';
import { AddObjectiveTradeVariant } from './AddObjectiveTradeVariant';
import { HoverInteractive } from 'components/bdd';
import { Button } from 'react-bootstrap';
import { POTradeDetail, POTxDetail } from './POTxDetail';
import useInnerScroll from 'components/bdd/hooks/useInnerScroll';
import { ScrollableRow } from 'components/bdd/ScrollableRow/ScrollableRow';
import BDDDropdown from 'components/bdd/Dropdown/Dropdown';
import Icon from 'components/bdd/Icon';
import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';
import { CreateScenario } from '../Scenarios/CreateScenario';
import { buildScenarioLink } from '../Scenarios/ScenarioCard';
import { AddObjectiveSigningVariant } from './AddObjectiveSigningVariant';
import { ScenarioContext } from '../Scenarios/ScenarioContextProvider';

export const BDDTransaction = ({
  transaction,
  modalOnClick = true,
  linkOnClick = false,
  handleClick,
  handleRemove,
  removeTooltip,
  highlightVariantId,
  showFullscreenIcon = false,
  showScenarioIcon = false,
  extraActions = [],
  canEdit = true,
  onlyShowEditSigning,
}) => {
  const history = useHistory();
  // const [variantIdx, setVariantIdx] = useState(0);
  const { modalContext, setModalContext, renderModal, hideModal, showModal } =
    useContext(ModalContext);
  const proObjCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { proObjectiveSlug, editPOTransaction } = !!proObjCtx ? proObjCtx : scenarioCtx;
  const selectedTxId = modalContext?.transactionId;

  const { renderScrollableRow } = useInnerScroll({});

  const handleRenderModal = (variantId) => {
    setModalContext((state) => ({ ...state, transactionId: transaction.id }));
    renderModal({
      size: 'xxl',
      title: (
        <Row gap={8}>
          <Typography variant="h5">{transaction.transactionType}</Typography>
          <Link
            to={buildObjectiveTransactionPageRoute(
              transaction.proObjectiveSlug,
              transaction.id
            )}
          >
            <IconButton icon={<BoxArrowInUpRight />} tooltip="Open transaction page" />
          </Link>
        </Row>
      ),
      body: (
        <Container maxHeight="80vh" overflowY="auto">
          <POTxDetail
            transaction={transaction}
            selectedVariantId={variantId}
            onlyShowEditSigning={onlyShowEditSigning}
            canEdit={canEdit}
          />
        </Container>
      ),
    });
  };

  const { renderModal: renderAddVariantModal, setShowModal: setShowAddVariantModal } =
    useModal({
      title: (
        <Typography variant="h5">
          Add a {capitalize(transaction.transactionType)} Variant
        </Typography>
      ),
      size: 'xxl',
    });

  useEffect(() => {
    if (showModal || !selectedTxId || selectedTxId != transaction.id) {
      return;
    }
    handleRenderModal(selectedTxId);
  }, [selectedTxId, transaction]);

  const handleEditTransaction = (input) => {
    editPOTransaction({
      variables: {
        proObjectiveSlug,
        input: {
          ...input,
          id: transaction.id,
        },
      },
    });
  };

  // const handleRemoveCurrVariant = () => {
  //   editPOTransaction({
  //     variables: {
  //       proObjectiveSlug,
  //       input: { id: transaction.id },
  //       variantsToRemove: [txToVariants(transaction)[variantIdx].id]
  //     }
  //   })
  // }

  const onClick = (variantId) => {
    if (!!handleClick) {
      handleClick(transaction, variantId);
    } else if (linkOnClick) {
      history.push(
        buildObjectiveTransactionPageRoute(
          transaction.proObjectiveSlug,
          transaction.id,
          variantId
        )
      );
    } else if (modalOnClick) {
      handleRenderModal(variantId);
    }
  };

  const numVariants = txToVariants(transaction).length;
  const title = !!transaction.title
    ? transaction.title
    : `Untitled ${capitalize(transaction.transactionType)}`;

  return (
    <Border onClick={() => onClick()} style={{ cursor: 'pointer' }}>
      <Container>
        <Row alignItems="center" gap={10}>
          <Container flexGrow={1} padding={1}>
            <Row justifyContent="space-between">
              <Container width={150} onClick={(ev) => ev.stopPropagation()}>
                <Row gap={2}>
                  <Typography variant="stat">
                    {numVariants} Variant{numVariants > 1 && 's'}
                  </Typography>
                  {canEdit && (
                    <IconButton
                      icon={<PlusCircle />}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        setShowAddVariantModal(true);
                      }}
                      tooltip={`Add a variant of this ${transaction.transactionType.toLowerCase()}`}
                    />
                  )}
                </Row>
              </Container>

              <Typography
                variant="body1"
                textAlign="center"
                style={{ cursor: 'auto' }}
                onClick={(ev) => ev.stopPropagation()}
              >
                {canEdit ? (
                  <BDDEditInline
                    defaultValue={title}
                    onChange={(name, ret) => handleEditTransaction({ title: ret })}
                  />
                ) : (
                  <Typography variant="body1">{title}</Typography>
                )}
              </Typography>

              <Container width={150}>
                <Row justifyContent="end" gap={16}>
                  {extraActions}
                  {showScenarioIcon && <CreateScenarioIcon transaction={transaction} />}

                  {showFullscreenIcon && (
                    <IconButton
                      tooltip={'Show transaction details'}
                      icon={<Fullscreen />}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        handleRenderModal(highlightVariantId);
                      }}
                    />
                  )}

                  {!!handleRemove && (
                    <HoverInteractive
                      onClick={(ev) => ev.stopPropagation()}
                      content={
                        <Column gap={4}>
                          <Button
                            size="sm"
                            variant="warning"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              handleRemove(transaction.id);
                            }}
                          >
                            {removeTooltip}
                          </Button>
                        </Column>
                      }
                    >
                      <IconButton
                        icon={<Trash />}
                        onClick={(ev) => ev.stopPropagation()}
                      />
                    </HoverInteractive>
                  )}
                </Row>
              </Container>
            </Row>
          </Container>
          {!!transaction.description && (
            <Typography variant="caption" textAlign="center">
              {transaction.description}
            </Typography>
          )}
        </Row>

        <Container padding={1}>
          {renderScrollableRow([...txToVariants(transaction)]
            .sort((v1, v2) =>
              v1.id == highlightVariantId ? -1 : v2.id == highlightVariantId ? 1 : 0
            )
            .map((variant, i) => (
              <Container
                key={variant.id}
                minWidth={
                  txToVariants(transaction).length === 1
                    ? '100%'
                    : transaction.transactionType === 'TRADE'
                    ? 400
                    : 200
                }
                onClick={(ev) => {
                  ev.stopPropagation();
                  onClick(variant.id);
                }}
                style={{
                  border:
                    variant.id == highlightVariantId
                      ? `2px solid ${theme.colors.states.danger}77`
                      : '1px solid #ddd',
                  transition: 'all 0.2s ease-in-out',
                  flexGrow: 1,
                }}
                hoverStyles={
                  txToVariants(transaction).length > 1
                    ? {
                        boxShadow: '0 0 10px #ddd',
                      }
                    : {}
                }
              >
                {transaction.transactionType === 'TRADE' ? (
                  <BDDTrade
                    trade={variant}
                    topLeftDecoration={
                      numVariants > 1 &&
                      `V${
                        transaction.bddTrades.findIndex((t) => t.id == variant.id) + 1
                      }`
                    }
                  />
                ) : transaction.transactionType === 'SIGNING' ? (
                  <BDDSigning
                    signing={variant}
                    topLeftDecoration={
                      numVariants > 1 &&
                      `V${
                        transaction.bddSignings.findIndex((s) => s.id == variant.id) + 1
                      }`
                    }
                  />
                ) : null}
              </Container>
            )))}
        </Container>
      </Container>

      {renderAddVariantModal(
        transaction.transactionType === 'TRADE' ? (
          <AddObjectiveTradeVariant
            transaction={transaction}
            defaultTxIdx={0}
            onCompleted={() => setShowAddVariantModal(false)}
          />
        ) : (
          <AddObjectiveSigningVariant transaction={transaction} />
        )
      )}
    </Border>
  );
};

const CreateScenarioIcon = ({ transaction }) => {
  const history = useHistory();
  const { setShowModal, renderModal } = useModal({
    title: 'Create Scenario',
    size: 'sm',
  });

  const { proObjectiveSlug, updateScenario } = useContext(ProObjectiveContext);

  const handleAttachTx = (scenarioId, txId) => {
    // attaches or changes variant on attached transaction
    updateScenario({
      variables: {
        input: {
          id: scenarioId,
          proObjectiveSlug,
        },
        transactionsToAttach: [{ id: txId }],
      },
    });
  };

  const handleCompleted = ({ createProObjectiveScenario: { scenario } }) => {
    handleAttachTx(scenario.id, transaction.id);
    setShowModal(false);
    history.push(buildScenarioLink(proObjectiveSlug, scenario.id));
  };

  return (
    <Container>
      {renderModal(
        <CreateScenario
          proObjectiveSlug={proObjectiveSlug}
          onCompleted={handleCompleted}
        />
      )}
      <IconButton
        tooltip={'Create scenario with transaction'}
        icon={<Puzzle />}
        onClick={(ev) => {
          ev.stopPropagation();
          setShowModal(true);
        }}
      />
    </Container>
  );
};
