import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { getStrengthChoices } from 'helpers/filters';
import { useContext } from 'react';
import { SLStatContext } from '..';

export const StrengthFilter = ({ selectProps }) => {
  const { setFilters, filters } = useContext(SLStatContext);

  const select = (
    <StyledBDDSelect
      size="sm"
      options={getStrengthChoices(true)}
      selectedValue={filters['strength']}
      onChange={(n, v) => {
        setFilters({ strength: v });
      }}
      selectProps={selectProps}
    />
  );

  return (
    <Container minWidth={125}>
      <Column>
        <Typography variant="label">Strength</Typography>
        {select}
      </Column>
    </Container>
  );
};
