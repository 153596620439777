import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Spinner } from 'react-bootstrap';

import { Column, Container, Row } from 'components/bdd/Layout';
import { Divider } from 'components/bdd/Divider';
import { Typography } from 'components/bdd/Typography';
import BDDInputField from 'components/form/bddinputfield';
import { GET_FILE_UPLOAD_URL } from 'apollo/queries/fileUploads.queries';
import { useLazyQuery } from '@apollo/client';
import { post_pregenerated_s3_url } from 'requests/bs3';

export const FileUploadForm = ({ folder, onCancel, onSubmit }) => {
  const [getFileUploadUrl, { data, loading }] =
    useLazyQuery(GET_FILE_UPLOAD_URL);

  const initialValues = {
    name: '',
    url: '',
    file: null,
  };

  const schema = Yup.object({
    name: Yup.string().required(),
    url: Yup.string(),
    file: Yup.object().nullable(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const fileUploadUrl = data?.fileUploadUrl;

    if (fileUploadUrl) {
      setSubmitting(true);

      post_pregenerated_s3_url(values.file, JSON.parse(fileUploadUrl.response))
        .then((res) => {
          onSubmit({
            ...values,
            s3Url: fileUploadUrl?.s3Url,
            s3Key: fileUploadUrl?.s3Key,
          });

          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
        });
    } else {
      onSubmit(values);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      onReset={onCancel}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        handleReset,
        isSubmitting,
        dirty,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Column gap={1}>
              <Field
                type="text"
                name="name"
                placeholder="Descriptive name for file"
                component={BDDInputField}
              />
              <Field
                type="text"
                name="url"
                placeholder="Url of external file (ex. from Dropbox)"
                component={BDDInputField}
              />
              <Typography variant="body1">Or</Typography>
              <div style={{ cursor: 'pointer' }}>
                <input
                  name="file"
                  type="file"
                  onChange={(ev) => {
                    const filename = ev.currentTarget.files[0].name;
                    getFileUploadUrl({
                      variables: {
                        destinationUrl: filename,
                        folder,
                        contentType: ev.currentTarget.files[0].type,
                      },
                    });
                    setFieldValue('file', ev.currentTarget.files[0]);
                  }}
                />
              </div>
              <Divider />
              <Row columnGap={1} justifyContent="end">
                {onCancel && (
                  <Button
                    size="sm"
                    variant="outline-danger"
                    type="button"
                    onClick={handleReset}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  size="sm"
                  variant="warning"
                  type="submit"
                  disabled={
                    !dirty ||
                    isSubmitting ||
                    loading ||
                    (!values.file && !values.url)
                  }
                >
                  {isSubmitting ? (
                    <Row columnGap={1}>
                      <Spinner size="sm" animation="border" />
                      Loading File...
                    </Row>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Row>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
};
