import React, { useMemo, useState } from 'react'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { HoverInteractive } from '../../bdd'
import { RANKING_SPLIT_FIELDS } from '../constants'
import { useQuery } from '@apollo/client'
import { LIST_SCOUTED_PLAYER_RANKING_SPLITS } from '../../../apollo/queries/scouting.queries'
import BDDApiError from '../../bdd/bddapierror'
import { BDDLoader } from '../../bdd/bddloader'


const StyledTable = styled.table`
    width: 100%;
    table-layout: fixed;
    text-align: center;
    font-size: 0.8em;
    border: 1px solid black;
    tr.header {
        th {
            font-size: 0.9em;
            position: sticky;
            top: -16px;
            background: white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .selected-filter {
                font-weight: normal;
            }
        }
    }
    tr {
        border: 1px solid black;
        cursor: pointer;
        :hover {
            background: #ddd;
        }
        transition: all 0.3s;
    }
    tr.selected {
        background: #dedede;
    }

`
export const ChooseSplitTable = ({ splits: splitsArg=null, currentSplitId, handleSetSplitId, showScout=false, maxHeight='300px' }) => {
    if (!!splitsArg) {
        var splits = splitsArg
        var loading = false
        var error = undefined
    } else {
        var { data, loading, error } = useQuery(LIST_SCOUTED_PLAYER_RANKING_SPLITS)
        var splits = !!data ? data.listScoutedPlayerRankingSplits : []
    }
    const [filters, setFilters] = useState({})


    var fields = RANKING_SPLIT_FIELDS
    if (showScout) fields = ['scoutName'].concat(fields)

    const isSplitSelected = s => s.id == currentSplitId

    const handleToggleFilter = (field, val) => {
        if (filters[field] == val) {
            var newFilters = { ...filters }
            delete newFilters[field]
            setFilters(newFilters)
        } else {
            setFilters({ ...filters, [field]: val })
        }
    }

    const filteredSplits = splits.filter(s => {
        for (let i=0; i<fields.length; i++) {
            const f = fields[i]
            if (!!filters[f]) {
                if (filters[f] == 'null_placeholder') {
                    if (s[f] != null) {
                        return false
                    }
                } else if (s[f] != filters[f]) {
                    return false
                }
            } 
        }
        return true
    })

    const valToLabel = val => val == 'null_placeholder' ? 'Allow All' : String(val)
    const fieldToOptions = useMemo(() => {
        const ret = {}
        if (!splits) return ret
        fields.forEach(f => ret[f] = new Set())
        splits.forEach(s => {
            fields.forEach(f => {
                const val = s[f] == null ? 'null_placeholder' : s[f]
                ret[f].add(val)
            })
        })
        return ret
    }, [filteredSplits])

    const handleSetSplit = split => handleSetSplitId(split.id)

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error}/>

    return <div>
        <div style={{maxHeight: maxHeight, position: 'relative', overflow: 'scroll', padding: '15px'}}>
        <StyledTable style={{ width: '100%', border: '1px solid black'}}>
        <tbody>
            <tr className='header'>
                {fields.map(f => <th key={f}>
                    <HoverInteractive content={<div style={{maxHeight: '200px', overflow: 'scroll', padding: '15px'}}>
                        {Array.from(fieldToOptions[f]).map(val => <div key={val} 
                            style={{ cursor: 'pointer', color: filters[f] == val ? 'black' : '#777' }}
                            onClick={() => handleToggleFilter(f, val)}
                            >
                            <span className='selected-filter'>{valToLabel(val)}</span>
                        </div>)}
                    </div>}>
                        {f}:
                        {' '}
                        {!!filters[f] ? valToLabel(filters[f])
                        : <FontAwesomeIcon style={{ color: !!filters[f] ? '#333' : '#ddd' }} icon={faFilter}/>}
                    </HoverInteractive>
                </th>)}
            </tr>
            {filteredSplits.map((split, i) => 
                <tr key={i} onClick={() => handleSetSplit(split)} className={isSplitSelected(split) ? 'selected' : ''}>
                    {fields.map(f => <td key={f}>
                        {!!split[f] ? String(split[f]) : <em>Allow All</em>}
                    </td>)}
                </tr>
            )}
        </tbody>
        </StyledTable>
        </div>
        <center><small><em>Showing {filteredSplits.length} of {splits.length} rows</em></small></center>
    </div>
}