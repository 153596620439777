import { BDDDateRangePicker } from 'components/bdd/bdddatepicker';
import { Column, Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { SLStatContext } from 'components/SLStats';
import { getDateRangeModeOptions } from 'helpers/daterangemode';
import { formatDate } from 'helpers/helpers';
import { useContext, useState } from 'react';

export const DateRangeModeFilter = ({
  includeCustom = false,
  includeCurrentSeason = true,
  includeRecentDays = true,
  includeWeeks = true,
  selectProps,
}) => {
  const { setFilters, filters } = useContext(SLStatContext);
  const [dateRange, setDateRange] = useState({
    startDate:
      filters['start_date'] ||
      (filters['strict_start_date'] ? new Date(filters['strict_start_date']) : null),
    endDate:
      filters['end_date'] ||
      (filters['strict_end_date'] ? new Date(filters['strict_end_date']) : null),
  });

  const options = getDateRangeModeOptions({
    includeCustom,
    includeCurrentSeason,
    includeRecentDays,
    includeWeeks,
  });

  const select = (
    <StyledBDDSelect
      options={options}
      selectedValue={filters['dateRangeMode']}
      onChange={(n, v) => {
        setFilters({ dateRangeMode: v });
      }}
      selectProps={selectProps}
    />
  );

  const customDateRangeSelect = (
    <BDDDateRangePicker
      selected={dateRange.startDate}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      onChange={([s, e]) => {
        setDateRange({
          startDate: s,
          endDate: e,
        });

        if (!!s && !!e) {
          setFilters({
            strict_start_date: formatDate(s),
            strict_end_date: formatDate(e),
          });
        }
      }}
      isClearable={true}
    />
  );

  return (
    <Container>
      <Column>
        <Typography variant="body1">Date Range</Typography>
        <Row columnGap={1}>
          <Container minWidth={125}>{select}</Container>
          {includeCustom && filters['dateRangeMode'] == 'CUSTOM' && (
            <Container>{customDateRangeSelect}</Container>
          )}
        </Row>
      </Column>
    </Container>
  );
};
