import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { SigningForm } from './SigningForm';
import { Divider } from 'components/bdd/Divider';
import { Typography } from 'components/bdd/Typography';
import { SimilarContractsContextProvider } from '../ContractSearch/SimilarContracts/context';
import { ContractSearchContextProvider } from '../ContractSearch/context';
import { TargetDetails } from '../ContractSearch/SimilarContracts/TargetDetails';
import { TargetVector } from '../ContractSearch/SimilarContracts/TargetVector';
import { ContractSearchFilters } from '../ContractSearch/ContractSearchFilters';
import { SimilarContractsData } from '../ContractSearch/SimilarContracts/SimilarContractsData';
import { LinkButton } from 'components/bdd/Button';
import { useState } from 'react';
import { getCurrentSeason } from 'helpers/hockeyutils';

export const CreateUpdateSigning = ({
  proObjectiveSlug,
  bddPlayerSlug,
  existingSigning,
  onCompleted,
  showSimilar,
  addToPOTransactionId, // if supplied (as an ID) will call "addPOTransactionVariant" instead of "createPOTransaction"
}) => {
  const targetDetails = {
    bddPlayerSlug: {
      id: 'bddPlayerSlug',
      value: bddPlayerSlug,
    },
    targetMode: {
      id: 'targetMode',
      value: 'future',
    },
    statsMode: {
      id: 'statsMode',
      value: 'combined',
    },
    priorSeasons: {
      id: 'priorSeasons',
      value: 0,
    },
    presets: {
      id: 'presets',
      value: 'player_type',
    },
    nNeighbours: {
      id: 'nNeighbours',
      value: 20,
    },
  };

  const [showSimilarContracts, setShowSimilarContracts] = useState(false);

  return (
    <Container>
      <Column gap={2}>
        <SigningForm
          bddPlayerSlug={bddPlayerSlug}
          proObjectiveSlug={proObjectiveSlug}
          existingSigning={existingSigning}
          onCompleted={onCompleted}
          addToPOTransactionId={addToPOTransactionId}
        />
        <Divider />
        {showSimilar && (
          <>
            <LinkButton onClick={() => setShowSimilarContracts(!showSimilarContracts)}>
              <Typography variant="body1">
                {!showSimilarContracts
                  ? 'Show similar contracts...'
                  : 'Hide similar contracts...'}
              </Typography>
            </LinkButton>
            <Container
              paddingTop={2}
              style={{ display: !!showSimilarContracts ? null : 'none' }}
            >
              <SimilarContractsContextProvider targetDetails={targetDetails}>
                <ContractSearchContextProvider
                  defaultFilters={{
                    season: {
                      id: 'season',
                      value: getCurrentSeason(),
                    },
                  }}
                >
                  <Column gap={1}>
                    <TargetDetails hideContractSelect />
                    <TargetVector />
                    <ContractSearchFilters useSearchParams={false} />
                    <SimilarContractsData />
                  </Column>
                </ContractSearchContextProvider>
              </SimilarContractsContextProvider>
            </Container>
          </>
        )}
      </Column>
    </Container>
  );
};
