import { useQuery } from '@apollo/client';
import { GET_DRAFT_PLAYER_CHECKS } from 'apollo/queries/scouting.queries';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const useDraftPlayerCheckStore = create(
  immer((set, get) => ({
    checksByRinknetId: {},
    actions: {
      loadData: (data) =>
        set((state) => {
          const checksByRinknetId = data.draftPlayerChecks.reduce((dict, dpc) => {
            if (!(dpc.rinknetId in dict)) {
              dict[dpc.rinknetId] = [];
            }

            dict[dpc.rinknetId].push(dpc);
            return dict;
          }, {});

          state.checksByRinknetId = {
            ...state.checksByRinknetId,
            ...checksByRinknetId,
          };
        }),
    },
  }))
);

export const useDraftPlayerChecks = ({ rinknetId, rinknetIds, draftYear }) => {
  const { loadData } = useDraftPlayerCheckStore((state) => state.actions);
  const { data, loading, error } = useQuery(GET_DRAFT_PLAYER_CHECKS, {
    variables: { rinknetIds, draftYear },
    skip: !rinknetIds?.length,
    onCompleted: loadData,
  });

  const checks = data?.draftPlayerChecks?.filter((c) => c.rinknetId == rinknetId);
  return {
    checks,
    allChecks: data?.draftPlayerChecks,
    loading,
    error,
  };
};
