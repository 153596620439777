import { theme } from 'constants';
import styled from 'styled-components';

// Necessary evil until we can eventually remove bootstrap
export const NoColorLink = styled.a(({ textDecoration }) => ({
  color: theme.colors.light.text.primary,
  textDecoration: 'none',
  '&:hover': {
    color: theme.colors.light.text.primary,
    textDecoration: textDecoration || 'none',
  },
}));
