import { useButtonGroup } from 'components/bdd/ButtonGroup';
import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { DepthChartSnapshotForm } from './DepthCharts/DepthChartSnapshotForm';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import { GET_BDD_DEPTH_CHART_KEYS } from 'apollo/queries/depthchart.queries';

export const useDepthChartButtonGroup = ({ teamSlug, initialSelectedValue='active' }) => {
  const { renderModal, hideModal } = useContext(ModalContext);
  const { data, loading } = usePlaceholder(useQuery(GET_BDD_DEPTH_CHART_KEYS));
  const depthChartKeyOptions =
    data?.bddDepthChartKeys
      .filter((d) => d.key != 'active' && d.key != 'future')
      .map((d) => ({
        value: d.key,
        label: d.name,
      })) || [];

  const {
    buttonGroup: depthChartButtonGroup,
    selectedValue: selectedDepthChartKey,
    ...rest
  } = useButtonGroup({
    initialSelectedValue,
    loading,
    options: [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'future',
        label: 'Future',
      },
      {
        label: 'Snapshots',
        options: [
          ...depthChartKeyOptions,
          {
            renderOption: () => {
              return (
                <Container
                  onClick={() => {
                    renderModal({
                      title: (
                        <Typography variant="h6">Add Depth Chart Snapshot</Typography>
                      ),
                      body: (
                        <Container padding={1}>
                          <DepthChartSnapshotForm
                            onSubmit={hideModal}
                            teamSlug={teamSlug}
                          />
                        </Container>
                      ),
                    });
                  }}
                >
                  <Row columnGap={1}>
                    <Icon icon={<Plus />} />
                    <Typography variant="body1">Add new snapshot</Typography>
                  </Row>
                </Container>
              );
            },
          },
        ],
      },
    ],
  });

  return {
    depthChartButtonGroup,
    selectedDepthChartKey,
    ...rest,
  };
};
