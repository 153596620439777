import React from 'react';
import styled from 'styled-components';
import { Clock, ChatDots, Person } from 'react-bootstrap-icons';

import { theme } from 'constants';
import BDDTag from 'components/PlayerTagging/bddtag';
import { dateToTimeAgo } from 'helpers/helpers';
import {
  issuePriorityToColor,
  issuePriorityToLabel,
  issueStatusToColor,
  issueStatusToLabel,
  issueTypeToLabel,
} from '../helpers';
import { Row } from 'components/bdd/Layout';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing[3],
});

const TagContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Description = styled.div({
  ...theme.typography.body2,
});

const IconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing[1],
});

export const IssueHeader = ({ issue }) => {
  const date = dateToTimeAgo(issue.lastModifiedDate || issue.createdDate);

  return (
    <Container>
      <TagContainer>
        <BDDTag
          tag={{
            color: theme.colors.dark.highlight,
            textColor: theme.colors.dark.text.primary,
            tag: issueTypeToLabel[issue.issueType],
            description: issueTypeToLabel[issue.issueType],
          }}
        />
        <BDDTag
          tag={{
            color: issueStatusToColor[issue.status],
            textColor: theme.colors.dark.text.primary,
            tag: issueStatusToLabel[issue.status],
            description: issueStatusToLabel[issue.status],
          }}
        />
        <BDDTag
          tag={{
            color: issuePriorityToColor[issue.priority],
            textColor: theme.colors.light.text.primary,
            tag: issuePriorityToLabel[issue.priority],
            description: issuePriorityToLabel[issue.priority],
          }}
        />
      </TagContainer>
      <Row flexWrap columnGap={3} rowGap={1}>
        <IconContainer>
          <Clock />
          <Description>Last modified {date}</Description>
        </IconContainer>
        <IconContainer>
          <Person />
          <Description>
            {issue.assigneeUserId
              ? `Assigned to ${issue.assigneeUser.username}`
              : 'No user assigned'}
          </Description>
        </IconContainer>
        <IconContainer>
          <ChatDots />
          <Description>
            {issue.comments?.length > 0
              ? `${issue.comments.length} comments`
              : 'No comments'}
          </Description>
        </IconContainer>
      </Row>
    </Container>
  );
};
