import { useMutation } from '@apollo/client';
import {
  GET_PLAYER_WATCH_LIST_POSITIONS,
  UPDATE_PLAYER_LIST,
} from 'apollo/queries/playerlist.queries';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { NumberInput } from 'components/bdd/NumberInput/NumberInput';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { toastBddApiError } from 'components/bdd/bddtoasts';
import { getCurrentDraftYear } from 'helpers/hockeyutils';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export const AddToWatchList = ({ rinknetId, watchLists, league, onClose }) => {
  const currentDraftYear = getCurrentDraftYear() + 1;
  const matchingWatchList = watchLists.find(
    (wl) =>
      wl.name.toLowerCase().includes(currentDraftYear.toString().toLowerCase()) &&
      wl.name.toLowerCase().includes(league.toLowerCase())
  );

  const [listNumber, setListNumber] = useState(matchingWatchList?.length || 1);
  const { selected, select } = useSelect({
    variant: 'outlined',
    size: 'sm',
    options: watchLists.map((wl) => ({
      value: wl.id,
      label: wl.name,
      length: wl.length,
    })),
    initialSelectedValue: matchingWatchList?.id,
  });

  useEffect(() => {
    if (!selected) return;
    setListNumber(selected.length + 1);
  }, [selected?.value]);

  const [updatePlayerlist, { loading: updateLoading }] = useMutation(UPDATE_PLAYER_LIST, {
    onError: (error) => toastBddApiError(error),
    onCompleted: () => {
      if (onClose) onClose();
    },
  });

  const handleAdd = () => {
    updatePlayerlist({
      variables: {
        input: { id: selected.value },
        playersToAdd: [{ id: rinknetId, listNumber }],
      },
      refetchQueries: [GET_PLAYER_WATCH_LIST_POSITIONS],
    });
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Column gap={1}>
        <Row columnGap={1}>
          <Typography variant="label">Watch list:</Typography>
          {select}
        </Row>
        {selected && (
          <NumberInput
            min={1}
            max={selected.length + 1}
            label={'List number: '}
            value={listNumber}
            variant="label"
            horizontal
            onChange={(e) => setListNumber(e.target.value)}
          />
        )}
        <Divider />
        <Row justifyContent="end" columnGap={2}>
          <Button variant="secondary" size="sm" disabled={!selected} onClick={onClose}>
            <Typography variant="stat">
              <b>Close</b>
            </Typography>
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={!selected || updateLoading}
            onClick={handleAdd}
          >
            <Typography variant="stat">
              <b>{updateLoading ? 'Adding...' : 'Add'}</b>
            </Typography>
          </Button>
        </Row>
      </Column>
    </Container>
  );
};
