import { DecoratedBDDInput } from "../Input";
import { Column, Row } from "../Layout";


export default function RangeInputBoxes({
  name: nameArg,
  label,
  field,
  value: valueArg,
  onChange: onChangeArg,
  onChangeComplete,
  greaterThan='>=',
  lessThan='<=',
  backDecoration,
  inputStyles={}
}) {
  const { value, onChange, name } = field || { name: nameArg, onChange: onChangeArg, value: valueArg };
  const min = value.min;
  const max = value.max;

  const handleChange = (ev) => {
    const val = ev.target.value;
    if (ev.target.name.endsWith('min')) {
      onChange({ target: { name,  value: { min: val, max }}})
    } else {
      onChange({ target: { name,  value: { min, max: val }}})
    }
  }

  const handleBlur = (ev) => {
    !!onChangeComplete && onChangeComplete({ min, max })
  }

  return <Column>
    {!!label && label}
    <Row columnGap={2}>
      <DecoratedBDDInput
        name={`${name}-min`}
        frontDecoration={<div style={{fontSize: '0.8em', whiteSpace: 'nowrap'}}>{greaterThan}</div>}
        backDecoration={backDecoration}
        type='number'
        value={min || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{textAlign: 'center', ...inputStyles}}
      />
      <DecoratedBDDInput
        name={`${name}-max`}
        frontDecoration={<div style={{fontSize: '0.8em', whiteSpace: 'nowrap'}}>{lessThan}</div>}
        backDecoration={backDecoration}
        type='number'
        value={max || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{textAlign: 'center', ...inputStyles}}
      />
    </Row>
  </Column>
}