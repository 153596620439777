import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { create, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { SearchParams } from 'helpers/searchParams';
import { buildIdToQueryFilters } from './helpers';
import { buildQueryFilters } from 'components/bdd/Filters';
import { defaultColumnIds, defaultFilters } from './defaults';
import { GOALIE_COLUMNS_KEY, SKATER_COLUMNS_KEY } from './constants';
import { createJSONStorage, persist } from 'zustand/middleware';
import { buildSetActions, buildSearchParamStorage } from 'helpers/zustand';
import localforage from 'localforage';

const ContractSearchContext = createContext();

const createStore = ({
  defaultFilters: defaultFiltersArg,
  defaultTimeRange,
  defaultStatFilters = [],
  contractList,
  columnIdsToDisplay,
  useSearchParams = true,
  persistKey,
}) => {
  const initialState = {
    filters: defaultFiltersArg || defaultFilters,
    appliedFilters: {},
    columnIdsToDisplay:
      columnIdsToDisplay?.length > 0 ? columnIdsToDisplay : defaultColumnIds,
    filtersApplied: false,
    selectedContracts: [],
    sort: null,
    data: null,
    statData: null,
    timeRange: defaultTimeRange || {
      'time-range': {
        id: 'time-range',
        type: 'select',
        value: 'platform-stats',
      },
    },
    statFilters: defaultStatFilters,
    recentFilters: [],
    contractList,
  };

  let store = immer((set, get) => ({
    ...initialState,
    actions: {
      ...buildSetActions(set, initialState),
      setFilters: (filters) => {
        set((state) => {
          state.filters = { ...filters };
          state.filtersApplied = false;
        });
      },
      applyFilters: () => {
        set((state) => {
          state.appliedFilters = get().filters;
          state.variables = {
            filters: buildQueryFilters(state.filters, buildIdToQueryFilters),
            timeRange: buildQueryFilters(state.timeRange, buildIdToQueryFilters),
            sort: state.sort,
            statFilters: state.statFilters,
          };
          state.filtersApplied = true;
          const newFilter = {
            id:
              JSON.stringify(state.filters) +
              JSON.stringify(state.timeRange) +
              JSON.stringify(state.statFilters),
            filters: state.filters,
            statFilters: state.statFilters,
            timeRange: state.timeRange,
            timestamp: new Date(),
          };

          state.recentFilters = [
            newFilter,
            ...state.recentFilters.filter((filter) => filter.id !== newFilter.id),
          ].slice(0, 5);
        });
      },
    },
  }));

  if (!!persistKey) {
    store = persist(store, {
      name: persistKey,
      storage: createJSONStorage(() =>
        useSearchParams
          ? buildSearchParamStorage({ ignoreKeys: ['recentFilters'] })
          : localforage
      ),
      partialize: (state) => {
        return {
          filters: state.filters,
          timeRange: state.timeRange,
          statFilters: state.statFilters || [],
          recentFilters: state.recentFilters || [],
        };
      },
      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            console.log('an error happened during hydration', error);
          } else {
            state.actions.applyFilters();
          }
        };
      },
    });
  }

  return create(store);
};

export const ContractSearchContextProvider = ({
  defaultFilters,
  defaultTimeRange,
  contractList,
  children,
  useSearchParams = true,
  persistKey,
}) => {
  const searchParams = new SearchParams();
  const columnIdsToDisplay = searchParams.getArray('columnIdsToDisplay');
  const storedColumnIdsToDisplay =
    localStorage.getItem(SKATER_COLUMNS_KEY)?.split(',') || [];

  const store = useRef(
    createStore({
      defaultFilters: defaultFilters,
      defaultTimeRange: defaultTimeRange,
      defaultStatFilters: [],
      contractList,
      columnIdsToDisplay:
        columnIdsToDisplay.length > 0 ? columnIdsToDisplay : storedColumnIdsToDisplay,
      useSearchParams,
      persistKey,
    })
  ).current;

  return (
    <ContractSearchContext.Provider value={store}>
      {children}
      <ContractListListener contractList={contractList} />
      <PositionListener />
    </ContractSearchContext.Provider>
  );
};

export const useContractSearchContext = (selector) =>
  useStore(useContext(ContractSearchContext), selector);

export const useContractSearchFilterValues = () =>
  useContractSearchContext((state) =>
    Object.fromEntries(Object.values(state.filters).map((f) => [f.id, f.value]))
  );

const ContractListListener = ({ contractList }) => {
  const { setContractList } = useContractSearchContext((state) => state.actions);
  useEffect(() => {
    setContractList(contractList);
  }, [contractList]);
};

const PositionListener = ({}) => {
  const [initialPosition, setInitialPosition] = useState();
  const { position } = useContractSearchFilterValues();
  const filtersApplied = useContractSearchContext((state) => state.filtersApplied);
  const { setColumnIdsToDisplay } = useContractSearchContext((state) => state.actions);

  useEffect(() => {
    if (!position || !filtersApplied) return;
    if (!initialPosition) {
      setInitialPosition(position);
      return;
    }

    const columnKey = position == 'G' ? GOALIE_COLUMNS_KEY : SKATER_COLUMNS_KEY;
    const storedColumnIdsToDisplay = localStorage.getItem(columnKey)?.split(',') || [];

    setInitialPosition(position);
    setColumnIdsToDisplay(
      storedColumnIdsToDisplay.length > 0 ? storedColumnIdsToDisplay : defaultColumnIds
    );
  }, [position]);
};
