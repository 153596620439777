import { GameShiftVideoButton } from 'components/PlayersV2/ByGame/GameShiftVideoButton';
import { GameShiftVideoProvider } from 'components/PlayersV2/ByGame/GameShiftVideoProvider';
import { useGameDeploymentModal } from 'components/PlayersV2/Deployment/useGameDeploymentModal';
import { Container, Row } from 'components/bdd/Layout';

export const GameExtrasHeader = ({ data }) => {
  const { modal: deploymentModal, renderDeploymentButton } = useGameDeploymentModal({});

  return (
    <Row columnGap={1}>
      {deploymentModal}
      <GameShiftVideoProvider>
        <GameShiftVideoButton
          gameUid={data.game_uid}
          playerId={data.player_id}
          leagueSlug={data.league_slug}
          season={data.season}
          gameType={data.game_type}
        />
      </GameShiftVideoProvider>
      <Container>
        {renderDeploymentButton({
          slug: data.slug,
          leagueSlug: data.league_slug,
          seasons: [data.season],
          gameType: data.game_type,
          gameUid: data.game_uid,
          playerName: data.Player,
        })}
      </Container>
    </Row>
  );
};
