import { useContext } from 'react';
import { DropdownFilter, EventFilterContext } from '.';
import { VideoContext } from '../..';
import { applyPlayerFilter, getPlayerInvolvement } from '../helpers';

export const OnIceToggle = ({}) => {
  const { slPlayer } = useContext(VideoContext);
  const { filters, setEventFilters } = useContext(EventFilterContext);

  const selectedValue = getPlayerInvolvement(filters)

  const options = [
    {
      label: 'Primary Player',
      value: 'primary',
    },
    {
      label: 'While On Ice',
      value: 'woi',
    },
    {
      label: 'While On Ice Against',
      value: 'woia',
    },
  ];

  if (!slPlayer) return null;

  return (
    <DropdownFilter
      label={'Involvement'}
      value={selectedValue}
      options={options}
      onChange={(value) => setEventFilters(applyPlayerFilter(slPlayer, value, {}))}
    />
  );
};
