import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CompareDraftPlayersModal from 'components/DraftLists/comparedraftplayersmodal';
import { useAnalyticUploadData } from '../hooks/useAnalyticUploadData';
import { useEPData } from '../hooks/useEPData';

export const ComparePlayersModal = ({ list }) => {
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { placeholder: analyticPlaceholder, playerToAnalytics } =
    useAnalyticUploadData(list);

  const { placeholder: epPlaceholder, playerToEP } = useEPData();

  const hideModals = () => history.push(pathname);

  return (
    <CompareDraftPlayersModal
      list={list}
      show={hash.startsWith('#compare')}
      handleClose={() => hideModals()}
      playerToAnalytics={playerToAnalytics}
      analyticLoading={analyticPlaceholder}
      playerToEP={playerToEP}
      epLoading={epPlaceholder}
    />
  );
};
