import { useEffect } from 'react';
import { CheckboxGroupFilter, EventFilterContext } from '.';
import { getCombinedOutcomeOptions } from '../helpers';
import { useContext } from 'react';
import { VideoContext } from '../..';

export const OutcomeFilter = ({ readonly }) => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name']?.val;
  if (!eventNames) return null;

  return (
    <CheckboxGroupFilter
      col="outcome"
      id="outcome"
      label={'Outcome'}
      condAny="includes_substr"
      options={getCombinedOutcomeOptions(eventNames)}
      readonly={readonly}
    />
  );
};
