import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Tab, Nav, Row, Col, Button, Spinner, Collapse } from "react-bootstrap"

import { useUser } from "../../helpers/user"
import { BDDLoader } from "../bdd/bddloader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const ListViewerContainer = styled(Row)`
    min-height: 200px;
    width: 100%;

    .nav-pills {
        box-shadow: 2px 0px 5px #777;
    }

    .nav-item a {
        border-radius: 0px;
        border: 1px solid #333;
        background-color: #fff;
        color: #333;
        :hover {
            background-color: #777;
            color: #fff;  
        }
    }
    .nav-item a.active,
    .nav-item a.active:hover,
    .nav-item a.active:focus {
        background-color: #333;
        color: #fff;
    }
`

const ListTabStyle = styled.div`
    margin: 2px;
    padding: 10px 2px 10px 2px;
    width: 100%;

    ${props => props.isActive ? `
    background: #DDD;
    color: #333;
    ` : `
    background: #333;
    color: #DDD;
    `}
    
    font-weight: bold;
    font-size: 0.9em;
`


const GET_PLAYER_LISTS = gql`query GetPlayerLists(
    $userId: ID!,
    $includePublic: Boolean
) {
    playerLists(userId: $userId, includePublic: $includePublic) {
        id
        name
        description
        public
        userId
        user {
            id
            firstname
            lastname
            email
        }
        players {
            slug
            firstname
            lastname
        }
    }
}`

const UPDATE_PLAYER_LIST = gql`mutation UpdatePlayerList(
    $input: UpdatePlayerListInput!,
    $playersToAdd:[String!],
    $playersToRemove:[String!]
) {
  updatePlayerList(input: $input, playersToAdd: $playersToAdd, playersToRemove: $playersToRemove) {
    playerList {
      id
      userId
      name
      description
      public
      isActive
      bddList
      players {
        firstname
        lastname
        slug
      }
      user {
        id
        firstname
        lastname
        email
      }
    }
  }
}`


export default function ListViewer({ selectedPlayers, setSelectedPlayers, setCreateListMode }) {
    const [activeKey, setActiveKey] = useState(null)
    const [showInfo, setShowInfo] = useState(false)
    const { user } = useUser();
    const { data, loading, error } = useQuery(GET_PLAYER_LISTS, {variables: { userId: user?.id } })
    const [addPlayersToList, { loading: addingPlayers, error: addPlayersError }] = useMutation(UPDATE_PLAYER_LIST)
    const [removePlayersFromList, { loading: removingPlayers, error: removePlayersError }] = useMutation(UPDATE_PLAYER_LIST)

    useEffect(() => {
        if (!!data && data.playerLists && data.playerLists.length) {
            setActiveKey(data.playerLists[0].id)
        }
    }, [loading]);

    if (loading) return <BDDLoader/>

    const handleAddPlayers = (listId, playerSlugs) => {
        addPlayersToList({ variables: { 
            input: {id: listId},
            playersToAdd: playerSlugs
        }})
    }

    const removePlayerButton = (listId, playerSlug) => <span
        style={{cursor: 'pointer'}}
        onClick={() => {
            removePlayersFromList({ variables: {
                input: {id: listId},
                playersToRemove: [playerSlug]
            }})
        }}>
            <FontAwesomeIcon icon={faTimes} />
        </span>

    const myLists = data.playerLists.filter(l => l.userId === user?.id)
    const publicLists = data.playerLists.filter(l => l.userId != user?.id)

    const listToUser = l => l.user.firstname && l.user.lastname ? 
        `${l.user.firstname} ${l.user.lastname}`
        : l.user.email ? l.user.email
        : 'Unknown User'
    return <>
        <div style={{textAlign: 'center'}}>
            <b>Selected Players</b>
        </div>
        <small>
        <a href="" onClick={ev => {
            ev.preventDefault()
            setShowInfo(!showInfo)
        }}>
            {showInfo ? 'Hide' : 'Explain...' }
        </a>
        <Collapse in={showInfo}>
        <div>
            <div>
                Use Player Lists to store groups of players and quickly load them later.
                Lists are accessible from many different parts of the BruinsDataDen.
                From this List Management Tool you can...
            </div>
            <ul>
                <li>Create a new list with players selected from the "Players" dropdown (close this popup) </li>
                <li>Add your selected players to an existing list (below)</li>
                <li>Or load players from one of the lists below 
                    (n.b., if a player doesn't show up in your stats query,
                    they still won't show up after you load them from a list)
                </li>
            </ul>
            
        </div>
        </Collapse>
        </small>
        <div>
            <ul>
                {selectedPlayers.map(p => <li key={p.slug}>
                    {p.name} ({p.currentteam_shorthand})
                </li> )}
            </ul>
        </div>
        
        {selectedPlayers.length === 0 ? 
            <div><em>
                No players selected -- Make some selections in the "Players" dropdown
                if you want to make a new list or add to an existing list
            </em></div>
        : <Button size="sm" variant="outline-dark" onClick={setCreateListMode}>
                Create List w/ Players
            </Button>
        }

        <hr></hr>
        <div style={{textAlign: 'center'}}>
            <b>Player Lists</b>
        </div>
        <Tab.Container id="player-lists-pills" activeKey={activeKey}>
        <ListViewerContainer>
            <Col sm={4}>
            <div><b>My Lists</b></div>
            {myLists.length === 0 ? <em>You don't have any lists yet</em> : null}
            <Nav variant="pills" className="flex-column">
                {myLists.map(l => <Nav.Item key={`tab-${l.id}`}>
                    <Nav.Link eventKey={l.id} onClick={() => setActiveKey(l.id)}>
                        {l.name}
                    </Nav.Link>
                </Nav.Item>)}
            </Nav>
            <div><b>Public Lists</b></div>
            <Nav variant="pills" className="flex-column">
                {publicLists.map(l => <Nav.Item key={`tab-${l.id}`}>
                    <Nav.Link eventKey={l.id} onClick={() => setActiveKey(l.id)}>
                        {l.name}
                        <div><small>{listToUser(l)}</small></div>
                    </Nav.Link>
                </Nav.Item>)}
            </Nav>
            </Col>
            <Col sm={8}>
            <Tab.Content>
                {myLists.concat(publicLists).map(l => <Tab.Pane key={`content-${l.id}`} eventKey={l.id}>
                    <Row>
                        <Col md="auto">
                            <div><b>{l.name}</b></div>
                            <div><small>
                                Created by {listToUser(l)}
                            </small></div>
                            <div style={{color: l.public ? 'red' : 'black'}}>
                                <small><em>This list is {l.public ? 'public': 'private'}</em></small>
                            </div>
                            <br></br>
                            <div><small>{l.description}</small></div>
                        </Col>
                        <Col md="auto">
                            {l.players.map(p => <li key={p.slug}>
                                {p.firstname} {p.lastname} | {removePlayerButton(l.id, p.slug)}
                            </li>)}
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            {selectedPlayers.length ?
                            <div>
                                <Button 
                                    size="sm" 
                                    variant="outline-dark"
                                    onClick={() => handleAddPlayers(l.id, selectedPlayers.map(p => p.slug))}
                                >
                                    {addingPlayers ? <>
                                        <Spinner animation="border" as="span" size="sm"/>
                                        Adding Players...
                                    </> : 'Add Selected Players to List'
                                    }
                                </Button>
                            </div> : null }
                        </Col>
                        <Col>
                            <div>
                                <Button 
                                    size="sm" 
                                    variant="outline-dark"
                                    onClick={() => setSelectedPlayers(l.players.map(p => p.slug))}
                                >
                                    Load Players from List
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </Tab.Pane>)}
            </Tab.Content>
            </Col>
        </ListViewerContainer>
        </Tab.Container>
    </>
}