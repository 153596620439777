import { useQuery } from '@apollo/client';
import {
  buildPlayersFromListQuery,
  buildRinknetDraftListQuery,
} from 'apollo/queries/playerlist.queries';

export const usePlayersFromLists = ({ lists }) => {
  const bddPlayerListIds = lists
    ?.filter((tl) => tl.listType != 'RINKNET_DRAFT_LIST')
    .map((tl) => tl.id);

  const rinknetDraftListIds = lists
    ?.filter((tl) => tl.listType == 'RINKNET_DRAFT_LIST')
    .map((tl) => tl.id);

  const { data: playerListData } = useQuery(buildPlayersFromListQuery(), {
    variables: { listIds: bddPlayerListIds },
    skip: !bddPlayerListIds || bddPlayerListIds.length == 0,
  });

  const { data: rinknetDraftListData } = useQuery(
    buildRinknetDraftListQuery({ includePlayerData: true }),
    {
      variables: { listIds: rinknetDraftListIds },
      skip: !rinknetDraftListIds || rinknetDraftListIds.length == 0,
    }
  );

  const bddPlayerListOptions = playerListData?.playerLists.map((pl) => ({
    ...pl,
    length: pl.players.length,
    value: pl.id,
    label: pl.name,
  }));

  const rinknetDraftListOptions = rinknetDraftListData?.rinknetDraftLists.map((rdl) => ({
    ...rdl,
    value: rdl.id,
    label: `${rdl.description} by ${rdl.scoutFirstName} ${rdl.scoutLastName}`,
    length: rdl.players.length,
    tierOrderedPlayers: [
      {
        tier: null,
        players: rdl.players
          .filter((p) => !!p.rinknetPlayer)
          .map((p) => ({ playerId: p.rinknetPlayer.realId })),
      },
    ],
  }));

  return {
    listsWithPlayers: (bddPlayerListOptions || []).concat(rinknetDraftListOptions || []),
  };
};
