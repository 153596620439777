import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { theme } from 'constants';

import { getColorWithOpacity } from 'helpers/plotting';
import { IconButton } from '../../Button';
import {
  PauseFill,
  PlayFill,
  VolumeMuteFill,
  VolumeUpFill,
} from 'react-bootstrap-icons';

const Controls = styled.div({
  ...theme.borders.roundedMedium,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
  backgroundColor: getColorWithOpacity(theme.colors.dark.background, 0.6),
  padding: theme.spacing[2],
  opacity: 0,
  transition: 'all 0.3s ease-in-out',
});

const VideoContainer = styled.div({
  position: 'relative',
});

const VideoContainerWithHover = styled(VideoContainer)`
  &:hover ${Controls} {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
`;

const ControlContainer = styled.div(({ videoWidth }) => ({
  position: 'absolute',
  width: '100%',
  ...(videoWidth >= 300 && {
    bottom: videoWidth >= 500 ? theme.spacing[8] : theme.spacing[4],
    padding: videoWidth >= 500 ? theme.spacing[8] : theme.spacing[4],
  }),
}));

const Input = styled.input({
  cursor: 'pointer',
  width: '80%',
});

const VideoPlayerSmall = ({ 
    src,
    videoRef,
    playerState,
    handleOnTimeUpdate,
    togglePlay,
    toggleMute,
    handleVideoProgress,
  }) => {
    
  return (
    <div>
      <VideoContainerWithHover>
        <video
          style={{ width: '100%', height: 'auto' }}
          src={src}
          ref={videoRef}
          onTimeUpdate={handleOnTimeUpdate}
        />
        <ControlContainer videoWidth={videoRef?.current?.offsetWidth}>
          <Controls>
            <IconButton
              icon={playerState.isPlaying ? <PauseFill /> : <PlayFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={30}
              onClick={togglePlay}
            />
            <IconButton
              icon={playerState.isMuted ? <VolumeMuteFill /> : <VolumeUpFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={30}
              onClick={toggleMute}
            />
            <Input
              type="range"
              min="0"
              max="100"
              value={playerState.progress}
              onChange={(e) => handleVideoProgress(e)}
            />
          </Controls>
        </ControlContainer>
      </VideoContainerWithHover>
    </div>
  );
};

export default VideoPlayerSmall;
