import { IconButton } from "components/bdd/Button"
import { Typography } from "components/bdd/Typography"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { People } from "react-bootstrap-icons"
import { TeammatesOpponentsContent } from "./TeammatesOpponents"


export const useGameDeploymentModal = ({}) => {

  const [state, setState] = useState({
    show: false
  })


  const renderDeploymentButton = ({
    slug,
    leagueSlug,
    seasons,
    gameType,
    gameUid,
    playerName
  }) => {
    return <IconButton
      onClick={() => {
        setState({
          show: true,
          slug,
          leagueSlug,
          seasons,
          gameType,
          gameUid,
          playerName
        })
      }}
      icon={<People/>}
      tooltip='Deployment data'
    />
  }


  const modal = state.show && <Modal 
    show={state.show} 
    onHide={() => setState({ ...state, show: false })}
    size='xl'
  >
    <Modal.Header closeButton>
      <Typography variant='h5'>
        {state.playerName} Deployment
      </Typography>
    </Modal.Header>
    <Modal.Body>
      <TeammatesOpponentsContent
        {...state}
      />
    </Modal.Body>
  </Modal>

  return {
    renderDeploymentButton,
    modal
  }
}