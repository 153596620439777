import { useQuery } from '@apollo/client';
import { GET_SL_EVENTS, GET_SL_PLAYSEQUENCE_EVENTS } from 'apollo/queries/events.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import Icon from 'components/bdd/Icon';
import { BDDInput } from 'components/bdd/Input';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useModal } from 'components/bdd/Modal';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { HoverInteractive } from 'components/bdd/bddcomponents';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { theme } from 'constants';
import { formatGameTime } from 'helpers/helpers';
import { bruinsGold } from 'helpers/plotting';
import { camelToSnakeCase, capitalize, snakeToCamelCase } from 'helpers/string';
import { useEffect, useState } from 'react';
import { ArrowsExpand, Clipboard } from 'react-bootstrap-icons';
import styled from 'styled-components';

const Styles = styled.div({
  table: {
    th: {
      ...theme.typography.body1,
      minWidth: '50px',
    },
    td: {
      ...theme.typography.body2,
      border: '1px solid #ddd',
      paddingLeft: '2px',
      paddingRight: '2px',
      textAlign: 'center',
    },
    'tr.focus': {
      boxShadow: `inset 0 0 5px ${bruinsGold}`,
    },
  },
});

export const EventHover = ({ event, children }) => {
  const { renderModal, showModal, setShowModal } = useModal({
    title: 'Sportlogiq Events',
    size: 'xxl',
  });

  // useEffect(() => {
  //   setShowModal(true)
  // }, [])

  return (
    <Container>
      {showModal && renderModal(<SLEventWindow focusEvent={event} />)}
      <HoverInteractive
        delay={{ show: 500, hide: 100 }}
        content={
          <Container maxWidth={500}>
            <Row gap={8}>
              <button
                style={{ fontSize: '0.8em', background: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(JSON.stringify(event, null, 2));
                }}
              >
                <Clipboard />
              </button>
              <button
                style={{ fontSize: '0.8em', background: 'none' }}
                onClick={() => setShowModal(true)}
              >
                <Icon icon={<ArrowsExpand />} tooltip="See SL Events surrounding" />
              </button>
            </Row>
            <pre style={{ maxHeight: '300px' }}>{JSON.stringify(event, null, 2)}</pre>
          </Container>
        }
      >
        {children}
      </HoverInteractive>
    </Container>
  );
};

const SLEventWindow = ({ focusEvent }) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'sl',
    options: [
      { label: 'SL', value: 'sl' },
      { label: 'BDD', value: 'bdd' },
    ],
  });
  return (
    <Container>
      {buttonGroup}
      {selectedValue === 'sl' ? (
        <SLEventTable focusEvent={focusEvent} />
      ) : (
        <BDDEventTable focusEvent={focusEvent} />
      )}
    </Container>
  );
};

const getCol = (key) => ({
  id: key,
  Header: camelToSnakeCase(key)
    .replace('event', '')
    .replace('_', ' ')
    .split(' ')
    .map(capitalize)
    .join(' '),
  accessor: (d) => d[key],
});

const BDDEventTable = ({ focusEvent }) => {
  const defaultColumns = [
    getCol('eventId'),
    {
      id: 'game_time',
      Header: 'Game Time',
      accessor: (d) => formatGameTime(d.gameTime, true),
    },
    getCol('teamSlug'),
    {
      id: 'player',
      Header: 'Player',
      accessor: (d) =>
        !!d.player?.firstname ? d.player.firstname + ' ' + d.player.lastname : '',
    },
    getCol('eventName'),
    getCol('outcome'),
    getCol('eventType'),
    getCol('eventFlags'),
    getCol('precedingFlags'),
    getCol('successiveFlags'),
  ];

  const { table } = useEventTable({
    type: 'bdd',
    key: 'getSlEvents',
    query: GET_SL_EVENTS,
    defaultColumns,
    focusEvent,
    filters: [{ col: 'game_uid', val: focusEvent.gameUid }],
  });

  return table;
};

const SLEventTable = ({ focusEvent }) => {
  const columns = [
    getCol('eventId'),
    {
      id: 'game_time',
      Header: 'Game Time',
      accessor: (d) => formatGameTime(d.gameTime, true),
    },
    getCol('team'),
    {
      id: 'name',
      Header: 'Player',
      accessor: (d) =>
        !!d.playerLastName ? d.playerFirstName + ' ' + d.playerLastName : '',
    },
    getCol('eventName'),
    getCol('eventType'),
    getCol('eventOutcome'),
    getCol('eventZone'),
    getCol('eventFlags'),
  ];

  const { table } = useEventTable({
    query: GET_SL_PLAYSEQUENCE_EVENTS,
    key: 'getSlPlaysequenceEvents',
    focusEvent,
    type: 'sl',
    defaultColumns: columns,
    filters: [
      { col: 'season', val: focusEvent.slGame.season },
      { col: 'game_id', val: focusEvent.slGame.gameId },
    ],
  });

  return table;
};

const useEventTable = ({
  type = 'sl',
  query,
  key,
  defaultColumns,
  focusEvent,
  filters, // not including game_time, that is managed here
}) => {
  const [lead, setLead] = useState(5);
  const [tail, setTail] = useState(5);
  const queryFilters = [
    ...filters,
    { col: 'game_time', val: focusEvent.gameTime - parseFloat(lead), cond: '>=' },
    { col: 'game_time', val: focusEvent.gameTime + parseFloat(tail), cond: '<=' },
  ];

  const { data, placeholder } = usePlaceholder(
    useQuery(query, {
      variables: {
        limit: 1000,
        sort: [{ id: 'event_id' }],
        filters: type == 'sl' ? queryFilters : [queryFilters],
      },
    })
  );

  const hiddenCols = !!data
    ? Object.keys(data[key].data[0]).filter(
        (key) => !defaultColumns.find((c) => c.id == key)
      )
    : [];
  const { select, selectedValue: extraKeys } = useSelect({
    options: hiddenCols.map((key) => ({ label: key, value: key })),
    selectProps: {
      isMulti: true,
      isClearable: true,
      placeholder: 'Select more columns...',
    },
  });

  const extraColumns = !!extraKeys?.length ? extraKeys.map((key) => getCol(key)) : [];
  const columns = defaultColumns.concat(extraColumns);

  const table = !!placeholder ? (
    placeholder
  ) : (
    <Styles>
      <Column gap={4} paddingTop={4}>
        <Row gap={4}>
          <Container width={70}>
            <Typography variant="label">Lead</Typography>
            <BDDInput
              type="number"
              value={lead}
              min={1}
              max={50}
              onChange={(ev) => setLead(ev.target.value)}
            />
          </Container>
          <Container width={70}>
            <Typography variant="label">Tail</Typography>
            <BDDInput
              type="number"
              value={tail}
              min={1}
              max={50}
              onChange={(ev) => setTail(ev.target.value)}
            />
          </Container>
          <Container width={200}>
            <Typography variant="label">Columns</Typography>
            {select}
          </Container>
        </Row>
        <Container maxHeight={800} overflowY="scroll">
          <BDDSortableTable
            data={data[key].data}
            columns={columns}
            columnJustify="center"
            trClassCallback={(row) =>
              type === 'sl'
                ? row.original.eventId == focusEvent.eventId
                  ? 'focus'
                  : ''
                : row.original.eventId == focusEvent.eventId &&
                  row.original.eventName == focusEvent.eventName
                ? 'focus'
                : ''
            }
          />
        </Container>
      </Column>
    </Styles>
  );

  return {
    table,
    lead,
    tail,
    data,
  };
};
