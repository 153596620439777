import { useMutation } from '@apollo/client';
import {
  CREATE_PLAYLIST,
  GET_PLAYLIST,
  GET_PLAYLISTS,
  UPDATE_PLAYLIST,
} from 'apollo/queries/video.queries';
import { toastInfo } from 'components/bdd/bddtoasts';
import produce from 'immer';
import { create } from 'zustand';
import { usePlaylistActions } from './usePlaylists';

const usePlaylistMutationStore = create((set) => ({
  playlistStates: {},
  updatePlaylistState: (id, data) =>
    set(
      produce(({ playlistStates }) => {
        playlistStates[id] = data;
      })
    ),
}));

export const useCreatePlaylist = () => {
  const { addPlaylist } = usePlaylistActions();

  return useMutation(CREATE_PLAYLIST, {
    onCompleted: (data) => {
      toastInfo('Playlist created');
      addPlaylist(data.createBddPlaylist);
    },
  });
};

export const usePlaylistMutation = (playlist) => {
  const { updatePlaylist: loadPlaylist } = usePlaylistActions();
  const { playlistStates, updatePlaylistState } = usePlaylistMutationStore();
  const [handleUpdatePlaylist] = useMutation(UPDATE_PLAYLIST);

  const updatePlaylist = (mutationData) => {
    updatePlaylistState(playlist.id, {
      loading: true,
    });

    return handleUpdatePlaylist({
      variables: mutationData.variables,
      onCompleted: (data) => {
        mutationData.onCompleted && mutationData.onCompleted(data);
        updatePlaylistState(playlist.id, {
          loading: false,
        });

        loadPlaylist(data.updateBddPlaylist);
      },
    });
  };

  const reorderPlaylist = ({ ids }) => {
    // Get clips for ids (in orginal order)
    const clips = playlist.clips
      .filter((c) => ids.includes(c.id))
      .sort((a, b) => a.playlistNumber - b.playlistNumber);

    // Sort clips by index of new order
    let newSortedClips = [...clips].sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

    // Apply the new order to the original clips
    const sortedClips = newSortedClips.map((c, index) => ({
      id: c.id,
      playlistId: playlist.id,
      playlistNumber: clips[index].playlistNumber,
    }));

    updatePlaylist({
      variables: {
        input: {
          id: playlist.id,
        },
        clipsToUpdate: sortedClips,
      },
    });
  };

  const removeClipsFromPlaylist = (clips) => {
    updatePlaylist({
      variables: {
        input: {
          id: playlist.id,
        },
        clipsToRemove: clips.map((c) => ({
          id: c.id,
          playlistId: playlist.id,
        })),
      },
    });
  };

  const removeClipFromPlaylist = (clip) => {
    removeClipsFromPlaylist([clip]);
  };

  const playlistState =
    playlist && playlist.id in playlistStates ? playlistStates[playlist.id] : {};

  return {
    updatePlaylist,
    removeClipsFromPlaylist,
    removeClipFromPlaylist,
    reorderPlaylist,
    ...playlistState,
  };
};
