import { Container } from 'components/bdd/Layout';
import { SelectFilter } from './SelectFilter';

export const ShiftStartFilter = ({}) => {
  const shiftStartOptions = [
    {
      value: null,
      label: 'All starts',
    },
    {
      value: true,
      label: 'Faceoff starts',
    },
    {
      value: false,
      label: 'Non-faceoff starts',
    },
  ];

  return (
    <Container minWidth={200}>
      <SelectFilter col={'is_faceoff'} options={shiftStartOptions} />
    </Container>
  );
};
