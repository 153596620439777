import { LinkButton } from 'components/bdd/Button';
import { Divider } from 'components/bdd/Divider';
import { DividerWithText } from 'components/bdd/Divider/Divider';
import { Filters } from 'components/bdd/Filters';
import { buildFiltersFromValues, getFilterValues } from 'components/bdd/Filters/helpers';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants';
import { dateToTimeAgo, formatMinutes, formatSeconds } from 'helpers/helpers';
import { Button } from 'react-bootstrap';
import { Download, Play } from 'react-bootstrap-icons';
import { usePlaylist, usePlaylistMutation } from '.';
import { useEffect, useState } from 'react';
import { getShiftAnnotation } from 'components/bdd/Video/components/ShiftList';
import {
  getEventLabelData,
  useEventLabelData,
  useEventNameOptions,
} from 'components/bdd/Video/Filtering/Event';
import { VideoClipProvider } from 'components/bdd/Video/Player';
import { usePlaylistMediaClips } from './PlaylistVideoPlayer';

export const DownloadPlaylistForm = ({ playlist: { title, id } }) => {
  const { playlist } = usePlaylist({ id, pollInterval: 5 * 1000 });
  const { updatePlaylist, loading } = usePlaylistMutation(playlist);

  const handleDownload = ({ annotationSettings }) => {
    updatePlaylist({
      variables: {
        input: {
          id: playlist.id,
        },
        downloadToCreate: {
          annotationSettings,
        },
      },
    });
  };

  return (
    <Container padding={2}>
      <Column gap={3}>
        <NewDownloadPlaylistForm
          playlist={playlist}
          disabled={!!loading}
          onSubmit={handleDownload}
        />
        {playlist.downloads?.length > 0 && (
          <>
            <Divider />
            <Container maxHeight={'100%'} overflow="auto">
              <Typography variant="body1">Downloads:</Typography>
              <Column gap={1}>
                {[...playlist.downloads]
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((d) => (
                    <ExistingDownload key={d.id} playlistTitle={title} download={d} />
                  ))}
              </Column>
            </Container>
          </>
        )}
        {/* <DividerWithText
          content={<Typography variant="body1">Create new download</Typography>}
        /> */}
      </Column>
    </Container>
  );
};

export const ExistingDownload = ({ playlistTitle, download }) => {
  return (
    <Border>
      <Container padding={2}>
        <Row columnGap={1} justifyContent="space-between">
          <Row columnGap={1} flexWrap="wrap">
            {!!download.presignedUrl && (
              <BDDTag
                tag={{
                  color: theme.colors.states.success,
                  textColor: theme.colors.dark.text.primary,
                  tag: 'Ready to Download',
                }}
              />
            )}
            {!download.presignedUrl && !download.details && (
              <BDDTag
                tag={{
                  color: theme.colors.states.warning,
                  textColor: theme.colors.light.text.primary,
                  tag: 'In progress...',
                }}
              />
            )}
            {!download.presignedUrl && !!download?.details?.errors && (
              <BDDTag
                tag={{
                  color: theme.colors.states.danger,
                  textColor: theme.colors.dark.text.primary,
                  tag: 'Error',
                }}
              />
            )}
            <Typography variant="stat">
              Triggered {dateToTimeAgo(download.createdAt)}
            </Typography>
          </Row>
          <Row columnGap={1}>
            {!!download.presignedUrl && (
              <a
                href={`${download.presignedDownloadUrl}`}
                download={`${playlistTitle} Download.mp4`}
              >
                <Button variant="primary">
                  <Row columnGap={1}>
                    <Icon icon={<Download />} />
                    <Typography variant="stat">
                      <b>Download</b>
                    </Typography>
                  </Row>
                </Button>
              </a>
            )}
            {!!download.presignedUrl && (
              <Button
                variant="outline-dark"
                onClick={() => window.open(download.presignedUrl, '_blank')}
              >
                <Row columnGap={1}>
                  <Icon icon={<Play />} />
                  <Typography variant="stat">
                    <b>Play</b>
                  </Typography>
                </Row>
              </Button>
            )}
          </Row>
        </Row>
      </Container>
    </Border>
  );
};

export const NewDownloadPlaylistForm = ({ playlist, disabled, onSubmit }) => {
  const [annotationSettings, setAnnotationSettings] = useState({
    type: 'none',
    includeNotes: false,
  });

  const { clips, eventMediaLoading, shiftMediaLoading } = usePlaylistMediaClips(
    playlist.clips
  );

  const duration = clips.reduce(
    (duration, clip) =>
      clip.eventDuration
        ? duration + clip.eventDuration
        : duration + (clip.frontPad ?? 5) + (clip.backPad ?? 5),
    0
  );

  return (
    <Container>
      <Column gap={3}>
        <Row columnGap={4} alignItems="center" flexWrap>
          <Typography variant="body2">
            {!eventMediaLoading && !shiftMediaLoading ? (
              <>
                Duration: <b>{clips.length}</b> clips, <b>{formatSeconds(duration)}</b>{' '}
                minutes
              </>
            ) : (
              'Loading clip media...'
            )}
          </Typography>
          <AnnotationSettingsForm
            playlist={playlist}
            defaults={annotationSettings}
            onChange={setAnnotationSettings}
          />
        </Row>
        <Container>
          <Button
            disabled={disabled || duration <= 0}
            variant="primary"
            size="sm"
            onClick={() => {
              onSubmit &&
                onSubmit({
                  annotationSettings,
                });
            }}
          >
            <Typography variant="body1">
              {disabled ? 'Triggerring...' : 'Trigger Download'}
            </Typography>
          </Button>
        </Container>
      </Column>
    </Container>
  );
};

export const AnnotationSettingsForm = ({ playlist, defaults, onChange }) => {
  const options = useEventNameOptions();
  const clipIdToAnnotation = playlist.clips?.reduce((dict, c) => {
    if (!!c.eventId) {
      const { actor, eventName, eventType, outcome, context } = getEventLabelData(
        c.event,
        options
      );

      const clipName = c.name || c.description ? `${c.name} ${c.description} ` : '';
      dict[c.id] = {
        name: `${clipName}${eventName} ${actor} ${eventType ?? ''} ${outcome}`,
        description: context,
      };
    } else {
      dict[c.id] = getShiftAnnotation(c.shift, c.name, c.description);
    }

    return dict;
  }, {});

  useEffect(() => {
    onChange({
      ...defaults,
      clipIdToAnnotation,
    });
  }, []);

  return (
    <Filters
      defaults={buildFiltersFromValues(defaults)}
      onChange={(filters) => {
        return (
          onChange &&
          onChange({
            ...getFilterValues(filters),
            clipIdToAnnotation,
          })
        );
      }}
    >
      <Row columnGap={2}>
        <Typography variant="body1">Add annotations:</Typography>
        <Filters.ButtonGroup
          id={'type'}
          // label="Add annotations"
          options={[
            {
              value: 'none',
              label: 'None',
            },
            {
              value: 'overlay',
              label: 'Overlay',
            },
            {
              value: 'clip',
              label: 'Clip Intro Screen',
            },
          ]}
        />
        <Filters.Checkbox id={'includeNotes'} label="Include clip notes" />
      </Row>
    </Filters>
  );
};
