import { Container } from 'components/bdd/Layout';
import { useContext, useEffect } from 'react';
import { VideoContext } from '../..';
import { usePlayerGameData } from 'components/bdd/Video/hooks';
import { SelectFilter } from './SelectFilter';

export const PlayerGameFilter = ({
  slug,
  target = 'skaters',
  isMulti = true,
}) => {
  const { sourceFilters, setSourceFilters } = useContext(VideoContext);

  const lsg = sourceFilters
    ? {
        leagueSlug: sourceFilters['league_slug']?.val,
        season: sourceFilters['season']?.val,
        gameType: sourceFilters['game_type']?.val,
      }
    : {
        leagueSlug: null,
        season: null,
        gameType: null,
      };

  const { data: gameData, placeholder } = usePlayerGameData({
    target,
    slug,
    ...lsg,
  });

  const options = gameData.map((d) => ({
    label: `${d.game_date}: ${d.team_shorthand} vs. ${d.opp_team_shorthand}`,
    value: d.game_uid,
  }));

  useEffect(() => {
    // Clear game filter if game data no longer includes selected games
    if (gameData.length == 0 || !sourceFilters) return;

    // Splitting conditional statement for single and multi select games for readability
    if (
      isMulti &&
      gameData.find((gd) =>
        sourceFilters['game_uid']?.val?.includes(gd.game_uid)
      )
    ) {
      return;
    }

    if (gameData.find((gd) => gd.game_uid == sourceFilters['game_uid']?.val)) {
      return;
    }

    setSourceFilters({
      game_uid: {
        col: 'game_uid',
        val: isMulti ? null : gameData[0].game_uid,
      },
    });
  }, [placeholder]);

  useEffect(() => {
    if (!sourceFilters) return;
    if (isMulti || 'game_uid' in sourceFilters || gameData.length == 0) return;

    setSourceFilters({
      game_uid: {
        col: 'game_uid',
        val: gameData[0].game_uid,
      },
    });
  }, [gameData]);

  return (
    <Container minWidth={200}>
      <SelectFilter
        col="game_uid"
        id="game_uid"
        label={isMulti ? 'Games' : 'Game'}
        options={options}
        loading={!!placeholder}
        isMulti={isMulti}
        selectProps={{
          isClearable: true,
        }}
      />
    </Container>
  );
};
