import { CheckboxGroupFilter } from '.';
import { shotQualityOptions } from '../helpers';

export const ShotQualityFilter = ({ readonly }) => {
  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="shot_quality"
      label={'Shot Flags'}
      options={shotQualityOptions}
      readonly={readonly}
    />
  );
};
