import React, { useEffect, useState } from 'react'
import BDDPlotly from '../bdd/bddplotly'
import { SubSectionHeader } from '../reports'

export function DraftAnalyticsBar({
    rinknetIds,
    playerToAnalytics,
    columns, // [{ col: 'Percentile.skating', label: 'Skating' } ...]
    title=''
}) {
    const [warnings, setWarnings] = useState('')
    useEffect(() => {
        if (rinknetIds.some(rid => !(rid in playerToAnalytics))) {
            setWarnings('Data not found for some players')
        } else {
            setWarnings('')
        }
    }, [rinknetIds, playerToAnalytics])

    const data = []
    rinknetIds.forEach(rid => {
        const aData = playerToAnalytics[parseInt(rid)]
        if (!(rid in playerToAnalytics)) return
        data.push({
            name: aData.player,
            x: columns.map(c => c.label),
            y: columns.map(c => aData[c.col]),
            type: 'bar'
        })
    })
    return <>
        {<SubSectionHeader>{title}</SubSectionHeader>}
        <BDDPlotly
            data={data}
            layout={{
                legend: {
                    x: 0, y: 0,
                    bgcolor: '#FFFFFF77',
                    bordercolor: '#333',
                    borderwidth: 2
                },
                margin: { l: 20, r: 20, t: 25 }
            }}
        />
        <div style={{ fontSize: '0.8em', color: '#777' }}>
            <em>{warnings}</em>
        </div>
    </>
}