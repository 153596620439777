import { useQuery } from "@apollo/client";
import { GET_PLAYER_LISTS_W_PLAYERS_W_SLUGS } from "apollo/queries/playerlist.queries";
import { getBDDPlayer, getBDDPlayerSlug } from "components/Lists/Builder/helpers";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import PlayerHover from "components/PlayersV2/Hover/playerhover";
import { ItemGrid } from "components/bdd";
import { Label } from "components/bdd/Label/Label";
import { Column, Container, Row } from "components/bdd/Layout"
import usePlayerListSelect from "components/bdd/Select/usePlayerListSelect"
import { Typography } from "components/bdd/Typography";
import { Button } from "react-bootstrap";


export const ListsModal = ({
  setSelectedPlayers
}) => {
  const { select, selected } = usePlayerListSelect({});
  
  const { data, placeholder } = usePlaceholder(useQuery(GET_PLAYER_LISTS_W_PLAYERS_W_SLUGS, {
    variables: {
      listIds: [selected?.id]
    },
    skip: !selected
  }));

  const playerList = data?.playerLists?.[0];
  const listPlayers = playerList?.players;
  
  const handleSubmit = () => {
    setSelectedPlayers(listPlayers.map(lp => getBDDPlayerSlug(lp, playerList)));
  }
  
  return (
    <Container>
      <Column gap={2}>
        <Container>
          <Label>Select a player list</Label>
          {select}
        </Container>

        <Container>
          {!!placeholder ? placeholder
          : (
            <Container maxHeight='300px' overflowY='auto'>
              <ItemGrid
                items={listPlayers}
                keyField={'playerId'}
                leftToRight={false}
                render={(lp) => (
                  <Row gap={8}>
                    <Typography variant='caption'>{lp.listNumber}.</Typography>
                    <PlayerHover playerSlug={getBDDPlayerSlug(lp, playerList)} delay={{ show: 500, hide: 200 }}>
                      <Typography variant='caption'>{lp.firstname} {lp.lastname}</Typography>
                    </PlayerHover>
                  </Row>
                )}
              />
            </Container>
          )}
        </Container>

        <Container>
          <Row justifyContent='center'>
            <Button variant='primary' disabled={!data} onClick={handleSubmit}>
              Filter Players
            </Button>
          </Row>
        </Container>
      </Column>
    </Container>
  )
}