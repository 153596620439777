import { useBddStatQueryV2 } from "apollo/queries"
import { BDDLoader } from "components/bdd/bddloader"
import { Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { HighlightBox } from "components/bdd/warcomponents"
import { formatMinutes } from "helpers/helpers"
import { getCurrentSeason } from "helpers/hockeyutils"
import { getMetastatsByUniqueId } from "helpers/stats"
import { getRedGreenColor, stddevColor } from "helpers/tables"

 
 
const STAT_REPORTS = [{
  id: 'usage',
  label: 'USAGE',
  statDefs: [
    { slug: 'toiPerGP', label: 'ES', filter_set: { manpower_situation: 'ES' } },
    { slug: 'toiPerGP', label: 'PP', filter_set: { manpower_situation: 'PP' } },
    { slug: 'toiPerGP', label: 'SH', filter_set: { manpower_situation: 'SH' } },
  ]
}]

export default function DCPlayerSLData({
  playerSlug,
  playerSlugs,
  report='usage'
}) {
  const statReport = STAT_REPORTS.find(r => r.id === report);
  const { data, loading, error } = useBddStatQueryV2({
    target: 'skaters',
    filters: [
      { col: 'league', val: 'NHL' },
      { col: 'season', val: getCurrentSeason() },
      { col: 'game_type', val: 'nhl_regular' },
      { col: 'slug', val: playerSlugs, cond: 'isin' }
    ],
    statDefs: statReport?.statDefs,
    norm: 20,
    index: ['slug'],
    extras: { metastats: { target_group: 'league' }}
  }, !statReport || !playerSlugs?.length)

  const playerData = data?.data?.find(d => d.slug === playerSlug);
  const metastats = data?.extras?.metastats?.league?.[0]?.metastats;
  const statDefs = data?.format?.stat_definitions.reduce((acc, curr) => ({ ...acc, [curr.label]: curr }), {});

  const labelToMetas = (label) => {
    if (!data) return null
    const uid = statDefs[label].unique_id;
    return getMetastatsByUniqueId(metastats, uid);
  }
  
  return <Container>
    {!!loading 
      ? <BDDLoader variant='squres' />
      : !!error
      ? <Typography variant='error'>Error</Typography>
      : null}
    {!!playerData && <Row>
      {statReport.statDefs.map(s => (
        <Container key={s.label}>
          <HighlightBox
            title={s.label}
            content={formatMinutes(playerData[s.label])}
            color={stddevColor(playerData[s.label], labelToMetas(s.label))}
            fontSize="10pt" padding='2px'
          />
        </Container>
      ))}
    </Row>}
  </Container>
}