import { theme } from "constants";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import CreateUpdateStatReport from "./CreateUpdateStatReport";

const Header = styled.div({
  ...theme.typography.h4
})

export default function CreateUpdateReportModal({
  show,
  handleClose,
  ...rest
}) {
  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Header>Create Stat Report</Header>
    </Modal.Header>
    <Modal.Body>
      <CreateUpdateStatReport
        {...rest}
      />
    </Modal.Body>
  </Modal>
}