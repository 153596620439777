import { useMutation } from '@apollo/client';
import { CREATE_BDD_NOTIFICATION } from 'apollo/queries/notification.queries';
import { toastBddApiError } from 'components/bdd/bddtoasts';

export const useCreateNotification = ({ onCompleted }) => {
  return useMutation(CREATE_BDD_NOTIFICATION, {
    update(
      cache,
      {
        data: {
          createBddNotification: { bddNotification },
        },
      }
    ) {
      cache.modify({
        fields: {
          paginatedNotifications: (existing, { readField }) => {
            return {
              ...existing,
              data: [bddNotification].concat(existing.data),
            };
          },
        },
      });
    },
    onError: (error) => toastBddApiError(error),
    onCompleted,
  });
};
