import useInputRange from 'components/bdd/Range/useInputRange';
import { useFilterContext } from './context';
import { Typography } from 'components/bdd/Typography';
import { useRegisterFilterTag } from './Group/context';
import { FilterTag } from '.';

export const InputRangeFilter = ({
  id,
  label,
  scale,
  unit,
  formatLabel,
  step,
  onChange,
  includeInputBoxes,
  hideRange,
  meta,
}) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters } = useFilterContext((state) => state.actions);

  useRegisterFilterTag(
    id,
    filter && (
      <FilterTag
        name={label}
        label={
          formatLabel
            ? `${formatLabel(filter.value.min)}-${formatLabel(filter.value.max)}`
            : `${filter.value.min}-${filter.value.max}${unit || ''}`
        }
      />
    )
  );

  const { inputRange } = useInputRange({
    ...(scale || { min: 0, max: 100 }),
    valueSuffix: unit,
    formatLabel: formatLabel,
    minWidth: 'fit-content',
    step: step,
    initialMinValue: filter?.value.min,
    initialMaxValue: filter?.value.max,
    label: <Typography variant="label">{label}</Typography>,
    hideRange,
    includeInputBoxes,
    onChangeComplete: (value) => {
      setFilters(id, {
        id,
        type: 'inputRange',
        value: value,
        meta,
      });

      onChange && onChange(value);
    },
    onLabelSelected: () => {},
  });
  
  return inputRange;
};
