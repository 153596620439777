import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { LOGIN_ROUTE, ADMIN_LOGIN_ROUTE } from '../../constants';
import { promise_logout_user } from '../../requests/user';
import { BDDLoader } from '../bdd/bddloader';
import client from 'apollo/client';
import localforage from 'localforage';
import { useNotificationActions } from 'components/Notifications/hooks';

export default function Logout(props) {
  const [loggedOut, setLoggedOut] = useState(false);
  const { clear: clearNotifications } = useNotificationActions();

  promise_logout_user().then((res) => {
    localStorage.removeItem('user');
    localforage.clear();
    clearNotifications();
    client.resetStore();
    setLoggedOut(true);
  });
  const redirect = props.switchUser ? ADMIN_LOGIN_ROUTE : LOGIN_ROUTE;
  return <>{loggedOut ? <Redirect to={`${redirect}`} /> : <BDDLoader />}</>;
}
