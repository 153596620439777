import React, { useEffect } from 'react'
import { DragPreviewImage, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

// takes itemType, data, className, children
function Draggable({
    itemType,
    data,
    className,
    activeClassName,
    children, // can either supply children directly, or a callback function
    renderChildren, // is called with arguments (isDragging)
    customStyles,
    noDragPreview,
    onEnd,
    draggable=true
}) {
    const type = itemType
    const [{ isDragging, ...collected }, drag, dragPreview] = useDrag({
        canDrag: draggable,
        item: { 
            type: type,
            data: data
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (!!onEnd) onEnd(item, monitor)
        }
    })

    if (customStyles) {
        var style = customStyles(isDragging)
    } else {
        var style = {
            cursor: draggable ? 'all-scroll' : null,
            opacity: isDragging ? 0.5 : 1
        }
    }

    if (noDragPreview) {
        useEffect(() => {
            dragPreview(getEmptyImage(), { captureDraggingState: true });
        }, []);
    }

    return <div
        className={`${className} ${isDragging ? activeClassName : null}`}
        ref={drag}
        style={style}
    >
        {!!renderChildren ? renderChildren({ isDragging })
        : children}
    </div>
}

export default Draggable