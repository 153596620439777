export const customPlayerFieldToinfo = {
  bio: { label: 'Bio' },
  scoutingranks: { label: 'Scouting Ranks' },
  epstats: { label: 'Eliteprospects Stats' },
  // slstats: { label: 'Sportlogiq Stats' },
  slskills: { label: 'Sportlogiq Skills' },
  // war: { label: 'WAR' },
  // contract: { label: 'Contract' },
  bddplayernotes: { label: 'Player Notes' },
  listplayernotes: { label: 'List Player Notes' },
}
export const allCustomPlayerFields = Object.keys(customPlayerFieldToinfo)

