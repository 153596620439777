import { createContext, useState } from 'react';
import { ModalContextProvider } from 'components/bdd/Modal';
import { useSearchParamsStore } from 'components/bdd/hooks';
import useSignings from './hooks/useSignings';
import SigningsFilters from './Signings/SigningsFilters';
import SigningsTable from './Signings/SigningsTable';


export const SigningsContext = createContext();

export default function BFSignings({ collapsedFilters=false, defaultPageSize=50, hideFilters=false, hidePaginationControls=false }) {
  const context = useSignings({ defaultPageSize });
  const store = useSearchParamsStore();

  return (
    <SigningsContext.Provider value={context}>
      <ModalContextProvider
        onHide={() => store.deleteKeys(['signing'])}
      >
        {!hideFilters && <SigningsFilters collapsedFilters={collapsedFilters} />}

        <SigningsTable hidePaginationControls={hidePaginationControls} />
      </ModalContextProvider>
    </SigningsContext.Provider>
  )
}