import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import {
  PlayerInfo,
  PlayerLeague,
} from 'components/Lists/Builder/components/PlayerBio';
import { EliteProspectsData } from 'components/Lists/Builder/components/EliteProspectsData';
import { Divider } from 'components/bdd/Divider';
import { IssueHeader } from './components';
import { IssueTitle } from './components/IssueTitle';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { useOnScreenRef } from 'components/bdd/hooks';
import VisibilityChangeWrapper from 'components/bdd/VisibilityChangeWrapper';

const IssueContainer = styled.div(({ selected }) => ({
  cursor: 'pointer',
  padding: theme.spacing[2],
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing[3],
  '&: hover': {
    backgroundColor: theme.colors.light.secondary,
  },
  backgroundColor: selected
    ? theme.colors.light.secondary
    : theme.colors.light.primary,
}));

const CellContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  columnGap: theme.spacing[3],
});

const VerticalCellContainer = styled(CellContainer)({
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
});

export const IssueList = ({ issues, selectedIssue, onIssueSelected }) => {
  const rinknetPlayers = issues.map((i) => i.player?.rinknetPlayer);

  return (
    <>
      {issues.map((i) => {
        const { issue, details, player } = i;

        return (
          <BDDErrorBoundary key={`issue-${i.issue.id}`} errorContent={<></>}>
            <Divider />
            <IssueContainer
              selected={selectedIssue && selectedIssue.issue.id == issue.id}
              onClick={() => onIssueSelected && onIssueSelected(i)}
            >
              <CellContainer>
                <IssueTitle
                  variant="subtitle2"
                  issue={issue}
                  details={details}
                  player={player}
                  useHover={true}
                />
              </CellContainer>
              <CellContainer>
                <VerticalCellContainer>
                  <PlayerInfo rp={player.rinknetPlayer} />
                  <PlayerLeague rp={player.rinknetPlayer} />
                </VerticalCellContainer>
                <VerticalCellContainer>
                  <VisibilityChangeWrapper
                    hideChildren={true}
                    defaultOnScreen={false}
                  >
                    <EliteProspectsData
                      rp={player.rinknetPlayer}
                      rinknetPlayers={rinknetPlayers}
                    />
                  </VisibilityChangeWrapper>
                </VerticalCellContainer>
              </CellContainer>
              <IssueHeader issue={issue} />
            </IssueContainer>
            <Divider />
          </BDDErrorBoundary>
        );
      })}
    </>
  );
};
