import { gql } from '@apollo/client';
import { CFPlayerContractFragment } from './contract.queries';
import { PageInfoFragment } from './pagination.queries';

export const CFPlayerFragment = gql`
  fragment cfPlayerFragment on CFPlayers {
    slug
    cfId
    firstName
    lastName
    birthdate
    position
    positionFdg
    teamSlug
    height
    heightInches
    weight
    status
    isActive
    acquired
    acquiredDate
    waiverExempt
    age
    freeAgentYear
    expiryStatus
    cfDraftPick {
      round
      overall
    }
  }
`;

export const CFPlayerContractSeasonFragment = gql`
  fragment cfPlayerContractSeasonsFragment on CFPlayerContractSeasons {
    contractId
    season
    contractYear
    capHit
    capHitStr
    value
    performanceBonus
    signingBonus
    baseSalary
    totalSalary
    minorSalary
    guaranteedSalary
    ntc
    nmc
    clauseDetails
    capPct
    isTwoWay
  }
`;

export const CFTeamFragment = gql`
  fragment CFTeamFragment on CFTeams {
    slug
    cfId
    name
    isActive
    abbreviation
    nhlid
  }
`;

export const CFPlayerInjuryHistoryFragment = gql`
  fragment cfPlayerInjuryHistoryFragment on CFPlayerInjuryHistory {
    playerSlug
    startDate
    endDate
    expectedDuration
    actualDuration
    teamSlug
    category
    details
    lastUpdateDate
  }
`;

export const CFDraftPickFragment = gql`
  fragment cfDraftPickFragment on CFDraftPicks {
    id
    year
    overall
    round
    originalTeamSlug
    currentTeamSlug
    playerSlug
    isConditional
    conditionalDetails
    originalTeam {
      id
      slug
      cfId
      name
      isActive
      abbreviation
      nhlid
    }
    currentTeam {
      id
      slug
      cfId
      name
      isActive
      abbreviation
      nhlid
    }
    player {
      slug
      cfId
      firstName
      lastName
      birthdate
      position
      teamSlug
      height
      weight
      status
      isActive
    }
  }
`;

export const CFCapHitFragment = gql`
  fragment cfCapHitFragment on CFPlayerCapHits {
    id
    playerSlug
    season
    effectiveCapHit
    dailyCapHit
    accumulatedCapHit
    projectedCapHit
    initialCapHit
    retainedCapHit
    finalCapHit
    retainedPct
    retainedCapHit
    retainedDetails
    hasPastContract
    hasActiveContract
    hasFutureContract
    currentAav
    currentCapHit
    currentRemainingTerm
    expiryStatus
    expiryStatusDetails
    futureCapHit
    futureRemainingTerm
    daysMajors
    daysIr
    daysLtir
    daysSoltir
    daysConditioning
    daysEmergency
    daysWaivers
    daysMinors
    daysJunior
    daysLoan
    daysSuspended
  }
`;

export const CFTransactionsFragment = gql`
  fragment cfTransactionsFragment on CFTransactions {
    id
    date
    teamSlug
    playerSlug
    cfPlayer {
      ...cfPlayerFragment
      bddPlayer {
        slug
      }
    }
    cfTeam {
      ...CFTeamFragment
    }
    fromCfTeam {
      ...CFTeamFragment
    }
    category
    details
    rightsTransferred
    fromTeamSlug
    tradeId
    contractId
    injuryId
  }
`;

export const CFTradesFragment = gql`
  fragment cfTradesFragment on CFTrades {
    id
    date
    season
    numTeams
    updatedAt
    teamSummaries {
      tradeId
      teamSlug
      cfTeam {
        ...CFTeamFragment
      }
      numPlayersIn
      numPlayersOut
      numPicksIn
      numPicksOut
      capHitIn
      capHitOut
      capHitChange
      capHitAffectingIn
      capHitAffectingOut
      capHitAffectingChange
      salaryIn
      salaryOut
      salaryChange
      isFutureConsiderations
      isExpansionDraftConsiderations
      details
    }
    tradedPlayers {
      tradeId
      playerSlug
      cfPlayer {
        ...cfPlayerFragment
        bddPlayer {
          slug
        }
      }
      cfPlayerContract {
        id
        startSeason
        endSeason
        aav
        capHit
      }
      toTeamSlug
      fromTeamSlug
      toCfTeam {
        ...CFTeamFragment
      }
      fromCfTeam {
        ...CFTeamFragment
      }
      capHit
      capHitAffecting
      salary
      retainedSalary
      retainedSalaryPct
      isMinor
      signingRights
    }
    tradedDraftPicks {
      tradeId
      toTeamSlug
      fromTeamSlug
      originalTeamSlug
      toCfTeam {
        ...CFTeamFragment
      }
      fromCfTeam {
        ...CFTeamFragment
      }
      originalCfTeam {
        ...CFTeamFragment
      }
      pickId
      year
      round
      isConditional
      conditionalDetails
      cfDraftPick {
        ...cfDraftPickFragment
      }
    }
  }
`;

export const GET_CF_TEAMS = gql`
  query GetCFTeams($activeOnly: Boolean) {
    cfTeams(activeOnly: $activeOnly) {
      ...CFTeamFragment
    }
  }
  ${CFTeamFragment}
`;

export const buildCFPlayerQuery = ({
  loadBddPlayerSlugs,
  loadTeams,
  loadContracts,
  loadStats,
}) => gql`query GetCFPlayers(
  $statuses: [String],
  $filters: GenericScalar,
) {
  cfPlayers(statuses: $statuses, 
    filters: $filters, 
    loadBddPlayerSlugs: ${!!loadBddPlayerSlugs}, 
    loadTeams: ${!!loadTeams},
    loadContracts: ${!!loadContracts},
    loadStats: ${!!loadStats}) {
      players {
        ...cfPlayerFragment
        ${loadBddPlayerSlugs ? `bddPlayer { slug }` : ''}
        ${loadTeams ? `cfTeam { slug, nhlid, abbreviation }` : ''}
        ${
          loadContracts
            ? `
              activeAndFutureContracts {
                id
                isActive
                aav
              }`
            : ''
        }
      }
      ${!!loadStats ? `statData` : ''}
  }
} ${CFPlayerFragment}`;

export const GET_CF_SIMILAR_CONTRACTS = gql`
  query GetSimilarContracts(
    $timeRange: GenericScalar
    $filters: GenericScalar
    $statFilters: GenericScalar
    $targetVector: GenericScalar
    $numericalWeights: GenericScalar
    $categoricalWeights: GenericScalar
    $statMode: String
    $nNeighbours: Int
  ) {
    cfSimilarContracts(
      timeRange: $timeRange
      filters: $filters
      statFilters: $statFilters
      targetVector: $targetVector
      numericalWeights: $numericalWeights
      categoricalWeights: $categoricalWeights
      nNeighbours: $nNeighbours
      statMode: $statMode
    ) {
      distances
      targetVectors
      contracts {
        id
        cfPlayer {
          slug
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_CF_SIMILAR_TRADES = gql`
  query GetSimilarTrades(
    $timeRange: GenericScalar
    $filters: GenericScalar
    $statFilters: GenericScalar
    $targetVector: GenericScalar
    $numericalWeights: GenericScalar
    $categoricalWeights: GenericScalar
    $statMode: String
    $nNeighbours: Int
  ) {
    cfSimilarTrades(
      timeRange: $timeRange
      filters: $filters
      statFilters: $statFilters
      targetVector: $targetVector
      numericalWeights: $numericalWeights
      categoricalWeights: $categoricalWeights
      nNeighbours: $nNeighbours
      statMode: $statMode
    ) {
      distances
      targetVectors
      similarTrades {
        ...cfTradesFragment
      }
    }
  }
  ${CFTradesFragment}
  ${CFTeamFragment}
  ${CFPlayerFragment}
  ${CFDraftPickFragment}
`;

export const GET_CF_DRAFT_CAPITAL_FOR_TEAM = gql`
  query GetCFDraftCapitalForTeam($teamSlug: ID, $cfTeamSlug: ID, $years: [Int]) {
    cfDraftCapitalForTeam(teamSlug: $teamSlug, cfTeamSlug: $cfTeamSlug, years: $years) {
      cfTeam {
        ...CFTeamFragment
      }
      picks {
        year
        currentPicks {
          ...cfDraftPickFragment
        }
        tradedPicks {
          ...cfDraftPickFragment
        }
      }
    }
  }
  ${CFDraftPickFragment}
  ${CFTeamFragment}
`;

export const GET_CF_DRAFT_PICKS_FOR_YEAR = gql`
  query GetCFDraftPicksForYear($year: Int!) {
    cfDraftPicksForYear(year: $year) {
      ...cfDraftPickFragment
      player {
        bddPlayer {
          slug
        }
      }
      trades {
        id
      }
    }
  }
  ${CFDraftPickFragment}
`;

export const GET_CF_PLAYER_AND_CONTRACTS = gql`
  query GetCFPlayerAndContracts($cfPlayerSlug: ID!) {
    cfPlayer(cfPlayerSlug: $cfPlayerSlug) {
      ...cfPlayerFragment
      contracts {
        ...cfPlayerContractFragment
      }
      currentCapHit {
        ...cfCapHitFragment
      }
      cfTeam {
        ...CFTeamFragment
      }
    }
  }
  ${CFPlayerFragment}
  ${CFTeamFragment}
  ${CFPlayerContractFragment}
  ${CFCapHitFragment}
`;

export const CFTeamSeasonSummaryFragment = gql`
  fragment cfTeamSeasonSummaryFragment on CFTeamSeasonSummaries {
    teamSlug
    season
    currentCapHit
    currentCapSpace
    projectedCapHit
    projectedCapSpace
    projectedLtirUsed
    tradeDeadlineCapSpace

    numContracts
    rosterSize
    reserveListCount
    injuryCount

    ltirSalaryRelief
    ltirSalaryUsed
    ltirSalaryRemaining

    ltirBonusRelief
    ltirBonusUsed
    ltirBonusRemaining

    buriedCount
    buriedCapHit
  }
`;

export const GET_CF_TEAM_SEASON_SUMMARY = gql`
  query GetCFTeamSeasonSummary($slTeamSlug: ID, $cfTeamSlug: ID, $season: Int) {
    cfTeamSeasonSummary(
      slTeamSlug: $slTeamSlug
      cfTeamSlug: $cfTeamSlug
      season: $season
    ) {
      ...cfTeamSeasonSummaryFragment

      cfTeam {
        ...CFTeamFragment
      }
    }
  }
  ${CFTeamFragment}
  ${CFTeamSeasonSummaryFragment}
`;

export const GET_CF_TEAM_SEASON_SUMMARIES = gql`
  query GetCFTeamSeasonSummaries($season: Int) {
    cfTeamSeasonSummaries(season: $season) {
      ...cfTeamSeasonSummaryFragment
      cfTeam {
        ...CFTeamFragment
      }
    }
  }
  ${CFTeamFragment}
  ${CFTeamSeasonSummaryFragment}
`;

export const GET_CF_TEAM_BREAKDOWN = gql`
  query GetCFTeamBreakdown($slTeamSlug: ID, $cfTeamSlug: ID) {
    cfTeam(slTeamSlug: $slTeamSlug, cfTeamSlug: $cfTeamSlug) {
      ...CFTeamFragment
      currentSeasonSummary {
        ...cfTeamSeasonSummaryFragment
      }
      cfPlayers {
        ...cfPlayerFragment
        bddPlayer {
          slug
        }
        freeAgentSeason {
          season
          expiryStatus
          expiryStatusDetails
        }
        allContractSeasons {
          ...cfPlayerContractSeasonsFragment
        }
        currentCapHit {
          ...cfCapHitFragment
        }
      }
      injuryHistory {
        ...cfPlayerInjuryHistoryFragment
        cfPlayer {
          slug
          firstName
          lastName
          position
        }
      }
    }
  }
  ${CFTeamFragment}
  ${CFTeamSeasonSummaryFragment}
  ${CFPlayerFragment}
  ${CFPlayerContractSeasonFragment}
  ${CFCapHitFragment}
  ${CFPlayerInjuryHistoryFragment}
`;

export const GET_CF_PLAYER_INJURY_HISTORY = gql`
  query GetCFPlayerInjuryHistory($cfPlayerSlug: ID!) {
    cfPlayerInjuryHistory(cfPlayerSlug: $cfPlayerSlug) {
      ...cfPlayerInjuryHistoryFragment
      cfPlayer {
        ...cfPlayerFragment
      }
      cfTeam {
        ...CFTeamFragment
      }
      lastUpdate {
        id
        date
        teamSlug
        playerSlug
        category
        details
        expectedDuration
        isUpdate
      }
    }
  }
  ${CFTeamFragment}
  ${CFPlayerFragment}
  ${CFPlayerInjuryHistoryFragment}
`;

export const GET_PAGINATED_CF_TRANSACTIONS = gql`
  query GetPaginatedCFTransactions(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $types: [CFTransactionTypes]
    $includesCfPlayerSlugs: [ID]
    $includesCfTeamSlugs: [ID]
    $positions: [String]
  ) {
    paginatedCfTransactions(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      types: $types
      includesCfPlayerSlugs: $includesCfPlayerSlugs
      includesCfTeamSlugs: $includesCfTeamSlugs
      positions: $positions
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...cfTransactionsFragment
        cfTrade {
          ...cfTradesFragment
        }
      }
    }
  }
  ${PageInfoFragment}
  ${CFTransactionsFragment}
  ${CFTradesFragment}
  ${CFTeamFragment}
  ${CFPlayerFragment}
  ${CFDraftPickFragment}
`;

export const GET_PAGINATED_CF_TRADES = gql`
  query GetPaginatedCFTrades(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $includesCfPlayerSlugs: [ID]
    $includesCfTeamSlugs: [ID]
    $seasonPhases: [String]
    $tradeType: String
    $includesRetention: Boolean
    $includesMoneyLaundering: Boolean
  ) {
    paginatedCfTrades(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      includesCfPlayerSlugs: $includesCfPlayerSlugs
      includesCfTeamSlugs: $includesCfTeamSlugs
      seasonPhases: $seasonPhases
      tradeType: $tradeType
      includesRetention: $includesRetention
      includesMoneyLaundering: $includesMoneyLaundering
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...cfTradesFragment
      }
    }
  }
  ${PageInfoFragment}
  ${CFTradesFragment}
  ${CFTeamFragment}
  ${CFPlayerFragment}
  ${CFDraftPickFragment}
`;

export const GET_CF_TRADE = gql`
  query GetCFTrade($id: ID!) {
    cfTrade(id: $id) {
      ...cfTradesFragment
    }
  }
  ${CFTradesFragment}
  ${CFPlayerFragment}
  ${CFTeamFragment}
  ${CFDraftPickFragment}
`;

export const GET_PAGINATED_CF_SIGNINGS = gql`
  query GetPaginatedCFSignings(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $cfPlayerSlug: ID
    $cfTeamSlugs: [ID]
    $positions: [String]
    $minSigningAge: Float
    $maxSigningAge: Float
    $minAav: Float
    $maxAav: Float
    $terms: [Int]
    $signingStatus: String
    $expiryStatus: String
    $seasonPhases: [String]
    $platformLeagues: [String]
    $wasCollegeFa: Boolean
    $wasEuroFa: Boolean
    $wasChlFa: Boolean
  ) {
    paginatedCfSignings(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      cfPlayerSlug: $cfPlayerSlug
      cfTeamSlugs: $cfTeamSlugs
      positions: $positions
      minSigningAge: $minSigningAge
      maxSigningAge: $maxSigningAge
      minAav: $minAav
      maxAav: $maxAav
      terms: $terms
      signingStatus: $signingStatus
      expiryStatus: $expiryStatus
      seasonPhases: $seasonPhases
      platformLeagues: $platformLeagues
      wasCollegeFa: $wasCollegeFa
      wasEuroFa: $wasEuroFa
      wasChlFa: $wasChlFa
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...cfPlayerContractFragment
        platformStats
        cfPlayer {
          ...cfPlayerFragment
          bddPlayer {
            slug
          }
        }
      }
    }
  }
  ${PageInfoFragment}
  ${CFPlayerContractFragment}
  ${CFPlayerFragment}
`;

export const CFContractListFragment = gql`
  fragment cfContractListFragment on CFContractList {
    id
    title
    description
    isArchived
    contracts {
      contractId
      order
      payload
    }
  }
`;

export const GET_CONTRACT_LISTS = gql`
  query GetContractLists {
    cfContractLists {
      ...cfContractListFragment
    }
  }
  ${CFContractListFragment}
`;

export const CREATE_CONTRACT_LIST = gql`
  mutation CreateContractList(
    $input: CFContractListInput!
    $contractsToAdd: [CFContractListContractInput]
  ) {
    createCfContractList(input: $input, contractsToAdd: $contractsToAdd) {
      cfContractList {
        ...cfContractListFragment
      }
    }
  }
  ${CFContractListFragment}
`;

export const ARCHIVE_CONTRACT_LIST = gql`
  mutation ArchiveContractList($id: ID!) {
    archiveCfContractList(id: $id) {
      cfContractList {
        ...cfContractListFragment
      }
    }
  }
  ${CFContractListFragment}
`;

export const UPDATE_CF_CONTRACT_LIST = gql`
  mutation UpdateCFContractList(
    $input: CFContractListInput!
    $contractsToAdd: [CFContractListContractInput]
    $contractsToRemove: [CFContractListContractInput]
    $contractsToUpdate: [CFContractListContractInput]
  ) {
    updateCfContractList(
      input: $input
      contractsToAdd: $contractsToAdd
      contractsToRemove: $contractsToRemove
      contractsToUpdate: $contractsToUpdate
    ) {
      cfContractList {
        ...cfContractListFragment
      }
    }
  }
  ${CFContractListFragment}
`;

export const UPDATE_CF_CONTRACT = gql`
  mutation UpdateBDDContract($input: CFPlayerContractInput!) {
    updateCfContract(input: $input) {
      cfContract {
        id
        overridePlatformSeason
      }
    }
  }
`;

export const GET_CF_SIMILAR_CONTRACT_VECTOR = gql`
  query GetTargetVector(
    $filters: GenericScalar
    $timeRange: GenericScalar
    $targetMode: String
    $statMode: String
  ) {
    cfSimilarContractTargetVector(
      filters: $filters
      timeRange: $timeRange
      targetMode: $targetMode
      statMode: $statMode
    )
  }
`;

export const GET_CF_SIMILAR_TRADE_PLAYER_VECTOR = gql`
  query GetTargetVector(
    $filters: GenericScalar
    $timeRange: GenericScalar
    $statMode: String
  ) {
    cfSimilarTradePlayerTargetVector(
      filters: $filters
      timeRange: $timeRange
      statMode: $statMode
    )
  }
`;
