import { LeftRightArrowButtons } from "components/bdd/Button";
import { Divider } from "components/bdd/Divider";
import Icon from "components/bdd/Icon";
import { Border, Column, Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { NoteCommentForm, useNotes } from "components/Notes";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";

export const ScenarioNotes = ({ scenario, allowEdit, single=false }) => {
  const entity = {
    entityId: scenario.id,
    entityType: 'PRO_SCENARIO',
  };
  
  const {
    noteItems,
    placeholder: notesPlaceholder,
    createNote,
    updateNote,
  } = useNotes({
    ...entity,
    entityIds: [entity.entityId],
  });

  if (!noteItems?.length && !allowEdit) return null;

  if (single) {
    return (
      <ScenarioNoteSingleViewer
        entity={entity}
        noteItems={noteItems}
        placeholder={notesPlaceholder}
        createNote={createNote}
        updateNote={updateNote}
        canEdit={scenario.canEdit && allowEdit}
      />
    )
  }
  return (
    <ScenarioNotesViewer
      entity={{ ...entity }}
      noteItems={noteItems}
      placeholder={notesPlaceholder}
      createNote={createNote}
      updateNote={updateNote}
      canEdit={scenario.canEdit && allowEdit}
    />
  );
}

export const ScenarioNotesViewer = ({ entity, noteItems, placeholder, createNote, updateNote, canEdit }) => {

  const items = noteItems
    ?.sort((a, b) => new Date(b.note.dateCreated) - new Date(a.note.dateCreated))
    .map((ni, index) => <Container key={ni.note.id}>{ni.render(entity)}</Container>);

  return (
    <Container>
      {noteItems ? (
        <Column gap={1}>

          <Border>
            <Container>
              {items}
            </Container>
          </Border>
          
          {canEdit && <NoteCommentForm
            disableAutoFocus
            hidePinToggle
            {...entity}
            onSubmit={(values) => {
              createNote({ variables: { ...values } });
            }}
          />}
        </Column>
      ) : (
        placeholder
      )}
    </Container>
  );
};



export const ScenarioNoteSingleViewer = ({ entity, noteItems, placeholder, createNote, updateNote, canEdit }) => {
  const [idx, setIdx] = useState(0);
  const [mode, setMode] = useState('view');
  const items = noteItems?.sort((a, b) => new Date(b.note.dateCreated) - new Date(a.note.dateCreated));

  if (!items?.length && !canEdit) return null;
  return (
    <Container>
      {!!placeholder 
      ? placeholder 
      : (
        <Column gap={1} padding={2}>
          <Row justifyContent='space-between'>
            {items.length > 1 && canEdit && (
              <Row gap={2}>
                <LeftRightArrowButtons
                  leftOnClick={() => setIdx(idx - 1)}
                  leftDisabled={idx <= 0}
                  rightOnClick={() => setIdx(idx + 1)}
                  rightDisabled={idx >= noteItems?.length - 1}
                />
                <Typography variant='stat'>
                  Note {idx + 1} of {noteItems?.length}
                </Typography>
              </Row>
            )}
            <Container></Container>

            {canEdit && <Button size='sm' variant='outline-dark' onClick={() => setMode(mode === 'view' ? 'edit' : 'view')}>
              <Row columnGap={1}>
                <Icon icon={<Plus/>} />
                <Typography variant='stat'>
                  Add Note
                </Typography>
              </Row>
            </Button>}
          </Row>

          {mode === 'view'
            ? !items.length 
              ? <Typography variant='stat'>No notes to display</Typography>
              : (
                <Border>
                  <Container>
                    {items[idx]?.render(entity)}
                  </Container>
                </Border>
              ) : (
                <NoteCommentForm
                  disableAutoFocus
                  hidePinToggle
                  {...entity}
                  onSubmit={(values) => {
                    createNote({ variables: { ...values } });
                    setIdx(0);
                    setMode('view');
                  }}
                  onCancel={() => setMode('view')}
                />
              )}
        </Column>
      )}
      <Divider/>
    </Container>
  );
}
