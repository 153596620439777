import { useQuery } from "@apollo/client"
import { GET_PLAYER_QUESTIONNAIRE_LINKS } from "apollo/queries/questionnaire.queries"
import useModal from "components/bdd/Modal/useModal"
import ColinQuestionnaire from "components/Questionnaire/ColinQuestionnaire"


export default function useQuestionnaires({
  rinknetIds,
  year,
  skip
}) {
  const { data, loading, error } = useQuery(GET_PLAYER_QUESTIONNAIRE_LINKS, {
    variables: { rinknetIds, year },
    skip
  })

  const rinknetIdToHasQuestionnaire = data?.getPlayerQuestionnaireLinks?.reduce((acc, curr) => {
    acc[curr.rinknetId] = true
    return acc
  }, {})

  const { setShowModal, renderModal } = useModal({})

  const renderQuestionnaireModalForRinknetId = (rinknetId) => {
    return renderModal((
      <ColinQuestionnaire rinknetId={rinknetId} year={year} />
    ))
  }

  return {
    setShowModal,
    renderQuestionnaireModalForRinknetId,
    rinknetIdToHasQuestionnaire,
    data,
    loading,
    error
  }
}