import { Editor, Transforms, Element as SlateElement, Range } from 'slate';

export const isPlaylistActive = (editor) => {
  const [playlist] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'playlist',
  });
  return !!playlist;
};

export const wrapPlaylist = (editor) => {
  if (isPlaylistActive(editor)) {
    unwrapPlaylist(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const playlist = {
    type: 'playlist',
    children: isCollapsed ? [{ text: '', bold: true }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [playlist, { text: ' ' }]);
  } else {
    Transforms.wrapNodes(editor, playlist, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapPlaylist = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'playlist',
  });
};

export const insertPlaylist = (editor) => {
  if (editor.selection) {
    wrapPlaylist(editor);
  }
};