import { gql } from "@apollo/client"

export const PageInfoFragment = gql`
    fragment pageInfoFragment on BDDPageInfoV2 {
        totalRows
        limit
        offset
        hasNextPage
        hasPreviousPage
        startIndex
        endIndex
    }
`