import { useQuery } from '@apollo/client';
import { GET_ISSUES_BY_SCOUTING_OBJECTIVE } from 'apollo/queries/scoutingobjectives.queries';
import { usePaginated } from 'components/bdd/Paginated';

const sortValueToFilter = {
  createdDate: [
    { id: 'created_date', desc: true },
    { id: 'last_modified_date', desc: true },
  ],
  lastModifiedDate: [
    { id: 'last_modified_date', desc: true },
    { id: 'created_date', desc: true },
  ],
};

export const useIssues = ({
  scoutingObjective,
  search,
  issueTypes,
  myIssues,
  closedIssues,
  minExpectedPick,
  firstYearDraft,
  filters,
  sort,
  limit = 100,
}) => {
  const {
    paginatedData: issueData,
    placeholder: issuePlaceholder,
    loadingWrapper,
    pageControls,
  } = usePaginated({
    query: GET_ISSUES_BY_SCOUTING_OBJECTIVE,
    defaultLimit: limit,
    variables: {
      slug: scoutingObjective.slug,
      issueTypes,
      myIssues,
      closedIssues,
      minExpectedPick,
      firstYearDraft,
      filters: JSON.stringify(filters),
      sort: sortValueToFilter[sort] || sortValueToFilter['createdDate'],
      search,
    },
    key: 'bddIssuesByScoutingObjective',
    itemNames: 'issues',
  })

  return {
    issues:
      issueData &&
      issueData
        ?.filter((issue) => issue.targetType == 'BDD_PLAYER_SLUG')
        .map((issue) => ({
          issue,
          details: JSON.parse(issue.issueDetails),
          player: issue.bddPlayer,
        })),
    unlinkedIssues:
      issueData &&
      issueData.filter((issue) => issue.targetType !== 'BDD_PLAYER_SLUG'),
    placeholder: issuePlaceholder,
    loadingWrapper,
    pageControls,
  };
};
