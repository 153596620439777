import { useQuery } from '@apollo/client';
import { GET_BDD_WAR_PROJECTION } from 'apollo/queries/iso.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import BDDPlotly from 'components/bdd/bddplotly';
import { roundToX } from 'helpers/data';
import {
  getCurrentSeason,
  getSeasonPlusMinusYears,
  season8to4,
} from 'helpers/hockeyutils';
import { bruinsBlack, bruinsGold } from 'helpers/plotting';

const thresholdsByPosition = {
  f: [
    { label: 'Top 6', value: 2.2 },
    { label: 'Mid 6', value: 1.2 },
    { label: 'Bot 6', value: 0.6 },
  ],
  d: [
    { label: 'Top 4', value: 1.8 },
    { label: 'Mid', value: 1 },
    { label: 'Bot 4', value: 0.6 },
  ],
};

const MAX_FUTURE_YEARS = 5;

export const WarProjectionChart = ({ slug, maxHeight, posFDG, showTitle = true }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BDD_WAR_PROJECTION, {
      variables: { slug },
    })
  );

  const intType = 'prediction_interval';
  const intSize = 0.75;

  const projections = data?.bddWarProjection?.seasons?.filter(
    (s) => s.season <= getSeasonPlusMinusYears(getCurrentSeason(), MAX_FUTURE_YEARS)
  );

  const { select, selectedValue: mode } = useSelect({
    initialSelectedValue: 'war',
    options: [
      { label: 'WAR', value: 'war' },
      { label: 'Prob NHL', value: 'probNhl' },
    ],
    variant: 'outlined',
  });

  const getProbNhl = (p) => (!!p.probNhl ? p.probNhl : 1);
  const getWar = (p) =>
    p.source === 'projected' ? p.warGivenNhl : p.actualWar ? p.actualWar : p.expectedWar;
  const getValue = (p, accessor) => {
    return mode === 'probNhl' ? getProbNhl(p) : getWar(p);
  };

  if (!!data) {
    if (!data?.bddWarProjection) {
      return <Typography variant="body2">No WAR projection data found</Typography>;
    }

    const nhlPast = projections.filter(
      (p) => p.bddLeagueSlug === 'nhl' && p.source !== 'projected'
    );
    const nhlProj = projections.filter(
      (p) => p.bddLeagueSlug === 'nhl' && p.source === 'projected'
    );
    const ahl = projections.filter((p) => p.bddLeagueSlug === 'ahl');
    const other = projections.filter((p) => !['nhl', 'ahl'].includes(p.bddLeagueSlug));
    const past = projections.filter((p) => p.source !== 'projected');
    const mostCommonPast = past.filter((p) => p.isMostCommonLeague);
    const future = projections.filter((p) => p.source === 'projected');
    const currAndFuture = mostCommonPast.slice(-1).concat(future);

    const projToHover = (p) =>
      `<b>League:</b> ${p.bddLeagueSlug.toUpperCase()}<br>` +
      `<b>Age:</b> ${Math.floor(p.age)}<br>` +
      `<b>Season:</b> ${season8to4(p.season, true)}<br>` +
      `<b>GP:</b> ${p.gp}<br>` +
      `<b>WAR:</b> ${roundToX(p.actualWar ? p.actualWar : p.expectedWar, 2)}<br>` +
      (!!p.probNhl ? `<b>Prob NHL:</b> ${roundToX(p.probNhl, 2)}<br>` : '') +
      (!!p.warGivenNhl ? `<b>WAR if NHL:</b> ${roundToX(p.warGivenNhl, 2)}<br>` : '');

    const nhlPastScatter = {
      type: 'scatter',
      mode: 'markers',
      x: nhlPast.map((p) => Math.floor(p.age)),
      y: nhlPast.map((p) => getValue(p)), // shoudl be just expected war
      hovertemplate: `%{customdata}<extra></extra>`,
      customdata: nhlPast.map((p) => projToHover(p)),
      marker: {
        color: `${bruinsGold}DD`,
        size: 8,
        line: {
          color: `${bruinsGold}DD`,
          width: 2,
        },
      },
    };

    const nhlProjScatter = {
      type: 'scatter',
      mode: 'markers',
      x: nhlProj.map((p) => Math.floor(p.age)),
      y: nhlProj.map((p) => getValue(p)), // shoudl be just predictedWar
      hovertemplate: `%{customdata}<extra></extra>`,
      customdata: nhlProj.map((p) => projToHover(p)),
      marker: {
        color: `${bruinsGold}33`,
        size: 8,
        line: {
          color: `${bruinsGold}DD`,
          width: 2,
        },
      },
    };

    const ahlScatter = {
      type: 'scatter',
      mode: 'markers',
      x: ahl.map((p) => Math.floor(p.age)),
      y: ahl.map((p) => getValue(p)),
      hovertemplate: `%{customdata}<extra></extra>`,
      customdata: ahl.map((p) => projToHover(p)),
      marker: {
        color: `${bruinsBlack}DD`,
        size: 8,
        line: {
          color: '33333377',
          width: 2,
        },
      },
    };

    const otherScatter = {
      type: 'scatter',
      mode: 'markers',
      x: other.map((p) => Math.floor(p.age)),
      y: other.map((p) => getValue(p)), // should be just expected war
      hovertemplate: `%{customdata}<extra></extra>`,
      customdata: other.map((p) => projToHover(p)),
      marker: {
        color: `ffffffDD`,
        size: 8,
        line: {
          color: '00000077',
          width: 2,
        },
      },
    };

    const warTrend = {
      type: 'scatter',
      mode: 'lines',
      x: mostCommonPast.map((p) => Math.floor(p.age)),
      y: mostCommonPast.map((p) => getValue(p)),
      line: {
        color: `${bruinsBlack}77`,
      },
      hoverinfo: 'skip',
    };

    const warProj = {
      type: 'scatter',
      mode: 'lines',
      x: currAndFuture.map((p) => Math.floor(p.age)),
      y: currAndFuture.map((p, i) => getValue(p)),
      line: {
        color: `${bruinsBlack}77`,
        dash: 'dot',
      },
      hoverinfo: 'skip',
    };

    const uncertainties = projections.filter((p) => !!p.details?.war?.[intType][intSize]);
    const lowBound = {
      type: 'scatter',
      mode: 'lines',
      name: 'Lower Bound',
      x: uncertainties.map((p) => `${Math.floor(p.age)}`),
      y: uncertainties.map((p) => p.details.war[intType][intSize][0]),
      line: { width: 0 },
      hoverinfo: 'skip',
    };

    const highBound = {
      type: 'scatter',
      mode: 'lines',
      name: 'Upper Bound',
      x: uncertainties.map((p) => `${Math.floor(p.age)}`),
      y: uncertainties.map((p) => p.details.war[intType][intSize][1]),
      fill: 'tonexty',
      fillcolor: 'rgba(30, 30, 30, 0.1)',
      line: { width: 0 },
      hoverinfo: 'skip',
    };

    let shapes = [];
    let annotations = [];
    if (!!thresholdsByPosition[posFDG.toLowerCase()]) {
      thresholdsByPosition[posFDG.toLowerCase()].forEach(({ label, value }) => {
        shapes.push({
          type: 'line',
          xref: 'paper',
          x0: 0,
          x1: 1,
          y0: value,
          y1: value,
          line: {
            color: '#aaaaaadd',
            dash: 'dot',
          },
        });
        annotations.push({
          xref: 'paper',
          yref: 'y',
          x: 1,
          y: value,
          text: label,
          yanchor: label === 'Bot 4' ? 'top' : 'bottom',
          showarrow: false,
          font: {
            color: '#333',
          },
        });
      });
    }

    var layout = {
      showlegend: false,
      hovermode: 'closest',
      shapes: mode === 'war' ? shapes : null,
      annotations: mode === 'war' ? annotations : null,
      margin: { l: 35, r: 25, t: 30, b: 70 },
      xaxis: {
        title: {
          text: 'Age',
          standoff: 5,
        },
      },
      yaxis: {
        title: mode === 'war' ? 'WAR' : 'Prob NHL',
        rangemode: 'tozero',
      },
    };

    var pdata =
      mode === 'war'
        ? [
            nhlPastScatter,
            nhlProjScatter,
            ahlScatter,
            otherScatter,
            warTrend,
            warProj,
            lowBound,
            highBound,
          ]
        : [nhlPastScatter, nhlProjScatter, warProj];
  } else {
    pdata = [];
  }

  return (
    <Container>
      {!!placeholder ? (
        placeholder
      ) : (
        <Container>
          {showTitle && (
            <Row justifyContent="center" gap={4}>
              <Typography variant="body1">Projected</Typography>
              <Container>{select}</Container>
            </Row>
          )}
          <BDDPlotly
            data={pdata}
            layout={layout}
            displayModeBar={false}
            style={{ maxHeight: maxHeight }}
          />
        </Container>
      )}
    </Container>
  );
};
