import { LoadingIcon } from '../Icon/Icon';
import { Row } from '../Layout';
import { Typography } from '../Typography';

export const LoadingPlaceholder = ({ loading, variant, children }) => {
  return loading ? (
    <Row columnGap={1}>
      <LoadingIcon />
      <Typography variant={variant || 'body1'}>Loading...</Typography>
    </Row>
  ) : (
    children
  );
};
