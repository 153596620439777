import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';

const NoDataTitle = styled.div({
  ...theme.font.base,
  ...theme.typography.subtitle1,
});

const usePlaceholder = ({ data, loading, error, ...queryAttrs }, placeholderProps) => {
  let placeholder;

  if (loading) {
    placeholder = <BDDLoader variant="squares" {...placeholderProps} />;
  } else if (error) {
    placeholder = <BDDApiError error={error} />;
  } else if (!data) {
    placeholder = <NoDataTitle>No data found</NoDataTitle>;
  }

  return {
    data,
    placeholder,
    loading,
    error,
    ...queryAttrs
  };
};

export default usePlaceholder;
