import { useEffect } from 'react';
import { CheckboxGroupFilter, EventFilterContext } from '.';
import { useContext } from 'react';
import { VideoContext } from '../..';

export const ZoneFilter = ({ readonly }) => {
  const { eventType } = useContext(VideoContext);
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters?.['event_name']?.val;
  const col = eventType == 'events' ? 'zone' : 'zone_in';

  const zoneOptions = [
    {
      value: 'dz',
      label: 'DZ',
    },
    {
      value: 'oz',
      label: 'OZ',
    },
    {
      value: 'nz',
      label: 'NZ',
    },
  ];

  const invalidEvents = ['gbr', 'win', 'loss'];

  if (eventNames && eventNames.find((en) => invalidEvents.includes(en))) return null;
  if (!filters) return null;

  return (
    <CheckboxGroupFilter
      col={col}
      id={col}
      label={'Zone'}
      condAny="includes_substr"
      options={zoneOptions}
      readonly={readonly}
    />
  );
};
