import { useQuery } from '@apollo/client';
import { useEPStatsContext } from '../context';
import { useEffect } from 'react';
import { Cell, buildColumns } from 'components/bdd/Table';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HoverInteractive } from 'components/bdd';
import { GET_BDD_ISSUES_BY_PLAYER } from 'apollo/queries/issues.queries';
import { issueTypeToLabel } from 'components/ScoutingV2/components/issues/helpers';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { IssueTitle } from 'components/ScoutingV2/components/issues/components';

const ISSUES_COLUMN_ID = 'issues';

export const IssuesData = ({}) => {
  const indexColumnIds = useEPStatsContext((state) => state.indexColumnIds);
  const results = useEPStatsContext((state) => state.results);
  const { addColumns } = useEPStatsContext((state) => state.actions);

  const playerSlugs = results.stats?.data.map((s) => s.slug).filter((s) => !!s);
  const { data: issuesData } = useQuery(GET_BDD_ISSUES_BY_PLAYER, {
    skip:
      !playerSlugs ||
      playerSlugs.length == 0 ||
      !indexColumnIds.includes(ISSUES_COLUMN_ID),
    variables: {
      bddPlayerSlugs: playerSlugs,
      ignoreScoutingObjectives: true,
    },
  });

  const issuesByPlayer =
    issuesData &&
    [...issuesData.allBddIssues]
      .sort((a, b) => new Date(a.lastModifiedDate) - new Date(b.lastModifiedDate))
      .reduce((issuesByPlayer, issue) => {
        issuesByPlayer[issue.targetId] = issue;
        return issuesByPlayer;
      }, {});

  const issuesColumn = buildColumns([
    {
      id: ISSUES_COLUMN_ID,
      disableSort: true,
      width: 200,
      header: 'Issues',
      accessor: (row) => {
        if (!issuesByPlayer) {
          return <Cell textAlign="center">Loading...</Cell>;
        }

        const issue = issuesByPlayer[row['slug']];
        if (!issue) {
          return null;
        }

        return (
          <Row justifyContent="center">
            <HoverInteractive
              placement="right"
              renderContent={(forceClose) => (
                <Container width={200}>
                  <IssueTitle
                    variant="body3"
                    issue={issue}
                    playerName={row['player']}
                    details={JSON.parse(issue.issueDetails)}
                  />
                </Container>
              )}
            >
              <Typography variant="stat" state="danger">
                <Row columnGap={1}>
                  <Icon icon={<ExclamationTriangle />} />
                  <b>{issueTypeToLabel[issue.issueType]}</b>
                </Row>
              </Typography>
            </HoverInteractive>
          </Row>
        );
      },
      header: 'Issues',
    },
  ]);

  useEffect(() => {
    addColumns(issuesColumn);
  }, [issuesData, results?.stats]);
};
