import { dateRangeModeToDates } from "./daterangemode"
import { getDefaultGameType, NULL_FILTER_VALUE } from "./filters"
import { formatDate, formatDateGQL } from "./helpers"
import { getCurrentSeason, isTargetGroup } from "./hockeyutils"
import { stddevColor } from "./tables"

const timestampToDate = tstamp => {
  try {
    return formatDate(new Date(tstamp), { format: '%m/%d/%Y' })
  } catch (RangeError) { return null }
}

const INDEX_RELABELS = {
  currentteam_shorthand: 'Curr Team'
};
const remapIndex = (i) => {
  if (i in INDEX_RELABELS) return INDEX_RELABELS[i];
  return i;
}

export function filtersToQV2({
  filters,
  target,
  indexDefaults,
  withPlayers,
}) {
  /* Given an object of filter values, convert into a "q" for a stat query */

  // convert each filterable field into a list of {} of filters
  const filterList = []
  if (!!filters.league) {
    if (filters.league instanceof Object) {
      filterList.push({ col: 'league_slug', val: filters.league, cond: 'isin' })
    } else {
      filterList.push({ col: 'league_slug', val: filters.league })
    }

  }
  if (!!filters.season) {
    if (filters.season instanceof Object) {
      filterList.push({ col: 'season', val: filters.season, cond: 'isin' })
    } else {
      filterList.push({ col: 'season', val: filters.season })
    }
  }
  if (!!filters.game_type) {
    filterList.push({ col: 'game_type', val: filters.game_type })
  }

  if (!!filters.gameTypes) {
    filterList.push({ col: 'game_type', val: filters.gameTypes.split(','), cond: 'isin' });
  }

  if (!!filters.strength && filters.strength !== NULL_FILTER_VALUE) {
    filterList.push({ col: 'manpower_situation', val: filters.strength.slice(0, 2) })
    if (filters.strength.length > 2) {
      filterList.push({ col: 'strength', val: filters.strength.slice(2) })
    }
  }
  if (filters.teams && filters.teams.length) {
    filterList.push({ col: target === 'teams' ? 'slug' : 'team_slug', val: filters.teams, cond: 'isin' })
  }
  if (!!filters.position && filters.position !== NULL_FILTER_VALUE && target !== 'goalies') {
    filterList.push({ col: 'position', val: filters.position, cond: '~' })
  }
  if (filters.start_date && filters.end_date) {
    filterList.push({ col: 'game_date', val: timestampToDate(filters.start_date), cond: '>=' })
    filterList.push({ col: 'game_date', val: timestampToDate(filters.end_date), cond: '<=' })
  }
  if (filters.strict_start_date && filters.strict_end_date) {
    filterList.push({ col: 'game_date', val: filters.strict_start_date, cond: '>=' })
    filterList.push({ col: 'game_date', val: filters.strict_end_date, cond: '<=' })
  }
  if (filters.dateRangeMode) {
    const [startDate, endDate] = dateRangeModeToDates(filters.dateRangeMode);
    if (!!startDate && !!endDate) {
      filterList.push({ col: 'game_date', val: timestampToDate(startDate), cond: '>=' })
      filterList.push({ col: 'game_date', val: timestampToDate(endDate), cond: '<=' })
    }
  }
  if (filters.currentteam_slug) {
    filterList.push({ col: 'currentteam_slug', val: filters.currentteam_slug })
  }
  if (filters.sl_uids) {
    filterList.push({ col: 'sl_uid', val: filters.sl_uids, cond: 'isin' })
  }
  if (filters.slug) {
    filterList.push({ col: 'slug', val: filters.slug })
  }
  if (filters.slugs) {
    filterList.push({ col: 'slug', val: filters.slugs, cond: 'isin' });
  }
  if (filters.gameUid) {
    filterList.push({ col: 'game_uid', val: filters.gameUid });
  }

  const filterIndex = !!filters.index ? filters.index.split(',') : []

  if (!indexDefaults) {
    if (isTargetGroup(target)) {
      indexDefaults = ['id', 'Players', target, 'jerseynumbers', 'slugs']
    } else {
      indexDefaults = ['slug']
      if (target !== 'teams') {
        if (filters.league === 'NHL') {
          indexDefaults.push('currentteam_nhlid')
        } else {
          indexDefaults.push('currentteam_shorthand')
        }
      }
    }
  }
  let index = filterIndex
    .concat(indexDefaults.filter(i => !filterIndex.includes(i)))
    .concat(filters.extraInfo ? filters.extraInfo : []);
  index = index.filter((indexVal, i) => index.indexOf(indexVal) === i);
  index = index.map(i => ({ col: i, label: remapIndex(i) }))

  const q = {
    target,
    filters: filterList,
    index: index,
    format: { percentages: { mult100: true } },
    ...(!!filters.statReport ? { statReport: filters.statReport } : {}),
    ...(!!filters.statSlugs ? { statSlugs: filters.statSlugs } : {}),
    ...(!!filters.statDefs ? { statDefs: filters.statDefs } : {}),
    with: withPlayers,
  }
  const hasSeasonFilter = filterList.filter(f => f.col === 'season').length > 0
  if (filters.min_toi) {
    q.min_toi = parseInt(filters.min_toi)
  }
  if (filters.norm !== NULL_FILTER_VALUE) {
    q.norm = filters.norm
    //     const targetPosition = filters.position !== NULL_FILTER_VALUE ? filters.position : 'all'
    //     const targetGroup = filters.metastats ? filters.metastats : 'league'
    //     const targetTeamSlug = filters.metastats === 'team' && !!filters.target_team_slug ? filters.target_team_slug : null

    //     q.extras = { metastats: { target_group: targetGroup, target_position: targetPosition } }
    //     if (!hasSeasonFilter) {
    //         q.extras.metastats['use_defaults'] = true
    //     }
    //     if (targetTeamSlug) {
    //         q.extras.metastats.target_team_slug = targetTeamSlug
    //     }
    // }
  }
  if (!!filters.metastats) {
    q.extras = { metastats: filters.metastats }
  } else {
    let metastats = {}
    if (!!filters.position && filters.position !== NULL_FILTER_VALUE) {
      metastats.target_position = filters.position
    }
    if (Object.keys(metastats).length === 0) metastats = true
    q.extras = { metastats }
  }

  if (!!filters.onthefly) {
    q.onthefly = true
  }

  return q
}

export const parseStatUniqueId = uniqueId => {
  const split = uniqueId.split("-")
  return {
    slug: split[0],
    filters: split[1],
    norm: split[2]
  }
}


export const getMetastatsByUniqueId = (metastatsObject, uniqueId) => {
  return metastatsObject?.[uniqueId];
}
export const getMetastatsBySlug = (metastatsObject, slug) => {
  if (!metastatsObject || !slug) return null;
  if (slug in metastatsObject) return metastatsObject[slug];
  return Object.values(metastatsObject).find(m => m.stat_slug === slug);
}
export const getMetastatsByStatFilterId = (metastatsObject, statFilterId) => {
  if (!metastatsObject || !statFilterId) return null;
  return Object.values(metastatsObject).find(m => m.stat_filter_id === statFilterId);
}

export const getMetastatsBySlugAndStatFilterId = (
  metastatsObject,
  slug,
  statFilterId
) => {
  if (!metastatsObject || !slug || !statFilterId) return null;
  if (slug in metastatsObject) return metastatsObject[slug];
  return Object.values(metastatsObject).find(
    (m) => m.stat_slug === slug && m.stat_filter_id == statFilterId
  );
};


const defaultOpts = {
  targetGroup: 'league',
  targetPosition: 'all',
  matchOn: 'unique_id', // either unique_id, stat_filter_id, or slug.
  defaultSeason: getCurrentSeason()
}
export const searchMetastatsSplitsForStat = (metastats, statDef, row, opts = {}) => {
  /* Given metastats in legacy format, finds the right set of metastats for a given stat for supplied opts
  :metastats: dictionary with keys for each target group (e.g. { league: { metastats, split }, nhl.bos: ...})
  :statDef
  :row: row from data response with keys for league_slug, game_type, season
  :opts supplied options for targetGroup, targetPosition, matchOn
  returns [split, metastats for stat]
  */
  opts = { ...defaultOpts, ...opts }
  if (!metastats) return [null, null]

  if (!row.season) row = { ...row, season: opts.defaultSeason }
  if (!row.game_type) row = { ...row, game_type: getDefaultGameType(row.league_slug) }

  const splitAttrs = ['league_slug', 'game_type', 'season'];
  const metaSplit = metastats?.[opts.targetGroup]?.find(
    (m) => {
      return splitAttrs.every((key) => compareSplitAttrs(key, m.split, row, opts.targetPosition))
    });

  if (opts.matchOn === 'slug') { // don't use the full uniqueId, just slug
    var statMetastats = getMetastatsBySlug(metaSplit?.metastats, statDef.slug)
  } else if (opts.matchOn === 'stat_filter_id') {
    var statMetastats = getMetastatsByStatFilterId(metaSplit?.metastats, statDef.stat_filter_id)
  } else if (opts.matchOn === 'unique_id') {
    statMetastats = getMetastatsByUniqueId(metaSplit?.metastats, statDef.unique_id)
  } else if (opts.matchOn === 'slug_stat_filter_id') {
    statMetastats = getMetastatsBySlugAndStatFilterId(metaSplit?.metastats, statDef.slug, statDef.stat_filter_id)
  } else {
    throw Error(`Unknown "matchOn" argument to searchMetastatsSplitsForStat: ${opts.matchOn}`)
  }

  return [metaSplit?.split, statMetastats];
};


const compareSplitAttrs = (key, split, row, targetPosition) => {
   const directMatch = split[key] === row[key] && split.position.toLowerCase() === targetPosition.toLowerCase()

   // If there is no direct match and the row is requesitng nhl_postseason, default to using the nhl_regular split
   if (!directMatch && key === 'game_type' && row[key] === 'nhl_postseason' && split[key] ===  'nhl_regular') {
      return true
   }
   return directMatch
};


export const gameHighlightCallback = (metastats, label, row, statDef, toiStatDef) => {
  // When dealing with game level data we like to highlight based on rate stats (per 20)
  // which means transforming the data to do the metastats comparison
  let value = row[label];

  if (statDef.slug === 'toi') return null;
  if (
    statDef.norm === 'gp' ||
    statDef.norm === 'None' ||
    statDef.norm === null
  ) {
    // when we've got stats unnormalized (or normalized by GP, which is the same thing in a by game table)
    // Let's compare a normalized value with the metastats
    if (!toiStatDef) {
      console.warn(
        `Can't compare totals by game using per 20 metastats without "toi" in stat request`
      );
      return null;
    }
    if (statDef.counting_stat && !!toiStatDef) {
      if (toiStatDef.time_unit === 'minutes') {
        value = value / (row[toiStatDef.label] / 20);
      } else {
        value = value / (row[toiStatDef.label] / 60 / 20);
      }
    }
  }

  return stddevColor(value, metastats, statDef.negative, 2, 4);
};
