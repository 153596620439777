import { useQuery } from '@apollo/client';
import { GET_BF_TEAMS } from 'apollo/queries/bruinsfriendly.queries';
import { BDDDateRangePicker } from 'components/bdd/bdddatepicker';
import { Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import useBDDPlayerSearch from 'components/Search/useBDDPlayerSearch';
import { useContext, useEffect, useState } from 'react';
import { TradeContext } from '../Trades/Trades';
import Toggle from 'components/bdd/Toggle/Toggle';

export const seasonPhaseOptions = [
  { label: 'In-season', value: 'INSEASON' },
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Offseason', value: 'OFFSEASON' },
  { label: 'Post Deadline', value: 'POSTDEADLINE'}
];

export default function TradesFilters({}) {
  const { store, setStoreValue } = useContext(TradeContext);
  const [dates, setDates] = useState([store.startDate, store.endDate]);

  const { data: bfTeams } = useQuery(GET_BF_TEAMS);
  const teamOptions = !!bfTeams
    ? bfTeams.bfTeams.map((t) => ({ value: t.slug, label: t.name }))
    : [];

  const { searchComponent } = useBDDPlayerSearch({
    initialSelectedValue: store.includesBfPlayerSlugs?.[0],
    onSelect: (opt) => {
      if (!!opt) {
        setStoreValue('includesBfPlayerSlugs', [opt.bddPlayer.bfSlug]);
      } else {
        setStoreValue('includesBfPlayerSlugs', null);
      }
    },
  });

  const tradeTypeOptions = [
    { value: 'PLAYERS_ONLY', label: 'Players Only' },
    { value: 'PICKS_ONLY', label: 'Picks Only' },
    { value: 'MIXED', label: 'Mixed Assets' },
  ];

  const setOrRemoveBooleanKey = (key, toggled) => {
    if (toggled) {
      setStoreValue(key, true);
    } else {
      setStoreValue(key, null);
    }
  };

  useEffect(() => {
    if (!!dates[0] && !!dates[1]) {
      setStoreValue('startDate', dates[0]);
      setStoreValue('endDate', dates[1]);
    } else if (!dates[0] && !dates[1]) {
      setStoreValue('startDate', null);
      setStoreValue('endDate', null);
    }
  }, [dates]);

  return (
    <Container padding={4}>
      <Row columnGap={8} flexWrap>
        <Container minWidth={200}>
          <Typography variant="label">Teams</Typography>

          <StyledBDDSelect
            selectedValue={store.includesBfTeamSlugs}
            options={teamOptions}
            onChange={(n, v) => setStoreValue('includesBfTeamSlugs', v)}
            selectProps={{
              placeholder: 'Filter Teams',
              isMulti: true,
              isClearable: true,
              isSearchable: true,
            }}
          />
        </Container>

        <Container minWidth={200}>
          <Typography variant="label">Players</Typography>
          {searchComponent}
        </Container>

        <Container minWidth={200}>
          <Typography variant="label">Time in season</Typography>
          <StyledBDDSelect
            selectedValue={store.seasonPhases}
            options={seasonPhaseOptions}
            onChange={(n, v) => setStoreValue('seasonPhases', v)}
            selectProps={{
              isMulti: true,
              isClearable: true,
            }}
          />
        </Container>

        <Container>
          <Typography variant="label">Date Range</Typography>
          <Container minWidth={200}>
            <Typography variant="body1">
              <BDDDateRangePicker
                zIndex={6}
                placeholderText="Date Range"
                selected={dates[0]}
                startDate={dates[0]}
                endDate={dates[1]}
                onChange={(dates) => setDates(dates)}
                showYearDropdown
                showMonthDropdown
                isClearable
              />
            </Typography>
          </Container>
        </Container>

        <Container minWidth={200}>
          <Typography variant="label">Asset Types</Typography>
          <StyledBDDSelect
            selectedValue={store.tradeType}
            options={tradeTypeOptions}
            onChange={(n, v) =>
              !!v ? setStoreValue('tradeType', v) : setStoreValue('tradeType', null)
            }
            selectProps={{
              placeholder: 'Filter involved assets',
              isClearable: true,
            }}
          />
        </Container>

        <Container minWidth={200}>
          <Toggle
            toggled={store.includesRetention}
            label={'Includes Retention'}
            setToggled={(newVal) => setOrRemoveBooleanKey('includesRetention', newVal)}
          />
        </Container>

        <Container minWidth={200}>
          <Toggle
            toggled={store.includesMoneyLaundering}
            label={'Includes Money Laundering'}
            setToggled={(newVal) =>
              setOrRemoveBooleanKey('includesMoneyLaundering', newVal)
            }
          />
        </Container>
      </Row>
    </Container>
  );
}
