import React, {useState} from "react"
import BDDSelect from '../bdd/bddselect'
import { getSeasonChoices } from "../../helpers/filters"
import { getCurrentSeason, getSeasonPlusMinusYears } from "../../helpers/hockeyutils"

export default function SeasonSelect({
    value,
    onChange,
    variant,
    className,
    style,
    seasonRange=[-3, 0], // [-3, 0] == 3 years back till current season
    useBDDSelect=false,
    ...rest
}) {

    const smallStyles = {
        option: provided => ({
            ...provided,
            fontSize: "0.7em",
        }),
        control: provided => ({
            ...provided,
            fontSize: "0.9em",
            textAlign: "center",
        }),
        singleValue: provided => ({
            ...provided,
            fontSize: "0.9em",
        })
    }
    const variantsToStyles = {'sm': smallStyles}

    var styles = {}
    if (variant && variant in variantsToStyles) {
        styles = {...variantsToStyles[variant]}
    }

    const currSeason = getCurrentSeason()
    const startSeason = getSeasonPlusMinusYears(currSeason, seasonRange[0])
    const endSeason = getSeasonPlusMinusYears(currSeason, seasonRange[1])
    
    const options = getSeasonChoices().filter(o => o.value >= startSeason && o.value <= endSeason)
    const content = useBDDSelect ?
        <BDDSelect
            {...rest}
            name="season"
            value={value}
            options={options}
            onChange={(name, value) => onChange(name, value)}
            // styles={styles}
        />
            :
        <select 
            name="season"
            value={value} 
            onChange={ev => onChange(ev.target.name, ev.target.value)}
            className={className}
            style={{ fontFamily: 'Oswald', ...style }}
        >
            {options.map(s => 
                <option key={s.value} value={s.value}>{s.label}</option>)}
        </select>

    return (
        <>{content}</>
    )
}