import { permissionOptions } from 'components/PlayerNotes/helpers';

export const getVisibilityLabel = (requiresPermission, userAccess) => {
  const userLabel =
    userAccess && userAccess.length > 0 && `Shared (${userAccess.length})`;

  const permissionLabel =
    requiresPermission &&
    permissionOptions.find((po) => po.value == requiresPermission).label;

  switch (true) {
    case !!permissionLabel && permissionLabel == 'Private':
      return permissionLabel;

    case !requiresPermission && !!userLabel:
      return userLabel;

    case !userLabel && !!permissionLabel:
      return permissionLabel;

    case !!requiresPermission && !!userLabel:
      return `${permissionLabel}: ${userLabel}`;

    case !requiresPermission && !userLabel && 'BDD User':
    default:
      return 'BDD User';
  }
};
