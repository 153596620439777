import {BRUINS_API_URL, LOGOUT_ROUTE} from "../constants"
import { promise_refresh_user } from "./user";
export const axios = require('axios');

function promise_bruins_api(path, config) {
    return new Promise((resolve, reject) => {
        axios({
            method: config.method || 'GET',
            params: config.params || {},
            url: BRUINS_API_URL + '/' + path,
            data: config.data,
            responseType: config.responseType || 'json',
            withCredentials: true,
            crossDomain: true,
            headers: {
                'X-CSRF-TOKEN': config.refreshCSRF ? localStorage.getItem('refresh_csrf') : localStorage.getItem('access_csrf'),
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                // if this is a non-auth action, get a refresh token
                reject(err)
                if (!(path.indexOf('auth') >= 0)) {
                    promise_refresh_user().then(res => {
                        console.log(`[Refreshing Access Token]`)
                        localStorage.setItem('access_csrf', res.data.access_csrf)
                        window.location.reload()
                    }).catch(err => {
                        console.error(`[Auth Token Refresh Error]: ${err.response}`)
                        window.location.href = LOGOUT_ROUTE
                    })
                }
            }
            reject(err)
        });
    });
}

function promise_request(url, config) {
    if (!('responseType' in config)) {
        config.responseType = 'text'
    }
    const cconfig = {...config, data: config.body}
    return promise_bruins_api(url, cconfig)
}

function jsonify_params(params) {
    return {q: JSON.stringify(params)}
}


export {promise_bruins_api, promise_request, jsonify_params};