/* A component (usually rendered in a modal) that shows reports along with descriptions and stats */

import { BDDTabLink, BDDTabLinkContainer } from "components/bdd";
import BDDErrorBoundary from "components/bdd/bdderrorboundary";
import { useState } from "react";
import styled from "styled-components"
// import AddReportForm from "./addreportform";
import CreateUpdateStatReport from "./CreateUpdateStatReport";
import StatReportSearch from "./StatReportSearch";
import StatReportSelector from "./StatReportSelector";

const Container = styled.div({})

export default function StatReportExplorer({
  target,
  queryVariables,
  handleSelect,
  handleReportCreated,
}) {
  const [tab, setTab] = useState('select')
  return <Container>
    <BDDErrorBoundary>
    <BDDTabLinkContainer leftAlign>
      <BDDTabLink onClick={() => setTab('select')} isactive={tab === 'select'}>
        Select
      </BDDTabLink> 
      <BDDTabLink onClick={() => setTab('search')} isactive={tab === 'search'}>
        Search
      </BDDTabLink> 
      <BDDTabLink onClick={() => setTab('create')} isactive={tab === 'create'}>
        Create
      </BDDTabLink> 
    </BDDTabLinkContainer>
    {tab === 'select' ? <StatReportSelector queryVariables={queryVariables} handleSelect={handleSelect}/>
    : tab === 'create' ? <CreateUpdateStatReport 
      noHeader 
      isUpdate={false}
      forceTarget={target}
      onCompleted={statReportV2 => handleReportCreated(statReportV2)} 
    />
    : tab === 'search' ? <StatReportSearch 
      target={target} 
      handleSelect={handleSelect} 
    />
    : null}
    </BDDErrorBoundary>
  </Container>
}