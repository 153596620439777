import { useSearchParamsState } from 'components/bdd/hooks';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { range } from 'helpers/data';
import { getCurrentSeason, seasonToEndYear } from 'helpers/hockeyutils';
import { useContext } from 'react';
import { PlayerSearchContext } from '..';

const endYear = seasonToEndYear(getCurrentSeason());
const freeAgentYearOptions = range(0, 10).map((i) => {
  const year = endYear + i;

  return {
    value: year,
    label: year,
  };
});

export const useFreeAgentYearFilter = ({ initialYear }) => {
  const { store, setStore } = useContext(PlayerSearchContext);
  const freeAgentYear = store.freeAgentYear || [initialYear];

  return {
    freeAgentYearFilter: freeAgentYear
      ? {
          col: 'free_agent_year',
          val: freeAgentYear,
          cond: 'isin',
        }
      : null,
    freeAgentYearSelect: (
      <StyledBDDSelect
        selectedValue={freeAgentYear}
        options={freeAgentYearOptions}
        selectProps={{
          isMulti: true,
          isClearable: true,
          placeholder: 'Filter to FA year',
        }}
        onChange={(n, v) => {
          setStore((store) => ({
            ...store,
            freeAgentYear: v,
          }));
        }}
      />
    ),
  };
};
