import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipSpan } from 'components/reports';
import { checkIdToIcon } from './DraftPlayerChecks';
import { Container } from 'components/bdd/Layout';

export const DraftCheckFilter = ({ checkId, checkDescription, isChecked, onClick }) => {
  return (
    <TooltipSpan content={checkDescription}>
      <Container
        variant="button"
        onClick={() => {
          onClick(!!isChecked ? false : isChecked == null ? true : null);
        }}
      >
        <FontAwesomeIcon
          icon={checkIdToIcon[checkId]}
          color={isChecked == null ? 'grey' : isChecked ? 'green' : 'red'}
        />
      </Container>
    </TooltipSpan>
  );
};
