import { Column, Container, Row } from 'components/bdd/Layout';
import { BDDTrade } from './BDDTrade';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { EditBDDTrade } from 'components/ProScouting/BDDTrades/EditBDDTrade';
import { useContext } from 'react';
import { ModalContext } from 'components/bdd/Modal';
import { BDDSigning } from './BDDSigning';
import { SigningForm } from './SigningForm';
import { useQuery } from '@apollo/client';

export const BDDSigningDetail = ({
  bddSigning,
  proObjectiveSlug,
  onlyShowEdit,
  canEdit = true,
}) => {
  const { hideModal } = useContext(ModalContext);

  const { buttonGroup, selectedValue } = useButtonGroup({
    options: [
      { label: 'Evaluate', value: 'evaluate' },
      { label: 'Edit', value: 'edit' },
    ],
    initialSelectedValue: 'evaluate',
  });

  const signingForm = (
    <Container paddingTop={4}>
      <SigningForm
        bddPlayerSlug={bddSigning.bddPlayerSlug}
        proObjectiveSlug={proObjectiveSlug}
        existingSigning={bddSigning}
        onCompleted={() => hideModal()}
      />
    </Container>
  );

  if (!canEdit) return null;
  if (onlyShowEdit) return signingForm;

  return (
    <Container minHeight="200px" padding={4}>
      <Column gap={2}>
        <Container width={200}>{buttonGroup}</Container>

        {selectedValue === 'evaluate' ? (
          <Row justifyContent="center">
            <BDDSigning signing={bddSigning} />
          </Row>
        ) : selectedValue === 'edit' ? (
          <SigningForm
            bddPlayerSlug={bddSigning.bddPlayerSlug}
            proObjectiveSlug={proObjectiveSlug}
            existingSigning={bddSigning}
            onCompleted={() => hideModal()}
          />
        ) : null}
      </Column>
    </Container>
  );
};
