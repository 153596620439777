import { useQuery } from '@apollo/client';
import { GET_MEDIA_FOR_FULL_GAME } from 'apollo/queries/video.queries';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { VideoController } from 'components/bdd/Video/components';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { theme } from 'constants';
import styled from 'styled-components';
import { VideoContextProvider } from '..';

const SelectedContainer = styled(Container)({
  borderBottom: (props) =>
    props.selected ? `1px solid ${theme.colors.teams.bos.primary}` : '1px solid #333',
  boxShadow: (props) =>
    props.selected ? `inset 1px 1px 5px ${theme.colors.teams.bos.primary}` : null,
});

export const FullGameVideo = ({ gameUid, placeholder }) => {
  const { data: mediaData, placeholder: mediaPlaceholder } = usePlaceholder(
    useQuery(GET_MEDIA_FOR_FULL_GAME, {
      variables: { gameUid },
    })
  );

  const clips = mediaData?.getMediaForFullGame || [];

  return (
    <VideoContextProvider videoContext={{}}>
      <VideoController
        playbackMode={'fullclip'}
        placeholder={placeholder || mediaPlaceholder}
        clips={clips}
        loading={mediaPlaceholder}
      />
    </VideoContextProvider>
  );
};
