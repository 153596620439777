import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'constants';

const Frame = styled.div({
  width: '100%',
});

const Header = styled.div({
  ...theme.typography.subtitle1,
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing[2],
});

const Button = styled.div({
  ...theme.typography.body1,
  cursor: 'pointer',
});

const DayOfWeek = styled.div({
  ...theme.typography.caption,
  height: theme.spacing[8],
  width: '14.2%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Body = styled.div({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
});

const Day = styled.div(({ isSelected }) => ({
  ...theme.font.base,
  width: '14.2%',
  height: theme.spacing[14],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(isSelected && {
    ...theme.borders.thin,
  }),
}));

export default ({
  renderDay,
  onDateSelected,
  onMonthSelected,
  dataPlaceholder,
}) => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const MONTHS = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const today = new Date();
  const [date, setDate] = useState(today);
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  const handleSetDate = (date) => {
    onDateSelected && onDateSelected(date);
    setDate(date);
  };

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  return (
    <Frame>
      <Header>
        <Button
          onClick={() => {
            const newDate = new Date(year, month - 1, day);
            handleSetDate(newDate);
            onMonthSelected(newDate);
          }}
        >
          Prev
        </Button>
        <div>
          {MONTHS[month]} {year}
        </div>
        <Button
          onClick={() => {
            const newDate = new Date(year, month + 1, day);
            handleSetDate(new Date(year, month + 1, day));
            onMonthSelected(newDate);
          }}
        >
          Next
        </Button>
      </Header>
      {dataPlaceholder || (
        <Body>
          {DAYS_OF_THE_WEEK.map((d) => (
            <DayOfWeek key={d}>
              <strong>{d}</strong>
            </DayOfWeek>
          ))}
          {Array(days[month] + (startDay - 1))
            .fill(null)
            .map((_, index) => {
              const d = index - (startDay - 2);
              return (
                <Day
                  key={index}
                  isToday={d === today.getDate()}
                  isSelected={d === day}
                  onClick={() => handleSetDate(new Date(year, month, d))}
                >
                  {renderDay(d > 0 ? new Date(year, month, d) : null)}
                </Day>
              );
            })}
        </Body>
      )}
    </Frame>
  );
};
