import { useRinknetLeagueSelect } from "components/League"
import { IconButton } from "components/bdd/Button";
import { Label } from "components/bdd/Label/Label";
import { Border, Column, Container, Row } from "components/bdd/Layout"
import { useSeasonSelect } from "components/bdd/Select";
import { StyledBDDSelect } from "components/bdd/Select/StyledBDDSelect";
import { useRinknetGamesSelect } from "components/bdd/Select/useRinknetGamesSelect";
import { Typography } from "components/bdd/Typography";
import TeamsSelect, { useSourcedTeamsSelect } from "components/filters/teamsselect"
import { theme } from "constants";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";


export const ReportGamesSelector = ({ handleSubmit, handleCancel, defaultLeagueId, defaultTeamId }) => {
  const [games, setGames] = useState([]);
  const { rinknetLeagueSelect, rinknetLeagueSelected } = useRinknetLeagueSelect({
    initialLeagueIds: defaultLeagueId,
    selectProps: { isMulti: false }
  });
  const { seasonSelect, season } = useSeasonSelect({
    selectProps: { isMulti: false },
    variant: 'default'
  })
  const { select: teamsSelect, selectedValue: selectedTeam } = useSourcedTeamsSelect({
    useRinknet: true,
    defaultTeam: defaultTeamId,
    league: rinknetLeagueSelected?.value
  });

  const { gameOptions } = useRinknetGamesSelect({
    initialValue: null,
    leagueid: rinknetLeagueSelected?.value,
    teamid: selectedTeam,
    season,
  })

  const options = gameOptions.concat(games.filter(g => !gameOptions.find(go => go.value === g.value)));

  return (
    <Container>
      <Column gap={1}>
        <Typography variant='body1'>Choose game(s) for this report.  You can select games across different leagues/teams.</Typography>
        <Container>
          <Label>League</Label>
          {rinknetLeagueSelect}
        </Container>

        <Container>
          <Label>Season</Label>
          {seasonSelect}
        </Container>

        <Container>
          <Label>Team</Label>
          {teamsSelect}
        </Container>

        <Container>
          <Label>Game</Label>
          <StyledBDDSelect
            selectedValue={games.map(g => g.value)}
            options={options}
            onChange={(n, v) => setGames(options.filter(go => v.includes(go.value)))}
            selectProps={{
              isMulti: true,
              placeholder: games.length > 0 ? 'Select more games...' : 'Select a game...'
            }}

          />
        </Container>

        <Row alignContent='center' style={{ minHeight: '80px' }} gap={2} flexWrap>
          {!games.length && <Typography variant='body1'>No games selected</Typography>}
          {games.map(g => (
            <Border key={g.value}>
              <Container backgroundColor={theme.colors.light.secondary} padding={2}>
                <Row gap={8}>
                  <Typography variant='body1'>{g.label}</Typography>
                  <IconButton icon={<X/>} onClick={() => setGames(games.filter(gm => gm.value !== g.value))} />
                </Row>
              </Container>
            </Border>
          ))}
        </Row>

        <Row justifyContent='center' gap={8}>
          <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
          <Button variant='primary' onClick={() => handleSubmit(games)}>Submit</Button>
        </Row>
      </Column>
    </Container>
  )
}