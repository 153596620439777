import { useRef } from "react";
import { Container, Row } from "../Layout"
import useInnerScroll from "../hooks/useInnerScroll";
import Icon from "../Icon";
import { CaretLeft, CaretRight } from "react-bootstrap-icons";
import styled from "styled-components";

const ShadowButton = styled.div(({ isLeft, show }) => ({
  zIndex: 10,
  transition: 'boxShadow ease 0.5s',
  // transition: ' ease 0.5s',
  visibility: show ? 'visible' : 'hidden',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'vertical',
  justifyContent: 'center',
  top: 0,
  right: !isLeft && 0 ,
  left: isLeft && 0 ,
  height: '100%',
  cursor: 'pointer',
  background: 'white',
  paddingLeft: '5px',
  paddingRight: '5px',
  border: '1px solid #ddd',
  boxShadow: isLeft ? `5px 0px 8px #ccc` : `-5px 0px 8px #ccc`,
  ':hover': {
    boxShadow: isLeft ? `8px 0px 10px #777` : `-8px 0px 10px #777`,

  }
}))

export const useScrollableRow = ({ gap=8 }) => {
  const ref = useRef();
  const { hasLeftScroll, hasRightScroll } = useInnerScroll({ ref });
  const handleScroll = (direction) => {
    if (!ref.current) return;
    const scrollStep = 500;
    if (direction === 'left') {
      ref.current.scrollTo(ref.current.scrollLeft - scrollStep, 0)
    }  else {
      ref.current.scrollTo(ref.current.scrollLeft + scrollStep, 0)
    }
  }
  const render = (children) => {
    return <Container style={{ position: 'relative', minWidth: 0 }}>
      <Row 
        ref={ref}
        justifyContent={hasLeftScroll || hasRightScroll ? 'start' : 'center'} 
        gap={gap}
        alignItems='stretch'
        style={{ scrollBehavior: 'smooth', overflow: 'hidden' }}
      >
        {children}
      </Row>

      <ShadowButton isLeft={true} show={hasLeftScroll} onClick={ev => {
        ev.stopPropagation();
        handleScroll('left');
      }}>
        <Icon icon={<CaretLeft/>} />
      </ShadowButton>

      <ShadowButton show={hasRightScroll} onClick={ev => {
        ev.stopPropagation();
        handleScroll('right');
      }}>
        <Icon icon={<CaretRight/>} />
      </ShadowButton>
    </Container>
  }

  return {
    hasLeftScroll,
    hasRightScroll,
    handleScroll,
    render
  }
}

export const ScrollableRow = ({ children, gap }) => {

  const { render, handleScroll, hasLeftScroll, hasRightScroll } = useScrollableRow({ gap })
  
  return (
    <Container>
      {render(children)}
    </Container>
  );
}

