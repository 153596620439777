import { Column, Container, Row } from 'components/bdd/Layout';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { VideoClipContext } from '..';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { VideoContext } from '../..';

export const VideoClipSortSelect = ({}) => {
  const { eventType } = useContext(VideoContext);
  const { clips, reorderClips } = useContext(VideoClipContext);

  if (eventType != 'events') return null;

  return (
    <Container>
      <StyledBDDSelect
        variant="outlined"
        // selectedValue={JSON.stringify(sort)}
        options={[
          {
            value: 'game_time',
            label: 'Sort by game and time',
          },
          {
            value: 'xpg',
            label: 'Sort by XPG desc',
          },
          ,
        ]}
        selectProps={{
          placeholder: 'Sort by...',
        }}
        onChange={(n, v) => {
          let clipsToReorder = [...clips];

          if (v == 'game_time') {
            clipsToReorder.sort((a, b) => {
              const eventA = a.event;
              const eventB = b.event;

              if (eventA.gameUid != eventB.gameUid) {
                return eventA.gameUid - eventB.gameUid;
              } else {
                return eventA.gameTime - eventB.gameTime;
              }
            });
          } else if (v == 'xpg') {
            clipsToReorder.sort((a, b) => {
              const valueA = a.event.value2 || a.event.value;
              const valueB = b.event.value2 || b.event.value;

              return valueB - valueA;
            });
          }

          reorderClips(clipsToReorder.map((c) => c.id));
        }}
      />
    </Container>
  );
};
