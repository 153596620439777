import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useContractSearchContext, useContractSearchFilterValues } from './context';
import { contractColumns, playerColumns } from './columns';
import { Typography } from 'components/bdd/Typography';
import { DraggableList } from 'components/bdd/DragAndDrop';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useRef, useState } from 'react';
import { IconButton, LinkButton } from 'components/bdd/Button';
import { X } from 'react-bootstrap-icons';
import { theme } from 'constants';
import { GOALIE_COLUMNS_KEY, SKATER_COLUMNS_KEY } from './constants';

export const ColumnDisplayResults = ({}) => {
  const columnIdsToDisplay = useContractSearchContext(
    (state) => state.columnIdsToDisplay
  );

  const { position } = useContractSearchFilterValues();
  const columnIdKey = position == 'G' ? GOALIE_COLUMNS_KEY : SKATER_COLUMNS_KEY;

  const [storedColumnIdsToDisplay, setStoredColumnIds] = useState(
    localStorage.getItem(columnIdKey)?.split(',') || []
  );

  const presetUsed = columnIdsToDisplay.every(
    (id, index) => storedColumnIdsToDisplay.indexOf(id) == index
  );

  const { statDataToUse } = useContractSearchContext((state) => state.data);
  // const columns = useContractSearchContext((state) => state.columns);
  const { setColumnIdsToDisplay } = useContractSearchContext((state) => state.actions);

  const statColumns = statDataToUse?.statDefinitions.reduce((columns, c) => {
    columns[c.label] = {
      id: c.label,
      header: c.label,
      label: c.label,
    };

    return columns;
  }, {});

  const allColumns = { ...playerColumns, ...contractColumns, ...statColumns };
  const ref = useRef();

  return (
    <Container padding={2}>
      <Column gap={2}>
        {!presetUsed && (
          <Row columnGap={1} justifyContent="space-between">
            <LinkButton
              onClick={() => {
                localStorage.setItem(columnIdKey, columnIdsToDisplay);
                setStoredColumnIds(columnIdsToDisplay);
              }}
            >
              <Typography variant="stat">Save as preset</Typography>
            </LinkButton>
            {storedColumnIdsToDisplay.length > 0 && (
              <LinkButton onClick={() => setColumnIdsToDisplay(storedColumnIdsToDisplay)}>
                <Typography variant="stat" color={theme.colors.states.danger}>
                  Revert to saved preset
                </Typography>
              </LinkButton>
            )}
          </Row>
        )}
        <Container width={200}>
          <StyledBDDSelect
            size="sm"
            options={Object.values(allColumns)
              .filter((c) => !columnIdsToDisplay.includes(c.id))
              .map((c) => ({ value: c.id, label: c.header }))}
            selectProps={{
              placeholder: 'Add column...',
              isSearchable: true,
            }}
            onChange={(n, v) => {
              setColumnIdsToDisplay([...columnIdsToDisplay, v]);
              ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }}
          />
        </Container>
        <Container maxHeight={300} overflow="auto">
          <DraggableList
            ids={columnIdsToDisplay.filter((id) => id in allColumns)}
            renderItem={(id, index) => {
              const column = allColumns[id];
              if (!column) {
                return null;
              }

              return (
                <Border>
                  <Container padding={0.5}>
                    <Row columnGap={1} justifyContent="space-between">
                      <Typography variant="label">{column.header}</Typography>
                      <IconButton
                        icon={<X />}
                        onClick={() =>
                          setColumnIdsToDisplay(
                            columnIdsToDisplay.filter((id) => id != column.id)
                          )
                        }
                      />
                    </Row>
                  </Container>
                </Border>
              );
            }}
            noDragPreview={false}
            onChange={({ ids }) => {
              setColumnIdsToDisplay(ids);
            }}
          />
          <div style={{ height: 45 }} ref={ref} />
        </Container>
      </Column>
    </Container>
  );
};
