import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { txToVariants } from './helpers';
import { BDDTradeDetail } from './BDDTradeDetail';
import { BDDTrade } from './BDDTrade';
import { useContext, useEffect, useState } from 'react';
import { BDDSigningDetail } from './BDDSigningDetail';
import { ProObjectiveContext } from '../ProObjectiveContextProvider';
import { BDDSigning } from './BDDSigning';
import { ScenarioContext } from '../Scenarios/ScenarioContextProvider';

export const POTxDetail = ({
  transaction,
  selectedVariantId,
  onlyShowEditSigning,
  onClick,
  canEdit = true,
}) => {
  const getIdxForVariantId = (variantId) => {
    if (!variantId) {
      return 0;
    }
    const idx = txToVariants(transaction).findIndex((v) => v.id == selectedVariantId);
    if (idx < 0) {
      return 0;
    }
    return idx;
  };

  const [selected, setSelected] = useState(getIdxForVariantId(selectedVariantId));
  const objectiveCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { teams } = !!objectiveCtx ? objectiveCtx : scenarioCtx;

  const handleClick = (variant, idx) => {
    setSelected(idx);
    if (!!onClick) onClick(variant, idx);
  };

  useEffect(() => {
    if (selectedVariantId != undefined) {
      setSelected(getIdxForVariantId(selectedVariantId));
    }
  }, [selectedVariantId]);

  return (
    <Container padding={2}>
      <Row gap={16} alignItems="stretch" flexWrap="wrap">
        {txToVariants(transaction).map((variant, idx) => (
          <Border
            key={variant.id}
            onClick={() => handleClick(variant, idx)}
            style={{
              cursor: 'pointer',
              boxShadow: idx === selected ? '0 0 0 2px #007bff' : '',
            }}
          >
            <Container padding={1} height="100%" minWidth={400}>
              {transaction.transactionType === 'TRADE' ? (
                <BDDTrade trade={variant} />
              ) : (
                <BDDSigning signing={variant} />
              )}
            </Container>
          </Border>
        ))}
      </Row>

      {transaction.transactionType === 'TRADE' ? (
        <BDDTradeDetail
          bddTrade={transaction.bddTrades[selected]}
          showAnalysis={true}
          poTeams={teams}
          allowEdit={canEdit}
        />
      ) : (
        <BDDSigningDetail
          bddSigning={transaction.bddSignings[selected]}
          proObjectiveSlug={transaction.proObjectiveSlug}
          onlyShowEdit={onlyShowEditSigning}
          canEdit={canEdit}
        />
      )}
    </Container>
  );
};
