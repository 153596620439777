import gql from 'graphql-tag';
import { PageInfoFragment } from './pagination.queries';
import {
  RinknetPlayerFragment,
  RINKNET_PLAYER_EXTENDED_FRAGMENT,
} from './players.queries';
import { permissions } from 'constants';

export const BddPlayerScoutingReportFragment = gql`
  fragment bddPlayerScoutingReportFragment on BDDPlayerScoutingReport {
    id
    author
    reportType
    creationDate
    position
    positionDetail
    rinknetPlayer {
      firstname
      lastname
      currentteam
      currentleagueabbr
      heightStr
      weight
      birthdate
      handedness
      draftyear
      overall
      selectedbyteam
      bddPlayerSlug {
        slUid
      }
    }
    user {
      id
      email
      username
      rinknetUserId
    }
    rinknetUserId
    rinknetUser {
      id
      userid
      email
      scouttype
      firstname
      lastname
    }
    games {
      edges {
        node {
          id
          date
          hometeamscore
          awayteamscore
          leaguename
          leagueshorthand
          hometeam {
            shortname
          }
          awayteam {
            shortname
          }
        }
      }
    }
    metastatsSplit {
      id
      reportType
      leagueGroup
      league
      position
      wasDraftEligible
      ageGroup
      scoutId
      scoutName
      ratingsMinCount
      ratingsMaxCount
      scoutingMetastats {
        ratingSlug
        rating {
          slug
          ratingType
          primaryName
          shortName
          aliases {
            alias
          }
        }
        count
        mean
        stddev
        max
        min
      }
    }
    ratings
    skillcategoryratings
    notes
    isViewed
    metastatsSplitId
  }
`;

const RinknetReportFragment = gql`
  fragment rinknetReportFragment on RinknetReports {
    id
    author
    rinknetGame {
      id
      gameId
      date
      hometeam {
        id
        longname
        shortname
      }
      awayteam {
        id
        longname
        shortname
      }
      leaguename
      leagueshorthand
      bddGame {
        slGameUid
      }
    }
    playerrinknetid
    rinknetPlayer {
      id
      firstname
      lastname
      bddPlayerSlug {
        slUid
      }
    }
    scoutid
    rinknetUser {
      id
      userid
      firstname
      lastname
    }
    currentteam
    reportteam
    position
    line
    date
    reportNotes: notes
    ratings
    skillcategoryratings
    metastatsSplitId
    metastatsSplit {
      id
      reportType
      leagueGroup
      league
      position
      wasDraftEligible
      ageGroup
      scoutId
      scoutName
      ratingsMinCount
      ratingsMaxCount
      scoutingMetastats {
        ratingSlug
        rating {
          slug
          ratingType
          primaryName
          shortName
          aliases {
            alias
          }
        }
        count
        mean
        stddev
        max
        min
      }
    }
  }
`;

const ScoutingMetastatSplitsFragment = gql`
  fragment scoutingMetastatSplitsFragment on ScoutingMetastatSplits {
    id
    reportType
    leagueGroup
    league
    position
    wasDraftEligible
    ageGroup
    scoutId
    scoutName
    ratingsMinCount
    ratingsMaxCount
    scoutingMetastats {
      ratingSlug
      rating {
        slug
        ratingType
        primaryName
        shortName
        aliases {
          alias
        }
      }
      count
      mean
      stddev
      max
      min
    }
  }
`;

const ScoutingMetastatsFragment = gql`
  fragment scoutingMetastatsFragment on ScoutingMetastats {
    id
    splitId
    split {
      id
      reportType
      leagueGroup
      league
      position
      wasDraftEligible
      ageGroup
      scoutId
    }
    ratingSlug
    rating {
      slug
      ratingType
      primaryName
      shortName
      aliases {
        alias
      }
    }
    count
    mean
    stddev
    max
    min
  }
`;
export const GET_ALL_REPORTS_FOR_PLAYER = gql`
  query GetAllReportsForPlayer($rinknetId: ID!, $scoutId: ID) {
    allPlayerReports(rinknetId: $rinknetId, scoutId: $scoutId) {
      statsData
      metastatSplits {
        ...scoutingMetastatSplitsFragment
      }
      reports {
        __typename
        ... on BDDPlayerScoutingReport {
          ...bddPlayerScoutingReportFragment
        }
        ... on RinknetReports {
          ...rinknetReportFragment
        }
      }
    }
  }
  ${BddPlayerScoutingReportFragment}
  ${RinknetReportFragment}
  ${ScoutingMetastatSplitsFragment}
`;

export const GET_ALL_REPORTS = gql`
  query GetAllReports(
    $limit: Int!
    $offset: Int
    $sort: [BDDSortV2]
    $search: String
    $filters: JSONString
    $scoutingObjectiveSlug: ID
  ) {
    allReports(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      filters: $filters
      scoutingObjectiveSlug: $scoutingObjectiveSlug
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      extraData
      data {
        __typename
        ... on BDDPlayerScoutingReport {
          ...bddPlayerScoutingReportFragment
        }
        ... on RinknetReports {
          ...rinknetReportFragment
        }
      }
    }
  }
  ${BddPlayerScoutingReportFragment}
  ${RinknetReportFragment}
  ${PageInfoFragment}
`;

export const GET_PLAYERS_BY_USER_REPORTS = gql`
  query GetPlayersByUserReports {
    playersByUserReports {
      totalReportCount
      bddReportCount
      rinknetReportCount
      player {
        id
        realId
        firstname
        lastname
        position
      }
    }
  }
`;

export const GET_BDD_PLAYER_REPORTS = gql`
  query GetBddPlayerReports($filters: BDDPlayerScoutingReportFilters) {
    bddPlayerScoutingReports(filters: $filters) {
      ...bddPlayerScoutingReportFragment
    }
    bddPlayerReportViews {
      userId
      reportId
    }
  }
  ${BddPlayerScoutingReportFragment}
`;

export const GET_RINKNET_REPORTS = gql`
  query GetRinknetReports($filters: RinknetReportFilters) {
    rinknetReports(filters: $filters) {
      ...rinknetReportFragment
    }
  }
  ${RinknetReportFragment}
`;

const SummaryReportFragment = gql`
  fragment summaryReportFragment on SummaryReports {
    id
    user {
      id
      username
    }
    isMine
    rinknetUserId
    rinknetUser {
      userid
      firstname
      lastname
    }
    author
    season
    rinknetId
    rinknetPlayer {
      ...RinknetPlayerExtendedFragment
    }
    reportType
    position
    positionDetail
    ratings
    skillcategoryratings
    notes
    lastModified
  }
  ${RINKNET_PLAYER_EXTENDED_FRAGMENT}
`;

export const GET_SUMMARY_REPORT = gql`
  query GetSummaryReport($id: ID!) {
    summaryReport(id: $id) {
      ...summaryReportFragment
    }
  }
  ${SummaryReportFragment}
`;

// export const GET_OR_CREATE_SUMMARY_REPORT = gql`query GetOrCreateSummaryReport(
//     $season: Int!,
//     $rinknetId: ID!,
//     $reportType: ReportType!
// ) {
//     getOrCreateSummaryReport(season: $season, rinknetId: $rinknetId, reportType: $reportType) {
//         ...summaryReportFragment
//     }
// } ${SummaryReportFragment}`

export const CREATE_SUMMARY_REPORT = gql`
  mutation CreateSummaryReport($input: CreateSummaryReportInput!) {
    createSummaryReport(input: $input) {
      summaryReport {
        ...summaryReportFragment
      }
    }
  }
  ${SummaryReportFragment}
`;

export const UPDATE_SUMMARY_REPORT = gql`
  mutation UpdateSummaryReport($input: UpdateSummaryReportInput!) {
    updateSummaryReport(input: $input) {
      summaryReport {
        ...summaryReportFragment
      }
    }
  }
  ${SummaryReportFragment}
`;

export const DELETE_SUMMARY_REPORT = gql`
  mutation DeleteSummaryReport($id: ID!) {
    deleteSummaryReport(id: $id) {
      id
    }
  }
`;

export const LIST_SUMMARY_REPORTS = gql`
  query ListSummaryReports {
    listSummaryReports {
      ...summaryReportFragment
      rinknetPlayer {
        ...rinknetPlayerFragment
      }
    }
  }
  ${RinknetPlayerFragment}
  ${SummaryReportFragment}
`;

export const GET_SUMMARY_REPORTS_BY_PLAYER = gql`
  query GetSummaryReportsByPlayer {
    summaryReportsByPlayer {
      rinknetId
      rinknetPlayer {
        ...rinknetPlayerFragment
      }
      reports {
        ...summaryReportFragment
      }
    }
  }
  ${RinknetPlayerFragment}
  ${SummaryReportFragment}
`;

const ScoutingRatingFragment = gql`
  fragment scoutingRatingFragment on ScoutingRatings {
    slug
    ratingType
    primaryName
    shortName
    aliases {
      alias
    }
  }
`;

const ScoutedPlayerRankingFragment = gql`
  fragment scoutedPlayerRankingFragment on ScoutedPlayerRankings {
    id
    splitId
    rinknetId
    rinknetPlayer {
      id
      firstname
      lastname
      posFDG
    }
    rating {
      slug
      ratingType
      primaryName
      shortName
      aliases {
        alias
      }
    }
    count
    rank
    percentile
    total
  }
`;

const ScoutedPlayerRankingFragmentWithSplit = gql`
  fragment scoutedPlayerRankingWSplitFragment on ScoutedPlayerRankings {
    id
    splitId
    rinknetId
    split {
      id
      scoutId
      scoutName
      position
      timeRange
      isDraftEligible
      leagueGroup
      league
    }
    rating {
      slug
      ratingType
      primaryName
      shortName
      aliases {
        alias
      }
    }
    count
    rank
    percentile
    total
  }
`;

const SPRSplitFragment = gql`
  fragment sPRSplitFragment on ScoutedPlayerRankingSplits {
    id
    scoutId
    scoutName
    position
    timeRange
    isDraftEligible
    leagueGroup
    league
    predictedGrades
  }
`;
export const GET_SCOUTED_PLAYER_RANKINGS = gql`
  query GetScoutedPlayerRankings(
    $rinknetId: ID!
    $splitId: ID
    $scoutId: ID
    $useDefaultSplit: Boolean
    $position: String
    $timeRange: String
    $isDraftEligible: Boolean
    $leagueGroup: String
    $league: String
    $predictedGrades: Boolean
  ) {
    scoutedPlayerRankings(
      rinknetId: $rinknetId
      splitId: $splitId
      scoutId: $scoutId
      useDefaultSplit: $useDefaultSplit
      position: $position
      timeRange: $timeRange
      isDraftEligible: $isDraftEligible
      leagueGroup: $leagueGroup
      league: $league
      predictedGrades: $predictedGrades
    ) {
      totalPlayers
      rinknetPlayer {
        id
        firstname
        lastname
        posFDG
      }
      split {
        ...sPRSplitFragment
      }
      otherValidSplits {
        ...sPRSplitFragment
      }
      rankings {
        ...scoutedPlayerRankingFragment
      }
    }
  }
  ${ScoutedPlayerRankingFragment}
  ${SPRSplitFragment}
`;

export const GET_SCOUTING_RANKINGS_FOR_PLAYER = gql`
  query GetScoutingRankingsForPlayer(
    $rinknetId: ID!
    $position: String
    $timeRange: TimeRangeEnum
    $isDraftEligible: Boolean
    $leagueGroup: String
    $league: String
  ) {
    scoutingRankingsForPlayer(
      rinknetId: $rinknetId
      position: $position
      timeRange: $timeRange
      isDraftEligible: $isDraftEligible
      leagueGroup: $leagueGroup
      league: $league
    ) {
      rinknetPlayer {
        id
        firstname
        lastname
        posFDG
        isdrafteligible
      }
      rankings {
        ...scoutedPlayerRankingWSplitFragment
      }
    }
  }
  ${ScoutedPlayerRankingFragmentWithSplit}
`;

export const GET_FULL_SCOUTED_RANKING_SET = gql`
  query GetFullScoutedRankingSet(
    $ratingSlug: String
    $splitId: ID
    $scoutId: ID
    $position: String
    $timeRange: String
    $isDraftEligible: Boolean
    $leagueGroup: String
    $league: String
    $predictedGrades: Boolean
    $rinknetIds: [ID]
  ) {
    fullScoutedRankingSet(
      ratingSlug: $ratingSlug
      splitId: $splitId
      scoutId: $scoutId
      position: $position
      timeRange: $timeRange
      isDraftEligible: $isDraftEligible
      leagueGroup: $leagueGroup
      league: $league
      predictedGrades: $predictedGrades
      rinknetIds: $rinknetIds
    ) {
      ratings
      playerRankings {
        rinknetPlayer {
          id
          realId
          firstname
          lastname
          currentteam
          currentleagueabbr
          bddPlayerSlug {
            slug
          }
        }
        rankings {
          ratingSlug
          rating {
            slug
            primaryName
            shortName
          }
          rank
          percentile
          count
          total
        }
      }
      split {
        ...sPRSplitFragment
      }
    }
  }
  ${SPRSplitFragment}
`;

export const LIST_SCOUTING_RATINGS = gql`
  query ListScoutingRatings {
    listScoutingRatings {
      ...scoutingRatingFragment
    }
  }
  ${ScoutingRatingFragment}
`;

export const LIST_SCOUTED_PLAYER_RANKING_SPLITS = gql`
  query ListScoutedPlayerRankingSplits($scoutId: ID) {
    listScoutedPlayerRankingSplits(scoutId: $scoutId) {
      ...sPRSplitFragment
    }
  }
  ${SPRSplitFragment}
`;

export const GET_REPORT_FILTER_OPTIONS = gql`
  query GetReportFilterOptions($scoutingObjectiveSlug: ID) {
    scoutingReportFilterOptions(scoutingObjectiveSlug: $scoutingObjectiveSlug) {
      leagues {
        id
        realId
        name
        shorthand
      }
      scouts {
        id
        username
      }
    }
  }
`;

export const SEARCH_FOR_REPORT_PLAYERS = gql`
  query SearchForReportPlayers(
    $term: String!
    $filters: JSONString
    $scoutingObjectiveSlug: ID
  ) {
    searchForReportPlayers(
      term: $term
      filters: $filters
      scoutingObjectiveSlug: $scoutingObjectiveSlug
    ) {
      ...rinknetPlayerFragment
    }
  }
  ${RinknetPlayerFragment}
`;

const UserReportPermissionsFragment = gql`fragment userReportPermissionsFragment on Users {
  id
  name
  username
  firstname
  lastname
  hasPermission(permission: "${permissions.viewPlayerReports}")
  scoutingReportPermissions {
    userId
    authorUserIds
    authorRinknetIds
    authorUsers {
      id
      username  
    }
  }
}`;

export const GET_USER_REPORT_PERMISSIONS = gql`
  query GetUserReportPermissions {
    userReportPermissions {
      ...userReportPermissionsFragment
    }
  }
  ${UserReportPermissionsFragment}
`;

export const UPDATE_USER_REPORT_PERMISSIONS = gql`
  mutation UpdateUserReportPermissions(
    $userId: ID!
    $usersToAdd: [Int!]
    $usersToRemove: [Int!]
  ) {
    updateUserReportPermissions(
      userId: $userId
      usersToAdd: $usersToAdd
      usersToRemove: $usersToRemove
    ) {
      user {
        ...userReportPermissionsFragment
      }
    }
  }
  ${UserReportPermissionsFragment}
`;

export const GET_PLAYER_VIEW_STATISTICS = gql`
  query GetPlayerViewStatistics($rinknetIds: [ID], $season: Int) {
    playerViewStatistics(rinknetIds: $rinknetIds season: $season) {
      rinknetId
      statistics
    }
  }
`;

export const GET_PLAYER_VIEW_STATS_BY_GAME = gql`
  query GetPlayerViewStatsByGame($rinknetId: ID, $season: Int) {
    playerViewStatsByGame(rinknetId: $rinknetId, season: $season)
  }
`;


export const GET_DRAFT_PLAYER_CHECKS = gql`
  query GetDraftPlayerChecks(
    $rinknetIds: [ID]
    $draftYear: Int
  ) {
    draftPlayerChecks(
      rinknetIds: $rinknetIds
      draftYear: $draftYear
    ) {
      id
      rinknetId
      draftYear
      checkId
      isChecked
      checkDescription
      payload
    }
  }
`