import { LinkButton } from "components/bdd/Button"
import { Container, Row } from "components/bdd/Layout"
import { useModal } from "components/bdd/Modal"
import { Typography } from "components/bdd/Typography"
import { PlayerHeightWeightChart } from "./PlayerHeightWeightChart"
import { useQuery } from "@apollo/client"
import { GET_UPLOAD_ROWS } from "apollo/queries/upload.queries"
import { HEIGHT_WEIGHT_TRACKER_SLUG } from "constants/upload.constants"
import Icon from "components/bdd/Icon"
import { GraphUpArrow } from "react-bootstrap-icons"


export const PlayerHeightWeight = ({ rinknetPlayer, rinknetIds }) => {
  if (!rinknetIds || rinknetIds.length == 0) {
    rinknetIds = [rinknetPlayer.realId]
  }

  const { data, loading, error } = useQuery(GET_UPLOAD_ROWS, {
    variables: {
      slug: HEIGHT_WEIGHT_TRACKER_SLUG,
      filters: [{ col: 'RinkNet ID', cond: 'isin', val: rinknetIds.map(id => `${id}`) }]
    }
  })
  const uploadRows = data?.uploadRows?.filter(r => r['RinkNet ID'] == rinknetPlayer.realId);

  const { renderModal, showModal, setShowModal } = useModal({
    title: `${rinknetPlayer.firstname} ${rinknetPlayer.lastname} Height/Weight Chart`
  });
  return <Container>
    {showModal && renderModal(<PlayerHeightWeightChart rinknetPlayer={rinknetPlayer} data={uploadRows} />)}
    <LinkButton onClick={ev => {
      ev.stopPropagation(); 
      setShowModal(true);
    }}>
      <Row gap={2}>
        {!!uploadRows?.length && <Icon icon={<GraphUpArrow/>}/>}
        <Typography variant="body2">
          {rinknetPlayer.heightStr} 
          {!!rinknetPlayer.weight ? <> | {rinknetPlayer.weight} lbs</> : null}
        </Typography>
      </Row>
    </LinkButton>
  </Container>
}