import { useState } from "react";
import { mapStatsSelectToQuery, mapStatsSelectToReport } from "../helpers";
import StatsSelect from "../statsselect";


export default function useStatsSelect({
  defaultStats=[], // list of stats [{ slug, label, filterSetId, norm }]
  ...rest
}) {
  const [stats, setStats] = useState(defaultStats)

  const statsSelectComponent = <StatsSelect
    stats={stats}
    setStats={setStats}
    {...rest}
  />

  const statDefs = mapStatsSelectToQuery(stats)
  const statReportStats = mapStatsSelectToReport(stats)

  return {
    stats, // raw stats (in format StatsSelect uses)
    statDefs, // stats ready to use in statDefs attribute of a query
    statReportStats, // stats in format used by stat reports
    statsSelectComponent,
    forceSetStatDefs: setStats
  }
}