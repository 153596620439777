import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { NumberInput } from 'components/bdd/NumberInput/NumberInput';
import { Typography } from 'components/bdd/Typography';
import { getShiftLabel } from 'components/bdd/Video/components/ShiftList';
import { useEventLabel } from 'components/bdd/Video/Filtering';
import BDDInputField from 'components/form/bddinputfield';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { usePlaylistMutation } from '.';

export const EditPlaylistClipForm = ({ playlist, clip, clips, onSubmit }) => {
  const schema = Yup.object({
    name: Yup.string(),
    description: Yup.string(),
    frontPad: Yup.number(),
    backPad: Yup.number(),
  });

  const targetClip = clip || clips[0];

  const initialValues = {
    name: targetClip.name || '',
    description: targetClip.description || '',
    frontPad: targetClip.frontPad || 5,
    backPad: targetClip.backPad || 5,
  };

  const { updatePlaylist } = usePlaylistMutation(playlist);
  const handleSubmit = (values) => {
    let clipsToUpdate = [];
    if (!!clip) {
      clipsToUpdate.push({
        id: targetClip.id,
        playlistId: playlist?.id,
        playlistNumber: targetClip.playlistNumber,
        ...values,
      });
    } else {
      clipsToUpdate = clips.map((c) => ({
        id: c.id,
        playlistId: playlist?.id,
        playlistNumber: c.playlistNumber,
        ...values,
      }));
    }

    if (playlist) {
      updatePlaylist({
        variables: {
          input: {
            id: playlist.id,
          },
          clipsToUpdate,
        },
      });
    }

    onSubmit && onSubmit(clipsToUpdate);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors, setFieldValue }) => {
        const eventLabel = useEventLabel({
          event: targetClip.event,
          name: values.name,
          description: values.description,
        });

        const shiftLabel = getShiftLabel(
          targetClip.shift,
          values.name,
          values.description
        );

        const clipName = (
          <Typography as="span" variant="body2">
            {!!clips && 'Example:'} {eventLabel || shiftLabel}
          </Typography>
        );

        return (
          <Form>
            <Container padding={3}>
              <Column gap={2}>
                {clipName}
                <Field
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder={'Name'}
                  component={BDDInputField}
                />
                <Field
                  autoComplete="off"
                  type="text"
                  name="description"
                  placeholder="Description"
                  component={BDDInputField}
                />
                <Row columnGap={3}>
                  <Row columnGap={1} alignItems="baseline">
                    <label htmlFor="lead-time">
                      <Typography variant="body1">Lead time:</Typography>
                    </label>
                    <NumberInput
                      id="lead-time"
                      type="number"
                      min={2}
                      max={600}
                      value={values.frontPad}
                      onChange={(ev) =>
                        setFieldValue('frontPad', parseInt(ev.target.value))
                      }
                    />
                  </Row>
                  <Row columnGap={1} alignItems="baseline">
                    <label htmlFor="lead-time">
                      <Typography variant="body1">Tail time:</Typography>
                    </label>
                    <NumberInput
                      id="tail-time"
                      type="number"
                      min={2}
                      max={600}
                      value={values.backPad}
                      onChange={(ev) =>
                        setFieldValue('backPad', parseInt(ev.target.value))
                      }
                    />
                  </Row>
                </Row>
              </Column>
            </Container>
            <Divider />
            <Container padding={2}>
              <Row justifyContent="end">
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-dark"
                  disabled={isSubmitting}
                >
                  <Typography variant="body1">
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Typography>
                </Button>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
