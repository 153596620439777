import React, { useState, useEffect } from 'react';

export default function useOnScreen(
  ref,
  defaultValue = false,
  resetVariable = null,
  rootMargin = '0px'
) {
  const [isIntersecting, setIntersecting] = useState(defaultValue);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );

    const element = ref?.current;
    if (!!element) {
      observer.observe(element);
    }

    return () => {
      if (!!element) {
        observer.unobserve(element);
      }
    };
  }, [ref, resetVariable]);

  return isIntersecting;
}
