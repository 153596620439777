import { gql } from '@apollo/client';
import { BddTagFragment } from './bddtags.queries';
import { BFPlayerContractFragment } from './contract.queries';
import { BFCapHitFragment } from './bruinsfriendly.queries';

const BDDDepthChartFragment = gql`
  fragment bddDepthChartFragment on BDDDepthChart {
    id
    uniqueKey
    title
    name
    description
    creationDate
    user {
      id
      username
    }
    lastModified
    lastModifiedUser {
      id
      username
    }
    active
    isMyDepthChart
    isEditable
    slTeam {
      slug
      longname
      shorthand
      nhlid
      leagueSlug
    }
  }
`;
const BDDDepthChartSpotFragment = gql`
  fragment bddDepthChartSpotFragment on DepthChartSpot {
    id
    playerSlug
    position
    lineNum
    depthLeague
    lineupSpot
  }
`;

export const BDDDepthChartSpotWrapperFragment = gql`
  fragment bddDepthChartSpotWrapperFragment on DepthChartSpotWrapper {
    id
    bddDepthChartId
    position
    lineNum
    depthLeague
    capHit
    term
    notes
    payload
  }
`;

export const BDDDepthChartChangeFragment = gql`
  fragment bddDepthChartChangeFragment on BDDDepthChartChange {
    id
    bddDepthChartId
    timestamp
    user {
      id
      username
    }
    notes
    changeType
    bddPlayer1 {
      slug
      fullName
      firstname
      lastname
    }
    slTeam1 {
      slug
      longname
      shorthand
      nhlid
    }
    position1
    depthLeague1
    lineNum1

    bddPlayer2 {
      slug
      fullName
      firstname
      lastname
    }
    slTeam2 {
      slug
      longname
      shorthand
      nhlid
    }
    position2
    depthLeague2
    lineNum2
  }
`;

const BDDDepthChartBDDPlayerFragment = gql`
  fragment bddDepthChartBDDPlayerFragment on BDDPlayerSlug {
    id
    slug
    firstname
    lastname
    slUid
    bfSlug
    roleTag {
      id
      flags
      isProjection
      tag {
        ...bddTagFragment
      }
    }
    bddManagedTags {
      id
      flags
      isProjection
      tag {
        ...bddTagFragment
      }
    }
    availability
    contractStatus
  }
`;
const BDDDepthChartRinknetPlayerFragment = gql`
  fragment bddDepthChartRinknetPlayerFragment on RinknetPlayer {
    id
    firstname
    lastname
    age
    position
    posFDG
    round
    caphitamount
    contractstatus
    currentleague
    masterdepthchartorder
    masterdepthchartposition
    jerseynumber
    progamesplayed
  }
`;


export const BDDCapSheetFragment = gql`
  fragment bddCapSheetFragment on BDDCapSheet {
    teamSummary {
      projectedCapHit
      projectedCapSpace
      projectedLtiUsed
      currentCapHit
      currentCapSpace
      rosterCapHit
      deadCapHit
      rosterSize
      upperLimit
      numContracts
      buriedCount
      buriedCapHit
      isInLti
      ltirSalaryRelief
      ltirSalaryUsed
      ltirSalaryRemaining
      ltirBonusRelief
      ltirBonusUsed
      ltirBonusRemaining
      activeForwardsCapHit
      activeDefensemenCapHit
      activeGoalieCapHit
      pctCapForwards
      pctCapDefensemen
      pctCapGoalie
    }
    salaryCap {
      upperLimit
      lowerLimit
      maxBuried
    }
    players {
      playerSlug
      bddPlayer {
        slug
        firstname
        lastname
      }
      capHit
      remainingTerm
      source
      position
      hasFutureContract
    }
  }
`;

const rankedPlayerListFragment = gql`
  fragment rankedPlayerListFragment on DepthChartPlayerRankList {
    depthChartId
    playerListId
    title
    playerList {
      ...playerListFragment
    }
  }
`;

export const buildBDDDepthChartQuery = ({
  includeContracts = false,
  includeRinknetPlayer = false,
  includeCapSheet = false,
}) => {
  const q = `query GetBddDepthChart($id: ID, $teamSlug: String, $key: String) {
    bddDepthChart(id: $id, teamSlug: $teamSlug, key: $key) {
      ...bddDepthChartFragment
      depthChartSpotWrappers {
        ...bddDepthChartSpotWrapperFragment
      }
      depthChartPlayers {
        ...bddDepthChartSpotFragment
        bddPlayer {
          ...bddDepthChartBDDPlayerFragment
          ${
            includeContracts
              ? `bfCapHit {
              ...bfCapHitFragment
            }`
              : ''
          }
          ${
            includeRinknetPlayer
              ? `rinknetPlayer {
              ...bddDepthChartRinknetPlayerFragment
            }`
              : ''
          }
        }
      }
      ${
        includeCapSheet
          ? `capSheet {
            ...bddCapSheetFragment
          }`
          : ''
      }
    }
  }`;
  return gql`
    ${q}
    ${BDDDepthChartFragment}
    ${BDDDepthChartSpotFragment}
    ${!!includeCapSheet ? BDDCapSheetFragment : ''}
    ${includeRinknetPlayer ? BDDDepthChartRinknetPlayerFragment : ''}
    ${BDDDepthChartBDDPlayerFragment}
    ${BddTagFragment}
    ${includeContracts ? BFCapHitFragment : ''}
    ${BDDDepthChartSpotWrapperFragment}
  `;
};

export const GET_RINKNET_BENCH_DEPTH_PLAYERS = gql`
  query GetRinknetBenchDepthPlayers($slTeamSlug: ID!) {
    getRinknetTeamDepth(slTeamSlug: $slTeamSlug) {
      ...bddDepthChartRinknetPlayerFragment
      bddPlayerSlug {
        ...bddDepthChartBDDPlayerFragment
        bfAllContracts {
          ...bfPlayerContractFragment
        }
      }
    }
  }
  ${BDDDepthChartBDDPlayerFragment}
  ${BDDDepthChartRinknetPlayerFragment}
  ${BFPlayerContractFragment}
  ${BddTagFragment}
`;

export const GET_BDD_DEPTH_CHARTS = gql`
  query GetBddDepthCharts(
    $activeOnly: Boolean
    $keyedOnly: Boolean
    $teamSlug: String
    $key: String
  ) {
    bddDepthCharts(
      activeOnly: $activeOnly
      keyedOnly: $keyedOnly
      teamSlug: $teamSlug
      key: $key
    ) {
      id
      name
      uniqueKey
      description
      user {
        id
        username
      }
      lastModified
      lastModifiedUser {
        id
        username
      }
      teamSlug
      slTeam {
        slug
        longname
      }
    }
  }
`;

export const UPDATE_BDD_DEPTH_CHART = gql`
  mutation UpdateBddDepthChart(
    $input: UpdateBDDDepthChartInput!
    $lineupSpotsToSet: [SetDepthSpotInput!]
    $playersToRemove: [ID!]
  ) {
    updateBddDepthChart(
      input: $input
      lineupSpotsToSet: $lineupSpotsToSet
      playersToRemove: $playersToRemove
    ) {
      bddDepthChart {
        ...bddDepthChartFragment
        depthChartPlayers {
          ...bddDepthChartSpotFragment
          bddPlayer {
            ...bddDepthChartBDDPlayerFragment
          }
        }
      }
      externalEffects {
        bddDepthChartId
        ...bddDepthChartSpotFragment
        depthChart {
          id
          slTeam {
            slug
            longname
          }
        }
      }
    }
  }
  ${BDDDepthChartFragment}
  ${BDDDepthChartSpotFragment}
  ${BDDDepthChartBDDPlayerFragment}
  ${BddTagFragment}
`;

export const CREATE_BDD_DEPTH_CHART = gql`
  mutation CreateBddDepthChart($input: CreateBDDDepthChartInput!) {
    createBddDepthChart(input: $input) {
      bddDepthChart {
        ...bddDepthChartFragment
        depthChartPlayers {
          ...bddDepthChartSpotFragment
        }
      }
    }
  }
  ${BDDDepthChartFragment}
  ${BDDDepthChartSpotFragment}
`;

export const GET_TEAM_ACTIVE_DEPTH_CHART = gql`
  query GetTeamActiveDepthChart($teamSlug: ID!, $uniqueKey: String) {
    activeDepthChartForTeam(teamSlug: $teamSlug, uniqueKey: $uniqueKey) {
      ...bddDepthChartFragment
    }
  }
  ${BDDDepthChartFragment}
`;

export const COPY_BDD_DEPTH_CHART = gql`
  mutation CreateBddDepthChart(
    $destUniqueKey: String!
    $sourceDepthChartId: ID
    $sourceTeamSlug: ID
  ) {
    copyBddDepthChart(
      destUniqueKey: $destUniqueKey
      sourceDepthChartId: $sourceDepthChartId
      sourceTeamSlug: $sourceTeamSlug
    ) {
      bddDepthChart {
        ...bddDepthChartFragment
        depthChartPlayers {
          ...bddDepthChartSpotFragment
        }
      }
    }
  }
  ${BDDDepthChartFragment}
  ${BDDDepthChartSpotFragment}
`;

export const CREATE_BDD_DEPTH_CHART_KEY = gql`
  mutation CreateBddDepthChartKey($input: BDDDepthChartKeyInput!) {
    createBddDepthChartKey(input: $input) {
      bddDepthChartKey {
        key
        name
        description
      }
    }
  }
`;

export const GET_BDD_DEPTH_CHART_KEYS = gql`
  query GetBddDepthChartKeys {
    bddDepthChartKeys {
      key
      name
      description
    }
  }
`;

export const CREATE_DEPTH_CHART_SPOT_WRAPPER = gql`
  mutation CreateDepthChartSpotWrapper($id: ID!, $input: DepthSpotWrapperInput!) {
    createDepthChartSpotWrapper(id: $id, input: $input) {
      depthChartSpotWrapper {
        ...bddDepthChartSpotWrapperFragment
      }
    }
  }
  ${BDDDepthChartSpotWrapperFragment}
`;

export const UPDATE_DEPTH_CHART_SPOT_WRAPPER = gql`
  mutation UpdateDepthChartSpotWrapper($id: ID!, $input: DepthSpotWrapperInput!) {
    updateDepthChartSpotWrapper(id: $id, input: $input) {
      depthChartSpotWrapper {
        ...bddDepthChartSpotWrapperFragment
      }
    }
  }
  ${BDDDepthChartSpotWrapperFragment}
`;

export const DELETE_DEPTH_CHART_SPOT_WRAPPER = gql`
  mutation DeleteDepthChartSpotWrapper($id: ID!) {
    deleteDepthChartSpotWrapper(id: $id) {
      id
    }
  }
`;
