import { ModalContextProvider } from 'components/bdd/Modal';
import React, { createContext } from 'react';

export const ProObjectiveContext = createContext();

export const ProObjectiveContextProvider = ({ context, children }) => {
  return (
    <ProObjectiveContext.Provider value={context}>
      <ModalContextProvider>
        {children}
      </ModalContextProvider>
    </ProObjectiveContext.Provider>
  );
};
