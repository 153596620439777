import { useQuery } from '@apollo/client';
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries';
import { IconButton } from 'components/bdd/Button';
import { useSearchParamsStore } from 'components/bdd/hooks';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { usePlayerSLGames } from 'components/SLStats/hooks';
import { theme } from 'constants';
import { XLg } from 'react-bootstrap-icons';

export const ComparePlayerTag = ({ slug }) => {
  const store = useSearchParamsStore();
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_CARD, {
      variables: { slug },
    })
  );

  const rinknetPlayer = data?.bddPlayer.rinknetPlayer;

  return (
    <Border borderRadius={1}>
      <Container padding={2}>
        <Row columnGap={2} justifyContent="space-between">
          <Typography variant="body1">
            {rinknetPlayer
              ? `${rinknetPlayer.firstname} ${rinknetPlayer.lastname}`
              : 'Loading Player...'}
          </Typography>
          <IconButton
            size={16}
            icon={<XLg />}
            hoverColor={theme.colors.teams.bos.primary}
            tooltip="Remove this player"
            onClick={() =>
              store.setArray(
                'selectedPlayerSlugs',
                store
                  .getArray('selectedPlayerSlugs')
                  .filter((s) => s !== data?.bddPlayer.slug)
              )
            }
          />
        </Row>
      </Container>
    </Border>
  );
};
