import { IconButton } from 'components/bdd/Button';
import { useContractSearchContext } from './context';
import { ChevronDown, Plus, XLg } from 'react-bootstrap-icons';
import { Typography } from 'components/bdd/Typography';
import { Row, Container } from 'components/bdd/Layout';
import { Button, Dropdown } from 'react-bootstrap';
import Icon from 'components/bdd/Icon';
import styled from 'styled-components';
import {
  CREATE_CONTRACT_LIST,
  GET_CONTRACT_LISTS,
  UPDATE_BF_CONTRACT_LIST,
} from 'apollo/queries/bruinsfriendly.queries';
import { useMutation, useQuery } from '@apollo/client';
import { permissions } from 'constants';
import { useUser } from 'helpers/user';
import { useContext } from 'react';
import { ModalContext } from 'components/bdd/Modal';
import { ContractListForm } from './ContractListForm';

const DropdownStyles = styled.div({
  '.dropdown-toggle::after': {
    display: 'none',
  },
});

export const ContractActionToolbar = ({}) => {
  const selectedContracts = useContractSearchContext((state) => state.selectedContracts);
  const contractList = useContractSearchContext((state) => state.contractList);
  const { setSelectedContracts } = useContractSearchContext((state) => state.actions);
  const { data, loading } = useQuery(GET_CONTRACT_LISTS);
  const contractLists = data?.bfContractLists;

  const [updateContractList, { loading: updatingLoading }] = useMutation(UPDATE_BF_CONTRACT_LIST);
  const [addContractList] = useMutation(CREATE_CONTRACT_LIST);
  const { userHasPermission } = useUser();
  const { renderModal, hideModal } = useContext(ModalContext);

  if (!selectedContracts || selectedContracts.length == 0) return;

  return (
    <Container>
      <Row columnGap={2}>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => setSelectedContracts([])}
        >
          <Typography variant="stat">
            <b>Clear {selectedContracts.length} selected</b>
          </Typography>
        </Button>
        {userHasPermission(permissions.manageCFContractLists) && (
          <DropdownStyles>
            <Dropdown>
              <Dropdown.Toggle variant="dark" size="sm">
                <Row columnGap={2}>
                  <Typography variant="stat">
                    <b>Add contracts to list</b>
                  </Typography>
                  <Icon icon={<ChevronDown />} />
                </Row>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {loading && <Typography variant="body2">Loading..</Typography>}
                {contractLists?.map((c) => (
                  <Dropdown.Item
                    key={c.title}
                    onClick={() => {
                      updateContractList({
                        variables: {
                          input: {
                            id: c.id,
                          },
                          contractsToAdd: selectedContracts.map((sc, index) => ({
                            contractId: sc.id,
                            order: c.contracts.length + index + 1,
                          })),
                        },
                        onCompleted: () => {
                          setSelectedContracts([]);
                        },
                        refetchQueries: [GET_CONTRACT_LISTS],
                      });
                    }}
                  >
                    <Typography variant="body2">{c.title}</Typography>
                  </Dropdown.Item>
                ))}
                <Dropdown.Item
                  onClick={() => {
                    renderModal({
                      title: <Typography variant="h6">Add Contract List</Typography>,
                      body: (
                        <ContractListForm
                          defaultValues={{ contracts: selectedContracts }}
                          onSubmit={(values) => {
                            addContractList({
                              variables: {
                                input: {
                                  title: values.title,
                                  description: values.description,
                                },
                                contractsToAdd: values.contracts.map((c, idx) => ({
                                  contractId: c.id,
                                  order: idx,
                                })),
                              },
                              onCompleted: () => {
                                setSelectedContracts([]);
                                hideModal();
                              },
                              refetchQueries: [GET_CONTRACT_LISTS],
                            });

                            hideModal();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <Row columnGap={1}>
                    <Icon icon={<Plus />} />
                    <Typography variant="stat">
                      <b>Add new list</b>
                    </Typography>
                  </Row>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </DropdownStyles>
        )}
        {!!contractList?.id && userHasPermission(permissions.manageCFContractLists) && (
          <Button
            variant="danger"
            size="sm"
            disabled={updatingLoading}
            onClick={() => {
              updateContractList({
                variables: {
                  input: {
                    id: contractList.id,
                  },
                  contractsToRemove: selectedContracts.map((sc) => ({
                    contractId: sc.id,
                  })),
                },
                onCompleted: () => {
                  setSelectedContracts([]);
                },
                refetchQueries: [GET_CONTRACT_LISTS],
              });
            }}
          >
            <Typography variant="stat">
              <b>Remove from list</b>
            </Typography>
          </Button>
        )}
        {updatingLoading && (
          <Typography variant="stat">
            <b>Updating...</b>
          </Typography>
        )}
      </Row>
    </Container>
  );
};
