import React from 'react';
import { theme } from 'constants';
import styled from 'styled-components';
import { BDDLoader } from 'components/bdd/bddloader';
import Event from './Event';
import { Column, Container, Row } from 'components/bdd/Layout';
const Styles = styled.div({
  ...theme.font.base,
});

export default function EventBank({
  events,
  loading,
  selectedEvents,
  setSelectedEvents,
  height='60vh',
  onPlay,
}) {
  const handleToggleSelect = (event) => {
    const newSelectedEvents = selectedEvents.find((ev) => ev.id === event.id)
      ? selectedEvents.filter((ev) => ev.id !== event.id)
      : [...selectedEvents, event];

    setSelectedEvents(newSelectedEvents);
  };
  console.log(events)
  return (
    <Styles>
      {loading ? (
        <BDDLoader variant="squares" />
      ) : !events ? (
        <center>No events loaded</center>
      ) : (
        <Container overflowY="auto" height={height}>
          {events.map((e) => (
            <Event
              key={e.id}
              event={e}
              selected={selectedEvents.find((ev) => ev.id === e.id)}
              onClick={(_) => handleToggleSelect(e)}
              onPlay={onPlay}
            />
          ))}
        </Container>
      )}
    </Styles>
  );
}
