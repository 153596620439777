import styled from 'styled-components'
import './pulsing.css'

const Styles = styled.div(({
  color='#77777777',
  size=5,
  radius='5px'
}) => {
  return `
    border-radius: ${radius};
    box-shadow: 0 0 ${size}px ${color};
    animation: pulse 1s ease-out infinite;
    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 ${color};
      }
      70% {
          -webkit-box-shadow: 0 0 0 ${size*1.5}px ${color};
      }
      100% {
          -webkit-box-shadow: 0 0 0 0 ${color};
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 ${color};
        box-shadow: 0 0 0 0 ${color};
      }
      70% {
          -moz-box-shadow: 0 0 0 ${size*1.5}px ${color};
          box-shadow: 0 0 0 ${size*1.5}px ${color};
      }
      100% {
          -moz-box-shadow: 0 0 0 0 ${color};
          box-shadow: 0 0 0 0 ${color};
      }
    }
  `
});

export const PulsingShadow = ({ children }) => {
  console.log('here')
  return (
    <Styles className='test'>
      {children}
    </Styles>
  )
}