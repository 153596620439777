export const StatTypes = [
    'OnIceDependentStat',
    'CountSumStat',
    'AgainstDependentStat',
    'RateStat',
    'PositionDependentStat',
    'ZoneDependentStat',
    'OneMinusStat',
    'PlusMinusStat'
]

export const StatTargets = [
    'skaters',
    'goalies',
    'teams',
    'lines',
    'pairs',
    'units'
]