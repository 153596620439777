import React from 'react';
import { useField } from 'formik';
import { TextEditor } from '.';

export const TextEditorField = ({ header, ...rest }) => {
  const [_, meta, helpers] = useField(rest.name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <TextEditor
      header={header}
      onTextUpdated={(text) => setValue(text)}
      text={value}
      {...rest}
    />
  );
};
