import { Column, Container } from 'components/bdd/Layout';
import Toggle from 'components/bdd/Toggle/Toggle';
import { useContext } from 'react';
import { SLStatContext } from '..';

export const Per20Toggle = ({ label='Per 20' }) => {
  const { filters, setFilters } = useContext(SLStatContext);

  return (
    <Container width={100}>
      <Column>
        <Toggle 
          label={label} 
          toggled={filters.norm == '20'} 
          setToggled={(per20) => {
            setFilters({ norm: per20 ? '20' : 'None' })
          }}
        />
      </Column>
    </Container>
  );
};
