import { Row } from 'components/bdd/Layout';
import { useFilterContext } from './context';
import { Typography } from 'components/bdd/Typography';
import { LinkButton } from 'components/bdd/Button';
import { theme } from 'constants';
import React from 'react';

export const FilterControls = React.memo(({}) => {
  const { showAllGroups } = useFilterContext((state) => state.actions);

  return (
    <Row columnGap={3}>
      <LinkButton onClick={showAllGroups}>
        <Typography variant="label">Show all filters</Typography>
      </LinkButton>
      <LinkButton onClick={() => showAllGroups(false)}>
        <Typography variant="label">Hide all filters</Typography>
      </LinkButton>
      <ResetFilters />
    </Row>
  );
});

export const ResetFilters = React.memo(({ onReset }) => {
  const defaults = useFilterContext((state) => state.defaults);
  const filters = useFilterContext((state) => state.filters);
  const { clearFilters } = useFilterContext((state) => state.actions);

  return (
    JSON.stringify(defaults) != JSON.stringify(filters) && (
      <LinkButton
        onClick={(e) => {
          e.stopPropagation();
          clearFilters();
          onReset && onReset();
        }}
      >
        <Typography variant="label" color={theme.colors.states.danger}>
          Reset filters
        </Typography>
      </LinkButton>
    )
  );
});
