import { Editor, Transforms, Element as SlateElement, Range } from 'slate';

export const isMediaActive = (editor) => {
  const [media] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'media',
  });
  return !!media;
};

export const wrapMedia = (editor) => {
  if (isMediaActive(editor)) {
    unwrapMedia(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const media = {
    type: 'media',
    children: isCollapsed ? [{ text: '', bold: true }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [media, { text: ' ' }]);
  } else {
    Transforms.wrapNodes(editor, media, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapMedia = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'media',
  });
};

export const insertMedia = (editor) => {
  if (editor.selection) {
    wrapMedia(editor);
  }
};
