import { useEPStatsContext } from 'components/EliteProspects/v2/context';
import { Filters, useFilterContext, useFilterValues } from 'components/bdd/Filters';
import React, { useState } from 'react';
import { getStatQueryOptions } from '../helpers';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import Icon from 'components/bdd/Icon';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import {
  CollapsibleContainer,
  FilterTags,
} from 'components/bdd/Filters/Group/FilterGroup';
import { QueryTags } from 'components/bdd/Query/QueryTags';
import { draftFilters } from './Filters';
import { theme } from 'constants';
import { useHover } from 'components/bdd/hooks/useHover';

export const FiltersButton = ({ children }) => {
  const show = useEPStatsContext((state) => state.showFilters);
  const { setShowFilters, applyFilters } = useEPStatsContext((state) => state.actions);

  const statFilters = useEPStatsContext((state) => state.statFilters)?.filters;
  const { position } = useFilterValues();
  const tags = useFilterContext((state) => state.tags);
  const options = [...draftFilters, ...getStatQueryOptions({ position })];

  return (
    <Container>
      <HoverContainer variant="button" show={show} onClick={() => setShowFilters(!show)}>
        <Typography variant="body1">
          <Row columnGap={2} rowGap={2} flexWrap>
            Filters:
            <Icon icon={!show ? <ChevronDown /> : <ChevronUp />} />
            <Filters.Reset onReset={() => applyFilters()} />
            {!show && (
              <>
                {Object.values(tags).map((tag, index) => (
                  <React.Fragment key={index}>{tag}</React.Fragment>
                ))}
                <QueryTags filters={statFilters} options={options} />
              </>
            )}
          </Row>
        </Typography>
      </HoverContainer>
      <CollapsibleContainer visible={!!show}>{children}</CollapsibleContainer>
    </Container>
  );
};

const HoverContainer = ({ children, show, ...props }) => {
  const [hoverRef, isHovered] = useHover();

  const hoverStyles = isHovered
    ? {
        backgroundColor: theme.colors.light.secondary,
        textDecoration: 'underline',
      }
    : {};

  return (
    <Container
      paddingTop={!show ? 0 : 0.5}
      paddingBottom={!show ? 0 : 0.5}
      {...props}
      ref={hoverRef}
      {...hoverStyles}
    >
      {children}
    </Container>
  );
};
