import { Column, Container, Row } from 'components/bdd/Layout';
import { QueryContextProvider, useQueryContext } from './context';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { FilterSet } from './FilterSet/FilterSet';
import { Filter } from './Filter';
import { LinkButton } from '../Button';
import { Typography } from '../Typography';
import React, { useEffect } from 'react';
import Icon from '../Icon';
import { Plus } from 'react-bootstrap-icons';

const QueryRoot = ({ children, options, defaults, useSearchParams, searchParamsKey }) => {
  return (
    <BDDErrorBoundary>
      <QueryContextProvider
        options={options}
        defaults={defaults}
        useSearchParams={useSearchParams}
        searchParamsKey={searchParamsKey}
      >
        <Container>{children}</Container>
      </QueryContextProvider>
    </BDDErrorBoundary>
  );
};

export const QueryBuilder = ({ variant, onChange }) => {
  const filters = useQueryContext((state) => state.filters);
  const { getQueryFilters, addFilter } = useQueryContext((state) => state.actions);

  useEffect(() => {
    onChange && onChange(filters, getQueryFilters());
  }, [filters]);

  const filterComponents = filters.map((filter) => {
    if (Array.isArray(filter)) {
      return (
        <Container>
          <Column gap={1}>
            {filter.map((fs) => (
              <Filter filter={fs} />
            ))}
            <Container>
              <LinkButton>
                <Typography variant="stat">Add OR condition</Typography>
              </LinkButton>
            </Container>
          </Column>
        </Container>
      );
    } else {
      return (
        <React.Fragment key={filter.key}>
          <Filter filter={filter} />
        </React.Fragment>
      );
    }
  });

  return (
    <Container>
      <Column gap={1}>
        {variant == 'horizontal' ? (
          <Row columnGap={2} rowGap={2} flexWrap>
            {filterComponents}
          </Row>
        ) : (
          filterComponents
        )}
        <Container width="fit-content">
          <LinkButton
            onClick={() => {
              addFilter();
            }}
          >
            <Row>
              <Icon icon={<Plus />} />
              <Typography variant="stat">Add filter condition</Typography>
            </Row>
          </LinkButton>
        </Container>
      </Column>
    </Container>
  );
};

export const Query = Object.assign(QueryRoot, {
  Set: FilterSet,
  Filter: Filter,
  Builder: QueryBuilder,
});