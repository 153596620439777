import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { getNormChoices } from 'helpers/filters';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const IndexSelect = ({ indexOptions, selectProps }) => {
  const { filters, setFilters } = useContext(SLStatContext);

  const select = (
    <StyledBDDSelect
      options={indexOptions}
      selectedValue={filters.index}
      onChange={(n, v) => {
        setFilters({ index: v });
      }}
      selectProps={selectProps}
    />
  );

  return (
    <Container width={200}>
      <Column>
        <Typography variant="body1">Group By</Typography>
        {select}
      </Column>
    </Container>
  );
};
