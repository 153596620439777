import { gql, useQuery } from "@apollo/client"
import { faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parse } from "graphql"
import React, { useState } from "react"
import { Collapse } from "react-bootstrap"
import { useRouteMatch } from "react-router-dom"
import { strContains } from "../../helpers/data"
import { PillTab, PillTabContainer } from "../bdd"
import { BDDLoader } from "../bdd/bddloader"
import './playercardnhldata.css'

const GET_PLAYER_CAREER_NHL_DATA = gql`query GetPlayerCareerNHLData($slug: ID!) {
    bddPlayer(slug: $slug) {
        slug
        rinknetPlayer { 
            position
        }
        slPlayerMaster {
            nhlid
            careerNhlData
        }
    }
}`

export default function PlayercardNHLDataV2({ 
    slug,
    showHeader=true,
    showTabs=true,
    defaultTab='reg',
    defaultNumRows=5,
    skaterStatsFilter, // stats allowed to display.  If not supplied will display all
    goalieStatsFilter
}) {
    const [activeTab, setActiveTab] = useState(defaultTab)
    const [numRows, setNumRows] = useState(defaultNumRows)
    const [expandSeasons, setExpandSeasons] = useState({})
    const { data, loading, error } = useQuery(GET_PLAYER_CAREER_NHL_DATA, { variables: { slug: slug }})

    const strToiToSeconds = (toiStr) => {
        const [min, sec] = toiStr.split(':').map(x => parseInt(x))
        return (min*60) + sec
    }
    const toiToPerGame = (toi, gp) => {
        if (!toi) return null
        const toiSec = strToiToSeconds(toi)
        const secPerGP = toiSec / parseFloat(gp)
        var min = Math.floor(secPerGP / 60)
        var sec = Math.floor(secPerGP % 60)
        const ret = `${min}:${sec.toFixed(0).padStart(2, '0')}`
        return ret
    }

    const addTotalRows = (rows) => {
        const nullVal = val => !val && val !== 0
        const combineRows = (row1, row2) => {
            var newrow = {}
            for (let key in row1) {
                if (nullVal(row1[key]) && !nullVal(row2[key])) newrow[key] = row2[key]
                else if (nullVal(row2[key]) && !nullVal(row1[key])) newrow[key] = row1[key]
                else if (nullVal(row1[key]) && nullVal(row2[key])) newrow[key] = null
                else if (strContains(key, 'Pct')) newrow[key] = null
                else if (strContains(key, 'imeOnIce')) {
                    const [min1, sec1] = row1[key].split(':').map(x => parseInt(x))
                    const [min2, sec2] = row2[key].split(':').map(x => parseInt(x))
                    const totalSec = (min1*60) + sec1 + (min2*60) + sec2
                    newrow[key] = `${Math.floor(totalSec / 60)}:${(totalSec % 60).toFixed(0).padStart(2, '0')}`
                }
                else if (strContains(key, 'savepercentage', true)) {
                    var shotStat
                    var saveStat
                    if (key === 'savePercentage') {
                        shotStat = 'shotsAgainst'
                        saveStat = 'saves'
                    } else {
                        var prefix = key.replace('SavePercentage', '')
                        prefix = prefix === 'evenStrength' ? 'even': prefix
                        shotStat = `${prefix}Shots`
                        saveStat = `${prefix}Saves`
                    }
                    const totalShots = row1[shotStat] + row2[shotStat]
                    const totalSaves = row1[saveStat] + row2[saveStat]
                    const svp = key === 'savePercentage' ? totalSaves / totalShots
                        : (totalSaves / totalShots) * 100
                    newrow[key] = svp
                }
                else if (key === 'goalAgainstAverage') {
                    const toi1 = strToiToSeconds(row1.timeOnIce)
                    const toi2 = strToiToSeconds(row2.timeOnIce)
                    newrow[key] = ((row1[key]*toi1) + (row2[key]*toi2)) / (toi1 + toi2)
                }
                else newrow[key] = parseFloat(row1[key]) + parseFloat(row2[key]) 
            }
            return newrow
        }
        var ret = []
        var currTotals = null
        var currTeam = null
        for (let i=0; i<rows.length; i++) {
            var row = rows[i]
            var prevRow = i > 0 ? rows[i-1] : null
            var nextRow = i+1 < rows.length ? rows[i+1] : null
            //initialization
            if (!prevRow) {
                currTotals = row.stat
                currTeam = [row.team.abbreviation]
            }

            // add current row
            if (nextRow && nextRow.season === row.season) {
                row['isPartialSeason'] = true
            } else if (prevRow && prevRow.season === row.season) {
                row['isPartialSeason'] = true
            }
            ret.push(row)

            // check if prev row is same season
            if (prevRow && prevRow.season === row.season) {
                currTotals = combineRows(currTotals, row.stat)
                currTeam.push(row.team.abbreviation)

                if (i+1 < rows.length && row.season === rows[i+1].season) {
                    // next row is same season, don't do anything yet
                } else {
                    // otherwise, create a totals row
                    ret.push({
                        isSeasonTotals: true,
                        season: row.season,
                        league: { name: 'National Hockey League' },
                        team: { abbreviation: currTeam.join('/')},
                        stat: currTotals
                    })
                }
            } else {
                // if last row was diff season, overwrite totals
                currTotals = row.stat
                currTeam = [row.team.abbreviation]
            }

            prevRow = row
        }
        return ret
    }

    if (!!data) {
        const position = data.bddPlayer.rinknetPlayer.position
        var nhlData = JSON.parse(data.bddPlayer.slPlayerMaster?.careerNhlData)
        if (!nhlData) {
            return <div className="table-container-dark">
                {!!showHeader && 'Career Stats'}
                <center><em><small>No NHL data found</small></em></center>
            </div>
        }

        var regSeason = nhlData.stats.filter(s => s.type.displayName === "yearByYear")[0].splits
        var postSeason = nhlData.stats.filter(s => s.type.displayName === "yearByYearPlayoffs")[0].splits
        var career = nhlData.stats.filter(s => s.type.displayName === "careerRegularSeason")[0].splits
        var careerPost = nhlData.stats.filter(s => s.type.displayName === "careerPlayoffs")[0].splits
        var regSeasonNHL = regSeason.filter(d => d.league.name === 'National Hockey League')
        var postSeasonNHL = postSeason.filter(d => d.league.name === 'National Hockey League')
        
        // add totals rows
        var regSeasonNHLTots = addTotalRows(regSeasonNHL)
        
        // Add career to bottom of NHL data sets
        const seasonSets = [[regSeasonNHLTots, career], [postSeasonNHL, careerPost]]
        seasonSets.forEach(([dataRows, agged]) => {
            const seasons = dataRows.map(s => parseInt(s.season))
            const fullSeasonRange = `${Math.min(...seasons).toString().slice(0,4)}${Math.max(...seasons).toString().slice(4)}`
            if (agged.length) {
                dataRows.push({
                    season: fullSeasonRange,
                    league: { name: 'National Hockey League' },
                    team: { abbreviation: 'CAREER' },
                    stat: agged[0].stat
                })
            }
        })

        var activeData = activeTab === 'reg' ? regSeasonNHLTots
            : activeTab === 'post' ? postSeasonNHL
            : activeTab === 'regall' ? regSeason
            : activeTab === 'postall' ? postSeason 
            : activeTab === 'career' ? career : null
        
        // Filter out partial seasons unless they are expanded
        activeData = activeData.filter(d => !d.isPartialSeason || !!expandSeasons[d.season])
        var columns = {
            'league': d => d.league.name === 'National Hockey League' ? 'NHL' : d.league.name,
            'season': d => `${d.season.slice(2, 4)}-${d.season.slice(6)}`, 
            'team': d => d.team.abbreviation ? 
                <span style={d.team.abbreviation === 'TOTALS' ? {fontWeight: 'bold'} : null}>{d.team.abbreviation}</span> 
                : d.team.name, 
            'GP': 'games'
        }
        var skaterColumns = {
            'TOI/GP': d => toiToPerGame(d.stat.timeOnIce, d.stat.games), 
            'ES TOI/GP': d => toiToPerGame(d.stat.evenTimeOnIce, d.stat.games), 
            'G': 'goals', 
            'A': 'assists', 
            'P': 'points', 
            '+/-': 'plusMinus', 
            'PIM': 'pim', 
            'PPG': 'powerPlayGoals', 
            'PPP': 'powerPlayPoints', 
            'PP TOI/GP': d => toiToPerGame(d.stat.powerPlayTimeOnIce, d.stat.games),
            'SHG': 'shortHandedGoals', 
            'SHP': 'shortHandedPoints', 
            'SH TOI/GP': d => toiToPerGame(d.stat.shortHandedTimeOnIce, d.stat.games),
            'FOW%': d => d.stat.faceOffPct ? parseFloat(d.stat.faceOffPct).toFixed(2) : null,
            'SH%': d => d.stat.shotPct ? parseFloat(d.stat.shotPct).toFixed(2) : null        
        }
        var goalieColumns = {
            'Starts': 'gamesStarted',
            'W': 'wins',
            'L': 'losses',
            'OT': 'ot',
            'Saves': 'saves',
            'Shots': 'shotsAgainst',
            'GA': 'goalsAgainst',
            'GAA': d => d.stat.goalAgainstAverage ? parseFloat(d.stat.goalAgainstAverage).toFixed(2) : null,
            'SV%': d => d.stat.savePercentage ? (100*parseFloat(d.stat.savePercentage)).toFixed(2) : null,
            'ES SV%': d => d.stat.evenStrengthSavePercentage ? parseFloat(d.stat.evenStrengthSavePercentage).toFixed(2) : null,
            'SH SV%': d => d.stat.shortHandedSavePercentage ? parseFloat(d.stat.shortHandedSavePercentage).toFixed(2) : null  
        }
        const filterColumns = (colObj, statFilter) => {
            // given a list of stats to allow, create new colObj object
            if (!statFilter) return colObj
            const ret = {}
            statFilter.forEach(s => {
                if (!(s in colObj)) console.warn(`Unknown stat filter stat: "${s}"`)
                ret[s] = colObj[s]
            })
            return ret
        }
        if (position === 'Goalie') {
            goalieColumns = filterColumns(goalieColumns, goalieStatsFilter)
            columns = {...columns, ...goalieColumns}
        } else {
            skaterColumns = filterColumns(skaterColumns, skaterStatsFilter)
            columns = {...columns, ...skaterColumns}
        }
        // columns = position === 'Goalie' ? {...columns, ...goalieColumns} : {...columns, ...skaterColumns}
        if (['reg', 'post'].includes(activeTab)) {
            delete columns.league
        } else if (activeTab === 'career') {
            delete columns.league
            delete columns.season
            delete columns.team
        }

        // Calculate num rows to show (we don't count partial seasons as rows)
        var rowsToShow = numRows > 0 ? numRows + activeData.slice(-numRows).filter(d => d.isPartialSeason).length : 0
    }

    return <div className="table-container-dark">
        {!!showHeader && 'Career Stats'}
        {loading ? <BDDLoader/> : null}
        {error ? <center>Error loading data</center> : null}
        {!!data ? <>
        {showTabs && <PillTabContainer style={{margin: "1%", fontSize: "0.9em"}}>
            <PillTab onClick={() => setActiveTab('reg')} isactive={activeTab === 'reg'}>
                Regular Season - NHL
            </PillTab>
            <PillTab onClick={() => setActiveTab('post')} isactive={activeTab === 'post'}>
                Playoffs - NHL
            </PillTab>
            <PillTab onClick={() => setActiveTab('regall')} isactive={activeTab === 'regall'}>
                Regular Season - All
            </PillTab>
            <PillTab onClick={() => setActiveTab('postall')} isactive={activeTab === 'postall'}>
                Playoffs - All
            </PillTab>
        </PillTabContainer>}
        <table className="table-dark nhl-table">
        <tbody>
            <tr>
                <th></th>
                {Object.keys(columns).map(c => <th key={c}>{c}</th>)}
            </tr>
            {numRows !== 0 && activeData.length > numRows ? <tr><td colSpan={Object.keys(columns).length + 1}>
                <span style={{width: '100%', textAlign: 'center', cursor: 'pointer'}} onClick={() => setNumRows(0)}>
                    Show <b>{activeData.length - rowsToShow}</b> more rows <FontAwesomeIcon icon={faChevronUp}/>
                </span>
            </td></tr> : null}
            {activeData.slice(-rowsToShow).map(d => {
                const style = {}
                if (d.team.abbreviation === 'CAREER') style.fontWeight = 'bold'
                if (d.isSeasonTotals) style.cursor = 'pointer'
                if (d.isPartialSeason && !expandSeasons[d.season]) {
                    style.display = 'none'
                }
                return <tr 
                    key={`${d.season}${d.league.name}${d.team.name}`}
                    style={style}
                    className={d.isPartialSeason ? 'partial-season': null}
                    onClick={() => d.isSeasonTotals ? 
                        setExpandSeasons({...expandSeasons, [d.season]: !expandSeasons[d.season]}) : null}
                >
                    <td style={{textAlign: 'center'}}>
                        {d.isSeasonTotals ?
                            !!expandSeasons[d.season] ? <FontAwesomeIcon icon={faChevronUp}/>
                            : <FontAwesomeIcon icon={faChevronRight}/>
                        : null}
                    </td>
                    {Object.keys(columns).map(c => {
                        const accessor = columns[c]
                        return <td key={c}>
                            {accessor instanceof Function ? accessor(d) 
                            : accessor in d.stat ? d.stat[accessor] : null}
                        </td>
                    })}
                </tr> })}
            {activeData.length === 0 ? <tr><td colSpan={Object.keys(columns).length}>
                <center><em>No data found</em></center>
            </td></tr> : null}
        </tbody>
        </table> 
        </> : null}
        
    </div>

}