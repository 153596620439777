import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { BaseTimelineItem, Timeline } from 'components/bdd/Timeline';
import {
  GET_BDD_ISSUE,
  UPDATE_BDD_ISSUE_COMMENT,
} from 'apollo/queries/issues.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { CommentTimelineItem } from 'components/bdd/TextEditor';
import { PencilSquare } from 'react-bootstrap-icons';
import { dateToTimeAgo } from 'helpers/helpers';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GET_USERS } from 'apollo/queries/user.queries';

export const IssueActivity = ({ issue }) => {
  const [updateBddIssueComment, { loading }] = useMutation(
    UPDATE_BDD_ISSUE_COMMENT,
    {
      onError: (error) => toastBddApiError(error),
      onCompleted: () => toastInfo('Comment updated!'),
      refetchQueries: [
        {
          query: GET_BDD_ISSUE,
          variables: {
            id: issue.id,
          },
        },
      ],
    }
  );

  const { data, placeholder } = usePlaceholder(useQuery(GET_USERS));
  const users = data?.users;

  const commentItems = issue?.comments.map((c) => ({
    date: c.createdDate,
    renderItem: (lastItem) => (
      <CommentTimelineItem
        key={`${c.id}-${c.lastModifiedDate}`}
        lastItem={lastItem}
        user={c.user}
        comment={JSON.parse(c.comment).comment}
        dateCreated={c.createdDate}
        onSubmit={(values) => {
          updateBddIssueComment({
            variables: {
              id: c.id,
              comment: JSON.stringify(values),
            },
          });
        }}
      />
    ),
  }));

  const modificationItems = issue?.modifications.map((m) => {
    const details = JSON.parse(m.details);
    let header;

    if (m.newStatus != m.oldStatus) {
      
      header = (
        <>
          <b>{m.user.username}</b> changed the status from <b>{m.oldStatus}</b>{' '}
          to <b>{m.newStatus}</b> {dateToTimeAgo(m.date)}
        </>
      );
    } else if (details) {
      if (details.new_assignee) {
        const assigneeUser = users?.find((u) => u.id == details.new_assignee);

        header = (
          <>
            Issue assigned to <b>{assigneeUser?.username}</b>{' '}
            {dateToTimeAgo(m.date)}
          </>
        );
      }
      if (details.new_priority) {
        header = (
          <>
            Issue priority updated from <b>{details.old_priority}</b> to{' '}
            <b>{details.new_priority}</b> {dateToTimeAgo(m.date)}
          </>
        );
      }
    }

    return {
      date: m.date,
      renderItem: (lastItem) => (
        <BaseTimelineItem
          key={`${m.id}-${m.date}`}
          lastItem={lastItem}
          header={header}
          icon={<PencilSquare />}
        />
      ),
    };
  });

  const items = commentItems
    .concat(modificationItems)
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const itemComponents = items.map((i, index) =>
    i.renderItem(index == items.length - 1)
  );

  return commentItems && <Timeline items={itemComponents} />;
};
