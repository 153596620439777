import { gql } from "@apollo/client";

const UploadFragment = gql`
    fragment uploadFragment on Uploads {
        slug
        name
        type
        description
        uploadDate        
    }
`

export const GET_UPLOADS = gql`query GetUploads {
    uploads {
        ...uploadFragment
    }
} ${UploadFragment}`

export const CREATE_UPLOAD = gql`mutation CreateUpload($input: CreateUploadInput!) {
    createUpload(input: $input) {
        upload {
            ...uploadFragment
        }
    }
} ${UploadFragment}`


export const OVERWRITE_UPLOAD = gql`mutation CreateUpload(
  $input: CreateUploadInput!
) {
  createUpload(input: $input overwrite: true) {
    upload {
      ...uploadFragment
    }
  }
} ${UploadFragment}`

export const DELETE_UPLOAD = gql`mutation DeleteUpload($slug: ID!) {
    deleteUpload(slug: $slug) {
        slug
    }
}`

export const GET_UPLOAD = gql`query GetUpload($slug: ID!) {
    upload(slug: $slug) {
        slug
        name
        description
        uploadData {
            id
            data
        }
    }
}`
// Group data into single JSONString
export const GET_UPLOAD_BULK_DATA = gql`query GetUploadBulkData($slug: ID!) {
    upload(slug: $slug) {
        slug
        name
        description
        bulkData
    }
}`

export const GET_UPLOAD_ROW = gql`query GetUploadRow($slug: ID!, $filters: GenericScalar!) {
    uploadRow(slug: $slug, filters: $filters)
}`

export const GET_UPLOAD_ROWS = gql`query GetUploadRows($slug: ID!, $filters: GenericScalar!) {
  uploadRows(slug: $slug, filters: $filters)
}`