import { Container } from 'components/bdd/Layout';
import { useContext } from 'react';
import { VideoContext } from '../..';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { toiSecToString } from 'helpers/data';
import { useQuery } from '@apollo/client';
import { GET_PLAYER_TEAMMATES_OPPONENTS } from 'apollo/queries/teammates.queries';

export const OnIcePlayerFilter = ({ slug, isTeammate = true }) => {
  const { sourceFilters, secondaryPlayerFilters, setSecondaryPlayerFilters } =
    useContext(VideoContext);

  const {
    league_slug: leagueSlug,
    season,
    game_type: gameType,
    game_uid: gameUid,
  } = sourceFilters || {};

  const { data, loading } = useQuery(GET_PLAYER_TEAMMATES_OPPONENTS, {
    variables: {
      slug,
      leagueSlug: leagueSlug?.val,
      seasons: season?.val && [season?.val],
      gameType: gameType?.val,
      gameUid: gameUid?.val,
      minToi: 5,
      numPlayersPerSplit: 20,
    },
    skip: (!leagueSlug?.val || !season?.val || !gameType?.val) && !gameUid?.val,
  });

  const options = data?.playerTeammatesOpponents?.playerPairs
    .filter((pp) => (isTeammate ? pp.isTeammate : !pp.isTeammate))
    .map((pp) =>
      pp.pairs.map((ppp) => {
        const slPlayer = ppp.otherBddPlayer.slPlayerMaster.slPlayers.find(
          (spm) => spm.leagueSlug == leagueSlug?.val
        );

        return {
          value: slPlayer.playerId,
          label: `${ppp.otherBddPlayer.firstname} ${ppp.otherBddPlayer.lastname}`,
          subCaption: `Time played: ${toiSecToString(ppp.toi)}`,
          slPlayer,
        };
      })
    )
    .flat();

  const selectedPlayerIds = secondaryPlayerFilters
    ? Object.values(secondaryPlayerFilters)
        .map((pf) => pf.val)
        .flat()
    : [];

  const label = isTeammate ? 'Teammates' : 'Opponents';

  return (
    <Container minWidth={200}>
      <Typography variant="body1">{label}</Typography>
      <StyledBDDSelect
        options={options || []}
        selectedValue={selectedPlayerIds}
        onChange={(n, v) => {
          const playerOptions = options.filter((o) => v.includes(o.value));

          let forwardsOnIce = [];
          let defencemenOnIce = [];

          playerOptions.forEach((po) => {
            po.slPlayer.primaryposition == 'F'
              ? forwardsOnIce.push(po.slPlayer.playerId)
              : defencemenOnIce.push(po.slPlayer.playerId);
          });

          setSecondaryPlayerFilters({
            [isTeammate ? 'team_forwards_on_ice' : 'opp_forwards_on_ice']: {
              col: isTeammate ? 'team_forwards_on_ice' : 'opp_forwards_on_ice',
              val: forwardsOnIce,
              cond: 'array_includes_all',
            },
            [isTeammate ? 'team_defencemen_on_ice' : 'opp_defencemen_on_ice']: {
              col: isTeammate ? 'team_defencemen_on_ice' : 'opp_defencemen_on_ice',
              val: defencemenOnIce,
              cond: 'array_includes_all',
            },
          });
        }}
        selectProps={{
          isSearchable: true,
          isClearable: true,
          isLoading: loading,
          isMulti: true,
          placeholder: `Select ${label}...`,
        }}
      />
    </Container>
  );
};
