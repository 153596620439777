import { Column, Container, Grid, GridArea, Row } from 'components/bdd/Layout';
import { EventBank, EventControls } from 'components/bdd/Video/Filtering';
import { useContext, useEffect, useState } from 'react';
import {
  PlayerLSGFilter,
  PlayerGameFilter,
  FilterSummary,
  StatDefinitionFilter,
  PeriodFilter,
  StrengthFilter,
  OnIcePlayerFilter,
} from 'components/bdd/Video/Filtering/components';
import { EventMap } from './EventMap';
import { Typography } from 'components/bdd/Typography';
import { Divider } from 'components/bdd/Divider';
import { WindowContext } from 'components/bdd/context';
import { theme } from 'constants';
import {
  EventVideoController,
  VideoContext,
  VideoSelect,
  VideoSelectContext,
} from 'components/bdd/Video';

export const defaultStatDefinitionForPosition = {
  F: 'shotAttempts',
  D: 'defensivePlays',
  G: 'shotsOnGoalAgainst',
};

export const Events = ({ bddPlayer, leagueSlug }) => {
  const player = bddPlayer.slPlayerMaster.slPlayers.find(
    (sp) => sp.leagueSlug == leagueSlug
  );

  if (!player) {
    return (
      <Typography variant="body1">No video data found for player</Typography>
    );
  }

  const { width } = useContext(WindowContext);
  const gridLayout =
    width >= theme.breakpoints.lg
      ? {
          gridTemplateColumns: '1fr 2fr',
          gridTemplateAreas: '"controls controls" "bank map"',
        }
      : {
          gridTemplateAreas: '"controls" "map" "bank"',
          rowGap: 4,
        };

  return (
    <EventVideoController
      slPlayer={player}
      statDefinitionSlug={
        defaultStatDefinitionForPosition[player.primaryposition]
      }
      useStateParams
    >
      <Container>
        <Column gap={4} alignItems="start" justifyContent="space-between">
          <VideoFilters
            bddPlayer={bddPlayer}
            slPlayer={player}
            leagueSlug={leagueSlug}
          />
          <Divider />
          <VideoSelect>
            <Grid {...gridLayout} style={{ alignItems: 'center' }}>
              <GridArea area={'controls'}>
                <EventControls />
              </GridArea>
              <GridArea area={'map'}>
                <EventMap />
              </GridArea>
              <GridArea area={'bank'}>
                <PlayerEventBank />
              </GridArea>
            </Grid>
          </VideoSelect>
        </Column>
      </Container>
    </EventVideoController>
  );
};

const VideoFilters = ({ bddPlayer, slPlayer, leagueSlug }) => {
  return (
    <Container width="100%">
      <Column gap={3}>
        <Row alignItems="end" columnGap={2} rowGap={2} flexWrap>
          <Row columnGap={2} rowGap={2} flexWrap>
            <PlayerLSGFilter slug={bddPlayer.slug} leagueSlug={leagueSlug} />
            <PlayerGameFilter slug={bddPlayer.slug} />
            <OnIcePlayerFilter slug={bddPlayer.slug} />
            <OnIcePlayerFilter slug={bddPlayer.slug} isTeammate={false} />
            <PeriodFilter />
            <StrengthFilter />
          </Row>
          <StatDefinitionFilter slPlayer={slPlayer} />
        </Row>
        <Divider />
        <FilterSummary />
      </Column>
    </Container>
  );
};

const PlayerEventBank = () => {
  const { allEvents, loading } = useContext(VideoContext);
  const { selectedEvents, setSelectedEvents } = useContext(VideoSelectContext);

  return (
    <Container>
      <EventBank
        events={allEvents}
        loading={loading}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        // onPlay={onPlay}
      />
    </Container>
  );
};
