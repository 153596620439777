import { Column, Container, Row } from "components/bdd/Layout"
import { ProObjectiveContext } from "../ProObjectiveContextProvider";
import { useContext } from "react";
import { Typography } from "components/bdd/Typography";
import { BDDTransaction } from "./BDDTransaction";
import { useSelect } from "components/bdd/Select";
import { Label } from "components/bdd/Label/Label";
import useTeamSelect from "components/bdd/Select/useTeamSelect";
import { Divider } from "components/bdd/Divider";
import { bruinsGold } from "helpers/plotting";
import { useMutation } from "@apollo/client";
import { REMOVE_PRO_OBJECTIVE_TRANSACTION } from "apollo/queries/proobjective.queries";
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import { strContains } from "helpers/data";
import { useHistory, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { ScenarioContext } from "../Scenarios/ScenarioContextProvider";


export const ObjectiveTransactionsPage = ({
  showHeader=true,
  highlightedTransactions=[],
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  return <ObjectiveTransactions
    showHeader={showHeader}
    highlightedTransactions={highlightedTransactions}
    onTxClick={(tx, variantId) => history.push(`${url}/${tx.id}/${variantId}`)}
    showScenarioIcon={true}
  />
}

export const ObjectiveTransactions = ({ 
  showHeader=true, 
  highlightedTransactions=[], // [ids] or [{ id, variantId }] ids of transactions/variants to highlight
  onTxClick=(tx, variantId) => {},
  handleRemove: handleRemoveArg, // (tid, force) => {}
  removeTooltip='Remove transaction from pro objective',
  showScenarioIcon=false,
  canSign=true,
  canTrade=true,
  canViewExistingTransactions=true,
}) => {
  const objectiveCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { proObjective, transactions, transactionsPlaceholder } = !!objectiveCtx ? objectiveCtx : scenarioCtx;

  const [removePOTx] = useMutation(REMOVE_PRO_OBJECTIVE_TRANSACTION, {
    onCompleted: () => toastInfo('Transaction removed'),
    onError: toastBddApiError,
    update: (cache, { data: { removeProObjectiveTransaction: { id } }}) => {
      cache.modify({
        fields: {
          proObjectiveTransactions(existing=[], { readField }) {
            return existing.filter(e => readField('id', e) != id);
          }
        }
      })
    }
  })

  const handleFKConstraint = (txid) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('This transaction is attached to 1 or more scenarios.  Do you want to force remove this transaction from the objective and all scenarios?')) {
      handleRemoveTx(txid, true);
    }
  }

  const handleRemoveTx = (txid, force=false) => {
    if (handleRemoveArg) {
      handleRemoveArg(txid, force);
    } else {
      removePOTx({
        onError: err => {
          console.log(err.message)
          if (strContains(err.message, 'violates foreign key constraint')) {
            handleFKConstraint(txid);
          }
        },
        variables: {
          id: txid,
          proObjectiveSlug: proObjective.slug,
          force
        }
      })
    }
  }

  const { select: typeSelect, selectedValue: selectedType } = useSelect({
    options: [
      { label: 'Trade', value: 'trade' },
      { label: 'Signing', value: 'signing' },
    ],
    selectProps: {
      isClearable: true,
      placeholder: 'Select type...'
    }
  })

  const { select: teamSelect, selectedValue: selectedTeam } = useTeamSelect({
    leagueSlug: 'NHL',
    selectProps: {
      isClearable: true
    }
  })

  let playerOptions = transactions?.reduce((acc, curr) => {
    if (curr.transactionType === 'TRADE') {
      curr.bddTrades.forEach(t => t.tradedPlayers.forEach(p => {
        if (!acc.find(a => a.value === p.playerSlug)) {
          acc.push({ 
            label: `${p.bddPlayer.firstname} ${p.bddPlayer.lastname}`, 
            value: p.playerSlug 
          })
        }
      }));
    } else if (curr.transactionType === 'SIGNING') {
      curr.bddSignings.forEach(s => {
        if (!acc.find(a => a.value === s.bddPlayerSlug)) {
          acc.push({ 
            label: `${s.bddPlayer.firstname} ${s.bddPlayer.lastname}`, 
            value: s.bddPlayerSlug 
          })
        }
      })
    }
    return acc
  }, []);

  const { select: playerSelect, selectedValue: selectedSlug } = useSelect({
    options: playerOptions?.sort((a, b) => a < b ? -1 : 1),
    initialSelectedValue: null,
    selectProps: {
      isClearable: true,
      isSearchable: true
    }
  })

  const { select: sortSelect, selectedValue: selectedSort } = useSelect({
    initialSelectedValue: 'newest',
    options: [
      { label: 'Newest', value: 'newest' },
      { label: 'Oldest', value: 'oldest' },
      { label: 'Recently Changed', value: 'recent' },
    ],
  })

  const txIsHighlighted = (tid) => !!highlightedTransactions.find(t => typeof t === 'object' ? t.id === tid : t === tid);
  const txToHighlightedVariant = (tid) => highlightedTransactions.find(t => typeof t === 'object' ? t.id === tid : false)?.variantId;

  const filterTransactions = (t) => {
    if (!canViewExistingTransactions) {
      if (!txIsHighlighted(t.id)) return false
    }
    if (t.transactionType === 'TRADE') {
      if (!canTrade) return false;
      if (!!selectedType && selectedType !== 'trade') return false;
      if (!!selectedTeam && !t.bddTrades.find(bt => bt.teamSummaries.find(team => team.teamSlug === selectedTeam))) return false;
      if (!!selectedSlug && !t.bddTrades.find(bt => bt.tradedPlayers.find(p => p.playerSlug === selectedSlug))) return false;
    } else if (t.transactionType === 'SIGNING') {
      if (!canSign) return false;
      if (!!selectedType && selectedType !== 'signing') return false;
      if (!!selectedTeam && selectedTeam !== proObjective.teamSlug) return false;
      if (!!selectedSlug && !t.bddSignings.find(s => s.bddPlayerSlug === selectedSlug)) return false;
    }
    return true
  }

  const sortedTransactions = !transactions ? [] : [...transactions].sort((a, b) => {
    if (selectedSort === 'oldest') {
      return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
    } else if (selectedSort === 'recent') {
      return new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1
    } else if (selectedSort === 'newest') {
      return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    }
  })

  return <Container>
    <Column gap={2}>
      {showHeader && <Container>
        <Typography variant='subtitle1'>
          Objective Transactions
        </Typography>
        <Typography variant='caption'>
          These are (hypothetical) transactions created in this Pro Objective
        </Typography>
      </Container>}
      
      <Row gap={16}>
        <Container width={200}>
          <Label>Transaction Type</Label>
          {typeSelect}
        </Container>

        <Container width={200}>
          <Label>Team</Label>
          {teamSelect}
        </Container>

        <Container width={200}>
          <Label>Player</Label>
          {playerSelect}
        </Container>
      </Row>

      <Row>
        <Container width={200}>
          <Label>Sort</Label>
          {sortSelect}
        </Container>
      </Row>

      <Divider/>
      
      {!!transactionsPlaceholder && transactionsPlaceholder}
      <Column gap={2}>
        {sortedTransactions?.filter(filterTransactions).map(t => 
          <div key={t.id} style={txIsHighlighted(t.id) ? { boxShadow: `0 0 5px ${bruinsGold}` } : {}}>
            <BDDTransaction 
              transaction={t}
              handleRemove={handleRemoveTx}
              handleClick={onTxClick}
              removeTooltip={removeTooltip}
              highlightVariantId={txToHighlightedVariant(t.id)}
              showScenarioIcon={showScenarioIcon}
            />
          </div>
        )}
      </Column>
      <Container height={400}/>
    </Column>
  </Container>
}