import React from 'react';
import { ErrorMessage } from 'formik';

import BDDFormError from '../bdd/bddformerror';
import { Typography } from 'components/bdd/Typography';
import BDDButtonGroup from 'components/bdd/bddbuttongroup';

export default function BDDButtonGroupField({ title, noTitle, field, ...rest }) {
  const { name, value, onChange } = field; // dont know why error isn't in here
  const error = rest.form.errors[name];
  const touched = rest.form.touched[name];
  return (
    <div>
      {noTitle || title == undefined ? null : <Typography variant='label'>{title}</Typography>}
      <BDDButtonGroup
        name={name}
        placeholder={title}
        value={value}
        isInvalid={!!error && !!touched}
        onClick={(value) => onChange({ target: { name, value }})}
        {...rest}
      />
      <BDDFormError>
        <ErrorMessage name={name} />
      </BDDFormError>
    </div>
  );
}
