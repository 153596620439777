import { CheckboxGroupFilter, EventFilterContext } from '.';
import { useContext } from 'react';
import { eventNameToMapping } from '../helpers';

export const EventFlagFilter = ({}) => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name']?.val;
  const options = eventNames?.flatMap((e) => eventNameToMapping[e]?.eventFlags || []);
  if (!options || options.length <= 1) return null;

  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="event_flags"
      label={'Event Flags'}
      options={options}
    />
  );
};
