import { useQuery } from "@apollo/client"
import { GET_OPPONENTS_TOI_BY_GAME } from "apollo/queries/teammates.queries"
import BDDApiError from "components/bdd/bddapierror"
import { BDDLoader } from "components/bdd/bddloader"
import { IconButton } from "components/bdd/Button"
import Icon from "components/bdd/Icon"
import { Container, Row } from "components/bdd/Layout"
import Toggle from "components/bdd/Toggle/Toggle"
import { Typography } from "components/bdd/Typography"
import { theme } from "constants"
import { formatDate, formatMinutes } from "helpers/helpers"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons"
import styled from "styled-components"

const GameCard = styled.div({
  ...theme.cards.medium,
  border: '1px solid #ddd',
  padding: '15px',
  minWidth: '160px'
})

export default function OpposingToiByGame({
  slug,
  leagueSlug,
  season,
  gameType,
  lastN=5,
}) {
  const [offset, setOffset] = useState(0)
  const { data, loading, error } = useQuery(GET_OPPONENTS_TOI_BY_GAME, {
    variables: {
      slug,
      leagueSlug,
      season,
      gameType,
      lastN,
      offset
    }
  })

  const posSets = [{label: 'Forwards', value: 'f'}, { label: 'Defencemen', value: 'd'}]
  const games = data?.opponentsToiByGame?.games
  return <>
    {loading ? <BDDLoader variant="squares"/>
    : error ? <BDDApiError error={error} />
    : data ? 
    <Row justifyContent='space-between' gap={4} styles={{ minHeight: '200px' }}>
      <Button
        variant='dark'
        onClick={() => setOffset(offset - lastN)}
        disabled={offset === 0}
      >
        <ArrowLeft/>
      </Button>
      <Row justifyContent='space-between' gap={'2px'} style={{overflowX: 'scroll'}}>
        {games?.map(g => <GameCard key={g.slGame.id}>
          <Typography variant='subtitle1' style={{ fontSize: ''}}>
            {formatDate(g.slGame.gameDate)} vs. {g.otherTeam.shorthand}
          </Typography>
          {posSets.map(({ label, value }) => <Container key={value}>
            <Typography variant='body1'>
              {label}
            </Typography>
            <div style={{ paddingLeft: '5px'}}>
              {g.opponents.filter(p => p.otherPosition === value).slice(0, 9).map(o =>
                <Typography key={o.otherPlayerSlug} variant='body2'>
                  {formatMinutes(o.toi/60)} {o.otherBddPlayer.firstname} {o.otherBddPlayer.lastname}
                </Typography>
              )}
            </div>
          </Container>)}
        </GameCard>)}
      </Row>
      <Button
        variant='dark'
        onClick={() => setOffset(offset + lastN)}
      >
        <ArrowRight/>
      </Button>
    </Row>
    : null}
  </>
}