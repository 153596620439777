import { theme } from "constants";
import styled from "styled-components";
import { IconButton } from "components/bdd/Button";

export const ToolbarIconButton = styled(IconButton)(({ active }) => {
  if (!active) {
    return {
      height: '100%',
      padding: theme.spacing[1],
      color: theme.colors.light.text.secondary,
      '&:hover': {
        backgroundColor: theme.colors.light.focus,
        color: theme.colors.light.text.primary,
        borderRadius: theme.spacing[1],
      },
    };
  }

  return {
    height: '100%',
    backgroundColor: theme.colors.light.focus,
    color: theme.colors.light.text.primary,
    borderRadius: theme.spacing[1],
    padding: theme.spacing[1],
  };
});
