import { gql } from '@apollo/client';

export const BDDNoteFragment = gql`
  fragment bddNoteFragment on BDDNote {
    id
    entityId
    user {
      id
      username
      name
    }
    parentEntityId
    entityType
    subEntityId
    subEntityType
    dateCreated
    dateEdited
    note
    noteType
    requiresPermission
    isArchived
    isEdited
    isPrivate
    isEditable
    isPinned
    userAccess
    files {
      name
      s3Key
      s3Url
      url
    }
  }
`;

export const GET_NOTES = gql`
  query GetNotes(
    $ids: [ID!]
    $userIds: [ID!]
    $entityIds: [ID!]
    $entityType: String
    $subEntityId: ID
    $subEntityType: String
    $noteType: String
    $startDate: Date
    $endDate: Date
    $onlyPinned: Boolean
    $includeMentions: Boolean
    $includeSubEntityTypes: [String]
    $targetStatus: String
  ) {
    bddNotes(
      ids: $ids
      userIds: $userIds
      entityIds: $entityIds
      entityType: $entityType
      subEntityId: $subEntityId
      subEntityType: $subEntityType
      noteType: $noteType
      endDate: $endDate
      startDate: $startDate
      onlyPinned: $onlyPinned
      includeMentions: $includeMentions
      includeSubEntityTypes: $includeSubEntityTypes
      targetStatus: $targetStatus
    ) {
      notes {
        ...bddNoteFragment
      }
      mentions {
        id
        noteId
        entityId
        entityType
        entityLabel
        bddNote {
          ...bddNoteFragment
        }
      }
      bddPlayers {
        slug
        fullName
        rinknetPlayer {
          firstname
          lastname
          posFDG
        }
      }
      slTeams {
        slug
        longname
      }
      proObjectives {
        slug
        name
      }
      proScenarios {
        id
        title
      }
    }
  }
  ${BDDNoteFragment}
`;

export const CREATE_NOTE = gql`
  mutation CreateBDDNote(
    $entityId: ID!
    $entityType: String!
    $subEntityId: ID
    $subEntityType: String
    $note: JSONString!
    $noteType: String!
    $requiresPermission: String
    $isPrivate: Boolean
    $files: [BDDNoteFileInput]
    $mentions: [BDDNoteMentionInput]
    $isPinned: Boolean
    $userAccess: GenericScalar
  ) {
    createBddNote(
      input: {
        entityId: $entityId
        entityType: $entityType
        subEntityId: $subEntityId
        subEntityType: $subEntityType
        note: $note
        files: $files
        mentions: $mentions
        noteType: $noteType
        requiresPermission: $requiresPermission
        isPrivate: $isPrivate
        isPinned: $isPinned
        userAccess: $userAccess
      }
    ) {
      bddNote {
        ...bddNoteFragment
      }
      bddPlayers {
        slug
        fullName
        rinknetPlayer {
          firstname
          lastname
          posFDG
        }
      }
      slTeams {
        slug
        longname
      }
      proObjectives {
        slug
        name
      }
      proScenarios {
        id
        title
      }
    }
  }
  ${BDDNoteFragment}
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $id: Int!
    $note: JSONString
    $requiresPermission: String
    $isPrivate: Boolean
    $files: [BDDNoteFileInput]
    $mentions: [BDDNoteMentionInput]
    $isArchived: Boolean
    $isPinned: Boolean
    $userAccess: GenericScalar
  ) {
    updateBddNote(
      input: {
        id: $id
        note: $note
        requiresPermission: $requiresPermission
        isPrivate: $isPrivate
        files: $files
        mentions: $mentions
        isArchived: $isArchived
        isPinned: $isPinned
        userAccess: $userAccess
      }
    ) {
      bddNote {
        ...bddNoteFragment
      }
      newBddNote {
        ...bddNoteFragment
      }
    }
  }
  ${BDDNoteFragment}
`;

export const GET_NOTE_HISTORY = gql`
  query GetNoteHistory($parentEntityId: Int!) {
    bddNoteHistory(parentEntityId: $parentEntityId) {
      id
      user {
        id
        username
      }
      dateCreated
      note
    }
  }
`;
