import { gql } from '@apollo/client';

export const GET_BDD_ISSUES_BY_PLAYER = gql`
  query GetBddIssuesByPlayer(
    $bddPlayerSlug: ID
    $bddPlayerSlugs: [ID]
    $ignoreScoutingObjectives: Boolean
  ) {
    allBddIssues(
      targetId: $bddPlayerSlug
      targetIds: $bddPlayerSlugs
      ignoreScoutingObjectives: $ignoreScoutingObjectives
    ) {
      id
      parentType
      issueDetails
      issueType
      status
      targetType
      targetId
      priority
      createdDate
      createdByUserId
      lastModifiedByUser {
        firstname
        lastname
      }
      createdByUser {
        firstname
        lastname
      }
      lastModifiedDate
      assigneeUserId
      assigneeUser {
        username
      }
      source
    }
  }
`;

export const GET_BDD_ISSUE = gql`
  query GetBddIssue($id: ID!) {
    bddIssue(id: $id) {
      id
      parentType
      issueDetails
      issueType
      status
      targetType
      targetId
      priority
      createdDate
      createdByUserId
      lastModifiedByUser {
        firstname
        lastname
      }
      createdByUser {
        firstname
        lastname
      }
      lastModifiedDate
      assigneeUserId
      assigneeUser {
        username
      }
      source
      comments {
        id
        createdDate
        lastModifiedDate
        user {
          id
          username
        }
        comment
      }
      modifications {
        oldStatus
        newStatus
        userId
        date
        details
        user {
          id
          username
        }
      }
    }
  }
`;

export const UPDATE_BDD_ISSUE = gql`
  mutation UpdateBDDIssue(
    $id: ID!
    $status: BDDIssueStatus
    $assigneeUserId: Int
    $priority: BDDIssuePriority
  ) {
    updateBddIssue(
      input: {
        id: $id
        status: $status
        assigneeUserId: $assigneeUserId
        priority: $priority
      }
    ) {
      issue {
        id
      }
    }
  }
`;

export const CREATE_BDD_ISSUE_COMMENT = gql`
  mutation CreateBddIssueComment($comment: String!, $issueId: Int!) {
    createBddIssueComment(input: { comment: $comment, issueId: $issueId }) {
      comment {
        id
      }
    }
  }
`;

export const UPDATE_BDD_ISSUE_COMMENT = gql`
  mutation UpdateBddIssueComment($comment: String!, $id: Int!) {
    updateBddIssueComment(input: { comment: $comment, id: $id }) {
      comment {
        id
      }
    }
  }
`;
