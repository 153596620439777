import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';

export const BFThirtyFivePlusTag = ({ contract, ...tagProps }) => {
  if (!contract?.isThirtyFivePlus) return null;

  return (
    <BDDTag
      tag={{
        color: theme.colors.states.link,
        textColor: theme.colors.dark.text.primary,
        tag: (
          <Typography variant="caption" style={{ lineHeight: '14px' }}>
            35+
          </Typography>
        ),
        description: '35+',
      }}
      {...tagProps}
    />
  );
};
