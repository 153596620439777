import { useQuery } from '@apollo/client';
import { GET_SL_EVENT_NAMES } from 'apollo/queries/events.queries';
import { CheckboxGroupFilter } from '.';

export const EventNameFilter = ({}) => {
  const { data } = useQuery(GET_SL_EVENT_NAMES);
  const options = !!data
    ? data.slEventNames.map((e) => ({ value: e.name, label: e.label }))
    : [];

  return (
    <CheckboxGroupFilter
      id={'event_name'}
      col={'event_name'}
      condAny="isin"
      label={'Event Name'}
      options={options}
    />
  );
};
