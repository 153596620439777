import React, { useContext } from 'react';
import { Paperclip } from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import { FileUploadForm, ToolbarIconButton } from '.';
import { ModalContext, ModalContextProvider, useModal } from '../Modal';
import { Typography } from '../Typography';
import { Container } from '../Layout';
import { LinkPopover } from '../Popover';

export const FileUploadButton = ({ folder, onSubmit, onCancel, size='xl', usePopover=false }) => {
  const { showModal, setShowModal, renderModal } = useModal({
    size,
    title: <Typography variant='h6'>Upload File</Typography>
  });

  if (usePopover) {

    return (
      <LinkPopover
        renderLink={(setShow, target) => (
          <Container ref={target} onClick={() => setShow(true)}>
            <ToolbarIconButton
              as={IconButton}
              icon={<Paperclip />}
              tooltip='Attach file'
            />
          </Container>
        )}
        renderContent={(forceClose) => (
          <Container padding={3}>
            <FileUploadForm
              folder={folder}
              onSubmit={(values) => {
                console.log('here', values)
                onSubmit(values);
                forceClose();
              }}
              onCancel={() => {
                onCancel && onCancel();
                forceClose();
              }}
            />
          </Container>
        )}
      />
    );
  }

  return (
    <ModalContextProvider>
      {({ renderModal, hideModal }) => {
        const form = (
          <Container padding={3}>
            <FileUploadForm
              folder={folder}
              onSubmit={(values) => {
                onSubmit(values);
                hideModal();
              }}
              onCancel={() => {
                onCancel && onCancel();
                hideModal();
              }}
            />
          </Container>
        );

        return (
          <ToolbarIconButton
            as={IconButton}
            icon={<Paperclip />}
            onClick={() => {
              renderModal({
                size,
                title: <Typography variant="h6">Upload file</Typography>,
                body: form,
              });
            }}
          />
        );
      }}
    </ModalContextProvider>
  );
};
