import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { LinkButton } from 'components/bdd/Button';
import { Divider } from 'components/bdd/Divider';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { SLStatContextProvider } from 'components/SLStats';
import { useSLStatContext } from 'components/SLStats/hooks';
import { dateRangeModeIsLastMonth } from 'helpers/daterangemode';
import { NULL_FILTER_VALUE } from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { buildPlayerListRoute, buildPlayerRouteV2 } from 'helpers/routing';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toastError } from 'components/bdd/bddtoasts';

import TargetedPlayersTable from './TargetedPlayersTable';
import { Star } from 'react-bootstrap-icons';

export const MAX_TARGETED_PLAYERS = 150;

const filtersAreValid = (filters) => {
  const keys = [
    'season',
    'strength',
    'dateRangeMode',
    'norm',
    'index',
    'metastats',
    'slugs',
    'statDefs',
    'statReport',
  ];

  const missingKeys = keys.filter((k) => !(k in filters));
  if (missingKeys.length > 0) {
    console.warn('Invalid filters, missing keys:', missingKeys);
    return false;
  }
  const extraKeys = Object.keys(filters).filter((k) => !keys.includes(k));
  if (extraKeys.length > 0) {
    console.warn('Invalid filters, unknown key(s)', extraKeys);
    return false;
  }
  if (!!filters.start_date !== !!filters.end_date) {
    // if one of the dates are set but not the other...
    console.warn('Invalid filters, mismatched dates', filters);
    return false;
  }
  return true;
};

const loadStoredFilters = () => {
  try {
    const savedDefaultsRaw = localStorage.getItem(FILTERS_STORAGE_KEY);
    let savedDefaults = JSON.parse(savedDefaultsRaw);

    if (!savedDefaults) return null;
    if (!!savedDefaults.start_date) {
      savedDefaults.start_date = new Date(savedDefaults.start_date);
    }
    if (!!savedDefaults.end_date) {
      savedDefaults.end_date = new Date(savedDefaults.end_date);
    }
    if (!filtersAreValid(savedDefaults)) {
      localStorage.setItem(FILTERS_STORAGE_KEY, null);
      return null;
    }
    return savedDefaults;
  } catch {
    console.error('Error loading stored targeted player filters, cleaning out');
    localStorage.setItem(FILTERS_STORAGE_KEY, null);
    return null;
  }
};

const FILTERS_STORAGE_KEY = 'dashboard-targetedplayersstatfilters';
const TargetedPlayers = ({
  slugs,
  selectedOption,
  ignoreStoredFilters,
  defaultFilters,
}) => {
  const nonByGameIndex =
    'league_slug,season,game_type,slug,Player,player_id,position,team_slug';
  const byGameIndex = `game_date,game_uid,opp_team_shorthand,${nonByGameIndex}`;

  const blankFilters = {
    // only use these when nothing is stored
    season: [getCurrentSeason()],
    strength: NULL_FILTER_VALUE,
    dateRangeMode: 'LAST_7',
    norm: NULL_FILTER_VALUE,
    index: byGameIndex,
    metastats: { norm: '20' },
    ...defaultFilters,
  };

  const storedFilters = useMemo(() => loadStoredFilters(), []);
  const filters = !!storedFilters && !ignoreStoredFilters ? storedFilters : blankFilters;

  // regardless of where filters are loaded from, slugs come from props
  const defaults = { target: 'skaters', filters: { ...filters, slugs } };

  const handleValidateSetFilters = (incoming, store) => {
    // "validateFilters" takes incoming filter change and store
    // e.g. ({ strength: "ES55" }, { defaults, filters... }
    // returns false if change is invalid (then it won't be made)
    // otherwise, returns proposed filter update (possibly with alterations)

    const filters = store.filters;

    if (!!incoming.index) {
      // setting index...
      if (incoming.index === byGameIndex && filters.index !== byGameIndex) {
        // we're toggling into byGame mode
        if (!dateRangeModeIsLastMonth(filters.dateRangeMode)) {
          toastError('"By Game" toggle requires a Date Range of a month or less');
          return false;
        }
      }
    }
    if (!!incoming.dateRangeMode) {
      // setting drm
      if (
        !dateRangeModeIsLastMonth(incoming.dateRangeMode) &&
        dateRangeModeIsLastMonth(filters.dateRangeMode)
      ) {
        // we're setting drm from a short mode (within a month) to something less restricted
        if (filters.index === byGameIndex) {
          toastError(
            'Setting a Date Range over a month requires the "By Game" toggle to be off'
          );
          return { ...incoming, index: nonByGameIndex };
        }
      }
    }

    return incoming;
  };

  const context = useSLStatContext({
    defaults,
    skip: !slugs?.length || slugs?.length > MAX_TARGETED_PLAYERS,
    skipCallback: ({ store }) => !store.filters?.slugs?.length,
    validateFilters: handleValidateSetFilters,
  });

  useEffect(() => {
    if (!!context.store && !ignoreStoredFilters) {
      localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(context.store.filters));
    }
  }, [JSON.stringify(context.store.filters)]);

  if (slugs?.length > MAX_TARGETED_PLAYERS) {
    return (
      <Container padding={4}>
        <Typography variant="body2">
          {selectedOption && (
            <>
              "<b>{selectedOption.label}</b>" has more than {MAX_TARGETED_PLAYERS}{' '}
              players.{' '}
            </>
          )}
          Please choose a smaller group of players.
        </Typography>
      </Container>
    );
  }

  return (
    <BDDErrorBoundary>
      {selectedOption && (
        <>
          <Container>
            <Row gap={2}>
              <Typography variant="body1">Selected Players:</Typography>
              <Typography variant="body2">
                {selectedOption.type === 'list' ? (
                  <Link
                    to={buildPlayerListRoute(
                      selectedOption.listType,
                      selectedOption.value
                    )}
                  >
                    {selectedOption.label}
                  </Link>
                ) : (
                  selectedOption.label
                )}
              </Typography>
            </Row>
            <Row gap={4} flexWrap>
              {selectedOption?.players?.map((p, i) => (
                <Typography key={p.slug} variant="caption">
                  <PlayerHover playerSlug={p.slug} delay={{ show: 500, hide: 50 }}>
                    <Link to={buildPlayerRouteV2(p.slug)} style={{ color: 'black' }}>
                      {p.firstname} {p.lastname}
                    </Link>
                  </PlayerHover>
                  {i !== selectedOption?.players?.length - 1 && ','}
                </Typography>
              ))}
              {!!selectedOption && !selectedOption.players?.length && (
                <Typography variant="caption">
                  <em>
                    No players{' '}
                    {selectedOption.value === 'favorites' ? 'favorited' : 'on list'}.{' '}
                    {selectedOption.value === 'favorites' && (
                      <>
                        To add favorites, click the <Star /> in the search modal.
                      </>
                    )}
                  </em>
                </Typography>
              )}
            </Row>
          </Container>
          <Divider style={{ margin: '10px' }} />
        </>
      )}
      <Container>
        {!!slugs?.length && (
          <SLStatContextProvider context={context}>
            <TargetedPlayersTable
              slugs={slugs}
              byGameIndex={byGameIndex}
              nonByGameIndex={nonByGameIndex}
            />
          </SLStatContextProvider>
        )}
      </Container>
    </BDDErrorBoundary>
  );
};

export default TargetedPlayers;
