import React from 'react'
import StillBuilding from '../bdd/stillbuilding'

export default function DraftSLPlayerData({
    rinknetId
}) {
    return <>
        Draft SL Player Data
        <StillBuilding/>
    </>
}