import React from "react"
import { useDrop } from "react-dnd"

function Droppable({
    acceptItemTypes,
    hoverColor,
    onDrop,
    onHover,
    className,
    activeClassName,
    children,
    customStyles,
    canDrop,
    style={}
}) {
    // accept, onDrop, hoverColor, className
    const accept = acceptItemTypes
    var hoverColor = hoverColor || '#abfcab'
    const [{ isOver, item }, drop] = useDrop({
        accept: accept,
        canDrop: canDrop,
        hover: (item) => onHover && onHover(item),
        // hover(item) {
        //     if (item.type === itemTypes.PLAYER_LIST_TIER) {
        //         moveTier(item.data.listId, item.data.tierId, id, item.data.startEnd)
        //     } else if (item.type === itemTypes.PLAYER_LIST_PLAYER) {
        //         const draggedId = item.id
        //         if (draggedId !== id) {
        //             movePlayer(item.listId, draggedId, id)
        //         }
        //     }
        // },
        drop: (item, monitor) => onDrop(item, monitor),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            item: monitor.getItem()
        })
    })

    if (customStyles) {
        style = { style, ...customStyles(isOver, item) }
    } else {
      if (isOver) {
        style = { ...style, background: hoverColor, boxShadow: `0px 0px 5px ${hoverColor}` }
      }
    }

    return (
        <div ref={drop}
            className={`${className} ${isOver ? activeClassName : null}`}
            style={style}
        >
            {children}
        </div>
    )
}

export default Droppable