import { useSearchParamsStore } from 'components/bdd/hooks/useSearchParamsStore';
import { getCurrentSeason } from 'helpers/hockeyutils';

const defaultSeason = getCurrentSeason().toString();

const parseValues = (store) => {
  return {
    leagues: (store.getArray('leagues').length > 0 && store.getArray('leagues')) || [
      'nhl',
    ],
    seasons: (store.getArray('seasons').length > 0 && store.getArray('seasons')) || [
      `${defaultSeason.slice(0, 4)}-${defaultSeason.slice(4)}`,
    ],
    gameType: store.getValue('gameType') || 'REGULAR_SEASON',
    positions: store.getValue('positions') || 'skaters',
    teams: store.getArray('teams') || [],
    groupBy: store.getValue('groupBy') || 'player,player_id,league,league_slug',
    age: store.getRange('age'),
    draftEligibleYears: store.getRange('drafteligibleyears'),
  };
};

export const useStatSearchParams = () => {
  const store = useSearchParamsStore();
  const serverFilterValues = parseValues(store);

  const playerFilterValues = store
    .getArray('player_ids')
    .map((p) => parseInt(p))
    .filter((p) => !!p);

  const getStatDefFilterValues = (stats) =>
    stats?.format.stat_definitions.reduce((values, sd) => {
      if (store.getRange(sd.slug)) {
        values[sd.slug] = store.getRange(sd.slug);
      }

      return values;
    }, {}) || {};

  return {
    store,
    serverFilterValues,
    playerFilterValues,
    getStatDefFilterValues,
  };
};
