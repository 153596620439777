import React from "react"
import styled from "styled-components"

import { ThStat } from "../reports"
import { roundToX } from "../../helpers/data"
import { getRedGreenColor } from "../../helpers/tables"

const StyledTable = styled.table`
    width: 100%;
    text-align: center;

    tr {
        border-bottom: 1px solid #DDDDDD77;
    }
`

const renderStat = (statSlug, value, isPercentage) => {
    const roundTo = statSlug === 'gamesPlayed' ? 0 : 2
    if (statSlug === 'production') return value
    return `${roundToX(value, roundTo)}${isPercentage ? '%' : ''}`
}

// data,
// format,
// statSlugs,
// index,
// borderAfter,
// numBorders,
// joinedMetastats
function PlayerTable(props) {
    if (!props.data) return <>No Data</>

    const statSlugToLabel = {...props.format.slug_to_label}
    statSlugToLabel['production'] = 'G-A-P'

    const isNegative = {'G-A-P': false}
    const isPercentage = {'G-A-P': false}
    const statLabelToSlug = {'G-A-P': 'production'}
    const slugToDescription = {'production': 'Goals - Assists - Points'}
    props.format.stat_definitions.forEach(s => {
        isNegative[s.slug] = s.negative
        isPercentage[s.slug] = s.is_percentage
        statLabelToSlug[s.label] = s.slug
        slugToDescription[s.slug] = s.description
    })
    if (!!props.statSlugs) {
        var statSlugs = props.statSlugs
    } else if (!!props.statLabels) {
        var statSlugs = props.statLabels.map(s => statLabelToSlug[s])
    }

    const alphaMult = 3.0
    return <StyledTable>
        <thead>
            <tr style={{borderBottom: "1px solid #33333333"}}>
                {props.index.map(i => <th key={i}>
                    {i}
                </th> )}
                {statSlugs.map(slug => <ThStat key={slug} description={slugToDescription[slug]}>
                    {statSlugToLabel[slug]}
                </ThStat>
                )}
            </tr>
        </thead>
        <tbody>
            {props.data.map((d, rIdx) => {
            const trstyle = props.borderAfter ? 
                (rIdx+1) % props.borderAfter == 0 && ((rIdx+1) / props.borderAfter <= (props.numBorders || 10)) ? 
                    {borderBottom: "3px solid #DDD"} : null
                : null
            return <tr key={props.index.map(i => `${d[i]}`).join('-')} style={trstyle}>                
                {props.index.map(i => <td key={i}>
                    {d[i]}
                </td> )}
                {props.joinedMetastats ?
                    statSlugs.map(slug => {
                        const { value, zscore } = d[slug]
                        const color = getRedGreenColor(zscore, -2, 2, isNegative[slug], alphaMult)
                        return <td key={slug} style={{backgroundColor: color}}>
                            {renderStat(slug, value, isPercentage[slug])}
                        </td>

                    })
                : statSlugs.map(slug => 
                    <td key={slug}>
                        {renderStat(slug, value, isPercentage[s])}
                    </td>
                )}
            </tr>})}
        </tbody>
    </StyledTable>
}

export { PlayerTable }