import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useBddStatQuery, useBddStatQueryV2 } from "../../apollo/queries"
import { getCurrentSeason, nameToLastName } from "../../helpers/hockeyutils"
import { getGameTypeChoices } from "../../helpers/filters"
import { getTeamColorPalette } from "../../helpers/plotting"
import { BDDLoader } from "../bdd/bddloader"
import BDDApiError from "../bdd/bddapierror"
import { filtersToQ } from "../Stats"
import GoodBadHighLowScatter from "../plotting/gbhlscatter"
import ModalFilterBank from "../filters/modalfilterbank"
import { SubSectionHeader } from "../reports"
import { useHistory } from "react-router-dom"
import { buildPlayerRoute } from "helpers/routing"
import { Typography } from "components/bdd/Typography"


export default function TeamSkatersScatter({ league, teamSlug, defaultFilters, slUids, minToi = 50 }) {
  const history = useHistory();
  const [filters, setFilters] = useState(!!defaultFilters ? defaultFilters
    : {
      season: getCurrentSeason(),
      game_type: getGameTypeChoices(league)[0].value,
      strength: 'ES55',
      index: 'Player',
      teams: [teamSlug], // This used to be currentteam_slug (but this causes issues when season changes)
      min_toi: minToi,
      metastats: 'team',
      target_team_slug: teamSlug
    })
  if (!!slUids) {
    delete filters['currentteam_slug']
    filters.sl_uids = slUids
  }

  useEffect(() => {
    setFilters({ ...filters, teams: [teamSlug] })
  }, [teamSlug])

  const q = filtersToQ(filters, 'skaters')
  q.target = 'skaters';
  q.league_slug = league;
  q.norm = 20;
  delete q['statReport'];
  q.statDefs = [{ slug: 'XPGF' }, { slug: 'XPGA' }, { slug: 'toi' }]
  const { data, loading, error, refetch } = useBddStatQueryV2(q)

  const color = getTeamColorPalette(teamSlug)[0]
  try {
    var metastats = data.extras.metastats[teamSlug][0].metastats
  } catch {
    var metastats = null
  }
  return <div>
    <div style={{ paddingBottom: '10px' }}>
      <SubSectionHeader>
        Player XPG Scatter
        <span style={{ paddingLeft: '20px' }}>
          <ModalFilterBank
            filters={filters}
            setFilters={setFilters}
            league={league}
            fetchData={() => refetch()} precalc={true}
          />
        </span>
      </SubSectionHeader>
      <Typography variant='caption' textAlign='center'>
        Click a player to see their playercard
      </Typography>
    </div>
    {loading ? <BDDLoader />
      : error ? <BDDApiError error={error} />
        : !!data ? <GoodBadHighLowScatter
          data={data.data}
          metastats={metastats}
          xStat='XPGF'
          yStat='XPGA'
          invertY
          color={color}
          dataToText={d => `${nameToLastName(d.Player)}`}
          dataToCustomData={d => `${d.toi.toFixed(1)} min.`}
          onClick={({ event, points }) => {
            const slug = points?.[0]?.data?.extraData?.[0]?.slug;
            if (!slug) return
            if (event.metaKey) {
              window.open(buildPlayerRoute(slug), "_blank");
            } else {
              history.push(buildPlayerRoute(slug))
            }
          }}
        /> : null}
  </div>
}