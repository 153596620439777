import React, { useContext } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { Card } from 'components/bdd/Card';
import { ScoutingHistogram } from './ScoutingHistogram';
import { ScoutingTrendPlot } from './ScoutingTrendPlot';
import { PlayerScoutingLists } from '../PlayerScoutingLists';
import { ScoutingGrades } from './ScoutingGrades';
import { ReportSummary } from './ReportSummary';
import { PlayerCalendar } from 'components/bdd/Calendar/PlayerCalendar';
import { WindowContext } from 'components/bdd/context';
import { AnalyticModelScores } from '../AnalyticModelScores';
import ColinQuestionnaire from 'components/Questionnaire/ColinQuestionnaire';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Issues } from './Issues';
import { getCurrentSeason } from 'helpers/hockeyutils';
import useReportOptions from './useReportOptions';
import SkatingEval from 'components/Questionnaire/SkatingEval';
import { DraftPlayerChecks } from 'components/Lists/Builder/components/DraftPlayerChecks';
import { LinkButton } from 'components/bdd/Button';
import { useModal } from 'components/bdd/Modal';
import { DraftSimilarPlayers } from 'components/DraftApp/DraftSimilarPlayers';
import { Typography } from 'components/bdd/Typography';

const Grid = styled.div({
  display: 'grid',
  gap: theme.spacing[3],
  gridAutoRows: '1fr',
  minWidth: '100%',
});

const DesktopGrid = styled(Grid)({
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  gridTemplateRows: '0.1fr 0.1fr 500px 0.5fr 0.1fr 0.1fr 0.5fr 0.5fr 0.5fr',
  gridTemplateAreas: `
    "summary summary summary skills skills skills"
    "analytic analytic schedule schedule schedule schedule"
    "lists lists schedule schedule schedule schedule"
    "reports reports reports reports reports reports"
    "issues issues issues issues issues issues"
    "questionnaire questionnaire questionnaire questionnaire questionnaire questionnaire"
    "skatingeval skatingeval skatingeval skatingeval skatingeval skatingeval"
    "trendPlot trendPlot trendPlot histogram histogram histogram"`,
});

const TabletGrid = styled(Grid)({
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  gridTemplateRows: '0.1fr 0.1fr 500px 0.5fr 0.1fr 0.1fr 0.1fr 0.5fr 0.5fr',
  gridTemplateAreas: `
    "summary summary summary skills skills skills"
    "analytic analytic schedule schedule schedule schedule"
    "lists lists schedule schedule schedule schedule"
    "reports reports reports reports reports reports"
    "issues issues issues issues issues issues"
    "questionnaire questionnaire questionnaire questionnaire questionnaire questionnaire"
    "skatingeval skatingeval skatingeval skatingeval skatingeval skatingeval"
    "trendPlot trendPlot trendPlot trendPlot trendPlot trendPlot"
    "histogram histogram histogram histogram histogram histogram"`,
});

const MobileGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
});

const GridCard = styled(Card)(({ area }) => ({
  gridArea: area,
}));

export default function Scouting({ bddPlayer }) {
  const { width } = useContext(WindowContext);

  const rp = bddPlayer.rinknetPlayer;
  const draftYear = !!rp?.draftyear
    ? rp.draftyear
    : parseInt(getCurrentSeason().toString().slice(4));

  const { buttonGroup: reportsModeBg, component: reportsComponent } = useReportOptions({
    rinknetId: bddPlayer.rinknetId,
    rinknetPlayer: rp,
  });

  return (
    <Grid
      as={
        width >= theme.breakpoints.xl
          ? DesktopGrid
          : width >= theme.breakpoints.sm
          ? TabletGrid
          : MobileGrid
      }
    >
      <GridCard area="summary" header={'Summary'}>
        <Container marginTop={4}>
          <ReportSummary bddPlayer={bddPlayer} />
        </Container>
      </GridCard>
      <GridCard area="skills" header={'Skill Grades'}>
        <Column gap={1}>
          <ScoutingGrades rinknetId={bddPlayer.rinknetId} position={bddPlayer.posFDG.toLowerCase()} />
          <ScoutingGrades rinknetId={bddPlayer.rinknetId} position={bddPlayer.posFDG.toLowerCase()} predictedGrades />
          <DraftPlayerChecks rinknetId={bddPlayer.rinknetId} rinknetIds={[bddPlayer.rinknetId]} draftYear={draftYear} pos={bddPlayer.posFDG}/>
        </Column>
      </GridCard>
      <GridCard area="analytic" header={'Analytic Model Scores'}>
        <Row gap={1} flexWrap>
          <AnalyticModelScores bddPlayer={bddPlayer} rinknetId={bddPlayer.rinknetId} />
          <SimilarScoutingPlayersLinkButton name={`${bddPlayer.firstname} ${bddPlayer.lastname}`} rinknetId={bddPlayer.rinknetId} draftYear={draftYear} />
        </Row>
      </GridCard>
      <GridCard area="lists" header={'Player Lists'}>
        <PlayerScoutingLists bddPlayer={bddPlayer} />
      </GridCard>
      <GridCard
        area="reports"
        header={'Reports'}
        actionContent={<div>{reportsModeBg}</div>}
      >
        <div style={{ marginTop: theme.spacing[3] }}>{reportsComponent}</div>
      </GridCard>
      <GridCard area="questionnaire" header="Questionnaire">
        <Container paddingTop={3} maxHeight={600} overflowY="auto">
          <ColinQuestionnaire rinknetId={bddPlayer.rinknetId} year={draftYear} />
        </Container>
      </GridCard>
      <GridCard area="skatingeval" header="Skating Eval">
        <Container paddingTop={3} maxHeight={600} overflowY="auto">
          <SkatingEval rinknetId={bddPlayer.rinknetId} year={draftYear} />
        </Container>
      </GridCard>
      <GridCard area="issues" header="Issues">
        <Issues bddPlayer={bddPlayer} />
      </GridCard>
      <GridCard area="schedule" header={'Schedule'}>
        <Container>
          <PlayerCalendar bddPlayer={bddPlayer} showReportModal={true} />
        </Container>
      </GridCard>
      {/* <Card style={{ minWidth: 500, flexGrow: 1 }}>
        <SkillRatingsViz rinknetId={bddPlayer.rinknetId} />
      </Card> */}
      <GridCard area="trendPlot" header={'Scouting Grade Trends'}>
        <ScoutingTrendPlot bddPlayer={bddPlayer} />
      </GridCard>
      <GridCard area="histogram" header={'Scouting Grade Histogram'}>
        <ScoutingHistogram bddPlayer={bddPlayer} />
      </GridCard>
    </Grid>
  );
}


export const SimilarScoutingPlayersLinkButton = ({ name, rinknetId, draftYear, text='Show Similar Players' }) => {
  const { renderModal, showModal: showSimilarPlayers, setShowModal: setShowSimilarPlayers } = useModal({
    title: <Typography variant='h4'>Similar Players for {name}</Typography>,
    size: 'xxl'
  });
  return (
    <Container>
      <LinkButton onClick={ev => {
        ev.stopPropagation();
        setShowSimilarPlayers(true);
      }}>
        {text}
      </LinkButton>
      {showSimilarPlayers && renderModal(<DraftSimilarPlayers rinknetId={rinknetId} draftYear={draftYear}/>)}
    </Container>
  );
}