import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { Card } from 'components/bdd/Card';
import { Container } from 'components/bdd/Layout';
import { CreateButton } from 'components/bdd/Button/CreateButton';
import { useModal } from 'components/bdd/Modal';
import { CreateTransactionModal } from './CreateTransactionModal';
import { ObjectiveTransactionsPage } from './ObjectiveTransactions';
import { RecentTranscations } from './RecentTransactions';
import { ButtonGroup } from 'components/bdd/ButtonGroup/ButtonGroup';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { POTransactionDetailPage } from './POTransactionDetailPage';
import { buildProObjectiveRoute } from 'helpers/routing';

export const buildObjectiveTransactionPageRoute = (proObjectiveSlug, txId, variantId) => {
  return `${buildProObjectiveRoute(proObjectiveSlug)}/transactions/proposed/${txId}/${!!variantId ? variantId : ''}`
}

export default function TransactionsPage({ slug }) {
  const { path, params: { proposedOrExecuted } } = useRouteMatch();
  const history = useHistory();

  const { renderModal, setShowModal, showModal } = useModal({
    title: 'Create Transaction',
    size: 'xxl'
  })

  return (
    <BDDErrorBoundary>
      {showModal && renderModal(<CreateTransactionModal proObjectiveSlug={slug} />)}
      <Container padding={3}>
        <Card
          header={(
            <ButtonGroup
              selectedValue={proposedOrExecuted}
              onClick={({ value }) => history.push(path.replace(':proposedOrExecuted?', value))}
              options={[
                { label: 'Proposed', value: 'proposed' },
                { label: 'Executed', value: 'executed' },          
              ]}
            />
          )}
          actionContent={(
            <CreateButton onClick={() => setShowModal(true)}>Create Transaction</CreateButton>
          )}
        >
          <Switch>
            <Route
              path={`${path.replace(':proposedOrExecuted?', 'proposed')}/:id/:variantId?`} 
              render={({ match: { params: { id }}}) => <POTransactionDetailPage transactionId={id}/>}
            />
            <Route path={path.replace(':proposedOrExecuted?', 'proposed')} component={ObjectiveTransactionsPage} />
            <Route path={path.replace(':proposedOrExecuted?', 'executed')} component={RecentTranscations}/>
            <Redirect to={path.replace(':proposedOrExecuted?', 'proposed')}/>
          </Switch>
        </Card>
      </Container>
    </BDDErrorBoundary>
  );
}
