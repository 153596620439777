import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { theme } from 'constants';

const Button = styled.div(({ selected, color, size }) => ({
  ...theme.borders.none,
  ...theme.buttons.base,
  display: 'flex',
  justifyContent: 'start',
  height: size == 'small' ? theme.spacing[7] : theme.spacing[8],
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  backgroundColor: selected
    ? theme.colors[color].highlight
    : theme.colors[color].background,
  '&: hover': {
    backgroundColor: theme.colors[color].highlight,
  },
}));

const Title = styled.div(({ selected, size, color }) => ({
  ...theme.font.base,
  ...(size == 'small' ? theme.typography.body1 : theme.typography.subtitle1),
  width: '100%',
  color: selected ? theme.colors[color].text.primary : theme.colors[color].text.secondary,
  paddingRight: 'auto',
  paddingLeft: theme.spacing[3],
}));

const LinkStyles = styled(RouterLink)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '&: hover': {
    textDecoration: 'none',
  },
});

const Link = ({ title, url, selected, color, size, onClick }) => {
  const linkContent = (
    <Title selected={selected} color={color} size={size}>
      {title}
    </Title>
  );

  return (
    <Button selected={selected} color={color} size={size} onClick={onClick}>
      {url ? <LinkStyles to={url}>{linkContent}</LinkStyles> : linkContent}
    </Button>
  );
};

export default Link;
