import useToggle from 'components/bdd/Toggle/useToggle';
import { useEffect } from 'react';
import { useEPStatsContext } from '../context';

export const TargetGroupToggle = ({}) => {
  const { setTargetGroup } = useEPStatsContext((state) => state.actions);
  const { toggled: useAgeComparison, toggleComponent: ageComparisonToggle } = useToggle({
    label: 'Use age comparison',
    initialToggled: true,
  });

  useEffect(() => {
    setTargetGroup(useAgeComparison ? 'age' : 'league');
  }, [useAgeComparison]);

  return ageComparisonToggle;
};
