import { useQuery } from "@apollo/client";
import { GET_BF_TRADE } from "apollo/queries/bruinsfriendly.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { CreateUpdateBFTradeFlow } from "./CreateUpdateBFTradeFlow";
import useBddTeams from "components/bdd/Teams/useBddTeams";

export const UpdateBFTrade = ({ bfTrade: bfTradeArg, tradeId, onSubmit }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_TRADE, {
      variables: { id: tradeId },
      skip: !!bfTradeArg,
    })
  );

  if (!tradeId && !bfTradeArg) {
    throw Error('Must supply either tradeId or bfTrade');
  }

  const bfTrade = !!data ? data?.bfTrade : bfTradeArg;

  const { bddTeams } = useBddTeams({ leagueSlug: 'NHL' });
  const bfTeamSlugToBDD = bddTeams?.reduce((acc, t) => {
    acc[t.bfTeamSlug] = t;
    return acc;
  }, {});

  if ((!bfTradeArg && !!placeholder) || !bddTeams) return placeholder;

  const teams = bfTrade.teamSummaries.map((t) => bfTeamSlugToBDD[t.teamSlug].slTeamSlug);
  const assets = bfTrade.tradedPlayers
    .map((p) => ({
      fromTeamSlug: bfTeamSlugToBDD[p.fromTeamSlug].slTeamSlug,
      toTeamSlug: bfTeamSlugToBDD[p.toTeamSlug].slTeamSlug,
      assetType: 'PLAYER',
      bfSlug: p.playerSlug,
      retainedSalary: p.retainedSalary,
      retainedSalaryPct: p.retainedSalaryPct
    })).concat(
      bfTrade.tradedDraftPicks.map((p) => ({
        fromTeamSlug: bfTeamSlugToBDD[p.fromTeamSlug].slTeamSlug,
        toTeamSlug: bfTeamSlugToBDD[p.toTeamSlug].slTeamSlug,
        assetType: 'DRAFT_PICK',
        assetId: p.pickId,
        bfDraftPick: p.bfDraftPick
      })) 
    );

  return (
    <CreateUpdateBFTradeFlow
      existingBfTradeId={bfTrade.id}
      includePickUpdateStep={true}
      defaultTeams={teams}
      defaultAssets={assets}
      defaultDate={bfTrade.date}
      onSubmit={() => !!onSubmit && onSubmit()}
    />
  )
}