import { Column } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { useContext } from 'react';
import { VideoContext } from '../..';

export const SelectFilter = ({
  label,
  options,
  col,
  loading,
  onSelected,
  isMulti,
  placeholder,
  selectProps,
}) => {
  const { setSourceFilters, sourceFilters } = useContext(VideoContext);

  if (!sourceFilters) return null;

  return (
    <Column>
      <Typography variant="body1">{label}</Typography>
      <StyledBDDSelect
        options={options}
        selectedValue={sourceFilters[col]?.val}
        onChange={(n, v) => {
          setSourceFilters({
            [col]: {
              id: col,
              col,
              val: v,
              ...(isMulti ? { cond: 'isin' } : {}),
            },
          });

          onSelected && onSelected(v);
        }}
        selectProps={{
          isSearchable: true,
          isClearable: false,
          loading,
          isMulti,
          placeholder,
          ...selectProps,
        }}
      />
    </Column>
  );
};
