import { PlaylistForm } from './PlaylistForm';
import { usePlaylistMutation } from '.';

export const UpdatePlaylistForm = ({ playlist, onSubmit }) => {
  const { updatePlaylist, loading } = usePlaylistMutation(playlist);
  const handleSubmit = (values) => {
    updatePlaylist({
      variables: {
        input: {
          id: playlist.id,
          name: values.name,
          description: values.description,
        },
        usersToUpdate: values.users.map((u) => ({
          userId: u.userId,
          canEdit: u.canEdit,
        })),
      },
    });

    onSubmit && onSubmit();
  };

  const defaultValues = {
    ...playlist,
    users: playlist.users.map((u) => ({
      userId: u.user.id,
      username: u.user.username,
      canEdit: u.canEdit,
    })),
  };

  return (
    <PlaylistForm
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      submitting={loading}
      disabled={loading}
    />
  );
};
