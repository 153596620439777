import { useState, useEffect } from 'react'

export const windowSizes = [
  { size: 'xs', maxWidth: 575},
  { size: 'sm', maxWidth: 768},
  { size: 'md', maxWidth: 992},
  { size: 'lg', maxWidth: 1200},
  { size: 'xl', maxWidth: 1400},
]

export const windowSizeIsLessThanEq = (windowSize, size) => {
  const index = windowSizes.findIndex(w => w.size === windowSize)
  const index2 = windowSizes.findIndex(w => w.size === size)
  return index <= index2
}
export const windowSizeIsGreaterThanEq = (windowSize, size) => {
  const index = windowSizes.findIndex(w => w.size === windowSize)
  const index2 = windowSizes.findIndex(w => w.size === size)
  return index >= index2
}

function getWindowSize() {
  const { innerWidth: width } = window;
  for (let i=0; i<windowSizes.length; i++) {
    if (width <= windowSizes[i].maxWidth) {
      return windowSizes[i].size
    }
  }
  return 'xxl'
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}