import { useQuery } from '@apollo/client';
import { GET_NOTE_HISTORY } from 'apollo/queries/note.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { TextEditor } from 'components/bdd/TextEditor';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { dateToTimeAgo } from 'helpers/helpers';

export const NoteHistory = ({ parentEntityId }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_NOTE_HISTORY, { variables: { parentEntityId } })
  );

  if (!data) return placeholder;

  return (
    <Container maxHeight={500} width={300} overflow="auto">
      {data.bddNoteHistory.map((n) => (
        <Border>
          <Container padding={1} backgroundColor={theme.colors.light.secondary}>
            <Typography variant="body1">
              {n.user.username} commented {dateToTimeAgo(n.dateCreated)}
            </Typography>
          </Container>
          <TextEditor readOnly text={JSON.parse(n.note).comment} />
        </Border>
      ))}
    </Container>
  );
};
