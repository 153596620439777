import React from 'react'
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

const DisappearingRowStyles = styled(Row)`     
    flex-wrap: nowrap;
    [class*='disappearing-col'] {
        transition: 
            flex 0.5s ease-in-out, 
            max-width .5s ease-in-out, 
            max-height .5s ease-in-out,
            padding .5s ease-in-out,
            border .5s ease-in-out;
            font-size: .5s ease-in-out;
    }
`
export const DisappearingRow = props => <DisappearingRowStyles {...props} />

const DisappearingColStyles = styled(Col)`
    ${props => props.isvisible ? `
        overflow: scroll;
    ` : `
        overflow: hidden;
        max-width: 0 !important;
        max-height: 0 !important;
        padding: 0 !important;
        font-size: 0 !important;
    `}
`

export const DisappearingCol = ({size=6, isvisible, children, ...rest}) => 
    <DisappearingColStyles className='disappearing-col' 
        sm={!!isvisible ? size : 0} 
        isvisible={isvisible ? 1 : 0} 
        {...rest}
    >
        {children}
    </DisappearingColStyles>