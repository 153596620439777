import { ModalContext } from 'components/bdd/Modal';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { FullGameVideo } from '../components';

const removeProperty = (obj, id) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v.id != id));

const removeNull = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v.val != null));

export const useFGVideoContext = ({ gameUid }) => {
  const [filters, setStoreFilters] = useState({
    game_uid: {
      col: 'game_uid',
      val: gameUid,
    },
  });

  const [selectedEvents, setSelectedEvents] = useState([]);

  const setFilters = (filtersToSet) => {
    const newFilters = removeNull({ ...filters, ...filtersToSet });
    setStoreFilters(newFilters);
    setSelectedEvents([]);
  };

  const clearFilters = (ids) => {
    ids.forEach((id) => setStoreFilters(removeProperty(filters, id)));
    setSelectedEvents([]);
  };

  const resetFilters = () => {
    setStoreFilters(defaults);
    setSelectedEvents([]);
  };

  const [videoState, setVideoState] = useState({
    show: false,
    events: null,
  });

  const onPlay = (events) =>
    setVideoState({
      show: true,
      events: events,
    });

  const { renderModal } = useContext(ModalContext);

  useEffect(() => {
    if (!videoState.show) return;

    renderModal({ body: <FullGameVideo gameUid={gameUid} />, size: 'xxl' });
  }, [videoState]);

  return {
    filters,
    setFilters,
    clearFilters,
    resetFilters,
    selectedEvents,
    setSelectedEvents,
    onPlay,
  };
};
