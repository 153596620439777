import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import InputRange from 'react-input-range';
import useDebounce from '../hooks/usedebounce';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { NumberInput } from '../NumberInput/NumberInput';
import { TooltipSpan } from 'components/reports';
import { IconButton } from '../Button';

export const InputRangeContainer = styled.div(({ minWidth }) => ({
  minWidth: minWidth || 125,
}));

export const RangeSliderContainer = styled.div(({ color }) => ({
  paddingLeft: theme.spacing[2],
  '.input-range__track--active': {
    background: color,
    borderColor: color,
  },
  '.input-range__slider': {
    backgroundColor: color,
    borderColor: color,
  },
}));

export const LabelContainer = styled.div({
  ...theme.typography.label,
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
});

const useInputRange = ({
  key,
  name,
  min,
  max,
  initialMinValue,
  initialMaxValue,
  valueSuffix,
  label,
  tooltip,
  step,
  onLabelSelected,
  formatLabel, // called with min or max
  formatFullLabel, // called with { min, max }
  minWidth,
  includeInputBoxes,
  hideRange,
  onChange,
  onChangeComplete,
  debounceMS = 0,
  isClearable = false,
}) => {
  const initialState = {
    min: initialMinValue === undefined ? min : initialMinValue,
    max: initialMaxValue === undefined ? max : initialMaxValue,
  };

  const [value, setValue] = useState(initialState);
  const debouncedMinValue = useDebounce(value.min, 1500);
  const debouncedMaxValue = useDebounce(value.max, 1500);

  const inputChanged = value.min > min || value.max < max;

  useEffect(() => {
    setValue(initialState);
  }, [initialMinValue, initialMaxValue, min, max]);

  const resetInputRange = () => {
    setValue({ min, max });
  };

  const getFilters = (col) => [
    {
      col,
      cond: '<=',
      val: value.max,
    },
    {
      col,
      cond: '>=',
      val: value.min,
    },
  ];

  return {
    value: { min: debouncedMinValue, max: debouncedMaxValue },
    inputChanged,
    resetInputRange,
    getFilters,
    inputRange: (
      <InputRangeContainer key={key} minWidth={minWidth}>
        <Row justifyContent="space-between">
          <LabelContainer onClick={() => onLabelSelected()}>
            <Row columnGap={2}>
              <TooltipSpan content={tooltip}>{label}</TooltipSpan>
              <Typography variant="stat">
                {!!formatFullLabel
                  ? formatFullLabel(value)
                  : !!formatLabel
                  ? `${formatLabel(value.min)}-${formatLabel(value.max)}`
                  : `${value.min}-${value.max}${valueSuffix || ''}`}
              </Typography>
            </Row>
          </LabelContainer>
          {/* {isClearable && (
            <IconButton/>
          )} */}
        </Row>
        {includeInputBoxes && (
          <Container paddingBottom={1}>
            <Row columnGap={2}>
              <NumberInput
                variant="label"
                value={value.min}
                min={min}
                max={max}
                onChange={(e) => {
                  const updated = { min: parseFloat(e.target.value), max: value.max };
                  setValue(updated);
                  !!onChange && onChange(updated);
                  !!onChangeComplete && onChangeComplete(updated);
                }}
              />
              <NumberInput
                variant="label"
                value={value.max}
                min={min}
                max={max}
                onChange={(e) => {
                  const updated = { max: parseFloat(e.target.value), min: value.min };
                  setValue(updated);
                  !!onChange && onChange(updated);
                  !!onChangeComplete && onChangeComplete(updated);
                }}
              />
            </Row>
          </Container>
        )}
        {!hideRange && (
          <RangeSliderContainer color="black">
            <InputRange
              name={name}
              maxValue={max}
              minValue={min}
              formatLabel={() => ''}
              value={value}
              step={step}
              onChange={(value) => {
                setValue(value);
                !!onChange && onChange(value);
              }}
              onChangeComplete={(value) => {
                !!onChangeComplete && onChangeComplete(value);
              }}
            />
          </RangeSliderContainer>
        )}
      </InputRangeContainer>
    ),
  };
};

export default useInputRange;
