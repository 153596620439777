export const numberOperatorOptions = [
  {
    value: '=',
    label: 'Equals',
  },
  {
    value: '>',
    label: 'More than',
  },
  {
    value: '>=',
    label: 'More or equal than',
  },
  {
    value: '<',
    label: 'Less than',
  },
  {
    value: '<=',
    label: 'Less or equal than',
  },
];
export const numberOperators = numberOperatorOptions.map((o) => o.value);

export const selectOperatorOptions = [
  {
    value: '=',
    label: 'Is',
  },
  {
    value: '!=',
    label: 'Is not',
  },
];

export const booleanOperatorOptions = [
  {
    value: '=',
    label: 'True',
  },
  {
    value: '!=',
    label: 'False',
  },
];

export const dateOperatorOptions = [
  {
    value: '=',
    label: 'On',
  },
  {
    value: '>',
    label: 'After',
  },
  {
    value: '>=',
    label: 'On or after',
  },
  {
    value: '<',
    label: 'Before',
  },
  {
    value: '<=',
    label: 'On or before',
  },
];

// export const inputRangeOperatorOptions = [
//   {
//     value: '=',
//     label: 'Is',
//   },
//   {
//     value: '!=',
//     label: 'Is not',
//   },
// ];

// const options = [
//   {
//     id: 'browse',
//     label: 'Player Status',
//     // operators: ['='],
//     type: 'select',
//     options: [
//       {
//         value: 'active-players',
//         label: 'Active Players',
//       },
//       {
//         value: 'free-agents',
//         label: 'Free Agents',
//       },
//       {
//         value: 'ufas',
//         label: 'UFAs',
//       },
//       {
//         value: 'rfas',
//         label: 'RFAs',
//       },
//       {
//         value: 'elcs',
//         label: 'ELCs',
//       },
//     ],
//   },
// ];

// // Should translate to:
// const filter = {
//   id: 'browse',
//   cond: '=',
//   val: 'active-players',
// };

// const statOptions = [
//   {
//     id: 'stats-gp',
//     label: 'Games Played',
//     type: 'number',
//     // operators: numberOperators,
//     extraFilters: [
//       {
//         id: 'time-range',
//         type: 'select',
//         options: timeRangeOptions,
//       },
//     ],
//   },
// ];

// // Should translate to:
// const statFilter = {
// id: 'stats-gp',
// cond: '>=',
// val: 10,
// extraFilters: [
//   {
//     id: 'time-range',
//     cond: '=',
//     val: 'platform-stats',
//   },
// ],
// };
