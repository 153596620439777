

export const getMetastatsForReport = (report, metastats) => {
  if (!report.metastatsSplitId || !metastats) return null;
  const split = metastats.filter((m) => m.id == report.metastatsSplitId);
  if (split.length) return split[0];
  return null;
};

export const getScoutId = scout => {
  // given either a rinknetUser or user object, find ID
  if (scout.__typename === 'Users') {
    return !!scout.rinknetUserId ? scout.rinknetUserId : scout.id
  } else {
    return scout.id 
  }
}

export const getReportScoutId = report => {
  if (report.__typename === 'RinknetReports') {
    return report.scoutid
  } else {
    return !!report.user.rinknetUserId ? report.user.rinknetUserId : report.user.id
  }
}

export const getScoutName = scout => {
  if (scout.__typename === 'RinknetUsers') {
    return `${scout.firstname} ${scout.lastname}`
  } else {
    return `${scout.username}`
  }
}

export const reportToDate = report => {
  if (report.__typename === 'RinknetReports') {
    return report.date
  } else {
    return report.creationDate
  }
}