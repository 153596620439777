import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { theme } from 'constants';
import { UPDATE_PLAYER_LIST } from 'apollo/queries/playerlist.queries';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const names = ['Dan', 'Seth', 'Josh']; // won't catch CW in here
const getName = () => names[Math.floor(Math.random() * names.length)];

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.spacing[6],
});

const Title = styled.div({
  ...theme.typography.h5,
});

const Subtitle = styled.div({
  ...theme.typography.subtitle2,
});

const ButtonContainer = styled.div({
  ...theme.typography.subtitle2,
});

export const ListControllerErrorContent = ({ listId, error }) => {
  const history = useHistory();

  const [updatePlayerlist, { called }] = useMutation(UPDATE_PLAYER_LIST, {
    onCompleted: () => history.go(0),
  });

  return (
    <Container>
      <div>
        <Title>This list is broken!</Title>
        <Subtitle>It's probably {getName()}'s fault...</Subtitle>
      </div>
      <ButtonContainer>
        <Button
          variant="outline-dark"
          disabled={called}
          onClick={() => {
            updatePlayerlist({
              variables: {
                input: { id: listId },
              },
            });
          }}
        >
          {(called && 'Cleaning up list') || 'Clean-up list'}
        </Button>
      </ButtonContainer>
      <Subtitle>Or send this to Engineering:</Subtitle>
      <code>{error.stack}</code>
    </Container>
  );
};
