import { BDDJSON } from 'components/bdd/bddcomponents';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { VideoContext } from '../..';

export const SourceJSONFilter = ({}) => {
  const { replaceSourceFilters, sourceFilters } = useContext(VideoContext);
  const [filters, setFilters] = useState(sourceFilters);

  return (
    <Container>
      <Column gap={2}>
        <BDDJSON
          height="800px"
          placeholder={filters}
          onChange={(obj) => {
            if (obj.jsObject) {
              setFilters(obj.jsObject);
            } else {
              console.warn(obj.error);
            }
          }}
        />
        <Button
          variant="primary"
          // disabled={JSON.stringify(filters) == JSON.stringify(sourceFilters)}
          onClick={() => {
            replaceSourceFilters(filters);
          }}
        >
          <Typography variant="body2">Fetch Events</Typography>
        </Button>
      </Column>
    </Container>
  );
};
