import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { GET_PLAYER_WAR } from '../../../apollo/queries/iso.queries';
import { SubSectionHeader } from '../../reports';
import { BDDLoader } from '../../bdd/bddloader';
import BDDApiError from '../../bdd/bddapierror';
import { PctHighlightBox } from '../../bdd/warcomponents';
import { BDDOptionGroup } from '../../bdd';
import {
  getCurrentSeason,
  getPreviousSeason,
  season8to4,
} from '../../../helpers/hockeyutils';
import { roundToX } from '../../../helpers/data';
import styled from 'styled-components';
import { numberToOrdinal } from '../../../helpers/helpers';
import { theme } from 'constants';

const HoverStyles = styled.div`
  table {
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    th {
      border-bottom: 1px solid #aaa;
    }
    th,
    td {
      padding: 0 10px;
    }
  }
`;

const Header = styled.div({
  ...theme.typography.h5,
  color: theme.colors.light.text.secondary,
  marginBottom: theme.spacing[2]
});

// VALID statTypes are: war, totalImpact[55|PP|SH], pen, [off|def|shot]ImpactPct[55|PP|SH],
export default function PlayerWarBoxes({
  slUid,
  posFDG,
  warData,
  showHeader = true,
  showSplitOptions = true,
  statTypes = null, // if supplied, will render only these stattypes
  fontSize,
}) {
  const [dataSplit, setDataSplit] = useState('100');
  const [strength, setStrength] = useState('55');
  // const [plotMode, setPlotMode] = useState('raw')
  if (!warData && !slUid)
   return null
  if (!!warData) {
    var loading = false;
    var error = undefined;
  } else {
    var variables = { slUid };
    if (dataSplit === '100') {
      variables = { ...variables, splitType: 'game', numGames: 100 };
    } else {
      variables = { ...variables, splitType: 'season', season: dataSplit };
    }
    var { data, loading, error } = useQuery(GET_PLAYER_WAR, { variables });
    var warData = !!data ? data.playerIsolatedImpact : null;
  }

  if (!!warData) {
    var strengthToData = warData.dataByStrength.reduce(
      (acc, curr) => ({ ...acc, [curr.strength]: curr }),
      {}
    );
    var gp = warData.gp;
  }
  const strengths = ['55', 'PP', 'SH'];
  const strengthToTitle = { 55: '5v5', PP: 'PP', SH: 'SH' };
  const cats = ['offImpactPct', 'defImpactPct', 'shotImpactPct'];
  const catToTitle = {
    offImpactPct: 'OFF',
    defImpactPct: 'DEF',
    shotImpactPct: 'SHOT',
  };
  const currSeason = getCurrentSeason();
  const splitTypeOptions = [
    { label: '100G', value: '100' },
    { label: season8to4(currSeason, true), value: currSeason },
    {
      label: season8to4(getPreviousSeason(currSeason), true),
      value: getPreviousSeason(currSeason),
    },
  ];

  const getHoverContent = (prefix, strength, title, isGoalie=false) => {
    const dataSource = !!strength ? strengthToData[strength] : warData;
    const goalsOrWinsAdded = isGoalie && !!strength // need to treat goaile strenght impact special
      ? roundToX(dataSource[prefix] * dataSource['shotsOnGoalAgainst'], 2)
      : roundToX(dataSource[prefix], 2);
      
    return (
      <HoverStyles>
        <table>
          <tbody>
            <tr>
              <th>GP</th>
              {!!strength && !isGoalie && <th>toi</th>}
              <th>{!!title ? title : prefix.toUpperCase()}</th>
              <th>% / Rank (among {warData.posFdg.toUpperCase()})</th>
            </tr>
            <tr>
              <td>{warData.gp}</td>
              {!!strength && !isGoalie && <td>{roundToX(dataSource.toi, 1)}</td>}
              <td>{roundToX(goalsOrWinsAdded, 2)}</td>
              <td>
                {roundToX(100 * (1 - dataSource[`${prefix}Pct`]), 1)}%{' / '}
                {numberToOrdinal(dataSource[`${prefix}Rank`])}
              </td>
            </tr>
          </tbody>
        </table>
      </HoverStyles>
    );
  };

  // A helper function for rendering a box given a statType (see above)
  const renderBox = (statType) => {
    if (statType === 'war') {
      return (
        <PctHighlightBox
          key={statType}
          title={'OVR'}
          val={warData.warPct}
          hoverContent={getHoverContent('war')}
          fontSize={fontSize}
        />
      );
    } else if (statType.startsWith('totalImpact')) {
      const s = statType.slice(-2);
      const toiPerGp = strengthToData[s].toi / gp;
      const toiThresh = s == '55' ? 5 : 1;
      const val =
        toiPerGp > toiThresh ? strengthToData[s].totalImpactPct : 'N/A';

      const titleStyle = { cursor: 'pointer' };
      if (strength === s) titleStyle['fontWeight'] = 'bold';
      return (
        <PctHighlightBox
          key={s}
          hoverContent={getHoverContent('totalImpact', s, 'Goals Added')}
          title={
            <span onClick={() => setStrength(s)} style={titleStyle}>
              {strengthToTitle[s]}
            </span>
          }
          val={val}
          fontSize={fontSize}
        />
      );
    } else if (statType.startsWith('goalieImpact')) {
      const s = statType.slice(-2);
      const val = strengthToData[s].goalieImpactPct;
      // const toiPerGp = strengthToData[s].toi / gp;
      // const toiThresh = s == '55' ? 5 : 1;
      // const val =
      //   toiPerGp > toiThresh ? strengthToData[s].totalImpactPct : 'N/A';

      // const titleStyle = { cursor: 'pointer' };
      // if (strength === s) titleStyle['fontWeight'] = 'bold';
      return (
        <PctHighlightBox
          key={s}
          hoverContent={getHoverContent('goalieImpact', s, 'Goals Saved', true)}
          title={
            <span>
              {strengthToTitle[s]}
            </span>
          }
          val={val}
          fontSize={fontSize}
        />
      )
    } else if (statType === 'pen') {
      return (
        <PctHighlightBox
          title="PEN"
          val={warData.adjPenaltyDifferentialPct}
          hoverContent={
            <HoverStyles>
              <table>
                <tbody>
                  <tr>
                    <th>PDiff</th>
                    <th>%</th>
                  </tr>
                  <tr>
                    <td>
                      {warData.penaltyDifferential > 0 ? '+' : ''}
                      {warData.penaltyDifferential}
                    </td>
                    <td>
                      {roundToX(100 * (1 - warData.penaltyDifferentialPct), 1)}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </HoverStyles>
          }
          fontSize={fontSize}
        />
      );
    } else if (statType.indexOf('ImpactPct') >= 0) {
      // cat will be "off/def/shot + ImpactPct"
      // but we'll translate that into "weightedOff..."
      const cat = statType.slice(0, -2);
      const s = statType.slice(-2);
      return (
        <PctHighlightBox
          key={cat}
          title={`${strengthToTitle[s]} ${catToTitle[cat]}`}
          val={
            strengthToData[s][`weighted${cat[0].toUpperCase() + cat.slice(1)}`]
          }
          fontSize={fontSize}
        />
      );
    }
  };

  return (
    <>
      {showHeader && <Header>Isolated Impacts</Header>}
      {showSplitOptions && (
        <BDDOptionGroup
          selected={dataSplit}
          options={splitTypeOptions}
          onClick={(val) => setDataSplit(val)}
          fontSize={'14px'}
        />
      )}
      {loading ? (
        <BDDLoader variant='squares'/>
      ) : error ? (
        <BDDApiError error={error} />
      ) : !!warData ? (
        <div>
          {!!statTypes ? (
            <>{statTypes.map(renderBox)}</>
          ) : posFDG === 'g'
          ? (
            <>
              <Row noGutters>
                <Col style={{ textAlign: 'center' }}>
                  {renderBox('war')}
                  {strengths.map((s) => renderBox(`goalieImpact${s}`))}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row noGutters>
                <Col style={{ textAlign: 'center' }}>
                  {renderBox('war')}
                  {strengths.map((s) => renderBox(`totalImpact${s}`))}
                  {renderBox('pen')}
                </Col>
              </Row>
              <Row className="justify-content-md-center" noGutters>
                <Col style={{ textAlign: 'center' }}>
                  {cats.map((c) => renderBox(`${c}${strength}`))}
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <em>No impact data found</em>
      )}
    </>
  );
}
