import { useBddStatQueryV2 } from 'apollo/queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';

export const useGamePlayersData = ({ gameUid, target }) => {
  const { data, placeholder } = usePlaceholder(
    useBddStatQueryV2(
      {
        target,
        filters: [
          {
            col: 'game_uid',
            val: gameUid,
          },
        ],
        index: [
          'game_uid',
          'sl_uid',
          'player_id',
          'position',
          'Player',
          'team_slug',
          'team_shorthand',
          'opp_team_shorthand',
        ],
        statSlugs: [],
      },
      !gameUid
    )
  );

  return {
    placeholder,
    data:
      !!data && !!data?.data?.length
        ? data.data
            .map((d) => d)
            .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
        : [],
  };
  W;
};
