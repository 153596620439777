import { useEffect, useState } from 'react';
import { useSearchParamsStore } from 'components/bdd/hooks/useSearchParamsStore';
import { formatDateGQL } from 'helpers/helpers';

const parseValues = (store) => {
  return {
    leagues: store.getArray('leagues'),
    scouts: store.getArray('scouts'),
    rinknetIds: store.getArray('rinknetIds'),
    startDate: store.getValue('startDate'),
    endDate: store.getValue('endDate'),
  };

  //   leagues: (store.getArray('leagues').length > 0 &&
  //     store.getArray('leagues')) || ['shl', 'ushl'],
  //   seasons: (store.getArray('seasons').length > 0 &&
  //     store.getArray('seasons')) || ['2020-2021', '2021-2022'],
  //   gameType: store.getValue('gameType') || 'REGULAR_SEASON',
  //   positions: store.getValue('positions') || 'F',
  //   teams: store.getArray('teams') || [],
  //   groupBy: store.getValue('groupBy') || 'player,player_id,league,league_slug',
  //   age: store.getRange('age') || { min: 15, max: 18 },
  // };
};

export const filtersToGQL = (fs) => {
  var gqlFilters = [];
  Object.keys(fs).forEach((key) => {
    if (key === 'leagues') {
      gqlFilters.push({ col: 'leagueid', val: fs[key], cond: 'isin' });
    } else if (key === 'scouts') {
      gqlFilters.push({ col: 'scout_id', val: fs[key], cond: 'isin' });
    } else if (key === 'rinknetIds') {
      gqlFilters.push({ col: 'player_id', val: fs[key], cond: 'isin' });
    } else if (key === 'startDate' && fs[key]) {
      gqlFilters.push({ col: 'creation_date', val: formatDateGQL(fs[key]), cond: '>=' });
    } else if (key === 'endDate' && fs[key]) {
      gqlFilters.push({ col: 'creation_date', val: formatDateGQL(fs[key]), cond: '<=' });
    } else if (key === 'reportIds') {
      gqlFilters.push({ col: 'report_id', val: fs[key], cond: 'isin' });
    } else if (key === 'game_id') {
      gqlFilters.push({ col: 'game_id', val: fs[key], cond: 'isin' });
    } else {
      gqlFilters.push({ col: key, val: fs[key] });
    }
  });

  gqlFilters = JSON.stringify(gqlFilters);
  return gqlFilters;
};

export const useReportSearchParams = (
  defaultFilters = {},
  disableSearchParams = false
) => {
  var store = useSearchParamsStore(); // eslint-disable-line
  const [filters, setFilters] = useState(
    disableSearchParams ? defaultFilters : parseValues(store)
  );

  const setSearchParams = () => {
    if (!!filters.leagues) store.setArray('leagues', filters.leagues);
    if (!!filters.scouts) store.setArray('scouts', filters.scouts);
    // if (!!filters.rinknetIds) store.setArray('rinknetIds', filters.rinknetIds)
  };

  const parsedFilters = filtersToGQL(filters);
  const [currRequestFilters, setCurrRequestFilters] = useState(parsedFilters);

  // if search params are disabled, we want to react to changes in default filters
  useEffect(() => {
    if (disableSearchParams) {
      setFilters(defaultFilters);
      setCurrRequestFilters(filtersToGQL(defaultFilters));
    }
  }, [filtersToGQL(defaultFilters), disableSearchParams]);

  return {
    filters,
    setFilters,
    setSearchParams,
    parsedFilters,
    currRequestFilters,
    setCurrRequestFilters,
  };
};
