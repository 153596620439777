import React, { useState } from "react"
import { gql, useQuery } from '@apollo/client'
import { Button, Col, Modal, Row } from "react-bootstrap"
import styled from "styled-components"

import BDDSelect from "../bdd/bddselect"
import { HoverInfo } from "../bdd"
import { BDDLoader } from "../bdd/bddloader"
import BDDApiError from "../bdd/bddapierror"
import { SectionHeader, SubSectionHeader } from "../reports"
import { formatDate } from "../../helpers/helpers"
import { getSeasonChoices } from "../../helpers/filters"
import { getCurrentSeason } from "../../helpers/hockeyutils"
import { GET_RINKNET_LEAGUES } from "../../apollo/queries/league.queries"
import { GET_RINKNET_TEAMS_BY_LEAGUE } from "../../apollo/queries/teams.queries"
import { positionOptions, SelectInput } from "./playerformhelpers"
import { Field } from "formik"
import { GET_RINKNET_GAMES_BY_TEAM } from "../../apollo/queries/game.queries"
import { useModal } from "components/bdd/Modal"
import { ReportGamesSelector } from "./ReportGamesSelector"

const Styles = styled.div`
  .select-row {
      margin-bottom: 10px;
  }
`
const SelectGamesModal = ({ show, handleClose, handleSubmit, defaultLeagueId, defaultTeamId }) => {

  const seasonOptions = getSeasonChoices()

  const [filters, setFilters] = useState({
    league: defaultLeagueId,
    season: getCurrentSeason(),
    teamid: defaultTeamId
  })
  const [games, setGames] = useState([])
  const { data: leagueData, loading: leagueLoading, error: leagueError } = useQuery(GET_RINKNET_LEAGUES)
  // const { data: seasonsData, loading: seasonsLoading, error: seasonsError } = useQuery(GET_RINKNET_LEAGUES_SEASONS, {
  //     variables: { leagueid: filters.league  }
  // })
  const {
    data: teamData,
    loading: teamLoading,
    error: teamError,
  } = useQuery(GET_RINKNET_TEAMS_BY_LEAGUE, {
    variables: { filters: { leagueid: filters.league, active: true } },
  });

  const { data: gamesData, loading: gamesLoading, error: gamesError } = useQuery(GET_RINKNET_GAMES_BY_TEAM, {
    variables: { leagueid: filters.league, season: filters.season, teamid: filters.teamid }
  })
  const leagueOptions = !!leagueData ? leagueData.allRinknetLeagues.edges.map(e => ({
    label: e.node.name, value: e.node.id_
  })) : []
  // const seasonOptions = !!seasonsData ? seasonsData.rinknetSeasons.map(s => ({
  //     label: s, value: s
  // })) : []
  const teamOptions = !!teamData ? teamData.allRinknetTeams.edges.map(e => ({
    label: e.node.longname, value: e.node.id_
  })) : []
  const gameOptions = !!gamesData ? gamesData.allRinknetGames.edges.map(e => ({
    label: `${formatDate(e.node.date)} ${e.node.hometeam.shortname} vs ${e.node.awayteam.shortname}`, value: e.node.id
  })) : []

  return <Modal
    show={show}
    onHide={handleClose}
  >
    <Modal.Header>
      <SectionHeader>Select Games</SectionHeader>
    </Modal.Header>
    <Modal.Body>
      {leagueLoading ? <BDDLoader />
        : leagueError ? <BDDApiError error={leagueError} />
          : <>
            <Styles>
              <div className='select-row'>
                <BDDSelect
                  name='league'
                  isSearchable
                  options={leagueOptions}
                  value={filters.league}
                  onChange={(n, v) => setFilters({ ...filters, [n]: v })}
                />
              </div>
              <div className='select-row'>
                <BDDSelect
                  name='season'
                  options={seasonOptions}
                  value={filters.season}
                  onChange={(n, v) => setFilters({ ...filters, [n]: v })}
                />
              </div>
              <div className='select-row'>
                <BDDSelect
                  name='teamid'
                  isSearchable
                  options={teamOptions}
                  value={filters.teamid}
                  onChange={(n, v) => setFilters({ ...filters, [n]: v })}
                  isLoading={teamLoading}
                />
              </div>
              <div className='select-row'>
                <BDDSelect
                  name='games'
                  isMulti
                  isSearchable
                  options={gameOptions}
                  value={games.map(g => g.value)}
                  onChange={(n, v) => setGames(gameOptions.filter(g => v.includes(g.value)))}
                  isLoading={gamesLoading}
                />
              </div>
            </Styles>
          </>}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={() => handleSubmit(games)}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
}

const GamesList = ({ games }) => <ul>{games.map(g => <li key={g.value}>
  {g.label}
</li>)}
</ul>

const SelectGames = ({ player, field }) => {
  const { name, value, error, onChange } = field
  const [showModal, old] = useState(false)
  const { renderModal, setShowModal } = useModal({
    title: 'Select Games',
    size: 'md'
  })

  return <>
    {!!value && !!value.length ? <HoverInfo
      title='Games' content={<GamesList games={value} />}
    >
      <a style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
        {value.length} Game{value.length > 1 ? 's' : ''}
      </a>
    </HoverInfo> : <em>No games selected</em>}
    {' '}
    <Button style={{ float: 'right' }} variant="outline-dark" size="sm" onClick={() => setShowModal(true)}>
      Select Games
    </Button>
    {renderModal(<ReportGamesSelector 
      handleSubmit={games => {
        onChange({ target: { name: 'games', value: games } })
        setShowModal(false)
      }}
      handleCancel={() => setShowModal(false)}
      defaultLeagueId={player.currentleagueid}
      defaultTeamId={player.currentteamid}
    />)}
    {/* <SelectGamesModal
      show={showModal}
      handleClose={() => old(false)}
      handleSubmit={games => {
        onChange({ target: { name: 'games', value: games } })
        old(false)
      }}
      defaultLeagueId={player.currentleagueid}
      defaultTeamId={player.currentteamid}
    /> */}
  </>
}

export default function PlayerReportHeader({ player, position }) {
  const hToHand = h => h === 'L' ? 'Left' : 'Right'
  return <>
    <Row>
      <Col>
        <SubSectionHeader style={{ textAlign: 'left' }}>{player.firstname} {player.lastname}</SubSectionHeader>
        <div>{player.position} / {hToHand(player.handedness)} {player.posFDG === 'G' ? 'Catch' : 'Shot'}</div>
        <div>{player.heightStr} / {player.weight}lbs - {formatDate(player.birthdate)}</div>
        <div>Team: {player.currentteam} ({player.currentleagueabbr})</div>
        <div>{player.isdrafteligible ? 'Draft Eligible' : ''}</div>
      </Col>
      <Col>
        <SubSectionHeader style={{ textAlign: 'left' }}>Viewing Specific Settings:</SubSectionHeader>
        <Field noTitle name="positionDetail" title='' placeholder="Position for viewings" position={position} component={SelectInput} />
        <div style={{ marginTop: "5px" }}>
          <Field name="games" player={player} component={SelectGames} />
        </div>
      </Col>
    </Row>
  </>
}