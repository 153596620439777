import { CheckboxGroupFilter } from '.';
import { shotTypeOptions } from '../helpers';

export const ShotTypeFilter = ({ readonly }) => {
  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="shot_type"
      label={'Shot Type'}
      options={shotTypeOptions}
      readonly={readonly}
    />
  );
};
