import { gql } from '@apollo/client';

const LineupsV3Fragment = gql`
  fragment lineupsV3Fragment on LineupsV3 {
    id
  }
`;

const LineupPlayersV3Fragment = gql`
  fragment lineupPlayersV3Fragment on LineupPlayersV3 {
    lineupId
    lineupSpot
    lineNum
    position
    leaguePlayerId
    isF
  }
`;

const LineupForGameFragment = gql`
  fragment lineupForGameFragment on LineupsV3 {
    gameUid
    teamSlug
    lineupType
    lineupPlayers {
      lineupSpot
      lineNum
      position
      isF
      slPlayer {
        slPlayerMaster {
          bddPlayerSlug {
            slug
            firstname
            lastname
          }
        }
        playerId
        leaguePlayerId
        slUid
      }
    }
  }
`;

export const GET_PLAYER_LINEUP_SPOTS = gql`
  query GetPlayerLineupSpots(
    $slUid: ID!
    $leagueSlug: String!
    $gameType: String!
    $season: Int
  ) {
    slPlayerMaster(slUid: $slUid) {
      slUid
      firstname
      lastname
      lineupSpotsV3(
        leagueSlug: $leagueSlug
        gameType: $gameType
        season: $season
      ) {
        ...lineupPlayersV3Fragment
        lineup {
          id
          game {
            id
            gameDate
            homeTeamSlug
            homeScore
            awayTeamSlug
            awayScore
          }
        }
        linemates {
          ...lineupPlayersV3Fragment
          slPlayer {
            slUid
            firstname
            lastname
            jerseynumber
          }
        }
      }
    }
  }
  ${LineupPlayersV3Fragment}
`;

export const GET_LINEUPS_FOR_GAME = gql`
  query GetLineupsForGame($slGameUid: ID!) {
    lineupsForGame(slGameUid: $slGameUid) {
      league
      homeTeam {
        slug
        longname
        shorthand
      }
      awayTeam {
        slug
        longname
        shorthand
      }
      gameDate
      lineups {
        ...lineupForGameFragment
      }
    }
  }
  ${LineupForGameFragment}
`;

export const CREATE_UPDATE_AUDITED_LINEUP = gql`
  mutation CreateOrUpdateAuditedLinup($input: AuditedLineupInputV2!) {
    createUpdateAuditedLineupV2(input: $input) {
      lineup {
        ...lineupForGameFragment
      }
    }
  }
  ${LineupForGameFragment}
`;

export const GET_LINEUP_STATS = gql`
  query GetLineupStats(
    $teamSlug: ID!
    $season: Int!
    $onlyTeamPlayers: Boolean
  ) {
    lineupStatsV3(
      teamSlug: $teamSlug
      season: $season
      onlyTeamPlayers: $onlyTeamPlayers
    ) {
      stats {
        lineNum
        position
        isF
        players {
          leaguePlayerId
          count
          percentage
        }
      }
      players {
        leaguePlayerId
        firstname
        lastname
        slPlayerMaster {
          bddPlayerSlug {
            slug
          }
        }
      }
      commonLineup {
        leaguePlayerId
        lineNum
        position
        isF
      }
    }
  }
`;

export const GET_COMMON_LINEUP = gql`
  query GetCommonLineup(
    $teamSlug: ID!
    $season: Int!
    $onlyTeamPlayers: Boolean
  ) {
    commonLineupV3(
      teamSlug: $teamSlug
      season: $season
      onlyTeamPlayers: $onlyTeamPlayers
    ) {
      isF
      lineNum
      position
      leaguePlayerId
      slPlayer {
        firstname
        lastname
        slPlayerMaster {
          bddPlayerSlug {
            slug
          }
        }
      }
    }
  }
`;
