import { useQuery } from '@apollo/client';
import { buildBddPlayerQuery } from 'apollo/queries/players.queries';
import TargetedPlayers from 'components/DashboardV2/TargetedPlayers/TargetedPlayers';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GameReport } from 'components/PlayerDevelopment/components/feed/GameReports';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { useSLStatContext } from 'components/SLStats/hooks';
import { ReportsByGame } from 'components/ScoutingV2/components/reports/ReportsByGame';
import useReports from 'components/ScoutingV2/components/reports/useReports';
import { LinkButton } from 'components/bdd/Button';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Divider } from 'components/bdd/Divider';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { NULL_FILTER_VALUE } from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { ByGameStatTable, HeadlineStatTable, PlayerHighlightStats } from '.';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { useOnScreenRef } from 'components/bdd/hooks';
import { BDDLoader } from 'components/bdd/bddloader';
import { theme } from 'constants';
import { getPayloadDateRangeFormat } from '../helpers';
import { usePlayersFromLists } from 'components/Lists/hooks';
import { NoteSummaryBody } from '../NoteSummaryBody';
import { ListRecencyView } from 'components/Lists/Builder/components/ListRecencyView';

const DEFAULT_INDEX = 'league_slug,season,game_type,slug,Player,player_id,position';

const BY_GAME_INDEX = `game_date,game_uid,team_slug,opp_team_shorthand,${DEFAULT_INDEX}`;

const RINKNET_FIELDS = `
  id
  realId
  firstname
  lastname
  birthdate age
  currentteam
  currentteamnhlid
  currentleagueabbr
  currentleagueid
  position
  heightStr
  weight
  height 
  heightFt
  posFDG
  eliteprospectsid
  handedness
  agentname
  collegecommitmentteam
`;

const BDD_FIELDS = `
  slug
  rinknetPlayer {
    ${RINKNET_FIELDS}
  }`;

const tabOptions = [
  {
    label: 'Player Overview',
    value: 'players',
  },
  {
    label: 'Game Reports',
    value: 'reports',
  },
  {
    label: 'Notes',
    value: 'notes',
  },
  {
    label: 'Recent Stats',
    value: 'stats',
  },
  {
    label: 'Current Season Stats',
    value: 'stats-season',
  },
];

export const TargetedPlayersSummaryBody = ({ payload }) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    options: tabOptions,
    variant: 'link',
    initialSelectedValue: 'players',
  });

  return (
    <Container padding={2} height="100%" overflow="auto">
      <Column gap={3}>
        <Typography variant="subtitle2">
          {payload.players.length} targeted players {getPayloadDateRangeFormat(payload)}
        </Typography>
        <Container>{buttonGroup}</Container>
        {selectedValue == 'players' && (
          <ListRecencyView
            targetLists={payload.targetLists}
            bddPlayerSlugs={payload.players.map(p => p['bdd_player_slug'])}
            rinknetIds={payload.players.map(p => p['rinknet_id'])}
            startDate={payload.start_date}
            endDate={payload.end_date}
            rinknetGameIds={payload.rinknet_game_ids}
          />
          // <PlayerList payload={payload} />
        )}
        {selectedValue == 'notes' && (
          <NoteSummaryBody noteIds={payload?.['note_ids'] || []} />
        )}
        {selectedValue == 'stats' && (
          <TargetedPlayers
            slugs={payload['players'].map((p) => p['bdd_player_slug'])}
            ignoreStoredFilters
            defaultFilters={{
              index: DEFAULT_INDEX,
              dateRangeMode: 'CUSTOM',
              strict_start_date: payload['start_date'],
              strict_end_date: payload['end_date'],
            }}
          />
        )}
        {selectedValue == 'stats-season' && (
          <TargetedPlayers
            slugs={payload['players'].map((p) => p['bdd_player_slug'])}
            ignoreStoredFilters
            defaultFilters={{
              index: DEFAULT_INDEX,
              dateRangeMode: 'CURRENT_SEASON',
            }}
          />
        )}
        {selectedValue == 'reports' && (
          <ReportsByGame
            hideFilters
            defaultFilters={{
              startDate: payload['start_date'],
              endDate: payload['end_date'],
              rinknetIds: payload.players.map((p) => p.rinknet_id),
            }}
          />
        )}
      </Column>
    </Container>
  );
};
