import React, { useMemo, useState } from "react"
import { gql, useQuery, useMutation } from '@apollo/client'
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import { Col, Button, Spinner, Row } from "react-bootstrap"
import { Row as BDDRow, Column, Container } from "components/bdd/Layout"
import { Persist } from 'formik-persist'
import * as Yup from "yup"

import BDDSelect from '../bdd/bddselect'
import { useUser } from "../../helpers/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BDDErrorColor, ItemGrid } from "../bdd";
import styled from "styled-components";
import { positionToCategories, SelectInput } from "./playerformhelpers";
import PlayerReportHeader from "./playerreportheader";
import BDDFormError from "../bdd/bddformerror";
import { BDDLoader } from "../bdd/bddloader";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CoreReportForm from "./corereportform";
import { useModal } from "components/bdd/Modal";
import { Typography } from "components/bdd/Typography";


const Styles = styled.div`
    label {
        margin-top: 10px;
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 0.9em;
    }
    
    textarea {
        margin-top: 5px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
`

export const GET_RINKNET_PLAYER = gql`query GetRinknetPlayer($rinknetId: ID!) {
    rinknetPlayer(id: $rinknetId) {
        id
        firstname
        lastname
        birthdate
        currentteam
        currentteamid
        currentleagueabbr
        currentleagueid
        position
        posFDG
        handedness
        heightStr
        weight
        isdrafteligible
        eliteprospectsid
    }
}`
const GET_USER = gql`query GetUser($id: ID!) {
    users(id: $id) {
        id
        username
        rinknetUser {
            userid
            scouttype       
        }
    }
}`

const CREATE_PLAYER_REPORT = gql`
    mutation createReport($input:CreateBDDPlayerScoutingReportInput!) {
    createBddPlayerScoutingReport(input: $input) {
        bddPlayerScoutingReport {
            id
            userId
            rinknetUser {
            email
                scouttype
            }
            games {
                edges {
                    node {
                        id
                        hometeam {
                            longname
                        }
                    }
                }
            }
            ratings
            skillcategoryratings
            notes
        }
    }
}`

export const createPlayerReportUpdateCache = (cache, { data: { createBddPlayerScoutingReport } }) => {
    cache.modify({
        fields: {
            bddPlayerScoutingReports(existing=[]) {
                return existing.concat(createBddPlayerScoutingReport.bddPlayerScoutingReport)
            },
            allReports(existing=[]) {
                return {
                  ...existing,
                  data: [createBddPlayerScoutingReport.bddPlayerScoutingReport].concat(existing.data)
                }
            }
        }
    });
}

export default function PlayerReportForm({ 
    rinknetId, 
    position: positionArg, 
    reportType: reportTypeArg, 
    onCompleted 
}) {
    const { user, isUserAdmin, userHasPermission } = useUser();
    const storageKey = `${rinknetId}-playerreport`
    const [completed, setCompleted] = useState(false)
    const { data, loading } = useQuery(GET_RINKNET_PLAYER, { variables: { rinknetId } })
    const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, { variables: { id: user?.id }})
    const [createPlayerReport, { data: mutationData, loading: mutationLoading }] = useMutation(CREATE_PLAYER_REPORT, {
        update: createPlayerReportUpdateCache, 
        onCompleted: () => {
            setCompleted(true)
            if (onCompleted) onCompleted(storageKey)
        }
    })
    const reportType = !!reportTypeArg ? reportTypeArg : undefined


    const position = positionArg ? positionArg : data.rinknetPlayer.posFDG.toLocaleLowerCase()
    const schema = useMemo(() => {
        const schemaDef = {
            reportType: Yup.string().required('Report type is a required field'),
            positionDetail: Yup.string().nullable(),
            games: Yup.array().of(Yup.object()),
            gameScore: Yup.number().nullable(),
            currentRole: Yup.number().nullable(),
            playerType: Yup.string().nullable(),
            proProjection: Yup.number().nullable().required('Pro Projection is a required field'),
            draftRound: Yup.string().ensure().when('reportType', {
                is: (reportType) => reportType === 'draft',
                then: Yup.string().required()
            }),
            // draftRound: Yup.string().nullable().required('Draft Round is a required field'),
            powerPlay: Yup.boolean(),
            penaltyKill: Yup.boolean(),
            Overall_Notes: Yup.string().required('Overall notes are required')
        }
        // if (reportType != 'draft') {
        //     delete schemaDef.draftRound
        // }
        positionToCategories[position].forEach(cat => {
            schemaDef[cat] = Yup.number().nullable()
            schemaDef[`${cat}_Notes`] = Yup.string()
        })
        return Yup.object(schemaDef);
    }, [loading])

    const actuallySubmit = values => {
      console.log(values)
      const ratings = {
          gameScore: values.gameScore,
          currentRole: values.currentRole,
          playerType: values.playerType,
          proProjection: values.proProjection,
          draftRound: values.draftRound,
          powerPlay: values.powerPlay,
          penaltyKill: values.penaltyKill
      }
      const skillcategoryratings = {}
      const notes = { Overall: values['Overall_Notes'] }
      positionToCategories[position].forEach(cat => {
          skillcategoryratings[cat] = values[cat]
          notes[cat] = values[`${cat}_Notes`]
      })
      const entry = {
          userId: user?.id,
          reportType: values.reportType,
          rinknetUserId: rinknetUserId,
          rinknetId: values.rinknetId,
          position: values.position,
          positionDetail: values.positionDetail,
          games: values.games.map(g => g.value),
          ratings: JSON.stringify(ratings),
          skillcategoryratings: JSON.stringify(skillcategoryratings),
          notes: JSON.stringify(notes)
      }
      createPlayerReport({ variables: { input: entry } })
  }

    const { renderModal, showModal, setShowModal } = useModal({
      size: 'sm',
      title: <Typography variant='h5'>Confirm</Typography>,
      useNewModal: false
    })

    if (userLoading) return <BDDLoader/>
    if (userError) return "Error"
    const rinknetUserId = userData.users[0].rinknetUser ? userData.users[0].rinknetUser.userid : null
    if (!rinknetUserId && !isUserAdmin()) {
        return <>
        <br></br>
        <div style={{borderTop: `1px solid ${BDDErrorColor}`}}>
            <h6>Hmmmmm... we can't seem to find a Rinknet Scouting profile for you</h6>
            <p>If this is a mistake, and you have created reports in rinknet before, please contact BDD administrators.</p>
        </div>
        </>
    }

    const initialValues = {
        rinknetId: rinknetId,
        reportType: reportType,
        position: position,
        positionDetail: null,
        games: [],
        gameScore: null,
        currentRole: null,
        playerType: null,
        proProjection: null,
        draftRound: '',
        powerPlay: false,
        penaltyKill: false
    }
    positionToCategories[position].forEach(cat => {
        initialValues[cat] = null
        initialValues[`${cat}_Notes`] = ''
    })


    const handleSubmit = (values) => {
      setShowModal(true)
    }


    return <Styles>
    <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={schema}
    > 
        {(props) => {
        console.log(props.errors)
        return <Form>
            <PlayerReportHeader player={data.rinknetPlayer} position={position} />
            <hr></hr>
            <CoreReportForm
                errors={props.errors}
                position={position}
                reportType={props.values.reportType}
            />
            {/* <Row>
                <Col>
                    <Field name="currentRole" title='Current Role' position={position} component={SelectInput} /> 
                    <Field name="proProjection" title='Pro Projection' position={position} component={SelectInput} /> 
                    {position !== 'g' ? <div>
                        <label>Power Play:</label>{' '}
                        <Field type='checkbox' name='powerPlay'/>
                    </div> : null }
                </Col>
                <Col>
                    <Field name="playerType" title='Player Type' position={position} component={SelectInput} /> 
                    <span style={props.values.reportType === 'draft' || props.values.reportType === 'underage' ? {} : { visibility: 'hidden' }}>
                        <Field name="draftRound" title='Draft Round' position={position} reportType={props.values.reportType} component={SelectInput} />
                    </span>
                    {position !== 'g' ? <div>
                        <label>Penalty Kill:</label>{' '}
                        <Field type='checkbox' name='penaltyKill'/> 
                    </div> : null }
                </Col>
            </Row>
            <hr></hr>
            <ItemGrid
                items={positionToCategories[position].map(cat => ({cat: cat}))}
                numCols={2}
                keyField={'cat'}
                render={c => <>
                    <Field 
                        name={c.cat} 
                        title={c.cat}
                        position={position}
                        component={SelectInput} 
                    />
                    <Field
                        as='textarea'
                        rows={3}
                        name={`${c.cat}_Notes`}
                    />
                </>}
            />
            <Row>
                <Col>
                    <label>Overall Notes</label>
                    <Field
                        as='textarea'
                        rows={3}
                        name={`Overall_Notes`}
                        style={!!props.errors['Overall_Notes'] ? { boxShadow: `0px 0px 5px ${BDDErrorColor}` } : null}
                    />
                    <BDDFormError>
                        <ErrorMessage name={'Overall_Notes'}/>
                    </BDDFormError>
                </Col>
            </Row> */}
            {!completed && <Persist name={storageKey} debounce={1000} /> }
            
            <div style={{float: 'right'}}>
                <Button variant="primary" type="submit">
                    {mutationLoading ?
                        <><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Submitting
                    </> 
                    : completed ? <>
                        <FontAwesomeIcon icon={faCheck}/>
                        {' '}Submitted
                    </> : <>Submit</>}
                </Button>
            </div>
            {showModal && renderModal(
              <Container>
                <Column gap={2}>
                  <Typography variant='body1'>Please confirm you're ready to submit</Typography>
                  <BDDRow justifyContent='end' gap={8}>
                    <Button variant='secondary' onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button variant='primary' onClick={() => {
                      actuallySubmit(props.values);
                      setShowModal(false);
                    }}>Submit</Button>
                  </BDDRow>
                </Column>  
              </Container>
            )}
        </Form> }}
    </Formik>
    </Styles>
}
