import React, { useState } from "react"
import { useMutation } from '@apollo/client';
import { useFormik } from "formik";
import { Col, Button, Form, Spinner, Row } from "react-bootstrap"
import * as Yup from "yup";

import BDDSelect from '../../bdd/bddselect'
import { CREATE_UPDATE_STAT_REPORTS_V2 } from "../../../apollo/queries/statsv2.queries";
import { useUser } from "helpers/user";
import { NULL_FILTER_VALUE } from "../../../helpers/filters";
import { StatTargets } from "../../../constants/stats";
import StatsSelect from "./statsselect"
import styled from "styled-components";
import { BDDTextArea } from "components/bdd";
import { mapStatsReportsToStatSelect, mapStatsSnakeToCamel } from "./helpers";
import { toastBddApiError } from "components/bdd/bddtoasts";

const schema = Yup.object({
  name: Yup.string().required(),
  slug: Yup.string().required(),
  description: Yup.string(),
  target: Yup.string(),
  isBddReport: Yup.boolean(),
  isPublic: Yup.boolean(),
  priorityRank: Yup.number(),
});

const Styles = styled.div`
    .row {
        margin-bottom: 5px;
    }
    label {
        font-size: 0.8em;
    }
`

export const createStatReportUpdateCache = (cache, { data: { createUpdateStatReportV2 } }) => {
  cache.modify({
    fields: {
      statReportsV2(existing = [], { readField }) {
        const incoming = createUpdateStatReportV2.statReportV2
        return existing.filter(e => readField('slug', e) !== incoming.slug).concat(incoming)
      }
    }
  });
}


export default function CreateUpdateStatReport({
  isUpdate=false, // if true, this is an update form, otherwise its a create form
  defaultReport,
  defaultStats: defaultStatsArg,
  onCompleted,
  forceTarget,
  noHeader
}) {
  const { user, isUserAdmin } = useUser();
  
  var defaultStats = !!defaultStatsArg ? defaultStatsArg : []
  if (!!defaultStats?.[0]?.statSlug) {
    defaultStats = mapStatsReportsToStatSelect(defaultStats)
  }
  defaultStats = mapStatsSnakeToCamel(defaultStats);

  const [stats, setStats] = useState(defaultStats)
  const [completed, setCompleted] = useState(false)
  const [createReport, { data, loading }] = useMutation(CREATE_UPDATE_STAT_REPORTS_V2, {
    update: createStatReportUpdateCache,
    onCompleted: ({createUpdateStatReportV2: { statReportV2 }}) => {
      setCompleted(true)
      if (onCompleted) onCompleted(statReportV2)
    },
    onError: toastBddApiError
  })

  const emptyReport = {
    name: '',
    slug: '',
    description: '',
    target: forceTarget ? forceTarget : 'none',
    isBddReport: false,
    isPublic: false,
    isPublicEdit: false,
    priorityRank: 0,
  }
  if (!!defaultReport) {
    var initialValues = {}
    Object.keys(emptyReport).forEach(k => initialValues[k] = defaultReport[k])
  } else {
    initialValues = emptyReport
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: values => {
      var vals = { ...values }
      if (vals.target === NULL_FILTER_VALUE) vals.target = null
      if (isUpdate) {
        if (!defaultReport.slug) throw Error(`Attempting to update report but no slug found`)
        vals.slug = defaultReport.slug
      }
      vals.userId = user?.id
      vals['stats'] = stats.filter(s => !!s.slug).map(s => ({
        slug: s.slug,
        label: !!s.label ? s.label : s.slug, // Default label is slug
        filterSetId: s.filterSetId === NULL_FILTER_VALUE ? null : s.filterSetId, // Replace null filter values with "null"
        norm: s.norm === NULL_FILTER_VALUE ? null : s.norm
      }))

      createReport({ variables: { input: vals, updateOnly: isUpdate, createOnly: !isUpdate } })
    }
  })

  var targetOptions = []
  targetOptions.push({ label: 'None', value: NULL_FILTER_VALUE })
  targetOptions.push(...StatTargets.map(t => ({
    label: t[0].toLocaleUpperCase() + t.substring(1),
    value: t
  })))

  return <Styles>
    <Form onSubmit={formik.handleSubmit}>
      {noHeader ? null : <div style={{ textAlign: 'center' }}><b>Create Stat Report</b></div>}
      <Row>
        <Col md={12}>
          <Row>
            <Col>
              <Form.Control
                type="text"
                name="name"
                placeholder='Report Name'
                value={formik.values.name}
                onChange={ev => {
                  var slug = ev.target.value.replace(/\W/g, '')
                  if (slug.length > 0) { slug = slug[0].toLowerCase() + slug.slice(1) }
                  formik.values.slug = slug
                  formik.handleChange(ev)
                }}
                isInvalid={!!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                name="slug"
                placeholder='Report Slug'
                disabled={isUpdate}
                value={formik.values.slug}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.slug}
              />
              <em><small>
                A unique text identifier. You can use the default.
              </small></em>
              <Form.Control.Feedback type="invalid">
                {formik.errors.slug}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <BDDTextArea
                name="description"
                placeholder='Description'
                value={formik.values.description}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Col>
          </Row>
          {forceTarget ? null
            : <Row>
              <Col>
                <BDDSelect
                  name="target"
                  placeholder='Stat target'
                  value={formik.values.target}
                  options={targetOptions}
                  onChange={(name, val) => {
                    formik.handleChange({ target: { name: name, value: val } })
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.target}
                </Form.Control.Feedback>
              </Col>
            </Row>}
          <Row>
            {isUserAdmin() ? <>
              <Col style={{ textAlign: 'right' }}>
                <label htmlFor='isBddReport'>BDD Report</label>
              </Col>
              <Col>
                <Form.Check
                  style={{ float: 'left' }}
                  name="isBddReport"
                  checked={formik.values.isBddReport}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <label htmlFor='priorityRank'>Priority Rank</label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  name="priorityRank"
                  value={formik.values.priorityRank}
                  onChange={formik.handleChange}
                  style={{ width: '60px', height: '30px', float: 'left' }}
                />
              </Col>
            </> : null}
            <Col style={{ textAlign: 'right' }}>
              <label htmlFor='isPublic'>Public</label>
            </Col>
            <Col>
              <Form.Check
                name="isPublic"
                checked={formik.values.isPublic}
                onChange={formik.handleChange}
                style={{ float: 'left' }}
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <label htmlFor='isPublicEdit'>Public Edit</label>
            </Col>
            <Col>
              <Form.Check
                name="isPublicEdit"
                checked={formik.values.isPublicEdit}
                onChange={formik.handleChange}
                style={{ float: 'left' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <div style={{ marginBottom: '50px', padding: '0 5%' }}>
            <StatsSelect
              stats={stats}
              setStats={setStats}
              defaultTarget={forceTarget}
            />
          </div>
        </Col>
      </Row>
      <center>
        <Button variant="outline-primary" type="submit">
          {loading ?
            <><Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> Submitting
            </>
            : completed ?
              <>Submitted</>
              : <>Submit</>}
        </Button>
      </center>
    </Form>
  </Styles>
}
