import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import styled from "styled-components";


export const LabelStyles = styled.label(({ dotted }) => ({  
  ...theme.typography.label,
  marginBottom: 0,
  textDecoration: dotted ? 'underline dotted' : 'none',
  cursor: dotted ? 'pointer' : 'auto',
}))

export const Label = ({ children, dotted, hover }) => {
  return (
    <LabelStyles dotted={dotted}>
      <TooltipSpan content={hover}>
        {children}
      </TooltipSpan>
    </LabelStyles>
  );
}