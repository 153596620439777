import { useQuery } from '@apollo/client';
import { GET_PLAYER_TEAMMATES_OPPONENTS_STATS } from 'apollo/queries/teammates.queries';
import { HoverInteractive } from 'components/bdd';
import BDDApiError from 'components/bdd/bddapierror';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Column, Container, Row } from 'components/bdd/Layout';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import { EventVideoController } from 'components/bdd/Video';
import { getLSGSourceFilters } from 'components/bdd/Video/Filtering';
import { TooltipSpan } from 'components/reports';
import { StatVideoLinks } from 'components/SLStats';
import { theme } from 'constants';
import { roundToX } from 'helpers/data';
import { formatBddStat } from 'helpers/format';
import { formatMinutes } from 'helpers/helpers';
import { buildPlayerRouteV2, buildTeamRoute } from 'helpers/routing';
import { sortNumeric, stddevColor } from 'helpers/tables';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useLSGTOptions from '../hooks/useLSGTOptions';
import PlayerHover from '../Hover/playerhover';
import OpposingToiByGame from './OpposingToiByGame';
import { VideoClipFilters } from 'components/bdd/Video/components';
import {
  OnIcePlayerFilter,
  PeriodFilter,
  StrengthFilter,
} from 'components/bdd/Video/Filtering/components';

const Styles = styled.div({
  table: {
    td: {
      textAlign: 'center',
    },
    'tr:nth-child(even)': {
      backgroundColor: '#fafafa',
    },
  },
});

const TableContainer = styled.div({
  ...theme.cards.medium,
  border: '1px solid #ddd',
  padding: '10px',
  margin: '10px',
  width: '100%',
});

export const deploymentStats = {
  baseStats: ['toi', 'gamesPlayed', 'ozFaceoffStartPercentage'],
  xpProductionStats: ['iXG', 'iXA', 'XPGF', 'XPGA', 'XPGPercentage', 'Act2Expected'],
  productionStats: [
    'goals',
    'assists',
    'points',
    'goalsFor',
    'goalsAgainst',
    'goalPercentage',
    'shotAttempts',
    'shotsOnGoal',
  ],
  zoneStats: [
    'ozFaceOffsWOI',
    'dzFaceOffsWOI',
    // 'percentageOZTime', // Once player pairs is re-run, these will work.
    // 'ozToi', // old bug ozTOi == dzToi
    // 'dzToi'
  ],
  puckPlayStats: [
    'PDPs',
    'percentagePDP',
    'nzTurnoverRate',
    'dzTurnoverRate',
    'nzdzTurnoverRate',
    'ozToi',
    'percentageOZTime',
  ],
  involvementStats: [
    'LPRs',
    'percentagePDP',
    'percentageSCGP',
    'percentOZPossession',
    'percentageDPlays',
  ],
};
// stats we would add if we didn't miscalculate them in the pipeline:
// LPRs, %PDP, %OZTime, ozFaceoffStart, dzFaceoffStart, ozs%, shots
const statGroups = [
  {
    id: 'expected-prod',
    stats: deploymentStats.baseStats.concat(deploymentStats.xpProductionStats),
  },
  {
    id: 'production',
    stats: deploymentStats.baseStats.concat(deploymentStats.productionStats),
  },
  {
    id: 'allproduction',
    stats: deploymentStats.baseStats
      .concat(deploymentStats.productionStats)
      .concat(deploymentStats.xpProductionStats),
  },
  {
    id: 'puckplay',
    stats: deploymentStats.puckPlayStats,
  },
  {
    id: 'involvement',
    stats: deploymentStats.involvementStats,
  },
  {
    id: 'zones',
    stats: deploymentStats.zoneStats,
  },
  {
    id: 'all',
    stats: Array.from(
      new Set(
        deploymentStats.baseStats
          .concat(deploymentStats.productionStats)
          .concat(deploymentStats.xpProductionStats)
          .concat(deploymentStats.puckPlayStats)
          .concat(deploymentStats.involvementStats)
          .concat(['Act2Expected'])
      )
    ),
  },
];

export default function TeammatesOpponents({ slug, leagueSlug }) {
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'allproduction',
    options: [
      { value: 'all', label: 'All' },
      { value: 'production', label: 'Production' },
      { value: 'expected-prod', label: 'Exp. Production' },
      { value: 'allproduction', label: 'All Production' },
      { value: 'involvement', label: 'Involvement' },
      { value: 'puckplay', label: 'Puck Play' },
      { value: 'zones', label: 'By Zone' },
    ],
  });

  const { toggled: per20, toggleComponent } = useToggle({
    initialToggled: true,
    label: 'Per 20',
  });

  const {
    season,
    game_type: gameType,
    loading: loadingLSGT,
    selectLSGT,
  } = useLSGTOptions({
    slug: slug,
    leagueSlug,
    variant: 'outlined',
    combineTeamSlugs: true,
  });

  return (
    <Container padding={2}>
      <Row justifyContent="space-between">
        <Container>{selectLSGT}</Container>
        <Container>{buttonGroup}</Container>
      </Row>
      <Row>{toggleComponent}</Row>
      {loadingLSGT ? (
        <BDDLoader variant="squares" />
      ) : (
        <Column>
          <Container>
            <BDDErrorBoundary>
              <TeammatesOpponentsContent
                slug={slug}
                leagueSlug={leagueSlug}
                seasons={[season]}
                gameType={gameType}
                per20={per20}
                stats={statGroups.find((sg) => sg.id === selectedValue).stats}
              />
            </BDDErrorBoundary>
          </Container>
          <Container>
            <BDDErrorBoundary>
              <OpposingToiByGame
                slug={slug}
                leagueSlug={leagueSlug}
                season={season}
                gameType={gameType}
              />
            </BDDErrorBoundary>
          </Container>
        </Column>
      )}
    </Container>
  );
}

export const TeammatesOpponentsContent = ({
  slug,
  leagueSlug,
  seasons,
  gameType,
  startDate,
  endDate,
  gameUid,
  otherPosition,
  minToi,
  per20 = false,
  minToiForGp = 5,
  stats = statGroups.find((s) => s.id === 'all').stats,
}) => {
  const { data, loading, error } = useQuery(GET_PLAYER_TEAMMATES_OPPONENTS_STATS, {
    variables: {
      slug,
      leagueSlug,
      seasons,
      gameType,
      startDate,
      endDate,
      gameUid,
      otherPosition,
      minToi,
      minToiForGp,
      numPlayersPerSplit: 10,
      per20,
    },
    skip: !slug,
  });

  const splitRows = [
    [{ isTeammate: true, otherPosition: 'f' }],
    [{ isTeammate: true, otherPosition: 'd' }],
    [
      { isTeammate: false, otherPosition: 'f' },
      { isTeammate: false, otherPosition: 'd' },
    ],
  ];

  const bddPlayer = data?.playerTeammatesOpponents?.bddPlayer;
  const metastats = data?.playerTeammatesOpponents?.metastats;
  const statToMetastats = metastats?.reduce((acc, curr) => {
    acc[curr.stat] = curr;
    return acc;
  }, {});
  const highlightCallback = (statDef, value, row) => {
    if (!statDef) {
      console.warn('Stat definition undefined');
      return null;
    }
    if (['toi', 'gamesPlayed'].includes(statDef.slug)) return null;
    if (!data) return null;
    if (!metastats) return null;
    const statMetastats = statToMetastats?.[statDef.slug];
    if (!statMetastats) return null;
    if (value !== 0 && !value) return null;
    if (!per20 && statDef.countingStat) {
      // if not per 20, we need to manually normalize (metastats will always be per20)
      value = value / (row.toi / 20);
    }
    return stddevColor(value, statMetastats, statDef.negative);
  };

  const getVideoFilters = (row) => {
    const slPlayer = bddPlayer?.slPlayerMaster?.slPlayers?.find(
      (sp) => sp.leagueSlug == leagueSlug
    );

    const sourceFilters = getLSGSourceFilters({
      leagueSlug,
      season: seasons[0],
      gameType,
    });

    const otherSlPlayer = row.slPlayerMaster.slPlayers.find(
      (sp) => sp.leagueSlug == leagueSlug
    );

    const col =
      otherSlPlayer.primaryposition == 'F'
        ? 'team_forwards_on_ice'
        : 'team_defencemen_on_ice';

    const secondaryPlayerFilters = {
      [col]: {
        col,
        cond: 'array_includes_all',
        val: [otherSlPlayer.playerId],
      },
    };

    // Override player current team slug
    const eventFilters = {
      team_slug: {
        col: 'team_slug',
        val: row['team_slug'],
      },
    };

    return {
      slPlayer,
      eventFilters,
      sourceFilters,
      secondaryPlayerFilters,
    };
  };

  const cellHoverCallback = (statDef, value, row, forceClose) => {
    const { slPlayer, eventFilters, sourceFilters, secondaryPlayerFilters } =
      getVideoFilters(row);

    const clipFilters = (
      <Container maxHeight={400} overflow="auto">
        <VideoClipFilters
          renderPlayerFilters={(bddPlayer) => (
            <>
              <OnIcePlayerFilter slug={bddPlayer.slug} />
              <OnIcePlayerFilter slug={bddPlayer.slug} isTeammate={false} />
              <PeriodFilter />
              <StrengthFilter />
            </>
          )}
        />
      </Container>
    );

    return (
      <StatVideoLinks
        clipFilters={clipFilters}
        sourceFilters={sourceFilters}
        slPlayer={slPlayer}
        statDefinitionSlug={statDef.slug}
        secondaryPlayerFilters={secondaryPlayerFilters}
        eventFilters={eventFilters}
        onClick={forceClose}
      />
    );
  };

  const statDefs = data?.playerTeammatesOpponents?.statDefinitions;
  const getColumns = (isTeammate, otherPosition) => {
    const statsToShow = isTeammate ? stats : ['toi', 'gamesPlayed'];
    var ret = [
      {
        id: 'player',
        Header: (
          <Typography variant="body1" style={{ minWidth: '110px' }}>
            {isTeammate
              ? otherPosition === 'f'
                ? 'Teammate Forwards'
                : 'Teammate Defensemen'
              : otherPosition === 'f'
              ? 'Opposing Forwards'
              : 'Opposing Defensemen'}
          </Typography>
        ),
        accessor: (d) => {
          return (
            <Typography variant="body2">
              <PlayerHover playerSlug={d.slug}>
                <Link to={buildPlayerRouteV2(d.slug)} style={{ color: 'black' }}>
                  {d.firstname} {d.lastname}
                </Link>
              </PlayerHover>
            </Typography>
          );
        },
      },
    ];
    if (!isTeammate) {
      ret = ret.concat([
        {
          id: 'team_slug',
          Header: <Typography variant="body1">Team</Typography>,
          accessor: (d) => (
            <Typography variant="body2">
              <Link to={buildTeamRoute(d.otherTeamSlug)} style={{ color: 'black' }}>
                {d.otherTeamSlug?.split('.')?.[1]?.toUpperCase()}
              </Link>
            </Typography>
          ),
        },
      ]);
    }
    ret = ret.concat(
      statsToShow.map((statSlug) => {
        const statDef = statDefs.find((sd) => sd.slug === statSlug);
        return {
          id: statSlug,
          Header: (
            <Typography variant="body1" textAlign="center">
              <TooltipSpan
                content={
                  <>
                    {statSlug === 'gamesPlayed' ? (
                      <div>
                        <em>
                          Games where players played at least {minToiForGp} minutes
                          together
                        </em>
                      </div>
                    ) : (
                      statDef?.description
                    )}
                  </>
                }
              >
                {statDef?.shortName || statSlug}
                {statSlug === 'gamesPlayed' && '*'}
              </TooltipSpan>
            </Typography>
          ),
          accessor: (d) => d[statSlug],
          Cell: ({ value, row }) => {
            return (
              <div
                style={{
                  backgroundColor: highlightCallback(statDef, value, row.original),
                  padding: '0px 5px',
                }}
              >
                <Typography variant="body2" textAlign="center">
                  <HoverInteractive
                    renderContent={(forceClose) =>
                      cellHoverCallback(statDef, value, row.original, forceClose)
                    }
                  >
                    {formatBddStat(value, statDef)}
                  </HoverInteractive>
                </Typography>
              </div>
            );
          },
          sortType: sortNumeric,
        };
      })
    );
    return ret;
  };

  return (
    <BDDErrorBoundary>
      <Container>
        {loading ? (
          <BDDLoader variant="squares" />
        ) : error ? (
          <BDDApiError error={error} />
        ) : (
          <Styles>
            <Typography variant="caption">
              Showing {bddPlayer.firstname} {bddPlayer.lastname} data{' '}
              {per20 ? 'per 20 minutes' : ''} while playing with teammates. Highlighting
              based on per 20.
            </Typography>
            {splitRows.map((splits, i) => (
              <Row key={i}>
                {splits.map(({ isTeammate, otherPosition }) => {
                  const split = data.playerTeammatesOpponents.playerPairs.find(
                    (pp) =>
                      pp.isTeammate === isTeammate && pp.otherPosition === otherPosition
                  );
                  const splitData = split.pairs.map((p) => ({
                    otherTeamSlug: p.otherTeamSlug,
                    ...p.otherBddPlayer,
                    ...p.stats,
                  }));
                  return (
                    <Column
                      key={`${isTeammate}${otherPosition}`}
                      style={{ width: '100%' }}
                    >
                      <TableContainer>
                        <EventVideoController>
                          <BDDSortableTable
                            data={splitData}
                            columns={getColumns(isTeammate, otherPosition)}
                          />
                        </EventVideoController>
                      </TableContainer>
                    </Column>
                  );
                })}
              </Row>
            ))}
          </Styles>
        )}
      </Container>
    </BDDErrorBoundary>
  );
};
