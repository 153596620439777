import PlayerLinkHover from "components/PlayersV2/Hover/PlayerLinkHover";
import { HoverInteractive } from "components/bdd";
import Icon from "components/bdd/Icon";
import { Container, Row } from "components/bdd/Layout";
import BDDStatTableV2 from "components/bdd/Stats/BDDStatTableV2";
import { Typography } from "components/bdd/Typography";
import BDDSortableTable from "components/bdd/bddsortabletable";
import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import { roundToX } from "helpers/data";
import { formatMinutes } from "helpers/helpers";
import { posFDGToPosition } from "helpers/hockeyutils";
import { searchMetastatsSplitsForStat } from "helpers/stats";
import { capitalize } from "helpers/string";
import { stddevColor } from "helpers/tables";
import { Check } from "react-bootstrap-icons";

const greenColor = theme.colors.states.success;
const redColor = theme.colors.states.danger;

const DepthCheck = ({ check, skatersData, goaliesData }) => {
  const target = check.payload?.target;
  const statColumnLabels = check.payload?.columns;
  const checkedSlugs = check.payload?.checked_players;
  const data = target === 'skaters' ? skatersData : goaliesData;
  const rows = data.data.filter(d => statColumnLabels.some(col => !!d[col]));
  const splitByPos = true;
  let positions = target === 'skaters' 
    ? splitByPos ? ['F', 'D'] : ['All Skaters']
    : ['G'];
  if (check.slug === 'defensivePuckMovers') {
    positions = ['D'];
  }
  let tables = positions.map(pos => ({
    pos,
    label: pos !== 'All Skaters' ? capitalize(posFDGToPosition[pos.toLowerCase()]) : pos,
    rows: pos === 'All Skaters' ? rows : rows.filter(row => row['position'] === pos)
  }));
  tables = tables.filter(t => !!t.rows.length)

  const getIndexColumns = (label) => {
    const ret = [];
    if (checkedSlugs?.length) {
      ret.push({
        id: 'check',
        Header: '',
        accessor: d => checkedSlugs.includes(d.slug)
          ? <Icon icon={<Check/>} color={theme.colors.states.success} />
          : null
      })
    }
    ret.push({
      id: 'Player',
      Header: label,
      accessor: d => <PlayerLinkHover allowHover={false} name={d.Player} slug={d.slug}>{d.Player}</PlayerLinkHover>
    })
    return ret;
  }

  const formatLabel = (label) => label.replace('projected_', '').replace('_', ' ');
  const statColumns = [
    {
      id: 'gp',
      Header: <Typography textAlign='center'>GP</Typography>,
      accessor: d => d.projected_gamesPlayed,
    },
    {
      id: 'toi',
      Header: <Typography textAlign='center'>TOI</Typography>,
      accessor: d => formatMinutes(d.projected_toiPerGP),
    },
  ].concat(statColumnLabels?.map(c => ({
    id: c,
    Header: formatLabel(c),
    accessor: d => roundToX(d[c], 2),
    Cell: ({
      cell: {
        value,
        row: { original },
      },
    }) => {
      const row = original;
      const label = c.replace('projected_', '');
      const statDef = data?.format.stat_definitions.find(sd => sd.label === label);
      const [split, metas] = searchMetastatsSplitsForStat(data.extras.metastats, statDef, row, { targetPosition: row.position });
      if (!metas) return <Typography textAlign='center'>{value}</Typography>
      // metastats are per 20, but values are projected totals...
      const normVal = statDef.counting_stat ? row[c] / (row['projected_toi'] / 20) : row[c];
      const color = stddevColor(normVal, metas, statDef.negative);
      return <Typography textAlign='center' style={{backgroundColor: color}}>{value}</Typography>
    },
  })));

  return (
    <HoverInteractive
      content={(
        !!target && !!statColumnLabels && (
          <Container maxHeight="300px" overflowY='auto'>
            {check.slug !== 'centersByHandedness' && <Typography variant='caption'>
              Showing projected totals
            </Typography>}
            <Row gap={8} alignItems='start'>
              {tables.map(({pos, label, rows}) => (
                <Typography key={pos} variant='stat'>
                  <BDDSortableTable
                    data={rows}
                    columns={getIndexColumns(label).concat(statColumns)}
                    defaultSort={[{ id: statColumnLabels?.[0], desc: true }]}
                  />
                </Typography>
              ))}
            </Row>
          </Container>
        )
      )}
    >
      <Row gap={4}>
        {check.thresholds.map(({ value, threshold, threshold_label }, i) => (
          <Row key={i} gap={2}>
            <Typography key={i} variant='body1' color={value >= threshold ? greenColor : redColor}>
              {roundToX(value, 1)}
            </Typography>
            <div>/</div>
            <Typography variant='body2' color="#777">
              {!!threshold_label ? threshold_label : threshold}{i !== check.thresholds.length - 1 && ','}
            </Typography>
          </Row>
        ))}
      </Row>
    </HoverInteractive>
  )
}

export const ScenarioChecks = ({ scenario }) => {
  const fields = scenario?.depthChecks?.checks.map((c) => ({
    id: `check-${c.slug}`,
    label: c.name,
    tooltip: c.description,
    value: (
      <DepthCheck check={c} skatersData={scenario.depthChecks.skatersData} goaliesData={scenario.depthChecks.goaliesData} />
    )
  }));

  if (!scenario) return <Typography variant='body2'>Loading...</Typography>
  if (!scenario?.depthChecks) return null;
  return (
    <Container>
      <Row gap={32} rowGap={1} justifyContent='start' alignItems='start' flexWrap>
        {fields.map(({ id, label, tooltip, value }) => (
          <Container key={id}>
            <Typography variant='body1' color='#777'>
              <TooltipSpan content={tooltip}>
                {label}
              </TooltipSpan>
            </Typography>
            <Typography variant='body2'>{value}</Typography>
          </Container>  
        ))}
      </Row>
    </Container>
  )
}