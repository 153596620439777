import { useQuery } from '@apollo/client';
import { GET_MEDIA_FOR_EVENTS, GET_MEDIA_FOR_SHIFTS } from 'apollo/queries/video.queries';
import { VideoContext, VideoSelectContext } from 'components/bdd/Video';
import { VideoController } from 'components/bdd/Video/components';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useContext } from 'react';

export const PlaylistVideoPlayer = ({ playSelectedEvents, autoPlay = true }) => {
  const { allEvents, loading: eventsLoading } = useContext(VideoContext);
  const { selectedEvents } = useContext(VideoSelectContext) || {};
  const playlistClips = !allEvents ? [] : playSelectedEvents ? selectedEvents : allEvents;
  const { clips, eventMediaLoading, shiftMediaLoading } =
    usePlaylistMediaClips(playlistClips);

  return (
    <VideoController
      clips={clips}
      autoPlay={autoPlay}
      loading={eventMediaLoading || shiftMediaLoading}
    />
  );
};

export const usePlaylistMediaClips = (playlistClips) => {
  playlistClips.forEach((e) => {
    if (!e.shift && !e.eventId)
      console.error(`Each playlist clip must have an associated shift or event`);
  });

  const events = playlistClips.filter((c) => !!c.eventId);
  const shifts = playlistClips.filter((c) => !!c.shift);

  const { data: eventMediaData, loading: eventMediaLoading } = usePlaceholder(
    useQuery(GET_MEDIA_FOR_EVENTS, {
      skip: events.length == 0,
      variables: {
        events: [...events]
          .sort((a, b) => a.eventId - b.eventId)
          .map((e) => ({
            gameUid: e.gameUid,
            eventId: e.eventId,
            eventName: e.eventName,
          })),
      },
    })
  );

  const { data: shiftMediaData, loading: shiftMediaLoading } = usePlaceholder(
    useQuery(GET_MEDIA_FOR_SHIFTS, {
      skip: shifts.length == 0,
      variables: { shiftIds: shifts?.map((s) => s.id) },
    })
  );

  let clips = [];
  const clipsLoaded =
    (shiftMediaData?.getMediaForShifts.length || 0) +
    (eventMediaData?.getMediaForEvents.length || 0);

  if (clipsLoaded == playlistClips.length) {
    clips = playlistClips.map((pc) => ({
      ...pc,
      ...(shiftMediaData?.getMediaForShifts.find((s) => s.id == pc.id) || {}),
      ...(eventMediaData?.getMediaForEvents.find((e) => e.id == pc.id) || {}),
    }));
  }

  return {
    clips,
    shiftMediaLoading,
    eventMediaLoading,
  };
};
