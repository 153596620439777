import React from "react";

export const unnestResponse = response => {
    if ('edges' in response) {
        return response.edges.map(e => e.node)
    }
    return response
}

// Given a value, a range (min1-max1) and a new range (min2, max2)
// remap number to new range
// clips at new range edges
export const reMapNum = (value, min1, max1, min2, max2) => {
    var ret = (value - min1) * (max2 - min2) / (max1 - min1) + min2;
    ret = Math.min(ret, max2)
    ret = Math.max(ret, min2)
    return ret
}

// helper function for sorting.  Given value and list, returns index of value IF IN list, else returns large pos number
export const idxIfIn = (v, arr) => {
    const i = arr.indexOf(v)
    return i >= 0 ? i : Infinity
}

export const roundToX = (num, x, trailingZeros=false, replaceNaN='NaN') => {
    if (num === null || isNaN(num)) return replaceNaN
    if (trailingZeros) return parseFloat(num).toFixed(x)
    return parseFloat(parseFloat(num).toFixed(x))
    
    // let mult = Math.pow(10, x)
    // return Math.round(num * mult) / mult
}

export const strContains = (str_, x, caseInsensitive=true, anyOrAll='any') => { 
    if (!str_) return false
    const str = caseInsensitive ? str_.toLowerCase() : str_
    if (x instanceof Array) {
        if (anyOrAll == 'all') {
            return x.reduce((acc, curr) => {
                const curr_ = caseInsensitive ? curr.toLowerCase() : curr
                return acc && str.indexOf(curr_) >= 0
            }, true)    
        } else if (anyOrAll == 'any') {
            return x.reduce((acc, curr) => {
                const curr_ = caseInsensitive ? curr.toLowerCase() : curr
                return acc || (str.indexOf(curr_) >= 0)
            }, false)
        } else {
            return new Error(`Parameter "anyOrAll" expects value 'any' or 'all', got ${anyOrAll}`)
        }
    } else {
        return caseInsensitive ? str.toLowerCase().indexOf(x.toLowerCase()) >= 0 : str.indexOf(x) >= 0
    }
}

export const toiSecToString = (toiSec) => {
  let min = Math.floor(toiSec / 60);
  let sec = Math.floor(toiSec % 60);

  return `${min}:${sec.toFixed(0).padStart(2, '0')}`;
};

const defaultFormatOpts = {
    toiToTime: true,
    roundTo: 2,
    addPercentage: false,
    seasonToShort: false
}
export function formatStats(data, stats, params=defaultFormatOpts) {
    // Format stats recieved from API (orient="records")
    // Valid params: 
    // toiToTime -- convert toi from seconds to MM:SS
    params = {...defaultFormatOpts, ...params}
    const toiStats = ['toi', 'toi/gp', 'pp toi/gp', 'sh toi/gp', 'es toi/gp']
    return data.map(d => {
        var retrow = {...d}
        if (params.seasonToShort && 'season' in d) {
            retrow['season'] = `${retrow['season'].toString().slice(2,4)}-${retrow['season'].toString().slice(6,8)}`
        }

        stats.forEach(s => {
            if (toiStats.indexOf(s.toLowerCase()) >= 0) {
                if (params.toiToTime) {
                    var seconds = Math.round(retrow[s] % 60).toString()
                    seconds = seconds.length === 1 ? '0'+seconds : seconds
                    retrow[s] = `${Math.floor(retrow[s]/60)}:${seconds}`
                } else {
                    retrow[s] = retrow[s] / 60
                }
            }
            if ((strContains(s, '%') || strContains(s, 'rate') || strContains(s, 'tendency') ||
            strContains(s, 'gbr')) && !strContains(s, 'rank')) {
                retrow[s] = retrow[s] * 100;
            }
            if (strContains(s, 'rank')) {
                retrow[s] = retrow[s] || data.length
            } 
            // TODO: else if (all elements in column are integers, then cast to int) {}

            else if (!isNaN(retrow[s])) {
                if (typeof params.roundTo == 'object') {
                    if (s in params.roundTo) {
                        retrow[s] = roundToX(retrow[s], params.roundTo[s])
                    } else {
                        retrow[s] = roundToX(retrow[s], params.roundTo.default)
                    }
                } else {
                    retrow[s] = roundToX(retrow[s], params.roundTo)
                }
            }
            if (params.addPercentage) {
                if (strContains(s, '%') || strContains(s, 'rate') || strContains(s, 'tendency')) {
                    retrow[s] += '%'
                }
            }
        })
        return retrow
    })
}

export function addCompToData(data, dataIndex, compData, compIndex, compType) {
    if ((compData instanceof Array && compData.length == 0) || (compData instanceof Object && Object.keys(compData).length == 0)) {
        return []
    }
    const skipCols = dataIndex.concat(['toi', 'gp'])

    if (compType === 'metastats') {
        
        // if team is in the index but its actually just "league", we remove from index
        var joinIndex = compIndex
        if (compIndex instanceof Array && compIndex.indexOf('team') >= 0 &&
        compData.means.length > 0 && compData.means[0]['team'] == 'league') {
            joinIndex = compIndex.filter(i => i != 'team')
        }

        var ret = []
        var joined = joinObjects(data, compData.means, joinIndex, ['', '_mean'])
        var joined = joinObjects(joined, compData.stddevs, joinIndex, ['', '_stddev'])
        const isNeg = compData.is_negative_stat

        data.forEach((row, i) => {
            var retrow = {}
            for (var key in row) {
                if (skipCols.indexOf(key.toLowerCase()) >= 0) { continue }
                if (key === compIndex || (compIndex instanceof Array && compIndex.indexOf(key) >= 0)) {
                    ret[key] = row[key]
                } else {
                    var comp = (row[key] - joined[i][key+'_mean']) / joined[i][key+'_stddev']
                    comp = roundToX(comp, 2)
                    var className = 'averages-paren'
                    if ((comp <= -1 && isNeg[key]) || (comp >= 1 && !isNeg[key])) {
                        className += ' green-text'
                    } else if ((comp >= 1 && isNeg[key]) || (comp <= -1 && !isNeg[key])) {
                        className += ' red-text'
                    }
                    retrow[key] = {
                        className: className,
                        value: `${comp > 0 ? '+' : ''}${comp}`
                    }
                }
            }
            ret = ret.concat(retrow)
        })
        return ret
    } else if (compType === 'averages') {
        // if team is in the index but its actually just "league", we remove from index
        var joinIndex = compIndex
        if (compIndex instanceof Array && compIndex.indexOf('team') >= 0 &&
        compData.data.length > 0 && compData.data[0]['team'] == 'league') {
            joinIndex = compIndex.filter(i => i != 'team')
        }

        var ret = []
        var joined = joinObjects(data, compData.data, joinIndex, ['', '_mean'])

        joined.forEach(row => {
            var retrow = {}
            for (var key in row) {
                // For each non-average key
                if (key.endsWith('_mean')) { continue }
                // skip over "skipCols"
                if (skipCols.indexOf(key.toLowerCase()) >= 0) { continue }
                // Check if this is the "index" key (or part of index list)
                if (key === compIndex || (compIndex instanceof Array && compIndex.indexOf(key) >= 0)) {
                    ret[key] = row[key]
                } else {
                    retrow[key] = {className: 'averages-paren', value: roundToX(row[key+'_mean'], 2)}
                }
            } 
            ret = ret.concat(retrow)
        })
        
        return ret        
    }

}

function renameKeys(obj, funcOrObj) {
    var ret = {}
    for (var key in obj) {
        if (typeof funcOrObj === 'function') {
            ret[funcOrObj(key)] = obj[key]
        } else if (key in funcOrObj) {
            ret[funcOrObj[key]] = obj[key]
        }
    }
    return ret
} 

export function joinObjects(objList1, objList2, on, suffixes=['', '_right']) {
    // takes two lists-O-dicts (orient='records') 
    // and a key or list of keys to join on
    // returns list of joined objs
    var ret = []
    let how = 'left'
    if (!objList1 || ! objList2) { return [] } 
    
    var indexedObjs = []
    var orderedIndexes = []
    // go through each obj first
    var objLists = [objList1, objList2]
    objLists.forEach(objList => {
        var indexedObj = {}
        var orderedIdx = []
        objList.forEach(obj => {
            // Check that join column(s) present in object
            if (on instanceof Array) {
                if (!on.reduce((acc, curr) => acc && (curr in obj))) {
                    throw new Error(`Column(s) ${on} not found in join obj ${obj}`)
                }
            } else {
                if (!on in obj) {
                    throw new Error(`Column(s) ${on} not found in join obj ${obj}`)
                }
            }
            var key = on instanceof Array ? on.map(ki => obj[ki]).join('_') : obj[on]
            orderedIdx.push(key)
            indexedObj[key] = obj
        })
        indexedObjs.push(indexedObj)
        orderedIndexes.push(orderedIdx)
    })

    const joinI = how === 'left' ? 0 : 1
    const otherI = how === 'left' ? 1 : 0

    objLists[joinI].forEach((row, i) => {
        var key = orderedIndexes[joinI][i]
        var otherObj = indexedObjs[otherI][key]
        var suffixedRow = renameKeys(row, (x => x+suffixes[joinI]))
        var suffixedJoinObj = renameKeys(otherObj, (x => x+suffixes[otherI]))
        ret = ret.concat(
            {
                ...suffixedRow,
                ...suffixedJoinObj
            }
        )
    })
    return ret
}

function getKernel(type, size) {
    switch (type) {
        case 'box': {
            var ret = []
            for (var i=0; i < size; i++) {
                ret.push(1.0/size)
            }
            return ret
        } case 'triang': {
            var ret = []
            const midIdx = Math.floor(size / 2)
            var kernelSum = 0
            for (var i=0; i < size; i++) {
                const distFromMid = Math.abs(midIdx - i)
                const invDistFromMid = midIdx+1 - distFromMid
                ret.push(invDistFromMid)
                kernelSum += invDistFromMid
            }
            for (var i=0; i < size; i++) {
                ret[i] /= kernelSum
            }
            return ret
        }
    }
}

function dotProd(arr1, arr2, isObject=false) {
    if (arr1.length != arr2.length) {
        throw new Error(`Array length mismatch in dotProd: 
            arr1 has length ${arr1.length}, arr2 has length ${arr2.length}`)
    }
    if (isObject) {
        var ret = {}
        for (var i=0; i<arr1.length; i++) {
            for(var key in arr1) {
                ret[key] += arr1[i][key]*arr2[i][key]
            }
        }
    } else {
        var ret = 0
        for (var i=0; i<arr1.length; i++) {
            ret += arr1[i]*arr2[i]
        }
    }
    return ret
}

function padArr(arrObj, padSize, how='mean') {
    // Pads an array, returns new array
    var frontPad = []
    var backPad = [] 
    if (arrObj.length == 0) {
        return []
    }
    switch (how) {
        case 'extend': {
            for (var i=0; i < padSize; i++) {
                frontPad[i] = arrObj[0]
                backPad[i] = arrObj[arrObj.length-1]
            }
            return frontPad.concat(arrObj).concat(backPad)
        } case 'mean': {
            const mean = arrObj.reduce((prev, curr) => prev+curr) / arrObj.length
            for (var i=0; i < padSize; i++) {
                frontPad[i] = mean
                backPad[i] = mean
            }
            return frontPad.concat(arrObj).concat(backPad)
        } case 'zero': {
            for (var i=0; i < padSize; i++) {
                frontPad[i] = 0
                backPad[i] = 0
            }
            return frontPad.concat(arrObj).concat(backPad)
        }
    }
}


const defaultSmoothOpts = {
    mode: "window",
    timeSeries: null,
    windowSize: 9,
    windowType: 'triang',
    center: true, 
    padding: 'mean'
}
export function smoothData(data, opts={}) {
    /*
    data -- array of numbers (should be in correct order)
    opts --
        mode: "window" or "timeSeries".
            "window": -the default. Uses a fixed window size of data points
            "timeSeries": -requires an extra "timeSeries" option (see below)
                -Also expects windowSize to be in the same units as this array
                -In this mode, we use the opts.timeSeries to match each element in 
                    "data" with a timestamp.  We return a new array where each entry
                    is the average of all the points in data within "windowSize" seconds
                    of the original data[i] (more specifically, we look at all points within
                    a window of width "windowSize" seconds centered at d[i]'s timestamp)
        timeSeries: Array of same length as data.  These are timestamps to use for
            creating windows. 
        windowSize: size of smoothing window
        windowType: one of "box", "triang"
        center: if true, smoothing is centered, else, uses right edge
        padding: how to handle padding, "extend", "zero" or "mean"
    */
    opts = {...defaultSmoothOpts, ...opts}
    if (opts.mode === 'timeSeries') opts.center = false

    if (!data.length > 0) {
        return null
    }
    if (opts.center && opts.windowSize % 2 == 0) {
        throw new Error(`Odd window size required for centered smoothing, recieved ${opts.windowSize}`)
    }
    if (opts.mode === 'timeSeries') {
        if (!opts.timeSeries) throw new Error('"timeSeries" required in second argument when mode=timeSeries')
        if (data.length !== opts.timeSeries.length) throw new Error('"timeSeries" array must be of same length as "data"')
        // TODO add code here that parses the timeSeries array into useable timestamps (epoch time?)
    }


    var dataCopy = data.slice()
    
    var ret = [] // we return either an Array of numbers (if !isObject) or an Array of Objects
    var retIdx = 0
    var currWindow = null // holds window of values
    var kernel = getKernel(opts.windowType, opts.windowSize)
    const halfWindow = Math.floor(opts.windowSize / 2)

    const updateCurrWindow = (curr, data_, i) => {
        if (opts.mode === 'timeSeries') {
            const ret = []
            const ts = opts.timeSeries
            const min = ts[i] - opts.windowSize/2
            const max = ts[i] + opts.windowSize/2
            for (let j=0; j<data_.length; j++) {
                if (ts[j] > max) break
                else if (ts[j] >= min && ts[j] <= max) {
                    ret.push(data_[j])
                }
            }
            return ret
        } else if (opts.center) {
            if (!curr) { // initialize
                return data_.slice(i-halfWindow, i+halfWindow+1)
            }
            curr.shift()
            curr.push(data_[i+halfWindow])
        } else {
            if (!curr) { //initialize
                return data_.slice(0, opts.windowSize)
            }
            curr.shift()
            curr.push(data_[i+opts.windowSize])
        }
        return curr
    }

    if (opts.mode === 'timeSeries') {
        var start = 0
        var end = data.length
    } else if (opts.center) {
        dataCopy = padArr(dataCopy, halfWindow, opts.padding)
        var start = halfWindow
        var end = halfWindow+data.length
        // currWindow = dataCopy.slice(i-halfWindow, i+halfWindow+1)
    } else {
        dataCopy = padArr(dataCopy, opts.windowSize, opts.padding)
        var start = 0
        var end = data.length
    }
    for (var i=start; i < end; i++) {
        // Update window
        currWindow = updateCurrWindow(currWindow, dataCopy, i)
        if (opts.mode === 'timeSeries') {
            // In ts mode, need a kernel that matches window size (cause variable size windows)
            kernel = getKernel(opts.windowType, currWindow.length)
        }
        // Apply smoothing kernel
        ret[retIdx++] = dotProd(currWindow, kernel)
    }

    return ret
}

export function sortData(data, sortCol) {
    // expects array of objects, returns new array of objects
    if (!data || !sortCol) {
        return null
    }
    var dataCopy = data.slice()
    dataCopy.sort((a,b) => a[sortCol] - b[sortCol])
    return dataCopy
}

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => checkEquality(o1[p], o2[p]));

export const checkEquality = (a, b, defaultEq=true) => {
  // Compare equality of arrays, objects or base types
  // Can supply a defaultEq (defaults to true) to use for objects that cant be compared
  // like react nodes
  if (React.isValidElement(a)) {
    if (!React.isValidElement(b)) return false
    return defaultEq
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return false
    return arraysEqual(a, b)
  }
  if (a === null) return b === null
  if (typeof a === 'object') {
    if (typeof b !== 'object') return false
    return objectsEqual(a, b)
  }
  return a === b
}

export const range = (min, max) =>
  [...Array(max - min + 1).keys()].map((i) => i + min);

export const removeNull = (obj, getVal) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => (getVal ? getVal(v) : v?.val || v?.op) != null
    )
  );

export const removeKeyFromObj = (obj, keyToRemove) => {
  return Object.keys(obj).filter(key => key !== keyToRemove).reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});
}

export const mergeLists = (currentList, newList) => {
  const map = currentList.reduce((map, obj) => {
    map[obj.id] = obj;
    return map;
  }, {});

  newList.forEach((obj) => {
    map[obj.id] = obj;
  });

  return Object.values(map);
};