import styled from "styled-components";

const BDDLinkButton = styled.button`
    background: none;
    border: 0px;
    color: ${props => !!props.color ? props.color : '#333'};
    :hover {
        color: black;
        text-decoration: underline;
    }
`
export default BDDLinkButton