import { VerticalDivider } from 'components/bdd/Divider';
import { Dropdown, useDropdown } from 'components/bdd/Dropdown';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDPlotly from 'components/bdd/bddplotly';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import { formatDate } from 'helpers/helpers';
import { bruinsBlack, bruinsGold } from 'helpers/plotting';
import { useEffect, useMemo, useState } from 'react';

export default function useSimilarContractAgg({ contracts }) {
  const [selectedContractIds, setSelectedContractIds] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);

  const handleSelectTerm = (term) => {
    setSelectedTerm(term);
    setSelectedContractIds(
      !!contracts ? contracts.filter((c) => c.numYears == term).map((c) => c.id) : []
    );
  };
  const {
    modeTerm,
    allTerms,
    focusTerms,
    terms,
    contractsByTerm,
    focusContracts,
    nonFocusContracts,
    focusAvgAav,
    focusAvgPctSal,
    focusAvgPctPerf,
  } = useMemo(() => {
    if (!contracts?.length)
      return {
        focusContracts: [],
        nonFocusContracts: [],
        allTerms: [],
      };
    const focusContracts = contracts.filter((c) => selectedContractIds.includes(c.id));

    const nonFocusContracts = contracts.filter(
      (c) => !selectedContractIds.includes(c.id)
    );
    const contractsByTerm = {};
    contracts.forEach((c) => {
      const term = c.numYears;
      if (!(term in contractsByTerm)) {
        contractsByTerm[term] = [];
      }
      contractsByTerm[term].push(c);
    });
    const terms = Object.keys(contractsByTerm).sort();
    const focusTerms = Array.from(new Set(focusContracts.map((c) => c.numYears))).sort();

    const avgAavByTerm = {};
    const avgSalByTerm = {};
    const avgPerfByTerm = {};
    terms.forEach((t) => {
      avgAavByTerm[t] =
        contractsByTerm[t].reduce((acc, curr) => acc + curr.aav, 0) /
        contractsByTerm[t].length;
      avgSalByTerm[t] =
        contractsByTerm[t].reduce(
          (acc, curr) => acc + curr.totalSalary / curr.numYears,
          0
        ) / contractsByTerm[t].length;
      avgPerfByTerm[t] =
        contractsByTerm[t].reduce(
          (acc, curr) => acc + curr.performanceBonus / curr.numYears,
          0
        ) / contractsByTerm[t].length;
    });
    const focusAvgAav =
      focusContracts.reduce((acc, curr) => acc + curr.aav, 0) / focusContracts.length;
    const focusAvgPctSal =
      focusContracts.reduce(
        (acc, curr) => acc + curr.totalSalary / curr.numYears / curr.aav,
        0
      ) / focusContracts.length;
    const focusAvgPctPerf =
      focusContracts.reduce(
        (acc, curr) => acc + curr.performanceBonus / curr.numYears / curr.aav,
        0
      ) / focusContracts.length;

    const modeTerm = Object.entries(contractsByTerm).sort(
      ([t1, c1], [t2, c2]) => c2.length - c1.length
    )[0][0];

    return {
      allTerms: contracts.map((c) => c.numYears),
      terms,
      modeTerm,
      contractsByTerm,
      avgAavByTerm,
      avgSalByTerm,
      avgPerfByTerm,
      focusTerms,
      focusContracts,
      nonFocusContracts,
      focusAvgAav,
      focusAvgPctSal,
      focusAvgPctPerf,
    };
  }, [contracts, selectedContractIds, selectedTerm]);

  useEffect(() => {
    handleSelectTerm(modeTerm);
  }, [modeTerm]);

  const sharedLayout = {
    width: 200,
    height: 150,
    bargap: 0.05,
    margin: { t: 5, b: 35, l: 30, r: 5 },
    xaxis: {
      fixedrange: true,
    },
    yaxis: {
      tickformat: ',d',
      fixedrange: true,
    },
  };

  const dropdownOptions = !!terms?.length
    ? terms.map((t) => ({
        label: (
          <Column gap={2}>
            <Typography variant="h6">{t} year</Typography>
            <Typography variant="body2">
              ({contractsByTerm[t].length} similar contracts)
            </Typography>
          </Column>
        ),
        value: t,
      }))
    : [];

  const buildScatterTrace = (cs, name, color) => ({
    x: cs.map((c) => c.numYears),
    y: cs.map((c) => c.aav),
    name,
    type: 'scatter',
    mode: 'markers',
    marker: { color, opacity: 0.8 },
    customdata: cs.map(
      (c) =>
        `${c.bfPlayer.firstName} ${c.bfPlayer.lastName} ${roundToX(
          c.signingAge,
          0
        )}y.o.<br>` +
        `${formatDollars(c.aav)}x${c.numYears}<br>` +
        `${formatDate(c.signedDate)}`
    ),
    hovertemplate: '%{customdata}<extra></extra>',
  });

  const component = !contracts ? null : contracts.length == 0 ? (
    <Typography variant="error">No contracts found</Typography>
  ) : (
    <Container>
      <Row columnGap={2} justifyContent="space-around" flexWrap>
        <Container>
          <Typography variant="body1" textAlign="center">
            All Similar Signings
          </Typography>
          <BDDPlotly
            displayModeBar={false}
            data={[
              buildScatterTrace(nonFocusContracts, 'unselected', bruinsBlack),
              buildScatterTrace(focusContracts, 'selected', bruinsGold),
            ]}
            layout={{
              ...sharedLayout,
              showlegend: false,
              hovermode: 'closest',
              yaxis: {
                ...sharedLayout.yaxis,
                tickformat: '$.1s',
              },
              xaxis: {
                ...sharedLayout.xaxis,
                range: [0, Math.max(...allTerms, 9)],
                title: 'Term (years)',
              },
            }}
          />
        </Container>

        <Container>
          <Typography variant="body1" textAlign="center">
            Terms on Similar Signings
          </Typography>
          <Typography variant="caption" textAlign="center">
            Click to quick select a term group
          </Typography>
          <BDDPlotly
            displayModeBar={false}
            data={[
              {
                x: allTerms,
                type: 'histogram',
                xbins: {
                  end: 9,
                  size: 1,
                  start: 1,
                },
                marker: { color: bruinsBlack, opacity: 0.8 },
              },
            ]}
            layout={{
              ...sharedLayout,
              xaxis: {
                ...sharedLayout.xaxis,
                range: [1, Math.max(...allTerms, 9)],
                title: 'Term (years)',
              },
            }}
            onClick={({ points }) => {
              if (points.length > 0) {
                handleSelectTerm(Math.floor(points[0].x));
              }
            }}
          />
        </Container>

        <VerticalDivider height={100} />
        <Container width={150}>
          <Dropdown
            noCaret={true}
            renderToggle={(selected) => (
              <Row columnGap={2}>
                <Typography variant="body2">Selected {focusContracts.length}</Typography>
                <Typography variant="body1">{focusTerms.join(', ')} year</Typography>
                <Typography variant="body2">similar signings</Typography>
              </Row>
            )}
            initialSelected={dropdownOptions.find((o) => o.value === selectedTerm)}
            options={dropdownOptions}
            onSelected={(option) => handleSelectTerm(option.value)}
          />

          <Column>
            <Row gap={4} justifyContent="space-between">
              <Typography variant="body1">Avg. AAV:</Typography>
              <Typography variant="h6">{formatDollars(focusAvgAav)}</Typography>
            </Row>

            <Row gap={4} justifyContent="space-between">
              <Typography variant="body2">Avg. % Salary:</Typography>
              <Typography variant="body1">{roundToX(100 * focusAvgPctSal)}%</Typography>
            </Row>

            <Row gap={4} justifyContent="space-between">
              <Typography variant="body2">Avg. % Bonus:</Typography>
              <Typography variant="body1">{roundToX(100 * focusAvgPctPerf)}%</Typography>
            </Row>
          </Column>
        </Container>

        <Container>
          <Typography variant="body1" textAlign="center">
            AAVs on Selected Similar Signings
          </Typography>
          <BDDPlotly
            displayModeBar={false}
            data={[
              {
                x: focusContracts.map((c) => c.aav),
                type: 'histogram',
                marker: { color: bruinsGold, opacity: 0.8 },
                text: focusContracts.map((c) => c.bfPlayer.lastname),
              },
            ]}
            layout={{
              ...sharedLayout,
              bargap: 0.05,
              xaxis: {
                ...sharedLayout.xaxis,
                title: 'AAV (Mill.)',
              },
            }}
          />
        </Container>
      </Row>
    </Container>
  );

  return {
    component,
    selectedContractIds,
    setSelectedContractIds,
  };
}
