import { BDDDateRangePicker } from 'components/bdd/bdddatepicker';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useContext } from 'react';
import { SLStatContext } from '..';

export const DateRangeFilter = ({}) => {
  const { filters, setFilters } = useContext(SLStatContext);

  return (
    <Container>
      <Typography variant="label">Date Range</Typography>
      <Container minWidth={200}>
        <Typography variant="body1">
          <BDDDateRangePicker
            compact
            zIndex={6}
            placeholderText="Date Range"
            selected={filters['start_date']}
            startDate={filters['start_date']}
            endDate={filters['end_date']}
            onChange={(dates) => {
              const sdate =
                dates[0] instanceof Object
                  ? dates[0].getTime()
                  : typeof dates[0] == 'number'
                  ? dates[0]
                  : null;

              const edate =
                dates[1] instanceof Object
                  ? dates[1].getTime()
                  : typeof dates[1] == 'number'
                  ? dates[1]
                  : null;

              setFilters({
                start_date: sdate,
                end_date: edate,
              });
            }}
            showYearDropdown
            isClearable
          />
        </Typography>
      </Container>
    </Container>
  );
};
