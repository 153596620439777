export const teamToIndStatSlugs = {
    "successRate": ['goalsFor', 'goalsAgainst'],
    "opportunities": ['penaltiesDrawn', 'penaltiesTaken'],
    "shotsOnGoalFor": ["shotsOnGoal"],
    "XPGperShot": ["XPGperShot"],
    "XPGF": ["iXP", "XPGF"],
    "goalsFor": ["goals"],
    "chances": ["chances", "iXG"],
    "chancesOnNet": ["chancesOnNet"],
    "percentageChancesOnNet": ["percentageChancesOnNet"],
    "percentageShotsFromSlot": ["slotShots", "percentageShotsFromSlot"],
    "shotsOnGoalAgainst": ["shotsOnGoalAgainst"],
    "XPGperShotAgainst": ["XPGperShotAgainst"],
    "XPGA": ["XPGA"],
    "goalsAgainst": ["goalsAgainst"],
    "chancesAgainst": ["chancesAgainst"],
    "chancesOnNetAgainst": ["chancesOnNetAgainst"],
    "percentageChancesOnNetAgainst": ["percentageChancesOnNetAgainst"],
    "percentageShotsFromSlotAgainst": ["percentageShotsFromSlotAgainst"],
    "highDangerChancesOnNet": ["highDangerChancesOnNet"],
    "highDangerChancesOnNetAgainst": ["highDangerChancesOnNetAgainst"],
    "possessionTimeFor": ["possessionTime"],
    "ozPossessionTime": ["ozPossessionTime"],
    "percentageOZTime": ["ozToi", "percentageOZTime"],
    "XPGFCycle": ["iXGCycle", "XPGFCycle"],
    "cycleGoals": ["cycleGoals"],
    "slotPassAttempts": ["slotPassAttempts", "iXA"],
    "slotPasses": ["slotPasses"],
    "slotPassPercentage": ["slotPassPercentage"],
    "slotPassTendency": ["slotPassTendency"],
    "northCyclePassTendency": ["northCyclePassTendency"],
    "ozEWPassTendency": ["ozEWPassTendency"],
    "XPGFRush": ["iXGRush", "XPGFRush"],
    "rushGoals": ["rushGoals"],
    "controlledEntryAttempts": ["controlledEntryAttempts", "PDPs"],
    "controlledEntrySuccessRate": ["controlledEntrySuccessRate", "controlledEntries"],
    "percentageEntriesWithChance": ["percentageEntriesWithChance", "controlledEntriesWithChance"],
    "XPGFForecheck": ["iXGForecheck", "XPGFForecheck"],
    "forecheckGoals": ["forecheckGoals"],
    "dumpinTendency": ["dumpinRecoveryAttempts", "dumpinTendency"],
    "dumpinRecoveryRate": ["dumpinRecoveryRate", "dumpinRecovery"],
    "fcSuccessRateOffDumpIns": ["ozForecheckStructured", "fcSuccessRateOffDumpIns"],
    "fcSuccessRateOffOZPlays": ["ozForecheckUnstructured", "fcSuccessRateOffOZPlays"],
    "fcSuccessRateTotal": ["ozForecheck", "fcSuccessRateTotal"],
    "deflections": ["deflections"],
    "ozReboundRecoveryRate": ["ozReboundLPR", "ozReboundRecoveryRate"],
    "XPGFRebound": ["iXGRebound", "XPGFRebound"],
    "reboundGoals": ["reboundGoals"],
    "defensemenPoints": ["defensemenPoints"],
    "defensemenChances": ["defensemenChances"],
    "defensemenSlotPasses": ["defensemenSlotPasses"],
    "defensemenShotAttempts": ["defensemenShotAttempts"],
    "ozPossessionTimeAgainst": ["ozPossessionTimeAgainst"],
    "percentageDZTime": ["percentageDZTime"],
    "XPGACycle": ["XPGACycle"],
    "cycleGoalsAgainst": ["cycleGoalsAgainst"],
    "slotPassAttemptsAgainst": ["slotPassAttemptsAgainst"],
    "slotPassesAgainst": ["slotPassesAgainst"],
    "slotPassPercentageAgainst": ["slotPassPercentageAgainst"],
    "slotPassTendencyAgainst": ["slotPassTendencyAgainst"],
    "dzTakeawayRate": ["dzTakeawayRate", "dzTakeaways"],
    "takeaways": ["takeaways"],
    "dzTakeaways": ["dzTakeaways"],
    "deflectionsAgainst": ["deflectionsAgainst"],
    "dzReboundRecoveryRate": ["dzReboundLPR", "dzReboundRecoveryRate"],
    "XPGARebound": ["XPGARebound"],
    "reboundGoalsAgainst": ["reboundGoalsAgainst"],
    "percentageOfOppositionShotsBlocked": ["percentageOfOppositionShotsBlocked", "shotBlocks"],
    "XPGARush": ["XPGARush"],
    "rushGoalsAgainst": ["rushGoalsAgainst"],
    "nzTakeaways": ["nzTakeaways"],
    "nzDefensivePlaysFor": ["nzDefensivePlays"],
    "nzTakeawayRate": ["nzTakeawayRate"],
    "controlledEntryDenialRate": ["controlledEntryDenials", "controlledEntryDenialRate"],
    "controlledEntriesAgainst": ["controlledEntriesAgainst"],
    "dumpinTendencyAgainst": ["dumpinRecoveryAttemptsAgainst", "dumpinTendencyAgainst"],
    "dumpinRecoveryRateAgainst": ["dumpinRecoveryAgainst", "dumpinRecoveryRateAgainst"],
    "percentageEntriesWithChanceAgainst": ["percentageEntriesWithChanceAgainst"],
    "XPGAForecheck": ["XPGAForecheck"],
    "forecheckGoalsAgainst": ["forecheckGoalsAgainst"],
    "nzTurnoverRate": ["nzTurnoverRate"],
    "dzTurnoverRate": ["dzTurnoverRate"],
    "dzFaceoffExitRate": ["dzFaceoffExitRate"],
    "breakoutSuccessRateOffDumpIns": ["breakoutSuccessRateOffDumpIns", "dzBreakoutStructured"],
    "breakoutSuccessRateOffOZPlays": ["breakoutSuccessRateOffOZPlays", "dzBreakoutUnstructured"],
    "totalDZBreakoutSuccessRate": ["totalDZBreakoutSuccessRate", "dzBreakout"],
    "nzNorthPassPercentage": ["nzNorthPasses", "nzNorthPassPercentage"],
    "outletPassPercentage": ["outletPasses", "outletPassPercentage"],
    "dzD2DPassPercentage": ["dzD2DPasses", "dzD2DPassPercentage"],
    "dzDumpoutSuccessRate": ["dumpoutSuccesses", "dzDumpoutSuccessRate"],
    "outletTendency": ["outletTendency", "outletPassAttempts"],
    "dzStretchTendency": ["dzStretchTendency", "dzStretchPassAttempts"],
    "defensemenDZD2DTendency": ["defensemenDZD2DTendency", "dzD2DPassAttempts"],
    "defensemenOutletTendency": ["defensemenOutletTendency", "defensemenOutletPassAttempts"],
    "defensemenDZStretchTendency": ["defensemenDZStretchTendency", "defensemenDZStretchPassAttempts"],
    "dzDumpoutRate": ["dzDumpouts", "dzDumpoutRate"],
    "dzCarryOuts": ["dzCarryOuts"],
    "OZFaceOffWinPercentage": ["OZFaceOffAttempts", "OZFaceOffWinPercentage"],
    "NZFaceOffWinPercentage": ["NZFaceOffAttempts", "NZFaceOffWinPercentage"],
    "DZFaceOffWinPercentage": ["DZFaceOffAttempts", "DZFaceOffWinPercentage"],
    "nzTakeawayRate": ['nzDefensivePlays']
}