import React, { useEffect, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Collapse, Button, Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

import { GET_PLAYER_LIST, GET_PLAYER_LISTS_W_PLAYERS, UPDATE_PLAYER_LIST } from "../../apollo/queries/playerlist.queries"
import { GET_RINKNET_PLAYERS } from "../../apollo/queries/players.queries"
import BDDApiError from "../bdd/bddapierror"
import { BDDLoader } from "../bdd/bddloader"
import { toastBddApiError } from "../bdd/bddtoasts"
import LeagueSelect from "../filters/leagueselect"
import TeamsSelect from "../filters/teamsselect"
import SearchSelect from "../Search/searchselect"
import SelectPlayersBank from "./selectplayersbank"
import BDDSelect from "../bdd/bddselect"
import { GET_PLAYERS_BY_USER_REPORTS } from "../../apollo/queries/scouting.queries"
import { LIST_ID_TYPES } from "constants"

const Styles = styled.div`
    position: sticky;
    top: 10px;

    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 3px #ddd;
    margin-top: 20px;

    .add-player-button {
        padding: 5px;
        color: #777;
        font-size: 13pt;
        font-family: Oswald;
        cursor: pointer;
        :hover {
            color: black;
        }
    }
    .expanded-section {
        margin: 10px;
        margin-top: 0;
        padding: 10px;
    }

    .subheader {
        text-transform: uppercase;
        font-size: 0.8em;
        color: #aaa;
        font-weight: bold;
        cursor: pointer;
    }
    .subheader.active {
        color: #333;
    }
`
export default function AddListPlayers({
    listId,
    defaultExpand=false,
    defaultSection='search',
    numCols=3,
    onPlayersAdded
}) {
    const [section, setSection] = useState(defaultSection)
    const [expandAddPlayers, setExpandAddPlayers] = useState(defaultExpand)
    const { data, loading, error } = useQuery(GET_PLAYER_LIST, { variables: { id: listId }})
    const [updatePlayerlist, { loading: updateLoading }] = useMutation(UPDATE_PLAYER_LIST, {
        onError: error => toastBddApiError(error),
        onCompleted: () => {
            if (onPlayersAdded) onPlayersAdded()
        }
    })

    const handleSelect = opt => {
        updatePlayerlist({ variables: {
            input: { id: listId }, 
            playersToAdd: [{ id: opt.value }] } 
        })
    }
    const handleSubmitPlayers = playerIds => {
        updatePlayerlist({ variables: {
            input: { id: listId }, 
            playersToAdd: playerIds.map(pid => ({ id: pid })) } 
        })
    }
    const handleDuplicateList = targetListId => {
        if (confirm('Are you sure you want to duplicate list?  This will overwrite your current list with the contents of the selected list.')) {
            updatePlayerlist({ variables: {
                input: { id: listId },
                listToDuplicate: targetListId
            }})
        }
    }

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error}/>

    const idType = data.playerList.idType
    const visibleSections = idType === LIST_ID_TYPES.rinknet_id ? [
        'search',
        'leagueteam',
        'list',
        'reportedon'
    ] : [
      'search',
      'list',
    ]

    return <Styles>
        <div className='add-player-button' onClick={() => setExpandAddPlayers(!expandAddPlayers)}>
            <FontAwesomeIcon icon={faPlusCircle}/> Add Players
        </div>
        <Collapse in={expandAddPlayers}>
            <div style={{ margin: '10px' }}>
            {expandAddPlayers && <div>
                {visibleSections.includes('search') && <>
                <div className={`subheader ${section == 'search' ? 'active' : ''}`} onClick={() => setSection('search')}>
                    Search by Name
                </div>
                <Collapse in={section === 'search'}>
                    <Container>
                        <SearchSelect
                            resultTypes={idType === LIST_ID_TYPES.rinknet_id ? 'RINKNETPLAYERS' : 'BDDPLAYERS'}
                            handleSelect={handleSelect}
                            placeholder='Search for player...'
                        />
                    </Container>
                </Collapse>
                <hr></hr>
                </>}
                
                {visibleSections.includes('leagueteam') && <>
                <div className={`subheader ${section == 'leagueteam' ? 'active' : ''}`} onClick={() => setSection('leagueteam')}>
                    Search by League/Team
                </div>
                <Collapse in={section === 'leagueteam'}>
                    <Container>
                        {section === 'leagueteam' && <AddPlayerByLeagueTeam listObj={data.playerList} handleSubmit={handleSubmitPlayers} numCols={numCols}/>}
                    </Container>
                </Collapse>
                <hr></hr>
                </>}

                {visibleSections.includes('list') && <>
                <div className={`subheader ${section == 'list' ? 'active' : ''}`} onClick={() => setSection('list')}>
                    Add from another list
                </div>
                <Collapse in={section === 'list'}>
                    <Container>
                        {section === 'list' && <AddPlayerFromList
                            listObj={data.playerList} 
                            handleSubmit={handleSubmitPlayers} 
                            handleDuplicateList={handleDuplicateList}
                            numCols={numCols}
                        />}
                    </Container>
                </Collapse>
                <hr></hr>
                </>}

                {visibleSections.includes('reportedon') && <>
                <div className={`subheader ${section == 'reportedon' ? 'active' : ''}`} onClick={() => setSection('reportedon')}>
                    Add players reported on
                </div>
                <Collapse in={section === 'reportedon'}>
                    <Container>
                        {section === 'reportedon' && 
                          <AddPlayersReportedOn listObj={data.playerList} handleSubmit={handleSubmitPlayers} numCols={numCols}/>
                        }
                    </Container>
                </Collapse>
                </>}
            </div> }
            </div>
        </Collapse>
    </Styles>
}

const defaultLeagues = {
    rinknet: -1171577383,
    sportlogiq: 1
}
export const AddPlayerByLeagueTeam = ({
    listObj,
    handleSubmit,
    numCols=3
}) => {
    // const { data, loading, error } = useLazyQuery(GET_PL)
    const source = listObj.idType === 'rinknet_id' ? 'rinknet' : 'sportlogiq'
    const [league, setLeague] = useState(defaultLeagues[source])
    const [team, setTeam] = useState(null)

    return <>
        <Row>
        <Col>
            <LeagueSelect 
                useRinknet={source === 'rinknet'}
                value={league}
                onChange={(n,v) => setLeague(v)}
            />
        </Col>
        <Col>
            <TeamsSelect
              useRinknet={source === 'rinknet'}
              leagueid={league}
              league={league}
              value={team}
              onChange={(n,v) => setTeam(v)}
              onTeamsLoaded={teams => setTeam(teams[0].value)}
            />
        </Col>
        </Row>
        <div>
            <LeagueTeamPlayerBank 
                league={league} 
                team={team}
                handleSubmit={handleSubmit} 
                disabledPlayerIds={new Set(listObj.players.map(p => p.playerId))}
                numCols={numCols}
            />
        </div>
    </>
}

const LeagueTeamPlayerBank = ({
    league,
    team,
    handleSubmit,
    numCols=3,
    disabledPlayerIds // a set of guys already on list
}) => {
    const [getPlayers, { called, data, loading, error }]= useLazyQuery(GET_RINKNET_PLAYERS)

    useEffect(() => {
        if (!!league && !!team) {
            getPlayers({ variables: { 
                filters: { currentleagueid: league, currentteamid: team },
                sort: ['FIRSTNAME_ASC']
            }})
        }
    }, [league, team])

    if (!called) return <div>
        <em>Select a league and team</em>
    </div>
    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error} />

    const players = data.allRinknetPlayers.edges.map(e => e.node)
    return <SelectPlayersBank 
        players={players} 
        idKey='realId' 
        onSubmit={handleSubmit} 
        disabledPlayerIds={disabledPlayerIds}
        numCols={numCols}
    />
}


export const AddPlayerFromList = ({ 
    listObj,
    handleSubmit,
    handleDuplicateList,
    numCols=3,
    showDuplicateList=true
}) => {
    const [listId, setListId] = useState(null)
    const { data, loading, error } = useQuery(GET_PLAYER_LISTS_W_PLAYERS, { variables: { idType: listObj.idType }})

    const options = !!data ? 
        data.playerLists.filter(pl => pl.id != listObj.id).map(pl => ({ value: pl.id, label: pl.name })) 
        : []
    
    useEffect(() => {
        if (!!data && options.length > 0 && listId == null) {
            setListId(options[0].value)
        }
    }, [data])

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error}/>

    const currList = data.playerLists.filter(pl => pl.id == listId)[0]
    const players = !!currList ? currList.players : []
    const disabledPlayerIds = new Set(listObj.players.map(p => p.playerId))
    console.log(currList)
    return <>
        <Row>
            <Col>
                <BDDSelect name='listId'
                    value={listId}
                    onChange={(n,v) => setListId(v)}
                    options={options}
                    isSearchable={true}
                />
            </Col>
        </Row>
        <SelectPlayersBank 
            players={players} 
            idKey='playerId' 
            onSubmit={handleSubmit} 
            disabledPlayerIds={disabledPlayerIds}
            numCols={numCols}
            numberPlayers={true}
            dividerAfterIndexes={currList?.tiers?.map(t => t.endsWithListNumber-1)}
        />
        {showDuplicateList && <Row>
            <Col className='ml-auto' sm='auto'>
                <Button size='sm'
                    variant='outline-dark'
                    onClick={() => {
                        if (listId) {
                            handleDuplicateList(listId)
                        } else {
                            alert('Select a list in order to duplicate')
                        }
                    }}
                >
                    Duplicate List
                </Button>
            </Col>
        </Row>}
    </>
}

export const AddPlayersReportedOn = ({ 
    listObj,
    handleSubmit,
    numCols
}) => {
    const disabledPlayerIds = new Set(listObj.players.map(p => p.playerId))
    const { data, loading, error } = useQuery(GET_PLAYERS_BY_USER_REPORTS)

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error} />

    const players = data.playersByUserReports.map(pr => ({ ...pr.player, count: pr.totalReportCount }))
    return <>
        <SelectPlayersBank 
            players={players} 
            renderPlayer={p => `${p.firstname} ${p.lastname} (${p.count})`}
            idKey='realId' 
            onSubmit={handleSubmit} 
            disabledPlayerIds={disabledPlayerIds}
            numCols={numCols}
        />

    </>
}