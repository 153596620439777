import { useQuery } from '@apollo/client';
import { GET_MEDIA_FOR_SHIFTS } from 'apollo/queries/video.queries';
import { Container } from 'components/bdd/Layout';
import { VideoController } from 'components/bdd/Video/components';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useContext } from 'react';
import { VideoContext, VideoSelectContext } from '..';
import { ShiftList } from './ShiftList';

export const ShiftVideoPlayer = ({ playSelectedEvents, autoPlay = true }) => {
  const { allEvents, loading: shiftsLoading } = useContext(VideoContext);
  const { selectedEvents } = useContext(VideoSelectContext) || {};
  const shifts = !allEvents
    ? []
    : playSelectedEvents
    ? selectedEvents
    : allEvents;

  const { data: mediaData, loading } = usePlaceholder(
    useQuery(GET_MEDIA_FOR_SHIFTS, {
      skip: !shifts || shifts.length == 0,
      variables: { shiftIds: shifts?.map((s) => s.id) },
    })
  );

  const clips = mediaData?.getMediaForShifts || [];

  return (
    <VideoController
      playbackMode={'offsets'}
      loading={shiftsLoading || loading}
      clips={clips}
      autoPlay={autoPlay}
    />
  );
};
