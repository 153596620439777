import React from 'react';
import styled from 'styled-components';
import { theme } from 'constants';

const Container = styled.div({
  ...theme.typography.body3,
  marginTop: theme.spacing[6],
  marginRight: theme.spacing[6],
});

const Notes = styled.ul({
  listStylePosition: 'outside',
});

export const CombineNotes = ({ rinknetId, playerToCombineNotes }) => {
  const combineNotes =
    rinknetId in playerToCombineNotes && playerToCombineNotes[rinknetId];

  if (!combineNotes) {
    return <Container>No combine notes for player</Container>;
  }

  const notes = combineNotes.notes
    .replace('[', '')
    .replace(']', '')
    .split("'")
    .filter((note) => note.length > 2);

  return (
    <Container>
      <Notes>
        {notes.map((note, index) => (
          <li key={`note-${index}`}>{note}</li>
        ))}
      </Notes>
    </Container>
  );
};
