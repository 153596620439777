import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { useStatSearchParams } from './hooks/useStatSearchParams';
import StatScatterChart from './StatScatterChart';
import { StatFilters } from './StatFilters';
import { StatClientFilters } from './StatClientFilters';
import { StatChartControls } from './StatChartControls';
import { useEPData } from 'components/EliteProspects/hooks/useEPData';
import { StatTable } from 'components/EliteProspects/StatTable';
import { Typography } from 'components/bdd/Typography';
import { getCurrentSeason, getSeasonPlusMinusYears, seasonToEndYear } from 'helpers/hockeyutils';

const Divider = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  width: '100%',
  overflow: 'auto',
  marginBottom: theme.spacing[3],
});

const DEFAULT_SKATER_SEASON_QUERY = {
  target: 'ep_player_season_stats',
  extras: {},
  format: {},
  id_type: 'eliteprospects',
};

const SKATER_STATS = ['GP', 'G', 'A', 'PTS', 'PM', 'PIM', 'G/GP', 'A/GP', 'PTS/GP', 'PM/GP', 'PIM/GP']
const GOALIE_STATS = ['GP', 'SVP', 'GAA', 'GA', 'SA', 'SVS', 'GA/GP', 'SA/GP', 'SVS/GP']


const range = (min, max) => {
  if (max < min) return [];
  return [...Array(parseInt(max) - parseInt(min) + 1).keys()].map((i) => i + parseInt(min));
}


const buildQuery = (values) => {
  let query = {
    filters: [],
    extras: {}
  };

  query.stats = values.positions == 'G' ? GOALIE_STATS : SKATER_STATS

  query.filters.push({
    col: 'league_slug',
    cond: 'isin',
    val: values.leagues,
  });

  query.filters.push({
    col: 'season',
    cond: 'isin',
    val: values.seasons,
  });

  query.filters.push({
    col: 'game_type',
    val: values.gameType == 'null' ? null : values.gameType,
  });

  query.filters.push({
    col: 'position',
    val: values.positions,
  });

  query.filters.push({
    col: 'team_id',
    cond: 'isin',
    val: values.teams,
  });

  if (!!values.age?.min) {
    query.filters.push({
      col: 'age', cond: '>=', val: values.age.min
    })
  }

  if (!!values.age?.max) {
    query.filters.push({
      col: 'age', cond: '<=', val: values.age.max
    })
  }

  if (!!values.draftEligibleYears?.min || !!values.draftEligibleYears?.max) {
    const minYear = 1990;
    const maxYear = seasonToEndYear(getSeasonPlusMinusYears(getCurrentSeason(), 2));
    const yearsRange = range(
      isNaN(values.draftEligibleYears?.min) ? minYear : values.draftEligibleYears.min,
      isNaN(values.draftEligibleYears?.max) ? maxYear : values.draftEligibleYears.max,
    )
    query.filters.push({
      col: 'draft_eligible_years', 
      cond: 'includes', 
      val: yearsRange
    })
  }

  query.index = values.groupBy;
  query.extras.metastats = {
    target_group: ['league', 'age'],
    position: values.positions == 'skaters' ? ['F', 'D'] : [values.positions],
    game_type: values.gameType == 'null' ? null : values.gameType
  }
  return { ...DEFAULT_SKATER_SEASON_QUERY, ...query };
};

export const Stats = () => {
  const {
    store,
    serverFilterValues,
    getStatDefFilterValues,
    playerFilterValues,
  } = useStatSearchParams();

  const query = buildQuery(serverFilterValues);
  const highlightAgeToggled = store.getValue('highlightAgeToggled') == 'true';
  const useZScore = store.getValue('useZScore') == 'true';

  const {
    stats,
    metastats,
    placeholder,
    highlightCallback,
    formatStatByZScore,
  } = useEPData({
    query,
    gameType: serverFilterValues.gameType,
    targetGroup: highlightAgeToggled ? 'age' : 'league',
  });

  const chartType = store.getValue('chartType');

  return (
    <>
      <StatFilters />
      {stats ? (
        <>
          <Divider />
          {stats.data.length > 0 ? (
            <>
              <StatClientFilters stats={stats} />
              <Divider />
              <StatChartControls stats={stats} />
              <Divider />
              {(!chartType || chartType == 'table') && (
                <StatTable
                  stats={stats}
                  highlightCallback={highlightCallback}
                  formatStatByZScore={useZScore ? formatStatByZScore : null}
                  statDefFilterValues={getStatDefFilterValues(stats)}
                  playerFilterValues={playerFilterValues}
                  showPagination={true}
                />
              )}
              {chartType == 'scatter' && (
                <StatScatterChart
                  target="player"
                  data={stats.data}
                  metastats={metastats}
                  statDefinitions={stats.format.stat_definitions}
                  index={stats.format.index}
                />
              )}
            </>
          ) : (
            <Typography variant="body1">
              No stats available for the given filters
            </Typography>
          )}
        </>
      ) : (
        placeholder
      )}
    </>
  );
};
