import React from "react"
import { ErrorMessage } from "formik"

import BDDFormError from "../bdd/bddformerror"
import { BDDTextArea } from "../bdd"
import { Label } from "components/bdd/Label/Label"

export default function BDDTextAreaField({ title, noTitle, field, ...rest }) {
    const { name, value, onChange } = field // dont know why error isn't in here
    const error = rest.form.errors[name]
    const touched = rest.form.touched[name]
    return <>
    {noTitle ? null : <Label>{title}</Label>}
    <BDDTextArea
        name={name}
        placeholder={title}
        rows={2 || rest.rows}
        value={value}
        onChange={onChange}
        isInvalid={!!error && !!touched}
        {...rest}
    />
    <BDDFormError>
        <ErrorMessage name={name}/>
    </BDDFormError>
    </>
}
