import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Container, Modal, Row, Collapse } from 'react-bootstrap';
import { Row as LayoutRow } from 'components/bdd/Layout';
import update from 'immutability-helper';

import { BDDLoader } from '../bdd/bddloader';
import BDDApiError from '../bdd/bddapierror';
import {
  buildBDDDepthChartQuery,
  UPDATE_BDD_DEPTH_CHART,
} from '../../apollo/queries/depthchart.queries';
import styled from 'styled-components';
import { toastBddApiError, toastInfo } from '../bdd/bddtoasts';
import { BDDOptionGroup, LinkUnstyled, OverflowEllipsisCol } from '../bdd';
import { DisappearingRow, DisappearingCol } from '../bdd/disappearingcol';
import DraggableDepthChart from './draggabledepthchart';
import RinknetBench from './rinknetbench';
import BDDErrorBoundary from '../bdd/bdderrorboundary';
import DCSetPlayerForSpotModal from './dcsetplayerforspotmodal';
import DepthChartAnalyzer from './depthchartanalyzer';
import TeamSkatersScatter from '../Team/teamskatersscatter';
// import { DCPlayerWrapper } from "./dcplayers"
import { SubSectionHeader } from '../reports';
import { dgPos, fPos, parseLineupSpot } from './helpers';

import DepthChartChanges from './depthchartchanges';
import DepthChartProspectRankings from './depthchartprospectrankings';
import DepthChartLists from './depthcharlists';
import { DCPlayerWrapper } from './DepthCharts/DCPlayerWrapper';
import DepthChartPlayer from './DepthCharts/DepthChartPlayer';
import { Typography } from 'components/bdd/Typography';
import { LinkButton } from 'components/bdd/Button';
import { Plus } from 'react-bootstrap-icons';
import { ModalContext } from 'components/bdd/Modal';
import { DepthChartSpotWrapperForm } from './DepthChartSpotWrapperForm';

const Styles = styled.div`
  .control-row {
    margin-bottom: 10px;
  }
  .non-nhl-depth-container {
    border-top: 1px solid #ddd;
    margin-top: 10px;
  }
`;

const sideModeToWidth = {
  rinknet: 4,
  cap: 6,
  analytics: 6,
  prospects: 6,
  lists: 6,
};

const depthLeagues = {
  NHL: 'NHL',
  AHL: 'AHL',
  Prospect: 'Prospect',
};

// "adjustModes" define the behavior of depth chart after a player is dropped on top of another
export const adjustModes = {
  SWAP: 'SWAP', // players are swapped
  BUMP: 'BUMP', // "dropped_on" player is bumped down (as are players beneath)
};

const SL_STAT_DEFS = [
  { slug: 'toi', label: 'toi' },
  { slug: 'esToiPerGP' },
  { slug: 'ppToiPerGP' },
  { slug: 'shToiPerGP' },
];

export default function BDDDepthChartConstructor({
  depthChartId,
  renderPlayer: renderPlayerArg,
  highlightCallback,
  editable = true,
  defaultEditable,
  showMiniEditButton = false,
  showButtons = true,
  showProspects = true,
  showLists = false,
  showChanges = true,
  playerSize = 'lg',
  notifyOnExternalEffects,
  nonNHLDisplayMode = 'visible', // either visible, expandable or hidden
  onUpdate, // called on mutation update (cache, bddDpethChart, externalEffects)
  refetchWrapperQueries, // queries to be refetched on changes to wrappers
  forceAdjustMode,
}) {
  defaultEditable = defaultEditable == undefined ? editable : defaultEditable;
  const [showNonNHL, setShowNonNHL] = useState(nonNHLDisplayMode == 'visible');
  const [addPlayer, setAddPlayer] = useState({ show: false, lineupSpot: null });
  const [sideMode, setSideMode] = useState(null);
  const [playerRenderMode, setPlayerRenderMode] = useState('info');
  // currDepthChart = { NHL: { leftwing: [brad-marchand-88, ...], center: ...}, AHL: ..., Prospect: ... }
  const [currDepthChart, setCurrDepthChart] = useState(null);
  const [editMode, setEditMode] = useState(defaultEditable);
  const { data, loading, error } = useQuery(
    buildBDDDepthChartQuery({
      includeRinknetPlayer: true,
      includeContracts: true,
    }),
    { variables: { id: depthChartId } }
  );

  const bddDepthChart = data?.bddDepthChart;
  const depthChartPlayers = bddDepthChart?.depthChartPlayers;
  const slTeam = bddDepthChart?.slTeam;

  // Update editable if user doesnt have access
  useEffect(() => {
    if (!!data && bddDepthChart.isEditable == false) {
      setEditMode(false);
    }
  }, [data]);

  // Update editable if "editable" prop changes
  useEffect(() => {
    if (!!data && bddDepthChart.isEditable) {
      setEditMode(editable);
    }
  }, [editable]);

  const [updateBddDepthChart, { loading: mutationLoading }] = useMutation(
    UPDATE_BDD_DEPTH_CHART,
    {
      onCompleted: () => {
        setEditMode(true);
        // setLineupFromData()
      },
      onError: (error) => {
        toastBddApiError(error);
        setLineupFromData();
      },
      update: (
        cache,
        {
          data: {
            updateBddDepthChart: { bddDepthChart, externalEffects },
          },
        }
      ) => {
        // SPECIAL UPDATE CASE: moving players between ACTIVE depth charts
        if (!!externalEffects && externalEffects.length > 0 && bddDepthChart.active) {
          // Remove the DepthChartSpot for each externally effecting player move
          externalEffects.forEach((eff) => {
            if (!!notifyOnExternalEffects) {
              toastInfo(
                `Removing ${eff.playerSlug} from ${eff.depthChart.slTeam.longname} Depth Chart`
              );
            }
            cache.modify({
              id: cache.identify(eff),
              fields: {
                id(_, { DELETE }) {
                  return DELETE;
                },
              },
            });
          });
        }
        if (!!onUpdate) onUpdate(cache, bddDepthChart, externalEffects);
      },
    }
  );

  const playerInfo = useMemo(() => {
    const ret = {};
    if (!!data) {
      depthChartPlayers.forEach((d) => {
        if (!!d.playerSlug) {
          ret[d.bddPlayer.slug] = {
            depthChartPlayer: d,
            bddPlayer: d.bddPlayer,
            rinknetPlayer: d.bddPlayer.rinknetPlayer,
          };
        }
      });
    }
    return ret;
  }, [data]);

  // RENDER PLAYER
  // we inject some props to the renderPlayer func, like the current size of the depth chart column (1-12)

  const injectedProps = {
    size: playerSize,
    colSize: 12 - !!sideMode ? sideModeToWidth[sideMode] : 0,
    playerRenderMode,
    playerSlugs: depthChartPlayers?.map((p) => p.playerSlug),
    depthChartId,
    wrappers: bddDepthChart?.depthChartSpotWrappers,
  };

  renderPlayerArg = !!renderPlayerArg
    ? renderPlayerArg
    : (props) => <DepthChartPlayer {...props} />;

  const renderPlayer = ({ playerSlug, bddPlayer, rinknetPlayer, ...props }) => {
    bddPlayer = !!bddPlayer ? bddPlayer : playerInfo[playerSlug]?.bddPlayer;
    rinknetPlayer = !!rinknetPlayer
      ? rinknetPlayer
      : playerInfo[playerSlug]?.rinknetPlayer;
    return (
      <DCPlayerWrapper
        playerSlug={bddPlayer?.slug}
        highlightColor={!!highlightCallback ? highlightCallback(bddPlayer) : null}
      >
        {renderPlayerArg({
          ...props,
          ...injectedProps,
          playerSlug,
          bddPlayer,
          rinknetPlayer,
        })}
      </DCPlayerWrapper>
    );
  };
  // ? renderPlayerArg
  // : ({ playerSlug, ...rest }) => (

  // );
  // if (!renderPlayerArg) {
  //   // if no render player supplied, we use the DCPlayer component as default
  //   var renderPlayer = ({ playerSlug, ...rest }) => {
  //     const player = playerInfo[playerSlug];
  //     return <DCPlayerWrapper
  //       size={playerSize}
  //       playerSlug={playerSlug}
  //       highlightCallback={highlightCallback}
  //       {...rest}
  //       {...injectedProps}
  //     />
  //     // return <DCPlayer playerSlug={playerSlug} player={player} {...rest} {...injectedProps} />
  //   }
  // } else {
  //   // if render player is supplied, we wrap it in an error boundary and force it to wait for player info
  //   renderPlayer = (args) => {
  //     if (!args.player) {
  //       return <small>Loading...</small>
  //     }
  //     return <BDDErrorBoundary errorContent={<small>{args.playerSlug}</small>}>
  //       {renderPlayerArg({ ...args, ...injectedProps })}
  //     </BDDErrorBoundary>
  //   }
  // }
  const setLineupFromData = () => {
    // Transform list of depthChartPlayers into object: { NHL: {leftwing: [slug, slug...], rightwing...}, ... }
    // Assumes depthChartPlayers are sorted by lineNum (they should be)
    if (!!bddDepthChart) {
      const ret = {};
      // first, fill with all leagues/positions
      Object.values(depthLeagues).forEach((league) => {
        ret[league] = {};
        fPos.forEach((p) => (ret[league][p] = []));
        dgPos.forEach((p) => (ret[league][p] = []));
      });
      data.bddDepthChart.depthChartPlayers.forEach((d) => {
        if (!(d.depthLeague in ret)) ret[d.depthLeague] = {};
        if (!(d.position in ret[d.depthLeague])) ret[d.depthLeague][d.position] = [];
        ret[d.depthLeague][d.position][d.lineNum - 1] = d.playerSlug;
      });
      setCurrDepthChart(ret);
    }
  };

  useEffect(() => {
    if (!loading && !!data) {
      setLineupFromData();
    }
  }, [data]);

  if (!!data && !data.bddDepthChart)
    return (
      <center>
        <em>No depth chart found</em>
      </center>
    );

  const selectedPlayerSlugs = depthChartPlayers?.map((p) => p.playerSlug);

  const findPlayerInDC = (playerSlug) => {
    var ret = null;
    Object.keys(currDepthChart).forEach((league) => {
      Object.keys(currDepthChart[league]).forEach((pos) => {
        currDepthChart[league][pos].forEach((pslug, i) => {
          if (pslug === playerSlug) {
            ret = { depthLeague: league, position: pos, lineNum: i + 1 };
          }
        });
      });
    });
    return ret;
  };

  const handleMovePlayerToSpot = (
    lineupSpot,
    playerSlug,
    adjustMode=adjustModes.SWAP
  ) => {
    if (forceAdjustMode) {
      adjustMode = forceAdjustMode;
    }
    const { depthLeague, position, lineNum } = parseLineupSpot(lineupSpot);

    // "existing" is the player being dragged (if already in depth chart)
    const existing = findPlayerInDC(playerSlug);
    if (
      !!existing &&
      existing.depthLeague == depthLeague &&
      existing.position == position &&
      existing.lineNum == lineNum
    ) {
      return;
    }

    setEditMode(false); // disable editing until operation is complete

    // Check adjustMode which determines how other players move
    if (adjustMode == adjustModes.SWAP) {
      // "droppedOn" is the player who we've dropped onto
      try {
        var droppedOn = currDepthChart[depthLeague][position][lineNum - 1];
      } catch {
        var droppedOn = undefined;
      }

      // Put player into targetted spot
      var updateFn = {
        [depthLeague]: {
          [position]: { [lineNum - 1]: { $set: playerSlug } },
        },
      };
      if (!!existing) {
        // Player already existed in our depth chart and we want to swap
        if (!(existing.depthLeague in updateFn)) {
          updateFn[existing.depthLeague] = {};
        }
        if (!(existing.position in updateFn[existing.depthLeague])) {
          updateFn[existing.depthLeague][existing.position] = {};
        }
        updateFn[existing.depthLeague][existing.position][existing.lineNum - 1] = {
          $set: droppedOn,
        };
      }
    } else if (adjustMode == adjustModes.BUMP) {
      // If we're "BUMP"ing, we splice the player into the targetted spot
      // But we don't splice them out of current spot... we simply set undefined
      var updateFn = {};
      if (!!existing) {
        if (existing.depthLeague == depthLeague) {
          if (existing.position == position) {
            // if moving within position, splice out, splice in
            var updateFn = {
              [depthLeague]: {
                [position]: {
                  $splice: [
                    [existing.lineNum - 1, 1],
                    [lineNum - 1, 0, playerSlug],
                  ],
                },
              },
            };
          } else {
            if (
              currDepthChart[existing.depthLeague][existing.position].length ==
              existing.lineNum
            ) {
              //if player is the last player at position, splice him out
              var updateFn = {
                [depthLeague]: {
                  // we can use "depthLeague" here because we've checked it matches existing.depthLeague
                  [existing.position]: { $splice: [[existing.lineNum - 1, 1]] },
                  [position]: { $splice: [[lineNum - 1, 0, playerSlug]] },
                },
              };
            } else {
              // otherwise, we set undefined where they are and splice into where they're going
              var updateFn = {
                [depthLeague]: {
                  // we can use "depthLeague" here because we've checked it matches existing.depthLeague
                  [position]: { $splice: [[lineNum - 1, 0, playerSlug]] },
                  [existing.position]: { [existing.lineNum - 1]: { $set: undefined } },
                },
              };
            }
          }
        } else {
          // if the leagues are different we just check for last player at position
          if (
            currDepthChart[existing.depthLeague][existing.position].length ==
            existing.lineNum
          ) {
            //if player is the last player at position, splice him out
            var updateFn = {
              [existing.depthLeague]: {
                [existing.position]: { $splice: [[existing.lineNum - 1, 1]] },
              },
              [depthLeague]: {
                [position]: { $splice: [[lineNum - 1, 0, playerSlug]] },
              },
            };
          } else {
            // otherwise, we set undefined where they are and splice into where they're going
            var updateFn = {
              [depthLeague]: {
                // we can use "depthLeague" here because we've checked it matches existing.depthLeague
                [position]: { $splice: [[lineNum - 1, 0, playerSlug]] },
              },
              [existing.depthLeague]: {
                [existing.position]: { [existing.lineNum - 1]: { $set: undefined } },
              },
            };
          }
        }
      } else {
        // player doesn't exist, just splice into destination
        var updateFn = {
          [depthLeague]: {
            [position]: { $splice: [[lineNum - 1, 0, playerSlug]] },
          },
        };
      }
    }
    var newDepthChart = update(currDepthChart, updateFn);

    setCurrDepthChart(newDepthChart);
    updateBddDepthChart({
      variables: {
        input: { id: depthChartId },
        lineupSpotsToSet: [{ depthLeague, position, lineNum, playerSlug, adjustMode }],
      },
    });
  };

  const handleRemovePlayer = (lineupSpot, playerSlug) => {
    const { depthLeague, position, lineNum } = parseLineupSpot(lineupSpot);

    // Now we set their spot as undefined (allowing holes in DCs)
    const newDepthChart = update(currDepthChart, {
      [depthLeague]: {
        [position]: { [lineNum - 1]: { $set: undefined } },
      },
    });

    setCurrDepthChart(newDepthChart);
    updateBddDepthChart({
      variables: {
        input: { id: depthChartId },
        playersToRemove: [playerSlug],
      },
    });
  };

  const toggleSideMode = (mode) =>
    sideMode === mode ? setSideMode(null) : setSideMode(mode);
  const playerRenderOptions = [
    { value: 'info', label: 'Info' },
    { value: 'warOvr', label: 'WAR OVR' },
    { value: 'war55', label: 'WAR 5v5' },
    { value: 'usage', label: 'Usage' },
  ];

  const depthChart = !!data ? data.bddDepthChart : null;
  const slugs = !!currDepthChart ? Object.values(currDepthChart).flat() : [];
  const slUids = slugs.filter((s) => s in playerInfo).map((s) => playerInfo[s].slUid);

  const { renderModal, hideModal } = useContext(ModalContext);

  return (
    <>
      <BDDErrorBoundary>
        <Styles>
          <Container fluid>
            {loading ? (
              <BDDLoader />
            ) : error ? (
              <BDDApiError error={error} />
            ) : !!currDepthChart ? (
              <>
                {showChanges && <DepthChartChanges depthChartId={depthChart.id} />}
                {showButtons && (
                  <Row className="control-row justify-content-center">
                    <Col sm="5">
                      <BDDOptionGroup
                        selected={playerRenderMode}
                        onClick={(val) => setPlayerRenderMode(val)}
                        options={playerRenderOptions}
                      />
                    </Col>
                    <Col sm="2" className="m-auto"></Col>
                    <Col sm="5" style={{ textAlign: 'right' }}>
                      {showProspects && (
                        <>
                          <Button
                            title="Prospects"
                            className="sidebar-button"
                            active={sideMode === 'prospects'}
                            variant="outline-dark"
                            size="sm"
                            onClick={() => toggleSideMode('prospects')}
                          >
                            Prospects
                          </Button>{' '}
                        </>
                      )}
                      {showLists && (
                        <>
                          <Button
                            title="Lists"
                            className="sidebar-button"
                            active={sideMode === 'lists'}
                            variant="outline-dark"
                            size="sm"
                            onClick={() => toggleSideMode('lists')}
                          >
                            Lists
                          </Button>{' '}
                        </>
                      )}
                      <Button
                        title="Analytics"
                        className="sidebar-button"
                        active={sideMode === 'analytics'}
                        variant="outline-dark"
                        size="sm"
                        onClick={() => toggleSideMode('analytics')}
                      >
                        Analytics
                      </Button>
                      {/* {' '}
                    <Button
                      title='Salary Cap Info'
                      className='sidebar-button'
                      active={sideMode === 'cap'}
                      variant='outline-dark' size='sm'
                      onClick={() => toggleSideMode('cap')}
                    >
                      Salary Cap
                    </Button> */}{' '}
                      <Button
                        title="Rinknet Depth Chart"
                        className="sidebar-button"
                        active={sideMode === 'rinknet'}
                        variant="outline-dark"
                        size="sm"
                        onClick={() => toggleSideMode('rinknet')}
                      >
                        Rinknet
                      </Button>{' '}
                      <Button
                        className="sidebar-button"
                        variant="outline-dark"
                        size="sm"
                        onClick={() =>
                          renderModal({
                            title: (
                              <Typography variant="h6">
                                Add Depth Chart Spot Wrapper
                              </Typography>
                            ),
                            body: (
                              <DepthChartSpotWrapperForm
                                depthChartId={depthChart.id}
                                existingWrappers={depthChart.depthChartSpotWrappers}
                                onSubmit={hideModal}
                                onCancel={hideModal}
                              />
                            ),
                          })
                        }
                      >
                        Wrappers
                      </Button>
                    </Col>
                  </Row>
                )}
                <DisappearingRow style={{ borderTop: '2px solid #fff' }}>
                  <Col>
                    <DraggableDepthChart
                      id={depthChart.id}
                      depthLeague={depthLeagues.NHL}
                      depthChart={currDepthChart[depthLeagues.NHL]}
                      spotWrappers={bddDepthChart?.depthChartSpotWrappers}
                      slugToPlayer={playerInfo}
                      editMode={editMode}
                      renderPlayer={renderPlayer}
                      onPlayerDrop={handleMovePlayerToSpot}
                      onRemovePlayer={handleRemovePlayer}
                      onClickAddPlayer={(lineupSpot) =>
                        setAddPlayer({ show: true, lineupSpot })
                      }
                      playerSize={playerSize}
                      onUpdate={onUpdate}
                      refetchWrapperQueries={refetchWrapperQueries}
                    />
                    {nonNHLDisplayMode === 'expandable' && (
                      <div style={{ marginTop: '5px', fontSize: '0.6em' }}>
                        <a
                          href=""
                          style={{ color: 'black' }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowNonNHL(!showNonNHL);
                          }}
                        >
                          <em>{showNonNHL ? 'Hide' : 'Show'} Non-NHL Depth</em>
                        </a>
                      </div>
                    )}
                    {nonNHLDisplayMode === 'hidden' ? null : (
                      <Collapse in={showNonNHL}>
                        <div>
                          <div className="non-nhl-depth-container">
                            <SubSectionHeader
                              style={{
                                paddingTop: nonNHLDisplayMode === 'expandable' ? 0 : 12,
                                fontSize:
                                  nonNHLDisplayMode === 'expandable' ? '0.8em' : '1.2em',
                              }}
                            >
                              AHL
                            </SubSectionHeader>
                            <DraggableDepthChart
                              id={depthChart.id}
                              depthLeague={depthLeagues.AHL}
                              depthChart={currDepthChart[depthLeagues.AHL]}
                              slugToPlayer={playerInfo}
                              editMode={editMode}
                              renderPlayer={renderPlayer}
                              onPlayerDrop={handleMovePlayerToSpot}
                              onRemovePlayer={handleRemovePlayer}
                              onClickAddPlayer={(lineupSpot) =>
                                setAddPlayer({ show: true, lineupSpot })
                              }
                              playerSize={playerSize}
                              onUpdate={onUpdate}
                            />
                          </div>
                          <div className="non-nhl-depth-container">
                            <SubSectionHeader
                              style={{
                                paddingTop: nonNHLDisplayMode === 'expandable' ? 0 : 12,
                                fontSize:
                                  nonNHLDisplayMode === 'expandable' ? '0.8em' : '1.2em',
                              }}
                            >
                              Prospect
                            </SubSectionHeader>
                            <DraggableDepthChart
                              id={depthChart.id}
                              depthLeague={depthLeagues.Prospect}
                              depthChart={currDepthChart[depthLeagues.Prospect]}
                              slugToPlayer={playerInfo}
                              editMode={editMode}
                              renderPlayer={renderPlayer}
                              onPlayerDrop={handleMovePlayerToSpot}
                              onRemovePlayer={handleRemovePlayer}
                              onClickAddPlayer={(lineupSpot) =>
                                setAddPlayer({ show: true, lineupSpot })
                              }
                              playerSize={playerSize}
                              onUpdate={onUpdate}
                            />
                          </div>
                        </div>
                      </Collapse>
                    )}
                    <Typography
                      variant="caption"
                      style={{ height: '25px', float: 'right' }}
                    >
                      {mutationLoading ? (
                        <em>
                          <small>Saving...</small>
                        </em>
                      ) : showMiniEditButton ? (
                        <a
                          href=""
                          style={{ color: 'black' }}
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(!editMode);
                          }}
                        >
                          <em>{editMode ? 'Done' : 'Edit'}</em>
                        </a>
                      ) : null}
                    </Typography>
                  </Col>
                  <DisappearingCol
                    size={sideModeToWidth[sideMode]}
                    isvisible={!!sideMode}
                    style={!!sideMode ? { borderLeft: '2px solid #ddd' } : null}
                  >
                    <a
                      href=""
                      style={{ fontSize: '0.8em', fontStyle: 'italic' }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setSideMode(null);
                      }}
                    >
                      Hide
                    </a>
                    {sideMode === 'rinknet' ? (
                      <BDDErrorBoundary>
                        <RinknetBench
                          teamSlug={slTeam?.slug}
                          skipPlayerSlugs={selectedPlayerSlugs}
                          renderPlayer={renderPlayer}
                          slugToPlayer={playerInfo}
                        />
                      </BDDErrorBoundary>
                    ) : sideMode === 'cap' ? (
                      <BDDErrorBoundary>
                        <DepthChartAnalyzer depthChartId={depthChart.id} />
                      </BDDErrorBoundary>
                    ) : sideMode === 'analytics' ? (
                      <TeamSkatersScatter
                        league="NHL"
                        teamSlug={data.bddDepthChart.slTeam.slug}
                        slUids={slUids}
                        minToi={0}
                      />
                    ) : showProspects && sideMode === 'prospects' ? (
                      <BDDErrorBoundary>
                        <DepthChartProspectRankings
                          depthChartId={data.bddDepthChart.id}
                        />
                      </BDDErrorBoundary>
                    ) : showLists && sideMode === 'lists' ? (
                      <BDDErrorBoundary>
                        <DepthChartLists />
                      </BDDErrorBoundary>
                    ) : null}
                  </DisappearingCol>
                </DisappearingRow>
              </>
            ) : null}
          </Container>
        </Styles>
      </BDDErrorBoundary>
      {addPlayer.show && (
        <DCSetPlayerForSpotModal
          show={addPlayer.show}
          handleClose={() => setAddPlayer({ show: false, lineupSpot: null })}
          depthChartTitle={depthChart.title}
          onAddPlayer={(lineupSpot, playerSlug) =>
            handleMovePlayerToSpot(lineupSpot, playerSlug, adjustModes.BUMP)
          }
          // onAddPlayer={playerSlug => handleMovePlayerToSpot(addPlayer.lineupSpot, playerSlug)}
          maxLineupSpot={addPlayer.lineupSpot}
        />
      )}
    </>
  );
}
