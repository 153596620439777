import React from 'react';
import styled from 'styled-components';
import { theme } from 'constants';
import { roundToX } from 'helpers/data';
import { PlayerName } from 'components/Lists/Builder/components/PlayerBio';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';

const Id = styled.div({
  color: theme.colors.light.text.secondary,
});

export const IssueTitle = ({
  playerName: playerNameArg,
  player,
  details,
  issue,
  useHover,
  showId,
  variant = 'h5',
}) => {
  const playerName =
    playerNameArg ||
    (player ? (
      useHover ? (
        <PlayerName bdd={player} rp={player.rinknetPlayer} minWidth={0} />
      ) : (
        `${player.rinknetPlayer.firstname} ${player.rinknetPlayer.lastname} `
      )
    ) : null);

  let description;
  const modelScore = roundToX(details.model_score, 0);

  switch (issue.issueType) {
    case 'LOW_VIEWS':
      const viewDifference = Math.ceil(details.expected_views - details.actual_views);
      const viewDescription =
        details.actual_views == 0
          ? ` has not yet been viewed`
          : ` only viewed ${details.actual_views} time(s). Needs ${viewDifference} more view(s)`;

      description = (
        <Row columnGap={1} flexWrap>
          {playerName}
          {` has an expected pick of #${modelScore} but `}
          {viewDescription}
        </Row>
      );
      break;
    case 'NOT_ON_LIST':
      const expectedPick = roundToX(details.expected_pick, 0);

      description = (
        <Row columnGap={1} flexWrap>
          {playerName}
          {` is currently not on the list, but expected to be #${expectedPick} based on their model score.`}
        </Row>
      );
      break;
    default:
      'I NEED A PROPER ISSUE DESCRIPTION';
  }

  return (
    <Container>
      <Typography variant={variant}>
        <Row columnGap={3} alignItems="baseline" flexWrap>
          {description}
          {showId && <Id># {issue.id}</Id>}
        </Row>
      </Typography>
    </Container>
  );
};
