import React from "react"
import InputRange from "react-input-range"
import styled from "styled-components"
import "react-input-range/lib/css/index.css"
import { roundToX } from "../../helpers/data";

const RangeSliderContainer = styled.div`
    padding: 0px 8px 10px 8px;
    .input-range__track--active,
    .input-range__slider {
        background: ${props => props.color}; 
        border-color: ${props => props.color};
    }
`;

export default function BDDRangeSlider({ color, 
    name, 
    maxValue, 
    minValue, 
    step, 
    value,
    handleChange, 
    handleChangeComplete,
    formatLabel= (value, type) => type === 'value' ? `${roundToX(value, 2)}` : null
}) {
    return <RangeSliderContainer color={color || "black"}>
        <InputRange
            name={name}
            maxValue={maxValue}
            minValue={minValue}
            step={step}
            formatLabel={formatLabel}
            value={value}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete} 
        />
    </RangeSliderContainer>
}