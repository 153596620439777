import { useQuery } from '@apollo/client';
import {
  buildBDDDepthChartQuery,
  GET_TEAM_ACTIVE_DEPTH_CHART,
} from 'apollo/queries/depthchart.queries';
import {
  GET_COMMON_LINEUP,
  GET_LINEUP_STATS,
} from 'apollo/queries/lineup.queries';
import { GET_BDD_TEAMS } from 'apollo/queries/teams.queries';
import DraggableLineup from 'components/bdd/draggablelineup';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { useSeasonSelect, useSelect } from 'components/bdd/Select';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { SLStatContextProvider, SLStatline } from 'components/SLStats';
import { StatReportFilter } from 'components/SLStats/filters';
import { useSLStatContext } from 'components/SLStats/hooks';
import { range } from 'helpers/data';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { useEffect, useState } from 'react';

export const TeamLineupSandbox = ({ primaryLineup, leagueSlug, teamSlug }) => {
  const { data: teamsData, placeholder: teamsPlaceholder } = usePlaceholder(
    useQuery(GET_BDD_TEAMS, {
      variables: { leagueSlug: leagueSlug.toLowerCase() },
    })
  );

  const { toggleComponent: onlyTeamPlayersToggle, toggled: onlyTeamPlayers } =
    useToggle({
      id: `onlyTeamPlayers-${primaryLineup}${leagueSlug}${teamSlug}`,
      label: 'Only show players currently on team',
      initialToggled: true,
    });

  const { select: teamSelect, selected: selectedTeam } = useSelect({
    variant: 'outlined',
    initialSelectedValue: teamSlug,
    options:
      teamsData?.bddTeams.map((t) => ({
        value: t.slTeamSlug,
        label: t.longname,
      })) || [],
    updateOnOptionsChange: true,
    selectProps: {
      isDisabled: !!primaryLineup,
    },
  });

  const { seasonSelect, season } = useSeasonSelect();
  const selectedTeamSlug = selectedTeam?.value || teamSlug;

  const { data: depthChartData, placeholder: depthChartPlaceholder } =
    usePlaceholder(
      useQuery(buildBDDDepthChartQuery({}), {
        variables: { teamSlug: selectedTeamSlug },
      })
    );

  const { data: commonLineupData, placeholder: commonLineupPlaceholder } =
    usePlaceholder(
      useQuery(GET_COMMON_LINEUP, {
        variables: {
          teamSlug: selectedTeamSlug,
          season: season || getCurrentSeason(),
          onlyTeamPlayers,
        },
      })
    );

  const placeholder =
    teamsPlaceholder || commonLineupPlaceholder || depthChartPlaceholder;
  const lineupSpots = commonLineupData?.commonLineupV3;

  const unitStatDefaults = {
    filters: {
      min_toi: 10,
      norm: 20,
      league: leagueSlug,
      season,
      strength: 'ES55',
      teams: [selectedTeamSlug],
    },
  };

  const linesContext = useSLStatContext({
    defaults: {
      target: 'lines',
      ...unitStatDefaults,
    },
  });

  const pairsContext = useSLStatContext({
    defaults: {
      target: 'pairs',
      ...unitStatDefaults,
    },
  });

  useEffect(() => {
    linesContext.setFilters({ season, teams: [selectedTeamSlug] });
    pairsContext.setFilters({ season, teams: [selectedTeamSlug] });
  }, [selectedTeamSlug, season]);

  const initialLineup = lineupSpots?.reduce((lineup, lineupSpot) => {
    lineup[`${lineupSpot.position}${lineupSpot.lineNum}`] =
      lineupSpot.slPlayer.slPlayerMaster.bddPlayerSlug.slug;

    return lineup;
  }, {});

  const lineupSpotPlayers = lineupSpots?.reduce((playerInfo, lineupSpot) => {
    const bddPlayerSlug = lineupSpot.slPlayer.slPlayerMaster.bddPlayerSlug.slug;
    playerInfo[bddPlayerSlug] = {
      ...lineupSpot.slPlayer,
      slug: bddPlayerSlug,
    };

    return playerInfo;
  }, {});

  const depthChartPlayers =
    depthChartData?.bddDepthChart.depthChartPlayers?.reduce(
      (playerInfo, depthChartPlayer) => {
        const bddPlayerSlug = depthChartPlayer.bddPlayer.slug;
        playerInfo[bddPlayerSlug] = depthChartPlayer.bddPlayer;

        return playerInfo;
      },
      {}
    );

  const playerInfo = { ...lineupSpotPlayers, ...depthChartPlayers };
  const [lineup, setLineup] = useState(initialLineup);
  useEffect(() => {
    if (!initialLineup) return;

    setLineup(initialLineup);
  }, [placeholder, onlyTeamPlayers]);

  return (
    <Container width="100%" padding={3}>
      <Column>
        <Row columnGap={3} flexWrap>
          {teamSelect} {seasonSelect} {onlyTeamPlayersToggle}
        </Row>
        {placeholder ||
          (lineup && (
            <DraggableLineup
              lineup={lineup}
              setLineup={setLineup}
              editMode
              playerInfo={playerInfo}
              maxForwardRows={4}
              maxDefenceRows={3}
              renderPlayer={(bddPlayer) => {
                return (
                  <span>
                    <Typography variant="body1" textAlign="center">
                      {bddPlayer.firstname} {bddPlayer.lastname}
                    </Typography>
                  </span>
                );
              }}
              renderForwardLineupContent={() => (
                <LineStatGrid
                  isF
                  lineup={lineup}
                  playerInfo={playerInfo}
                  statContext={linesContext}
                />
              )}
              renderDefensemenLineupContent={() => (
                <LineStatGrid
                  isF={false}
                  lineup={lineup}
                  playerInfo={playerInfo}
                  statContext={pairsContext}
                />
              )}
              noGoalie
            />
          ))}
      </Column>
    </Container>
  );
};

const LineStatGrid = ({ lineup, playerInfo, statContext, isF }) => {
  const statLines = range(1, 4).reduce((statLines, lineNumber) => {
    const positions = isF
      ? ['leftwing', 'center', 'rightwing']
      : ['leftdefence', 'rightdefence'];

    const targetSlugs = positions.map((p) => lineup[`${p}${lineNumber}`]);
    const targetPlayers = targetSlugs.map((s) => playerInfo[s]);
    const targetLineData = statContext.stats?.find((d) =>
      d.slugs.every((s) => targetSlugs.includes(s))
    );

    if (targetPlayers.every((p) => !!p)) {
      statLines.push({
        data: targetLineData,
        players: targetPlayers,
      });
    }

    return statLines;
  }, []);

  return (
    <Container padding={2}>
      <SLStatContextProvider context={statContext}>
        <StatReportFilter hideEditStatsButton />
        {statContext.data ? (
          <Row alignItems="start" columnGap={3}>
            <Container width={150}>
              <Typography variant="stat">{isF ? 'Lines' : 'Pairs'}</Typography>
              {statLines.map((sl, index) => (
                <Container height={20}>
                  <Typography
                    key={`sl-${isF}-label-${index}`}
                    variant="stat"
                    noWrap
                  >
                    <b>{sl.players.map((p) => p.lastname).join('-')}</b>
                  </Typography>
                </Container>
              ))}
            </Container>
            <Container overflow="auto" paddingRight={2}>
              {statLines.map((sl, index) => (
                <SLStatline
                  key={`sl-${isF}-${index}`}
                  data={statContext?.data}
                  row={sl.data}
                  excludeStatLabels={index > 0}
                />
              ))}
            </Container>
          </Row>
        ) : (
          <Typography textAlign="center" variant="stat">
            Loading Stats...
          </Typography>
        )}
      </SLStatContextProvider>
    </Container>
  );
};
