import * as React from "react"

import "./bddformerror.css"

export default function BDDFormError(props) {
    return (
        <span className={`bdd-form-error ${props.className}`} style={props.style}>
            {props.children}
        </span>
    )
}