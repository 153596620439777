import React from "react"
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDragLayer } from 'react-dnd'
import { itemTypes } from "../../constants";
import { PLPlayer } from "./playerlistplayer";

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};
function getItemStyles(itemType, initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;
    if (itemType === itemTypes.PLAYER_LIST_TIER) {
        var transform = `translate(${initialOffset.x+15}px, ${y+8}px)`;
    } else {
        var transform = `translate(${x}px, ${y}px)`;
    }
    return {
        transform,
        WebkitTransform: transform
    };
}
export const ListDragLayer = ({ listNumberInMotion, idToPlayer }) => {
    const { itemType, isDragging, item, initialOffset, currentOffset, didDrop } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
        didDrop: monitor.didDrop()
    }));
    // const x = {itemType, isDragging, item, initialOffset, currentOffset, didDrop}
    function renderItem() {
        if (itemType === itemTypes.PLAYER_LIST_TIER) {
            return <div style={{ 
                height: '30px', 
                width: '30px',
                background: 'white', 
                border: '3px solid #333', 
                borderRadius: '50%',
                textAlign: 'center'
            }}>
                <FontAwesomeIcon icon={faSort}/>
            </div>
        } else if (itemType === itemTypes.PLAYER_LIST_PLAYER) {
            return <PLPlayer 
                player={idToPlayer[item.id]} 
                listNumber={listNumberInMotion} 
                allowMove={false}
                inMotion={true}
                width={'50%'}
                allowRemoval={false}
            />
        }
        return null
    }
    if (!isDragging) {
        return null;
    }
    return <div style={layerStyles}>
        <div style={getItemStyles(itemType, initialOffset, currentOffset)}>
            {renderItem()}
        </div>
    </div>
}