import { IconButton, LinkButton } from 'components/bdd/Button';
import { useSearchParamsStore } from 'components/bdd/hooks';
import { Column, Container, Row } from 'components/bdd/Layout';
import { EPLink } from 'components/bdd/lineupcomponents';
import { Typography } from 'components/bdd/Typography';
import {
  PlayerActiveContract,
  PlayerDraftYear,
  PlayerInfo,
  PlayerLeague,
  PlayerPosition,
} from 'components/Lists/Builder/components/PlayerBio';
import { theme } from 'constants';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { buildTeamRoute } from 'helpers/routing';
import { XLg } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { buildPlayerRoute } from 'helpers/routing';

export const ComparePlayerBio = ({ bddPlayer, onRemove }) => {
  const store = useSearchParamsStore();
  const rinknetPlayer = bddPlayer.rinknetPlayer;

  const teamLogo = rinknetPlayer.currentteamnhlid && (
    <Container
      style={{ alignSelf: 'center' }}
      to={
        !!rinknetPlayer.currentteamslug
          ? buildTeamRoute(rinknetPlayer.currentteamslug)
          : null
      }
    >
      <img
        height={24}
        style={{ marginLeft: -theme.spacing[2] }}
        src={getNHLTeamLogoUrl(rinknetPlayer.currentteamnhlid)}
      />
    </Container>
  );

  return (
    <Container>
      <Column>
        <Row columnGap={2} justifyContent="space-between">
          <Row columnGap={2}>
            <Row>
              {teamLogo}
              <PlayerHover playerSlug={bddPlayer.slug}>
                <Link to={buildPlayerRoute(bddPlayer.slug)}>
                  <LinkButton>
                    <Typography variant="h6">
                      {rinknetPlayer.firstname} {rinknetPlayer.lastname} #
                      {rinknetPlayer.jerseynumber}
                    </Typography>
                  </LinkButton>
                </Link>
              </PlayerHover>
            </Row>
            <Typography variant="h6">
              <EPLink
                epid={rinknetPlayer.eliteprospectsid}
                firstname={rinknetPlayer.firstname}
                lastname={rinknetPlayer.lastname}
              />
            </Typography>
          </Row>
          {(!!store.paramExists('selectedPlayerSlugs') || !!onRemove) && <IconButton
            size="sm"
            icon={<XLg />}
            hoverColor={theme.colors.teams.bos.primary}
            tooltip="Remove this player"
            onClick={() => {
              if (onRemove) onRemove(bddPlayer.slug);
              else if (store.paramExists('selectedPlayerSlugs')) {
                store.setArray(
                  'selectedPlayerSlugs',
                  store
                    .getArray('selectedPlayerSlugs')
                    .filter((s) => s !== bddPlayer.slug)
                )
              }
            }}
          />}
        </Row>
        <Row columnGap={1}>
          <PlayerInfo rp={rinknetPlayer} />
          {!!rinknetPlayer.draftyear && (
            <>
              <Typography variant="body2">|</Typography>
              <PlayerDraftYear rp={rinknetPlayer} />
            </>
          )}
        </Row>
        <Row columnGap={3}>
          <PlayerLeague rp={rinknetPlayer} />
          <PlayerActiveContract bd={bddPlayer} />
          <PlayerPosition rp={rinknetPlayer} />
        </Row>
      </Column>
    </Container>
  );
};
