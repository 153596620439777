import React, { useEffect, useState, useRef } from 'react';
import useOnScreen from 'components/bdd/hooks/useonscreen';

export default function VisibilityChangeWrapper({ 
  callback, 
  children,
  hideChildren=false,
  defaultOnScreen=true // if true, we expect the wrapper will start out on screen
}) {
  // This component wraps around children components
  // Detects when it's on screen state changes and calls "callback"
  const fetchButtonRef = useRef()
  const [prevIsVis, setPrevIsVis] = useState(defaultOnScreen)
  const isVisible = useOnScreen(fetchButtonRef, defaultOnScreen) // we expect it to be on screen at first

  useEffect(() => {
      if (prevIsVis === false && isVisible === true) {
        callback && callback()
      }
      setPrevIsVis(isVisible)
  }, [isVisible])

  return <div ref={fetchButtonRef}>
    {(!hideChildren || isVisible) && children}
  </div>
}
