import { Column, Container, Row } from 'components/bdd/Layout';
import { useSimilarContractsContext } from './context';
import { Typography } from 'components/bdd/Typography';
import { NumberInput } from 'components/bdd/NumberInput/NumberInput';
import { Filters, InputRangeFilter } from 'components/bdd/Filters';
import { Button } from 'react-bootstrap';
import { useRef, useState } from 'react';
import { getStatNumericalWeightOptions, getTargetVectorWeights } from './helpers';
import { roundToX } from 'helpers/data';
import { categoricalFilterOptions, numericalWeightOptions } from './constants';
import { booleanOptions } from '../constants';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import produce from 'immer';

export const SimilarContractsTargetWeights = ({ onChange }) => {
  const targetWeights = useSimilarContractsContext((state) => state.targetWeights);
  const targetVector = useSimilarContractsContext((state) => state.targetVector);
  const { setState } = useSimilarContractsContext((state) => state.actions);

  return (
    <TargetWeights
      categoricalWeightOptions={Object.values(categoricalFilterOptions).map((o) => ({
        ...o,
        value: o.col,
      }))}
      numericalWeightOptions={numericalWeightOptions}
      targetWeights={targetWeights}
      targetVector={targetVector}
      onChange={(data) => {
        setState(data);
        onChange && onChange();
      }}
    />
  );
};

export const TargetWeights = ({
  onChange,
  targetWeights,
  targetVector,
  categoricalWeightOptions,
  numericalWeightOptions,
}) => {
  const { setState } = useSimilarContractsContext((state) => state.actions);

  const ref = useRef();
  const [tempTargetWeights, setTempTargetWeights] = useState(targetWeights);
  const [tempTargetVector, setTempTargetVector] = useState(targetVector);
  const dirty =
    JSON.stringify(tempTargetWeights) != JSON.stringify(targetWeights) ||
    JSON.stringify(tempTargetVector) != JSON.stringify(targetVector);
  const allWeights = getTargetVectorWeights(tempTargetVector, tempTargetWeights);

  const filterUsedWeights = (option) =>
    !(option.value in tempTargetWeights.numericalWeights) &&
    !(option.value in tempTargetWeights.categoricalWeights);

  const weightOptions = [
    {
      label: 'Numerical Weights',
      options: getStatNumericalWeightOptions(tempTargetVector, tempTargetWeights)
        .concat(numericalWeightOptions)
        .filter(filterUsedWeights),
    },
    {
      label: 'Categorical Weights',
      options: categoricalWeightOptions.filter(filterUsedWeights),
    },
  ];

  return (
    <Container padding={1}>
      <Column gap={1}>
        <Typography variant="body1">Set Target Weights:</Typography>
        <Container width={'100%'}>
          <StyledBDDSelect
            size="sm"
            options={weightOptions}
            selectProps={{
              placeholder: 'Add weight...',
              isSearchable: true,
            }}
            onChange={(n, v) => {
              setTempTargetWeights(
                produce(tempTargetWeights, (draft) => {
                  const weightIsNumerical = weightOptions[0].options.find(
                    (o) => o.value == v
                  );

                  if (weightIsNumerical) {
                    draft.numericalWeights[v] = 1;
                  } else {
                    draft.categoricalWeights[v] = 1;
                  }
                })
              );

              ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }}
          />
        </Container>
        <Container paddingTop={2} maxHeight={300} overflow="auto">
          <Column gap={1}>
            {Object.keys(tempTargetWeights.numericalWeights).map((wKey) => {
              const targetWeight = allWeights.find((w) => w.key == wKey);

              return (
                <Container key={targetWeight.header}>
                  <Filters
                    onChange={(filters) => {
                      if (!filters.weight) {
                        setTempTargetWeights(
                          produce((draft) => {
                            delete draft.numericalWeights[wKey];
                          })
                        );
                      } else {
                        setTempTargetVector({
                          ...tempTargetVector,
                          [wKey]: filters.value.value,
                        });

                        setTempTargetWeights((tempTargetWeights) => ({
                          ...tempTargetWeights,
                          numericalWeights: {
                            ...tempTargetWeights.numericalWeights,
                            [wKey]: filters.weight.value,
                          },
                        }));
                      }
                    }}
                    defaults={{
                      value: {
                        id: 'value',
                        value: roundToX(targetVector[wKey], 2),
                      },
                      weight: {
                        id: 'weight',
                        value: targetWeight.weight,
                      },
                    }}
                  >
                    <Row columnGap={3}>
                      <Container width={120}>
                        <Filters.Number label={targetWeight.header} id={'value'} />
                      </Container>
                      <Filters.Number
                        step={0.1}
                        label={'Weight'}
                        id={'weight'}
                        isClearable
                      />
                    </Row>
                  </Filters>
                </Container>
              );
            })}
            {Object.keys(tempTargetWeights.categoricalWeights).map((wKey) => {
              const targetWeight = allWeights.find((w) => w.key == wKey);
              const categoricalOptions = categoricalWeightOptions.find(
                (o) => o.value == wKey
              );

              return (
                <Container key={targetWeight.header}>
                  <Row columnGap={3}>
                    <Filters
                      onChange={(filters) => {
                        if (!filters.weight) {
                          setTempTargetWeights(
                            produce((draft) => {
                              delete draft.categoricalWeights[wKey];
                            })
                          );
                        } else {
                          setTempTargetVector({
                            ...tempTargetVector,
                            [wKey]: filters.value.value,
                          });

                          setTempTargetWeights((tempTargetWeights) => ({
                            ...tempTargetWeights,
                            categoricalWeights: {
                              ...tempTargetWeights.categoricalWeights,
                              [wKey]: filters.weight.value,
                            },
                          }));
                        }
                      }}
                      defaults={{
                        value: {
                          id: 'value',
                          value: targetVector[wKey],
                        },
                        weight: {
                          id: 'weight',
                          value: targetWeight.weight,
                        },
                      }}
                    >
                      <Row columnGap={3} justifyContent="space-between">
                        {categoricalOptions.type == 'select' && (
                          <Filters.Select
                            id={'value'}
                            {...categoricalOptions}
                            width={120}
                          />
                        )}
                        {categoricalOptions.type == 'boolean' && (
                          <Filters.Select
                            id={'value'}
                            {...categoricalOptions}
                            options={booleanOptions}
                            width={120}
                          />
                        )}
                        <Filters.Number
                          step={0.1}
                          label={'Weight'}
                          id={'weight'}
                          isClearable
                        />
                      </Row>
                    </Filters>
                  </Row>
                </Container>
              );
            })}
          </Column>
          <div style={{ height: 20 }} ref={ref} />
        </Container>
        <Container paddingTop={2}>
          <Button
            variant={dirty ? 'dark' : 'outline-dark'}
            size="sm"
            onClick={() => {
              setState({
                targetVector: tempTargetVector,
                targetWeights: tempTargetWeights,
              });

              onChange && onChange();
            }}
          >
            <Typography variant="stat">Apply</Typography>
          </Button>
        </Container>
      </Column>
    </Container>
  );
};
