import { useQuery } from '@apollo/client';
import { GET_SL_GAME } from 'apollo/queries/game.queries';
import { Container } from 'components/bdd/Layout';
import { useContext } from 'react';
import { VideoContext } from '../..';
import { SelectFilter } from './SelectFilter';

export const GameTeamFilter = ({}) => {
  const { sourceFilters } = useContext(VideoContext);
  const gameUid = sourceFilters['game_uid'].val;

  // Get game information
  const { data: gameData, loading } = useQuery(GET_SL_GAME, {
    skip: !gameUid,
    variables: { gameId: gameUid },
  });

  // Filter should only be used for one game
  const game = gameData?.slGame;

  const options = game
    ? [
        {
          value: null,
          label: 'Both Teams',
        },
        {
          value: game.homeTeam.slug,
          label: game.homeTeam.longname,
        },
        {
          value: game.awayTeam.slug,
          label: game.awayTeam.longname,
        },
      ]
    : [];

  return (
    <Container minWidth={150}>
      <SelectFilter
        col={'team_slug'}
        label={'Team Selection'}
        options={options}
        loading={loading}
      />
    </Container>
  );
};
