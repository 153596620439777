import { permissionOptions } from 'components/PlayerNotes/helpers';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { PersonCircle, XLg } from 'react-bootstrap-icons';
import { BDDLoader } from '../bddloader';
import { IconButton } from '../Button';
import { Divider } from '../Divider';
import { Column, Container, Row } from '../Layout';
import { useSelect, useUserGroupSelect, useUserSelect } from '../Select';
import { Typography } from '../Typography';

export const VisibilityForm = ({ userAccess, requiresPermission, onChange }) => {
  const [users, setUsers] = useState(userAccess || []);
  const { userOptions, userId: userToAdd, userSelect } = useUserSelect();
  const { userIds: usersToAdd, userGroupSelect } = useUserGroupSelect();

  const handleAddUser = (userToAdd) => {
    handleAddUsers([userToAdd]);
  };

  const handleAddUsers = (userIdsToAdd) => {
    const usersToAdd = userIdsToAdd
      .map((userToAdd) => {
        const userExists = users.find((u) => u.userId == userToAdd);

        return (
          !userExists && {
            userId: userToAdd,
            canView: true,
            canEdit: false,
          }
        );
      })
      .filter((userToAdd) => userToAdd);

    setUsers([...users, ...usersToAdd]);
  };

  const { select: permissionSelect, selectedValue } = useSelect({
    initialSelectedValue: requiresPermission,
    options: permissionOptions,
    variant: 'outlined',
    selectProps: {
      menuPlacement: 'auto',
    },
  });

  const selectedUserData = userOptions
    ?.filter((uo) => users.find((u) => u.userId == uo.value))
    .map((uo) => uo.user);

  useEffect(() => {
    if (
      selectedValue == requiresPermission &&
      JSON.stringify(users) == JSON.stringify(userAccess)
    ) {
      return;
    }

    onChange &&
      onChange({
        requiresPermission: selectedValue,
        users,
      });
  }, [selectedValue, users.join(',')]);

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      {userOptions ? (
        <Column gap={2}>
          <Row columnGap={4} justifyContent="space-between">
            <Typography variant="body2">Requires Permission:</Typography>
            {permissionSelect}
          </Row>
          <Divider />
          <Row columnGap={3} justifyContent="space-between">
            <Container width={200}>{userSelect}</Container>
            <Button variant="outline-dark" onClick={() => handleAddUser(userToAdd)}>
              <Typography variant="body1">Add</Typography>
            </Button>
          </Row>
          <Row columnGap={3} justifyContent="space-between">
            <Container width={200}>{userGroupSelect}</Container>
            <Button variant="outline-dark" onClick={() => handleAddUsers(usersToAdd)}>
              <Typography variant="body1">Add</Typography>
            </Button>
          </Row>
          <Column>
            {selectedUserData.map((su) => (
              <Container key={su.id}>
                <Row justifyContent="space-between">
                  <Row columnGap={3}>
                    <PersonCircle />
                    <Typography variant="body2">{su.username}</Typography>
                  </Row>
                  <IconButton
                    icon={<XLg />}
                    onClick={() => setUsers(users.filter((u) => u.userId != su.id))}
                  />
                </Row>
              </Container>
            ))}
          </Column>
        </Column>
      ) : (
        <BDDLoader variant="squares" />
      )}
    </Container>
  );
};
