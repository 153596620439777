import { useBddStatQueryV2 } from 'apollo/queries';

export const useTeamGamePlayerData = ({
  teamSlug,
  leagueSlug,
  season,
  gameType,
  target = 'skaters',
}) => {
  const { data, loading } = useBddStatQueryV2(
    {
      target,
      filters: [
        {
          col: 'team_slug',
          val: teamSlug,
        },
        {
          col: 'league_slug',
          val: leagueSlug,
        },
        {
          col: 'season',
          val: season,
        },
        {
          col: 'game_type',
          val: gameType,
        },
      ],
      index: [
        'Player',
        'player_id',
        'league_slug',
        'position',
        'game_uid',
        'game_date',
        'game_type',
        'team_slug',
        'team_shorthand',
        'opp_team_shorthand',
      ],
      statSlugs: [],
    },
    !leagueSlug || !season
  );

  const games =
    data?.data &&
    Object.values(
      data.data.reduce((games, d) => {
        if (d.game_uid in games) {
          return games;
        }

        games[d.game_uid] = d;
        return games;
      }, {})
    ).sort((a, b) => new Date(b.game_date) - new Date(a.game_date));

  const players =
    data?.data &&
    Object.values(
      data.data.reduce((players, d) => {
        if (d.player_id in players) {
          return players;
        }

        players[d.player_id] = d;
        return players;
      }, {})
    );

  return {
    loading,
    games,
    players,
  };
};
