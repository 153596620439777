import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { highlightCallback } from './helpers';
import { formatBddStat } from 'helpers/format';
import { theme } from 'constants';

export const PlayerHighlightStats = ({
  bddPlayer,
  seasonStatContext,
  recentGamesStatContext,
  target,
}) => {
  const { stats: seasonStats, data: seasonStatData } = seasonStatContext;
  const { stats: recentStats } = recentGamesStatContext;

  if (!seasonStats || !recentStats) {
    return <Typography variant="body2">Loading...</Typography>;
  }

  const statToHighlight = target == 'skaters' ? 'PBR' : 'GBR';
  const recentStatsForPlayer = recentStats.find(
    (s) =>
      s.slug == bddPlayer.slug &&
      !['nhl_postseason', 'nhl_preseason'].includes(s.game_type)
  );

  const seasonStatsForPlayer = seasonStats.filter(
    (s) =>
      s.slug == bddPlayer.slug &&
      !['nhl_postseason', 'nhl_preseason'].includes(s.game_type)
  );

  const statDefinition = seasonStatData.format?.stat_definitions.find(
    (sd) => sd.label == statToHighlight
  );

  let stats = [];

  if (!!recentStatsForPlayer) {
    stats.push({
      label: `Recent ${statToHighlight}`,
      value: !isNaN(recentStatsForPlayer[statToHighlight])
        ? formatBddStat(recentStatsForPlayer[statToHighlight], statDefinition)
        : null,
      highlight: highlightCallback(
        statToHighlight,
        { ...recentStatsForPlayer, statContext: recentGamesStatContext },
        statDefinition
      ),
    });
  }

  if (!!seasonStatsForPlayer) {
    stats = stats.concat(
      seasonStatsForPlayer.map((s) => ({
        label: `${s.league_slug} ${statToHighlight}`,
        value: !isNaN(s[statToHighlight])
          ? formatBddStat(s[statToHighlight], statDefinition)
          : null,
        highlight: highlightCallback(
          statToHighlight,
          { ...s, statContext: seasonStatContext },
          statDefinition
        ),
      }))
    );
  }

  return (
    <Container>
      <Row columnGap={2} flexWrap>
        {stats.map((s) => (
          <HighlightBox key={s.label} stat={s} />
        ))}
      </Row>
    </Container>
  );
};

const HighlightBox = ({ stat }) => {
  return (
    <Container minWidth={theme.spacing[11]} variant="button">
      <Column>
        <Typography variant="stat" textAlign="center">
          {stat.label}
        </Typography>
        <Border>
          <Container style={{ backgroundColor: stat.highlight }}>
            <Typography variant="stat" textAlign="center">
              {stat.value}
            </Typography>
          </Container>
        </Border>
      </Column>
    </Container>
  );
};
