import { gql } from '@apollo/client';

export const GET_PLAYER_TEAMMATES_OPPONENTS_STATS = gql`
  query GetPlayerTeammatesOpponents(
    $slug: ID!
    $leagueSlug: String!
    $seasons: [Int!]
    $gameType: String!
    $startDate: Date
    $endDate: Date
    $gameUid: ID
    $otherPosition: String
    $minToi: Float
    $numPlayersPerSplit: Int
    $per20: Boolean
    $minToiForGp: Float
  ) {
    playerTeammatesOpponents(
      slug: $slug
      leagueSlug: $leagueSlug
      seasons: $seasons
      gameType: $gameType
      startDate: $startDate
      endDate: $endDate
      gameUid: $gameUid
      otherPosition: $otherPosition
      minToi: $minToi
      numPlayersPerSplit: $numPlayersPerSplit
      per20: $per20
      minToiForGp: $minToiForGp
    ) {
      bddPlayer {
        slug
        firstname
        lastname
        slPlayerMaster {
          slPlayers {
            leagueSlug
            playerId
            primaryposition
            teamSlug
          }
        }
      }
      metastats {
        stat
        mean
        stddev
      }
      statDefinitions {
        slug
        negative
        primaryName
        shortName
        description
        hasDecimals
        countingStat
      }
      filters {
        leagueSlug
        seasons
        gameType
        startDate
        endDate
      }
      playerPairs {
        isTeammate
        otherPosition
        pairs {
          toi
          gamesPlayed
          otherTeamSlug
          otherBddPlayer {
            slug
            firstname
            lastname
            slPlayerMaster {
              slPlayers {
                playerId
                leagueSlug
                primaryposition
                teamSlug
              }
            }
          }
          position
          otherPosition
          stats
        }
      }
    }
  }
`;

export const GET_PLAYER_TEAMMATES_OPPONENTS = gql`
  query GetPlayerTeammatesOpponents(
    $slug: ID!
    $leagueSlug: String!
    $seasons: [Int!]
    $gameType: String
    $startDate: Date
    $endDate: Date
    $gameUid: ID
    $otherPosition: String
    $minToi: Float
    $numPlayersPerSplit: Int
    $per20: Boolean
    $minToiForGp: Float
  ) {
    playerTeammatesOpponents(
      slug: $slug
      leagueSlug: $leagueSlug
      seasons: $seasons
      gameType: $gameType
      startDate: $startDate
      endDate: $endDate
      gameUid: $gameUid
      otherPosition: $otherPosition
      minToi: $minToi
      numPlayersPerSplit: $numPlayersPerSplit
      per20: $per20
      minToiForGp: $minToiForGp
    ) {
      bddPlayer {
        slug
        firstname
        lastname
        slPlayerMaster {
          slPlayers {
            leagueSlug
            playerId
            primaryposition
            teamSlug
          }
        }
      }
      playerPairs {
        isTeammate
        otherPosition
        pairs {
          toi
          gamesPlayed
          otherTeamSlug
          otherBddPlayer {
            slug
            firstname
            lastname
            slPlayerMaster {
              slPlayers {
                playerId
                leagueSlug
                primaryposition
                teamSlug
              }
            }
          }
          position
          otherPosition
        }
      }
    }
  }
`;


export const GET_OPPONENTS_TOI_BY_GAME = gql`
query GetOpponentsToiByGame(
  $slug: ID!
  $leagueSlug: String!
  $season: Int!
  $gameType: String!
  $startDate: Date
  $endDate: Date
  $lastN: Int,
  $offset: Int
) {
  opponentsToiByGame(
    slug: $slug
    leagueSlug: $leagueSlug
    season: $season 
    gameType: $gameType
    startDate: $startDate
    endDate: $endDate
    lastN: $lastN,
    offset: $offset
  ){
    bddPlayer {
      slug firstname lastname
    }
    games {
      slGame {
        id
        gameDate
      }
      team {
        slug
        longname
        shorthand
      }
      otherTeam {
        slug
        longname
        shorthand
      }
      opponents {
        otherPlayerSlug
        otherBddPlayer {
          slug
          firstname
          lastname
        }
        otherPosition
        toi 
      }
    }
  }
}`