import { GET_PLAYER_SEARCH_QUERY } from "apollo/queries/search.queries";
import useBDDSearchSelect from "components/Search/useBDDSearchSelect";


export default function useBDDPlayerSearch({ 
  onSelect, // called with option
  valueType='slug',
  selectProps={},
  ...props 
}) {

  const { searchComponent, selected, selectedOption } = useBDDSearchSelect({
    query: GET_PLAYER_SEARCH_QUERY,
    resultToOptions: data => data?.search?.map(s => ({
      teamid: s.result.rinknetPlayer?.currentteamnhlid, 
      label: `${s.result.firstname} ${s.result.lastname}`,
      value: s.result[valueType],
      subLabel: s.result.rinknetPlayer?.currentteam,
      bddPlayer: s.result
    })),
    placeholder: 'Search for player',
    isClearable: true,
    onSelect,
    selectProps,
    ...props
  });

  return {
    searchComponent,
    selected,
    selectedOption
  }
}