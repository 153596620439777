import { formatDate } from 'helpers/helpers';
import {
  numberOperatorOptions,
  selectOperatorOptions,
  dateOperatorOptions,
  booleanOperatorOptions,
} from './constants';

export const buildNewFilter = (col, key, options) => {
  const newFilterOptions = options.find((o) => o.col == col);
  const operatorOptions = getOperatorOptions(newFilterOptions);

  return {
    col,
    key,
    cond: newFilterOptions.defaultCond || operatorOptions?.[0]?.value || '=',
    val: getDefaultFilterValue(newFilterOptions),
    extraFilters: newFilterOptions.extraFilters
      ?.map((ef) =>
        !!ef.defaultValue
          ? {
              col: ef.col,
              cond: getOperatorOptions(ef)[0].value,
              val: ef.defaultValue,
            }
          : null
      )
      .filter((ef) => !!ef),
  };
};

export const getOperatorOptions = (options) => {
  switch (options.type) {
    case 'select':
      return selectOperatorOptions;
    case 'date':
      return dateOperatorOptions;
    case 'custom':
      return options.operatorOptions || numberOperatorOptions;
    case 'boolean':
      return booleanOperatorOptions;
    default:
      return numberOperatorOptions;
  }
};

export const getFilterDisplayValue = (filter, type) => {
  switch (type) {
    case 'date':
      return formatDate(filter.val, { format: '%b %d %Y' });
    case 'number':
      return filter.val ?? 'N/A';
    case 'boolean':
      return !!filter.val ? 'Yes' : 'No';
    default:
      return (typeof filter.val != 'object' && filter.val) || 'N/A';
  }
};

export const getDefaultFilterValue = (filterOptions) => {
  if (filterOptions.defaultValue) return filterOptions.defaultValue;

  switch (filterOptions.type) {
    case 'select':
      return filterOptions.options[0].value;
    default:
      return 0;
  }
};
