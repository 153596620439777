import { TooltipSpan } from 'components/reports';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';

const TextContainer = styled.div(({ maxWidth }) => ({
  ...(maxWidth && {
    maxWidth: maxWidth,
  }),
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  overflow: 'hidden',
}));

export default function TruncatedText({
  maxWidth,
  maxCharacters,
  maxLines,
  fontSize,
  allowClose,
  showTooltip,
  children,
}) {
  const [showAllText, setShowAllText] = useState(false);

  if (!children) {
    return null;
  }

  if (!!children && typeof children !== 'string') {
    throw Error('Children must be a string');
  }

  return (
    <TooltipSpan
      content={!!showTooltip && <Typography variant="stat">{children}</Typography>}
    >
      <TextContainer
        maxWidth={maxWidth}
        maxLines={maxLines}
        fontSize={fontSize}
        onClick={() => setShowAllText(!(showAllText && allowClose))}
      >
        {showAllText ? (
          children
        ) : (
          <>
            {children.slice(0, maxCharacters)}
            {children.length > maxCharacters && '...'}
          </>
        )}
      </TextContainer>
    </TooltipSpan>
  );
}
