import { Card } from 'components/bdd/Card';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Plus, PlusLg } from 'react-bootstrap-icons';
import { TeamLineupSandbox } from '.';

export const LineupSandbox = ({ teamSlug, leagueSlug }) => {
  const [lineups, setLineups] = useState([
    {
      primaryLineup: true,
      teamSlug,
    },
  ]);

  const addLineupButton = (
    <Button
      variant="outline-dark"
      onClick={() => {
        setLineups([...lineups, { teamSlug }]);
      }}
    >
      <Row columnGap={1}>
        <PlusLg />
        <Typography variant="body1">Add Lineup</Typography>
      </Row>
    </Button>
  );

  return (
    <Card header="Sandbox" actionContent={addLineupButton}>
      <Container overflowX="auto" padding={3}>
        <Row columnGap={2} alignItems="start">
          {lineups.map((l, index) => (
            <Container width="50%">
              <Border>
                <Container padding={1} key={`${index}-${l.teamSlug}`}>
                  <TeamLineupSandbox
                    primaryLineup={l.primaryLineup}
                    teamSlug={teamSlug}
                    leagueSlug={leagueSlug}
                  />
                </Container>
              </Border>
            </Container>
          ))}
        </Row>
      </Container>
    </Card>
  );
};
