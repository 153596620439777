import { useQuery } from '@apollo/client';
import { GET_MEDIA_FOR_EVENTS } from 'apollo/queries/video.queries';
import { EventShiftContext, VideoController } from 'components/bdd/Video/components';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useContext } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { VideoContext, VideoSelectContext } from '..';

export const EventVideoPlayer = ({ playSelectedEvents, firstEventId, firstClipIdx: firstClipIdxArg, autoPlay = true }) => {
  const { allEvents, loading: eventsLoading } = useContext(VideoContext);
  const { selectedEvents } = useContext(VideoSelectContext) || {};
  const events = !allEvents ? [] : playSelectedEvents ? selectedEvents : allEvents;

  events.forEach((e) => {
    if (!e.gameUid || !e.eventId)
      console.error(`Each event in video modal must have gameUid and eventId`);
  });

  const { data: mediaData, loading } = usePlaceholder(
    useQuery(GET_MEDIA_FOR_EVENTS, {
      skip: events.length == 0,
      variables: {
        events: events.map((e) => ({
          gameUid: e.gameUid,
          eventId: e.eventId,
          eventName: e.eventName,
        })),
      },
    })
  );

  const clips = mediaData?.getMediaForEvents || [];
  const firstClipIdx =  !!firstClipIdxArg ? firstClipIdxArg : clips?.findIndex(c => c.eventId == firstEventId);

  return (
    <VideoController
      clips={clips}
      firstClipIdx={firstClipIdx >= 0 ? firstClipIdx : 0}
      autoPlay={autoPlay}
      playbackMode={'offsets'}
      loading={eventsLoading || loading}
    />
  );
};
