import { useQuery } from '@apollo/client';
import { GET_PLAYER_LISTS } from 'apollo/queries/playerlist.queries';
import { useSelect } from 'components/bdd/Select';

export const usePlayerListSelect = ({ initialListId, listTypes }) => {
  const { data: listData, loading: listLoading } = useQuery(GET_PLAYER_LISTS, {
    variables: {
      listTypes: listTypes || [
        PLAYER_LIST_TYPES.DRAFT_LIST,
        PLAYER_LIST_TYPES.AGGREGATED_RANK_LIST,
      ],
    },
  });

  const playerListOptions = !!listData
    ? listData.playerLists.reduce((acc, curr) => {
        const lt = curr.listType.replace('_', ' ');
        const existing = acc.find((g) => g.label === lt);
        if (existing) {
          existing.options.push({ value: curr.id, label: curr.name });
        } else {
          acc.push({
            options: [{ value: curr.id, label: curr.name }],
            label: lt,
          });
        }
        lt in acc
          ? acc[lt].push({ value: curr.id, label: curr.name })
          : (acc[lt] = [{ value: curr.id, label: curr.name }]);
        return acc;
      }, [])
    : [];

  const { select: listSelect, selectedValue: selectedListId } = useSelect({
    options: playerListOptions,
    initialSelectedValue: initialListId,
    updateOnOptionsChange: true,
    selectProps: {
      isLoading: listLoading,
    },
  });

  return {
    listSelect,
    selectedListId,
  };
};
