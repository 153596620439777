import { getDateDaysBack, removeTimeFromDate } from "./helpers"


export const getDateRangeModeOptions = ({
  includeWeeks=true,
  includeCustom=true,
  includeCurrentSeason=false, 
  includeRecentDays=false
}) => {
  var ret = []
  if (includeRecentDays) {
      ret = ret.concat([
          { value: 'TODAY', label: 'Today' },
          { value: 'YESTERDAY', label: 'Yesterday' },
          { value: 'LAST_3', label: '3 Days' }
      ])
  }
  if (includeWeeks) {
    ret = ret.concat([
        { value: 'LAST_7', label: '7 Days' },
        { value: 'LAST_14', label: '14 Days' },
        { value: 'LAST_21', label: '21 Days' },
        { value: 'LAST_28', label: '28 Days' }
    ])
  }
  if (includeCurrentSeason) ret.push({ value: 'CURRENT_SEASON', label: 'Curr. Season' })
  if (includeCustom) ret.push({ value: 'CUSTOM', label: 'Custom' })
  return ret
}


export const dateRangeModeToDates = (drm) => {
  /* Translate a date range mode into a startdate and enddate */
  if (drm === 'CURRENT_SEASON') {
    return [null, null]
  }
  if (drm.startsWith('LAST_')) {
    const days = parseInt(drm.split('_')[1])
    return [removeTimeFromDate(getDateDaysBack(days)), removeTimeFromDate(new Date())]
  }
  if (drm === 'TODAY') {
    const today = removeTimeFromDate(new Date())
    return [today, today]
  }
  if (drm === 'YESTERDAY') {
    const yesterday = removeTimeFromDate(getDateDaysBack(1))
    return [yesterday, yesterday]
  }
  return [null, null]
}

export const dateRangeModeIsLastMonth = (drm) => {
  // returns true if date range mode is the last month or shorter
  if (['TODAY', 'YESTERDAY'].includes(drm)) return true
  if (drm.startsWith('LAST_')) {
    const days = parseInt(drm.split('_')[1]);
    return days < 31;
  }
  return false;
}