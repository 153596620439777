import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import ReactInputRange from 'react-input-range';
import { TooltipSpan } from 'components/reports';

const InputRangeContainer = styled.div({
  minWidth: 250,
});

const RangeSliderContainer = styled.div(({ color }) => ({
  paddingRight: theme.spacing[2],
  paddingBottom: theme.spacing[2],
  paddingLeft: theme.spacing[2],
  '.input-range__track--active': {
    background: color,
    borderColor: color,
  },
  '.input-range__slider': {
    backgroundColor: color,
    borderColor: color,
  },
}));

const LabelContainer = styled.div({
  ...theme.typography.subtitle1,
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing[5],
  cursor: 'pointer',
  userSelect: 'none',
});

export const InputRange = ({
  label,
  tooltip,
  min,
  max,
  valueSuffix,
  onLabelSelected,
  formatLabel,
  field,
  ...rest
}) => {
  // Injected by Formik
  const { value, onChange, name } = field || {};

  return (
    <InputRangeContainer type="input">
      <LabelContainer onClick={() => onLabelSelected && onLabelSelected()}>
        <TooltipSpan content={tooltip}>
          {label}
        </TooltipSpan>
      </LabelContainer>
      <RangeSliderContainer color="black">
        <ReactInputRange
          type="input"
          name={name}
          maxValue={max}
          minValue={min}
          value={value || min}
          formatLabel={formatLabel}
          onChange={(newValue) => {
            onChange({ target: { name, value: newValue } });
          }}
          {...rest}
        />
      </RangeSliderContainer>
    </InputRangeContainer>
  );
};
