

export const txToVariants = (tx) => {
  if (tx.transactionType === 'TRADE') {
    return tx.bddTrades;
  } else if (tx.transactionType === 'SIGNING') {
    return tx.bddSignings;
  }
}

export const txToFirstVariant = (tx) => {
  return txToVariants(tx)[0];
}

export const buildSlugToTxs = (transactions) => {
  if (!transactions) return {};
  return transactions.reduce((acc, curr) => {
    if (curr.transactionType === 'SIGNING') {
      var playerSlugs = curr.bddSignings.map(s => s.bddPlayerSlug);
    } else if (curr.transactionType === 'TRADE') {
      playerSlugs = curr.bddTrades.flatMap(t => t.tradedPlayers.map(p => p.playerSlug));
    }
    playerSlugs.forEach(p => {
      if (!(p in acc)) {
        acc[p] = [];
      }
      if (!acc[p].find(t => t.id == curr.id)) {
        acc[p].push(curr)
      }
    })
    return acc
  }, {});
}