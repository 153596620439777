import { sortNumericAccessor } from 'helpers/tables';
import { Cell, Header } from '.';

export const buildColumns = (columns, context = {}, options = {}) => {
  return columns.map((column) => {
    const accessor = (d) => (column.accessor ? column.accessor(d, context) : {});

    return {
      Header: (
        <Header textAlign={column.textAlign || 'center'} noWrap={!options.wrapHeader}>
          {column.header}
        </Header>
      ),
      ...column,
      minWidth: column.minWidth || options.minWidth || 50,
      accessor,
      sortDescFirst: true,
      sortType:
        column.sortType ||
        ((a, b) => sortNumericAccessor(a.original, b.original, accessor)),
      Cell: ({
        cell: {
          row: { original: d },
        },
      }) => {
        const value = accessor(d);

        return column.renderCell ? (
          column.renderCell(d, value)
        ) : (
          <Cell textAlign={column.textAlign || 'center'}>
            {column.renderLabel ? column.renderLabel(d, value) : value}
          </Cell>
        );
      },
    };
  });
};

export const mapSort = (sort, sortMapping = {}) => {
  // given a sort [{ id, desc }] map columns as necessary
  // some columns (contract) map to others (final_cap_hit) when sorting
  return sort.map((s) => {
    if (s.id in sortMapping) {
      return { ...s, id: sortMapping[s.id] };
    } else {
      return s;
    }
  });
};
