import { useMutation } from "@apollo/client";
import { CREATE_BF_TRADE, DELETE_BF_TRADE, LINK_TRANSACTACTION_TO_TRADE, REMOVE_TRANSACTION_TRADE_LINK, UPDATE_BF_TRADE } from "apollo/queries/bruinsfriendly.queries";
import { toastBddApiError } from "components/bdd/bddtoasts";


export const useBFTradeMutations = ({
  onCreate,
  onLink,
  onRemoveLink,
  onDelete,
  onUpdate
}) => {
  
  const [createBFTrade, { loading: createLoading }] = useMutation(CREATE_BF_TRADE, {
    onCompleted: ({ createBfTrade: { bfTrade }}) => {
      onCreate && onCreate(bfTrade);
    },
    onError: toastBddApiError,
    update: (cache, { data: { createBfTrade: { bfTrade } } }) => {
      cache.modify({
        fields: {
          paginatedBfTrades(existing) {
            return {
              data: [bfTrade].concat(existing.data),
              pageInfo: existing.pageInfo,
            }
          },
        },
      });
    }
  });

  const [updateBFTrade, { loading: updateLoading }] = useMutation(UPDATE_BF_TRADE, {
    onCompleted: ({ updateBfTrade: { bfTrade }}) => {
      onUpdate && onUpdate(bfTrade);
    },
    onError: toastBddApiError
  });

  const [deleteBFTrade, { loading: deleteLoading }] = useMutation(DELETE_BF_TRADE, {
    onCompleted: ({ deleteBfTrade: { id }}) => {
      onDelete && onDelete(id);
    },
    onError: toastBddApiError,
    update: (cache, { data: { deleteBfTrade } }) => {
      cache.modify({
        fields: {
          paginatedBfTrades(existing, { readField }) {
            return {
              data: existing.data.filter((bfTrade) => readField('id', bfTrade) != deleteBfTrade.id),
              pageInfo: existing.pageInfo,
            }
          },
        },
      });
    }
  });

  const [linkTransactionToTrade, { loading: linkLoading }] = useMutation(LINK_TRANSACTACTION_TO_TRADE, {
    onCompleted: () => {
      onLink && onLink();
    },
    onError: toastBddApiError,
    update: (cache, { data: { linkTransactionToTrade: { nhlDailyTransaction, bfTrade } } }) => {
      cache.modify({
        fields: {
          paginatedNhlDailyTransactions(existing, { readField }) {
            console.log('link cache update', existing, nhlDailyTransaction, bfTrade)
            const ret = {
              data: existing.data.map((transaction) => readField('id', transaction) == nhlDailyTransaction.id ? nhlDailyTransaction : transaction),
              pageInfo: existing.pageInfo,
            };
            return ret;
          },
        },
      });
    }
  });

  const [removeTransactionTradeLink, { loading: unlinkLoading }] = useMutation(REMOVE_TRANSACTION_TRADE_LINK, {
    onCompleted: () => {
      onRemoveLink && onRemoveLink();
    },
    onError: toastBddApiError,
    update: (cache, { data: { removeTransactionTradeLink: { nhlDailyTransaction, bfTrade } } }) => {
      cache.modify({
        fields: {
          paginatedNhlDailyTransactions(existing) {
            return {
              data: existing.data.map((transaction) => transaction.id === nhlDailyTransaction.id ? nhlDailyTransaction : transaction),
              pageInfo: existing.pageInfo,
            };
          },
        },
      })}
  });

  return {
    createBFTrade,
    createLoading,
    updateBFTrade,
    updateLoading,
    deleteBFTrade,
    deleteLoading,
    linkTransactionToTrade,
    linkLoading,
    removeTransactionTradeLink,
    unlinkLoading
  }
}