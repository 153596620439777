import { Typography } from "components/bdd/Typography";
import { BDD_ASSET_TYPES } from "./constants";
import { Border, Column, Container, Row } from "components/bdd/Layout";
import { Asterisk, CashStack, CheckCircle, XCircle } from "react-bootstrap-icons";
import { IconButton, LinkButton } from "components/bdd/Button";
import { useState } from "react";
import { BDDTextArea, DecoratedBDDInput, HoverInteractive } from "components/bdd";
import BFPlayerContractsSmall from "components/PlayersV2/Contract/BFPlayerContractsSmall";
import { toastInfo } from "components/bdd/bddtoasts";
import { formatDollars } from "helpers/contracts";
import { getNHLTeamLogoUrl } from "helpers/logos";
import PlayerHover from "components/PlayersV2/Hover/playerhover";
import { useQuery } from "@apollo/client";
import { GET_PLAYER_CARD } from "apollo/queries/players.queries";
import { BDDTradePlayerAnalysis } from "./BDDTradePlayerAnalysis";
import { roundToX } from "helpers/data";
import Icon from "components/bdd/Icon";
import { Collapse } from "react-bootstrap";
import useToggle from "components/bdd/Toggle/useToggle";
import { numberToOrdinal } from "helpers/helpers";
import { year4to2 } from "helpers/hockeyutils";

export const BDDTradedPlayer = ({
  bddPlayer,
  setAsset,
  retainedSalary,
  retainedSalaryPct,
  allowEdit=true,
  allowRetention=false,
  handleRemove,
  allowHover=true,
  analysis=false
}) => {
  return analysis
    ? <BDDTradePlayerAnalysis
      slug={bddPlayer.slug}
      bddPlayer={bddPlayer}
      setAsset={setAsset}
      retainedSalary={retainedSalary}
      retainedSalaryPct={retainedSalaryPct}
      allowEdit={allowEdit}
      allowRetention={allowRetention}
      handleRemove={handleRemove}
      allowHover={allowHover}

    /> : <BDDTradedPlayerBrief
      bddPlayer={bddPlayer}
      setAsset={setAsset}
      retainedSalary={retainedSalary}
      retainedSalaryPct={retainedSalaryPct}
      allowEdit={allowEdit}
      allowRetention={allowRetention}
      handleRemove={handleRemove}
      allowHover={allowHover}
    />
}

export const BDDTradedPlayerBrief = ({
  bddPlayer,
  setAsset,
  retainedSalary,
  retainedSalaryPct,
  allowEdit=true,
  allowRetention=false,
  handleRemove,
  allowHover=true,
  variant='body2'
}) => {
  const [showRetention, setShowRetention] = useState(false);
  const { data, loading } = useQuery(GET_PLAYER_CARD, { // if bfCapHit not in bddPlayer we'll need to fetch
    variables: { slug: bddPlayer.slug },
    skip: !!bddPlayer?.bfCapHit
  })
  const bfCapHit = !!data ? data.bddPlayer.bfCapHit : bddPlayer?.bfCapHit;

  const handleUpdateRetention = (val, type) => {
    if (type === 'pct') {
      if (val > 50) {
        toastInfo('Retention cannot exceed 50% of AAV')
        val = 50;
      }
      var newRetainedPct = val;
      var newRetainedDollars = (val / 100) * bfCapHit.currentAav;
    } else {
      newRetainedDollars = val;
      newRetainedPct = 100 * (val / bfCapHit.currentAav);
      if (newRetainedPct > 50) {
        toastInfo('Retention cannot exceed 50% of AAV')
        newRetainedPct = 50;
        newRetainedDollars = .5 * bfCapHit.currentAav;
      }
    }
    setAsset({ 
      retainedSalary: newRetainedDollars,
      retainedSalaryPct: newRetainedPct
    })
  }

  return <Row gap={8} justifyContent='space-between'>
    <PlayerHover playerSlug={bddPlayer.slug} disabled={!allowHover} delay={{show: 1000, hide: 200 }}>
      <Typography variant={variant}>
        {bddPlayer.firstname} {bddPlayer.lastname}
      </Typography>
    </PlayerHover>

    <Row gap={4}>
      {loading 
        ? <Typography variant='stat'>Loading cap hit...</Typography>
        : !showRetention && <BFPlayerContractsSmall 
        name={`${bddPlayer.firstname} ${bddPlayer.lastname}`} 
        bfCapHit={bfCapHit} 
        bfPlayerSlug={bddPlayer.bfSlug}
      />}
      {!showRetention && !!retainedSalary && (
        <Container>
          <Typography variant='body2'>
            w/{roundToX(retainedSalaryPct, 2)}% ret. ({formatDollars(retainedSalary, { truncate: true })})
          </Typography>
        </Container>
      )}
      {allowEdit && allowRetention && !!bfCapHit && showRetention
        ? (
          <Row gap={4}>
          <Typography variant='caption'>
            w/ retention: 
          </Typography>
            <DecoratedBDDInput
              type='number'
              min='0'
              max='50'
              backDecoration={'%'}
              value={retainedSalaryPct}
              onChange={ev => handleUpdateRetention(ev.target.value, 'pct')}
              style={{ fontSize: '8pt', 'WebkitAppearance': 'none', 'margin': 0 }}
            />
            <Typography variant='caption'>OR</Typography>
            <DecoratedBDDInput
              type='number'
              min='0'
              max={`${bfCapHit.currentAav / 2 / 1e6}`}
              style={{ fontSize: '8pt' }}
              frontDecoration={'$'}
              backDecoration={'M'}
              value={retainedSalary/1e6}
              onChange={ev => handleUpdateRetention(ev.target.value*1e6, 'dollars')}
            />
            <IconButton
              icon={<CheckCircle/>}
              onClick={() => setShowRetention(false)}
            />
          </Row>
        ) : allowEdit && allowRetention && !loading
        ? <IconButton 
          icon={<CashStack/>} 
          onClick={() => setShowRetention(true)} 
          hover='Add retention'
        /> : null
      }
    </Row>

    {allowEdit && handleRemove && <IconButton
      icon={<XCircle />}
      tooltip='Remove from trade'
      onClick={() => handleRemove()}
    />}
  </Row>
}

export const BDDTradeDraftPick = ({ ...props }) => {
  return <BDDTradeDraftPickBrief {...props} />
}

export const BDDTradeDraftPickBrief = ({ bfDraftPick, setAsset, allowConditions, isConditional, conditionalDetails, handleRemove, allowEdit, variant='body2' }) => {
  return <Container>
    <Row gap={16}>
      <Typography variant={variant}>
        <img 
          alt={bfDraftPick.originalTeam.abbreviation} 
          src={getNHLTeamLogoUrl(bfDraftPick.originalTeam.nhlid)} 
          height='15px'
        />
        {year4to2(bfDraftPick.year)} {numberToOrdinal(bfDraftPick.round)} 
      </Typography>

      {allowConditions && (
        <Container>
          <HoverInteractive
            content={(
              <Container width={200}>
                <Row gap={4} justifyContent='space-between'>
                  <Typography variant='stat'>Start typing to add a condition</Typography>
                  {isConditional && <IconButton 
                    icon={<XCircle/>} 
                    tooltip='Remove condition' 
                    onClick={() => setAsset({isConditional: false, conditionalDetails: ''})} 
                  />}
                </Row>
                <BDDTextArea
                  placeholder='Enter conditional details, or say "Unspecified"...'
                  value={conditionalDetails || ''}
                  onChange={ev => {
                    setAsset({conditionalDetails: ev.target.value, isConditional: ev.target.value.length > 0})
                  }}
                />
              </Container>
            )}
          >
            <Typography variant='stat'>
              {isConditional ? 'Conditional' : 'Add Condition'}
            </Typography>
          </HoverInteractive>
          {/* {toggleComponent} */}
        </Container>
      )}

      {allowEdit && handleRemove && <IconButton
        icon={<XCircle />}
        tooltip='Remove from trade'
        onClick={() => handleRemove()}
      />}
    </Row>
    {/* {allowConditions && (
      <Collapse in={showCondition && isConditional}>
        <Container>
          <Row justifyContent='end'>
            <LinkButton onClick={() => setShowCondition(!showCondition)}>
              <Typography variant='stat'>
                {showCondition ? 'Hide' : 'Show'}
              </Typography>
            </LinkButton>
          </Row>
          <Typography variant='stat'>
            <BDDTextArea
              placeholder='Enter conditional details...'
              value={conditionalDetails}
              onChange={ev => setAsset({conditionalDetails: ev.target.value})}
            />
          </Typography>
        </Container>
      </Collapse>
    )} */}
  </Container>
}

export default function BDDTradeAsset({
  asset,
  setAsset,
  allowEdit=true,
  allowRetention,
  allowConditions,
  handleRemove
}) {
  return <Container>
    {asset.assetType === BDD_ASSET_TYPES.PLAYER
      ? (
        <BDDTradedPlayer
          bddPlayer={asset.bddPlayer}
          setAsset={incoming => setAsset({...asset, ...incoming})}
          retainedSalary={asset.retainedSalary}
          retainedSalaryPct={asset.retainedSalaryPct}
          allowEdit={allowEdit}
          allowRetention={allowRetention}
          handleRemove={handleRemove}
        />
      ) : (
        <BDDTradeDraftPick 
          bfDraftPick={asset.bfDraftPick} 
          setAsset={incoming => setAsset({...asset, ...incoming})}
          allowConditions={allowConditions}
          isConditional={asset.isConditional}
          conditionalDetails={asset.conditionalDetails}
          allowEdit={allowEdit} 
          handleRemove={handleRemove} 
        />
      )
    }
  </Container>

}