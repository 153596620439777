import { infoColumns } from '../columns';
import {
  contractTypeOptions,
  expiryStatusOptions,
  signingStatusOptions,
} from '../constants';

export const similarContractWeightPresets = {
  current: {
    production: {
      numericalWeights: {
        'stats_P/GP': 1,
        'stats_TOI/GP': 0.8,
        stats_GP: 0.8,
        stats_G: 0.8,
        stats_P: 0.8,
        stats_SOG: 0.8,
        stats_PPG: 0.3,
        stats_PPP: 0.3,
      },
      categoricalWeights: {
        bf_player_position_fdg: 1,
      },
    },
    contract: {
      numericalWeights: {
        aav: 1,
        cap_hit: 1,
        num_years: 1,
        signing_bonus: 0.8,
        performance_bonus: 0.8,
        signing_age: 0.5,
        minor_salary: 0.5,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
        is_extension: 1,
        has_trade_clause: 1,
      },
    },
    bio: {
      numericalWeights: {
        signing_age: 1,
        expiration_age: 0.5,
        bf_player_height_inches: 1,
        bf_player_weight: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
      },
    },
    player_type: {
      numericalWeights: {
        bf_player_height_inches: 1,
        bf_player_weight: 0.5,
        signing_age: 0.7,
        stats_GP: 0.8,
        'stats_TOI/GP': 0.8,
        'stats_PP TOI/GP': 0.8,
        'stats_SH TOI/GP': 0.8,
        'stats_PIM/GP': 0.8,
        'stats_P/GP': 0.8,
        stats_G: 0.5,
        stats_P: 0.5,
        stats_SOG: 0.5,
        stats_PBR: 0.5,
        'stats_XPG %': 0.8,
        stats_XPGA: 0.5,
        stats_PPG: 0.3,
        stats_PPP: 0.3,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
      },
    },
  },
  future: {
    production: {
      numericalWeights: {
        'stats_P/GP': 1,
        'stats_TOI/GP': 0.8,
        stats_GP: 0.8,
        stats_G: 0.8,
        stats_P: 0.8,
        stats_SOG: 0.8,
        stats_PPG: 0.3,
        stats_PPP: 0.3,
      },
      categoricalWeights: {
        bf_player_position_fdg: 1,
      },
    },
    bio: {
      numericalWeights: {
        signing_age: 1,
        bf_player_height_inches: 1,
        bf_player_weight: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
      },
    },
    player_type: {
      numericalWeights: {
        bf_player_height_inches: 1,
        bf_player_weight: 0.5,
        signing_age: 0.7,
        stats_GP: 0.8,
        'stats_TOI/GP': 0.8,
        'stats_PP TOI/GP': 0.8,
        'stats_SH TOI/GP': 0.8,
        'stats_PIM/GP': 0.8,
        'stats_P/GP': 0.8,
        stats_G: 0.5,
        stats_P: 0.5,
        stats_SOG: 0.5,
        stats_PBR: 0.5,
        'stats_XPG %': 0.8,
        stats_XPGA: 0.5,
        stats_PPG: 0.3,
        stats_PPP: 0.3,
      },
      categoricalWeights: {
        signing_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
      },
    },
  },
};

export const goalieSimilarContractWeightPresets = {
  current: {
    production: {
      numericalWeights: {
        stats_GP: 0.8,
        stats_Win: 0.8,
        stats_GBR: 0.8,
        'stats_S%': 0.8,
        stats_GSAA: 0.8,
      },
      categoricalWeights: {
        bf_player_position_fdg: 1,
      },
    },
    contract: {
      numericalWeights: {
        aav: 1,
        cap_hit: 1,
        num_years: 1,
        signing_bonus: 0.8,
        performance_bonus: 0.8,
        signing_age: 0.5,
        minor_salary: 0.5,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
        is_extension: 1,
        has_trade_clause: 1,
      },
    },
    bio: {
      numericalWeights: {
        signing_age: 1,
        expiration_age: 0.5,
        bf_player_height_inches: 1,
        bf_player_weight: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
      },
    },
    player_type: {
      numericalWeights: {
        bf_player_height_inches: 1,
        bf_player_weight: 0.5,
        signing_age: 0.7,
        stats_GP: 0.8,
        stats_Win: 0.8,
        stats_GBR: 0.8,
        'stats_S%': 0.8,
        stats_GSAA: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        expiry_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
      },
    },
  },
  future: {
    production: {
      numericalWeights: {
        stats_GP: 0.8,
        stats_Win: 0.8,
        stats_GBR: 0.8,
        'stats_S%': 0.8,
        stats_GSAA: 0.8,
      },
      categoricalWeights: {
        bf_player_position_fdg: 1,
      },
    },
    bio: {
      numericalWeights: {
        signing_age: 1,
        bf_player_height_inches: 1,
        bf_player_weight: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
        is_arbitration_eligible: 0.8,
      },
    },
    player_type: {
      numericalWeights: {
        bf_player_height_inches: 1,
        bf_player_weight: 0.5,
        signing_age: 0.7,
        stats_GP: 0.8,
        stats_GBR: 0.8,
        'stats_S%': 0.8,
        stats_GSAA: 0.8,
        stats_Win: 0.8,
      },
      categoricalWeights: {
        signing_status: 1,
        contract_type: 1,
        bf_player_position_fdg: 1,
        is_thirty_five_plus: 1,
      },
    },
  },
};

export const numericalWeightOptions = Object.values(infoColumns).map((o) => ({
  value: o.id,
  label: o.header,
}));

export const categoricalFilterOptions = {
  bf_player_position_fdg: {
    col: 'bf_player_position_fdg',
    label: 'Position FDG',
    options: [
      {
        value: 'F',
        label: 'F',
      },
      {
        value: 'D',
        label: 'D',
      },
      {
        value: 'G',
        label: 'G',
      },
    ],
    type: 'select',
  },
  signing_status: {
    col: 'signing_status',
    label: 'Signing Status',
    type: 'select',
    options: signingStatusOptions,
  },
  expiry_status: {
    col: 'expiry_status',
    label: 'Expiry Status',
    type: 'select',
    options: expiryStatusOptions,
  },
  contract_type: {
    col: 'contract_type',
    label: 'Contract Type',
    type: 'select',
    options: contractTypeOptions,
  },
  is_thirty_five_plus: {
    col: 'is_thirty_five_plus',
    label: '35+ Contract',
    type: 'boolean',
  },
  is_arbitration_eligible: {
    col: 'is_arbitration_eligible',
    label: 'Arb. Eligible',
    type: 'boolean',
  },
  is_extension: {
    col: 'is_extension',
    label: 'Is Extension',
    type: 'boolean',
  },
  has_trade_clause: {
    col: 'has_trade_clause',
    label: 'Has Trade Clause',
    type: 'boolean',
  },
};
