import { Container, Row } from 'components/bdd/Layout';
import { theme } from 'constants/theme';
import { useRef, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';
import useBDDPlayerSearch from 'components/Search/useBDDPlayerSearch';
import { LinkPopover } from 'components/bdd/Popover';
import { Link } from 'react-router-dom';
import { PLAYERS_V2_ROUTE } from 'constants';
import { Typography } from 'components/bdd/Typography';
import useBDDSearch from 'components/Search/useBDDSearch';
import { TEAM_ROUTE } from 'constants';
import { InlineChromiumBugfix } from '..';

const SEARCH_RESULT_TO_ENTITY = {
  player: 'BDD_PLAYER',
  team: 'SL_TEAM',
  user: 'BDD_USER',
  userGroup: 'BDD_USER_GROUP',
};

const ENTITY_TO_ROUTE = {
  BDD_PLAYER: PLAYERS_V2_ROUTE,
  SL_TEAM: TEAM_ROUTE,
};

export const MentionField = ({ readOnly, children, attributes, element }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  // const focused = useFocused();
  const ref = useRef();

  const [selectedMention, setSelectedMention] = useState(element.entityLabel);
  const path = ReactEditor.findPath(editor, element);

  const { searchComponent } = useBDDSearch({
    resultTypes: ['BDDPLAYERS', 'TEAMS', 'BDD_USERS', 'BDD_USER_GROUPS'],
    initialSelectedValue: element.entityLabel,
    onSelect: ({ value, label, type }) => {
      setSelectedMention(label);

      Transforms.setNodes(
        editor,
        {
          ...element,
          entityLabel: label,
          entityId: value,
          entityType: SEARCH_RESULT_TO_ENTITY[type],
        },
        { at: path }
      );
      
      Transforms.insertNodes(editor, { at: path, text: ' ' });
    },
    autoFocus: true,
  });

  const readonlyMention = (
    <Typography as="span" color={theme.colors.light.text.primary}>
      <b>@{selectedMention}</b>
    </Typography>
  );

  return (
    <span {...attributes}>
      <InlineChromiumBugfix />
      <span
        ref={ref}
        style={{
          padding: theme.spacing[0.5],
          backgroundColor: selected
            ? theme.colors.states.danger
            : theme.colors.light.focus,
        }}
      >
        {readOnly ? (
          element.entityType in ENTITY_TO_ROUTE ? (
            <Link to={`${ENTITY_TO_ROUTE[element.entityType]}/${element.entityId}`}>
              {readonlyMention}
            </Link>
          ) : (
            readonlyMention
          )
        ) : (
          <LinkPopover
            defaultShow={!element.entityId}
            renderLink={(setShow, target) => (
              <span
                style={{ cursor: 'pointer' }}
                ref={target}
                onClick={() => {
                  setShow((show) => !show);
                }}
              >
                <b>@{selectedMention}</b>
              </span>
            )}
            renderContent={() => <Container width={200}>{searchComponent}</Container>}
          />
        )}
        {children}
      </span>
      <InlineChromiumBugfix />
    </span>
  );
};
