import { useQuery } from '@apollo/client';
import { create } from 'zustand';

export const createApolloStore = ({ query, idKey, setState }) =>
  create((set, get) => ({
    ids: [],
    actions: {
      useEntityQuery: ({ skip, variables, ...rest }) => {
        const idsToQuery = variables?.[idKey];

        return useQuery(query, {
          skip,
          variables: {
            ...variables,
            // [idKey]: idsToQuery,
          },
          ...rest,
          onCompleted: (data) => {
            set({
              ids: [...new Set([...idsToQuery, ...get().ids])],
              ...setState(data, get),
            });
          },
        });
      },
    },
  }));
