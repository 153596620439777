import React, {useState} from "react"
import BDDSelect from '../bdd/bddselect'
import { getGameTypeChoices, getDefaultGameType } from "../../helpers/filters"

export default function GameTypeSelect({
    value,
    onChange,
    useBDDSelect,
    mode='nhl',
    style,
    className,
    ...rest
}) {
    const content = useBDDSelect ?
        <BDDSelect
            name="game_type"
            value={value}
            options={getGameTypeChoices(mode)}
            {...rest}
            onChange={(name, value) => onChange(name, value)}
        />
            :
        <select 
            name="game_type"
            value={value} 
            onChange={ev => onChange(ev.target.name, ev.target.value)}
            className={className}
            style={{ fontFamily: 'Oswald', ...style }}
            {...rest}
        >
            {getGameTypeChoices(mode).map(g =>
                <option key={g.value} value={g.value}>{g.label}</option>)}
        </select>

    return (
        <>{content}</>
    )
}