import { useMutation, useQuery } from "@apollo/client";
import { GET_BF_DRAFT_PICKS, GET_BF_TRADE, UPDATE_BF_DRAFT_PICKS } from "apollo/queries/bruinsfriendly.queries";
import { BDDTextArea } from "components/bdd";
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import { Border, Column, Container, Row } from "components/bdd/Layout";
import useToggle from "components/bdd/Toggle/useToggle";
import { Typography } from "components/bdd/Typography";
import { DraftPickInfo } from "components/DraftV2/DraftHistory/DraftPickInfo";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";


export const BFTradePickUpdates = ({ bfTradeId, onSkip, onCompleted }) => {
  const { data: tradeData, placeholder: tradePlaceholder } = usePlaceholder(useQuery(GET_BF_TRADE, {
    variables: { id: bfTradeId },
  }));

  const bfTrade = tradeData?.bfTrade;

  if (!bfTrade) return tradePlaceholder;
  return <BFTradePickUpdatesFromTrade bfTrade={bfTrade} onSkip={onSkip} onCompleted={onCompleted} />
}

export const BFTradePickUpdatesFromTrade = ({ bfTrade, onSkip, onCompleted }) => {
  const tradedPicks = bfTrade?.tradedDraftPicks;
  const pickIds = tradedPicks?.map(p => p.pickId);

  // given a bfTrade, generate updates to bf draft picks
  const defaultConditions = tradedPicks?.reduce((obj, tp) => ({ 
    ...obj, 
    [tp.pickId]: { isConditional: tp.isConditional, conditionalDetails: tp.conditionalDetails || '' } 
  }), {});
  const [conditions, setConditions] = useState(defaultConditions); // { pickId: { isConditional, conditionalDetails }}

  const { data, placeholder } = usePlaceholder(useQuery(GET_BF_DRAFT_PICKS, {
    variables: {
      ids: pickIds
    },
    skip: !pickIds?.length
  }))
  // filter to picks that have changes relative to stored versions
  const bfPicks = data?.bfDraftPicks;
  const tradedAndStored = bfPicks?.reduce((acc, p) => {
    const traded = tradedPicks.find(tp => tp.pickId == p.id);
    return [
      ...acc, 
      { 
        id: p.id,
        tradedPick: traded, 
        bfDraftPick: p 
      }
    ];
  }, [])
  const movedPicks = tradedAndStored?.filter(ts => ts.tradedPick.toTeamSlug != ts.bfDraftPick.currentTeamSlug);

  useEffect(() => {
    if (!!data && !movedPicks.length && !!onCompleted) onCompleted();
  }, [movedPicks])

  const [updateBfDraftPicks, { loading }] = useMutation(UPDATE_BF_DRAFT_PICKS, {
    onError: toastBddApiError,
    onCompleted: () => {
      toastInfo('BF Draft picks updated!')
      !!onCompleted && onCompleted();
    }
  });

  const handleSubmit = () => {
    updateBfDraftPicks({
      variables: {
        picks: movedPicks.map(ap => {
          const tradedPick = ap.tradedPick;
          return {
            id: ap.id, 
            currentTeamSlug: tradedPick.toTeamSlug,
            isConditional: conditions[ap.id].isConditional,
            conditionalDetails: !!conditions[ap.id].isConditional && conditions[ap.id].conditionalDetails
          } 
        })
      }
    })
  }


  return (
    <Column gap={2}>
      <Typography variant='body1'>
        The following draft pick updates are recommended:
      </Typography>

      <Container>
        <Column gap={1}>
          {!!placeholder
            ? placeholder
            : movedPicks.map((ts, i) => {
              return <PickUpdate 
                key={ts.id} 
                bfDraftPick={ts.bfDraftPick} 
                toTeamAbbr={ts.tradedPick.toBfTeam.abbreviation} 
                conditions={conditions[ts.id]}
                setConditions={incoming => setConditions({ ...conditions, [ts.id]: incoming })}
              />
          })}
        </Column>
      </Container>
      
      <Row gap={8} justifyContent='end' alignItems='end'>
        <Button block variant='secondary' size='sm' onClick={onSkip}>
          <Typography variant='body1' textAlign='center'>
            Skip
          </Typography>
        </Button>
        <Button block variant='warning' size='sm' onClick={handleSubmit}>
          <Typography variant='body1' textAlign='center'>
            {loading ? 'Submitting...' : 'Submit'}
          </Typography>
        </Button>
      </Row>
    </Column>
  )

}

const PickUpdate = ({ bfDraftPick, toTeamAbbr, conditions, setConditions }) => {

  const { toggleComponent, toggled } = useToggle({
    id: bfDraftPick.id,
    label: 'Is conditional',
    initialToggled: !!conditions.isConditional,
    onToggled: (toggled) => {
      setConditions({ ...conditions, isConditional: toggled });
    }
  });  

  const p = bfDraftPick;
  return <Border>
    <Container padding={2}>
      <Column gap={1}>
        <Row gap={32}>
          <Container>
            <Row gap={4}>
              <DraftPickInfo pick={p} isEditable={false} />
              <Typography variant='body1'>
                {p.year} R{p.round}
              </Typography>
            </Row>
          </Container>

          <Row gap={4}>
            <Typography variant='body2'>
              Change curr. team from
            </Typography>
            <Typography variant='body1'>
              {p.currentTeam.abbreviation}
            </Typography>
            <Typography variant='body2'>
              to
            </Typography>
            <Typography variant='body1'>
              {toTeamAbbr}
            </Typography>
          </Row>

          {toggleComponent}
        </Row>
        
        {toggled && (
          <BDDTextArea
            label='Conditional details'
            placeholder='Enter the details of the trade conditions'
            autoFocus
            value={conditions.conditionalDetails || ''}
            onChange={ev => setConditions({ ...conditions, conditionalDetails: ev.target.value })}
          />
        )}
      </Column>
    </Container>
  </Border>
}