import styled from "styled-components"

const StyledInput = styled.input`
    font-family: Oswald;
    font-weight: lighter;
    width: 100%;
    border-radius: ${({ flatRight, flatLeft }) => 
      `${flatLeft ? '0' : '5px'} ${flatRight ? '0' : '5px'} ${flatRight ? '0' : '5px'} ${flatLeft ? '0' : '5px'};`
    }
    border: 1px solid #bababa;
    padding: 5px;
    ${props => props.isInvalid ? `
        box-shadow: 0 0 5px red;
        border: 1px solid red;  
    ` : null}
`
export const BDDInput = (props) => <StyledInput {...props} />

