import { Container } from 'components/bdd/Layout';
import { useContext, useEffect } from 'react';
import { VideoContext } from '../..';
import { useTeamGamePlayerData } from 'components/bdd/Video/hooks';
import { SelectFilter } from './SelectFilter';

export const TeamGameFilter = ({ isMulti = true }) => {
  const { sourceFilters, setSourceFilters } = useContext(VideoContext);

  const { games, loading } = useTeamGamePlayerData({
    teamSlug:
      sourceFilters['team_slug']?.val || sourceFilters['opp_team_slug']?.val,
    leagueSlug: sourceFilters['league_slug']?.val,
    season: sourceFilters['season']?.val,
    gameType: sourceFilters['game_type']?.val,
  });

  const options = games?.map((d) => ({
    label: `${d.game_date}: ${d.team_shorthand} vs. ${d.opp_team_shorthand}`,
    value: d.game_uid,
  }));

  useEffect(() => {
    if (isMulti || 'game_uid' in sourceFilters || gameData.length == 0) return;

    setSourceFilters({
      game_uid: {
        col: 'game_uid',
        val: games[0].game_uid,
      },
    });
  }, [games]);

  useEffect(() => {
    // clear game filter if lsg has changed
    setSourceFilters({
      game_uid: {
        col: 'game_uid',
        val: null,
      },
    });
  }, [
    sourceFilters['league_slug']?.val,
    sourceFilters['season']?.val,
    sourceFilters['game_type']?.val,
  ]);

  return (
    <Container minWidth={200}>
      <SelectFilter
        col="game_uid"
        id="game_uid"
        label={isMulti ? 'Games' : 'Game'}
        options={options || []}
        isMulti={isMulti}
        selectProps={{
          isClearable: true,
          isLoading: loading,
        }}
      />
    </Container>
  );
};
