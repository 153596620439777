import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { getNormChoices } from 'helpers/filters';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const NormSelect = ({ selectProps }) => {
  const { filters, setFilters } = useContext(SLStatContext);

  const select = (
    <StyledBDDSelect
      size="sm"
      options={getNormChoices()}
      selectedValue={filters.norm}
      onChange={(n, v) => {
        setFilters({ norm: v });
      }}
      selectProps={selectProps}
    />
  );

  return (
    <Container width={150}>
      <Column>
        <Typography variant="label">Norm</Typography>
        {select}
      </Column>
    </Container>
  );
};
