import { CheckboxGroupFilter } from '.';
import { shotOutcomeOptions } from '../helpers';

export const ShotOutcomeFilter = ({}) => {
  return (
    <CheckboxGroupFilter
      col="event_flags"
      id="shot_outcome"
      label={'Shot Outcome'}
      options={shotOutcomeOptions}
    />
  );
};
