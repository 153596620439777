import { gql } from '@apollo/client';
import { BddTagFragment } from './bddtags.queries';
import { BFCapHitFragment, BFPlayerFragment } from './bruinsfriendly.queries';

export const BDDPlayerNoteFragment = gql`
  fragment bddPlayerNoteFragment on BDDPlayerNote {
    id
    bddPlayerSlug
    user {
      id
      username
    }
    parentPlayerNoteId
    dateCreated
    dateEdited
    note
    noteType
    requiresPermission
    isPrivate
    isEditable
    isPinned
    userAccess
    files {
      name
      s3Key
      s3Url
      url
    }
  }
`;

export const SLPlayerFragment = gql`
  fragment slPlayerFragment on SLPlayer {
    slUid
    playerId
    leagueSlug
    leaguePlayerId
    firstname
    lastname
    primaryposition
    pos
    jerseynumber
    teamSlug
  }
`;

export const RinknetPlayerFragment = gql`
  fragment rinknetPlayerFragment on RinknetPlayer {
    id
    realId
    nhlid
    firstname
    lastname
    currentteam
    currentleague
    currentleagueabbr
    posFDG
  }
`;

export const RINKNET_PLAYER_EXTENDED_FRAGMENT = gql`
  fragment RinknetPlayerExtendedFragment on RinknetPlayer {
    id
    eliteprospectsid
    nhlid
    realId
    firstname
    lastname
    currentteam
    currentteamid
    currentteamnhlid
    currentteamslug
    currentleague
    currentleagueabbr
    jerseynumber
    age
    birthdate
    heightStr
    weight
    hometown
    nationality
    position
    posFDG
    handedness
    round
    overall
    draftyear
    selectedbyteamshort
    selectedbyteamslug
    collegecommitmentteam
    agent {
      id
      title
      firstname
      lastname
      companyname
      email
      phone
      cellphone
    }
  }
`;

export const GET_PLAYER_CARD = gql`
  query GetPlayerCard($slug: ID, $rinknetId: ID, $slLeaguePlayerId: ID) {
    bddPlayer(slug: $slug, rinknetId: $rinknetId, slLeaguePlayerId: $slLeaguePlayerId) {
      id
      slug
      rinknetId
      slUid
      epId
      nhlid
      cfSlug
      bfSlug
      firstname
      lastname
      birthdate
      posFDG
      notesCount
      currentBddTeam {
        id
        longname
        shorthand
        nhlid
        slTeamSlug
      }
      nhlPlayerStats {
        id
        fullName
        firstname
        lastname
        draftDetails
        headshot
        heroImage
        teamLogo
      }
      bssPlayer {
        id
        status
        position
      }
      rinknetPlayer {
        ...RinknetPlayerExtendedFragment
      }
      slPlayerMaster {
        slUid
        slPlayers {
          ...slPlayerFragment
        }
        isolatedImpact100 {
          warPct
          totalImpact55Pct
        }
      }
      bfPlayer {
        ...bfPlayerFragment
      }
      bfCapHit {
        ...bfCapHitFragment
      }
      bddManagedTags {
        id
        flags
        isProjection
        tag {
          ...bddTagFragment
        }
      }
      playerLists {
        id
        listNumber
        playerList {
          id
          isActive
          name
          listType
          pinPriority
        }
      }
    }
  }
  ${RINKNET_PLAYER_EXTENDED_FRAGMENT}
  ${BddTagFragment}
  ${SLPlayerFragment}
  ${BFPlayerFragment}
  ${BFCapHitFragment}
`;

export const GET_BDD_PLAYER_SLUG_FROM_RINKNET_ID = gql`
  query GetBddPlayerSlugsFromRinknetId($rinknetIds: [ID!]) {
    bddPlayers(rinknetIds: $rinknetIds) {
      slug
      rinknetId
    }
  }
`;

export const GET_RINKNET_PLAYERS = gql`
  query GetRinknetPlayers(
    $filters: RinknetPlayerFilters
    $sort: [RinknetPlayerSortEnum]
  ) {
    allRinknetPlayers(filters: $filters, sort: $sort) {
      edges {
        node {
          ...rinknetPlayerFragment
          bddPlayerSlug {
            slug
            slUid
          }
        }
      }
    }
  }
  ${RinknetPlayerFragment}
`;


export const GET_RINKNET_PLAYERS_BRIEF = gql`
  query GetRinknetPlayersBrief(
    $filters: RinknetPlayerFilters
    $sort: [RinknetPlayerSortEnum]
  ) {
    allRinknetPlayers(filters: $filters, sort: $sort) {
      edges {
        node {
          id
          realId
          firstname
          lastname
          currentleague
          currentteam
          jerseynumber
          position
        }
      }
    }
  }
  ${RinknetPlayerFragment}
`;



export const GET_PLAYER_RINKNET_LISTS = gql`
  query GetPlayerRinknetLists($slug: ID!) {
    bddPlayer(slug: $slug) {
      playerRinknetLists {
        id
        listNumber
        playerList {
          scoutFirstName
          scoutLastName
          length
          description
        }
      }
    }
  }
`;

export const GET_BDD_PLAYER_NAMES = gql`
  query ($slugs: [ID!]) {
    bddPlayers(slugs: $slugs) {
      fullName
      rinknetId
      slug
      __typename
    }
  }
`;

export const GET_BDD_PLAYER_NAMES_BY_NHLID = gql`
  query ($nhlids: [ID!]) {
    bddPlayers(nhlids: $nhlids) {
      nhlid
      fullName
      rinknetId
      slug
      __typename
    }
  }
`;

export const buildBddPlayerQuery = (playerFields) => gql`
  query GetBddPlayers($slugs: [ID!], $teamId: Int) {
    bddPlayers(slugs: $slugs, teamId: $teamId) {
      id
      fullName
      rinknetId
      slug
      nhlid
      ${playerFields}
    }
  }
`;

export const GET_PLAYER_CAREER_EP_DATA = gql`
  query GetPlayerCareerEPData($playerPath: String!, $statsPath: String!) {
    stats: epQuery(path: $statsPath, params: "{}")
    player: epQuery(path: $playerPath, params: "{}")
  }
`;

export const GET_PLAYER_NOTES = gql`
  query GetPlayerNotes(
    $bddPlayerSlugs: [ID!]
    $noteType: String
    $bddPlayerListId: ID
    $startDate: Date
    $endDate: Date
    $onlyPinned: Boolean
  ) {
    bddPlayerNotes(
      bddPlayerSlugs: $bddPlayerSlugs
      noteType: $noteType
      bddPlayerListId: $bddPlayerListId
      endDate: $endDate
      startDate: $startDate
      onlyPinned: $onlyPinned
    ) {
      ...bddPlayerNoteFragment
    }
  }
  ${BDDPlayerNoteFragment}
`;

export const GET_PLAYER_NOTE_HISTORY = gql`
  query GetPlayerNoteHistory($parentPlayerNoteId: Int!) {
    bddPlayerNoteHistory(parentPlayerNoteId: $parentPlayerNoteId) {
      id
      user {
        id
        username
      }
      dateCreated
      note
    }
  }
`;

export const CREATE_PLAYER_NOTE = gql`
  mutation CreatePlayerNote(
    $bddPlayerSlug: ID!
    $note: JSONString!
    $noteType: String!
    $requiresPermission: String
    $userAccess: GenericScalar
    $isPrivate: Boolean
    $files: [BDDPlayerNoteFileInput]
    $isPinned: Boolean
  ) {
    createBddPlayerNote(
      input: {
        bddPlayerSlug: $bddPlayerSlug
        note: $note
        files: $files
        noteType: $noteType
        requiresPermission: $requiresPermission
        userAccess: $userAccess
        isPrivate: $isPrivate
        isPinned: $isPinned
      }
    ) {
      bddPlayerNote {
        ...bddPlayerNoteFragment
      }
    }
  }
  ${BDDPlayerNoteFragment}
`;

export const UPDATE_PLAYER_NOTE = gql`
  mutation UpdatePlayerNote(
    $id: Int!
    $note: JSONString
    $requiresPermission: String
    $isPrivate: Boolean
    $files: [BDDPlayerNoteFileInput]
    $isArchived: Boolean
    $isPinned: Boolean
    $userAccess: GenericScalar
  ) {
    updateBddPlayerNote(
      input: {
        id: $id
        note: $note
        requiresPermission: $requiresPermission
        isPrivate: $isPrivate
        files: $files
        isArchived: $isArchived
        isPinned: $isPinned
        userAccess: $userAccess
      }
    ) {
      bddPlayerNote {
        ...bddPlayerNoteFragment
      }
      newBddPlayerNote {
        ...bddPlayerNoteFragment
      }
    }
  }
  ${BDDPlayerNoteFragment}
`;

export const GET_PLAYER_VIEW_COUNT = gql`
  query GetPlayerViewCount(
    $entityId: ID!
    $entityType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    playerViewCount(
      entityId: $entityId
      entityType: $entityType
      startDate: $startDate
      endDate: $endDate
    ) {
      bddPlayerSlug
      gameCount
      uniqueGameCount
    }
  }
`;


export const GET_BDD_PLAYERS = gql`
query ($slugs: [ID!], $bfSlugs: [ID!]) {
  bddPlayers(slugs: $slugs, bfSlugs: $bfSlugs) {
    id
    slug
    bfSlug
    firstname
    lastname
    rinknetId
    slUid
    nhlid
    epId
    cfSlug
    bfSlug
    rinknetPlayer {
      position
      posFDG
      height
      weight
      nationality
    }
  }
}
`;

export const GET_BDD_PLAYERS_WITH_CAP = gql`
query GetBDDPLayersWithCap($slugs: [ID!], $bfSlugs: [ID!], $nhlids: [ID!]) {
  bddPlayers(slugs: $slugs, bfSlugs: $bfSlugs, nhlids: $nhlids) {
    id
    slug
    bfSlug
    firstname
    lastname
    rinknetId
    slUid
    nhlid
    epId
    cfSlug
    bfSlug
    bfCapHit {
      ...bfCapHitFragment
    }
    rinknetPlayer {
      position
      posFDG
      height
      weight
      nationality
    }
  }
}
${BFCapHitFragment}`;

export const buildBDDPlayersQuery = (fields) => {
  const q = `query GetBDDPlayers($slugs: [ID!]) {
    bddPlayers(slugs: $slugs) {
      id
      slug
      ${!!fields ? fields : ''}
    }
  }`;
  
  return gql`${q}`;
}