import { useQuery } from "@apollo/client";
import { GET_PLAYER_LINEUP_SPOTS } from "apollo/queries/lineup.queries";
import { HoverInfo, HoverInteractive } from "components/bdd";
import { BDDLoader } from "components/bdd/bddloader";
import { LinkButton } from "components/bdd/Button";
import { Container } from "components/bdd/Layout";
import { theme } from "constants";
import { formatDate } from "helpers/helpers";
import { useMemo, useState } from "react";
import styled from "styled-components";

const StyledTable = styled.table({
  ...theme.typography.body2,
  width: '100%',
  tr: {
    cursor: 'pointer'
  },
  td: {
    textAlign: 'center'
  }
})

const HoverTable = styled.table({
  ...theme.typography.caption,
  width: '100%',
  textAlign: 'center',
  fontSize: '8pt',
  td: {
    padding: '0 5px'
  }
})

const lineupPlayersToOrderedLine = (isForward, players) => {
  if (isForward) {
    return [
      players.find(p => p.position === 'leftwing'),
      players.find(p => p.position === 'center'),
      players.find(p => p.position === 'rightwing'),
    ]
  } else {
    return [
      players.find(p => p.position === 'leftdefence'),
      players.find(p => p.position === 'rightdefence'),
    ]
  }
}

export default function CommonLines({ bddPlayer, leagueSlug, season, gameType, posFDG, defaultNumToShow=3 }) {
  const [showAll, setShowAll] = useState(false)
  const { data, loading, error } = useQuery(GET_PLAYER_LINEUP_SPOTS, {
    skip: !season || !gameType,
    variables: {
      slUid: bddPlayer.slUid,
      leagueSlug,
      season,
      gameType,
    },
  });

  var lines = useMemo(() => { 
    // returns [{lineSorted (sorted player ids), orderToCount { lineOrdered: count }, count, dates}]
    if (!data) return null
    const slPlayer = { // fake slplayer to put into lines
      firstname: data.slPlayerMaster.firstname,
      lastname: data.slPlayerMaster.lastname
    } 

    return data.slPlayerMaster.lineupSpotsV3.reduce((acc, curr) => {
      if ((curr.isF && curr.linemates.length !== 2) || (!curr.isF && curr.linemates.length !==1)) {
        return acc
      }
      const players = [{ ...curr, slPlayer }, ...curr.linemates];
      const lineSorted = players.map(p => p.leaguePlayerId).sort().join('-');
      const lineOrdered = lineupPlayersToOrderedLine(curr.isF, players)
      const lineOrderedKey = lineOrdered.map(p => p.leaguePlayerId).join('-');
      const existing = acc.find(a => a.lineSorted === lineSorted);
      // either find existing or create new row
      const row = !!existing ? existing : { 
        lineSorted, 
        orderToCount: { [lineOrderedKey]: { key: lineOrderedKey, count: 0, players: lineOrdered }}, 
        count: 0, 
        dates: [] 
      };
      if (!existing) {
        acc.push(row);
      }
      // update row
      row.count++;
      if (lineOrderedKey in row.orderToCount) {
        row.orderToCount[lineOrderedKey].count++;
      } else {
        row.orderToCount[lineOrderedKey] = { key: lineOrderedKey, count: 1, players: lineOrdered }
      }
      row.dates.push({ lineOrdered, game: curr.lineup.game })

      return acc
    }, [])
  }, [data])
  
  lines = lines?.sort((a, b) => b.count - a.count)

  const linesToShow = showAll ? lines : lines?.slice(0, defaultNumToShow)

  return <Container>
    {loading ? <BDDLoader variant='squares' />
    : error ? 'Error.'
    : <StyledTable>
    <tbody>
      {linesToShow?.map(l => {
        var mostCommonPlayers = null
        Object.values(l.orderToCount).forEach((playersAndCount) => {
          if (!mostCommonPlayers) {
            mostCommonPlayers = playersAndCount;
          }
          else if (playersAndCount.count > mostCommonPlayers.count) {
            mostCommonPlayers = playersAndCount;
          }
        })
        return <tr key={l.lineSorted}>
          <td style={{ paddingRight: '15px' }}>
            <HoverInteractive
              content={<HoverTable>
                <tbody>
                {Object.values(l.orderToCount).sort((a, b) => b.count - a.count)
                .map(playersAndCount =>
                  <tr key={playersAndCount.key}>
                    <th>{playersAndCount.players.map(p => p.slPlayer?.lastname || "UNLINKED").join('-')}</th>
                    <td>{playersAndCount.count}</td>
                  </tr>
                )}
              </tbody>
              </HoverTable>}
            >
              {mostCommonPlayers.players.map(p => p.slPlayer?.lastname || "UNLINKED").join(' - ')}
            </HoverInteractive>
          </td>
          <td>
            <HoverInteractive
              content={<div style={{width: '390px', maxHeight: '300px', overflow: 'auto'}}>
                <HoverTable>
                  <tbody>
                  {l.dates.map(g => {
                    const homeShort = g.game.homeTeamSlug.split('.')[1].toUpperCase();
                    const awayShort = g.game.awayTeamSlug.split('.')[1].toUpperCase();
                    return <tr key={g.game.id}>
                      <th>{formatDate(g.game.gameDate)} {awayShort} ({g.game.awayScore}) @ {homeShort} ({g.game.homeScore})</th>
                      <td>
                        <b>{posFDG === 'f' ? 'L' : 'P'}{g.lineOrdered[0].lineNum}</b>
                        {g.lineOrdered.map(p => p.slPlayer?.lastname || "UNLINKED").join(' - ')}
                      </td>
                    </tr>
                  })}
                </tbody>
                </HoverTable>
              </div>}
            >
              {l.count}
            </HoverInteractive>
          </td>
        </tr>
      })}
    </tbody>
    </StyledTable>}
    {lines?.length > defaultNumToShow && <LinkButton
      onClick={() => setShowAll(!showAll)}
    >
      {showAll ? 'Show less' : 'Show more...'}
    </LinkButton>}
  </Container>
}