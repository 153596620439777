import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { HoverInfo } from 'components/bdd';
import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { ExclamationTriangle, Pen } from 'react-bootstrap-icons';
import { scenarioPlayerToWarning } from './helpers';
import { useContext } from 'react';
import { ProObjectiveContext } from '../ProObjectiveContextProvider';
import { useModal } from 'components/bdd/Modal';
import { ScenarioTxModal, useHandleAttachTx } from './ScenarioTx';
import { IconButton } from 'components/bdd/Button';
import { CreateTransactionModal } from '../Transactions/CreateTransactionModal';
import { txToFirstVariant } from '../Transactions/helpers';
import { ScenarioContext } from './ScenarioContextProvider';

export const ScenarioPlayerWarning = ({ scenarioPlayer, canEdit = true }) => {
  const warning = scenarioPlayerToWarning(scenarioPlayer);
  const needsContract = !!warning?.endsWith('needs a contract');

  const scenarioCtx = useContext(ScenarioContext);
  const objectiveCtx = useContext(ProObjectiveContext);
  const { proObjectiveSlug } = !!objectiveCtx ? objectiveCtx : scenarioCtx;

  const name = `${scenarioPlayer.bddPlayer.firstname} ${scenarioPlayer.bddPlayer.lastname}`;
  const { renderModal, showModal, setShowModal } = useModal({
    title: `Sign ${name}`,
    size: 'xl',
  });

  const handleAttachTx = useHandleAttachTx({ scenarioId: scenarioPlayer.scenarioId, proObjectiveSlug, onUpdate: null });

  const icon = needsContract ? <Pen /> : <ExclamationTriangle />;
  return (
    warning && (
      <Container>
        <HoverInfo content={<Container>{warning}</Container>}>
          {canEdit ? (
            <IconButton
              color="red"
              icon={icon}
              onClick={needsContract ? () => setShowModal(true) : null}
            />
          ) : (
            <Icon color="red" icon={icon} />
          )}
        </HoverInfo>

        {showModal &&
          renderModal(
            <CreateTransactionModal
              proObjectiveSlug={proObjectiveSlug}
              defaultSigningBDDPlayerSlug={scenarioPlayer.bddPlayer.slug}
              defaultTab="signing"
              onCompleted={(poTransaction) => {
                handleAttachTx(poTransaction, txToFirstVariant(poTransaction)?.id);
                setShowModal(false);
              }}
              canSign={true}
              canTrade={false}
            />
          )}
      </Container>
    )
  );
};

export const ScenarioPlayer = ({ scenarioPlayer }) => {
  const bddPlayer = scenarioPlayer.bddPlayer;

  return (
    <Container>
      <Row gap={8} justifyContent="space-between">
        <PlayerLinkHover
          slug={bddPlayer.slug}
          name={`${bddPlayer.firstname} ${bddPlayer.lastname}`}
          variant="caption"
        />
        <ScenarioPlayerWarning scenarioPlayer={scenarioPlayer} />
      </Row>
    </Container>
  );
};
