import { Container } from 'components/bdd/Layout';
import { SelectFilter } from './SelectFilter';
import { useGamePlayersData } from '../../hooks/useGamePlayersData';
import { useContext } from 'react';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { VideoContext } from '../..';

export const GamePlayersFilter = ({
  gameUid,
  target = 'skaters',
  isMulti = true,
}) => {
  const { slPlayer, setSlPlayer } = useContext(VideoContext);

  const { data: playerData, placeholder } = useGamePlayersData({
    target,
    gameUid,
  });

  const options = playerData.map((d) => ({
    label: `${d.Player}`,
    value: d.player_id,
  }));

  const label = target == 'skaters' ? 'Player' : 'Goalie';

  return (
    <Container minWidth={200}>
      <Typography variant="body1">{label}s</Typography>
      <StyledBDDSelect
        options={options}
        selectedValue={slPlayer?.playerId}
        onChange={(n, v) => {
          const player = playerData.find((d) => d.player_id == v);

          if (!player) {
            setSlPlayer(null);
          } else {
            setSlPlayer({
              playerId: player.player_id,
              primaryposition: player.position,
              teamSlug: player.team_slug,
            });
          }
        }}
        selectProps={{
          isSearchable: true,
          isClearable: true,
          loading: !!placeholder,
          isMulti,
          placeholder: `Select ${label}...`,
        }}
      />
    </Container>
  );
};
