import { IconButton } from 'components/bdd/Button';
import { EditIcon } from 'components/bdd/Icon/EditIcon';
import { Container, Row } from 'components/bdd/Layout';
import BDDTag from 'components/PlayerTagging/bddtag';
import { useManageTagModal } from 'components/PlayerTagging/useMangeTagModal';

export const PlayerTags = ({ bddPlayer, allowEdit }) => {
  const { setShow, modal } = useManageTagModal({ playerSlug: bddPlayer?.slug });
  
  return (
    bddPlayer && (
      <Container>
        <Row columnGap={1}>
          {bddPlayer.bddManagedTags?.map((t) => (
            <BDDTag key={t.id || t.tagId} isProjection={t.isProjection} tag={t.tag} />
          ))}
          {allowEdit && <IconButton icon={<EditIcon />} onClick={() => setShow(true)} />}
        </Row>
        {modal}
      </Container>
    )
  );
};
