// Wrapper around react-bootstrap button group to make them easier to define
import React from 'react'
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import BDDDropdownMenu from './bdddropdownmenu'


export default function BDDButtonGroup ({
    value,
    onClick,
    options, // [{array of objects}], each must have label (node), optionally "onClick" or "value"
    style={},
    buttonStyle={},
    variant='outline-dark',
    size='md',
    minWidth='100px'
}) {
    if (size === 'xs') {
        buttonStyle = {
            fontSize: '10px',
            padding: '0px 5px',
            margin: 0,
            ...buttonStyle
        }
    }
    return <ButtonGroup style={{ width: '100%', ...style }} size={size}>
        {options.map((opt, idx) => {
            return opt.isDropdown ?  
            <BDDDropdownMenu key={idx}
                trigger={<Button
                    style={{ 
                        width: `${1/options.length}%`, 
                        minWidth: minWidth, 
                        ...buttonStyle,
                        borderRadius: 0
                    }} 
                    active={value == opt.value}
                    variant={variant}
                >
                    {opt.label}
                </Button>} 
                items={opt.items}
            />
            : <Button key={opt.value}
                style={{ 
                  width: `${1/options.length}%`, 
                  minWidth: minWidth, 
                  ...buttonStyle
                }} 
                active={value == opt.value}
                variant={variant}
                onClick={() => {
                    if (!!opt.onClick) { opt.onClick(opt.value)}
                    else { onClick(opt.value) }
                }}
            >
                {opt.label}
            </Button>
        })}
    </ButtonGroup>
}