import { useQuery } from '@apollo/client';
import { GET_RANKINGS_V2 } from 'apollo/queries/statsv2.queries';
import { HoverInfo, HoverInteractive } from 'components/bdd';
import { LinkButton } from 'components/bdd/Button';
import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { useSeasonSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { EventVideoController } from 'components/bdd/Video';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { StatVideoLinks } from 'components/SLStats';
import { theme } from 'constants';
import { roundToX, toiSecToString } from 'helpers/data';
import { getDefaultGameType } from 'helpers/filters';
import { filterSetToString, posFDGToPosition } from 'helpers/hockeyutils';
import { getRedGreenColor } from 'helpers/tables';
import { useState } from 'react';
import useLSGTOptions from '../hooks/useLSGTOptions';
import { VideoClipFilters } from 'components/bdd/Video/components';
import {
  OnIcePlayerFilter,
  PeriodFilter,
  StrengthFilter,
} from 'components/bdd/Video/Filtering/components';
import { SkillTrendChart } from './SkillTrendChart';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';

export const SkillHighlightBoxes = ({
  bddPlayer,
  leagueSlug: leagueSlugArg,
  categories,
  variant,
  includeDividers = true,
  padding,
  minWidth,
  showPlaceholder=false
}) => {
  const posFDG = bddPlayer?.rinknetPlayer.posFDG.toLowerCase();
  const statDefinitions = categories.flatMap((c) =>
    c.statDefinitions.map((sd) => ({
      statSlug: sd.slug,
      ...(!!sd.manpowerSituation 
          ? { manpowerSituation: sd.manpowerSituation } 
          : posFDG === 'g' ? {} : { manpowerSituation: 'ES' }),
      ...(!!sd.strength
        ? { strength: sd.strength }
        : {}),
    }))
  );

  const {
    season,
    league_slug: lsgtLeague,
    game_type: gameType,
    selectLSGT,
    loading: loadingLSGT,
  } = useLSGTOptions({
    slug: bddPlayer.slug,
    variant: 'outlined',
    leagueSlug: leagueSlugArg,
    combineTeamSlugs: true,
    selectProps: {
      placeholder: '',
    },
  });

  const leagueSlug = leagueSlugArg || lsgtLeague;
  const playerId = bddPlayer?.slPlayerMaster?.slPlayers.find(
    (sp) => sp.leagueSlug == leagueSlug
  )?.playerId;

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_RANKINGS_V2, {
      skip: !playerId || !leagueSlug || loadingLSGT,
      variables: {
        leagueSlug,
        gameType,
        playerId,
        statDefinitions,
        season,
        position: posFDG,
        norm: posFDG == 'g' ? '60' : '20',
      },
    })
  );

  const rankings = data?.slPlayerRankingsV2;
  const toiRanking = rankings?.find((r) => r.stat == 'toi');
  const toiSeconds = toiRanking?.value;
  const toiSituation = !!toiRanking ? filterSetToString(toiRanking.filterSet) : '';
  const categoryComponents = data && (
    <SkillHighlightCategoryBoxes
      categories={categories}
      rankings={rankings}
      posFDG={posFDG}
      includeDividers={includeDividers}
      padding={padding}
      minWidth={minWidth}
    />
  );

  if (showPlaceholder && !!placeholder) return placeholder;
  return (
    <>
      <Container>
        <Row columnGap={2} flexWrap alignItems="baseline">
          {selectLSGT}
          <Typography variant="stat">
            {data && !loadingLSGT ? (
              toiSeconds ? (
                <em>
                  Skills based on {toiSecToString(toiSeconds)} {toiSituation} TOI among{' '}
                  {posFDGToPosition[posFDG]}
                </em>
              ) : (
                <em>No data found</em>
              )
            ) : !playerId ? (
              <em>No data found</em>
            ) : (
              <em>Loading...</em>
            )}
          </Typography>
        </Row>
      </Container>
      {data && !loadingLSGT && (
        <Container>
          {variant == 'horizontal' ? (
            <Row columnGap={3}>{categoryComponents}</Row>
          ) : (
            <Column gap={3}>{categoryComponents}</Column>
          )}
        </Container>
      )}
    </>
  );
};

export const SkillHighlightCategoryBoxes = ({
  categories,
  rankings,
  posFDG,
  includeDividers = true,
  minWidth,
  padding
}) => {
  return categories
    .filter((c) => !c.hidden)
    .map((c) => (
      <Column key={`c-shb-${c.label}`} gap={1}>
        {includeDividers && <Divider />}
        <Row columnGap={1} alignItems="baseline">
          <Typography variant="body1">{c.label}</Typography>
          {c.getSubLabel && (
            <Typography variant="stat">{c.getSubLabel(rankings)}</Typography>
          )}
        </Row>
        <SkillHighlightBoxGroup
          statDefinitions={c.statDefinitions}
          rankings={rankings}
          posFDG={posFDG}
          minWidth={minWidth}
          padding={padding}
        />
      </Column>
    ));
};

export const SkillHighlightBoxGroup = ({ statDefinitions, rankings, posFDG, minWidth, padding }) => {
  const defaultManpowerSituation = posFDG === 'g' ? undefined : 'ES';
  return (
    <Row columnGap={2} flexWrap>
      {statDefinitions
        .filter((sd) => !sd.position || sd.position == posFDG)
        .map((sd) => {
          const stat = rankings.find(
            (r) =>
              r.stat == sd.slug &&
              r.filterSet.manpowerSituation == (sd.manpowerSituation || defaultManpowerSituation) &&
              r.filterSet.strength == sd.strength
          );

          if (sd.hideStatDefinition && sd.hideStatDefinition(rankings)) {
            return null;
          }
          return (
            <SkillHighlightBox
              key={`shb-${sd.slug}-${sd.manpowerSituation}-${sd.strength}`}
              statData={stat}
              statDefinition={sd}
              minWidth={minWidth}
              padding={padding}
            />
          );
        })}
    </Row>
  );
};

export const SkillHighlightBox = ({ statData, statDefinition, minWidth=theme.spacing[11], padding=1 }) => {
  const pctile = !!statData
    ? !!statDefinition.negative
      ? statData.percentile
      : 1 - statData.percentile
    : null;
  const color = statData
    ? getRedGreenColor(pctile, 0, 1, false, 1)
    : theme.colors.light.secondary;

  return (
    <EventVideoController>
      <HoverInteractive
        delay={{ show: 400, hide: 400 }}
        renderContent={(forceClose) => (
          <SkillHighlightHoverContent
            statData={statData}
            statDefinition={statDefinition}
            forceClose={forceClose}
          />
        )}
      >
        <Container minWidth={minWidth} variant="button">
          <Column>
            <Typography variant="stat" textAlign="center">
              {statDefinition.label}
            </Typography>
            <Border>
              <Container style={{ backgroundColor: color }} padding={padding}>
                <Typography variant="stat" textAlign="center">
                  {statData ? roundToX(pctile * 100, 1) : 'N/A'}%
                </Typography>
              </Container>
            </Border>
          </Column>
        </Container>
      </HoverInteractive>
    </EventVideoController>
  );
};

const SkillHighlightHoverContent = ({ statData, statDefinition, forceClose }) => {
  const [showAllRankings, setShowAllRankings] = useState(false);
  const { data } = usePlaceholder(
    useQuery(GET_RANKINGS_V2, {
      skip: !statData,
      variables: statData
        ? {
            gameType: statData.gameType,
            leagueSlug: statData.leagueSlug,
            statDefinitions: [
              {
                statSlug: statData.stat,
                manpowerSituation: statData.filterSet.manpowerSituation,
                strength: statData.filterSet.strength,
              },
              {
                statSlug: 'toi',
                manpowerSituation: statData.filterSet.manpowerSituation,
                strength: statData.filterSet.strength,
              },
            ],
            season: statData.season,
            position: statData.position,
            norm: statData.norm,
          }
        : {},
    })
  );

  let rankings = data
    ? data.slPlayerRankingsV2.filter((r) => r.stat == statData.stat)
    : [];

  const toiRankings = data ? data.slPlayerRankingsV2.filter((r) => r.stat == 'toi') : [];

  if (!showAllRankings) {
    rankings = rankings?.filter(
      (r) => r.ranking >= statData.ranking - 5 && r.ranking <= statData.ranking + 5
    );
  }

  rankings = rankings?.sort((a, b) => a.ranking - b.ranking);

  const sourceFilters = {
    league_slug: {
      col: 'league_slug',
      val: statData.leagueSlug,
    },
    season: {
      col: 'season',
      val: statData.season,
    },
  };

  const eventFilters = {
    strength: {
      col: 'strength',
      val: statData.filterSet.strength,
    },
    manpower_situation: {
      col: 'manpower_situation',
      val: statData.filterSet.manpowerSituation,
    },
  };

  const slPlayer = {
    primaryposition: statData.position.toUpperCase(),
    playerId: statData.playerId,
    teamSlug: statData.teamSlug,
  };

  const clipFilters = (
    <Container maxHeight={400} overflow="auto">
      <VideoClipFilters
        renderPlayerFilters={(bddPlayer) => (
          <>
            <OnIcePlayerFilter slug={bddPlayer.slug} />
            <OnIcePlayerFilter slug={bddPlayer.slug} isTeammate={false} />
            <PeriodFilter />
            <StrengthFilter />
          </>
        )}
      />
    </Container>
  );

  const videoLinks = (
    <StatVideoLinks
      clipFilters={clipFilters}
      sourceFilters={sourceFilters}
      eventFilters={eventFilters}
      slPlayer={slPlayer}
      statDefinitionSlug={statData.stat}
      onClick={forceClose}
    />
  );

  return (
    <Container maxWidth={300} minWidth={200}>
      <Column gap={1}>
        <Typography variant="stat">
          <b>{statDefinition.slug}:</b> {statData?.statDefinition.description}
        </Typography>
        {videoLinks}
        
        <BDDErrorBoundary errorContent={null}>
          <SkillTrendChart
            leagueSlug={statData.leagueSlug}
            gameType={statData.gameType}
            playerId={statData.playerId}
            statDefinition={statDefinition}
            posFDG={statData.position}
          />
        </BDDErrorBoundary>

        {statData &&
          (data ? (
            <Typography variant="stat" textAlign="start">
              <LinkButton onClick={() => setShowAllRankings(!showAllRankings)}>
                <Typography variant="stat" textAlign="start">
                  <b>{!showAllRankings ? 'Show all' : 'Filter to player'}</b>
                </Typography>
              </LinkButton>

              <Container maxHeight={200} overflowY="auto">
                <Grid gridTemplateColumns="0.5fr 1fr 0.5fr 0.5fr" columnGap={2}>
                  <b>Rank</b>
                  <b>Player</b>
                  <b>TOI</b>
                  <b>{statDefinition.slug}</b>
                </Grid>
                {rankings.map((r) => {
                  const isPlayer = r.playerId == statData.playerId;
                  const playerName = `${r.slPlayer.firstname} ${r.slPlayer.lastname}`;
                  const color = getRedGreenColor(
                    1 - r.percentile,
                    0,
                    1,
                    !!statDefinition.negative,
                    1
                  );
                  const value = roundToX(r.value, 2);
                  const toi = toiRankings.find((tr) => tr.playerId == r.playerId);

                  return (
                    <Grid
                      key={playerName}
                      gridTemplateColumns="0.5fr 1fr 0.5fr 0.5fr"
                      columnGap={2}
                    >
                      {isPlayer ? (
                        <>
                          <b>#{r.ranking}</b>
                          <b>{playerName}</b>
                          <b>{toiSecToString(toi.value)}</b>
                          <Container style={{ backgroundColor: color }}>
                            <b>{value}</b>
                          </Container>
                        </>
                      ) : (
                        <>
                          <span>#{r.ranking}</span>
                          <span>{playerName}</span>
                          <span>{toiSecToString(toi.value)}</span>
                          <Container style={{ backgroundColor: color }}>
                            <span>{value}</span>
                          </Container>
                        </>
                      )}
                    </Grid>
                  );
                })}
              </Container>
            </Typography>
          ) : (
            <Typography variant="stat">Loading...</Typography>
          ))}
      </Column>
    </Container>
  );
};
