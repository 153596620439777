import {
  faArrowCircleUp,
  faArrowsAltV,
  faBrain,
  faBullseye,
  faCalculator,
  faExpandArrowsAlt,
  faFire,
  faHockeyPuck,
  faShieldAlt,
  faSkating,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDraftPlayerChecks } from 'components/ScoutingV2/hooks/usePlayerDraftChecks';
import { HoverInteractive } from 'components/bdd';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { roundToX } from 'helpers/data';

export const FORWARDS_CHECK_ORDER = [
  'F_MODEL_SCORES',
  'F_TALL_OR_SKATING',
  'F_PRODUCTION',
  'F_SENSE_OR_PM',
  'F_ABOVE_B6',
  'F_SCORING',
  'F_PUCK_HANDLING',
  'F_NOT_TWOWAY',
];

export const DEFENSEMEN_CHECK_ORDER = [
  'D_MODEL_SCORES',
  'D_ABOVE_DEPTH',
  'D_NOT_PHYSICAL_OR_DEFENSIVE',
  'D_INTENSITY_OR_SKATING',
  'D_PRODUCTION_AND_SENSE',
  'D_SCORING',
  'D_PUCK_HANDLING',
];

export const CHECK_ID_TO_DESCRIPTION = {
  F_MODEL_SCORES: 'Above 13.5% Made It and 78.5% OVR Scores',
  F_ABOVE_DEPTH: 'Above Depth as the projection on majority of reports',
  F_SCORING: 'Scoring above 70%',
  F_PUCK_HANDLING: 'Puck Handling above 70%',
  F_SENSE_OR_PM: 'Production above 50% AND Sense above 70%',
  F_TALL_OR_SKATING: `Over 5'10 OR Skating above 75%`,
  F_PRODUCTION: `Production above 70% (DY), 75% (DY+1), 80% (DY+2)`,
  F_NOT_TWOWAY: `Does not have Two-way player type from majority of reports`,
  F_ABOVE_B6: `Above Bottom 6 as the projection on majority of reports`,
  D_MODEL_SCORES: 'Above 13.5% Made It and 78.5% OVR Scores',
  D_ABOVE_DEPTH: 'Above Depth as the projection on majority of reports',
  D_INTENSITY_OR_SKATING: 'Intensity above 70% OR Skating above 70%',
  D_SCORING: 'Scoring above 70%',
  D_PUCK_HANDLING: 'Puck Handling above 70%',
  D_SENSE_OR_PM: 'Production above 50% AND Sense above 70%',
  D_TALL_OR_SKATING: `Over 5'10 OR Skating above 75%`,
  D_PRODUCTION: `Production above 70% (DY), 75% (DY+1), 80% (DY+2)`,
  D_NOT_TWOWAY: `Does not have Two-way player type from majority of reports`,
  D_ABOVE_B6: `Above Bottom 6 as the projection on majority of reports`,
};

export const checkIdToIcon = {
  D_ABOVE_DEPTH: faArrowCircleUp,
  D_INTENSITY_OR_SKATING: faFire,
  D_SCORING: faBullseye,
  D_PUCK_HANDLING: faHockeyPuck,
  D_NOT_PHYSICAL_OR_DEFENSIVE: faShieldAlt,
  D_PRODUCTION_AND_SENSE: faBrain,
  D_MODEL_SCORES: faChartBar,
  F_SENSE_OR_PM: faBrain,
  F_TALL_OR_SKATING: faSkating,
  F_PRODUCTION: faCalculator,
  F_NOT_TWOWAY: faArrowsAltV,
  F_ABOVE_B6: faArrowCircleUp,
  F_PUCK_HANDLING: faHockeyPuck,
  F_SCORING: faBullseye,
  F_MODEL_SCORES: faChartBar,
};

export const posToChecks = (position) => {
  position = position?.toLocaleLowerCase();
  return position === 'f' ? FORWARDS_CHECK_ORDER : DEFENSEMEN_CHECK_ORDER;
};

const DraftPlayerCheck = ({ check }) => {
  const payload = !!check.payload?.length ? JSON.parse(check.payload) : {};

  return (
    <Typography
      style={{
        fontSize: '0.8em',
        color: check.isChecked ? 'green' : 'red',
      }}
    >
      <HoverInteractive
        content={
          <Column>
            <Typography variant="h6">{check.checkDescription}</Typography>
            {Object.keys(payload).map((k) => (
              <Row key={k} gap={8}>
                <Typography variant="body1">{k}:</Typography>
                <Typography variant="body2">
                  {isNaN(payload[k]) ? payload[k] : roundToX(payload[k], 2)}
                </Typography>
              </Row>
            ))}
            <Typography variant="caption">
              Based on {check.draftYear} draft year
            </Typography>
          </Column>
        }
      >
        {!!checkIdToIcon[check.checkId] ? (
          <FontAwesomeIcon icon={checkIdToIcon[check.checkId]} />
        ) : (
          'NA'
        )}
        {/* <Icon icon={!!checkIdToIcon[check.checkId] ? checkIdToIcon[check.checkId] : <XCircle/]} /> */}
      </HoverInteractive>
    </Typography>
  );
};

export const DraftPlayerCheckSimple = ({ id, isChecked }) => {
  return (
    <Typography
      style={{
        fontSize: '0.8em',
        color: isChecked ? 'green' : 'red',
      }}
    >
      {!!checkIdToIcon[id] ? <FontAwesomeIcon icon={checkIdToIcon[id]} /> : 'NA'}
    </Typography>
  );
};

export const DraftPlayerChecks = ({ rinknetId, rinknetIds, draftYear, pos }) => {
  const checkKeys = posToChecks(pos.toLowerCase());
  const { checks } = useDraftPlayerChecks({ rinknetId, rinknetIds, draftYear });
  const orderedChecks = checks?.sort(
    (a, b) => checkKeys.indexOf(a.checkId) - checkKeys.indexOf(b.checkId)
  );
  return (
    <Container>
      <Row gap={8}>
        {orderedChecks?.map((c) => (
          <DraftPlayerCheck key={c.id} check={c} />
        ))}
      </Row>
    </Container>
  );
};
