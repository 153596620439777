import { useLazyQuery } from '@apollo/client';
import { GET_FILE_UPLOAD_URL } from 'apollo/queries/fileUploads.queries';
import { IconButton } from 'components/bdd/Button';
import { useOnScreenRef } from 'components/bdd/hooks';
import useDebounce from 'components/bdd/hooks/usedebounce';
import { Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { theme } from 'constants';
import { useField } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';
import styled from 'styled-components';
import { EMPTY_TEXT } from '../..';
import { isMobile } from 'react-device-detect';
import { BDDInput } from 'components/bdd/Input';
import { InlineChromiumBugfix } from '..';

const TextInput = styled(BDDInput)({
  ...theme.typography.body2,
});

export const MediaField = ({ readOnly, children, attributes, element }) => {
  const { ref, hasBeenVisible } = useOnScreenRef();
  const editor = useSlateStatic();
  let fileOptions = [];

  if (!readOnly) {
    const [_, meta] = useField('files');
    fileOptions = meta?.value || [];
  }

  const selected = useSelected();
  const focused = useFocused();

  const [s3Key, setS3Key] = useState(element.s3Key);
  const debouncedS3Key = useDebounce(s3Key, 400);

  const fileSelect = (
    <StyledBDDSelect
      selectedValue={s3Key}
      options={fileOptions?.map((f) => ({
        label: f.name,
        value: f.s3Key,
        file: f,
      }))}
      selectProps={{
        placeholder:
          fileOptions && fileOptions.length > 0
            ? 'Select attached image...'
            : 'No images attached...',
      }}
      onChange={(_, v) => {
        setS3Key(v);
      }}
    />
  );

  const fileInput = (
    <TextInput
      type="text"
      onClick={(e) => e.stopPropagation()}
      value={s3Key || ''}
      onChange={(e) => {
        setS3Key(e.target.value);
      }}
    />
  );

  useEffect(() => {
    if (!debouncedS3Key) return;
    const path = ReactEditor.findPath(editor, element);

    Transforms.setNodes(
      editor,
      { ...element, s3Key: debouncedS3Key },
      { at: path, text: EMPTY_TEXT }
    );

    getFileUploadUrl({
      variables: {
        destinationUrl: debouncedS3Key,
        folder: 'file_uploads',
      },
    });
  }, [debouncedS3Key]);

  const [getFileUploadUrl, { data, loading }] = useLazyQuery(GET_FILE_UPLOAD_URL);

  const url = data?.fileUploadUrl.s3Url;
  const mediaType = !!debouncedS3Key?.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : 'video';

  return (
    <span {...attributes} contentEditable={false}>
      <InlineChromiumBugfix />
      <span ref={ref}>
        {hasBeenVisible && (
          <>
            {!readOnly && (
              <Container as="span">
                <Row as="span" columnGap={2}>
                  <Container width={250}>{fileSelect}</Container>
                  <Container width={250}>{fileInput}</Container>
                  <IconButton
                    onClick={() => {
                      let path = ReactEditor.findPath(editor, element);

                      if (editor.children.length == 1) {
                        Transforms.unsetNodes(editor, {}, { at: path });
                      } else {
                        Transforms.removeNodes(editor, { at: path });
                      }
                    }}
                    icon={<Trash />}
                  />
                </Row>
              </Container>
            )}
            {url &&
              mediaType &&
              (mediaType == 'image' ? (
                <img
                  src={url}
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100em',
                    boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`,
                  }}
                />
              ) : (
                <video
                  src={url}
                  controls
                  preload={isMobile ? 'none' : 'metadata'}
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100em',
                  }}
                />
              ))}
          </>
        )}
        {children}
      </span>
      <InlineChromiumBugfix />
    </span>
  );
};
