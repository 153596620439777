import { LinkButton } from 'components/bdd/Button';
import { LinkPopover } from 'components/bdd/Popover';
import { getTimeRangeOptions, seasonOptions } from './constants';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { Cell, buildColumns } from 'components/bdd/Table';
import { Column, Container, Row } from 'components/bdd/Layout';
import {
  GET_CONTRACT_LISTS,
  UPDATE_BF_CONTRACT_LIST,
} from 'apollo/queries/bruinsfriendly.queries';
import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useContractSearchContext, useContractSearchFilterValues } from './context';
import { Filters, useFilterContext, useFilterValues } from 'components/bdd/Filters';
import { CustomStatQueryFilters, ignorePriorSeasonsForTimeRange } from './StatFilters';
import { Button } from 'react-bootstrap';

export const useTimeRangeColumn = ({ contractList }) => {
  const columns = useMemo(() => {
    if (!contractList) {
      return [];
    }

    return buildColumns([
      {
        id: 'timeRange',
        // minWidth: 20,
        // justify: 'start',
        disableSort: true,
        header: 'Time Range',
        renderCell: (d) => (
          <Row justifyContent="center">
            <TimeRangeButton contract={d} contractList={contractList} />
          </Row>
        ),
      },
    ]);
  }, [contractList]);

  return columns.length > 0 ? columns[0] : null;
};

// export const TimeRangeHover = ({}) => {
//   const timeRange = useContractSearchContext((state) => state.timeRange);

//   return (
//     <Filters defaults={timeRange}>
//       <TimeRangeButton>
//         <StatFilters />
//       </TimeRangeButton>
//     </Filters>
//   );
// };

export const TimeRangeButton = ({ contract, contractList }) => {
  const defaultTimeRange = useContractSearchContext((state) => state.timeRange);
  const contractPayloadFilters = contractList.contracts.find(
    (c) => c.contractId == contract.id
  )?.payload?.filters;

  const timeRange = {
    ...defaultTimeRange,
    ...(contractPayloadFilters || {}),
  };

  const { season } = useContractSearchFilterValues();
  const hasOverride = !!contractPayloadFilters;
  const timeRangeOption = getTimeRangeOptions(season).find(
    (o) => o.value == timeRange['time-range'].value
  );

  const timeRangeLabel =
    timeRangeOption?.renderLabel?.(timeRange) ?? timeRangeOption?.label;

  return (
    <LinkPopover
      hideTransition
      renderLink={(setShow, target, show) => (
        <Container ref={target}>
          <LinkButton onClick={() => setShow(!show)}>
            <Cell>{timeRangeLabel}</Cell>
            {hasOverride && (
              <Container style={{ position: 'absolute', right: -5, top: -2 }}>
                <Typography variant="body2" color={theme.colors.states.danger}>
                  *
                </Typography>
              </Container>
            )}
          </LinkButton>
        </Container>
      )}
      renderContent={(forceClose) => (
        <Filters defaults={timeRange}>
          <OverrideTimeRangeForm
            season={season}
            contract={contract}
            contractList={contractList}
            // onChange={forceClose}
          />
        </Filters>
      )}
    />
  );
};

export const OverrideTimeRangeForm = ({ contract, contractList }) => {
  const filters = useFilterContext((state) => state.filters);
  const { season, 'time-range': timeRange } = useFilterValues();
  const { removeFilter } = useFilterContext((state) => state.actions);

  const [updateContractList, { data, loading }] = useMutation(UPDATE_BF_CONTRACT_LIST);
  const [disclaimer, setDisclaimer] = useState(false);

  return (
    <Container padding={2} onClick={(e) => e.stopPropagation()}>
      <Column gap={2}>
        <Row columnGap={2} rowGap={2} flexWrap>
          <Filters.Select
            id={'time-range'}
            label={'Time Range'}
            options={getTimeRangeOptions(season)}
            width={150}
            onChange={(timeRange) => {
              if (timeRange != 'platform-stats') {
                removeFilter('platform-prior-seasons');
              }

              if (timeRange != 'custom-sl-stats') {
                removeFilter('custom_sl_filters');
              }

              if (timeRange != 'multiple-season-stats') {
                removeFilter('season');
              }
            }}
          />
          <Filters.Select
            id={'season'}
            col={'season'}
            label="Target Season"
            options={seasonOptions}
            width={100}
            hide={timeRange != 'multiple-season-stats'}
          />
          <Filters.Number
            id={'platform-prior-seasons'}
            label={'Prior Seasons'}
            isClearable
            min={0}
            max={5}
            hide={ignorePriorSeasonsForTimeRange(timeRange)}
          />
          {timeRange == 'custom-sl-stats' && <CustomStatQueryFilters />}
        </Row>
        <Row columnGap={2}>
          <Button
            variant="outline-dark"
            size="sm"
            disabled={loading}
            onClick={() => {
              updateContractList({
                variables: {
                  input: {
                    id: contractList.id,
                  },
                  contractsToUpdate: [
                    {
                      contractId: contract.id,
                      payload: {
                        filters,
                      },
                    },
                  ],
                },
                onCompleted: () => {
                  setDisclaimer(true);
                  // onChange && onChange();
                },
                refetchQueries: [GET_CONTRACT_LISTS],
              });
            }}
          >
            <Typography variant="stat">
              <b>{loading ? 'Applying...' : 'Apply'}</b>
            </Typography>
          </Button>
          {disclaimer && (
            <Typography variant="stat" color={theme.colors.states.danger}>
              Refresh page to see changes
            </Typography>
          )}
        </Row>
      </Column>
    </Container>
  );
};
