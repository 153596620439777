import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BFPlayerContractsSmall from 'components/PlayersV2/Contract/BFPlayerContractsSmall';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { theme } from 'constants';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { lineupSpotStatsToPositions, lineupSpotStatsToRoles } from './helpers';
import { Cell, Header } from 'components/bdd/Table';
import { getFutureSeasonChoices, getSeasonChoices } from 'helpers/filters';

export const playerRoleOptions = [
  {
    value: 'top-6',
    label: 'TOP 6',
    tag: 'T6',
    filter: {
      val: 20,
      cond: '>=',
      positions: ['center', 'rightwing', 'leftwing'],
      lineNums: [1],
    },
  },
  {
    value: 'middle-6',
    label: 'MIDDLE 6',
    tag: 'M6',
    filter: {
      val: 40,
      cond: '>=',
      positions: ['center', 'rightwing', 'leftwing'],
      lineNums: [1, 2],
    },
  },
  {
    value: 'bottom-6',
    label: 'BOTTOM 6',
    tag: 'B6',
    filter: {
      val: 30,
      cond: '>=',
      positions: ['center', 'rightwing', 'leftwing'],
      lineNums: [3, 4],
    },
  },
  {
    value: 'top-6-center',
    label: 'TOP 6 CENTER',
    tag: 'T6 C',
    filter: { val: 20, cond: '>=', lineNums: [1], positions: ['center'] },
  },
  {
    value: 'middle-6-center',
    label: 'MIDDLE 6 CENTER',
    tag: 'M6 C',
    filter: { val: 20, cond: '>=', lineNums: [1, 2], positions: ['center'] },
  },
  {
    value: 'bottom-6-center',
    label: 'BOTTOM 6 CENTER',
    tag: 'B6 C',
    filter: { val: 20, cond: '>=', lineNums: [3, 4], positions: ['center'] },
  },
  {
    value: 'top-6-winger',
    label: 'TOP 6 WINGER',
    tag: 'T6 W',
    filter: {
      val: 20,
      cond: '>=',
      lineNums: [1],
      positions: ['leftwing', 'rightwing'],
    },
  },
  {
    value: 'middle-6-winger',
    label: 'MIDDLE 6 WINGER',
    tag: 'M6 W',
    filter: {
      val: 20,
      cond: '>=',
      lineNums: [1, 2],
      positions: ['leftwing', 'rightwing'],
    },
  },
  {
    value: 'bottom-6-winger',
    label: 'BOTTOM 6 WINGER',
    tag: 'B6 W',
    filter: {
      val: 20,
      cond: '>=',
      lineNums: [3, 4],
      positions: ['leftwing', 'rightwing'],
    },
  },
  {
    value: 'top-pair-d',
    label: 'TOP PAIR DEFENSEMEN',
    tag: 'TP',
    filter: {
      val: 30,
      cond: '>=',
      lineNums: [1],
      positions: ['leftdefence', 'rightdefence'],
    },
  },
  {
    value: 'top-4-d',
    label: 'TOP 4 DEFENSEMEN',
    tag: 'T4',
    filter: {
      val: 30,
      cond: '>=',
      lineNums: [1, 2],
      positions: ['leftdefence', 'rightdefence'],
    },
  },
  {
    value: 'pair-3-d',
    label: 'PAIR 3 DEFENSEMEN',
    tag: 'PAIR 3',
    filter: {
      val: 40,
      cond: '>=',
      lineNums: [2, 3],
      positions: ['leftdefence', 'rightdefence'],
    },
  },
];

export const positionOptions = ['C', 'LW', 'RW', 'RD', 'LD', 'G'].map((p) => ({
  label: p,
  value: p,
}));

export const seasonOptions = getSeasonChoices('NHL', true);
export const expiryYearSeasonOptions = seasonOptions
  .reverse()
  .concat(getFutureSeasonChoices(9))
  .reverse();

export const contractTypeOptions = [
  {
    value: 'EntryLevel',
    label: 'Entry Level',
  },
  {
    value: 'Standard',
    label: 'Standard',
  },
  {
    value: 'ThirtyFivePlus',
    label: '35+',
  },
];

export const clauseDetailOptions = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 'Full',
    label: 'Full',
  },
  {
    value: 'Modified',
    label: 'Modified',
  },
];

export const signingStatusOptions = [
  {
    value: 'ELC',
    label: 'ELC',
  },
  {
    value: 'UFA',
    label: 'UFA',
  },
  {
    value: 'RFA',
    label: 'RFA',
  },
];

export const expiryStatusOptions = [
  {
    value: '10.2(c)',
    label: '10.2(c)',
  },
  {
    value: 'UFA',
    label: 'UFA',
  },
  {
    value: 'RFA',
    label: 'RFA',
  },
];

export const generalFilterOptions = [
  {
    value: 'active-players',
    label: 'Active Players',
    subCaption: 'All contracts that were/will be active in the given season',
  },
  {
    value: 'free-agents',
    label: 'Free Agents',
    subCaption: 'All contracts that expired in given season.',
  },
  {
    value: 'ufas',
    label: 'UFAs',
    subCaption: 'All contracts expiry as UFA in given season',
  },
  {
    value: 'rfas',
    label: 'RFAs',
    subCaption: 'All contracts that expiry is RFA in given season',
  },
  {
    value: 'elcs',
    label: 'ELCs',
    subCaption: 'All contracts that were ELC in given season',
  },
];

export const handednessOptions = [
  {
    value: 'L',
    label: 'Left',
  },
  {
    value: 'R',
    label: 'Right',
  },
];

export const playerColumns = [
  {
    id: 'player',
    width: 150,
    alwaysVisible: true,
    Header: <Header>Player</Header>,
    accessor: (d) => `${d.lastName}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <PlayerLinkHover name={`${d.firstName} ${d.lastName}`} slug={d.bddPlayer?.slug} />
      );
    },
  },
  {
    id: 'team',
    width: 75,
    Header: <Header>Team</Header>,
    accessor: (d) => `${d.cfTeam?.abbreviation}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Row>
          {d.cfTeam?.nhlid && (
            <img alt="" height="20px" src={getNHLTeamLogoUrl(d.cfTeam?.nhlid)} />
          )}
          <Typography variant="body2">{d.cfTeam?.abbreviation}</Typography>
        </Row>
      );
    },
  },
  {
    id: 'position',
    Header: <Header>Position</Header>,
    width: 100,
    accessor: (d) => `${d.position}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container>
          <Cell>{d.position}</Cell>
        </Container>
      );
    },
  },
  {
    id: 'age',
    width: 50,
    Header: <Header>Age</Header>,
    accessor: (d) => `${d.age}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container>
          <Cell>{roundToX(d.age, 1)}</Cell>
        </Container>
      );
    },
  },
];

export const contractColumns = [
  {
    id: 'contract',
    sortDescFirst: true,
    Header: <Header>Contract</Header>,
    width: 100,
    accessor: (d) => `${d.activeAndFutureContracts?.[0]?.aav}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container width="fit-content">
          <BFPlayerContractsSmall cfPlayerSlug={d.slug} cfCapHit={d.currentCapHit} />
        </Container>
      );
    },
  },
  {
    id: 'final_cap_hit',
    sortDescFirst: true,
    width: 100,
    Header: <Header>Cap Hit</Header>,
    accessor: (d) => `${d.currentCapHit?.finalCapHit}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Typography variant="body2">
          {formatDollars(d.currentCapHit?.finalCapHit, { truncate: false })}
        </Typography>
      );
    },
  },
];

export const predictedPositionsColumns = [
  {
    id: 'predicted-position',
    Header: <Header>Predicted Positions</Header>,
    accessor: (d) => `${d.position}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container>
          <Row columnGap={1} flexWrap>
            {lineupSpotStatsToPositions(d.lineupSpotStats).map((p) => (
              <Cell key={`cell-p-${p}`}>{p}</Cell>
            ))}
          </Row>
        </Container>
      );
    },
  },
  {
    id: 'roles',
    Header: <Header>Roles</Header>,
    accessor: (d) => `${d.position}`,
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container padding={1}>
          <Row columnGap={1} flexWrap>
            {lineupSpotStatsToRoles(d.lineupSpotStats).map((role) => (
              <Border key={`role-${role.tag}`}>
                <Container padding={1} backgroundColor={theme.colors.light.secondary}>
                  <Typography variant="stat">{role.tag}</Typography>
                </Container>
              </Border>
            ))}
          </Row>
        </Container>
      );
    },
  },
];
