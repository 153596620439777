import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import produce from 'immer';
import { PersonCircle, Check } from 'react-bootstrap-icons';

import { theme } from 'constants';
import { useQueryWithPlaceholder } from 'apollo/hooks';
import {
  QUERY_USERS_AND_PLAYER_LIST,
  UPDATE_PLAYER_LIST,
} from 'apollo/queries/playerlist.queries';
import {
  useSelect,
  useUserSelect,
  useUserGroupSelect,
} from 'components/bdd/Select';
import { Button } from 'react-bootstrap';
import { toastBddApiError } from 'components/bdd/bddtoasts';
import { useUser } from 'helpers/user';

const Container = styled.div({
  minWidth: 300,
  margin: theme.spacing[1],
  // minHeight: props => props.minHeight,
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled.div({
  ...theme.typography.subtitle1,
  marginTop: theme.spacing[2],
  marginBottom: theme.spacing[2],
});

const Divider = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  width: '100%',
  overflow: 'auto',
});

const PublicLinkAccess = styled.div({
  ...theme.typography.subtitle2,
  ...theme.font.weight.regular,
  marginTop: theme.spacing[3],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AddContainer = styled.div({
  ...theme.typography.subtitle2,
  ...theme.font.weight.regular,
  marginTop: theme.spacing[4],
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const FullSelectContainer = styled.div({
  flexGrow: 1,
  marginRight: theme.spacing[2],
});

const UsersContainer = styled.div({
  ...theme.typography.subtitle2,
  ...theme.font.weight.regular,
  flexGrow: 1,
  marginTop: theme.spacing[4],
  marginBottom: theme.spacing[4],
});

const UserContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const UsernameContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[2],
});

const Footer = styled.div({
  ...theme.typography.subtitle2,
  ...theme.font.weight.regular,
  display: 'flex',
  justifyContent: 'end',
  margin: theme.spacing[2],
});

const linkOptions = [
  {
    label: 'Anyone with public access',
    value: 'public',
  },
  {
    label: 'Only people invited',
    value: 'private',
  },
];

const readWriteOptions = [
  {
    value: 'read',
    label: 'Can read',
  },
  {
    value: 'write',
    label: 'Can edit',
  },
];

const userReadWriteOptions = [
  ...readWriteOptions,
  {
    value: 'remove',
    label: 'Remove',
  },
];

const ListAccessHover = ({ listId, showHeader=true }) => {
  const { isUserAdmin } = useUser();
  const [completed, setCompleted] = useState(false);
  const { data, placeholder } = useQueryWithPlaceholder(
    QUERY_USERS_AND_PLAYER_LIST,
    {
      id: listId,
    }
  );

  const [updatePlayerlist, { loading: updateLoading }] = useMutation(
    UPDATE_PLAYER_LIST,
    {
      onError: (error) => toastBddApiError(error),
      onCompleted: () => {
        setCompleted(true);
        // setTimeout(() => handleClose(), 500);
      },
    }
  );

  const [users, setUsers] = useState([]);
  const { userId: userToAdd, userSelect } = useUserSelect();
  const { userIds: usersToAdd, userGroupSelect } = useUserGroupSelect();

  const { selected: publicOption, select: publicSelect } = useSelect({
    options: linkOptions,
    initialSelectedValue: data?.playerList?.isPublic ? 'public' : 'private',
    variant: 'outlined',
  });

  const { selected: publicEditOption, select: publicEditSelect } = useSelect({
    options: readWriteOptions,
    initialSelectedValue: data?.playerList?.isPublicEdit ? 'write' : 'read',
    variant: 'outlined',
  });

  const isPublic = publicOption.value == 'public';
  const isPublicEdit = publicEditOption.value == 'write';

  useEffect(() => {
    if (!!data) {
      setUsers(data.playerList.accessList);
    }
  }, [data]);

  if (!data) {
    return placeholder;
  }

  const canEditAccess = data.playerList.isMyList || isUserAdmin();
  const idToUser = data.users.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const handleSubmit = () => {
    if (!canEditAccess) {
      alert('Only the owner of a list can edit user access list!');
      return;
    }
    const variables = {
      input: { id: listId },
    };

    // We add || isPublicEdit since if you can edit a list you can also view it
    if (isPublic != data.playerList.isPublic) {
      variables.input['isPublic'] = isPublic || isPublicEdit;
    }
    if (isPublicEdit != data.playerList.isPublicEdit) {
      variables.input['isPublicEdit'] = isPublicEdit;
    }

    variables['setUserAccessList'] = users.map((u) => ({
      userId: u.userId,
      canView: u.canView || u.canEdit, // if you can edit a list you need to be able to view it
      canEdit: u.canEdit,
    }));

    updatePlayerlist({ variables });
  };

  const handleAddUser = (userToAdd) => {
    handleAddUsers([userToAdd]);
  };

  const handleAddUsers = (userIdsToAdd) => {
    const usersToAdd = userIdsToAdd
      .map((userToAdd) => {
        const userExists = users.find((u) => u.userId == userToAdd);

        return (
          !userExists && {
            userId: userToAdd,
            user: idToUser[userToAdd],
            canView: true,
            canEdit: false,
          }
        );
      })
      .filter((userToAdd) => userToAdd);

    setUsers([...users, ...usersToAdd]);
  };

  const handleUserChange = (user, option) => { 
    if (option.value === 'remove') {
      setUsers(
        produce(users, (draft) => {
          const userIndexToRemove = draft.findIndex(
            (draftUser) => draftUser.userId === user.userId
          );

          draft.splice(userIndexToRemove, 1);
        })
      );
    } else {
      setUsers(
        produce(users, (draft) => {
          const userToUpdate = draft.find(
            (draftUser) => draftUser.userId === user.userId
          );

          userToUpdate.canView = option.value === 'read';
          userToUpdate.canEdit = option.value === 'write';
        })
      );
    }
  };

  return (
    <Container style={{ minHeight: canEditAccess ? 300 : 100 }}>
      {showHeader && <Header>User access</Header>}
      {showHeader && <Divider />}
      {canEditAccess ? (
        <>
          <PublicLinkAccess>
            {publicSelect}
            {!isPublic ? 'Can access' : publicEditSelect}
          </PublicLinkAccess>
          <AddContainer>
            <FullSelectContainer>{userGroupSelect}</FullSelectContainer>
            <Button
              variant="outline-dark"
              onClick={() => handleAddUsers(usersToAdd)}
            >
              Add
            </Button>
          </AddContainer>
          <AddContainer>
            <FullSelectContainer>{userSelect}</FullSelectContainer>
            <Button
              variant="outline-dark"
              onClick={() => handleAddUser(userToAdd)}
            >
              Add
            </Button>
          </AddContainer>
          <UsersContainer>
            {users.map((user) => (
              <UserContainer key={user.userId}>
                <UserAccess
                  user={user}
                  onSelected={(option) => handleUserChange(user, option)}
                />
              </UserContainer>
            ))}
          </UsersContainer>
          <Divider />
          <Footer>
            <Button variant="outline-dark" onClick={handleSubmit}>
              {completed ? (
                <>
                  <Check /> Submitted
                </>
              ) : updateLoading ? (
                'Submitting...'
              ) : (
                'Submit'
              )}
            </Button>
          </Footer>
        </>
      ) : (
        <>
          <PublicLinkAccess>
            {isPublic ? 'Anyone with public access' : 'Only people invited'}{' '}
            {isPublicEdit ? 'can edit' : 'can view'}
          </PublicLinkAccess>
          <UsersContainer>
            {users.map((user) => (
              <UserContainer key={user.userId}>
                <UsernameContainer>
                  <PersonCircle />
                  <div>{user.user.username}</div>
                </UsernameContainer>
                {user.canEdit ? 'can edit' : 'can read'}
              </UserContainer>
            ))}
          </UsersContainer>
          <Divider />
          <Footer></Footer>
        </>
      )}
    </Container>
  );
};

const UserAccess = ({ user, onSelected }) => {
  const { selected, select } = useSelect({
    options: userReadWriteOptions,
    initialSelectedValue: user.canEdit
      ? userReadWriteOptions[1]
      : userReadWriteOptions[0],
    variant: 'outlined',
  });

  useEffect(() => {
    if (selected) {
      onSelected && onSelected(selected);
    }
  }, [selected]);

  return (
    <UserContainer key={user.userId}>
      <UsernameContainer>
        <PersonCircle />
        <div>{user.user.username}</div>
      </UsernameContainer>
      {select}
    </UserContainer>
  );
};

export default ListAccessHover;
