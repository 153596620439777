import eventMappingData from './eventMapping.json';

export const eventNametoValueKey = {
  shot: 'XPG',
  expected_assist: 'iXA',
};

const labels = {
  successful: 'Successful',
  first: 'First',
  second: 'Second',
  BEHIND_THE_NET_CENTER: 'Behind the Net Center',
  BEHIND_THE_NET_CORNER: 'Behind the Net Corner',
  CENTER_LANE: 'Center Lane',
  CENTER_POINT: 'Center Point',
  HALFWALL: 'Half Wall',
  INNER_SLOT: 'Inner Slot',
  LOW_ANGLE: 'Low Angle',
  OUTER_LANE: 'Outer Lane',
  OUTER_POINT: 'Outer Point',
  OUTER_SLOT: 'Outer Slot',
  failed: 'Failed',
  oz_entry_carry: 'Oz Entry Carry',
  oz_entry_pass: 'Oz Entry Pass',
  '1on0': '1 on 0',
  '1on1': '1 on 1',
  '1on2': '1 on 2',
  '2on1': '2 on 1',
  '2on2': '2 on 2',
  '2on3': '2 on 3',
  '3on1': '3 on 1',
  '3on2': '3 on 2',
  '3on3': '3 on 3',
  eastofdot: 'East Of Dot',
  eastside: 'East Side',
  eastwest: 'East West',
  middleice: 'Middle Ice',
  midice: 'Mid Ice',
  outerice: 'Outer Ice',
  westeast: 'West East',
  westofdot: 'West Of Dot',
  westside: 'West Side',
  whistlebeforeexit: 'Whistle Before Exit',
  withcyclepass: 'With Cycle Pass',
  withfailedcyclepass: 'With Failed Cycle Pass',
  withplayafter: 'With Play After',
  withshotonnet: 'With Shot on Net',
  withshotonnetwhistle: 'With Shot on Net Whistle',
  withslotshot: 'With Slot Shot',
  fromcounter: 'From Counter',
  fromdumpout: 'From Dump Out',
  fromdzlpr: 'From DZ LPR',
  fromdztakeaway: 'From DZ Takeaway',
  fromexit: 'From Exit',
  fromfaceoff: 'From Faceoff',
  fromleftoutericeexit: 'From Left Outer Ice Exit',
  frommiddleiceexit: 'From Middle Ice Exit',
  fromnzleftoutericetouch: 'From NZ Left Outer Ice Touch',
  fromnzlpr: 'From NZ LPR',
  fromnzmiddleicetouch: 'From NZ Middle Ice Touch',
  fromnzrightoutericetouch: 'From NZ Right Outer Ice Touch',
  fromnztakeaway: 'From NZ Takeaway',
  fromoutlet: 'From Outlet',
  fromrightoutericeexit: 'From Right Outer Ice Exit',
  fromstretch: 'From Stretch',
  fromtakeback: 'From Take Back',
  withchance: 'With Chance',
  withcycle: 'With Cycle',
  withgoal: 'With Goal',
  withozturnover: 'With OZ Turnover',
  withshot: 'With Shot',
  withzoneset: 'With Zone Set',
  carry: 'Carry',
  pass: 'Pass',
  withentry: 'With Entry',
  withleftoutericeentry: 'With Left Outer Ice Entry',
  withmiddleiceentry: 'With Middle Ice Entry',
  withnzleftoutericetouch: 'With NZ Left Outer Ice Touch',
  withnzmiddleicetouch: 'With NZ Middle Ice Touch',
  withnzrightoutericetouch: 'With NZ Right Outer Ice Touch',
  withnzturnover: 'With NZ Turnover',
  withrightoutericeentry: 'With Right Outer Ice Entry',
  dplay: 'D Play',
  alongboards: 'Along Boards',
  withdumpin: 'With Dump In',
  withdumpout: 'With Dump Out',
  withexit: 'With Exit',
  withleftoutericeexit: 'With Left Outer Ice Exit',
  withmiddleiceexit: 'With Middle Ice Exit',
  withrightoutericeexit: 'With Right Outer Ice Exit',
  goal: 'Goal',
  lpr: 'LPR',
  puckprotection: 'Puck Protection',
  reception: 'Reception',
  shot: 'Shot',
  '1timer': '1 Timer',
  backhand: 'Backhand',
  backhandforehand: 'Backhand Forehand',
  crest: 'Crest',
  deflected: 'Deflected',
  fanned: 'Fanned',
  firstozreception: 'First OZ Reception',
  fivehole: 'Five Hole',
  forehandbackhand: 'Forehand Backhand',
  highleft: 'High Left',
  highright: 'High Right',
  lowhigh: 'Low High',
  lowleft: 'Low Left',
  lowright: 'Low Right',
  quickrelease: 'Quick Release',
  screened: 'Screened',
  seam: 'Seam',
  slapshot: 'Slap Shot',
  snapshot: 'Snap Shot',
  withpressure: 'With Pressure',
  withrebound: 'With Rebound',
  wristshot: 'Wrist Shot',
  blueline_block: 'Blueline Block',
  body_check: 'Body Check',
  none_receptionprevention: 'None Reception Prevention',
  None_receptionprevention: 'None Reception Prevention',
  pass_block: 'Pass Block',
  shot_block: 'Shot Block',
  stick_check: 'Stick Check',
  nzgreyarea: 'NZ Grey Area',
  recovered: 'Recovered',
  chip: 'Chip',
  dump: 'Dump',
  crossice: 'Cross Ice',
  rim: 'Rim',
  soft: 'Soft',
  straight: 'Straight',
  undetermined: 'Undetermined',
  none: 'None',
  boards: 'Boards',
  flip: 'Flip',
  ice: 'Ice',
  controlled: 'Controlled',
  uncontrolled: 'Uncontrolled',
  hipresopdump: 'Hi Pres Op Dump',
  hipresopdumpcontested: 'Hi Pres Op Dump Contested',
  opdump: 'Op Dump',
  contested: 'Contested',
  firsttouchsuccess: 'First Touch Success',
  hastouch: 'Has Touch',
  pressured: 'Pressured',
  strongsideexit: 'Strong Side Exit',
  strongsidetransition: 'Strong Side Transition',
  weaksideexit: 'Weak Side Exit',
  weaksidetransition: 'Weak Side Transition',
  error: 'Error',
  errorcontested: 'Error Contested',
  faceoff: 'Faceoff',
  faceoffcontested: 'Faceoff Contested',
  nofore: 'No Fore',
  rebound: 'Rebound',
  reboundcontested: 'Rebound Contested',
  no_shot: 'No Shot',
  d2d: 'D2D',
  d2doffboards: 'D2D Off Boards',
  eastwestoffboards: 'East West Off Boards',
  north: 'North',
  northoffboards: 'North Off Boards',
  outlet: 'Outlet',
  outletoffboards: 'Outlet Off Boards',
  outside: 'Outside',
  outsideblocked: 'Outside Blocked',
  ozentry: 'OZ Entry',
  ozentryoffboards: 'OZ Entry Off Boards',
  ozentrystretch: 'OZ Entry Stretch',
  ozentrystretchoffboards: 'OZ Entry Stretch Off Boards',
  quickpass: 'Quick Pass',
  rush: 'Rush',
  rushoffboards: 'Rush Off Boards',
  slot: 'Slot',
  slotblocked: 'Slot Blocked',
  south: 'South',
  southoffboards: 'South Off Boards',
  stretch: 'Stretch',
  stretchoffboards: 'Stretch Off Boards',
  fromcycle: 'From Cycle',
  fromdactivation: 'From D Activation',
  fromentry: 'From Entry',
  fromhighdztakeaway: 'From High DZ Takeaway',
  fromleftoutericeentry: 'From Left Outer Ice Entry',
  frommiddleiceentry: 'From Middle Ice Entry',
  fromozfirstpossession: 'From OZ First Possession',
  fromozsecondpossession: 'From OZ Second Possession',
  fromoztakeaway: 'From OZ Takeaway',
  fromrightoutericeentry: 'From Right Outer Ice Entry',
  fromtakeawaysustainedpossession: 'From Takeaway Sustained Possession',
  fromzoneset: 'From Zone Set',
  recoveredwithentry: 'Recovered With Entry',
  recoveredwithexit: 'Recovered With Exit',
  recoveredwithshotonnet: 'Recovered With Shot on Net',
  recoveredwithshotonnetandslotshot: 'Recovered With Shot on Net and Slot Shot',
  recoveredwithslotshot: 'Recovered With Slot Shot',
  afterconceding: 'After Conceding',
  afterscoring: 'After Scoring',
  centerfodot: 'Center FO Dot',
  eastfodot: 'East FO Dot',
  firstafterpp: 'First After PP',
  lefthandedopponent: 'Left Handed Opponent',
  righthandedopponent: 'Right Handed Opponent',
  startofperiod: 'Start Of Period',
  strongside: 'Strong Side',
  weakside: 'Weak Side',
  westfodot: 'West FO Dot',
  gwg: 'GWG',
  opdumpcontested: 'Op Dump Contested',
  assistedtakeaway: 'Assisted Takeaway',
  fromboards: 'From Boards',
  fromchip: 'From Chip',
  fromdump: 'From Dump',
  fromflip: 'From Flip',
  fromice: 'From Ice',
  individualtakeaway: 'Individual Takeaway',
  takeaway: 'Takeaway',
  withclear: 'With Clear',
  withdzturnover: 'With DZ Turnover',
  nodumpin: 'No Dump In',
  from_dumpout: 'From Dump Out',
  from_pass: 'From Pass',
  from_puckprotection: 'From Puck Protection',
  from_reception: 'From Reception',
  from_shot: 'From Shot',
  fordeflection: 'For Deflection',
  highslotline: 'High Slot Line',
  lowslotline: 'Low Slot Line',
  possessionkept: 'Possession Kept',
  possessionlost: 'Possession Lost',
  slotline: 'Slot Line',
  toinnerslot: 'To Inner Slot',
  toouterslot: 'To Outer Slot',
  fromforecheck: 'From Forecheck',
  crosschecking: 'Crosschecking',
  delayofgame: 'Delay of Game',
  faceoffviolation: 'Faceoff Violation',
  goalieinterference: 'Goalie Interference',
  highsticking: 'High Sticking',
  holding: 'Holding',
  holdingthestick: 'Holding the Stick',
  hooking: 'Hooking',
  interference: 'Interference',
  kneeing: 'Kneeing',
  otherundisciplined: 'Other Undisciplined',
  puckoverglass: 'Puck Over Glass',
  roughing: 'Roughing',
  slashing: 'Slashing',
  tripping: 'Tripping',
  dumpin: 'Dump In',
  dumpout: 'Dump Out',
  is_possession_start: 'Is Possession Start',
  offstick: 'Off Stick',
  onstick: 'On Stick',
  body: 'Body',
  deke: 'Deke',
  regular: 'Regular',
  deflected_shot: 'Deflected Shot',
  outside_shot_rebound: 'Outside Shot Rebound',
  pass_for_1timer: 'Pass For 1 Timer',
  rush_pass: 'Rush Pass',
  slot_lpr: 'Slot LPR',
  slot_pass: 'Slot Pass',
  slot_shot: 'Slot Shot',
  wraparound: 'Wraparound',
  blocked: 'Blocked',
  missed: 'Missed',
  on_net: 'On Net',
  cycle: 'Cycle',
  forecheck: 'Forecheck',
  other: 'Other',
  behindnet: 'Behind Net',
  behindnetcenter: 'Behind Net Center',
  behindnetcorner: 'Behind Net Corner',
  chance: 'Chance',
  emptynet: 'Empty Net',
  firstshot: 'First Shot',
  highdangerchance: 'High Danger Chance',
  highdangermissedshotrecovery: 'High Danger Missed Shot Recovery',
  lowdangerchance: 'Low Danger Chance',
  oppteamlpr: 'Opp Team LPR',
  preshotmovement: 'Pre Shot',
};

const valueToOption = (value) => ({
  value,
  label: labels?.[value] || value,
});

export const eventNameToMapping = eventMappingData.reduce((mapping, e) => {
  mapping[e['event_name']] = {
    eventName: e['event_name'],
    outcomes: e['outcome']
      .substring(1, e['outcome'].length - 1)
      .split(',')
      .map((v) => valueToOption(v)),
    eventTypes: e['event_type']
      .substring(1, e['event_type'].length - 1)
      .split(',')
      .map((v) => valueToOption(v)),
    eventFlags: e['event_flags']
      .substring(2, e['event_flags'].length - 2)
      .split(',')
      .filter((v) => v !== '"{}"')
      .map((v) => valueToOption(v)),
    precedingFlags: e['preceding_flags']
      .substring(2, e['preceding_flags'].length - 2)
      .split(',')
      .filter((v) => v !== '"{}"')
      .map((v) => valueToOption(v)),
    successiveFlags: e['successive_flags']
      .substring(2, e['successive_flags'].length - 2)
      .split(',')
      .filter((v) => v !== '"{}"')
      .map((v) => valueToOption(v)),
    location: e['location']
      .substring(1, e['location'].length - 1)
      .split(',')
      .map((v) => valueToOption(v)),
    location2: e['location_2']
      .substring(1, e['location_2'].length - 1)
      .split(',')
      .filter((v) => v !== 'NULL')
      .map((v) => valueToOption(v)),
  };

  return mapping;
}, {});

// let allValues = [];
// eventMappingData.forEach((e) => {
//   allValues = allValues
//     .concat(e['outcome'].substring(1, e['outcome'].length - 1).split(','))
//     .concat(e['event_type'].substring(1, e['event_type'].length - 1).split(','))
//     .concat(e['event_flags'].substring(2, e['event_flags'].length - 2).split(','))
//     .concat(e['preceding_flags'].substring(2, e['preceding_flags'].length - 2).split(','))
//     .concat(
//       e['successive_flags'].substring(2, e['successive_flags'].length - 2).split(',')
//     )
//     .concat(e['location'].substring(1, e['location'].length - 1).split(','))
//     .concat(e['location_2'].substring(1, e['location_2'].length - 1).split(','));
// });

// console.log([...new Set(allValues)]);

export const getCombinedOptions = (eventNames, getOptions) => {
  return eventNames.reduce((combinedOptions, eventName) => {
    const options = getOptions(eventName);

    return [
      ...combinedOptions,
      ...options.filter((o) => !combinedOptions.find((co) => co.value == o.value)),
    ];
  }, []);
};

export const getCombinedOutcomeOptions = (eventNames) => {
  return getCombinedOptions(eventNames, getOutcomeOptions);
};

export const getOutcomeOptions = (eventName) => {
  switch (eventName) {
    case 'shot':
      return [
        {
          value: 'on_net',
          label: 'On Net',
        },
        {
          value: 'missed',
          label: 'Missed',
        },
        {
          value: 'blocked',
          label: 'Blocked',
        },
      ];
    default:
      return [
        {
          value: 'successful',
          label: 'Successful',
        },
        {
          value: 'failed',
          label: 'Failed',
        },
      ];
  }
};

export const getCombinedPrecedingFlagOptions = (eventNames) => {
  return getCombinedOptions(eventNames, getPrecedingFlagOptions);
};

export const getPrecedingFlagOptions = (eventName) => {
  const precedingFlags = [
    {
      value: 'fromfaceoff',
      label: 'From Faceoff',
    },
    {
      value: 'fromtakeback',
      label: 'From Takeback',
    },
    {
      value: 'fromcounter',
      label: 'From Counter',
    },
    {
      value: 'fromforecheck',
      label: 'From Forecheck',
    },
    {
      value: 'fromdzlpr',
      label: 'From DZ LPR',
    },
    {
      value: 'fromnzlpr',
      label: 'From NZ LPR',
    },
    {
      value: 'fromcycle',
      label: 'From Cycle',
    },
    {
      value: 'fromoutlet',
      label: 'From Outlet',
    },
    {
      value: 'fromstretch',
      label: 'From Stretch',
    },
    {
      value: 'fromexit',
      label: 'From Exit',
    },
    {
      value: 'fromentry',
      label: 'From Entry',
    },
  ];

  let flagsToFilter = [];

  switch (eventName) {
    case 'shot':
    case 'assist':
    case 'goal':
    case 'expected_assist':
      flagsToFilter = [
        'fromfaceoff',
        'fromtakeback',
        'fromcounter',
        'fromforecheck',
        'fromdzlpr',
        'fromnzlpr',
        'fromcycle',
        'fromoutlet',
        'fromstretch',
        'fromexit',
        'fromentry',
      ];
      break;
    case 'controlled_entry':
      flagsToFilter = [
        'fromfaceoff',
        'fromtakeback',
        'fromcounter',
        'fromforecheck',
        'fromdzlpr',
        'fromnzlpr',
        'fromcycle',
        'fromoutlet',
        'fromstretch',
        'fromexit',
        'fromentry',
      ];
      break;
    case 'controlled_exit':
      flagsToFilter = ['fromfaceoff', 'fromtakeback', 'fromcounter', 'fromnzlpr'];
      break;
    case 'pass':
      flagsToFilter = [
        'fromfaceoff',
        'fromtakeback',
        'fromcounter',
        'fromforecheck',
        'fromdzlpr',
        'fromnzlpr',
      ];
      break;
    case 'dumpin':
      flagsToFilter = [
        'fromfaceoff',
        'fromtakeback',
        'fromcounter',
        'fromforecheck',
        'fromdzlpr',
        'fromnzlpr',
        'fromoutlet',
        'fromstretch',
        'fromexit',
      ];
      break;
    default:
      return [];
  }

  return precedingFlags.filter((pf) => flagsToFilter.includes(pf.value));
};

export const getCombinedSuccessiveFlagOptions = (eventNames) => {
  return getCombinedOptions(eventNames, getSuccessiveFlagOptions);
};

export const getSuccessiveFlagOptions = (eventName) => {
  const successiveFlagsToFilter = [
    {
      value: 'withexit',
      label: 'With Exit',
    },
    {
      value: 'withdumpout',
      label: 'With Dump-out',
    },
    {
      value: 'withentry',
      label: 'With Entry',
    },
    {
      value: 'withdumpin',
      label: 'With Dump-in',
    },
    {
      value: 'withshot',
      label: 'With Shot',
    },
    {
      value: 'withchance',
      label: 'With Chance',
    },
    {
      value: 'withgoal',
      label: 'With Goal',
    },
    {
      value: 'withozturnover',
      label: 'With OZ Turnover',
    },
    {
      value: 'withcycle',
      label: 'With Cycle',
    },
  ];

  let flagsToFilter = [];

  switch (eventName) {
    case 'pass':
    case 'structured_breakout':
    case 'unstructured_breakout':
    case 'lpr':
    case 'takeback':
    case 'counter':
    case 'controlled_entry':
      flagsToFilter.push(
        ...['withexit', 'withdumpout', 'withentry', 'withdumpin', 'withgoal', 'withcycle']
      );
    case 'pass':
    case 'structured_breakout':
    case 'unstructured_breakout':
    case 'lpr':
    case 'takeback':
    case 'counter':
    case 'structured_forecheck':
    case 'unstructured_forecheck':
    case 'turnover':
    case 'takeaway':
    case 'controlled_entry':
      flagsToFilter.push(...['withshot', 'withchance']);
      break;
    default:
      return [];
  }

  return successiveFlagsToFilter.filter((pf) => flagsToFilter.includes(pf.value));
};

export const shotTypeOptions = [
  {
    value: 'wristshot',
    label: 'Wrist Shot',
  },
  {
    value: 'snapshot',
    label: 'Snap Shot',
  },
  {
    value: 'slapshot',
    label: 'Slap Shot',
  },
  {
    value: 'backhand',
    label: 'Backhand',
  },
  {
    value: 'forehandbackhand',
    label: 'Forehand-backhand',
  },
  {
    value: 'backhandforehand',
    label: 'Backhand-forehand',
  },
  {
    value: 'wraparound',
    label: 'Wrap Around',
  },
];

export const shotQualityOptions = [
  {
    value: 'deflected',
    label: 'Deflected',
  },
  {
    value: 'withpressure',
    label: 'With Pressure',
  },
  {
    value: 'screened',
    label: 'Screened',
  },
  {
    value: 'fanned',
    label: 'Fanned',
  },
  {
    value: 'reboundshot',
    label: 'Rebound Shot',
  },
  {
    value: 'quickrelease',
    label: 'Quick Release',
  },
  {
    value: '1timer',
    label: 'One Timer',
  },
  {
    value: 'eastside',
    label: 'East-side',
  },
  {
    value: 'eastwest',
    label: 'East-west',
  },
  {
    value: 'westeast',
    label: 'West-east',
  },
];

export const shotLocationOptions = [
  {
    value: 'highleft',
    label: 'High Left',
  },
  {
    value: 'highright',
    label: 'High Right',
  },
  {
    value: 'lowleft',
    label: 'Low Left',
  },
  {
    value: 'lowright',
    label: 'Low Right',
  },
  {
    value: 'lowhigh',
    label: 'Low High',
  },
  {
    value: 'fivehole',
    label: 'Five-hole',
  },
];

export const shotChanceOptions = [
  {
    value: 'chance',
    label: 'All chances',
  },
  {
    value: 'highdangerchance',
    label: 'High Danger',
  },
  {
    value: 'middangerchance',
    label: 'Medium Danger',
  },
  {
    value: 'lowdangerchance',
    label: 'Low Danger',
  },
];

export const shotOutcomeOptions = [{ value: 'withgoal', label: 'With Goal' }];

export const getCombinedEventTypeOptions = (eventNames) => {
  return getCombinedOptions(eventNames, getEventTypeOptions);
};

export const getEventTypeOptions = (eventName) => {
  switch (eventName) {
    case 'shot':
      return [
        {
          value: 'forecheck',
          label: 'Forecheck',
        },
        {
          value: 'cycle',
          label: 'Cycle',
        },
        {
          value: 'rush',
          label: 'Rush',
        },
        {
          value: 'faceoff',
          label: 'Faceoff',
        },
      ];
    case 'possession_time':
      return [
        {
          value: 'pass',
          label: 'Pass',
        },
        {
          value: 'controlled_exit',
          label: 'Controlled Exit',
        },
        {
          value: 'dz_deke',
          label: 'DZ Deke',
        },
        {
          value: 'stretch_pass',
          label: 'Stretch Pass',
        },
        {
          value: 'redline_carry',
          label: 'Redline Carry',
        },
        {
          value: 'outlet_pass',
          label: 'Outlet Pass',
        },
        {
          value: 'controlled_entry',
          label: 'Controlled Entry',
        },
        {
          value: 'puckprotection',
          label: 'Puck Protection',
        },
      ];
    case 'defensive_play':
      return [
        {
          value: 'pass_block',
          label: 'Pass Block',
        },
        {
          value: 'shot_block',
          label: 'Shot Block',
        },
        {
          value: 'none_receptionprevention',
          label: 'Reception Prevention',
        },
        {
          value: 'stick_check',
          label: 'Stick Check',
        },
        {
          value: 'body_check',
          label: 'Body Check',
        },
        {
          value: 'blueline_block',
          label: 'Blueline Block',
        },
      ];
    default:
      return [];
  }
};

const positionToOnIceKey = {
  F: 'team_forwards_on_ice',
  D: 'team_defencemen_on_ice',
  G: 'team_goalie_on_ice',
};

const positionToOppIceKey = {
  F: 'opp_forwards_on_ice',
  D: 'opp_defencemen_on_ice',
  G: 'opp_goalie_on_ice',
};

const onIceKeyToOppIceKey = {
  team_forwards_on_ice: 'opp_forwards_on_ice',
  team_defencemen_on_ice: 'opp_defencemen_on_ice',
  team_goalie_on_ice: 'opp_goalie_on_ice',
  team_unit: 'opp_unit',
};

const emptyFilters = [
  'team_forwards_on_ice',
  'team_defencemen_on_ice',
  'opp_forwards_on_ice',
  'opp_defencemen_on_ice',
  'player_id',
  'team_goalie_on_ice',
  'opp_goalie_on_ice',
  'team_unit',
  'opp_unit',
].reduce((filters, key) => {
  filters[key] = {
    val: null,
  };

  return filters;
}, {});

export const applySecondaryPlayerFilter = ({
  filters,
  secondaryPlayerFilters,
  involvement,
}) => {
  let updatedFilters = { ...filters };

  // Merge player filters
  Object.keys(secondaryPlayerFilters).forEach((key) => {
    let keyToUse = key;
    // Flip involment column
    if (involvement == 'woia') {
      keyToUse = onIceKeyToOppIceKey[key];
    }

    if (keyToUse in updatedFilters && updatedFilters[keyToUse].val) {
      updatedFilters[keyToUse].val = [
        ...updatedFilters[keyToUse].val,
        ...secondaryPlayerFilters[key].val,
      ];

      if (secondaryPlayerFilters[key].cond) {
        updatedFilters[keyToUse].cond = secondaryPlayerFilters[key].cond;
      }
    } else {
      updatedFilters[keyToUse] = secondaryPlayerFilters[keyToUse];
    }
  });

  return updatedFilters;
};

export const applyPlayerFilter = (slPlayer, selectedValue, filters) => {
  // Clear all existing player filters
  let updatedFilters = { ...filters, ...emptyFilters };

  switch (selectedValue) {
    case 'primary':
      // Player must exist for 'primary' events
      if (!slPlayer) return updatedFilters;

      return {
        ...updatedFilters,
        player_id: {
          col: 'player_id',
          val: slPlayer.playerId,
        },
        // team_slug: {
        //   col: 'team_slug',
        //   val: slPlayer.teamSlug,
        // },
      };
    case 'woi':
      const onIcePlayerFilter = slPlayer
        ? {
            [positionToOnIceKey[slPlayer.primaryposition]]: {
              col: positionToOnIceKey[slPlayer.primaryposition],
              cond: slPlayer.primaryposition == 'G' ? 'isin' : 'array_includes_any',
              val: [slPlayer.playerId],
            },
          }
        : {};

      return {
        ...updatedFilters,
        ...onIcePlayerFilter,
        ...(filters['team_slug']?.val
          ? {
              team_slug: {
                col: 'team_slug',
                val: slPlayer ? slPlayer.teamSlug : filters['team_slug']?.val,
              },
            }
          : {}),
      };

    case 'woia':
      const oppTeamFilter = {
        opp_team_slug: {
          col: 'opp_team_slug',
          val: slPlayer ? slPlayer.teamSlug : filters['team_slug']?.val,
        },
        team_slug: {
          col: 'team_slug',
          val: null,
        },
      };

      // const teamFilter =
      //   'opp_team_slug' in updatedFilters
      //     ? {
      //         team_slug: {
      //           ...updatedFilters['opp_team_slug'],
      //           col: 'team_slug',
      //         },
      //       }
      //     : {
      //         team_slug: {
      //           col: 'team_slug',
      //           val: null,
      //         },
      //       };

      const offIcePlayerFilter = slPlayer
        ? {
            [positionToOppIceKey[slPlayer.primaryposition]]: {
              col: positionToOppIceKey[slPlayer.primaryposition],
              cond: slPlayer.primaryposition == 'G' ? 'isin' : 'array_includes_any',
              val: [slPlayer.playerId],
            },
          }
        : {};

      return {
        ...updatedFilters,
        ...oppTeamFilter,
        // ...teamFilter,
        ...offIcePlayerFilter,
      };
    default:
      return updatedFilters;
  }
};

export const getPlayerInvolvement = (filters) =>
  'player_id' in filters
    ? 'primary'
    : 'team_forwards_on_ice' in filters || 'team_defencemen_on_ice' in filters
    ? 'woi'
    : 'woia';

export const getLSGSourceFilters = ({ leagueSlug, season, gameType }) => {
  let filters = {};

  if (leagueSlug) {
    filters['league_slug'] = {
      col: 'league_slug',
      val: leagueSlug,
    };
  }

  if (season) {
    filters['season'] = {
      col: 'season',
      val: season,
    };
  }

  if (gameType) {
    filters['game_type'] = {
      col: 'game_type',
      val: gameType,
    };
  }

  return filters;
};
