import { gql } from "@apollo/client";
import { PageInfoFragment } from "./pagination.queries";

export const BDDFeedbackFragment = gql`fragment bddFeedbackFragment on BDDFeedback {
  id
  feedbackType
  title
  bugType
  sourceUrl
  payload
  body
  createdAt
  createdBy {
    id
    name
    username
  }
  files {
    id
    feedbackId
    name
    s3Key
    s3Url
    url
  }
  comments {
    id
    feedbackId
    comment
    createdAt
    createdBy {
      id
      name
      username
    }
  }
  lastModified
  isPrivate
  isArchived
  pinPriority
  status
}`

export const GET_BDD_FEEDBACK = gql`
  query GetBddFeedback($id: ID!) {
    bddFeedback(id: $id) {
      ...bddFeedbackFragment
    }
  }
${BDDFeedbackFragment}`

export const GET_PAGINATED_FEEDBACK = gql`
  query GetPaginatedFeedback(
    $sort: [BDDSortV2]
    $limit: Int
    $offset: Int
    $userId: ID
  ) {
    paginatedFeedback(
      sort: $sort
      limit: $limit
      offset: $offset
      userId: $userId
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...bddFeedbackFragment
      }
    }
  }
${BDDFeedbackFragment}
${PageInfoFragment}
`  

export const CREATE_BDD_FEEDBACK = gql`
  mutation CreateBddFeedback($input: CreateBDDFeedbackInput!, $files: [BDDFeedbackFileInput!]) {
    createBddFeedback(input: $input, files: $files) {
      bddFeedback {
        ...bddFeedbackFragment
      }
    }
  }
${BDDFeedbackFragment}
`

export const UPDATE_BDD_FEEDBACK = gql`
  mutation UpdateBddFeedback($input: UpdateBDDFeedbackInput!, $filesToAdd: [BDDFeedbackFileInput!], $filesToRemove: [ID!], $commentsToAdd: [String!], $commentsToRemove: [ID!]) {
    updateBddFeedback(input: $input, filesToAdd: $filesToAdd, filesToRemove: $filesToRemove, commentsToAdd: $commentsToAdd, commentsToRemove: $commentsToRemove) {
      bddFeedback {
        ...bddFeedbackFragment
      }
    }
  }
${BDDFeedbackFragment}
`