import { StatVideoLinks } from '.';
import { useQuery } from '@apollo/client';
import { GET_FSETS_V2 } from 'apollo/queries/statsv2.queries';
import { Typography } from 'components/bdd/Typography';
import { useContext, useMemo } from 'react';
import { SLStatContext } from '.';

const filtersToIgnore = [
  'team_slug',
  'slug',
  'player_id',
  'currentteam_shorthand',
  'team_shorthand',
  'opp_team_shorthand',
  'position',
  'Player',
];

export const StatCellVideoLinks = ({ statDefinition, statRow, onClick, context }) => {
  const {
    query: { target, filters, index },
  } = useContext(SLStatContext) || context;

  const { data: fsData, loading: fsLoading } = useQuery(GET_FSETS_V2);
  const filterSet =
    !!fsData &&
    fsData.slStatsFilterSetsV2.find((fs) => fs.id == statDefinition.filter_set_id);

  // Video event filters need to be build off:
  // 1. Filters from the stat query,
  // 2. Index results of each stat row
  // 3. Filter sets of the given stat definition
  const videoEventFilters = useMemo(() => {
    if (fsLoading) return null;

    let teamFilter = {
      team_slug: {
        col: 'team_slug',
        val: target == 'teams' ? statRow['slug'] : statRow['team_slug'],
      },
    };

    const slPlayer = ['skaters', 'goalies'].includes(target) && {
      primaryposition: statRow['position'].toUpperCase(),
      playerId: statRow['player_id'],
      teamSlug: statRow['team_slug'],
    };

    const queryFilters = filters.filter((f) => !filtersToIgnore.includes(f.col));

    const indexFilters = index
      .filter((i) => !filtersToIgnore.includes(i))
      .reduce((filters, index) => {
        if (!(index in statRow)) return filters;

        filters[index] = {
          col: index,
          val: statRow[index],
        };

        return filters;
      }, {});

    const filterSetFilters = {
      strength: {
        col: 'strength',
        val: filterSet.strength,
      },
      manpowerSituation: {
        col: 'manpower_situation',
        val: filterSet.manpowerSituation,
      },
    };

    return {
      sourceFilters: {
        ...teamFilter,
        ...indexFilters,
        ...filterSetFilters,
      },
      eventFilters: queryFilters,
      slPlayer,
      statDefinitionSlug: statDefinition.slug,
    };
  }, [filterSet]);

  return videoEventFilters ? (
    <StatVideoLinks {...videoEventFilters} onClick={onClick} />
  ) : (
    <Typography variant="stat">Loading...</Typography>
  );
};
