import React from "react"
import styled from "styled-components"
import { getRedGreenColor } from "../../helpers/tables"
import { HoverInfo } from "./bddcomponents"

const HBoxStyles = styled.div`
    display: inline-block;
    text-align: center;
    .title {
        font-size: 0.8em;
    }
    .box {
        padding: ${props => props.padding};
        font-size: ${props => props.fontSize};
        background: ${props => props.color};
        border: ${props => props.border};
    }
`
export const HighlightBox = ({
    content,
    children,
    title='',
    color,
    fontSize='18px',
    padding='5px 5px',
    border='1px solid #eee',
    margin='5px',
    style={},
    hoverContent
}) => {
    style = {
        margin,
        ...style
    }
    return <HBoxStyles color={color} padding={padding} fontSize={fontSize} border={border} style={style}>
        {!!title && <div className='title'>{title}</div>}
        <HoverInfo content={!!hoverContent ? hoverContent : null}>
        <div className='box'>
            {content == undefined || content == null ?  children : content}
        </div>
        </HoverInfo>
    </HBoxStyles>
}

export const PctHighlightBox = ({
    val,
    title,
    decimals=0,
    subtractFromOne=true,
    hues=[120, 0],
    ...rest
}) => {
    const isNa = isNaN(val) || val == null
    if (!isNa && subtractFromOne) val = 1-val
    const color = getRedGreenColor(val, 0, 1, false, 1, hues)
    return <HighlightBox 
        title={title} 
        content={isNa ? 'N/A' : `${(100*val).toFixed(decimals)}%`}
        color={isNa ? '#ddd' : color}
        {...rest}
    />
}

export const WARPctHighlightBox = ({
    slUid
}) => {

    return <PctHighlightBox

    />
}