

export const parseLineupSpot = lineupSpot => {
    const [depthLeague, position, lineNum] = lineupSpot.split('-')
    return { depthLeague, position, lineNum }
}

export const buildLineupSpot = (depthLeague, position, lineNum) => {
    return `${depthLeague}-${position}-${lineNum}`
}

export const fPos = ['leftwing', 'center', 'rightwing']
export const dgPos = ['leftdefence', 'rightdefence', 'goalie']