import { useQuery } from '@apollo/client';
import { GET_FSETS_V2 } from 'apollo/queries/statsv2.queries';
import { LinkButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { EventVideoController } from 'components/bdd/Video';
import { EventVideoPlayer } from 'components/bdd/Video/components';
import { useStatDefinitionFilters } from 'components/bdd/Video/Filtering/hooks';
import { useContext, useMemo } from 'react';
import { CameraVideo } from 'react-bootstrap-icons';
import { SLStatContext } from '.';
import { ShiftVideoButton } from 'components/PlayersV2/ByGame/GameShiftVideoButton';

const isTimeStat = (statDefinition) => {
  if (!statDefinition || !statDefinition.kwargs) return false;
  const parsedKeyArgs = JSON.parse(statDefinition.kwargs);
  return parsedKeyArgs.is_time_stat;
};

const isInvalidStat = (statDefinition) =>
  statDefinition &&
  (isTimeStat(statDefinition) ||
    statDefinition.dependsOn?.some((sd) => isTimeStat(sd)) ||
    !statDefinition.statArgs);

export const useStatVideoDefinitions = ({ statDefinitionSlug }) => {
  const { statDefinition, loading } = useStatDefinitionFilters({
    slug: statDefinitionSlug,
  });

  const isInvalid = isInvalidStat(statDefinition);

  return {
    loading,
    isInvalid,
    statDefinitions:
      statDefinition?.dependsOn.length > 0
        ? statDefinition?.dependsOn.filter(
            (statDefinition) => !isInvalidStat(statDefinition)
          )
        : !!statDefinition
        ? [statDefinition]
        : [],
  };
};

export const StatVideoLinks = ({
  sourceFilters,
  eventFilters,
  secondaryPlayerFilters,
  slPlayer,
  statDefinitionSlug,
  sort,
  onClick,
  excludeStatSlugLabels,
  clipFilters,
  children,
  firstClipIdx = 0,
}) => {
  const { statDefinitions, loading, isInvalid } = useStatVideoDefinitions({
    statDefinitionSlug,
  });

  if (statDefinitionSlug == 'toi') {
    return (
      <ShiftVideoButton
        sourceFilters={sourceFilters}
        slPlayer={slPlayer}
        eventFilters={eventFilters}
        secondaryPlayerFilters={secondaryPlayerFilters}
        onClick={onClick}
      />
    );
  }

  const getStatDisplayName = (statDefinition) =>
    statDefinition?.primaryName || statDefinition?.slug;

  if (loading) {
    return <Typography variant="stat">Loading video definitions...</Typography>;
  }

  if (isInvalid) {
    return (
      <Typography variant="stat">No video available for {statDefinitionSlug}</Typography>
    );
  }

  return (
    <Container>
      <Row columnGap={3}>
        {statDefinitions.map((statDefinition) => (
          <StatVideoLinkWrapper
            key={statDefinition.slug}
            sourceFilters={sourceFilters}
            eventFilters={eventFilters}
            secondaryPlayerFilters={secondaryPlayerFilters}
            slPlayer={slPlayer}
            sort={sort}
            onClick={onClick}
            statSlug={statDefinition.slug}
            clipFilters={clipFilters}
            firstClipIdx={firstClipIdx}
          >
            {!!children ? (
              children
            ) : (
              <Row columnGap={1}>
                <CameraVideo />
                {!excludeStatSlugLabels && (
                  <LinkButton>
                    <Typography noWrap variant="stat">
                      <b>{getStatDisplayName(statDefinition)}</b>
                    </Typography>
                  </LinkButton>
                )}
              </Row>
            )}
          </StatVideoLinkWrapper>
        ))}
      </Row>
    </Container>
  );
};

export const StatVideoLinkWrapper = ({
  children,
  sourceFilters,
  eventFilters,
  secondaryPlayerFilters,
  clipFilters,
  slPlayer,
  statSlug,
  sort,
  onClick,
  firstClipIdx = 0,
}) => {
  const { renderModal } = useContext(ModalContext);

  return (
    <Container>
      <div
        onClick={() => {
          renderModal({
            body: (
              <EventVideoController
                sourceFilters={sourceFilters}
                eventFilters={eventFilters}
                slPlayer={slPlayer}
                secondaryPlayerFilters={secondaryPlayerFilters}
                statDefinitionSlug={statSlug}
                clipFilters={clipFilters}
                sort={sort}
              >
                <EventVideoPlayer firstClipIdx={firstClipIdx} />
              </EventVideoController>
            ),
            size: 'xxl',
          });

          onClick && onClick();
        }}
      >
        {children}
      </div>
    </Container>
  );
};
