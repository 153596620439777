import { Border, Column, Container, Row } from "components/bdd/Layout"
import { CreateUpdateSigning } from "./CreateUpdateSigning"
import { Typography } from "components/bdd/Typography"
import useBDDSearchSelect from "components/Search/useBDDSearchSelect"
import { GET_PLAYER_AND_CONTRACT_SEARCH_QUERY } from "apollo/queries/search.queries"
import { getCurrentSeason, getNextOrCurrentSeason, seasonToStartYear } from "helpers/hockeyutils"
import { theme } from "constants"
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries'
import usePlaceholder from 'components/Placeholder/usePlaceholder'
import { useQuery } from '@apollo/client'
import { LoadingPlaceholder } from 'components/bdd/Button'


export const CreateSigning = ({ proObjectiveSlug, defaultBDDPlayerSlug, showSimilar, onCompleted }) => {
  const getSubLabel = (result) => {
    if (!result.cfPlayer) return 'No contract data';
    return `${result.cfPlayer.expiryStatus} in ${result.cfPlayer.freeAgentYear}`;
  }

  const isNotFree = (bddPlayer) => {
    if (!bddPlayer?.cfPlayer) return false; // if no contract data, they're probably free
    const freeAgentYear = bddPlayer?.cfPlayer?.freeAgentYear;
    const yearsToFree = freeAgentYear - seasonToStartYear(getCurrentSeason());
    if (yearsToFree > 1) return true;
    return false;
  }

  const { searchComponent, selected, selectedOption } = useBDDSearchSelect({
    defaultTerm: defaultBDDPlayerSlug,
    query: GET_PLAYER_AND_CONTRACT_SEARCH_QUERY,
    resultToOptions: data => data?.search?.map(s => ({
      teamid: s.result.rinknetPlayer?.currentteamnhlid, 
      label: `${s.result.firstname} ${s.result.lastname}`,
      value: s.result.slug,
      subLabel: getSubLabel(s.result),
      bddPlayer: s.result
    })),
    placeholder: 'Search for player',
    isClearable: true,
  })

  const { data, loading } = useQuery(GET_PLAYER_CARD, {
    variables: { slug: defaultBDDPlayerSlug },
    skip: !defaultBDDPlayerSlug,
  });

  const defaultBDDPlayer = (
    <Border>
      <Container padding={2}>
        <LoadingPlaceholder loading={loading}>
          <Typography variant="body1">
            {data?.bddPlayer.firstname} {data?.bddPlayer.lastname}
          </Typography>
        </LoadingPlaceholder>
      </Container>
    </Border>
  );

  return <Column gap={2}>
    <Container width={300}>
      <Row gap={8} justifyContent='space-between'>
        <Typography variant='label'>Select a Player</Typography>
        {isNotFree(selectedOption?.bddPlayer) && <Typography variant='caption' color={theme.colors.states.danger}>Player is not a Free Agent</Typography>}
      </Row>
      {!!defaultBDDPlayerSlug ? defaultBDDPlayer : searchComponent}
    </Container>
    <Container>
      <Typography variant='label'>Create a signing</Typography>
      <Border>
        {!!selected || defaultBDDPlayerSlug  ? (
          <Container padding={2}>
            <CreateUpdateSigning 
              proObjectiveSlug={proObjectiveSlug}
              bddPlayerSlug={selected || defaultBDDPlayerSlug}
              showSimilar={showSimilar}
              onCompleted={onCompleted}
            /> 
          </Container>
        ) : (
          <Container padding={2}>
            <Typography variant='body2'>Select a player to create a signing.</Typography>
          </Container>
        )}
      </Border>
    </Container>
  </Column>
}