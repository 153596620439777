import { SLStatline } from 'components/SLStats';
import { getCurrentSeason } from 'helpers/hockeyutils';

export const ReportStats = ({ report, statsData }) => {
  if (report.__typename === 'BDDPlayerScoutingReport') return null;

  const slUid = report.rinknetPlayer.bddPlayerSlug?.slUid;
  const gameUid = report.rinknetGame?.bddGame?.slGameUid;

  if (!slUid || !gameUid) return null;

  const target = report.position == 'Goalie' ? 'goalies' : 'skaters';
  const row = statsData[target].data.find(
    (d) => d['sl_uid'] == slUid && d['game_uid'] == gameUid
  );

  const searchMetastatsOptions = {
    targetPosition: report.metastatsSplit.position,
    matchOn: 'slug',
  };

  if (!row) return null;

  return (
    <SLStatline
      data={statsData[target]}
      row={row}
      searchMetastatsOptions={searchMetastatsOptions}
    />
  );
};
