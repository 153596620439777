import { useQuery } from '@apollo/client';
import { GET_BF_TEAM_SEASON_SUMMARY } from 'apollo/queries/bruinsfriendly.queries';
import BDDPlotly from 'components/bdd/bddplotly';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { theme } from 'constants/theme';
import { formatDollars } from 'helpers/contracts';
import { getColorWithOpacity } from 'helpers/plotting';
import { LinkButton } from 'components/bdd/Button';
import { useState } from 'react';
import { HoverInfo } from 'components/bdd';
import { TeamCapBriefFields } from './TeamCapBrief';
import { CAP_TERM_DEFINITIONS } from 'components/SalaryCap/constants/definitions';

export const TeamActiveRosterBrief = ({ teamSlug }) => {
  const { data, placeholder, loading, error } = usePlaceholder(
    useQuery(GET_BF_TEAM_SEASON_SUMMARY, {
      variables: { slTeamSlug: teamSlug },
    })
  );

  const teamData = data?.bfTeamSeasonSummary;
  const bfTeam = teamData?.bfTeam;

  if (!teamData) return placeholder;
  return (
    <TeamActiveRosterBriefFields teamSlug={teamSlug} teamData={teamData} />
  )
}


export const TeamActiveRosterBriefFields = ({ teamSlug, teamData }) => {
  const [showMore, setShowMore] = useState(false);
  const capCeiling = teamData.upperLimit;
  return (
    <Container>
      <Column gap={1}>
        <Row columnGap={6} flexWrap alignItems="center" justifyContent="space-between">
          <Container>
            <Column gap={0}>
              <Row columnGap={1}>
                <HoverLabel label='System Cap Amount' info={CAP_TERM_DEFINITIONS.projectedCapHit}/>
                <Row gap={2}>
                  <Typography variant="subtitle2" color={teamData.projectedCapHit > capCeiling ? theme.colors.states.danger : 'black'}>
                    {formatDollars(teamData.projectedCapHit, { truncate: false })}
                  </Typography>
                  <Typography variant='body2'>
                    / {formatDollars(capCeiling, { truncate: false })}
                  </Typography>
                </Row>
              </Row>
              <Row columnGap={1}>
                <HoverLabel label='System Cap Room' info={CAP_TERM_DEFINITIONS.projectedCapSpace}/>
                <Typography variant="subtitle2">
                    {formatDollars(teamData.projectedCapSpace, { truncate: false })}
                </Typography>
              </Row>
              <Row columnGap={1}>
                <HoverLabel label='Projected LTI Used' info={CAP_TERM_DEFINITIONS.projectedLtirUsed}/>
                <Typography variant="subtitle2">
                  {!!teamData.isInLti ? formatDollars(teamData.projectedLtirUsed, { truncate: false }): '--'}
                </Typography>
              </Row>
            </Column>
          </Container>
          <Container>
            <Column gap={0}>
            <Row columnGap={1}>
                <HoverLabel label='Current Cap Hit' info={CAP_TERM_DEFINITIONS.currentCapHit}/>
                <Typography variant="subtitle2">
                  {formatDollars(teamData.currentCapHit, { truncate: false })}
                </Typography>
              </Row>
              <Row columnGap={1}>
                <HoverLabel label='Current Cap Space' info={CAP_TERM_DEFINITIONS.currentCapSpace}/>
                <Typography variant="subtitle2">
                  {formatDollars(teamData.currentCapSpace, { truncate: false })}
                </Typography>
              </Row>
              <Row columnGap={1}>
                <HoverLabel label='Roster Size'/>
                <Typography variant="subtitle2">{teamData.rosterSize} / 23</Typography>
              </Row>
            </Column>
          </Container>
          <Container>
            <PoolCharts teamData={teamData} />
          </Container>
          <LinkButton
            onClick={() => setShowMore(!showMore)}
            style={{ alignSelf: 'flex-end', color: '#777' }}
          >
            {showMore ? 'Less cap info...' : 'More cap info...'}
          </LinkButton>
        </Row>
        <Divider />
      </Column>
      {showMore && (
        <Column gap={1}>
          <Container paddingTop={2}>
            <TeamCapBriefFields
              teamData={teamData}
              showMoreButton={false}
            />
          </Container>
          <Divider />
        </Column>
      )}
    </Container>
  );
};


const PoolCharts = ({ teamData }) => {

  const salaryReliefLabel = `LTI Salary Pool: ${formatDollars(
    teamData.ltirSalaryRelief
  )}`;

  const bonusReliefLabel = `LTI Bonus Pool: ${formatDollars(
    teamData.ltirBonusRelief
  )}`;

  const plotData = [
    {
      name: 'Used',
      y: [bonusReliefLabel, salaryReliefLabel],
      x: [teamData.ltirBonusUsed, teamData.ltirSalaryUsed],
      text: [
        formatDollars(teamData.ltirBonusUsed),
        formatDollars(teamData.ltirSalaryUsed),
      ],
      textposition: 'inside',
      type: 'bar',
      orientation: 'h',
      marker: { color: theme.colors.states.danger },
      hoverinfo: 'skip',
    },
    {
      name: 'Remaining',
      y: [bonusReliefLabel, salaryReliefLabel],
      x: [Math.max(teamData.ltirBonusRemaining, 0), teamData.ltirSalaryRemaining],
      text: [
        formatDollars(teamData.ltirBonusRemaining),
        formatDollars(teamData.ltirSalaryRemaining),
      ],
      textposition: 'inside',
      type: 'bar',
      orientation: 'h',
      hovermode: 'closest',
      hoverinfo: 'skip',
      marker: { color: getColorWithOpacity(theme.colors.states.neutral, 0.2) },
    },
  ];

  const layout = {
    barmode: 'stack',
    xaxis: {
      rangemode: 'nonnegative',
      fixedrange: true,
    },
    yaxis: {
      ticktext: [bonusReliefLabel, salaryReliefLabel],
      tickmode: 'array',
      ticklen: 10,
      automargin: true,
      fixedrange: true,
    },
    width: 550,
    height: 60,
    margin: { l: 100, r: 20, t: 0, b: 0 },
  };

  return <BDDPlotly data={plotData} layout={layout} config={{ displayModeBar: false }} />;
};

export const HoverLabel = ({label, info}) => {
  return (
    <HoverInfo content={info}>
        <Typography
          variant="subtitle1"
          // style={
          //   !!info
          //     ? { textDecoration: 'underline', textDecorationStyle: 'dashed' }
          //     : {}
          // }
        >
          {label + ':'}
        </Typography>
    </HoverInfo>
  )
}