import { BDDTeamTradeAnalysis } from "components/ProScouting/BDDTrades/BDDTeamTradeAnalysis";
import BDDTradeAsset, { BDDTradeDraftPick, BDDTradedPlayer } from "components/ProScouting/BDDTrades/BDDTradeAsset";
import { LinkButton } from "components/bdd/Button";
import { Column, Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { theme } from "constants";
import { getNHLTeamLogoUrl } from "helpers/logos";
import styled from "styled-components";

const TopLeft = styled.div({
  position: 'absolute',
  top: 1,
  left: 3
})

export const BDDTrade = ({ 
  trade, 
  allowEdit,
  showAnalysis=false,
  poTeams,
  width='100%',
  topLeftDecoration
}) => {
  const teams = trade.teamSummaries;
  const tradedPlayers = trade.tradedPlayers;
  const tradedPicks = trade.tradedDraftPicks;
  return <Container width={width} height='100%'>
    {!!topLeftDecoration && (
      <TopLeft>
        <Typography variant='stat' color="#777">
          {topLeftDecoration}
        </Typography>
      </TopLeft>
    )}

    <Row gap={0} alignItems='stretch'>
      {teams.map((t, i) => (
        <Container 
          key={t.teamSlug} 
          flexBasis={`${100 / teams.length}%`}
          style={{
            borderRight: i < teams.length - 1 ? '1px solid #ddd' : '',
            overflow: 'auto',
          }}
          padding={2}
        > 
          <Column justifyContent='space-between' gap={4}>
            <Container>
              <Column>
                <Row gap={2} justifyContent='center'>
                  <img alt='' height={showAnalysis ? '30px' : '15px'} src={getNHLTeamLogoUrl(t.slTeam.nhlid)}/>
                  <Typography variant={showAnalysis ? 'h5' : 'body1'}>{t.slTeam.longname}</Typography>
                </Row>

                {showAnalysis && (
                  <BDDTeamTradeAnalysis 
                    teamSummary={t} 
                    teamCapData={poTeams?.find(pt => pt.teamSlug == t.teamSlug)?.bfTeamSeasonSummary}
                  />
                )}

                <Container backgroundColor={theme.colors.light.secondary} padding={2}>
                  <Column gap={2}>
                    {tradedPlayers.filter(p => p.toTeamSlug === t.teamSlug).map(p => (
                      <Container key={p.playerSlug} backgroundColor={theme.colors.light.background} padding={1}>
                        <BDDTradedPlayer 
                          bddPlayer={p.bddPlayer} 
                          retainedSalary={p.retainedSalary}
                          retainedSalaryPct={p.retainedSalaryPct}
                          allowRetention={true} 
                          allowEdit={false} 
                          analysis={showAnalysis}
                        />
                      </Container>
                    ))}
                    {tradedPicks.filter(p => p.toTeamSlug === t.teamSlug).map(p => (
                      <Container key={p.pickId} backgroundColor={theme.colors.light.background} padding={1}>
                        <BDDTradeDraftPick 
                          bfDraftPick={p.bfDraftPick} 
                          analysis={showAnalysis}
                        />
                      </Container>
                    ))}
                  </Column>
                </Container>
              </Column>
            </Container>

          </Column>
        </Container>
      ))}
      {allowEdit && <Container>
        <LinkButton>
          Edit details
        </LinkButton>
      </Container>}
    </Row>
  </Container>
}