import { usePlayerSLGames } from './usePlayerSLGames';
import { season8to4 } from 'helpers/hockeyutils';
import { strContains } from 'helpers/data';
import { useState } from 'react';
import { useEffect } from 'react';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';

export default function useLSGTOptions({
  slug,
  variant,
  leagueSlug,
  selectProps,
  initialValue,
  combineTeamSlugs = false,
}) {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  if (!slug) {
    console.error('Must supply player bdd slug to useLSGTOptions hook');
    return {
      lsgtOptions: [],
    };
  }

  const dataToValue = (d) =>
    `${d.league_slug},${d.season},${d.game_type},${d.team_slug},${d.player_id}`;
  const gameTypeToLabel = (d) =>
    strContains(d.game_type, 'postseason') ? 'PLAYOFFS ' : '';

  const { stats, loading } = usePlayerSLGames({ slug });

  let lsgtOptions =
    !!stats?.data && stats.data.length > 0
      ? [...stats?.data]
          .filter((d) => !strContains(d.game_type, 'preseason'))
          .sort((a, b) =>
            a.season === b.season ? b.gamesPlayed - a.gamesPlayed : b.season - a.season
          )
          .map((d) => ({
            label: `${season8to4(d.season, true)} ${d.league_slug} ${gameTypeToLabel(
              d
            )} ${d.team} (${d.gamesPlayed} GP)`,
            value: dataToValue(d),
            data: d,
          }))
      : [];

  if (combineTeamSlugs) {
    lsgtOptions = lsgtOptions.reduce((options, o) => {
      const lsgIndex = `${o.data.league_slug},${o.data.season},${o.data.game_type}`;

      if (options.find((o) => o.value == lsgIndex)) {
        return options;
      }

      const gamesPlayed = lsgtOptions
        .filter((o) => o.value.startsWith(lsgIndex))
        .reduce((sum, lsgt) => lsgt.data.gamesPlayed + sum, 0);

      return [
        ...options,
        {
          label: `${season8to4(o.data.season, true)} ${
            o.data.league_slug
          } ${gameTypeToLabel(o.data)} (${gamesPlayed} GP)`,
          value: `${o.data.league_slug},${o.data.season},${o.data.game_type}`,
          data: o.data,
        },
      ];
    }, []);
  }

  if (leagueSlug) {
    lsgtOptions = lsgtOptions.filter((o) => o.data.league_slug == leagueSlug);
  }

  // On initial load, setSelectedValue to the first option
  useEffect(() => {
    if (!!initialValue) return;

    if (!!stats && !!lsgtOptions) {
      setSelectedValue(lsgtOptions?.[0]?.value);
    }
  }, [stats, leagueSlug]);
  // Also, to avoid a double clutch ("loading" becomes true and then another render happens when selectedValue is set)
  // we return a selectedValue that changes as soon as "loading" does
  const _selectedValue =
    !!stats && !!lsgtOptions && !selectedValue ? lsgtOptions?.[0]?.value : selectedValue;

  const selectLSGT = (
    <StyledBDDSelect
      name="lsgt"
      selectedValue={_selectedValue}
      onChange={(n, v) => setSelectedValue(v)}
      options={lsgtOptions}
      selectProps={{
        placeholder: 'Select league, season, team',
        ...selectProps,
      }}
      variant={variant}
    />
  );

  const selectedOption = lsgtOptions.find((o) => o.value === _selectedValue);

  return {
    loading,
    selectedValue: _selectedValue,
    selectedOption,
    ...selectedOption?.data,
    selectLSGT,

    // snake to camel... this is ugly but whatever
    leagueSlug: selectedOption?.data?.league_slug,
    gameType: selectedOption?.data?.game_type,
    teamSlug: selectedOption?.data?.team_slug,
    playerId: selectedOption?.data?.player_id,
  };
}
