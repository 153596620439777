import { Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { useContext, useEffect } from 'react';
import { BFTradeBreakdown } from '../Trades/BFTradeBreakdown';
import { TradeContext } from '../Trades/Trades';
import { Button } from 'react-bootstrap';
import { Typography } from 'components/bdd/Typography';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { UpdateBFTrade } from 'components/ProScouting/BFTrades/UpdateBFTrade';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import { GET_BF_TRADE } from 'apollo/queries/bruinsfriendly.queries';
import { formatDate } from 'helpers/helpers';
import { useUser } from 'helpers/user';
import { permissions } from 'constants';

export default function useBFTradeModal({ useSearchParams = false }) {
  const { setStoreValue } = useContext(TradeContext);
  const { renderModal, hideModal, modalContext } = useContext(ModalContext);


  const handleRenderModal = ({ tradeId, bfTrade }) => {
    if (useSearchParams) {
      setStoreValue('trade', !!tradeId ? tradeId : bfTrade.id);
    }

    renderModal({
      size: 'xl',
      title: (
        <Typography variant='h4' textAlign='center'>
          {!bfTrade ? 'Trade Details' : `${bfTrade.teamSummaries.map(t => t.bfTeam.abbreviation).join(' <> ')} trade on ${formatDate(bfTrade.date)}`}
        </Typography>
      ),
      body: (
        <BFTradeModalBody tradeId={tradeId} bfTrade={bfTrade} />
      ),
      // footer: (
      //   <Row justifyContent='end'>
      //     <Button variant='secondary' onClick={hideModal}>
      //       Close
      //     </Button>
      //   </Row>
      // ),
      context: useSearchParams
        ? {
            trade: !!tradeId ? tradeId : bfTrade.id,
          }
        : null,
    });
  };

  useEffect(() => {
    if (modalContext?.trade) {
      handleRenderModal({ tradeId: modalContext?.trade });
    }
  }, [modalContext?.trade]);

  return {
    hideModal,
    handleRenderModal,
  };
}

const BFTradeModalBody = ({ tradeId, bfTrade: bfTradeArg }) => {
  const { userHasPermission } = useUser();
  const { buttonGroup, selectedValue, forceSelectOption } = useButtonGroup({
    initialSelectedValue: 'trade-breakdown',
    options: [
      { label: 'Trade Breakdown', value: 'trade-breakdown' },
      ...(userHasPermission(permissions.manageSalaryCap) 
        ? [{ label: 'Edit', value: 'edit' }]
        : []),
    ],
    variant: 'link'
  });

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_TRADE, {
      variables: { id: tradeId },
      skip: !!bfTradeArg,
    })
  );

  if (!tradeId && !bfTradeArg) {
    throw Error('Must supply either tradeId or bfTrade');
  }

  const bfTrade = !!data ? data?.bfTrade : bfTradeArg;

  return (
    <Container padding={2}>
      <Column gap={2}>
        {buttonGroup}

        <Container overflow='auto' padding={2} paddingBottom={8}>
          {selectedValue === 'trade-breakdown'
            ? <BFTradeBreakdown tradeId={tradeId} bfTrade={bfTrade} />
            : <UpdateBFTrade bfTrade={bfTrade} tradeId={tradeId} onSubmit={() => forceSelectOption('trade-breakdown')} />
          }
        </Container>
        
      </Column>
    </Container>
  )
}