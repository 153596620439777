import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Field } from './Field';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import React from 'react';

export const ButtonGroupFilter = React.memo(
  ({
    id,
    label,
    cond = '=',
    variant = 'body2',
    width,
    options,
    onChange,
    meta,
    hide,
  }) => {
    const filter = useFilterContext((state) => state.filters[id]);
    const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

    useRegisterFilterTag(
      id,
      filter && (
        <FilterTag
          name={label}
          label={options.find((o) => o.value == filter.value)?.label}
        />
      ),
      filter
    );

    return (
      !hide && (
        <Field width={width} label={label}>
          <ButtonGroup
            options={options}
            selectedValue={filter?.value}
            width={width}
            noWrap
            variant={variant}
            onClick={(option) => {
              setFilters(id, { id, type: 'select', cond, value: option.value, meta });
              onChange && onChange(option.value, { setFilters, removeFilter });
            }}
          />
        </Field>
      )
    );
  }
);
