import { StyledBDDSelect } from '../Select/StyledBDDSelect';

export const FilterOptionSelect = ({ filter, options, onChange }) => {
  if (!options) return;

  const selectOptions = options.map((o) => ({
    value: o.col,
    label: o.label,
  }));

  const selectedOption = selectOptions.find((o) => o.value == filter.col);

  return (
    <StyledBDDSelect
      size="sm"
      options={selectOptions}
      selectedValue={selectedOption.value}
      selectProps={{
        isSearchable: true,
      }}
      onChange={(n, v) => {
        onChange(v);
      }}
    />
  );
};
