import { Column, Container, Row } from 'components/bdd/Layout';
import { mapSort } from './helpers';
import { Button, Dropdown } from 'react-bootstrap';
import { Typography } from 'components/bdd/Typography';
import { useContractSearchContext } from './context';
import { IconButton, LoadingPlaceholder } from 'components/bdd/Button';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { infoColumns, buildSLStatColumns, buildAnalysisColumns } from './columns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ExportDataButton, StyledTable, buildColumns } from 'components/bdd/Table';
import { ColumnDisplayResults } from './ColumnDisplayResults';
import { Checkbox } from 'components/bdd/Checkbox';
import { ContractActionToolbar } from './ContractActionToolbar';
import { LinkPopover } from 'components/bdd/Popover';
import { BarChart, LayoutThreeColumns } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { TooltipSpan } from 'components/reports';
import { ModalContext } from 'components/bdd/Modal';
import useSimilarContractAgg from '../PlayerSearch/hooks/useSimilarContractAgg';
import { useTimeRangeColumn } from './useTimeRangeColumn';
import { ContractSearchSelect } from './ContractSearchSelect';
import { MultiPlayerSearch } from './MultiPlayerSearch';

export const ContractSearchTable = ({
  disableSort,
  sort: sortArg,
  extraColumns = [],
  showAnalysisByDefault,
  applyColumnAdjustment,
  showFindPlayers,
  controls,
}) => {
  const selectedContracts = useContractSearchContext((state) => state.selectedContracts);
  const filters = useContractSearchContext((state) => state.filters);
  const sort = sortArg || useContractSearchContext((state) => state.sort);
  const contractList = useContractSearchContext((state) => state.contractList);
  const { statDataToUse, tableData, pageControls, placeholder } =
    useContractSearchContext((state) => state.data) || {};

  const columnIdsToDisplay = useContractSearchContext(
    (state) => state.columnIdsToDisplay
  );

  const { applyFilters, setSelectedContracts, setSort } = useContractSearchContext(
    (state) => state.actions
  );

  useEffect(() => {
    if (!sort) setSort([{ id: 'cap_hit', desc: true }]);
    applyFilters();
  }, []);

  const selectColumn = useMemo(() => {
    return buildColumns([
      {
        id: 'select',
        minWidth: 20,
        justify: 'start',
        disableSort: true,
        header: (
          <TooltipSpan
            content={<Typography variant="body2">Select all contracts</Typography>}
          >
            <Checkbox
              checked={
                selectedContracts.length > 0 &&
                selectedContracts.length == tableData.length
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedContracts(tableData);
                } else {
                  setSelectedContracts([]);
                }
              }}
              styleProps={{
                height: 13,
                paddingLeft: 1,
                minWidth: '100%',
              }}
            />
          </TooltipSpan>
        ),
        renderCell: (d) => (
          <Container height={13}>
            <Checkbox
              checked={!!selectedContracts.find((sc) => sc.id == d.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedContracts([...selectedContracts, d]);
                } else {
                  setSelectedContracts(selectedContracts.filter((sc) => sc.id != d.id));
                }
              }}
              styleProps={{
                minWidth: '100%',
              }}
            />
          </Container>
        ),
      },
    ])[0];
  }, [selectedContracts, tableData]);

  const timeRangeColumn = useTimeRangeColumn({ contractList });
  const tableColumns = useMemo(() => {
    const tableColumns = buildColumns(
      Object.values(infoColumns),
      { filters },
      { wrapHeader: true, minWidth: 50 }
    );
    const statColumns = buildSLStatColumns(statDataToUse);

    return tableColumns
      .concat(statColumns)
      .filter((c) => columnIdsToDisplay.includes(c.id))
      .sort(
        (a, b) => columnIdsToDisplay.indexOf(a.id) - columnIdsToDisplay.indexOf(b.id)
      );
  }, [statDataToUse, columnIdsToDisplay]);

  const columns = useMemo(() => {
    let columns = [selectColumn];
    if (timeRangeColumn) columns.push(timeRangeColumn);

    columns = [...columns, ...extraColumns, ...tableColumns];

    if (applyColumnAdjustment) {
      columns = columns.map(applyColumnAdjustment);
    }

    return columns;
  }, [selectColumn, timeRangeColumn, tableColumns]);

  const table = useMemo(() => {
    if (!tableData) return;

    return (
      <BDDSortableTable
        columnJustify="center"
        columns={columns}
        scroll={false}
        data={tableData}
        defaultSort={sort}
        manualSortBy={!disableSort}
        disableSortRemove
        onSortChange={(sort) => {
          setSort(mapSort(sort));
          applyFilters();
        }}
        topHorizontalScroll
      />
    );
  }, [tableData, columns, sort]);

  return (
    <Column gap={2}>
      <Container>
        <ContractControls
          tableColumns={tableColumns}
          tableData={tableData}
          contractList={contractList}
          showAnalysisByDefault={showAnalysisByDefault}
          showFindPlayers={showFindPlayers}
        >
          {pageControls}
        </ContractControls>
      </Container>
      <Container height="100%" overflow={'auto'}>
        <StyledTable
          variant="sticky"
          rowHeight={24}
          stickyChild={2}
          withBorders
          withHighlight
        >
          {table}
          {placeholder}
        </StyledTable>
      </Container>
    </Column>
  );
};

const UpdateResultButton = ({ loading }) => {
  const { applyFilters } = useContractSearchContext((state) => state.actions);
  const areFiltersApplied = useContractSearchContext((state) => state.filtersApplied);

  useEffect(() => {
    applyFilters();
  }, []);

  return (
    <Button
      variant={areFiltersApplied ? 'outline-primary' : 'primary'}
      size="sm"
      disabled={areFiltersApplied}
      onClick={() => applyFilters()}
    >
      <LoadingPlaceholder loading={loading}>
        <Typography variant="body1">Update Result</Typography>
      </LoadingPlaceholder>
    </Button>
  );
};

const ColumnDisplayButton = ({}) => {
  return (
    <LinkPopover
      hideTransition
      placement="right"
      renderContent={() => (
        <Container maxWidth={300} onClick={(e) => e.stopPropagation()}>
          <ColumnDisplayResults />
        </Container>
      )}
      renderLink={(setShow, target, show) => (
        <Container paddingLeft={1} ref={target}>
          <TooltipSpan
            content={<Typography variant="body2">Show/Hide columns</Typography>}
          >
            <Button variant="outline-dark" size="sm" onClick={() => setShow(!show)}>
              <Icon padding={2} icon={<LayoutThreeColumns />} />
            </Button>
          </TooltipSpan>
        </Container>
      )}
    />
  );
};

const ContractControls = ({
  tableData,
  tableColumns,
  contractList,
  showAnalysisByDefault,
  showFindPlayers,
  children,
}) => {
  const [inlineContent, setInlineContent] = useState();

  return (
    <Container>
      <Row columnGap={1} rowGap={1} flexWrap>
        {/* <UpdateResultButton loading={loading} /> */}
        <ContractActionToolbar contractList={contractList} />
        <ColumnDisplayButton />
        <ContractAnalysisButton
          contracts={tableData}
          onContentShow={(content) => setInlineContent(content)}
          defaultShow={showAnalysisByDefault}
        />
        <ExportDataButton data={tableData} columns={tableColumns} />
        {showFindPlayers && <MultiPlayerSearch />}
        {children}
      </Row>
      {inlineContent}
    </Container>
  );
};

const ContractAnalysisButton = ({ contracts, defaultShow, onContentShow }) => {
  const [contentShown, showContent] = useState(defaultShow);

  useEffect(() => {
    if (!onContentShow) return;

    onContentShow(
      contentShown ? (
        <Container padding={2}>
          {!!contracts && contracts.length > 0 && (
            <ContractsAggModal contracts={contracts} />
          )}
        </Container>
      ) : null
    );
  }, [contentShown, contracts]);

  return (
    <TooltipSpan
      content={
        <Typography variant="body2">
          {!contentShown ? 'See Analysis' : 'Hide Analysis'}
        </Typography>
      }
    >
      <Button
        variant={contentShown ? 'dark' : 'outline-dark'}
        size="sm"
        disabled={!contracts}
        onClick={() => {
          showContent(!contentShown);
        }}
      >
        <Icon padding={2} icon={<BarChart />} />
      </Button>
    </TooltipSpan>
  );
};

const ContractsAggModal = ({ contracts }) => {
  const selectedContracts = useContractSearchContext((state) => state.selectedContracts);
  const selectedContractIds = selectedContracts.map((c) => c.id);

  // const data = useContractSearchContext((state) => state.data);
  // const contracts = data.tableData;

  const { setSelectedContracts } = useContractSearchContext((state) => state.actions);
  const [initialMount, setInitialMount] = useState(true);

  const {
    component,
    setSelectedContractIds,
    selectedContractIds: aggSelectedContractIds,
  } = useSimilarContractAgg({
    contracts,
  });

  useEffect(() => {
    if (!initialMount) {
      setSelectedContractIds(selectedContractIds);
    }

    setInitialMount(false);
  }, [JSON.stringify(selectedContractIds)]);

  useEffect(() => {
    const newSelectedContracts = contracts.filter((sc) =>
      aggSelectedContractIds.includes(sc.id)
    );

    setSelectedContracts(newSelectedContracts);
    // setSelectedContractIds(newSelectedContracts);
  }, [JSON.stringify(aggSelectedContractIds)]);

  return component;
};
