import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Container } from 'components/bdd/Layout';
import ByScoutViz from './ByScoutViz';
import ReportsByScout from './ReportsByScout';
import PlayerReportViewer from '../../Scouting/PlayerReports/playerreportviewer';
import { BarChartSteps, FilterLeft, Grid } from 'react-bootstrap-icons';
import { ScoutReportSummary } from 'components/Lists/Builder/components/ScoutReportSummary';

export default function useReportOptions({
  rinknetId,
  rinknetPlayer,
  defaultSelectedTab,
}) {
  const { buttonGroup, selectedValue: selectedReportMode } = useButtonGroup({
    options: [
      { label: 'All Reports', value: 'all' },
      {
        label: (
          <>
            By Scout <BarChartSteps />
          </>
        ),
        value: 'byscoutviz',
      },
      {
        label: (
          <>
            By Scout <Grid />
          </>
        ),
        value: 'byscoutgrid',
      },
      {
        label: (
          <>
            By Scout Summary <FilterLeft />
          </>
        ),
        value: 'byscoutsummary',
      },
    ],
    initialSelectedValue: defaultSelectedTab || 'all',
  });

  const content = (
    <Container>
      {selectedReportMode === 'all' ? (
        <PlayerReportViewer
          rinknetId={rinknetId}
          rinknetPlayer={rinknetPlayer}
          showGradeFilters={true}
        />
      ) : selectedReportMode === 'byscoutgrid' ? (
        <ReportsByScout rinknetId={rinknetId} />
      ) : selectedReportMode === 'byscoutviz' ? (
        <ByScoutViz rinknetId={rinknetId} />
      ) : selectedReportMode === 'byscoutsummary' ? (
        <ScoutReportSummary rinknetId={rinknetId} showTable />
      ) : null}
    </Container>
  );

  return {
    component: content,
    buttonGroup,
  };
}
