import { useQuery } from '@apollo/client';
import { GET_BF_SIMILAR_CONTRACTS } from 'apollo/queries/bruinsfriendly.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';

export const useSimilarContracts = ({
  targetVector,
  filters,
  timeRange,
  statFilters,
  nNeighbours,
  statMode = 'combined',
  skip,
  weights,
}) => {
  return usePlaceholder(
    useQuery(GET_BF_SIMILAR_CONTRACTS, {
      skip,
      variables: {
        targetVector,
        filters,
        timeRange,
        statFilters,
        ...weights,
        nNeighbours: nNeighbours || 10,
        statMode,
      },
    })
  );
};
