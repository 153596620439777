import React, { useRef, useState } from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'

import { NULL_FILTER_VALUE } from "../../../helpers/filters";
import styled from 'styled-components'
import Droppable from '../../bdd/droppable'
import { itemTypes } from '../../../constants';
import PickAStat, { StatStyles } from './pickastat';
import { Col, Row } from 'react-bootstrap';
import { LinkButton } from 'components/bdd/Button';
import { HoverInteractive } from 'components/bdd';
import StatReportSelector from './StatReportSelector';
import { mapStatsReportsToStatSelect } from './helpers';

const Styles = styled.div`
    input {
        width: 100%;
        padding: 6px 12px;
        border-radius: 5px;
        border: 1px solid #ddd;
    }
`

const ScrollButton = styled(LinkButton)({
  fontStyle: 'italic'
})

export const statToKey = stat => {
    // give a stat dict ({ slug, label, filterSetId, norm }) return unique key
    return `${stat.slug}-${stat.filterSetId}-${stat.norm}`
}


const StatsSelect = React.memo(({
    stats, // a stateful var from parent, structured as [{ slug, label, filterSetId, norm }]
    setStats, // setter func
    horizontal=false,
    hideFilterSet=false,
    hideNorm=false,
    autoScrollPrompt=false, // if true will show a text button to autoscroll to "add stats"
    defaultTarget, // used when copying stats from existing report
}) => {
    const [focused, setFocused] = useState(undefined)
    const addStatRef = useRef(null)
    if (!stats || !setStats) {
        throw Error('"stats" and "setStats" are required props')
    }

    const handleAddStat = () => {
        // if any stat doesn't have a slug, we can't add a new one yet
        if (stats.filter(s => !s.slug).length > 0) return
        setFocused(stats.length)
        setStats(stats.concat({ slug: undefined, label: undefined, filterSetId: null, norm: null}))
    }
    const handleRemoveStat = statIdx => {
        const newStats = update(stats, {
            $splice: [[statIdx, 1]]
        })
        setFocused(undefined)
        setStats(newStats)
    }
    const handleStatChange = (statIdx, newValues) => {
        const newStats = update(stats, {
            [statIdx]: { $merge: newValues }
        })
        setStats(newStats)
    }

    const handleReorderStats = (statKey, stat, newIdx) => {
        const oldIdx = stats.findIndex(s => statToKey(s) == statKey)
        const newStats = update(stats, {
            $splice: [[oldIdx, 1], [newIdx, 0, stat]]
        })
        setStats(newStats)
    }

    const handleFocus = i => {
        // When a stat is clicked, it gets focus
        // We also remove any "slugless" stats that are no longer focused
        // so a stat can stay as long as it has a slug OR is the incoming focus
        const newStats = stats.filter((s, idx) => idx === i || !!s.slug)
        // only call "setStats" if theres been a change
        if (newStats.length != stats.length) {
            setStats(newStats)
        }
        setFocused(i)
    }

    const handleUnfocus = () => {
        setFocused(undefined)
    }

    const handleScrollToAdd = () => {
      addStatRef.current.scrollIntoView()
      handleAddStat()
    }

    const handleCopyStats = (reportSlug, report) => {
      setStats(mapStatsReportsToStatSelect(report.stats))
    }

    return <Styles>
        <Row>
          <Col>
            <ScrollButton onClick={handleScrollToAdd}>
              Add stat
            </ScrollButton>
          </Col>
          <Col>
            <ScrollButton>
              <HoverInteractive
                content={<div style={{width: '500px'}}>
                  <StatReportSelector 
                    defaultTarget={defaultTarget}
                    allowEdit={false}
                    selectButtonText='Copy'
                    handleSelect={handleCopyStats}
                  />
                </div>}
              >
                Copy from report
              </HoverInteractive>
            </ScrollButton>
          </Col>
        </Row>
        <Row className={!!horizontal && 'flex-nowrap'} noGutters>
            {stats.map((stat, i) => <Col 
                key={!!stat.slug ? statToKey(stat) : 'new-stat'}
                xs={horizontal ? 'auto' : 12} 
            >
                <Droppable
                    acceptItemTypes={[itemTypes.STAT_REPORT_STAT]}
                    onDrop={({ data }) => {
                        handleReorderStats(data.statKey, data.stat, i)
                    }}
                >
                    <PickAStat
                        stat={stat}
                        setStat={newValues => handleStatChange(i, newValues)}
                        handleFocus={() => handleFocus(i)}
                        handleUnfocus={e => handleUnfocus()}
                        handleRemove={() => handleRemoveStat(i)}
                        focused={focused === i}
                        hideFilterSet={hideFilterSet}
                        hideNorm={hideNorm}
                    />
                </Droppable>
            </Col>)}
            {focused == undefined && <Col xs={horizontal ? 'auto' : 12}>
                <StatStyles>
                    <div className='add-stat-prompt' 
                        ref={addStatRef}
                        onClick={handleAddStat}
                    >
                        <FontAwesomeIcon icon={faPlusCircle}/> Add stat
                    </div>
                </StatStyles>
            </Col>}
        </Row>
    </Styles>
})
export default StatsSelect

