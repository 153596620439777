import { useContext } from 'react';
import { Column, Container, Row } from 'components/bdd/Layout';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import {
  PostgameHeader,
  SLStatContext,
  SLStatTable,
  StatCellVideoLinks,
  StatScatterChart,
} from 'components/SLStats';
import {
  FilterButton,
  GraphicalViewButton,
  PlayerLeagueFilter,
  StatReportFilter,
  StrengthFilter,
} from 'components/SLStats/filters';

import { season8to4 } from 'helpers/hockeyutils';
import { gameHighlightCallback, searchMetastatsSplitsForStat } from 'helpers/stats';
import { sortDate, stddevColor } from 'helpers/tables';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { formatDate } from 'helpers/helpers';
import { buildPlayerRouteV2, buildPostgameRoute } from 'helpers/routing';
import { Link } from 'react-router-dom';
import { Per20Toggle } from 'components/SLStats/filters/Per20Toggle';
import { ByGameToggle } from './components/ByGameToggle';
import { DateRangeModeFilter } from './components/DateRangeModeFilter';
import { useGameDeploymentModal } from 'components/PlayersV2/Deployment/useGameDeploymentModal';
import { TooltipSpan } from 'components/reports';
import { GameShiftVideoButton } from 'components/PlayersV2/ByGame/GameShiftVideoButton';
import { GameShiftVideoProvider } from 'components/PlayersV2/ByGame/GameShiftVideoProvider';
import { EventVideoController } from 'components/bdd/Video';
import { LinkButton } from 'components/bdd/Button';

const FILTERS_STORAGE_KEY = 'dashboard-targetedplayersstatfilters';

export default function TargetedPlayersTable({ slugs, byGameIndex, nonByGameIndex }) {
  const context = useContext(SLStatContext);

  const selectedIndex = context.filters['index'];
  const selectedIndexLabel = selectedIndex === byGameIndex ? 'Game' : 'Season';

  const { modal: deploymentModal, renderDeploymentButton } = useGameDeploymentModal({});

  let indexColumns = [
    {
      Header: 'Player',
      id: 'Player',
      accessor: (d) => (
        <Container>
          <Row>
            <PlayerHover playerSlug={d.slug}>
              <Link to={buildPlayerRouteV2(d.slug)}>
                <Typography variant="body2" style={{ color: 'black' }}>
                  {d.Player}
                </Typography>
              </Link>
            </PlayerHover>
          </Row>
        </Container>
      ),
      sortType: (a, b, colId) => (a.original[colId] > b.original[colId] ? 1 : -1),
    },
    ...(selectedIndexLabel === 'Game'
      ? [
          {
            Header: selectedIndexLabel,
            id: 'index',
            sortType: (a, b, colId) => sortDate(a, b, colId),
            accessor: (d) => (
              <Container minWidth={80}>
                <Typography variant="body1">
                  <PostgameHeader data={d} />
                </Typography>
              </Container>
            ),
          },
          {
            Header: '',
            id: 'game-extras',
            accessor: (d) => (
              <Row
                style={{ minWidth: '80px' }}
                alignItems="center"
                justifyContent="space-around"
              >
                <GameShiftVideoProvider>
                  <GameShiftVideoButton
                    gameUid={d.game_uid}
                    playerId={d.player_id}
                    leagueSlug={d.league_slug}
                    season={d.season}
                    gameType={d.game_type}
                  />
                </GameShiftVideoProvider>
                <Container>
                  {renderDeploymentButton({
                    slug: d.slug,
                    leagueSlug: d.league_slug,
                    seasons: [d.season],
                    gameType: d.game_type,
                    gameUid: d.game_uid,
                    playerName: d.Player,
                  })}
                </Container>
              </Row>
            ),
          },
        ]
      : [
          {
            Header: selectedIndexLabel,
            id: 'index',
            accessor: (d) =>
              d.season ? (
                <Container minWidth={150}>
                  {season8to4(d.season, true)} <b>{d.league_slug}</b> {d.team_shorthand}{' '}
                  {d.game_type === 'nhl_postseason' ? <small>PLAYOFFS</small> : ''}
                </Container>
              ) : (
                <Container minWidth={50}>{d.league_slug}</Container>
              ),
          },
        ]),
  ];

  // Toggle to control if we compare to "all" or specific position
  const { toggled: compareToPosition, toggleComponent: positionToggle } = useToggle({
    id: 'toggle-compare-position',
    label: `Compare to position`,
  });

  // toggle to control if we compare to players on team or to league
  const { toggled: compareToTeam, toggleComponent: targetGroupToggle } = useToggle({
    id: 'toggle-compare-team',
    label: 'Compare to team',
    initialToggled: false,
    disabled:
      !context.stats || (context.stats?.length && !('team_slug' in context.stats[0])),
  });

  const highlightCallback = (label, row, statDef) => {
    const value = row[label];
    if (statDef.slug === 'gamesPlayed') return null;
    if (isNaN(value) || value === null) return null;
    const [_, metastats] = findMetastats(statDef, row);
    if (!metastats) return null;
    if (context.filters.norm != 20) {
      const toiStatDef = context.data.format.stat_definitions.find(
        (s) => s.slug === 'toi'
      );
      return gameHighlightCallback(metastats, label, row, statDef, toiStatDef);
    }
    return stddevColor(value, metastats, statDef.negative);
  };

  const cellHoverCallback = (label, row, statDef, forceClose) => {
    return (
      <StatCellVideoLinks
        statDefinition={statDef}
        statRow={row}
        onClick={() => {
          forceClose(true);
        }}
      />
    );
  };

  const findMetastats = (statDef, row) => {
    // if team_slug not in row, must compare to league
    const targetGroup = compareToTeam && 'team_slug' in row ? row.team_slug : 'league';

    const rowPos = compareToPosition && row.position ? row.position.toLowerCase() : 'all';
    return searchMetastatsSplitsForStat(context.data?.extras?.metastats, statDef, row, {
      targetGroup,
      targetPosition: rowPos,
      matchOn: context.filters.norm === '20' ? 'unique_id' : 'slug', // metastats are always applied at the per 20 level
    });
  };

  const statSlugs =
    selectedIndexLabel === 'Game'
      ? context?.data?.format?.stat_definitions
          ?.filter((s) => s.slug != 'toiPerGP')
          .map((s) => s.slug)
      : null;

  return (
    <Container>
      {deploymentModal}
      <Column gap={2}>
        <Row columnGap={2}>
          <GraphicalViewButton />
          <FilterButton>
            <Container width={300}>
              <Column gap={2}>
                <Container>
                  <Row columnGap={3}>
                    <Per20Toggle />
                    <ByGameToggle
                      byGameIndex={byGameIndex}
                      nonByGameIndex={nonByGameIndex}
                    />
                    <Container style={{ marginLeft: 'auto' }}>
                      <Row justifyContent="end">
                        <Container>
                          <LinkButton
                            onClick={() => {
                              localStorage.setItem(FILTERS_STORAGE_KEY, null);
                              window.location.reload();
                            }}
                          >
                            Reset filters
                          </LinkButton>
                        </Container>
                      </Row>
                    </Container>
                  </Row>
                </Container>
                <Row columnGap={3} rowGap={2} flexWrap>
                  <PlayerLeagueFilter slugs={slugs} selectProps={{ isMulti: true }} />
                  <StrengthFilter />
                  <DateRangeModeFilter includeCustom />
                </Row>
              </Column>
            </Container>
          </FilterButton>
        </Row>
        <StatReportFilter storageKeyPrefix="dashboardTargetedPlayers" />
        {context?.data && context.data?.data?.length ? (
          <EventVideoController>
            <SLStatTable
              data={context.data}
              indexColumns={indexColumns}
              // highlight={false}
              cellHoverCallback={cellHoverCallback}
              highlightCallback={highlightCallback}
              toggleControls={[positionToggle, targetGroupToggle]}
              scroll={true}
              maxHeight="50vh"
              defaultSort={[{ id: 'index', desc: true }]}
              defaultSortDesc={true}
              statSlugs={statSlugs}
            />
          </EventVideoController>
        ) : (
          context.placeholder
        )}
      </Column>
    </Container>
  );
}
