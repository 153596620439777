import { BRUINS_ASSETS_URL } from '../constants';

export function getNHLTeamLogoUrl(teamid) {
  if (!teamid) return null;

  return `${BRUINS_ASSETS_URL}/nhl_team_logos/${teamid}.svg`;
}

export function getNHLTeamLogoUrlPng(teamid) {
  if (!teamid) return null;

  return `${BRUINS_ASSETS_URL}/nhl_team_logos/${teamid}.png`;
}

export const NHLShieldUrl = `${BRUINS_ASSETS_URL}/nhl_logos/nhl_shield.svg`;
