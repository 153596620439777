import { compareTwoStrings, findBestMatch } from './stringSimilarity';

export const getStringDiff = (a, b) => {
  if (a === b) return 0;
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;
  var ret = Math.abs(a.length - b.length);
  for (var i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      ret++;
    }
  }
  return ret / Math.max(a.length, b.length);
};

export const getStringSimilarity = (a, b) => {
  return 1 - getStringDiff(a, b);
};

export const strCompare = (a, b, caseInsensitive = true) => {
  if (caseInsensitive) {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }
  var similarity = compareTwoStrings(a, b);
  return similarity;
};

export const strFindBestMatch = (search, options, caseInsensitive = true) => {
  if (caseInsensitive) {
    search = search.toLowerCase();
    options = options.map((o) => o.toLowerCase());
  }
  const ret = findBestMatch(search, options);
  return ret;
};

export const filterObjectsByStringMatch = (
  search,
  objects,
  keys,
  minRating = 0.1,
  caseInsensitive = true
) => {
  if (!objects || objects.length == 0) return [];

  const options = objects.flatMap((o) => keys.map((k) => o[k])).filter((v) => !!v);
  const matches = strFindBestMatch(search, options, caseInsensitive)
    .ratings.filter((r) => r.rating >= minRating)
    .map((m) => m.target);

  return objects.filter((o) =>
    keys.some(
      (k) => !!o[k] && matches.includes(caseInsensitive ? o[k].toLowerCase() : o[k])
    )
  );
};

export const snakeToCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const capitalize = (str, eachWord=false) => {
  if (eachWord) {
    return str.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(' ');
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export const truncateString = (str, length) => {
  if (!str) return null;
  if (!length) return str;
  if (str.length > length) {
    return str.slice(0, length) + '...';
  }
  return str;
}