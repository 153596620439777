import React from "react"
import { useQuery } from "@apollo/client"
import { GET_RINKNET_LEAGUES, GET_SL_LEAGUES } from "../../apollo/queries/league.queries"
import BDDSelect from "../bdd/bddselect"

export default function LeagueSelect({ value, onChange, useRinknet }) {
    const q = useRinknet ? GET_RINKNET_LEAGUES : GET_SL_LEAGUES
    const { data, loading, error } = useQuery(q)
    const dKey = useRinknet ? 'allRinknetLeagues' : 'allSlLeagues'
    const idKey = useRinknet ? 'id_' : 'id'
    const options = !!data ? data[dKey].edges.map(e => ({ value: e.node[idKey], label: e.node.name })) : []
    return <BDDSelect
        name='league'
        isSearchable
        isLoading={loading}
        placeholder={loading ? 'Loading...' : error ? 'Error' : 'Select League...'}
        value={value}
        options={options}
        onChange={onChange}

    />
}