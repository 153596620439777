import { GET_SL_SHIFTS } from 'apollo/queries/shifts.queries';
import { usePaginated } from 'components/bdd/Paginated';
import { removeNull } from 'helpers/data';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { VideoContextProvider } from '.';
import { useSearchParamsState } from '../hooks';
import { applySecondaryPlayerFilter } from './Filtering';

export const ShiftVideoController = ({
  sourceFilters,
  eventFilters,
  secondaryPlayerFilters,
  slPlayer,
  children,
  useStateParams = false,
}) => {
  // Store data (in-memory or search params)
  const initialState = {
    sourceFilters,
    eventFilters,
    secondaryPlayerFilters,
    slPlayer,
  };

  // Store data (in-memory or search params)
  const [store, setStore] = useStateParams
    ? useSearchParamsState(initialState, 'shiftVideoContext')
    : useState(initialState);

  const filters = useMemo(() => {
    if (!store.sourceFilters) return null;
    const { sourceFilters, eventFilters, slPlayer } = store;

    let filters = removeNull({
      ...sourceFilters,
      ...(slPlayer
        ? {
            player_id: {
              col: 'player_id',
              val: slPlayer.playerId,
            },
          }
        : {}),
      ...(eventFilters || {}),
    });

    if (store.secondaryPlayerFilters) {
      filters = applySecondaryPlayerFilter({
        filters,
        secondaryPlayerFilters: store.secondaryPlayerFilters,
        involvement: 'woi',
      });
    }

    return filters;
  }, [store]);

  const {
    paginatedData: allEvents,
    placeholder,
    pageControls,
  } = usePaginated({
    query: GET_SL_SHIFTS,
    key: 'getSlShifts',
    defaultLimit: 100,
    itemNames: 'events',
    variables: {
      sort: [{ id: 'game_uid' }, { id: 'game_time_in' }],
      filters: filters && Object.values(filters),
    },
    skip: !filters || Object.keys(filters).length == 0,
  });

  const setEventFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.eventFilters = { ...draft.eventFilters, ...filters };
      })
    );

  const setSourceFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.sourceFilters = { ...draft.sourceFilters, ...filters };
      })
    );

  const setSlPlayer = (slPlayer) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.slPlayer = slPlayer;
      })
    );

  const setSecondaryPlayerFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.secondaryPlayerFilters = {
          ...draft.secondaryPlayerFilters,
          ...filters,
        };
      })
    );

  useEffect(() => {
    if (!slPlayer || slPlayer.playerId == store.slPlayer.playerId) return;

    setSlPlayer(slPlayer);
  }, [slPlayer]);

  const context = {
    eventType: 'shifts',
    ...store,
    filters: [filters] || [{}],
    setSourceFilters,
    setEventFilters,
    setSecondaryPlayerFilters,
    setSlPlayer,
    allEvents,
    loading: !!placeholder,
    pageControls,
  };

  return <VideoContextProvider videoContext={context}>{children}</VideoContextProvider>;
};
