import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import React from 'react';
import { NumberInput } from '../NumberInput/NumberInput';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { IconButton } from '../Button';
import { X } from 'react-bootstrap-icons';
import { theme } from 'constants';

export const NumberFilter = React.memo(
  ({ id, label, min, max, step = 1, isClearable, meta, hide }) => {
    const filter = useFilterContext((state) => state.filters[id]);
    const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

    useRegisterFilterTag(id, filter && <FilterTag name={label} label={filter.value} />);

    return (
      !hide && (
        <Container>
          <Row justifyContent="space-between">
            <Typography variant="label">{label}</Typography>
            {filter?.value != undefined && filter?.value != null && isClearable && (
              <IconButton
                icon={<X />}
                padding={0}
                hoverColor={theme.colors.light.text.secondary}
                onClick={() => removeFilter(id)}
              />
            )}
          </Row>
          <NumberInput
            min={min}
            max={max}
            step={step}
            variant="label"
            value={filter?.value || 0}
            onChange={(e) => {
              setFilters(id, { id, type: 'number', value: parseFloat(e.target.value), meta });
            }}
          />
        </Container>
      )
    );
  }
);
