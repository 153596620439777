import { formatBddStat } from 'helpers/format';
import React from 'react';
import { useMemo } from 'react';

import { roundToX } from '../../helpers/data';
import {
  getRedGreenColor,
  sortNumeric,
  sortNumericWithFormat,
} from '../../helpers/tables';
import { HoverInfo, HoverInteractive } from './bddcomponents';
import BDDSortableTable from './bddsortabletable';

/* Take a BDD response and turn it into a table */
export default function BDDStatTable({
  data,
  format,
  metastats,
  index: indexArg,
  indexColumns: indexColumnsArg,
  formatStat: formatStatArg,
  sortWithFormat,
  highlight,
  highlightCallback: highlightCallbackArg,
  statSlugs, // a list of statSlugs to use -- ignore all others
  cellHoverCallback: cellHoverCallbackArg,
  cellDecorationCallback: cellDecorationCallbackArg,
  ...rest
}) {
  if (!data || data.length == 0) {
    return (
      <div
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.8em' }}
      >
        No data found
      </div>
    );
  }

  if (!metastats && !highlightCallbackArg && highlight) {
    console.error(
      'Must supply either highlightCallback or metastats in order to do highlighting'
    );
    // throw Error('Must supply either highlightCallback or metastats in order to do highlighting')
  }

  const cellHoverCallback = useMemo(
    () => cellHoverCallbackArg,
    [cellHoverCallbackArg]
  );
  const cellDecorationCallback = useMemo(
    () => cellDecorationCallbackArg,
    [cellDecorationCallbackArg]
  );

  const statDefinitions = format.stat_definitions;
  const slugToDef = {};
  statDefinitions.forEach((s) => (slugToDef[s.slug] = s));

  const formatStat = formatStatArg
    ? formatStatArg
    : (val, slug, row, statDef) => {
        if (isNaN(val)) return val;
        // return roundToX(val, 2)
        return formatBddStat(val, statDef);
      };

  if (!!indexColumnsArg) {
    var indexColumns = indexColumnsArg;
  } else {
    const indexNames = indexArg ? indexArg : format.index;
    var indexColumns = indexNames.map((i) => ({
      Header: i,
      accessor: (d) => d[i],
      tooltip: i,
    }));
  }

  const highlightCallback = highlightCallbackArg
    ? highlightCallbackArg
    : (slug, value, row, statDef) => {
        try {
          const mean = metastats[statDef.unique_id].mean;
          const stddev = metastats[statDef.unique_id].stddev;
          return getRedGreenColor(
            value,
            mean - 2 * stddev,
            mean + 2 * stddev,
            statDef.negative,
            2
          );
        } catch {
          return null;
        }
      };

  const statDefsFiltered = !!statSlugs
    ? statDefinitions.filter((s) => statSlugs.includes(s.slug))
    : statDefinitions;
  const statColumns = statDefsFiltered.map((s) => ({
    Header: s.label,
    accessor: (d) => (
      <div
        style={{
          width: '100%',
          background: highlight
            ? highlightCallback(s.slug, d[s.label], d, s)
            : null,
        }}
      >
        {cellHoverCallback ? (
          <HoverInteractive
            renderContent={(forceClose) =>
              cellHoverCallback(s.label, d, s, forceClose)
            }
          >
            {formatStat(d[s.label], s.slug, d, slugToDef[s.slug])}
            {!!cellDecorationCallback
              ? cellDecorationCallback(s.label, d, s)
              : null}
          </HoverInteractive>
        ) : (
          <>
            {formatStat(d[s.label], s.slug, d, slugToDef[s.slug])}
            {!!cellDecorationCallback
              ? cellDecorationCallback(s.label, d, s)
              : null}
          </>
        )}
      </div>
    ),
    tooltip: s.description,
    sortType: (a, b, colId) =>
      sortWithFormat
        ? sortNumericWithFormat(a, b, colId, formatStat, slugToDef[s.slug])
        : sortNumeric(a, b, colId),
    sortDescFirst: true,
  }));
  const columns = indexColumns.concat(statColumns);
  return <BDDSortableTable data={data} columns={columns} {...rest} />;
}
