import { Button } from "react-bootstrap";
import { IconButton } from ".";
import { PinIcon } from "../Icon";
import { Column, Container } from "../Layout";
import { useModal } from "../Modal";
import { Typography } from "../Typography";


export const PinButton = ({ 
  isPinned,
  itemName='item',
  handlePin,
  handleUnpin,
  handleMoveToTop,
  handleMoveToBottom,
  actionButtonProps={}
}) => {

  const onPinClicked = () => {
    if (!isPinned) {
      handlePin();
    } else {
      setShowModal(true);
    }
  }

  const onUnpin = () => {
    setShowModal(false);
    handleUnpin();
  };

  const onMoveToTop = () => {
    setShowModal(false);
    handleMoveToTop();
  };

  const onMoveToBottom = () => {
    setShowModal(false);
    handleMoveToBottom();
  };

  const { modal, showModal, setShowModal } = useModal({
    size: 'sm',
    title: <Typography variant='h5'>Pin actions</Typography>,
    body: <Column gap={4} alignItems='center'>
      <Container width={200}>
        <Button block variant="outline-dark" onClick={onMoveToTop}>
          Move to top
        </Button>
      </Container>
      <Container width={200}>
        <Button block variant="outline-dark" onClick={onMoveToBottom}>
          Move to bottom
        </Button>
      </Container>
      <Container width={200}>
        <Button block variant="outline-danger" onClick={onUnpin}>
          Unpin
        </Button>
      </Container>
    </Column>
  })

  return (
    <div>
      <IconButton
        {...actionButtonProps}
        onClick={onPinClicked}
        tooltip={isPinned ? `Unpin ${itemName}` : `Pin ${itemName}`}
        icon={<PinIcon enabled={isPinned}></PinIcon>}
      />
      {showModal ? modal : null}
    </div>
  );
};