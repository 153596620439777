import { SearchParams } from 'helpers/searchParams';
import { current } from 'immer';
import { InputRangeFilter } from './InputRangeFilter';
import { NumberFilter } from './NumberFilter';
import { SelectFilter } from './SelectFilter';
import { SelectRangeFilter } from './SelectRangeFilter';
import React from 'react';
import { formatDateGQL } from 'helpers/helpers';

export const buildQueryFilters = (filters, buildIdToQueryFilters) => {
  const idToQueryFilters = buildIdToQueryFilters ? buildIdToQueryFilters(filters) : {};

  return Object.values(filters)
    .map((filter) => {
      if (filter.id in idToQueryFilters) {
        return idToQueryFilters[filter.id](filter);
      }

      if (filter.ignoreOnQuery) {
        return [];
      }

      // Assume 1-to-1 mapping with a table column
      switch (filter.type) {
        case 'inputRange':
          return [
            {
              col: filter.id,
              cond: '>=',
              val: filter.value.min,
            },
            {
              col: filter.id,
              cond: '<=',
              val: filter.value.max,
            },
          ];
        case 'boolean':
          return {
            col: filter.id,
            cond: '=',
            val: filter.checked,
          };
        case 'selectRange':
          return {
            col: filter.id || filter.col,
            cond: 'isin',
            val: filter.value.values,
          };
        case 'date':
          return {
            col: filter.id || filter.col,
            cond: filter.cond,
            val: formatDateGQL(filter.val || filter.value),
          };
        default:
          return {
            col: filter.id || filter.col,
            cond: filter.cond,
            val: filter.value ?? filter.val,
          };
      }
    })
    .flat();
};

const filterTypeToComponent = {
  select: SelectFilter,
  selectRange: SelectRangeFilter,
  inputRange: InputRangeFilter,
  number: NumberFilter,
};

export const buildFilterComponents = (filters) => {
  return filters.map((f) => {
    return React.createElement(filterTypeToComponent[f.type], {
      key: f.id,
      ...f,
    });
  });
};

export const getFilterValues = (filters) =>
  Object.fromEntries(Object.values(filters).map((f) => [f.id, f.value || f.checked]));

export const buildFiltersFromValues = (values) =>
  Object.keys(values).reduce((filters, key) => {
    filters[key] = {
      id: key,
      value: values[key],
      checked: !!values[key],
    };

    return filters;
  }, {});
