import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const useSearchParamsStore = ({ pushSearchParamsOnHistory } = {}) => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(document.location.search),
    [document.location.search]
  );

  const paramExists = (key) => searchParams.has(key) && searchParams.get(key) != '';

  const pushSearchParams = (newSearchParams) =>
    pushSearchParamsOnHistory
      ? history.push(`${pathname}?${newSearchParams}`)
      : history.replace(`${pathname}?${newSearchParams}`);

  const getValue = (key) => (paramExists(key) ? searchParams.get(key) : null);

  const getBoolean = (key, defaultValue = null) =>
    paramExists(key) ? searchParams.get(key) === 'true' : defaultValue;

  const getArray = (key, defaultValue = []) =>
    (paramExists(key) && searchParams.get(key)?.split(',')) || defaultValue;

  const getDate = (key) => {
    if (!paramExists(key)) return null;
    const rawDate = searchParams.get(key);
    if (!rawDate) return null;
    return new Date(parseInt(searchParams.get(key)));
  };

  const getRange = (key) =>
    paramExists(`${key}min`) &&
    paramExists(`${key}max`) && {
      min: parseInt(searchParams.get(`${key}min`)),
      max: parseInt(searchParams.get(`${key}max`)),
    };

  const getSerialized = (key) => paramExists(key) && JSON.parse(searchParams.get(key));

  const setValue = (key, value) => {
    searchParams.set(key, value);
    pushSearchParams(searchParams);
  };

  const setArray = (key, values) => {
    searchParams.set(key, values.join(','));
    pushSearchParams(searchParams);
  };

  const setRange = (key, min, max) => {
    searchParams.set(`${key}min`, min);
    searchParams.set(`${key}max`, max);
    pushSearchParams(searchParams);
  };

  const setDate = (key, val) => {
    searchParams.set(key, val);
    pushSearchParams(searchParams);
  };

  const setDates = (val1, val2, key1 = 'startDate', key2 = 'endDate') => {
    if (!!val1) {
      searchParams.set(key1, new Date(val1).getTime());
    }
    if (!!val2) {
      searchParams.set(key2, new Date(val2).getTime());
    }
    pushSearchParams(searchParams);
  };

  const deleteKeys = (keys) => {
    keys.forEach((key) => {
      searchParams.delete(key);
    });

    pushSearchParams(searchParams);
  };

  const deleteRangeKeys = (keys) => {
    keys.forEach((key) => {
      searchParams.delete(`${key}min`);
      searchParams.delete(`${key}max`);
    });

    pushSearchParams(searchParams);
  };

  const setSearchParams = (values) => {
    const newSearchParams = new URLSearchParams(values);
    pushSearchParams(newSearchParams);
  };

  const setSerialized = (key, obj) => {
    const serialized = JSON.stringify(obj);
    searchParams.set(key, serialized);
    pushSearchParams(searchParams);
  };

  const getAllSearchParams = () => {
    const result = {};
    for (const [key, value] of searchParams.entries()) {
      result[key] = value;
    }

    return result;
  };

  const getRouteWithSearchParams = (route) => {
    return `${route}${searchParams.toString() && `?${searchParams}`}`;
  };
  return {
    paramExists,
    getValue,
    getBoolean,
    getArray,
    getRange,
    getDate,
    getSerialized,
    setValue,
    setArray,
    setRange,
    setDate,
    setDates,
    setSerialized,
    setSearchParams,
    getAllSearchParams,
    getRouteWithSearchParams,
    deleteKeys,
    deleteRangeKeys,
  };
};
