import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Modal, Row, Col } from "react-bootstrap"
import { SectionHeader, SubSectionHeader } from "./report.elements"

import { bddStatQuery } from "../../apollo/queries"
import { getNHLTeamLogoUrl } from "../../helpers/logos"
import { numberToOrdinal } from "../../helpers/helpers"
import BDDPlotly from "../bdd/bddplotly"
import { BDDLoader } from "../bdd/bddloader"
import { getCurrentSeason } from "../../helpers/hockeyutils"
import { smoothData } from "../../helpers/data"
import { nhlTeamColorPalettes } from "../../helpers/plotting"
import BDDSelect from '../bdd/bddselect'
import { NUM_TEAMS } from "../../constants"
import { getRedGreenColor } from "../../helpers/tables"
import BDDErrorBoundary from "../bdd/bdderrorboundary"
import { teamToIndStatSlugs } from "../../data/TeamToIndStatSlugs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"

const ValDiv = styled.div`
    border-radius: 5px;
    ${props => `background: linear-gradient(to top, ${getRedGreenColor(props.rank, NUM_TEAMS, 0)}, #fff)`}
`

const RankedPlayerTableStyle = styled.table`
    width: 100%;
    text-align: left;
    font-size: 0.8em;
    th {
        text-align: center;
    }
`
const RankedPlayerTable = ({children}) => {
    return <RankedPlayerTableStyle>
        {children}
    </RankedPlayerTableStyle>
}

const StatInfo = ({teamSlug, teamData}) => {
    return <ValDiv rank={teamData[teamSlug].ranking}>
        <div>{teamData[teamSlug].raw_value.toFixed(2)}</div>
        <div>Rank: {numberToOrdinal(teamData[teamSlug].ranking)}</div>
        <div>{numberToOrdinal((teamData[teamSlug].percentile*100).toFixed(0))} Percentile</div>
    </ValDiv>
}

const TopPlayers = ({statSlug, situation, position, n, data, maxHeight}) => {
    const rows = [...data.data.data]
    const indSlugToLabel = data.data.format.slug_to_label
    if (statSlug in teamToIndStatSlugs) {
        var indStats = teamToIndStatSlugs[statSlug].filter(s => s in indSlugToLabel)
    } else {
        var indStats = statSlug in indSlugToLabel ? [statSlug] : []
    }

    // const indStats = statSlug in teamToIndStatSlugs ? teamToIndStatSlugs[statSlug] : [statSlug]
    // TODO use list of stats and make table sortable
    const [sort, setSort] = useState({sort: indStats && indStats[0], dir: 'desc'})

    if (!rows.length) return null
    if (indStats.length === 0) return null

    // const indStat = indStats[0]
    // if (!(indStat in indSlugToLabel)) return null

    // const indStatLabel = indSlugToLabel[indStat]
    // if (!(indStatLabel in rows[0])) return null
    // rows.sort((a,b) => b[indStatLabel] - a[indStatLabel])

    rows.sort((a, b) => {
        if (sort.dir === 'desc') {
            return b[indSlugToLabel[sort.sort]] - a[indSlugToLabel[sort.sort]]
        } else {
            return a[indSlugToLabel[sort.sort]] - b[indSlugToLabel[sort.sort]]
        }
    })

    const players = !!n ? rows.slice(0, n) : rows
    return <div style={{maxHeight: maxHeight || "150px", overflowY: 'scroll'}}>
    <RankedPlayerTable>
        <tbody>
            <tr>
            <th>
                {position === 'f' ? 'Forwards' 
                    : position === 'd' ? 'Defensemen'
                    : position === 'all' ? 'Skaters'
                : null}
                {' '}{' '}
                <em>{situation || '5v5'} per 20</em>
            </th>
            {indStats.map(s => 
            <th key={s} 
                style={{textAlign: 'left', cursor: 'pointer'}}
                onClick={() => {
                    if (sort.sort === s) {
                        var dir = sort.dir === 'desc' ? 'asc' : 'desc'
                    } else {
                        var dir = 'desc'
                    }
                    setSort({sort: s, dir: dir})
                }}
            >
                {indSlugToLabel[s]}
                {sort.sort === s ?
                    <FontAwesomeIcon icon={sort.dir === 'desc' ? faSortDown : faSortUp}/>
                : null}
            </th> )}
            </tr>
        {players.slice(0, n).map((d, idx) => <tr key={d['slug']}>
            <td>
                {idx+1}. {d['Player']}
            </td>
            {indStats.map(s => <td key={s}>
                {d[indSlugToLabel[s]].toFixed(2)}
            </td>)}
            {/* <td>{d[indStatLabel].toFixed(2)}</td> */}
        </tr> )}
        </tbody>
    </RankedPlayerTable>
    </div>
}


const TrendPlot = ({statSlug, statLabel, situation, teams}) => {
    const [statByGame, setStatByGame] = useState({loading: false, data: undefined, error: undefined})
    const [smoothWindow, setSmoothWindow] = useState(9)

    useEffect(() => {
        // team ES data
        setStatByGame({ ...statByGame, loading: true })
        bddStatQuery('NHL', 'teams', {
            filters: [{col: 'season', val: getCurrentSeason()}, 
                {col: 'game_type', val: "nhl_regular"},
                {col: 'manpower_situation', val: situation || 'ES'},
                {col: "team_slug", val: [teams[0].slug, teams[1].slug], cond: "isin"}],
            statSlugs: [statSlug],
            index: ['team', 'team_slug', 'game_date'],
            norm: 60,
            format: {percentages: {mult100: true}, sort: {sort: 'game_date', dir: 'asc'}}
        }).then(res => {
            setStatByGame({ ...statByGame, loading: false, data: res.data.stats })
        }).catch(err => {
            setStatByGame({ ...statByGame, loading: false, error: err.response })
        })
    }, [])

    if (statByGame.loading) return <center><BDDLoader/></center>
    if (statByGame.error) return <p>Error loading stat-by-game data</p>
    if (!statByGame.data) return <p>Data not found</p>

    const plotData = []
    teams.forEach((t, i) => {
        const sfdata = statByGame.data.data.filter(d => d.team_slug == t.slug)
                                            .sort((a,b) => a.game_date - b.game_date)
        const xs = sfdata.map(d => new Date(d['game_date']))
        const ys = smoothData(sfdata.map(d => d[statSlug]), { windowSize: smoothWindow })

        plotData.push({
            name: t.longname,
            x: xs,
            y: ys,
            type: 'scatter',
            mode: 'lines+markers',
            hoverinfo:"x+y",
            marker: { 
                color: nhlTeamColorPalettes[t.longname][0],
                opacity: 0.8,
                size: 7
            }
        })
    })
    const smoothWindowOptions = [
        {label: 'None', value: 1},
        {label: 'Light', value: 3},
        {label: 'Medium', value: 9},
        {label: 'Heavy', value: 21}
    ]
    return <>
        <Row>
            <Col md={3}></Col>
            <Col md="auto" className="m-auto">
                <SubSectionHeader>
                    {situation} {statLabel} Trend By Game (smoothed)
                </SubSectionHeader>
            </Col>
            <Col md={3} className="ml-auto">
            <BDDSelect
                name="smoothWindow"
                options={smoothWindowOptions}
                value={smoothWindow}
                onChange={(n,v) => setSmoothWindow(v)}
            />
            </Col>
        </Row>
        <BDDPlotly
            data={plotData}
            layout={{
                hovermode: 'closest',
                legend: { 
                    yanchor: "bottom",
                    y: 1.02,
                    xanchor: "left",
                    x: 0,
                    orientation: 'h'
                },
                margin: { t: 15 }
            }}
        />
    </>
}

export default function TeamsStatDetailModal(props) {
    const { statSlug, statLabel, situation, description } = props
    const { teams, teamData, forwardData, defData, skaterData } = props    

    const hasSkaterData = !!skaterData
    const hasFDData = !!forwardData && !!defData

    return <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
    >
        <Modal.Body>
            <div style={{textAlign: 'center'}}>
            <SectionHeader>{situation} {statLabel}</SectionHeader>
            <div>{description}</div>
            <BDDErrorBoundary>
            {props.adminMode ? 
                <a  
                    style={{cursor: 'pointer', color: 'red'}}
                    onClick={() => {
                        props.toggleHideStat(statSlug)
                        setTimeout(() => props.handleClose(), 250)
                    }}
                >
                    <em>Hide Stat</em>
                </a>
            : null}
            <Row>
                {teams.map(t => 
                <Col key={t.slug}>
                    <div><img src={getNHLTeamLogoUrl(t.nhlid)} height="50px"/></div>
                    <strong>{t.longname}</strong>
                </Col> )}
            </Row>
            <Row>
                {teams.map(t => <Col key={t.slug}>
                    <StatInfo key={t.slug} teamSlug={t.slug} teamData={teamData}/>
                </Col> )}
            </Row>
            <hr></hr>
            <Row>
                {hasSkaterData ? 
                    teams.map(t => <Col key={t.slug}>
                        {skaterData[t.slug].loading ? "Loading..." : <>
                            <TopPlayers statSlug={statSlug} situation={props.situation} position='all' data={skaterData[t.slug]}/>
                        </> }
                    </Col>) 
                : null }
                {hasFDData ? 
                    teams.map(t => <Col key={t.slug}>
                        {forwardData[t.slug].loading || defData[t.slug].loading ? "Loading..." : <>
                            <TopPlayers statSlug={statSlug} situation={props.situation} position='f' data={forwardData[t.slug]}/>
                            <TopPlayers statSlug={statSlug} situation={props.situation} position='d' data={defData[t.slug]}/>
                        </> }
                    </Col>) 
                : null }
            </Row>
            {hasSkaterData || hasFDData ? <hr></hr> : null}
            <TrendPlot statSlug={statSlug} statLabel={statLabel} teams={teams} situation={props.situation}/>
            </BDDErrorBoundary>
            </div>
        </Modal.Body>
    </Modal>
}