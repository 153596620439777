import styled from 'styled-components';
import useToggle from 'components/bdd/Toggle/useToggle';
import { roundToX } from 'helpers/data';
import { stddevColor } from 'helpers/tables';
import PlayerStatList from 'components/SLStats/PlayerStatList';
import { SLStatContext, SLStatTable } from '.';
import { useContext, useEffect } from 'react';
import useStatReports from 'components/StatReports/hooks/useStatReports';
import { Column, Container, Row } from 'components/bdd/Layout';
import { searchMetastatsSplitsForStat } from 'helpers/stats';
import { GraphicalViewButton } from './filters';

const ColoredStddev = styled.span({
  color: ({ color = 'grey' }) =>
    color === 'green'
      ? '#11A111'
      : color === 'grey'
      ? 'rgba(50, 50, 50, 0.5)'
      : color === 'red'
      ? '#A11111'
      : null,
});

export const PlayerSLStatTable = ({
  indexColumns,
  bddPlayer,
  storageKeyPrefx,
  // called with (metastats (based on toggled group/position), label, row, statDef, toiStatDef)
  // toiStatDef is useful when you need to do normalization for metastats
  highlightCallback: highlightCallbackArg,
  comparePlayer: comparePlayerArg = true,
  matchMetastatsSlugsOnly = false, // use when supplying a target norm or filters to metastats.  Match slug, not unique_id
  ...rest
}) => {
  const posFDG = bddPlayer.rinknetPlayer.posFDG.toLowerCase();
  const slUid = bddPlayer.slUid;

  const { data, placeholder, filters, setFilters, target, refetch } =
    useContext(SLStatContext);

  const { statReportsButtons, statObj, editStatsButton } = useStatReports({
    target,
    showMoreButton: true,
    storageKeyPrefx,
    refetchStats: refetch,
  });

  useEffect(() => {
    if (!statObj) return;
    setFilters(statObj);
  }, [statObj]);

  // Sometimes strange race conditions cause statReport to be unset when switching around...
  // this fixes that
  useEffect(() => {
    if (
      !(filters.statDefs || filters.statSlugs || filters.statReport) &&
      !!statObj
    ) {
      setFilters(statObj);
    }
  }, [filters]);

  // toggle to control if we compare to players on team or to league
  const { toggled: compareToTeam, toggleComponent: targetGroupToggle } =
    useToggle({
      id: 'toggle-compare-team',
      label: 'Compare to team',
      initialToggled: posFDG === 'g' ? false : true,
      disabled:
        !data ||
        !data.data ||
        (data.data.length && !('team_slug' in data.data[0])),
    });

  // Toggle to control if we compare to "all" or specific position
  const { toggled: compareToPosition, toggleComponent: positionToggle } =
    useToggle({
      id: 'toggle-compare-position',
      label: `Compare to ${posFDG.toUpperCase()}`,
      initialToggled: comparePlayerArg,
      disabled: posFDG === 'g',
    });

  const toggleControls = [
    targetGroupToggle,
    positionToggle,
    <div key={'edit-stats-button'} style={{ marginLeft: 'auto' }}>
      {editStatsButton}
    </div>,
  ];

  const findMetastats = (statDef, row) => {
    // if team_slug not in row, must compare to league
    const targetGroup =
      compareToTeam && 'team_slug' in row ? row.team_slug : 'league';

    const rowPos =
      posFDG === 'G'
        ? 'g'
        : compareToPosition && row.position
        ? row.position.toLowerCase()
        : 'all';

    return searchMetastatsSplitsForStat(data?.extras?.metastats, statDef, row, {
      targetGroup,
      targetPosition: rowPos,
      matchOn: matchMetastatsSlugsOnly ? 'slug' : 'unique_id',
    });
  };

  const zscoreToColor = (zscore, negative) => {
    if (zscore >= 1) {
      var color = negative ? 'red' : 'green';
    } else if (zscore <= -1) {
      color = negative ? 'green' : 'red';
    } else {
      color = 'grey';
    }
    return color;
  };

  const highlightCallback = (label, row, statDef) => {
    const value = row[label];
    if (isNaN(value) || value === null) return null;
    const [_, metastats] = findMetastats(statDef, row);
    if (!metastats) return null;
    if (!!highlightCallbackArg) {
      const toiStatDef = data.format.stat_definitions.find(
        (s) => s.slug === 'toi'
      );
      return highlightCallbackArg(metastats, label, row, statDef, toiStatDef);
    }
    return stddevColor(value, metastats, statDef.negative);
  };

  const cellHoverCallback = (label, row, statDef) => {
    const value = row[label];
    const statSlug = statDef.slug;
    const uniqueId = statDef.unique_id;
    const [split, metastats] = findMetastats(statDef, row);
    if (!metastats) return <em>No metastats data found</em>;
    const zscore = (value - metastats.mean) / metastats.stddev;
    const color = zscoreToColor(zscore, statDef.negative);
    const pm = zscore >= 0 ? '+' : '';
    const leagueTeam = compareToTeam ? 'team' : 'league';
    return (
      <>
        <ColoredStddev color={color}>
          {pm}
          {roundToX(zscore, 2)} stddevs {pm === '+' ? 'above' : 'below'}{' '}
          {leagueTeam} average
        </ColoredStddev>
        <PlayerStatList
          slUid={slUid}
          split={split}
          norm={statDef.norm}
          filterSetId={statDef.filter_set_id}
          statSlug={statSlug}
          statLabel={label}
          compareToPosition={compareToPosition}
          compareToTeam={compareToTeam}
        />
      </>
    );
  };

  return (
    <Column columnGap={2}>
      <Row alignItems="start" columnGap={2}>
        <GraphicalViewButton />
        <Container paddingBottom={2} flexGrow overflow="auto">
          {statReportsButtons}
        </Container>
      </Row>
      {!data || !data.data ? (
        placeholder
      ) : (
        <SLStatTable
          data={data}
          cellHoverCallback={cellHoverCallback}
          indexColumns={indexColumns}
          highlightCallback={highlightCallback}
          toggleControls={toggleControls}
          scroll={true}
          {...rest}
        />
      )}
    </Column>
  );
};
