import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SCOUTED_PLAYER_RANKINGS } from 'apollo/queries/scouting.queries';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';
import { numberToOrdinal } from 'helpers/helpers';
import { SectionHeader, TooltipSpan } from 'components/reports';
import BDDLinkButton from 'components/bdd/bddlinkbutton';
import { Button, Container, Modal, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChooseSplitTable } from 'components/Scouting/AggregatedRatings/choosesplittable';
import RankingsTable from 'components/Scouting/AggregatedRatings/rankingstable';

import {
  faBrain,
  faBullseye,
  faEye,
  faHockeyPuck,
  faSkating,
  faArrowsAltH,
  faVectorSquare,
  faShieldAlt,
  faMagic,
  faLongArrowAltRight,
  faPeopleArrows,
  faChevronCircleRight,
  faFire,
} from '@fortawesome/free-solid-svg-icons';
import { theme } from 'constants';
import styled from 'styled-components';
import { getRedGreenColor } from 'helpers/tables';
import { roundToX } from 'helpers/data';
import { Typography } from 'components/bdd/Typography';
import { nameToInitials } from 'helpers/hockeyutils';
import ReportsByScoutModal from './ReportsByScoutModal';
import { HoverInteractive } from 'components/bdd';
import { Border, Container as BDDContainer, Row as BDDRow } from 'components/bdd/Layout';

const ScoutingRankContainer = styled.div({
  ...theme.typography.stat,
  display: 'flex',
  gap: theme.spacing[1],
  flexWrap: 'wrap',
  justifyContent: 'start',
});

const ScoutingRatingIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[1],
  padding: theme.spacing[1],
});

const ScoutingWatchCount = styled.div({
  marginLeft: theme.spacing[1],
});

const SplitControlContainer = styled.div({
  ...theme.typography.body4,
  marginBottom: theme.spacing[1],
});

export const ratingToIcon = {
  skating: faSkating,
  hockeySense: faBrain,
  scoring: faBullseye,
  puckHandling: faHockeyPuck,
  positioning: faArrowsAltH,
  reboundControl: faVectorSquare,
  creativity: faMagic,
  defending: faShieldAlt,
  passing: faChevronCircleRight,
  intensity: faFire,
};

export const ratingToIconText = {
  intensity: 'IN',
  creativity: 'CR',
  passing: 'PS',
  postTracking: 'PT',
  trackingPucks: 'TP',
  handsGloveBlocker: 'GB',
  defending: 'DE',
};

export const ratingToText = {
  skating: 'Skating',
  hockeySense: 'Hockey Sense',
  scoring: 'Scoring',
  puckHandling: 'Puck Possession',
  positioning: 'Positioning',
  reboundControl: 'Rebound Control',
  intensity: 'Intensity / Compete',
  creativity: 'Creativity',
  passing: 'Passing',
  postTracking: 'Post Tracking',
  trackingPucks: 'Tracking Pucks',
  handsGloveBlocker: 'Hands Glove Blocker',
  defending: 'Defending',
};

export const posToRatings = (position) => {
  position = position?.toLocaleLowerCase();

  switch (position) {
    case 'f':
      return [
        'skating',
        'hockeySense',
        'scoring',
        'intensity',
        'puckHandling',
        'creativity',
        'defending',
      ];
    case 'd':
      return [
        'skating',
        'hockeySense',
        'scoring',
        'intensity',
        'puckHandling',
        'passing',
        'defending',
      ];
    case 'g':
      return [
        'positioning',
        'reboundControl',
        'postTracking',
        'trackingPucks',
        'handsGloveBlocker',
      ];
    default:
      return ['skating', 'hockeySense', 'scoring', 'intensity', 'puckHandling'];
  }
};

const renderRatingIcon = (icon, text, rank, predictedRank) => {
  if (!rank) return null;

  const color = getRedGreenColor(1 - rank.percentile, 0, 1, false, 2);
  const predictedColor =
    predictedRank && getRedGreenColor(1 - predictedRank.percentile, 0, 1, false, 2);

  return (
    <BDDContainer
      key={`rating-${rank.rating.primaryName}`}
      as={TooltipSpan}
      content={
        <div>
          <b>{rank.rating.primaryName}</b>
          <div>
            {numberToOrdinal(roundToX(100 * (1 - rank.percentile), 1))} percentile
          </div>
          <div>
            {numberToOrdinal(rank.rank)} out of {rank.total}
          </div>
          <i>Based on {rank.count} reports</i>
        </div>
      }
    >
      <Border>
        <BDDRow>
          <BDDContainer padding={0.5} paddingRight={1} style={{ backgroundColor: color }}>
            {!!icon ? <FontAwesomeIcon icon={icon} /> : text}{' '}
          </BDDContainer>
          <BDDContainer padding={0.5} style={{ backgroundColor: color }}>
            {!!rank ? roundToX(100 * (1 - rank.percentile), 0, true) : <em>NA</em>}
          </BDDContainer>
          {predictedRank && (
            <>
              <BDDContainer padding={0.5} style={{ backgroundColor: predictedColor }}>
                {roundToX(100 * (1 - predictedRank.percentile), 0, true)}
              </BDDContainer>
            </>
          )}
        </BDDRow>
      </Border>
    </BDDContainer>
  );
};

export const ScoutingRankSplitDescription = ({
  split,
  totalPlayers,
  brief = false,
  descriptionHoverContent,
}) => {
  const description = brief ? (
    <Typography variant="stat">
      From {totalPlayers}
      <b>
        {!!split.league ? (
          <TooltipSpan content={split.league}>
            {split.league.split(' ').map((word) => word[0])}
          </TooltipSpan>
        ) : !!split.leagueGroup ? (
          split.leagueGroup
        ) : null}{' '}
        {split.isDraftEligible ? 'draft elig.' : ''}{' '}
        {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'plyrs'}{' '}
        {split.timeRange === 'PAST_YEAR'
          ? 'past yr'
          : split.timeRange === 'PAST_2_YEARS'
          ? 'past 2 yrs'
          : ''}{' '}
        {!!split.scoutName && (
          <>
            <TooltipSpan content={split.scoutName}>
              {nameToInitials(split.scoutName)}'s
            </TooltipSpan>{' '}
            views
          </>
        )}{' '}
        {!!split.predictedGrades && `using predicted grades`}
      </b>
    </Typography>
  ) : (
    <Typography variant="stat">
      Ranked against {totalPlayers} other{' '}
      <b>
        {!!split.league ? (
          <TooltipSpan content={split.league}>
            {split.league.split(' ').map((word) => word[0])}
          </TooltipSpan>
        ) : !!split.leagueGroup ? (
          split.leagueGroup
        ) : null}{' '}
        {split.isDraftEligible ? 'draft elig.' : ''}{' '}
        {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'players'}{' '}
        {split.timeRange === 'PAST_YEAR'
          ? 'from past year'
          : split.timeRange === 'PAST_2_YEARS'
          ? 'from past 2 years'
          : split.timeRange?.startsWith('DRAFT_YEAR_')
          ? `from ${split.timeRange.replace('DRAFT_YEAR_', '')}`
          : ''}{' '}
        {!!split.scoutName && `based on ${split.scoutName}'s views`}{' '}
        {!!split.predictedGrades && `using predicted grades`}
      </b>
    </Typography>
  );

  return descriptionHoverContent ? (
    <HoverInteractive placement="top" content={descriptionHoverContent}>
      {description}
    </HoverInteractive>
  ) : (
    description
  );
};

export const ScoutingGradeBoxes = ({
  rankings,
  split,
  position, // fdg lower
  shortSplitDesc = false,
  noSplitDescription,
  predictedRankings,
  rinknetId, // used to pull up by scout modal
  playerName, // also for the modal
  hideScoutReportModal,
}) => {
  const [showByScout, setShowByScout] = useState(false);
  const ratingToRank = useMemo(() => {
    if (!!rankings) {
      return rankings.reduce((acc, curr) => ({ ...acc, [curr.rating.slug]: curr }), {});
    }
  }, [rankings]);

  const predictedRatingToRank = useMemo(() => {
    if (!!predictedRankings) {
      return predictedRankings.reduce(
        (acc, curr) => ({ ...acc, [curr.rating.slug]: curr }),
        {}
      );
    }
  }, [predictedRankings]);

  if (Object.keys(ratingToRank).length == 0)
    return (
      <Typography variant={'body1'}>
        <em>No scout ranking data found</em>
      </Typography>
    );

  const ratingsToPlot = posToRatings(position);
  const totalPlayers = rankings?.[0]?.total;
  const reportCount = Math.max(...Object.values(ratingToRank).map((rank) => rank.count));

  return (
    <div>
      {!noSplitDescription && (
        <SplitControlContainer>
          {!!split ? (
            <ScoutingRankSplitDescription
              split={split}
              totalPlayers={totalPlayers}
              brief={shortSplitDesc}
            />
          ) : (
            <em>No data found</em>
          )}
        </SplitControlContainer>
      )}
      <ScoutingRankContainer>
        {ratingsToPlot.map((rating) =>
          renderRatingIcon(
            rating in ratingToIcon && ratingToIcon[rating],
            rating in ratingToIconText && <b>{ratingToIconText[rating]}</b>,
            ratingToRank[rating],
            predictedRatingToRank?.[rating]
          )
        )}
        <ScoutingRatingIcon
          as={TooltipSpan}
          content="Click for breakdown by scout"
          onClick={() => setShowByScout(true)}
        >
          <FontAwesomeIcon icon={faEye} />
          <ScoutingWatchCount>{reportCount || 0}</ScoutingWatchCount>
        </ScoutingRatingIcon>
      </ScoutingRankContainer>
      {!!rinknetId && showByScout && !hideScoutReportModal && (
        <ReportsByScoutModal
          show={showByScout}
          handleClose={() => setShowByScout(false)}
          rinknetId={rinknetId}
          playerName={playerName}
        />
      )}
    </div>
  );
};

export const ScoutingGrades = ({
  rinknetId,
  scoutId,
  predictedGrades,
  position = null,
  height = '150px',
  author,
  showOptions = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showRankingsModal, setShowRankingsModal] = useState(false);
  const [splitId, setSplitId] = useState(null);

  // Define variables for request. Use split if we've selected one
  var variables = !!splitId ? { splitId } : { useDefaultSplit: true };
  variables.rinknetId = rinknetId;
  variables.scoutId = scoutId;
  variables.position = position;
  variables.predictedGrades = !!predictedGrades;

  const { data, loading, error } = useQuery(GET_SCOUTED_PLAYER_RANKINGS, {
    variables,
  });
  const rankings = !!data ? data.scoutedPlayerRankings.rankings : [];

  const handleSetSplitId = (sid) => {
    // function called with new split to use for building plot
    setSplitId(sid);
    setShowModal(false);
  };

  if (loading) return <BDDLoader />;
  if (error) return <BDDApiError error={error} />;

  if (!position && rankings.length > 0) {
    position = rankings[0].rinknetPlayer.posFDG.toLocaleLowerCase();
  }

  // this is the split in the current data, not to be confused with the split to request
  const currentSplit = data.scoutedPlayerRankings.split;
  if (currentSplit.id == -1) return null;

  const rinknetPlayer = data.scoutedPlayerRankings.rinknetPlayer;
  const playerName = `${rinknetPlayer.firstname} ${rinknetPlayer.lastname}`;
  return (
    <>
      <ScoutingGradeBoxes
        rankings={rankings}
        split={currentSplit}
        position={position}
        rinknetId={rinknetId}
        playerName={playerName}
      />
      <SplitControlContainer style={{ marginTop: '5px' }}>
        {showOptions && (
          <div>
            <BDDLinkButton
              color="#007BFF"
              onClick={() => setShowModal(true)}
              style={{ padding: 0 }}
            >
              Change comp group
            </BDDLinkButton>{' '}
            |{' '}
            <BDDLinkButton
              color="#007BFF"
              onClick={() => setShowRankingsModal(true)}
              style={{ padding: 0 }}
            >
              See full list
            </BDDLinkButton>
          </div>
        )}
      </SplitControlContainer>
      <ChooseSplitModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        splits={data.scoutedPlayerRankings.otherValidSplits}
        playerName={playerName}
        handleSetSplitId={handleSetSplitId}
        currentSplitId={currentSplit.id}
      />
      {showRankingsModal && (
        <FullRankingsModal
          show={showRankingsModal}
          handleClose={() => setShowRankingsModal(false)}
          currentSplitId={currentSplit.id}
          handleSetSplitId={handleSetSplitId}
          position={position}
        />
      )}
    </>
  );
};

const ChooseSplitModal = ({
  show,
  handleClose,
  playerName,
  splits,
  handleSetSplitId,
  currentSplitId,
}) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <SectionHeader>Valid Splits for {playerName}</SectionHeader>
      </Modal.Header>
      <Modal.Body>
        <ChooseSplitTable
          showScout
          splits={splits}
          currentSplitId={currentSplitId}
          handleSetSplitId={handleSetSplitId}
        />
      </Modal.Body>
    </Modal>
  );
};

const FullRankingsModal = ({ show, handleClose, currentSplitId, handleSetSplitId }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <SectionHeader>Rankings List</SectionHeader>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <ChooseSplitTable
              showScout
              currentSplitId={currentSplitId}
              handleSetSplitId={handleSetSplitId}
              maxHeight="200px"
            />
          </div>
          <hr></hr>
          <div>
            {!!currentSplitId ? (
              <RankingsTable
                splitId={currentSplitId}
                handleSetSplitId={handleSetSplitId}
              />
            ) : (
              <center>
                <b>Select a split above</b>
              </center>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
