import { promise_bruins_api } from "./requests"

export function promise_login_user(email, password, asEmail) {
    return promise_bruins_api('auth/login', {
        method: 'POST',
        data: {email: email, password: password, asEmail: asEmail}
    })
}

export function promise_refresh_user() {
    return new Promise((resolve, reject) => {
        promise_bruins_api('auth/refresh', {
            method: 'POST',
            refreshCSRF: true
        }).then(res => {
            localStorage.setItem('user', JSON.stringify(res.data.user))
            resolve(res)
        }).catch(err => reject(err))
    })
}

export function promise_logout_user() {
    return promise_bruins_api('auth/logout', {
        method: 'POST'
    })
}

export function promise_register_user(data) {
    if (!('email' in data) || !('password' in data)) {
        console.error('"email" and "password" required to create user')
        return
    }
    return promise_bruins_api('auth/register', {
        method: 'POST',
        data: data
    })
}

export function promise_change_password(data) {
    return promise_bruins_api('auth/changepassword', {
        method: 'POST',
        data: data
    })
}

export function promise_reset_password(data) {
    return promise_bruins_api('auth/resetpassword', {
        method: 'POST',
        data: data
    })
}