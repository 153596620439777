export const pToShort = {
  leftwing: 'LW',
  center: 'C',
  rightwing: 'RW',
  leftdefence: 'LD',
  rightdefence: 'RD',
  goalie: 'G',
  pp1: 'PP1',
  pp2: 'PP2',
  pp3: 'PP3',
  pp4: 'PP4',
  pp5: 'PP5',
};

export const fOrder = ['leftwing', 'center', 'rightwing'];
export const dOrder = ['leftdefence', 'rightdefence'];
