import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_REPORTS_FOR_PLAYER } from 'apollo/queries/scouting.queries';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';
import styled from 'styled-components';
import {
  formatDate,
  formatDateGQL,
  getDaysBetweenDates,
} from 'helpers/helpers';
import { HoverInfo } from 'components/bdd';
import { theme } from 'constants';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { buildBDDScheduleQuery } from 'apollo/queries/scoutingobjectives.queries';
import { getCurrentSeason } from 'helpers/hockeyutils';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[2],
});

const FieldGroup = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing[1],
});

const Title = styled.div({
  ...theme.typography.body1,
});

const Field = styled.div({
  ...theme.typography.body2,
});

export const ReportSummary = ({ bddPlayer }) => {
  const { data, loading, error } = useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
    variables: {
      rinknetId: bddPlayer.rinknetId,
    },
  });

  const { data: scoutScheduledData, placeholder: scoutScheduledPlaceholder } =
    usePlaceholder(
      useQuery(buildBDDScheduleQuery({ includeScouts: true }), {
        variables: {
          entities: [
            {
              id: bddPlayer.slug,
              type: 'BDD_PLAYER',
              startDate: formatDateGQL(new Date()),
            },
          ],
        },
      })
    );

  const schedule = scoutScheduledData?.bddSchedules[0];
  const scheduledScoutGames = schedule?.bddGames
    .filter((g) => g.countScouts > 0)
    .map((g) => ({
      game: g,
      scouts: schedule.rinknetScouts.filter((rs) =>
        g.rinknetScoutsMapping.includes(parseInt(rs.id))
      ),
    }));

  const reportToAuthor = (r) => {
    if (r.__typename == 'BDDPlayerScoutingReport') {
      return !!r.rinknetUserId
        ? `${r.rinknetUser.firstname} ${r.rinknetUser.lastname}`
        : r.user.username;
    } else if (r.__typename == 'RinknetReports') {
      return `${r.rinknetUser.firstname} ${r.rinknetUser.lastname}`;
    }
  };

  const reportToPosition = (r) => {
    if (r.__typename == 'BDDPlayerScoutingReport') {
      return bddPlayer.rinknetPlayer.position;
    } else if (r.__typename == 'RinknetReports') {
      return r.position;
    }
  };

  const [bySplit, scoutToCount, positionToCount] = useMemo(() => {
    const ret = { BDD: 0, Rinknet: 0, thisYear: 0, thisSeason: 0, uniqueScouts: 0 };
    var scoutToCount = {};
    let positionToCount = {};
    if (!!data) {
      data.allPlayerReports.reports.forEach((r) => {
        if (r.__typename === 'BDDPlayerScoutingReport') {
          ret['BDD'] += 1;
        }
        if (r.__typename === 'RinknetReports') {
          ret['Rinknet'] += 1;
        }
        const date =
          r.__typename === 'RinknetReports'
            ? new Date(r.date)
            : new Date(r.creationDate);
        if (getDaysBetweenDates(date, new Date()) < 365) {
          ret['thisYear'] += 1;
        }
        
        const seasonStartYear = getCurrentSeason().toString().slice(0, 4)
        if (date > new Date(`${seasonStartYear}-9-1`)) {
          ret['thisSeason'] += 1;
        }
        const author = reportToAuthor(r);
        if (!(author in scoutToCount)) scoutToCount[author] = 0;
        scoutToCount[author] += 1;

        const position = reportToPosition(r);
        if (!(position in positionToCount)) positionToCount[position] = 0;
        positionToCount[position] += 1;
      });
      ret.uniqueScouts = Object.keys(scoutToCount).length;
    }
    return [ret, scoutToCount, positionToCount];
  }, [data]);

  if (loading) return <BDDLoader />;
  if (error) return <BDDApiError error={error} />;

  const reports = data.allPlayerReports.reports;
  const lastViewedReport = [...reports].sort((a, b) => {
    return new Date(b.date || b.creationDate) - new Date(a.date || a.creationDate);
  })[0];

  const lastViewedDate = formatDate(
    lastViewedReport.date || lastViewedReport.creationDate
  );

  const positionsToCount = Object.keys(positionToCount);
  const positions = positionsToCount.map((position, index) => (
    <Title key={`position-${position}`}>
      {positionToCount[position]} {position}
      {index != positionsToCount.length - 1 ? ',' : ''}
    </Title>
  ));

  return (
    <Container>
      <FieldGroup>
        <Title>{reports.length} Reports</Title>
        <Field>
          ({bySplit.Rinknet} Rinknet, {bySplit.BDD} BDD)
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Title>{bySplit.thisSeason} views this season</Title>
        <Field>
          (Last viewed by {lastViewedReport.author} on {lastViewedDate})
        </Field>
      </FieldGroup>
      {/* <FieldGroup>
        <Title>{bySplit.thisYear} views this year</Title>
        <Field>
          (Last viewed by {lastViewedReport.author} on {lastViewedDate})
        </Field>
      </FieldGroup> */}
      <FieldGroup>
        <Title>{bySplit.uniqueScouts} scouts</Title>
        <HoverInfo
          content={() =>
            Object.keys(scoutToCount).length ? (
              Object.keys(scoutToCount).map((scout) => (
                <div key={scout}>
                  {scout}{' '}
                  <span
                    style={{
                      paddingLeft: '5px',
                      float: 'right',
                      fontSize: '0.8em',
                      fontStyle: 'italic',
                    }}
                  >
                    {scoutToCount[scout]} views
                  </span>
                </div>
              ))
            ) : (
              <em>No reports</em>
            )
          }
        >
          <Field>(Hover for views)</Field>
        </HoverInfo>
      </FieldGroup>
      <FieldGroup>
        {!scheduledScoutGames ? (
          scoutScheduledPlaceholder
        ) : scheduledScoutGames.length == 0 ? (
          <Title>Not scheduled to be viewed</Title>
        ) : (
          <>
            <Title>
              Scheduled to be viewed by{' '}
              {scheduledScoutGames[0].scouts
                .map((s) => `${s.firstname} ${s.lastname}`)
                .join(', ')}
            </Title>
            <Field>(on {formatDate(scheduledScoutGames[0].game.date)})</Field>
          </>
        )}
      </FieldGroup>
      <FieldGroup>{positions}</FieldGroup>
    </Container>
  );
};
