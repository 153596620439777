import React from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';
import styled from 'styled-components';

const LoadingContainer = styled.div(({ size }) => ({
  animation: 'rotate 1s linear infinite',
  '@keyframes rotate': {
    to: { transform: 'rotate(360deg)' },
  },
}));

const IconContainer = styled.div(
  ({ color, hoverColor, size, height, width, padding, marginTop, marginLeft }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    color: color,
    padding: padding,
    marginLeft: marginLeft,
    marginTop: marginTop,
    svg: {
      width: width || size,
      height: height || size,
    },
    '&:hover': {
      color: hoverColor || color,
    },
    'svg:hover': {
      fill: hoverColor || color,
    },
  })
);

const Icon = ({
  icon,
  color,
  hoverColor,
  size,
  height,
  width,
  padding,
  marginLeft,
  marginTop,
  onClick,
  loading,
  style,
}) => {
  return loading ? (
    <LoadingIcon color={color} hoverColor={hoverColor} size={size} padding={padding} />
  ) : (
    <IconContainer
      color={color}
      size={size}
      height={height}
      width={width}
      hoverColor={hoverColor}
      padding={padding}
      marginLeft={marginLeft}
      marginTop={marginTop}
      onClick={onClick}
      style={style}
    >
      {icon}
    </IconContainer>
  );
};

export const LoadingIcon = ({ color, hoverColor, size, padding }) => (
  <LoadingContainer>
    <Icon
      icon={<ArrowClockwise />}
      color={color}
      hoverColor={hoverColor}
      size={size}
      padding={padding}
    />
  </LoadingContainer>
);

export default Icon;
