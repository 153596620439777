import React, { useEffect, useState } from "react"
import { BDDLoader } from "../bdd/bddloader"
import PlayerListController from "./playerlistcontroller"

export default function PlayerListWrapper({
    listId,
    ...props
}) {
    const [prevListId, setPrevListId] = useState(listId)

    // if listId changes we gotta force a remounting
    useEffect(() => {
        setPrevListId(listId)
    }, [listId])
    
    if (listId !== prevListId) return <BDDLoader/>

    return <PlayerListController listId={listId} {...props}/>
}