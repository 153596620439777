import { Card } from "components/bdd/Card";
import { useSearchParamsState } from "components/bdd/hooks";
import { Column, Container, Row } from "components/bdd/Layout";
import { BFPlayerContractBuyout } from "components/PlayersV2/Contract/components/BFPlayerContractBuyout";
import { getNextOrCurrentSeason, season8to4, seasonToEndYear, seasonToStartYear } from "helpers/hockeyutils";
import { ContractSearchSelect } from "./ContractSearchSelect";
import { Label } from "components/bdd/Label/Label";
import BDDSelect from "components/bdd/bddselect";
import { StyledBDDSelect } from "components/bdd/Select/StyledBDDSelect";
import { useQuery } from "@apollo/client";
import { GET_BF_CONTRACT } from "apollo/queries/bruinsfriendly.queries";
import { Typography } from "components/bdd/Typography";
import { useEffect, useState } from "react";
import { Divider } from "components/bdd/Divider";


export const ContractBuyout = () => {

  const { calculator } = useContractBuyout({ useParams: true });



  return (
    <Container height="100%">
      <Card height="100%" overflow="hidden">
        {calculator}
      </Card>
    </Container>
  );
}

export const useContractBuyout = ({ defaultContractId, defaultYear, useParams, hideYear }) => {
  const [contractId, setContractId] = useParams ? useSearchParamsState(defaultContractId, 'contractId') : useState(defaultContractId);
  const [year, setYear] = useParams ? useSearchParamsState(defaultYear, 'year') : useState(defaultYear);
  console.log(contractId)

  const { data, loading } = useQuery(GET_BF_CONTRACT, {
    variables: { id: contractId },
    skip: !contractId
  });
  const contract = data?.bfContract;
  const yearOptions = contract?.contractSeasons.map(s => 
    ({ label: seasonToStartYear(s.season), value: seasonToStartYear(s.season) }));

  useEffect(() => {
    if (!!data && !hideYear) {
      if (!!data.bfContract.contractSeasons.find(s => s.season == getNextOrCurrentSeason())) {
        setYear(seasonToStartYear(getNextOrCurrentSeason()));
      } else {
        setYear(seasonToStartYear(data.bfContract.contractSeasons[0].season));
      }
    }
  }, [data])
  

  const calculator = (
    <Column gap={4}>
      <Container>
        <Row gap={8}>
          <Container>
            <Label>Contract</Label>
            <ContractSearchSelect
              placeholder={
                contract
                  ? `${contract.bfPlayer.firstName} ${contract.bfPlayer.lastName} ${seasonToStartYear(contract.startSeason)}-${seasonToEndYear(contract.endSeason)}`
                  : 'Select a contract...'}
              size='lg'
              selectedValue={contractId}
              onChange={(cid) => setContractId(cid)}
              selectedContract={contract}
            />
          </Container>

          {!!contractId && !!data && !hideYear &&  (
            <Container width={200}>
              <Label>Year</Label>
              <StyledBDDSelect 
                placeholder="Select a year..."
                selectedValue={year}
                options={yearOptions}
                onChange={(n, v) => setYear(v)}
                isLoading={loading}
              />
            </Container>
          )}
        </Row>
      </Container>

      <Divider/>

      <Container>
        {!contractId 
          ? <Typography variant='body2'>Please select a contract</Typography> 
          : !year
          ? <Typography variant='body2'>Please select a year</Typography>
          : <BFPlayerContractBuyout
            contractId={contractId}
            defaultYear={year}
            onYearChange={(yr) => setYear(yr)}
          />
        }
      </Container>
    </Column>
  )

  return {
    calculator,
    contractId,
    contract,
    year,
    setContractId,
    setYear
  }
}