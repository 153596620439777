import React, {useState} from "react"
import BDDSelect from '../bdd/bddselect'
import { getStrengthChoices } from "../../helpers/filters"

export default function StrengthSelect(props) {

    var strengthOptions = []
    getStrengthChoices().forEach(group => 
        strengthOptions = strengthOptions.concat(group.options))
    const content = props.useBDDSelect ?
        <BDDSelect
            name="strength"
            value={props.value}
            options={getStrengthChoices(props.precalc)}
            {...props}
            onChange={(name, value) => props.onChange(name, value)}
        />
            :
        <select 
            name="strength"
            value={props.season} 
            onChange={ev => props.onChange(ev.target.name, ev.target.value)}
            className={props.className}
        >
            {strengthOptions.map(s =>
                <option key={s.value} value={s.value}>{s.label}</option>)}
        </select>

    return (
        <>{content}</>
    )
}