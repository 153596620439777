import React from 'react';
import styled from 'styled-components';
import { Pin, PinFill } from 'react-bootstrap-icons';
import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';

// This icon class is only really necessary
// due to the alignment issues of the svg icons
const pinStyles = {
  marginTop: theme.spacing[0.5],
  cursor: 'pointer',
};

const StyledPin = styled(Pin)(pinStyles);
const StyledPinFill = styled(PinFill)(pinStyles);

export const PinIcon = ({ enabled, tooltip='Pin', ...props }) => (
  <TooltipSpan content={tooltip}>
    {enabled ? <StyledPinFill {...props} /> : <StyledPin {...props} />}
  </TooltipSpan>
)
