import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { buildPlayerRoute } from 'helpers/routing';
import { Link } from 'react-router-dom';
import PlayerHover from './playerhover';
import { PlayerName } from '../Name/PlayerName';

export default function PlayerLinkHover({
  name,
  slug,
  rinknetId,
  teamNhlid,
  style = {},
  color,
  playerHoverProps = {},
  allowHover = true,
  justifyContent = 'start',
  variant = 'body2',
  height = '', // default row height is 100% -- causes flex columns to fill height
  ...rest
}) {
  if (!slug && !rinknetId)
    return (
      <Row justifyContent={justifyContent} columnGap={1} style={{ height }}>
        {!!teamNhlid && <img height="20px" alt="" src={getNHLTeamLogoUrl(teamNhlid)} />}
        <PlayerName variant={variant} color={color} style={style} name={name} {...rest} />
      </Row>
    );
  return (
    <Row justifyContent={justifyContent} style={{ height }}>
      <PlayerHover
        playerSlug={slug}
        rinknetId={rinknetId}
        disabled={!allowHover}
        {...playerHoverProps}
      >
        <Link to={buildPlayerRoute(slug)} onClick={(ev) => ev.stopPropagation()}>
          <Row columnGap={1}>
            {!!teamNhlid && (
              <img height="20px" alt="" src={getNHLTeamLogoUrl(teamNhlid)} />
            )}
            <PlayerName
              variant={variant}
              color={color}
              style={style}
              name={name}
              {...rest}
            />
          </Row>
        </Link>
      </PlayerHover>
    </Row>
  );
}
