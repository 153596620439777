import { GET_RINKNET_GAMES_BY_TEAM } from "apollo/queries/game.queries"
import { useSelect } from "."
import { useQuery } from "@apollo/client"
import { formatDate } from "helpers/helpers"


export const useRinknetGamesSelect = ({
  initialValue,
  leagueid,
  teamid,
  season,
  isMulti=false,
  selectProps={},
  onChange
}) => {
  const { data, loading, error } = useQuery(GET_RINKNET_GAMES_BY_TEAM, {
    variables: { leagueid: leagueid, season, teamid: teamid }
  })

  const gameOptions = !!data ? data.allRinknetGames.edges.map(e => ({
    label: `${formatDate(e.node.date)} ${e.node.hometeam.shortname} vs ${e.node.awayteam.shortname}`, 
    value: e.node.id
  })) : []

  const { select, selected, selectedValue } = useSelect({
    options: gameOptions || [],
    initialSelectedValue: initialValue,
    selectProps: {
      isLoading: loading,
      isMulti,
      placeholder: 'Filter games...',
      isSearchable: true,
      ...selectProps
    },
    onChange
  })

  return {
    select,
    selectedValue,
    selected,
    gameOptions
  }
}