import styled from 'styled-components';
import { Timeline, TimelineItem } from 'components/bdd/Timeline';
import { Column, Container, Row } from 'components/bdd/Layout';
import { noteTypeToIcon } from 'components/PlayerNotes/helpers';
import { NoteCommentForm, useNotes } from '.';

const NotesContainer = styled.div({
  overflow: 'auto',
  height: 'calc(100vh - 400px)',
});

export const Notes = ({
  entityId,
  entityType,
  entityNameCallback,
  noteTypes,
  hidePinToggle,
}) => {
  const { noteItems, placeholder, createNote } = useNotes({
    entityIds: [entityId],
    entityType,
  });

  let filteredNoteItems = noteItems?.filter((pn) =>
    noteTypes?.length > 0 ? noteTypes.includes(pn.note.noteType) : true
  );

  const items = filteredNoteItems
    ?.sort((a, b) => new Date(b.note.dateCreated) - new Date(a.note.dateCreated))
    .map((pn, index) => {
      const entityName = entityNameCallback(pn.note);

      return (
        <TimelineItem
          key={`ti-${pn.note.id}`}
          icon={noteTypeToIcon(pn.note)}
          item={pn.render({ ...pn.note, entityName })}
          lastItem={index == filteredNoteItems.length - 1}
        />
      );
    });
    
  return (
    <Container paddingTop={3}>
      <Column gap={2}>
        <NotesContainer>
          {items ? <Timeline items={items} /> : placeholder}
        </NotesContainer>
        <NoteCommentForm
          disableAutoFocus
          hidePinToggle={hidePinToggle}
          entityId={entityId}
          entityType={entityType}
          onSubmit={(values) => {
            createNote({ variables: values });
          }}
        />
      </Column>
    </Container>
  );
};
