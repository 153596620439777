import { LinkButton } from "components/bdd/Button"
import { Container } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { useFeedbackModal } from "../hooks/useFeedbackModal";
import { theme } from "constants";


export const FeedbackPrompt = ({
  renderPrompt,
  promptText='Add Feedback',
  defaultValues={}
}) => {
  const { renderModal } = useFeedbackModal({ defaultValues }); 
  if (!renderModal) return null;
  return (
    <Container>
      {!!renderPrompt 
        ? renderPrompt()
        : (
          <LinkButton onClick={() => renderModal()} style={{ color: theme.colors.states.link}}>
            <Typography variant='body2'>{promptText}</Typography>
          </LinkButton>
        )}
    </Container>
  )
}