import { LinkButton, NoColorLink } from 'components/bdd/Button';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { TEAM_ROUTE } from 'constants';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const TeamLink = React.memo(
  ({ nhlid, slTeamSlug, noLink, abbreviation, logoSize = 20 }) => {
    return (
      <Container>
        <Row>
          {nhlid && <img alt="" height={logoSize} src={getNHLTeamLogoUrl(nhlid)} />}
          {slTeamSlug && !noLink ? (
            <LinkButton as={Link} to={`${TEAM_ROUTE}/${slTeamSlug}`}>
              <Typography variant="body2">{abbreviation}</Typography>
            </LinkButton>
          ) : (
            <Typography variant="subtitle2">{abbreviation}</Typography>
          )}
        </Row>
      </Container>
    );
  }
);
