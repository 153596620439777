import { Button } from 'react-bootstrap';
import { Column, Container, Row } from '../Layout';
import { Typography } from '../Typography';
import useModal from './useModal';
import { useContext } from 'react';
import { ModalContext } from '.';
import { LinkPopover } from '../Popover';

export const getArchiveDisclaimer = (entityName) =>
  `Are you sure you want to archive this ${entityName}? This will not permanently delete the ${entityName}, but only a developer will be able to undo this action.`;

export const useArchiveModal = ({ entityName, onArchive, onArchiveForAll }) => {
  const { renderModal, hideModal } = useContext(ModalContext);

  const body = (
    <Container padding={3}>
      <Column gap={2}>
        <Typography variant="subtitle2">{getArchiveDisclaimer(entityName)}</Typography>
        <Row columnGap={2} justifyContent="end">
          <Button size="sm" variant="outline-dark" type="button" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => {
              onArchive && onArchive();
              hideModal();
            }}
          >
            Archive
          </Button>
          {onArchiveForAll && (
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => {
                onArchiveForAll && onArchiveForAll();
                hideModal();
              }}
            >
              Archive For All
            </Button>
          )}
        </Row>
      </Column>
    </Container>
  );

  const renderArchiveModal = () =>
    renderModal({
      title: <Typography variant="subtitle1">Confirm</Typography>,
      body,
    });

  return {
    renderArchiveModal,
  };
};
