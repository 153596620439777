import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import styled from 'styled-components';

const SelectedContainer = styled(Container)({
  borderBottom: (props) =>
    props.selected ? `1px solid ${theme.colors.teams.bos.primary}` : '1px solid #333',
  boxShadow: (props) =>
    props.selected ? `inset 1px 1px 5px ${theme.colors.teams.bos.primary}` : null,
});

export const FullGamePeriod = ({ selected, period, onClick }) => {
  return (
    <SelectedContainer padding={3} variant="button" selected={selected} onClick={onClick}>
      <Typography variant="body1">
        {period <= 2 && `Period #${period + 1}`}
        {period == 3 && `Overtime`}
        {period > 3 && `Overtime #${period - 2}`}
      </Typography>
    </SelectedContainer>
  );
};
