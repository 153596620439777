import { useMemo } from 'react';
import { useQueryWithPlaceholder } from 'apollo/hooks';
import { GET_UPLOAD } from 'apollo/queries/upload.queries';

// const DEFAULT_COMBINE_NOTES_UPLOAD_SLUG = 'combineDraftNotes2022';


export const useCombineNotesUploadData = ({ skip, draftYear='2023' }) => {
  const uploadSlug = `combineDraftNotes${draftYear}`
  const { data, placeholder } = useQueryWithPlaceholder(
    GET_UPLOAD,
    {
      slug: uploadSlug,
    },
    {
      variant: 'squares-sm',
      nocenter: true,
    },
    skip
  );

  const playerToCombineNotes = useMemo(() => {
    const ret = {};
    if (!!data && !!data.upload) {
      data.upload.uploadData.forEach((d) => {
        const parsed = JSON.parse(d.data);
        ret[parseInt(parsed['rinknet_id'])] = parsed;
      });
    }
    return ret;
  }, [data]);

  return { data, placeholder, playerToCombineNotes };
};
