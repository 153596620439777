import React, { useRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_PLAYER_CAREER_EP_DATA,
} from 'apollo/queries/players.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GET_PLAYER_CAREER_NHL_DATA } from 'apollo/queries/nhl.queries';
import { strContains } from 'helpers/data';

const getLeaguesWithGamesPlayed = (stats) => {
  if (!stats) {
    return null;
  }

  const gamesPlayed = stats.reduce((gamesPlayed, s) => {
    const existing = gamesPlayed.find((gp) => gp.leagueName == s.leagueAbbrev);

    if (!existing) {
      gamesPlayed.push({
        leagueName: s.leagueAbbrev,
        gamesPlayed: s.gamesPlayed,
      });
    } else {
      existing.gamesPlayed += s.gamesPlayed;
    }

    return gamesPlayed;
  }, []);

  return gamesPlayed.sort((a, b) => b.gamesPlayed - a.gamesPlayed);
};

export const useCareerStats = ({ slug, epId, gameType, onlyNHL, useNHL }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_CAREER_NHL_DATA, {
      variables: { slug: slug },
      skip: !slug && !!epId,
    })
  );

  const epIdToQuery = epId || data?.bddPlayer.rinknetPlayer.eliteprospectsid;
  const { data: epData, placeholder: epPlaceholder } = usePlaceholder(
    useQuery(GET_PLAYER_CAREER_EP_DATA, {
      variables: {
        playerPath: `players/${epIdToQuery}`,
        statsPath: `players/${epIdToQuery}/stats`,
      },
      skip: !epId || (data?.bddPlayer.slPlayerMaster?.careerNhlData && !!useNHL)
    })
  );

  const nhlData = data?.bddPlayer.nhlPlayerStats;

  const playerCareerData = useMemo(() => {
    let stats;
    let nhlCareerTotals;
    let isGoalie;

    if (!!nhlData && !!useNHL) {
      isGoalie = data?.bddPlayer.rinknetPlayer.position == 'Goalie';
      stats = nhlData.stats.filter((s) => gameType == 'regular' ? s.gameTypeId == 2 : s.gameTypeId == 3);
      nhlCareerTotals = nhlData.nhlCareerTotals;

      if (onlyNHL) {
        stats = stats.filter((s) => s.leagueAbbrev == 'NHL');
      }
    } else if (epData && !strContains(epData.player, "Provided Api Key is expired")) {
      const epPlayer = JSON.parse(epData.player).data;
      const epPlayerStats = JSON.parse(epData.stats).data;

      isGoalie = epPlayer.position == 'G';

      stats = epPlayerStats.reduce((s, eps) => {
        const statsToUse =
          gameType == 'regular' ? eps.regularStats : eps.postseasonStats;

        if (!statsToUse) return s;

        s.push({
          ...eps,
          season: eps.season.slug.split('-').join(''),
          gamesPlayed: statsToUse.GP,
          goals: statsToUse.G,
          assists: statsToUse.A,
          points: statsToUse.PTS,
          pim: statsToUse.PIM,
          goalsAgainstAvg: statsToUse.GAA,
          savePctg: statsToUse.SVP / 100,
          wins: statsToUse.W,
          losses: statsToUse.L,
          otLosses: statsToUse.T,
          saves: statsToUse.SVS,
          shotsAgainst: statsToUse.SA,
          goalsAgainst: statsToUse.GA,
          gamesStarted: statsToUse.W + statsToUse.L + statsToUse.T,
          timeOnIce: statsToUse.TOI
        });

        return s;
      }, []);
    }

    const leaguesWithGamesPlayed = stats && getLeaguesWithGamesPlayed(stats);

    return { stats, nhlCareerTotals, isGoalie, leaguesWithGamesPlayed };

  }, [nhlData, epData, gameType, onlyNHL, useNHL]);

  return {
    nhlData,
    ...playerCareerData,
    placeholder: placeholder || epPlaceholder,
  };
};
