import { heightInchesToStr } from 'helpers/hockeyutils';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useQuery } from '@apollo/client';
import { Divider } from 'components/bdd/Divider';
import {
  booleanOptions,
  clauseDetailOptions,
  contractTypeOptions,
  expiryStatusOptions,
  expiryYearSeasonOptions,
  generalFilterOptions,
  generalSeasonOptions,
  handednessOptions,
  playerStatusOptions,
  positionOptions,
  seasonOptions,
  seasonPhaseOptions,
  signingStatusOptions,
} from './constants';
import { Filters } from 'components/bdd/Filters';
import { useContractSearchContext } from './context';
import { formatDollars } from 'helpers/contracts';
import { FiltersButton } from './FiltersButton';
import { ModalContextProvider } from 'components/bdd/Modal';
import { StatQuery } from './StatQuery';
import { GET_BF_TEAMS } from 'apollo/queries/bruinsfriendly.queries';

export const ContractSearchFilters = ({ children, hideTitle, showRecent }) => {
  const filters = useContractSearchContext((state) => state.filters);
  const { setFilters } = useContractSearchContext((state) => state.actions);

  return (
    <Filters defaults={filters} onChange={setFilters} updateOnDefaultsChange>
      <ModalContextProvider>
        <FiltersButton hideTitle={hideTitle} showRecent={showRecent}>
          <ContractSearchFiltersBody />
          {children}
        </FiltersButton>
      </ModalContextProvider>
    </Filters>
  );
};

export const ContractSearchFiltersBody = ({}) => {
  return (
    <Column gap={2}>
      <Filters.Group name="General Filters">
        <Container padding={2}>
          <Row columnGap={2} rowGap={2}>
            <Filters.Select
              id={'browse'}
              label="Browse By"
              options={generalFilterOptions}
              width={150}
            />
            <Filters.Select
              id={'season'}
              label="Season"
              options={generalSeasonOptions}
              width={100}
            />
          </Row>
        </Container>
      </Filters.Group>
      <Divider />
      <Filters.Group name="Player Filters">
        <Container padding={2}>
          <Column gap={2}>
            <Row columnGap={2} rowGap={2} flexWrap>
              <TeamFilter id="team_slug" label="Current Team" />
              <Filters.Select
                id={'status'}
                label="Status"
                options={playerStatusOptions}
                width={150}
                isClearable
              />
              <Filters.Select
                id={'shoots_catches'}
                label="Handedness"
                options={handednessOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'position'}
                label="Position"
                options={positionOptions}
                width={100}
              />
              <Filters.Select
                id={'is_expiry_status_arbitration_eligible'}
                label="Arbitration Eligible"
                options={booleanOptions}
                width={100}
                isClearable
              />
            </Row>
            <Container>
              <Row columnGap={8} flexWrap>
                <Filters.InputRange
                  id={'age'}
                  label="Current Age"
                  scale={{ min: 16, max: 47 }}
                  unit={'yrs'}
                />
                <Filters.InputRange
                  id={'height_inches'}
                  label="Height"
                  scale={{ min: 66, max: 84 }}
                  unit={'ft'}
                  formatLabel={(value) => heightInchesToStr(value)}
                />
                <Filters.InputRange
                  id={'weight'}
                  label="Weight"
                  scale={{ min: 140, max: 300 }}
                  unit={'lbs'}
                />
                <Filters.InputRange
                  id={'round'}
                  label="Draft Pick Round"
                  scale={{ min: 1, max: 9 }}
                />
                <Filters.InputRange
                  id={'overall'}
                  label="Draft Pick Overall"
                  scale={{ min: 1, max: 300 }}
                />
              </Row>
            </Container>
          </Column>
        </Container>
      </Filters.Group>
      <Divider />
      <Filters.Group name="Contract Filters">
        <Container>
          <Column gap={2}>
            <Row columnGap={2} rowGap={2} flexWrap>
              <TeamFilter id="signing_team_slug" label="Signing Team" />
              <Filters.Date id={'min_signing_date'} label={'Signed After'} isClearable />
              <Filters.Date id={'max_signing_date'} label={'Signed Before'} isClearable />
              <Filters.Select
                id={'season_phase'}
                label="Season Phase"
                options={seasonPhaseOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'contract_type'}
                label="Contract Type"
                options={contractTypeOptions}
                width={100}
                isClearable
              />
              <Filters.SelectAndRange
                id={'start_season'}
                label="Start Season"
                options={seasonOptions}
                width={300}
                isClearable
                isReverse
                renderRangeTagLabel={(options, { min, max }) => {
                  const minLabel = options.find((o) => o.value == min).label;
                  const maxLabel = options.find((o) => o.value == max).label;

                  return `${minLabel.replace('-', '')}-${maxLabel.replace('-', '')}`;
                }}
              />
              <Filters.SelectAndRange
                id={'end_season'}
                label="End Season"
                options={expiryYearSeasonOptions}
                width={300}
                isClearable
                isReverse
                renderRangeTagLabel={(options, { min, max }) => {
                  const minLabel = options.find((o) => o.value == min).label;
                  const maxLabel = options.find((o) => o.value == max).label;

                  return `${minLabel.replace('-', '')}-${maxLabel.replace('-', '')}`;
                }}
              />
              <Filters.Select
                id={'platform_season'}
                label="Platform Season"
                options={seasonOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'signing_status'}
                label="Signing Status"
                options={signingStatusOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'expiry_status'}
                label="Expiry Status"
                options={expiryStatusOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'ntc'}
                label="NTC"
                options={clauseDetailOptions}
                width={100}
              />
              <Filters.Select
                id={'nmc'}
                label="NMC"
                options={clauseDetailOptions}
                width={100}
              />
              <Filters.Select
                id={'is_extension'}
                label="Is Extension"
                options={booleanOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'arbitration_requested'}
                label="Arb. Requested"
                options={booleanOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'is_two_way'}
                label="Two-way Contract"
                options={booleanOptions}
                width={100}
                isClearable
              />
              <Filters.Select
                id={'has_european_clause'}
                label="European Assign Clause"
                options={booleanOptions}
                width={150}
                isClearable
              />
            </Row>
            <Container>
              <Row columnGap={8} flexWrap alignItems="start">
                <Filters.InputRange
                  id={'signing_age'}
                  label="Signing Age"
                  scale={{ min: 16, max: 47 }}
                  unit={'yrs'}
                  includeInputBoxes
                />
                <Filters.InputRange
                  id={'num_years'}
                  label="Length"
                  scale={{ min: 1, max: 15 }}
                  unit={'yrs'}
                  includeInputBoxes
                />
                <Filters.InputRange
                  id={'cap_hit'}
                  label="Cap Hit"
                  scale={{ min: 0, max: 20000000 }}
                  formatLabel={(value) => formatDollars(value)}
                  includeInputBoxes
                />
                <Filters.InputRange
                  id={'signing_bonus'}
                  label="Signing Bonus"
                  scale={{ min: 0, max: 15000000 }}
                  formatLabel={(value) => formatDollars(value)}
                  includeInputBoxes
                />
                <Filters.InputRange
                  id={'performance_bonus'}
                  label="Perf. Bonus"
                  scale={{ min: 0, max: 4000000 }}
                  formatLabel={(value) => formatDollars(value)}
                  includeInputBoxes
                />
              </Row>
            </Container>
          </Column>
        </Container>
      </Filters.Group>
      <Divider />
      <StatQuery />
      <Divider />
    </Column>
  );
};

export const TeamFilter = ({ id, label, ...filterProps }) => {
  const { data, loading } = useQuery(GET_BF_TEAMS, {
    variables: { activeOnly: true },
  });

  const options = data?.bfTeams.map((t) => ({
    value: t.slug,
    label: `${t.name}`,
  }));

  return (
    <Filters.Select
      id={id}
      label={label}
      options={options || []}
      selectProps={{
        isLoading: loading,
      }}
      width={150}
      isClearable
      {...filterProps}
    />
  );
};
