import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { getDateRangeModeOptions } from 'helpers/daterangemode';
import { getStrengthChoices } from 'helpers/filters';
import { useContext, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Funnel } from 'react-bootstrap-icons';
import { SLStatContext } from '..';

export const FilterButton = ({ children }) => {
  const { filters } = useContext(SLStatContext);
  const dateRangeModeOptions = getDateRangeModeOptions({
    includeWeeks: true,
    includeCurrentSeason: true,
    includeRecentDays: true,
    includeCustom: true,
  });

  const strengthOptions = getStrengthChoices(true);

  const filtersToDisplay = [
    {
      label: 'League',
      values:
        'league' in filters &&
        (filters['league'] instanceof Array
          ? filters['league']
          : [filters['league']]),
    },
    {
      label: 'Strength',
      values: 'strength' in filters && [
        strengthOptions.find((o) => o.value == filters['strength']).label,
      ],
    },
    {
      label: 'Date Range',
      values: 'dateRangeMode' in filters && [
        dateRangeModeOptions.find((o) => o.value == filters['dateRangeMode'])
          .label,
      ],
    },
    {
      label: 'Norm',
      values: 'norm' in filters && [filters['norm']],
    },
  ].filter((f) => !!f.values);

  return (
    <Row columnGap={3}>
      <Dropdown>
        <Dropdown.Toggle as={Button} variant="outline-dark" size="sm">
          <Funnel />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Container padding={3} onClick={(e) => e.stopPropagation()}>
            <Row columnGap={3} flexWrap>{children}</Row>
          </Container>
        </Dropdown.Menu>
      </Dropdown>
      {filtersToDisplay &&
        filtersToDisplay.map((df) => (
          <Container key={`df-${df.label}`}>
            <Row columnGap={1}>
              <Typography variant="stat">
                <b>{df.label}:</b>
              </Typography>
              {df.values.map((dfv) => (
                <Border key={`dfv-${dfv}`}>
                  <Container
                    backgroundColor={theme.colors.light.secondary}
                    padding={1}
                  >
                    <Typography variant="stat">{dfv}</Typography>
                  </Container>
                </Border>
              ))}
            </Row>
          </Container>
        ))}
    </Row>
  );
};
