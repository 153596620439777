import { useEffect, useRef, useState } from 'react';
import useOnScreen from './useonscreen';

export const useOnScreenRef = ({ resetVariable, defaultValue, rootMargin = '0px' } = {}) => {
  const ref = useRef(null);

  const [hasBeenVisible, setHasBeenVisible] = useState(defaultValue);
  const isVisible = useOnScreen(ref, defaultValue, resetVariable, rootMargin);

  useEffect(() => {
    if (isVisible && !hasBeenVisible) setHasBeenVisible(true);
  }, [isVisible]);

  useEffect(() => {
    setHasBeenVisible(isVisible);
  }, [resetVariable]);

  return {
    ref,
    isVisible, // true if currently visible
    hasBeenVisible, // true if ref has been on screen at all
  };
};
