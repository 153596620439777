import { gql } from '@apollo/client';
import { BFPlayerContractFragment } from './contract.queries';
import { PageInfoFragment } from './pagination.queries';

export const BFPlayerFragment = gql`
  fragment bfPlayerFragment on BFPlayers {
    slug
    nhlid
    firstName
    lastName
    birthdate
    position
    positionFdg
    teamSlug
    height
    heightInches
    weight
    status
    isActive
    isBuried
    acquired
    noMoveTrade
    acquiredDate
    waiversRequired
    waiverExempt
    waiversExemptGamesRemaining
    age
    freeAgentYear
    expiryStatus
    expiryStatusDetails
    ageElcFa
    ageWaivers
    mostRecentTeamSlug
    mostRecentFreeAgentYear
    mostRecentExpiryStatus
    mostRecentExpiryStatusDetails
    bfDraftPick {
      round
      overall
    }
  }
`;

export const BFPlayerBriefFragment = gql`
  fragment bfPlayerBriefFragment on BFPlayers {
    slug
    nhlid
    firstName
    lastName
    birthdate
    position
    positionFdg
    teamSlug
    height
    heightInches
    weight
    status
    isActive
    isBuried
    acquired
    noMoveTrade
    acquiredDate
    waiversRequired
    waiverExempt
    waiversExemptGamesRemaining
    age
    freeAgentYear
    mostRecentTeamSlug
    mostRecentFreeAgentYear
    mostRecentExpiryStatus
    mostRecentExpiryStatusDetails
    freeAgentSeason {
      season
      expiryStatus
      expiryStatusDetails
    }
    expiryStatus
    expiryStatusDetails
    ageElcFa
    ageWaivers
  }
`;

export const BFPlayerContractSeasonFragment = gql`
  fragment bfPlayerContractSeasonsFragment on BFPlayerContractSeasons {
    contractId
    season
    contractYear
    capHit
    capHitStr
    value
    performanceBonus
    signingBonus
    baseSalary
    totalSalary
    minorSalary
    guaranteedSalary
    ntc
    nmc
    clauseDetails
    capPct
    isTwoWay
  }
`;

export const BFTeamFragment = gql`
  fragment BFTeamFragment on BFTeams {
    slug
    name
    isActive
    abbreviation
    nhlid
  }
`;

export const BFPlayerInjuryHistoryFragment = gql`
  fragment bfPlayerInjuryHistoryFragment on BFPlayerInjuryHistory {
    playerSlug
    startDate
    endDate
    expectedDuration
    actualDuration
    teamSlug
    category
    details
    lastUpdateDate
  }
`;

export const BFSalaryCapFragment = gql`
  fragment bfSalaryCapFragment on BFSalaryCaps {
    season
    upperLimit
    lowerLimit
    minSalary
    maxBuried
    changePct
    confirmed
    startDate
    endDate
    numDays
    payload
  }
`;

export const BFDraftPickFragment = gql`
  fragment bfDraftPickFragment on BFDraftPicks {
    id
    year
    overall
    round
    originalTeamSlug
    currentTeamSlug
    playerSlug
    isConditional
    conditionalDetails
    additionalDetails
    originalTeam {
      id
      slug
      name
      isActive
      abbreviation
      nhlid
    }
    currentTeam {
      id
      slug
      name
      isActive
      abbreviation
      nhlid
    }
    player {
      slug
      nhlid
      firstName
      lastName
      birthdate
      position
      teamSlug
      height
      weight
      status
      isActive
    }
  }
`;

export const BFCapHitFragment = gql`
  fragment bfCapHitFragment on BFPlayerCapHits {
    id
    playerSlug
    season
    effectiveCapHit
    buriedCapHit
    dailyCapHit
    accumulatedCapHit
    projectedCapHit
    initialCapHit
    retainedCapHit
    finalCapHit
    retainedPct
    retainedCapHit
    retainedDetails
    soirCapHit
    hasPastContract
    hasActiveContract
    hasFutureContract
    currentAav
    currentCapHit
    currentRemainingTerm
    expiryStatus
    expiryStatusDetails
    futureCapHit
    futureRemainingTerm
    daysMajors
    daysIr
    daysLtir
    daysSoltir
    daysConditioning
    daysEmergency
    daysWaivers
    daysMinors
    daysJunior
    daysLoan
    daysSuspended
    contractExcluded
  }
`;

export const BFTransactionsFragment = gql`
  fragment bfTransactionsFragment on BFTransactions {
    id
    date
    teamSlug
    playerSlug
    bfPlayer {
      ...bfPlayerFragment
      bddPlayer {
        slug
      }
    }
    bfTeam {
      ...BFTeamFragment
    }
    fromBfTeam {
      ...BFTeamFragment
    }
    category
    details
    rightsTransferred
    fromTeamSlug
    tradeId
    contractId
    injuryId
  }
`;

export const BFTradesFragment = gql`
  fragment bfTradesFragment on BFTrades {
    id
    date
    season
    numTeams
    updatedAt
    nhlDailyTransactions {
      id
    }
    teamSummaries {
      tradeId
      teamSlug
      bfTeam {
        ...BFTeamFragment
        bddTeam {
          slTeamSlug
        }
      }
      numPlayersIn
      numPlayersOut
      numPicksIn
      numPicksOut
      capHitIn
      capHitOut
      capHitChange
      capHitAffectingIn
      capHitAffectingOut
      capHitAffectingChange
      salaryIn
      salaryOut
      salaryChange
      isFutureConsiderations
      isExpansionDraftConsiderations
      details
    }
    tradedPlayers {
      tradeId
      playerSlug
      bfPlayer {
        ...bfPlayerFragment
        bddPlayer {
          slug
        }
      }
      bfPlayerContract {
        id
        startSeason
        endSeason
        aav
        capHit
      }
      toTeamSlug
      fromTeamSlug
      toBfTeam {
        ...BFTeamFragment
      }
      fromBfTeam {
        ...BFTeamFragment
      }
      capHit
      capHitAffecting
      salary
      retainedSalary
      retainedSalaryPct
      isMinor
      signingRights
      isLayover
    }
    tradedDraftPicks {
      tradeId
      toTeamSlug
      fromTeamSlug
      originalTeamSlug
      toBfTeam {
        ...BFTeamFragment
      }
      fromBfTeam {
        ...BFTeamFragment
      }
      originalBfTeam {
        ...BFTeamFragment
      }
      pickId
      year
      round
      isConditional
      conditionalDetails
      bfDraftPick {
        ...bfDraftPickFragment
      }
    }
  }
`;

export const GET_BF_TEAMS = gql`
  query GetBFTeams($activeOnly: Boolean) {
    bfTeams(activeOnly: $activeOnly) {
      ...BFTeamFragment
    }
  }
  ${BFTeamFragment}
`;

export const buildPaginatedBFPlayerQuery = ({
  loadBddPlayerSlugs,
  loadTeams,
  loadContracts,
  loadStats,
  loadLineupSpots,
}) => gql`query GetPaginatedBFPlayers(
  $limit: Int,
  $offset: Int,
  $sort: [BDDSortV2],
  $statQ: GenericScalar,
  $playerFilters: GenericScalar,
  $lineupSpotFilters: LineupSpotFilters
) {
  paginatedBfPlayers(
    limit: $limit,
    offset: $offset,
    sort: $sort,
    statQ: $statQ,
    playerFilters: $playerFilters,
    lineupSpotFilters: $lineupSpotFilters,
    loadBddPlayerSlugs: ${!!loadBddPlayerSlugs}, 
    loadTeams: ${!!loadTeams},
    loadContracts: ${!!loadContracts}
    loadLineupSpots: ${!!loadLineupSpots}) {
      pageInfo {
        ...pageInfoFragment
      }
      ${!!loadStats ? `extraData` : ''}
      data {
        ...bfPlayerFragment
        ${loadBddPlayerSlugs ? `bddPlayer { slug }` : ''}
        ${loadTeams ? `bfTeam { slug, nhlid, abbreviation }` : ''}
        ${
          loadContracts
            ? `
              currentCapHit {
                ...bfCapHitFragment
              }`
            : ''
        }
        ${!!loadStats ? `stats` : ''}
      ${
        !!loadLineupSpots
          ? `lineupSpotStats {
          count
          lineNum
          position
        }`
          : ''
      }
    }
  }
} 
${BFPlayerFragment}
${PageInfoFragment}
${loadContracts ? BFCapHitFragment : ''}`;

export const buildPaginatedBFPlayerContractQuery = ({}) => gql`
  query GetPaginatedBFPlayerContracts(
    $limit: Int
    $offset: Int
    $timeRange: GenericScalar!
    $platformPriorSeasons: Int
    $filters: GenericScalar
    $statFilters: GenericScalar
    $customSlQueryFilters: GenericScalar
    $sort: [BDDSortV2]
  ) {
    paginatedBfPlayerContracts(
      limit: $limit
      offset: $offset
      timeRange: $timeRange
      platformPriorSeasons: $platformPriorSeasons
      filters: $filters
      statFilters: $statFilters
      customSlQueryFilters: $customSlQueryFilters
      sort: $sort
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      extraData
      data {
        ...bfPlayerContractFragment
        bfPlayer {
          age
          position
          positionFdg
          weight
          height
          firstName
          lastName
          bfDraftPick {
            round
            overall
          }
          bddPlayer {
            slug
          }
        }
        signingTeam {
          nhlid
          abbreviation
          bddTeam {
            slTeamSlug
          }
        }
      }
    }
  }
  ${PageInfoFragment}
  ${BFPlayerContractFragment}
`;

export const SEARCH_BF_CONTRACTS = gql`
  query SearchBFContract($searchTerm: String!) {
    searchBfPlayerContracts(searchTerm: $searchTerm) {
      id
      aav
      numYears
      startSeason
      contractType
      isActive
      bfPlayer {
        ...bfPlayerFragment
      }
      signingTeam {
        nhlid
        abbreviation
        bddTeam {
          slTeamSlug
        }
      }
    }
  }
  ${BFPlayerFragment}
`;

export const GET_BF_CONTRACT = gql`
  query GetBFContract($id: ID, $bddPlayerSlug: ID) {
    bfContract(id: $id, bddPlayerSlug: $bddPlayerSlug) {
      ...bfPlayerContractFragment
      bfPlayer {
        ...bfPlayerFragment
        bddPlayer {
          slug
        }
      }
    }
  }
  ${BFPlayerFragment}
  ${BFPlayerContractFragment}
`;

export const GET_BF_SIMILAR_CONTRACTS = gql`
  query GetSimilarContracts(
    $timeRange: GenericScalar
    $filters: GenericScalar
    $statFilters: GenericScalar
    $targetVector: GenericScalar
    $numericalWeights: GenericScalar
    $categoricalWeights: GenericScalar
    $statMode: String
    $nNeighbours: Int
  ) {
    bfSimilarContracts(
      timeRange: $timeRange
      filters: $filters
      statFilters: $statFilters
      targetVector: $targetVector
      numericalWeights: $numericalWeights
      categoricalWeights: $categoricalWeights
      nNeighbours: $nNeighbours
      statMode: $statMode
    ) {
      distances
      targetVectors
      contracts {
        id
        bfPlayer {
          slug
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_BF_SIMILAR_TRADES = gql`
  query GetSimilarTrades(
    $timeRange: GenericScalar
    $filters: GenericScalar
    $statFilters: GenericScalar
    $targetVector: GenericScalar
    $numericalWeights: GenericScalar
    $categoricalWeights: GenericScalar
    $statMode: String
    $nNeighbours: Int
  ) {
    bfSimilarTrades(
      timeRange: $timeRange
      filters: $filters
      statFilters: $statFilters
      targetVector: $targetVector
      numericalWeights: $numericalWeights
      categoricalWeights: $categoricalWeights
      nNeighbours: $nNeighbours
      statMode: $statMode
    ) {
      distances
      targetVectors
      similarTrades {
        ...bfTradesFragment
      }
    }
  }
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
`;

export const GET_BF_DRAFT_CAPITAL_FOR_TEAM = gql`
  query GetBFDraftCapitalForTeam($teamSlug: ID, $bfTeamSlug: ID, $years: [Int]) {
    bfDraftCapitalForTeam(teamSlug: $teamSlug, bfTeamSlug: $bfTeamSlug, years: $years) {
      bfTeam {
        ...BFTeamFragment
      }
      picks {
        year
        currentPicks {
          ...bfDraftPickFragment
        }
        tradedPicks {
          ...bfDraftPickFragment
        }
      }
    }
  }
  ${BFDraftPickFragment}
  ${BFTeamFragment}
`;

export const GET_BF_DRAFT_PICKS_FOR_YEAR = gql`
  query GetBFDraftPicksForYear($year: Int!) {
    bfDraftPicksForYear(year: $year) {
      ...bfDraftPickFragment
      player {
        bddPlayer {
          slug
        }
      }
      trades {
        id
      }
    }
  }
  ${BFDraftPickFragment}
`;

export const GET_BF_DRAFT_PICKS = gql`
  query GetBFDraftPicks($ids: [ID]) {
    bfDraftPicks(ids: $ids) {
      ...bfDraftPickFragment
    }
  }
  ${BFDraftPickFragment}
`;

export const GET_BF_PLAYER_AND_CONTRACTS = gql`
  query GetBFPlayerAndContracts($bfPlayerSlug: ID!) {
    bfPlayer(bfPlayerSlug: $bfPlayerSlug) {
      ...bfPlayerFragment
      contracts {
        ...bfPlayerContractFragment
      }
      currentCapHit {
        ...bfCapHitFragment
      }
      bfTeam {
        ...BFTeamFragment
      }
    }
  }
  ${BFPlayerFragment}
  ${BFTeamFragment}
  ${BFPlayerContractFragment}
  ${BFCapHitFragment}
`;

export const BFTeamSeasonSummaryFragment = gql`
  fragment bfTeamSeasonSummaryFragment on BFTeamSeasonSummaries {
    teamSlug
    season
    currentCapHit
    rosterCapHit
    accumulatedSalary
    currentCapSpace
    accruedCapSpace
    projectedRemainingCapHit
    projectedCapHit
    projectedCapSpace
    projectedLtirUsed
    tradeDeadlineCapSpace
    upperLimit

    numContracts
    rosterSize
    reserveListCount
    injuryCount

    ltiAcsl
    isInLti
    ltiStartDate
    ltirSalaryRelief
    ltirSalaryUsed
    ltirSalaryRemaining

    ltirBonusRelief
    ltirBonusUsed
    ltirBonusRemaining

    buriedCount
    buriedCapHit

    deadCount
    deadCapHit
    retentionCount
    retentionCapHit
    terminatedCount
    terminatedCapHit
    buyoutCount
    buyoutCapHit
    pastSeasonPerfBonusOverage

    pctCapForwards
    pctCapDefensemen
    pctCapGoalie
    activeForwardsCapHit
    activeDefensemenCapHit
    activeGoalieCapHit
  }
`;

export const BFTeamDeadSpaceFragment = gql`
  fragment bfTeamDeadSpaceFragment on BFTeamDeadSpace {
    id
    teamSlug
    playerSlug
    startSeason
    endSeason
    category
    seasons
    bfPlayer {
      slug
      firstName
      lastName
      status
      bddPlayer {
        slug
      }
    }
  }
`;

export const GET_BF_TEAM_SEASON_SUMMARY = gql`
  query GetBFTeamSeasonSummary($slTeamSlug: ID, $bfTeamSlug: ID, $season: Int) {
    bfTeamSeasonSummary(
      slTeamSlug: $slTeamSlug
      bfTeamSlug: $bfTeamSlug
      season: $season
    ) {
      ...bfTeamSeasonSummaryFragment
      bfTeam {
        ...BFTeamFragment
      }
    }
  }
  ${BFTeamFragment}
  ${BFTeamSeasonSummaryFragment}
`;

export const GET_BF_TEAM_SEASON_SUMMARIES = gql`
  query GetBFTeamSeasonSummaries($season: Int) {
    bfTeamSeasonSummaries(season: $season) {
      ...bfTeamSeasonSummaryFragment
      bfTeam {
        ...BFTeamFragment
        bddTeam {
          slTeamSlug
        }
      }
    }
    bfSalaryCap(season: $season) {
      ...bfSalaryCapFragment
    }
  }
  ${BFTeamFragment}
  ${BFTeamSeasonSummaryFragment}
  ${BFSalaryCapFragment}
`;

export const GET_BF_TEAM_BREAKDOWN = gql`
  query GetBFTeamBreakdown($slTeamSlug: ID, $bfTeamSlug: ID) {
    bfTeam(slTeamSlug: $slTeamSlug, bfTeamSlug: $bfTeamSlug) {
      ...BFTeamFragment
      currentSeasonSummary {
        ...bfTeamSeasonSummaryFragment
      }
      currentSeasonDeadSpace {
        ...bfTeamDeadSpaceFragment
      }
      bfPlayers {
        ...bfPlayerFragment
        bddPlayer {
          slug
        }
        freeAgentSeason {
          season
          expiryStatus
          expiryStatusDetails
        }
        activeAndFutureContracts {
          ...bfPlayerContractFragment
        }
        allContractSeasons {
          ...bfPlayerContractSeasonsFragment
        }
        currentCapHit {
          ...bfCapHitFragment
        }
      }
      recentFreeAgents {
        ...bfPlayerFragment
        bddPlayer {
          slug
        }
        freeAgentSeason {
          season
          expiryStatus
          expiryStatusDetails
        }
      }
    }
  }
  ${BFTeamFragment}
  ${BFTeamSeasonSummaryFragment}
  ${BFPlayerFragment}
  ${BFPlayerContractFragment}
  ${BFPlayerContractSeasonFragment}
  ${BFCapHitFragment}
  ${BFTeamDeadSpaceFragment}
`;

// TODO ADD DEAD CAP FRAGMENT ABOVE
export const GET_BF_PLAYER_INJURY_HISTORY = gql`
  query GetBFPlayerInjuryHistory($bfPlayerSlug: ID!) {
    bfPlayerInjuryHistory(bfPlayerSlug: $bfPlayerSlug) {
      ...bfPlayerInjuryHistoryFragment
      bfPlayer {
        ...bfPlayerFragment
      }
      bfTeam {
        ...BFTeamFragment
      }
      lastUpdate {
        id
        date
        teamSlug
        playerSlug
        category
        details
        expectedDuration
        isUpdate
      }
    }
  }
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFPlayerInjuryHistoryFragment}
`;

export const GET_PAGINATED_BF_TRANSACTIONS = gql`
  query GetPaginatedBFTransactions(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $types: [BFTransactionTypes]
    $includesBfPlayerSlugs: [ID]
    $includesBfTeamSlugs: [ID]
    $positions: [String]
  ) {
    paginatedBfTransactions(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      types: $types
      includesBfPlayerSlugs: $includesBfPlayerSlugs
      includesBfTeamSlugs: $includesBfTeamSlugs
      positions: $positions
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...bfTransactionsFragment
        bfTrade {
          ...bfTradesFragment
        }
      }
    }
  }
  ${PageInfoFragment}
  ${BFTransactionsFragment}
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
`;

export const GET_PAGINATED_BF_TRADES = gql`
  query GetPaginatedBFTrades(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $includesBfPlayerSlugs: [ID]
    $includesBfTeamSlugs: [ID]
    $seasonPhases: [String]
    $tradeType: String
    $includesRetention: Boolean
    $includesMoneyLaundering: Boolean
  ) {
    paginatedBfTrades(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      includesBfPlayerSlugs: $includesBfPlayerSlugs
      includesBfTeamSlugs: $includesBfTeamSlugs
      seasonPhases: $seasonPhases
      tradeType: $tradeType
      includesRetention: $includesRetention
      includesMoneyLaundering: $includesMoneyLaundering
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...bfTradesFragment
      }
    }
  }
  ${PageInfoFragment}
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
`;

export const GET_BF_TRADE = gql`
  query GetBFTrade($id: ID!) {
    bfTrade(id: $id) {
      ...bfTradesFragment
    }
  }
  ${BFTradesFragment}
  ${BFPlayerFragment}
  ${BFTeamFragment}
  ${BFDraftPickFragment}
`;

export const GET_PAGINATED_BF_SIGNINGS = gql`
  query GetPaginatedBFSignings(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $startDate: Date
    $endDate: Date
    $bfPlayerSlug: ID
    $bfTeamSlugs: [ID]
    $positions: [String]
    $minSigningAge: Float
    $maxSigningAge: Float
    $minAav: Float
    $maxAav: Float
    $terms: [Int]
    $signingStatus: String
    $expiryStatus: String
    $seasonPhases: [String]
    $platformLeagues: [String]
    $wasCollegeFa: Boolean
    $wasEuroFa: Boolean
    $wasChlFa: Boolean
  ) {
    paginatedBfSignings(
      limit: $limit
      offset: $offset
      sort: $sort
      startDate: $startDate
      endDate: $endDate
      bfPlayerSlug: $bfPlayerSlug
      bfTeamSlugs: $bfTeamSlugs
      positions: $positions
      minSigningAge: $minSigningAge
      maxSigningAge: $maxSigningAge
      minAav: $minAav
      maxAav: $maxAav
      terms: $terms
      signingStatus: $signingStatus
      expiryStatus: $expiryStatus
      seasonPhases: $seasonPhases
      platformLeagues: $platformLeagues
      wasCollegeFa: $wasCollegeFa
      wasEuroFa: $wasEuroFa
      wasChlFa: $wasChlFa
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...bfPlayerContractFragment
        platformStats
        bfPlayer {
          ...bfPlayerFragment
          bddPlayer {
            slug
          }
        }
      }
    }
  }
  ${PageInfoFragment}
  ${BFPlayerContractFragment}
  ${BFPlayerFragment}
`;

export const BFContractListFragment = gql`
  fragment bfContractListFragment on BFContractList {
    id
    title
    description
    isArchived
    contracts {
      contractId
      order
      payload
    }
  }
`;

export const GET_CONTRACT_LISTS = gql`
  query GetContractLists {
    bfContractLists {
      ...bfContractListFragment
    }
  }
  ${BFContractListFragment}
`;

export const CREATE_CONTRACT_LIST = gql`
  mutation CreateContractList(
    $input: BFContractListInput!
    $contractsToAdd: [BFContractListContractInput]
  ) {
    createBfContractList(input: $input, contractsToAdd: $contractsToAdd) {
      bfContractList {
        ...bfContractListFragment
      }
    }
  }
  ${BFContractListFragment}
`;

export const ARCHIVE_CONTRACT_LIST = gql`
  mutation ArchiveContractList($id: ID!) {
    archiveBfContractList(id: $id) {
      bfContractList {
        ...bfContractListFragment
      }
    }
  }
  ${BFContractListFragment}
`;

export const UPDATE_BF_CONTRACT_LIST = gql`
  mutation UpdateBFContractList(
    $input: BFContractListInput!
    $contractsToAdd: [BFContractListContractInput]
    $contractsToRemove: [BFContractListContractInput]
    $contractsToUpdate: [BFContractListContractInput]
  ) {
    updateBfContractList(
      input: $input
      contractsToAdd: $contractsToAdd
      contractsToRemove: $contractsToRemove
      contractsToUpdate: $contractsToUpdate
    ) {
      bfContractList {
        ...bfContractListFragment
      }
    }
  }
  ${BFContractListFragment}
`;

export const UPDATE_BF_CONTRACT = gql`
  mutation UpdateBDDContract($input: BFPlayerContractInput!) {
    updateBfContract(input: $input) {
      bfContract {
        id
        overridePlatformSeason
        overrideExpiryStatus
        overrideExpiryStatusDetails
      }
    }
  }
`;

export const GET_BF_SIMILAR_CONTRACT_VECTOR = gql`
  query GetTargetVector(
    $filters: GenericScalar
    $timeRange: GenericScalar
    $targetMode: String
    $statMode: String
  ) {
    bfSimilarContractTargetVector(
      filters: $filters
      timeRange: $timeRange
      targetMode: $targetMode
      statMode: $statMode
    )
  }
`;

export const GET_BF_SIMILAR_TRADE_PLAYER_VECTOR = gql`
  query GetTargetVector(
    $filters: GenericScalar
    $timeRange: GenericScalar
    $statMode: String
  ) {
    bfSimilarTradePlayerTargetVector(
      filters: $filters
      timeRange: $timeRange
      statMode: $statMode
    )
  }
`;

export const GET_BF_SALARY_CAPS = gql`
  query GetSalaryCaps {
    bfSalaryCaps {
      ...bfSalaryCapFragment
    }
  }
  ${BFSalaryCapFragment}
`;

export const BFLinkScrapeFragment = gql`
  fragment bfLinkScrapeFragment on LinkScrapes {
    id
    userId
    start
    end
    wasSuccessful
    details
    user {
      id
      name
    }
  }
`;

export const NHLDailyTransactionsFragment = gql`
  fragment nhlDailyTransactionsFragment on NHLDailyTransactions {
    id
    date
    teamAbbr
    teamNhlid
    otherTeamAbbr
    otherTeamNhlid
    playerId
    transactionType
    retroactiveDate
    pos
    firstname
    lastname
    raw
    details
    isLti
    teamSlug
    otherTeamSlug
    bfPlayer {
      slug
      nhlid
      firstName
      lastName
    }
    bfTeam {
      slug
      bddTeam {
        id
        slTeamSlug
      }
    }
    otherBfTeam {
      slug
      bddTeam {
        id
        slTeamSlug
      }
    }
    bfTradePlayer {
      id
      tradeId
    }
  }
`;
export const LinkOfficialTransactionsFragment = gql`
  fragment linkOfficialTransactionsFragment on LinkOfficialTransactions {
    id
    date
    teamAbbr
    teamNhlid
    otherTeamAbbr
    otherTeamNhlid
    playerId
    transactionType
    retroactiveDate
    pos
    firstname
    lastname
    raw
    details
    isLti
    teamSlug
    otherTeamSlug
    bfPlayer {
      slug
      nhlid
      firstName
      lastName
    }
    bfTeam {
      slug
      bddTeam {
        id
        slTeamSlug
      }
    }
    otherBfTeam {
      slug
      bddTeam {
        id
        slTeamSlug
      }
    }
  }
`;

export const NHLDailyTransactionEmailsFragment = gql`
  fragment nhlDailyTransactionEmailsFragment on NHLDailyTransactionEmails {
    id
    date
    timestamp
    messageId
    wasSuccessful
    numTransactions
  }
`;

export const GET_PAGINATED_BF_LINK_SCRAPES = gql`
  query GetPaginatedBFLinkScrapes($limit: Int, $offset: Int, $sort: [BDDSortV2]) {
    paginatedBfLinkScrapes(limit: $limit, offset: $offset, sort: $sort) {
      data {
        ...bfLinkScrapeFragment
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${BFLinkScrapeFragment}
  ${PageInfoFragment}
`;

export const buildPaginatedTransactionsQuery = ({ source = 'combined' }) => {
  const q = `
  query GetPaginatedTransactions${source.toUpperCase()}(
    $playerId: ID
    $date: Date
    $transactionTypes: [String]
    $teamSlugs: [ID]
    $otherTeamSlugs: [ID]
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
  ) {
    ${
      source === 'combined'
        ? 'paginatedCombinedTransactions'
        : source === 'official'
        ? 'paginatedLinkOfficialTransactions'
        : 'paginatedNhlDailyTransactions'
    }(
      playerId: $playerId
      date: $date
      transactionTypes: $transactionTypes
      teamSlugs: $teamSlugs
      otherTeamSlugs: $otherTeamSlugs
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      data {
        ${
          source === 'combined' || source === 'official'
            ? '...linkOfficialTransactionsFragment'
            : '...nhlDailyTransactionsFragment'
        }
        ${source === 'combined' ? 'source' : ''}
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }`;
  return gql`
    ${q}
    ${source === 'combined' || source === 'official'
      ? LinkOfficialTransactionsFragment
      : NHLDailyTransactionsFragment}
    ${PageInfoFragment}
  `;
};

export const GET_PAGINATED_NHL_DAILY_TRANSACTION_EMAILS = gql`
  query GetPaginatedNHLDailyTransactionEmails(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
  ) {
    paginatedNhlDailyTransactionEmails(limit: $limit, offset: $offset, sort: $sort) {
      data {
        ...nhlDailyTransactionEmailsFragment
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${NHLDailyTransactionEmailsFragment}
  ${PageInfoFragment}
`;

export const GET_NHL_DAILY_TRANSACTION = gql`
  query GetNHLDailyTransaction($id: ID!) {
    nhlDailyTransaction(id: $id) {
      ...nhlDailyTransactionsFragment
    }
  }
  ${NHLDailyTransactionsFragment}
`;

export const GET_PROBLEMATIC_TRANSACTIONS = gql`
  query GetProblematicTransactions {
    problematicTransactions {
      bfPlayer {
        ...bfPlayerFragment
        bddPlayer {
          slug
        }
      }
      emailTransactions {
        ...nhlDailyTransactionsFragment
      }
      officialTransactions {
        ...linkOfficialTransactionsFragment
      }
    }
  }
  ${NHLDailyTransactionsFragment}
  ${LinkOfficialTransactionsFragment}
  ${BFPlayerFragment}
`;

export const GET_BF_ADMIN_SUMMARY = gql`
  query GetBFAdminSummary($startDate: Date, $includeAccrualProblems: Boolean) {
    bfAdminSummary(startDate: $startDate, includeAccrualProblems: $includeAccrualProblems) {
      timeRange
      season
      startDate
      endDate
      numDays
      mostRecentScrape {
        ...bfLinkScrapeFragment
      }
      scrapesSummary {
        numScrapeAttempts
        numSuccessful
        avgRuntime
      }
      unscrapedTransactions {
        ...nhlDailyTransactionsFragment
      }
      transactionsSummary {
        mostRecentEmail {
          id
          date
          timestamp
          wasSuccessful
          numTransactions
        }
        mostRecentTransactionDate
        numTransactions
        numDaysWithTransactions
        numDaysWithoutTransactions
        daysWithoutTransactions
        numProblemTransactions
      }
      accrualProblems {
        bfPlayer {
          slug
          nhlid
          firstName
          lastName
          bddPlayer { slug }
        }
        playerSlug
        teamSlug
        linkSum
        bddSum
        diff
      }
    }
  }
  ${BFLinkScrapeFragment}
  ${NHLDailyTransactionsFragment}
`;

export const GET_BF_ACCRUAL_PROBLEMS = gql`
query BFAccrualProblems {
    bfAccrualProblems {
      bfPlayer {
        slug
        nhlid
        firstName
        lastName
        bddPlayer { slug }
      }
      playerSlug
      teamSlug
      linkSum
      bddSum
      diff
    }
  }
`

export const TRIGGER_BF_LINK_SCRAPE_DAG = gql`
  mutation TriggerBFLinkScrapeDag($mode: String!) {
    triggerLinkScrape(mode: $mode) {
      statusCode
      executionDate
    }
  }
`;

export const TRIGGER_SALARY_CAP_DAILY = gql`
  mutation TriggerSalaryCapDaily {
    triggerSalaryCapDaily {
      statusCode
      executionDate
    }
  }
`;

export const BFPlayerStatusFragment = gql`
  fragment bfPlayerStatusFragment on BFPlayerStatusByDate {
    playerId
    date
    teamSlug
    status
    statusAtLti
    season
    capHit
  }
`;

export const GET_BF_PLAYER_STATUSES = gql`
  query GetBFPlayerStatuses(
    $season: Int
    $startDate: Date
    $endDate: Date
    $teamSlug: ID
    $teamNhlid: ID
    $slTeamSlug: ID
    $playerIds: [ID]
  ) {
    bfPlayerStatuses(
      season: $season
      startDate: $startDate
      endDate: $endDate
      teamSlug: $teamSlug
      teamNhlid: $teamNhlid
      slTeamSlug: $slTeamSlug
      playerIds: $playerIds
    ) {
      bfPlayers {
        slug
        nhlid
        firstName
        lastName
        birthdate
        position
        teamSlug
        status
        isActive
        currentCapHit {
          dailyCapHit
          accumulatedCapHit
          finalCapHit
          daysMajors
          daysMinors
          daysIr
          daysLtir
        }
        bddPlayer {
          slug
        }
      }
      bfTeams {
        ...BFTeamFragment
      }
      dates {
        date
        statuses {
          ...bfPlayerStatusFragment
        }
      }
    }
  }
  ${BFPlayerStatusFragment}
  ${BFTeamFragment}
`;

export const GET_BF_TEAM_STATUSES = gql`
  query GetBFTeamStatuses(
    $season: Int
    $startDate: Date
    $endDate: Date
    $teamSlug: ID
    $teamNhlid: ID
    $slTeamSlug: ID
  ) {
    bfTeamStatuses(
      season: $season
      startDate: $startDate
      endDate: $endDate
      teamSlug: $teamSlug
      teamNhlid: $teamNhlid
      slTeamSlug: $slTeamSlug
    ) {
      bfTeam {
        ...BFTeamFragment
        currentSeasonSummary {
          currentCapHit
        }
        __typename
      }
      dates {
        date
        statuses {
          teamSlug
          date
          season
          capHit
          seasonCapHit
          playerCapCount
          isInLti
          ltiAcsl
          ltiUsed
          ltiCount
          seasonLtiAcsl
          seasonLtirSalaryRelief
          seasonLtirBonusRelief
          accruedCapSpace
          accruedCapSpaceOverride
        }
        __typename
      }
      __typename
    }
  }
  ${BFTeamFragment}
`;

export const GET_ALL_BF_DEAD_SPACE = gql`
  query GetAllBFDeadSpace {
    bfTeamDeadSpaces {
      ...bfTeamDeadSpaceFragment
      bfTeam {
        ...BFTeamFragment
      }
    }
  }
  ${BFTeamDeadSpaceFragment}
  ${BFTeamFragment}
`;

export const CREATE_BF_DEAD_CAP = gql`
  mutation CreateBFDeadCap($input: BFTeamDeadSpaceInput!) {
    createBfDeadCap(input: $input) {
      bfTeamDeadSpace {
        ...bfTeamDeadSpaceFragment
        bfTeam {
          ...BFTeamFragment
        }
      }
    }
  }
  ${BFTeamDeadSpaceFragment}
  ${BFTeamFragment}
`;

export const UPDATE_BF_DEAD_CAP = gql`
  mutation UpdateBFDeadCap($input: UpdateBFTeamDeadSpaceInput!) {
    updateBfDeadCap(input: $input) {
      bfTeamDeadSpace {
        ...bfTeamDeadSpaceFragment
        bfTeam {
          ...BFTeamFragment
        }
      }
    }
  }
  ${BFTeamDeadSpaceFragment}
  ${BFTeamFragment}
`;

export const DELETE_BF_DEAD_CAP = gql`
  mutation DeleteBFDeadCap($id: ID!) {
    deleteBfDeadCap(id: $id) {
      id
    }
  }
`;

export const UPDATE_BF_PLAYER_STATUS_BY_DATE = gql`
  mutation UpdateBFPlayerStatusByDate(
    $playerId: ID!
    $date: Date!
    $status: String!
    $teamSlug: ID
    $setFuture: Boolean
    $setUntilChange: Boolean
  ) {
    updateBfPlayerStatusByDate(
      playerId: $playerId
      date: $date
      status: $status
      teamSlug: $teamSlug
      setFuture: $setFuture
      setUntilChange: $setUntilChange
    ) {
      success
      numUpdated
      newStatus
      newCapHit
      updatedPlayerId
      updatedDates
      newActiveStatus
    }
  }
`;

export const UPDATE_BF_PLAYER = gql`
  mutation UpdateBFPlayer($input: BFPlayerInput!) {
    updateBfPlayer(input: $input) {
      bfPlayer {
        ...bfPlayerFragment
      }
    }
  }
  ${BFPlayerFragment}
`;

export const UPDATE_BF_TEAM_STATUS_BY_DATE = gql`
  mutation UpdateBFTeamStatusByDate(
    $teamSlug: ID!
    $date: Date!
    $accruedCapSpaceOverride: Float!
  ) {
    updateBfTeamStatusByDate(
      teamSlug: $teamSlug
      date: $date
      accruedCapSpaceOverride: $accruedCapSpaceOverride
    ) {
      success
      teamSlug
      date
      accruedCapSpace
    }
  }
`;

export const UPDATE_BF_TEAM_LTI_STATUS = gql`
  mutation UpdateBFTeamLTIStatus(
    $date: Date!
    $teamSlug: ID!
    $isInLti: Boolean!
    $playerStatuses: [BFPlayerStatusInput]!
  ) {
    updateBfTeamLtiStatus(
      teamSlug: $teamSlug
      date: $date
      playerStatuses: $playerStatuses
      isInLti: $isInLti
    ) {
      success
      date
      updatedPlayerStatuses {
        ...bfPlayerStatusFragment
      }
    }
  }
  ${BFPlayerStatusFragment}
`;

export const GET_BF_BUYOUT_CALCULATOR = gql`
  query GetBFBuyoutCalculator($contractId: Int, $buyoutYear: Int) {
    bfBuyoutCalculator(contractId: $contractId, buyoutYear: $buyoutYear) {
      teamSlug
      retainedPercent
      contractId
      buyoutStartSeason
      buyoutDate
      remainingTerm
      playerAge
      remainingBaseSalary
      remainingSigningBonus
      remainingTotalSalary
      buyoutMultiplier
      buyoutLength
      totalCost
      annualCost
      totalSavings
      seasons {
        season
        seasonIndex
        originalCapHit
        baseSalary
        signingBonus
        totalSalary
        annualBuyoutCost
        finalCapHit
        savings
      }
    }
  }
`;

export const CREATE_BF_TRADE = gql`
  mutation CreateBFTrade($trade: BDDTradeInput!, $date: Date!, $transactionId: ID) {
    createBfTrade(trade: $trade, date: $date, transactionId: $transactionId) {
      bfTrade {
        ...bfTradesFragment
      }
    }
  }
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
`;

export const UPDATE_BF_TRADE = gql`
  mutation UpdateBFTrade($id: ID!, $trade: BDDTradeInput!, $date: Date!) {
    updateBfTrade(id: $id, trade: $trade, date: $date) {
      bfTrade {
        ...bfTradesFragment
      }
    }
  }
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
`;

export const DELETE_BF_TRADE = gql`
  mutation DeleteBFTrade($id: ID!) {
    deleteBfTrade(id: $id) {
      id
    }
  }
`;

export const LINK_TRANSACTACTION_TO_TRADE = gql`
  mutation LinkTransactionToTrade($transactionId: ID!, $tradeId: ID!) {
    linkTransactionToTrade(transactionId: $transactionId, tradeId: $tradeId) {
      bfTrade {
        ...bfTradesFragment
      }
      nhlDailyTransaction {
        ...nhlDailyTransactionsFragment
      }
    }
  }
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
  ${NHLDailyTransactionsFragment}
`;

export const REMOVE_TRANSACTION_TRADE_LINK = gql`
  mutation RemoveTransactionTradeLink($transactionId: ID!, $tradeId: ID!) {
    removeTransactionTradeLink(transactionId: $transactionId, tradeId: $tradeId) {
      bfTrade {
        ...bfTradesFragment
      }
      nhlDailyTransaction {
        ...nhlDailyTransactionsFragment
      }
    }
  }
  ${BFTradesFragment}
  ${BFTeamFragment}
  ${BFPlayerFragment}
  ${BFDraftPickFragment}
  ${NHLDailyTransactionsFragment}
`;

export const UPDATE_BF_DRAFT_PICKS = gql`
  mutation UpdateBFDraftPicks($picks: [BFDraftPickInput!]!) {
    updateBfDraftPicks(picks: $picks) {
      bfDraftPicks {
        ...bfDraftPickFragment
        trades {
          id
        }
      }
    }
  }
  ${BFDraftPickFragment}
`;

export const CALCULATE_BF_ACSL = gql`
  query CalculateAcsl(
    $playerStatuses: [BFPlayerStatusAtLti]
    $startDate: Date!
    $teamSlug: ID!
  ) {
    bfCalculatedAcsl(
      playerStatuses: $playerStatuses
      startDate: $startDate
      teamSlug: $teamSlug
    ) {
      acsl
      salaryPool
      bonusPool
    }
  }
`;

export const REMOVE_TRANSACTION_PROBLEMS = gql`
  mutation RemoveTransactionProblems($txTypeIds: [TransactionTypeAndID]) {
    removeTransactionProblems(txTypeIds: $txTypeIds) {
      playerIds
      emailTransactions {
        ...nhlDailyTransactionsFragment
      }
      officialTransactions {
        ...linkOfficialTransactionsFragment
      }
    }
  }
  ${NHLDailyTransactionsFragment}
  ${LinkOfficialTransactionsFragment}
`;
