import { NULL_FILTER_VALUE } from "../../helpers/filters"
import { formatDate } from "../../helpers/helpers"
import { isTargetGroup } from "../../helpers/hockeyutils"

const timestampToDate = tstamp => {
    try { return formatDate(new Date(tstamp), {format: '%m/%d/%Y'}) 
    } catch (RangeError) { return null }
}

export function filtersToQ(filters, target, indexDefaults) {
    const filterList = []
    if (!!filters.season) {
        if (filters.season instanceof Object) {
            filterList.push({ col: 'season', val: filters.season, cond: 'isin' })
        } else {
            filterList.push({ col: 'season', val: filters.season })
        }
    }
    if (!!filters.game_type) {
        filterList.push({ col: 'game_type', val: filters.game_type })
    }
    if (!!filters.strength && filters.strength != NULL_FILTER_VALUE) {
        filterList.push({ col: 'manpower_situation', val: filters.strength.slice(0, 2) })
        if (filters.strength.length > 2) {
            filterList.push({ col: 'strength', val: filters.strength.slice(2) })
        }
    }
    if (filters.teams && filters.teams.length) {
        filterList.push({ col: target === 'teams' ? 'slug' : 'team_slug', val: filters.teams, cond: 'isin' })
    }
    if (!!filters.position && filters.position != NULL_FILTER_VALUE && target !== 'goalies') {
        filterList.push({ col: 'position', val: filters.position, cond: '~' })
    }
    if (filters.start_date && filters.end_date) {
        filterList.push({ col: 'game_date', val: timestampToDate(filters.start_date), cond: '>=' })
        filterList.push({ col: 'game_date', val: timestampToDate(filters.end_date), cond: '<=' })
    }
    if (filters.currentteam_slug) {
        filterList.push({col: 'currentteam_slug', val: filters.currentteam_slug})
    }
    if (filters.sl_uids) {
        filterList.push({col: 'sl_uid', val: filters.sl_uids, cond: 'isin'})
    }
    if (filters.slug) {
        filterList.push({col: 'slug', val: filters.slug})
    }
    
    const filterIndex = !!filters.index ? filters.index.split(',') : []

    if (!indexDefaults) {
      if (isTargetGroup(target)) {
          indexDefaults = ['id', 'Players', target, 'jerseynumbers', 'slugs']
      } else {
          indexDefaults = ['slug']
          if (target !== 'teams') {
              if (filters.league === 'NHL') {
                  indexDefaults.push('currentteam_nhlid')
              } else {
                  indexDefaults.push('currentteam_shorthand')
              }
          }    
      }
    }
    let index = filterIndex
      .concat(indexDefaults.filter(i => !filterIndex.includes(i)))
      .concat(filters.extraInfo ? filters.extraInfo : []);
    index = index.filter((indexVal, i) => index.indexOf(indexVal) === i);
    const q = {
        filters: filterList,
        index: index,
        format: { percentages: { mult100: true } },
        statReport: filters.statReport
    }
    const hasSeasonFilter = filterList.filter(f => f.col === 'season').length > 0
    if (filters.min_toi) {
        q.min_toi = parseInt(filters.min_toi)
    }
    if (filters.norm != NULL_FILTER_VALUE) {
        q.norm = filters.norm
        const targetPosition = filters.position !== NULL_FILTER_VALUE ? filters.position : 'all'
        const targetGroupType = filters.metastats ? filters.metastats : 'league' // league or team
        if (targetGroupType === 'league') {
          var metastats_dict = { target_group: 'league' }
        } else {
          const targetTeamSlug = targetGroupType === 'team' && !!filters.target_team_slug ? filters.target_team_slug : null
          metastats_dict = { target_team_slug: targetTeamSlug }
        }
        
        q.extras = { metastats: { ...metastats_dict, target_position: targetPosition } }
        if (!hasSeasonFilter) {
            q.extras.metastats['use_defaults'] = true
        }
    }
    return q    
}
