import { axios } from "./requests"

export function post_pregenerated_s3_url(file, response) {
    /* 
    : param file -- a file obj
    :param response -- A boto3 response object -- contains "url", "fields"
    */
    const formData = new FormData();
    for (var key in response.fields) {
        formData.append(key, response.fields[key])
    }
    formData.append('file', file)
    return axios.post(response.url, formData, {
        crossDomain: true,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}