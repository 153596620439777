import { Typography } from 'components/bdd/Typography';
import { positionToSkillCategories } from 'components/PlayersV2/SkillsOverview';
import useSLSkills from '../hooks/useSLSkills';

const DEFAULT_FLAT_LABELS = [
  'TOI/GP',
  'PBR',
  'SOT iXP',
  'SOO iXP',
  // 'SDiff iXP',
  'G',
  'A',
  'P',
  'XPGF',
  'XPGA',
  '%SCGP',
  '% PDPs',
  '% DPlays',
  'PP TOI/GP',
  'SH TOI/GP',
];
export const getDefaultListSkillStatDefs = (position = 'skaters') => {
  const statDefs = positionToSkillCategories(position).flatMap(
    (cat) => cat.statDefinitions
  );
  return DEFAULT_FLAT_LABELS.map((fl) =>
    statDefs.find((sd) => sd.flatLabel === fl)
  ).filter((sd) => !!sd);
};

export default function SportlogiqSkills({ bddPlayer, slugs, statDefinitions, season }) {
  const { loading, slugToSkills } = useSLSkills({
    slugs,
    statDefinitions,
    season
  });
  const skillsComponent = slugToSkills?.[bddPlayer?.slug]?.rowComponent;
  return (
    <div onClick={(ev) => ev.stopPropagation()}>
      {!skillsComponent && loading ? (
        <Typography variant="caption">Loading...</Typography>
      ) : !!skillsComponent ? (
        skillsComponent
      ) : (
        <Typography variant="caption">No skills data found</Typography>
      )}
    </div>
  );
}
