import { useMemo } from 'react';
import { useQueryWithPlaceholder } from 'apollo/hooks';
import { GET_EP_DATA } from 'apollo/queries/playerlist.queries';

export const useEPData = ({ rinknetIds, skip }) => {
  const { data, error, placeholder } = useQueryWithPlaceholder(
    GET_EP_DATA,
    {
      rinknetIds,
    },
    {
      variant: 'squares-sm',
      nocenter: true,
    },
    skip
  );

  const playerToEP = useMemo(() => {
    const ret = {};
    if (!!data) {
      try {
        var rawStats = JSON.parse(data.epData.stats);
      } catch {
        var rawStats = [];
      }
      rawStats.forEach((d) => {
        if (!(d.player.id in ret)) ret[d.player.id] = {};
        ret[d.player.id] = { ...d };
      });
      try {
        var rawRankings = JSON.parse(data.epData.rankings);
      } catch {
        var rawRankings = [];
      }
      rawRankings.forEach((d) => {
        if (!(d.player.id in ret)) ret[d.player.id] = {};
        ret[d.player.id] = { ...ret[d.player.id], ...d };
      });
    }
    return ret;
  }, [data]);

  return { data, error, placeholder, playerToEP };
};
