import { useState } from "react";
import RangeInputBoxes from "./RangeInputBoxes";
import { toastInfo } from "../bddtoasts";


export default function useRangeInputBoxes({
  initialMinValue,
  initialMaxValue,
  rangeMin=null, // min allowable value
  rangeMax=null,
  onChangeComplete,
  rangeInputBoxesProps={}
}) {
  const [values, setValues] = useState({ min: initialMinValue, max: initialMaxValue })

  return {
    values,
    ...values,
    rangeInputBoxes: <RangeInputBoxes
      value={values}
      onChange={ev => setValues(ev.target.value)}
      onChangeComplete={values => {
        if (!!rangeMin && values.min < rangeMin) {
          toastInfo('Value out of range!')
        } else if (!!rangeMax && values.max > rangeMax) {
          toastInfo('Value out of range!')
        }
        !!onChangeComplete && onChangeComplete(values)
      }}
      {...rangeInputBoxesProps}
    />
  }
}