import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import client from "../client"
import { STAT_ENGINE_V2_QUERY } from './statsv2.queries';


// V2
export function useBddStatQueryV2(q, skip) {
  const response = useQuery(STAT_ENGINE_V2_QUERY, {
    skip,
    variables: { q },
  });
  return {
    ...response,
    data: !!response.data 
      ? { ...response.data?.slStats }
      : undefined,
  };
}


const STATS_QUERY = gql`
  query statsQuery($path: String!, $input: JSONString) {
    stats(path: $path, input: $input) @rest(method: "POST", path: $path) {
      data
      format
      extras
    }
  }
`
// postSearch(query: "some key words", page_size: 5)
// @rest(type: "Post", path: "/search?{args}&{context.language}") {
const BDD_API_GET_QUERY = gql`
    query apiQuery($path: String!) {
        bddApi(path: $path, input: $input) 
            @rest(method: "GET", path: $path) {
                data
                format
            }
    }
`

const buildStatPath = (league, target, q, opts = {}) => {
  const eUC = encodeURIComponent
  if (['lines', 'pairs', 'units', 'linesbyplayer', 'pairsbyplayer', 'unitsbyplayer'].includes(target)) {
    if (!opts.teamSlug) throw Error('BDD group stat query requires teamSlug in 4th "opts" parameter')
    return `/leagues/${eUC(league)}/stats/teams/${eUC(opts.teamSlug)}/${eUC(target)}/`
  }
  return `/leagues/${eUC(league)}/stats/${opts.onthefly ? 'onthefly/' : ''}${eUC(target)}/`
}

export function bddStatQuery(league, target, q, opts = {}) {
  if (opts.useStatEngineV2 === true || (opts.useStatEngineV2 !== false && localStorage.getItem('useStatEngineV2') !== 'false')) {
    let newFilters;
    if (Array.isArray(q.filters)) {
      newFilters = [...q.filters];
      if (!q.filters.find(f => f.col == 'league_slug')) {
        newFilters = newFilters.concat([{ col: 'league_slug', val: league }])
      }
      if (!!opts.teamSlug) {
        newFilters = newFilters.concat([{ col: 'team_slug', val: opts.teamSlug }])
      }
    }
    else {
      newFilters = { ...q.filters, league_slug: league }
      if (!!opts.teamSlug) {
        newFilters['team_slug'] = opts.teamSlug
      }
    }
    q = { ...q, target, filters: newFilters }
    const response = client.query({
      query: STAT_ENGINE_V2_QUERY,
      variables: { q },
      fetchPolicy: opts.skipCache ? 'network-only' : 'cache-first'
    })
    return new Promise((resolve, reject) => {
      // for consistency with stat engine v1, which nested data under "stats"
      response.then(res => resolve({ ...res, data: { stats: res.data.slStats }})).catch(err => reject(err))
    })
  }
  const path = buildStatPath(league, target, q, opts)
  const args = { q }
  
  return client.query({
    query: STATS_QUERY,
    variables: { path, input: args }
  })
}

// V1
export function useBddStatQuery(league, target, q, opts={}) {
  const [state, setState] = useState({ loading: true, data: undefined, error: undefined })
  const fetchData = () => {
    setState({ loading: true, data: undefined, error: undefined })
    bddStatQuery(league, target, q, opts).then(res => {
      setState({ loading: false, data: res.data.stats, error: undefined })
    }).catch(err => {
      setState({ loading: false, data: undefined, error: err })
    })
  }

  useEffect(() => {
    fetchData()
  }, [])
  return { ...state, refetch: () => fetchData() }
}

// export function useLazyBddStatQuery(league, target, q, opts={}) {
export function useLazyBddStatQuery(...args) {
  const [state, setState] = useState({ loading: false, data: undefined, error: undefined, called: false })
  const fetchData = (league, target, q, opts = {}) => {
    if (!league && !target && !q && args.length >= 3) {
      [league, target, q, opts] = args
    }
    setState({ loading: true, data: undefined, error: undefined, called: true })
    bddStatQuery(league, target, q, opts).then(res => {
      setState({ loading: false, data: res.data.stats, error: undefined, called: true })
    }).catch(err => {
      setState({ loading: false, data: undefined, error: err, called: true })
    })
  }

  return [fetchData, { ...state }]
}


const buildPath = (config) => {
  const params = new URLSearchParams(config.params);
  return `${config.path}?${params}`
}
export function bddApiQuery(config) {
  /* Config expects:
      path
      method
      params: query string args
      data: body json data
  */
  const path = buildPath(config)
  return client.query({
    query: BDD_API_GET_QUERY,
    variables: { path },
    headers: {
      contentType: 'json'
    }
  })
}