import { ModalContext } from "components/bdd/Modal"
import { useContext } from "react";
import { Typography } from "components/bdd/Typography";
import { CreateFeedbackForm } from "../Forms/CreateFeedbackForm";



export const useFeedbackModal = ({
  isUpdate=false,
  defaultValues={}
}) => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) return {};
  const { renderModal, hideModal } = modalContext;

  return {
    hideModal,
    renderModal: () => renderModal({
      title: <Typography variant='h4'>Add Feedback</Typography>,
      body: isUpdate 
        ? null 
        : <CreateFeedbackForm 
          defaultValues={defaultValues}
          onCompleted={() => hideModal()} 
        />,
      size: 'md'
    })
  }
}