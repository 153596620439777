export const ROUTE_PREFIX = '/r';

export const LOGIN_ROUTE = `/login`;
export const LOGOUT_ROUTE = `/logout`;
export const SWITCHUSER_ROUTE = `/switchuser`;
export const ADMIN_LOGIN_ROUTE = `/adminlogin`;

export const CHANGE_PASSWORD_ROUTE = '/changepassword';
export const DASHBOARD_ROUTE = `/`;
export const ADMIN_ROUTE = '/admin';
export const USER_ROUTE = '/users';
export const PLAYERS_ROUTE = `/players2`;
export const PLAYERS_V2_ROUTE = `/players`;
export const LEAGUE_ROUTE = `/leagues`;
export const TEAM_WORKSHEETS_ROUTE = '/teamworksheets';
export const TEAM_ROUTE = `/teams`;
export const GAME_ROUTE = `/games`;
export const SCOUTING_V1_ROUTE = `/v1scouting`;
export const SCOUTING_ROUTE = `/scouting`;
export const DRAFT_LIST_BUILDER_ROUTE = `/scouting/draftlistbuilder`;
export const PRO_LIST_BUILDER_ROUTE = `/leagues/NHL/prolistbuilder`;
export const DRAFT_ROUTE = `/draft`;
export const STAT_MANAGER_ROUTE = `/statdefs`;
export const STATS_ROUTE = '/stats';
export const LINKING_ROUTE = '/linking';
export const RESEARCH_ROUTE = '/research';
export const PLAYOFF_PRESCOUT_ROUTE = '/playoffprescouts';
export const DEV_ROUTE = '/dev';
export const SPORTS_SCIENCE_ROUTE = '/sportsscience';
export const COMPARE_PLAYERS_ROUTE = '/compare';
export const LISTS_ROUTE = '/lists';
export const PLAYER_DEVELOPMENT_ROUTE = '/development';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const SUBSCRIPTIONS_ROUTE = '/subscriptions';
export const POLLS_ROUTE = '/polls';
export const PRO_ROUTE = '/pro';
export const SHOOTOUTS_ROUTE = '/shootouts';
export const PRO_OBJECTIVE_ROUTE = '/pro/objectives';
export const SCHEDULE_ROUTE = '/schedule';
export const STANDINGS_ROUTE = '/standings';
export const PLAYLISTS_ROUTE = '/playlists';
export const VIDEO_ROUTE = '/video';
export const NOTES_ROUTE = '/notes';
export const CALENDAR_ROUTE = '/calendar';
export const FEEDBACK_ROUTE = '/feedback';
