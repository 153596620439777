import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';
import { Circle, Icon1Circle } from 'react-bootstrap-icons';

export const BFElcTag = ({ contract, ...tagProps }) => {
  const isElcContract = contract?.signingStatus == 'ELC';

  if (!isElcContract) return null;

  return (
    <BDDTag
      {...tagProps}
      // isProjection
      tag={{
        color: theme.colors.categorical.purple,
        textColor: theme.colors.dark.text.primary,
        tag: (
          <Typography variant="body1">
            <Icon color={theme.colors.light.background} icon={<Icon1Circle />} />
          </Typography>
        ),
        description: 'ELC',
      }}
    />
  );
};
