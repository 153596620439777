import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  faArrowLeft,
  faBrain,
  faBullseye,
  faEye,
  faHockeyPuck,
  faPeopleArrows,
  faSkating,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  GET_PLAYER_LIST,
  GET_PLAYER_LIST_W_EP_DATA,
  UPDATE_PLAYER_LIST_WATCH,
} from '../../apollo/queries/playerlist.queries';
import { GET_UPLOAD } from '../../apollo/queries/upload.queries';
import { LISTS_ROUTE } from '../../constants';
import { roundToX } from '../../helpers/data';
import { season8to4 } from '../../helpers/hockeyutils';
import { zScoreToPercentile } from '../../helpers/math';
import {
  BDDOptionGroup,
  HoverInfo,
  HoverInteractive,
  OverflowEllipsisCol,
} from '../bdd';
import AddListPlayers from '../PlayerLists/addlistplayers';
import PlayerListWrapper from '../PlayerLists/playerlistwrapper';
import { TooltipSpan } from '../reports';
import CompareDraftPlayersModal from './comparedraftplayersmodal';
import { listConstants } from './constants';
import DraftPlayerModal from './draftplayermodal';
import { Typography } from 'components/bdd/Typography';

const Styles = styled.div`
  .control-row {
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .primary-row {
    height: 80vh;

    flex-wrap: nowrap;
    [class*='col-'] {
      transition: flex 0.5s ease-in-out, max-width 0.5s ease-in-out,
        padding 0.5s ease-in-out;
    }
  }

  .list-col {
    height: 100%;
    overflow: scroll;
  }

  .selected-player {
    border: 2px solid #aaaaff;
  }

  .player-row {
    font-size: 0.8em;
    .player-name {
      font-size: 1em;
    }
    .skill-icon {
      margin-right: 7px;
    }
  }
  .popover {
    max-width: 400px;
  }
`;
const DisappearingCol = styled(Col)`
  ${(props) =>
    props.isvisible
      ? `
        overflow: scroll;
    `
      : `
        overflow: hidden;
        max-width: 0 !important;
        padding: 0 !important;
    `}
`;

const DEFAULT_ANALYTIC_UPLOAD_SLUG = 'analyticDraftData2022';
export default function DraftListBuilder({ listId: listIdArg }) {
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const [playerMode, setPlayerMode] = useState('bio');
  const [showAddPlayers, setShowAddPlayers] = useState(false);
  const bottomOfListRef = useRef(null);

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const { listId: listIdParam } = useParams();
  const listId = !!listIdArg ? listIdArg : listIdParam;

  const { data } = useQuery(GET_PLAYER_LIST, { variables: { id: listId } });
  const [updatePlayerlistWatch] = useMutation(UPDATE_PLAYER_LIST_WATCH);

  useEffect(() => {
    if (data && data.playerList.isWatched) {
      updatePlayerlistWatch({
        variables: { id: listId },
      });
    }
  }, [data]);

  const [fetchEPData, { data: epData, loading: epLoading, called: epCalled }] =
    useLazyQuery(GET_PLAYER_LIST_W_EP_DATA, {
      variables: {
        listId,
      },
    });
  // Analytic upload slug read from player list extras, but has a default
  if (!data || !data.playerList.extras) {
    var analyticUploadSlug = DEFAULT_ANALYTIC_UPLOAD_SLUG;
  } else {
    try {
      const extras = JSON.parse(data.playerList.extras);
      var analyticUploadSlug =
        extras[listConstants.LIST_EXTRAS_ANALYTICS_UPLOAD_SLUG_KEY];
    } catch {
      var analyticUploadSlug = DEFAULT_ANALYTIC_UPLOAD_SLUG;
    }
  }
  const [
    fetchAnalyticData,
    { data: analyticData, loading: analyticLoading, called: analyticCalled },
  ] = useLazyQuery(GET_UPLOAD, {
    variables: {
      slug: analyticUploadSlug,
    },
  });

  useEffect(() => {
    if (playerMode === 'eliteprospects') {
      fetchEPData();
    }
    if (playerMode === 'scouting') {
      fetchAnalyticData();
    }
  }, [playerMode]);

  useEffect(() => {
    if (hash.startsWith('#compare') && !analyticCalled) {
      fetchAnalyticData();
    }
    if (hash.startsWith('#compare') && !epCalled) {
      fetchEPData();
    }
  }, [hash]);

  const playerToEP = useMemo(() => {
    const ret = {};
    if (!!epData) {
      try {
        var rawStats = JSON.parse(epData.playerListEpData.stats);
      } catch {
        var rawStats = [];
      }
      rawStats.forEach((d) => {
        if (!(d.player.id in ret)) ret[d.player.id] = {};
        ret[d.player.id] = { ...d };
      });
      try {
        var rawRankings = JSON.parse(epData.playerListEpData.rankings);
      } catch {
        var rawRankings = [];
      }
      rawRankings.forEach((d) => {
        if (!(d.player.id in ret)) ret[d.player.id] = {};
        ret[d.player.id] = { ...ret[d.player.id], ...d };
      });
    }
    return ret;
  }, [epData]);

  const playerToAnalytics = useMemo(() => {
    const ret = {};
    if (!!analyticData && !!analyticData.upload) {
      analyticData.upload.uploadData.forEach((d) => {
        const parsed = JSON.parse(d.data);
        ret[parseInt(parsed['rinknetid'])] = parsed;
      });
    }
    return ret;
  }, [analyticData]);

  const handleScrollToBottom = () =>
    bottomOfListRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  const showPlayerModal = (playerId) => {
    history.push(`${pathname}#player=${playerId}`);
  };

  const renderPlayer = (player, playerList) => {
    return (
      <DraftListPlayer
        player={player}
        playerMode={playerMode}
        epLoading={epLoading}
        playerToEP={playerToEP}
        playerToAnalytics={playerToAnalytics}
        analyticLoading={analyticLoading}
        showAddPlayers={showAddPlayers}
        showPlayerModal={showPlayerModal}
      />
    );
  };

  const showCompareModal = () => {
    var newHash = '#compare';
    if (selectedPlayers.length > 0) {
      newHash += `&players=${selectedPlayers.join(',')}`;
    }
    history.push(`${pathname}${newHash}`);
  };

  const hideModals = () => history.push(pathname);

  const handlePlayerClick = (playerId) => {
    if (selectedPlayers.indexOf(playerId) >= 0) {
      setSelectedPlayers(selectedPlayers.filter((pid) => pid !== playerId));
    } else {
      setSelectedPlayers(selectedPlayers.concat(playerId));
    }
  };

  return (
    <Container fluid>
      <Styles>
        <Row className="control-row">
          <Col sm="auto">
            <Link
              to={`${LISTS_ROUTE}`}
              style={{ fontSize: '16pt', color: 'black' }}
            >
              <TooltipSpan content="Back to draft lists">
                <FontAwesomeIcon icon={faArrowLeft} />
              </TooltipSpan>
            </Link>
          </Col>
          <Col>
            <BDDOptionGroup
              selected={playerMode}
              options={[
                { value: 'bio', label: 'Bio' },
                { value: 'scouting', label: 'Scouting' },
                { value: 'eliteprospects', label: 'EP' },
              ]}
              onClick={(v) => setPlayerMode(v)}
            />
          </Col>
          <Col sm="auto" className="ml-auto">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => {
                showCompareModal();
              }}
            >
              Compare Players
            </Button>{' '}
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => setShowAddPlayers(!showAddPlayers)}
            >
              Add Players
            </Button>
          </Col>
        </Row>
        <Row className="primary-row">
          <Col className="list-col" sm={showAddPlayers ? 7 : 12}>
            <PlayerListWrapper
              listId={listId}
              defaultEditMode={true}
              showEditButton={false}
              showDeleteButton={false}
              showDetailsButton={false}
              showAddPlayers={false}
              renderPlayer={renderPlayer}
              playerFields={`
                        rinknetPlayer { 
                            birthdate age
                            currentteam currentleagueabbr 
                            position heightStr weight
                            posFDG
                            eliteprospectsid
                            handedness
                            agentname
                            collegecommitmentteam
                            agent {
                                id
                                title firstname lastname
                                companyname
                                email phone cellphone
                            }
                        }
                    `}
              selectedPlayers={selectedPlayers}
              handlePlayerClick={handlePlayerClick}
              pollInterval={10000}
            />
            <div ref={bottomOfListRef} />
          </Col>
          <DisappearingCol
            sm={showAddPlayers ? 5 : 0}
            isvisible={showAddPlayers ? 1 : 0}
          >
            <AddListPlayers
              listId={listId}
              defaultExpand={true}
              numCols={2}
              onPlayersAdded={() => setTimeout(handleScrollToBottom, 500)}
            />
          </DisappearingCol>
        </Row>
      </Styles>
      {hash.startsWith('#player') && (
        <DraftPlayerModal
          show={hash.startsWith('#player')}
          handleClose={() => hideModals()}
          playerToEP={playerToEP}
        />
      )}
      {hash.startsWith('#compare') && (
        <CompareDraftPlayersModal
          listId={listId}
          show={hash.startsWith('#compare')}
          handleClose={() => hideModals()}
          playerToAnalytics={playerToAnalytics}
          analyticLoading={analyticLoading}
          playerToEP={playerToEP}
          epLoading={epLoading}
        />
      )}
    </Container>
  );
}

const EPTable = styled.table`
  width: 100%;
  text-align: center;
  font-size: 0.8em;

  th {
    padding: 0px 5px;
  }
  td {
    border: 1px solid #ddd;
  }
`;
export const PlayerEPTable = ({ stats }) => {
  if (!stats || stats.length == 0) return <Typography variant='caption'>No eliteprospects data found</Typography>;
  return (
    <div
      style={{ width: '500px', maxWidth: '500px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <EPTable>
        <tbody>
          <tr>
            <th>Season</th>
            <th>League</th>
            <th>Team</th>
            <th>GP</th>
            <th>G</th>
            <th>A</th>
            <th>PTS</th>
            <th>PIM</th>
            <th>PPG</th>
          </tr>
          {stats.map((s) => (
            <tr key={s.id}>
              <td>{s.season.slug}</td>
              <td>{s.leagueName}</td>
              <td>{s.team.name}</td>
              <td>{renderVal('GP', 'regularStats', s)}</td>
              <td>{renderVal('G', 'regularStats', s)}</td>
              <td>{renderVal('A', 'regularStats', s)}</td>
              <td>{renderVal('PTS', 'regularStats', s)}</td>
              <td>{renderVal('PIM', 'regularStats', s)}</td>
              <td>{renderVal('PPG', 'regularStats', s)}</td>
            </tr>
          ))}
        </tbody>
      </EPTable>
    </div>
  );
};

const renderVal = (stat, seasonType, statRow) =>
  !!statRow[seasonType] ? statRow[seasonType][stat] : 'N/A';

const DraftListPlayer = ({
  player,
  playerMode,
  epLoading,
  playerToEP,
  analyticLoading,
  playerToAnalytics,
  showAddPlayers,
  showPlayerModal,
}) => {
  const rinknetId = player.playerId;
  const rp = player.rinknetPlayer;
  const epid = player.rinknetPlayer.eliteprospectsid;

  if (playerMode === 'eliteprospects') {
    var stats = epid in playerToEP ? playerToEP[epid].stats : null;
    if (!!stats) {
      var primaryStats = stats[0];
    }
    var rankings = epid in playerToEP ? playerToEP[epid].rankings : null;
    if (!!rankings) {
      var avgRank = playerToEP[epid].avg_ranking;
    }
  } else if (playerMode === 'scouting') {
    var analytics =
      rinknetId in playerToAnalytics ? playerToAnalytics[rinknetId] : null;
  }

  const playerBioInfo = () => {
    return (
      <>
        <OverflowEllipsisCol sm={4}>
          {rp.currentteam} | {rp.currentleagueabbr}
        </OverflowEllipsisCol>
        <OverflowEllipsisCol>
          {rp.heightStr} {!!rp.weight ? <>| {rp.weight}lbs</> : null} |{' '}
          {rp.birthdate}
        </OverflowEllipsisCol>
        <Col sm="auto" className="ml-auto">
          {rp.handedness} | {rp.position}
        </Col>
      </>
    );
  };

  const renderRating = (zscore) =>
    roundToX(100 * zScoreToPercentile(zscore), 1, true);
  const renderRatingIcon = (icon, text, hover, rawValue, formatVal = true) => (
    <TooltipSpan content={hover}>
      {!!icon ? <FontAwesomeIcon icon={icon} /> : text}{' '}
      {formatVal ? renderRating(rawValue) : rawValue}
    </TooltipSpan>
  );
  return (
    <Row className="player-row">
      <Col sm={showAddPlayers ? 5 : 3} className="mr-auto player-name">
        <a
          href=""
          style={{ color: 'black' }}
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            showPlayerModal(player.playerId);
          }}
        >
          <HoverInteractive
            content={
              <>
                <b>
                  {player.firstname} {player.lastname}
                </b>
                {playerBioInfo()}
                <Col>
                  {rp.birthdate} | {roundToX(rp.age, 1)} y.o.
                </Col>
                <hr></hr>
                <Col>
                  <b>Agent:</b>{' '}
                  {!!rp.agent ? (
                    <TooltipSpan
                      content={
                        <>
                          <div>
                            <b>Cell:</b> {rp.agent.cellphone}
                          </div>
                          <div>
                            <b>Phone:</b> {rp.agent.phone}
                          </div>
                          <div>
                            <b>Email:</b> {rp.agent.email}
                          </div>
                        </>
                      }
                    >
                      {rp.agent.title} {rp.agent.firstname} {rp.agent.lastname}{' '}
                      ({rp.agent.companyname})
                    </TooltipSpan>
                  ) : (
                    <em>No agent</em>
                  )}
                </Col>
                <Col>
                  <b>College Commitment:</b>{' '}
                  {rp.collegecommitmentteam ? (
                    rp.collegecommitmentteam
                  ) : (
                    <em>None</em>
                  )}
                </Col>
              </>
            }
          >
            <b>
              {player.firstname} {player.lastname}
            </b>
          </HoverInteractive>
        </a>
      </Col>
      {playerMode === 'bio' ? (
        <>{playerBioInfo()}</>
      ) : playerMode === 'scouting' ? (
        <>
          {analyticLoading ? (
            <Col>
              <em>Loading...</em>
            </Col>
          ) : !!analytics ? (
            <>
              <Col sm="3">
                <TooltipSpan
                  content={
                    <>
                      <div>
                        <b>Scouting:</b> {roundToX(analytics['OVR_S'], 0)}
                      </div>
                      <div>
                        <b>Production:</b> {roundToX(analytics['OVR_P'], 0)}
                      </div>
                    </>
                  }
                >
                  <b>OVR:</b> {roundToX(analytics['OVR'], 0)}
                </TooltipSpan>
                {' | '}
                <TooltipSpan
                  content={
                    <>
                      <div>
                        <b>Low:</b> {analytics['draft_rnd_low']}
                      </div>
                      <div>
                        <b>High:</b> {analytics['draft_rnd_high']}
                      </div>
                    </>
                  }
                >
                  <b>Rd:</b> {analytics['draft_rnd']}
                </TooltipSpan>
              </Col>
              <OverflowEllipsisCol>
                <span className="skill-icon">
                  {renderRatingIcon(
                    faSkating,
                    null,
                    'Skating',
                    analytics['skating']
                  )}
                </span>
                <span className="skill-icon">
                  {renderRatingIcon(
                    faBrain,
                    null,
                    'Hockey Sense',
                    analytics['hockey_sense']
                  )}
                </span>
                <span className="skill-icon">
                  {renderRatingIcon(
                    faBullseye,
                    null,
                    'Scoring',
                    analytics['scoring']
                  )}
                </span>
                <span className="skill-icon">
                  {renderRatingIcon(
                    null,
                    <b>IN</b>,
                    'Intensity/Compete',
                    analytics['compete']
                  )}
                </span>
                {rp.posFDG === 'D' ? (
                  <>
                    <span className="skill-icon">
                      {renderRatingIcon(
                        faHockeyPuck,
                        null,
                        'Puck Handling',
                        analytics['puck_handling']
                      )}
                    </span>
                    <span className="skill-icon">
                      {renderRatingIcon(
                        null,
                        <b>PA</b>,
                        'Passing',
                        analytics['passing']
                      )}
                    </span>
                  </>
                ) : rp.posFDG === 'F' ? (
                  <>
                    <span className="skill-icon">
                      {renderRatingIcon(
                        faHockeyPuck,
                        null,
                        'Possession',
                        analytics['possession']
                      )}
                    </span>
                    <span className="skill-icon">
                      {renderRatingIcon(
                        null,
                        <b>CR</b>,
                        'Creativity',
                        analytics['creativity']
                      )}
                    </span>
                  </>
                ) : null}
                <span className="skill-icon">
                  {renderRatingIcon(
                    faEye,
                    null,
                    '# Reports',
                    analytics['reports'],
                    false
                  )}
                </span>
              </OverflowEllipsisCol>
            </>
          ) : (
            <Col>
              <em>No data found</em>
            </Col>
          )}
        </>
      ) : playerMode === 'eliteprospects' ? (
        <>
          {epLoading ? (
            <Col>
              <em>Loading...</em>
            </Col>
          ) : !!stats ? (
            <>
              <OverflowEllipsisCol>
                <HoverInteractive content={<PlayerEPTable stats={stats} />}>
                  <b>
                    {season8to4(
                      primaryStats.season.slug.replace('-', ''),
                      true
                    )}{' '}
                    {primaryStats.leagueName} {'Reg'}
                  </b>
                  : {renderVal('GP', 'regularStats', primaryStats)}GP |{' '}
                  {renderVal('G', 'regularStats', primaryStats)}-
                  {renderVal('A', 'regularStats', primaryStats)}-
                  {renderVal('PTS', 'regularStats', primaryStats)}
                </HoverInteractive>
              </OverflowEllipsisCol>
              {!!rankings && (
                <OverflowEllipsisCol>
                  <HoverInteractive
                    content={
                      <>
                        {rankings.map((r, i) => (
                          <div key={i}>
                            <small>
                              <b>
                                {r.rankingScout ? r.rankingScout.name : 'unk'}
                              </b>
                            </small>
                            : #{r.rank} {r.continent && `(${r.continent})`}
                            {r.rankingScout &&
                              r.rankingScout.continent &&
                              `(${r.rankingScout.continent})`}
                          </div>
                        ))}
                      </>
                    }
                  >
                    <b>Avg. Rank:</b> {roundToX(avgRank, 1)}
                  </HoverInteractive>
                </OverflowEllipsisCol>
              )}
            </>
          ) : (
            <Col>
              <Typography variant='stat'>
                <em>No eliteprospects data found</em>
              </Typography>
            </Col>
          )}
        </>
      ) : null}
    </Row>
  );
};
