import { Container } from "components/bdd/Layout"
import { useContext } from "react"
import { ProObjectiveContext } from "../ProObjectiveContextProvider"
import { Typography } from "components/bdd/Typography";
import { BDDTitleAndBackArrow } from "components/bdd";
import { Divider } from "components/bdd/Divider";
import { POTradeDetail, POTxDetail } from "./POTxDetail";
import { useHistory, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";



export const POTransactionDetailPage = ({ transactionId }) => {
  const { proObjectiveSlug, transactions } = useContext(ProObjectiveContext);
  const { path, params: { variantId }} = useRouteMatch();
  const history = useHistory();

  const handleClick = (variant, idx) => {
    history.replace(path.replace(':id', transactionId).replace(':variantId', variant.id));
  }

  const tx = transactions?.find(t => t.id == transactionId);  
  return <Container>
    {!transactions 
      ? <Typography variant='body2'>Loading...</Typography>
      : !!tx
      ? (
        <Container>
          <BDDTitleAndBackArrow
            title={<Typography variant='h5' textAlign='center'>{tx.transactionType}</Typography>}
          />
          <Divider/>
          
          <POTxDetail transaction={tx} selectedVariantId={variantId} onClick={handleClick} />

        </Container>
      ) : <Typography variant='error'>Transaction not found</Typography>}
  </Container>
}