import { useQuery } from "@apollo/client"
import { GET_SCENARIO_CAP_BREAKDOWN } from "apollo/queries/proobjective.queries"
import BDDApiError from "components/bdd/bddapierror";
import { BDDLoader } from "components/bdd/bddloader";


export const useScenarioCapBreakdown = ({ scenarioId }) => {
  const { data, loading, error, refetch } = useQuery(GET_SCENARIO_CAP_BREAKDOWN, {
    variables: { id: scenarioId },
    notifyOnNetworkStatusChange: true,
  });

  const placeholder = !!data 
    ? null
    : loading
    ? <BDDLoader />
    : error
    ? <BDDApiError error={error} />
    : null;

  return {
    capBreakdown: data?.scenarioCapBreakdown,
    data,
    placeholder,
    loading,
    error,
    refetch,
  }
}