import { Filters, useFilterContext, useFilterValues } from 'components/bdd/Filters';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useContractSearchFilterValues } from './context';
import {
  getTimeRangeOptions,
  statValueFilters,
  slStatValueFilters,
  generalSeasonOptions,
  seasonOptions,
} from './constants';
import { SLStatContext, SLStatContextProvider } from 'components/SLStats';
import {
  DateRangeFilter,
  GameTypeFilter,
  SeasonsFilter,
  StrengthFilter,
} from 'components/SLStats/filters';
import { useSLStatContext } from 'components/SLStats/hooks';
import { useContext, useEffect } from 'react';
import { getCurrentSeason, getNextOrCurrentSeason } from 'helpers/hockeyutils';

export const ignorePriorSeasonsForTimeRange = (timeRange) =>
  timeRange != 'platform-stats' && timeRange != 'multiple-season-stats';

export const StatFilters = ({}) => {
  const { 'time-range': timeRange, season: seasonTest } = useFilterValues();
  const { season } = useContractSearchFilterValues();
  const { setFilters, removeFilter, removeFilters } = useFilterContext(
    (state) => state.actions
  );

  return (
    <Column gap={2}>
      <Row columnGap={2} rowGap={2} flexWrap>
        <Filters.Select
          id={'time-range'}
          label={'Time Range'}
          options={getTimeRangeOptions(season)}
          width={150}
          meta={{
            ignoreOnQuery: true,
          }}
          onChange={(timeRange) => {
            removeFilters((filter) => !filter.meta?.isStatFilter);

            if (ignorePriorSeasonsForTimeRange(timeRange)) {
              removeFilter('platform-prior-seasons');
            }

            if (timeRange != 'custom-sl-stats') {
              removeFilter('custom_sl_filters');
            }

            if (timeRange == 'multiple-season-stats') {
              setFilters('season', { id: 'season', value: getNextOrCurrentSeason() });
            } else {
              removeFilter('season');
            }
          }}
        />
        <Filters.Select
          id={'season'}
          label="Target Season"
          options={seasonOptions}
          width={100}
          meta={{
            ignoreOnQuery: true,
          }}
          hide={timeRange != 'multiple-season-stats'}
        />
        <Filters.Number
          id={'platform-prior-seasons'}
          label={'Prior Seasons'}
          isClearable
          min={0}
          max={5}
          hide={ignorePriorSeasonsForTimeRange(timeRange)}
          meta={{
            ignoreOnQuery: true,
          }}
        />
        {timeRange == 'custom-sl-stats' && <CustomStatQueryFilters />}
      </Row>
    </Column>
  );
};

export const CustomStatQueryFilters = ({}) => {
  const { season, custom_sl_filters: customSlFilters } = useFilterValues();
  const defaults = {
    target: 'skaters',
    filters: !!customSlFilters
      ? customSlFilters.filters
      : {
          season,
          league: 'NHL',
          game_type: 'nhl_regular',
        },
  };

  const context = useSLStatContext({
    defaults,
    skip: true,
  });

  return (
    <SLStatContextProvider context={context}>
      <Row columnGap={3} rowGap={2} flexWrap>
        <SeasonsFilter selectProps={{ isClearable: false }} minWidth={200} />
        <DateRangeFilter />
        <GameTypeFilter />
        <StrengthFilter />
      </Row>
      <SLQueryListener />
    </SLStatContextProvider>
  );
};

const SLQueryListener = ({}) => {
  const { query, filters, target } = useContext(SLStatContext);
  const { setFilters } = useFilterContext((state) => state.actions);
  const queryFilters = query?.filters;

  useEffect(() => {
    setFilters('custom_sl_filters', {
      id: 'custom_sl_filters',
      value: { queryFilters, filters },
      meta: { ignoreOnQuery: true },
    });
  }, [JSON.stringify(queryFilters)]);
};
