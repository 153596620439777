import OpenReplay from '@openreplay/tracker';

let _tracker = null;

export function initTracker({ plugins }) {
  if (!process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY) return {};

  _tracker = new OpenReplay({
    projectKey: process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY,
    ingestPoint: "https://replay.bruinsdataden.com/ingest"
  });

  let pluginResults = {};
  if (plugins) {
    Object.keys(plugins).forEach((pk) => {
      pluginResults[pk] = _tracker.use(plugins[pk]());
    });
  }

  return pluginResults;
}

export function startTracker(user) {
  if (navigator.doNotTrack || !_tracker) return;

  return _tracker.start(user);
}

export function trackIssue(key, payload) {
  if (!_tracker?.isActive()) return;

  return _tracker.issue(key, payload);
}

export function trackError(error) {
  console.error(error);

  if (!_tracker?.isActive()) return;

  return _tracker.handleError(error);
}

export function getTrackerSessionUrl() {
  if (!_tracker?.isActive()) return;
  
  return _tracker.getSessionURL({ withCurrentTime: true })
}
