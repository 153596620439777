import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_PLAYER_HOCKEY_MODEL_RESULTS } from 'apollo/queries/models.queries';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HighlightBox } from 'components/bdd/warcomponents';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import styled from 'styled-components';
import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';

import {
  faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons';
import SimilarPlayersModal from './SimilarPlayersModal';
import { getCurrentDraftYear } from 'helpers/hockeyutils';
import { useState } from 'react';

const SimilarPlayersIcon = styled.div({
  alignItems: 'center',
  gap: theme.spacing[1],
  padding: theme.spacing[1],
});


export const AnalyticModelScores = ({ rinknetId, bddPlayer }) => {

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_HOCKEY_MODEL_RESULTS, {
      variables: {
        modelSlugs: ['draft-production-outcome', 'draft-scouting-outcome', 'draft-overall-outcome', 'draft-sl-outcome', 'draft-scouting-nhl', 'draft-production-nhl', 'draft-overall-nhl', 'draft-sl-nhl', 'draft-scouting-to-pick-number', 'draft-production-to-pick-number', 'draft-overall-to-pick-number',  'draft-sl-to-pick-number', 'draft-similar-players'],
        rinknetId: rinknetId,
      },
    })
  );
  const [showSimilarPlayers, setShowSimilarPlayers] = useState(false);

  if (!data) return placeholder;
  
  if (!data.hockeyModelResults || data.hockeyModelResults.length == 0) {
    return (
      <Typography variant="body1">
        <em>No model scores found</em>
      </Typography>
    );
  }

  const scoutingModels = [
    { title: 'Scouting', outcomeSlug: 'draft-scouting-outcome', nhlSlug: 'draft-scouting-nhl', pickSlug: 'draft-scouting-to-pick-number'},
    { title: 'Production', outcomeSlug: 'draft-production-outcome',  nhlSlug: 'draft-production-nhl', pickSlug: 'draft-production-to-pick-number'},
    { title: 'Overall', outcomeSlug: 'draft-overall-outcome',  nhlSlug: 'draft-overall-nhl', pickSlug: 'draft-overall-to-pick-number'},
    { title: 'SL Overall', outcomeSlug: 'draft-sl-outcome',  nhlSlug: 'draft-sl-nhl', pickSlug: 'draft-sl-to-pick-number'}
  ]


  const similarPlayersModels = [
    { title: 'Made It %', slug: 'draft-similar-players'},
  ]

  const modelToScore = Object.fromEntries(data.hockeyModelResults.map(result => [result.modelSlug, result]))

  const modelHover = (modelToScore, outcomeSlug, nhlSlug, pickSlug) => {

    const outcomeModelDetails = !!modelToScore[outcomeSlug]?.details ? modelToScore[outcomeSlug].parsedDetails: null
    const pNotNHL =  !!modelToScore[nhlSlug]?.modelScore ? modelToScore[nhlSlug].modelScore : null
    const expectedPick =  !!modelToScore[pickSlug]?.modelScore ? modelToScore[pickSlug].modelScore : null
    if (!outcomeModelDetails) {
      return <></>
    }
    const probabilities = [
      {'key': 'pT6', 'title': 'Top'},
      {'key': 'pM6', 'title': 'Middle' },
      {'key': 'pB6', 'title': 'Bottom' },
      {'key': 'pDep', 'title': 'Depth'},
      {'key': 'pDW', 'title': "Not NHL"}
    ]
    const probs = probabilities.filter((prob) => !!outcomeModelDetails[prob.key] )
    const maxScore = probs.map(({key, title}) => outcomeModelDetails[key]).sort((a,b) => b - a)[0]
    
    return (
      <Container>
        <Row columnGap={1}>
          <div>
            {probabilities.filter((prob) => !!outcomeModelDetails[prob.key] ).map( ({key, title}) =>
              <HighlightBox
                key={key}
                title={(
                  <Typography variant='body1' textAlign='center'>
                    {title}
                  </Typography>
                )}
                content={(
                  <Typography variant="stat">
                    {roundToX(outcomeModelDetails[key], 2, true)}
                  </Typography>
                )}
                color={getRedGreenColor(outcomeModelDetails[key], 0, maxScore)} /* highlight according to max score. Every model score has red and green */
              />
          )}
          </div>
        </Row>
        <Row columnGap={1}>
          <div>
            <HighlightBox
              key={'nhl'}
              title={(
                <Typography variant='body1' textAlign='center'>
                  {'NHL'}
                </Typography>
              )}
              content={(
                <Typography variant="stat">
                  {roundToX(1 - pNotNHL, 2, true)}
                </Typography>
              )}
              color={getRedGreenColor(1 - pNotNHL, 0, 1)}
            />
            <HighlightBox
              key={'not-nhl'}
              title={(
                <Typography variant='body1' textAlign='center'>
                  {'Not NHL'}
                </Typography>
              )}
              content={(
                <Typography variant="stat">
                  {roundToX(pNotNHL, 2, true)}
                </Typography>
              )}
              color={getRedGreenColor(pNotNHL, 0, 1, true)}
            />
          <HighlightBox
              key={'expected-pick'}
              title={(
                <Typography variant='body1' textAlign='center'>
                  {'Pick #'}
                </Typography>
              )}
              content={(
                <Typography variant="stat">
                  {roundToX(expectedPick, 0, true)}
                </Typography>
              )}
              color={getRedGreenColor(expectedPick, 0, 224, true)}
            />
          </div>
        </Row>
      </Container>
    );
  }; 

  return (
    <Container>
      <Row columnGap={2}>
        <div>
          {scoutingModels.map(({ title, outcomeSlug, nhlSlug, pickSlug }) => 
            <HighlightBox
              key={outcomeSlug}
              title={(
                <Typography variant='body1' textAlign='center'>
                  {title}
                </Typography>
              )}
              content={(
                <Typography variant="stat">
                  {roundToX(modelToScore[outcomeSlug]?.percentile * 100, 0)}
                </Typography>
              )}
              hoverContent={modelHover(modelToScore, outcomeSlug, nhlSlug, pickSlug)}
              color={getRedGreenColor(modelToScore[outcomeSlug]?.percentile, 0, 1)}
            />
          )}
          <SimilarPlayersIcon
            as={TooltipSpan}
            content="Click for similar players"
            onClick={() => setShowSimilarPlayers(true)}
          >
            <HighlightBox
              key={'similar-players-made-it'}
              title={(
                <Typography variant='body1' textAlign='center'>
                  {'Made It %'}
                </Typography>
              )}
              content={(
                <Typography variant="stat">
                  {roundToX(modelToScore['draft-similar-players']?.parsedDetails.metrics?.made_it_pct, 0)}
                </Typography>
              )}
              color={getRedGreenColor(modelToScore['draft-similar-players']?.parsedDetails.metrics?.made_it_pct, 0, 40)}
            />
          </SimilarPlayersIcon>
        {!!bddPlayer && showSimilarPlayers && (
          <SimilarPlayersModal
            show={showSimilarPlayers}
            handleClose={() => setShowSimilarPlayers(false)}
            bddPlayer={bddPlayer}
            draftYear={getCurrentDraftYear()}
          />
        )}
        </div>
      </Row>
    </Container>
  );
};
