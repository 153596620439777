import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { theme } from 'constants';

import BDDInputField from 'components/form/bddinputfield';
import BDDSelectField from 'components/form/bddselectfield';
import { Container } from 'components/bdd/Layout';
import useTeamSelect from 'components/bdd/Select/useTeamSelect';
import BDDToggleField from 'components/form/BDDToggleField';
import BDDTextAreaField from 'components/form/bddtextareafield';
import { Typography } from 'components/bdd/Typography';
import { UPDATE_BF_DRAFT_PICKS } from 'apollo/queries/bruinsfriendly.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing[2],
});

export const UpdateBFPickForm = ({ pick }) => {
  const [updateBFDraftPick] = useMutation(UPDATE_BF_DRAFT_PICKS, {
    onError: toastBddApiError
  });

  const schema = Yup.object({
    originalTeamSlug: Yup.string(),
    currentTeamSlug: Yup.string(),
    isConditional: Yup.boolean(),
    conditionalDetails: Yup.string(),
    additionalDetails: Yup.string()
  });

  const initialValues = {
    overall: pick.overall,
    round: pick.round,
    originalTeamSlug: pick.originalTeamSlug,
    currentTeamSlug: pick.currentTeamSlug,
    isConditional: pick.isConditional,
    conditionalDetails: pick.conditionalDetails || '',
    additionalDetails: pick.additionalDetails || ''
  };

  const { teamOptions } = useTeamSelect({
    leagueSlug: 'NHL',
    valueType: 'bfTeamSlug'
  });

  const handleSubmit = (values, { setSubmitting }) => {
    updateBFDraftPick({
      variables: {
        picks: [{
          id: pick.id,
          ...values,
          overall: pick.overall,
          round: pick.round
        }]
      },
      onCompleted: () => {
        setSubmitting(false);
        toastInfo('Draft pick updated');
      }
    })
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors }) => {
        return (
          <StyledForm>
            <Container width={300}>
              <Field
                type="number"
                title='Round'
                name="round"
                placeholder="Round"
                component={BDDInputField}
                disabled={true}
              />
            </Container>

            <Container width={300}>
              <Field
                type="number"
                title='Overall'
                name="overall"
                placeholder="Overall"
                component={BDDInputField}
                disabled={true}
              />
            </Container>

            <Container width={300}>
              <Field
                type="text"
                title='Original Team'
                name="originalTeamSlug"
                placeholder="Original Team"
                component={BDDSelectField}
                options={teamOptions}
              />
            </Container>
            <Container width={300}>
              <Field
                type="text"
                title='Current Team'
                name="currentTeamSlug"
                placeholder="Current Team"
                component={BDDSelectField}
                options={teamOptions}
              />
            </Container>

            <Container>
              <Field
                title='Is Conditional'
                name="isConditional"
                component={BDDToggleField}
              />
            </Container>

            {values.isConditional && (
              <Container width={300}>
                <Field
                  type="text"
                  title='Conditional Details'
                  name="conditionalDetails"
                  placeholder="Conditional Details"
                  component={BDDTextAreaField}
                />
              </Container>
            )}

              <Container width={300}>
                <Field
                  type="textarea"
                  title='Additional Details'
                  name="additionalDetails"
                  placeholder="Additional Details"
                  component={BDDTextAreaField}
                />
              </Container>

            <Button
              type="submit"
              variant="outline-dark"
              disabled={isSubmitting}
            >
              <Typography variant='body1'>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Typography>
            </Button>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

