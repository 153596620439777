import { Button } from "react-bootstrap";
import { Archive } from "react-bootstrap-icons";
import { IconButton } from ".";
import { Row, Column } from "../Layout";
import useModal from "../Modal/useModal";
import { Typography } from "../Typography";



export const ArchiveButton = ({
  onArchive,
  itemName='item',
  tooltip='Archive'
}) => {

  const { setShowModal, renderModal } = useModal({ title: 'Confirm' });
  const modal = renderModal(
    <Column gap={2}>
      <Typography variant="subtitle2">
        Are you sure you want to archive this {itemName}? This will not permanently
        delete the {itemName}, but only a developer will be able to undo this action.
      </Typography>
      <Row columnGap={2} justifyContent="end">
        <Button
          size="sm"
          variant="outline-dark"
          type="button"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button size="sm" variant="danger" type="button" onClick={() => { 
          onArchive()
          setShowModal(false)
        }}>
          Delete
        </Button>
      </Row>
    </Column>
  );

  return <>
    {modal}
    <IconButton
      icon={<Archive />}
      onClick={() => setShowModal(true)}
      tooltip={tooltip}
    />
  </>
}
