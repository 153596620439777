import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';
import { SlashCircle, Trash } from 'react-bootstrap-icons';

export const BFBoughtOutTag = ({ bfPlayer, contract, ...tagProps }) => {
  if (!contract?.isBoughtOut) return null;

  return (
    <BDDTag
      {...tagProps}
      tag={{
        color: theme.colors.states.neutral,
        textColor: theme.colors.dark.text.primary,
        tag: (
          <Typography variant="body1">
            <Icon color={theme.colors.light.background} icon={<Trash />} />
          </Typography>
        ),
        description: 'Contract bought out',
      }}
    />
  );
};
