import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { birthdateToAge, formatDate } from "../../helpers/helpers"
import { heightToNum, longToShortLeague } from "../../helpers/hockeyutils"
import { getNHLPlayerHeadshot } from "../../helpers/nhlcontent"
import { buildPlayerRoute } from "../../helpers/routing"
import BDDApiError from "../bdd/bddapierror"

import { BDDLoader } from "../bdd/bddloader"
import { SectionHeader } from "../reports"

const GET_PLAYER = gql`query GetPlayer($slug: ID!) {
  bddPlayer(slug: $slug) {
    id
    slug
    slUid
    rinknetId
    firstname
    lastname
    slPlayerMaster {
      id
      slPlayer {
        league
      }
    }
    rinknetPlayer {
      id
      nhlid
      position
      birthdate
      height
      weight
      hometown
      nationality
      currentteam
      currentleague
      nhlContracts {
        dateSigned
        averagedAmount
        signedWithTeamShort
        isActive
      }
    }
  }
}`

export default function BDDPlayerModal(props) {
    const { loading, data, error } = useQuery(GET_PLAYER, { variables: { slug: props.slug } })

    if (loading) return <BDDLoader style={{margin: "100px"}}/>
    if (!!data) {
        var p = data.bddPlayer
        var rp = p.rinknetPlayer
        var sl = p.slPlayerMaster    
        var currleague = longToShortLeague(rp.currentleague)
    }
    return <Modal
        size="lg"
        show={props.show}
        onHide={props.handleClose}
    >
        {!!error ? <Modal.Body>
            <BDDApiError error={error} />
        </Modal.Body>
        : !!data ? <>
        <Modal.Header closeButton style={{padding: "5px"}}>
            {rp.nhlid ? <Link to={buildPlayerRoute(p.slug)}>
                <img src={getNHLPlayerHeadshot(rp.nhlid)} height="40px" style={{borderRadius: "50%", margin: 0}}/>
            </Link> : null }
            <div style={{width: "100%"}}>
                <center>
                    <div>
                    <Link to={buildPlayerRoute(p.slug)}>
                        <SectionHeader>{p.firstname} {p.lastname}</SectionHeader>
                    </Link>
                    </div>
                </center>
            </div>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                <small>
                    <div>{rp.position}</div>
                    <div>{formatDate(rp.birthdate, {format: '%Y/%m/%d'})} ({birthdateToAge(rp.birthdate)}yo)</div>
                    <div>{rp.currentteam}, {currleague}</div>
                    <div>{rp.height.replace('.', "'")}, {rp.weight} lbs</div>
                    <div>{rp.hometown}</div>
                </small>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Link to={buildPlayerRoute(p.slug)}>
                <small><em>Go to Playercard</em></small>
            </Link>
        </Modal.Footer>
        </> : null }
    </Modal>
}