import useDebounce from 'components/bdd/hooks/usedebounce';
import { BDDInput } from 'components/bdd/Input';
import { theme } from 'constants';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Transforms } from 'slate';
import {
  ReactEditor,
  useSlateStatic,
} from 'slate-react';
import styled from 'styled-components';
import { EMPTY_TEXT } from '..';

const TextInput = styled(BDDInput)({
  ...theme.typography.body2,
});

export const HyperlinkField = ({ children, readOnly, attributes, element }) => {
  const editor = useSlateStatic();
  const [link, setLink] = useState(element.link);
  const debouncedLink = useDebounce(link);

  const path = ReactEditor.findPath(editor, element);

  useEffect(() => {
    if (!debouncedLink) return;

    Transforms.setNodes(
      editor,
      { ...element, link: debouncedLink },
      { at: path, text: EMPTY_TEXT }
    );
  }, [debouncedLink]);

  return (
    <span>
      <Link
        style={{ cursor: 'pointer' }}
        to={`/${link}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </Link>
      {!readOnly && (
        <TextInput
          style={{
            width: 250,
            paddingLeft: theme.spacing[2],
            paddingRight: theme.spacing[2],
          }}
          contentEditable={false}
          type="text"
          onClick={(e) => e.stopPropagation()}
          value={link || ''}
          onChange={(e) => {
            setLink(e.target.value);
          }}
          placeholder={'Add link within BDD'}
        />
      )}
    </span>
  );
};
