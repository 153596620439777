import { useQuery } from "@apollo/client"
import { GET_BDD_NOTIFICATION_FILTERS, GET_PAGINATED_NOTIFICATIONS } from "apollo/queries/notification.queries"
import { usePaginated } from "components/bdd/Paginated"
import usePlaceholder from "components/Placeholder/usePlaceholder"


export default function useNotifications({
  variables,
  defaultLimit,
  skip,
}) {

  console.log('HERE??')

  const { 
    paginatedData, 
    pageInfo, 
    pageControls, 
    loadingWrapper,
    data,
    placeholder, 
    loading, 
    error 
  } = usePaginated({ 
    query: GET_PAGINATED_NOTIFICATIONS, 
    key: 'paginatedNotifications',
    defaultLimit,
    defaultOffset: 0,
    variables,
    skip,
  })

  // we apply some client side filters here to update our views when the cache changes
  // e.g. if a notification gets archived
  const filteredNotifications = paginatedData?.filter(n => {
    if (!variables.includeArchived && n.isArchived) return false
    return true
  })

  return {
    notifications: filteredNotifications,
    pageInfo,
    pageControls,
    loadingWrapper,
    data,
    placeholder,
    loading,
    error
  }
}