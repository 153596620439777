import { Container } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { CreateUpdateSigning } from "./CreateUpdateSigning";


export const AddObjectiveSigningVariant = ({ transaction }) => {
  const proObjectiveSlug = transaction.proObjectiveSlug;
  const bddPlayer = transaction.bddSignings?.[0]?.bddPlayer;
  if (!bddPlayer) return <Typography variant='body2'>Unknown player to create signing variant for.</Typography>
  return <Container>
    <Typography variant='subtitle1'>Signing {bddPlayer.firstname} {bddPlayer.lastname}</Typography>
    <CreateUpdateSigning
      proObjectiveSlug={proObjectiveSlug}
      bddPlayerSlug={bddPlayer.slug}
      addToPOTransactionId={transaction.id}
      showSimilar={false}
    />
  </Container>
}