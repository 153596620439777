import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup"

import ColorDropdown from './colordropdown'
import { Row, Col, Button } from 'react-bootstrap'
import BDDSelectField from '../form/bddselectfield'
import BDDInputField from '../form/bddinputfield'
import BDDCheckboxField from '../form/bddcheckboxfield'
import BDDFormError from "../bdd/bddformerror"
import { useUser } from '../../helpers/user'
import { BDDInput } from '../bdd'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_BDD_TAG, CREATE_BDD_TAG_TYPE, DELETE_BDD_TAG, GET_BDD_TAG_TYPES, UPDATE_BDD_TAG } from '../../apollo/queries/bddtags.queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import BDDTag from './bddtag'
import { toastBddApiError, toastError, toastInfo } from '../bdd/bddtoasts'
import { Typography } from 'components/bdd/Typography'
import { Divider } from 'components/bdd/Divider'

const Styles = styled.div`
    .row {
        margin-top: 10px;
    }
    label {
        font-weight: bold;
        margin-right: 10px;
    }
`
const MAX_TAG_LENGTH = 30
export default function AddBDDTagForm({
  isUpdate = false,
  initialValues: initialValuesArg,
  onCompleted
}) {
  const { isUserAdmin } = useUser();
  const [completed, setCompleted] = useState(false)
  const { data, loading } = useQuery(GET_BDD_TAG_TYPES)
  const [createBddTag] = useMutation(CREATE_BDD_TAG, {
    update: (cache, { data: { createBddTag: { bddTag } } }) => {
      cache.modify({
        fields: {
          bddTags(existing = []) {
            return [bddTag].concat(existing)
          }
        }
      })
    },
    onCompleted: () => {
      setCompleted(true)
      if (onCompleted) onCompleted()
    },
    onError: toastBddApiError
  })
  const [updateBddTag] = useMutation(UPDATE_BDD_TAG, {
    onCompleted: () => {
      setCompleted(true)
      if (onCompleted) onCompleted()
    },
    onError: toastBddApiError
  })
  const [deleteBddTag] = useMutation(DELETE_BDD_TAG, {
    onCompleted: () => {
      setCompleted(true)
      if (onCompleted) onCompleted()
    },
    onError: toastBddApiError,
    update: (cache, { data: { deleteBddTag: { id } } }) => {
      cache.modify({
        fields: {
          bddTags(existing = [], { readField }) {
            return existing.filter(e => readField('id', e) != id)
          }
        }
      })
    },
  })
  const [createBddTagType] = useMutation(CREATE_BDD_TAG_TYPE, {
    onCompleted: () => toastInfo('Created tag type!'),
    onError: toastBddApiError,
    update: (cache, { data: { createBddTagType: { bddTagType } } }) => {
      cache.modify({
        fields: {
          bddTagTypes(existing = []) {
            return existing.concat([bddTagType])
          }
        }
      })
    }
  })

  const handleCreateTagType = (slug) => {
    if (!slug?.length) {
      toastError('Must enter a tag type to create')
      return;
    }
    createBddTagType({
      variables: { input: { slug } }
    })
  }

  const schema = Yup.object({
    tag: Yup.string().required('You must enter a tag'),
    tagType: Yup.string().nullable(),
    customTagType: Yup.string().nullable(),
    requiresPosition: Yup.string().nullable(),
    color: Yup.string().required('You must select a color'),
    textColor: Yup.string().required('You must select a text color'),
    description: Yup.string().nullable(),
    isBddTag: Yup.boolean().required('isBddTag is a required field')
  })

  const defaultInitialValues = {
    tag: '',
    description: '',
    tagType: null,
    customTagType: '',
    requiresPosition: null,
    color: '',
    textColor: 'Black',
    isBddTag: false,
    priority: 0,
  }
  if (isUpdate) {
    // Only copy the above values from the initialValuesArg (to skip __typename etc.)
    var initialValues = { customTagType: '' }
    Object.keys(defaultInitialValues).forEach(key => {
      if (key in initialValuesArg) {
        initialValues[key] = initialValuesArg[key]
      }
    })
    initialValues.id = initialValuesArg.id
  } else {
    var initialValues = defaultInitialValues
  }

  const handleSubmit = values => {
    console.log(values)
    if (!values.tagType)
      if (!!values.customTagType && values.customTagType.length > 0) {
        values.tagType = values.customTagType
      }
    const input = { ...values }
    delete input.customTagType
    if (isUpdate) {
      updateBddTag({
        variables: {
          input: input
        }
      })
    } else {
      createBddTag({
        variables: {
          input: input
        }
      })
    }
  }

  const handleDelete = () => {
    if (confirm('Are you sure you want to delete this tag?  It will be removed from every player.')) {
      deleteBddTag({ variables: { id: initialValuesArg.id } })
    }
  }

  return <Styles>
    {isUpdate && isUserAdmin() && <div>
      <center>
        <a href="" onClick={e => {
          e.preventDefault()
          handleDelete()
        }}>
          <small><em>Delete tag</em></small>
        </a>
      </center>
    </div>}
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ values, handleChange, isSubmitting }) => {
        return <Form>
          <Row>
            <Col>
              <BDDInput type='text'
                name='tag'
                placeholder={`Enter tag (limit ${MAX_TAG_LENGTH} characters)`}
                value={values.tag}
                onChange={ev => {
                  if (ev.target.value.length <= MAX_TAG_LENGTH) handleChange(ev)
                }}
                isInvalid={values.tag.length == MAX_TAG_LENGTH}
              />
              {values.tag.length == MAX_TAG_LENGTH && <em><small>Max tag length is {MAX_TAG_LENGTH} characters</small></em>}
              <BDDFormError>
                <ErrorMessage name={'tag'} />
              </BDDFormError>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field type='text'
                name="description"
                placeholder='Description'
                component={BDDInputField}
              />
            </Col>
          </Row>
          <Row>
            <div style={{ margin: '15px', padding: '5px 25px', border: '1px solid #ddd' }}>
              <div>
                <Row>
                  <Col>
                    <BDDInput type='text'
                      name='customTagType'
                      placeholder={`Enter a new tag type (only if it doesn't exist)`}
                      value={values.customTagType}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col sm='auto'>
                    <Button variant='outline-dark' size='sm'
                      disabled={!values.customTagType?.length}
                      onClick={() => {
                        handleCreateTagType(values.customTagType)
                        handleChange({ target: { name: 'tagType', value: values.customTagType } })
                      }}
                    >
                      Create Tag Type
                    </Button>
                  </Col>
                </Row>

                <small><em>Tag type is not required</em></small>

                <Field type='text'
                  name="tagType"
                  placeholder='Choose a tag type...'
                  component={BDDSelectField}
                  options={!!data ? data.bddTagTypes.map(tt => ({
                    label: tt.slug, value: tt.slug
                  })) : [
                    { label: 'Availability', value: 'AVAILABILITY' },
                    { label: 'Role', value: 'ROLE' }
                  ]}
                />

                {!values.tagType && values.customTagType.length > 0 && <small>Make sure you select your newly created tag type</small>}
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Field type='text'
                name="requiresPosition"
                placeholder={`Require a position (or don't)...`}
                component={BDDSelectField}
                options={[
                  { label: 'F', value: 'F' },
                  { label: 'D', value: 'D' },
                  { label: 'G', value: 'G' }
                ]}
                isMulti
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field type='number'
                name="priority"
                placeholder={`Priority (determines tag order in tag bank)`}
                component={BDDInputField}
              // min={0}
              />
            </Col>
          </Row>
          {/* <Row>
                    <Col>
                        <Field type='text' 
                            name="requiresPosition"
                            placeholder={`Flags`}
                            component={BDDSelectField}
                            options={[
                                { label: 'Projected role', value: 'isProjection' }
                            ]}
                            isMulti
                        /> 
                    </Col>
                </Row> */}
          <Row>
            <Col>
              <ColorDropdown
                name='color'
                placeholder='Choose background...'
                value={values.color}
                onChange={(name, value) => handleChange({ target: { name, value } })}
              />
            </Col>
            <Col>
              <ColorDropdown
                colors={['White', 'Black', 'Red']}
                name='textColor'
                placeholder='Choose text color...'
                value={values.textColor}
                onChange={(name, value) => handleChange({ target: { name, value } })}
              />
            </Col>
          </Row>
          {isUserAdmin() && <Row>
            <Col>
              <Field type='checkbox'
                name="isBddTag"
                title='Is BDD Tag?'
                component={BDDCheckboxField}
              />
            </Col>
          </Row>}

          <Divider />

          <div>
            <Typography variant='body1'>
              Preview:
            </Typography>
            {' '}
            <BDDTag tag={values} />
          </div>

          <Divider />

          <div>
            <center>
              <Button variant='primary' type="submit">
                {completed ? <><FontAwesomeIcon icon={faCheck} /> Submitted</>
                  : isSubmitting ? 'Submitting...'
                    : 'Submit'}
              </Button>
            </center>
          </div>
        </Form>
      }}
    </Formik>
  </Styles>
}