import React, { createContext, useEffect, useState } from 'react';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useSimilarContractsContext } from './context';
import { useQuery } from '@apollo/client';
import { GET_BF_SIMILAR_CONTRACT_VECTOR } from 'apollo/queries/bruinsfriendly.queries';
import { Typography } from 'components/bdd/Typography';
import { FilterTag } from 'components/bdd/Filters';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { LinkPopover } from 'components/bdd/Popover';
import { SimilarContractsTargetWeights, TargetWeights } from './TargetWeights';
import { getTargetVectorWeights } from './helpers';
import { useContractSearchContext } from '../context';
import { roundToX } from 'helpers/data';

export const TargetVector = ({ applyTargetVectorFilters }) => {
  const filters = useSimilarContractsContext((state) => state.targetDetails);
  const targetWeights = useSimilarContractsContext((state) => state.targetWeights);
  const targetVector = useSimilarContractsContext((state) => state.targetVector);
  const timeRange = useSimilarContractsContext((state) => state.timeRange);
  const { setState } = useSimilarContractsContext((state) => state.actions);

  const searchFilters = useContractSearchContext((state) => state.filters);
  const { setFilters, applyFilters } = useContractSearchContext((state) => state.actions);

  const variables = {
    filters: [
      {
        col: 'season',
        cond: '=',
        val: getCurrentSeason(),
      },
      {
        col: 'position_fdg',
        cond: '=',
        val: filters?.['position_fdg']?.value,
      },
      ...(!!filters?.['id']?.value
        ? [
            {
              col: 'id',
              val: filters?.['id']?.value,
            },
          ]
        : []),
      ...(!!filters?.['player_slug']?.value
        ? [
            {
              col: 'player_slug',
              val: filters?.['player_slug']?.value,
            },
          ]
        : []),
    ],
    timeRange: timeRange(),
    targetMode: filters?.['targetMode']?.value,
    statMode: filters?.statsMode?.value,
  };

  const { data, loading } = useQuery(GET_BF_SIMILAR_CONTRACT_VECTOR, {
    variables,
    skip: !filters?.['id'] && !filters?.['player_slug'],
    onCompleted: (data) => {
      const targetVector = data.bfSimilarContractTargetVector;

      if (!applyTargetVectorFilters) {
        setFilters({
          ...searchFilters,
          position_fdg: {
            id: 'position_fdg',
            value: targetVector['bf_player_position_fdg'],
          },
          signing_status: {
            id: 'signing_status',
            value: targetVector['signing_status'],
          },
          signing_age: {
            id: 'signing_age',
            type: 'inputRange',
            value: {
              min: roundToX(targetVector['bf_player_age'] - 2, 2),
              max: roundToX(targetVector['bf_player_age'] + 2, 2),
            },
          },
        });

        applyFilters();
      }

      setState({ targetVector });
    },
  });

  const targetVectorWeights =
    targetVector && getTargetVectorWeights(targetVector, targetWeights);

  const tags =
    targetVector &&
    targetVectorWeights.map((tw) => (
      <React.Fragment key={tw.key}>
        <FilterTag label={tw.label || tw.value} name={tw.header} />
      </React.Fragment>
    ));

  return (
    <LinkPopover
      hideTransition
      renderLink={(setShow, target) => (
        <Container
          variant="button"
          ref={target}
          width="fit-content"
          onClick={() => setShow((show) => !show)}
        >
          <Row columnGap={2} rowGap={2} flexWrap>
            <Typography variant="subtitle1">Target Details:</Typography>
            {!!loading && (
              <Typography variant="body2">
                <em>Loading new target vector...</em>
              </Typography>
            )}
            {!!data && (
              <Row columnGap={1} flexWrap>
                {tags}
              </Row>
            )}
          </Row>
        </Container>
      )}
      placement="bottom"
      renderContent={(forceClose) => {
        return (
          <Container onClick={(e) => e.stopPropagation()}>
            <SimilarContractsTargetWeights onChange={forceClose} />
          </Container>
        );
      }}
    />
  );
};
