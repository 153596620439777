import { gql } from '@apollo/client';

export const SLEventFragment = gql`
  fragment slEventFragment on SLEvents {
    id
    gameUid
    slGame {
      id
      league
      gameDate
      season
      gameId
      homeTeamSlug
      awayTeamSlug
    }
    eventId
    teamSlug
    team {
      nhlid
      slug
      longname
      shorthand
    }
    oppTeamSlug
    oppTeam {
      nhlid
      slug
      longname
      shorthand
    }
    period
    gameTime
    formattedTime
    manpowerSituation
    strength
    zone
    eventName
    player {
      leaguePlayerId
      playerId
      leagueSlug
      firstname
      lastname
      jerseynumber
    }
    playerId
    outcome
    eventType
    eventFlags
    precedingFlags
    successiveFlags
    value
    value2
    xAdjCoord
    yAdjCoord
    location
  }
`;


export const SLPlaysequenceEventFragment = gql`fragment slPlaysequenceEventFragment on SLPlaysequenceEvents {
  eventKey
  league
  season
  gameId
  homeTeam
  awayTeam
  eventId
  gameTime
  period
  periodTime
  team
  teamInPossession
  eventName
  eventType
  eventFlags
  eventOutcome
  eventZone
  playerJersey
  playerPosition
  playerFirstName
  playerLastName
  playerReferenceId
  opposingTeamGoalieOnIceRef
  isPossessionEvent
  xAdjCoord
  yAdjCoord
}`