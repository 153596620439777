import { Border, Column, Container, Row } from 'components/bdd/Layout';
import usePlayerViewStats from '../hooks/usePlayerViewStats';
import { Typography } from 'components/bdd/Typography';
import { getRedGreenColor } from 'helpers/tables';
import { roundToX } from 'helpers/data';
import { HoverInteractive } from 'components/bdd';
import styled from 'styled-components';
import { theme } from 'constants';
import Icon from 'components/bdd/Icon';
import { Asterisk } from 'react-bootstrap-icons';
import { formatDate, formatMinutes } from 'helpers/helpers';
import React from 'react';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import { GET_PLAYER_VIEW_STATS_BY_GAME } from 'apollo/queries/scouting.queries';
import { season8to4 } from 'helpers/hockeyutils';

const StyledTable = styled.table({
  width: '100%',
  textAlign: 'center',
  th: {
    ...theme.typography.body1,
    borderBottom: '1px solid #ddd',
  },
  td: {
    ...theme.typography.body2,
    borderBottom: '1px solid #ddd',
  },
});

const SMALL_SAMPLE_GP = 3;
export const PlayerViewStats = React.memo(({ rinknetId, rinknetIds, horizontal, season }) => {
  const { idToStats } = usePlayerViewStats({ rinknetIds, season });
  const stats = idToStats?.[rinknetId]?.statistics;
  if (!stats?.seen?.pbr || !stats?.unseen?.pbr) return null;
  const renderStats = (title, key) => {
    if (!stats?.[key]?.pbr) return null;
    return (
      <tr>
        <th>{title}</th>
        <td>{stats[key].gamesPlayed}</td>
        <td>{stats[key].goals}</td>
        <td>{stats[key].assists}</td>
        <td>{stats[key].points}</td>
        <td style={{ background: getRedGreenColor(stats[key].pbr, 0, 1) }}>
          {roundToX(stats[key].pbr * 100, 1)}
        </td>
      </tr>
    );
  };

  const delta = stats.seen.pbr - stats.unseen.pbr;
  const gamesSeen = stats.seen.gamesPlayed;
  const isSmallSample = gamesSeen < SMALL_SAMPLE_GP;
  
  const color = getRedGreenColor(delta, -0.15, 0.15);
  const background = isSmallSample
    ? `repeating-linear-gradient(-45deg, ${color}, ${color} 5px, white 5px, white 10px)`
    : color;
  const deltaBox = (
    <Border style={{ background }}>
      <Container padding={0.5} paddingLeft={1} paddingRight={1}>
        <Typography variant="stat" textAlign="center">
          {delta > 0 && '+'}
          {roundToX(delta * 100, 0)}
        </Typography>
      </Container>
    </Border>
  );

  return (
    <Container>
      <HoverInteractive
        content={
          <Container
            style={{ maxWidth: '400px', maxHeight: '400px', overflowY: 'auto' }}
            onClick={(ev) => ev.stopPropagation()}
          >
            <Column gap={2}>
              <Typography variant="caption">
                PBR scores in {!!season ? `${season8to4(season, true)} season` : 'the past year'} in "All" games, "Seen" games (reported on),
                and "Unseen" games (no reports)
              </Typography>
              {isSmallSample ? (
                <Row gap={2} justifyContent="center" style={{ color: 'red' }}>
                  <Icon icon={<Asterisk />} size={12} />
                  <Typography variant="caption">Small sample warning</Typography>
                </Row>
              ) : null}
              <Container>
                <StyledTable>
                  <thead>
                    <tr>
                      <th></th>
                      <th>GP</th>
                      <th>G</th>
                      <th>A</th>
                      <th>P</th>
                      <th>PBR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderStats('All', 'all')}
                    {renderStats('Seen', 'seen')}
                    {renderStats('Unseen', 'unseen')}
                  </tbody>
                </StyledTable>
              </Container>
              <PlayerViewStatsByGame rinknetId={rinknetId} season={season} />
            </Column>
          </Container>
        }
      >
        <Container>
          {!horizontal ? (
            <Column gap={1}>
              <Row justifyContent="center">
                <Typography variant="stat" textAlign="center">
                  View Bias
                </Typography>
                {gamesSeen < SMALL_SAMPLE_GP && <Icon icon={<Asterisk />} size={8} />}
              </Row>
              {deltaBox}
            </Column>
          ) : (
            <Row columnGap={2} justifyContent="center">
              <Typography variant="stat" textAlign="center">
                Bias
              </Typography>
              {gamesSeen < SMALL_SAMPLE_GP && <Icon icon={<Asterisk />} size={8} />}
              {deltaBox}
            </Row>
          )}
        </Container>
      </HoverInteractive>
    </Container>
  );
});

const PlayerViewStatsByGame = ({ rinknetId, season }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_VIEW_STATS_BY_GAME, {
      variables: {
        rinknetId,
        season
      },
    })
  );

  if (!data) return placeholder;

  const stats = [...data.playerViewStatsByGame].sort((a,b) => new Date(b.game.game_date) > new Date(a.game.game_date) ? 1 : -1);

  if (stats.length == 0) return null;

  return (
    <Container>
      <StyledTable>
        <thead>
          <tr>
            <th>Game</th>
            <th>Scouts</th>
            <th>TOI</th>
            <th>G</th>
            <th>A</th>
            <th>P</th>
            <th>PBR</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((v) => (
            <tr key={v.game.game_uid}>
              <td style={{ maxWidth: '75px' }}>
                {formatDate(v.game.game_date)} {v.game.team_shorthand} vs.{' '}
                {v.game.opp_team_shorthand}
              </td>
              <td style={{ maxWidth: '55px' }}>{v.scouts.join(', ')}</td>
              <td>{formatMinutes(v.game.toi)}</td>
              <td>{v.game.goals}</td>
              <td>{v.game.assists}</td>
              <td>{v.game.points}</td>
              {!!v.game.pbr ? (
                <td style={{ background: getRedGreenColor(v.game.pbr, 0, 1) }}>
                  {roundToX(v.game.pbr * 100, 1)}
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Container>
  );
};
