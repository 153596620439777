import { useQuery } from '@apollo/client';
import {
  GET_PAGINATED_CF_TRADES,
  GET_PAGINATED_CF_TRANSACTIONS,
} from 'apollo/queries/capfriendly.queries';
import { usePaginated } from 'components/bdd/Paginated';
import { useSearchParamsStore } from 'components/bdd/hooks';
import { formatDateGQL } from 'helpers/helpers';
import { useContext, useState } from 'react';
import { TradeContext } from '../Trades/Trades';
import { GET_PAGINATED_BF_TRADES } from 'apollo/queries/bruinsfriendly.queries';

export default function useTrades({ defaultPageSize=50, defaultSort=[{ id: 'date', desc: true }] }) {
  const [sort, setSort] = useState(defaultSort);
  const { store, setStore } = useContext(TradeContext);

  const { data, placeholder, pageControls } = usePaginated({
    query: GET_PAGINATED_BF_TRADES,
    defaultLimit: store.limit || defaultPageSize,
    key: 'paginatedBfTrades',
    variables: {
      ...store,
      startDate: formatDateGQL(store.startDate),
      endDate: formatDateGQL(store.endDate),
      sort,
    },
  });

  const trades = data?.paginatedBfTrades?.data;
  return {
    data,
    trades,
    placeholder,
    pageControls,
    sort,
    setSort,
    store,
    setStore,
    // setFilters: newFilters => setFilters({ ...filters, ...newFilters })
  };
}
