import React from 'react';
import styled from 'styled-components';
import { PencilSquare } from 'react-bootstrap-icons';
import { theme } from 'constants';

// This icon class is only really necessary
// due to the alignment issues of the svg icons
const styles = {
  marginTop: theme.spacing[0.5],
};

const StyledPencilSquare = styled(PencilSquare)(styles);

export const EditIcon = (props) => <StyledPencilSquare {...props} />
