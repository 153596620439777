


/* 
Renders StatReportExplorer along with ability to create/edit reports
*/

import { theme } from "constants";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import StatReportExplorer from "./StatReportExplorer";

const Header = styled.div({
  ...theme.typography.h5
})

export default function StatReportExplorerModal({
  show,
  handleClose,
  ...props
}) {
  return <Modal show={show} onHide={handleClose} size='lg'>
    <Modal.Header closeButton>
      <Header>
        Stat Reports
      </Header>
    </Modal.Header>
    <Modal.Body style={{paddingTop: 0}}>
      <StatReportExplorer {...props} />
    </Modal.Body>
  </Modal>
}