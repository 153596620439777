import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import { theme } from 'constants';
import { LIST_ID_TYPES } from 'constants/lists';
import {
  GET_BDD_PLAYER_NAMES,
  GET_RINKNET_PLAYERS,
} from 'apollo/queries/players.queries';
import {
  GET_PLAYER_LIST_CHANGES,
  UPDATE_PLAYER_LIST_WATCH,
} from 'apollo/queries/playerlist.queries';
import { dateToTimeAgo } from 'helpers/helpers';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import TruncatedText from 'components/bdd/Truncated/TruncatedText';

const Container = styled.div({
  margin: theme.spacing[1],
  maxHeight: 300,
  overflow: 'auto',
});

const TierHeader = styled.div({
  marginTop: theme.spacing[2],
  marginBottom: theme.spacing[2],
});

const Divider = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  width: '100%',
  overflow: 'auto',
});

const Header = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.spacing[1],
  marginBottom: theme.spacing[1],
});

const EmphasisLabel = styled.span(({ color }) => ({
  ...theme.typography.body2,
  ...theme.font.weight.regular,
  marginRight: theme.spacing[1],
  color: color,
}));

const Label = styled.span({
  ...theme.typography.body2,
  marginRight: theme.spacing[1],
});

const ItalicLabel = styled(Label)({
  fontStyle: 'italic',
});

const SESSION_LENGTH_MINUTES = 30;

const groupChangesByUserAndSession = (changes) => {
  const createNewGroup = (groupedChanges, change) => [
    ...groupedChanges,
    {
      username: change.user.username,
      dateCreated: change.dateCreated,
      changes: [change],
    },
  ];

  // Changes are ordered by date descending
  return changes.reduce((groupedChanges, change) => {
    // Create a new group if none exist
    if (groupedChanges.length == 0) {
      return createNewGroup(groupedChanges, change);
    }

    const latestGroup = groupedChanges[groupedChanges.length - 1];

    // Create a new group if a new user is making changes
    if (latestGroup.username != change.user.username) {
      return createNewGroup(groupedChanges, change);
    }

    // Create a new group if there is a new session occurred
    const lastDateCreated = new Date(
      latestGroup.changes[latestGroup.changes.length - 1].dateCreated
    );

    const dateCreated = new Date(change.dateCreated);

    const createNewSession =
      (lastDateCreated.getTime() - dateCreated.getTime()) / 60000 >
      SESSION_LENGTH_MINUTES;

    if (createNewSession) {
      return createNewGroup(groupedChanges, change);
    }

    // Add change to existing group
    latestGroup.changes.push(change);

    return groupedChanges;
  }, []);
};

const TierChange = ({ change }) => {
  const payload = change.parsedPayload;
  let label;

  const fromTitle = (payload.title || payload.from_title) && (
    <TruncatedText maxCharacters={20} allowClose>
      {payload.title || payload.from_title}
    </TruncatedText>
  );

  const toTitle = payload.to_title && (
    <TruncatedText maxCharacters={20} allowClose>
      {payload.to_title}
    </TruncatedText>
  );

  switch (change.changeType) {
    case 'TIER_UPDATED':
      label = (
        <>
          <Label>Tier</Label>
          {fromTitle && <EmphasisLabel>{fromTitle}</EmphasisLabel>}
          {toTitle ? (
            <>
              <Label>renamed to</Label>
              <EmphasisLabel>{toTitle}</EmphasisLabel>
            </>
          ) : (
            <>
              <Label>range updated</Label>
              <div>
                {payload.prev_starts_with_list_number && (
                  <>
                    <Label>from</Label>
                    <EmphasisLabel>
                      #{payload.prev_starts_with_list_number}
                    </EmphasisLabel>
                    <Label>-</Label>
                    <EmphasisLabel>
                      #{payload.prev_ends_with_list_number}
                    </EmphasisLabel>
                  </>
                )}
                <Label>to</Label>
                <EmphasisLabel>
                  #{payload.starts_with_list_number}
                </EmphasisLabel>
                <Label>-</Label>
                <EmphasisLabel>#{payload.ends_with_list_number}</EmphasisLabel>
              </div>
            </>
          )}
        </>
      );
      break;
    case 'TIER_ADDED':
      label = (
        <>
          <Label>New tier</Label>
          <EmphasisLabel color={theme.colors.states.success}>
            added
          </EmphasisLabel>
          <Label>from</Label>
          <EmphasisLabel>#{payload.starts_with_list_number}</EmphasisLabel>
          <Label>-</Label>
          <EmphasisLabel>#{payload.ends_with_list_number}</EmphasisLabel>
        </>
      );
      break;
    case 'TIER_REMOVED':
      label = (
        <>
          <Label>Tier</Label>
          <EmphasisLabel>{fromTitle}</EmphasisLabel>
          <EmphasisLabel color={theme.colors.states.danger}>
            removed
          </EmphasisLabel>
        </>
      );
      break;
  }

  return <Header>{label}</Header>;
};

const PlayerChange = ({ change }) => {
  const payload = change.parsedPayload;
  let label;

  switch (change.changeType) {
    case 'PLAYER_MOVED':
      label = (
        <>
          <EmphasisLabel
            color={
              payload.to_list_number < payload.from_list_number
                ? theme.colors.states.success
                : theme.colors.states.danger
            }
          >
            {`moved ${
              payload.to_list_number < payload.from_list_number ? 'up' : 'down'
            }`}
          </EmphasisLabel>
          <Label>from</Label>
          <EmphasisLabel>#{payload.from_list_number}</EmphasisLabel>
          <Label>to</Label>
          <EmphasisLabel>#{payload.to_list_number}</EmphasisLabel>
        </>
      );
      break;
    case 'PLAYER_ADDED':
      label = (
        <>
          <EmphasisLabel color={theme.colors.states.success}>
            added
          </EmphasisLabel>
          {payload.list_number && (
            <>
              <Label>to</Label>
              <EmphasisLabel>#{payload.list_number}</EmphasisLabel>
            </>
          )}
        </>
      );
      break;
    case 'PLAYER_REMOVED':
      label = (
        <>
          <EmphasisLabel color={theme.colors.states.danger}>
            removed
          </EmphasisLabel>
          {payload.from_list_number && (
            <>
              <Label>from</Label>
              <EmphasisLabel>#{payload.from_list_number}</EmphasisLabel>
            </>
          )}
        </>
      );
      break;
  }

  return (
    <Header>
      <Label>{change.player.fullName}</Label>
      {label}
    </Header>
  );
};

const NoteChange = ({ change }) => {
  const payload = change.parsedPayload;
  let label;

  switch (change.changeType) {
    case 'NOTE_ADDED':
      label = (
        <>
          <Label>Note</Label>
          <EmphasisLabel color={theme.colors.states.success}>
            added
          </EmphasisLabel>
          {change.player && (
            <>
              <Label>to</Label>
              <EmphasisLabel>{change.player.fullName}</EmphasisLabel>
            </>
          )}
          {payload.note && (
            <ItalicLabel>
              <TruncatedText maxCharacters={100} allowClose>
                {payload.note}
              </TruncatedText>
            </ItalicLabel>
          )}
        </>
      );
      break;
    case 'NOTE_REMOVED':
      label = (
        <>
          <Label>Note</Label>
          <EmphasisLabel color={theme.colors.states.success}>
            removed
          </EmphasisLabel>
          {change.player && (
            <>
              <Label>from</Label>
              <EmphasisLabel>{change.player.fullName}</EmphasisLabel>
            </>
          )}
          {payload.note && (
            <ItalicLabel>
              <TruncatedText maxCharacters={100} allowClose>
                {payload.note}
              </TruncatedText>
            </ItalicLabel>
          )}
        </>
      );
      break;
  }

  return <Header>{label}</Header>;
};

const ListChange = ({ change }) => {
  const payload = change.parsedPayload;

  return payload.name ? (
    <Header>
      <Label>List renamed from</Label>
      <EmphasisLabel>{payload.from_name}</EmphasisLabel>
      <Label>to</Label>
      <EmphasisLabel>{payload.name}</EmphasisLabel>
    </Header>
  ) : (
    <Header>
      <Label>Description updated to</Label>
      <ItalicLabel>
        <TruncatedText maxCharacters={25} allowClose>
          {payload.description}
        </TruncatedText>
      </ItalicLabel>
    </Header>
  );
};

const getChangeComponent = (change) => {
  switch (change.changeType) {
    case 'PLAYER_MOVED':
    case 'PLAYER_ADDED':
    case 'PLAYER_REMOVED':
      return <PlayerChange key={change.id} change={change} />;
    case 'TIER_UPDATED':
    case 'TIER_ADDED':
    case 'TIER_REMOVED':
      return <TierChange key={change.id} change={change} />;
    case 'NOTE_ADDED':
    case 'NOTE_REMOVED':
      return <NoteChange key={change.id} change={change} />;
    case 'LIST_UPDATED':
      return <ListChange key={change.id} change={change} />;
  }
};

const LastModifiedHover = ({ list, width=250 }) => {
  const { data: listChangeData, placeholder: listChangePlaceholder } =
    usePlaceholder(
      useQuery(GET_PLAYER_LIST_CHANGES, {
        variables: { id: list.id },
      })
    );

  const [updatePlayerlistWatch] = useMutation(UPDATE_PLAYER_LIST_WATCH);

  useEffect(() => {
    if (list.isWatched) {
      updatePlayerlistWatch({
        variables: { id: list.id },
      });
    }
  }, [list]);

  const isBddList = list.idType === LIST_ID_TYPES.bdd_player_slug;
  const [getPlayers, playerQuery] = useLazyQuery(
    isBddList ? GET_BDD_PLAYER_NAMES : GET_RINKNET_PLAYERS
  );

  const { data: playerData, placeholder: playerPlaceholder } =
    usePlaceholder(playerQuery);

  useEffect(() => {
    if (listChangeData) {
      const listChanges = listChangeData.playerList.changes;
      const ids = [
        ...new Set(
          listChanges
            .map((change) => JSON.parse(change.payload).id)
            .filter((id) => id)
        ),
      ];

      getPlayers({
        variables: {
          ...(isBddList ? { slugs: ids } : { filters: { idIn: ids } }),
        },
      });
    }
  }, [listChangeData]);

  const groupedListChanges = useMemo(() => {
    if (!listChangeData || !playerData) {
      return [];
    }

    const listChanges = listChangeData.playerList.changes;
    const parsedListChanges = listChanges.map((change) => ({
      ...change,
      parsedPayload: JSON.parse(change.payload),
    }));

    const changesWithPlayers = parsedListChanges.map((change) => ({
      ...change,
      player: isBddList
        ? playerData.bddPlayers.find(
            (player) => player.slug == change.parsedPayload.id
          )
        : playerData.allRinknetPlayers.edges
            .filter((edge) => edge.node.realId === change.parsedPayload.id)
            .map((edge) => ({
              ...edge,
              fullName: `${edge.node.firstname} ${edge.node.lastname}`,
            }))[0],
    }));

    return groupChangesByUserAndSession(changesWithPlayers);
  }, [listChangeData, playerData]);

  if (!listChangeData || !playerData) {
    return listChangePlaceholder || playerPlaceholder;
  }

  return (
    <Container style={{ width }}>
      {groupedListChanges.map((group, index) => {
        return (
          <div key={`group-${index}`}>
            <TierHeader>
              <div>
                <EmphasisLabel>
                  {dateToTimeAgo(group.dateCreated)}
                </EmphasisLabel>
                <Label>by {group.username}</Label>
              </div>
              <Divider />
            </TierHeader>
            {group.changes.map((change) => getChangeComponent(change))}
          </div>
        );
      })}
    </Container>
  );
};

export default LastModifiedHover;
