import { useEffect, useState } from "react";
import { BDDTradeForm } from "./BDDTradeForm"
import { Button, Spinner } from "react-bootstrap";
import { useTradeAnalysis } from "./useTradeAnalaysis";
import { useQuery } from "@apollo/client";
import { GET_BDD_PLAYERS, GET_BDD_PLAYERS_WITH_CAP } from "apollo/queries/players.queries";
import { Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";

export const useBDDTradeForm = ({
  defaultTeams,
  defaultAssets,
  showSubmit,
  handleSubmit,
  submitting,
  analyze=false,
  teamDepthChartIds, // [{ teamSlug, depthChartId }] // if supplied will use this DCID instead of the active
}) => {
  const [teams, setTeams] = useState(!!defaultTeams && defaultTeams.length
    ? defaultTeams 
    : ['nhl.bos', 'nhl.ana']
  );

  const bfAssetsWithoutBDDSlugs = defaultAssets?.filter(a => a.assetType == 'PLAYER' && !a.assetId && !!a.bfSlug);
  const { data: bddPlayerData, loading: bddPlayersLoading } = useQuery(GET_BDD_PLAYERS_WITH_CAP, {
    variables: {
      bfSlugs: bfAssetsWithoutBDDSlugs?.map(a => a.bfSlug)
    },
    skip: !bfAssetsWithoutBDDSlugs?.length
  });

  // Assets listed as: [{ fromTeamSlug, toTeamSlug, assetType, assetId }]
  // players should also have { bddPlayer, retainedSalaryPct, retainedSalary }
  // picks should also have { bfDraftPick, isConditional, conditionalDetails }
  const [assets, setAssets] = useState(
    !!defaultAssets
      ? defaultAssets
      : []
  );

  useEffect(() => {
    if (!!bddPlayerData) {
      const bddPlayers = bddPlayerData.bddPlayers;
      if (bddPlayers?.length != bfAssetsWithoutBDDSlugs?.length) {
        console.error('Unlinked players unable to be added to trade:', bfAssetsWithoutBDDSlugs.filter(a => !bddPlayers.find(p => p.bfSlug == a.bfSlug)))
      }
      setAssets(assets.map(a => {
        const bddPlayer = bddPlayers.find(p => p.bfSlug == a.bfSlug );
        if (!!a.bfSlug) {
          return { ...a, assetId: bddPlayer?.slug, bddPlayer}
        }
        return a;
      }));
    }
  }, [bddPlayerData])

  const form = (
    <Container>
      <BDDTradeForm
        teams={teams}
        setTeams={setTeams}
        assets={assets.filter(a => !!a.assetId)}
        setAssets={setAssets}
        showSubmit={showSubmit}
        handleSubmit={handleSubmit}
        submitting={submitting}
        teamDepthChartIds={teamDepthChartIds}
      />
      {bddPlayersLoading && <Typography variant='stat'>
        Loading players...
      </Typography>}
    </Container>
  );
  const submitButton = (
    <Button block variant='warning' disabled={assets.length === 0 || submitting} onClick={() => handleSubmit(teams, assets)}>
      {submitting 
      ? (
        <Row gap={4} justifyContent='center'>
          <Spinner size="sm" animation="border" />
          <Typography variant='body1'>Submitting</Typography>
        </Row>
      ) : <Typography textAlign='center' variant='body1'>Submit</Typography>}
    </Button>
  );

  const { analysis } = useTradeAnalysis({ 
    teams, 
    assets: assets.filter(a => !!a.assetId),
    skip: !analyze
  });
  
  return {
    form,
    submitButton,
    teams,
    setTeams,
    assets,
    setAssets
  }
}