import { GET_PAGINATED_BF_SIGNINGS } from "apollo/queries/bruinsfriendly.queries";
import { usePaginated } from "components/bdd/Paginated"
import { useSearchParamsStore } from "components/bdd/hooks";
import { formatDateGQL } from "helpers/helpers";
import { useState } from "react"

export default function useSignings({
  defaultSort=[{ id: 'signedDate', desc: true }],
  defaultPageSize=50
}) {
  const [sort, setSort] = useState(defaultSort);
  
  const store = useSearchParamsStore({});
  // const { paramExists } = store;

  const filters = {
    startDate: store.getDate('startDate'),
    endDate: store.getDate('endDate'),
    bfPlayerSlug: store.getValue('bfPlayerSlug'),
    bfTeamSlugs: store.getArray('bfTeamSlugs'),
    positions: store.getArray('positions'),
    minSigningAge: store.getRange('signingAge')?.min,
    maxSigningAge: store.getRange('signingAge')?.max,
    minAav: store.getRange('aav')?.min,
    maxAav: store.getRange('aav')?.max,
    terms: store.getArray('terms'),
    signingStatus: store.getValue('signingStatus'),
    expiryStatus: store.getValue('expiryStatus'),
    seasonPhases: store.getArray('seasonPhases'),
    platformLeagues: store.getArray('platformLeagues'),
    wasCollegeFa: store.getValue('faStatus') === 'wasCollegeFa' ? true : undefined,
    wasEuroFa: store.getValue('faStatus') === 'wasEuroFa' ? true : undefined,
    wasChlFa: store.getValue('faStatus') === 'wasChlFa' ? true : undefined
  }

  const columns = store.getArray('columns', [
    'signedDate',
    'player',
    'team',
    'contractType',
    'aav',
    'isExtension',
    'numYears'
  ]);

  const { data, placeholder, pageControls } = usePaginated({
    query: GET_PAGINATED_BF_SIGNINGS,
    defaultLimit: defaultPageSize,
    key: 'paginatedBfSignings',
    variables: {
      ...filters,
      startDate: formatDateGQL(filters.startDate),
      endDate: formatDateGQL(filters.endDate),
      minAav: filters.minAav * 1e6,
      maxAav: filters.maxAav * 1e6,
      sort,
    }
  })

  const toggleColumn = (column) => {
    store.setArray('columns', columns.find(c => c === column) ? columns.filter(c => c !== column) : columns.concat([column]))
  }

  const signings = data?.paginatedBfSignings?.data;
  return {
    data,
    signings,
    placeholder,
    pageControls,
    sort,
    setSort,
    filters,
    columns,
    toggleColumn,
    store,
    // setFilters: newFilters => setFilters({ ...filters, ...newFilters })
  }
}