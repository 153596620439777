import React from 'react';
import { ErrorMessage } from 'formik';

import BDDFormError from '../bdd/bddformerror';
import BDDSelect from '../bdd/bddselect';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { Label } from 'components/bdd/Label/Label';

export default function BDDSelectField({
  title,
  noTitle,
  titleComponent,
  options,
  field,
  disabled,
  size,
  ...rest
}) {
  const { name, value, onChange } = field; // dont know why error isn't in here
  const error = rest.form.errors[name];
  const touched = rest.form.touched[name];
  return (
    <div>
      {noTitle || !title || !title.length ? null : <Label>{title}</Label>}
      {titleComponent && titleComponent}
      <StyledBDDSelect
        selectedValue={value}
        options={options}
        size={size}
        onChange={(n, v) => onChange({ target: { name: n, value: v } })}
        selectProps={{
          name: name,
          placeholder: title,
          isClearable: 'isClearable' in rest ? rest.isClearable : true,
          isInvalid: !!error && !!touched,
          isDisabled: disabled,
          ...rest
        }}
      />
      <Typography variant='error'>
        {error}
      </Typography>
    </div>
  );
}
