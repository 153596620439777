import React from 'react';
import styled from 'styled-components';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { theme } from 'constants';
import Icon from 'components/bdd/Icon/Icon';
import { Collapse } from 'react-bootstrap';

import Link from './Link';
import { useUser } from 'helpers/user';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Row } from 'components/bdd/Layout';

const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const RowLink = styled(RouterLink)({
  width: '100%',
  '&: hover': {
    textDecoration: 'none',
  },
});

const Button = styled.button(({ selected, color, size }) => ({
  ...theme.buttons.base,
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  backgroundColor: selected
    ? theme.colors[color].focus
    : theme.colors[color].background,
  '&: hover': {
    backgroundColor: theme.colors[color].highlight,
  },
  width: '100%',
  height: size == 'small' ? theme.spacing[8] : theme.spacing[12],
}));

const Title = styled.div(({ color, size }) => ({
  ...theme.font.base,
  ...(size == 'small' ? theme.typography.body1 : theme.typography.subtitle1),
  color: theme.colors[color].text.primary,
  marginRight: 'auto',
}));

const CollapseContainer = styled.div({
  width: '100%',
  maxHeight: 200,
  overflowY: 'auto',
});

const LinkRow = ({
  id,
  icon,
  title,
  url,
  expanded,
  selected,
  isSelectedCallback,
  onClick,
  color,
  size,
  links,
  handleLinkSelection,
  customSidebar,
}) => {
  const { userHasPermission } = useUser();

  const rowContent = (
    <Button color={color} selected={selected} size={size} onClick={onClick}>
      <Container width="100%">
        <Row columnGap={1}>
          <Icon
            color={theme.colors[color].text.primary}
            height={theme.spacing[10]}
            width={theme.spacing[12]}
            icon={icon}
          />
          <Title color={color} size={size}>
            {title}
          </Title>
          {(links || customSidebar) && (
            <Icon
              color={theme.colors[color].text.primary}
              icon={expanded ? <ChevronUp /> : <ChevronDown />}
            />
          )}
        </Row>
      </Container>
    </Button>
  );
  return (
    <RowContainer>
      {url ? <RowLink to={url}>{rowContent}</RowLink> : rowContent}
      {!!links && (
        <Collapse in={expanded}>
          <CollapseContainer>
            {links
              .filter((link) => !link.permission || userHasPermission(link.permission))
              .map((link) => (
                <Link
                  key={link.id}
                  title={link.title}
                  selected={isSelectedCallback(link)}
                  url={link.url}
                  onClick={() => handleLinkSelection(link)}
                  color={color}
                  size={size}
                />
              ))}
          </CollapseContainer>
        </Collapse>
      )}
    </RowContainer>
  );
};

export default LinkRow;
