import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { useSearchParamsState } from 'components/bdd/hooks';
import { createContext, useState } from 'react';

export const PlayerSearchContext = createContext();

export const PlayerSearch = ({ useStateParams, initialState = {}, children }) => {
  const [store, setStore] = useStateParams
    ? useSearchParamsState(initialState, 'playerSearchContext')
    : useState(initialState);

  return (
    <PlayerSearchContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      <BDDErrorBoundary>{children}</BDDErrorBoundary>
    </PlayerSearchContext.Provider>
  );
};
