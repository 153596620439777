import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Typography } from 'components/bdd/Typography';
import { Modal } from 'react-bootstrap';
import { DraftSimilarPlayers } from 'components/DraftApp/DraftSimilarPlayers';

export default function SimilarPlayersModal({
  show,
  handleClose,
  bddPlayer,
  draftYear
}) {
  return (
    <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Typography variant="h6">Similar Players for {bddPlayer.firstname} {bddPlayer.lastname}</Typography>
      </Modal.Header>
      <Modal.Body>
        <DraftSimilarPlayers rinknetId={bddPlayer.rinknetId} draftYear={draftYear}/>
      </Modal.Body>
    </Modal>
  );
}
