import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Border, Column, Container, Row } from 'components/bdd/Layout';

import {
  getNoteTypeOptions,
} from './helpers';
import { NoteCommentForm, PLAYER_ENTITY_TYPE } from 'components/Notes';
import { getPlayerFormData } from 'components/Notes/helpers';

export const PlayerNoteCommentForm = ({
  bddPlayer,
  rinknetPlayer,
  existingPlayerNote,
  onSubmit,
  onCancel,
}) => {
  const { selectedOption: selectedNoteType, buttonGroup } = useButtonGroup({
    options: getNoteTypeOptions(bddPlayer),
    initialSelectedValue: 'GENERAL',
    variant: 'link',
  });

  const noteType = existingPlayerNote?.noteType || selectedNoteType.value;
  const formData = getPlayerFormData(noteType, rinknetPlayer);

  return (
    <Column gap={2}>
      {!existingPlayerNote && <Row justifyContent="space-between">{buttonGroup}</Row>}
      <NoteCommentForm
        entityId={bddPlayer.slug}
        entityType={PLAYER_ENTITY_TYPE}
        existingNote={existingPlayerNote}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formData={formData}
        noteType={noteType}
      />
    </Column>
  );
};
