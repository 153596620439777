import { theme } from 'constants';

export const issueTypeToLabel = {
  LOW_VIEWS: 'Low Views',
  LIST_POSITION: 'List Position',
  NOT_ON_LIST: 'Not on List',
  MEDICAL: 'Medical',
};

export const issueStatusToLabel = {
  OPEN: 'Open',
  ASSIGNED: 'Assigned',
  IN_PROGRESS: 'In Progress',
  PAUSED: 'Paused',
  IGNORED: 'Ignored',
  RESOLVED: 'Resolved',
  ARCHIVED: 'Archived'
};

export const issueStatusToColor = {
  OPEN: theme.colors.states.success,
  ASSIGNED: theme.colors.teams.bos.primary,
  IN_PROGRESS: theme.colors.teams.bos.primary,
  IGNORED: theme.colors.states.danger,
  RESOLVED: theme.colors.states.neutral,
  PAUSED: theme.colors.states.danger,
  ARCHIVED: theme.colors.states.neutral,
};

export const issuePriorityToColor = {
  LOW: theme.colors.states.background.success,
  MEDIUM: theme.colors.teams.bos.primary,
  HIGH: theme.colors.states.background.danger,
};

export const issuePriorityToLabel = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

export const issuePriorityToSort = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const issueStatusOptions = Object.keys(issueStatusToLabel).map(
  (key) => ({
    value: key,
    label: issueStatusToLabel[key],
  })
);

export const issuePriorityOptions = Object.keys(issuePriorityToLabel).map(
  (key) => ({
    value: key,
    label: issuePriorityToLabel[key],
  })
);

export const issueTypeOptions = Object.keys(issueTypeToLabel).map((key) => ({
  value: key,
  label: issueTypeToLabel[key],
}));