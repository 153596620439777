import { useQuery } from "@apollo/client";
import { GET_BF_TEAMS } from "apollo/queries/bruinsfriendly.queries";
import { BDDDateRangePicker } from "components/bdd/bdddatepicker";
import { Column, Container, Row } from "components/bdd/Layout";
import { StyledBDDSelect } from "components/bdd/Select/StyledBDDSelect";
import { Typography } from "components/bdd/Typography";
import useBDDPlayerSearch from "components/Search/useBDDPlayerSearch";
import { useContext, useEffect, useState } from "react";
import { SigningsContext } from "../BFSignings";
import { LinkButton } from "components/bdd/Button";
import { Collapse } from "react-bootstrap";
import { signingsTableColumns } from "./constants";
import { GET_BDD_LEAGUES } from "apollo/queries/bddentity.queries";
import { getSeasonPhaseChoices } from "helpers/filters";
import RangeInputBoxes from "components/bdd/Range/RangeInputBoxes";
import useRangeInputBoxes from "components/bdd/Range/useRangeInputBoxes";


export default function SigningsFilters({ collapsedFilters=false }) {
  const [expandColumns, setExpandCoumns] = useState(false);
  const [expandFilters, setExpandFilters] = useState(!collapsedFilters);
  const { filters, columns, store } = useContext(SigningsContext);
  const [dates, setDates] = useState([filters.startDate, filters.endDate])

  const { data: bfTeams } = useQuery(GET_BF_TEAMS);
  const teamOptions = !!bfTeams
    ? bfTeams.bfTeams.map(t => ({ value: t.slug, label: t.name }))
    : [];

  const { data: bddLeagues } = useQuery(GET_BDD_LEAGUES); // HACK: Querying EP Leagues is too big, but BDD League slugs are the same as EP slugs and there are fewer
  const leagueOptions = !!bddLeagues
    ? bddLeagues.bddLeagues.reduce((acc, curr) => {
      const country = !!curr.country ? curr.country : 'International';
      const existingCountry = acc.find(a => a.label === country);
      if (!existingCountry) {
        return acc.concat([{ label: country, options: [{ label: curr.name, value: curr.slug }]}])
      } else {
        existingCountry.options.push({ label: curr.name, value: curr.slug })
        return acc
      }
    }, [])
    : []

  const { searchComponent } = useBDDPlayerSearch({ 
    initialSelectedValue: filters.bfPlayerSlug,
    onSelect: opt => {
      if (!!opt) {
        store.setValue('bfPlayerSlug', opt.bddPlayer.bfSlug)
      } else {
        store.deleteKeys(['bfPlayerSlug'])
      }
    }
  })


  const { rangeInputBoxes: signingAgeRangeInput } = useRangeInputBoxes({
    initialMinValue: filters.minSigningAge,
    initialMaxValue: filters.maxSigningAge,
    rangeMin: 0,
    onChangeComplete: ({ min, max }) => store.setRange('signingAge', min, max),
  })
  const { rangeInputBoxes: aavRangeInput } = useRangeInputBoxes({
    initialMinValue: filters.minAav,
    initialMaxValue: filters.maxAav,
    rangeMin: 0,
    onChangeComplete: ({ min, max }) => store.setRange('aav', min, max),
    rangeInputBoxesProps: {
      greaterThan: '>= $',
      lessThan: '<= $',
      backDecoration: 'M',
    }
  })

  const positionOptions = ['C', 'W', 'LW', 'RW', 'D', 'LD', 'RD', 'G'].map(p => ({ label: p, value: p }));
  const termOptions = [...Array(15).keys()].map(t => ({ label: `${t+1} Year${t>0 ? 's' : ''}`, value: `${t+1}` }));
  const signingStatusOptions = [
    { label: 'ELC', value: 'ELC' },
    { label: 'RFA', value: 'RFA' },
    { label: 'UFA', value: 'UFA' }
  ]
  const expiryStatusOptions = [
    { label: '10.2(c)', value: '10.2(c)' },
    { label: 'RFA', value: 'RFA' },
    { label: 'UFA', value: 'UFA' }
  ]
  const seasonPhaseOptions = getSeasonPhaseChoices()
  const faStatusOptions = [
    { label: 'Was College FA', value: 'wasCollegeFa' },
    { label: 'Was Euro FA', value: 'wasEuroFa' },
    { label: 'Was CHL FA', value: 'wasChlFa' },
  ]

  useEffect(() => {
    if (!!dates[0] && !!dates[1]) {
      store.setDates(dates[0], dates[1], 'startDate', 'endDate');
    } else if (!dates[0] && !dates[1]) {
      store.deleteKeys(['startDate', 'endDate']);
    }
  }, [dates])

  return (
    <Container padding={4}>
      <Column gap={2}>
        <Row columnGap={4} flexWrap> 
          <Container minWidth={200}>
            <Typography variant='label'>
              Teams
            </Typography>

            <StyledBDDSelect
              selectedValue={filters.bfTeamSlugs}
              options={teamOptions}
              onChange={(n,v) => store.setArray('bfTeamSlugs', v)}
              selectProps={{
                placeholder: 'Filter Teams',
                isMulti: true,
                isClearable: true,
                isSearchable: true
              }}
            />
          </Container>

          <Container minWidth={200}>
            <Typography variant='label'>
              Players
            </Typography>
            {searchComponent}
          </Container>

          <Container>
            <Typography variant="label">Date Range</Typography>
              <Container minWidth={200}>
                <Typography variant="body1">
                  <BDDDateRangePicker
                    zIndex={6}
                    placeholderText="Date Range"
                    selected={dates[0]}
                    startDate={dates[0]}
                    endDate={dates[1]}
                    onChange={(dates) => setDates(dates)}
                    showYearDropdown
                    showMonthDropdown
                    isClearable
                  />
                </Typography>
            </Container>
          </Container>

          <Container minWidth={200}>
            <Typography variant='label'>
              Position
            </Typography>

            <StyledBDDSelect
              selectedValue={filters.positions}
              options={positionOptions}
              onChange={(n,v) => store.setArray('positions', v)}
              selectProps={{
                placeholder: 'Filter Positions',
                isMulti: true,
                isClearable: true,
                isSearchable: true
              }}
            />
          </Container>

        </Row>

        <Row justifyContent='start'>
          <Container>
            <LinkButton style={{ textAlign: 'left' }} onClick={() => setExpandFilters(!expandFilters)}>
              Advanced filters...
            </LinkButton>
          </Container>
        </Row>
        <Container>
          <Collapse in={expandFilters}>
            <Container paddingLeft={2}>
              <Row gap={16} flexWrap>
                <Container width={200}>
                  <Typography variant='label'>
                    Signing Age
                  </Typography>
                  {signingAgeRangeInput}
                </Container>

                <Container minWidth={200}>
                  <Typography variant='label'>
                    Signing Status
                  </Typography>
                  <StyledBDDSelect
                    options={signingStatusOptions}
                    selectedValue={filters.signingStatus}
                    onChange={(n,v) => store.setValue('signingStatus', v)}
                    selectProps={{
                      isClearable: true
                    }}
                  />
                </Container>

                <Container minWidth={200}>
                  <Typography variant='label'>
                    Platform Leagues
                  </Typography>
                  <StyledBDDSelect
                    options={leagueOptions}
                    selectedValue={filters.platformLeagues}
                    onChange={(n,v) => store.setArray('platformLeagues', v)}
                    selectProps={{
                      isMulti: true,
                      isClearable: true,
                      isSearchable: true
                    }}
                  />
                </Container>

                <Container minWidth={200}>
                  <Typography variant='label'>
                    Time in Season
                  </Typography>
                  <StyledBDDSelect
                    options={seasonPhaseOptions}
                    selectedValue={filters.seasonPhases}
                    onChange={(n,v) => store.setArray('seasonPhases', v)}
                    selectProps={{
                      isMulti: true,
                      isClearable: true,
                    }}
                  />
                </Container>


                <Container minWidth={200}>
                  <Typography variant='label'>
                    FA Status
                  </Typography>
                  <StyledBDDSelect
                    options={faStatusOptions}
                    selectedValue={filters.wasCollegeFa ? 'wasCollegeFa' 
                      : filters.wasEuroFa ? 'wasEuroFa'
                      : filters.wasChlFa ? 'wasChlFa'
                      : undefined}
                    onChange={(n,v) => store.setValue('faStatus', v)}
                    selectProps={{
                      isClearable: true,
                    }}
                  />
                </Container>

                <Container width={200}>
                  <Typography variant='label'>
                    AAV Range
                  </Typography>
                  {aavRangeInput}
                </Container>

                <Container minWidth={200}>
                  <Typography variant='label'>
                    Term
                  </Typography>
                  <StyledBDDSelect
                    options={termOptions}
                    selectedValue={filters.terms}
                    onChange={(n,v) => store.setArray('terms', v)}
                    selectProps={{
                      isMulti: true,
                      isClearable: true
                    }}
                  />
                </Container>

                <Container minWidth={200}>
                  <Typography variant='label'>
                    Expiry Status
                  </Typography>
                  <StyledBDDSelect
                    options={expiryStatusOptions}
                    selectedValue={filters.terms}
                    onChange={(n,v) => store.setArray('terms', v)}
                    selectProps={{
                      isMulti: true,
                      isClearable: true
                    }}
                  />
                </Container>

              </Row>
            </Container>
          </Collapse>
        </Container>


        <Row justifyContent='start'>
          <Container>
            <LinkButton style={{ textAlign: 'left' }} onClick={() => setExpandCoumns(!expandColumns)}>
              Columns
            </LinkButton>
          </Container>
        </Row>
        <Container>
          <Collapse in={expandColumns}>
            <Container paddingLeft={2}>
              <Row columnGap={8} flexWrap>
                <Container width={'100%'}>
                  <StyledBDDSelect
                    options={signingsTableColumns.map(c => ({ label: c.Header, value: c.id }))}
                    selectedValue={columns}
                    onChange={(n,v) => store.setArray('columns', v)}
                    selectProps={{
                      isMulti: true,
                      isSearchable: true
                    }}
                  />
                </Container>
              </Row>
            </Container>
          </Collapse>
        </Container>
      
      </Column>
    </Container>
  )
}