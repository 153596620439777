import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';

export const BDDNotificationFragment = gql`
  fragment bddNotificationsFragment on BDDNotifications {
    id
    title
    description
    body
    notificationType
    source
    isBroadcast
    payload
    createdAt
    shouldEmail
    expires
    isArchived
    pinPriority
    subscriptionId
    createdBy {
      id
      username
    }
    userInteraction {
      userId
      isViewed
      viewedAt
      isEmailed
      pinPriority
      isArchived
    }
    users {
      user {
        id
        username
        name
      }
      userId
      viewedAt
      isViewed
      isEmailed
    }
    files {
      id
      name
      url
      s3Url
    }
  }
`;

export const BDDNotificationSubscriptionFragment = gql`
  fragment bddNotificationSubscriptionFragment on BDDNotificationSubscription {
    id
    userId
    name
    description
    lastModified
    isPublic
    isArchived
    canEdit
    notificationType
    payload
    cronSchedule
    cronTimezone
    isExternallyTriggered
    lastRun
    users {
      userId
      canEdit
      payload
      cronSchedule
      cronTimezone
      emailEnabled
      lastRun
      user {
        id
        username
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query GetNotification($id: ID!) {
    notification(id: $id) {
      ...bddNotificationsFragment
    }
  }
  ${BDDNotificationFragment}
`;

export const GET_PAGINATED_NOTIFICATIONS = gql`
  query GetPaginatedNotifications(
    $userId: ID
    $createdById: ID
    $notificationTypes: [BDDNotificationTypes!]
    $sources: [BDDNotificationSources!]
    $subscriptionIds: [ID!]
    $isViewed: Boolean
    $startDate: Date
    $endDate: Date
    $pinnedOnly: Boolean
    $search: String
    $sort: [BDDSortV2!]
    $limit: Int
    $offset: Int
  ) {
    paginatedNotifications(
      userId: $userId
      createdById: $createdById
      notificationTypes: $notificationTypes
      subscriptionIds: $subscriptionIds
      sources: $sources
      isViewed: $isViewed
      startDate: $startDate
      endDate: $endDate
      pinnedOnly: $pinnedOnly

      search: $search
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
  ${PageInfoFragment}
`;

export const UPDATE_USER_BDD_NOTIFICATION = gql`
  mutation UpdateUserBDDNotification(
    $bddNotificationId: ID!
    $input: NotificationUserInput!
  ) {
    updateUserBddNotification(
      bddNotificationId: $bddNotificationId
      input: $input
    ) {
      bddNotification {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
`;

export const MARK_ALL_NOTIFICATIONS_VIEWED = gql`
  mutation MarkBDDNotificationsViewed {
    markBddNotificationsViewed {
      bddNotifications {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
`;

export const CREATE_BDD_NOTIFICATION = gql`
  mutation CreateBDDNotification(
    $input: CreateNotificationInput!
    $userIds: [ID!]
    $files: [BDDNotificationFileInput!]
  ) {
    createBddNotification(input: $input, userIds: $userIds, files: $files) {
      bddNotification {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
`;

export const UPDATE_BDD_NOTIFICATION = gql`
  mutation UpdateBDDNotification(
    $input: UpdateNotificationInput!
    $usersToAdd: [ID!]
    $usersToRemove: [ID!]
    $usersToUpdate: [NotificationUserInput!]
  ) {
    updateBddNotification(
      input: $input
      usersToAdd: $usersToAdd
      usersToRemove: $usersToRemove
      usersToUpdate: $usersToUpdate
    ) {
      bddNotification {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
`;

export const REORDER_BDD_NOTIFICATIONS = gql`
  mutation ReorderBDDNotifications($ids: [ID!]!, $reorderUserPinned: Boolean) {
    reorderBddNotifications(ids: $ids, reorderUserPinned: $reorderUserPinned) {
      bddNotifications {
        ...bddNotificationsFragment
      }
    }
  }
  ${BDDNotificationFragment}
`;

export const GET_BDD_NOTIFICATION_FILTERS = gql`
  query GetBDDNotificationFilters($userId: ID) {
    notificationFilters(userId: $userId) {
      createdByUsers {
        id
        username
        name
      }
      sources
      notificationTypes
    }
  }
`;

export const GET_BDD_NOTIFICATION_SUBSCRIPTIONS = gql`
  query GetBDDNotificationSubscriptions {
    notificationSubscriptions {
      ...bddNotificationSubscriptionFragment
    }
  }
  ${BDDNotificationSubscriptionFragment}
`;

export const CREATE_BDD_NOTIFICATION_SUBSCRIPTION = gql`
  mutation CreateBddNotificationSubscription(
    $input: NotificationSubscriptionInput!
    $users: [NotificationSubscriptionUserInput]
  ) {
    createBddNotificationSubscription(input: $input, users: $users) {
      notificationSubscription {
        ...bddNotificationSubscriptionFragment
      }
    }
  }
  ${BDDNotificationSubscriptionFragment}
`;

export const TRIGGER_BDD_NOTIFICATION_SUBSCRIPTIONS = gql`
  mutation TriggerBddNotificationSubscriptions(
    $userIds: [ID]
    $subscriptionIds: [ID]
    $startDate: Date
    $endDate: Date
    $enableEmail: Boolean
  ) {
    runBddNotificationSubscriptions(
      userIds: $userIds
      subscriptionIds: $subscriptionIds
      startDate: $startDate
      endDate: $endDate
      enableEmail: $enableEmail
    ) {
      notificationsCreated {
        id
        notificationType
        payload
        title
        description
        subscriptionId
        users {
          userId
        }
      }
    }
  }
`;

export const UPDATE_BDD_NOTIFICATION_SUBSCRIPTION = gql`
  mutation UpdateBddNotificationSubscription(
    $id: ID!
    $input: NotificationSubscriptionInput
    $users: [NotificationSubscriptionUserInput]
  ) {
    updateBddNotificationSubscription(id: $id, input: $input, users: $users) {
      notificationSubscription {
        ...bddNotificationSubscriptionFragment
      }
    }
  }
  ${BDDNotificationSubscriptionFragment}
`;

export const UPDATE_BDD_NOTIFICATION_SUBSCRIPTION_USER = gql`
  mutation UpdateBddNotificationSubscription(
    $userId: ID!
    $subscriptionId: ID!
    $input: NotificationSubscriptionUserInput!
  ) {
    updateBddNotificationSubscriptionUser(userId: $userId, input: $input, subscriptionId: $subscriptionId) {
      notificationSubscriptionUser {
        userId
        canEdit
        payload
        cronSchedule
        cronTimezone
        emailEnabled
        lastRun
        user {
          id
          username
        }
      }
    }
  }
`;


export const TRIGGER_PUBLISHED_REPORT_SUBSCRIPTION = gql`
  mutation TriggerPublishedReportSubscription(
    $reportType: String!
    $reportId: ID
    $bddGameId: ID
    $gameUid: ID
  ) {
    triggerPublishedReportSubscription(
      reportType: $reportType
      reportId: $reportId
      bddGameId: $bddGameId
      gameUid: $gameUid
    ) {
      notificationsCreated {
        id
        notificationType
        payload
        title
        description
        subscriptionId
        users {
          userId
        }
      }
    }
  }`