import React from 'react';
import styled from 'styled-components';
import { theme } from 'constants';

import { getColorWithOpacity, bruinsBlack, bruinsGold } from 'helpers/plotting';
import { IconButton } from '../../Button';
import loadingImage from 'assets/images/den_notext.png';

import {
  PauseFill,
  PlayFill,
  VolumeMuteFill,
  VolumeUpFill,
  SkipForwardFill,
  SkipBackwardFill,
} from 'react-bootstrap-icons';
import { formatGameTime } from 'helpers/helpers';
import { Column } from 'components/bdd/Layout';

const ControlContainer = styled.div(({ videoWidth }) => ({
  backgroundColor: getColorWithOpacity(bruinsBlack, 0.6),
}));

const Controls = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: theme.spacing[1],
  width: '100%',
  padding: theme.spacing[2],
});

const InputContainer = styled.div({
  position: 'relative',
  width: '100%',
});
const InputTick = styled.div({
  position: 'absolute',
  height: '100%',
  width: '10px',
  left: (props) => `${props.eventTimePct}%`,
  top: 0,
  backgroundColor: bruinsGold,
  cursor: 'pointer',
});
const Input = styled.input({
  cursor: 'pointer',
  width: '100%',
  accentColor: bruinsGold,
});

const ControlLabel = styled.label({
  ...theme.typography.caption,
  color: '#ddd',
  paddingRight: '10px',
});
const NumberInput = styled.input({
  height: '20px',
  width: '40px',
  fontSize: '10pt',
  textAlign: 'center',
  borderRadius: '2px',
  boxShadow: 0,
  border: 'none',
});

const FeedbackContainer = styled.div({
  ...theme.font.base,
  ...theme.typography.subtitle,
  color: '#ddd',
  minHeight: '20px',
  padding: theme.spacing[2],
});

const VideoPlayer = ({
  src,
  videoRef,
  playerState,
  handleOnTimeUpdate,
  seekToSeconds,
  setFrontPad,
  setBackPad,
  togglePlay,
  toggleMute,
  handleVideoProgress,
  clipInfo,
  showTick = true,
  maxHeight='100%',
}) => {
  console.log(playerState.frontPad, playerState.backPad)
  // If a "targetTime" is present in playerState, we'll create a tick for it
  if (!!playerState.targetTime && showTick && playerState.targetDuration == 0) {
    var eventTimePct =
      (100 * playerState.frontPad) /
      (playerState.frontPad + playerState.backPad);
  }
  return (
    <Column>
        <video
          style={{ width: '100%', height: 'auto', maxHeight: maxHeight }}
          src={src}
          ref={videoRef}
          onTimeUpdate={handleOnTimeUpdate}
          poster={loadingImage}
        />
        <ControlContainer videoWidth={videoRef?.current?.offsetWidth}>
          <Controls>
            <InputContainer>
              <Input
                type="range"
                min="0"
                max="100"
                value={playerState.progress}
                onChange={(e) => handleVideoProgress(e)}
              />
              {!!eventTimePct && (
                <InputTick
                  eventTimePct={eventTimePct}
                  onClick={() =>
                    handleVideoProgress({ target: { value: eventTimePct } })
                  }
                />
              )}
            </InputContainer>
          </Controls>
          <Controls>
            <IconButton
              icon={<SkipBackwardFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={25}
              onClick={() => seekToSeconds(playerState.currentTime + 10)}
            />
            <IconButton
              icon={playerState.isPlaying ? <PauseFill /> : <PlayFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={30}
              onClick={togglePlay}
            />
            <IconButton
              icon={<SkipForwardFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={25}
              onClick={() => seekToSeconds(playerState.currentTime + 10)}
            />
            <IconButton
              icon={playerState.isMuted ? <VolumeMuteFill /> : <VolumeUpFill />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={getColorWithOpacity(
                theme.colors.dark.text.primary,
                1
              )}
              size={30}
              onClick={toggleMute}
            />
            {playerState.playbackMode === 'offsets' && (
              <>
                <div>
                  <ControlLabel>Lead time:</ControlLabel>
                  <NumberInput
                    type="number"
                    min={2}
                    max={600}
                    value={playerState.frontPad}
                    onChange={(ev) => setFrontPad(ev.target.value)}
                  />
                </div>
                <div>
                  <ControlLabel>Tail time:</ControlLabel>
                  <NumberInput
                    type="number"
                    min={2}
                    max={600}
                    value={playerState.backPad}
                    onChange={(ev) => setBackPad(ev.target.value)}
                  />
                </div>
              </>
            )}
          </Controls>
          <FeedbackContainer>
            {!!clipInfo && (
              <>
                {formatGameTime(clipInfo.gameTime - playerState.frontPad)} to{' '}
                {
                  formatGameTime(
                    clipInfo.gameTime +
                      playerState.backPad +
                      playerState.targetDuration
                  ).split(' ')[1]
                }
              </>
            )}
            {playerState.buffering && <small> Buffering...</small>}
          </FeedbackContainer>
        </ControlContainer>
    </Column>
  );
};

export default VideoPlayer;
