import { GET_SL_EVENTS } from 'apollo/queries/events.queries';
import { usePaginated } from 'components/bdd/Paginated';

export const useEvents = ({ filters, sort, defaultLimit = 100 }) => {
  const {
    paginatedData: allEvents,
    placeholder,
    pageControls,
  } = usePaginated({
    query: GET_SL_EVENTS,
    key: 'getSlEvents',
    defaultLimit: defaultLimit,
    itemNames: 'events',
    variables: {
      sort: sort || [{ id: 'game_uid' }, { id: 'game_time' }],
      filters: filters && filters.map((f) => Object.values(f)),
    },
    skip: !filters || filters.length == 0,
  });

  return {
    allEvents,
    loading: placeholder,
    pageControls,
  };
};
