import { Container } from 'components/bdd/Layout';
import { getSeasonChoices } from 'helpers/filters';
import { SelectFilter } from './SelectFilter';

export const SeasonsFilter = ({ selectProps }) => {
  const options = getSeasonChoices();

  return (
    <Container minWidth={100}>
      <SelectFilter
        col="season"
        id="season"
        label={'Seasons'}
        options={options}
        selectProps={selectProps}
      />
    </Container>
  );
};
