import { gql } from '@apollo/client'

export const GET_CLIENT_REPORT_STATE = gql`query GetReportState($id: ID) {
    clientReportState(id: $id) @client
}`;



export const UPDATE_REPORT_STATE = gql`
    mutation UpdateReportState($id: ID!, $reportState: JSONString!) {
        updateReportData(input: {
            id: $id,
            reportState: $reportState
        }) {
            reportData {
                id
                reportState
                isPublished
                publishedTimestamp          
            }
        }
    }`
    // # ${ReportDataFragment}`

export const CREATE_REPORT_DATA = gql`
    mutation CreateReportData($input: CreateReportDataInput!) {
        createReportData(input: $input) {
            reportData {
                id
                reportState
                isPublished
                publishedTimestamp  
            }
        }
    }`
    // ${ReportDataFragment}`