import { BDDLoader } from 'components/bdd/bddloader';
import { Border, Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useNotes } from 'components/Notes';

export const NoteSummaryBody = ({ noteIds }) => {
  const { noteItems } = useNotes({
    skip: !noteIds,
    ids: noteIds,
  });

  const items = noteItems?.map((ni) => <Border key={ni.note.id}>{ni.render()}</Border>);

  return !noteIds || noteIds.length == 0 ? (
    <Typography variant="body1">No notes available</Typography>
  ) : (
    <Container padding={3} overflow="auto" height="100%">
      <Column gap={2}>{items || <BDDLoader variant="squares" />}</Column>
    </Container>
  );
};
