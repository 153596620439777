import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { NULL_FILTER_VALUE } from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';

import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useLazyBddStatQuery } from 'apollo/queries';

const Container = styled.div({
  ...theme.typography.stat,
  flexWrap: 'wrap',
});

const SlTable = styled.table({
  width: '100%',
});

const SlTableCell = styled.td({
  paddingRight: theme.spacing[5],
});

const prepareQ = (filters, playerSlugs, slStats) => {
  const filts = [
    {
      col: 'season',
      val: filters.season.length > 0 ? filters.season : [getCurrentSeason()],
      cond: 'isin',
    },
    { col: 'game_type', val: 'nhl_regular' },
    { col: 'slug', val: playerSlugs, cond: 'isin' },
  ];
  if (
    filters.strength != NULL_FILTER_VALUE &&
    filters.strength.startsWith('ES')
  )
    filts.push({ col: 'manpower_situation', val: 'ES' });
  if (filters.strength != NULL_FILTER_VALUE && filters.strength.endsWith('55'))
    filts.push({ col: 'strength', val: 55 });
  const q = {
    index: ['slug', 'Player'],
    filters: filts,
    statDefs: slStats
      .filter((sd) => !!sd.slug)
      .map((sd) => ({ slug: sd.slug, label: sd.label })),
  };
  if (filters.norm == '20') {
    q['extras'] = { metastats: { target_group: 'league' } };
    if (filters.compareTo != NULL_FILTER_VALUE)
      q['extras']['metastats']['target_position'] = filters.compareTo;
  }
  if (!!filters.norm && filters.norm != NULL_FILTER_VALUE)
    q['norm'] = filters.norm;
  return q;
};

export const SlData = ({ bddPlayer, list, filters, slStats }) => {
  const playerSlugs = list.players.map((p) => p.playerId);
  const [fetchSLData, slQuery] = useLazyBddStatQuery(
    'NHL',
    'skaters',
    prepareQ(filters, playerSlugs, slStats)
  );

  useEffect(() => {
    fetchSLData();
  }, [filters, slStats]);

  const { data: slData, placeholder } = usePlaceholder(slQuery, {
    variant: 'squares-sm',
    nocenter: true,
  });

  const playerToSL = useMemo(() => {
    const ret = {};
    if (slData) {
      slData.data.forEach((d) => {
        ret[d.slug] = d;
      });
    }
    return ret;
  }, [slData]);

  const slugToMetastats = useMemo(() => {
    if (!slData) return null;
    if (!slData?.extras?.metastats) {
      console.error('No metastat data found');
      return;
    }
    const metastats = slData.extras.metastats.league[0].metastats;
    const ret = {};
    slData.format.stat_definitions.forEach((sd) => {
      ret[sd.slug] = { ...metastats[sd.unique_id] };
      ret[sd.slug]['negative'] = sd.negative;
    });
    return ret;
  }, [slData]);

  const slHighlightCallback = (slug, val) => {
    try {
      const { mean, stddev, negative } = slugToMetastats[slug];
      const zscore = (val - mean) / stddev;
      return getRedGreenColor(zscore, -2, 2, negative);
    } catch {
      return null;
    }
  };

  if (!slData) {
    return <Container>Loading...</Container>;
  }


  const slPlayerData =
    bddPlayer?.slug in playerToSL ? playerToSL[bddPlayer?.slug] : null;

  if (!slPlayerData) {
    return (
      <Container>
        <em>No stats data found</em>
      </Container>
    );
  }

  return (
    <Container>
      <SlTable>
        <tbody>
          <tr>
            {slStats.map((stat) => (
              <th key={'header-' + stat.label}>{stat.label}</th>
            ))}
          </tr>
          <tr>
            {slStats.map((stat) => (
              <SlTableCell
                key={'row-' + stat.label}
                style={{
                  background: slHighlightCallback(
                    stat.slug,
                    slPlayerData[stat.label]
                  ),
                }}
              >
                {roundToX(slPlayerData[stat.label], 2)}
              </SlTableCell>
            ))}
          </tr>
        </tbody>
      </SlTable>
    </Container>
  );
};
