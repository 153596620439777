import { itemTypes } from 'constants';
import { useDragLayer } from 'react-dnd';
import { Container } from '../Layout';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(itemType, initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;

  if (itemType === itemTypes.PLAYER_LIST_TIER) {
    var transform = `translate(${initialOffset.x + 15}px, ${y + 8}px)`;
  } else {
    var transform = `translate(${x}px, ${y}px)`;
  }

  return {
    transform,
    WebkitTransform: transform,
  };
}

export const DragLayer = ({ children, isVisible }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset, didDrop } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }));

  // if (!item || !isVisible(item)) {
  //   return null;
  // }

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(itemType, initialOffset, currentOffset)}>
        <Container>{children}</Container>
      </div>
    </div>
  );
};
