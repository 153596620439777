import { HoverInteractive } from 'components/bdd';
import { LinkButton } from 'components/bdd/Button';
import { DatePickerField } from 'components/bdd/DatePicker';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { SelectField } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import {
  nameToOptions,
  proProjectionToText,
} from 'components/Scouting/playerformhelpers';
import { ErrorMessage } from 'formik';
import { dateToTimeAgo, formatDate } from 'helpers/helpers';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  developmentStatusOptions,
  developmentStatusToLabel,
  interestOptions,
  interestToLabel,
  mediumTypeOptions,
  mediumTypeToLabel,
  trendOptions,
  trendToLabel,
} from 'components/PlayerNotes/helpers';
import { PRO_OBJECTIVE_ROUTE, PLAYERS_V2_ROUTE, TEAM_ROUTE } from 'constants';
import { NoteHistory } from '.';
import { buildProScenarioLink } from 'components/ProObjectives/Scenarios/ScenarioCard';

const BDD_PLAYER_TYPES = ['BDD_PLAYER', 'TARGET_BDD_PLAYER'];

const HeaderLink = ({ name, link }) => {
  return link ? (
    <Link to={`${link}`}>
      <LinkButton>{name}</LinkButton>
    </Link>
  ) : (
    <b>{name}</b>
  );
};

const NoteHeader = ({ children }) => (
  <Row flexWrap columnGap={1}>
    {children}
  </Row>
);

const getPlayerNoteTypeHeader = (noteType, playerLink, username, date) => {
  switch (noteType) {
    case 'ANALYTIC':
      return (
        <>
          {username} made an assessment on {playerLink} {date}
        </>
      );
    case 'ASSESSMENT':
      return (
        <>
          {username} made an assessment on {playerLink} {date}
        </>
      );
    case 'CHECK-IN':
      return (
        <>
          {username} added a check-in with {playerLink} {date}
        </>
      );
    case 'GENERAL':
    default:
      return (
        <>
          {username} left a note about {playerLink} {date}
        </>
      );
  }
};

// const getTargetEntityLink = ({targetEntity}) => {
//   const route = targetEntity.entityType == 'SL_TEAM' ?

// }

const getMentionLink = (mention) => {
  // const link = mention.entityType == 'SL_TEAM' ? TEAM_ROUTE : PLAYERS_V2_ROUTE;

  return mention && <HeaderLink name={mention.entityLabel} />;
};

// This is just a giant if statement
export const buildNoteHeader = (note) => {
  if (!note) return null;

  const username = <b>{note.user.username}</b>;
  const mentionLink = getMentionLink(note.mention);
  // const isMentioned = note.entityId != targetEntity.entityId;
  const isEdited = note.dateEdited;
  const dateCreated = dateToTimeAgo(note.dateCreated);
  const dateEdited = isEdited && (
    <HoverInteractive
      content={
        <Container>
          <Column gap={2}>
            <Typography variant="body2">
              Edited {dateToTimeAgo(note.dateEdited)}
            </Typography>
            <NoteHistory parentEntityId={note.parentEntityId} />
          </Column>
        </Container>
      }
    >
      <Typography>
        {dateCreated}
        {isEdited && '*'}
      </Typography>
    </HoverInteractive>
  );

  const date = isEdited ? dateEdited : dateCreated;

  // BDD player notes
  if (
    BDD_PLAYER_TYPES.includes(note.entityType) ||
    BDD_PLAYER_TYPES.includes(note.subEntityType)
  ) {
    const notePlayerLink = !!note.bddPlayer ? (
      <HeaderLink
        name={note.bddPlayer.fullName}
        link={`${PLAYERS_V2_ROUTE}/${note.bddPlayer.slug}`}
      />
    ) : 'a player';

    if (!!mentionLink) {
      return (
        <NoteHeader>
          {username} mentioned {mentionLink} in a note for {notePlayerLink} {date}
        </NoteHeader>
      );
    }

    if (note.entityType == 'BDD_PLAYER') {
      return (
        <NoteHeader>
          {getPlayerNoteTypeHeader(note.noteType, notePlayerLink, username, date)}
        </NoteHeader>
      );
    }

    if (note.subEntityType == 'TARGET_BDD_PLAYER') {
      // We're reading pro objective player notes from a playercard
      if (note.entityType == 'PRO_OBJECTIVE') {
        const proObjectiveLink = (
          <HeaderLink
            name={note.proObjective.name}
            link={`${PRO_OBJECTIVE_ROUTE}/${note.proObjective.slug}`}
          />
        );

        return (
          <NoteHeader>
            {username} left a note for {notePlayerLink} in {proObjectiveLink} {date}
          </NoteHeader>
        );
      }

      return (
        <NoteHeader>
          {username} left a note about {notePlayerLink} {date}
        </NoteHeader>
      );
    }
  }

  // SL Teams
  if (note.entityType == 'SL_TEAM' || note.subEntityType == 'SL_TEAM') {
    const teamLink = (
      <HeaderLink
        name={note.slTeam.longname}
        link={`${TEAM_ROUTE}/${note.slTeam.slug}`}
      />
    );

    if (!!mentionLink) {
      return (
        <NoteHeader>
          {username} mentioned {mentionLink} in a note for {teamLink} {date}
        </NoteHeader>
      );
    }

    if (note.subEntityType == 'SL_TEAM') {
      // We're reading pro objective team notes from a team card
      if (note.entityType == 'PRO_OBJECTIVE') {
        const proObjectiveLink = (
          <HeaderLink
            name={note.proObjective.name}
            link={`${PRO_OBJECTIVE_ROUTE}/${note.proObjective.slug}`}
          />
        );

        return (
          <NoteHeader>
            {username} left a note for {teamLink} in {proObjectiveLink} {date}
          </NoteHeader>
        );
      }
    }

    return (
      <NoteHeader>
        {username} left a note about {teamLink} {date}
      </NoteHeader>
    );
  }

  if (note.entityType == 'PRO_OBJECTIVE') {
    const proObjectiveLink = (
      <HeaderLink
        name={note.proObjective.name}
        link={`${PRO_OBJECTIVE_ROUTE}/${note.proObjective.slug}`}
      />
    );

    if (!!mentionLink) {
      return (
        <NoteHeader>
          {username} mentioned {mentionLink} in {proObjectiveLink} {date}
        </NoteHeader>
      );
    }
  }

  if (note.entityType == 'PRO_SCENARIO') {
    const proScenarioLink = (
      <HeaderLink
        name={note.proScenario.title}
        link={buildProScenarioLink(note.proScenario.id)}
      />
    );
    return (
      <NoteHeader>
        {username} left a note in scenario {proScenarioLink}
      </NoteHeader>
    )
  }

  return (
    <NoteHeader>
      {username} left a note {date}
    </NoteHeader>
  );
};

export const getPlayerFormData = (noteType, rinknetPlayer) => {
  const posFDG = rinknetPlayer.posFDG.toLowerCase();

  const proProjectionOptions = [
    {
      value: null,
      label: 'N/A',
    },
    ...nameToOptions('proProjection', posFDG),
  ];

  switch (noteType) {
    case 'ANALYTIC':
      return {
        getInitialValues: (en) =>
          !!en
            ? {
                proProjection: en.proProjection,
                trend: en.trend,
                interest: en.interest,
              }
            : {
                proProjection: null,
                trend: null,
                interest: null,
              },
        schema: {
          proProjection: Yup.string().required('Pro Projection is required').nullable(),
          trend: Yup.string().required('Trend is required').nullable(),
          interest: Yup.string().nullable(),
        },
        components: [
          {
            title: 'Pro Projection:',
            field: (
              <Container minWidth={100}>
                <SelectField
                  name="proProjection"
                  options={proProjectionOptions}
                  variant="outlined"
                  minHeight={0}
                  selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                />
                <Typography key="proProjection" variant="error">
                  <ErrorMessage name="proProjection" />
                </Typography>
              </Container>
            ),
            getValue: (en) => (
              <b>{proProjectionToText(en.proProjection, posFDG) || 'N/A'}</b>
            ),
          },
          {
            title: 'Trend',
            field: (
              <Container minWidth={100}>
                <SelectField
                  name="trend"
                  options={trendOptions}
                  variant="outlined"
                  minHeight={0}
                  selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                />
                <Typography key="trend" variant="error">
                  <ErrorMessage name="trend" />
                </Typography>
              </Container>
            ),
            getValue: (en) => <b>{trendToLabel(en.trend)}</b>,
          },
          {
            title: 'Interest',
            field: (
              <Container minWidth={150}>
                <SelectField
                  name="interest"
                  options={interestOptions}
                  variant="outlined"
                  minHeight={0}
                  selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                />
                <Typography key="interest" variant="error">
                  <ErrorMessage name="interest" />
                </Typography>
              </Container>
            ),
            getValue: (en) => <b>{interestToLabel(en.interest)}</b>,
          },
        ],
      };
    case 'ASSESSMENT':
      return {
        getInitialValues: (en) =>
          en
            ? {
                proProjection: en.proProjection,
                developmentStatus: en.developmentStatus,
              }
            : {
                proProjection: null,
                developmentStatus: null,
              },
        schema: {
          proProjection: Yup.string(),
          developmentStatus: Yup.string(),
        },
        components: [
          {
            title: 'Pro Projection:',
            field: (
              <Container minWidth={200}>
                <SelectField
                  name="proProjection"
                  options={proProjectionOptions}
                  variant="outlined"
                  minHeight={0}
                  selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                />
              </Container>
            ),
            getValue: (en) => (
              <b>{proProjectionToText(en.proProjection, posFDG) || 'N/A'}</b>
            ),
          },
          {
            title: 'Development Status:',
            field: (
              <Container minWidth={200}>
                <SelectField
                  name="developmentStatus"
                  options={developmentStatusOptions}
                  variant="outlined"
                  minHeight={0}
                  selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                />
              </Container>
            ),
            getValue: (en) => <b>{developmentStatusToLabel(en.developmentStatus)}</b>,
          },
        ],
      };
    case 'CHECK-IN':
      return {
        getInitialValues: (existingNote) =>
          existingNote
            ? {
                date: new Date(existingNote.date),
                medium: existingNote.medium,
              }
            : {
                date: new Date(),
                medium: 'EMAIL',
              },
        schema: {
          date: Yup.date().required(),
          medium: Yup.string().required(),
        },
        components: [
          {
            title: 'Check-in date:',
            field: (
              <Container minWidth={100}>
                <Typography variant="body1">
                  <DatePickerField
                    name="date"
                    placeholderText={'Check-in Date'}
                    isClearable={false}
                    compact
                    noBorder
                  />
                </Typography>
              </Container>
            ),
            getValue: (en) => <b>{formatDate(en.date)}</b>,
          },
          {
            title: 'Medium:',
            field: (
              <Container minWidth={100}>
                <Typography variant="body1">
                  <SelectField
                    name="medium"
                    options={mediumTypeOptions}
                    variant="outlined"
                    minHeight={0}
                    selectProps={{ placeholder: '', menuPlacement: 'auto' }}
                  />
                </Typography>
              </Container>
            ),
            getValue: (en) => <b>{mediumTypeToLabel[en.medium]}</b>,
          },
        ],
      };
    case 'GENERAL':
    default:
      return null;
  }
};
