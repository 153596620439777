import { useState } from "react";
import { BDDInput } from "../Input";


export const useSearchField = ({
  defaultValue='',
  name='search',
  onChange,
  placeholder='Search...'
}) => {
  const [search, setSearch] = useState(defaultValue);
  const searchComponent = (
    <BDDInput
      name={name}
      value={search}
      placeholder={placeholder}
      onChange={ev => {
        setSearch(ev.target.value)
        if (!!onChange) onChange(ev);
      }}
    />
  );

  return {
    searchValue: search,
    setSearch,

    searchComponent
  }
}