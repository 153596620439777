import React from 'react';
import { ReportModal } from 'components/ScoutingV2';
import { LinkButton } from '../Button';
import useModal from '../Modal/useModal';

export const ScoutLinkButton = ({ playerId, scout, gameId }) => {
  const { setShowModal, modal } = useModal({
    title: 'Report',
    body: (
      <ReportModal
        playerRinknetId={playerId}
        scoutId={scout.id}
        gameId={gameId}
      />
    ),
  });

  return (
    <>
      {modal}
      <LinkButton
        style={{ justifyContent: 'start', width: 'unset' }}
        onClick={() => setShowModal(true)}
      >
        {`${scout.firstname} ${scout.lastname}`}
      </LinkButton>
    </>
  );
};
