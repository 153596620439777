import { NULL_FILTER_VALUE } from "helpers/filters"
import { snakeToCamelCase } from "helpers/string"


export const describeReportOwnership = (report, user) => {
  if (report.isBddReport) {
    return 'BDD Report'
  } else if (report.userId === user.id) {
    return 'Your Report'
  } else if (!!report.user) {
    return `${report?.user?.username}'s Report`
  } else {
    return 'Unknown report type'
  }
}

export const mapStatsReportsToStatSelect = (stats) =>
  stats?.map((s) => ({
    slug: s.statSlug,
    norm: s.norm,
    filterSetId: s.filterSetId,
    label: s.label,
  }));

export const mapStatsSelectToReport = (stats) => // this is one to one now... so just copy fields
  stats?.map((s) => ({
    slug: s.slug,
    norm: s.norm === NULL_FILTER_VALUE ? null : s.norm,
    filterSetId: s.filterSetId,
    label: s.label,
  }));

export const mapStatsSelectToQuery = (stats) =>
  stats?.map((s) => ({
    slug: s.slug,
    label: s.label,
    ...(s.norm === 'totals' ? { norm: null } : !!s.norm && s.norm !== NULL_FILTER_VALUE ? { norm: s.norm } : {}),
    ...(s.filterSetId ? { filter_set_id: s.filterSetId } : {}),
  }));


export const mapStatsSnakeToCamel = (stats) => // maps "filter_set_id" to filterSetId and any other snake/camel case keys
  stats?.map((s) => 
    Object.keys(s).reduce((acc, curr) => 
      ({ ...acc, [snakeToCamelCase(curr)]: s[curr] }), 
    {}));


export const mapStatDefsToStatsSelect = (stats) => {
  // - does snake to camel 
  // - if "norm: null" in stat def, translates this to "totals"
  return stats?.map((s) => {
    const ret = {};
    Object.keys(s).forEach(k => {
      ret[snakeToCamelCase(k)] = s[k];
      if (k === 'norm' && !s[k]) {
        ret[snakeToCamelCase(k)] = 'totals';
      }
      return ret
    });
    return ret
  });
}