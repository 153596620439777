import { Column, Container } from 'components/bdd/Layout';
import Toggle from 'components/bdd/Toggle/Toggle';
import { useContext } from 'react';
import { SLStatContext } from 'components/SLStats';
import { strContains } from 'helpers/data';
import { dateRangeModeIsLastMonth } from 'helpers/daterangemode';

export const ByGameToggle = ({ label='By Game', byGameIndex, nonByGameIndex, disabled=false }) => {
  const { filters, setFilters } = useContext(SLStatContext);

  const isShortTimeRange = dateRangeModeIsLastMonth(filters.dateRangeMode);
  return (
    <Container width={100}>
      <Column>
        <Toggle 
          disabled={!isShortTimeRange}
          label={label} 
          toggled={isShortTimeRange ? filters.index == byGameIndex : false} 
          setToggled={(useByGameIndex) => {
            setFilters({ index: useByGameIndex ? byGameIndex : nonByGameIndex })
          }}
        />
      </Column>
    </Container>
  );
};
