import { Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { formatDollars } from "helpers/contracts"
import styled from "styled-components"

const TopLeft = styled.div({
  position: 'absolute',
  top: 1,
  left: 3
})

export const BDDSigning = ({ signing, topLeftDecoration, width='100%' }) => {
  return <Container width={width}>
    {!!topLeftDecoration && (
      <TopLeft>
        <Typography variant='stat' color="#777">
          {topLeftDecoration}
        </Typography>
      </TopLeft>
    )}
    <Row gap={4} justifyContent='center'>
      <Typography variant='body2'>
        {signing.bddPlayer.firstname} {signing.bddPlayer.lastname}
      </Typography>
      <Typography variant='body2'>
        {signing.term} x {formatDollars(signing.capHitNhl)}
      </Typography>
    </Row>
  </Container>
}