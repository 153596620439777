import React from 'react';
import { XLg } from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import { theme } from 'constants';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import styled from 'styled-components';

const Link = styled.a({
  ...theme.buttons.link(theme.colors.light.text.primary),
});

export const FileAttachment = ({ file, readOnly, onFileRemove }) => {
  return (
    <Border>
      <Container padding={1} backgroundColor={theme.colors.light.secondary}>
        <Typography variant="body1">
          <Row columnGap={2}>
            <Link href={file.s3Url || file.url} target="_blank">
              {file.name}
            </Link>
            {!readOnly && (
              <IconButton icon={<XLg />} onClick={() => onFileRemove(file)} />
            )}
          </Row>
        </Typography>
      </Container>
    </Border>
  );
};

export const FileAttachmentGroup = ({ files, readOnly, onFileRemove }) => {
  return (
    <Container padding={3} paddingTop={0}>
      <Row columnGap={2}>
        {files.map((file) => (
          <FileAttachment
            readOnly={readOnly}
            key={file.s3Url || file.url}
            file={file}
            onFileRemove={onFileRemove}
          />
        ))}
      </Row>
    </Container>
  );
};
