import { Typography } from 'components/bdd/Typography';

export const PlayerName = ({
  bd,
  rp,
  ep,
  cf,
  bf,
  name,
  variant = 'body2',
  style = {},
  color = 'black',
  noWrap = true,
}) => {
  if (!name) {
    if (!!bd) name = `${bd.firstname} ${bd.lastname}`;
    else if (!!ep) name = `${ep.firstName} ${ep.lastName}`;
    else if (!!cf) name = `${cf.firstName} ${cf.lastName}`;
    else if (!!bf) name = `${bf.firstName} ${bf.lastName}`;
    else if (!!rp) name = `${rp.firstname} ${rp.lastname}`;
  }
  return (
    <Typography variant={variant} style={style} color={color} noWrap={noWrap}>
      {name}
    </Typography>
  );
};
