import styled from 'styled-components';

import { theme } from 'constants';
import { useSelect } from '.';
import { GET_USERS } from 'apollo/queries/user.queries';
import { useQuery } from '@apollo/client';

const Container = styled.div({
  minWidth: theme.spacing[24],
});

const useUserSelect = ({
  variant,
  initialSelectedValue,
  noInitialSelectedValue,
  selectProps = {},
  useName = false,
  onChange,
  filterUserOptions // callback to filter options
} = {}) => {
  const { data, loading } = useQuery(GET_USERS);
  let userOptions =
    data?.users?.map((u) => ({
      label: useName ? u.name : u.username,
      value: u.id,
      user: u,
    })) || [];
  if (filterUserOptions) {
    userOptions = userOptions.filter(filterUserOptions);
  }

  const { selected, select } = useSelect({
    options: userOptions,
    variant,
    initialSelectedValue,
    noInitialSelectedValue: noInitialSelectedValue,
    onChange,
    selectProps: {
      isLoading: loading,
      placeholder: 'Select user',
      isSearchable: true,
      isClearable: true,
      menuPlacement: 'auto',
      ...selectProps,
    },
  });

  return {
    userId: selected?.value,
    userSelect: <Container>{select}</Container>,
    userOptions,
  };
};

export default useUserSelect;
