import { useBddStatQueryV2 } from 'apollo/queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';

export const usePlayerGameData = ({
  slug,
  leagueSlug,
  season,
  gameType,
  target = 'skaters',
}) => {
  const { data, placeholder } = usePlaceholder(
    useBddStatQueryV2(
      {
        target,
        filters: [
          {
            col: 'slug',
            val: slug,
          },
          {
            col: 'league_slug',
            val: leagueSlug,
          },
          {
            col: 'game_type',
            val: gameType,
          },
          {
            col: 'season',
            val: season,
          },
        ],
        index: [
          'league_slug',
          'game_uid',
          'game_date',
          'game_type',
          'team_slug',
          'team_shorthand',
          'opp_team_shorthand',
        ],
        statSlugs: [],
      },
      !leagueSlug || !season
    )
  );

  return {
    placeholder,
    data:
      !!data && !!data?.data?.length
        ? data.data
            .map((d) => d)
            .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
        : [],
  };
  W;
};
