import { useQuery } from '@apollo/client';
import { GET_MULTI_RANKINGS_V2 } from 'apollo/queries/statsv2.queries';
import { Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { SkillHighlightBoxGroup } from 'components/PlayersV2/components/SkillHighlightBoxes';
import { strContains } from 'helpers/data';
import { season8to4 } from 'helpers/hockeyutils';
import { useMemo } from 'react';

const PlayerSkillBoxes = ({
  statDefinitions,
  allRankings,
  initialSplitKey,
  useFlatLabel = true,
}) => {
  const gameTypeToLabel = (gameType) =>
    strContains(gameType, 'postseason') ? 'PLAYOFFS ' : '';
  const { select, selectedValue } = useSelect({
    initialSelectedValue: initialSplitKey,
    options: allRankings.map((r) => {
      const gp = r.rankings.find((r) => r.stat === 'gamesPlayed')?.value;
      return {
        label: `${season8to4(r.split.season, true)} ${
          r.split.leagueSlug
        } ${gameTypeToLabel(r.split.gameType)} ${!!gp ? `(${gp} GP)` : ''}`,
        value: r.key,
      };
    }),
    variant: 'outlined',
    size: 'sm',
  });
  if (allRankings.length == 0) return <em>No SL Skills data found</em>;

  if (useFlatLabel) {
    statDefinitions = statDefinitions.map((sd) => ({
      ...sd,
      label: !!sd.flatLabel ? sd.flatLabel : sd.label,
    }));
  }
  return (
    <>
      <Row alignItems="center">{select}</Row>
      <SkillHighlightBoxGroup
        statDefinitions={statDefinitions}
        rankings={allRankings.find((r) => r.key === selectedValue)?.rankings}
        posFDG={null}
      />
    </>
  );
};

export default function useSLSkills({
  slugs,
  statDefinitions, // EITHER this or categories.  if supplied, will render a single row of boxes
  categories, // if supplied, will render row for each category
  season,
}) {
  const statDefsToFetch = statDefinitions
    ?.filter((sd) => !!sd)
    ?.map((sd) => ({
      statSlug: sd.slug,
      manpowerSituation: sd.manpowerSituation || 'ES',
      strength: sd.strength,
    }));

  const { data, previousData, loading, error } = useQuery(GET_MULTI_RANKINGS_V2, {
    skip: !slugs?.length || !statDefinitions?.length,
    variables: {
      slugs,
      statDefinitions: statDefsToFetch,
      season,
    },
  });

  const slugToSkills = useMemo(() => {
    if (!statDefinitions) return null;
    if (!data && !previousData) return {};

    const rankings =
      data?.multiSlPlayerRankingsV2 || previousData.multiSlPlayerRankingsV2 || [];

    return rankings.reduce((acc, curr) => {
      acc[curr.bddPlayer.slug] = {
        ...curr,
        rowComponent: (
          <PlayerSkillBoxes
            statDefinitions={statDefinitions}
            initialSplitKey={curr?.focusRankings?.key}
            allRankings={curr.allRankings}
          />
        ),
      };
      return acc;
    }, {});
  }, [data, previousData]);

  return {
    loading,
    error,
    slugToSkills,
  };
}
