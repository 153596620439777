import { useQuery } from '@apollo/client';
import { GET_PLAYER_VIEW_STATISTICS } from 'apollo/queries/scouting.queries';
import { GET_MULTI_RANKINGS_V2 } from 'apollo/queries/statsv2.queries';
import { Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { SkillHighlightBoxGroup } from 'components/PlayersV2/components/SkillHighlightBoxes';
import { strContains } from 'helpers/data';
import { season8to4 } from 'helpers/hockeyutils';
import { useMemo } from 'react';


export default function usePlayerViewStats({
  rinknetIds,
  season
}) {
  const { data, previousData, loading, error } = useQuery(GET_PLAYER_VIEW_STATISTICS, {
    skip: !rinknetIds?.length,
    variables: {
      rinknetIds,
      season
    },
  });

  const idToStats = useMemo(() => {
    if (!data && !previousData) return {};

    const stats =
      data?.playerViewStatistics || previousData?.playerViewStatistics || [];

    return stats.reduce((acc, curr) => {
      acc[curr.rinknetId] = {
        ...curr,
        rowComponent: 'asdf'
      };
      return acc;
    }, {});
  }, [data, previousData]);

  return {
    loading,
    error,
    idToStats,
  };
}
