import { IconButton } from 'components/bdd/Button';
import { Container, Row } from 'components/bdd/Layout';
import { theme } from 'constants/theme';
import { getColorWithOpacity } from 'helpers/plotting';
import { useContext, useEffect } from 'react';
import { Crop } from 'react-bootstrap-icons';
import { VideoClipContext, VideoPlayerContext } from '..';
import { LinkPopover } from 'components/bdd/Popover';
import { CommentForm } from 'components/bdd/TextEditor';
import { Button } from 'react-bootstrap';
import { Typography } from 'components/bdd/Typography';
import { VideoContext } from '../..';
import { usePlaylistMutation } from 'components/Video/Playlists';
import { TooltipSpan } from 'components/reports';

export const VideoClipEditControls = ({}) => {
  const { playlist } = useContext(VideoContext) || {};
  const { playerState, isClipEdited, currentTime } = useContext(VideoPlayerContext);
  const { updatePlaylist, loading: playlistUpdating } = usePlaylistMutation(playlist);

  const {
    clips,
    currentClip,
    updateClip,
    updateClips,
    clipTrimControlsToggled,
    toggleClipTrimControls,
  } = useContext(VideoClipContext);

  if (!!playlist && !playlist.canEdit) return null;

  const handleClipUpdate = () => {
    if (!!playlist) {
      updatePlaylist({
        variables: {
          input: {
            id: playlist.id,
          },
          clipsToUpdate: [
            {
              id: currentClip.id,
              frontPad: playerState.frontPad,
              backPad: playerState.backPad,
              leadTrim: playerState.leadTrim,
              trailTrim: playerState.trailTrim,
            },
          ],
        },
      });
    }

    updateClip({
      ...currentClip,
      frontPad: playerState.frontPad,
      backPad: playerState.backPad,
      leadTrim: playerState.leadTrim,
      trailTrim: playerState.trailTrim,
    });
  };

  const handleAllClipUpdate = () => {
    const updatedClips = clips.map((c) => ({
      id: c.id,
      frontPad: playerState.frontPad,
      backPad: playerState.backPad,
      leadTrim: playerState.leadTrim,
      trailTrim: playerState.trailTrim,
    }));

    if (!!playlist) {
      updatePlaylist({
        variables: {
          input: {
            id: playlist.id,
          },
          clipsToUpdate: updatedClips,
        },
      });
    }

    updateClips(
      clips.map((c) => ({
        ...c,
        frontPad: playerState.frontPad,
        backPad: playerState.backPad,
        leadTrim: playerState.leadTrim,
        trailTrim: playerState.trailTrim,
      }))
    );
  };

  return (
    <Container padding={1}>
      <Row columnGap={2}>
        <IconButton
          padding={0}
          icon={<Crop />}
          color={
            clipTrimControlsToggled
              ? theme.colors.teams.bos.primary
              : getColorWithOpacity(theme.colors.dark.text.primary, 0.8)
          }
          size={18}
          onClick={() => toggleClipTrimControls((isEdited) => !isEdited)}
        />
        {(isClipEdited() || playlistUpdating) && (
          <Button
            disabled={playlistUpdating}
            variant="success"
            size="sm"
            onClick={handleClipUpdate}
          >
            <Typography variant="body1">
              {playlistUpdating ? 'Saving...' : 'Save'}
            </Typography>
          </Button>
        )}
        {(isClipEdited() || playlistUpdating) && (
          <TooltipSpan
            content={
              <Typography variant="body1">
                This will OVERWRITE all changes made to other clips
              </Typography>
            }
          >
            <Button
              disabled={playlistUpdating}
              variant="dark"
              size="sm"
              onClick={handleAllClipUpdate}
            >
              <Typography variant="body1">
                {playlistUpdating ? 'Saving...' : 'Save all'}
              </Typography>
            </Button>
          </TooltipSpan>
        )}
      </Row>
    </Container>
  );
};

const PlaylistClipAnnotationForm = ({ currentTime }) => {
  return (
    <Container>
      <CommentForm />
    </Container>
  );
};
