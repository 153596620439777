import produce from 'immer';
import { create } from 'zustand';

const mergeLists = (currentList, newList) => {
  const map = currentList.reduce((map, obj) => {
    map[obj.id] = obj;
    return map;
  }, {});

  newList.forEach((obj) => {
    map[obj.id] = obj;
  });

  return Object.values(map);
};

const sortNotes = (notes) =>
  notes.sort(
    (a, b) => b.isPinned - a.isPinned || new Date(b.dateCreated) - new Date(a.dateCreated)
  );

const filterNotes = (notes) => notes.filter((n) => !n.isArchived);

export const useNoteStore = create((set, get) => ({
  notes: [],
  actions: {
    loadNotes: (data) => {
      set(
        produce((state) => {
          state.notes = sortNotes(filterNotes(mergeLists(get().notes, data)));
        })
      );
    },
    replaceNote: (oldNote, newNote) => {
      set(
        produce((state) => {
          state.notes = state.notes.filter((n) => n.id != oldNote.id);
          state.notes = sortNotes(filterNotes(mergeLists(state.notes, [newNote])));
        })
      );
    },
  },
}));

export const useNoteActions = () => useNoteStore((state) => state.actions);
