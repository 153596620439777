import { LinkButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { useContext, useState } from 'react';
import {
  EventFilterController,
  EventFlagFilter,
  EventNameFilter,
  EventTypeFilter,
  Location2Filter,
  LocationFilter,
  OnIceToggle,
  OutcomeFilter,
  PeriodFilter,
  PrecedingFlagsFilter,
  ShotLocationFilter,
  ShotOutcomeFilter,
  ShotQualityFilter,
  ShotTypeFilter,
  StrengthFilter,
  SuccessiveFlagsFilter,
  ZoneFilter,
} from '.';
import { VideoContext } from '../..';
import { ShotChanceTypeFilter } from './ShotChanceTypeFilter';

export const FilterSummary = ({}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const {
    isFiltersDirty,
    filters,
    clearFilters,
    setFilters,
  } = useContext(VideoContext);

  const filterRows = filters?.map((sf, index) => {
    const eventNames = sf['event_name']?.val;
    const includeShotFilters = eventNames?.find((en) =>
      ['shot', 'goal', 'assist'].includes(en)
    );

    return (
      <EventFilterController
        key={`filter-summary-${index}`}
        filters={sf}
        setEventFilters={(filters) => setFilters(filters, index)}
      >
        <Row columnGap={3} alignItems="start">
          <Container>
            <Row columnGap={1} rowGap={1} flexWrap alignItems="end">
              <EventNameFilter />
              <EventTypeFilter />
              <OnIceToggle />
              <OutcomeFilter />
              {includeShotFilters && (
                <>
                  <ShotOutcomeFilter />
                  <ShotChanceTypeFilter />
                </>
              )}
              {showAllFilters && (
                <>
                  <LocationFilter />
                  <Location2Filter />
                  {!includeShotFilters && <EventFlagFilter />}
                  {includeShotFilters && (
                    <>
                      <ShotLocationFilter />
                      <ShotTypeFilter />
                      <ShotQualityFilter />
                    </>
                  )}
                  <ZoneFilter />
                  <PrecedingFlagsFilter readonly />
                  <SuccessiveFlagsFilter readonly />
                </>
              )}
            </Row>
          </Container>
        </Row>
      </EventFilterController>
    );
  });

  return (
    <Container>
      <Column gap={2}>
        <Container minWidth={100}>
          <Row columnGap={2} alignItems="baseline">
            <Typography variant="body2">
              <b>Filters Applied:</b>
            </Typography>
            <Container width="fit-content">
              <LinkButton onClick={() => setShowAllFilters(!showAllFilters)}>
                <Typography variant="stat">
                  {showAllFilters ? 'Hide' : 'More'} filters
                </Typography>
              </LinkButton>
            </Container>
            {isFiltersDirty() && (
              <Container width="fit-content">
                <LinkButton onClick={() => clearFilters()}>
                  <Typography variant="stat" color={theme.colors.states.danger}>
                    Clear filters
                  </Typography>
                </LinkButton>
              </Container>
            )}
          </Row>
        </Container>
        {filterRows}
      </Column>
    </Container>
  );
};
