import React from 'react';
import { Column, Container, Row } from './Layout';
import styled from 'styled-components';

import { LineupSpot, DepthPlayer } from './lineupcomponents';
import { strContains } from '../../helpers/data';
import { ItemGrid } from './bddcomponents';
import { Typography } from './Typography';

/* Expects a lineup of lineupSpot -> playerSlug {leftdefence1: zdeno-chara-77, ...}
playerInfo links slugs to info
 */
export default function DraggableLineup({
  lineup, // { leftdefence1: zdeno-chara-77, ... }
  setLineup,
  playerInfo,
  editMode,
  renderPlayer,
  renderLineupSpot,
  renderLineupInformation,
  renderForwardLineupContent,
  renderDefensemenLineupContent,
  highlightCallback,
  powerPlay = false,
  noGoalie,
  maxForwardRows,
  maxDefenceRows,
}) {
  const fpos = ['leftwing', 'center', 'rightwing'];
  const dgpos = noGoalie
    ? ['leftdefence', 'rightdefence']
    : ['leftdefence', 'rightdefence', 'goalie'];
  const ppPos = ['pp1', 'pp2', 'pp3', 'pp4', 'pp5'];
  const pToShort = {
    leftwing: 'LW',
    center: 'C',
    rightwing: 'RW',
    leftdefence: 'LD',
    rightdefence: 'RD',
    goalie: 'G',
    pp1: 'PP1',
    pp2: 'PP2',
    pp3: 'PP3',
    pp4: 'PP4',
    pp5: 'PP5',
  };

  const lineupKeys = Object.keys(lineup);
  const lineupPlayerSlugs = new Set(Array.from(Object.values(lineup)));
  var numFRows =
    maxForwardRows ||
    Math.max(
      5,
      ...lineupKeys
        .filter((k) =>
          strContains(k, ['leftwing', 'center', 'rightwing'], true, 'any')
        )
        .map((k) => parseInt(k.slice(-1)) + 1)
    );

  var numDRows =
    maxDefenceRows ||
    Math.max(
      4,
      ...lineupKeys
        .filter((k) =>
          strContains(k, ['leftdefence', 'rightdefence', 'goalie'], true, 'any')
        )
        .map((k) => parseInt(k.slice(-1)) + 1)
    );
  var numPPRows = Math.max(
    2,
    ...lineupKeys
      .filter((k) => k.startsWith('pp'))
      .map((k) => parseInt(k.slice(-1)) + 1)
  );
  const unselectedPlayers = Object.keys(playerInfo)
    .filter((p) => !lineupPlayerSlugs.has(p))
    .map((p) => playerInfo[p]);
  if (powerPlay) {
    var rowObjects = [{ positions: ppPos, numRows: numPPRows }];
  } else {
    var rowObjects = [
      { isF: true, positions: fpos, numRows: numFRows },
      { isF: false, positions: dgpos, numRows: numDRows },
    ];
  }

  const updateLineupSpot = (lineupSpot, newPlayerSlug) => {
    const newLineup = { ...lineup };
    const oldPlayerSlug = lineup[lineupSpot];

    newLineup[lineupSpot] = newPlayerSlug;
    const existingSpot = Object.entries(lineup).filter(
      ([key, value]) => value === newPlayerSlug
    );
    if (existingSpot.length) {
      if (oldPlayerSlug) {
        newLineup[existingSpot[0][0]] = oldPlayerSlug;
      } else {
        newLineup[existingSpot[0][0]] = null;
      }
    }

    setLineup({ ...newLineup });
  };

  const handleRemovePlayer = (lineupSpot) => {
    const newLineup = { ...lineup };
    delete newLineup[lineupSpot];
    setLineup(newLineup);
  };

  const ColStyle = { margin: 0, padding: '0 3px' };
  return (
    <Container width="100%">
      <Column>
        {rowObjects.map((o) => (
          <Column key={`lp-${o.isF}`}>
            {[...Array(o.numRows).keys()].map((idx) => {
              const maxRow = Math.max(
                ...o.positions.map((p) =>
                  Math.max(
                    ...Object.keys(lineup)
                      .filter((ls) => ls.startsWith(p) && !!lineup[ls])
                      .map((p) => parseInt(p.slice(-1)))
                  )
                )
              );

              return (
                <>
                  <Container key={idx}>
                    <Row>
                      {o.positions.map((p) => {
                        const rowHeader =
                          idx === 0 ? (
                            <Typography textAlign="center" variant="body1">
                              {pToShort[p]}
                            </Typography>
                          ) : null;

                        const lineupSpot = `${p}${idx + 1}`;
                        if (!editMode && idx >= maxRow)
                          return (
                            <Column key={lineupSpot} style={ColStyle}>
                              {rowHeader}
                            </Column>
                          );

                        const numExpectedRows = powerPlay
                          ? 2
                          : fpos.includes(p)
                            ? 4
                            : 3;

                        if (idx > maxRow && idx + 1 > numExpectedRows) {
                          return (
                            <Column key={lineupSpot} style={ColStyle}></Column>
                          );
                        }

                        const player =
                          lineupSpot in lineup
                            ? playerInfo[lineup[lineupSpot]]
                            : null;

                        const contentProps = {
                          children: player ? (
                            <DepthPlayer
                              player={player}
                              renderPlayer={renderPlayer}
                              highlightCallback={highlightCallback}
                              showInfo={!editMode}
                              draggable={editMode}
                              lineupSpot={lineupSpot}
                              removeable
                              onRemovePlayer={handleRemovePlayer}
                            />
                          ) : null,
                          lineupSpot,
                        };

                        const displayLineupSpot = player || idx < maxRow;

                        return (
                          <Container
                            key={lineupSpot}
                            flexGrow
                            flexBasis={0}
                            padding={1}
                          >
                            {rowHeader}
                            <LineupSpot
                              lineupSpot={lineupSpot}
                              updateLineupSpot={updateLineupSpot}
                            >
                              {!!renderLineupSpot
                                ? renderLineupSpot(contentProps)
                                : contentProps.children}
                            </LineupSpot>
                          </Container>
                        );
                      })}
                    </Row>
                    {renderLineupInformation &&
                      renderLineupInformation({
                        positions: o.positions,
                        lineNumber: idx + 1,
                      })}
                  </Container>
                </>
              );
            })}
            {o.isF &&
              renderForwardLineupContent &&
              renderForwardLineupContent()}
            {!o.isF &&
              renderDefensemenLineupContent &&
              renderDefensemenLineupContent()}
          </Column>
        ))}
      </Column>
      {editMode ? (
        <>
          <br></br>
          <Typography variant="body1" textAlign="center">
            Bench
          </Typography>
          <div
            style={{
              maxHeight: '200px',
              overflowY: 'scroll',
              padding: '5px 20px',
            }}
          >
            <ItemGrid
              keyField="slug"
              items={unselectedPlayers}
              numCols={3}
              render={(d) => (
                <DepthPlayer player={d} renderPlayer={renderPlayer} draggable />
              )}
            />
          </div>
        </>
      ) : null}
    </Container>
  );
}
