export const withMentions = (editor) => {
  const { isInline, insertText, isElementReadOnly, isSelectable, isVoid, markableVoid } =
    editor;

  editor.isInline = (element) => {
    return element.type == 'mention' || isInline(element);
  };

  editor.isElementReadOnly = (element) => {
    return element.type === 'mention' || isElementReadOnly(element);
  };

  editor.isSelectable = (element) => element.type !== 'mention' && isSelectable(element);
  editor.insertText = (text) => !text.includes('@') && insertText(text);

  return editor;
};
