import { PlaylistForm } from './PlaylistForm';
import { Typography } from 'components/bdd/Typography';
import { PLAYLISTS_ROUTE } from 'constants';
import { useCreatePlaylist } from './usePlaylistMutation';

export const AddPlaylistForm = ({ playlistClips, showPlaylistLink, onSubmit }) => {
  const [createPlaylist, { data, loading }] = useCreatePlaylist();

  const newPlaylistLink = data && (
    <a href={`${PLAYLISTS_ROUTE}/${data.createBddPlaylist.bddPlaylist.id}`}>
      <Typography variant="body1">Go to playlist</Typography>
    </a>
  );

  const handleSubmit = (values) => {
    createPlaylist({
      variables: {
        input: {
          name: values.name,
          description: values.description,
        },
        users: values.users.map((u) => ({
          userId: u.userId,
          canEdit: u.canEdit,
        })),
        clips: (playlistClips || []).map((c) => ({
          frontPad: c.frontPad,
          backPad: c.backPad,
          trailTrim: c.trailTrim,
          leadTrim: c.leadTrim,
          ...(c.event
            ? {
                eventId: c.eventId,
                eventName: c.event.eventName,
                gameUid: c.event.gameUid,
              }
            : {}),
          ...(c.shift
            ? {
                shiftNumber: c.shift.shiftNumber,
                gameUid: c.shift.gameUid,
                playerId: c.shift.playerId,
              }
            : {}),
        })),
      },
    });

    onSubmit && onSubmit(values);
  };

  return (
    <PlaylistForm
      onSubmit={handleSubmit}
      submitting={loading}
      disabled={loading || newPlaylistLink}
    >
      {showPlaylistLink && newPlaylistLink}
    </PlaylistForm>
  );
};
