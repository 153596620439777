import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useContractSearchContext } from './context';
import { Filters, useFilterContext } from 'components/bdd/Filters';
import { LinkPopover } from 'components/bdd/Popover';
import { LinkButton } from 'components/bdd/Button';
import { Typography } from 'components/bdd/Typography';
import { ContractSearchFiltersBody } from './ContractSearchFilters';
import React, { useMemo } from 'react';
import { dateToTimeAgo, parseDateNoTimezone } from 'helpers/helpers';
import { options } from './StatQuery';
import { QueryTags } from 'components/bdd/Query/QueryTags';
import { Button } from 'react-bootstrap';
import { Divider } from 'components/bdd/Divider';
import styled from 'styled-components';
import { theme } from 'constants';

export const RecentFiltersLinkButton = ({}) => {
  return (
    <LinkPopover
      placement="bottom"
      hideTransition
      renderContent={(forceClose) => (
        <RecentFilters onApply={forceClose} onCancel={forceClose} />
      )}
      renderLink={(setShow, target) => (
        <LinkButton onClick={() => setShow((show) => !show)} ref={target}>
          <Typography variant="stat" state="link">
            <b>Recent filters</b>
          </Typography>
        </LinkButton>
      )}
    >
      Recent searches
    </LinkPopover>
  );
};

const HoverContainer = styled(Container)({
  ':hover': {
    backgroundColor: theme.colors.light.focus,
  },
});

const RecentFilters = ({ onApply, onCancel }) => {
  const recentFilters = useContractSearchContext((state) => state.recentFilters);
  const { setFilters, setTimeRange, setStatFilters, applyFilters } =
    useContractSearchContext((state) => state.actions);

  const handleSelect = (rf) => {
    setFilters(rf.filters);
    setTimeRange(rf.timeRange);
    setStatFilters(rf.statFilters);
    applyFilters();
    onApply && onApply();
  };

  return (
    <Container>
      <Column gap={0}>
        <Typography variant="body1">Load past filter search:</Typography>
        <Container paddingTop={2}>
          <Divider />
          {recentFilters.map((rf) => (
            <React.Fragment key={rf.timestamp}>
              <HoverContainer
                padding={1}
                variant="button"
                onClick={() => handleSelect(rf)}
              >
                <Filters defaults={rf.filters}>
                  <Row columnGap={2}>
                    <Typography variant="stat">
                      <b>{dateToTimeAgo(parseDateNoTimezone(rf.timestamp))}</b>
                    </Typography>
                    <RecentFilterTags statFilters={rf.statFilters} />
                  </Row>
                  <div style={{ display: 'none' }}>
                    <ContractSearchFiltersBody />
                  </div>
                </Filters>
              </HoverContainer>
              <Divider />
            </React.Fragment>
          ))}
        </Container>
        <Container paddingTop={2}>
          <Row justifyContent="end">
            <Button variant="danger" size="sm" onClick={onCancel}>
              <Typography variant="stat">
                <b>Close</b>
              </Typography>
            </Button>
          </Row>
        </Container>
      </Column>
    </Container>
  );
};

export const RecentFilterTags = ({ statFilters }) => {
  const tags = useFilterContext((state) => state.tags);

  // const filterBody = useMemo(() => {
  //   <div style={{ display: 'none' }}>
  //     <ContractSearchFiltersBody />
  //   </div>;
  // }, [tags]);

  // if (fuckyoucount == 10) return null;
  // fuckyoucount++;

  return (
    <>
      <Row columnGap={1}>
        {Object.values(tags).map((tag, index) => (
          <React.Fragment key={index}>{tag}</React.Fragment>
        ))}
        <QueryTags filters={statFilters} options={options} />
      </Row>
    </>
  );
};
