export const withMedia = (editor) => {
  const { isInline, insertText, isElementReadOnly, isSelectable, isVoid, markableVoid } =
    editor;

  editor.isInline = (element) => {
    return element.type == 'media' || isInline(element);
  };

  editor.isElementReadOnly = (element) => {
    return element.type === 'media' || isElementReadOnly(element);
  };

  editor.isSelectable = (element) => element.type !== 'media' && isSelectable(element);
  editor.insertText = (text) => !text.includes('@') && insertText(text);

  return editor;
};
