import { LinkButton } from 'components/bdd/Button';
import { LinkPopover } from 'components/bdd/Popover';
import { seasonOptions } from './constants';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { Cell } from 'components/bdd/Table';
import { Column, Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { season8to4 } from 'helpers/hockeyutils';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_BF_CONTRACT } from 'apollo/queries/bruinsfriendly.queries';

export const PlatformSeasonButton = ({ contract }) => {
  const hasOverride = !!contract.overridePlatformSeason;
  const platformSeason = contract.overridePlatformSeason || contract.platformSeason;
  const seasonLabel =
    seasonOptions.find((o) => o.value == platformSeason)?.label || 'Unknown';

  return (
    <LinkPopover
      hideTransition
      renderLink={(setShow, target, show) => (
        <Container ref={target}>
          <LinkButton onClick={() => setShow(!show)}>
            <Cell>{seasonLabel}</Cell>
            {hasOverride && (
              <Container style={{ position: 'absolute', right: -5, top: -2 }}>
                <Typography variant="body2" color={theme.colors.states.danger}>
                  *
                </Typography>
              </Container>
            )}
          </LinkButton>
        </Container>
      )}
      renderContent={() => <OverridePlatformSeasonForm contract={contract} />}
    />
  );
};

export const OverridePlatformSeasonForm = ({ contract }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const overrideOptions = seasonOptions.filter(
    (so) => so.value < contract.platformSeason
  );

  const [updateContract] = useMutation(UPDATE_BF_CONTRACT);

  return (
    <Container padding={2} onClick={(e) => e.stopPropagation()}>
      <Column gap={2}>
        {showDisclaimer && (
          <Typography variant="stat" color={theme.colors.states.danger}>
            Refresh page to see changes
          </Typography>
        )}
        <Container>
          <Typography variant="body1">Platform Season:</Typography>
          <Typography variant="body2">
            {season8to4(contract.platformSeason, true)}
          </Typography>
        </Container>
        <Container>
          <Typography variant="body1">Override Platform Season:</Typography>
          <StyledBDDSelect
            size="sm"
            width={150}
            options={overrideOptions}
            selectedValue={contract.overridePlatformSeason}
            selectProps={{ isClearable: true }}
            onChange={(n, v) => {
              updateContract({
                variables: {
                  input: {
                    id: contract.id,
                    overridePlatformSeason: v,
                  },
                },
                onCompleted: () => setShowDisclaimer(true),
              });
            }}
          />
        </Container>
      </Column>
    </Container>
  );
};
