import React from "react"
import { Formik } from "formik"
import { Form, Button } from "react-bootstrap"
import * as Yup from "yup"
import styled from "styled-components"
import { Redirect } from "react-router-dom"

import DocHead from "../../layouts/documenthead"
import { promise_login_user } from "../../requests/user"
import { isLoggedIn } from "../../helpers/user"
import { DASHBOARD_ROUTE } from "../../constants"
import { BDDErrorMessage, BDDInput } from "../bdd"
import denTextTransparent from "../../assets/images/den_text_transparent.png"
import { bruinsGold } from "../../helpers/plotting"
import { Typography } from "components/bdd/Typography"
import { Column, Container } from "components/bdd/Layout"

const LoginContainer = styled.div`
    text-align: center;
    width: 50%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;

    input {
        width: 100%;
        // Calculate width based on input area + padding (so doesn't overextend)
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */

        padding: 5px 10px;
        border: 0;
        border-radius: 5px;
        font-family: Arial;
        font-size: 18px;
    }

    button {
        margin-top: 20px;
        width: 100%;
        border-radius: 5px;
        background: transparent;
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        border: 1px solid ${bruinsGold};
        color: ${bruinsGold};
        :hover {
            background: ${bruinsGold};
            color: black;
        }
        transition: all 0.5s;
    }
`
const Logo = styled.img`
    display: block;
    max-width: 60%;
    width: auto;
    height: auto;
    margin: auto;

    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        0% { opacity:0; }
        50% { opacity:0.5; }
        100% { opacity:1; }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`
const formFadeIn = '4s'
const FormContainer = styled.div`
    animation: ${formFadeIn} ease-in 0s normal forwards 1 fadein;
    -webkit-animation: ${formFadeIn} ease-in 0s normal forwards 1 fadein; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein ${formFadeIn}; /* Firefox < 16 */
    -ms-animation: fadein ${formFadeIn}; /* Internet Explorer */
    -o-animation: fadein ${formFadeIn}; /* Opera < 12.1 */
`

const loginSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  otherUser: Yup.string().email("Invalid other email")
});

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      error: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values, { setSubmitting }) {

    setSubmitting(true)
    this.setState({ loggedIn: false, error: null })

    promise_login_user(values.email, values.password, values.otherUser).then(res => {
      localStorage.setItem('user', JSON.stringify(res.data.user))
      localStorage.setItem('access_csrf', res.data.access_csrf)
      localStorage.setItem('refresh_csrf', res.data.refresh_csrf)
      setSubmitting(false)
      this.setState({ loggedIn: true })
    }).catch(err => {
      setSubmitting(false)
      if (err.response && err.response.status === 401) {
        this.setState({ error: 'Username/Password not recognized' })
      } else {
        this.setState({ error: 'Something went wrong' })
      }
    })

  }

  render() {
    if (this.state.loggedIn && isLoggedIn()) {
      const loginRedirect = localStorage.getItem('loginRedirect')

      if (!!loginRedirect) {
        localStorage.removeItem('loginRedirect')
        return <Redirect to={loginRedirect} />
      }

      return <Redirect to={DASHBOARD_ROUTE} />
    }

    return <>
      <DocHead backgroundColor='black' />
      <LoginContainer>
        <Logo
          src={denTextTransparent}
        />
        <Formik
          initialValues={{ email: "", password: "", otherUser: "" }}
          validationSchema={loginSchema}
          onSubmit={this.handleSubmit}
          validateOnChange={false}
        >
          {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
            <FormContainer>
              <BDDErrorMessage>
                {this.state.error}
              </BDDErrorMessage>
              <Form onSubmit={handleSubmit}>
                <Column gap={4}>
                  <Container>
                  {!!this.props.admin &&
                    <Typography variant='body1' color='#ddd'>
                      Enter your credentials:
                    </Typography>
                  }
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Container>
                  <Container>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Container>
                  {!!this.props.admin &&
                    <Column>
                      <Typography variant='body1' color='#ddd'>
                        Enter email of user to login as:
                      </Typography>
                      <BDDInput
                        name="otherUser"
                        autoComplete="new-password"
                        placeholder='Enter "as user" email'
                        value={values.otherUser}
                        onChange={handleChange}
                        isInvalid={!!errors.otherUser}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.otherUser}
                      </Form.Control.Feedback>
                    </Column>
                  }
                  <button type="submit">
                    {isSubmitting ?
                      'Logging in...'
                      : 'Login'}
                  </button>
                </Column>
              </Form>
            </FormContainer>
          )}
        </Formik>
      </LoginContainer>
    </>
  }
}

export default Login;
