import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Field } from './Field';

export const SelectFilter = ({
  id,
  label,
  cond = '=',
  variant = 'default',
  size = 'sm',
  selectProps,
  options,
  onChange,
  loading,
  meta,
  hide,
  ...fieldProps
}) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  useRegisterFilterTag(
    id,
    filter && (
      <FilterTag
        name={label}
        label={
          Array.isArray(filter.value)
            ? options
                ?.filter((o) => filter.value.includes(o.value))
                .map((o) => o.label)
                .join(', ')
            : options?.find((o) => o.value == filter.value)?.label
        }
      />
    )
  );

  return (
    !hide && (
      <Field id={id} onChange={onChange} label={label} {...fieldProps}>
        <StyledBDDSelect
          selectedValue={filter?.value}
          variant={variant}
          size={size}
          selectProps={{
            isSearchable: true,
            isLoading: loading,
            ...selectProps,
          }}
          options={options}
          onChange={(n, v) => {
            console.log('change??', { id, type: 'select', cond, value: v, meta })

            if (selectProps?.isMulti && v.length == 0) {
              removeFilter(id);
            } else {
              setFilters(id, { id, type: 'select', cond, value: v, meta });
            }

            onChange && onChange(v);
          }}
        />
      </Field>
    )
  );
};
