import { gql } from '@apollo/client';
import { PlayerListFragment } from './playerlist.queries';

export const GET_NAVIGATION_INFO = gql`
  query GetNavigationInfo {
    playerLists(pinsOnly: true) {
      id
      listType
      idType
      name
    }
    scoutingObjectives(activeOnly: true, pinsOnly: true) {
      slug
      name
      objectiveType
    }
    proObjectives(includeArchived: false, pinsOnly: true) {
      slug
      name
    }
    proObjectiveScenarios(includeChecks: false, includeCapSheet: false, includeTeamStrength: false, pinsOrMineOnly: true) {
      id
      title
    }
    drafts(pinsOrMineOnly: true) {
      id
      slug
      name
      year
      lastUpdateTime
      pinPriority
      isPublic
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    countNotificationsForUser(isViewed: false, includeExpired: false)
  }
`;
