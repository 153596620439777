import styled from 'styled-components'
import React, { Component } from 'react';
import { Form } from "react-bootstrap"

export const BDDErrorColor = 'rgb(256, 66, 78)'

export const BDDErrorMessage = styled.span`
    color: ${BDDErrorColor};
`

export const BDDFormControl = ({ formik, title, name, label, ...rest }) => {
    return <>
        {label ? <Form.Label>{title}</Form.Label> : null }
        <Form.Control
            type="text"
            name={name}
            value={formik.values[name]}
            onChange={formik.handleChange}
            isInvalid={formik.touched[name] && formik.errors[name]}
            {...rest}
        />
        <Form.Control.Feedback type="invalid">
            {formik.errors[name]}
        </Form.Control.Feedback>
    </>
}