import { useMutation } from '@apollo/client';
import { ARCHIVE_PLAYLIST, GET_PLAYLISTS } from 'apollo/queries/video.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { IconButton } from 'components/bdd/Button';
import { useArchiveModal } from 'components/bdd/Modal';
import { theme } from 'constants';
import { Archive } from 'react-bootstrap-icons';
import { usePlaylistActions } from '.';

export const ArchivePlaylistIconButton = ({ playlistId, onArchive }) => {
  const { updatePlaylist } = usePlaylistActions();
  const [archivePlaylist, { loading }] = useMutation(ARCHIVE_PLAYLIST, {
    onError: (error) => toastBddApiError(error),
    onCompleted: (data) => {
      toastInfo(`Playlist successfully archived`);
      updatePlaylist(data.archiveBddPlaylist);
    },
  });

  const { renderArchiveModal } = useArchiveModal({
    entityName: 'playlist',
    onArchive: () => {
      archivePlaylist({
        variables: {
          id: playlistId,
        },
      });

      onArchive && onArchive();
    },
  });

  return (
    <IconButton
      icon={<Archive />}
      hoverColor={theme.colors.teams.bos.primary}
      tooltip="Archive this playlist"
      loading={loading}
      onClick={renderArchiveModal}
    />
  );
};
