import { useQuery } from '@apollo/client';
import {
  GET_COMMON_LINEUP,
  GET_LINEUP_STATS,
} from 'apollo/queries/lineup.queries';
import DraggableLineup from 'components/bdd/draggablelineup';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { SLStatline } from 'components/SLStats';
import { useSLStatContext } from 'components/SLStats/hooks';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { buildPlayerRoute } from 'helpers/routing';
import { Link } from 'react-router-dom';

export const CommonLineup = ({ season, leagueSlug, teamSlug }) => {
  const { toggleComponent: onlyTeamPlayersToggle, toggled: onlyTeamPlayers } =
    useToggle({
      label: 'Show players currently on team',
      initialToggled: true,
    });

  const { toggleComponent: groupByLineToggle, toggled: groupByLine } =
    useToggle({
      label: 'Group by line/pair',
      initialToggled: true,
    });

  const { toggleComponent: showStatsToggle, toggled: showStats } = useToggle({
    label: 'Show stats',
  });

  const { data: lineupStatsData, placeholder: lineupStatsPlaceholder } =
    usePlaceholder(
      useQuery(GET_LINEUP_STATS, {
        skip: groupByLine,
        variables: {
          teamSlug,
          season: getCurrentSeason(),
          onlyTeamPlayers,
        },
      })
    );

  const { data: commonLineupData, placeholder: commonLineupPlaceholder } =
    usePlaceholder(
      useQuery(GET_COMMON_LINEUP, {
        skip: !groupByLine,
        variables: {
          teamSlug,
          season,
          onlyTeamPlayers,
        },
      })
    );

  const placeholder = groupByLine
    ? commonLineupPlaceholder
    : lineupStatsPlaceholder;

  const lineupSpots = groupByLine
    ? commonLineupData?.commonLineupV3
    : lineupStatsData?.lineupStatsV3.commonLineup.map((ls) => ({
        ...ls,
        slPlayer: lineupStatsData?.lineupStatsV3.players.find(
          (p) => p.leaguePlayerId == ls.leaguePlayerId
        ),
      }));

  const unitStatDefaults = {
    filters: {
      min_toi: 10,
      norm: 20,
      league: leagueSlug,
      season: season || getCurrentSeason(),
      strength: 'ES55',
      teams: [teamSlug],
      statDefs: [
        {
          slug: 'toi',
          label: 'toi',
        },
        {
          slug: 'ozFaceoffStartPercentage',
          label: 'OZS%',
        },
        {
          slug: 'XPGDiff',
          label: 'XPGΔ',
        },
        {
          slug: 'XPGF',
          label: 'XPGF',
        },
        {
          slug: 'XPGA',
          label: 'XPGA',
        },
        {
          slug: 'shotAttemptsFor',
          label: 'SAT For',
        },
        {
          slug: 'shotAttemptsAgainst',
          label: 'SAT Ag',
        },
        {
          slug: 'ozPossessionPercent',
          label: '% OZ Poss',
        },
        {
          slug: 'controlledEntriesFor',
          label: 'Entries',
        },
      ],
    },
  };

  const { data: linesData } = useSLStatContext({
    defaults: {
      target: 'lines',
      ...unitStatDefaults,
    },
  });

  const { data: pairsData } = useSLStatContext({
    defaults: {
      target: 'pairs',
      ...unitStatDefaults,
    },
  });

  const lineup = lineupSpots?.reduce((lineup, lineupSpot) => {
    lineup[`${lineupSpot.position}${lineupSpot.lineNum}`] =
      lineupSpot.slPlayer.slPlayerMaster.bddPlayerSlug.slug;

    return lineup;
  }, {});

  const playerInfo = lineupSpots?.reduce((playerInfo, lineupSpot) => {
    const bddPlayerSlug = lineupSpot.slPlayer.slPlayerMaster.bddPlayerSlug.slug;
    playerInfo[bddPlayerSlug] = lineupSpot.slPlayer;

    return playerInfo;
  }, {});

  return (
    <Container padding={3}>
      <Row columnGap={4} flexWrap>
        {onlyTeamPlayersToggle}
        {groupByLineToggle}
        {showStatsToggle}
      </Row>
      {placeholder || (
        <DraggableLineup
          lineup={lineup}
          playerInfo={playerInfo}
          renderPlayer={(slPlayer) => {
            const slug = slPlayer.slPlayerMaster.bddPlayerSlug.slug;

            return (
              <PlayerHover playerSlug={slug}>
                <Link to={buildPlayerRoute(slug)}>
                  <Typography textAlign="center" variant="body1">
                    {slPlayer.firstname} {slPlayer.lastname}
                  </Typography>
                </Link>
              </PlayerHover>
            );
          }}
          renderLineupInformation={({ positions, lineNumber }) => {
            if (!showStats) return null;
            const targetSlugs = positions.map(
              (p) => lineup[`${p}${lineNumber}`]
            );

            if (!!targetSlugs.some((s) => !s)) {
              return null;
            }

            if (!pairsData || !linesData)
              return (
                <Typography variant="stat" textAlign="center">
                  Loading stats...
                </Typography>
              );

            const statData = positions.includes('center')
              ? linesData
              : pairsData;

            const targetData = statData.data.find((d) =>
              d.slugs.every((s) => targetSlugs.includes(s))
            );

            if (!targetData)
              return (
                <Typography variant="stat" textAlign="center">
                  No stats found for line/pair
                </Typography>
              );

            return <SLStatline data={statData} row={targetData} />;
          }}
          noGoalie
        />
      )}
    </Container>
  );
};
