import { useQuery } from '@apollo/client';
import { GET_ALL_REPORTS_FOR_PLAYER } from 'apollo/queries/scouting.queries';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import BDDReport from 'components/Scouting/PlayerReports/bddreport';
import { getMetastatsForReport } from 'components/Scouting/PlayerReports/helpers';
import RinknetReport from 'components/Scouting/PlayerReports/rinknetreport';
import { formatDate } from 'helpers/helpers';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';

const reportToOption = (r) => {
  // Maps a report to the "option" text on left bar
  if (r.__typename == 'RinknetReports') {
    return `${r.author} ${formatDate(r.date)}`;
  } else if (r.__typename == 'BDDPlayerScoutingReport') {
    return `${r.author} ${formatDate(r.creationDate)}`;
  }
};

export const PlayerReportViewer = ({ rinknetId, scoutId: scoutIdArg }) => {
  const [index, setIndex] = useState(0);
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
      variables: { rinknetId, scoutId: scoutIdArg },
    })
  );

  const reports = !!data ? data.allPlayerReports.reports : [];
  const metastats = !!data ? data.allPlayerReports.metastatSplits : [];
  const statsData = data?.allPlayerReports?.statsData;

  const reportOptions = reports?.map((r) => ({
    label: reportToOption(r),
    value: r.id,
    report: r,
  }));

  const selectedReport = reportOptions[index];

  if (!data) return placeholder;

  return (
    <Container>
      <Column gap={3}>
        <Row justifyContent="space-between">
          <StyledBDDSelect
            options={reportOptions}
            variant="outlined"
            selectedValue={reportOptions[index]}
            onChange={(n, v) => {
              setIndex(reportOptions.map((r) => r.value).indexOf(v));
            }}
          />
          <Row columnGap={1}>
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => {
                if (index == 0) {
                  setIndex(reportOptions.length - 1);
                } else {
                  setIndex(index - 1);
                }
              }}
            >
              <Icon icon={<ArrowLeft />} />
            </Button>
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => {
                if (index == reportOptions.length - 1) {
                  setIndex(0);
                } else {
                  setIndex(index + 1);
                }
              }}
            >
              <Icon icon={<ArrowRight />} />
            </Button>
          </Row>
        </Row>
        {!!selectedReport ? (
          selectedReport.report.__typename === 'RinknetReports' ? (
            <RinknetReport
              report={selectedReport.report}
              metastats={getMetastatsForReport(selectedReport.report, metastats)}
              statsData={statsData}
            />
          ) : (
            <BDDReport
              report={selectedReport.report}
              expandable={false}
              noBorder={true}
              metastats={getMetastatsForReport(selectedReport.report, metastats)}
            />
          )
        ) : (
          <em>Select a report</em>
        )}
      </Column>
    </Container>
  );
};
