import { buildSlugToTxs, txToFirstVariant, txToVariants } from "components/ProObjectives/Transactions/helpers";
import { isFreeAgent } from "../helpers";

export const buildScenarios = ({
  scenarios,
  baseDepthChart,
  transactions,
  allTargets=null,

}) => {

  const activeDcPlayers = baseDepthChart?.depthChartPlayers?.map(
    (p) => p.playerSlug
  );
  const activeDcPlayerSet = new Set(activeDcPlayers);

  const transactionLookup = transactions?.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const slugToTxs = buildSlugToTxs(transactions);

  if (!scenarios) return null;
  if (!baseDepthChart) return null;
  return scenarios?.map((s) => {
    const teamSlug = s.teamSlug;
    
    const transactions = !!transactionLookup
      ? s.transactions
          .filter((t) => t.transactionId in transactionLookup)
          .map((t) => {
            const tx = transactionLookup[t.transactionId];
            const selectedVariant = !!t.variantId
              ? txToVariants(tx).find((v) => v.id == t.variantId)
              : txToFirstVariant(tx);

            if (!selectedVariant) {
              console.error('Null selected variant', s, t, tx);
              return null;
            }

            return {
              ...tx,
              variantId: t.variantId,
              selectedVariant,
            };
          })
          .filter((t) => !!t)
      : [];
    const scenarioTxIds = transactions.map((t) => t.id);
    const scenarioDcPlayers = s.depthChart.depthChartPlayers.map((p) => p.playerSlug);
    const scenarioDcPlayerSet = new Set(scenarioDcPlayers);

    const buildScenarioPlayer = ({ inOrOut, bddPlayer, depthChartSpot }) => {
      const playerTxs =
        slugToTxs?.[bddPlayer.slug]?.filter((tx) => scenarioTxIds.includes(tx.id)) ||
        [];
      return {
        inOrOut,
        scenarioId: s.id,
        slug: bddPlayer.slug,
        bddPlayer: bddPlayer,
        depthChartSpot,
        isFreeAgent: isFreeAgent(bddPlayer, playerTxs),
        target: allTargets?.find((t) => t.bddPlayerSlug === bddPlayer.slug),
        transactions: playerTxs,
        isInDc: scenarioDcPlayerSet.has(bddPlayer.slug),
        isInActiveDc: activeDcPlayerSet.has(bddPlayer.slug),
        transactionType:
          playerTxs.length > 1
            ? 'MULTIPLE'
            : playerTxs.length === 1
            ? playerTxs[0].transactionType
            : null,
      };
    };

    // "playersIn" is the combo of players who are in our DC and players acquired thru TX not in DC yet
    let playersIn = s.depthChart.depthChartPlayers
      .filter((p) => !!p.playerSlug && !activeDcPlayerSet.has(p.playerSlug))
      .map((p) =>
        buildScenarioPlayer({
          inOrOut: 'in',
          bddPlayer: p.bddPlayer,
          depthChartSpot: p,
        })
      );

    transactions.forEach((tx) => {
      if (tx.transactionType === 'SIGNING') {
        if (!playersIn.find((p) => p.slug === tx.selectedVariant.bddPlayerSlug)) {
          playersIn.push(
            buildScenarioPlayer({
              inOrOut: 'in',
              bddPlayer: tx.selectedVariant.bddPlayer,
            })
          );
        }
      } else if (tx.transactionType === 'TRADE') {
        if (!tx.selectedVariant) {
          console.error('Null selected variant', tx);
          return;
        }

        tx.selectedVariant?.tradedPlayers
          ?.filter((tp) => tp.toTeamSlug === teamSlug && !tp.isLayover)
          ?.forEach((tp) => {
            if (!playersIn.find((p) => p.slug === tp.playerSlug)) {
              playersIn.push(
                buildScenarioPlayer({ inOrOut: 'in', bddPlayer: tp.bddPlayer })
              );
            }
          });
      }
    });

    // similarly, "playersOut" is the combo of assets that are no longer in the DC and players traded out
    let playersOut = baseDepthChart?.depthChartPlayers
      .filter((p) => 
        !!p.playerSlug && !scenarioDcPlayerSet.has(p.playerSlug) && !slugToTxs?.[p.playerSlug]?.find(tx => scenarioTxIds.includes(tx.id) && tx.transactionType === 'SIGNING'))
      .map((p) =>
        buildScenarioPlayer({
          inOrOut: 'out',
          bddPlayer: p.bddPlayer,
          depthChartSpot: p,
        })
      );
    if (!playersOut) playersOut = [];
    // Now we add players from trades where fromTeamSlug is focus team
    transactions.forEach((tx) => {
      if (tx.transactionType === 'TRADE') {
        tx.selectedVariant.tradedPlayers
          .filter((tp) => tp.fromTeamSlug === teamSlug)
          .forEach((tp) => {
            if (!playersOut.find((p) => p.slug === tp.playerSlug)) {
              playersOut.push(
                buildScenarioPlayer({ inOrOut: 'out', bddPlayer: tp.bddPlayer })
              );
            }
          });
      }
    });

    // Now we remove players who are in both (since they're in and out)
    let playersInBoth = playersIn
      .filter((pi) => !!playersOut.find((po) => po.slug === pi.slug))
      .map((p) => p.slug);
    playersIn = playersIn.filter((p) => !playersInBoth.includes(p.slug));
    playersOut = playersOut.filter((p) => !playersInBoth.includes(p.slug));

    // Find all our free agents
    const freeAgents = s.depthChart.depthChartPlayers
      .filter((p) =>
        isFreeAgent(
          p.bddPlayer,
          slugToTxs?.[p.playerSlug]?.filter((tx) => scenarioTxIds.includes(tx.id))
        )
      )
      .map((p) =>
        buildScenarioPlayer({
          inOrOut: 'in',
          bddPlayer: p.bddPlayer,
          depthChartSpot: p,
        })
      );

    let picksIn = [];
    let picksOut = [];
    transactions.forEach((tx) => {
      if (tx.transactionType === 'TRADE') {
        tx.selectedVariant.tradedDraftPicks.forEach((tp) => {
          if (tp.toTeamSlug === teamSlug && !tp.isLayover) {
            picksIn.push({ ...tp });
          } else if (tp.fromTeamSlug === teamSlug) {
            const existing = picksOut.find((po) => po.pickId === tp.pickId);
            if (!!existing) {
              existing.isDoubleTraded = true;
            } else {
              picksOut.push({ ...tp });
            }
          }
        });
      }
    });

    // Again, clean out picks acquired and then sent away
    const picksInBoth = picksIn
      .filter((pi) => !!picksOut.find((po) => po.pickId === pi.pickId))
      .map((p) => p.pickId);
    picksIn = picksIn.filter((p) => !picksInBoth.includes(p.pickId));
    picksOut = picksOut.filter((p) => !picksInBoth.includes(p.pickId));

    // attach agent based results
    // const agentBasedData = agentData?.runTeamStrengthDepthCharts
    //   ?.find((d) => d.depthChartId == s.depthChartId)
    //   .teamStrengths.find((s) => s.teamSlug == proObjective.teamSlug);
    const agentBasedData = s.teamStrength;

    // num NHL players
    const numNhlPlayers = s.depthChart.depthChartPlayers.filter(
      (p) => p.depthLeague === 'NHL'
    ).length;

    return {
      ...s,
      hasEmptyDepthChart: s.depthChart.depthChartPlayers.length === 0,
      playersIn,
      playersOut,
      freeAgents,
      picksIn,
      picksOut,
      transactions,
      agentBased: agentBasedData,
      // baseAgentBased: baseAgentBasedData,
      baseAgentBased: null,
      numNhlPlayers,
    };
  });
};