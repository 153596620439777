import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Form, Row, Col, Button, Spinner } from "react-bootstrap"

import { BDDFormControl } from "../bdd"
import { useUser } from "../../helpers/user"
import BDDSelect from '../bdd/bddselect'
import { useQuery, useMutation, gql } from "@apollo/client"

const schema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    public: Yup.boolean(),
    bddList: Yup.boolean(),
    userId: Yup.number(),
    playerSlugs: Yup.array().of(Yup.string())
})

const CREATE_PLAYER_LIST = gql`mutation CreatePlayerList(
    $input: CreatePlayerListInput!
) {
    createPlayerList(input: $input) {
        playerList {
            id
            name
            description
            public
            isActive
            bddList
            isWatchlist
            user {
                id 
                firstname
                lastname
            }
            players {
                firstname
                lastname
                slug
            }
        }
    }
}`

const updateCacheFxn = {
    update(cache, { data: { createPlayerList } }) {
        cache.modify({
            fields: {
                playerLists(existing=[]) {
                    return existing.concat(createPlayerList.playerList)
                }
            }
        });
    }
}

export default function CreateListForm(props) {
    const { user, isUserAdmin } = useUser();
    // const [] = useQuery(gql`{}`) // do we need to query for players?
    const [createPlayerList, { loading: mutationLoading, error: mutationError }]  = useMutation(CREATE_PLAYER_LIST, updateCacheFxn)

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            public: false,
            bddList: false,
            userId: user?.id,
            playerSlugs: props.selectedPlayers.map(p => p.slug) || []
        },
        validationSchema: schema,
        onSubmit: values => {
            console.log(values)
            createPlayerList({variables: { input: values }}).then(_ => props.setListViewerMode())
        }
    })
    return <Form onSubmit={formik.handleSubmit}>
        <div style={{textAlign: 'center'}}>
            <b>Create Player List</b>
        </div>
        {props.selectedPlayers.length === 0 ? 
            <div><em>No players selected</em></div>
        : <ul>
            {props.selectedPlayers.map(p => <li key={p.slug}>
                {p.name} ({p.currentteam_shorthand})
            </li> )}
        </ul> }

        <Row>
            <Col>
                <BDDFormControl
                    formik={formik}
                    title='Name'
                    name='name'
                    label
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <BDDFormControl
                    formik={formik}
                    title='Description'
                    name='description'
                    as='textarea'
                    rows={3}
                    label
                />
            </Col>
        </Row>
        {/* <Row>
            <Col>
                <BDDSelect
                />
            </Col>
        </Row> */}
        <Row>
            <Col>
                <Form.Check
                    name="public"
                    label="Share publicly"
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.public}
                    feedback={formik.errors.public}
                />
            </Col>
            {isUserAdmin() ? 
                <Col>
                    <Form.Check
                        name="bddList"
                        label="BDD List"
                        onChange={formik.handleChange}
                        isInvalid={!!formik.errors.bddList}
                        feedback={formik.errors.bddList}
                    />
                </Col>
            : null}
        </Row>
        <Row style={{marginTop: "5px"}}>
            <Col>
                <Button type="submit" variant="warning">
                    {mutationLoading ? 
                        <>
                            <Spinner as="span" animation="border" size="sm"/>
                            Submitting
                        </>
                    : 'Submit' }
                </Button>
            </Col>
        </Row>
        
    </Form>
}