import { theme } from 'constants';
import { TimelineItem } from '.';
import { Container } from '../Layout';
import { Typography } from '../Typography';

export const BaseTimelineItem = ({ header, icon, lastItem }) => {
  const item = (
    <Container
      paddingLeft={3}
      padding={2}
      backgroundColor={theme.colors.light.secondary}
    >
      <Typography variant="body2">{header}</Typography>
    </Container>
  );

  return <TimelineItem item={item} lastItem={lastItem} icon={icon} />;
};
