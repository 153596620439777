import React from 'react'
import { Dropdown } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
))

export default function BDDDropdownMenu({
    id,
    trigger,
    items, // [{ label, href, onClick }]
    includeCaret=false,
    ...props // n.b. you can pass in "show" and "onToggle" to make this component controlled
}) {
    return <Dropdown {...props}>
    <Dropdown.Toggle as={CustomToggle} id={id}>
        {trigger}
        {includeCaret ? <>&#x25bc;</> : null}
    </Dropdown.Toggle>

    <Dropdown.Menu>
        {items.map(i => 
            <Dropdown.Item key={'key' in i ? i.key : i.label} href={i.href} onClick={i.onClick} className='bdd-dropdown-item'>
                {i.label}
            </Dropdown.Item>)}
    </Dropdown.Menu>
    </Dropdown>
}