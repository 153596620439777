import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PLAYER_LIST } from 'apollo/queries/playerlist.queries';
import { GET_BDD_PLAYER_SLUG_FROM_RINKNET_ID } from 'apollo/queries/players.queries';
import { IconButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { COMPARE_PLAYERS_ROUTE } from 'constants';
import { theme } from 'constants/theme';
import { Button, Dropdown } from 'react-bootstrap';
import { ChevronDown, XLg } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const ListActionToolbar = ({
  playerList,
  selectedPlayers,
  tiers,
  onPlayersCleared,
}) => {
  const { data: selectedBddPlayerData } = useQuery(GET_BDD_PLAYER_SLUG_FROM_RINKNET_ID, {
    skip: selectedPlayers.length == 0 || playerList.idType != 'rinknet_id',
    variables: {
      rinknetIds: selectedPlayers,
    },
  });

  const [updatePlayerTierMove, { data, loading }] = useMutation(UPDATE_PLAYER_LIST);

  const [removePlayers] = useMutation(UPDATE_PLAYER_LIST);

  const selectedPlayerSlugs =
    playerList.idType == 'bdd_player_slug'
      ? selectedPlayers
      : selectedBddPlayerData?.bddPlayers.map((bdd) => bdd.slug);

  if (!selectedPlayers || selectedPlayers.length == 0) {
    return null;
  }

  const movePlayerToTier = (t) => {
    console.log(t)
    const selectedListPlayers = playerList.players.filter((pl) =>
      selectedPlayers.includes(pl.playerId)
    );

    let playersToReorder = [];
    let startsWithListNumber = t.startsWithListNumber;
    let endsWithListNumber = t.endsWithListNumber;

    if (t.endsWithListNumber + selectedPlayers.length > playerList.players.length) {
      startsWithListNumber = Math.max(1, t.startsWithListNumber - selectedPlayers.length);

      playersToReorder = selectedListPlayers.reverse().map((sp, index) => ({
        id: sp.playerId,
        fromListNumber: sp.listNumber,
        toListNumber: endsWithListNumber - index,
      }));
    } else {
      endsWithListNumber = Math.min(
        t.endsWithListNumber + selectedPlayers.length,
        playerList.players.length
      );

      playersToReorder = selectedListPlayers.map((sp, index) => ({
        id: sp.playerId,
        fromListNumber: sp.listNumber,
        toListNumber: endsWithListNumber + index,
      }));
    }

    let tiersToUpdate = [
      {
        id: t.id,
        startsWithListNumber,
        endsWithListNumber,
      },
    ];

    // Identify the tier from which the players are being moved
    const tierFrom = tiers.find(
      (t) =>
        t.startsWithListNumber <= selectedListPlayers[0].listNumber &&
        t.endsWithListNumber >=
          selectedListPlayers[selectedListPlayers.length - 1].listNumber
    );

    if (tierFrom) {
      let { startsWithListNumber, endsWithListNumber } = tierFrom;
      // Update the tier from which the players are being moved
      if (tierFrom.endsWithListNumber <= t.endsWithListNumber) {
        endsWithListNumber -= selectedPlayers.length;
      }

      if (tierFrom.startsWithListNumber >= t.startsWithListNumber) {
        startsWithListNumber += selectedPlayers.length;
      }

      tiersToUpdate.push({
        id: tierFrom.id,
        startsWithListNumber,
        endsWithListNumber,
      });
    }

    tiersToUpdate.sort((a, b) => a.startsWithListNumber - b.startsWithListNumber);

    updatePlayerTierMove({
      variables: {
        input: { id: playerList.id },
        playersToReorder,
        tiersToUpdate,
      },
      onCompleted: onPlayersCleared,
    });
  };

  return (
    <Container>
      <Row columnGap={2}>
        <Typography variant="subtitle1">
          {selectedPlayers.length} players selected
        </Typography>
        <IconButton
          icon={<XLg />}
          onClick={onPlayersCleared}
          tooltip={<Typography variant="body1">Clear selected</Typography>}
        />
        <Link
          to={`${COMPARE_PLAYERS_ROUTE}?selectedPlayerSlugs=${selectedPlayerSlugs || ''}`}
        >
          <Button variant="outline-dark" size="sm">
            <Typography variant="body1">Compare Players</Typography>
          </Button>
        </Link>
        {!!tiers && tiers.length > 0 && (
          <Dropdown>
            <Dropdown.Toggle variant="primary" size="sm">
              <Row columnGap={2}>
                <Typography variant="body1">Move Players to Tier</Typography>
                <Icon icon={<ChevronDown />} />
              </Row>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {tiers.map((t) => (
                <Dropdown.Item
                  key={t.title || 'Untitled Tier'}
                  onClick={() => movePlayerToTier(t)}
                >
                  <Typography variant="body2">{t.title || 'Untitled Tier'}</Typography>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            if (confirm('Are you sure you want to delete these players?')) {
              removePlayers({
                variables: {
                  input: { id: playerList.id },
                  playersToRemove: selectedPlayers,
                },
                onCompleted: onPlayersCleared,
              });
            }
          }}
        >
          <Typography variant="body1">Remove Players</Typography>
        </Button>
      </Row>
    </Container>
  );
};
