import { gql } from '@apollo/client';

const UserGroupFragment = gql`
  fragment userGroupFragment on UserGroups {
    slug
    name
    description
    users {
      userId
    }
  }
`;

const UserFragment = gql`
  fragment userFragment on Users {
    id
    username
    firstname
    lastname
    admin
    name
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users(loadPermissions: true) {
      ...userFragment
    }
  }
  ${UserFragment}
`;

export const GET_USERS_WITH_PERMISSIONS = gql`
  query GetUsersWithPermissions {
    users(loadPermissions: true) {
      ...userFragment
      permissions
      rolePermissions
      userPermissions
    }
  }
  ${UserFragment}
`;

export const GET_USERS_WITH_ROLES = gql`
  query GetUsersWithRoles {
    users {
      ...userFragment
      roles {
        id
        slug
        description
      }
    }
  }
  ${UserFragment}
`;

export const GET_USERS_FULL = gql`
  query GetUsersFull {
    users(
      loadPermissions: true
      loadGroups: true
      loadRinknet: true
      includeLoginDetails: true
    ) {
      ...userFragment
      email
      isDisabled
      lastLogin
      lastPasswordChange
      registeredOn
      permissions
      rolePermissions
      userPermissions
      roles {
        id
        slug
        description
      }
      rinknetUserId
      loginInfo
      rinknetUser {
        userid
        firstname
        lastname
        email
        isscout
        scouttype
        title
        isinactive
        bddUserId
      }
      groups {
        userGroupSlug
        userGroup {
          slug
          name
          description
        }
      }
    }
  }
  ${UserFragment}
`;

export const GET_RINKNET_USERS = gql`
  query GetRinknetUsers {
    rinknetUsers {
      userid
      firstname
      lastname
      scouttype
      email
      isinactive
      iseuroscout
      phone
      cell
      bddUserId
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroups {
    userGroups {
      ...userGroupFragment
    }
    users(loadPermissions: true) {
      ...userFragment
    }
  }
  ${UserGroupFragment}
  ${UserFragment}
`;

export const GET_USER_SETTINGS = gql`
  query GetUserSettings($id: ID!) {
    userSettings(id: $id) {
      settings
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($id: ID!, $settings: GenericScalar!) {
    updateUserSettings(id: $id, settings: $settings) {
      userSettings {
        settings
      }
    }
  }
`;

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($input: UserGroupInput!, $usersToAdd: [String!]!) {
    createUserGroup(input: $input, usersToAdd: $usersToAdd) {
      userGroup {
        ...userGroupFragment
      }
    }
  }
  ${UserGroupFragment}
`;

export const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($slug: String!) {
    deleteUserGroup(slug: $slug) {
      userGroup {
        ...userGroupFragment
      }
    }
  }
  ${UserGroupFragment}
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($input: UserGroupInput!, $usersToAdd: [String!]!) {
    updateUserGroup(input: $input, usersToAdd: $usersToAdd) {
      userGroup {
        ...userGroupFragment
      }
    }
  }
  ${UserGroupFragment}
`;

export const LINK_RINKNET_USER = gql`
  mutation LinkRinknetUser($rinknetUserId: ID!, $bddUserId: ID!) {
    linkRinknetUser(rinknetUserId: $rinknetUserId, bddUserId: $bddUserId) {
      user {
        ...userFragment
        rinknetUserId
      }
    }
  }
  ${UserFragment}
`;
