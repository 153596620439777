import { useMutation } from '@apollo/client';
import { DELETE_DEPTH_CHART_SPOT_WRAPPER } from 'apollo/queries/depthchart.queries';
import { toastInfo } from 'components/bdd/bddtoasts';
import { getQueryToRefetch } from './DepthChartSpotWrapperForm';

export const useDeleteDepthChartSpotWrapper = ({ wrapper, depthChartId, refetchQueries=[] }) => {
  const [deleteWrapper, query] = useMutation(DELETE_DEPTH_CHART_SPOT_WRAPPER);
  const handleDelete = () => {
    deleteWrapper({
      variables: {
        id: wrapper.id,
      },
      onCompleted: () => {
        toastInfo('Wrapper deleted successfully.');
      },
      refetchQueries: [getQueryToRefetch(depthChartId), ...refetchQueries],
    });
  };

  return {
    handleDelete,
    ...query,
  };
};
