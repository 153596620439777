import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import {
  buildPlayerListQuery,
} from '../../apollo/queries/playerlist.queries';
import { strContains } from '../../helpers/data';
import { BDDLoader } from '../bdd/bddloader';
import BDDSelect from '../bdd/bddselect';
import { SectionHeader, SubSectionHeader } from '../reports';
import BDDApiError from '../bdd/bddapierror';
import { useHistory } from 'react-router-dom';
import { DraftAnalyticsBar } from './comparedraftplayers';
import { PlayerEPTable } from './draftlistbuilder';
import styled from 'styled-components';
import { LIST_ID_TYPES } from 'constants';

const parseHash = (hash) => {
  const hashbase = hash.split('&')[0];
  const playerIds = strContains(hash, 'players=')
    ? hash.split('players=')[1].split('&')[0].split(',')
    : [];
  return {
    hashbase,
    playerIds,
  };
};

const Styles = styled.div``;
export default function CompareDraftPlayersModal({
  show,
  handleClose,
  list,
  playerToAnalytics,
  analyticLoading,
  playerToEP,
  epLoading,
}) {
  const history = useHistory();
  const { data, loading, error } = useQuery(
    buildPlayerListQuery(`bddPlayer { rinknetPlayer { id eliteprospectsid }  }
      rinknetPlayer { id eliteprospectsid }`),
    { variables: { id: list.id } }
  );

  const { hash, pathname } = useLocation();

  const playerToEPID = useMemo(
    () =>
      !!data
        ? data.playerList.players.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.playerId]:
                curr.idType == LIST_ID_TYPES.bdd_player_slug
                  ? curr.bddPlayer.rinknetPlayer.eliteprospectsid
                  : curr.rinknetPlayer.eliteprospectsid,
            }),
            {}
          )
        : {},
    [data]
  );

  const handleChange = (players) => {
    if (players.length > 0) {
      history.push(`${pathname}#compare&players=${players.join(',')}`);
    } else {
      history.push(`${pathname}#compare`);
    }
  };
  const { hashbase, playerIds } = parseHash(hash);
  const playerOptions = !!data
    ? data.playerList.players.map((p) => ({
        value: p.playerId,
        label: `${p.firstname} ${p.lastname}`,
      }))
    : [];

  return (
    <Styles>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <SectionHeader>Compare Prospects</SectionHeader>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <BDDLoader />
          ) : error ? (
            <BDDApiError error={error} />
          ) : (
            <>
              <BDDSelect
                name="players"
                isMulti
                placeholder="Select Players..."
                value={playerIds}
                onChange={(n, v) => handleChange(v)}
                options={playerOptions}
              />
              <hr></hr>
              {!!playerIds && playerIds.length > 0 ? (
                <div>
                  <Row>
                    {epLoading ? (
                      <div style={{ padding: '20px' }}>
                        <em>Loading eliteprospects data...</em>
                      </div>
                    ) : (
                      playerIds.map((pid) => {
                        const epid = playerToEPID[pid];
                        if (!epid)
                          return <em key={pid}>EP ID not found for player</em>;
                        const epdata = playerToEP[epid];
                        if (!epdata)
                          return (
                            <em key={pid}>EP data not found for player</em>
                          );
                        return (
                          <Col
                            key={pid}
                            md={playerIds.length > 1 ? 6 : 12}
                            style={{
                              overflow: 'scroll',
                              border: '1px solid #ddd',
                            }}
                          >
                            <SubSectionHeader>
                              {epdata.player.name}
                            </SubSectionHeader>
                            <PlayerEPTable stats={epdata.stats} />
                          </Col>
                        );
                      })
                    )}
                  </Row>
                  <Row>
                    {analyticLoading ? (
                      <div style={{ padding: '20px' }}>
                        <em>Loading scouting/analytics data...</em>
                      </div>
                    ) : (
                      <>
                        <Col>
                          <DraftAnalyticsBar
                            title="Skill Ratings"
                            columns={[
                              {
                                col: 'Percentile.pro_projection',
                                label: 'Pro Projection',
                              },
                              { col: 'Percentile.skating', label: 'Skating' },
                              {
                                col: 'Percentile.hockey_sense',
                                label: 'Hockey Sense',
                              },
                              { col: 'Percentile.scoring', label: 'Scoring' },
                              { col: 'Percentile.compete', label: 'Compete' },
                            ]}
                            rinknetIds={playerIds}
                            playerToAnalytics={playerToAnalytics}
                          />
                        </Col>
                        <Col>
                          <DraftAnalyticsBar
                            title="Model Scores"
                            columns={[
                              { col: 'OVR', label: 'Overall' },
                              { col: 'OVR_P', label: 'Production' },
                              { col: 'OVR_S', label: 'Scouting' },
                            ]}
                            rinknetIds={playerIds}
                            playerToAnalytics={playerToAnalytics}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              ) : (
                <div style={{ height: '400px' }}>
                  <em>Select players above...</em>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </Styles>
  );
}
