import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Card } from 'components/bdd/Card';
import { Container, Row } from 'components/bdd/Layout';
import { useSeasonSelect } from 'components/bdd/Select';
import { CommonLineup, PlayerBreakdown } from '.';

export const LineupOverview = ({ leagueSlug, teamSlug }) => {
  const { buttonGroup, selectedOption } = useButtonGroup({
    options: [
      { label: 'Common Lineup', value: 'common-lineup' },
      { label: 'Player Breakdown', value: 'player-breakdown' },
    ],
  });

  const { season, seasonSelect } = useSeasonSelect();

  const actionContent = (
    <Row columnGap={1} flexWrap>
      {seasonSelect}
      {buttonGroup}
    </Row>
  );

  return (
    <BDDErrorBoundary>
      <Card header="Lineup Overview" actionContent={actionContent}>
        <Container>
          {selectedOption.value == 'common-lineup' && (
            <CommonLineup
              teamSlug={teamSlug}
              leagueSlug={leagueSlug}
              season={season}
            />
          )}
          {selectedOption.value == 'player-breakdown' && (
            <PlayerBreakdown
              teamSlug={teamSlug}
              leagueSlug={leagueSlug}
              season={season}
            />
          )}
        </Container>
      </Card>
    </BDDErrorBoundary>
  );
};
