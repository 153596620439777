import { useQuery } from '@apollo/client';
import { GET_LINEUP_STATS } from 'apollo/queries/lineup.queries';
import { Border, Column, Container, Grid } from 'components/bdd/Layout';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { roundToX } from 'helpers/data';
import { dOrder, fOrder, pToShort } from './helpers';

export const PlayerBreakdown = ({ season, teamSlug }) => {
  const { toggleComponent, toggled: onlyTeamPlayers } = useToggle({
    label: 'Show players currently on team',
    initialToggled: true,
  });

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_LINEUP_STATS, {
      variables: {
        teamSlug,
        season,
        onlyTeamPlayers,
      },
    })
  );

  const lineupStats = data && data.lineupStatsV3;

  const forwardLineupSpots = lineupStats?.stats
    .filter((ls) => ls.isF)
    .sort(
      (a, b) =>
        a.lineNum - b.lineNum ||
        fOrder.indexOf(a.position) - fOrder.indexOf(b.position)
    );

  const defenseLineupSpots = lineupStats?.stats
    .filter((ls) => !ls.isF)
    .sort(
      (a, b) =>
        a.lineNum - b.lineNum ||
        dOrder.indexOf(a.position) - dOrder.indexOf(b.position)
    );

  return (
    <Container padding={3}>
      {toggleComponent}
      {!data ? (
        placeholder
      ) : (
        <Column gap={2}>
          <Grid gridTemplateColumns={'1fr 1fr 1fr'} columnGap={3} rowGap={3}>
            {forwardLineupSpots.map((l) => (
              <LineupSpotOverview
                key={`ls-${l.lineNum}-${l.position}`}
                lineupStat={l}
                players={lineupStats.players}
              />
            ))}
          </Grid>
          <Grid gridTemplateColumns={'1fr 1fr'} columnGap={3} rowGap={3}>
            {defenseLineupSpots.map((l) => (
              <LineupSpotOverview
                key={`ls-${l.lineNum}-${l.position}`}
                lineupStat={l}
                players={lineupStats.players}
              />
            ))}
          </Grid>
        </Column>
      )}
    </Container>
  );
};

const LineupSpotOverview = ({ lineupStat, players }) => {
  const lineupPlayers = lineupStat.players
    .filter((lp) => lp.count >= 5)
    .map((lp) => ({
      ...lp,
      player: players.find((p) => p.leaguePlayerId == lp.leaguePlayerId),
    }))
    .sort((a, b) => b.count - a.count);

  if (lineupPlayers.length == 0) return null;

  return (
    <Border borderRadius={2}>
      <Container padding={2}>
        <Column gap={1}>
          <Typography variant="body1" textAlign="center">
            {`${lineupStat.lineNum} ${pToShort[lineupStat.position]}`}
          </Typography>
          <Typography variant="body2">
            <Grid gridTemplateColumns="1fr 0.2fr 0.2fr">
              <span>Player</span>
              <span>GP</span>
              <span>%</span>
            </Grid>
          </Typography>
          <Container>
            <Column gap={1}>
              {lineupPlayers.map((p, index) => (
                <PlayerLineupStatLine
                  key={`statline-${p.leaguePlayerId}`}
                  statline={p}
                  firstPlayer={index == 0}
                />
              ))}
            </Column>
          </Container>
        </Column>
      </Container>
    </Border>
  );
};

const PlayerLineupStatLine = ({ statline, firstPlayer }) => {
  const variant = firstPlayer ? 'body1' : 'body2';

  return (
    <Grid gridTemplateColumns="1fr 0.2fr 0.2fr">
      <Typography variant={variant}>
        {statline.player.firstname} {statline.player.lastname}
      </Typography>
      <Typography variant={variant}>{statline.count}</Typography>
      <Typography variant={variant}>
        {roundToX(statline.percentage * 100, 2)}%
      </Typography>
    </Grid>
  );
};
