import { useQuery } from '@apollo/client';

import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GET_BDD_ISSUES_BY_PLAYER } from 'apollo/queries/issues.queries';
import { Container } from 'components/bdd/Layout';
import { IssueList } from 'components/ScoutingV2/components/issues/IssueList';
import { Typography } from 'components/bdd/Typography';

export const Issues = ({ bddPlayer }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BDD_ISSUES_BY_PLAYER, {
      variables: {
        bddPlayerSlug: bddPlayer.slug,
      },
    })
  );

  if (!data) {
    return placeholder;
  }

  const issues = data.allBddIssues.map((issue) => ({
    issue,
    details: JSON.parse(issue.issueDetails),
    player: bddPlayer,
  }));

  return issues.length > 0 ? (
    <Container paddingTop={3}>
      <IssueList issues={issues} />
    </Container>
  ) : (
    <Typography variant="body1">
      <em>No issues found</em>
    </Typography>
  );
};
