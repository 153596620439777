import { useQuery } from "@apollo/client"
import { GET_PLAYERS_WITH_SKATING_EVALS } from "apollo/queries/questionnaire.queries"
import useModal from "components/bdd/Modal/useModal"
import ColinQuestionnaire from "components/Questionnaire/ColinQuestionnaire"
import SkatingEval from "components/Questionnaire/SkatingEval"


export default function useSkatingEvals({
  rinknetIds,
  year,
  skip
}) {
  const { data, loading, error } = useQuery(GET_PLAYERS_WITH_SKATING_EVALS, {
    variables: { rinknetIds, year },
    skip
  })

  const rinknetIdToHasSkatingEval = data?.getPlayersWithSkatingEvals?.reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})

  const { setShowModal, renderModal } = useModal({})

  const renderSkatingEvalModalForRinknetId = (rinknetId) => {
    return renderModal((
      <SkatingEval rinknetId={rinknetId} year={year} />
    ))
  }

  return {
    setShowModal,
    renderSkatingEvalModalForRinknetId,
    rinknetIdToHasSkatingEval,
    data,
    loading,
    error
  }
}