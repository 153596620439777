import React, { useState } from 'react';
import Toggle from './Toggle';

export default function useToggle({
  id,
  label,
  labelToggled,
  onToggled,
  initialToggled,
  disabled = false,
  tooltip
}) {
  const [toggled, setToggled] = useState(initialToggled || false);
  const handleToggled = (toggled) => {
    setToggled(toggled);
    onToggled && onToggled(toggled);
  };

  return {
    toggled,
    toggleComponent: (
      <Toggle
        key={id}
        disabled={disabled}
        id={id}
        label={label}
        labelToggled={labelToggled}
        toggled={toggled}
        setToggled={handleToggled}
        tooltip={tooltip}
      />
    ),
    forceSetToggle: (val) => setToggled(val)
  };
}
