import React from 'react';
import styled from 'styled-components';
import { itemTypes, theme } from '../../constants';
import { useDragLayer, useDrop } from 'react-dnd';

import Draggable from '../bdd/draggable';
import Droppable from '../bdd/droppable';

const Styles = styled.div({
  '.depth-table': {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
  },
  '.position-header': {
    ...theme.typography.body1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

/* 
    @prop depthChart = {
        leftwing: [zdeno-chara-77, ...]},
        center: {...} ...
    ]
    slugToPlayer links slugs to info
 */
export default function DraggableDepthChart({
  id,
  depthChart,
  depthLeague = 'NHL', // the "depthLeague" for this chart (NHL, AHL, Prospect)
  slugToPlayer,
  editMode,
  renderPlayer,
  onPlayerDrop,
  onRemovePlayer,
  onClickAddPlayer,
  spotWrappers = [],
  playerSize,
  onUpdate,
  refetchWrapperQueries
}) {
  if (!depthChart) depthChart = {};

  // const ppPos = ['pp1', 'pp2', 'pp3', 'pp4', 'pp5']
  const pToShort = {
    leftwing: 'LW',
    center: 'C',
    rightwing: 'RW',
    leftdefence: 'LD',
    rightdefence: 'RD',
    goalie: 'G',
    pp1: 'PP1',
    pp2: 'PP2',
    pp3: 'PP3',
    pp4: 'PP4',
    pp5: 'PP5',
  };

  const allPositions = fPos.concat(dgPos);
  allPositions.forEach((p) => {
    if (!(p in depthChart)) depthChart[p] = [];
  });

  const positionSets = [
    { pos: 'F', positions: fPos },
    { pos: 'D', positions: dgPos },
  ];

  const playerRows = {}; // { f: [[p1, p2, p3], [p4, p5...], d: ...}
  const posToLastVacant = {}; // the linenum of the first vacancy (where to put the "add players" button)
  positionSets.forEach((ps) => {
    const psWrappers = spotWrappers.filter(sw => sw.depthLeague === depthLeague && ps.positions.includes(sw.position));
    const depthLengths = ps.positions
      .map((pos) => depthChart[pos].length)
      .concat(psWrappers.map(sw => sw.lineNum)); // length of each depth set
    
    playerRows[ps.pos] = [];
    for (let i = 0; i < Math.max(...depthLengths); i++) {
      const newRow = [];
      ps.positions.forEach((pos) => newRow.push(depthChart[pos][i]));
      playerRows[ps.pos].push(newRow);
    }
    ps.positions.forEach((pos) => {
      // make a copy of "playerRows" but with "wrappers" into wrapper spots (cause we don't want to put "Add Players" on top of a wrapper)
      const wrappers = spotWrappers.filter(sw => sw.depthLeague === depthLeague && sw.position === pos);
      const occupiedSpots = depthChart[pos].map(slug => !!slug);
      wrappers.forEach(w => occupiedSpots[w.lineNum - 1] = true);
      posToLastVacant[pos] = occupiedSpots.length + 1;
    })
  });

  return (
    <Styles>
      {positionSets.map((ps) => (
        <table key={ps.pos} className="depth-table">
          <tbody>
            <tr>
              {ps.positions.map((pos) => (
                <th
                  key={pos}
                  className="position-header"
                  style={{
                    paddingTop: playerSize == 'sm' ? 0 : 12,
                    fontSize: playerSize == 'sm' ? '0.8em' : '1em',
                  }}
                >
                  {pToShort[pos]}
                </th>
              ))}
            </tr>
            {playerRows[ps.pos].map((pr, idx) => (
              <tr key={idx}>
                {pr.map((pslug, j) => {
                  const position = ps.positions[j];
                  const lineNum = idx + 1;
                  const lineupSpot = buildLineupSpot(depthLeague, position, lineNum);
                  const player = pslug in slugToPlayer ? slugToPlayer[pslug] : null;
                  const wrapper = spotWrappers.find(
                    (w) =>
                      w.lineNum == lineNum &&
                      w.position == position &&
                      w.depthLeague == depthLeague
                  );

                  return (
                    <DepthSpot
                      depthChartId={id}
                      key={lineupSpot}
                      lineupSpot={lineupSpot}
                      updateLineupSpot={onPlayerDrop}
                      showAddButton={lineNum == posToLastVacant[position]} // is this the first empty cell in col?
                      editMode={editMode}
                      onClickAddPlayer={onClickAddPlayer}
                      spotWrappers={spotWrappers}
                      wrapper={wrapper}
                      playerSize={playerSize}
                      onUpdate={onUpdate}
                      refetchWrapperQueries={refetchWrapperQueries}
                    >
                      {pslug ? (
                        <DraggableDCPlayer
                          playerSlug={pslug}
                          player={player}
                          renderPlayer={renderPlayer}
                          editMode={editMode}
                          lineupSpot={lineupSpot}
                          handleRemovePlayer={onRemovePlayer}
                        />
                      ) : null}
                    </DepthSpot>
                  );
                })}
              </tr>
            ))}
            <tr>
              {ps.positions.map((pos) => {
                if (playerRows[ps.pos].length+1 == posToLastVacant[pos]) {
                  return <DepthSpot
                    depthChartId={id}
                    key={pos}
                    lineupSpot={buildLineupSpot(
                      depthLeague,
                      pos,
                      depthChart[pos].length + 1
                    )}
                    showAddButton={true}
                    updateLineupSpot={onPlayerDrop}
                    editMode={editMode}
                    onClickAddPlayer={onClickAddPlayer}
                    onUpdate={onUpdate}
                  />
                }
                return <td key={pos}></td>
              })}
            </tr>
          </tbody>
        </table>
      ))}
    </Styles>
  );
}

const DragDCPlayerStyles = styled.div`
  .draggable-player {
    height: 100%;
    // border: 1px solid #777;
    // overflow: hidden;
  }
`;
const DraggableDCPlayer = ({
  playerSlug,
  player,
  renderPlayer,
  lineupSpot,
  editMode,
  handleRemovePlayer,
  customStyles, // callback that accepts isDragging
}) => {
  return (
    <DragDCPlayerStyles>
      <Draggable
        className="draggable-player"
        itemType={itemTypes.DEPTH_CHART_PLAYER}
        data={{ slug: playerSlug }}
        draggable={editMode}
        customStyles={customStyles}
        renderChildren={({ isDragging }) => {
          if (!!renderPlayer) {
            return renderPlayer({
              playerSlug,
              player,
              lineupSpot,
              editMode,
              handleRemovePlayer,
              isDragging,
            });
          } else {
            return <div>{playerSlug}</div>;
          }
        }}
      />
      {/* {!!renderPlayer ? 
            renderPlayer({ playerSlug, player, lineupSpot, editMode, handleRemovePlayer }) 
            : <div style={{background: 'white'}}>
                {playerSlug}
            </div>
        } */}
      {/* </Draggable> */}
    </DragDCPlayerStyles>
  );
};

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { adjustModes } from './bdddepthchartconstructor';
import { buildLineupSpot, dgPos, fPos, parseLineupSpot } from './helpers';
import { DepthChartSpotWrapper } from './DepthChartSpotWrapper';
import { Border, Container, Row } from 'components/bdd/Layout';
import { useRenderDepthChartSpotWrapperForm } from './DepthChartSpotWrapperForm';

const getStylesForLZ = (isOver, canDrop, hasChildren) => {
  const hoverColor = '#abfcab';
  const canDropColor = '#FDFFFD';
  const ret = {
    border: '1px solid transparent',
  };
  if (isOver) {
    ret.background = hoverColor;
  } else if (canDrop) {
    ret.background = canDropColor;
  }
  if (!hasChildren && canDrop) {
    ret.border = '1px solid #ddd';
  }
  return ret;
};

const DepthSpotTD = styled.td`
  vertical-align: top;
  min-height: 30px;
  padding: 0;
`;
const DepthSpotSwapLZStyles = styled.div`
  transition: all 0.2s;
  padding: 1px;

  .add-player-button {
    font-size: 0.7em;
    text-align: center;
    cursor: pointer;
    color: #aaa;
    :hover {
      color: #333;
    }
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
`;
const DepthSpot = ({
  depthChartId,
  lineupSpot,
  updateLineupSpot,
  children,
  showAddButton,
  editMode,
  onClickAddPlayer,
  spotWrappers,
  wrapper,
  playerSize,
  onUpdate,
  refetchWrapperQueries // queries to be refetched on changes to wrappers
}) => {
  // accept, onDrop, hoverColor, className
  const accept = itemTypes.DEPTH_CHART_PLAYER;
  const [{ isOver, canDrop, item }, drop] = useDrop({
    accept: accept,
    drop: () => {
      updateLineupSpot(lineupSpot, item.data.slug, adjustModes.SWAP);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
      item: monitor.getItem(),
    }),
  });

  const handleRenderModal = useRenderDepthChartSpotWrapperForm({
    existingWrappers: spotWrappers,
    wrapper,
    depthChartId,
    onUpdate,
    refetchQueries: refetchWrapperQueries,
  });

  // if there isn't a player in this spot, then the target is the TD (not a div within it)
  const isEmpty = !children;
  var style = getStylesForLZ(isOver, canDrop, !!children);

  // const tdStyle = !children && canDrop ? { border: '1px solid #ddd' } : {}
  if (!children) {
    style.height = '30px';
  }

  return (
    <DepthSpotTD style={isEmpty ? style : {}} ref={isEmpty ? drop : undefined}>
      {!isEmpty ? (
        <DepthSpotBumpLZ lineupSpot={lineupSpot} updateLineupSpot={updateLineupSpot} />
      ) : (
        <div style={{ height: 2 }} />
      )}
      <DepthSpotSwapLZStyles
        style={isEmpty ? {} : style}
        ref={isEmpty ? undefined : drop}
      >
        <DepthChartSpotWrapper
          depthChartId={depthChartId}
          wrappers={spotWrappers}
          wrapper={wrapper}
          playerSize={playerSize}
          onUpdate={onUpdate}
          refetchQueries={refetchWrapperQueries}
        >
          {children}
        </DepthChartSpotWrapper>
        {editMode && isEmpty && !wrapper && showAddButton && (
          <Border>
            <Row>
              <div
                className="add-player-button"
                onClick={() => onClickAddPlayer(lineupSpot)}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Player
              </div>
              <div
                className="add-player-button"
                onClick={() => {
                  handleRenderModal(parseLineupSpot(lineupSpot));
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Wrapper
              </div>
            </Row>
          </Border>
        )}
      </DepthSpotSwapLZStyles>
    </DepthSpotTD>
  );
};

const DepthSpotBumpStyles = styled.div`
  transition: all 0.2s ease-in-out;
`;
const DepthSpotBumpLZ = ({ lineupSpot, updateLineupSpot }) => {
  const accept = itemTypes.DEPTH_CHART_PLAYER;
  const [{ isOver, canDrop, item }, drop] = useDrop({
    accept: accept,
    // canDrop: () => isDroppable,
    drop: () => {
      updateLineupSpot(lineupSpot, item.data.slug, adjustModes.BUMP);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
      item: monitor.getItem(),
    }),
  });

  var style = getStylesForLZ(isOver, canDrop);
  if (isOver) {
    style.height = '20px';
  } else if (canDrop) {
    style.height = '7px';
  } else {
    style.height = '0px';
  }
  style.border = '1px solid transparent';
  return <DepthSpotBumpStyles ref={drop} style={style}></DepthSpotBumpStyles>;
};
