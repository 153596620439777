import { Container } from "components/bdd/Layout";
import { useCreateFeedback } from "../hooks/useFeedbackMutations"
import { FeedbackForm } from "./FeedbackForm";
import { Typography } from "components/bdd/Typography";


export const CreateFeedbackForm = ({ defaultValues, onCompleted }) => {
  const { createFeedback, loading } = useCreateFeedback({ onCompleted });

  const handleSubmit = (values) => {
    const input = {};
    Object.keys(values).filter(k => k !== 'files').forEach(k => input[k] = values[k]);
    const files = values.files.map(f => ({ name: f.name, s3Key: f.s3Key, url: f.s3url }));
    createFeedback({
      variables: {
        input,
        files
      }
    })
  }

  return <Container>
    <FeedbackForm
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      submitting={loading}
    />
  </Container>
}