// Red green color scale
export function getRedGreenColor(
  value,
  min,
  max,
  negative = false,
  alphaMult = 1.0,
  hues = [120, 0]
) {
  /*
        negative: is the stat negative
        alphaMult: multiplier to apply to alpha value.  Higher number == more transparent
    */
  if (isNaN(value)) {
    return '#FFFFFFFF';
  }
  var scaled = (value - min) / (max - min);
  if (negative) {
    scaled = 1 - scaled;
  }
  scaled = Math.min(scaled, 1);
  scaled = Math.max(scaled, 0);
  // scaled is a value between 0 and 1
  const distFromMiddle = Math.abs(0.5 - scaled);
  // distFromMiddle is 0.5 at the extremes, 0 in the middle
  // var hue = (scaled*120).toString(10)
  if (scaled > 0.5) {
    var hue = hues[0];
  } else {
    var hue = hues[1];
  }
  // luminosity is highest in the middle
  var l = 100 - Math.abs(scaled - 0.5) * 90;
  // var l = 50
  var sat = 60;
  if (alphaMult === 0) var alpha = 100;
  else var alpha = (100 * 2 * distFromMiddle) / alphaMult; // max (100) at 0, 1.  0 at 0.5
  var ret = ['hsl(', hue, ',', sat, '%,', l, '%,', alpha, '%)'].join('');
  return ret;
}

export function getQuantileBounds(value, quantiles, isNegative) {
  // given value, list of quantiles and isNegative bool
  // returns number 0->1, 1 being best
  // This is the center of the quantile range this value falls in
  const numQuantiles = quantiles.length + 1;
  var lowerQ = 0;
  var upperQ = numQuantiles;

  for (let i = 0; i < numQuantiles - 1; i++) {
    if (quantiles[i] < value) lowerQ = i + 1;
    if (quantiles[i] > value) {
      upperQ = i + 1;
      break;
    }
  }
  var centerQ = (lowerQ + upperQ) / 2;
  var normCenterQ = (100 * centerQ) / numQuantiles;
  lowerQ = (100 * lowerQ) / numQuantiles;
  upperQ = (100 * upperQ) / numQuantiles;
  if (isNegative) normCenterQ = 100 - normCenterQ;
  return { center: normCenterQ, low: lowerQ, high: upperQ };
}

export function quantileToColor(value, quantiles, isNegative, alphaMult = 2) {
  const { center } = getQuantileBounds(value, quantiles, isNegative);
  const color = getRedGreenColor(center, 0, 100, false, alphaMult);
  return color;
}

export function stddevColor(
  value,
  statMetastats,
  isNegative = false,
  stdwidth = 2,
  alphaMult = 2
) {
  const { mean, stddev } = statMetastats;
  const min = mean - stdwidth * stddev;
  const max = mean + stdwidth * stddev;

  const color = getRedGreenColor(value, min, max, isNegative, alphaMult);
  return color;
}

export function steColor(
  value,
  metastats,
  numSamples,
  size,
  isNegative = false,
  stdwidth = 1,
  alphaMult = 2
) {
  const ste = metastats.stddev * (Math.sqrt(numSamples) / Math.sqrt(size));
  return getRedGreenColor(
    value,
    metastats.mean - ste * 2,
    metastats.mean + ste * 2,
    isNegative,
    alphaMult
  );
}

export const rankToColor = (rank, numTeams = 31) => {
  return getRedGreenColor(rank, numTeams, 0);
};

export function sortNumericAccessor(a, b, accessor) {
  const aVal = parseFloat(accessor(a));
  const bVal = parseFloat(accessor(b));

  return sortWithNaN(aVal, bVal);
}

export const getSortNumeric = (accessor) => {
  // function that returns sorting function.  Accessor called with the table row object, so likely needs to start with d => d.original
  return (a, b) => sortNumericAccessor(a, b, accessor);
};

export function sortNumeric(a, b, colId) {
  if (!(colId in a.original))
    console.warn(
      `sortNumeric encountered unknown colId "${colId}" while attempting sort`
    );
  const aVal = parseFloat(a.original[colId]);
  const bVal = parseFloat(b.original[colId]);

  return sortWithNaN(aVal, bVal);
}

export function sortNumericWithFormat(a, b, colId, formatStat) {
  if (!(colId in a.original))
    console.warn(
      `sortNumeric encountered unknown colId "${colId}" while attempting sort`
    );

  const formattedA = parseFloat(formatStat(a.original[colId], colId, a.original));
  const formattedB = parseFloat(formatStat(b.original[colId], colId, b.original));

  return sortWithNaN(formattedA, formattedB);
}

export function sortWithNaN(aVal, bVal) {
  if (isNaN(aVal) && isNaN(bVal)) return -1; // a and b both NaN
  else if (isNaN(aVal)) return -1; // a is NaN so treat a as smaller
  else if (isNaN(bVal)) return 1; // b is NaN so treat a as larger

  return aVal - bVal;
}

export function sortDate(a, b, colId, dateCol = 'game_date', nullslast = true) {
  const aVal = a.original[dateCol];
  const bVal = b.original[dateCol];
  if (!aVal && !nullslast) return 1;
  if (!bVal && !nullslast) return -1;
  return new Date(aVal) > new Date(bVal) ? 1 : -1;
}
