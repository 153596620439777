import { useMutation } from '@apollo/client';
import {
  CREATE_DEPTH_CHART_SPOT_WRAPPER,
  UPDATE_DEPTH_CHART_SPOT_WRAPPER,
  buildBDDDepthChartQuery,
} from 'apollo/queries/depthchart.queries';
import { pToShort } from 'components/TeamsV2/Lineups';
import { DividerWithText } from 'components/bdd/Divider/Divider';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { NumberInput } from 'components/bdd/NumberInput/NumberInput';
import { EMPTY_TEXT, TextEditorField } from 'components/bdd/TextEditor';
import { Typography } from 'components/bdd/Typography';
import { toastBddApiError } from 'components/bdd/bddtoasts';
import BDDSelectField from 'components/form/bddselectfield';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';

const depthLeagueOptions = ['NHL', 'AHL', 'Prospect'].map((l) => ({
  value: l,
  label: l,
}));

export const getQueryToRefetch = (depthChartId) => ({
  query: buildBDDDepthChartQuery({
    includeRinknetPlayer: true,
    includeContracts: true,
  }),
  variables: {
    id: depthChartId,
  },
});

export const DepthChartSpotWrapperForm = ({
  depthChartId,
  existingWrappers,
  requireEmptySpot, // if true, will check existing players to make sure wrapper goes into empty spot
  existingPlayers,
  requireCapHit,
  wrapper,
  defaultValues = {},
  onSubmit,
  onCancel,
  refetchQueries=[],
  hideDepthFields=false, // if true, hide depthLeague, position, etc...
}) => {
  const schema = Yup.object({
    lineNum: Yup.number().required(),
    depthLeague: Yup.string().required(),
    position: Yup.string().required(),
    capHit: Yup.number().nullable(),
    term: Yup.number(),
    notes: Yup.object().nullable(),
  });

  const initialValues = wrapper
    ? {
        lineNum: wrapper.lineNum,
        depthLeague: wrapper.depthLeague,
        position: wrapper.position,
        term: wrapper.term,
        capHit: wrapper.capHit,
        notes: !!wrapper.notes ? JSON.parse(wrapper.notes) : EMPTY_TEXT,
      }
    : {
        lineNum: 1,
        depthLeague: 'NHL',
        position: 'leftwing',
        term: 1,
        capHit: 0,
        notes: EMPTY_TEXT,
        ...defaultValues,
      };

  const [createWrapper] = useMutation(CREATE_DEPTH_CHART_SPOT_WRAPPER, {
    refetchQueries: [getQueryToRefetch(depthChartId), ...refetchQueries],
  });

  const [updateWrapper] = useMutation(UPDATE_DEPTH_CHART_SPOT_WRAPPER, {
    refetchQueries: [getQueryToRefetch(depthChartId), ...refetchQueries],
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!wrapper) {
      createWrapper({
        variables: {
          id: depthChartId,
          input: {
            ...values,
            notes: values.notes == EMPTY_TEXT ? null : values.notes,
          },
        },

        onCompleted: (data) => {
          setSubmitting(false);
          onSubmit && onSubmit();
        },
        onError: toastBddApiError
      });
    } else {
      updateWrapper({
        variables: {
          id: wrapper.id,
          input: {
            ...values,
            notes: values.notes == EMPTY_TEXT ? null : values.notes,
          },
        },
        onCompleted: (data) => {
          setSubmitting(false);
        },
        update(
          cache,
          {
            data: {
              updateDepthChartSpotWrapper: { depthChartSpotWrapper },
            },
          }
        ) {
          onSubmit && onSubmit(cache);

          cache.modify({
            fields: {
              bddDepthChart(existing, { readField }) {
                if (readField('id', existing) == depthChartSpotWrapper.bddDepthChartId) {
                  return existing?.depthChartWrappers?.concat(depthChartSpotWrapper);
                }

                return existing;
              },
            },
          });
        },
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ isSubmitting, values, errors, setFieldValue }) => {
        const wrapperExists = !!existingWrappers?.find(
          (w) =>
            w.id != wrapper?.id &&
            w.lineNum == values.lineNum &&
            w.depthLeague == values.depthLeague &&
            w.position == values.position
        );
        const spotOccupied = !!existingPlayers?.find(
          (p) =>
            p.lineNum == values.lineNum &&
            p.depthLeague == values.depthLeague &&
            p.position == values.position
        );

        return (
          <Form>
            <Container padding={3}>
              <Column gap={1}>
                {!hideDepthFields && <Row columnGap={2} alignItems="end">
                  <Container>
                    <Typography variant="label">Line #</Typography>
                    <LineNumberInput
                      values={values}
                      setFieldValue={setFieldValue}
                      requireEmptySpot={requireEmptySpot}
                      existingPlayers={existingPlayers}
                      existingWrappers={existingWrappers}
                    />
                  </Container>
                  <Container width={100}>
                    <Typography variant="label">Position</Typography>
                    <Field
                      name="position"
                      size="sm"
                      placeholder="Position"
                      options={Object.keys(pToShort).map((key) => ({
                        value: key,
                        label: pToShort[key],
                      }))}
                      isSearchable={true}
                      isClearable={false}
                      component={BDDSelectField}
                    />
                  </Container>
                  <Container width={100}>
                    <Typography variant="label">Depth League</Typography>
                    <Field
                      name="depthLeague"
                      size="sm"
                      placeholder="Depth League"
                      options={depthLeagueOptions}
                      isSearchable={true}
                      isClearable={false}
                      component={BDDSelectField}
                    />
                  </Container>
                  {(!wrapperExists && spotOccupied && requireEmptySpot) && (
                    <Typography state="danger" variant="stat">
                      <b>A player is in this spot, please pick an empty spot</b>
                    </Typography>
                  )}
                  {wrapperExists && (
                    <Typography state="danger" variant="stat">
                      <b>A wrapper already exists for this spot</b>
                    </Typography>
                  )}
                </Row>}
                <DividerWithText
                  content={<Typography variant="body2">Contract Details</Typography>}
                />
                <Row columnGap={2}>
                  <Container>
                    <Typography variant="label">Term</Typography>
                    <NumberInput
                      id="term"
                      type="number"
                      min={1}
                      max={8}
                      value={values.term}
                      onChange={(ev) => setFieldValue('term', parseInt(ev.target.value))}
                    />
                  </Container>
                  <Container>
                    <Typography variant="label">NHL cap hit (Millions)</Typography>
                    <NumberInput
                      id="capHit"
                      type="number"
                      min={0}
                      step={0.1}
                      value={values.capHit}
                      placeholder="e.g. 1.5, 0.875, 8.66..."
                      onChange={(ev) =>
                        setFieldValue('capHit', parseFloat(ev.target.value))
                      }
                    />
                  </Container>
                  {(!values.capHit && requireCapHit) && (
                    <Typography state="danger" variant="stat">
                      <b>Please enter a cap hit</b>
                    </Typography>
                  )}
                </Row>
                <DividerWithText
                  content={<Typography variant="body2">Notes</Typography>}
                />
                <Container>
                  <Border>
                    <TextEditorField name="notes" />
                  </Border>
                </Container>
                <Container paddingTop={2}>
                  <Row justifyContent="end" columnGap={1}>
                    <Button variant="danger" size="sm" onClick={onCancel}>
                      <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      size="sm"
                      disabled={wrapperExists || (requireCapHit && !values.capHit) || (requireEmptySpot && spotOccupied) || isSubmitting}
                    >
                      <Typography variant="body1">
                        {!isSubmitting ? 'Submit' : 'Submitting...'}
                      </Typography>
                    </Button>
                  </Row>
                </Container>
              </Column>
            </Container>
            {/* <Divider />
            <Container padding={3}>
              <Row justifyContent="space-between">
                <Container>{children}</Container>
                <Button type="submit" variant="outline-dark" disabled={disabled}>
                  <Typography variant="body1">
                    {submitting ? 'Submitting...' : 'Submit'}
                  </Typography>
                </Button>
              </Row>
            </Container> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export const useRenderDepthChartSpotWrapperForm = ({
  depthChartId,
  existingWrappers,
  wrapper,
  onUpdate,
  refetchQueries
}) => {
  const { renderModal, hideModal } = useContext(ModalContext);
  const handleUpdate = (cache) => {
    hideModal();
    onUpdate && onUpdate(cache);
  };

  return (values) => {
    renderModal({
      title: (
        <Typography variant="h6">
          {!!wrapper ? 'Edit' : 'Add'} Depth Chart Spot Wrapper
        </Typography>
      ),
      body: (
        <DepthChartSpotWrapperForm
          depthChartId={depthChartId}
          wrapper={wrapper}
          defaultValues={values}
          existingWrappers={existingWrappers}
          onSubmit={handleUpdate}
          onCancel={handleUpdate}
          refetchQueries={refetchQueries}
        />
      ),
    });
  };
};


const LineNumberInput = ({
  values,
  setFieldValue,
  requireEmptySpot,
  existingWrappers,
  existingPlayers
}) => {

  useEffect(() => {
    if (requireEmptySpot) {
      for (let i = 1; i <= 12; i++) {
        if (
          !existingWrappers.find((w) => w.depthLeague === values.depthLeague && w.position === values.position &&  w.lineNum == i) && 
          !existingPlayers.find((p) => p.depthLeague === values.depthLeague && p.position === values.position && p.lineNum == i)
        ) {
          setFieldValue('lineNum', i)
          return;
        }
      }
    }
  }, [values.position, values.depthLeague])

  return <NumberInput
    id="lineNum"
    type="number"
    min={1}
    max={12}
    value={values.lineNum}
    onChange={(ev) =>
      setFieldValue('lineNum', parseInt(ev.target.value))
    }
  />
}