import { useQuery } from "@apollo/client";
import BDDDepthChart, { BDDDepthChartSmall } from "../bdddepthchart"
import { buildBDDDepthChartQuery, GET_TEAM_ACTIVE_DEPTH_CHART } from "apollo/queries/depthchart.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { BDDLoader } from "components/bdd/bddloader";


export const useBDDDepthChart= ({
  depthChartId: depthChartIdArg,
  activeTeamSlug,
  uniqueKey,
  size='sm',
  depthChartProps={}
}) => {
  // If you want a depth chart where you can select players and then perform actions
  // use InteractiveDC instead
  if ((!!depthChartIdArg && !!activeTeamSlug) || (!depthChartIdArg && !activeTeamSlug)) {
    console.error('One of "depthChartId" OR "activeTeamSlug" required (not both)');
  }
  var { data: activeData, loading: activeLoading } = useQuery(GET_TEAM_ACTIVE_DEPTH_CHART, {
    variables: { teamSlug: activeTeamSlug, uniqueKey: uniqueKey },
    skip: !!depthChartIdArg
  });

  const depthChartId = !!depthChartIdArg ? depthChartIdArg : activeData?.activeDepthChartForTeam.id;

  const { data, loading, error, placeholder } = usePlaceholder(useQuery(
    buildBDDDepthChartQuery({
      includeRinknetPlayer: true,
      includeContracts: true,
    }),
    { 
      variables: { id: depthChartId },
      skip: !depthChartId
    }
  ));

  const depthChartComponent = !depthChartId 
    ? null
    : size === 'sm' 
      ? <BDDDepthChartSmall
        depthChartId={depthChartId}
        {...depthChartProps}
      /> : 
      <BDDDepthChart
        depthChartId={depthChartId}
        {...depthChartProps}
      />

  return {
    depthChartComponent,
    data,
    depthChart: data?.bddDepthChart,

    loading,
    error,
    placeholder: activeLoading ? <BDDLoader/> : placeholder
  }
}