import { Button } from "react-bootstrap"
import { Row } from "../Layout"
import Icon from "../Icon"
import { PlusCircle } from "react-bootstrap-icons"
import { Typography } from "../Typography"


export const CreateButton = ({ 
  children, 
  icon=<PlusCircle/>,
  onClick,
  variant='outline-dark',
  size='md'
}) => {
  return <Button variant={variant} size={size} onClick={onClick}>
    <Row gap={4}>
      <Icon icon={icon}/>
      <Typography variant='body2'>
        {children}
      </Typography> 
    </Row>
  </Button>
}