import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';

export const BFGroupSixTag = ({ bfPlayer, contract, ...tagProps }) => {
  const isGroupSix = !bfPlayer
    ? contract?.expiryStatusDetails == 'GroupSix'
    : bfPlayer.expiryStatusDetails == 'GroupSix';

  if (!isGroupSix) return null;

  return (
    <BDDTag
      {...tagProps}
      tag={{
        color: theme.colors.states.link,
        textColor: theme.colors.dark.text.primary,
        tag: (
          <Typography variant="caption" style={{ lineHeight: '14px' }}>
            VI
          </Typography>
        ),
        description: 'Group 6',
      }}
    />
  );
};
