import { Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import useLSGTOptions from 'components/PlayersV2/hooks/useLSGTOptions';
import { useContext } from 'react';
import { useEffect } from 'react';
import { VideoContext } from '../..';

export const PlayerLSGFilter = ({ slug, leagueSlug: leagueSlugArg }) => {
  const { sourceFilters, setSourceFilters } = useContext(VideoContext);
  const initialValue = sourceFilters
    ? `${sourceFilters['league_slug']?.val},${sourceFilters['season']?.val},${sourceFilters['game_type']?.val}`
    : null;

  const { selectLSGT, season, leagueSlug, gameType } = useLSGTOptions({
    slug,
    leagueSlug: leagueSlugArg,
    combineTeamSlugs: true,
    initialValue,
  });

  useEffect(() => {
    if (!leagueSlug) return;

    setSourceFilters({
      league_slug: {
        col: 'league_slug',
        val: leagueSlug,
      },
      season: {
        col: 'season',
        val: season,
      },
      game_type: {
        col: 'game_type',
        val: gameType,
      },
    });
  }, [leagueSlug, season, gameType]);

  return (
    <Container minWidth={150}>
      <Typography variant="body1">League/Season</Typography>
      {selectLSGT}
    </Container>
  );
};
