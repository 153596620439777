import { gql, useQuery } from '@apollo/client';
import { useBddStatQueryV2 } from 'apollo/queries';


const GET_PLAYER_SL_GAMES = gql`query GetPlayerSlGames($slug: ID!) {
  bddPlayer(slug: $slug) {
    slug
    slGamesByLeague {
      stats
      leaguesWithGamesPlayed
    }
  }
}`
export const usePlayerSLGames = ({ slug }) => {
  const { data, loading } = useQuery(GET_PLAYER_SL_GAMES, { variables: { slug }})
  const stats = data?.bddPlayer?.slGamesByLeague?.stats
  const leaguesWithGamesPlayed = data?.bddPlayer?.slGamesByLeague?.leaguesWithGamesPlayed
  return { stats, leaguesWithGamesPlayed, loading }
};

