import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';

export const SLShiftFragment = gql`
  fragment slShiftFragment on SLShifts {
    id
    shiftNumber
    playerId
    gameUid
    period
    manpowerSituationIn
    formattedTimeIn
    gameTimeIn
    gameTimeOut
    duration
    isFaceoff
    zoneIn
    team {
      shorthand
    }
    oppTeam {
      shorthand
    }
    slGame {
      gameDate
    }
    player {
      firstname
      lastname
    }
  }`

export const GET_SL_SHIFTS = gql`
  query GetShifts(
    $limit: Int!
    $offset: Int
    $sort: [BDDSortV2]
    $filters: GenericScalar
  ) {
    getSlShifts(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        id
        gameUid
        period
        manpowerSituationIn
        formattedTimeIn
        gameTimeIn
        gameTimeOut
        duration
        isFaceoff
        zoneIn
        team {
          shorthand
        }
        oppTeam {
          shorthand
        }
        slGame {
          gameDate
        }
        player {
          firstname
          lastname
        }
      }
    }
  }
  ${PageInfoFragment}
`;
