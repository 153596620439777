import { Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { useSearchParamsStore } from 'components/bdd/hooks';
import { useContext, useEffect } from 'react';
import BFSigningBreakdown from '../Signings/BFSigningBreakdown';
import { Typography } from 'components/bdd/Typography';
import { Button } from 'react-bootstrap';

export default function useBFSigningModal({ useSearchParams = false }) {
  const store = useSearchParamsStore();

  const { renderModal, hideModal } = useContext(ModalContext);

  const handleRenderModal = ({ contractId, bfContract }) => {

    if (useSearchParams) {
      store.setValue('signing', !!contractId ? contractId : bfContract.id);
    }

    renderModal({
      size: 'xl',
      // title: <Typography variant="h5">{bfContract?.signingDate}</Typography>,
      body: (
        <Container
          paddingTop={4}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={4}
        >
          {/* <CFSigningBreakdown contractId={store.getValue('signing')} /> */}
          <BFSigningBreakdown bfContract={bfContract} />

        </Container>
      ),
      footer: (
        <Container>
          <Row justifyContent="end">
            <Button variant="outline-dark" onClick={hideModal}>
              <Typography variant="body1">Close</Typography>
            </Button>
          </Row>
        </Container>
      ),
    });
  };

  useEffect(() => {
    if (!!useSearchParams) {
      if (store.paramExists('signing')) {
        handleRenderModal({ contractId: store.getValue('signing') });
      }
    }
  }, []);

  return {
    hideModal,
    handleRenderModal,
  };
}
