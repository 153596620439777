import { theme } from 'constants';
import styled from 'styled-components';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';

export const Divider = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  width: '100%',
  overflow: 'auto',
});

export const DividerWithText = ({ content }) => (
  <Row gap={2}>
    <Container flexGrow={1}>
      <Divider/>
    </Container>
    <Typography variant='h6'>
      {content}
    </Typography>
    <Container flexGrow={1}>
      <Divider/>
    </Container>
  </Row>
)

export const VerticalDivider = styled.div(({ height }) => ({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.right,
  height: height || '100%',
  overflow: 'auto',
}));
