import { SEARCH } from 'apollo/queries/search.queries';
import useBDDSearchSelect from 'components/Search/useBDDSearchSelect';

export default function useBDDSearch({ onSelect, ...props }) {
  const { searchComponent, selected, selectedOption } = useBDDSearchSelect({
    query: SEARCH,
    resultToOptions: (data) =>
      data?.search?.map((s) => {
        if (s.result.__typename === 'BDDPlayerSlug') {
          return {
            label: `${s.result.firstname} ${s.result.lastname}`,
            value: s.result.slug,
            teamid: s.result.rinknetPlayer?.currentteamnhlid,
            subLabel: s.result.rinknetPlayer?.currentteam,
            type: 'player',
          };
        } else if (s.result.__typename === 'SLTeam') {
          return {
            label: s.result.longname,
            value: s.result.slug,
            teamid: s.result.nhlid,
            type: 'team',
          };
        } else if (s.result.__typename === 'Users') {
          return {
            label: s.result.name,
            subLabel: 'BDD user',
            value: s.result.id,
            type: 'user',
          };
        } else if (s.result.__typename === 'UserGroups') {
          return {
            label: s.result.groupName,
            subLabel: `BDD group ${s.result.description && `: ${s.result.description}`}`,
            value: s.result.slug,
            type: 'userGroup',
          };
        }
      }),
    placeholder: 'Search BDD',
    isClearable: true,
    onSelect,
    ...props,
  });

  return {
    searchComponent,
    selected,
    selectedOption,
  };
}
