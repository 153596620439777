import { Button } from "react-bootstrap";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import Icon from "../Icon";
import { Row } from "../Layout";


export default function LeftRightArrowButtons ({
  buttonProps={},
  leftOnClick,
  rightOnClick,
  disabled=false,
  leftDisabled=false,
  rightDisabled=false
}) {

  return <Row columnGap={1}>
    <Button
      variant="outline-dark"
      size="sm"
      disabled={disabled || leftDisabled}
      {...buttonProps}
      onClick={leftOnClick}
    >
      <Icon icon={<ArrowLeft />} />
    </Button>
    <Button
      variant="outline-dark"
      size="sm"
      disabled={disabled || rightDisabled}
      {...buttonProps}
      onClick={rightOnClick}
    >
      <Icon icon={<ArrowRight />} />
    </Button>
  </Row>
}