import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, Collapse, Modal, Row } from "react-bootstrap"
import styled from "styled-components"
import { roundToX } from "../../helpers/data"

import { getMax, getMin } from "../../helpers/math"
import { bruinsGold } from "../../helpers/plotting"
import { heightToNum } from "../../helpers/hockeyutils"
import BDDRangeSlider from "../bdd/bddrangeslider"
import BDDSelect from "../bdd/bddselect"

const INDEX_COL_TO_FILTER_TYPE = {
    Player: null,
    age: 'RangeFilter',
    position: 'MultiSelect',
    height: 'RangeFilter',
    weight: 'RangeFilter',
    AAV: 'RangeFilter',
    'FA Year': 'MultiSelect',
}
const DEFAULT_FILTER_TYPE = 'MultiSelect'

const NumberInput = styled.input`
    border-radius: 5px; 
    border: 1px solid #DDD;
    width: 100%;
`

const FilterContainer = styled.div`
    border-radius: 2px;
    border-bottom: 1px solid #aaa;
    padding: 5px;

    ${props => props.isactive ? `background: linear-gradient(#FFFFFFFF, ${bruinsGold+'77'});` : null}

    .filter-header {
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }
    .filter-body {
        margin-top: 15px;
    }
`
const ColumnFilter = ({col, filterComponent, isactive}) => {
    const [open, setOpen] = useState(isactive)
    return <FilterContainer isactive={isactive}>
        <div className="filter-header" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}/>
            {' '}
            {col}
        </div>
        <Collapse in={open}>
            <div className='filter-body'>
                {filterComponent}
            </div>
        </Collapse>
    </FilterContainer>
}

const RangeFilter = ({realMin, realMax, min, max, setValues}) => {
    const [internalVals, setInternalVals] = useState({min: min, max: max})
    const step = (realMax - realMin) / 100.0
    
    useEffect(() => {
        if (min !== internalVals.min || max !== internalVals.max) {
            setInternalVals({min: min, max: max})
        }
    }, [min, max])

    return <>
    <div>
        <BDDRangeSlider
            color="#777"
            minValue={realMin}
            maxValue={realMax}
            step={step}
            value={{min: internalVals.min||realMin, max: internalVals.max||realMax}}
            handleChange={v => setInternalVals(v)}
            handleChangeComplete={v => {
                if (v.min - step <= realMin) v.min = realMin
                if (v.max + step >= realMax) v.max = realMax
                if (v.min <= realMin && v.max >= realMax) {
                    var active = false
                } else {
                    var active = true
                }
                v.min = roundToX(v.min, 2)
                v.max = roundToX(v.max, 2)
                setInternalVals(v)
                setValues({...v, active: active})
            }}
        />
    </div>
    <Row>
        <Col md={5}>
            <NumberInput
                placeholder="min"
                type="number"
                value={min || ''}
                onChange={ev => {
                    const v = parseFloat(ev.target.value)
                    var active = v <= realMin && max >= realMax ? false : true
                    setInternalVals({...internalVals, min: v})
                    setValues({min: v, active: active})
                }}
            /> 
        </Col>
        <Col md={2} style={{textAlign: 'center'}}>
            to
        </Col>
        <Col md={5}>
            <NumberInput
                placeholder="max"
                type="number"
                value={max === realMax ? '' : max || ''}
                onChange={ev => {
                    const v = parseFloat(ev.target.value)
                    var active = v <= realMin && max >= realMax ? false : true
                    setInternalVals({...internalVals, max: v})
                    setValues({max: v, active: active})
                }}
            />
        </Col>
    </Row>
    </>
}

const MultiSelectFilter = ({values, options, handleChange}) => {
    return <>
        <BDDSelect
            isMulti
            value={values}
            options={options}
            onChange={handleChange}
        />
    </>
}


export default function StatTableFiltersModal(props) {
    const { show, handleClose, index, statLabels, tableFilters, setTableFilters, data } = props
    const defaultFilters = React.useMemo(() => {
        const ret = {}
        index.forEach(i => {
            if (i in INDEX_COL_TO_FILTER_TYPE) var filterType = INDEX_COL_TO_FILTER_TYPE[i]
            else var filterType = DEFAULT_FILTER_TYPE
            if (!!filterType) {
                if (filterType === 'MultiSelect') {
                    ret[i] = { type: 'MultiSelect', values: [], active: false }
                } else if (filterType === 'RangeFilter') {
                    ret[i] = { type: 'RangeFilter', min: null, max: null, active: false }
                }    
            }
        })
        statLabels.forEach(s => ret[s] = { type: 'RangeFilter', min: null, max: null, active: false })
        return ret
    }, [])
    useEffect(() => {
        setTableFilters(defaultFilters)
    }, [])

    // export const heightToNum = h => {
    //     const [ft, inches] = h.split("'")
    //     if (inches) return parseFloat(ft) + parseFloat(inches)/12
    //     return parseFloat(ft)
    // }
    
    const renderFilter = (type, col) => {
        if (type === 'MultiSelect') {
            var options = Array.from(new Set(data.map(d => d[col]))
                ).map(v => ({label: `${v}`, value: v})).sort((a, b) => {
                    if (a.label < b.label) return -1
                    if (a.label > b.label) return 1
                    return 0
                })
            var component = <MultiSelectFilter 
                values={tableFilters[col].values}    
                options={options}
                handleChange={(n,v) => setFilter(col, {values: v, active: v.length > 0})}
            />
        } else if (type === 'RangeFilter') {
            const realMin = getMin(data.map(d => col === 'height' ? heightToNum(d[col]) : parseFloat(d[col])))
            // const realMax = getMax(data.map(d => parseFloat(d[col])))
            const realMax = getMax(data.map(d => col === 'height' ? heightToNum(d[col]) : parseFloat(d[col])))

            const min = tableFilters[col].min
            const max = tableFilters[col].max
            var component = <RangeFilter 
                realMin={realMin}
                realMax={realMax}
                min={tableFilters[col].min}
                max={tableFilters[col].max}
                active={tableFilters[col].active}
                setValues={vs => setFilter(col, vs)}
            />
        }
        return component
    }

    const setFilter = (n, newVals) => {
        setTableFilters({...tableFilters, [n]: {...tableFilters[n], ...newVals}})
    }
    if (!tableFilters) return "Loading..."
    return <Modal
        show={show}
        onHide={() => handleClose()}
    >
        <Modal.Body>
            <div style={{textAlign: 'center'}}>
                <b>Filter Data</b>
                <div style={{float: "right"}}>
                    <Button variant="outline-danger" size="sm" onClick={() => setTableFilters(defaultFilters)}>
                        Reset Filters
                    </Button>
                </div>
            </div>
            <hr></hr>
            {index.concat(statLabels).filter(c => c in tableFilters).map(c => {
                const component = renderFilter(tableFilters[c].type, c)
                return <ColumnFilter 
                    key={c}
                    col={c}
                    isactive={tableFilters[c].active}
                    filterComponent={component}
                />
            })}
        </Modal.Body>
    </Modal>
}