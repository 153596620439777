import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { getGameTypeChoices } from 'helpers/filters';
import { useContext } from 'react';
import { SLStatContext } from '..';

export const GameTypeFilter = ({ selectProps }) => {
  const { setFilters, filters } = useContext(SLStatContext);
  const options = filters.league ? getGameTypeChoices(filters.league) : [];

  const select = (
    <StyledBDDSelect
      size="sm"
      options={options}
      selectedValue={filters['game_type']}
      onChange={(n, v) => {
        setFilters({ game_type: v });
      }}
      selectProps={{ isMulti: false, ...selectProps }}
    />
  );

  return (
    <Container minWidth={150}>
      <Column>
        <Typography variant="label">Game Type</Typography>
        {select}
      </Column>
    </Container>
  );
};
