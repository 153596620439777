import { useQuery } from '@apollo/client';
import { useBddStatQueryV2 } from 'apollo/queries';
import { GET_BDD_TEAMS } from 'apollo/queries/teams.queries';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { STANDINGS_ROUTE } from 'constants';
import { TEAM_ROUTE } from 'constants';
import { theme } from 'constants/theme';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NHL_SATURATE_LOGOS = [14];
export const BDD_LEAGUES = ['nhl', 'ahl', 'ohl', 'whl', 'qmjhl', 'ushl', 'ncaa'];

const StyledLink = styled(Link)({
  cursor: 'pointer',
  width: '100%',
  '&: hover': {
    textDecoration: 'none',
  },
});

const SidebarContainer = styled(Container)(({ visible }) => ({
  position: 'absolute',
  opacity: visible ? 1 : 0,
  width: '100%',
  left: visible ? 0 : '-100px',
  transition: 'all 0.3s ease-in',
  transform: visible ? 'translateX(0)' : 'translateX(-100px)',
}));

const LinkContainer = styled(Container)(({}) => ({
  '&: hover': {
    backgroundColor: theme.colors.dark.highlight,
  },
}));

const NhlLogo = styled.img(({ dark }) => ({
  filter: dark ? 'grayscale(1) brightness(9.5)' : 'unset',
}));

export const LeagueSidebar = ({ forceClose }) => {
  const { select, selectedValue: selectedLeague } = useSelect({
    initialSelectedValue: 'nhl',
    variant: 'outlined',
    options: BDD_LEAGUES.map((l) => ({ value: l, label: l.toUpperCase() })),
    color: 'dark',
  });

  const { data, loading } = useQuery(GET_BDD_TEAMS, {
    variables: {
      leagueSlugs: BDD_LEAGUES,
      activeOnly: true
    },
  });

  return (
    <Container
      height="95vh"
      width="100%"
      overflowY="auto"
      backgroundColor={theme.colors.dark.background}
      onClick={(e) => e.stopPropagation()}
    >
      <Column gap={1}>
        <Typography variant="subtitle1">{select}</Typography>
        <Divider />
        <Container>
          <SidebarContainer visible={!!selectedLeague}>
            <StyledLink to={`${STANDINGS_ROUTE}/${selectedLeague.toUpperCase()}`}>
              <LinkContainer padding={1} variant="button" onClick={forceClose}>
                <Typography variant="body1" color={theme.colors.dark.text.primary}>
                  STANDINGS
                </Typography>
                {loading && (
                  <Typography variant="body1" color={theme.colors.dark.text.primary}>
                    <em>Loading Teams...</em>
                  </Typography>
                )}
              </LinkContainer>
            </StyledLink>
            {data &&
              data.bddTeams
                .filter((t) => t.bddLeagueSlug == selectedLeague)
                .sort((a, b) => a.longname.localeCompare(b.longname))
                .map((t) => (
                  <StyledLink key={`link-${t.id}`} to={`${TEAM_ROUTE}/${t.slTeamSlug}`}>
                    <LinkContainer padding={1} variant="button" onClick={forceClose}>
                      <Row columnGap={3}>
                        {t.nhlid && (
                          <NhlLogo
                            src={getNHLTeamLogoUrl(t.nhlid)}
                            height="20px"
                            dark={NHL_SATURATE_LOGOS.includes(t.nhlid)}
                          />
                        )}
                        <Typography
                          variant="body1"
                          color={theme.colors.dark.text.primary}
                          noWrap
                        >
                          {t.longname}
                        </Typography>
                      </Row>
                    </LinkContainer>
                  </StyledLink>
                ))}
          </SidebarContainer>
        </Container>
      </Column>
    </Container>
  );
};
