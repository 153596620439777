import useBDDPlayerSearch from 'components/Search/useBDDPlayerSearch';
import { useContext } from 'react';
import { PlayerSearchContext } from '..';

export const BDDPlayerSearch = ({}) => {
  const { setStore } = useContext(PlayerSearchContext);

  const { searchComponent } = useBDDPlayerSearch({
    onSelect: (v) => {
      setStore((store) => ({
        ...store,
        selected: v?.bddPlayer.cfSlug,
      }));
    },
  });

  return searchComponent;
};
