import { useMutation } from "@apollo/client"
import { GET_SCENARIO_CAP_BREAKDOWN, UPDATE_PRO_OBJECTIVE_SCENARIO } from "apollo/queries/proobjective.queries"
import { toastBddApiError } from "components/bdd/bddtoasts"
import { useButtonGroup } from "components/bdd/ButtonGroup"
import { Divider } from "components/bdd/Divider"
import { Column, Container, Row } from "components/bdd/Layout"
import StillBuilding from "components/bdd/stillbuilding"
import { Typography } from "components/bdd/Typography"
import { ContractBuyout, useContractBuyout } from "components/ProObjectives/ContractSearch/ContractBuyout"
import { ScenarioDeadCapForm } from "components/ProObjectives/Scenarios/ScenarioDeadCapForm"
import { ScenarioDepth } from "components/ProObjectives/Scenarios/ScenarioDepth"
import { CreateTransactionModal } from "components/ProObjectives/Transactions/CreateTransactionModal"
import { useBDDDepthChart } from "components/ProScouting/DepthCharts/useBDDDepthChart"
import { DepthChartSpotWrapperForm } from "components/ProScouting/DepthChartSpotWrapperForm"
import { seasonToStartYear } from "helpers/hockeyutils"
import { bruinsGold } from "helpers/plotting"
import { Button } from "react-bootstrap"

export const CapHitAddModal = ({ scenario, capBreakdown, onCompleted, refetchBreakdown }) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'wrapper',
    options: [
      { label: 'Add Placeholder', value: 'wrapper' },
      { label: 'Buyout Player', value: 'buyout' },
      { label: 'Create Dead Cap', value: 'deadcap' },
      { label: 'Manage Depth', value: 'depth'},
    ]
  });

  return (
    <Container>
      <Column gap={1}>
        <Container>
          {buttonGroup}
        </Container>

        <Divider/>
        
        {selectedValue === 'depth'
          ? <ManageDepth scenario={scenario} />
          // : selectedValue === 'transaction' 
          // ? <CreateTransactionModal /> 
          : selectedValue === 'buyout'
          ? <ScenarioContractBuyout scenario={scenario} onCompleted={onCompleted} />
          : selectedValue === 'deadcap'
          ? <ScenarioDeadCapForm scenario={scenario} bfTeam={capBreakdown?.bfTeam} onCompleted={onCompleted} />
          : selectedValue === 'wrapper'
          ? <CreateWrapper scenario={scenario} capBreakdown={capBreakdown} onCompleted={onCompleted} refetchBreakdown={refetchBreakdown} />
        : null}
      </Column>
    </Container>
  )
}


const ManageDepth = ({ scenario }) => {
  return (
    <Column gap={1}>
      <Container>
        <Typography variant='body2'>
          There is a depth chart tied to this cap outlook scenario.  You can move players around in here if you'd like.
        </Typography>
        <Row gap={4}>
          <Typography variant='body1' color={bruinsGold}>
            NOTE:
          </Typography>
          <Typography variant='body2'>
            Players moved to different leagues or F/D/G positions will be reflected in the cap chart.
          </Typography>
        </Row>
      </Container>
      <Row justifyContent='center'>
        <Container maxWidth={500}>
          <ScenarioDepth scenario={scenario} size='sm' />
        </Container>
      </Row>
    </Column>
  )
}

const CreateWrapper = ({ scenario, capBreakdown, onCompleted, refetchBreakdown }) => {
  const existingWrappers = capBreakdown?.wrappers;
  const depthChartPlayers = scenario.depthChart.depthChartPlayers;

  return (
    <Column gap={2}>
      <Typography variant='body2'>
        Add a placeholder, associated with a particular spot in the team's depth chart, which will be reflected on the cap sheet.
      </Typography>
      <DepthChartSpotWrapperForm
        depthChartId={scenario.depthChartId}
        existingWrappers={existingWrappers}
        defaultValues={{}}
        requireEmptySpot={true}
        requireCapHit={true}
        existingPlayers={depthChartPlayers}
        onSubmit={() => {
          refetchBreakdown();
          onCompleted();
        }}
      />
    </Column>
  )
}


export const ScenarioContractBuyout = ({ scenario, onCompleted, defaultContractId, hideContractSearch }) => {
  const { calculator, contract } = useContractBuyout({ 
    defaultYear: seasonToStartYear(scenario.season), 
    hideYear: true,
    hideContractSearch,
    defaultContractId
  });

  const [updateScenario, { loading: updateScenarioLoading }] = useMutation(UPDATE_PRO_OBJECTIVE_SCENARIO, {
    onCompleted: () => onCompleted(),
    onError: toastBddApiError
  })

  const handleSubmit = () => {
    if (!contract) return;
    updateScenario({ 
      variables: { 
        input: {
          id: scenario.id
        }, 
        contractToBuyout: contract.id
      } 
    })
  }

  const isValid = contract?.isActive;
  return (
    <Column gap={2}>
      <Container padding={2}>
        {calculator}
      </Container>
      
      {!!contract && !isValid && (
        <Typography textAlign='center' variant='h6' state='danger'>Please select an active contract</Typography>
      )}

      <Divider/>

      <Row justifyContent='end'>
        <Button variant='warning' size='sm' disabled={updateScenarioLoading || !isValid} onClick={handleSubmit}>
          <Typography variant='body1'>
            {updateScenarioLoading ? 'Submitting...' : 'Submit'}
          </Typography>
        </Button>
      </Row>
    </Column>
  )
}