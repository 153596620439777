import React, { useState, memo, useEffect } from 'react';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompactNotes, NoteCommentForm, useNoteStore, useNotes } from 'components/Notes';
import { Border, Container } from 'components/bdd/Layout';
import { EMPTY_TEXT } from 'components/bdd/TextEditor';

export const AddTierNoteButton = ({ tier, listId }) => {
  const { createEmptyNote } = useNotes({
    entityIds: [listId],
    entityType: 'BDD_PLAYER_LIST',
  });

  return (
    <span
      className="tier-button"
      title="Add note"
      onClick={() =>
        createEmptyNote({
          entityId: listId,
          entityType: 'BDD_PLAYER_LIST',
          subEntityId: tier.id,
          subEntityType: 'BDD_PLAYER_LIST_TIER',
        })
      }
    >
      <FontAwesomeIcon icon={faStickyNote} />
    </span>
  );
};

export const TierNotes = ({ tier, listId }) => {
  const cachedNotes = useNoteStore((state) =>
    state.notes.filter(
      (n) =>
        n.entityId == listId &&
        n.subEntityId == tier.id &&
        n.entityType == 'BDD_PLAYER_LIST' &&
        n.subEntityType == 'BDD_PLAYER_LIST_TIER'
    )
  );

  const { updateNote } = useNotes({
    entityIds: [listId],
    entityType: 'BDD_PLAYER_LIST',
  });

  const noteItems = cachedNotes?.map((n, index) => ({
    note: n,
    render: () => (
      <NoteCommentForm
        existingNote={n}
        // variant="horizontal"
        {...n}
        onSubmit={(values) => {
          updateNote({ variables: values });
        }}
        defaultEditMode={
          JSON.stringify(JSON.parse(n.note).comment) == JSON.stringify(EMPTY_TEXT)
        }
        variant="vertical-compact"
      />
    ),
  }));

  return (
    noteItems?.length > 0 && (
      <Container padding={3} paddingTop={1}>
        {noteItems?.map((ni, index) => (
          <Border key={`note-${ni.note.id}`}>
            <Container>{ni.render()}</Container>
          </Border>
        ))}
      </Container>
    )
  );
};
