import { createContext, useState } from 'react';
import { useSearchParamsState } from 'components/bdd/hooks';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { ModalContextProvider } from 'components/bdd/Modal';
import { removeNull } from 'helpers/data';

export const TradeContext = createContext();

export default function Trades({ useStateParams, initialState = {}, children }) {
  const [store, setStore] = useStateParams
    ? useSearchParamsState(initialState, 'tradeContext')
    : useState(initialState);

  const setStoreValue = (key, value) => {
    setStore((state) =>
      removeNull(
        {
          ...state,
          [key]: value,
        },
        (v) => v
      )
    );
  };

  const parsedStore = {
    ...store,
    startDate: store.startDate ? new Date(store.startDate) : null,
    endDate: store.endDate ? new Date(store.endDate) : null,
  };

  return (
    <TradeContext.Provider value={{ store: parsedStore, setStore, setStoreValue }}>
      <BDDErrorBoundary>{children}</BDDErrorBoundary>
    </TradeContext.Provider>
  );
}
