import gql from 'graphql-tag';
import { PageInfoFragment } from './pagination.queries';

export const GENERIC_EP_QUERY = gql`
  query GenericEPQuery(
    $path: String!
    $params: JSONString
    $multirequest: Boolean
    $multirequestlimit: Int
  ) {
    epQuery(
      path: $path
      params: $params
      multirequest: $multirequest
      multirequestlimit: $multirequestlimit
    )
  }
`;

export const PAGINATED_EP_QUERY = gql`
  query GetPaginatedEPQuery(
    $limit: Int
    $offset: Int
    $query: GenericScalar
    $sort: [BDDSortV2]
  ) {
    paginatedEpStats(limit: $limit, offset: $offset, sort: $sort, query: $query) {
      pageInfo {
        ...pageInfoFragment
        __typename
      }
      data
      __typename
    }
  }
  ${PageInfoFragment}
`;

export const EPLeaguesFragment = gql`
  fragment epLeaguesFragment on EPLeagues {
    slug
    name
    fullName
    country
    level
    epUpdatedAt
    bddLeagueSlug
  }
`;

export const GET_EP_LEAGUES = gql`
  query GetEPLeagues {
    epLeagues {
      ...epLeaguesFragment
    }
  }
  ${EPLeaguesFragment}
`;
