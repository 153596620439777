export const permissions = {
  adminFullAccess: 'adminFullAccess',
  manageScouting: 'manageScouting',

  viewMockDraftResults: 'viewMockDraftResults',

  viewScouting: 'viewScouting',
  viewProScouting: 'viewProScouting',
  proScenarioAccess: 'proScenarioAccess',
  viewPlayerReports: 'viewPlayerReports',
  editActiveDepthCharts: 'editActiveDepthCharts',
  editBddPlayerTags: 'editBddPlayerTags',

  uploadSportsScienceData: 'uploadSportsScienceData',
  viewSportsScience: 'viewSportsScience',
  viewSportsScienceReport: 'viewSportsScienceReport',
  viewSportsScienceTables: 'viewSportsScienceTables',
  viewSportsScienceDashboard: 'viewSportsScienceDashboard',
  viewSportsSciencePlayers: 'viewSportsSciencePlayers',

  viewPlayerDevelopment: 'viewPlayerDevelopment',
  managePlayerDevelopment: 'managePlayerDevelopment',
  manageCFContractLists: 'manageCFContractLists',

  manageSummaryReports: 'manageSummaryReports', // No longer used
  
  editQuestionnaireLinks: 'editQuestionnaireLinks',  

  manageSalaryCap: 'manageSalaryCap',
  
  manageShootoutData: 'manageShootoutData',
};
