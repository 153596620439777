import { useQuery } from "@apollo/client"
import { GET_BF_TEAM_SEASON_SUMMARY } from "apollo/queries/bruinsfriendly.queries"
import Icon from "components/bdd/Icon"
import { Container, Row } from "components/bdd/Layout"
import { theme } from "constants"
import { formatDollars } from "helpers/contracts"
import { roundToX } from "helpers/data"
import styled from "styled-components"

const StyledTable = styled.table({
  width: '100%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  th: {
    ...theme.typography.stat,
    borderBottom: '1px solid #ddd',
    padding: '4px 8px'
  },
  td: {
    ...theme.typography.body2,
    // border: '1px solid #ddd',
    padding: '4px 8px'
  }
})

export const BDDTeamTradeAnalysis = ({ teamSummary, teamCapData }) => {
  const { data, loading } = useQuery(GET_BF_TEAM_SEASON_SUMMARY, {
    variables: {
      slTeamSlug: teamSummary.teamSlug,
    },
    skip: !!teamCapData
  });

  teamCapData = !!teamCapData ? teamCapData : data?.bfTeamSeasonSummary;

  const numContracts = !!teamCapData ?teamSummary.numPlayersIn - teamSummary.numPlayersOut + teamCapData?.numContracts : null;
  const deadlineCapSpace = !!teamCapData ? teamCapData.tradeDeadlineCapSpace - teamSummary.capHitAffectingChange : null;

  return (
    <Container>
      <StyledTable>
        <thead>
          <tr>
            {!!teamCapData && <th>Contracts</th>}
            <th>Cap &Delta;</th>
            {!!teamCapData && <th>Deadline Cap Space</th>}
            <th>WAR &Delta;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {!!teamCapData && <td style={{ color: numContracts > 50 ? 'red': 'black' }}>
              {numContracts}
            </td>}
            <td>{teamSummary.capHitAffectingChange > 0 ? '+' : ''}{formatDollars(teamSummary.capHitAffectingChange)}</td>
            {!!teamCapData && <td style={{ color: deadlineCapSpace < 0 ? 'red': 'black' }}>
              {formatDollars(deadlineCapSpace)}
            </td>}
            <td style={{ 
              color: teamSummary.analysis?.total_war_change > 0 ? 'green' 
                : teamSummary.analysis?.total_war_change < 0 ? 'red' 
                : 'black' 
            }}>
              {teamSummary.analysis?.total_war_change > 0 ? '+' : ''}
              {roundToX(teamSummary.analysis?.total_war_change, 2, true)}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </Container>
  )
}