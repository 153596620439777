import { useBddStatQueryV2 } from "apollo/queries";
import BDDApiError from "components/bdd/bddapierror";
import { BDDLoader } from "components/bdd/bddloader";
import BDDStatTableV2 from "components/bdd/Stats/BDDStatTableV2";
import { season8to4 } from "helpers/hockeyutils";
import { bruinsGold } from "helpers/plotting";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";

const Container = styled.div({
  textAlign: 'center',
  maxHeight: '200px',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  paddingRight: '20px',
  'th': {
    paddingLeft: '10px'
  }
})

const splitToText = split => {
  const playoffs = split.game_type === 'nhl_postseason' ? ' playoffs' : ''
  return `${split.target_group === 'league' ? split.league_slug : split.target_group}${playoffs} ${season8to4(split.season, true)} ${split.position.toUpperCase()}`
}

export default function PlayerStatList({
  slUid,
  statSlug,
  statLabel,
  split,
  filterSetId,
  norm
}) {
  const targetPlayerRef = useRef(null)
  const containerRef = useRef(null)
  const filters = {...split}
  if (filters.target_group !== 'league') {
    filters.team_slug = filters.target_group
  }
  if (filters.position === 'all') {
    delete filters.position
  }
  delete filters.target_group
  const { data, loading, error } = useBddStatQueryV2({
    target: 'skaters',
    filters,
    norm,
    index: ['Player', 'sl_uid'],
    statDefs: [{ slug: 'gamesPlayed', label: 'GP' }, { slug: statSlug, label: statLabel, filter_set_id: filterSetId }],
    extras: { metastats: { target_group: split.target_group, target_position: split.position } },
    format: { percentages: { mult100: true }}
  })

  if (loading) return <BDDLoader/>
  if (error) return <BDDApiError error={error}/>
  if (data.length === 0) {
    return <em>No skater data found for {splitToText(split)}</em>
  }

  const normStr = !!norm ? `per ${norm}` : ''
  return <Container ref={containerRef}>
    <small><em>Comparing to {data.data.length} {splitToText(split)} {normStr}</em></small>
    <BDDStatTableV2
      data={data?.data}
      format={data?.format}
      extras={data?.extras}
      index={['Player']}
      indexColumns={[{ 
          id: 'Player', Header: 'Player', 
          accessor: d => 
          <span 
            ref={d.sl_uid === slUid ? targetPlayerRef : null} 
            style={d.sl_uid === slUid ? { fontWeight: 'bold', fontSize: '1.1em', color: bruinsGold } : {}}
          >
            {d.Player}
          </span> 
      }]}
      highlight={true}
      metastats={data?.extras?.metastats?.[split.target_group]?.[0]?.metastats}
      defaultSort={[{ id: statLabel, desc: true }]}
    />
  </Container>
}