
export function getMin(arrayOfNumbers) {
    var minReduce = function (a, b) { return (isNaN(b) || b > a) ? a : b };
    var smallest = arrayOfNumbers.reduce(minReduce, Number.MAX_VALUE);
    if (smallest < Number.MAX_VALUE) return smallest
    return NaN
}

export function getMax(arrayOfNumbers) {
    var maxReduce = function (a, b) { return (isNaN(b) || b < a) ? a : b };
    var biggest = arrayOfNumbers.reduce(maxReduce, Number.MIN_VALUE);
    if (biggest > Number.MIN_VALUE) return biggest
    return NaN
}

export function getMean(arrayOfNumbers) {
  return arrayOfNumbers.reduce((acc, curr) => acc + curr, 0) / arrayOfNumbers.length
}

export function getStd(arrayOfNumbers) {
    arrayOfNumbers = [...arrayOfNumbers]
    arrayOfNumbers = arrayOfNumbers.filter(v => !isNaN(v))
    const avg = arrayOfNumbers.reduce((a, b) => a + b, 0) / arrayOfNumbers.length
    const sqDiffs = arrayOfNumbers.map(v => (v-avg) * (v - avg))
    const avgSqDiff = sqDiffs.reduce((a, b) => a + b, 0) / sqDiffs.length
    return Math.sqrt(avgSqDiff)
}

export function vectorNorm(vec) {
    // sqrt(sum of squares)
    return Math.sqrt(vec.map(v => v*v).reduce((acc, curr) => acc+curr, 0))
}

export function normalizeVector(vec) {
    const norm = vectorNorm(vec)
    return vec.map(v => v/norm)
}

export function norm_cdf(x, mu, sigma) {
    return stdNormal((x-mu)/sigma);
}

function stdNormal(z) {
    var j, k, kMax, m, values, total, subtotal, item, z2, z4, a, b;

    // Power series is not stable at these extreme tail scenarios
    if (z < -6) { return 0; }
    if (z >  6) { return 1; }

    m      = 1;        // m(k) == (2**k)/factorial(k)
    b      = z;        // b(k) == z ** (2*k + 1)
    z2     = z * z;    // cache of z squared
    z4     = z2 * z2;  // cache of z to the 4th
    values = [];

    // Compute the power series in groups of two terms.
    // This reduces floating point errors because the series
    // alternates between positive and negative.
    for (k=0; k<100; k+=2) {
        a = 2*k + 1;
        item = b / (a*m);
        item *= (1 - (a*z2)/((a+1)*(a+2)));
        values.push(item);
        m *= (4*(k+1)*(k+2));
        b *= z4;
    }

    // Add the smallest terms to the total first that
    // way we minimize the floating point errors.
    total = 0;
    for (k=49; k>=0; k--) {
        total += values[k];
    }

    // Multiply total by 1/sqrt(2*PI)
    // Then add 0.5 so that stdNormal(0) === 0.5
    return 0.5 + 0.3989422804014327 * total;
}

export function zScoreToPercentile(z) {
    // Z Scores to Percentile!
    // z == number of standard deviations from the mean
  
    // if z is greater than 6.5 standard deviations from the mean the
    // number of significant digits will be outside of a reasonable range
  
    if (z < -6.5) {
        return 0.0;
    }
  
    if (z > 6.5) {
        return 1.0;
    }
  
    var factK = 1;
    var sum = 0;
    var term = 1;
    var k = 0;
    var loopStop = Math.exp(-23);
    // var loopStop = Math.exp(-10)

    while(Math.abs(term) > loopStop) {
        term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
        sum += term;
        k++;
        factK *= k;
    }
  
    sum += 0.5;
  
    return sum;
}

// export function findLineByLeastSquares(xValues, yValues) {
//     console.log(xValues, yValues)
//     var sumX = 0;
//     var sumY = 0;
//     var sumXY = 0;
//     var sumXX = 0;
//     var count = 0;

//     // We'll use those variables for faster read/write access.
//     var x = 0;
//     var y = 0;
//     var len = xValues.length;

//     if (xValues.length != yValues.length) {
//         console.error('The parameters xValues, yValues need to have same size!');
//     }

//     // Nothing to do.
//     if (len === 0) return [ [], [] ];

//     // Calculate the sum for each of the parts necessary.
//     for (var i = 0; i < len; i++) {
//         x = xValues[i];
//         y = yValues[i];
//         sumX += x;
//         sumY += y;
//         sumXX += x*x;
//         sumXY += x*y;
//         count++;
//     }

//     // y = mx + b
//     var m = (count*sumXY - sumX*sumY) / (count*sumXX - sumX*sumX);
//     var b = (sumY/count) - (m*sumX)/count;
//     console.log('m', m, 'b', b)

//     // Calculate R2 ()
//     var sse = 0
//     var minX = Math.min(xValues)
//     var maxX = Math.min(xValues)
//     // at the min and max X values we capture our line or best fit
//     var bestFitX = [minX, maxX]
//     var bestFitY = [minX*m+b, maxX*m+b]
//     for (var i = 0; i < len; i++) {
//         x = xValues[i];
//         y = x * m + b;
//         var err = y - yValues[i]
//         var sqErr = err*err
//         sse += sqErr
//     }
//     var r2 = sse / (2*len)

//     return [bestFitX, bestFitY, r2];
// }