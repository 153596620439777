import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { PlaylistsPage, Playlist } from '.';

export const PlaylistRouter = ({}) => {
  const { url: baseRoute } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${baseRoute}/all`} component={PlaylistsPage} />
        <Route path={`${baseRoute}/:id`} component={Playlist} />

        <Redirect to={`${baseRoute}/all`} />
      </Switch>
    </>
  );
};
