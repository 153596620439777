import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import {
  EventVideoController,
  VideoContext,
  VideoSelect,
  VideoSelectContext,
} from 'components/bdd/Video';
import { EventBank, EventControls } from 'components/bdd/Video/Filtering';
import {
  GameTypeFilter,
  StrengthFilter,
  OppTeamFilter,
  PeriodFilter,
  SeasonsFilter,
  StatDefinitionFilter,
  TeamGameFilter,
  TeamPlayerFilter,
  FilterSummary,
  PlayerLSGFilter,
  PlayerGameFilter,
  OnIcePlayerFilter,
} from 'components/bdd/Video/Filtering/components';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { EventMap } from 'components/PlayersV2/Video/EventMap';
import useBDDSearch from 'components/Search/useBDDSearch';
import { getGameTypeChoices } from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';
import React, { useContext, useState } from 'react';
import { GET_TEAM } from 'apollo/queries/teams.queries';
import { defaultStatDefinitionForPosition } from 'components/PlayersV2/Video/Events';
import { VideoClipFilters } from 'components/bdd/Video/components';
import { useSearchParamsState } from 'components/bdd/hooks';

export const AddVideoEvents = ({ playlist, useStateParams }) => {
  const teamSlug = 'nhl.bos';
  const leagueSlug = 'NHL';

  const defaults = {
    team_slug: {
      col: 'team_slug',
      val: teamSlug,
    },
    league_slug: {
      col: 'league_slug',
      val: leagueSlug,
    },
    season: {
      col: 'season',
      val: getCurrentSeason(),
    },
    game_type: {
      col: 'game_type',
      val: getGameTypeChoices(leagueSlug)[0].value,
    },
  };

  return (
    <EventVideoController
      statDefinitionSlug={'shotAttempts'}
      sourceFilters={defaults}
      playlist={playlist}
      useStateParams={useStateParams}
      clipFilters={<VideoClipFilters />}
    >
      <Container padding={3} height="100%">
        <Column gap={4} alignItems="start" justifyContent="space-between">
          <EventFilters />
          <Divider />
          <VideoSelect>
            <Container height="90%">
              <Column gap={3}>
                <EventControls />
                <Container>
                  <Row
                    columnGap={3}
                    rowGap={3}
                    alignItems="start"
                    justifyContent="stretch"
                  >
                    <FormEventBank />
                    <EventMap />
                  </Row>
                </Container>
              </Column>
            </Container>
          </VideoSelect>
        </Column>
      </Container>
    </EventVideoController>
  );
};

const EventFilters = ({}) => {
  const { slPlayer, sourceFilters } = useContext(VideoContext);

  let filters = {};

  if (!!slPlayer && slPlayer.bddPlayer) {
    const bddPlayer = slPlayer.bddPlayer;
    const leagueSlug = sourceFilters?.['league_slug']?.val;

    filters = (
      <Row alignItems="end" columnGap={2} rowGap={2} flexWrap>
        <Container>
          <Row columnGap={2} rowGap={2} flexWrap>
            <PlayerLSGFilter slug={bddPlayer.slug} leagueSlug={leagueSlug} />
            <PlayerGameFilter slug={bddPlayer.slug} />
            <OnIcePlayerFilter slug={bddPlayer.slug} />
            <OnIcePlayerFilter slug={bddPlayer.slug} isTeammate={false} />
            <PeriodFilter />
            <StrengthFilter />
          </Row>
        </Container>
        <StatDefinitionFilter slPlayer={slPlayer} />
      </Row>
    );
  } else if ('team_slug' in sourceFilters) {
    filters = (
      <Row columnGap={2} rowGap={2} alignItems="end" flexWrap>
        <SeasonsFilter selectProps={{ isMulti: false }} />
        <GameTypeFilter />
        <TeamGameFilter />
        <OppTeamFilter />
        <TeamPlayerFilter />
        <TeamPlayerFilter target="goalies" />
        <PeriodFilter />
        <StrengthFilter />
        <StatDefinitionFilter />
      </Row>
    );
  }

  return (
    <Container width="100%">
      <Column gap={3}>
        <SearchFilter />
        {filters}
        <Divider />
        {!!filters && <FilterSummary />}
      </Column>
    </Container>
  );
};

const SearchFilter = ({}) => {
  const {
    setSlPlayer,
    filters,
    sourceFilters,
    setSourceFilters,
    replaceSourceFilters,
    setStatDefinition,
    clearFilters,
  } = useContext(VideoContext);

  const [searchTerm, setSearchTerm] = useSearchParamsState('Boston Bruins', 'searchTerm');

  const [getBddPlayer, { loading: playerLoading }] = useLazyQuery(GET_PLAYER_CARD, {
    onCompleted: (data) => {
      const leagueSlug = sourceFilters?.['league_slug']?.val;

      let slPlayer;
      if (!!leagueSlug) {
        slPlayer = data.bddPlayer.slPlayerMaster.slPlayers.find(
          (sp) => sp.leagueSlug == leagueSlug
        );
      }

      if (!slPlayer) {
        slPlayer = data.bddPlayer.slPlayerMaster.slPlayers[0];
      }

      replaceSourceFilters({});
      clearFilters();
      setStatDefinition(defaultStatDefinitionForPosition[slPlayer.primaryposition]);

      setSlPlayer({
        ...slPlayer,
        bddPlayer: data.bddPlayer,
      });
    },
  });

  const [getSLTeam, { loading: teamLoading }] = useLazyQuery(GET_TEAM, {
    onCompleted: (data) => {
      replaceSourceFilters({
        team_slug: {
          col: 'team_slug',
          val: data.slTeam.slug,
        },
        league_slug: {
          col: 'league_slug',
          val: data.slTeam.leagueSlug,
        },
        season: {
          col: 'season',
          val: getCurrentSeason(),
        },
        game_type: {
          col: 'game_type',
          val: getGameTypeChoices(data.slTeam.leagueSlug)[0].value,
        },
      });

      clearFilters();
      setSlPlayer(null);
      setStatDefinition('shotAttempts');
    },
  });

  const { searchComponent } = useBDDSearch({
    initialSelectedValue: searchTerm,
    onSelect: (selected) => {
      if (!selected) return;

      setSearchTerm(selected.label);

      if (selected.type == 'player') {
        getBddPlayer({
          variables: {
            slug: selected.value,
          },
        });
      } else if (selected.type == 'team') {
        getSLTeam({
          variables: {
            slug: selected.value,
          },
        });
      }
    },
    autoFocus: true,
  });

  const loading = playerLoading || teamLoading;

  return (
    <Container>
      <Typography variant="body1">Search for target</Typography>
      <Container>
        <Row columnGap={3}>
          <Container width={250}>{searchComponent}</Container>
          {loading && <Typography variant="body1">Loading...</Typography>}
        </Row>
      </Container>
    </Container>
  );
};

const FormEventBank = ({}) => {
  const { allEvents, loading } = useContext(VideoContext);
  const { selectedEvents, setSelectedEvents } = useContext(VideoSelectContext);

  return (
    <Container minWidth={400} overflow="auto">
      <EventBank
        events={allEvents}
        loading={loading}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        // height={'100%'}
      />
    </Container>
  );
};
