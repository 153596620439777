import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import {
  contractTypeOptions,
  expiryStatusOptions,
  expiryYearSeasonOptions,
  playerStatusOptions,
  seasonOptions,
  seasonPhaseOptions,
  signingStatusOptions,
} from './constants';
import { TeamLink } from 'components/TeamsV2';
import { formatDollars as baseFormatDollars } from 'helpers/contracts';
import React, { useContext } from 'react';
import { ModalContext } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { Container, Row } from 'components/bdd/Layout';
import { LinkButton } from 'components/bdd/Button';
import { sortNumericAccessor, stddevColor } from 'helpers/tables';
import { searchMetastatsSplitsForStat } from 'helpers/stats';
import { formatBddStat } from 'helpers/format';
import { TooltipSpan } from 'components/reports';
import { PlatformSeasonButton } from './PlatformSeasonButton';
import BFSigningBreakdown from 'components/ProScouting/Transactions/Signings/BFSigningBreakdown';
import { ExpiryStatusButton } from './ExpiryStatusButton';

const { Header, Cell } = require('components/bdd/Table');
const { roundToX } = require('helpers/data');

const formatDollars = (value) => baseFormatDollars(value, { trailingZeros: 1 });
const formatDollarsForExport = (value) => baseFormatDollars(value, { truncate: false });
const salaryColumnFormat = {
  renderLabel: (_, accessorValue) => {
    return accessorValue || accessorValue == 0 ? formatDollars(accessorValue) : 'Unknown';
  },
  exportValue: (d, accessorValue) => {
    return accessorValue || accessorValue == 0
      ? formatDollarsForExport(accessorValue)
      : 'Unknown';
  },
};

const ModalContractLink = React.memo(({ label, contract }) => (
  <ModalLinkCell
    label={label}
    modal={{
      size: 'xl',
      title: (
        <Typography variant="h6">
          {`${contract.bfPlayer.firstName} ${contract.bfPlayer.lastName} Contract Details`}
        </Typography>
      ),
      body: (
        <Container padding={2}>
          <BFSigningBreakdown bfContract={contract} />
        </Container>
      ),
    }}
  />
));

const getContractSeason = (d, season) => {
  if (!season) return d.contractSeasons[0];

  return d.contractSeasons.find((cs) => cs.season == season);
};

export const playerColumns = {
  index: {
    id: 'index',
    header: '#',
    minWidth: 20,
    disableSort: true,
    renderCell: (_, __, index) => {
      return <Cell textAlign={'center'}>{index + 1}</Cell>;
    },
  },
  player: {
    id: 'player',
    header: 'Player',
    minWidth: 125,
    justify: 'start',
    accessor: (d) => d.bfPlayer.lastName,
    exportValue: (d) => `${d.bfPlayer.firstName} ${d.bfPlayer.lastName}`,
    renderCell: (d) => (
      <PlayerLinkHover
        name={`${d.bfPlayer.firstName} ${d.bfPlayer.lastName}`}
        slug={d.bfPlayer.bddPlayer?.slug}
      />
    ),
  },
  age: {
    id: 'age',
    header: 'Current Age',
    accessor: (d) => roundToX(d.bfPlayer.age, 1),
  },
  position: {
    id: 'position',
    header: 'Position',
    accessor: (d) => d.bfPlayer.position,
    minWidth: 85,
  },
  position_fdg: {
    id: 'position_fdg',
    header: 'Position FDG',
    accessor: (d) => d.bfPlayer.positionFdg,
    minWidth: 85,
  },
  height: {
    id: 'height',
    header: 'Height',
    accessor: (d) => d.bfPlayer.height,
    exportValue: (_, accessorValue) => `"${accessorValue.replace(/'/g, '"')}`,
  },
  height_inches: {
    id: 'height_inches',
    header: 'Height (in)',
    accessor: (d) => d.bfPlayer.heightInches,
  },
  weight: {
    id: 'weight',
    header: 'Weight (lbs)',
    accessor: (d) => d.bfPlayer.weight,
  },
  is_expiry_status_arbitration_eligible: {
    id: 'is_expiry_status_arbitration_eligible',
    header: 'Arb. Eligible',
    accessor: (d) => d.expiryStatusDetails?.includes('Arbitration Eligible').toString(),
    renderLabel: (d) => (
      <input
        type="checkbox"
        readOnly
        checked={!!d.expiryStatusDetails?.includes('Arbitration Eligible') || false}
      />
    ),
  },
  round: {
    id: 'round',
    header: 'Draft Round',
    minWidth: 100,
    accessor: (d) => d.bfPlayer.bfDraftPick?.round || 'Not Drafted',
  },
  overall: {
    id: 'overall',
    header: 'Draft Overall',
    minWidth: 100,
    accessor: (d) => d.bfPlayer.bfDraftPick?.overall || 'Not Drafted',
  },
  status: {
    id: 'status',
    header: 'Status',
    minWidth: 200,
    accessor: (d) =>
      playerStatusOptions.find((o) => o.value == d.bfPlayer.status)?.label || 'Unknown',
  },
};

export const contractColumns = {
  signing_team_slug: {
    id: 'signing_team_slug',
    header: 'Signing Team',
    Header: (
      <Container paddingLeft={1} paddingRight={1}>
        <Header>Signing Team</Header>
      </Container>
    ),
    minWidth: 65,
    accessor: (d) => d.signingTeam?.abbreviation,
    renderLabel: (d) => (
      <Row justifyContent="center">
        <TeamLink
          abbreviation={d.signingTeam?.abbreviation}
          nhlid={d.signingTeam?.nhlid}
          slTeamSlug={d.signingTeam?.bddTeam?.slTeamSlug}
          logoSize={18}
        />
      </Row>
    ),
  },
  contract: {
    id: 'contract',
    header: 'Contract',
    accessor: (d) => `${formatDollars(d.capHit / d.numYears)} x ${d.numYears}`,
    renderCell: (d, accessorValue) => {
      return <ModalContractLink label={accessorValue} contract={d} />;
    },
  },
  contract_type: {
    id: 'contract_type',
    header: 'Contract Type',
    accessor: (d) =>
      contractTypeOptions.find((o) => o.value == d.contractType)?.label || 'Unknown',
    renderLabel: (_, accessorValue) => accessorValue,
  },
  signing_age: {
    id: 'signing_age',
    header: 'Signing Age',
    accessor: (d) => roundToX(d.signingAge, 1),
    renderLabel: (_, accessorValue) => accessorValue,
  },
  expiration_age: {
    id: 'expiration_age',
    header: 'Expiration Age',
    accessor: (d) => roundToX(d.expirationAge, 1),
    renderLabel: (_, accessorValue) => accessorValue,
  },
  num_years: {
    id: 'num_years',
    header: 'Length',
    accessor: (d) => d.numYears,
    renderCell: (d) => {
      return <ModalContractLink label={d.numYears} contract={d} />;
    },
  },
  remaining_term: {
    id: 'remaining_term',
    header: 'Remaining Term',
    accessor: (d) => d.remainingTerm,
  },
  cap_hit: {
    id: 'cap_hit',
    header: 'Cap Hit',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.capHit || d.capHit / d.numYears;
    },
    exportValue: (d, accessorValue) => {
      return accessorValue || accessorValue == 0
        ? formatDollarsForExport(accessorValue)
        : 'Unknown';
    },
    renderCell: (d, accessorValue) => {
      return (
        <ModalContractLink
          label={
            accessorValue || accessorValue == 0 ? formatDollars(accessorValue) : 'Unknown'
          }
          contract={d}
        />
      );
    },
  },
  aav: {
    id: 'aav',
    header: 'AAV',
    accessor: (d) => {
      return d.aav;
    },
    ...salaryColumnFormat,
  },
  aav_sum: {
    id: 'aav_sum',
    header: 'AAV Sum',
    accessor: (d) => {
      return d.aavSum;
    },
    ...salaryColumnFormat,
  },
  cap_pct: {
    id: 'cap_pct',
    header: 'Cap %',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.capPct;
    },
    renderLabel: (_, accessorValue) => {
      return accessorValue ? roundToX(accessorValue, 1) : 'Unknown';
    },
    exportValue: (d, accessorValue) => {
      return accessorValue ? roundToX(accessorValue, 1) : 0;
    },
  },
  signing_bonus: {
    id: 'signing_bonus',
    header: 'Signing Bonus',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.signingBonus;
    },
    ...salaryColumnFormat,
  },
  performance_bonus: {
    id: 'performance_bonus',
    header: 'Perf. Bonus',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.performanceBonus;
    },
    ...salaryColumnFormat,
  },
  minor_salary: {
    id: 'minor_salary',
    header: 'Minors Salary',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.minorSalary;
    },
    ...salaryColumnFormat,
  },
  guaranteed_salary: {
    id: 'guaranteed_salary',
    header: 'Guar. Salary',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.guaranteedSalary;
    },
    ...salaryColumnFormat,
  },
  signing_status: {
    id: 'signing_status',
    header: 'Signing Status',
    accessor: (d) =>
      signingStatusOptions.find((o) => o.value == d.signingStatus)?.label || 'Unknown',
  },
  expiry_status: {
    id: 'expiry_status',
    header: 'Expiry Status',
    accessor: (d) => d.expiryStatus,
    renderCell: (d) => (
      <Row justifyContent="center">
        <ExpiryStatusButton contract={d} />
      </Row>
    ),
  },
  signed_date: {
    id: 'signed_date',
    header: 'Signed Date',
    accessor: (d) => d.signedDate,
    renderLabel: (d) => {
      return d.signedDate;
    },
  },
  season_phase: {
    id: 'season_phase',
    header: 'Season Phase',
    accessor: (d) =>
      seasonPhaseOptions.find((o) => o.value == d.seasonPhase)?.label || 'Unknown',
  },
  start_season: {
    id: 'start_season',
    header: 'Start Season',
    accessor: (d) => d.startSeason,
    renderLabel: (d) => {
      return seasonOptions.find((o) => o.value == d.startSeason)?.label || 'Unknown';
    },
  },
  end_season: {
    id: 'end_season',
    header: 'End Season',
    accessor: (d) => d.endSeason,
    renderLabel: (d) => {
      return (
        expiryYearSeasonOptions.find((o) => o.value == d.endSeason)?.label || 'Unknown'
      );
    },
  },
  platform_season: {
    id: 'platform_season',
    header: 'Platform Season',
    accessor: (d) => d.platformSeason,
    renderCell: (d) => (
      <Row justifyContent="center">
        <PlatformSeasonButton contract={d} />
      </Row>
    ),
  },
  trade_clause_details: {
    id: 'trade_clause_details',
    header: 'Trade Clause',
    accessor: (d, { filters }) => {
      return getContractSeason(d, filters.season?.value)?.clauseDetails;
    },
  },
  was_college_fa: {
    id: 'was_college_fa',
    header: 'College FA',
    accessor: (d) => d.wasCollegeFa,
  },
  was_chl_fa: {
    id: 'was_chl_fa',
    header: 'CHL FA',
    accessor: (d) => d.wasChlFa,
  },
  was_euro_fa: {
    id: 'was_euro_fa',
    header: 'Euro FA',
    accessor: (d) => d.wasEuroFa,
  },
  is_thirty_five_plus: {
    id: 'is_thirty_five_plus',
    header: '35+ Contract',
    accessor: (d) => d.isThirtyFivePlus,
  },
  has_trade_clause: {
    id: 'has_trade_clause',
    header: 'Has Trade Clause',
    accessor: (d) => d.hasTradeClause,
  },
  is_extension: {
    id: 'is_extension',
    header: 'Is Extension',
    accessor: (d) => d.isExtension,
  },
};

const ModalLinkCell = ({ label, modal }) => {
  const { renderModal } = useContext(ModalContext);

  return (
    <Row justifyContent="center">
      <LinkButton onClick={() => renderModal(modal)}>
        <Cell textAlign={'center'}>{label}</Cell>
      </LinkButton>
    </Row>
  );
};

export const infoColumns = { ...playerColumns, ...contractColumns };

export const buildSLStatColumns = (statDataToUse) => {
  if (!statDataToUse) return [];

  const targetPosition = statDataToUse.metastats.league
    ? statDataToUse.metastats.league[0].split.position
    : 'all';
  const norm = targetPosition == 'g' ? 60 : 20;

  const findMetastats = (statDef, row) => {
    const statsRow = {
      ...row,
      league_slug: 'NHL',
    };

    return searchMetastatsSplitsForStat(statDataToUse.metastats, statDef, statsRow, {
      targetGroup: 'league',
      targetPosition,
      matchOn: 'slug_stat_filter_id',
    });
  };

  const highlightCallback = (value, row, statDef) => {
    if (isNaN(value) || value === null) return null;
    let formattedValue = value;
    const [_, metastats] = findMetastats(statDef, row);

    if (!metastats) return null;
    if (statDef.label == 'TOI' || statDef.label == 'GP') return null;

    // TODO: Probably need to find a cleaner way to display totals + norm stats
    if (statDef.counting_stat && statDef.label != 'XPGF' && statDef.label != 'XPGA') {
      formattedValue = value == 0 ? value : value / (row['TOI'] / norm);
    }

    return stddevColor(formattedValue, metastats, statDef.negative, 2, 4);
  };

  const getStatsForPlayer = (playerSlug) =>
    statDataToUse.data.find((d) => d['bf_player_slug'] == playerSlug);

  return (
    statDataToUse.statDefinitions.map((s) => {
      const getStatValue = (d) => {
        const stats = getStatsForPlayer(d.playerSlug || d['bf_player_slug']);
        const value = stats?.[s.label] || 0;

        // TODO: Probably need to find a cleaner way to display totals + norm stats
        if (s.label == 'XPGF' || s.label == 'XPGA') {
          return value == 0 ? value : value / (stats['TOI'] / 20);
        }

        return value;
      };

      const labelLength = s.label.length * 10;
      const minWidth = Math.max(s.is_time_stat || s.is_percentage ? 75 : 50, labelLength);

      return {
        ...s,
        id: s.label,
        header: s.label,
        sortDescFirst: true,
        Header: (
          <TooltipSpan content={<Typography variant="body2">{s.description}</Typography>}>
            <Header noWrap>{s.label}</Header>
          </TooltipSpan>
        ),
        accessor: (d) => getStatValue(d),
        sortType: (a, b) => sortNumericAccessor(a.original, b.original, getStatValue),
        exportValue: (_, accessorValue) =>
          formatBddStat(accessorValue, s, { showPercent: false }),
        minWidth,
        Cell: ({
          cell: {
            row: { original: d },
          },
        }) => {
          const value = formatBddStat(getStatValue(d), s, {
            showPercent: false,
          });

          return (
            <Container
              style={{
                backgroundColor: highlightCallback(
                  getStatValue(d),
                  getStatsForPlayer(d.playerSlug),
                  s
                ),
              }}
            >
              <Cell textAlign="center">{!value.includes('NaN') ? value : ''}</Cell>
            </Container>
          );
        },
      };
    }) || []
  );
};
