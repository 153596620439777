import { useMutation } from "@apollo/client"
import { CREATE_BDD_FEEDBACK, GET_PAGINATED_FEEDBACK, UPDATE_BDD_FEEDBACK } from "apollo/queries/feedback.queries"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts"



export const useCreateFeedback = ({
  onCompleted,
  toastSuccess=true,
  toastFailure=true
}) => {
  const [createFeedback, extras] = useMutation(CREATE_BDD_FEEDBACK, {
    onCompleted: () => {
      toastSuccess && toastInfo('Added new feedback!')
      onCompleted && onCompleted()
    },
    onError: (error) => toastFailure && toastBddApiError(error),
    refetchQueries: [GET_PAGINATED_FEEDBACK]
  })
  return {
    createFeedback,
    ...extras
  }
}

export const useUpdateFeedback = ({
  onCompleted,
  toastSuccess=true,
  toastFailure=true
}) => {
  const [updateFeedback, extras] = useMutation(UPDATE_BDD_FEEDBACK, {
    onCompleted: () => {
      toastSuccess && toastInfo('Feedback updated!')
      onCompleted && onCompleted()
    },
    onError: (error) => toastFailure && toastBddApiError(error),
  });

  return {
    updateFeedback,
    ...extras
  }
}