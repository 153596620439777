import produce from "immer";
import { FileAttachment } from ".";
import { Container, Row } from "../Layout";
import { Typography } from "../Typography";


export const FileAttachments = ({
  files,
  setFiles, // called with (file)
  readOnly
}) => {
  return (
    <Container>
      <Row columnGap={2}>
        <Typography variant="body2">Attached files:</Typography>
        {files.map((file) => (
          <FileAttachment
            key={file.name}
            file={file}
            readOnly={readOnly}
            onFileRemove={(file) => {
              setFiles(
                produce(files, (draft) => {
                  const fileIndexToRemove = draft.findIndex(
                    (draftFile) => draftFile.file.name === file.name
                  );
                  draft.splice(fileIndexToRemove, 1);
                })
              );
            }}
          />
        ))}
      </Row>
    </Container>
  )
}