import { CheckboxGroupFilter, EventFilterContext } from '.';
import { eventNameToMapping } from '../helpers';
import { useContext } from 'react';

export const SuccessiveFlagsFilter = ({ readonly }) => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name'].val;
  const options = eventNames.flatMap((e) => eventNameToMapping[e]?.successiveFlags || []);

  if (options.length <= 1) return null;

  return (
    <CheckboxGroupFilter
      col={'successive_flags'}
      id={'successive_flags'}
      label="Successive Flags"
      options={options}
      readonly={readonly}
    />
  );
};
