import { StatCellVideoLinks } from 'components/SLStats';
import { gameHighlightCallback, searchMetastatsSplitsForStat } from 'helpers/stats';
import { stddevColor } from 'helpers/tables';

export const cellHoverCallback = (label, row, statDef, forceClose) => {
  return (
    <StatCellVideoLinks
      statDefinition={statDef}
      statRow={row}
      onClick={() => {
        forceClose(true);
      }}
      context={row.statContext}
    />
  );
};

export const highlightCallback = (label, row, statDef) => {
  const {
    filters,
    data: { extras, format },
  } = row.statContext;

  const value = row[label];
  if (statDef.slug === 'gamesPlayed') return null;
  if (isNaN(value) || value === null) return null;
  const [_, metastats] = findMetastats(extras.metastats, filters, statDef, row);

  if (!metastats) return null;

  if (filters.norm != 20) {
    const toiStatDef = format.stat_definitions.find((s) => s.slug === 'toi');
    return gameHighlightCallback(metastats, label, row, statDef, toiStatDef);
  }

  return stddevColor(value, metastats, statDef.negative);
};

const findMetastats = (metastats, filters, statDef, row) => {
  const targetGroup = 'league';

  return searchMetastatsSplitsForStat(metastats, statDef, row, {
    targetGroup,
    targetPosition: row.position.toLowerCase(),
    matchOn: filters.norm === '20' ? 'unique_id' : 'stat_filter_id', // metastats are always applied at the per 20 level
  });
};
