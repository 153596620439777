import { useQuery } from '@apollo/client';
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { VideoContext } from 'components/bdd/Video';
import { EventBank, EventControls } from 'components/bdd/Video/Filtering';
import {
  GameTypeFilter,
  StrengthFilter,
  OppTeamFilter,
  PeriodFilter,
  SeasonsFilter,
  StatDefinitionFilter,
  TeamGameFilter,
  TeamPlayerFilter,
  FilterSummary,
  PlayerLSGFilter,
  PlayerGameFilter,
  OnIcePlayerFilter,
} from 'components/bdd/Video/Filtering/components';
import React, { useContext, useState } from 'react';

export const VideoClipFilters = ({ renderPlayerFilters }) => {
  const { slPlayer, sourceFilters } = useContext(VideoContext);

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_CARD, {
      variables: {
        slLeaguePlayerId:
          slPlayer && `${sourceFilters?.['league_slug']?.val}_${slPlayer.playerId}`,
      },
      skip: !slPlayer,
    })
  );

  const bddPlayer = data?.bddPlayer;
  let filters = {};

  if (!!slPlayer) {
    if (!bddPlayer) return placeholder;

    const leagueSlug = sourceFilters?.['league_slug']?.val;

    filters = (
      <Row alignItems="end" columnGap={2} rowGap={2} flexWrap>
        <Container>
          <Row columnGap={2} rowGap={2} flexWrap>
            {renderPlayerFilters ? (
              renderPlayerFilters(bddPlayer)
            ) : (
              <>
                <PlayerLSGFilter slug={bddPlayer.slug} leagueSlug={leagueSlug} />
                <PlayerGameFilter slug={bddPlayer.slug} />
                <OnIcePlayerFilter slug={bddPlayer.slug} />
                <OnIcePlayerFilter slug={bddPlayer.slug} isTeammate={false} />
                <PeriodFilter />
                <StrengthFilter />
              </>
            )}
          </Row>
        </Container>
        <StatDefinitionFilter slPlayer={slPlayer} ignorePositionChange />
      </Row>
    );
  } else if ('team_slug' in sourceFilters) {
    filters = (
      <Row columnGap={2} rowGap={2} alignItems="end" flexWrap>
        <SeasonsFilter selectProps={{ isMulti: false }} />
        <GameTypeFilter />
        <TeamGameFilter />
        <OppTeamFilter />
        <TeamPlayerFilter />
        <TeamPlayerFilter target="goalies" />
        <PeriodFilter />
        <StrengthFilter />
        <StatDefinitionFilter ignorePositionChange />
      </Row>
    );
  }

  return (
    <Container width="100%">
      <Column gap={3}>
        {filters}
        <Divider />
        {!!filters && <FilterSummary />}
      </Column>
    </Container>
  );
};
