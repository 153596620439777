import { NetworkStatus, useQuery } from '@apollo/client';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { BDDLoader } from '../bddloader';
import { LinkButton } from '../Button';
import { useButtonGroup } from '../ButtonGroup';
import { useDropdown } from '../Dropdown';
import Icon from '../Icon';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import VisibilityChangeWrapper from '../VisibilityChangeWrapper';

export const MAX_LIMIT = 50000;

export const usePaginated = ({
  query,
  key,
  defaultLimit,
  skip,
  variables: variablesArg,
  itemNames = 'records',
  defaultOffset = 0,
  limitOptions, // if supplied, overrides defaults.  Supplied as list of ints (e.g. [20, 50, 100])
  queryParams = {},
  mergeExtraData,
  onDataFetched,
}) => {
  // we want this component to control limit/offset
  // that way apollo takes advantage of the cache when paging back
  // also it allows pagination controls to change the page size
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(defaultOffset);

  // Reset the offset to its default value if variables have changed
  const [queriedVariables, setQueriedVariables] = useState(variablesArg);
  useEffect(() => {
    setQueriedVariables(variablesArg);
    setOffset(defaultOffset);
  }, [JSON.stringify(variablesArg)]);

  const variablesHaveChanged =
    JSON.stringify(variablesArg) != JSON.stringify(queriedVariables);
  const offsetToUse = variablesHaveChanged ? defaultOffset : offset;
  const variables = { ...variablesArg, limit, offset: offsetToUse };

  const {
    data,
    previousData,
    placeholder,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore: _fetchMore,
  } = usePlaceholder(
    useQuery(query, {
      variables,
      skip,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        onDataFetched && onDataFetched(data);
      },
      ...queryParams,
    })
  );

  const fetchMore = (offset) => {
    if (!data || !data[key].pageInfo.hasNextPage) return;

    _fetchMore({
      variables: {
        offset: offset || data[key].pageInfo.endIndex,
      },
      updateQuery: (existingData, { fetchMoreResult }) => {
        onDataFetched && onDataFetched(fetchMoreResult);
        const existing = existingData[key];
        const incoming = fetchMoreResult[key];

        return {
          [key]: {
            pageInfo: {
              ...incoming.pageInfo,
              hasPreviousPage: !!existing.pageInfo
                ? existing.pageInfo.hasPreviousPage
                : incoming.pageInfo.hasPreviousPage,
              startIndex: !!existing.pageInfo ? existing.pageInfo.startIndex : 0,
            },
            data: [...existing.data, ...incoming.data],
            extraData: mergeExtraData
              ? mergeExtraData(existing.extraData, incoming.extraData)
              : [...existing.extraData, ...incoming.extraData],
          },
        };
      },
    });
  };

  const pageInfo = data ? data[key].pageInfo : previousData && previousData[key].pageInfo;

  useEffect(() => {
    if (!!pageInfo?.offset && pageInfo.offset !== offset) {
      setOffset(() => pageInfo.offset);
    }
  }, [pageInfo]);

  const fetchNext = () => {
    if (!data || !pageInfo.hasNextPage) return;
    // when fetching ahead, we use the "refetch" helper,
    // which will persist existing data (pageInfo) while new data is fetched
    // NOTE: this isn't ideal if you toggle back and forth
    // it will re-fetch the data even after its been cached
    refetch({ offset: pageInfo.endIndex });
  };

  const fetchPrev = () => {
    if (!data || !pageInfo.hasPreviousPage) return;
    // when fetching prev, we just change the offset state var
    // we expect data to be cached so no need to load
    setOffset(() => pageInfo.startIndex - pageInfo.limit);
  };

  const handleLimitChange = (limit) => {
    refetch({ offset: 0, limit });
    setOffset(0);
    setLimit(limit);
  };

  const loadingWrapper = pageInfo && pageInfo.hasNextPage && (
    <VisibilityChangeWrapper callback={fetchMore} defaultOnScreen={false}>
      <BDDLoader variant="squares" />
    </VisibilityChangeWrapper>
  );

  const pageControls = (
    <PagePaginationControls
      pageInfo={pageInfo}
      fetchNext={fetchNext}
      fetchPrev={fetchPrev}
      itemNames={itemNames}
      loading={loading || networkStatus === NetworkStatus.refetch}
      limit={limit}
      onLimitChange={handleLimitChange}
      limitOptions={limitOptions}
    />
  );

  const paginatedData = data && data[key].data;

  return {
    data,
    limit,
    offset,
    previousData,
    loading,
    error,
    placeholder,
    paginatedData,
    loadingWrapper,
    pageControls,
    pageInfo,
  };
};

const PagePaginationControls = ({
  pageInfo,
  loading,
  fetchNext,
  fetchPrev,
  itemNames,
  onLimitChange,
  limitOptions: limitOptionsArg,
}) => {
  if (!pageInfo) {
    return <Typography variant="stat">Loading...</Typography>;
  }

  const endIndex = pageInfo.endIndex;
  const limit = pageInfo.limit;
  const currentPage = endIndex / limit;

  const defaultLimitOptions = [
    {
      value: 100,
      caption: '100',
    },
    {
      value: 200,
      caption: '200',
    },
    {
      value: 1000,
      caption: '1000',
    },
  ];
  var limitOptions = !!limitOptionsArg
    ? limitOptionsArg.map((o) => ({ value: o, caption: o }))
    : defaultLimitOptions;
  if (!limitOptions.find((l) => l.value == limit)) {
    limitOptions = [{ value: limit, caption: limit }].concat(limitOptions);
  }

  if (pageInfo.totalRows / limit <= 1 && limitOptions.length == 0) {
    return null;
  }

  const { buttonGroup } = useButtonGroup({
    options: limitOptions,
    initialSelectedValue: limit,
    onClick: (option) => {
      onLimitChange(option.value);
    },
    typographyVariant: 'stat',
  });

  return (
    <Container padding={2}>
      <Row columnGap={3}>
        <Typography variant="stat">
          {pageInfo.totalRows} {itemNames}
        </Typography>
        <Typography variant="stat">
          Page {currentPage} of {Math.ceil(pageInfo.totalRows / limit)}
        </Typography>
        {buttonGroup}
        <Row columnGap={1}>
          <Button
            variant="outline-dark"
            size="sm"
            disabled={!pageInfo.hasPreviousPage || loading}
            onClick={() => fetchPrev()}
          >
            <Icon icon={<ArrowLeft />} />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            disabled={!pageInfo.hasNextPage || loading}
            onClick={() => fetchNext()}
          >
            <Icon icon={<ArrowRight />} />
          </Button>
        </Row>
        {loading && (
          <Typography variant="stat">
            <em>Loading...</em>
          </Typography>
        )}
      </Row>
    </Container>
  );
};
