import React, { useRef, useState, useContext, useEffect } from 'react';
import { theme } from 'constants/theme';
import { Container, Row } from 'components/bdd/Layout';
import { IconButton } from 'components/bdd/Button';
import { Fullscreen, FullscreenExit, LayoutSidebarReverse } from 'react-bootstrap-icons';
import { WindowContext } from 'components/bdd/context';
import styled from 'styled-components';
import { Video, VideoClipControls, VideoClipList, VideoClipProvider } from '../Player';
import { ModalContext } from 'components/bdd/Modal';

const OverlayContainer = styled.div({
  backgroundColor: theme.colors.light.background,
  position: 'absolute',
  height: '100%',
  right: 0,
  width: 350,
  opacity: 0.95,
});

export const VideoController = ({
  clips,
  firstClipIdx,
  renderClipList,
  playbackMode,
  autoPlay,
  loading,
  playlist,
}) => {
  const videoControllerRef = useRef(null);
  const { modalRef, hideModal, isFullscreen, defaultFullscreen, setSize } =
    useContext(ModalContext);
  const { width, height } = useContext(WindowContext);

  const useOverlay = width <= theme.breakpoints.md || height <= theme.breakpoints.xs;
  const [compactSidebar, setCompactSidebar] = useState(useOverlay);
  const [modalHeight, setModalHeight] = useState(modalRef?.current?.clientHeight);

  // Weird React error where the modalContext height is delayed by one render on window resize.
  // TODO: useLayoutEffect might solve this...
  useEffect(() => {
    setTimeout(() => {
      setModalHeight(modalRef?.current?.clientHeight);
    }, 100);
  });

  const VIDEO_CLIP_CONTROLS_HEIGHT = useOverlay ? 0 : 150;

  const EVENTS_CONTAINER_WIDTH = useOverlay
    ? 0
    : width >= theme.breakpoints.xxl
    ? 510
    : 400;

  const compactSidebarToggle = (
    <IconButton
      tooltip={compactSidebar ? 'Show clips' : 'Hide clips'}
      icon={<LayoutSidebarReverse />}
      color={
        compactSidebar ? theme.colors.teams.bos.primary : theme.colors.light.text.primary
      }
      hoverColor={theme.colors.teams.bos.primary}
      onClick={() => setCompactSidebar(!compactSidebar)}
    />
  );

  const fullscreenToggle = (
    <IconButton
      tooltip={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
      icon={isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      color={
        isFullscreen
          ? theme.colors.teams.bos.primary
          : compactSidebar
          ? theme.colors.dark.text.primary
          : theme.colors.light.text.primary
      }
      hoverColor={theme.colors.teams.bos.primary}
      onClick={() => {
        if (isFullscreen && defaultFullscreen && hideModal) {
          hideModal();
        } else {
          setSize(isFullscreen ? 'xxl' : 'fullscreen');

          if (isFullscreen && document.fullscreenElement) {
            document.exitFullscreen();
          } else if (!isFullscreen && !document.fullscreenElement) {
            videoControllerRef?.current.requestFullscreen();
          }
        }
      }}
    />
  );

  const videoContainerRef = useRef(null);
  const containerHeight = `100%`;

  return (
    <VideoClipProvider
      clips={clips}
      firstClipIdx={firstClipIdx}
      playlist={playlist}
      videoPlayerProps={{
        playbackMode,
        autoPlay,
      }}
      loading={loading}
    >
      <Container
        height="100%"
        ref={videoControllerRef}
        backgroundColor={theme.colors.dark.background}
      >
        <Row alignItems="stretch">
          <Container
            style={{
              width: `calc(100% - ${!compactSidebar ? EVENTS_CONTAINER_WIDTH : 0}px)`,
            }}
            alignSelf="start"
            ref={videoContainerRef}
          >
            <Video
              height={
                modalHeight
                  ? useOverlay
                    ? modalHeight
                    : modalHeight - VIDEO_CLIP_CONTROLS_HEIGHT
                  : '100%'
              }
            />
          </Container>
          <Container
            style={{
              position: 'absolute',
              bottom: 0,
              width: `calc(100% - ${!compactSidebar ? EVENTS_CONTAINER_WIDTH : 0}px)`,
            }}
          >
            <VideoClipControls />
          </Container>
          {compactSidebar ? (
            <Container
              paddingLeft={3}
              paddingRight={3}
              paddingTop={3}
              style={{ position: 'absolute', right: 0, top: 0 }}
            >
              <Row columnGap={1}>
                {fullscreenToggle}
                {compactSidebarToggle}
              </Row>
            </Container>
          ) : (
            <Container
              backgroundColor={theme.colors.light.background}
              as={useOverlay ? OverlayContainer : Container}
              paddingTop={2}
              paddingLeft={2}
              minWidth={EVENTS_CONTAINER_WIDTH}
              flexBasis={EVENTS_CONTAINER_WIDTH}
              maxHeight={containerHeight}
            >
              <VideoClipList
                firstClipIdx={firstClipIdx}
                renderClipList={renderClipList}
                actionButtons={
                  <Row columnGap={1}>
                    {fullscreenToggle}
                    {compactSidebarToggle}
                  </Row>
                }
              />
            </Container>
          )}
        </Row>
      </Container>
    </VideoClipProvider>
  );
};
