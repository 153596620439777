export const NUM_TEAMS = 32;

export const nhlSlugToLongname = {
  'nhl.ana': 'Anaheim Ducks',
  'nhl.ari': 'Arizona Coyotes',
  'nhl.bos': 'Boston Bruins',
  'nhl.buf': 'Buffalo Sabres',
  'nhl.car': 'Carolina Hurricanes',
  'nhl.cbj': 'Columbus Blue Jackets',
  'nhl.cgy': 'Calgary Flames',
  'nhl.chi': 'Chicago Blackhawks',
  'nhl.col': 'Colorado Avalanche',
  'nhl.dal': 'Dallas Stars',
  'nhl.det': 'Detroit Red Wings',
  'nhl.edm': 'Edmonton Oilers',
  'nhl.fla': 'Florida Panthers',
  'nhl.lak': 'Los Angeles Kings',
  'nhl.min': 'Minnesota Wild',
  'nhl.mtl': 'Montreal Canadiens',
  'nhl.njd': 'New Jersey Devils',
  'nhl.nsh': 'Nashville Predators',
  'nhl.nyi': 'New York Islanders',
  'nhl.nyr': 'New York Rangers',
  'nhl.ott': 'Ottawa Senators',
  'nhl.phi': 'Philadelphia Flyers',
  'nhl.pit': 'Pittsburgh Penguins',
  'nhl.sea': 'Seattle Kraken',
  'nhl.sjs': 'San Jose Sharks',
  'nhl.stl': 'St. Louis Blues',
  'nhl.tbl': 'Tampa Bay Lightning',
  'nhl.tor': 'Toronto Maple Leafs',
  'nhl.wsh': 'Washington Capitals',
  'nhl.wpg': 'Winnipeg Jets',
  'nhl.van': 'Vancouver Canucks',
  'nhl.vgk': 'Vegas Golden Knights',
  'nhl.uth': "Utah Hockey Club"
};

export const PLAYER_POSITIONS = {
  GOALIE: 'Goalie',
  CENTER: 'Center',
  LEFT_WING: 'Left Wing',
  FORWARD: 'Forward',
  RIGHT_WING: 'Right Wing',
  DEFENCE: 'Defence',
  CENTER_RIGHT_WING: 'Center/R.Wing',
  WING: 'wing',
  CENTER_LEFT_WING: 'Center/L.Wing',
  LEFT_DEFENCE: 'Left Defence',
  RIGHT_DEFENCE: 'Right Defence',
};

export const PLAYER_HANDEDNESS = {
  LEFT: 'L',
  RIGHT: 'R',
};
