import produce from 'immer';
import { createContext, useContext, useEffect, useRef } from 'react';
import { create, useStore } from 'zustand';
import { useFilterContext } from '../context';

const FilterGroupContext = createContext();
const createStore = () =>
  create((set) => ({
    ids: [],
    tags: {},
    actions: {
      registerFilterTag: (id, tag) => {
        set(
          produce((state) => {
            state.tags[id] = tag;
          })
        );
      },
    },
  }));

export const FilterGroupContextProvider = ({ children }) => {
  const store = useRef(createStore()).current;

  return (
    <FilterGroupContext.Provider value={store}>{children}</FilterGroupContext.Provider>
  );
};

export const useFilterGroupContext = (selector) => {
  const context = useContext(FilterGroupContext);
  return context && useStore(context, selector);
};

export const useRegisterFilterTag = (id, filter, filterListener) => {
  const { registerFilterTag: registerParentFilterTag } =
    useFilterContext((state) => state.actions) || {};

  const { registerFilterTag } = useFilterGroupContext((state) => state.actions) || {};

  useEffect(() => {
    registerFilterTag && registerFilterTag(id, filter);
    registerParentFilterTag(id, filter);
  }, [filterListener || filter]);
};
