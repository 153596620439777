import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import PlayerWarBoxes from 'components/PlayersV2/PlayerWar/playerwarboxes';
import useOnScreen from 'components/bdd/hooks/useonscreen';
import { OverflowEllipsisCol } from 'components/bdd';
import { WindowContext } from 'components/bdd/context';

const WarContainer = styled.div({
  ...theme.typography.body2,
  display: 'flex',
  gap: theme.spacing[3],
});

export const WarData = ({ bddPlayer }) => {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref, true);
  const [showWar, setShowWar] = useState(false);
  const { width } = useContext(WindowContext);

  useEffect(() => {
    if (isVisible && !showWar) setShowWar(true);
  }, [isVisible]);

  return (
    <WarContainer ref={ref}>
      <OverflowEllipsisCol>
        {bddPlayer?.slUid &&
          showWar &&
          ((
            <PlayerWarBoxes
              slUid={bddPlayer.slUid}
              showHeader={false}
              showSplitOptions={false}
              statTypes={[
                'offImpactPct55',
                'defImpactPct55',
                'shotImpactPct55',
              ]}
              fontSize={width > theme.breakpoints.xs ? '12px' : '10px'}
            />
          ) || <em>No impact data found</em>)}
      </OverflowEllipsisCol>
    </WarContainer>
  );
};
