import React from 'react';
import { useField } from 'formik';
import BDDDatePicker from '../bdddatepicker';

export const DatePickerField = ({ placeholderText, isClearable, ...rest }) => {
  const [_, meta, helpers] = useField(rest.name);
  let { value } = meta;
  const { setValue } = helpers;

  if (typeof value == 'string') {
    value = new Date(value);
  }

  return (
    <BDDDatePicker
      placeholderText={placeholderText}
      selected={value}
      onChange={(date) => setValue(date)}
      styles={{ height: 50, minWidth: 100 }}
      isClearable={isClearable}
      {...rest}
    />
  );
};
