import React, { useContext, useEffect, useState } from 'react';
import { theme } from 'constants';
import { PlayFill } from 'react-bootstrap-icons';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { Button } from 'react-bootstrap';
import Icon from 'components/bdd/Icon';
import { VideoContext } from 'components/bdd/Video';
import { VideoSelectContext } from '../VideoSelect';
import { ModalContext } from 'components/bdd/Modal';
import { EventVideoPlayer, ShiftVideoPlayer, VideoController } from '../components';
import { PlaylistVideoPlayer } from 'components/Video/Playlists';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';

export const EventControls = ({}) => {
  const { allEvents, pageControls, loading, eventType, sort, setSort } =
    useContext(VideoContext);

  const { selectedEvents, setSelectedEvents } = useContext(VideoSelectContext);
  const { renderModal, modalContext, setModalContext } = useContext(ModalContext);

  const displayModal = ({ playSelectedEvents, autoPlay = true }) => {
    renderModal({
      body:
        eventType == 'events' ? (
          <EventVideoPlayer autoPlay={autoPlay} playSelectedEvents={playSelectedEvents} />
        ) : eventType == 'shifts' ? (
          <ShiftVideoPlayer autoPlay={autoPlay} playSelectedEvents={playSelectedEvents} />
        ) : (
          <PlaylistVideoPlayer
            autoPlay={autoPlay}
            playSelectedEvents={playSelectedEvents}
          />
        ),
      size: 'xxl',
    });

    setModalContext({
      ...modalContext,
      showModal: playSelectedEvents ? 'playSelectedEvents' : 'playAllEvents',
    });
  };

  useEffect(() => {
    if (modalContext?.showModal) {
      displayModal({
        playSelectedEvents: modalContext.showModal == 'playSelectedEvents',
        autoPlay: false,
      });
    }
  }, [modalContext?.showModal]);

  return (
    <Container>
      <Row columnGap={2} flexWrap>
        <Button
          disabled={!!loading}
          variant="outline-dark"
          onClick={() => displayModal({ playSelectedEvents: false })}
        >
          <Typography variant="body1">
            <Row columnGap={2}>
              <Icon icon={<PlayFill />} />
              <div>Play All</div>
            </Row>
          </Typography>
        </Button>
        {selectedEvents?.length > 0 && (
          <>
            <Button
              variant="warning"
              disabled={selectedEvents.length == 0}
              onClick={() => displayModal({ playSelectedEvents: true })}
            >
              <Typography variant="body1">
                <Row columnGap={2}>
                  <Icon icon={<PlayFill />} />
                  <div>Play {selectedEvents.length} Selected</div>
                </Row>
              </Typography>
            </Button>
            <Button
              variant="danger"
              disabled={selectedEvents.length == 0}
              onClick={() => setSelectedEvents([])}
            >
              <Typography variant="body1">Clear Selected</Typography>
            </Button>
          </>
        )}
        {allEvents?.length == 0 && (
          <Typography variant="body1">No video for events found</Typography>
        )}
        {pageControls}
        {allEvents?.length > 0 && (
          <Container>
            <StyledBDDSelect
              variant="outlined"
              selectedValue={JSON.stringify(sort)}
              options={[
                {
                  value: JSON.stringify([{ id: 'game_uid' }, { id: 'game_time' }]),
                  label: 'Sort by game and time',
                },
                ...(allEvents.find((e) => e.eventName == 'shot')
                  ? [
                      {
                        value: JSON.stringify([{ id: 'value_2', desc: true }]),
                        label: 'Sort by XPG desc',
                      },
                    ]
                  : []),
                ,
              ]}
              selectProps={{
                placeholder: 'Sort by...',
              }}
              onChange={(n, v) => {
                setSort(JSON.parse(v));
              }}
            />
          </Container>
        )}
      </Row>
    </Container>
  );
};
