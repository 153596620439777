import { Container } from 'components/bdd/Layout';
import { SelectFilter } from '.';

export const PeriodFilter = ({}) => {
  const options = [
    {
      value: null,
      label: 'All Periods',
    },
    {
      value: 1,
      label: 'First Period',
    },
    {
      value: 2,
      label: 'Second Period',
    },
    {
      value: 3,
      label: 'Third Period',
    },
    {
      value: 4,
      label: 'Overtime',
    },
  ];

  return (
    <Container minWidth={125}>
      <SelectFilter col={'period'} options={options} label={'Period'} />
    </Container>
  );
};
