import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';

export const CFPlayerContractFragment = gql`
  fragment cfPlayerContractFragment on CFPlayerContracts {
    id
    playerSlug
    signingTeamSlug
    signedDate
    isActive
    isFuture
    startSeason
    endSeason
    numYears
    value
    capHit
    aav
    aavSum
    aavStr
    remainingTerm
    signingBonus
    performanceBonus
    baseSalary
    totalSalary
    minorSalary
    signingStatus
    signingAge
    expiryStatus
    expiryStatusDetails
    expirationAge
    contractType
    hasTradeClause
    isExtension
    isThirtyFivePlus
    isBoughtOut
    isTerminated
    isEarlyRetirement
    isOfferSheet
    isArbitrationEligible
    hasEuropeanClause
    expiryStatusDetails
    tradeClauseDetails
    performanceBonusDetails
    arbitrationDetails
    entryLevelSlideYears
    platformSeason
    overridePlatformSeason
    platformStats
    expiryQualifyingOffer
    source
    note
    seasonPhase
    platformLeagues
    epStats
    slStats
    platformIsolatedImpact
    warValueOfCaphit
    wasCollegeFa
    wasEuroFa
    wasChlFa
    isThirtyFivePlus
    signingTeam {
      slug
      cfId
      name
      abbreviation
      nhlid
    }
    contractSeasons {
      season
      contractYear
      capHit
      capHitStr
      value
      performanceBonus
      signingBonus
      baseSalary
      totalSalary
      minorSalary
      guaranteedSalary
      ntc
      nmc
      clauseDetails
      capPct
      isTwoWay
    }
  }
`;

export const BFPlayerContractFragment = gql`
  fragment bfPlayerContractFragment on BFPlayerContracts {
    id
    playerSlug
    signingTeamSlug
    signedDate
    isActive
    isFuture
    startSeason
    endSeason
    numYears
    value
    capHit
    aav
    aavSum
    aavStr
    remainingTerm
    signingBonus
    performanceBonus
    baseSalary
    totalSalary
    minorSalary
    signingStatus
    signingAge
    expiryStatus
    expirationAge
    contractType
    hasTradeClause
    isExtension
    isThirtyFivePlus
    isBoughtOut
    isTerminated
    isEarlyRetirement
    isOfferSheet
    isArbitrationEligible
    hasEuropeanClause
    expiryStatusDetails
    tradeClauseDetails
    performanceBonusDetails
    arbitrationDetails
    entryLevelSlideYears
    platformSeason
    overridePlatformSeason
    overrideExpiryStatus
    overrideExpiryStatusDetails
    platformStats
    expiryQualifyingOffer
    source
    note
    seasonPhase
    platformLeagues
    epStats
    slStats
    platformIsolatedImpact
    warValueOfCaphit
    wasCollegeFa
    wasEuroFa
    wasChlFa
    wasNonQo
    wasGroup6
    was102c
    signingTeam {
      slug
      name
      abbreviation
      nhlid
    }
    contractSeasons {
      season
      contractYear
      capHit
      capHitStr
      value
      performanceBonus
      signingBonus
      baseSalary
      totalSalary
      minorSalary
      guaranteedSalary
      ntc
      nmc
      clauseDetails
      capPct
      isTwoWay
    }
  }
`;

export const NHLContractFragment = gql`
  fragment nhlContractFragment on NHLContract {
    nhlId
    dateSigned
    name
    signingAge
    expirationAge
    isActive
    isFuture
    freeAgentYear
    startSeason
    seasonFromTo
    signingContractGroup
    expirationContractGroup
    expirationSeason
    remainingTerm
    signedWithTeam
    signedWithTeamShort
    averagedAmount
    averagedAmountStr
    contractYears
    contractClauses
    contractComments
    hasNmcOrNtc
  }
`;

export const GET_NHL_SALARY_CAP = gql`
  query GetNHLSalaryCap($season: Int) {
    nhlSalaryCap(season: $season) {
      season
      salaryCapCeiling
      salaryCapFloor
      minimumPlayerSalary
      buriedContractMaximum
      extras
    }
  }
`;

export const LIST_NHL_CONTRACTS = gql`
  query ListNHLContracts($limit: Int, $offset: Int, $sort: [BDDSortV2!]) {
    listNhlContracts(limit: $limit, offset: $offset, sort: $sort) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        nhlContract {
          ...nhlContractFragment
        }
        stats
      }
    }
  }
  ${NHLContractFragment}
  ${PageInfoFragment}
`;
