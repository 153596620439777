import { GET_ALL_REPORTS } from 'apollo/queries/scouting.queries';
import FilterBank from './FilterBank';
import { useReportSearchParams } from './useReportsSearchParams';
import { usePaginated } from 'components/bdd/Paginated';

export default function useReports({
  limit = 10,
  scoutingObjectiveSlug,
  defaultFilters = {},
  useSearchParams = true,
  hideDateRangeFilter,
}) {
  const {
    filters,
    setFilters,
    setSearchParams,
    parsedFilters,
    currRequestFilters,
    setCurrRequestFilters,
  } = useReportSearchParams(defaultFilters, !useSearchParams);

  const refetchReports = (freshFilters) => {
    console.log('here????');
    queryResponse.refetch({
      limit,
      filters: freshFilters,
      scoutingObjectiveSlug,
    });
  };

  const paginatedResponse = usePaginated({
    query: GET_ALL_REPORTS,
    key: 'allReports',
    defaultLimit: 10,
    defaultOffset: 0,
    variables: {
      filters: currRequestFilters,
      scoutingObjectiveSlug,
    },
    mergeExtraData: (existing, incoming) => {
      return {
        skaters: [...(existing.skaters ?? []), ...(incoming.skaters ?? [])],
        goalies: [...(existing.goalies ?? []), ...(incoming.goalies ?? [])],
      };
    },
  });

  return {
    ...paginatedResponse,
    filterBankComponent: (
      <FilterBank
        scoutingObjectiveSlug={scoutingObjectiveSlug}
        filters={filters}
        setFilters={setFilters}
        requiresRefetch={parsedFilters !== currRequestFilters}
        loading={paginatedResponse.loading}
        hideDateRangeFilter={hideDateRangeFilter}
        handleFetch={() => {
          setCurrRequestFilters(parsedFilters);
          setSearchParams();
          refetchReports(parsedFilters);
        }}
      />
    ),
    filters,
    currRequestFilters,
    parsedFilters,
  };
}
