import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import BDDDatePicker from '../bdddatepicker';
import { formatDate, formatDateGQL } from 'helpers/helpers';
import React from 'react';
import { IconButton } from '../Button';
import { X } from 'react-bootstrap-icons';
import { theme } from 'constants';

export const DateFilter = React.memo(({ id, label, isClearable }) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  useRegisterFilterTag(
    id,
    filter && <FilterTag name={label} label={formatDate(filter.value)} />
  );

  return (
    <Container>
      <Row justifyContent="space-between">
        <Typography variant="label">{label}</Typography>
        {filter?.value != undefined && filter?.value != null && isClearable && (
          <IconButton
            icon={<X />}
            padding={0}
            hoverColor={theme.colors.light.text.secondary}
            onClick={() => removeFilter(id)}
          />
        )}
      </Row>
      <BDDDatePicker
        compact
        showMonthDropdown
        showYearDropdown
        zIndex={20}
        value={filter?.value ? formatDate(filter.value) : 'Select Date...'}
        onChange={(date) => {
          setFilters(id, { id, type: 'date', value: date });
        }}
      />
    </Container>
  );
});
