import BDDDropdownMenu from "components/bdd/bdddropdownmenu";
import { Typography } from "components/bdd/Typography";
import PlayerHover from "components/PlayersV2/Hover/playerhover";
import { useState } from "react"


export default function DCPlayerSmall ({
  playerSlug,
  bddPlayer,
  dropdownOptions,
  isDragging
}) {
  const [show, setShow] = useState(false);
  // dropdown menu must be controlled from here so we can disabled playerhover
  return <>
    <BDDDropdownMenu
      id={`${playerSlug}-dropdown`}
      show={show}
      onToggle={() => {
        setShow(!show)
      }}
      trigger={
        <Typography variant='body2' textAlign='center' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
          <PlayerHover playerSlug={playerSlug} delay={{ show: 500, hide: 200 }} disabled={show || isDragging}>
            {bddPlayer.firstname} {bddPlayer.lastname}
          </PlayerHover>
        </Typography>
      }
      items={dropdownOptions}
    />
  </>
}