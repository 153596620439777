import * as React from "react"
import { Helmet } from "react-helmet"

export default function({ title, backgroundColor='#fefefe' }) {
    return (<Helmet>
        <title>{title || 'Bruins Data Den'}</title>

        <meta name="robots" content="noindex"/>
        <meta name="googlebot" content="noindex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        {/* Favicon */}
        <link rel="icon" href="https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Boston_Bruins.svg/220px-Boston_Bruins.svg.png"/>
        
        <style type="text/css">{`
            html { 
                height: 100%;
                width: 100%;
            }
            body { 
                height: 100%;
                width: 100%;
                margin: 0;
                background-color: ${backgroundColor}; 
            }
        `}</style>
    </Helmet>)    
}
