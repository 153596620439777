import { permissions } from 'constants';
import {
  ChatDots,
  ClipboardCheck,
  Envelope,
  FileEarmark,
  FilePerson,
  HeartPulse,
  Telephone,
} from 'react-bootstrap-icons';

export const mediumTypeToLabel = {
  EMAIL: 'E-mail',
  PHONE: 'Phone',
  TEXT: 'Text',
  INPERSON: 'In Person',
  VIDEO: 'Sent Video',
};

export const mediumTypeOptions = Object.keys(mediumTypeToLabel).map((key) => ({
  value: key,
  label: mediumTypeToLabel[key],
}));

export const developmentStatusOptions = [
  {
    value: null,
    label: 'N/A',
  },
  {
    value: 'FALLING_BEHIND',
    label: 'Falling Behind',
  },
  {
    value: 'EXCEEDING_EXPECTATIONS',
    label: 'Exceeding Expectations',
  },
  {
    value: 'ON_TRACK',
    label: 'On track',
  },
];

export const developmentStatusToLabel = (status) =>
  developmentStatusOptions.find((s) => s.value == status)?.label || 'N/A';

export const trendOptions = [
  {
    value: null,
    label: 'N/A',
  },
  {
    value: 'ASCENDING',
    label: 'Ascending',
  },
  {
    value: 'AT_PEAK',
    label: 'At peak',
  },
  {
    value: 'DESCENDING',
    label: 'Descending',
  },
];

export const trendToLabel = (status) =>
  trendOptions.find((s) => s.value == status)?.label || 'N/A';

// TODO: Should be generated based on the user
export const permissionOptions = [
  {
    value: null,
    label: 'BDD user',
  },
  {
    value: permissions.viewPlayerDevelopment,
    label: 'Player Development Portal',
  },
  {
    value: permissions.managePlayerDevelopment,
    label: 'Player Development Staff',
  },
  {
    value: 'private',
    label: 'Private',
  },
];

export const interestOptions = [
  {
    value: 'N/A',
    label: 'N/A',
  },
  {
    value: null,
    label: 'None',
  },
  {
    value: 'LOW',
    label: 'Low',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'HIGH',
    label: 'High',
  },
];

export const interestToLabel = (status) =>
  interestOptions.find((s) => s.value == status)?.label || 'N/A';

export const getPermissions = (requiresPermission) => ({
  requiresPermission:
    requiresPermission != 'private' ? requiresPermission : null,
  isPrivate: requiresPermission == 'private',
});

export const getPermissionValue = (note) =>
  note?.isPrivate ? 'private' : note?.requiresPermission;

export const noteTypeToIcon = (note) => {
  switch (note.noteType) {
    case 'CHECK-IN':
      switch (JSON.parse(note.note).medium) {
        case 'EMAIL':
          return <Envelope />;
        case 'PHONE':
          return <Telephone />;
        case 'TEXT':
        default:
          return <ChatDots />;
      }
    case 'ASSESSMENT':
    case 'ANALYTIC:':
      return <ClipboardCheck />;
    case 'MEDICAL':
      return <HeartPulse />;
    case 'PERSONAL':
      return <FilePerson />;
    case 'AVAILABILITY':
      return <FileEarmark />;
    default:
      return null;
  }
};

export const noteTypeToLabel = {
  GENERAL: 'General',
  PERSONAL: 'Personal',
  ASSESSMENT: 'Assessment',
  ANALYTIC: 'Analytic',
  'CHECK-IN': 'Check-in',
  MEDICAL: 'Medical',
  AVAILABILITY: 'Availability',
};

export const noteTypeOptions = Object.keys(noteTypeToLabel).map((key) => ({
  value: key,
  label: noteTypeToLabel[key],
}));

export const getNoteTypeOptions = (bddPlayer) => {
  const isDevelopmentPlayer =
    bddPlayer?.isDevelopmentPlayer ||
    bddPlayer?.playerLists?.find(
      (pl) => pl.playerList.listType == 'PLAYER_DEVELOPMENT_LIST'
    );

  return [
    {
      value: 'GENERAL',
      label: 'General',
      tooltip: 'Leave a note or comment about the player',
    },
    ...(isDevelopmentPlayer
      ? [
          {
            value: 'CHECK-IN',
            label: 'Check-In',
            tooltip: 'Record a check-in with a player',
          },
          {
            value: 'ASSESSMENT',
            label: 'Assessment',
            tooltip: 'Assess a development players performance',
          },
        ]
      : [
          {
            value: 'ANALYTIC',
            label: 'Analytic',
            tooltip: 'Assess a players performance',
          },
        ]),
    {
      value: 'MEDICAL',
      label: 'Medical',
      tooltip: 'Record medical updates from a player',
    },
    {
      value: 'PERSONAL',
      label: 'Personal',
      tooltip: 'Store any personal notes about a player',
    },
    {
      value: 'AVAILABILITY',
      label: 'Availability',
      tooltip: 'Leave a note about a player trade availability',
    },
  ];
};
