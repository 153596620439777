import { usePlayerSLGames } from './usePlayerSLGames';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ButtonGroup } from 'components/bdd/ButtonGroup/ButtonGroup';
import { season8to4 } from 'helpers/hockeyutils';
import { strContains } from 'helpers/data';
import useWindowSize from 'components/bdd/hooks/usewindowsize';
import { theme } from 'constants';

const SmallBGStyles = styled.div({
  overflowX: 'auto',
  paddingBottom: '5px',
  maxWidth: '100%'
})

const findDefaultLeague = stats => {
  // given a stat request response, choose default league
  // league with most recent season with most gp
  // expects stats: { data: [{league_slug, season, gamesPlayed}, ...]}
  const ret = stats.data.reduce((mostrecent, d) => {
    if (d.season > mostrecent.season) {
      return d
    } else if (d.season === mostrecent.season && d.gamesPlayed > mostrecent.gamesPlayed) {
      return d
    }
    return mostrecent
  }, { league_slug: null, season: -1, gamesPlayed: -1 })
  return ret.league_slug
}

export default function useLeagueButtons({ slug }) {
  if (!slug) {
    console.error('Must supply player bdd slug to useLeagueButtons hook')
    return {
      slLeagues: []
    }
  }
  const width = useWindowSize()
  const isMobile = width === 'xs';
  
  const { stats, leaguesWithGamesPlayed: slLeagues, loading } = usePlayerSLGames({ slug });
  const defaultLeague = !!stats ? findDefaultLeague(stats) : null
  // const defaultLeague = slLeagues?.[0]?.leagueSlug
  const [selectedLeague, setSelectedLeague] = useState(defaultLeague)

  let leagueOptions = useMemo(() => !!slLeagues ? slLeagues.map((league) => {
    return {
      value: league.leagueSlug,
      label: league.leagueSlug,
      caption: `${league.gamesPlayed} SL GP`,
      hover: `Set default league to ${league.leagueSlug}`
    };
  }) : [], [slLeagues]);
  // leagueOptions = ['NHL', 'AHL', 'OHL', 'QMJHL', 'SHL', 'WJC', 'IIHF'].map(l => ({value: l, label: l, caption: l}))

  useEffect(() => {
    if (!!leagueOptions && leagueOptions.length > 0 && !selectedLeague) {
      setSelectedLeague(findDefaultLeague(stats))
    }
  }, [selectedLeague, leagueOptions])

  useEffect(() => {
    setSelectedLeague(null)
  }, [slug])

  const priorityLeaguesToSort = ['AHL', 'NHL'] // negative sorting (so other leagus go after these)
  const sortedLeagueOptions = [...leagueOptions].sort((a, b) => priorityLeaguesToSort.indexOf(b.value) - priorityLeaguesToSort.indexOf(a.value))

  const noCaptionOptions = sortedLeagueOptions?.map(lo => ({ value: lo.value, label: lo.label, hover: lo.hover }))

  const buttonGroup = <ButtonGroup
    options={isMobile ? noCaptionOptions : sortedLeagueOptions}
    selectedValue={selectedLeague}
    onClick={(option) => setSelectedLeague(option.value)}
    tooltipPlacement='bottom'
  />

  const noCaptionBG = <ButtonGroup
    options={noCaptionOptions}
    selectedValue={selectedLeague}
    onClick={option => setSelectedLeague(option.value)}
  />
  
  const smallButtonGroup = <SmallBGStyles>
    {noCaptionBG}
  </SmallBGStyles>

  return {
    loading,
    buttonGroup,
    smallButtonGroup,
    selectedLeague,
    setSelectedLeague,
    slLeagues
  }
}
