import { useMutation } from "@apollo/client"
import { CREATE_PRO_OBJECTIVE_SCENARIO } from "apollo/queries/proobjective.queries"
import { Column, Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts"
import BDDInputField from "components/form/bddinputfield"
import { Field, Form, Formik } from "formik"
import { getNextOrCurrentSeason, getSeasonPlusMinusYears } from "helpers/hockeyutils"
import { Button } from "react-bootstrap"
import * as Yup from "yup";

export const CreateScenario = ({ proObjectiveSlug, proObjective, onCompleted }) => {
  const [createScenario] = useMutation(CREATE_PRO_OBJECTIVE_SCENARIO, {
    onCompleted: (args) => {
      toastInfo('Scenario created');
      !!onCompleted && onCompleted(args);
    },
    onError: toastBddApiError,
    update: (cache, { data: { createProObjectiveScenario: { scenario }}}) => {
      cache.modify({
        fields: {
          proObjectiveScenarios(existing=[]) {
            return [scenario].concat(existing)
          }
        }
      })
    }
  })

  const handleSubmit = (values) => {
    createScenario({
      variables: {
        input: {
          proObjectiveSlug,
          title: values.title,
          description: values.description,
          teamSlug: proObjective.teamSlug,
          season: proObjective.bddDepthChartUniqueKey === 'future' ? getSeasonPlusMinusYears(getNextOrCurrentSeason(), 1) : getNextOrCurrentSeason()
        }
      }
    })
  }

  return <Container>
    <Formik
      validationSchema={Yup.object({
        title: Yup.string().required(),
        description: Yup.string(),
      })}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      initialValues={{
        title: '',
        description: '',
      }}
    >
      {({
        handleSubmit,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Column gap={4}>
            <Field
              name='title'
              title='Title'
              placeholder='Scenario title'
              component={BDDInputField}
            />
            <Field
              name='description'
              title='Description'
              placeholder='Scenario description'
              component={BDDInputField}
            />

            <Row justifyContent='center'>
              <Button type='submit'>
                Submit
              </Button>
            </Row>

          </Column>
        </Form>
      )}
    </Formik>
  </Container>
}