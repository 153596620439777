import { Column, Container, Row } from "components/bdd/Layout";
import { ScenarioPick } from "./ScenarioPick";
import { Typography } from "components/bdd/Typography";
import { HoverInteractive } from "components/bdd";


export const scenarioPlayerToWarning = (scenarioPlayer) => {
  const lastname = scenarioPlayer.bddPlayer.lastname;
  const playerHasBeenTraded = scenarioPlayer.inOrOut === 'out' && !!scenarioPlayer.transactionType
  let warning = null;
  if (scenarioPlayer.inOrOut === 'in' && !scenarioPlayer.transactionType && scenarioPlayer.depthChartSpot.depthLeague != 'Prospect') {
    if (isFreeAgent(scenarioPlayer.bddPlayer, scenarioPlayer.transactions)) {
      warning = `${lastname} needs a contract`;
    } else {
      warning = `${lastname} has been added to this scenario's depth chart but no transaction found to acquire him.`;
    }
  }
  else if (scenarioPlayer.inOrOut === 'in' && !scenarioPlayer.isInDc) {
    warning = `${lastname} is part of a trade but is not in the depth chart.`;
  }
  else if (scenarioPlayer.inOrOut === 'out' && !scenarioPlayer.isInDc && !scenarioPlayer.transactionType) {
    warning = `${lastname} is an asset but isn't included in this scenario's depth chart and hasn't been moved in any associated transactions`
  }
  else if (playerHasBeenTraded && scenarioPlayer.isInDc) {
    warning = `${lastname} is part of a trade but is still included in this scenario's depth chart`
  }
  else if (isFreeAgent(scenarioPlayer.bddPlayer, scenarioPlayer.transactions) && !playerHasBeenTraded && scenarioPlayer.depthChartSpot.depthLeague != 'Prospect') {
    warning = `${lastname} needs a contract`
  }

  return warning;
}

export const scenarioPickToWarning = (scenarioPick) => {
  let warning = null;
  if (scenarioPick.isDoubleTraded) {
    warning = 'This pick has been double-traded'
  }
  return warning;
}

export const isFreeAgent = (bddPlayer, playerTxs) => {
  if (playerTxs?.find(t => t.transactionType == 'SIGNING')) return false;
  
    // Expects bddPlayer with bfCapHit loaded
  if (!bddPlayer?.bfCapHit) return true;

  return bddPlayer.bfCapHit.currentRemainingTerm < 1 && !bddPlayer.bfCapHit.futureRemainingTerm;
}

export const summarizePicks = (picks) => { 
  const sortedPicks = picks.sort((a, b) => a.overall - b.overall);
  console.log(sortedPicks)
  if (sortedPicks.length === 0) {
    return <Typography variant='caption' style={{ fontStyle: 'italic' }}>None</Typography>
  } else if (sortedPicks.length === 1) {
    return <ScenarioPick pick={sortedPicks[0]} />
  } else {
    return (
      <Container>
        <Column>
          <ScenarioPick pick={sortedPicks[0]} />
          <HoverInteractive content={(
            picks?.map(p => (
              <ScenarioPick key={p.pickId} pick={p} />
            ))
          )}>
            <Typography variant='caption' textDecoration='underline'>+{picks.length-1} more</Typography>
          </HoverInteractive>
    
        </Column>
      </Container>
    )
  }
}