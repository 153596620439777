import { theme } from 'constants';
import BDDErrorBoundary from '../bdderrorboundary';
import { Container } from '../Layout';

export const PageBody = ({ children, backgroundColor=theme.colors.light.secondary, padding=2 }) => {
  return (
    <BDDErrorBoundary>
      <Container
        padding={padding}
        backgroundColor={backgroundColor}
        height="100%"
        overflow="auto"
      >
        {children}
      </Container>
    </BDDErrorBoundary>
  );
};
