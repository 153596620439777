import { useEffect, useState } from 'react';
import { useSearchParamsStore } from './useSearchParamsStore';

export const useSearchParamsState = (initialState, key = 'state') => {
  const store = useSearchParamsStore();

  const [state, setState] = useState(
    store.paramExists(key) ? store.getSerialized(key) : initialState
  );

  useEffect(() => {
    if (!state) {
      if (!store.getValue(key)) {
        return;
      }

      store.deleteKeys([key]);
    } else {
      store.setSerialized(key, state);
    }
  }, [state]);

  return [state, setState];
};
