import { useMemo } from 'react';
import { useQueryWithPlaceholder } from 'apollo/hooks';
import { GET_UPLOAD } from 'apollo/queries/upload.queries';

const DEFAULT_ANALYTIC_UPLOAD_SLUG = 'analyticDraftData2022';

export const useAnalyticUploadData = ({ playerList, skip } = {}) => {
  let analyticUploadSlug;

  if (!playerList || !playerList.extras) {
    analyticUploadSlug = DEFAULT_ANALYTIC_UPLOAD_SLUG;
  } else {
    try {
      const extras = JSON.parse(data.playerList.extras);
      analyticUploadSlug =
        extras[listConstants.LIST_EXTRAS_ANALYTICS_UPLOAD_SLUG_KEY];
    } catch {
      analyticUploadSlug = DEFAULT_ANALYTIC_UPLOAD_SLUG;
    }
  }

  const { data, placeholder } = useQueryWithPlaceholder(
    GET_UPLOAD,
    {
      slug: analyticUploadSlug,
    },
    {
      variant: 'squares-sm',
      nocenter: true,
    },
    skip
  );

  const playerToAnalytics = useMemo(() => {
    const ret = {};
    if (!!data && !!data.upload) {
      data.upload.uploadData.forEach((d) => {
        const parsed = JSON.parse(d.data);
        ret[parseInt(parsed['rinknetid'])] = parsed;
      });
    }
    return ret;
  }, [data]);

  return { data, placeholder, playerToAnalytics };
};
