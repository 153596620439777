import { gql } from "@apollo/client";

export const BddTagFragment = gql`fragment bddTagFragment on BDDTag {
    id
    tag
    tagType
    description
    color
    textColor
    dateCreated
    isBddTag
    requiresPosition
    priority
}`

const BddPlayerWithTagsFragment = gql`fragment bddPlayerWithTagsFragment on BDDPlayerSlug {
    id
    slug
    firstname
    lastname
    posFDG
    availability
    bddManagedTags {
        id
        flags
        isProjection
        tag {
            ...bddTagFragment
        }
    }
} ${BddTagFragment}`

export const CREATE_BDD_TAG = gql`mutation CreateBDDTag($input: CreateBDDTagInput!) {
    createBddTag(input: $input) {
        bddTag {
            ...bddTagFragment
        }
    }
} ${BddTagFragment}`

export const UPDATE_BDD_TAG = gql`mutation UpdateBDDTag($input: UpdateBDDTagInput!) {
    updateBddTag(input: $input) {
        bddTag {
            ...bddTagFragment
        }
    }
} ${BddTagFragment}`

export const DELETE_BDD_TAG = gql`mutation DeleteBDDTag($id: ID!) {
    deleteBddTag(id: $id) {
        id
    }
}`

export const GET_BDD_TAGS = gql`query GetBDDTags($tagType: String) {
    bddTags(tagType: $tagType) {
        ...bddTagFragment
    }
} ${BddTagFragment}`

export const GET_BDD_TAGS_FOR_PLAYER = gql`query GetBDDTagsForPlayer($slug: ID!) {
    bddPlayer(slug: $slug) {
        ...bddPlayerWithTagsFragment
    }
} ${BddPlayerWithTagsFragment}`

export const ADD_PLAYER_TAG = gql`mutation AddPlayerTag(
    $bddPlayerSlug: ID!,
    $tagId: ID!,
    $isPublic: Boolean
    $flags: [String!]
) {
    addPlayerTag(bddPlayerSlug: $bddPlayerSlug, tagId: $tagId, isPublic: $isPublic, flags: $flags) {
        bddPlayer {
            ...bddPlayerWithTagsFragment
        }
    }
} ${BddPlayerWithTagsFragment}`

// Removing a plyer tag can be accomplished by supplying either the bddPlayerTag.id OR the player slug, tagId, and isPublic
export const REMOVE_PLAYER_TAG = gql`mutation RemovePlayerTag(
    $id: ID,
    $bddPlayerSlug: ID,
    $tagId: ID,
    $isPublic: Boolean
) {
    removePlayerTag(id: $id, bddPlayerSlug: $bddPlayerSlug, tagId: $tagId, isPublic: $isPublic) {
        bddPlayer {
            ...bddPlayerWithTagsFragment
        }
    }
} ${BddPlayerWithTagsFragment}`


const BddPlayerTagRequestFragment = gql`fragment bddPlayerTagRequestFragment on BDDPlayerTagRequest {
    id
    user { id username }
    slug
    bddPlayer { slug firstname lastname }
    tagsToAdd
    tagsToRemove
    notes
    dateCreated
    status
}`

export const CREATE_BDD_TAG_REQUEST = gql`mutation CreateBddTagRequest($input: CreateBDDPlayerTagRequestInput!) {
    createBddPlayerTagRequest(input: $input) {
        bddPlayerTagRequest {
            ...bddPlayerTagRequestFragment
        }
    }
} ${BddPlayerTagRequestFragment}`


export const BDDTagTypeFragment = gql`fragment bddTagTypeFragment on BDDTagTypes {
  id
  slug
  defaultIsPublic
  isMutuallyExclusive
}`

export const GET_BDD_TAG_TYPES = gql`query GetBddTagTypes {
  bddTagTypes {
    ...bddTagTypeFragment
  }
}
${BDDTagTypeFragment}`

export const CREATE_BDD_TAG_TYPE = gql`mutation CreateBDDTagType($input: BDDTagTypeInput!) {
  createBddTagType(input: $input) {
    bddTagType {
      ...bddTagTypeFragment
    }
  }
}
${BDDTagTypeFragment}`

export const UPDATE_BDD_TAG_TYPE = gql`mutation UpdateBDDTagType($input: BDDTagTypeInput!) {
  updateBddTagType(input: $input) {
    bddTagType {
      ...bddTagTypeFragment
    }
  }
}
${BDDTagTypeFragment}`

export const DELETE_BDD_TAG_TYPE = gql`mutation DeleteBDDTagType($slug: ID!) {
  deleteBddTagType(slug: $slug) {
    slug
  }
}`