import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  COPY_BDD_DEPTH_CHART,
  GET_TEAM_ACTIVE_DEPTH_CHART,
} from '../../apollo/queries/depthchart.queries';
import BDDErrorBoundary from '../bdd/bdderrorboundary';
import BDDDepthChartConstructor from './bdddepthchartconstructor';
import { BDDLoader } from '../bdd/bddloader';
import BDDApiError from '../bdd/bddapierror';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { LinkButton, LoadingPlaceholder } from 'components/bdd/Button';
import { useDepthChartButtonGroup } from './useDepthChartButtonGroup';

const NoDepthChartError = ({ teamSlug, uniqueKey }) => {
  const [copyBddDepthChart, { loading }] = useMutation(COPY_BDD_DEPTH_CHART);

  return (
    <Container padding={3}>
      <Typography variant="subtitle1">No depth chart found</Typography>
      <LoadingPlaceholder loading={loading}>
        {teamSlug && uniqueKey && (
          <LinkButton
            onClick={() =>
              copyBddDepthChart({
                variables: {
                  sourceTeamSlug: teamSlug,
                  destUniqueKey: uniqueKey,
                },
                refetchQueries: [
                  {
                    query: GET_TEAM_ACTIVE_DEPTH_CHART,
                    variables: {
                      teamSlug,
                      uniqueKey,
                    },
                  },
                ],
              })
            }
          >
            Copy from active depth chart
          </LinkButton>
        )}
      </LoadingPlaceholder>
    </Container>
  );
};

export default function BDDDepthChart({
  depthChartId,
  activeTeamSlug,
  uniqueKey,
  ...rest
}) {
  if ((!!depthChartId && !!activeTeamSlug) || (!depthChartId && !activeTeamSlug)) {
    throw Error('One of "depthChartId" OR "activeTeamSlug" required (not both)');
  }
  if (!!activeTeamSlug) {
    var { data, loading, error } = useQuery(GET_TEAM_ACTIVE_DEPTH_CHART, {
      variables: { teamSlug: activeTeamSlug, uniqueKey: uniqueKey },
    });
    if (loading) return <BDDLoader />;
    if (error) return <BDDApiError error={error} />;
    if (!data || !data.activeDepthChartForTeam)
      return <NoDepthChartError teamSlug={activeTeamSlug} uniqueKey={uniqueKey} />;
    depthChartId = data.activeDepthChartForTeam.id;
  }
  return (
    <BDDErrorBoundary>
      <BDDDepthChartConstructor depthChartId={depthChartId} {...rest} />
      {/* {showAnalyzer && <DepthChartAnalyzer depthChartId={depthChartId} />} */}
    </BDDErrorBoundary>
  );
}

export const TeamBDDDepthChart = ({ activeTeamSlug, initialSelectedValue='active', ...rest }) => {
  const { depthChartButtonGroup, selectedDepthChartKey } = useDepthChartButtonGroup({ 
    teamSlug: activeTeamSlug,
    initialSelectedValue
  });

  return (
    <Container paddingTop={1}>
      <Column gap={1}>
        <Row justifyContent="end">{depthChartButtonGroup}</Row>
        <BDDDepthChart
          activeTeamSlug={activeTeamSlug}
          uniqueKey={selectedDepthChartKey}
          {...rest}
        />
      </Column>
    </Container>
  );
};

export const BDDDepthChartSmall = ({ depthChartId, activeTeamSlug, ...rest }) => {
  if ((!!depthChartId && !!activeTeamSlug) || (!depthChartId && !activeTeamSlug)) {
    throw Error('One of "depthChartId" OR "activeTeamSlug" required (not both)');
  }
  if (!!activeTeamSlug) {
    var { data, loading, error } = useQuery(GET_TEAM_ACTIVE_DEPTH_CHART, {
      variables: { teamSlug: activeTeamSlug },
    });
    if (loading) return <BDDLoader />;
    if (error) return <BDDApiError error={error} />;
    if (!data || !data.activeDepthChartForTeam) return <NoDepthChartError />;
    depthChartId = data.activeDepthChartForTeam.id;
  }
  return (
    <BDDErrorBoundary>
      <BDDDepthChartConstructor
        depthChartId={depthChartId}
        defaultEditable={false}
        showButtons={false}
        showProspects={false}
        showLists={false}
        showChanges={false}
        playerSize="sm"
        nonNHLDisplayMode="expandable" // either visible, expandable or hidden
        {...rest}
      />
    </BDDErrorBoundary>
  );
};
