import { useMutation, useQuery } from '@apollo/client';
import { GET_PLAYLISTS, UPDATE_PLAYLIST } from 'apollo/queries/video.queries';
import { toastInfo } from 'components/bdd/bddtoasts';
import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants/theme';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { usePlaylist, usePlaylistMutation, usePlaylists } from '.';

export const AddToExistingPlaylistForm = ({ playlist, playlistClips, onSubmit }) => {
  const { playlists, loading, error, placeholder } = usePlaylists({ onlyEdit: true });
  const { select: playlistSelect, selected } = useSelect({
    initialSelectedValue: playlist?.id,
    options:
      playlists?.map((p) => ({
        label: p.name,
        caption: p.description,
        value: p.id,
      })) || [],
    selectProps: {
      isLoading: loading,
    },
  });

  const { playlist: selectedPlaylist } = usePlaylist({ id: selected?.value });
  const { updatePlaylist } = usePlaylistMutation(selectedPlaylist);

  return (
    <Container width={300}>
      <Column gap={2}>
        {!!playlist && (
          <Border borderColor={theme.colors.teams.bos.primary}>
            <Container padding={1} backgroundColor={theme.colors.light.focus}>
              <Typography variant="body1">{playlist.name}</Typography>
              <Typography variant="body2">{playlist.description}</Typography>
            </Container>
          </Border>
        )}
        {!playlist && (
          <Container>
            <Typography variant="body2">Select playlist:</Typography>
            {playlistSelect}
          </Container>
        )}
        {/* {loading || error
              ? placeholder
              : playlists.map((p) => (
                  <Border
                    key={p.id}
                    borderColor={
                      selectedPlaylist &&
                      selectedPlaylist.id == p.id &&
                      theme.colors.teams.bos.primary
                    }
                  >
                    <Container
                      variant="button"
                      padding={1}
                      onClick={() => setSelectedPlaylist(p)}
                      backgroundColor={
                        selectedPlaylist &&
                        selectedPlaylist.id == p.id &&
                        theme.colors.light.focus
                      }
                    >
                      <Typography variant="body1">{p.name}</Typography>
                      <Typography variant="body2">{p.description}</Typography>
                    </Container>
                  </Border>
                ))} */}
        <Divider />
        <Row justifyContent="end">
          <Button
            variant="outline-dark"
            disabled={!selectedPlaylist}
            onClick={() =>
              updatePlaylist({
                onCompleted: () => {
                  toastInfo('Clips successfully added to playlist');
                  onSubmit && onSubmit();
                },
                variables: {
                  input: {
                    id: selectedPlaylist.id,
                  },
                  clipsToAdd: playlistClips.map((pc, index) => ({
                    id: pc.id,
                    playlistId: selectedPlaylist.id,
                    playlistNumber:
                      pc.playlistNumber || selectedPlaylist.clips.length + index,
                    name: pc.name,
                    description: pc.description,
                    frontPad: pc.frontPad,
                    backPad: pc.backPad,
                    ...(pc.event
                      ? {
                          eventId: pc.eventId,
                          eventName: pc.event.eventName,
                          gameUid: pc.event.gameUid,
                        }
                      : {}),
                    ...(pc.shift
                      ? {
                          shiftNumber: pc.shift.shiftNumber,
                          gameUid: pc.shift.gameUid,
                          playerId: pc.shift.playerId,
                        }
                      : {}),
                  })),
                },
              })
            }
          >
            <Typography variant="body1">Submit</Typography>
          </Button>
        </Row>
      </Column>
    </Container>
  );
};
