import { Button, Container } from "react-bootstrap";
import { useModal } from "../Modal";
import { Typography } from "../Typography";
import { Column, Row } from "../Layout";
import { useState } from "react";


export const useConfirmModal = ({
  onConfirm,
  prompt='Please confirm'
}) => {

  const { renderModal, showModal, setShowModal } = useModal({
    size: 'sm',
    title: <Typography variant='h5'>Confirm</Typography>,
    useNewModal: false
  });

  const [state, setState] = useState(undefined);
  const setStateAndShow = (newState) => {
    setState(newState);
    setShowModal(true);
  }

  const modal = (
    showModal && renderModal(
      <Container>
        <Column gap={2}>
          <Typography variant='body1'>{prompt}</Typography>
          <Row justifyContent='end' gap={8}>
            <Button size='sm' variant='secondary' onClick={() => setShowModal(false)}>Cancel</Button>
            <Button size='sm' variant='primary' onClick={() => {
              onConfirm(state);
              setShowModal(false);
            }}>Submit</Button>
          </Row>
        </Column>  
      </Container>
    )
  )

  return {
    modal,
    setShowModal,
    setStateAndShow, // helper to pass data on to eventual "onConfirm" call
  }
}