import { useMemo } from 'react';

import { roundToX } from 'helpers/data';
import { getRedGreenColor, sortNumeric, sortNumericWithFormat } from 'helpers/tables';
import { HoverInteractive } from '../bddcomponents';
import BDDSortableTable from '../bddsortabletable';
import { PlusCircle } from 'react-bootstrap-icons';
import { formatBddStat } from 'helpers/format';
import { theme } from 'constants/theme';

/* Take a BDD response and turn it into a table */
export default function BDDStatTableV2({
  data,
  format,
  metastats,
  index: indexArg,
  indexColumns: indexColumnsArg, // if supplied, will overwrite "columns" of react-table
  formatStat: formatStatArg, // callback function for formatting each stat (val, statDef)
  sortWithFormat, // will use "sortNumericWithFormat" helper function when sorting
  highlight, // bool, if true will add highlighting
  highlightCallback: highlightCallbackArg, // if supplied, callback used instead of default stddev highlighting
  statSlugs, // a list of statSlugs to use -- ignore all others
  statLabels, // a list of statLabels to use -- ignore all others
  cellHoverCallback: cellHoverCallbackArg, // callback to display on hover over cell
  cellDecorationCallback: cellDecorationCallbackArg, // decoration is added to DOM after the value in each cell
  addStatToTableCallback, // If supplied, table will support adding stats to the table (re-fetch handled externally)
  hoverProps, // props for hover interactive
  centerContent=true,
  ...rest
}) {
  const cellHoverCallback = useMemo(() => cellHoverCallbackArg, [cellHoverCallbackArg]);
  const cellDecorationCallback = useMemo(
    () => cellDecorationCallbackArg,
    [cellDecorationCallbackArg]
  );
  if (!data) {
    return (
      <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.8em' }}>
        Loading...
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.8em' }}>
        No data found
      </div>
    );
  }

  if (!metastats && !highlightCallbackArg && highlight) {
    throw Error(
      'Must supply either highlightCallback or metastats in order to do highlighting'
    );
  }

  let statDefinitions = format.stat_definitions;
  if (format.index.includes('game_date')) {
    statDefinitions = statDefinitions.filter((sd) => sd.slug != 'gamesPlayed');
  }

  const idToDef = {};
  statDefinitions.forEach((s) => (idToDef[s.unique_id] = s));

  const formatStat = formatStatArg
    ? formatStatArg
    : (val, statDef, row) => {
        if (isNaN(val)) return val;
        return formatBddStat(val, statDef);
      };

  let indexColumns;
  if (!!indexColumnsArg) {
    indexColumns = indexColumnsArg;
  } else {
    const indexNames = indexArg ? indexArg : format.index;
    indexColumns = indexNames.map((i) => ({
      Header: i,
      accessor: (d) => d[i],
      tooltip: i,
    }));
  }

  const highlightCallback = highlightCallbackArg
    ? highlightCallbackArg
    : (label, row, statDef) => {
        try {
          const uniqueId = statDef.unique_id;
          const value = row[label];
          const mean = metastats[uniqueId].mean;
          const stddev = metastats[uniqueId].stddev;
          const color = getRedGreenColor(
            value,
            mean - 2 * stddev,
            mean + 2 * stddev,
            statDef.negative,
            2
          );
          return color;
        } catch {
          return null;
        }
      };

  const statDefsFiltered = !!statSlugs
    ? statDefinitions.filter((s) => statSlugs.includes(s.slug))
    : !!statLabels
    ? statDefinitions.filter((s) => statLabels.includes(s.label))
    : statDefinitions;

  const statColumns = statDefsFiltered.map((s) => ({
    id: s.label,
    Header: (
      <div style={{ ...theme.font.weight.regular, minWidth: 'max-content' }}>
        {s.label}
      </div>
    ),
    accessor: (d) => (
      <div
        style={{
          width: '100%',
          background: highlight ? highlightCallback(s.label, d, s) : null,
          textAlign: centerContent ?  'center' : 'left'
        }}
      >
        {cellHoverCallback ? (
          <HoverInteractive
            renderContent={(forceClose) => cellHoverCallback(s.label, d, s, forceClose)}
            {...hoverProps}
          >
            <div style={rest.columnJustify === 'center' ? { textAlign: 'center'} : {}}>
              {formatStat(d[s.label], s, d)}
              {!!cellDecorationCallback ? cellDecorationCallback(s.label, d, s) : null}
            </div>
          </HoverInteractive>
        ) : (
          <>
            {formatStat(d[s.label], s, d)}
            {!!cellDecorationCallback ? cellDecorationCallback(s.label, d, s) : null}
          </>
        )}
      </div>
    ),
    tooltip: s.description,
    sortType: (a, b, colId) =>
      sortWithFormat
        ? sortNumericWithFormat(a, b, colId, formatStat)
        : sortNumeric(a, b, colId),
    sortDescFirst: true,
  }));

  const columns = indexColumns.concat(statColumns);

  return <BDDSortableTable data={data} columns={columns} {...rest} />;
}
