import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { LOGIN_ROUTE } from '../../constants'
import { isLoggedIn } from '../../helpers/user'

const PrivateRoute = ({component: Component, render, ...rest}) => {
    const valid = isLoggedIn()
    if (!valid) {
        localStorage.setItem('loginRedirect', window.location.pathname + window.location.search)
    }
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route {...rest} render={props => (
            valid ?
                !!Component ? <Component {...props} />
                : render()
            : <Redirect to={LOGIN_ROUTE} />
        )} />
    );
};

export default PrivateRoute;