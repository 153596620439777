export const LIST_ID_TYPES = {
  rinknet_id: 'rinknet_id',
  bdd_player_slug: 'bdd_player_slug',
};

export const PLAYER_LIST_TYPES = {
  DRAFT_LIST: 'DRAFT_LIST',
  PRO_LIST: 'PRO_LIST',
  FREE_AGENT_LIST: 'FREE_AGENT_LIST',
  AGGREGATED_RANK_LIST: 'AGGREGATED_RANK_LIST',
  PLAYER_DEVELOPMENT_LIST: 'PLAYER_DEVELOPMENT_LIST',
};
