
export const ASSET_TYPES = {
  PLAYER: 'PLAYER',
  DRAFT_PICK: 'DRAFT_PICK'
}

export const NEED_TYPES = {
  PLAYER: 'PLAYER'
}

export const OBJECTIVE_LIST_TYPES = {
  TARGET: 'TARGET',
  ASSET: 'ASSET'
}