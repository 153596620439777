import styled from 'styled-components';

import { theme } from 'constants';
import { useSelect } from '.';
import { GET_USER_GROUPS } from 'apollo/queries/user.queries';
import { useQuery } from '@apollo/client';

const Container = styled.div({
  minWidth: theme.spacing[24],
});

const useGroupSelect = (variant, useName = false) => {
  const { data, loading } = useQuery(GET_USER_GROUPS);
  const idToName = data?.users?.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
    {}
  );

  const userGroupOptions =
    data?.userGroups?.map((ug) => {
      return {
        label: ug.name,
        value: ug.slug,
        userIds: ug.users.map((u) => u.userId),
        // rinknetUserIds: ug.usergs.map((u => u.rinknetUser?.id)),
        subCaption: (
          <div>
            {ug.users.length} users:{' '}
            {ug.users
              .slice(0, 5)
              .map((ugu) => idToName[ugu.userId])
              .join(', ')}
            {ug.users.length > 5 && '...'}
          </div>
        ),
      };
    }) || [];

  const { selected, select } = useSelect({
    options: userGroupOptions,
    variant,
    selectProps: {
      placeholder: 'Select group',
      isSearchable: true,
      isClearable: true,
      isLoading: loading,
      menuPlacement: 'auto',
    },
  });

  return {
    groupId: selected?.value,
    userIds: selected?.userIds,
    userGroupSelect: <Container>{select}</Container>,
    userGroupOptions,
  };
};

export default useGroupSelect;
