import { useState } from "react"
import ManagePlayerTagModal from "./manageplayertagmodal";


export const useManageTagModal = ({ playerSlug }) => {
  const [show, setShow] = useState(false);
  const modal = show && (
    <ManagePlayerTagModal
      show={show}
      handleClose={() => setShow(false)}
      bddPlayerSlug={playerSlug}
    />
  )
  return {
    modal,
    show,
    setShow
  }
}