import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { BDDSelectSmall } from "./bddcomponents"

const Styles = styled.div``

const PageNumInput = ({currPage, numPages, handleChangePage}) => {
    return <input 
        type='number'
        value={currPage}
        onChange={ev => handleChangePage(parseInt(ev.target.value))}
        min="1"
        max={numPages}
    />
}

const SelectNumRows = ({ pageLen, setPageLen, setCurrPage, pageLenOptions, itemName }) => {
    if (!pageLenOptions.includes(parseInt(pageLen))) pageLenOptions.push(pageLen)
    const options = pageLenOptions.map(n => ({ label: `${n} ${itemName}`, value: n }))
    return <BDDSelectSmall
        value={pageLen}
        options={options}
        onChange={(n,v) => {
            setCurrPage(1)
            setPageLen(parseInt(v));
        }}
    />
}

export default function BDDPagination({
    currPage,
    setCurrPage,
    pageLen,
    setPageLen,
    totalItems,
    itemName='records',
    pageLenOptions=[5, 10, 25, 50]
}) {
    const numPages = Math.ceil(totalItems / pageLen)
    const handleChangePage = num => {
        if (num < 1 || num > numPages) throw Error('Requesting page out of range')
        else setCurrPage(num)
    }
    return <Styles>
    <Row noGutters>
    <Col sm={3}>
        <Button block size="md" variant="outline-dark" 
            disabled={currPage === 1}
            onClick={() => handleChangePage(currPage-1)}
        >
            <FontAwesomeIcon icon={faArrowLeft}/>
        </Button>
    </Col>
    <Col sm={6}>
        <small>
        <Row>
            <Col>
            {(currPage-1)*pageLen+1} to {Math.min(currPage*pageLen, totalItems)}
            {' '}of {totalItems} total {itemName}</Col>
            <Col>
                Page <PageNumInput currPage={currPage} 
                    handleChangePage={handleChangePage} 
                    numPages={numPages} 
                /> of {numPages}
            </Col>
            <Col>
                {!!setPageLen ? <SelectNumRows
                    pageLen={pageLen}
                    setPageLen={setPageLen}
                    setCurrPage={setCurrPage}
                    pageLenOptions={pageLenOptions}
                    itemName={itemName}
                /> : `Showing ${pageLen} ${itemName}`}
            </Col>
        </Row>
        </small>
    </Col>
    <Col sm={3}>
        <Button block size="md" variant="outline-dark" 
            disabled={currPage === numPages}
            onClick={() => handleChangePage(currPage+1)}
        >
            <FontAwesomeIcon icon={faArrowRight}/>
        </Button>
    </Col>
    </Row>
    </Styles>
}