import { useQuery } from "@apollo/client";
import { } from "apollo/queries/playerlist.queries";
import { GET_BDD_TEAMS, GET_TEAMS_BY_LEAGUE } from "apollo/queries/teams.queries";
import { getNHLTeamLogoUrl } from "helpers/logos";
import { useSelect } from ".";
import { Row } from "../Layout";
import { Typography } from "../Typography";
import { components } from 'react-select';
import useBddTeams from "../Teams/useBddTeams";

export const TeamOption = ({ label, data: { nhlid }, ...props }) => {
  return (
    <components.Option {...props}>
      <Row>
      {!!nhlid && <img alt='' height='20px' src={getNHLTeamLogoUrl(nhlid)} />}
        <Typography variant='body1'>
          {label}
        </Typography>
      </Row>
    </components.Option>
  );
};

export default function useTeamSelect({
  leagueSlug = 'NHL', // NOTE this is an SL league slug
  valueType = 'slTeamSlug', // must be an attribute on the BDDTeam (slTeamSlug, nhlid, bfTeamSlug, slug...)
  activeOnly=true,
  variant,
  skip = false,
  initialSelectedValue,
  selectProps={},
  onChange, // called with (value, setSelectedValue)
}) {
  const { data, bddTeams, loading } = useBddTeams({
    leagueSlug,
    activeOnly,
    skip
  });

  const teamOptions = !!data
    ? bddTeams.map(t => ({
      label: (
        <Row>
          {!!t.nhlid && <img alt='' height='20px' src={getNHLTeamLogoUrl(t.nhlid)} />}
          <Typography variant='body1'>
            {t.longname}
          </Typography>
        </Row>
      ),
      value: t[valueType],
      nhlid: t.nhlid,
      bddTeam: t
    })) : [];

  const { selected, select, selectedValue, forceSetSelected } = useSelect({
    options: teamOptions,
    variant,
    initialSelectedValue,
    noInitialSelectedValue: !initialSelectedValue,
    onChange,
    selectProps: {
      placeholder: loading ? 'Loading...' : 'Select team...',
      isLoading: loading,
      isSearchable: true,
      isClearable: true,
      // components: { Option: TeamOption },
      ...selectProps
    },
  });

  return {
    data,
    bddTeams,
    teamOptions,
    selected,
    selectedValue,
    select,
    forceSetSelected
  }
}
