import {
  GET_PAGINATED_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_VIEWED,
  REORDER_BDD_NOTIFICATIONS,
  UPDATE_BDD_NOTIFICATION,
  UPDATE_USER_BDD_NOTIFICATION,
} from 'apollo/queries/notification.queries';
import { create } from 'zustand';
import { usePaginated } from 'components/bdd/Paginated';
import { mergeLists } from 'helpers/data';
import { useUser } from 'helpers/user';
import produce from 'immer';
import client from 'apollo/client';
import { createJSONStorage, persist } from 'zustand/middleware';
import localforage from 'localforage';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { filterObjectsByStringMatch } from 'helpers/string';

const filterNotifications = (notifications) =>
  notifications.filter((n) => !n.isArchived && !n.userInteraction?.isArchived);

const sortNotifications = (notifications) => {
  return notifications.sort(
    (a, b) =>
      b.pinPriority - a.pinPriority ||
      b.userInteraction?.pinPriority - a.userInteraction?.pinPriority ||
      new Date(b.createdAt) - new Date(a.createdAt)
  );
};

const initialState = {
  notificationStates: {},
  notifications: [],
  notificationsForUser: {},
  unreadNotificationCount: 0,
};

export const useNotificationStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      actions: {
        clear: () => {
          set(initialState);
        },
        updateNotificationState: (notificationId, loading) =>
          set(
            produce((state) => {
              state.notificationStates[notificationId] = {
                loading,
              };
            })
          ),
        loadNotifications: (notifications) => {
          set(
            produce((state) => {
              notifications.forEach((notification) => {
                notification.users.forEach((u) => {
                  const notificationForUser = { ...notification, userInteraction: u };

                  if (u.userId in state.notificationsForUser) {
                    state.notificationsForUser[u.userId] = mergeLists(
                      state.notificationsForUser[u.userId],
                      [notificationForUser]
                    );
                  } else {
                    state.notificationsForUser[u.userId] = [notificationForUser];
                  }

                  state.notificationsForUser[u.userId] = sortNotifications(
                    filterNotifications(state.notificationsForUser[u.userId])
                  );
                });
              });

              state.notifications = sortNotifications(
                filterNotifications(mergeLists(get().notifications, notifications))
              );

              state.unreadNotificationCount = state.notifications.filter(
                (n) => n.userInteraction && !n.userInteraction.isViewed
              ).length;
            })
          );
        },
        updateUserNotification: (variables) => {
          get().actions.updateNotificationState(variables.bddNotificationId, true);

          client
            .mutate({
              mutation: UPDATE_USER_BDD_NOTIFICATION,
              variables,
            })
            .then(
              ({
                data: {
                  updateUserBddNotification: { bddNotification },
                },
              }) => {
                get().actions.loadNotifications([bddNotification]);
                get().actions.updateNotificationState(variables.bddNotificationId, false);
              }
            );
        },
        markAllNotificationsViewed: () => {
          get().actions.updateNotificationState('all', true);

          client
            .mutate({
              mutation: MARK_ALL_NOTIFICATIONS_VIEWED,
            })
            .then(
              ({
                data: {
                  markBddNotificationsViewed: { bddNotifications },
                },
              }) => {
                get().actions.updateNotificationState('all', false);
                get().actions.loadNotifications(bddNotifications);
              }
            )
            .catch((error) => toastBddApiError(error));
        },
        reorderPinPriority: (notificationIds, reorderUserPinned) => {
          get().actions.updateNotificationState('all', true);

          client
            .mutate({
              mutation: REORDER_BDD_NOTIFICATIONS,
              variables: {
                ids: notificationIds,
                reorderUserPinned,
              },
            })
            .then(
              ({
                data: {
                  reorderBddNotifications: { bddNotifications },
                },
              }) => {
                get().actions.updateNotificationState('all', false);
                get().actions.loadNotifications(bddNotifications);
              }
            )
            .catch((error) => toastBddApiError(error));
        },
        updateNotification: (input, usersToUpdate) => {
          get().actions.updateNotificationState(input.id, true);

          client
            .mutate({
              mutation: UPDATE_BDD_NOTIFICATION,
              variables: {
                input,
                usersToUpdate,
              },
            })
            .then(
              ({
                data: {
                  updateBddNotification: { bddNotification },
                },
              }) => {
                get().actions.loadNotifications([bddNotification]);
                get().actions.updateNotificationState(input.id, false);
              }
            )
            .catch((error) => toastBddApiError(error));
        },
        archiveNotification: (notificationId) => {
          get().actions.updateNotification({
            id: notificationId,
            isArchived: true,
          });
        },
        archiveNotificationForUser: (notificationId, userId) => {
          get().actions.updateUserNotification({
            bddNotificationId: notificationId,
            input: {
              userId: userId,
              isArchived: true,
            },
          });
        },
        toggleNotificationPin: (notificationId, userId, toggled) => {
          get().actions.updateUserNotification({
            bddNotificationId: notificationId,
            input: {
              userId: userId,
              pinPriority: toggled ? get().notifications[0].pinPriority + 1 : null,
            },
          });
        },
        toggleNotificationPinForAll: (notificationId, toggled) => {
          get().actions.updateNotification({
            id: notificationId,
            pinPriority: toggled ? get().notifications[0].pinPriority + 1 : null,
          });
        },
      },
    }),
    {
      name: 'notification-storage',
      storage: createJSONStorage(() => localforage),
      partialize: (state) => ({
        notifications: state.notifications,
        unreadNotificationCount: state.unreadNotificationCount,
      }),
    }
  )
);

export const useNotificationActions = () => {
  return useNotificationStore((state) => state.actions);
};

export const useNotifications = ({ userId, pollInterval, variables = {} } = {}) => {
  let notifications = useNotificationStore(
    (state) => state.notificationsForUser?.[userId] || []
  );

  if (!!variables.subscriptionIds && variables.subscriptionIds.length > 0) {
    notifications = notifications.filter((n) =>
      variables.subscriptionIds.find((s) => s == n.subscriptionId)
    );
  }

  if (!!variables.notificationTypes && variables.notificationTypes.length > 0) {
    notifications = notifications.filter((n) =>
      variables.notificationTypes.find((nt) => nt == n.notificationType)
    );
  }

  if (!!variables.createdBy) {
    notifications = notifications.filter((n) => variables.createdBy == n.createdBy.id);
  }

  if (!!variables.search) {
    notifications = filterObjectsByStringMatch(
      variables.search,
      [...notifications],
      ['title', 'description']
    );
  }

  if ('isViewed' in variables && !variables.isViewed) {
    notifications = notifications.filter((n) => !n.userInteraction?.isViewed);
  }

  const { loadNotifications } = useNotificationActions();
  const { loadingWrapper, loading, offset, error } = usePaginated({
    query: GET_PAGINATED_NOTIFICATIONS,
    key: 'paginatedNotifications',
    defaultOffset: 0,
    defaultLimit: 50,
    variables: {
      ...(userId ? { userId } : {}),
      sort: [{ id: 'createdAt', desc: true }],
      ...variables,
    },
    onDataFetched: (data) => {
      loadNotifications(data.paginatedNotifications.data);
    },
    queryParams: {
      pollInterval,
    },
    skip: !userId,
  });

  return { notifications, loading, loadingWrapper, error };
};
