import { immer } from 'zustand/middleware/immer';
import { createContext, useContext, useEffect, useRef } from 'react';
import { create, useStore } from 'zustand';
import { buildSearchParams, parseSearchParams } from 'helpers/searchParams';
import { buildNewFilter } from './helpers';
import { nanoid } from 'nanoid';

const QueryContext = createContext();
const createStore = ({ defaults, options, searchParamFilters }) =>
  create(
    immer((set, get) => ({
      defaults,
      options,
      filters: searchParamFilters || defaults || [],
      actions: {
        getQueryFilters: () => {
          return get()
            .filters.map((f) => {
              const option = get().options.find((o) => o.id == (f.id || f.col));

              if (!!option?.buildQueryFilter) {
                return option.buildQueryFilter(f);
              } else {
                return f;
              }
            })
            .flat();
        },
        updateFilter: (updatedFilter) => {
          set((state) => {
            const indexToUpdate = state.filters.findIndex(
              (f) => f.key == updatedFilter.key
            );
            state.filters[indexToUpdate] = updatedFilter;
          });
        },
        addFilter: () => {
          set((state) => {
            const newFilter = buildNewFilter(get().options[0].col, nanoid(), options);

            state.filters.push(newFilter);
          });
        },
        removeFilter: (key) => {
          set((state) => {
            state.filters = state.filters.filter((f) => f.key != key);
          });
        },
      },
    }))
  );

export const QueryContextProvider = ({
  children,
  onChange,
  defaults,
  options,
  useSearchParams,
  searchParamsKey = 'query',
}) => {
  const searchParamFilters = useSearchParams && parseSearchParams(searchParamsKey);
  const store = useRef(createStore({ options, defaults, searchParamFilters })).current;

  return (
    <QueryContext.Provider value={store}>
      {children}
      <SearchParamsListener useSearchParams searchParamsKey={searchParamsKey} />
    </QueryContext.Provider>
  );
};

export const useQueryContext = (selector) => useStore(useContext(QueryContext), selector);

const SearchParamsListener = ({ useSearchParams, searchParamsKey }) => {
  const filters = useQueryContext((state) => state.filters);

  useEffect(() => {
    if (!useSearchParams) return;
    buildSearchParams(filters, searchParamsKey);
  }, [filters]);
};
