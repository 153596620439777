import { useState, useEffect } from "react";
import { GET_STAT_REPORTS_V2_PAGINATED } from "apollo/queries/statsv2.queries";
import { Column, Container } from "components/bdd/Layout";
import useDebounce from "components/bdd/hooks/usedebounce";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { BDDLoader } from "components/bdd/bddloader";
import BDDApiError from "components/bdd/bddapierror";
import { EditStatReport, ScrollableOptions, StatReportDetail, StatReportOption } from "./StatReportSelector";
import { Col, Row } from "react-bootstrap";
import { theme } from "constants";
import StatDefinitionSearchSelect from "./useStatDefinitionsSearchSelect";
import { Typography } from "components/bdd/Typography";
import useStatDefinitionSearchSelect from "./useStatDefinitionsSearchSelect";


const Input = styled.input({
  borderRadius: '5px',
  border: '1px solid #aaa',
  width: '100%',
  padding: '5px'
})

export default function StatReportSearch({
  target,
  handleSelect
}) {
  const [selected, setSelected] = useState(null)
  const [edit, setEdit] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 200)

  const { 
    selected: includesStatSlug, 
    searchComponent: statDefSearch 
  } = useStatDefinitionSearchSelect()

  const { data, loading, error } = useQuery(GET_STAT_REPORTS_V2_PAGINATED, {
    variables: { 
      search: debouncedSearch, 
      includesStatSlug,
      target,
      limit: 20 },
    skip: search.length === 0 && !includesStatSlug
  })

  const noResults = data?.statReportsV2Paginated?.data?.length === 0

  useEffect(() => {
    if (!!data && data.statReportsV2Paginated.data.length) {
      setSelected(data.statReportsV2Paginated.data[0].slug)
    } if (!!data && noResults) {
      setSelected(null)
    }
  }, [data])

  return <Container>
    <Column gap={2}>
      <div>
        <Input 
          placeholder='Search for stat report'
          value={search}
          onChange={ev => setSearch(ev.target.value)}
        />
      </div>
      <Typography variant='body1' style={{textAlign: 'center'}}>
        -OR-
      </Typography>
      <div>
        {statDefSearch}
      </div>
    </Column>
    <hr></hr>
    <Container>
      {loading && <BDDLoader variant='squares'/>}
      {error && <BDDApiError error={error}/>}
      {edit ? <EditStatReport reportSlug={edit} handleBack={() => setEdit(null)}/>
      : noResults ? <em>No reports found</em>
      : <Row>
        <Col md={4}>
          <ScrollableOptions>
            {data?.statReportsV2Paginated?.data.map(r => 
              <StatReportOption key={r.slug}
                selected={r.slug === selected} 
                report={r}
                handleSelect={() => setSelected(r.slug)}
              />)}
          </ScrollableOptions>
        </Col>
        <Col style={{ height: '400px', overflow: 'auto' }}>
          {!!selected && <StatReportDetail 
            reportSlug={selected} 
            handleEditReport={() => setEdit(selected)}
            handleSelect={handleSelect}
          />}
        </Col>
      </Row>}
    </Container>
  </Container>
}