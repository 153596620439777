import { Container } from 'components/bdd/Layout';
import { useContext, useEffect, useState } from 'react';
import { VideoContext } from '../..';
import { SelectFilter } from './SelectFilter';
import { useQuery } from '@apollo/client';
import { GET_TEAMS_BY_LEAGUE } from 'apollo/queries/teams.queries';

export const OppTeamFilter = ({ isMulti = true }) => {
  const { sourceFilters, setSourceFilters } = useContext(VideoContext);
  const filterLeagueSlug = sourceFilters['league_slug']?.val;
  const [leagueSlug, setLeagueSlug] = useState(filterLeagueSlug);
  const { data, loading, error } = useQuery(GET_TEAMS_BY_LEAGUE, {
    skip: !leagueSlug,
    variables: { league: leagueSlug },
  });

  const options = data?.allSlTeams.edges.map((t) => ({
    value: t.node.slug,
    label: t.node.longname,
  }));

  useEffect(() => {
    if (!filterLeagueSlug || filterLeagueSlug == leagueSlug) return;

    // clear team filter if league has changed
    setSourceFilters({
      opp_team_slug: {
        col: 'opp_team_slug',
        val: null,
      },
    });

    setLeagueSlug(sourceFilters['league_slug']?.val);
  }, [filterLeagueSlug]);

  return (
    <Container minWidth={200}>
      <SelectFilter
        col="opp_team_slug"
        id="opp_team_slug"
        label={isMulti ? 'Opponents' : 'Opponent'}
        options={options || []}
        isMulti={isMulti}
        selectProps={{
          isClearable: true,
          isLoading: loading,
        }}
      />
    </Container>
  );
};
