import { useQuery } from "@apollo/client";
import { GET_BDD_TEAMS } from "apollo/queries/teams.queries";

export default function useBddTeams({
  leagueSlug = 'NHL', // NOTE this is an SL league slug
  activeOnly=true,
  skip = false,
}) {
  const { data, loading } = useQuery(GET_BDD_TEAMS, {
    variables: { slLeagueSlug: leagueSlug, activeOnly },
    skip
  });

  const bddTeams = data?.bddTeams;
  const bfToBdd = (bfSlug) => {
    return bddTeams?.find(t => t.bfTeamSlug === bfSlug);
  }
  const slToBdd = (slSlug) => {
    return bddTeams?.find(t => t.slTeamSlug === slSlug);
  }

  return {
    data,
    bddTeams: data?.bddTeams,
    bfToBdd,
    slToBdd,
    loading
  }
}
