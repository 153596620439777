import * as React from 'react';
import './bddloader.css';

export function BDDLoader({
  variant = 'roller', // one of "roller", "squares", "ellipsis" or "original"
  color = 'gold',
  nocenter = false,
}) {
  /* Loader animation (css based) */
  if (variant === 'original') return <div className="bdd-loader"></div>;
  const loader =
    variant === 'roller' ? (
      <div className={`lds-roller ${color}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : variant === 'squares' ? (
      <div className={`lds-squares ${color}`}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : variant === 'squares-sm' ? (
      <div className={`lds-squares-sm ${color}`}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : variant === 'ellipsis' ? (
      <div className={`lds-ellipsis ${color}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : null;
  if (nocenter) return loader;
  return <center>{loader}</center>;
}
