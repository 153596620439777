import { IconButton } from 'components/bdd/Button';
import { ModalContext } from 'components/bdd/Modal';
import { ShiftVideoController, VideoContext } from 'components/bdd/Video';
import { ShiftVideoPlayer } from 'components/bdd/Video/components';
import { useContext } from 'react';
import { CameraVideo } from 'react-bootstrap-icons';

export const GameShiftVideoButton = ({
  playerId,
  gameUid,
  leagueSlug,
  season,
  gameType,
}) => {
  const sourceFilters = {
    game_uid: {
      col: 'game_uid',
      val: gameUid,
    },
    league_slug: {
      col: 'league_slug',
      val: leagueSlug,
    },
    season: {
      col: 'season',
      val: season,
    },
    game_type: {
      col: 'game_type',
      val: gameType,
    },
  };

  return (
    <ShiftVideoButton
      slPlayer={{
        playerId,
      }}
      sourceFilters={sourceFilters}
    />
  );
};

export const ShiftVideoButton = ({
  slPlayer,
  eventFilters,
  sourceFilters,
  secondaryPlayerFilters,
  onClick,
}) => {
  const { renderModal } = useContext(ModalContext);

  return (
    <IconButton
      tooltip={'Watch shift video'}
      icon={<CameraVideo />}
      onClick={() => {
        renderModal({
          body: (
            <ShiftVideoController
              slPlayer={slPlayer}
              sourceFilters={sourceFilters}
              secondaryPlayerFilters={secondaryPlayerFilters}
              eventFilters={eventFilters}
            >
              <ShiftVideoPlayer />
            </ShiftVideoController>
          ),
          size: 'xxl',
        });

        onClick && onClick();
      }}
    />
  );
};
