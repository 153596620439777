import { PostgameHeader } from 'components/SLStats';
import BDDStatTableV2 from 'components/bdd/Stats/BDDStatTableV2';
import { Typography } from 'components/bdd/Typography';
import { EventVideoController } from 'components/bdd/Video';
import { cellHoverCallback, highlightCallback } from './helpers';
import { GameExtrasHeader } from 'components/SLStats/GameExtrasHeader';
import { Container, Row } from 'components/bdd/Layout';

export const ByGameStatTable = ({ byGameStatContext }) => {
  const { stats, data } = byGameStatContext;

  if (!stats) return <Typography variant="body2">Loading...</Typography>;

  const rows = stats.map((s) => ({ ...s, statContext: byGameStatContext }));

  const labelColumn = {
    id: 'header',
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container width={130}>
          <Typography variant="stat">
            <Row columnGap={2}>
              <Container width={70}>
                <b>
                  <PostgameHeader data={d} />
                </b>
              </Container>
              <GameExtrasHeader data={d} />
            </Row>
          </Typography>
        </Container>
      );
    },
  };

  return (
    <Typography variant="stat">
      <EventVideoController>
        <BDDStatTableV2
          leaveSpaceForSort
          data={rows}
          indexColumns={[labelColumn]}
          format={data.format}
          cellHoverCallback={cellHoverCallback}
          highlight
          highlightCallback={highlightCallback}
          statSlugs={data.format?.stat_definitions
            .filter((sd) => sd.slug != 'toi')
            .map((sd) => sd.slug)}
        />
      </EventVideoController>
    </Typography>
  );
};
