import { PlayerHoverContent } from "components/PlayersV2/Hover/PlayerHoverContent";
import PlayerHover from "components/PlayersV2/Hover/playerhover";
import { GeneralPickIcon } from "components/ProScouting/Draft/DraftPickIcon";
import { BFTradeBreakdown } from "components/ProScouting/Transactions/Trades/BFTradeBreakdown";
import { useButtonGroup } from "components/bdd/ButtonGroup";
import { Divider } from "components/bdd/Divider";
import { Border, Column, Container, Row } from "components/bdd/Layout";
import { ModalContext } from "components/bdd/Modal";
import { Typography } from "components/bdd/Typography";
import { useContext } from "react";
import { UpdateBFPickForm } from "./UpdateBFPickForm";
import { useUser } from "helpers/user";

export const DraftPickInfo = ({ pick, isEditable, showOriginalTeam=true, fadeLogo=false }) => {
  const { renderModal } = useContext(ModalContext);
  const { isUserAdmin } = useUser();

  const shouldModalRender = (isUserAdmin() && isEditable) || !!pick.trades?.length;

  const handleRenderModal = (pick) => {
    if (!shouldModalRender) return null;
    renderModal({
      size: 'xl',
      title: <Container>
        <Row gap={4}>
          <GeneralPickIcon draftPick={pick} showTooltip={false} />
          <Typography variant='h5'>Round {pick.round}, #{pick.overall} Overall</Typography>
        </Row>
        <Row gap={8}>
          {pick.currentTeamSlug !== pick.originalTeamSlug && <Typography variant='body2'>
            Originally {pick.originalTeam.name}
          </Typography>}
        </Row>
      </Container>,
      body: (
        <PickInfoModal pick={pick} isEditable={isEditable} />
      )
    })
  }


  if (!pick) return null;
  return (
    <Container 
      onClick={() => handleRenderModal(pick)}
      style={{ cursor: 'pointer' }}
    >
      {!!pick.player
        ? (
          <Row columnGap={1}>
            <GeneralPickIcon draftPick={pick} showOriginalTeam={showOriginalTeam} height='15px' fadeLogo={fadeLogo} />
            <PlayerHover playerSlug={pick.player?.bddPlayer?.slug} delay={{ show: 1000, hide: 50 }}>
              <Typography variant='body2'>
                {pick.player.firstName} {pick.player.lastName}
              </Typography>
            </PlayerHover>
          </Row>
        ) : (
        <Row justifyContent='center'>
          <GeneralPickIcon draftPick={pick} showOriginalTeam={showOriginalTeam} fadeLogo={fadeLogo} />
        </Row>
        )
      }
    </Container>
  )
}


const PickInfoModal = ({ pick, isEditable}) => {
  const { isUserAdmin } = useUser();
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: (isUserAdmin() && isEditable) ? 'edit' : 'trades',
    options: [
      { label: 'Trades', value: 'trades', disabled: !pick.trades?.length  },
      { label: 'Edit', value: 'edit', disabled: !(isUserAdmin && isEditable)  },
    ]
  });

  return (
    <Container padding={2}>
      <Column gap={2}>
        <Container width={100}>
          {buttonGroup}
        </Container>

        {selectedValue === 'trades' && !!pick.trades?.length 
          ? (
          <Container padding={2}>
            <Typography variant='h6'>Involved in {pick.trades.length} Trades</Typography>
            <Column>
              {pick.trades.map(t => (
                <Container key={t.id}>
                  <BFTradeBreakdown
                    tradeId={t.id}
                  />
                  <Divider/>
                </Container>
              ))}
              {pick.trades.length === 0 && <Typography variant='body1'>This pick has not been traded</Typography>}
            </Column>
          </Container>
        ) : selectedValue === 'edit' ? (
          <UpdateBFPickForm 
            pick={pick} 
          />
        ) : null}
      </Column>

    </Container>
  );
}