import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Field } from './Field';
import { Border, Column, Container, Row } from '../Layout';
import { LinkPopover } from '../Popover';
import { theme } from 'constants/theme';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { HexColorPicker } from 'react-colorful';
import useDebounce from '../hooks/usedebounce';
import { useState } from 'react';

const defaultColors = [
  'white',
  '#ffedc6',
  '#CCE4FF',
  '#D0ECD9',
  '#F8D3D3',
  '#DBCDF0',
  '#fed7aa',
];

const HexColorPickerStyles = styled.div({
  '.react-colorful': {
    width: 'auto',
  },
});

export const ColorFilter = ({ id, label, colors, onChange, ...fieldProps }) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  useRegisterFilterTag(id, filter && <FilterTag name={label} label={filter?.value} />);

  const handleChange = (value) => {
    onChange && onChange(value);
    setFilters(id, { id, value: value, cond: '=' });
  };

  return (
    <Field id={id} onChange={onChange} label={label} {...fieldProps}>
      <LinkPopover
        hideTransition
        placement="bottom"
        renderLink={(setShow, target) => (
          <Container
            ref={target}
            onClick={(e) => {
              e.stopPropagation();
              setShow((show) => !show);
            }}
          >
            <ColorBox c={filter?.value || 'white'} onChange={onChange} />
          </Container>
        )}
        renderContent={(close) => (
          <Container>
            <Column gap={2}>
              <HexColorPickerStyles>
                <HexColorPicker
                  color={filter?.value || defaultColors[0]}
                  onChange={handleChange}
                />
              </HexColorPickerStyles>
              <ColorPicker id={id} onChange={handleChange} colors={colors} />
            </Column>
          </Container>
        )}
      />
    </Field>
  );
};

const ColorPicker = ({ id, onChange, colors }) => {
  return (
    <Row columnGap={2} rowGap={1} alignItems="start" flexWrap>
      {(colors || defaultColors).map((c) => (
        <ColorBox key={c} onChange={onChange} c={c} />
      ))}
    </Row>
  );
};

const ColorBox = ({ c, onChange }) => (
  <Border
    key={c}
    borderRadius={1}
    onClick={() => onChange && onChange(c)}
    style={{ overflow: 'hidden' }}
  >
    <Container height={28} width={28} variant="button" style={{ backgroundColor: c }} />
  </Border>
);
