import React, { createContext } from 'react';
import useWindowDimensions from '../hooks/usewindowdimensions';

const WindowContext = createContext();

const WindowContextProvider = ({ children }) => {
  const windowDimensions = useWindowDimensions();

  return (
    <WindowContext.Provider value={windowDimensions}>
      {children}
    </WindowContext.Provider>
  );
};

export { WindowContext, WindowContextProvider };
