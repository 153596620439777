import { Column, Container, Row } from "components/bdd/Layout"
import { ListRecencyView } from "./ListRecencyView"
import { usePlayersFromLists } from "components/Lists/hooks";
import { useQuery } from "@apollo/client";
import { buildPlayerListQuery } from "apollo/queries/playerlist.queries";
import { LIST_BUILDER_PLAYER_FIELDS } from "../ListBuilder";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { getBDDPlayer, getRinknetPlayer } from "../helpers";
import { useBddDateRangePicker } from "components/bdd/hooks/useBddDateRangePicker";
import { formatDate, getDateDaysBack } from "helpers/helpers";
import { buildBDDScheduleQuery } from "apollo/queries/scoutingobjectives.queries";
import { Divider } from "components/bdd/Divider";
import { Label } from "components/bdd/Label/Label";


export const ListBuilderRecencyWrapper = ({
  playerList, // id, idType
}) => {
  const { data, placeholder } = usePlaceholder(useQuery(
    buildPlayerListQuery(LIST_BUILDER_PLAYER_FIELDS[playerList.idType]), {
    variables: { id: playerList.id },
  }));

  const bddPlayerSlugs = data?.playerList?.players?.map(p => getBDDPlayer(p, playerList)?.slug);
  const rinknetIds = data?.playerList?.players?.map(p => getRinknetPlayer(p, playerList)?.realId);

  const { datePicker, startDate, endDate } = useBddDateRangePicker({
    initialStartDate: getDateDaysBack(7),
    initialEndDate: new Date()
  });

  const rinknetGameIds = [];
  if (!!placeholder) return placeholder;
  return (
    <Container>
      <Column gap={1}>
        <Row gap={16}>
          <Container width={300}>
            <Label>Date Range</Label>
            {datePicker}
          </Container>
        </Row>
        <Divider/>
        <ListRecencyView
          targetLists={[playerList]}
          bddPlayerSlugs={bddPlayerSlugs}
          rinknetIds={rinknetIds}
          rinknetGameIds={rinknetGameIds}
          startDate={startDate}
          endDate={endDate}
        />
      </Column>
    </Container>
  )
}