import { Container } from 'components/bdd/Layout';
import React from 'react';
import Event from '../Filtering/Event';

export default function VideoClipList({
  events,
  currentEventId,
  onPlay,
  eventProps = {},
}) {
  const isSelected = (eid) => {
    if (Array.isArray(currentEventId)) {
      return currentEventId.includes(eid);
    }

    return currentEventId == eid;
  };

  return (
    <Container>
      {events.map((e) => (
        <Row columnGap={1}>
          <input type="checkbox" />
          <Event
            key={e.id}
            event={e}
            onPlay={onPlay}
            selected={isSelected(e.eventId)}
            {...eventProps}
          />
        </Row>
      ))}
    </Container>
  );
}
