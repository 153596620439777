import { NoColorLink } from 'components/bdd/Button';
import { Container } from 'components/bdd/Layout';
import { TooltipSpan } from 'components/reports';
import { formatDate } from 'helpers/helpers';
import { buildPostgameRoute } from 'helpers/routing';
import { Link } from 'react-router-dom';

export const PostgameHeader = ({ data }) => {
  return (
    <Container>
      <NoColorLink
        as={Link}
        to={buildPostgameRoute(
          data.game_uid,
          data.team_slug,
          data.position.toLowerCase() === 'f'
            ? 'forwards'
            : data.position.toLowerCase() === 'd'
            ? 'defensemen'
            : data.position.toLowerCase() === 'g'
            ? 'goalies'
            : ''
        )}
      >
        <TooltipSpan content="Go to postgame">
          {formatDate(data.game_date, { format: '%m/%n' })} vs. {data.opp_team_shorthand}
        </TooltipSpan>
      </NoColorLink>
    </Container>
  );
};
