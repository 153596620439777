import { gql } from "@apollo/client"
import { PageInfoFragment } from "./pagination.queries"

export const STAT_ENGINE_V2_QUERY = gql`query SLStats($q: GenericScalar!) {
  slStats(q: $q)
}`

export const FilterSetV2Fragment = gql`
    fragment filterSetV2Fragment on SLStatFilterSetV2 {
        id
        manpowerSituation
        strength
    }
`


export const GET_FSETS_V2 = gql`{
    slStatsFilterSetsV2 {
      ...filterSetV2Fragment
    }
} ${FilterSetV2Fragment}`

export const StatDefinitionV2Fragment = gql`
    fragment statDefinitionV2Fragment on StatDefinitionV2 {
        id
        slug
        statType
        primaryName
        shortName
        description
        args
        kwargs
    }
`

export const CREATE_STAT_V2 = gql`
mutation CreateStatV2($input: CreateStatInputV2!, $overwrite: Boolean){
    createStatV2(input: $input, overwrite: $overwrite) {
      statDefinitionV2 {
        ...statDefinitionV2Fragment
      }
    }
} ${StatDefinitionV2Fragment}`


export const GET_STAT_DEFINITION_V2 = gql`query StatDefinitionV2(
  $slug: ID!
) {
  statDefinitionV2(
      slug: $slug
  ) {
      ...statDefinitionV2Fragment
      negative
      countingStat
      statArgs
      dependsOn {
        ...statDefinitionV2Fragment
        statArgs
      }
  }
} ${StatDefinitionV2Fragment}`

export const GET_STAT_DEFINITIONS_V2 = gql`query StatDefinitionsV2(
    $limit: Int,
    $offset: Int,
    $search: String,
    $sort: [BDDSortV2],
    $statTypes: [StatTypes!]
) {
    statDefinitionsV2(
        limit: $limit,
        offset: $offset,
        search: $search,
        sort: $sort,
        statTypes: $statTypes
    ) {
        pageInfo {
            ...pageInfoFragment
        }
        data {
            ...statDefinitionV2Fragment
        }
    }
} ${PageInfoFragment} ${StatDefinitionV2Fragment}`

export const UPDATE_STAT_V2 = gql`mutation UpdateStatV2(
    $input: UpdateStatInputV2!
) {
    updateStatV2(input: $input) {
        statDefinitionV2 {
            ...statDefinitionV2Fragment
        }
    }
} ${StatDefinitionV2Fragment}`

// Reports
export const StatReportV2Fragment = gql`
    fragment statReportV2Fragment on StatReportV2 {
        id
        slug
        name
        description
        target
        lastModified
        dateCreated
        isBddReport
        isPublic
        isPublicEdit
        userId
        priorityRank
        user { id username }
}`

// A repeat fragment but this time with the stats include
export const StatReportV2StatsFragment = gql`
fragment statReportV2StatsFragment on StatReportV2 {
    id
    slug
    name
    description
    target
    lastModified
    dateCreated
    isBddReport
    isPublic
    isPublicEdit
    user { id username }
    stats {
        label
        statNumber
        filterSetId
        filterSet {
          ...filterSetV2Fragment
        }
        norm
        statSlug
        stat {
            ...statDefinitionV2Fragment
        }
    }
} ${StatDefinitionV2Fragment} ${FilterSetV2Fragment}`

export const CREATE_STAT_REPORT_V2 = gql`
    mutation CreateStatReportV2($input: CreateStatReportInputV2!) {
    createStatReportV2(input: $input) {
        statReportV2 {
            ...statReportV2StatsFragment
        }
    }
} ${StatReportV2StatsFragment}`

export const GET_STAT_REPORT_V2 = gql`
    query GetStatReportV2($slug: ID!) {
        statReportV2(slug: $slug) {
            ...statReportV2StatsFragment
        }
    } ${StatReportV2StatsFragment}`

export const GET_STAT_REPORTS_V2 = gql`
    query GetStatReportsV2(
      $targets: [String!],
      $hide: [String!]
    ) {
        statReportsV2(targets: $targets, hide: $hide) {
            ...statReportV2Fragment
        }
    } ${StatReportV2Fragment}`

export const GET_STAT_REPORTS_V2_PAGINATED = gql`
    query GetStatReportsV2Paginated($search: String, $includesStatSlug: ID, $target: String, $sort: [BDDSortV2], $limit: Int) {
        statReportsV2Paginated(
          search: $search, 
          includesStatSlug: $includesStatSlug, 
          sort: $sort, 
          limit: $limit,
          target: $target
        ) {
            data {
                ...statReportV2Fragment
            }
            pageInfo {
                ...pageInfoFragment
            }
        }
    } ${StatReportV2Fragment} ${PageInfoFragment}`

export const CREATE_UPDATE_STAT_REPORTS_V2 = gql`
    mutation CreateUpdateStatReportV2(
        $input: UpdateStatReportInputV2!,
        $updateOnly: Boolean,
        $createOnly: Boolean,
        $statsToAdd: [StatReportStatInputV2!],
        $statsToRemove: [ID!],
        $reorderStats: [ID!],
        $overwriteStats: [StatReportStatInputV2]
    ) {
    createUpdateStatReportV2(input: $input, 
        updateOnly: $updateOnly,
        createOnly: $createOnly,
        statsToAdd: $statsToAdd,
        statsToRemove: $statsToRemove,
        reorderStats: $reorderStats,
        overwriteStats: $overwriteStats
    ) {
            statReportV2 {
                ...statReportV2StatsFragment
            }
        }
    } ${StatReportV2StatsFragment}`

export const DELETE_STAT_REPORT_V2 = gql`
    mutation DeleteStatReportV2(
        $slug: String!
    ) {
        deleteStatReportV2(slug: $slug) {
            statReportV2 {
                id
            }
        }
    }
    `

export const SLPlayerRankingV2Fragment = gql`fragment slPlayerRankingV2Fragment on SLPlayerRankingsV2 {
  id
  playerId
  leagueSlug
  season
  gameType
  position
  norm
  stat
  ranking
  percentile
  value
  filterSet {
    ...filterSetV2Fragment
  }
  statDefinition {
    description
  }
}`

export const GET_RANKINGS_V2 = gql`
      query GetRankingsV2(
        $playerId: ID
        $statDefinitions: [SLPlayerRankingStatDefinition!]!
        $leagueSlug: String
        $gameType: String
        $position: String
        $season: Int
        $norm: String
      ) {
        slPlayerRankingsV2(
          playerId: $playerId
          leagueSlug: $leagueSlug
          gameType: $gameType
          statDefinitions: $statDefinitions
          position: $position
          season: $season
          norm: $norm
        ) {
          ...slPlayerRankingV2Fragment
          slPlayer {
            firstname
            lastname
          }
        }
      }
    ${FilterSetV2Fragment} ${SLPlayerRankingV2Fragment}`

const SLPlayerRankingSplitFragment = gql`fragment slPlayerRankingSplitFragment on SLPlayerRankingSplit {
  leagueSlug
  gameType
  season
  position
}`
export const GET_MULTI_RANKINGS_V2 = gql`
  query GetMultiRankingsV2(
    $slugs: [ID]
    $statDefinitions: [SLPlayerRankingStatDefinition!]!
    $season: Int
    $norm: String
  ) {
    multiSlPlayerRankingsV2(
      slugs: $slugs
      statDefinitions: $statDefinitions
      season: $season
      norm: $norm
    ) {
      bddPlayer {
        slug
      }
      focusRankings {
        key
        split {
          ...slPlayerRankingSplitFragment
        }
        rankings {
          ...slPlayerRankingV2Fragment
        }
      }
      allRankings {
        key
        split {
          ...slPlayerRankingSplitFragment
        }
        rankings {
          ...slPlayerRankingV2Fragment
        }
      }
    }
  }
  ${FilterSetV2Fragment} ${SLPlayerRankingV2Fragment} ${SLPlayerRankingSplitFragment}`

export const SLTeamRankingV2Fragment = gql`fragment slTeamRankingV2Fragment on SLTeamRankingsV2 {
  id
  teamSlug
  leagueSlug
  season
  gameType
  norm
  stat
  ranking
  percentile
  value
  filterSet {
    ...filterSetV2Fragment
  }
  statDefinition {
    description
    statType
    negative
  }
}`

export const GET_TEAM_RANKINGS_V2 = gql`
      query GetTeamRankingsV2(
        $teamSlug: ID
        $statDefinitions: [SLTeamRankingStatDefinition!]!
        $leagueSlug: String
        $gameType: String
        $season: Int
        $norm: String
      ) {
        slTeamRankingsV2(
          teamSlug: $teamSlug
          leagueSlug: $leagueSlug
          gameType: $gameType
          statDefinitions: $statDefinitions
          season: $season
          norm: $norm
        ) {
          ...slTeamRankingV2Fragment
          team {
            slug
            longname
            shorthand
            nhlid
          }
        }
      }
    ${FilterSetV2Fragment} ${SLTeamRankingV2Fragment}`


const DataAccessRequestFragment = gql`fragment dataAccessRequestFragment on DataAccessRequests {
  id
  hash
  title
  description
  createdAt
  lastRun
  pinPriority
  isArchived
  user {
    id
    name
  }
  query
}`
const DataAccessRequestRunFragment = gql`fragment dataAccessRequestRunFragment on DataAccessRequestRuns {
  requestHash
  timestamp
  userId
  response
}`

export const GET_DATA_ACCESS_REQUESTS = gql`query GetDataAccessRequests {
  dataAccessRequests {
    ...dataAccessRequestFragment
  }
} 
${DataAccessRequestFragment}
`

export const GET_DATA_ACCESS_REQUEST = gql`query GetDataAccessRequest($hash: ID!) {
  dataAccessRequest(hash: $hash) {
    ...dataAccessRequestFragment
    runs {
      ...dataAccessRequestRunFragment
    }
  }
} 
${DataAccessRequestFragment}
${DataAccessRequestRunFragment}
`

export const CREATE_DATA_ACCESS_REQUEST_RUN = gql`mutation CreateDataAccessRequestRun($q: GenericScalar!) {
  createDataAccessRequestRun(q: $q) {
    dataAccessRequest {
      ...dataAccessRequestFragment
      runs {
        ...dataAccessRequestRunFragment
      }
    }
    slStats
  }
}
${DataAccessRequestRunFragment}
${DataAccessRequestFragment}
`

export const UPDATE_DATA_ACCESS_REQUEST = gql`mutation UpdateDataAccessRequest($input: UpdateDataAccessRequestInput!) {
  updateDataAccessRequest(input: $input) {
    dataAccessRequest {
      ...dataAccessRequestFragment
      runs {
        ...dataAccessRequestRunFragment
      }
    }
  }
}
${DataAccessRequestFragment}
${DataAccessRequestRunFragment}
`