import { useEPStatsContext } from './context';
import { Button } from 'react-bootstrap';
import { LoadingPlaceholder } from 'components/bdd/Button';
import { Typography } from 'components/bdd/Typography';
import { Container, Row } from 'components/bdd/Layout';
import { ColumnDisplayButton, ExportDataButton } from 'components/bdd/Table';
import { buildIndexColumns } from './columns';

export const StatsControls = ({}) => {
  const areFiltersApplied = useEPStatsContext((state) => state.filtersApplied);
  const { setShowFilters, applyFilters } = useEPStatsContext((state) => state.actions);

  const columns = useEPStatsContext((state) => state.columns);
  const { tableData, pageControls, loading } = useEPStatsContext(
    (state) => state.results
  );

  return (
    <Container>
      <Row columnGap={1}>
        <Button
          variant={'primary'}
          size="sm"
          disabled={areFiltersApplied}
          onClick={() => {
            setShowFilters(false);
            applyFilters();
          }}
        >
          <LoadingPlaceholder loading={loading} variant="stat">
            <Typography variant="stat">
              <b>Update Result</b>
            </Typography>
          </LoadingPlaceholder>
        </Button>
        {!!tableData && tableData.length > 0 && (
          <>
            <ExportDataButton data={tableData} columns={columns} />
            <ColumnDisplay />
          </>
        )}
        {pageControls}
      </Row>
    </Container>
  );
};

const ColumnDisplay = ({}) => {
  const indexColumnIds = useEPStatsContext((state) => state.indexColumnIds);
  const idToColumn = useEPStatsContext((state) => state.idToColumn);
  const { setIndexColumnIds } = useEPStatsContext((state) => state.actions);

  // We're going to show all stat columns for ease of switching between goalies/skaters
  const filteredIdToColumn = Object.keys(idToColumn)
    .filter((key) => !key.includes('stats_'))
    .reduce((acc, key) => {
      acc[key] = idToColumn[key];
      return acc;
    }, {});

  return (
    <ColumnDisplayButton
      idToColumn={filteredIdToColumn}
      columnIdsToDisplay={indexColumnIds}
      setColumnIdsToDisplay={setIndexColumnIds}
    />
  );
};
