import React, { useRef, useEffect, useState } from 'react'
import { useQueryWithPlaceholder } from 'apollo/hooks'
import styled from 'styled-components';

import { GET_MEDIA_FOR_GAME } from 'apollo/queries/video.queries'
import useBddVideoPlayer from 'components/bdd/Video/hooks/useBddVideoPlayer';
import VideoPlayer from './VideoPlayer';
import VideoClipList from './VideoClipList';
import { theme } from 'constants/theme';
import { useQuery } from '@apollo/client';
import { GET_FACEOFF_EVENT_LIST } from 'apollo/queries/events.queries';
import { useMemo } from 'react';
import { HoverInteractive } from 'components/bdd/bddcomponents';
import { Col, Row } from 'react-bootstrap';
import BDDSelect from 'components/bdd/bddselect';

const Container = styled.div({
  display: 'flex'
})
const VideoContainer = styled.div({
  flexGrow: 1,
  padding: theme.spacing[5]
})
const ClipListContainer = styled.div({
  // minWidth: '200px'
  flex: '0 0 300px'
})

const AlertBanner = styled.div({
  ...theme.font.base,
  ...theme.typography.heading,
  fontWeight: 'bold',
  padding: '30px',
  backgroundColor: '#a53535',
  color: '#ccc'
})

const ApplyFiltersStyles = styled.div({
  ...theme.font.base,
  fontSize: '0.8em',
  color: 'blue',
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline'
  }
})

export default function FGVideoController({
  gameUid, period
}) {
  if (!gameUid || !period) console.error(`Must have non null gameUid and period`)

  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const { data, placeholder } = useQueryWithPlaceholder(GET_MEDIA_FOR_GAME, { gameUid, period })
  
  const clip = !!data ? data.getMediaForGame : null
  const clipId = !!data ? `${gameUid}${period}` : null

  const { data: eventData, loading, error } = useQuery(GET_FACEOFF_EVENT_LIST, { variables: {
    gameUid, period
  }})

  // get options for teamslug/eventname...
  const [allTeamSlugs, allEventNames] = useMemo(() => {
    if (!eventData) return [[], []]
    const teams = new Set()
    const eventNames = new Set()
    eventData.getFaceoffEventList.forEach(fel => {
      fel.events.forEach(e => {
        teams.add(e.teamSlug)
        eventNames.add(e.eventName)
      })
    })
    return [Array.from(teams), Array.from(eventNames)]
  }, [eventData])

  const videoRef = useRef(null)
  const videoControls = useBddVideoPlayer({
    clipId,
    videoRef, 
    src: clip?.videoUrl,
    autoPlay: true
  })

  const {
    playerState
  } = videoControls

  const fel = !!eventData ? eventData.getFaceoffEventList : null
  const currSpan = useMemo(() => {
    if (!fel || !fel.length) return null
    for (let i = 0; i < fel.length; i++) {
      if (fel[i].videoLink.videoTimeS >= playerState.currentTime) {
        if (i > 0) {
          return fel[i-1]
        } else {
          return fel[0]
        }
      }
    }
    return fel[fel.length-1]
  }, [eventData, playerState.currentTime])

  const filterEvents = e => {
    if (!!filters.teamSlug && filters.teamSlug !== e.teamSlug) return false
    if (!!filters.eventName && !!filters.eventName.length && !filters.eventName.includes(e.eventName)) return false

    return true
  }

  var currentEventIds = []
  var focusEvents = []
  if (!!currSpan) {
    const { videoLink, events } = currSpan
    const currGameTime = videoLink.gameTimeS + Math.max(playerState.currentTime - videoLink.videoTimeS, 0)
    focusEvents = events.filter(e => Math.abs(e.gameTime - currGameTime) < 5).filter(filterEvents)
    currentEventIds = focusEvents.filter(e => Math.abs(e.gameTime - currGameTime) < 2).map(e => e.id)
  }

  const handleJumpToEvent = () => {

  }

  return <>
    {!!data && !data.getMediaForGame && <AlertBanner>No video found for this game</AlertBanner>}
    <Container>
      <VideoContainer>
        <VideoPlayer 
          src={clip?.videoUrl}
          videoRef={videoRef}
          // eventTimePct={eventTimePct}
          clipInfo={clip}
          {...videoControls} 
        />
        
      </VideoContainer>
      <ClipListContainer>
        <ApplyFiltersStyles onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? 'Show Events' : 'Apply Filters'}
        </ApplyFiltersStyles>
        {showFilters ?
          <EventFeedFilters 
            allEventNames={allEventNames}
            allTeamSlugs={allTeamSlugs}
            filters={filters} 
            setFilters={setFilters}
          />
        : <VideoClipList 
          events={focusEvents} 
          currentEventId={currentEventIds} 
          eventProps={{
            small: true
          }}/>}
      </ClipListContainer>
    </Container>
  </>
}

const EventFeedFilters = ({ 
  allTeamSlugs=[],
  allEventNames=[],
  filters, 
  setFilters 
}) => {
  return <div style={{minWidth: '200px'}}>
    <Row>
      <Col>
        <BDDSelect
          isClearable
          name='teamSlug'
          placeholder='Team'
          options={allTeamSlugs.map(t => ({ value: t, label: t }))}
          value={filters.teamSlug}
          onChange={(n, v) => setFilters({ ...filters, teamSlug: v })}
        />
      </Col>
    </Row>
    <Row style={{marginTop: '10px'}}>
      <Col>
        <BDDSelect
          isClearable
          isMulti
          isSearchable
          name='eventName'
          placeholder='Event Name'
          options={allEventNames.map(t => ({ value: t, label: t }))}
          value={filters.eventName}
          onChange={(n, v) => setFilters({ ...filters, eventName: v })}
        />
      </Col>
    </Row>
  </div>
}