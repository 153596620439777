import React, { useEffect } from 'react';
import { ContractSearchSelect } from '../ContractSearchSelect';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useSimilarContractsContext } from './context';
import { Filters, useFilterContext } from 'components/bdd/Filters';
import {
  goalieSimilarContractWeightPresets,
  similarContractWeightPresets,
} from './constants';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import { GET_BF_CONTRACT } from 'apollo/queries/bruinsfriendly.queries';

export const TargetDetails = ({ hideContractSelect }) => {
  const targetDetails = useSimilarContractsContext((state) => state.targetDetails);
  const { setState } = useSimilarContractsContext((state) => state.actions);

  const filters = useSimilarContractsContext((state) => state.filters);
  const positionFdg = targetDetails?.['position_fdg']?.value;
  const contractPresets =
    positionFdg == 'G'
      ? goalieSimilarContractWeightPresets
      : similarContractWeightPresets;

  const targetPresetOptions =
    targetDetails.targetMode.value == 'future'
      ? [
          { value: 'player_type', label: 'Default' },
          { value: 'production', label: 'Production' },
          { value: 'bio', label: 'Bio' },
        ]
      : [
          { value: 'player_type', label: 'Default' },
          { value: 'production', label: 'Production' },
          { value: 'contract', label: 'Contract' },
          { value: 'bio', label: 'Bio' },
        ];

  return (
    <Filters
      defaults={targetDetails}
      onChange={(targetDetails) => setState({ targetDetails })}
    >
      <Row columnGap={3} alignItems="start" flexWrap>
        <Filters.ButtonGroup
          id="targetMode"
          label="Target mode"
          options={[
            { value: 'future', label: 'Active Players' },
            { value: 'current', label: 'Existing Contracts' },
          ]}
          onChange={(targetMode, { setFilters }) => {
            if (targetMode == 'future') {
              setFilters('presets', {
                id: 'presets',
                type: 'select',
                value: 'player_type',
              });
              setFilters('statsMode', {
                id: 'presets',
                type: 'select',
                value: 'combined',
              });
            }
          }}
        />
        {/* <Container width={250}>{playerSearch}</Container> */}
        <ContractSearchSelectFilter hideContractSelect={hideContractSelect} />
        <Filters.ButtonGroup
          id="presets"
          label="Presets"
          options={targetPresetOptions}
          onChange={(value) => {
            setState({
              targetWeights: contractPresets[targetDetails.targetMode.value][value],
            });
          }}
        />
        {targetDetails.targetMode.value != 'future' && (
          <>
            <Filters.ButtonGroup
              id="statsMode"
              label="Stats mode"
              options={[
                { value: 'combined', label: 'Combined' },
                { value: 'split', label: 'Split' },
              ]}
            />
            <Filters.Number id="priorSeasons" label="Prior seasons" min={0} max={5} />
          </>
        )}
        <Filters.ButtonGroup
          id="nNeighbours"
          label={'# Results'}
          options={[
            {
              value: 10,
              label: '10',
            },
            {
              value: 20,
              label: '20',
            },
            {
              value: 50,
              label: '50',
            },
          ]}
        />
      </Row>
    </Filters>
  );
};

const ContractSearchSelectFilter = ({ hideContractSelect }) => {
  const targetDetails = useFilterContext((state) => state.filters);
  const { setFilters } = useFilterContext((state) => state.actions);
  const { setState } = useSimilarContractsContext((state) => state.actions);

  const { data: contractData } = usePlaceholder(
    useQuery(GET_BF_CONTRACT, {
      skip: !targetDetails?.id?.value && !targetDetails?.bddPlayerSlug?.value,
      variables: {
        id: targetDetails?.id?.value,
        bddPlayerSlug: targetDetails?.bddPlayerSlug?.value,
      },
    })
  );

  const bfPlayer = contractData?.bfContract.bfPlayer;
  const showContractDetails = targetDetails.targetMode.value == 'current';

  const handleContractChange = (contract) => {
    setFilters('id', { id: 'id', value: contract.id, mode: 'select' });

    setFilters('player_slug', {
      id: 'player_slug',
      value: contract.bfPlayer.slug,
      mode: 'select',
    });

    setFilters('position_fdg', {
      id: 'position_fdg',
      value: contract.bfPlayer.positionFdg,
      mode: 'select',
    });

    const contractPresets =
      contract.bfPlayer.positionFdg == 'G'
        ? goalieSimilarContractWeightPresets
        : similarContractWeightPresets;

    setState({
      targetWeights:
        contractPresets[targetDetails.targetMode.value][targetDetails.presets.value],
    });
  };

  useEffect(() => {
    if (!contractData) return;

    handleContractChange(contractData.bfContract);
  }, [contractData]);

  return (
    !hideContractSelect && (
      <Filters.Field
        id={'id'}
        label={showContractDetails ? 'Player contract' : 'Player'}
        width={200}
      >
        <ContractSearchSelect
          activeContractOnly={!showContractDetails}
          onChange={(v, contract) => {
            handleContractChange(contract);
          }}
          placeholder={
            bfPlayer
              ? `${bfPlayer.firstName} ${bfPlayer.lastName}`
              : showContractDetails
              ? 'Select Contract...'
              : 'Select Player...'
          }
        />
      </Filters.Field>
    )
  );
};
