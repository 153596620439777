import { useQuery } from "@apollo/client"
import { GET_RINKNET_BENCH_DEPTH_PLAYERS } from "apollo/queries/depthchart.queries"
import usePlaceholder from "components/Placeholder/usePlaceholder"
import React, { useState } from "react"
import styled from "styled-components"
import { itemTypes } from "../../constants"
import { BDDSelectSmall } from "../bdd"
import Draggable from "../bdd/draggable"

const Styles = styled.div`
    max-height: 50vh;
    overflow: scroll;
    padding: 10px;

    .position-header {
        font-size: 0.7em;
        font-weight: bold;
        text-align: center;
        margin-top: 5px;
    }
`
export default function RinknetBench({
  teamSlug,
  skipPlayerSlugs, // slugs to skip (because they're already in depth chart)
  renderPlayer
}) {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_RINKNET_BENCH_DEPTH_PLAYERS, {
      variables: { slTeamSlug: teamSlug },
      skip: !teamSlug
    })
  )
  const players = data?.getRinknetTeamDepth;

  const [league, setLeague] = useState('National Hockey League')

  if (placeholder) return placeholder;
  const skipSlugsSet = new Set(skipPlayerSlugs);
  var filteredPlayers = players.filter(p => 
    !skipSlugsSet.has(p.bddPlayerSlug?.slug) && 
    (league === 'all' || p.currentleague === league)
  );
  const leagueOrder = ['American Hockey League', 'National Hockey League']
  const positionOrder = ['LW', 'C', 'RW', 'LD', 'RD', 'G']
  filteredPlayers = filteredPlayers.sort((a, b) => {
    return leagueOrder.indexOf(b.currentleague) - leagueOrder.indexOf(a.currentleague) ||
      positionOrder.indexOf(a.masterdepthchartposition) - positionOrder.indexOf(b.masterdepthchartposition) ||
      b.caphitamount - a.caphitamount
  })

  return <Styles>
    <small>
      <b>Available Players (Rinknet)</b>
    </small>
    <hr></hr>
    <div>
      <BDDSelectSmall
        value={league}
        onChange={(n, v) => setLeague(v)}
        options={[
          { value: 'National Hockey League', label: 'NHL ' },
          { value: 'American Hockey League', label: 'AHL ' },
          { value: 'all', label: 'All ' }
        ]}
      />
    </div>
    <br></br>
    {filteredPlayers.length === 0 ? <em>No rinknet players found</em> : null}
    {filteredPlayers.map((p, i) => {
      const header = !filteredPlayers[i - 1] || filteredPlayers[i - 1].masterdepthchartposition !== p.masterdepthchartposition ?
        <div className='position-header'>{p.masterdepthchartposition}</div>
        : null
      return <div key={p.bddPlayerSlug.slug}>
        {header}
        <div style={{ borderRadius: '10px', border: '1px solid #ddd' }}>
          <Draggable data={{ slug: p.bddPlayerSlug.slug }} itemType={itemTypes.DEPTH_CHART_PLAYER}>
            {renderPlayer({ playerSlug: p.bddPlayerSlug.slug, rinknetPlayer: p, bddPlayer: p.bddPlayerSlug, showEditMenu: false })}
          </Draggable>
        </div>
      </div>
    })}
  </Styles>
}