import { useMutation, useQuery } from '@apollo/client';
import {
  BDDNoteFragment,
  CREATE_NOTE,
  GET_NOTES,
  UPDATE_NOTE,
} from 'apollo/queries/note.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { formatDateGQL } from 'helpers/helpers';
import { getPlayerFormData } from './helpers';
import { NoteCommentForm } from './NoteCommentForm';
import { useNoteActions } from './useNoteStore';
import { EMPTY_TEXT } from 'components/bdd/TextEditor';

export const useNotes = ({
  ids,
  userIds,
  entityIds,
  entityType,
  subEntityId,
  subEntityType,
  type,
  startDate,
  endDate,
  onlyPinned,
  includeMentions,
  includeSubEntityTypes,
  targetStatus,
  skip,
  noEntities = false, // if true, won't force at least one entity id to be supplied
  variant,
}) => {
  const variables = {
    ids,
    userIds,
    entityIds,
    entityType,
    subEntityId,
    subEntityType,
    type,
    startDate: formatDateGQL(startDate, { useTimezone: false }),
    endDate: formatDateGQL(endDate, { useTimezone: false }),
    onlyPinned,
    includeMentions,
    includeSubEntityTypes,
    targetStatus,
  };

  const { loadNotes, replaceNote } = useNoteActions();

  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE, {
    onError: (error) => toastBddApiError(error),
    onCompleted: (data) => {
      toastInfo('Note updated!');

      const { bddNote, newBddNote } = data.updateBddNote;

      if (!newBddNote) {
        loadNotes([bddNote]);
      } else {
        replaceNote(bddNote, newBddNote);
      }
    },
    update: (
      cache,
      {
        data: {
          updateBddNote: { bddNote, newBddNote },
        },
      }
    ) => {
      cache.modify({
        fields: {
          bddNotes: (existing = {}, { readField, DELETE }) => {
            const mentionUpdated = existing.mentions.find(
              (m) => readField('noteId', m) == bddNote.id
            );

            // TODO: Find a way to update mention cache properly
            if (mentionUpdated) {
              return DELETE;
            }

            const existingNotes = existing.notes.filter(
              (n) => readField('id', n) != bddNote.id
            );

            // Remove note from cache if archived
            if (bddNote.isArchived) {
              return {
                ...existing,
                notes: existingNotes,
              };
            }

            const newRef = cache.writeFragment({
              data: newBddNote || bddNote,
              fragment: BDDNoteFragment,
            });

            return {
              ...existing,
              notes: existingNotes.concat(newRef),
            };
          },
        },
      });
    },
  });

  const [createNote, { loading: createNoteLoading }] = useMutation(CREATE_NOTE, {
    onError: (error) => toastBddApiError(error),
    onCompleted: (data) => {
      toastInfo('Note created!');
      loadNotes([data.createBddNote.bddNote]);
    },
    update: (
      cache,
      {
        data: {
          createBddNote: { bddNote, bddPlayers, slTeams, proObjectives, proScenarios },
        },
      }
    ) => {
      cache.modify({
        fields: {
          bddNotes: (existing = {}, { readField }) => {
            const ret = {
              ...existing,
              bddPlayers: existing.bddPlayers.concat(bddPlayers),
              slTeams: existing.slTeams.concat(slTeams),
              proObjectives: existing.proObjectives.concat(proObjectives),
              proScenarios: existing.proScenarios.concat(proScenarios),
              notes: existing.notes.concat(bddNote),
            };
            return ret;
          },
        },
      });
    },
    refetchQueries: [
      {
        query: GET_NOTES,
        variables,
      },
    ],
  });

  const createEmptyNote = (variables) => {
    createNote({
      variables: {
        ...variables,
        mentions: [],
        files: [],
        note: JSON.stringify({
          comment: EMPTY_TEXT,
        }),
        noteType: 'GENERAL',
      },
    });
  };

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_NOTES, {
      onCompleted: (data) => loadNotes(data.bddNotes.notes),
      skip: skip || (!noEntities && (ids || []).concat(entityIds || []).length == 0),
      variables,
    })
  );

  const mentionNotes = data?.bddNotes.mentions
    ?.filter(
      (mention) => !data?.bddNotes.notes.find((note) => mention.bddNote.id == note.id)
    )
    .map((mention) => ({
      mention,
      ...mention.bddNote,
    }));

  const notes = data?.bddNotes.notes
    .filter((note) => {
      if (!entityIds) return true;
      return (
        !!entityIds.find((id) => id == note.entityId) ||
        entityIds.find((id) => id == note.subEntityId) ||
        subEntityId == note.entityId ||
        subEntityId == note.subEntityId
      );
    })
    .concat(mentionNotes)
    ?.map((note) => ({
      ...note,
      bddPlayer: data?.bddNotes.bddPlayers.find(
        (bdd) => bdd.slug == note.entityId || bdd.slug == note.subEntityId
      ),
      slTeam: data?.bddNotes.slTeams.find(
        (t) => t.slug == note.entityId || t.slug == note.subEntityId
      ),
      proObjective: data?.bddNotes.proObjectives.find(
        (p) => p.slug == note.entityId || p.slug == note.subEntityId
      ),
      proScenario: data?.bddNotes.proScenarios.find(
        (p) => p.id == note.entityId || p.id == note.subEntityId
      ),
    }))
    .sort(
      (a, b) =>
        b.isPinned - a.isPinned || new Date(b.dateCreated) - new Date(a.dateCreated)
    );

  const noteItems = notes?.map((n) => ({
    note: n,
    render: () => {
      let formData;

      if (n.entityType == 'BDD_PLAYER') {
        formData = getPlayerFormData(n.noteType, n.bddPlayer.rinknetPlayer);
      }

      return (
        <NoteCommentForm
          existingNote={n}
          variant={variant}
          {...n}
          formData={formData}
          onSubmit={(values) => {
            updateNote({ variables: values });
          }}
        />
      );
    },
  }));

  return {
    notes,
    noteItems,
    placeholder,
    createNote,
    createEmptyNote,
    updateNote,
    createNoteLoading,
    updateNoteLoading,
  };
};
