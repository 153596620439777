import { useMutation, useQuery } from "@apollo/client";
import { DELETE_PRO_OBJECTIVE_SCENARIO, GET_PRO_OBJECTIVE_SCENARIOS, UPDATE_PRO_OBJECTIVE_SCENARIO } from "apollo/queries/proobjective.queries";
import { BDDTradeDraftPick } from "components/ProScouting/BDDTrades/BDDTradeAsset";
import { IconButton } from "components/bdd/Button";
import { Border, Column, Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import { buildProObjectiveRoute } from "helpers/routing"
import { ArrowClockwise, Trash } from "react-bootstrap-icons";
import { Link, useHistory } from "react-router-dom";
import { TeamStrengthField } from "./ScenarioDiagnostics";
import { theme } from "constants";
import { formatDollars } from "helpers/contracts";
import { ScenarioChecks } from "./ScenarioChecks";
import { ScenarioSynopsis } from "./ScenarioSynopsis";
import { useUser } from "helpers/user";
import { PRO_ROUTE } from "constants";
import { Button } from "react-bootstrap";
import { useModal } from "components/bdd/Modal";
import { PinIcon } from "components/bdd/Icon";

export const buildScenarioLink = (proObjectiveSlug, scenarioId) => `${buildProObjectiveRoute(proObjectiveSlug)}/scenarios/${scenarioId}`;
export const buildProScenarioLink = (scenarioId, section='overview') => `${PRO_ROUTE}/scenarios/${scenarioId}/${section}`;

export const ScenarioCard = ({ scenario, linkTitle=true, linkCard=true, linkToPro=false, showExtended=false, showPin=false, maxPriority, minPriority }) => {
  const history = useHistory();
  const { isUserAdmin } = useUser();

  const [deleteScenario] = useMutation(DELETE_PRO_OBJECTIVE_SCENARIO, {
    onCompleted: () => toastInfo('Scenario deleted'),
    onError: toastBddApiError,
    update: (cache, { data: { deleteProObjectiveScenario: { id }}}) => {
      cache.modify({
        fields: {
          proObjectiveScenarios(existing=[], { readField }) {
            return existing.filter(e => readField('id', e) != id)
          }
        }
      })
    }
  });

  const { renderModal, setShowModal } = useModal({
    title: `Pin ${scenario.title}`,
    size: 'sm'
  });
  const [updateScenario] = useMutation(UPDATE_PRO_OBJECTIVE_SCENARIO, {
    onCompleted: () => {
      toastInfo('Scenario updated!');
      setShowModal(false);
    },
    onError: toastBddApiError,
  });
  
  const handleSetPin = (priority) => {
    updateScenario({
      variables: {
        input: {
          id: scenario.id,
          priority
        }
      }
    })
  }


  const handleDelete = () => {
    if (confirm('Are you sure you want to delete this scenario?')) {
      deleteScenario({
        variables: { id: scenario.id }
      })
    }
  }

  const path = linkToPro
    ? buildProScenarioLink(scenario.id)
    : buildScenarioLink(scenario.proObjectiveSlug, scenario.id);
  return <Border 
    style={{ borderRadius: '5px', ...(!!linkCard ? { cursor: 'pointer' } : {}) }}
    onClick={() => linkCard ? history.push(path) : null}
  >
    {renderModal(
      <ScenarioPinPriorityModal
        onMoveToTop={() => handleSetPin(maxPriority+1)}
        onMoveToBottom={() => handleSetPin(minPriority-1)}
        onUnpin={() => handleSetPin(null)}
      />
    )}
    <Container padding={2}>
      <Row gap={4} justifyContent='space-between'>
        <Container>
          <Typography variant='subtitle1' as={linkTitle ? Link : 'div'} to={path} color='black'>
            {scenario.title}
          </Typography>
          <Typography variant='caption'>
            {scenario.description}
          </Typography>
        </Container>

        <Row gap={12}>
          {showPin && <PinIcon
            enabled={scenario.priority != undefined}
            onClick={ev => {
              ev.stopPropagation();
              setShowModal(true);
            }}
          />}

          <IconButton
            icon={<Trash/>}
            onClick={ev => {
              ev.stopPropagation();
              handleDelete();
            }}
            tooltip='Delete scenario'
          />
        </Row>
      </Row>

      <ScenarioChecks scenario={scenario} />

      <Row gap={32} alignItems='top' justifyContent='start'>

        <ScenarioSynopsis scenario={scenario} extended={showExtended} />

        <Column gap={0}>
          <Typography variant='body1' color='#777'>Cap Room</Typography>
          <Typography variant='caption' color={scenario.projectedCapSpace < 0 ? theme.colors.states.danger : 'none'}>
            {formatDollars(scenario.projectedCapSpace)}
          </Typography>
        </Column>

        <Column gap={0}>
          <Typography variant='body1' color='#777'>Cap Change</Typography>
          <Typography variant='caption' color={scenario.capChange > 0 ? theme.colors.states.success : scenario.capChange < 0 ? theme.colors.states.danger : 'none' }>
            {formatDollars(scenario.capChange)}
          </Typography>
        </Column>

        <Column gap={0}>
          <Typography variant='body1' color='#777'>Team Strength</Typography>
          {!!scenario.agentBased && <TeamStrengthField agentBased={scenario.agentBased} />}
        </Column>
        
      </Row>

    </Container>
  </Border>
}


const ScenarioPinPriorityModal = ({
  onMoveToTop,
  onMoveToBottom,
  onUnpin,
}) => {
  return (
    <Row justifyContent='center'>
      <Container width={300}>
        <Column gap={2}>
          <Button variant="outline-dark" onClick={onMoveToTop}>
            Move to top
          </Button>
          <Button variant="outline-dark" onClick={onMoveToBottom}>
            Move to bottom
          </Button>
          <Button variant="outline-danger" onClick={onUnpin}>
            Unpin
          </Button>
        </Column>
      </Container>
    </Row>
  );
};

