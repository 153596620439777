import { gql } from '@apollo/client';
import { SLEventFragment, SLPlaysequenceEventFragment } from './events.fragments';
import { PageInfoFragment } from './pagination.queries';
import { BDDVideoLinkFragment } from './video.fragments';

export const GET_SL_EVENTS = gql`
  query GetEvents(
    $limit: Int!
    $offset: Int
    $sort: [BDDSortV2]
    $filters: GenericScalar
  ) {
    getSlEvents(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...slEventFragment
      }
    }
  }
  ${SLEventFragment}
  ${PageInfoFragment}
`;

export const GET_SL_EVENT_NAMES = gql`
  query GetSlEventNames {
    slEventNames {
      name
      label
    }
  }
`;

export const GET_FACEOFF_EVENT_LIST = gql`
  query GetFaceoffEventList($gameUid: ID!, $period: Int) {
    getFaceoffEventList(gameUid: $gameUid, period: $period) {
      videoLink {
        ...bddVideoLinkFragment
      }
      events {
        ...slEventFragment
      }
    }
  }
  ${BDDVideoLinkFragment}
  ${SLEventFragment}
`;

export const GET_SL_EVENT_SHIFTS = gql`
  query GetSlEventShifts($eventId: ID!) {
    slEventShifts(eventId: $eventId) {
      leagueSlug
      teamSlug
      gameTimeIn
      gameTimeOut
      player {
        primaryposition
        slPlayerMaster {
          slPlayers {
            slUid
            teamSlug
            leagueSlug
            firstname
            lastname
            jerseynumber
          }
        }
      }
    }
  }
`;


export const GET_SL_PLAYSEQUENCE_EVENTS = gql`
query GetSLPlaysequenceEvents(
  $limit: Int!
  $offset: Int
  $sort: [BDDSortV2]
  $filters: GenericScalar
) {
  getSlPlaysequenceEvents(
    limit: $limit
    offset: $offset
    sort: $sort
    filters: $filters
  ) {
    pageInfo {
      ...pageInfoFragment
    }
    data {
      ...slPlaysequenceEventFragment
    }
  }
}
${SLPlaysequenceEventFragment}
${PageInfoFragment}
`;


export const GET_SL_FACEOFFS_BY_OPPONENT = gql`
  query GetSlFaceoffsByOpponent(
    $leagueSlug: String!
    $playerId: ID
    $teamSlug: ID
    $oppTeamSlug: ID
    $seasons: [Int]
    $gameType: String
    $manpowerSituation: String
    $strength: Int
    $zone: String
    $side: String
    $hand: String
    $startDate: Date
    $endDate: Date
    $secondaryPlayerId: ID
    $limit: Int
  ) {
    slFaceoffsByOpponent(
      leagueSlug: $leagueSlug
      playerId: $playerId
      teamSlug: $teamSlug
      oppTeamSlug: $oppTeamSlug
      seasons: $seasons
      gameType: $gameType
      manpowerSituation: $manpowerSituation
      strength: $strength
      zone: $zone
      side: $side
      hand: $hand
      startDate: $startDate
      endDate: $endDate
      secondaryPlayerId: $secondaryPlayerId
      limit: $limit
    ) {
      playerId
      slPlayer {
        leaguePlayerId
        playerId
        firstname
        lastname
      }
      opponents {
        player
        playerSlug
        playerId
        playerStats
        headToHeadStats
        metastats
      }
    }
  }

`