import React from 'react'
import { Button } from 'react-bootstrap'

import { useMutation } from '@apollo/client'
import useQueryWithPlaceholder from 'apollo/hooks/useQueryWithPlaceholder'
import { GET_QUESTIONNAIRE, DELETE_QUESTIONNAIRE_LINK, GET_QUESTIONNAIRES } from 'apollo/queries/questionnaire.queries'
import Questionnaire from './Questionnaire'
import LinkQuestionnaire from './LinkQuestionnaire'
import styled from 'styled-components'
import { theme } from 'constants'
import { useUser } from 'helpers/user'
import { permissions } from 'constants'
import { buildSingleQuestionnaireRoute } from 'helpers/routing'
import { getCurrentSeason, seasonToEndYear } from 'helpers/hockeyutils'
import { Typography } from 'components/bdd/Typography'
import { Column, Container, Row } from 'components/bdd/Layout'
import { useButtonGroup } from 'components/bdd/ButtonGroup'

const Unlinking = styled.div(() => ({
  ...theme.typography.body1,
  fontStyle: 'italic',
  '@media print': {
    display: 'none'
  }
}))

export default function ColinQuestionnaire({
  rinknetId,
  year
}) {
  const { data, placeholder } = useQueryWithPlaceholder(GET_QUESTIONNAIRES, { rinknetId });

  const { buttonGroup, selectedValue: selectedYear } = useButtonGroup({
    variant: 'link',
    options: data?.playerQuestionnaires?.map(d => ({ label: d.year, value: d.year })),
    initialSelectedValue: !!year ? year : seasonToEndYear(getCurrentSeason())
  })

  if (!data) return placeholder
  if (!data.playerQuestionnaires) return <b>No questionnaires found</b>
  return <>
    <Column>
      <Container>
        {buttonGroup}
      </Container>
      <Container>
        {data.playerQuestionnaires.map(q => (
          <QuestionnaireManager key={q.id} questionnaire={q} year={selectedYear} rinknetId={rinknetId} />
        ))}
      </Container>
    </Column>
    
  </>
}

const QuestionnaireManager = ({ questionnaire, year, rinknetId }) => {
  const { userHasPermission } = useUser();

  const [deleteQuestionnaireLink] = useMutation(DELETE_QUESTIONNAIRE_LINK, {
    update: (cache, { data: { deleteQuestionnaireLink: { rinknetId } } }) => {
      cache.modify({
        fields: {
          playerQuestionnaire(existing) {
            return null
          }
        }
      })
    }
  })
  const handleUnlink = () => {
    deleteQuestionnaireLink({ variables: { rinknetId }})
  }

  return (
    <Container>
      <Row justifyContent='space-between'>
        {questionnaire.requiresLinking && userHasPermission(permissions.editQuestionnaireLinks) && <>
          <Unlinking>
            Is this the wrong questionnaire for this player?
            {' '}-{' '}
            <Button size="sm" variant="outline-dark" onClick={handleUnlink}>Unlink</Button>
          </Unlinking>
          <hr></hr>
        </>}
        <Typography variant='body2'>
          <a target='_blank' rel="noreferrer" href={`${buildSingleQuestionnaireRoute(year, rinknetId)}`}>
            Open in new tab
          </a>
        </Typography>
      </Row>
      <Questionnaire questionnaire={questionnaire} />
    </Container>
  )
}