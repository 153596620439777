import { Divider } from "components/bdd/Divider"
import React, { useState, useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { ItemGrid } from "../bdd"

const Styles = styled.div`
    width: 100%;
    padding: 10px;
    padding-right: 15px; // for scroll bar
    margin: 5px;
    border: 1px solid #ddd;

    font-size: 0.8em;
    text-align: center;
    max-height: 400px;
    overflow-y: scroll;
    
    .player {
        cursor: pointer;
    }

    .selected {
        font-weight: bold;
    }
    .disabled {
        cursor: not-allowed;
        color: #ccc;
    }
`
export default function SelectPlayersBank({
    players,
    renderPlayer,
    idKey='id',
    onSubmit,
    numCols,
    disabledPlayerIds, // set()
    showAddPlayersButton=true,
    dividerAfterIndexes,
    numberPlayers=false
}) {
    if (!disabledPlayerIds) disabledPlayerIds = new Set()
    const [selected, setSelected] = useState([])
    // if "players" changes, make sure every selected player exists in list
    useEffect(() => {
        const allPids = players.map(p => p[idKey])
        setSelected(selected.filter(pid => allPids.indexOf(pid) >= 0))
    }, [players])
    return <>
    <Styles>
        {players.length === 0 ? <em>No players found</em> : null}
        <ItemGrid 
            leftToRight={false}
            items={players}
            keyField={idKey}
            numCols={numCols}
            render={(p, i) => {
                const isDisabled = disabledPlayerIds.has(p[idKey])
                const isSelected = !isDisabled && selected.indexOf(p[idKey]) >= 0
                
                return <div 
                    className={`player ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => {
                        if (isDisabled) return
                        if (isSelected) setSelected(selected.filter(s => s != p[idKey]))
                        else setSelected(selected.concat(p[idKey]))
                    }}
                >
                    {renderPlayer ? renderPlayer(p)
                    : <>
                        {numberPlayers && `${i+1}.`} {p.firstname} {p.lastname} {!!p.posFDG && <>({p.posFDG})</>}
                        {!!dividerAfterIndexes && dividerAfterIndexes.includes(i) && <Divider/>}
                    </>}
                </div>
            }}
        />
    </Styles>
    {showAddPlayersButton && <Row className='justify-content-md-center'>
        <Col md='auto'>
            <Button 
                block 
                size='sm' 
                variant='outline-dark' 
                onClick={() => onSubmit(selected.filter(s => !disabledPlayerIds.has(s)))}
                disabled={selected.length === 0}
            >
                Add Players
            </Button>
        </Col>
    </Row>}
    </>
}