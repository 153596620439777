import { roundToX } from 'helpers/data';
import { infoColumns } from '../columns';

export const getTargetVectorWeights = (targetVector, targetWeights) => {
  const weightEntries = Object.entries(targetWeights.numericalWeights).concat(
    Object.entries(targetWeights.categoricalWeights)
  );

  return weightEntries.map(([wkey, weight]) => {
    let value;
    let header;
    let columnKey = wkey;

    if (wkey.startsWith('stats_')) {
      header = wkey.split('stats_')[1];
      value = roundToX(targetVector[wkey], 2);
    } else {
      if (wkey.startsWith('bf_player_')) {
        columnKey = wkey.split('bf_player_')[1];
      }
      header = infoColumns?.[columnKey]?.header;
      value = !targetVector[wkey]
        ? 'false'
        : typeof targetVector[wkey] == 'number'
        ? roundToX(targetVector[wkey], 1)
        : typeof targetVector[wkey] == 'boolean'
        ? targetVector[wkey].toString()
        : targetVector[wkey];
    }

    return {
      key: wkey,
      value,
      header,
      weight,
    };
  });
};

export const getStatNumericalWeightOptions = (targetVector, targetWeights) => {
  return Object.keys(targetVector)
    .filter((key) => key.includes('stats_'))
    .map((key) => ({
      value: key,
      label: key.slice(6),
    }));
};
