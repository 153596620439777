import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { useQuery } from '@apollo/client';
import { GET_PLAYLIST, GET_PLAYLISTS } from 'apollo/queries/video.queries';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { InlineChromiumBugfix } from '..';
import { Container, Row } from 'components/bdd/Layout';
import { theme } from 'constants';
import Icon from 'components/bdd/Icon';
import { FileEarmarkPlay } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { PLAYLISTS_ROUTE } from 'constants';
import { Typography } from 'components/bdd/Typography';
import { usePlaylists } from 'components/Video/Playlists';
import { NoColorLink } from 'components/bdd/Button';

export const PlaylistField = ({ readOnly, children, attributes, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const { playlists, loading } = usePlaylists();
  // const { data: playlistData, loading: playlistLoading } = useQuery(GET_PLAYLIST, {
  //   skip: !element.playlistId,
  //   variables: {
  //     id: element.playlistId,
  //   },
  // });

  // const playlist = playlistData?.bddPlaylist;
  // const clips = playlist?.clips;

  const playlistSelect = (
    <Container as="span" width={250}>
      <StyledBDDSelect
        selectedValue={element.playlistId}
        options={
          playlists?.map((p) => ({
            label: p.name,
            caption: p.description,
            value: p.id,
          })) || []
        }
        selectProps={{
          isLoading: loading,
        }}
        onChange={(_, v) => {
          Transforms.setNodes(
            editor,
            {
              ...element,
              playlistId: v,
            },
            { at: path }
          );

          Transforms.insertNodes(editor, { at: path, text: ' ' });
        }}
      />
    </Container>
  );

  const selectedPlaylist = playlists?.find((p) => p.id == element.playlistId);

  // const playlistLink = (
  //   <Container>
  //     <VideoContextProvider videoContext={{ allEvents: clips }}>
  //       <ModalContextProvider searchParams={false}>
  //         {({ renderModal }) => (
  //           <LinkButton
  //             variant="body2"
  //             as="span"
  //             style={{ cursor: 'pointer' }}
  //             onClick={() => {
  //               renderModal({
  //                 size: 'xxl',
  //                 body: <PlaylistVideoPlayer autoPlay={true} />,
  //               });
  //             }}
  //           >
  //             <Icon icon={<FileEarmarkPlay />} />
  //             {!element.playlistId
  //               ? 'No playlist selected'
  //               : loading || playlistLoading
  //               ? 'Loading...'
  //               : selectedPlaylist.name}
  //             {/* {modal} */}
  //           </LinkButton>
  //         )}
  //       </ModalContextProvider>
  //     </VideoContextProvider>
  //   </Container>
  // );

  const playlistLink = (
    <NoColorLink as={Link} to={`${PLAYLISTS_ROUTE}/${element.playlistId}`}>
      <Row as="span" columnGap={1}>
        <FileEarmarkPlay />
        <Typography as="span" variant="body1">
          {!element.playlistId
            ? 'No playlist selected'
            : loading
            ? 'Loading...'
            : selectedPlaylist.name}
        </Typography>
      </Row>
    </NoColorLink>
  );

  return (
    <span {...attributes} contentEditable={false}>
      <InlineChromiumBugfix />
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: theme.spacing[2],
        }}
      >
        {playlistLink} {!readOnly && playlistSelect} {children}
      </span>
      <InlineChromiumBugfix />
    </span>
  );
};
