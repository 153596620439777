import React, { useState, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { theme } from 'constants';
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries';
import { buildPlayerRoute } from 'helpers/routing';
import PlayerLineupSpots from '../playerlineupspots';
import PlayerWarBoxes from '../PlayerWar/playerwarboxes';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';
import PlayercardNHLDataV2 from 'components/player/playercardnhldatav2';
import BDDTag from 'components/PlayerTagging/bddtag';
import { TooltipSpan } from 'components/reports';
import SkillRatingsViz from 'components/Scouting/Visuals/skillratingsviz';
import LinkList from 'components//bdd/LinkList/LinkList';

import { PlayerBio } from './PlayerBio';
import CareerStats from '../CareerStats';
import { PlayerSchedule } from './PlayerSchedule';
import { Container, Row } from 'components/bdd/Layout';
import useLeagueButtons from '../hooks/useLeagueButtons';
import { NotesOverview } from '../NotesOverview';
import { SkillsOverview } from '../SkillsOverview';
import PlayerLineupSpotsV3 from '../PlayerLineupSpotsV3';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { ScoutingGrades } from '../Scouting/ScoutingGrades';
import { ModalContext } from 'components/bdd/Modal';
import { PlayerReportViewer } from '../Compare/components';

const ContentContainer = styled.div({
  width: '100%',
  minHeight: 300,
});

const PlayerHoverCard = styled.div({
  display: 'flex',
  height: '100%',
});

const Sidebar = styled.div({
  width: '25%',
  ...theme.borders.thin,
  ...theme.borders.light,
  ...theme.borders.right,
});

const Content = styled.div({
  width: '75%',
});

const Footer = styled.div({
  ...theme.borders.thin,
  ...theme.borders.light,
  ...theme.borders.top,
  ...theme.typography.caption,
  ...theme.font.italic,
});

const InfoBox = styled.div({
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  paddingBottom: theme.spacing[2],
});

const Title = styled.div({
  ...theme.typography.h6,
  color: theme.colors.light.text.primary,
});

const TagContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  ...theme.typography.caption,
  marginTop: theme.spacing[2],
});

const Divider = styled.div({
  ...theme.borders.thin,
  ...theme.borders.top,
  ...theme.borders.light,
  width: '100%',
});

const CareerStatsContainer = styled.div({
  display: 'flex',
  marginLeft: theme.spacing[3],
  marginBottom: theme.spacing[3],
  maxHeight: 500,
});

const linkRows = [
  {
    id: 'bio',
    title: 'Bio',
  },
  {
    id: 'notes',
    title: 'Notes',
  },
  {
    id: 'skills',
    title: 'Skills',
  },
  {
    id: 'positions-overview',
    title: 'Positions',
    skatersOnly: true,
  },
  {
    id: 'schedule',
    title: 'Schedule',
  },
  {
    id: 'skills-war',
    title: 'WAR',
  },
  {
    id: 'stats-career',
    title: 'Career Stats',
  },
  {
    id: 'scouting',
    title: 'Scouting',
    links: [
      {
        id: 'scouting-grades',
        title: 'Grades',
      },
      {
        id: 'scouting-reports',
        title: 'Reports',
      },
    ],
  },
];

// wrapper to not break hook order
const PlayerLineupSpotsWrapper = ({ slug, slUid }) => {
  const {
    loading,
    selectedLeague,
    smallButtonGroup: leagueButtonGroupSm,
  } = useLeagueButtons({ slug });
  if (loading) return <BDDLoader />;
  if (!selectedLeague) return 'No sl data found';
  return (
    <PlayerLineupSpots
      slUid={slUid}
      leagueSlug={selectedLeague}
      leagueButtonGroupSm={leagueButtonGroupSm}
    />
  );
};

export const PlayerHoverContent = ({
  playerSlug,
  rinknetId,
  defaultRinknetPlayer,
  forceClose,
  handleShowTagModal,
  displayFooter,
}) => {
  const [tab, setTab] = useState('bio');
  const { data, loading, error } = useQuery(GET_PLAYER_CARD, {
    variables: { slug: playerSlug, rinknetId },
    skip: !!defaultRinknetPlayer,
  });
  if (!!data && !playerSlug) {
    playerSlug = data.bddPlayer.slug;
  }

  const { showModal } = useContext(ModalContext);

  const bddPlayer = !!data ? data.bddPlayer : {};
  const rp = data ? bddPlayer?.rinknetPlayer : defaultRinknetPlayer || {};
  const nhlPlayerStats = data ? bddPlayer?.nhlPlayerStats : {};
  const tags = bddPlayer?.bddManagedTags || [];

  const handleLinkSelection = (link) => {
    // in this context, if a link has sublinks, it doesn't get selected (linklist will expand it)
    if (!link.links) {
      setTab(link.id);
    }
  };

  const getLinksToDisplay = useCallback(
    (data) => {
      if (defaultRinknetPlayer) {
        return linkRows.filter((row) => row.id == 'bio');
      }

      const ret =
        data && data.bddPlayer.posFDG === 'G'
          ? linkRows.filter((row) => !row.skatersOnly)
          : linkRows;

      const notesLink = ret.find((r) => r.id === 'notes');
      if (notesLink) {
        const numNotes = bddPlayer?.notesCount;
        notesLink.title =
          numNotes > 0 ? (
            <Row columnGap={1}>
              Notes
              <BDDTag
                tag={{
                  tag: numNotes,
                  textColor: 'white',
                  color: theme.colors.states.danger,
                }}
              />
            </Row>
          ) : (
            'Notes'
          );
      }
      return ret;
    },
    [data, defaultRinknetPlayer]
  );

  return (
    <BDDErrorBoundary>
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <BDDLoader variant="squares" />
        ) : error ? (
          <BDDApiError error={error} />
        ) : bddPlayer && rp ? (
          <>
            <PlayerHoverCard>
              <Sidebar>
                <InfoBox>
                  <a href={bddPlayer?.slug && buildPlayerRoute(bddPlayer.slug)}>
                    <Title>
                      {rp.firstname} {rp.lastname}
                    </Title>
                  </a>
                  <TagContainer>
                    {tags.map((t) => (
                      <BDDTag key={t.id} isProjection={t.isProjection} tag={t.tag} />
                    ))}
                    <TooltipSpan
                      content="Edit tags"
                      onClick={() => {
                        handleShowTagModal();
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </TooltipSpan>
                  </TagContainer>
                </InfoBox>
                <Divider />
                <LinkList
                  linkRows={getLinksToDisplay(data)}
                  color="light"
                  size="small"
                  onLinkSelected={handleLinkSelection}
                  isSelectedCallback={(linkOrRow) => linkOrRow.id === tab}
                />
              </Sidebar>
              <Content>
                <div>
                  {tab === 'bio' ? (
                    <Container paddingLeft={3}>
                      <PlayerBio
                        bddPlayer={bddPlayer}
                        rinknetPlayer={rp}
                        nhlPlayerStats={nhlPlayerStats}
                      />
                    </Container>
                  ) : tab === 'skills' ? (
                    <Container padding={3}>
                      <SkillsOverview bddPlayer={bddPlayer} />
                    </Container>
                  ) : tab === 'notes' ? (
                    <NotesOverview
                      bddPlayer={bddPlayer}
                      onAddNote={() => !showModal && forceClose()} // if a ContextModal is already open, then we don't want to force close
                    />
                  ) : tab === 'positions-overview' ? (
                    <Container padding={3}>
                      <PlayerLineupSpotsV3
                        bddPlayer={bddPlayer}
                        posFDG={rp.posFDG.toLowerCase()}
                      />
                    </Container>
                  ) : tab === 'skills-war' ? (
                    <center>
                      <PlayerWarBoxes slUid={bddPlayer.slUid} showHeader={false} />
                    </center>
                  ) : tab === 'stats-career' ? (
                    <CareerStatsContainer>
                      <CareerStats
                        tableId={'player-hover-career-stats'}
                        slug={bddPlayer.slug}
                        epId={bddPlayer.epId}
                      />
                    </CareerStatsContainer>
                  ) : tab === 'scouting-reports' ? (
                    <Container padding={1}>
                      <PlayerReportViewer rinknetId={bddPlayer.rinknetId} />
                    </Container>
                  ) : tab === 'scouting-grades' ? (
                    <Container padding={2}>
                      <ScoutingGrades rinknetId={bddPlayer.rinknetId} />
                      <ScoutingGrades rinknetId={bddPlayer.rinknetId} predictedGrades />
                    </Container>
                  ) : tab === 'schedule' ? (
                    <PlayerSchedule bddPlayer={bddPlayer} />
                  ) : null}
                </div>
              </Content>
            </PlayerHoverCard>
            {displayFooter && (
              <Footer>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    forceClose();
                  }}
                >
                  Close
                </a>
                {bddPlayer?.slug && (
                  <span style={{ float: 'right' }}>
                    <Link to={buildPlayerRoute(bddPlayer.slug)}>Go to playercard</Link>
                  </span>
                )}
              </Footer>
            )}
          </>
        ) : null}
      </ContentContainer>
    </BDDErrorBoundary>
  );
};
