import BFPlayerContractBreakdown from 'components/PlayersV2/Contract/components/BFPlayerContractBreakdown';
import { Border, Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { defaultFilters } from 'components/ProObjectives/ContractSearch/defaults';
import { ContractSearchContextProvider } from 'components/ProObjectives/ContractSearch/context';
import { SimilarContractsContextProvider } from 'components/ProObjectives/ContractSearch/SimilarContracts/context';
import { TargetDetails } from 'components/ProObjectives/ContractSearch/SimilarContracts/TargetDetails';
import { TargetVector } from 'components/ProObjectives/ContractSearch/SimilarContracts/TargetVector';
import { ContractSearchFilters } from 'components/ProObjectives/ContractSearch/ContractSearchFilters';
import { SimilarContractsData } from 'components/ProObjectives/ContractSearch/SimilarContracts/SimilarContractsData';

export default function BFSigningBreakdown({ bfContract }) {
  const targetDetails = {
    id: {
      id: 'id',
      value: bfContract.id,
    },
    targetMode: {
      id: 'targetMode',
      value: 'current',
    },
    statsMode: {
      id: 'statsMode',
      value: 'combined',
    },
    priorSeasons: {
      id: 'priorSeasons',
      value: 0,
    },
    presets: {
      id: 'presets',
      value: 'production',
    },
  };

  return (
    <Container>
      <Column gap={3}>
        <Container>
          <BFPlayerContractBreakdown contract={bfContract} />
        </Container>
        <Border borderRadius={1}>
          <Container padding={3}>
            <Column gap={2}>
              <Typography variant="subtitle1">Similar Signings</Typography>
              <SimilarContractsContextProvider targetDetails={targetDetails }>
                <ContractSearchContextProvider>
                  <Container>
                    <Column gap={1}>
                      <TargetDetails hideContractSelect />
                      <TargetVector />
                      <ContractSearchFilters />
                    </Column>
                  </Container>
                  <SimilarContractsData />
                </ContractSearchContextProvider>
              </SimilarContractsContextProvider>
            </Column>
          </Container>
        </Border>
      </Column>
    </Container>
  );
}
