import { useMutation, useQuery } from '@apollo/client';
import { SEARCH_BF_CONTRACTS } from 'apollo/queries/bruinsfriendly.queries';
import { TeamLink } from 'components/TeamsV2';
import { Column, Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import useDebounce from 'components/bdd/hooks/usedebounce';
import { formatDollars } from 'helpers/contracts';
import { useEffect, useState } from 'react';
import { components as SelectComponents } from 'react-select';
import { seasonOptions } from './constants';
import Icon from 'components/bdd/Icon';
import { CircleFill } from 'react-bootstrap-icons';
import { theme } from 'constants';

const ContractOption = ({ data, ...props }) => {
  const contract = data.contract;

  return (
    <SelectComponents.Option {...props}>
      <Container paddingTop={1} paddingBottom={1}>
        <Column columnGap={2}>
          <Row columnGap={1}>
            <Icon
              icon={<CircleFill />}
              size={8}
              color={
                contract.isActive
                  ? theme.colors.states.success
                  : theme.colors.states.danger
              }
            />
            <Typography variant="label">
              {contract.bfPlayer.firstName} {contract.bfPlayer.lastName}
            </Typography>
          </Row>
          <Row columnGap={2}>
            <Typography variant="body2">
              {seasonOptions.find((o) => o.value == contract.startSeason)?.label}
            </Typography>
            <Typography variant="body2">
              {`${formatDollars(contract.aav)} x ${contract.numYears}`}
            </Typography>
            <TeamLink
              abbreviation={contract.signingTeam?.abbreviation}
              nhlid={contract.signingTeam?.nhlid}
              slTeamSlug={contract.signingTeam?.bddTeam?.slTeamSlug}
              noLink
            />
          </Row>
        </Column>
      </Container>
    </SelectComponents.Option>
  );
};

const ContractValue = ({ data, ...props }) => {
  return (
    <SelectComponents.SingleValue {...props}>
      <Container>
        <Typography variant="label">
          {data.bfPlayer.firstName} {data.bfPlayer.lastName}
        </Typography>
      </Container>
    </SelectComponents.SingleValue>
  );
};

export const ContractSearchSelect = ({
  onChange,
  placeholder,
  variant = 'default',
  size = 'sm',
  debounceMS = 400,
  activeContractOnly,
  selectedValue,
}) => {
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, debounceMS);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!selectedValue) setSelected();
  }, [selectedValue]);

  const { data, error, loading } = useQuery(SEARCH_BF_CONTRACTS, {
    variables: { searchTerm: debouncedSearch },
    skip: !debouncedSearch || debouncedSearch.length === 0,
  });

  let options =
    [...(data?.searchBfPlayerContracts || [])]
      // .sort((a, b) => new Date(b.signedDate) - a.signedDate)
      .map((c) => ({
        value: c.id,
        contract: c,
      })) || [];

  if (activeContractOnly) {
    options = options.filter((o) => !!o.contract.isActive);
  }

  return (
    <Container width={200}>
      <StyledBDDSelect
        options={options}
        selectedValue={selected}
        variant={variant}
        size={size}
        selectProps={{
          isSearchable: true,
          isLoading: loading,
          filterOption: ({ label, value, data }) => true,
          components: {
            Option: ContractOption,
            SingleValue: ContractValue,
          },
          placeholder: placeholder || 'Add Contract...',
          onInputChange: (v) => {
            setSearch(v);
          },
        }}
        onChange={(n, v) => {
          const contract = options.find((o) => o.value == v).contract;
          onChange && onChange(v, contract);
          setSelected(contract);
        }}
      />
    </Container>
  );
};
