// A re-useable search component
// Expects a query that hits a BDDPaginated endpoint
// with variables search, limit
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Form } from 'react-bootstrap';
import { components } from 'react-select';

import { getNHLTeamLogoUrl } from '../../helpers/logos';
import BDDSelect from '../bdd/bddselect';
import useDebounce from 'components/bdd/hooks/usedebounce';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { Container, Row } from 'components/bdd/Layout';
import Icon from 'components/bdd/Icon';
import { PeopleFill, PersonCircle } from 'react-bootstrap-icons';

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <Container>
        <Typography variant="body1">
          <Row columnGap={1}>
            {data.type === 'user' && <Icon icon={<PersonCircle />} />}
            {data.type === 'userGroup' && <Icon icon={<PeopleFill />} />}
            {!!data.teamid && (
              <img
                src={getNHLTeamLogoUrl(data.teamid)}
                style={{ height: '18px' }}
                alt=""
              />
            )}
            {data.label}
          </Row>
        </Typography>
        {data.subLabel ? (
          <Typography variant="caption">{data.subLabel}</Typography>
        ) : null}
      </Container>
    </components.Option>
  );
};

export default function useBDDSearchSelect({
  query,
  resultToOptions, // callback that translates "data" from query into options
  limit = 50,
  debounceMS = 250,
  placeholder = 'Search',
  menuPrompt = 'Search',
  onSelect,
  customStyles: customStylesArg,
  selectRef,
  defaultTerm = '',
  customOption,
  noDropdownIndicator = false,
  initialSelectedValue = null,
  defaultOptions = [],
  resultTypes,
  selectProps,
  ...rest
}) {
  const [search, setSearch] = useState(defaultTerm);
  const debouncedSearch = useDebounce(search, debounceMS);
  const [selected, setSelected] = useState(initialSelectedValue);
  const [latestResults, setLatestResults] = useState([]);
  const { data, error, loading } = useQuery(query, {
    variables: { search: debouncedSearch, term: debouncedSearch, limit, resultTypes },
    skip: debouncedSearch.length === 0,
  });

  const handleChange = (opt) => {
    if (!!onSelect) onSelect(opt);
    setSelected(opt?.value);
  };

  const handleInputChange = (t, action) => {
    setSearch(t);
  };

  const resetSearch = () => {
    setSearch('');
    setSelected(null);
  };

  const components = {
    ...(!!customOption ? { Option: customOption } : { Option }),
    ...(!!noDropdownIndicator ? { DropdownIndicator: () => null } : {}),
    ...(!!noDropdownIndicator ? { IndicatorSeparator: () => null } : {}),
  };

  useEffect(() => {
    if (!loading && !!data) {
      setLatestResults(resultToOptions(data));
    }
  }, [loading, data]);

  // const options = search.length ? latestResults : []
  var options = !!latestResults?.length ? latestResults : defaultOptions;

  if (!!selected && !options.find((o) => o.value === selected)) {
    options.push({ value: selected, label: selected });
  }
  const selectedOption = !!selected && options.find((o) => o.value === selected);

  const searchComponent = (
    <div style={{ width: '100%' }}>
      <StyledBDDSelect
        options={options}
        selectProps={{
          selectRef: selectRef,
          defaultOnChange: true,
          name: 'search',
          placeholder: placeholder,
          isSearchable: true,
          isLoading: loading || (search.length && !options.length),

          value: selected,
          onChange: handleChange,
          filterOption: ({ label, value, data }) => true,

          inputValue: search,
          onInputChange: handleInputChange,
          components: components,
          autosize: false,
          noOptionsMessage: (input) =>
            input.inputValue.length ? 'No results found' : menuPrompt,
          menuPortalTarget: document.body,
          ...rest,
          ...selectProps,
        }}
        {...rest}
      />
    </div>
  );

  return {
    searchComponent,
    selected,
    selectedOption,
    resetSearch,
  };
}
