import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { ScenarioPlayer } from './ScenarioPlayer';
import { ScenarioPick } from './ScenarioPick';
import { HoverInteractive } from 'components/bdd';
import { BDDTransaction } from '../Transactions/BDDTransaction';
import { summarizePicks } from './helpers';

export const ScenarioSynopsis = ({ scenario, extended = true, maxHeight = 200 }) => {
  const trades = scenario.transactions.filter((tx) => tx.transactionType == 'TRADE');
  const signings = scenario.transactions.filter((tx) => tx.transactionType == 'SIGNING');

  return (
    <Container maxHeight={maxHeight} overflow="auto">
      <Row gap={40} alignItems="top" justifyContent="start" flexWrap>
        <Column gap={0} justifyContent="start">
          <Typography variant="body1" color="#777">
            Transactions
          </Typography>
          {!!trades?.length && (
            <HoverInteractive
              content={
                <Column gap={2} style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                  {scenario.transactions
                    ?.filter((tx) => tx.transactionType === 'TRADE')
                    ?.map((tx) => (
                      <BDDTransaction key={tx.id} transaction={tx} />
                    ))}
                </Column>
              }
            >
              <Typography variant="caption">
                {scenario.transactions?.length} Trades
              </Typography>
            </HoverInteractive>
          )}

          {!!signings?.length && (
            <HoverInteractive
              content={
                <Column gap={2}>
                  {scenario.transactions
                    ?.filter((tx) => tx.transactionType === 'SIGNING')
                    ?.map((tx) => (
                      <BDDTransaction key={tx.id} transaction={tx} />
                    ))}
                </Column>
              }
            >
              <Typography variant="caption">
                {scenario.transactions?.length} Signings
              </Typography>
            </HoverInteractive>
          )}
          {!scenario.transactions?.length && (
            <Typography variant="caption" style={{ fontStyle: 'italic' }}>
              None
            </Typography>
          )}
        </Column>

        <Column gap={0} justifyContent="start">
          <Typography variant="body1" color="#777">
            Players In
          </Typography>
          {!scenario.playersIn?.length ? (
            <Typography variant="caption" style={{ fontStyle: 'italic' }}>
              None
            </Typography>
          ) : !!extended ? (
            scenario.playersIn?.map((p) => (
              <ScenarioPlayer key={p.slug} scenarioPlayer={p} />
            ))
          ) : (
            <HoverInteractive
              content={scenario.playersIn?.map((p) => (
                <ScenarioPlayer key={p.slug} scenarioPlayer={p} />
              ))}
            >
              <Typography variant="caption">
                {scenario.playersIn?.length} players
              </Typography>
            </HoverInteractive>
          )}
        </Column>

        <Column gap={0} justifyContent="start">
          <Typography variant="body1" color="#777">
            Players Out
          </Typography>
          {!scenario.playersOut?.length ? (
            <Typography variant="caption" style={{ fontStyle: 'italic' }}>
              None
            </Typography>
          ) : !!extended ? (
            scenario.playersOut?.map((p) => (
              <ScenarioPlayer key={p.slug} scenarioPlayer={p} />
            ))
          ) : (
            <HoverInteractive
              content={scenario.playersOut?.map((p) => (
                <ScenarioPlayer key={p.slug} scenarioPlayer={p} />
              ))}
            >
              <Typography variant="caption">
                {scenario.playersOut?.length} players
              </Typography>
            </HoverInteractive>
          )}
        </Column>

        {scenario.picksIn && (
          <Column gap={0} justifyContent="start">
            <Typography variant="body1" color="#777">
              Picks In
            </Typography>
            {!!extended
              ? scenario.picksIn?.map((p) => <ScenarioPick key={p.pickId} pick={p} />)
              : summarizePicks(scenario.picksIn)}
          </Column>
        )}

        {scenario.picksOut && (
          <Column gap={0} justifyContent="start">
            <Typography variant="body1" color="#777">
              Picks Out
            </Typography>
            {!!extended
              ? scenario.picksOut?.map((p) => <ScenarioPick key={p.pickId} pick={p} />)
              : summarizePicks(scenario.picksOut)}
          </Column>
        )}
      </Row>
    </Container>
  );
};
