import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { GraphUp } from 'react-bootstrap-icons';
import { ScatterChartOptions } from '.';
import { SLStatContext } from '..';

export const GraphicalViewButton = ({}) => {
  const { chartOptions, setChartOptions } = useContext(SLStatContext);
  const { select: graphTypeSelect, selected: graphTypeSelected } = useSelect({
    initialSelectedValue: chartOptions.type,
    options: [
      {
        value: 'table',
        label: 'Table',
      },
      {
        value: 'scatter',
        label: 'Scatter Chart',
      },
      // {
      //   value: 'bar',
      //   label: 'Bar Chart',
      // },
    ],
  });

  useEffect(() => {
    setChartOptions({
      type: graphTypeSelected.value,
      options: {},
    });
  }, [graphTypeSelected.value]);

  return (
    <Dropdown>
      <Dropdown.Toggle as={Button} variant="outline-dark" size="sm">
        <GraphUp />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Container padding={3} onClick={(e) => e.stopPropagation()}>
          <Column gap={2}>
            <Column>
              <Typography variant="body1">Select chart type</Typography>
              <Container width={150}>{graphTypeSelect}</Container>
            </Column>
            <Divider />
            {graphTypeSelected.value == 'scatter' && <ScatterChartOptions />}
          </Column>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
  );
};
