import { CheckboxGroupFilter, EventFilterContext } from '.';
import { eventNameToMapping, getCombinedEventTypeOptions } from '../helpers';
import { useContext } from 'react';
import { VideoContext } from '../..';

export const EventTypeFilter = ({ readonly, extraOptions=[] }) => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name']?.val;

  if (!eventNames) return null;

  const options = eventNames.flatMap((e) => eventNameToMapping[e]?.eventTypes || []);
  if (extraOptions.length > 0) {
    options.push(...extraOptions);
  }

  if (options.length == 0) return null;

  return (
    <CheckboxGroupFilter
      col="event_type"
      id="event_type"
      label={'Event Type'}
      condAny={'includes_substr'}
      options={options}
      readonly={readonly}
    />
  );
};
