import { useMultiPlayerSearch } from 'components/DraftApp/DraftPickSelection/SimulateDraftPicks';
import { Container } from 'components/bdd/Layout';
import { useEffect } from 'react';
import { useContractSearchContext } from './context';
import { GET_BDD_PLAYERS } from 'apollo/queries/players.queries';
import { useQuery } from '@apollo/client';

export const MultiPlayerSearch = ({}) => {
  const filters = useContractSearchContext((state) => state.filters);
  const { setFilters, applyFilters } = useContractSearchContext((state) => state.actions);

  const selectedPlayerSlugs = filters['slug']?.value || [];

  const { multiSearchComponent, selectedPlayers, setSelectedPlayers, resetSearch } =
    useMultiPlayerSearch({
      selectProps: {
        menuPlacement: 'auto',
      },
      idType: 'bdd_player_slug',
      width: 200,
    });

  const { data, loading } = useQuery(GET_BDD_PLAYERS, {
    skip: selectedPlayerSlugs.length == 0 || selectedPlayers.length > 0,
    variables: { bfSlugs: filters['slug']?.value },
  });

  useEffect(() => {
    if (!data) return;

    setSelectedPlayers(
      data.bddPlayers.map((bdd) => ({
        bddPlayer: bdd,
        label: `${bdd.firstname} ${bdd.lastname}`,
        value: bdd.bfSlug,
      }))
    );
  }, [loading]);

  useEffect(() => {
    if (selectedPlayerSlugs.length == 0) {
      setSelectedPlayers([]);
    }
    resetSearch();
  }, [selectedPlayerSlugs.join(',')]);

  const selectedPlayerIds = selectedPlayers.map((p) => p.bddPlayer.bfSlug);

  useEffect(() => {
    if (selectedPlayerIds.length > 0) {
      setFilters({
        ...filters,
        ...{
          slug: {
            id: 'slug',
            type: 'select',
            cond: 'isin',
            value: selectedPlayerIds,
          },
        },
      });
    } else {
      const filtersToRemove = { ...filters };
      delete filtersToRemove['slug'];
      setFilters(filtersToRemove);
    }
    applyFilters();
    resetSearch();
  }, [selectedPlayerIds.join('')]);

  return <Container>{multiSearchComponent}</Container>;
};
