import { nameToLastName } from "./hockeyutils"

const FORWARD_POSITIONS = ['leftwing', 'center', 'rightwing']
const DEFENSE_POSITIONS = ['leftdefence', 'rightdefence']
const PP_POSITIONS = ['pp1', 'pp2', 'pp3', 'pp4', 'pp5']

export function lineupQueryToGroupStruct(data, posFDG=undefined) {
    // Given the results of a lineup query (audited or predicted)
    // return array of objects, where the objects have keys "name", "slUids"
    // such that element 0 is 1st line w/ name "Marchand-Bergeron-Pastrnak" (pos aware)
    // and slUids = '-' separated string of sluids
    // EXPECTS data to be a list of obj, obj must have keys: lineNum, position, slUid, slPlayerMaster { name }
    // set posFDG as 'f' or 'd' to get only forwards or defense.  Otherwise get all
    const ret = []
    const positions = !posFDG ? ['f', 'd']
        : posFDG === 'f' ? ['f']
        : posFDG === 'd' ? ['d']
        : []

    positions.forEach(pfdg => {
        if (pfdg === 'f') {
            var ps = FORWARD_POSITIONS
            var numLines = 4
        } else if (pfdg === 'd') {
            var ps = DEFENSE_POSITIONS
            var numLines = 3
        }  
        for (let i=1; i<numLines+1; i++) {
            const nameArr = []
            const slUidArr = []
            const filtered = data.filter(p => p.lineNum === i)
            ps.forEach(pos => {
                const plyr = filtered.filter(fp => fp.position === pos)
                if (plyr.length) {
                    nameArr.push(plyr[0].slPlayerMaster.lastname)
                    slUidArr.push(plyr[0].slUid)
                } else {}
            })
            const newLine = { name: nameArr.join('-'), slUids: slUidsToString(slUidArr) }
            ret.push(newLine)
        }
    })
    return ret
}

export function lineupObjToGroupStruct(lineup, posFDG=undefined) {
    // Given a lineup with keys like "center1", "leftwing2"...
    // return array of objects, where the objects have keys "name", "slUids"
    // such that element 0 is 1st line w/ name "Marchand-Bergeron-Pastrnak" (pos aware)
    // and slugs = ',' separated string of slugs
    // EXPECTS data to be a list of obj, obj must have keys: lineNum, position, slUid, slPlayerMaster { name }
    // set posFDG as 'f' or 'd' to get only forwards or defense.  Otherwise get all
    const ret = []
    const positions = !posFDG ? ['f', 'd']
        : posFDG === 'f' ? ['f']
        : posFDG === 'd' ? ['d']
        : posFDG == 'pp' ? ['pp']
        : []
    positions.forEach(pfdg => {
        if (pfdg === 'f') {
            var ps = FORWARD_POSITIONS
            var numLines = 4
        } else if (pfdg === 'd') {
            var ps = DEFENSE_POSITIONS
            var numLines = 3
        } else if (pfdg === 'pp') {
            var ps = PP_POSITIONS
            var numLines = 2
        }

        for (let i=1; i<numLines+1; i++) {
            const nameArr = []
            const slugArr = []
            var skipLine = false
            ps.forEach(pos => {
                const posStr = `${pos}${i}`
                if (posStr in lineup && typeof(lineup[posStr]) === "object" && 'slug' in lineup[posStr]) {
                    slugArr.push(lineup[posStr].slug)
                    nameArr.push(lineup[posStr].lastname)    
                } else {
                    skipLine = true
                }
            })
            const newLine = { name: nameArr.join('-'), slugs: slugsToString(slugArr) }
            if (!skipLine) {
                ret.push(newLine)
            }
        }
    })
    return ret
}

export function lineupQueryToPlayerToPos(data) {
    // Given the results of a lineup query (audited or predicted)
    // return { slUid: "leftwing" }
    const ret = {}
    data.forEach(d => {
        ret[d.slUid] = d.position
    })
    return ret
}

export function lineupQueryToLineup(data) {
    // Given the results of a lineup query (audited or predicted)
    // return { leftdefence1: "zdeno-chara-77" }
    const ret = {}
    data.forEach(d => {
        if ('slug' in d) {
            ret[d.lineupSpot] = d.slug
        } else if ('slPlayerMaster' in d && 'bddPlayerSlug' in d.slPlayerMaster) {
            ret[d.lineupSpot] = d.slPlayerMaster.bddPlayerSlug.slug
        }
    })
    return ret
}

export function orderGroupPlayers(groupType, positions) {
    // Example call: orderGroupPlayers('lines', ['center', 'leftwing', 'rightwing'])
    // return [1, 0, 2]
    var retArr
    var posSet
    if (groupType === 'lines') {
        var groupSize = 3
        posSet = FORWARD_POSITIONS
    } else if (groupType === 'pairs') {
        var groupSize = 2
        posSet = DEFENSE_POSITIONS
    }
    retArr = Array(groupSize)

    const dupPlyrIdx = [] // any player indexes that are duplicates (second center)
    positions.forEach((pos, plyrIdx) => {
        const posIdx = posSet.indexOf(pos) // lw == 0, center ==1, rw ==2
        if (posIdx < 0) {
            // throw Error(`Unknown position "${pos}"`)
            console.error(`Unknown position "${pos}"`)
            return [...new Array(groupSize).keys()]
        }
        if (isNaN(retArr[posIdx])) {
            retArr[posIdx] = plyrIdx
        } else  {
            dupPlyrIdx.push(plyrIdx)
        }
    })
    if (dupPlyrIdx.length) {
        dupPlyrIdx.forEach(i => {
            for (let ri=0; ri<retArr.length; ri++) {
                if (isNaN(retArr[ri])) {
                    retArr[ri] = i
                    break
                }
            }
        })
    }
    return retArr
}


export function slUidsToString(slUidArr) {
    const ret = [...slUidArr].sort().join('-')
    return ret
}

export function slugsToString(slugArr) {
    const ret = [...slugArr].sort().join(',')
    return ret
}

export function slugsStringToArr(slugStr) {
    return slugStr.split(',')
}