import { useQuery } from "@apollo/client"
import { GET_BF_TRADE } from "apollo/queries/bruinsfriendly.queries"
import { Border, Column, Container, Row } from "components/bdd/Layout";
import useBddTeams from "components/bdd/Teams/useBddTeams";
import { Typography } from "components/bdd/Typography";
import usePlaceholder from "components/Placeholder/usePlaceholder"
import { useState } from "react";
import { BDDDepthChartSmall } from "../bdddepthchart";
import { Button } from "react-bootstrap";
import Icon from "components/bdd/Icon";
import { SkipForward } from "react-bootstrap-icons";
import { BFTradeBox } from "../Transactions/Trades/BFTrade";
import Draggable from "components/bdd/draggable";
import { itemTypes } from "constants";
import PlayerHover from "components/PlayersV2/Hover/playerhover";
import { useDragLayer } from "react-dnd";
import { Divider } from "components/bdd/Divider";
import { useBDDDepthChart } from "../DepthCharts/useBDDDepthChart";
import { theme } from "constants";
import { PulsingShadow } from "components/bdd/Pulsing/PulsingShadow";



export const BFTradeDepthChartUpdates = ({ bfTradeId, onCompleted }) => {
  const [teamIdx, setTeamIdx] = useState(0);

  const { data, placeholder } = usePlaceholder(useQuery(GET_BF_TRADE, {
    variables: { id: bfTradeId },
  }));
  console.log(data?.bfTrade)
  const { bddTeams, bfToBdd } = useBddTeams({ leagueSlug: 'NHL' });  

  const tradedPlayers = data?.bfTrade?.tradedPlayers;
  const teamsList = !!data && !!bddTeams
    ? Array.from(new Set(tradedPlayers?.map(tp => bfToBdd(tp.fromTeamSlug))))
    : [];
  const currTeam = teamsList[teamIdx];
  const currPlayers = tradedPlayers?.filter(p => p.toTeamSlug === currTeam.bfTeamSlug && !p.isLayover)
  const hasNextTeam = teamIdx < teamsList.length - 1;
  const handleNext = () => {
    if (hasNextTeam) {
      setTeamIdx(teamIdx + 1)
    } else {
      onCompleted();
    }
  }
  return (
    <Row>
      <Container maxWidth={800}>
        {(!!placeholder || !bddTeams) ? placeholder : (
          <Column gap={2}>
            <Container>
              <BFTradeBox trade={data?.bfTrade} />
            </Container>

            <Typography variant='body2'>
              This trade includes player movement.  Please update each team's depth chart.
            </Typography>
            <Container>
              {!!currTeam && <BFTradeTeamDepthUpdates
                bddTeam={currTeam}
                incomingPlayers={currPlayers}
              />}
            </Container>

            <Row gap={4} justifyContent='end'>
              <Button size='sm' variant='warning'>
                <Typography variant='body1' onClick={handleNext}>
                  {hasNextTeam ? 'Next Team' : 'Finish'}
                </Typography>
              </Button>
            </Row>
          </Column>
        )}
      </Container>
    </Row>
  )
}

const BFTradeTeamDepthUpdates = ({ bddTeam, incomingPlayers }) => {

  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  const { depthChart, depthChartComponent, placeholder } = useBDDDepthChart({ 
    activeTeamSlug: bddTeam.slTeamSlug,
    size: 'sm',
    depthChartProps: { forceAdjustMode: 'BUMP' }
  });

  const playerIsAdded = (slug) => depthChart?.depthChartPlayers.find(p => p.playerSlug === slug);
  const unAddedPlayers = incomingPlayers?.filter(p => !playerIsAdded(p.bfPlayer?.bddPlayer?.slug));

  return (
    <Container>
      <Column gap={1}>
        <Typography variant='h6'>To {bddTeam.longname}</Typography>

        <Container backgroundColor={theme.colors.light.secondary} padding={2}>
          <Column>
            {unAddedPlayers?.map(p => {
              const bfPlayer = p.bfPlayer;
              const bddPlayer = bfPlayer.bddPlayer;
              if (!bddPlayer) return (
                <Typography variant='body2'>WARNING: {bfPlayer.firstName} {bfPlayer.lastName} is un-linked (can't be added to depth charts yet)</Typography>
              )
              return (
                <Row key={p.playerSlug} gap={4}>
                  <PulsingShadow>
                    <Draggable
                      itemType={itemTypes.DEPTH_CHART_PLAYER}
                      data={{ slug: bddPlayer.slug, playerSlug: bddPlayer.slug, ...p }}
                    >
                      <Border>
                        <Container paddingLeft={4} paddingRight={4} backgroundColor='white'>
                          <PlayerHover playerSlug={bddPlayer.slug} disabled={isDragging}>
                            <Typography variant='body2'>
                              {bfPlayer.firstName} {bfPlayer.lastName}
                            </Typography>
                          </PlayerHover>
                        </Container>
                      </Border>
                    </Draggable>
                  </PulsingShadow>

                  <Typography variant='body2'>
                    traded from {p.fromBfTeam.abbreviation} to {p.toBfTeam.abbreviation}
                  </Typography>
                </Row>
              )
            })}
            {unAddedPlayers?.length === 0 && <Typography variant='body2'>All players have been added!</Typography>}
          </Column>
        </Container>
        
        <Divider/>

        <Border>
          {depthChartComponent}
        </Border>
      </Column>
    </Container>
  )
}