import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { EMPTY_TEXT, FileUploadButton, TextEditorField } from 'components/bdd/TextEditor';
import { Typography } from 'components/bdd/Typography';
import BDDButtonGroupField from 'components/form/BDDButtonGroupField';
import BDDInputField from 'components/form/bddinputfield';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import * as Yup from 'yup';
import { BUG_TYPE_OPTIONS, FEEDBACK_TYPES } from '../constants';
import { Label } from 'components/bdd/Label/Label';
import BDDSelectField from 'components/form/bddselectfield';
import { LinkButton } from 'components/bdd/Button';
import { FileAttachments } from 'components/bdd/TextEditor/FileAttachments';
import { getTrackerSessionUrl } from 'helpers/tracker';

export const FeedbackForm = ({
  defaultValues={},
  onSubmit,
  submitting,
  disabled,
  fileUploadFolder='file_uploads',
}) => {
  const [showBugFields, setShowBugFields] = useState(false);

  const schema = Yup.object({
    feedbackType: Yup.string().required('Feedback type is required'),
    title: Yup.string().required('Title is a required field'),
    body: Yup.string(),
    bugType: Yup.string(),
    sourceUrl: Yup.string(),
    payload: Yup.object(),
    isPrivate: Yup.boolean(),
    files: Yup.array(),
  });

  const initialValues = {
    feedbackType: FEEDBACK_TYPES.REQUEST,
    title: '',
    body: EMPTY_TEXT,
    files: [],
    payload: {},
    ...defaultValues
  };

  const feedbackTypeOptions = Object.values(FEEDBACK_TYPES).map(ft => ({ label: <Typography variant='body1' textAlign='center'>{ft}</Typography>, value: ft }))
  const bugTypeOptions = Object.values(BUG_TYPE_OPTIONS).map(bt => ({ label: bt, value: bt }));

  const handleSubmit = (values) => {
    onSubmit({ 
      ...values, 
      body: JSON.stringify(values.body),
      payload: JSON.stringify({
        ...values.payload,
        replaySessionUrl: getTrackerSessionUrl()
      })
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnChange={false}>
      {({ isSubmitting, values, errors, setFieldValue }) => {
        const typeString = values.feedbackType === FEEDBACK_TYPES.BUG 
          ? 'the bug' 
          : values.feedbackType === FEEDBACK_TYPES.REQUEST
          ? 'your request'
          : 'your feedback';
        return (
          <Form>
            <Container padding={3} maxHeight='50vh' overflowY='auto'>
              <Column gap={2}>
                <Container width={300}>
                  <Field
                    name='feedbackType'
                    options={feedbackTypeOptions}
                    component={BDDButtonGroupField}
                  />
                </Container>
                <Container padding={2}>
                  <Column gap={2}>
                    <Container>
                      <Label>Title</Label>
                      <Field
                        autoComplete="off"
                        type="text"
                        name="title"
                        placeholder={`Briefly describe ${typeString}...`}
                        component={BDDInputField}
                      />
                    </Container>

                    <Container>
                      <Label>Body</Label>
                      <Container style={{ border: '1px solid #ddd' }}>
                        <TextEditorField
                          name="body"
                          placeholder={`Enter a longer description of ${typeString}.  You can attach screenshots if you'd like...`}
                          actionButtons={[
                            <FileUploadButton
                              key={`file-upload-button`}
                              folder={fileUploadFolder}
                              usePopover={true}
                              onSubmit={(newValues) => {
                                setFieldValue('files', [
                                  ...values.files,
                                  newValues,
                                ]);
                              }}
                            />,
                          ]}
                        />
                      </Container>
                      {!!values.files.length && <FileAttachments files={values.files} setFiles={(files) => setFieldValue('files', files)} />}
                    </Container>

                    {values.feedbackType === FEEDBACK_TYPES.BUG && (
                      <Container>
                        {/* <LinkButton onClick={() => setShowBugFields(!showBugFields)}>
                          <Typography variant='caption'>{!showBugFields ? 'Show' : 'Hide'} Bug Details</Typography>
                        </LinkButton> */}
                        {/* <Collapse in={showBugFields}> */}
                          {/* <Border>
                            <Container padding={2}> */}
                              <Column gap={2}>
                                <Container>
                                  <Label>Bug Type</Label>
                                  <Field
                                    name='bugType'
                                    options={bugTypeOptions}
                                    component={BDDSelectField}
                                  />
                                </Container>

                                <Container>
                                  <Label>Source URL</Label>
                                  <Field
                                    name='sourceUrl'
                                    component={BDDInputField}
                                  />
                                </Container>
                              </Column>
                            {/* </Container>
                          </Border> */}
                        {/* </Collapse> */}
                      </Container>
                    )}
                  </Column>
                </Container>
              </Column>
            </Container>

            <Divider />
            <Container padding={3}>
              <Row justifyContent="end">
                <Button type="submit" variant="outline-dark" disabled={disabled}>
                  <Typography variant="body1">
                    {submitting ? 'Submitting...' : 'Submit'}
                  </Typography>
                </Button>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
