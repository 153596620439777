import { Border, Column, Container, Row } from "components/bdd/Layout"
import { BDDTrade } from "./BDDTrade"
import { useButtonGroup } from "components/bdd/ButtonGroup"
import { EditBDDTrade } from "components/ProScouting/BDDTrades/EditBDDTrade"
import { useEffect, useState } from "react"
import { IconButton } from "components/bdd/Button"
import { EditIcon } from "components/bdd/Icon/EditIcon"
import { ClipboardData } from "react-bootstrap-icons"
import { useMutation } from "@apollo/client"
import { UPDATE_BDD_TRADE } from "apollo/queries/bddtransactions.queries"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts"
import { useUser } from "helpers/user"


export const BDDTradeDetail = ({
  bddTrade,
  onEditCompleted,
  allowEdit=true,
  showAnalysis=false,
  poTeams
}) => {
  const { isUserAdmin } = useUser();
  const [edit, setEdit] = useState(false);
  const [updateTrade] = useMutation(UPDATE_BDD_TRADE, {
    onCompleted: () => console.log('Trade analyzed and stored'),
    onError: (err) => console.log('Error analyzing trade', err)
  })
  const handleAnalyze = () => {
    updateTrade({
      variables: {
        tradeId: bddTrade.id,
        analyze: true
      }
    })
  }

  useEffect(() => {
    if (!bddTrade.isAnalyzed) {
      handleAnalyze();
    }
  }, [bddTrade.id])

  useEffect(() => {
    setEdit(false);
  }, [bddTrade.id])

  return <Container minHeight='200px' padding={4}>
    <Column gap={2}>
      <Border>
        <Container padding={1}>
          <Row justifyContent='end' gap={8}>
            {isUserAdmin() && <IconButton
              icon={<ClipboardData/>}
              onClick={() => handleAnalyze()}
              tooltip='Analyze'
            />}
            {allowEdit && <IconButton
              icon={<EditIcon />}
              onClick={() => setEdit(!edit)}
              tooltip={edit ? 'Cancel' : 'Edit trade'}
            />}
          </Row>
          {!edit
            ? (
              <BDDTrade 
                trade={bddTrade} 
                showAnalysis={showAnalysis}
                poTeams={poTeams}
              />
            ) : (
              <EditBDDTrade 
                bddTrade={bddTrade} 
                onCompleted={() => !!onEditCompleted && onEditCompleted()} 
              />
            )
          }
        </Container>
      </Border>
    </Column>
  </Container>
}