export class SearchParams {
  constructor() {
    this.searchParams = new URLSearchParams(document.location.search);
  }

  get data() {
    const result = {};
    for (const [key, value] of this.searchParams.entries()) {
      result[key] = value;
    }

    return result;
  }

  paramExists = (key) => this.searchParams.has(key) && this.searchParams.get(key) != '';

  getValue = (key) => (this.paramExists(key) ? this.searchParams.get(key) : null);

  getBoolean = (key, defaultValue = null) =>
    this.paramExists(key) ? this.searchParams.get(key) === 'true' : defaultValue;

  getArray = (key, defaultValue = []) =>
    (this.paramExists(key) && this.searchParams.get(key)?.split(',')) || defaultValue;

  getRange = (key) =>
    this.paramExists(`${key}min`) &&
    this.paramExists(`${key}max`) && {
      min: parseInt(this.searchParams.get(`${key}min`)),
      max: parseInt(this.searchParams.get(`${key}max`)),
    };

  getSerialized = (key) =>
    this.paramExists(key) && JSON.parse(this.searchParams.get(key));

  setValue = (key, value) => {
    this.searchParams.set(key, value);
  };

  setArray = (key, values) => {
    this.searchParams.set(key, values.join(','));
  };

  setRange = (key, min, max) => {
    this.searchParams.set(`${key}min`, min);
    this.searchParams.set(`${key}max`, max);
  };

  setSerialized = (key, obj) => {
    const serialized = JSON.stringify(obj);
    this.searchParams.set(key, serialized);
  };

  clear = () => {
    this.searchParams = new URLSearchParams();
  };

  push(pushOnHistory = false) {
    let url = new URL(window.location.href);
    url.search = this.searchParams.toString();

    pushOnHistory
      ? window.history.pushState({}, '', url)
      : window.history.replaceState({}, '', url);
  }
}

export const buildSearchParams = (obj, key = 'filters') => {
  const searchParams = new SearchParams();
  searchParams.setSerialized(key, obj);
  searchParams.push();
};

export const parseSearchParams = (key = 'filters') => {
  const searchParams = new SearchParams();
  return searchParams.getSerialized(key);
};
