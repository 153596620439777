import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import { theme } from 'constants';
import { GET_ALL_REPORTS_FOR_PLAYER } from 'apollo/queries/scouting.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { getGradeOptions, nameToOptions } from 'components/Scouting/playerformhelpers';
import BDDPlotly from 'components/bdd/bddplotly';
import { useSelect } from 'components/bdd/Select';
import { formatDate } from 'helpers/helpers';

const ControlsContainer = styled.div({
  display: 'flex',
  gap: theme.spacing[3],
  marginTop: theme.spacing[3],
});

const SelectContainer = styled.div({
  width: 200,
});

const FullSelectContainer = styled.div({
  flexGrow: 1,
});

function median(numbers) {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

const periodOptions = [
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'day',
    label: 'Game Date',
  },
];

const xPeriod = {
  year: 'M12',
  month: 'M1',
};

const getDateKey = (date, period) => {
  switch (period) {
    case 'year':
      return `${date.getFullYear()}`;
    case 'month':
      return `${date.getFullYear()}-${date.getMonth()}`;
    case 'day':
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  }
};

export const ScoutingTrendPlot = ({ bddPlayer, scoutId }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
      variables: { rinknetId: bddPlayer.rinknetId, scoutId },
    })
  );

  const { selected: selectedPeriod, select: periodSelect } = useSelect({
    options: periodOptions,
    initialSelectedValue: 'month',
  });

  // TODO: Figure out multiple y-axis and better hover text on the plot
  // before supporting multiselect
  const { selected: selectedGrades, select: gradeSelect } = useSelect({
    options: getGradeOptions(bddPlayer.rinknetPlayer.posFDG),
    initialSelectedValue: 'gameScore',
    // initialSelectedValue: ['gameScore', 'skating'],
    // selectProps: {
    //   isMulti: true,
    //   isSearchable: true,
    // },
  });

  const { selected: selectedScouts, select: scoutSelect } = useSelect({
    options: [
      ...new Set(data?.allPlayerReports.reports.map((report) => report.author)),
    ].map((author) => ({
      value: author,
      label: author,
    })),
    selectProps: {
      isMulti: true,
      isSearchable: true,
      placeholder: 'Filter scouts...',
    },
  });

  if (!data) return placeholder;

  let reports = data.allPlayerReports.reports;
  if (selectedScouts && selectedScouts.length > 0) {
    reports = reports.filter((report) =>
      selectedScouts.find((scout) => scout.value == report.author)
    );
  }

  let pdata = [];
  let pOptions = [];

  [selectedGrades].forEach((selectedGrade) => {
    const options = nameToOptions(
      selectedGrade.value,
      bddPlayer.rinknetPlayer.posFDG.toLowerCase()
    );

    pOptions.push({
      tickvals: options.map((o) =>
        selectedGrade.invert ? selectedGrade.invert - o.value : o.value
      ),
      ticktext: options.map((o) => o.label),
    });

    const reportsByDate = reports.reduce((rbs, report) => {
      const date = new Date(report.date || report.creationDate);
      const key = getDateKey(date, selectedPeriod.value);
      const existingGroup = rbs.find((r) => r.dateKey == key);

      if (existingGroup) {
        existingGroup.reports.push(report);

        if (date < existingGroup.date) {
          existingGroup.date = date;
        }
      } else {
        rbs.push({
          dateKey: key,
          date: new Date(report.date || report.creationDate),
          reports: [report],
        });
      }

      return rbs;
    }, []);

    let x = [];
    let y = [];
    let customdata = [];

    reportsByDate.forEach((reportsOnDate) => {
      const plotDataForDate = reportsOnDate.reports
        .map((report) => {
          const ratings = JSON.parse(
            selectedGrade.skillCategory
              ? report.skillcategoryratings
              : report.ratings
          );

          const reportRating =
            ratings &&
            ratings.length &&
            ratings.find((r) =>
              selectedGrade.ratings.includes(
                selectedGrade.skillCategory ? r.Type.Name : r.Type
              )
            );

          return {
            grade: reportRating && parseFloat(reportRating.Rating.Name),
            scoutName: report.author,
            formattedDate: formatDate(
              new Date(report.date || report.creationDate),
              { format: '%m/%d' }
            ),
          };
        })
        .filter((rating) => !!rating.grade);

      const grade = median(plotDataForDate.map((grade) => grade.grade));
      const hoverText = plotDataForDate
        .map(
          (grade) =>
            `<b>Date: </b>${grade.formattedDate} <b>Rating: </b>${grade.grade} <b>Scout: </b>${grade.scoutName}`
        )
        .join('<br>');

      if (grade) {
        x.push(reportsOnDate.date);
        y.push(selectedGrade.invert ? selectedGrade.invert - grade : grade);
        customdata.push(hoverText);
      }
    });

    pdata.push({
      type: 'bar',
      x,
      y,
      textposition: 'auto',
      customdata,
      ...(xPeriod[selectedPeriod.value] && {
        xperiod: xPeriod[selectedPeriod.value],
        xperiodalignment: 'start',
      }),
      name: selectedGrade.label,
      hovertemplate: '%{customdata}<extra></extra>',
      marker: {
        color: theme.colors.teams.bos.primary,
        line: {
          color: theme.colors.light.text.primary,
          width: 1,
        },
      },
    });
  });

  return (
    <div>
      <ControlsContainer>
        <SelectContainer>{gradeSelect}</SelectContainer>
        <SelectContainer>{periodSelect}</SelectContainer>
        <FullSelectContainer>{scoutSelect}</FullSelectContainer>
      </ControlsContainer>
      <BDDPlotly
        data={pdata}
        layout={{
          hovermode: 'x unified',
          yaxis: {
            fixedrange: true,
            cliponaxis: false,
            tickvals: pOptions.map((po) => po.tickvals)[0],
            ticktext: pOptions.map((po) => po.ticktext)[0],
            automargin: true,
          },
        }}
      />
    </div>
  );
};
