import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CF_SIMILAR_CONTRACTS } from 'apollo/queries/capfriendly.queries';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { IconButton, LinkButton, NoColorLink } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import {
  platformStatColumnIds,
  signingsTableColumns,
} from 'components/ProScouting/Transactions/Signings/constants';
import { theme } from 'constants/theme';
import { removeKeyFromObj, removeNull, roundToX } from 'helpers/data';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Divider } from 'components/bdd/Divider';
import { WindowContext } from 'components/bdd/context';
import useSimilarContractAgg from './hooks/useSimilarContractAgg';
// import { ModalContext } from 'components/bdd/Modal';
import BDDEditInline from 'components/bdd/bddeditinline';
import { useBddStatQueryV2 } from 'apollo/queries';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { CSVLink } from 'react-csv';
import { Download } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { TooltipSpan } from 'components/reports';
import { GET_PLAYER_CARD } from 'apollo/queries/players.queries';
import { targetToPosFDG } from '../Targets/helpers';

const NumberInput = styled.input(({ width }) => ({
  ...theme.typography.stat,
  ...theme.borders.thin,
  ...theme.borders.light,
  width,
  textAlign: 'center',
  // borderRadius: 2,
  // boxShadow: 0,
  // border: 'none',
}));

const Styles = styled.div({
  table: {
    tbody: {
      tr: {
        td: {
          paddingLeft: '2px',
          paddingRight: '2px',
        },
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '1px 1px 15px #ddd',
        },
        borderBottom: '1px solid #ddd',
        minHeight: 40,
      },
      'tr.selected': {
        backgroundColor: theme.colors.light.focus,
      },
    },
  },
});

const contractSearchToggles = [
  {
    header: 'Signing Age',
    id: 'signing_age',
  },
  {
    header: 'gamesPlayed',
    id: 'gamesPlayed',
    defaultUnselected: true,
  },
  {
    header: 'TOI / GP',
    id: 'toiPerGP',
    formatValue: (val) => (!!val ? roundToX(val, 2) : ''),
  },
  {
    header: 'points',
    id: 'points',
    defaultUnselected: true,
  },
  {
    header: 'goals',
    id: 'goals',
    defaultUnselected: true,
  },
  {
    header: 'Goals / GP',
    id: 'goalsPerGP',
    formatValue: (val) => (!!val ? roundToX(val, 2) : ''),
  },
  {
    header: 'Points / GP',
    id: 'pointsPerGP',
    formatValue: (val) => (!!val ? roundToX(val, 2) : ''),
  },
  // {
  //   header: 'XPGF / 20',
  //   id: 'XPGFPer20',
  // },
  // {
  //   header: 'XPGA / 20',
  //   id: 'XPGAPer20',
  // },
  // {
  //   header: 'iXP / 20',
  //   id: 'iXPPer20',
  // },
  // {
  //   header: 'PDPs / 20',
  //   id: 'PDPsPer20',
  // },
];

export const SimilarSigningWrapper = ({
  bddPlayerSlug,
  target, // if target is supplied, should have all the fields we need, otherwise will need to fetch
  ...rest
}) => {
  const { data, placeholder } = useQuery(GET_PLAYER_CARD, {
    variables: { slug: bddPlayerSlug },
    skip: !!target
  });

  if (!!target) {
    return <SimilarFreeAgentSignings
      cfPlayerSlug={target.bddPlayer.cfSlug}
      signingAge={target.bddPlayer.age}
      expiryStatus={target.bddPlayer.cfCapHit?.expiryStatus}
      positionFdg={targetToPosFDG(target)?.toUpperCase()}
    />
  }

  const bddPlayer = data?.bddPlayer;
  if (!!placeholder) return placeholder;
  if (!data) return null;
  return <SimilarFreeAgentSignings
    cfPlayerSlug={bddPlayer.cfSlug}  
    signingAge={bddPlayer.age}
    expiryStatus={bddPlayer.cfCapHit?.expiryStatus}
    positionFdg={bddPlayer.posFDG?.toUpperCase()}
    {...rest}
  />
}

export const SimilarFreeAgentSignings = ({
  signingAge,
  expiryStatus,
  positionFdg,
  cfPlayerSlug,
}) => {
  const { width } = useContext(WindowContext);

  const {
    data,
    loading: statsLoading,
    error: statsError,
  } = useBddStatQueryV2({
    target: 'skaters',
    filters: [
      { col: 'season', val: getCurrentSeason() },
      { col: 'game_type', val: 'nhl_regular' },
      { col: 'cf_slug', val: cfPlayerSlug },
    ],
    statDefs: [
      { slug: 'gamesPlayed' },
      { slug: 'toiPerGP' },
      { slug: 'goals' },
      { slug: 'points' },
      { slug: 'points', norm: 'gp', label: 'pointsPerGP' },
      { slug: 'goals', norm: 'gp', label: 'goalsPerGP' },
    ],
  });

  const ps = data?.data?.[0];
  const statsDict = {
    signing_age: roundToX(signingAge, 1),
    gamesPlayed: ps?.gamesPlayed,
    toiPerGP: ps?.toiPerGP,
    goals: ps?.goals,
    points: ps?.points,
    pointsPerGP: ps?.pointsPerGP,
    goalsPerGP: ps?.goalsPerGP,
  };
  const initialContractValues = Object.keys(statsDict).reduce((acc, curr) => {
    if (!contractSearchToggles.find((t) => t.id === curr).defaultUnselected) {
      acc[curr] = statsDict[curr];
    }
    return acc;
  }, {});

  const [contractInput, setContractInput] = useState(initialContractValues);
  const toggleColumn = (id) => {
    if (id in contractInput) {
      setContractInput(removeKeyFromObj(contractInput, id));
    } else {
      setContractInput({ ...contractInput, [id]: statsDict[id] });
    }
  };
  const renderValue = (id) => {
    const togg = contractSearchToggles.find((t) => t.id === id);
    if (!!togg.formatValue) {
      return togg.formatValue(statsDict[id]);
    }
    return statsDict[id];
  };

  useEffect(() => {
    setContractInput(initialContractValues);
  }, [JSON.stringify(initialContractValues)]);

  const [getSimilarSignings, { data: similarContractData, loading }] = useLazyQuery(
    GET_CF_SIMILAR_CONTRACTS
  );

  let signingStatus = expiryStatus
    ? expiryStatus != '10.2(c)'
      ? expiryStatus
      : 'RFA'
    : 'UFA';

  const findSimilarSignings = (overrides = {}) => {
    getSimilarSignings({
      variables: {
        distance: 0.2,
        targetContractInput: {
          ...removeNull(contractInput, (v) => v),
          // ...overrides, // we need to "override" when opening a new modal, before contractInputs have passed through store
        },
        filters: {
          position_fdg: positionFdg,
          signing_status: signingStatus,
        },
      },
    });
  };

  useEffect(() => {
    if (!!statsLoading || !!statsError) return;
    findSimilarSignings(contractInput);
  }, [contractInput]);

  const similarContracts = similarContractData?.cfSimilarContracts?.contracts?.map(
    (c, index) => ({
      ...c,
      distance: similarContractData?.cfSimilarContracts.distances[index],
    })
  );

  const {
    component: similarContractAggComponent,
    selectedContractIds,
    setSelectedContractIds,
  } = useSimilarContractAgg({
    contracts: similarContracts,
  });

  const columns = [
    'select',
    'signedDate',
    'player',
    'team',
    'signingAge',
    'numYears',
    'aav',
  ]
    .concat(width > theme.breakpoints.md ? ['totalSalary', 'performanceBonus'] : [])
    .concat(platformStatColumnIds)
    .concat(['similarity']);

  const handleToggleContractId = (contractId) => {
    if (selectedContractIds.find((sid) => sid === contractId)) {
      setSelectedContractIds(selectedContractIds.filter((sid) => sid !== contractId));
    } else {
      setSelectedContractIds(selectedContractIds.concat([contractId]));
    }
  };

  const tableColumns = [
    {
      id: 'select',
      Header: (
        <LinkButton
          onClick={() => {
            if (selectedContractIds?.length > 0) {
              setSelectedContractIds([]);
            } else {
              setSelectedContractIds(similarContracts.map((c) => c.id));
            }
          }}
        >
          <Typography variant="caption">All</Typography>
        </LinkButton>
      ),
      accessor: (s) => (
        <Container>
          <input
            type="checkbox"
            checked={selectedContractIds.includes(s.id)}
            onChange={() => handleToggleContractId(s.id)}
          />
        </Container>
      ),
    },
  ].concat(signingsTableColumns.filter((c) => columns.includes(c.id)));

  const exportData = similarContracts
    ?.filter((s) => selectedContractIds.includes(s.id))
    .map((s) =>
      signingsTableColumns.reduce((data, column) => {
        if (!!column.export) {
          data[column.id] = column.export(s);
        }

        return data;
      }, {})
    );

  return (
    <Container padding={3}>
      <Column gap={2}>
        <Container>
          {/* <Typography variant="body1">Search Parameters</Typography> */}
          <Container width="100%">
            <Row justifyContent="space-between">
              {contractInput && (
                <Row flexWrap columnGap={8}>
                  {contractSearchToggles.map((sp) => {
                    return (
                      <Column gap={2} key={sp.id}>
                        <Row gap={4}>
                          <Typography variant="body1" noWrap>
                            {sp.header}
                          </Typography>
                          <Container>
                            <input
                              type="checkbox"
                              checked={sp.id in contractInput}
                              onChange={() => toggleColumn(sp.id)}
                            />
                          </Container>
                        </Row>
                        <Typography variant="body2" textAlign="center" noWrap>
                          <BDDEditInline
                            name={sp.id}
                            trackValueChanges={true}
                            editingProps={{ type: 'number' }}
                            emptyValue={'None'}
                            defaultValue={renderValue(sp.id)}
                            onChange={(name, val) =>
                              setContractInput({
                                ...contractInput,
                                [name]: val != '' ? parseFloat(val) : null,
                              })
                            }
                          />
                        </Typography>
                      </Column>
                    );
                  })}
                </Row>
              )}
              <Button size="sm" variant="outline-dark" onClick={findSimilarSignings}>
                <Row columnGap={1}>
                  {loading && <IconButton loading={true} />}
                  <Typography variant="body1">
                    {loading ? 'Finding...' : 'Find Similar Signings'}
                  </Typography>
                </Row>
              </Button>
            </Row>
          </Container>
        </Container>
        {loading ? (
          <BDDLoader variant="squares" />
        ) : similarContracts && similarContracts.length == 0 ? (
          <Typography variant="error">No similar contracts found</Typography>
        ) : (
          <Column>
            {similarContractAggComponent}
            <Container paddingTop={4} paddingBottom={4}>
              <Divider />
            </Container>
            <Container paddingBottom={2}>
              <Row justifyContent="end">
                {similarContracts && (
                  <CSVLink data={exportData} filename={`similar_contracts_${cfPlayerSlug}.csv`}>
                    <NoColorLink>
                      <TooltipSpan
                        content={
                          <Typography variant="body2">Download selected data</Typography>
                        }
                      >
                        <Button variant="outline-dark">
                          <Icon icon={<Download />} size={14} />
                        </Button>
                      </TooltipSpan>
                    </NoColorLink>
                  </CSVLink>
                )}
              </Row>
            </Container>
            <Styles>
              <BDDSortableTable
                maxHeight={'50vh'}
                data={similarContracts || []}
                columns={tableColumns}
                columnJustify="start"
                // virtualizeRows={true}
                onRowClick={(row) => handleToggleContractId(row.original.id)}
                trClassCallback={(row) =>
                  selectedContractIds.find((cid) => cid == row.original.id)
                    ? 'selected'
                    : ''
                }
              />
            </Styles>
          </Column>
        )}
      </Column>
    </Container>
  );
};
