import { useQuery } from '@apollo/client';
import { GET_SIMILAR_PLAYER_MADE_IT_PCT } from 'apollo/queries/models.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { getRedGreenColor } from 'helpers/tables';
import { roundToX } from 'helpers/data';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const usePlayerMadeItResultStore = create(
  immer((set, get) => ({
    resultsByRinknetId: {},
    actions: {
      loadData: (data) =>
        set((state) => {
          state.resultsByRinknetId = data.similarPlayersMadeItPct.reduce((dict, m) => {
            dict[m.rinknetId] = m;
            return dict;
          }, {});
        }),
    },
  }))
);

export const DraftListPlayerMadeItResult = ({ rinknetId, rinknetIds }) => {
  const { loadData } = usePlayerMadeItResultStore((state) => state.actions);
  const { data } = usePlaceholder(
    useQuery(GET_SIMILAR_PLAYER_MADE_IT_PCT, {
      variables: {
        rinknetIds,
      },
      onCompleted: loadData,
    })
  );

  const hockeyModelResult =
    data &&
    data.similarPlayersMadeItPct.length > 0 &&
    data.similarPlayersMadeItPct.find((model) => model.rinknetId == rinknetId);

  return hockeyModelResult ? (
    <Container>
      <Row columnGap={1}>
        <Typography variant="stat">Made it</Typography>
        <Border>
          <Container
            paddingLeft={1}
            paddingRight={1}
            paddingTop={0.5}
            paddingBottom={0.5}
            style={{
              backgroundColor: getRedGreenColor(
                hockeyModelResult.madeItPct,
                0,
                40,
                false,
                1.5
              ),
            }}
          >
            <Typography variant="stat">
              {roundToX(hockeyModelResult.madeItPct, 2)}%
            </Typography>
          </Container>
        </Border>
      </Row>
    </Container>
  ) : !data ? (
    <Container>
      <Typography variant="stat">Loading...</Typography>
    </Container>
  ) : null;
};
