import { LIST_ID_TYPES } from "constants";

export const getRinknetPlayer = (player, list) =>
  list.idType == LIST_ID_TYPES.bdd_player_slug
    ? player.bddPlayer?.rinknetPlayer
    : player.rinknetPlayer;

export const getBDDPlayer = (player, list) =>
  list.idType == LIST_ID_TYPES.bdd_player_slug
    ? player.bddPlayer
    : player.rinknetPlayer?.bddPlayerSlug;

export const getSLPlayerMaster = (player, list) =>
  list.idType == LIST_ID_TYPES.bdd_player_slug
    ? player.bddPlayer?.slPlayerMaster
    : player.rinknetPlayer?.bddPlayerSlug?.slPlayerMaster;

export const getBDDPlayerSlug = (player, list) =>
  list.idType == LIST_ID_TYPES.bdd_player_slug
    ? player.playerId
    : player.rinknetPlayer?.bddPlayerSlug?.slug;
