import { Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { getStrengthChoices } from 'helpers/filters';
import { useContext } from 'react';
import { VideoContext } from '../..';

export const StrengthFilter = ({}) => {
  const { filters, sourceFilters, setSourceFilters, eventType } =
    useContext(VideoContext);

  const manpowerCol =
    eventType == 'events' ? 'manpower_situation' : 'manpower_situation_in';

  if (!sourceFilters || !filters) return null;

  const strengthCol = eventType == 'events' ? 'strength' : 'strength_in';
  const selectedStrength = !sourceFilters[manpowerCol]
    ? 'None'
    : !sourceFilters[strengthCol]
    ? sourceFilters[manpowerCol].val
    : `${sourceFilters[manpowerCol]?.val}${sourceFilters[strengthCol]?.val || ''}`;

  const strengthOptions = getStrengthChoices(false);
  const involvement =
    eventType == 'shifts'
      ? 'primary'
      : filters && !('opp_team_slug' in filters[0])
      ? 'primary'
      : 'woia';

  const options = strengthOptions.map((group) => ({
    ...group,
    options: group.options.map((o) => ({
      ...o,
      value: involvement == 'woia' ? o.oppValue : o.value,
    })),
  }));

  return (
    <Container minWidth={150}>
      <Typography variant="body1">Strength Situation</Typography>
      <StyledBDDSelect
        options={options}
        selectedValue={selectedStrength}
        onChange={(n, v) => {
          setSourceFilters({
            [manpowerCol]: {
              id: manpowerCol,
              col: manpowerCol,
              val: v == 'None' ? null : v.length == 4 ? v.slice(0, 2) : v,
            },
            [strengthCol]: {
              id: strengthCol,
              col: strengthCol,
              val: v == 'None' ? null : v.length == 4 ? v.slice(2, 4) : null,
            },
          });
        }}
        selectProps={{
          isSearchable: false,
          isClearable: false,
        }}
      />
    </Container>
  );
};
