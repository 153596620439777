import { useQuery } from '@apollo/client';
import { GET_ALL_REPORTS } from 'apollo/queries/scouting.queries';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { Report } from 'components/ScoutingV2/components/reports/Report';

export const ReportModal = ({ playerRinknetId, scoutId, gameId }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS, {
      variables: {
        limit: 200,
        filters: JSON.stringify([
          { col: 'game_id', val: [gameId], cond: 'isin' },
        ]),
      },
    })
  );

  if (!data) return placeholder;
  let reports = data.allReports.data;

  console.log(reports);

  if (playerRinknetId) {
    console.log(playerRinknetId, reports);

    reports = reports.filter((r) => r.playerrinknetid == playerRinknetId);
  }

  if (scoutId) {
    reports = reports.filter((r) => r.scoutid == scoutId);
  }

  if (reports.length == 0) {
    return <Typography variant="body1">No report found</Typography>;
  }

  if (reports.length == 1) {
    return <Report key={reports[0].id} report={reports[0]} />;
  }

  return (
    <Container height={'50vh'} overflowY="auto">
      <Column gap={2}>
        {reports.map((r) => (
          <Report key={r.id} report={r} />
        ))}
      </Column>
    </Container>
  );
};
