import { pToShort } from 'components/TeamsV2/Lineups';
import { playerRoleOptions } from './constants';

export const lineupSpotStatsToPositions = (
  lineupSpotStats,
  { minCount = 5 } = {}
) => {
  if (!lineupSpotStats) return [];

  return lineupSpotStats
    .filter((lss) => lss.count >= minCount)
    .sort((a, b) => b.count - a.count)
    .reduce((positions, lss) => {
      positions.push(`${lss.lineNum}${pToShort[lss.position]}`);

      return positions;
    }, []);
};

export const lineupSpotStatsToRoles = (lineupSpotStats) => {
  if (!lineupSpotStats) return [];

  return playerRoleOptions
    .map((role) => {
      return evaluatePlayerRole(lineupSpotStats, role) ? role : null;
    })
    .filter((r) => !!r);
};

// Ideally player roles should exist in the db so that this function doesn't need to exist
const evaluatePlayerRole = (lineupSpotStats, role) => {
  let matchCount = 0;
  const filter = role.filter;

  lineupSpotStats.forEach((lss) => {
    if (!!filter.positions && !filter.positions.includes(lss.position)) {
      return;
    }

    if (!!filter.lineNums && !filter.lineNums.includes(lss.lineNum)) {
      return;
    }

    matchCount += lss.count;
  });

  if (filter.cond == '>=') {
    return matchCount >= filter.val;
  } else if (filter.cond == '<=') {
    return matchCount <= filter.val;
  } else {
    return false;
  }
};


const sortMapping = {
  contract: 'final_cap_hit'
}
export const mapSort = (sort) => {
  // given a sort [{ id, desc }] map columns as necessary
  // some columns (contract) map to others (final_cap_hit) when sorting
  return sort.map(s => {
    if (s.id in sortMapping) {
      return { ...s, id: sortMapping[s.id] }
    } else {
      return s
    }
  })
}