import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';
import { SlashCircle } from 'react-bootstrap-icons';

export const BFTerminatedTag = ({ contract, ...tagProps }) => {
  if (!contract?.isTerminated) return null;

  return (
    <BDDTag
      tag={{
        color: theme.colors.states.danger,
        textColor: theme.colors.dark.text.primary,
        tag: (
          <Typography variant="body1">
            <Icon color={theme.colors.light.background} icon={<SlashCircle />} />
          </Typography>
        ),
        description: 'Contract terminated',
      }}
      {...tagProps}
    />
  );
};
