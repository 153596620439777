import React from 'react';
import styled from 'styled-components';
import { Archive, ArchiveFill } from 'react-bootstrap-icons';
import { theme } from 'constants';

// This icon class is only really necessary
// due to the alignment issues of the svg icons
const ArchiveStyles = {};

const StyledArchive = styled(Archive)(ArchiveStyles);
const StyledArchiveFill = styled(ArchiveFill)(ArchiveStyles);

export const ArchiveIcon = ({ enabled, ...props }) =>
  enabled ? <StyledArchiveFill {...props} /> : <StyledArchive {...props} />;
