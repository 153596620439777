import React, { useState, useEffect } from 'react';

import { arraysEqual } from 'helpers/data';
import { SELECT_VARIANTS, StyledBDDSelect } from './StyledBDDSelect';

const useSelect = ({
  options,
  initialSelectedValue,
  variant = SELECT_VARIANTS.DEFAULT, // default or outlined
  selectProps,
  size = 'default', // default or sm
  color = 'light',
  updateOnOptionsChange = false,
  updateOnInitialChange=false,
  noInitialSelectedValue = false,
  onChange, // called with (value, setSelectedValue)
  collapseIndicator=false,
  renderWithoutOptions=false
}) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [initialOptions, setInitialOptions] = useState(options);

  useEffect(() => {
    if (updateOnOptionsChange && !arraysEqual(options, initialOptions)) {
      setSelectedValue(initialSelectedValue);
      setInitialOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (updateOnInitialChange) {
      setSelectedValue(initialSelectedValue);
    }
  }, [initialSelectedValue])

  if (!options && !renderWithoutOptions) {
    return {
      selected: null,
      select: null,
    };
  }

  const flatOptions = options
    ?.filter((o) => !!o.value)
    ?.concat(options.filter((o) => !!o.options).flatMap((o) => o.options));

  let selectedOption;
  if (selectProps?.isMulti) {
    selectedOption = selectedValue
      ? flatOptions?.filter((option) => selectedValue.includes(option.value))
      : [];
  } else {
    selectedOption = selectedValue
      ? flatOptions.find((option) => option.value == selectedValue)
      : flatOptions.length > 0 && !noInitialSelectedValue
      ? flatOptions[0]
      : null;
  }

  return {
    selected: selectedOption,
    selectedValue,
    resetSelection: () => setSelectedValue(initialSelectedValue),
    forceSetSelected: (value) => setSelectedValue(value),
    select: (
      <StyledBDDSelect
        name={selectProps?.name}
        options={options}
        selectedValue={selectedValue}
        variant={variant}
        size={size}
        color={color}
        onChange={(n, v) => {
          setSelectedValue(v);
          if (!!onChange) onChange(v, setSelectedValue);
        }}
        selectProps={selectProps}
        collapseIndicator={collapseIndicator}
        renderWithoutOptions={renderWithoutOptions}
      />
    ),
  };
};

export default useSelect;
