import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Column, Container, Row } from 'components/bdd/Layout';
import useStatDefinitionSearchSelect from 'components/StatManager/v2/useStatDefinitionsSearchSelect';
import { useContext, useEffect, useState } from 'react';
import { VideoContext } from '../..';

const defaultStatDefinitionForPosition = {
  F: 'shotAttempts',
  D: 'defensivePlays',
  G: 'shotsOnGoalAgainst',
};

export const StatDefinitionFilter = ({ ignorePositionChange }) => {
  const {
    slPlayer,
    statDefinitionSlug: statDefinition,
    setStatDefinition,
  } = useContext(VideoContext);

  const { selected: selectedStatSlug, searchComponent: statDefSearch } =
    useStatDefinitionSearchSelect({
      initialSelectedValue: statDefinition,
      isClearable: false,
    });

  useEffect(() => {
    setStatDefinition(selectedStatSlug);
  }, [selectedStatSlug]);

  const [position, setPosition] = useState(slPlayer?.primaryposition);

  const groupOptions = [
    ...(slPlayer?.primaryposition == 'G'
      ? [
          {
            label: 'Goalie',
            value: 'goalie',
            options: [
              {
                label: 'Saves',
                value: 'shotsOnGoalAgainst',
              },
              {
                label: 'Rebounds',
                value: 'reboundsGivenUp',
              },
              {
                label: 'Goals Against',
                value: 'goalsAgainst',
              },
              {
                label: 'Passes',
                value: 'passAttempts',
              },
            ],
          },
        ]
      : [
          {
            label: 'Offense',
            value: 'offense',
            options: [
              {
                label: 'Shot Attempts',
                value: 'shotAttempts',
              },
              {
                label: 'Scoring Chances',
                value: 'highDangerChancesOnNet',
              },
              {
                label: 'Goals',
                value: 'goals',
              },
              {
                label: 'Assists',
                value: 'assists',
              },
              {
                label: 'Expected Assists',
                value: 'iXA',
              },
              {
                label: 'Faceoffs',
                value: 'faceOffAttempts',
              },
            ],
          },
          {
            label: 'Defense',
            value: 'defense',
            options: [
              {
                label: 'Shot Attempts Against',
                value: 'XPGA',
              },
              {
                label: 'Defensive Plays',
                value: 'defensivePlays',
              },
              {
                label: 'Takeaways',
                value: 'takeaways',
              },
              {
                label: 'Battles',
                value: 'contestedLPRAttempts',
              },
              {
                label: 'DZ LPRs',
                value: 'dzLPRs',
              },
            ],
          },
          {
            label: 'Puck Play',
            value: 'puckPlay',
            options: [
              {
                label: 'Pass Attempts',
                value: 'passAttempts',
              },
              {
                label: 'Possession',
                value: 'PDPs',
              },
              {
                label: 'Entries',
                value: 'controlledEntryAttempts',
              },
              {
                label: 'NZDZ Turnovers',
                value: 'nzdzTurnover',
              },
              {
                label: 'OZ Turnovers',
                value: 'ozTurnover',
              },
            ],
          },
        ]),
    {
      label: 'Custom',
      value: 'custom',
      options: [],
    },
  ];

  const getGroupFromStatDefinition = (statDefinition) =>
    groupOptions.find((go) => go.options.find((o) => o.value == statDefinition))?.value ||
    'custom';

  const {
    buttonGroup: groupGroup,
    selectedOption,
    forceSelectOption: forceSelectGroupOption,
  } = useButtonGroup({
    variant: 'link',
    options: groupOptions,
    initialSelectedValue: getGroupFromStatDefinition(statDefinition),
  });

  const { buttonGroup, forceSelectOption } = useButtonGroup({
    options: selectedOption.options,
    initialSelectedValue: statDefinition,
    noInitialSelectedValue: true,
    noWrap: true,
    onClick: (option) => {
      setStatDefinition(option.value);
    },
  });

  useEffect(() => {
    forceSelectOption && forceSelectOption(statDefinition);
  }, [statDefinition]);

  useEffect(() => {
    if (ignorePositionChange) return;
    const newPosition = slPlayer ? slPlayer.primaryposition : 'F';
    setPosition(newPosition);

    if (!position || newPosition == position) return;
    const newStatDefinition = defaultStatDefinitionForPosition[newPosition];
    forceSelectGroupOption(getGroupFromStatDefinition(newStatDefinition));
    forceSelectOption && forceSelectOption(newStatDefinition);
    setStatDefinition(newStatDefinition);
  }, [slPlayer]);

  return (
    <Container width="100%">
      <Row flexWrap columnGap={2}>
        <Container width="100%">
          <Column gap={2}>
            <Row columnGap={3} justifyContent="space-between">
              {groupGroup}
            </Row>
            <Container overflow="auto">{buttonGroup}</Container>
            {selectedOption.value == 'custom' && statDefSearch}
          </Column>
        </Container>
      </Row>
    </Container>
  );
};
