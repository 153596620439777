import React from 'react';
import { useField } from 'formik';
import { StyledBDDSelect } from './StyledBDDSelect';

export const SelectField = ({ ...rest }) => {
  const [_, meta, helpers] = useField(rest.name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <StyledBDDSelect
      selectedValue={value}
      onChange={(_, v) => {
        setValue(v);
      }}
      {...rest}
    />
  );
};
