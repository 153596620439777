import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';
import { usePlayerSLGames } from '../hooks';

export const PlayerLeagueFilter = ({ slug, slugs, selectProps, isGoalie=false }) => {
  const { setFilters, filters } = useContext(SLStatContext);
  const { leagueOptions } = usePlayerSLGames({ slug, slugs, isGoalie });

  const select = (
    <StyledBDDSelect
      size="sm"
      options={leagueOptions || []}
      selectedValue={filters['league']}
      onChange={(n, v) => {
        setFilters({ league: v });
      }}
      selectProps={{
        loading: !leagueOptions,
        ...selectProps,
      }}
    />
  );

  return (
    <Container minWidth={150}>
      <Column>
        <Typography variant="label">League</Typography>
        {select}
      </Column>
    </Container>
  );
};
