import { ModalContextProvider } from 'components/bdd/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';

import { HoverInteractive } from '../../bdd';
import ManagePlayerTagModal from '../../PlayerTagging/manageplayertagmodal';
import { PlayerHoverContent } from './PlayerHoverContent';

const PlayerHoverContainer = styled.div({
  width: 600,
});

export default function PlayerHover({
  playerSlug,
  rinknetId,
  slLeaguePlayerId,
  defaultRinknetPlayer,
  delay = { show: 200, hide: 300 },
  children,
  disabled = false,
}) {
  const [showTagModal, setShowTagModal] = useState(false);
  return (
    <>
      <HoverInteractive
        delay={delay}
        renderContent={(forceClose) => (
          <PlayerHoverContainer>
            <PlayerHoverContent
              playerSlug={playerSlug}
              rinknetId={rinknetId}
              slLeaguePlayerId={slLeaguePlayerId}
              defaultRinknetPlayer={defaultRinknetPlayer}
              forceClose={forceClose}
              handleShowTagModal={() => setShowTagModal(true)}
              displayFooter={true}
            />
          </PlayerHoverContainer>
        )}
        disabled={disabled}
      >
        {children}
      </HoverInteractive>
      {showTagModal && (
        <ManagePlayerTagModal
          show={showTagModal}
          handleClose={() => setShowTagModal(false)}
          bddPlayerSlug={playerSlug}
        />
      )}
    </>
  );
}
