import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useModal } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { ProObjectiveContext } from '../ProObjectiveContextProvider';
import { ObjectiveTransactions } from '../Transactions/ObjectiveTransactions';
import { BDDTransaction } from '../Transactions/BDDTransaction';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { CreateTransactionModal } from '../Transactions/CreateTransactionModal';
import { txToFirstVariant } from '../Transactions/helpers';
import { ScenarioContext } from './ScenarioContextProvider';

export const ScenarioTxModal = ({
  scenario,
  handleAttachTx,
  handleRemoveTx,
  canSign,
  canTrade,
  canViewExistingTransactions,
  hideModal,
}) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    variant: 'link',
    initialSelectedValue: canViewExistingTransactions ? 'existing' : 'create',
    options: [
      { label: 'Existing', value: 'existing' },
      { label: 'Create', value: 'create' },
    ],
  });

  return (
    <Container>
      {canViewExistingTransactions && buttonGroup}
      {selectedValue === 'existing' ? (
        <Container>
          <Typography variant="body2">
            Select a transaction to add it to this scenario.
          </Typography>
          <ObjectiveTransactions
            showHeader={false}
            onTxClick={(tx, variantId) => handleAttachTx(tx, variantId)}
            highlightedTransactions={scenario.transactions.map((t) => ({
              id: t.id,
              variantId: t.variantId,
            }))}
            handleRemove={(txid, force) => handleRemoveTx(txid)}
            removeTooltip="Remove transaction from scenario"
            canSign={canSign}
            canTrade={canTrade}
            canViewExistingTransactions={canViewExistingTransactions}
          />
        </Container>
      ) : (
        <Container>
          <CreateTransactionModal
            proObjectiveSlug={scenario.proObjectiveSlug}
            onCompleted={(poTransaction) => {
              handleAttachTx(poTransaction, txToFirstVariant(poTransaction)?.id);
              hideModal();
            }}
            defaultTab={canTrade ? 'trade' : 'signing'}
            canSign={canSign}
            canTrade={canTrade}
            tradeFormProps={{
              teamDepthChartIds: [{ teamSlug: scenario.teamSlug, depthChartId: scenario.depthChartId }],
            }}
          />
        </Container>
      )}
    </Container>
  );
};

export const useHandleAttachTx = ({ scenarioId, proObjectiveSlug, onUpdate }) => {
  const scenarioCtx = useContext(ScenarioContext);
  const objectiveCtx = useContext(ProObjectiveContext);
  const { updateScenario } = !!scenarioCtx ? scenarioCtx : objectiveCtx;

  return (tx, variantId) => {
    // attaches or changes variant on attached transaction
    if (!!tx.variantId && !variantId) return;
    if (!!variantId && tx.variantId == variantId) return;
    updateScenario({
      variables: {
        input: {
          id: scenarioId,
          proObjectiveSlug: proObjectiveSlug,
        },
        transactionsToAttach: [
          {
            id: tx.id,
            transactionType: tx.transactionType,
            variantId,
          },
        ],
      },
      onCompleted: () => {
        onUpdate && onUpdate();
      },
    });
  };
};

export const ScenarioTx = ({
  scenario,
  disabled,
  canSign = true,
  canTrade = true,
  canViewExistingTransactions = true,
  onUpdate,
}) => {
  const { renderModal, showModal, setShowModal } = useModal({
    title: 'Scenario Transactions',
    size: 'xl',
  });

  const { updateScenario } = useContext(ScenarioContext);
  const handleAttachTx = useHandleAttachTx({ scenarioId: scenario.id, proObjectiveSlug: scenario.proObjectiveSlug, onUpdate });

  const handleRemoveTx = disabled
    ? null
    : (tid) => {
        updateScenario({
          variables: {
            input: {
              id: scenario.id,
              proObjectiveSlug: scenario.proObjectiveSlug,
            },
            transactionsToRemove: [tid],
          },
          onCompleted: () => {
            onUpdate && onUpdate();
          },
        });
      };

  const addTransactionsButton = !disabled && (
    <Button variant="outline-dark" size="sm" onClick={() => setShowModal(true)}>
      <Row gap={4}>
        <Icon icon={<PlusCircle />} />
        <Typography variant="stat">
          <b>Add Transactions</b>
        </Typography>
      </Row>
    </Button>
  );

  const trades =
    canTrade &&
    scenario.transactions
      .filter((t) => t.transactionType === 'TRADE')
      .map((t) => (
        <BDDTransaction
          key={t.id}
          transaction={t}
          showFullscreenIcon={true}
          handleRemove={handleRemoveTx}
          removeTooltip={'Remove from scenario'}
          highlightVariantId={t.variantId}
          handleClick={(tx, variantId) => handleAttachTx(tx, variantId)}
          canEdit={!disabled}
        />
      ));

  const signings =
    canSign &&
    scenario.transactions
      .filter((t) => t.transactionType === 'SIGNING')
      .map((t) => (
        <BDDTransaction
          key={t.id}
          transaction={t}
          showFullscreenIcon
          handleRemove={handleRemoveTx}
          removeTooltip={'Remove from scenario'}
          highlightVariantId={t.variantId}
          handleClick={(tx, variantId) => handleAttachTx(tx, variantId)}
          canEdit={!disabled}
          onlyShowEditSigning
        />
      ));

  const buttonHeader = (
    <Row justifyContent="space-between">
      <Typography variant="body1" color="#777">
        {canTrade ? 'Trades' : 'Signings'}
      </Typography>
      {addTransactionsButton}
    </Row>
  );

  return (
    <Container>
      {showModal &&
        renderModal(
          <ScenarioTxModal
            scenario={scenario}
            handleAttachTx={handleAttachTx}
            handleRemoveTx={handleRemoveTx}
            hideModal={() => setShowModal(false)}
            canSign={canSign}
            canTrade={canTrade}
            canViewExistingTransactions={canViewExistingTransactions}
          />
        )}
      <Column gap={8}>
        <Column gap={2}>
          {buttonHeader}
          {canTrade ? trades : signings}
        </Column>
        {canTrade && canSign && (
          <Column gap={2}>
            <Typography variant="body1" color="#777">
              Signings
            </Typography>
            {signings}
          </Column>
        )}
      </Column>
    </Container>
  );
};
