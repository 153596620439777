import { IconButton, LinkButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { LinkPopover } from 'components/bdd/Popover';
import { EMPTY_TEXT, TextEditor } from 'components/bdd/TextEditor';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants/theme';
import { formatDollars } from 'helpers/contracts';
import { PencilSquare, Sticky, Trash } from 'react-bootstrap-icons';
import { useRenderDepthChartSpotWrapperForm } from './DepthChartSpotWrapperForm';
import { pToShort } from 'components/TeamsV2/Lineups';
import { Divider } from 'components/bdd/Divider';
import { getColorWithOpacity } from 'helpers/plotting';
import { useDeleteDepthChartSpotWrapper } from './useDeleteDepthChartSpotWrapper';

export const DepthChartSpotWrapper = ({
  depthChartId,
  wrappers,
  wrapper,
  playerSize,
  onUpdate,
  children,
  refetchQueries
}) => {
  const handleRenderModal = useRenderDepthChartSpotWrapperForm({
    existingWrappers: wrappers,
    wrapper,
    depthChartId,
    onUpdate,
    refetchQueries
  });

  const wrapperHeight = playerSize == 'sm' ? 20 : 45;
  const wrapperBackgroundColor = getColorWithOpacity(theme.colors.states.neutral, 0.1);

  const { handleDelete, loading } = useDeleteDepthChartSpotWrapper({
    wrapper,
    depthChartId,
    refetchQueries
  });

  if (!!wrapper) {
    const { position, lineNum, capHit, term } = wrapper;
    const capHitDisplay = (capHit && term) ? `${formatDollars(capHit * 1e6)} x ${term}` : '';

    const wrapperLabel = (
      <Typography variant="body2">
        {lineNum}
        {pToShort[position]} | <b>{capHitDisplay}</b>
      </Typography>
    );

    const getLinkButtonContent = (forceClose) => (
      <Container padding={1} width={200}>
        <Column gap={1}>
          <Row justifyContent="space-between">
            {wrapperLabel}
            <Row columnGap={1}>
              <IconButton
                icon={<PencilSquare />}
                hoverColor={theme.colors.light.highlight}
                tooltip={<Typography variant="body1">Edit this wrapper</Typography>}
                tooltipPlacement={'bottom'}
                onClick={() => {
                  handleRenderModal();
                  forceClose();
                }}
              />
              <IconButton
                icon={<Trash />}
                loading={loading}
                hoverColor={theme.colors.light.highlight}
                tooltip={<Typography variant="body1">Delete this wrapper</Typography>}
                tooltipPlacement={'bottom'}
                onClick={handleDelete}
              />
            </Row>
          </Row>
          <Divider />
        </Column>
        {!!wrapper.notes && (
          <Container paddingTop={2}>
            <TextEditor
              variant="horizontal"
              readOnly
              size="sm"
              text={JSON.parse(wrapper.notes) || EMPTY_TEXT}
            />
          </Container>
        )}
      </Container>
    );

    const capDetailsBadge = (
      <LinkPopover
        hideTransition
        renderLink={(setShow, target) => {
          return (
            <Container
              ref={target}
              backgroundColor={theme.colors.states.neutral}
              padding={0.5}
              style={{
                zIndex: 2,
                ...(playerSize == 'sm'
                  ? {
                      height: '100%',
                    }
                  : {
                      position: 'absolute',
                      left: 0,
                      top: 0,
                    }),
              }}
              variant="button"
              onClick={() => setShow((show) => !show)}
            >
              <Row columnGap={1}>
                <Typography variant="body2">
                  <Icon
                    icon={<Sticky />}
                    color={
                      !!wrapper?.notes
                        ? theme.colors.teams.bos.primary
                        : theme.colors.dark.text.primary
                    }
                  />
                </Typography>
                {capHitDisplay && (
                  <Typography
                    variant="stat"
                    noWrap
                    color={theme.colors.dark.text.primary}
                  >
                    <b>{capHitDisplay}</b>
                  </Typography>
                )}
              </Row>
            </Container>
          );
        }}
        renderContent={getLinkButtonContent}
      />
    );

    let truncatedNotes = !children && !!wrapper?.notes && (
      <Row justifyContent="center">
        <Container maxHeight={wrapperHeight} overflow="hidden">
          <TextEditor
            variant="horizontal"
            readOnly
            size={'sm'}
            text={JSON.parse(wrapper.notes) || EMPTY_TEXT}
            backgroundColor={'transparent'}
          />
        </Container>
      </Row>
    );

    return (
      <Border borderColor={theme.colors.states.neutral} borderStyle="dashed">
        <Container
          minHeight={playerSize == 'sm' ? 20 : 45}
          backgroundColor={wrapperBackgroundColor}
        >
          <Row>
            {capDetailsBadge}
            {!children && playerSize == 'sm' && truncatedNotes}
            <Container flexGrow>{children}</Container>
          </Row>
          {!children && playerSize != 'sm' && (
            <Container>
              <Column alignItems="center">
                <LinkPopover
                  renderLink={(setShow, target) => (
                    <Container
                      variant="button"
                      ref={target}
                      onClick={() => setShow((show) => !show)}
                      padding={0.5}
                    >
                      <Row columnGap={1}>{wrapperLabel}</Row>
                    </Container>
                  )}
                  renderContent={getLinkButtonContent}
                />
                {truncatedNotes}
              </Column>
            </Container>
          )}
        </Container>
      </Border>
    );
  }

  return children;
};
