import BDDErrorBoundary from "components/bdd/bdderrorboundary";
import { LinkButton } from "components/bdd/Button";
import CreateUpdateReportModal from "components/StatManager/v2/CreateUpdateReportModal";
import { theme } from "constants";
import { useState } from "react";
import styled from "styled-components";
// import useEditStatsModal from "../hooks/useEditStatsModal";
import EditStatsModal from "./EditStatsModal";

const ButtonsContainer = styled.div({
  display: 'flex'
})

const TextButton = styled(LinkButton)({
  whiteSpace: 'nowrap',
  marginRight: '20px',
  fontSize: '0.7em'
})

const SaveButton = styled(TextButton)({
  color: theme.colors.states.danger,
  fontStyle: 'italic',
  ':hover': {
    color: theme.colors.states.danger
  }
})

const Title = styled.div({
  ...theme.typography.subtitle1,
  whiteSpace: 'nowrap',
  marginRight: '5px'
})

export default function EditStatsButton({
  target,
  mode,
  statDefs,
  statReport,
  statReportDetail,
  isDefaultReport,
  setLocalDefaultReport,
  handleReportCreated,
  refetchStats,
  ...props
}) {
  const [showModal, setShowModal] = useState(false)
  const [showCreate, setShowCreate] = useState(false)

  const handleCreation = (newStatReport) => {
    handleReportCreated(newStatReport)
    setShowCreate(false)
  }

  return <ButtonsContainer>
    <BDDErrorBoundary errorContent={<>Error</>}>
      {mode === 'statDefs' ? <SaveButton onClick={() => setShowCreate(true)}>
        <Title>Temporary Stat Report</Title>
        (save)
      </SaveButton> 
      : <>
        <Title>{statReportDetail?.name || statReport}</Title>
        {!isDefaultReport && <TextButton onClick={() => setLocalDefaultReport()}>Set Default Report</TextButton>}
      </>}
      <TextButton onClick={() => setShowModal(true)}>
        Add/Remove Stats
      </TextButton>
      <EditStatsModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        refetchStats={refetchStats}
        mode={mode}
        statDefs={statDefs}
        statReportDetail={statReportDetail}
        statReport={statReport}
        {...props}
      />
      {mode === 'statDefs' && <CreateUpdateReportModal
        noHeader
        show={showCreate}
        handleClose={() => setShowCreate(false)}
        isUpdate={false}
        defaultStats={statDefs}
        forceTarget={target}
        onCompleted={handleCreation}
      />}
    </BDDErrorBoundary>
  </ButtonsContainer>
}