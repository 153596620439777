import React, { useContext, useRef } from 'react';
import {
  Archive,
  Dot,
  ExclamationCircle,
  Eye,
  EyeSlash,
  PencilSquare,
  Pin,
  PinAngle,
  PinFill,
  ThreeDotsVertical,
} from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

import { theme } from 'constants';
import { IconButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { FileAttachmentGroup, TextEditor } from '.';
import Icon from 'components/bdd/Icon';
import useModal from 'components/bdd/Modal/useModal';
import { useUser } from 'helpers/user';
import { Divider } from '../Divider';
import { getVisibilityLabel } from './helpers';
import { useArchiveModal } from '../Modal';
import { WindowContext } from '../context';
import { LinkPopover } from '../Popover';

export const CommentReadonlyForm = ({
  userId,
  header,
  formComponents,
  isPinned,
  comment,
  requiresPermission,
  userAccess,
  files,
  onEdit,
  onArchive,
  onPin,
  hasUnsavedChanges,
  variant = 'vertical',
  invalid,
}) => {
  const { width } = useContext(WindowContext);
  const { user, userHasPermission } = useUser();

  const visibility = getVisibilityLabel(requiresPermission?.value, userAccess);
  const userComment = user && user.id == userId;

  const { renderArchiveModal } = useArchiveModal({
    entityName: 'note',
    onArchive,
  });

  const headerContainer = (
    <Container>
      <Row columnGap={1} flexWrap>
        <Typography variant="body2">{header}</Typography>
        {hasUnsavedChanges && (
          <Typography variant="stat" color={theme.colors.states.danger}>
            Unsaved changes
          </Typography>
        )}
      </Row>
    </Container>
  );

  const editNoteButton = (userComment || userHasPermission('adminFullAccess')) && (
    <IconButton icon={<PencilSquare />} onClick={onEdit} tooltip="Edit this note" />
  );

  const archiveNoteButton = (userComment || userHasPermission('adminFullAccess')) && (
    <IconButton
      icon={<Archive />}
      onClick={renderArchiveModal}
      tooltip="Archive this note"
    />
  );

  const pinNoteButton = (userComment || userHasPermission('adminFullAccess')) && (
    <IconButton
      icon={isPinned ? <PinFill /> : <PinAngle />}
      onClick={onPin}
      tooltip={!isPinned ? 'Pin this note' : 'Unpin this note'}
    />
  );

  const visibilityIndicator = visibility && (
    <Typography variant="stat">
      <Row columnGap={1}>
        <Icon icon={visibility == 'Private' ? <EyeSlash /> : <Eye />} />
        {visibility}
      </Row>
    </Typography>
  );

  const noteInfoContainer = invalid ? (
    <IconButton
      icon={<ExclamationCircle />}
      color={theme.colors.states.danger}
      tooltip={'This note has been edited or archived and no longer exists'}
    />
  ) : width <= theme.breakpoints.sm || variant == 'horizontal' ? (
    <LinkPopover
      renderContent={() => (
        <Container>
          <Row columnGap={2}>
            {visibilityIndicator}
            {editNoteButton}
            {archiveNoteButton}
            {pinNoteButton}
          </Row>
        </Container>
      )}
      renderLink={(setShow, target, show) => (
        <Container ref={target}>
          <IconButton
            size={14}
            icon={<ThreeDotsVertical />}
            onClick={() => setShow(!show)}
          />
        </Container>
      )}
    />
  ) : (
    <Row columnGap={3}>
      {visibilityIndicator}
      <Row columnGap={2}>
        {editNoteButton}
        {archiveNoteButton}
        {pinNoteButton}
      </Row>
    </Row>
  );

  const textContainer = (
    <Column>
      <TextEditor readOnly text={comment} />
      {!!formComponents && <Divider />}
      {formComponents}
      {files?.length > 0 && <FileAttachmentGroup readOnly files={files} />}
    </Column>
  );

  return variant == 'horizontal' ? (
    <Row alignItems="stretch">
      <Container
        paddingLeft={3}
        padding={2}
        backgroundColor={theme.colors.light.secondary}
        width={225}
      >
        <Row justifyContent="space-between" columnGap={2}>
          {headerContainer}
          {noteInfoContainer}
        </Row>
      </Container>
      {textContainer}
    </Row>
  ) : variant == 'vertical-compact' ? (
    <>
      <Container
        paddingLeft={3}
        padding={2}
        backgroundColor={theme.colors.light.secondary}
        style={{
          zIndex: 2,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        {noteInfoContainer}
      </Container>
      {textContainer}
    </>
  ) : (
    <>
      <Container
        paddingLeft={3}
        padding={2}
        backgroundColor={theme.colors.light.secondary}
        width="100%"
      >
        <Row justifyContent="space-between" rowGap={1}>
          {headerContainer}
          {noteInfoContainer}
        </Row>
      </Container>
      {textContainer}
    </>
  );
};
