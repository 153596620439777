import { Border, Container, Row } from 'components/bdd/Layout';

import React, { useContext, useRef, useState } from 'react';
import { theme } from 'constants/theme';
import { useHover } from 'components/bdd/hooks/useHover';
import styled from 'styled-components';
import { formatSeconds } from 'helpers/helpers';
import { Typography } from 'components/bdd/Typography';
import { ArrowBarLeft, ArrowBarRight } from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import { VideoClipEditControls } from './VideoClipEditControls';
import { VideoPlayerContext } from '..';
import { usePlaylist } from 'components/Video/Playlists';
import { VideoContext } from '../..';

const SliderContainer = styled(Container)({
  transition: 'all 0.2s ease-in-out',
});

const SliderProgressContainer = styled(Container)({
  position: 'absolute',
  transition: 'height 0.2s ease-in-out',
});

const SliderThumb = styled(Container)({
  position: 'absolute',
  transition: 'height 0.2s ease-in-out',
});

const HoverComponent = styled.div({
  position: 'absolute',
  top: '-30px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  borderRadius: '5px',
  transition: 'opacity 0.3s ease',
});

const DurationIndicator = styled(HoverComponent)({
  top: '-30px',
  left: '50%',
  opacity: 0,
  [`${SliderContainer}:hover &`]: {
    opacity: 1,
  },
});

const getHoverProgress = (e, ref) => {
  const parentRect = ref?.current?.getBoundingClientRect();
  const parentWidth = ref?.current?.clientWidth;

  if (!parentWidth || !parentRect) return 0;

  const clickX = e.clientX - parentRect.left;
  return (clickX / parentWidth) * 100;
};

const getProgressPosition = (parentWidth, progress) => {
  if (!parentWidth) return 0;

  return (progress / 100) * parentWidth;
};

const PadTimeButton = ({ padKey, pad, setPad }) => {
  return (
    <Row>
      <IconButton
        padding={0}
        color={theme.colors.dark.text.primary}
        hoverColor={theme.colors.teams.bos.primary}
        tooltip={
          <Typography variant="body2">
            {padKey == 'lead' ? 'Add lead time' : 'Remove trail time'}
          </Typography>
        }
        tooltipPlacement="top"
        icon={<ArrowBarLeft />}
        onClick={() => setPad(pad + (padKey == 'lead' ? 5 : -5))}
      />
      <Typography variant="body2" noUserSelect color={theme.colors.dark.text.primary}>
        {pad}
      </Typography>
      <IconButton
        padding={0}
        color={theme.colors.dark.text.primary}
        hoverColor={theme.colors.teams.bos.primary}
        tooltip={
          <Typography variant="body2">
            {padKey == 'lead' ? 'Remove lead time' : 'Add trail time'}
          </Typography>
        }
        tooltipPlacement="top"
        icon={<ArrowBarRight />}
        onClick={() => setPad(pad + (padKey == 'trail' ? 5 : -5))}
      />
    </Row>
  );
};

export const VideoClipSlider = React.forwardRef((props, ref) => {
  const { showEditControls, frontPad, backPad, setFrontPad, setBackPad, targetTime } =
    props;

  const [hoverRef, isHovered] = useHover();
  const parentRef = useRef();
  const { playlist } = useContext(VideoContext) || {};

  return (
    <Container ref={hoverRef} height={35} width="100%">
      <Row columnGap={2}>
        {!!targetTime && (
          <PadTimeButton padKey={'lead'} pad={frontPad} setPad={setFrontPad} />
        )}
        <Container flexGrow ref={parentRef}>
          {showEditControls ? (
            <EditVideoClipSlider ref={parentRef} isHovered={isHovered} {...props} />
          ) : (
            <TrimmedVideoClipSlider ref={parentRef} isHovered={isHovered} {...props} />
          )}
        </Container>
        {!!targetTime && (
          <PadTimeButton padKey={'trail'} pad={backPad} setPad={setBackPad} />
        )}
        {(!playlist || !!playlist?.canEdit) && (
          <HoverComponent style={{ top: -70 }}>
            <VideoClipEditControls />
          </HoverComponent>
        )}
      </Row>
    </Container>
  );
});

const EditVideoClipSlider = React.forwardRef(
  (
    {
      duration,
      progress,
      showEditControls,
      trim,
      handleVideoTrim,
      handleVideoProgress,
      isHovered,
    },
    ref
  ) => {
    const [mouseProgress, setMouseProgress] = useState(progress);
    const parentWidth = ref?.current?.clientWidth;

    const updateVideoProgress = (e) => {
      let newProgress = getHoverProgress(e, ref);

      if (
        newProgress > Math.min(trim.trail, 100) ||
        newProgress < Math.max(trim.lead, 0)
      ) {
        return;
      }

      handleVideoProgress(newProgress);
    };

    const updateLeadTrim = (e) => updateVideoTrim(e, true);
    const updateTrailTrim = (e) => updateVideoTrim(e, false);
    const updateVideoTrim = (e, isLeadTrim) => {
      const updatedProgress = getHoverProgress(e, ref);

      if (updatedProgress > 100 || updatedProgress < 0) return;

      const newTrim = {
        lead: isLeadTrim ? updatedProgress : trim.lead,
        trail: !isLeadTrim ? updatedProgress : trim.trail,
      };

      if (newTrim.trail < newTrim.lead) return;

      handleVideoTrim(newTrim);
      handleVideoProgress(updatedProgress);
    };

    const progressThumb = (
      <SliderThumb
        style={{ left: getProgressPosition(parentWidth, progress) }}
        width={8}
        height={isHovered ? 20 : 10}
        backgroundColor={theme.colors.light.background}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document.addEventListener('mousemove', updateVideoProgress);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateVideoProgress);
          });
        }}
      />
    );

    const leadTrimThumb = (
      <SliderThumb
        style={{ left: getProgressPosition(parentWidth, trim.lead), top: -4 }}
        width={8}
        height={isHovered ? 30 : 20}
        backgroundColor={
          trim?.lead > 0 ? theme.colors.states.danger : theme.colors.states.neutral
        }
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document.addEventListener('mousemove', updateLeadTrim);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateLeadTrim);
          });
        }}
      />
    );

    const trailTrimThumb = (
      <SliderThumb
        style={{ left: getProgressPosition(parentWidth, trim.trail), top: -4 }}
        width={8}
        height={isHovered ? 30 : 20}
        backgroundColor={
          trim?.trail < 100 ? theme.colors.states.danger : theme.colors.states.neutral
        }
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document.addEventListener('mousemove', updateTrailTrim);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateTrailTrim);
          });
        }}
      />
    );

    return (
      <SliderContainer
        variant="button"
        height={isHovered ? 20 : 10}
        backgroundColor={theme.colors.light.border}
        onClick={updateVideoProgress}
        onMouseMove={(e) => setMouseProgress(getHoverProgress(e, ref))}
      >
        <SliderProgressContainer
          width={getProgressPosition(parentWidth, progress)}
          height={isHovered ? 20 : 10}
          backgroundColor={theme.colors.teams.bos.primary}
        />
        {progressThumb}
        {showEditControls && (
          <>
            <SliderProgressContainer
              width={getProgressPosition(parentWidth, trim.lead)}
              height={isHovered ? 20 : 10}
              backgroundColor={theme.colors.light.text.secondary}
            />
            <SliderProgressContainer
              width={getProgressPosition(parentWidth, 100 - trim.trail)}
              style={{ left: getProgressPosition(parentWidth, trim.trail) }}
              height={isHovered ? 20 : 10}
              backgroundColor={theme.colors.light.text.secondary}
            />
            {leadTrimThumb}
            {trailTrimThumb}
          </>
        )}
        <DurationIndicator
          style={{ left: getProgressPosition(parentWidth, mouseProgress) }}
        >
          <Typography variant="body2">
            {formatSeconds((duration * mouseProgress) / 100)}
          </Typography>
        </DurationIndicator>
      </SliderContainer>
    );
  }
);

const TrimmedVideoClipSlider = React.forwardRef(
  ({ duration, progress, trim, isHovered, handleVideoProgress }, ref) => {
    const parentWidth = ref?.current?.clientWidth;

    const trimmedDuration = duration * ((100 - trim.lead - (100 - trim.trail)) / 100);

    const trimmedProgress = progress
      ? ((progress - trim.lead) / (100 - trim.lead - (100 - trim.trail))) * 100
      : 0;

    const [mouseProgress, setMouseProgress] = useState(trimmedProgress);

    const updateVideoProgress = (e) => {
      let trimmedProgress = getHoverProgress(e, ref);
      const updatedProgress =
        (trimmedProgress / 100) * (100 - trim.lead - (100 - trim.trail)) + trim.lead;

      handleVideoProgress(updatedProgress);
    };

    const progressThumb = (
      <SliderThumb
        style={{ left: getProgressPosition(parentWidth, trimmedProgress) }}
        width={8}
        height={isHovered ? 20 : 10}
        backgroundColor={theme.colors.light.background}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document.addEventListener('mousemove', updateVideoProgress);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateVideoProgress);
          });
        }}
      />
    );

    return (
      <SliderContainer
        variant="button"
        // width={parentWidth}
        height={isHovered ? 20 : 10}
        backgroundColor={theme.colors.light.border}
        onClick={updateVideoProgress}
        onMouseMove={(e) => setMouseProgress(getHoverProgress(e, ref))}
      >
        <SliderProgressContainer
          width={getProgressPosition(parentWidth, trimmedProgress)}
          height={isHovered ? 20 : 10}
          backgroundColor={theme.colors.teams.bos.primary}
        />
        {progressThumb}
        <DurationIndicator
          style={{ left: getProgressPosition(parentWidth, mouseProgress) }}
        >
          <Typography variant="body2">
            {formatSeconds((trimmedDuration * mouseProgress) / 100)}
          </Typography>
        </DurationIndicator>
      </SliderContainer>
    );
  }
);
