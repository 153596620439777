import { Container } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import { playerRoleOptions } from '../constants';

export const usePlayerRoleSelect = () => {
  const { select, selected } = useSelect({
    options: playerRoleOptions,
    selectProps: {
      placeholder: 'Filter to player roles...',
      isMulti: true,
    },
  });

  const lineupSpotFilters =
    selected.length > 0
      ? {
          expression: 'OR',
          filters: selected.map((s) => s.filter),
        }
      : null;

  return {
    playerRoleSelect: <Container width={250}>{select}</Container>,
    lineupSpotFilters,
  };
};
