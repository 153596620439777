import React, { useRef } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Dropdown } from 'react-bootstrap';

import { Column, Container, Row } from 'components/bdd/Layout';
import {
  EMPTY_TEXT,
  FileAttachment,
  FileUploadButton,
  TextEditorField,
  VisibilityForm,
} from 'components/bdd/TextEditor';
import { Divider } from 'components/bdd/Divider';
import { Typography } from 'components/bdd/Typography';
import produce from 'immer';
import { useEffect } from 'react';
import { useFormPersist } from '../Form';
import { getVisibilityLabel } from './helpers';
import { LinkPopover } from '../Popover';
import { getMentionEntities } from './components/Mentions';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { theme } from 'constants';
import { IconButton } from '../Button';

export const CommentForm = ({
  existingNote,
  requiresPermission,
  userAccess,
  placeholder,
  onSubmit,
  onCancel,
  extraForm,
  actionButtons,
  files,
  includeFileUpload,
  persistKey,
  fileUploadFolder = 'file_uploads',
  disableAutoFocus,
}) => {
  const ref = useRef();
  const initialValues = {
    comment: existingNote?.comment || EMPTY_TEXT,
    requiresPermission: requiresPermission?.value,
    userAccess: userAccess,
    files: files || [],
    ...extraForm?.initialValues,
  };

  const schema = Yup.object({
    comment: Yup.string().required(),
    requiresPermission: Yup.string().nullable(),
    files: Yup.array(),
    ...extraForm?.schema,
  });

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
    resetForm({
      values: initialValues,
    });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      // onReset={onCancel}
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        values,
        handleReset,
        dirty,
        setFieldValue,
        initialValues,
        setValues,
        isSubmitting,
        resetForm,
      }) => {
        useEffect(() => {
          // eslint-disable-line
          // Formik freaks out if we have data in the field and then change the form type
          handleReset();
        }, [initialValues]);

        const { resetFormPersist } = useFormPersist({
          key: persistKey,
          values,
          setValues,
          isSubmitting,
        });

        const fileUploadButton = (
          <FileUploadButton
            key={`file-upload-button`}
            folder={fileUploadFolder}
            onSubmit={(newValues) => {
              setFieldValue('files', [...values.files, newValues]);
            }}
          />
        );

        const buttonGroup = (
          <Row columnGap={2} rowGap={2} wrap="wrap">
            {onCancel && (
              <Button
                key={'button-cancel-comment'}
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  resetForm();
                  resetFormPersist();
                  onCancel();
                }}
              >
                Cancel
              </Button>
            )}
            {actionButtons?.map((ab) => ab.renderComponent(values.comment))}
            <Button
              size="sm"
              variant="success"
              type="submit"
              onClick={() => {
                onSubmit(values);
                resetForm({
                  values: initialValues,
                });
                resetFormPersist();
              }}
              disabled={
                !dirty || JSON.stringify(values.comment) == JSON.stringify(EMPTY_TEXT)
              }
            >
              <Typography variant="body2">Comment</Typography>
            </Button>
          </Row>
        );

        const mentions = getMentionEntities(values.comment);
        const displayMentionVisibilityWarning =
          mentions?.filter((m) => ['BDD_USER', 'BDD_USER_GROUP'].includes(m.entityType))
            ?.length > 0 &&
          (values.userAccess?.length > 0 || !!values.requiresPermission);

        return (
          <Form ref={ref}>
            <Column>
              <TextEditorField
                name="comment"
                placeholder={placeholder}
                actionButtons={includeFileUpload ? fileUploadButton : null}
                disableAutoFocus={disableAutoFocus}
              />
              <Container padding={3} paddingTop={0}>
                <Row columnGap={2}>
                  {values.files.map((file) => (
                    <FileAttachment
                      key={file.name}
                      file={file}
                      onFileRemove={(file) => {
                        setFieldValue(
                          'files',
                          produce(values.files, (draft) => {
                            const fileIndexToRemove = draft.findIndex(
                              (draftFile) => draftFile.name === file.name
                            );

                            draft.splice(fileIndexToRemove, 1);
                          })
                        );
                      }}
                    />
                  ))}
                </Row>
              </Container>
              <Divider />
              <Container padding={3}>
                <Row
                  columnGap={2}
                  rowGap={2}
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap
                >
                  <Row columnGap={4} rowGap={1} flexWrap>
                    {requiresPermission && (
                      <Column>
                        <Typography variant="body2">Visibility:</Typography>
                        <LinkPopover
                          ref={ref}
                          title={
                            <Typography variant="subtitle1">Visibility Access</Typography>
                          }
                          closeButton
                          placement="top"
                          renderContent={(forceClose) => (
                            <VisibilityForm
                              userAccess={values.userAccess}
                              requiresPermission={values.requiresPermission}
                              onChange={(userAccess) => {
                                setValues({
                                  ...values,
                                  requiresPermission: userAccess.requiresPermission,
                                  userAccess: userAccess.users,
                                });
                              }}
                            />
                          )}
                        >
                          <Row columnGap={1}>
                            <Typography variant="body1" noWrap textDecoration="underline">
                              {getVisibilityLabel(
                                values.requiresPermission,
                                values.userAccess
                              )}
                            </Typography>
                            {displayMentionVisibilityWarning && (
                              <IconButton
                                icon={<ExclamationCircle />}
                                color={theme.colors.states.danger}
                                tooltip={
                                  'Mentioned users or groups may not have permission to view this note'
                                }
                              />
                            )}
                          </Row>
                        </LinkPopover>
                      </Column>
                    )}
                    {extraForm?.components &&
                      extraForm.components.map((c) => (
                        <Column key={`ec-${c.title}`}>
                          <Typography variant="body2">{c.title}</Typography>
                          {c.field}
                        </Column>
                      ))}
                  </Row>
                  {buttonGroup}
                </Row>
              </Container>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
};
