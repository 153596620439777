import produce from 'immer';
import { createContext, useContext, useEffect, useState } from 'react';
import { VideoPlayerContext, VideoPlayerProvider } from '.';

export const VideoClipContext = createContext();

export const VideoClipProvider = ({
  clips: clipsArg,
  firstClipIdx,
  playlist,
  loading,
  videoPlayerProps,
  children,
}) => {
  const [clips, setClips] = useState([]);

  useEffect(() => {
    setClips(clipsArg.map((c) => ({ ...c, toggled: false })));
  }, [loading]);

  // const [clipIdx, setClipIdx] = useState(0);
  const [clipIdx, setClipIdx] = useState(firstClipIdx ? firstClipIdx : 0);
  const currentClip = clips[clipIdx];

  useEffect(() => {
    if (firstClipIdx >= 0) {
      setClipIdx(firstClipIdx);
    }
  }, [firstClipIdx]);

  const [clipTrimControlsToggled, toggleClipTrimControls] = useState(false);

  const setClip = (index) => {
    setClipIdx(index);
  };

  const setNextClip = () => {
    setClipIdx((clipIdx) => (clipIdx + 1 < clips.length ? clipIdx + 1 : 0));
  };

  const setPreviousClip = () => {
    setClipIdx((clipIdx) => (clipIdx - 1 >= 0 ? clipIdx - 1 : clips.length - 1));
  };

  const reorderClips = (ids) => {
    setClips((clips) => ids.map((id) => clips.find((c) => c.id == id)));
  };

  const removeClips = (ids) => {
    setClips((clips) => clips.filter((c) => !ids.includes(c.id)));
  };

  const updateClip = (clip) => {
    setClips(clips.map((c) => (c.id == clip.id ? { ...c, ...clip } : c)));
  };

  const updateClips = (clips) => {
    setClips(clips);
  };

  const toggleClipForPlaylist = (clip) => {
    setClips(
      produce(clips, (draft) => {
        draft.forEach((draftClip) => {
          if (draftClip.id == clip.id) {
            draftClip.toggled = !draftClip.toggled;
          }
        });
      })
    );
  };

  const toggleAllClipsForPlaylist = (toggled) => {
    setClips(
      produce(clips, (draft) => {
        draft.forEach((draftClip) => {
          draftClip.toggled = toggled;
        });
      })
    );
  };

  const toggledClips = clips.filter((c) => c.toggled);

  return (
    <VideoClipContext.Provider
      value={{
        clips,
        currentClip,
        loading,
        setClip,
        setNextClip,
        setPreviousClip,
        reorderClips,
        removeClips,
        toggleClipForPlaylist,
        toggleAllClipsForPlaylist,
        playlist,
        toggledClips,
        updateClip,
        updateClips,
        clipTrimControlsToggled,
        toggleClipTrimControls,
      }}
    >
      <VideoPlayerProvider currentClip={currentClip} {...videoPlayerProps}>
        <VideoClipListener />
        {children}
      </VideoPlayerProvider>
    </VideoClipContext.Provider>
  );
};

export const VideoClipListener = ({}) => {
  const { playerState, togglePlay } = useContext(VideoPlayerContext);
  const { setNextClip, clipTrimControlsToggled } = useContext(VideoClipContext);

  useEffect(() => {
    if (playerState.isFinished) {
      if (clipTrimControlsToggled) {
        togglePlay();
      } else {
        setNextClip();
      }
    }
  }, [playerState.isFinished]);
};
