import { theme } from 'constants';
import { Divider } from '../Divider';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton } from '../Button';
import { ArrowLeft } from 'react-bootstrap-icons';
import { PageTabs } from './PageTabs';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function PageHeader({
  title,
  helmetTitle,
  caption,
  subtitle,
  showHistoryBack,
  content,
  actionContent,
  secondaryActionContent, // rendered across from routes
  routes,
  divider = true,
  persistSearchParams,
}) {
  const history = useHistory();
  const location = useLocation();
  const [originPath] = useState(location.state?.origin);

  return (
    <Container>
      {(typeof helmetTitle === 'string' || typeof title === 'string') && (
        <Helmet>
          <title>{helmetTitle || title}</title>
        </Helmet>
      )}
      <Container padding={3} backgroundColor={theme.colors.light.background}>
        <Row justifyContent="space-between" flexWrap rowGap={1} columnGap={1}>
          <Row columnGap={1} alignItems="baseline" flexWrap>
            {(showHistoryBack && history.length > 1) && (
              <IconButton
                icon={<ArrowLeft />}
                size={25}
                onClick={() => {
                  if (!!originPath) {
                    history.push(originPath);
                  } else {
                    history.goBack();
                  }
                }}
              />
            )}
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle2">{caption}</Typography>
          </Row>
          <Container>{actionContent}</Container>
        </Row>
        {subtitle && (
          <Container>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </Container>
        )}
        {content && <Container>{content}</Container>}
        <Row justifyContent="space-between">
          {routes && <PageTabs routes={routes} persistSearchParams={persistSearchParams} />}
          {secondaryActionContent && <Container>{secondaryActionContent}</Container>}
        </Row>
      </Container>
      {divider && <Divider />}
    </Container>
  );
}
