import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_ALL_REPORTS_FOR_PLAYER } from '../../../apollo/queries/scouting.queries';
import { Button, Col, Row } from 'react-bootstrap';
import BDDSelect from '../../bdd/bddselect';
import { BDDDateRangePicker } from '../../bdd/bdddatepicker';
import { BDDLoader } from '../../bdd/bddloader';
import BDDApiError from '../../bdd/bddapierror';
import { formatDate } from '../../../helpers/helpers';
import RinknetReport from './rinknetreport';
import BDDReport from './bddreport';
import { HoverInteractive } from '../../bdd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { useScoutingGradeFilter } from './useScoutingGradeFilter';
import { theme } from 'constants';
import { getMetastatsForReport } from './helpers';
import { Row as LayoutRow } from 'components/bdd/Layout';

const InputFilterContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  gap: theme.spacing[10],
  margin: theme.spacing[3],
  flexWrap: 'wrap',
});

const InputFilterControlsContainer = styled.div({
  display: 'flex',
  flexBasis: 0,
  flexGrow: 1,
  alignItems: 'stretch',
  gap: theme.spacing[3],
});

const Styles = styled.div`
  padding: 0 15px;
  .report_viewer {
    padding: 10px;
  }
  .filters {
    margin-bottom: 10px;
  }

  .option-column {
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '500px')};
    overflow: scroll;
  }
  .option-column.minimized {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .report-option {
    font-size: 0.8em;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .report-option.selected {
    color: #ddd;
    background: #333;
  }

  .report-viewer-container {
    display: table;
  }
  .report-viewer-report {
    display: table-cell;
    overflow: scroll;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '500px')};
  }

  .report-navigation {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    width: 30px;
    min-width: 30px;
    color: #333;
    border: 1px solid black;
    :hover {
      // color: #333;
      // border-color: #333;
    }
    transition: all 0.5s;
  }
  .report-navigation.left {
    margin-right: 2px;
    border-radius: 5px 0 0 5px;
    :hover {
      box-shadow: inset -2px 0 5px #333;
    }
  }
  .report-navigation.right {
    margin-left: 2px;
    border-radius: 0 5px 5px 0;
    :hover {
      box-shadow: inset 2px 0 5px #333;
    }
  }
`;

function PlayerReportViewer({
  rinknetId,
  scoutId: scoutIdArg, // rinknet userid or "mine".  if supplied, filter for this scouts reports
  maxHeight = '500px',
  showFilters = true,
  minimizeOptions = false,
  showGradeFilters = false,
  rinknetPlayer, // Must be provided to display grade filters
}) {
  const [selected, setSelected] = useState();
  const [scoutId, setScoutId] = useState(null);
  const [dates, setDates] = useState([null, null]);
  const [reportType, setReportType] = useState(null);

  const { data, loading, error } = useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
    variables: { rinknetId, scoutIdArg },
  });

  const reports = !!data ? data.allPlayerReports.reports : [];
  const metastats = !!data ? data.allPlayerReports.metastatSplits : [];

  const scoutOptions = useMemo(() => {
    var ret = [];
    if (!data) return ret;
    const scoutsSeen = new Set();
    reports.forEach((r) => {
      if (!scoutsSeen.has(r.author)) {
        ret.push({ label: r.author, value: r.author });
        scoutsSeen.add(r.author);
      }
    });
    ret = ret.sort((a, b) => {
      return a.value.toLowerCase() <= b.value.toLowerCase() ? -1 : 1;
    });
    return ret;
  }, [data]);

  const { inputFilters, sortAndFilterReports, filtersChanged, resetFilters } =
    useScoutingGradeFilter({ posFDG: rinknetPlayer?.posFDG });

  const [showInputFilters, setShowInputFilters] = useState(false);

  useEffect(() => {
    if (reports.length > 0) setSelected(reports[0].id);
  }, [data]);

  if (loading) return <BDDLoader />;
  if (error) return <BDDApiError error={error} />;

  const reportToOption = (r) => {
    // Maps a report to the "option" text on left bar
    if (r.__typename == 'RinknetReports') {
      return (
        <>
          {r.author} {formatDate(r.date)}
        </>
      );
    } else if (r.__typename == 'BDDPlayerScoutingReport') {
      return (
        <>
          {r.author} {formatDate(r.creationDate)}
        </>
      );
    }
  };

  const filterReports = (reports) => {
    if (!!reports && reports.length > 0) {
      let filteredReports = reports.filter((r) => {
        const rdate = new Date(
          r.__typename == 'RinknetReports' ? r.date : r.creationDate
        );
        if (!!scoutId && scoutId.length > 0 && !scoutId.includes(r.author)) return false;
        if (!!dates && dates.length > 0 && !!dates[0] && !!dates[1]) {
          if (rdate < new Date(dates[0]) || rdate > new Date(dates[1])) return false;
        }
        if (!!reportType && r.__typename != reportType) return false;
        return true;
      });

      if (showGradeFilters && rinknetPlayer) {
        filteredReports = sortAndFilterReports(filteredReports);
      }

      return filteredReports;
    }
    return [];
  };
  const filteredReports = filterReports(reports);
  const currReportIdx = filteredReports.findIndex((r) => r.id == selected);

  const handleSelectNextReport = () => {
    if (currReportIdx < filteredReports.length - 1)
      setSelected(filteredReports[currReportIdx + 1].id);
  };
  const handleSelectPrevReport = () => {
    if (currReportIdx > 0) setSelected(filteredReports[currReportIdx - 1].id);
  };

  const reportTypeOptions = [
    { label: 'Rinknet Reports', value: 'RinknetReports' },
    { label: 'BDD Reports', value: 'BDDPlayerScoutingReport' },
  ];

  const selectedReport = selected ? reports.filter((r) => r.id === selected)[0] : null;

  return (
    <Styles maxHeight={maxHeight}>
      {showFilters && (
        <Row className="filters">
          <Col>
            <BDDSelect
              isClearable
              name="reportType"
              value={reportType}
              onChange={(n, v) => setReportType(v)}
              options={reportTypeOptions}
              placeholder="BDD/Rinknet..."
            />
          </Col>
          <Col>
            <BDDSelect
              isMulti
              isSearchable
              name="scoutId"
              value={scoutId}
              onChange={(n, v) => setScoutId(v)}
              options={scoutOptions}
              placeholder="Scout..."
            />
          </Col>
          <Col>
            <BDDDateRangePicker
              isClearable
              showMonthDropdown
              showYearDropdown
              selected={dates[0]}
              startDate={dates[0]}
              endDate={dates[1]}
              onChange={([start, end]) => {
                setDates([start, end]);
              }}
            />
          </Col>
          {showGradeFilters && (
            <InputFilterControlsContainer>
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => setShowInputFilters(!showInputFilters)}
              >
                {showInputFilters ? 'Hide Filters' : 'More Filters'}
              </Button>
              {filtersChanged && (
                <div style={{ display: 'flex' }}>
                  <Button variant="outline-danger" size="sm" onClick={resetFilters}>
                    Reset Filters
                  </Button>
                </div>
              )}
            </InputFilterControlsContainer>
          )}
        </Row>
      )}
      {showGradeFilters && showInputFilters && (
        <>
          <InputFilterContainer>{inputFilters.slice(0, 3)}</InputFilterContainer>
          <InputFilterContainer>{inputFilters.slice(3)}</InputFilterContainer>
        </>
      )}
      <Row className="report-viewer">
        <Col sm={minimizeOptions ? 12 : 4}>
          <div className={`option-column ${minimizeOptions ? 'minimized' : ''}`}>
            {minimizeOptions ? (
              <div
                style={{
                  display: 'table',
                  borderBottom: '1px solid #333',
                  width: '100%',
                }}
              >
                <div
                  style={{ display: 'table-cell' }}
                  className={`report-option selected`}
                >
                  {!!selectedReport ? reportToOption(selectedReport) : 'Nothing selected'}
                </div>{' '}
                <div className={`report-option`} style={{ display: 'table-cell' }}>
                  <HoverInteractive
                    renderContent={(forceClose) => (
                      <div
                        style={{
                          maxHeight: '100px',
                          overflow: 'scroll',
                          fontSize: '0.8em',
                          padding: '10px',
                        }}
                      >
                        {filteredReports.map((r) => (
                          <div
                            key={r.id}
                            style={{ cursor: 'pointer' }}
                            onClick={(ev) => {
                              setSelected(r.id);
                              forceClose();
                            }}
                          >
                            {reportToOption(r)}
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    {filteredReports.length > 0 ? (
                      <>
                        +{filteredReports.length} more reports{' '}
                        <FontAwesomeIcon icon={faCaretDown} />
                      </>
                    ) : (
                      '0 reports'
                    )}
                  </HoverInteractive>
                </div>
              </div>
            ) : (
              <>
                {filteredReports.map((r) => (
                  <div
                    key={r.id}
                    className={`report-option ${r.id === selected ? 'selected' : ''}`}
                    onClick={(ev) => setSelected(r.id)}
                  >
                    {reportToOption(r)}
                  </div>
                ))}
                {filteredReports.length == 0 && (
                  <small>
                    <b>No reports</b>
                  </small>
                )}
              </>
            )}
          </div>
        </Col>
        {minimizeOptions && (
          <LayoutRow justifyContent="end">
            <div
              className="report-navigation left"
              onClick={() => handleSelectPrevReport()}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </div>
            <div
              className="report-navigation right"
              onClick={() => handleSelectNextReport()}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          </LayoutRow>
        )}
        <Col sm>
          <div className="report-viewer-container">
            {!minimizeOptions && (
              <div
                className="report-navigation left"
                onClick={() => handleSelectPrevReport()}
              >
                <FontAwesomeIcon icon={faCaretLeft} />
              </div>
            )}
            <div className="report-viewer-report">
              {!!selectedReport ? (
                selectedReport.__typename === 'RinknetReports' ? (
                  <RinknetReport
                    statsData={data.allPlayerReports.statsData}
                    report={selectedReport}
                    metastats={getMetastatsForReport(selectedReport, metastats)}
                  />
                ) : (
                  <BDDReport
                    report={selectedReport}
                    expandable={false}
                    noBorder={true}
                    metastats={getMetastatsForReport(selectedReport, metastats)}
                  />
                )
              ) : (
                <em>Select a report</em>
              )}
            </div>
            {!minimizeOptions && (
              <div
                className="report-navigation right"
                onClick={() => handleSelectNextReport()}
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Styles>
  );
}

export default React.memo(PlayerReportViewer);
