import { gql } from '@apollo/client';

//Stats

export const CREATE_STAT = gql`
  mutation CreateStat($input: CreateStatInput!, $overwrite: Boolean) {
    createStat(input: $input, overwrite: $overwrite) {
      statDefinition {
        id
        slug
        description
        aliases {
          alias
        }
      }
    }
  }
`;

export const GET_STAT_DEFINITIONS = gql`
  {
    statDefinitions {
      id
      slug
      statType
      description
      args
      kwargs
      aliases {
        alias
      }
    }
  }
`;

// Reports
export const CREATE_STAT_REPORT = gql`
  mutation CreateStatReport($input: CreateStatReportInput!) {
    createStatReport(input: $input) {
      statReport {
        id
        slug
        name
        description
        target
        lastModified
        dateCreated
        isUserCreated
        isPublic
        stats {
          label
          statNumber
          stat {
            id
            slug
            description
            aliases {
              alias
            }
          }
        }
      }
    }
  }
`;

export const GET_STAT_REPORTS = gql`
  query GetStatReports {
    statReports(sort: "date_created") {
      id
      slug
      name
      description
      target
      isPublic
      isUserCreated
      userId
      dateCreated
      lastModified
      priorityRank
      stats {
        label
        statNumber
        stat {
          id
          slug
        }
      }
    }
  }
`;

export const UPDATE_STAT_REPORTS = gql`
  mutation UpdateStatReport(
    $input: UpdateStatReportInput!
    $statsToAdd: [StatSlugLabel!]
    $statsToRelabel: [StatSlugLabel!]
    $statsToRemove: [String!]
    $reorderStats: [String!]
  ) {
    updateStatReport(
      input: $input
      statsToAdd: $statsToAdd
      statsToRelabel: $statsToRelabel
      statsToRemove: $statsToRemove
      reorderStats: $reorderStats
    ) {
      statReport {
        id
        slug
        name
        description
        target
        userId
        isPublic
        isUserCreated
        dateCreated
        lastModified
        stats {
          label
          statNumber
          stat {
            slug
          }
        }
      }
    }
  }
`;

export const DELETE_STAT_REPORT = gql`
  mutation DeleteStatReport($slug: String!) {
    deleteStatReport(slug: $slug) {
      statReport {
        id
      }
    }
  }
`;

export const GET_EP_STATS = gql`
  query GetEPStats($q: JSONString!) {
    epStats(q: $q)
  }
`;


export const GET_EP_STAT_FILTERS = gql`
  query GetEPStatsFilters($target: String!) {
    epStatsFilters(target: $target)
  }
`;
