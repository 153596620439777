import produce from 'immer';
import React, { createContext, useContext } from 'react';
import { VideoContext } from '.';
import { useSearchParamsState } from '../hooks';
import { ModalContextProvider } from '../Modal/ModalContextProvider';

export const VideoSelectContext = createContext();

export const VideoSelect = ({ children }) => {
  const { allEvents } = useContext(VideoContext);
  const [store, setStore] = useSearchParamsState(
    {
      selectedEventIds: [],
    },
    'videoSelectContext'
  );

  const setSelectedEvents = (events) => {
    setStore(
      produce(store, (draft) => {
        draft.selectedEventIds = events.map((e) => e.id);
      })
    );
  };

  const context = {
    ...store,
    selectedEvents: allEvents?.filter((e) =>
      store.selectedEventIds.includes(e.id)
    ),
    setSelectedEvents,
  };

  return (
    <VideoSelectContext.Provider value={context}>
      <ModalContextProvider>{children}</ModalContextProvider>
    </VideoSelectContext.Provider>
  );
};
