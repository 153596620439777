import React from "react"
import { ErrorMessage } from "formik"

import BDDFormError from "../bdd/bddformerror"
import { BDDInput } from "../bdd"
import { Typography } from "components/bdd/Typography"


export default function BDDCheckboxField({ title, noTitle, field, ...rest }) {
    const { name, value, onChange } = field // dont know why error isn't in here
    const error = rest.form.errors[name]
    const touched = rest.form.touched[name]
    return <>
    {noTitle 
      ? null 
      : <Typography variant='label'>
        <label htmlFor={name}>{title}</label>
      </Typography>}
    <input
        id={name}
        type={'checkbox'}
        name={name}
        value={!!value}
        checked={!!value}
        onChange={onChange}
    />
    <BDDFormError>
        <ErrorMessage name={name}/>
    </BDDFormError>
    </>
}
