import { Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import ManagePlayerTagModal from 'components/PlayerTagging/manageplayertagmodal';
import { buildPlayerRoute } from 'helpers/routing';
import { useState } from 'react';
import { DCPlayerLarge } from './DCPlayerLarge';
import DCPlayerSmall from './DCPlayerSmall';
import { parseLineupSpot } from '../helpers';
import { useRenderDepthChartSpotWrapperForm } from '../DepthChartSpotWrapperForm';
import { useDeleteDepthChartSpotWrapper } from '../useDeleteDepthChartSpotWrapper';

export default function DepthChartPlayer({
  size = 'lg', // lg or sm
  playerSlug,
  playerSlugs,
  bddPlayer,
  rinknetPlayer,
  handleRemovePlayer,
  lineupSpot,
  editMode,
  isDragging,
  playerRenderMode,
  depthChartId,
  wrappers,
}) {
  const [showTagModal, setShowTagModal] = useState(false);
  const parsedLineupSpot = parseLineupSpot(lineupSpot);
  const wrapper = wrappers?.find(
    (w) =>
      w.lineNum == parsedLineupSpot.lineNum &&
      w.depthLeague == parsedLineupSpot.depthLeague &&
      w.position == parsedLineupSpot.position
  );

  const handleRenderModal = useRenderDepthChartSpotWrapperForm({
    depthChartId,
    wrapper,
    wrappers,
  });

  const { handleDelete } = useDeleteDepthChartSpotWrapper({
    wrapper,
    depthChartId,
  });

  const dropdownOptions = [
    { label: 'Go to Playercard', href: buildPlayerRoute(playerSlug) },
    { label: 'Manage tags', onClick: () => setShowTagModal(true) },
  ];
  if (!wrapper) {
    dropdownOptions.push({
      label: 'Add wrapper',
      onClick: () => handleRenderModal(parseLineupSpot(lineupSpot)),
    });
  } else {
    dropdownOptions.push({
      label: 'Edit wrapper',
      onClick: () => handleRenderModal(parseLineupSpot(lineupSpot)),
    });

    dropdownOptions.push({
      label: 'Delete wrapper',
      onClick: () => handleDelete(),
    });
  }

  if (editMode && !!lineupSpot && !!handleRemovePlayer) {
    dropdownOptions.push({
      label: 'Remove Player',
      onClick: () => handleRemovePlayer(lineupSpot, bddPlayer.slug),
    });
  }

  const playerProps = {
    playerSlug,
    playerSlugs,
    bddPlayer,
    rinknetPlayer,
    dropdownOptions,
    isDragging,
    playerRenderMode,
  };

  if (!bddPlayer) return <Typography variant="body2">{playerSlug}</Typography>;
  return (
    <Container>
      <Container>
        {size === 'lg' ? (
          <DCPlayerLarge {...playerProps} />
        ) : (
          <DCPlayerSmall {...playerProps} />
        )}
      </Container>
      {showTagModal && (
        <ManagePlayerTagModal
          bddPlayerSlug={playerSlug}
          playerName={`${bddPlayer.firstname} ${bddPlayer.lastname}`}
          show={showTagModal}
          handleClose={() => setShowTagModal(false)}
        />
      )}
    </Container>
  );
}
