import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { theme } from 'constants';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { buildBDDScheduleQuery } from 'apollo/queries/scoutingobjectives.queries';
import { formatDate } from 'helpers/helpers';
import Calendar from 'components/bdd/Calendar/Calendar';
import { Column, Container, Row } from '../Layout';
import { Divider } from '../Divider';
import { Typography } from '../Typography';
import { ScoutLinkButton } from './ScoutLinkButton';

function getStartDateOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

const Day = styled.div(({ backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  backgroundColor,
}));

const DayHeader = styled.div({
  ...theme.typography.caption,
  paddingLeft: theme.spacing[1],
});

const DayLabel = styled.div({
  ...theme.typography.stat,
});

const DayLabelContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing[1],
});

const Opponent = styled.div({
  ...theme.typography.stat,
  // ...theme.buttons.link(theme.colors.light.text.primary),
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'start',
});

const DayDetailsLabel = styled.div({
  ...theme.typography.stat,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const score = (game, isHome) => {
  if (!game || !game.isCompleted) {
    return null;
  }

  const isWin =
    (isHome && game.homeTeamScore > game.awayTeamScore) ||
    game.awayTeamScore > game.homeTeamScore;

  const score = `${Math.max(game.homeTeamScore, game.awayTeamScore)}-${Math.min(
    game.homeTeamScore,
    game.awayTeamScore
  )}`;

  return (
    <DayDetailsLabel>{`${isWin ? 'W' : 'L'} ${
      isWin ? score : score.split('').reverse().join('')
    }`}</DayDetailsLabel>
  );
};

const scouts = (game) => {
  return <DayDetailsLabel>{game.countScouts} scout(s)</DayDetailsLabel>;
};

export const PlayerCalendar = ({ bddPlayer, showReportModal }) => {
  const [startDate, setStartDate] = useState(getStartDateOfMonth(new Date()));
  const [selectedDate, setSelectedDate] = useState(startDate);

  const endDate = new Date(
    new Date(startDate).setMonth(startDate.getMonth() + 1)
  );

  const { data, placeholder } = usePlaceholder(
    useQuery(
      buildBDDScheduleQuery({
        includePlayers: true,
        includeScouts: true,
      }),
      {
        variables: {
          entities: [
            {
              id: bddPlayer.slug,
              type: 'BDD_PLAYER',
              startDate: formatDate(startDate, { format: '%Y-%m-%d' }),
              endDate: formatDate(endDate, { format: '%Y-%m-%d' }),
            },
          ],
        },
      }
    )
  );

  const schedule = data?.bddSchedules[0];
  const scheduleByDate = schedule?.bddGames.reduce((scheduleByDate, game) => {
    const formattedDate = formatDate(game.date);

    const gameInfo = {
      ...game,
      scouts: schedule?.rinknetScouts.filter((rs) =>
        game.rinknetScoutsMapping.includes(parseInt(rs.id))
      ),
    };

    if (formattedDate in scheduleByDate) {
      scheduleByDate[formattedDate].push(gameInfo);
    } else {
      scheduleByDate[formattedDate] = [gameInfo];
    }

    return scheduleByDate;
  }, {});

  const renderDay = (date) => {
    if (!date) return null;
    const formattedDate = formatDate(date);
    const gamesOnDate = scheduleByDate[formattedDate];

    if (gamesOnDate?.length == 1) {
      const game = gamesOnDate[0];

      const isHome = game.homeTeamRinknetPlayersMapping.includes(
        bddPlayer.rinknetId
      );

      const opponent = (
        <Opponent>
          {isHome
            ? `vs. ${game.awayTeam.shorthand}`
            : `@ ${game.homeTeam.shorthand}`}
        </Opponent>
      );

      return (
        <Day>
          <DayHeader>{date.getDate()}</DayHeader>
          {gamesOnDate && (
            <>
              {opponent}
              {score(game, isHome)}
              {scouts(game)}
            </>
          )}
        </Day>
      );
    } else {
      return (
        <Day>
          <DayHeader>{date.getDate()}</DayHeader>
          {gamesOnDate && (
            <div style={{ marginTop: -4 }}>
              <DayLabelContainer>
                <DayLabel>{gamesOnDate.length} game(s)</DayLabel>
              </DayLabelContainer>
            </div>
          )}
        </Day>
      );
    }
  };

  const formattedDate = formatDate(selectedDate);
  const scheduleForDate = scheduleByDate && scheduleByDate[formattedDate];

  return (
    <Column>
      <div style={{ minHeight: 400 }}>
        <Calendar
          renderDay={renderDay}
          onDateSelected={setSelectedDate}
          onMonthSelected={(startDate) =>
            setStartDate(getStartDateOfMonth(startDate))
          }
          dataPlaceholder={placeholder}
        />
      </div>
      <Container padding={2}>
        <Typography variant="subtitle1" textAlign="center">
          {formatDate(selectedDate)}
        </Typography>
      </Container>
      {scheduleForDate ? (
        scheduleForDate.map((g) => (
          <Container key={`gc-${g.gameId}`}>
            <Divider />
            <Container padding={3}>
              <Row columnGap={2}>
                <Typography variant="body1">
                  {g.bddLeagueSlug.toUpperCase()}
                </Typography>
                <Typography variant="body2">
                  {g.awayTeam.longname} @ {g.homeTeam.longname}
                </Typography>
              </Row>
              <Row columnGap={1} alignItems="start" flexWrap>
                <Typography variant="body2">Scouts:</Typography>
                {g.scouts.length > 0 ? (
                  g.scouts.map((s) =>
                    showReportModal ? (
                      <ScoutLinkButton
                        key={`gcs-${g.id}-${s.firstname}-${s.lastname}`}
                        playerId={bddPlayer.rinknetId}
                        scout={s}
                        gameId={g.rinknetGameId}
                      />
                    ) : (
                      <Typography variant="body1">
                        {`${s.firstname} ${s.lastname}`}
                      </Typography>
                    )
                  )
                ) : (
                  <Typography variant="body1">
                    <em>No scouts scheduled</em>
                  </Typography>
                )}
              </Row>
            </Container>
          </Container>
        ))
      ) : (
        <Typography variant="body2" textAlign="center">
          <em>No games scheduled on this date</em>
        </Typography>
      )}
    </Column>
  );
};
