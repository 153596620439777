import { Button, Modal } from "react-bootstrap";
import { Typography } from "components/bdd/Typography";
import Droppable from "components/bdd/droppable";
import Draggable from "components/bdd/draggable";
import styled from "styled-components";
import { theme } from "constants";
import { itemTypes } from "constants";
import { Column, Container, Row } from "components/bdd/Layout";
import { IconButton, LinkButton } from "components/bdd/Button";
import BDDErrorBoundary from "components/bdd/bdderrorboundary";
import ListPlayer from "./ListPlayer";
import { useQuery } from "@apollo/client";
import { GET_PLAYER_CARD } from "apollo/queries/players.queries";
import { allCustomPlayerFields, customPlayerFieldToinfo } from "components/Lists/constants";
import { HoverInteractive } from "components/bdd";
import { GearFill } from "react-bootstrap-icons";
import { positionToSkillCategories } from "components/PlayersV2/SkillsOverview";
import Toggle from "components/bdd/Toggle/Toggle";
import { getDefaultListSkillStatDefs } from "./SportlogiqSkills";


const noTextSelect = {
  userSelect: 'none'
}

const StyledDraggableField = styled(Draggable)({
  ...theme.cards.medium,
  ...theme.typography.body1,
  ...noTextSelect,
  ...theme.borders.light,
  border: '1px solid',
  padding: '5px 10px',
  width: 'auto',
  whiteSpace: 'nowrap',
})

const DroppableArea = styled(Droppable)({
  ...theme.typography.body2,
  ...theme.borders.roundedMedium,
  ...theme.borders.light,
  ...noTextSelect,
  border: '1px solid',
  padding: '5px 10px'
})

const FieldsContainer = styled(Row)({
  minHeight: '80px',
  flexWrap: 'wrap',
  gap: '5px'
})

const SkillOption = styled.div(({ selected }) => ({
  cursor: 'pointer',
  borderRadius: '5px',
  padding: '5px',
  border: '1px solid #ddd',
  transition: '0.2s',
  ':hover': {
    background: '#ddd'
  },
  ...(selected
    ? { 
      background: 'green',
      ':hover': {
        background: 'lightgreen'
      }
    }
    : {}
  )
}))

const compareSkillDefs = (sd1, sd2) => sd1.flatLabel === sd2.flatLabel

const SLSkillsSelector = ({ selectedStats: selectedStatsArg, setStats }) => {
  const categories = positionToSkillCategories('skaters')
  const selectedStats = !!selectedStatsArg ? selectedStatsArg.filter(sd => !!sd) : []
  const handleClickStat = statDef => {
    // clean out stats..
    if (selectedStats.find(sd => compareSkillDefs(statDef, sd))) {
      setStats(selectedStats
        .filter(s => !compareSkillDefs(s, statDef))
        .filter(s => typeof(s) === 'object')
      )
    } else {
      setStats(selectedStats.concat([statDef]))
    }
  }

  const handleReorderStats = (sdToMove, destIdx) => {
    let newStats = [...selectedStats]
    newStats.splice(destIdx, 0, sdToMove)
    newStats = newStats.filter((sd, idx) => idx === destIdx || !compareSkillDefs(sd, sdToMove))
    setStats(newStats)
  }

  return <HoverInteractive
    content={<Column rowGap={2}>
      <Row gap={2} justifyContent='space-around'>
        {selectedStats.map((sd, i) => 
          <Droppable 
            acceptItemTypes={['skill']}
            key={sd.flatLabel} 
            onDrop={({ data }) => {
              console.log(data)
              handleReorderStats(data, i)
            }}
          >
            <Draggable itemType='skill' data={sd}>
              <SkillOption>
                {sd.flatLabel}
              </SkillOption>
            </Draggable>
          </Droppable>)}
      </Row>
      <hr></hr>
      <b>Select skills to display for each player</b>
      {' '}
      <Row justifyContent='space-around'>
        <div>
          <LinkButton onClick={() => setStats(getDefaultListSkillStatDefs())}>
            Use Defaults
          </LinkButton>
        </div>
        <div>
          <LinkButton onClick={() => setStats([])}>
            Clear Existing
          </LinkButton>
        </div>
      </Row>
      {categories.filter(cat => !cat.hidden).map((cat, i) => <Row key={`${cat.label}-${i}`} gap={5}>
        <div><b>{cat.label}</b></div>
        {cat.statDefinitions.map(sd => <SkillOption 
          key={sd.flatLabel} 
          selected={selectedStats?.find(s => compareSkillDefs(sd, s))}
          onClick={() => handleClickStat(sd)}
        >
          {sd.flatLabel}
        </SkillOption>)}
      </Row>)}
    </Column>}
  >
    <GearFill/>
  </HoverInteractive>
} 

const DraggableField = ({ field, settings, setFieldSettings, handleRemove }) => {
  return <StyledDraggableField
    itemType={itemTypes.PLAYER_LIST_FIELDS}
    data={{ field }}
  >
    {customPlayerFieldToinfo[field].label}
    {' '}
    {field === 'slskills' && <SLSkillsSelector 
      selectedStats={settings?.statDefinitions} 
      setStats={stats => setFieldSettings('statDefinitions', stats )}
    />}
    {' '}
  </StyledDraggableField>
}

const SETTINGS_FIELDS = [
  { label: 'Disable Field Wrap', value: 'noWrap' },
  { label: 'Hide Field Headers', value: 'hideHeader' }
]

export default function CustomListPlayerModal({
  show,
  handleClose,
  list,
  fields,
  setFields,
  settings,
  setSettings
}) {

  const fieldsToAdd = allCustomPlayerFields.filter(f => !fields.includes(f))

  const handleAddField = (data, i) => {
    const field = data.data.field
    const newFields = fields.filter(f => f !== field)
    newFields.splice(i, 0, field)
    setFields(newFields)
  }

  const handleBankDrop = ({ data: { field }}) => {
    const newFields = fields.filter(f => f !== field)
    setFields(newFields)
  }

  const handleSetFieldSettings = (field, key, value) => {
    setSettings({ ...settings, [field]: { ...settings[field], [key]: value } })
  }

  const handleToggleSetting = (s, t) => setSettings({ ...settings, [s]: t })

  
  return <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Typography variant='h6'>
        Choose Player Fields
      </Typography>
    </Modal.Header>
    <Modal.Body>
      {/* {!!list && <Container>
        <BDDErrorBoundary errorContent={<>Error rendering example player</>}>
          <Typography variant='subtitle2' style={{ fontColor: theme.colors.states.danger}}>
            Example Player
          </Typography>
          <ExampleListPlayer list={list} customFields={fields} />
        </BDDErrorBoundary>
      </Container>} */}
      <BDDErrorBoundary>
      <FieldsContainer>
        <div>
          <Typography variant='body2'>
            Fields to display:
          </Typography>
        </div>
        {fields.map((f, i) => <Droppable
          key={f}
          acceptItemTypes={[itemTypes.PLAYER_LIST_FIELDS]}
          onDrop={dropped => handleAddField(dropped, i)}
        >
          <DraggableField 
            field={f} 
            settings={settings[f]} 
            setFieldSettings={(key, value) => handleSetFieldSettings(f, key, value)}
            handleRemove={() => setFields(fields.filter(fi => fi !== f))}
          />
        </Droppable>)}
        <DroppableArea 
          acceptItemTypes={[itemTypes.PLAYER_LIST_FIELDS]}
          onDrop={dropped => handleAddField(dropped, fields.length)}
        >
          Add fields to display
        </DroppableArea>
      </FieldsContainer>

      <Row>
        <Typography variant='subtitle1'>
          Settings
        </Typography>
      </Row>
      <div>
        {SETTINGS_FIELDS.map(sf => <Toggle
          key={sf.value}
          label={sf.label}
          toggled={settings?.[sf.value]}
          setToggled={t => handleToggleSetting(sf.value, t)}
        />)}
      </div>

      <Row justifyContent='space-between'>
        <Typography variant='subtitle1'>
          Field Bank
        </Typography>
        <Typography variant='body2'>
          Drag-n-drop fields to add/re-order them 
        </Typography>
        <Typography variant='caption'>
          <LinkButton onClick={() => setFields([])}>
            Clear all fields
          </LinkButton>
        </Typography>
      </Row>
      <Container>
        <DroppableArea
          acceptItemTypes={[itemTypes.PLAYER_LIST_FIELDS]}
          hoverColor='#cfc'
          onDrop={handleBankDrop}
        >
          <Column rowGap={5} minHeight='200px'>
            {fieldsToAdd.map(f => <DraggableField 
              key={f}
              field={f}
              settings={settings[f]} 
              setFieldSettings={(key, value) => handleSetFieldSettings(f, key, value)}
            />)}
          </Column>
        </DroppableArea>
      </Container>
      </BDDErrorBoundary>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={handleClose}>
        Done
      </Button>
    </Modal.Footer>
  </Modal>
}



const ExampleListPlayer = ({ list, customFields }) => {
  if (!list || !list.players.length > 0) {
    console.warn('No list prop or no players so no example player')
  }

  const listPlayer = list?.players?.[0]
  const idType = listPlayer?.idType === 'bdd_player_slug' ? 'slug' : 'rinknetId'
  const { data, loading, error } = useQuery(GET_PLAYER_CARD, { 
    variables: { [idType]: listPlayer.playerId },
    skip: !listPlayer
  })

  if (!data) return null

  return <div style={{
    border: '1px solid #aaa',
    borderRadius: '5px',
    padding: '5px'
  }}>
    <ListPlayer
      rinknetPlayer={data?.bddPlayer?.rinknetPlayer}
      bddPlayer={data?.bddPlayer}
      list={list}
      mode='custom'
      customFields={customFields}
    />
  </div>
}