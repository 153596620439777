import { useState } from 'react';
import { useFilterContext } from './context';
import { LinkButton } from '../Button';
import { Typography } from '../Typography';
import { Filters } from '.';

export const SelectAndRangeFilter = ({
  id,
  label,
  width = 300,
  options,
  onChange,
  renderRangeTagLabel,
  isReverse,
}) => {
  const filters = useFilterContext((state) => state.filters);
  const { removeFilter } = useFilterContext((state) => state.actions);

  const [showRangeFilters, setShowRangeFilters] = useState(
    !!filters[id]?.value && !!filters[id]?.value.min
  );

  const toggle = (
    <LinkButton
      onClick={() => {
        setShowRangeFilters(!showRangeFilters);
        removeFilter(id);
      }}
    >
      <Typography variant="stat" state="link">
        <b>{showRangeFilters ? 'Filter by values' : 'Filter by range'}</b>
      </Typography>
    </LinkButton>
  );

  return showRangeFilters ? (
    <Filters.SelectRange
      id={id}
      label={`${label} Range`}
      subLabel={toggle}
      options={options}
      width={width}
      renderTagLabel={renderRangeTagLabel}
      isReverse={isReverse}
    />
  ) : (
    <Filters.Select
      width={width}
      id={id}
      subLabel={toggle}
      options={options}
      isClearable
      label={label}
      cond="isin"
      selectProps={{
        isMulti: true,
      }}
      onChange={onChange}
    />
  );
};
