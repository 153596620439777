import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useSearchParamsState } from 'components/bdd/hooks';
import { contractColumns, playerColumns, predictedPositionsColumns } from '../constants';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { PlayerSearchContext } from '..';

const defaultColumnIds = [
  'player',
  'team',
  'age',
  'predicted-position',
  'roles',
  'contract',
];

export const usePlayerSearchColumns = ({ target }) => {
  const [showColumnOptions, setShowColumnOptions] = useState();

  const { store, setStore } = useContext(PlayerSearchContext);
  const columnIds = store.columnIds || defaultColumnIds;

  const allColumns = [
    ...playerColumns,
    ...contractColumns,
    ...(target == 'skaters' ? predictedPositionsColumns : []),
    // ...statDefColumns,
  ];

  const columns = allColumns.filter((c) => columnIds.includes(c.id));

  const playerColumnSelect = (
    <StyledBDDSelect
      selectProps={{
        placeholder: 'Player info',
        isMulti: true,
        isSearchable: true,
      }}
      selectedValue={columnIds}
      options={allColumns.map((c) => ({
        value: c.id,
        label: c.Header,
      }))}
      onChange={(n, v) => {
        setStore((store) => ({
          ...store,
          columnIds: v,
        }));
      }}
    />
  );

  return {
    columns: columns,
    columnSelect: (
      <Container>
        <Column gap={2}>
          <Container
            variant="button"
            onClick={() => setShowColumnOptions(!showColumnOptions)}
          >
            <Typography variant="body1">
              <Row columnGap={1}>
                {!showColumnOptions ? 'Show/Hide columns' : 'Hide column options'}
                <Icon icon={!showColumnOptions ? <ChevronDown /> : <ChevronUp />} />
              </Row>
            </Typography>
          </Container>
        </Column>
        {!!showColumnOptions && (
          <Container>
            <Row>
              <Container width={'100%'}>{playerColumnSelect}</Container>
            </Row>
          </Container>
        )}
      </Container>
    ),
  };
};
