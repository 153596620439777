import { useQuery } from '@apollo/client';
import {
  GET_PLAYER_LISTS,
  GET_RINKNET_DRAFT_LISTS,
  buildRinknetDraftListQuery,
} from 'apollo/queries/playerlist.queries';
import { useSelect } from '.';

export default function usePlayerListSelect({
  variables,
  variant,
  skip = false,
  isMulti = false,
  initialSelectedValue,
  includeRinknetDraftLists,
  selectProps = {},
  size,
}) {
  const { data, loading } = useQuery(GET_PLAYER_LISTS, { variables, skip });

  const { data: rinknetDraftListData, loading: rinknetDraftListLoading } = useQuery(
    buildRinknetDraftListQuery({ loadPlayerData: false }),
    {
      skip: skip || !includeRinknetDraftLists,
    }
  );

  const rinknetDraftLists =
    rinknetDraftListData?.rinknetDraftLists.map((rdl) => ({
      ...rdl,
      listType: 'RINKNET_DRAFT_LIST',
      name: `${rdl.description} by ${rdl.scoutFirstName} ${rdl.scoutLastName}`,
    })) || [];

  const listToOption = (l) => ({ ...l, value: l.id, label: l.name });

  const playerListOptions = !!data
    ? data.playerLists.concat(rinknetDraftLists).reduce((acc, curr) => {
        const lt = curr.listType.replace('_', ' ');
        const existing = acc.find((g) => g.label === lt);
        if (existing) {
          existing.options.push(listToOption(curr));
        } else {
          acc.push({
            options: [listToOption(curr)],
            label: lt,
          });
        }
        lt in acc ? acc[lt].push(listToOption(curr)) : (acc[lt] = [listToOption(curr)]);
        return acc;
      }, [])
    : [];

  const { selected, select } = useSelect({
    options: playerListOptions,
    variant,
    initialSelectedValue,
    noInitialSelectedValue: !initialSelectedValue,
    size,
    selectProps: {
      placeholder: loading || rinknetDraftListLoading ? 'Loading...' : 'Select list',
      isLoading: loading || rinknetDraftListLoading,
      isSearchable: true,
      isClearable: true,
      isMulti,
      ...selectProps,
    },
  });

  return {
    data,
    options: playerListOptions,
    selected,
    select,
  };
}
