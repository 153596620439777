import { useEPStatsContext } from '../context';
import React, { useEffect, useState } from 'react';
import { Cell, Header, buildColumns } from 'components/bdd/Table';
import {
  ScoutingRankDataLoader,
  getScoutingRanks,
  useScoutingRankDataStore,
} from 'components/Lists/Builder/hooks/useScoutingRankDataLoader';
import { PLAYER_LIST_TYPES } from 'constants';
import { getCurrentSeason } from 'helpers/hockeyutils';
import {
  ratingToIcon,
  ratingToIconText,
  ratingToText,
} from 'components/PlayersV2/Scouting/ScoutingGrades';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipSpan } from 'components/reports';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Asterisk, ExclamationTriangle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { theme } from 'constants';
import { IconButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { Typography } from 'components/bdd/Typography';
import { LinkPopover } from 'components/bdd/Popover';
import { ScoutingColumnSettings } from './ScoutingColumnSettings';
import { getFilterValues } from 'components/bdd/Filters/helpers';

const SCOUTING_COLUMN_ID = 'scouting';
const SCOUTING_COLUMN_RATINGS = [
  'scoring',
  'puckHandling',
  'intensity',
  'creativity',
  'hockeySense',
  'defending',
  'skating',
  'passing',
];

export const ScoutingReportData = ({}) => {
  const indexColumnIds = useEPStatsContext((state) => state.indexColumnIds);
  const results = useEPStatsContext((state) => state.results);
  const { addColumns } = useEPStatsContext((state) => state.actions);

  const rinknetIds = results.stats?.data
    .map((s) => s['player_rinknet_id'])
    .filter((s) => !!s);

  const disableSort =
    !results || results.pageInfo?.hasNextPage || results.pageInfo?.hasPreviousPage;

  const loaders = rinknetIds && indexColumnIds.includes(SCOUTING_COLUMN_ID) && (
    <>
      {['f', 'd', 'g'].map((position) => (
        <div key={position}>
          <ScoutingRankDataLoader
            {...{
              rinknetIds,
              listType: 'all',
              queryVariables: {
                position,
                timeRange: getCurrentSeason(),
                predictedGrades: true,
              },
            }}
          />
          <ScoutingRankDataLoader
            {...{
              rinknetIds,
              listType: 'all',
              queryVariables: {
                position,
                timeRange: 'PAST_YEAR',
              },
            }}
          />
        </div>
      ))}
    </>
  );

  const { rankingsByPlayer, splits } = useScoutingRankDataStore();
  const getRatingColumn = (rating, isPredicted) => ({
    id: `scouting-rating-${rating}-${isPredicted ? 'predicted' : ''}`,
    disableSort,
    minWidth: 25,
    header: `${isPredicted ? 'Predicted' : ''} ${ratingToText[rating]}`,
    Header: (
      <Container paddingTop={1}>
        <TooltipSpan
          content={`${isPredicted ? 'Predicted' : ''} ${ratingToText[rating]}`}
        >
          <Row>
            {ratingToIcon[rating] ? (
              <FontAwesomeIcon icon={ratingToIcon[rating]} />
            ) : (
              ratingToIconText[rating]
            )}
            {isPredicted && (
              <Container style={{ position: 'absolute', top: 0, right: -5 }}>
                <Icon size={8} icon={<Asterisk />} color={theme.colors.states.danger} />
              </Container>
            )}
          </Row>
        </TooltipSpan>
      </Container>
    ),
    accessor: (row) => {
      const { ratingToRank } = getScoutingRanks(
        row['player_rinknet_id'],
        rankingsByPlayer,
        splits,
        {
          predictedGrades: isPredicted ? true : null,
          position: row['position'].toLowerCase(),
        }
      );

      const rank = ratingToRank?.[rating];
      if (!rank) return null;

      return roundToX(100 * (1 - rank.percentile), 0, true);
    },
    renderCell: (_, av) => {
      if (!av) return <Cell textAlign={'center'}>-</Cell>;

      const color = getRedGreenColor(av / 100, 0, 1, false, 2);

      return (
        <Cell textAlign={'center'}>
          <Container style={{ backgroundColor: color }}>{av}</Container>
        </Cell>
      );
    },
  });

  const [columnSettings, setColumnSettings] = useState();
  const { showPredicted } = columnSettings ? getFilterValues(columnSettings) : {};

  const scoutingColumn = {
    id: SCOUTING_COLUMN_ID,
    header: 'Scouting',
    Header: (
      <Header>
        <Row columnGap={1}>
          Scouting
          <LinkPopover
            renderLink={(setShow, target) => (
              <div ref={target}>
                <IconButton
                  icon={<ThreeDotsVertical />}
                  onClick={() => setShow((show) => !show)}
                  padding={0}
                />
              </div>
            )}
            renderContent={() => (
              <ScoutingColumnSettings
                defaults={columnSettings}
                onChange={(settings) => setColumnSettings(settings)}
              />
            )}
          />
          {disableSort && (
            <IconButton
              tooltipPlacement={'bottom'}
              tooltip={
                'Sorting by scouting ranks will not work with paginated queries. Try increasing the page limit or adding more filters'
              }
              icon={<ExclamationTriangle />}
              color={theme.colors.states.danger}
            />
          )}
        </Row>
      </Header>
    ),
    columns: buildColumns(
      SCOUTING_COLUMN_RATINGS.flatMap((rating) => [
        getRatingColumn(rating, false),
        ...(showPredicted ? [getRatingColumn(rating, true)] : []),
      ])
    ),
  };

  useEffect(() => {
    addColumns([scoutingColumn]);
  }, [loaders, rinknetIds]);

  return loaders;
};
