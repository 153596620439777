import { Bug, InfoSquare, PatchQuestion } from "react-bootstrap-icons"


export const FEEDBACK_TYPES = {
  REQUEST: 'REQUEST',
  BUG: 'BUG',
  GENERAL: 'GENERAL',
}

export const BUG_TYPE_OPTIONS = {
  API: 'API',
  FRONTEND: 'FRONTEND',
  OTHER: 'OTHER'
}

export const FEEDBACK_TYPE_TO_ICON = {
  'REQUEST': <PatchQuestion color='green'/>,
  'BUG': <Bug color='red'/>,
  'GENERAL': <InfoSquare color='blue'/>
}