import { LinkButton } from 'components/bdd/Button';
import { LinkPopover } from 'components/bdd/Popover';
import { expiryStatusOptions, expiryStatusDetailsOptions } from './constants';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { Cell } from 'components/bdd/Table';
import { Column, Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { season8to4 } from 'helpers/hockeyutils';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_BF_CONTRACT } from 'apollo/queries/bruinsfriendly.queries';

export const ExpiryStatusButton = ({ contract }) => {
  const hasStatusOverride = !!contract.overrideExpiryStatus;
  const expiryStatus = contract.overrideExpiryStatus || contract.expiryStatus;
  const statusLabel = expiryStatusOptions.find((o) => o.value == expiryStatus)?.label || 'Unknown';

  const hasDetailsOverride = !!contract.overrideExpiryStatusDetails;
  return (
    <LinkPopover
      hideTransition
      renderLink={(setShow, target, show) => (
        <Container ref={target}>
          <LinkButton onClick={() => setShow(!show)}>
            <Cell>{statusLabel}</Cell>
            {hasStatusOverride || hasDetailsOverride && (
              <Container style={{ position: 'absolute', right: -5, top: -2 }}>
                <Typography variant="body2" color={theme.colors.states.danger}>
                  *
                </Typography>
              </Container>
            )}
          </LinkButton>
        </Container>
      )}
      renderContent={() => <OverrideExpiryStatusForm contract={contract} />}
    />
  );
};

export const OverrideExpiryStatusForm = ({ contract }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [updateContract] = useMutation(UPDATE_BF_CONTRACT);
  const detailsLabel = expiryStatusDetailsOptions.find((o) => o.value == contract.expiryStatusDetails)?.label || '--';

  return (
    <Container padding={2} onClick={(e) => e.stopPropagation()}>
      <Column gap={2}>
        {showDisclaimer && (
          <Typography variant="stat" color={theme.colors.states.danger}>
            Refresh page to see changes
          </Typography>
        )}
        <Container>
          <Typography variant="body1">Expiry Status:</Typography>
          <Typography variant="body2">
            {contract.expiryStatus}
          </Typography>
        </Container>
        <Container>
          <Typography variant="body1">Override Expiry Status:</Typography>
          <StyledBDDSelect
            size="sm"
            width={150}
            options={expiryStatusOptions}
            selectedValue={contract.overrideExpiryStatus}
            selectProps={{ isClearable: true }}
            onChange={(n, v) => {
              updateContract({
                variables: {
                  input: {
                    id: contract.id,
                    overrideExpiryStatus: v,
                  },
                },
                onCompleted: () => setShowDisclaimer(true),
              });
            }}
          />
        </Container>
        <Container>
          <Typography variant="body1">Expiry Status Details:</Typography>
          <Typography variant="body2">
            {detailsLabel}
          </Typography>
        </Container>
        <Container>
          <Typography variant="body1">Override Expiry Status Details:</Typography>
          <StyledBDDSelect
            size="sm"
            width={150}
            options={expiryStatusDetailsOptions}
            selectedValue={contract.overrideExpiryStatusDetails}
            selectProps={{ isClearable: true }}
            onChange={(n, v) => {
              updateContract({
                variables: {
                  input: {
                    id: contract.id,
                    overrideExpiryStatusDetails: v,
                  },
                },
                onCompleted: () => setShowDisclaimer(true),
              });
            }}
          />
        </Container>
      </Column>
    </Container>
  );
};
