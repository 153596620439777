import { CheckboxGroupFilter, EventFilterContext } from '.';
import { eventNameToMapping } from '../helpers';
import { useContext } from 'react';

export const PrecedingFlagsFilter = ({}) => {
  const { filters } = useContext(EventFilterContext);
  const eventNames = filters['event_name'].val;
  const options = eventNames.flatMap((e) => eventNameToMapping[e]?.precedingFlags || []);

  if (options.length <= 1) return null;

  return (
    <CheckboxGroupFilter
      col={'preceding_flags'}
      id={'preceding_flags'}
      label="Preceding Flags"
      options={options}
    />
  );
};
