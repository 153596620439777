import React from 'react';
import styled from 'styled-components';
import { theme } from 'constants';
import { Row } from '../Layout';
import { TooltipSpan } from 'components/reports';

const LinkButton = styled.div({
  ...theme.typography.body2,
  userSelect: 'none',
  cursor: 'pointer',
});

const SelectedLinkButton = styled(LinkButton)({
  borderBottom: `5px solid ${theme.colors.teams.bos.primary}`,
});

const Tooltip = ({ tooltip, children }) =>
  tooltip ? <TooltipSpan content={tooltip}>{children}</TooltipSpan> : <>{children}</>;

export const LinkButtonGroup = ({ options, selectedValue, onClick }) => {
  return (
    <Row columnGap={3} rowGap={1} alignItems="baseline" flexWrap>
      {options.map((option) => (
        <Tooltip tooltip={option.tooltip} key={`button-group-${option.value}`}>
          <LinkButton
            as={selectedValue == option.value ? SelectedLinkButton : LinkButton}
            onClick={() => (option.disabled ? null : onClick(option))}
          >
            {option.renderOption ? option.renderOption() : <b>{option.label}</b>}
          </LinkButton>
        </Tooltip>
      ))}
    </Row>
  );
};
