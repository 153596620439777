import { useContext } from "react"
import { DeadCapForm } from "../../SalaryCap/Admin/DeadCapForm"
import { ScenarioContext } from "./ScenarioContextProvider";


export const ScenarioDeadCapForm = ({ scenario, bfTeam, onCompleted }) => {

  const { updateScenario } = useContext(ScenarioContext);
  
  const createMutation = ({ variables: { input }}) => {
    console.log(('here', input))
    updateScenario({
      variables: {
        input: {
          id: scenario.id,
        },
        deadSpaceToAdd: input
      }
    })
    onCompleted && onCompleted()
  }

  console.log(scenario)
  return (
    <DeadCapForm
      hideTeam
      forceTeamSlug={bfTeam.slug}
      updateMutation={null}
      createMutation={createMutation}
      handleDelete={null}
      onCancel={null}
    />
  )
}