import { theme } from 'constants/theme';
import { Typography } from '../Typography';

export const Cell = ({ variant, textAlign, isStatCell, children }) => {
  return (
    <Typography
      variant={variant || 'body2'}
      textAlign={textAlign || 'start'}
      noWrap
      style={{ backgroundColor: isStatCell ? theme.colors.light.background : 'unset' }}
    >
      {children}
    </Typography>
  );
};
