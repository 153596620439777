import { BDDLoader } from 'components/bdd/bddloader';
import BDDPlotly from 'components/bdd/bddplotly';
import { Divider } from 'components/bdd/Divider';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import Toggle from 'components/bdd/Toggle/Toggle';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { getDateRangeModeOptions } from 'helpers/daterangemode';
import { getStrengthChoices } from 'helpers/filters';
import { isTargetGroup, nameToLastName } from 'helpers/hockeyutils';
import { useContext, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Funnel, GraphUp } from 'react-bootstrap-icons';
import { SLStatContext } from '.';
import regression from 'regression';

const getRangeValues = (metastats) => {
  const { mean, stddev, quantiles } = metastats;
  var low = Math.max(mean - stddev, quantiles[0]);
  var high = Math.min(mean + stddev, quantiles[quantiles.length - 1]);

  return [low, high];
};
const getMean = (metastats, col) => {
  return metastats[col].mean;
};

const getVal = (d, lab) => {
  if (lab === 'height') {
    const hs = d[lab];
    const [ft, inches] = hs.split("'");
    return parseFloat(ft) + parseFloat(inches) / 12.0;
  } else {
    return d[lab];
  }
};

export const StatScatterChart = ({}) => {
  const { chartOptions, data, query } = useContext(SLStatContext);
  const { target } = query;
  const selected = [];

  const {
    options: {
      xAxisStat,
      yAxisStat,
      showLabel,
      showStandardRange,
      showLeagueAvg,
      showBestFit,
      invertXAxis,
      invertYAxis,
    },
  } = chartOptions || {};

  if (!data) return <BDDLoader variant="squares" />;

  const statInfo = {};
  data.format.stat_definitions.forEach((s) => {
    statInfo[s.unique_id] = s;
  });

  const metastats = data.extras.metastats.league[0].metastats;
  const targetName = target.charAt(0).toUpperCase() + target.slice(1);

  if (xAxisStat && yAxisStat) {
    const x = [];
    const y = [];
    const xyPairs = [];
    const names = [];
    const labels = [];
    var pData = [];
    const { label: xLabel, value: xCol } = xAxisStat;
    const { label: yLabel, value: yCol } = yAxisStat;
    const nameKey =
      target === 'teams'
        ? 'team'
        : target === 'skaters' || target === 'goalies'
        ? 'Player'
        : isTargetGroup(target)
        ? 'jerseynumbers'
        : null;
    const dataToLabel = (d) =>
      target === 'teams'
        ? d['team']
        : target === 'skaters' || target === 'goalies'
        ? nameToLastName(d['Player'])
        : isTargetGroup(target)
        ? d['jerseynumbers']
        : null;

    let isSelected;
    if (isTargetGroup(target)) {
      isSelected = (d) => selected?.find((s) => d.slugs.includes(s.value));
    } else {
      isSelected = (d) => selected?.find((s) => s.value == d.slug);
    }

    const selectedX = [];
    const selectedY = [];
    const selectedLabels = [];
    const selectedNames = [];

    data.data.forEach((d) => {
      x.push(getVal(d, xLabel));
      y.push(getVal(d, yLabel));
      xyPairs.push([getVal(d, xLabel), getVal(d, yLabel)]);
      if (isTargetGroup(target)) {
        names.push(`${d['Players']}<br>${d['jerseynumbers']}`);
      } else {
        names.push(d[nameKey]);
      }
      labels.push(dataToLabel(d));

      if (isSelected(d)) {
        selectedX.push(getVal(d, xLabel));
        selectedY.push(getVal(d, yLabel));
        selectedLabels.push(dataToLabel(d));
        selectedNames.push(names[names.length - 1]);
      }
    });

    // Define layout obj
    var layout = {
      title: {
        text: `${yLabel} vs. ${xLabel}`,
        font: { family: 'Oswald' },
      },
      showLegend: true,
      legend: { orientation: 'h' },
      hovermode: 'closest',
      xaxis: { title: xLabel },
      yaxis: { title: yLabel },
      // margin: { t: 30 },
      shapes: [],
    };

    if (invertXAxis) {
      layout.xaxis['autorange'] = 'reversed';
      layout.xaxis.title = `${xLabel} (inverted)`;
    }
    if (invertYAxis) {
      layout.yaxis['autorange'] = 'reversed';
      layout.yaxis.title = `${yLabel} (inverted)`;
    }

    // If toggled, add best fit line
    if (showBestFit) {
      const regResult = regression.linear(xyPairs);
      const minX = Math.min(...x);
      const maxX = Math.max(...x);
      pData.push({
        mode: 'lines',
        name: 'Best Fit',
        x: [minX, maxX],
        y: [regResult.predict(minX)[1], regResult.predict(maxX)[1]],
        line: { dash: 'dot', color: 'black' },
      });

      layout.title.text +=
        `<br>` +
        `OLS Best Fit: ${regResult.string}<br>` +
        `r<sup>2</sup>: ${regResult.r2}`;
    }

    // Add Skater Scatter
    pData.push({
      name: targetName,
      mode: showLabel ? 'markers+text' : 'markers',
      type: 'scatter',
      x: x,
      y: y,
      text: labels,
      customdata: names,
      marker: {
        size: 10,
        color:
          selected?.length > 0
            ? theme.colors.states.neutral
            : theme.colors.teams.bos.primary,
        opacity: showLabel ? 0.5 : 1.0,
      },
      hovertemplate:
        '<b>%{customdata}</b><br><br>' +
        '%{yaxis.title.text}: %{y:.2f}<br>' +
        '%{xaxis.title.text}: %{x:.2f}<br>' +
        '<extra></extra>',
    });

    // Add Selected Scatter
    pData.push({
      name: `Selected ${targetName}`,
      mode: 'markers+text',
      type: 'scatter',
      x: selectedX,
      y: selectedY,
      text: selectedLabels,
      customdata: selectedNames,
      marker: {
        size: 20,
        color: theme.colors.light.highlight,
        opacity: 1,
      },
      hovertemplate:
        '<b>%{customdata}</b><br><br>' +
        '%{yaxis.title.text}: %{y:.2f}<br>' +
        '%{xaxis.title.text}: %{x:.2f}<br>' +
        '<extra></extra>',
    });

    // Add means/ranges if toggled
    if (!!metastats) {
      const xColMetastats = Object.values(metastats).find(
        (m) => m.stat_slug == xCol
      );

      const yColMetastats = Object.values(metastats).find(
        (m) => m.stat_slug == yCol
      );

      if (showLeagueAvg) {
        const meansShapesDefaults = {
          type: 'line',
          line: {
            color: '#f33',
            opacity: 0.5,
            width: 1,
            dash: 'dash',
          },
          layer: 'below',
        };

        if (yColMetastats?.mean) {
          layout.shapes.push({
            ...meansShapesDefaults,
            xref: 'paper',
            x0: 0,
            x1: 1,
            y0: yColMetastats?.mean,
            y1: yColMetastats?.mean,
          });
        }
        if (xColMetastats?.mean) {
          layout.shapes.push({
            ...meansShapesDefaults,
            yref: 'paper',
            y0: 0,
            y1: 1,
            x0: xColMetastats?.mean,
            x1: xColMetastats?.mean,
          });
        }
      }
      if (showStandardRange) {
        const stddevsShapesDefaults = {
          type: 'rect',
          layer: 'below',
          fillcolor: '#DDD',
          opacity: 0.4,
          line: { width: 0 },
        };
        if (yColMetastats && yColMetastats.mean && yColMetastats.stddev) {
          layout.shapes.push({
            ...stddevsShapesDefaults,
            xref: 'paper',
            x0: 0,
            y0: getRangeValues(yColMetastats)[0],
            x1: 1,
            y1: getRangeValues(yColMetastats)[1],
          });
        }
        if (xColMetastats && xColMetastats.mean && xColMetastats.stddev) {
          layout.shapes.push({
            ...stddevsShapesDefaults,
            yref: 'paper',
            x0: getRangeValues(xColMetastats)[0],
            y0: 0,
            x1: getRangeValues(xColMetastats)[1],
            y1: 1,
          });
        }
      }
    }
  }

  return (
    <Container>
      <BDDPlotly data={pData} layout={layout} />
    </Container>
  );
};
