import { theme } from "constants";
import styled from "styled-components"


export const NotificationWrapper = styled.div({
  position: 'relative'
})

export const NotificationIndicator = styled.div(({ size, fontSize, color, backgroundColor, top, right }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: top || 0,
  right: right || '-10px',
  borderRadius: '50%',
  height: size || '15px',
  width: size || '15px',
  fontSize: fontSize || '7pt',
  color: color || 'white',
  backgroundColor: backgroundColor || theme.colors.states.danger,
  float: 'center',
}))

export default NotificationIndicator;