import React from 'react';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from '../Typography';

export const Timeline = ({ items }) => {
  return (
    <Container>
      {items?.length > 0 ? (
        <Column gap={4}>{items}</Column>
      ) : (
        <Typography variant="body1">No items to display</Typography>
      )}
    </Container>
  );
};
