import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { SectionHeader, SubSectionHeader, TooltipSpan } from '../reports'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowsAltH, faEdit, faSlidersH } from "@fortawesome/free-solid-svg-icons"
import { dateToTimeAgo, formatDate, formatDateGQL, getDateDaysBack } from "../../helpers/helpers"
import { positionToAbbrev } from "../../helpers/hockeyutils"
import styled from 'styled-components'
import { BDDOptionGroup, HoverInteractive } from '../bdd'
import BDDEditInline from "../bdd/bddeditinline"
import { BDDDepthChartChangeFragment } from '../../apollo/queries/depthchart.queries'
import BDDSelect from '../bdd/bddselect'
import { BDDLoader } from '../bdd/bddloader'
import BDDApiError from '../bdd/bddapierror'
import { BDDDateRangePicker } from '../bdd/bdddatepicker'
import BDDErrorBoundary from '../bdd/bdderrorboundary'

const Styles = styled.div`
    .changes-col {
        margin: 10px;
        font-size: 0.8em;
        ul {
            margin-bottom: 0;
        }
    }
    .changes-container {
        max-height: 100px;
        overflow: auto;

        padding-right: 15px;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
    }
    .note-icon {
        color: #ddd;
        margin-right: 10px;
        cursor: pointer;
    }
    .has-note {
        color: #333;
    }

`

const UPDATE_CHANGE_NOTE = gql`mutation UpdateDepthChartChangeNote($id: ID!, $notes: String) {
    updateDepthChartChangeNote(id: $id, notes: $notes) {
        bddDepthChartChange {
            ...bddDepthChartChangeFragment
        }
    }
} ${BDDDepthChartChangeFragment}`

const GET_DEPTH_CHART_CHANGES = gql`query GetDepthChartChanges(
    $allActive: Boolean,
    $depthChartId: ID,
    $n: Int,
    $startDate: Date,
    $endDate: Date,
    $changeTypes: [String!]
) {
    bddDepthChartChanges(
        allActive: $allActive,
        depthChartId: $depthChartId,
        n: $n,
        startDate: $startDate,
        endDate: $endDate,
        changeTypes: $changeTypes
    ) {
        ...bddDepthChartChangeFragment
    }
} ${BDDDepthChartChangeFragment}`

const getGQLDateDaysBack = daysBack => formatDateGQL(getDateDaysBack(daysBack))

export default function DepthChartChanges({
    allActive=false, // bool: if true, grabs changes from all active DCs
    depthChartId=undefined, // if not allActive, must supply a dc id
    alwaysShowTeam // if set, will always say the team
}) {
    if (!allActive && !depthChartId) {
        throw Error('Must supply "allActive=true" or a specific depthChartId to fetch changes')
    }
    const [filters, setFilters] = useState({
        n: 10,
        startDate: getDateDaysBack(10),
        endDate: getDateDaysBack(-1), // date in the future to avoid time issues
        changeTypes: []
    })
    const { data, loading, error, refetch } = useQuery(GET_DEPTH_CHART_CHANGES, { variables: {
        allActive, depthChartId, ...filters,
        startDate: formatDateGQL(filters.startDate),
        endDate: formatDateGQL(filters.endDate)
    }})
    const [show, setShow] = useState(false)
    const [updateChangeNote, { loading: mutationLoading }] = useMutation(UPDATE_CHANGE_NOTE)
    const emptyNoteMsg = 'Click to add note'
    const handleUpdateNote = (changeId, notes) => {
        if (notes === emptyNoteMsg) return
        updateChangeNote({ variables: {
            id: changeId,
            notes
        }})
    }
    const changes = !!data ? data.bddDepthChartChanges : []
    return <Styles>
    <BDDErrorBoundary>
    <Row className='control-row changes'>
        <Col sm='auto' className='m-auto changes-col'>
            <Row>
                <Col sm='1' className='mr-auto'>
                    <small>
                        <a href='' onClick={e => {
                            e.preventDefault()
                            refetch()
                        }}>
                            Refresh
                        </a>
                    </small>
                </Col>
                <Col>
                    <SubSectionHeader>Depth Chart Changes</SubSectionHeader>
                </Col>
                <Col sm='1' className='ml-auto'>
                    <TooltipSpan content='Filters'>
                    <Button size="sm" style={{ fontSize: '12px', border: 0 }} variant="outline-dark" onClick={() => setShow(true)}>
                        <FontAwesomeIcon icon={faSlidersH} />
                    </Button>
                    </TooltipSpan>
                </Col>
            </Row>
            {loading ? <BDDLoader/>
            : error ? <BDDApiError error={error}/>
            : <div className='changes-container'>
            {changes.map(c => <div key={c.id} className='change-entry'>
                <span className={`note-icon ${!!c.notes && c.notes.length > 0 ? 'has-note' : ''}`}>
                    <HoverInteractive
                        style={{ minWidth: '100px' }}
                        content={<>
                            <BDDEditInline
                                name='notes'
                                editingElement='textarea'
                                defaultValue={!!c.notes && c.notes.length > 0 ? c.notes : emptyNoteMsg }
                                onChange={(n,v) => handleUpdateNote(c.id, v)}
                            />
                            <div style={{ color: '#ddd', fontSize: '0.7em', visibility: mutationLoading ? 'visible' : 'hidden' }}>
                                Saving...
                            </div>
                    </>}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </HoverInteractive>
                </span>
                {alwaysShowTeam && `${c.slTeam1.shorthand} `}
                <b>{c.changeType}</b> {c.bddPlayer1.fullName}
                {' '} 
                {!!c.slTeam2 && c.slTeam1.slug != c.slTeam2.slug ? `${c.slTeam1.shorthand} ` : null}
                {c.depthLeague1 != 'NHL' || c.depthLeague1 != c.depthLeague2 ? `${c.depthLeague1} ` : null}
                {positionToAbbrev(c.position1)}-{c.lineNum1}
                {c.changeType === 'MOVE' ? <>
                    {' '}
                    <FontAwesomeIcon icon={faArrowRight}/>
                    {' '}
                    {!!c.slTeam2 && c.slTeam1.slug != c.slTeam2.slug ? `${c.slTeam2.shorthand} ` : null}
                    {c.depthLeague2 != 'NHL' || c.depthLeague1 != c.depthLeague2 ? `${c.depthLeague2} ` : null}
                    {positionToAbbrev(c.position2)}-{c.lineNum2}
                </> : c.changeType === 'SWAP' ? <>
                    {' '}
                    <FontAwesomeIcon icon={faArrowsAltH}/>
                    {' '}
                    {c.bddPlayer2.fullName}
                    {' '}
                    {!!c.slTeam2 && c.slTeam1.slug != c.slTeam2.slug ? `${c.slTeam2.shorthand} ` : null}
                    {c.depthLeague2 != 'NHL' || c.depthLeague1 != c.depthLeague2 ? `${c.depthLeague2} ` : null}
                    {positionToAbbrev(c.position2)}-{c.lineNum2}
                </> : null}
                <span style={{ float: 'right', marginLeft: '20px' }}>
                    <TooltipSpan content={<>{c.user.username} @ {formatDate(c.timestamp, { format: '%m/%d/%y %H:%M:%S%ampm'})}</>}>
                        <small>{dateToTimeAgo(c.timestamp)}</small>
                    </TooltipSpan>
                </span>
            </div>)}
            </div>}
        </Col>
    </Row>
    <ChangesFilterBank show={show} handleClose={() => setShow(false)}
        filters={filters}
        setFilters={setFilters}
    />
    </BDDErrorBoundary>
    </Styles>
}


const FilterStyles = styled.div`
    .row {
        margin-top: 10px;
    }
    label {
        font-weight: bold;
        font-size: 0.9em;
    }
`

const ChangesFilterBank = ({
    show, handleClose,
    filters, setFilters
}) => {
    const [dateRangeMode, setDateRangeMode] = useState('LAST_10')
    const handleChange = (n,v) => setFilters({ ...filters, [n]: v })
    
    const handleDateModeChange = val => {
        setDateRangeMode(val)
        if (dateRangeMode != 'CUSTOM' && val.startsWith('LAST_')) {
            setFilters({ ...filters,
                startDate: getDateDaysBack(parseInt(val.split("_")[1])),
                endDate: getDateDaysBack(-1)
            })
        }
    }
    return <Modal show={show} onHide={handleClose}>
        <Modal.Header>
            <SectionHeader>Depth Chart Change Filters</SectionHeader>
        </Modal.Header>
        <Modal.Body>
            <FilterStyles>
            <Row>
                <Col sm='4'>
                    <label htmlFor='n'>Changes to show</label>
                </Col>
                <Col>
                    <BDDSelect name='n'
                        value={filters.n}
                        onChange={handleChange}
                        options={[
                            { label: 5, value: 5 },
                            { label: 10, value: 10},
                            { label: 30, value: 30},
                            { label: 50, value: 50},
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm='4'>
                    <label htmlFor='n'>Changes Type</label>
                </Col>
                <Col>
                    <BDDSelect name='changeTypes'
                        isMulti
                        value={filters.changeTypes}
                        onChange={handleChange}
                        options={[
                            { label: 'ADD', value: 'ADD' },
                            { label: 'REMOVE', value: 'REMOVE'},
                            { label: 'MOVE', value: 'MOVE'},
                            { label: 'SWAP', value: 'SWAP'},
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm='4'>
                    <label htmlFor='date'>Date range</label>
                </Col>
                <Col>
                    <BDDOptionGroup name='date'
                        selected={dateRangeMode}
                        onClick={handleDateModeChange}
                        options={[
                            { label: '10 days', value: 'LAST_10' },
                            { label: '20 days', value: 'LAST_20' },
                            { label: 'Custom', value: 'CUSTOM' }
                        ]}
                    />
                    {dateRangeMode === 'CUSTOM' && <>
                        <BDDDateRangePicker
                            placeholderText='Custom Date Range'
                            showMonthDropdown
                            showYearDropdown
                            isClearable
                            selected={filters.startDate}
                            startDate={filters.startDate}
                            endDate={filters.endDate}
                            onChange={([start, end]) => setFilters({ ...filters, startDate: start, endDate: end })}
                        /> 
                    </>}
                </Col>
            </Row>
            </FilterStyles>
        </Modal.Body>
    </Modal>
}