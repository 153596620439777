import BDDPlotly from 'components/bdd/bddplotly';
import { VideoSelectContext, VideoContext } from 'components/bdd/Video';
import { theme } from 'constants';
import { useContext } from 'react';
import hockeyRink from 'assets/images/hockey_rink_2.png';
import useWindowDimensions from 'components/bdd/hooks/usewindowdimensions';
import { Container, Row } from 'components/bdd/Layout';
import { roundToX } from 'helpers/data';
import { getTeamColorPalette } from 'helpers/plotting';
import { getNHLTeamLogoUrlPng } from 'helpers/logos';

const RINK_WIDTH = 85;
const RINK_HALF_WIDTH = RINK_WIDTH / 2;
const RINK_LENGTH = 200;
const RINK_HALF_LENGTH = RINK_LENGTH / 2;

const eventNameToValueName = (eventName) => {
  switch (eventName) {
    case 'expected_assist':
      return 'iXA';
    case 'shot':
    default:
      return 'XPG';
  }
};

const eventNameToValueKey = (eventName) => {
  switch (eventName) {
    case 'shot':
      return 'value2';
    case 'expected_assist':
      return 'value';
    default:
      return null;
  }
};

export const EventMap = ({ adjustEventsByTeam }) => {
  const { allEvents, loading } = useContext(VideoContext);

  const { selectedEvents, setSelectedEvents } = useContext(VideoSelectContext);

  const dims = useWindowDimensions();

  if (!allEvents) return loading;

  let pdata = [];
  let x = [];
  let y = [];
  let customData = [];
  let colors = [];

  allEvents.forEach((e) => {
    x.push(
      adjustEventsByTeam && e.slGame.homeTeamSlug == e.teamSlug
        ? e.xAdjCoord
        : -e.xAdjCoord
    );

    y.push(
      adjustEventsByTeam && e.slGame.homeTeamSlug == e.teamSlug
        ? -e.yAdjCoord
        : e.yAdjCoord
    );

    const valueName = eventNameToValueName(e.eventName);
    const valueKey = eventNameToValueKey(e.eventName);

    customData.push([
      `${e.slGame.gameDate} ${e.team?.shorthand} vs. ${e.oppTeam?.shorthand}`,
      e,
      `${e.formattedTime}`,
      valueKey ? `${valueName}: ${roundToX(e[valueKey], 2)}` : ``,
    ]);

    colors.push(
      selectedEvents?.find((se) => se.id == e.id)
        ? theme.colors.states.neutral
        : getTeamColorPalette(e.team?.slug)[0]
    );
  });

  pdata.push({
    x: x,
    y: y,
    customdata: customData,
    hovertemplate: `<b>%{customdata[0]}</b><br />%{customdata[2]}<br />%{customdata[3]}<extra></extra>`,
    type: 'scatter',
    mode: 'markers+text',
    marker: {
      color: colors,
      size: 12,
      opacity: 0.9,
    },
  });

  const teamIds = [...new Set(allEvents.map((e) => e.team.nhlid))];
  const gameIds = [...new Set(allEvents.map((e) => e.gameUid))];

  let images = [];
  // Display only team logos for single game or team
  if (gameIds.length == 1 || teamIds.length == 1) {
    images = teamIds.map((id, i) => ({
      source: getNHLTeamLogoUrlPng(id),
      opacity: 0.6,
      xref: 'x',
      yref: 'y',
      x: i === 0 ? 35 : -35,
      y: 0,
      sizex: 15,
      sizey: 15,
      xanchor: 'center',
      yanchor: 'middle',
      layer: 'below',
    }));
  }

  images.push({
    source: hockeyRink,
    opacity: 0.4,
    xref: 'paper',
    yref: 'paper',
    x: 0,
    y: 0,
    sizex: 1,
    sizey: 1,
    xanchor: 'left',
    yanchor: 'bottom',
    layer: 'below',
  });

  const axisProps = {
    showgrid: false,
    zeroline: false,
    showline: false,
    autotick: false,
    ticks: '',
    showticklabels: false,
    fixedrange: true,
  };

  const marginTop = 50;
  const widthPct = 0.5;
  const width = dims.width * widthPct;
  const heightPct = (widthPct * RINK_WIDTH) / RINK_LENGTH;
  const height = heightPct * dims.width + marginTop;
  const rotate = dims.width <= theme.breakpoints.lg;

  return (
    <Container
      width="100%"
      // style={{ transform: rotate ? 'rotate(270deg)' : null }}
    >
      <Row justifyContent="center">
        <BDDPlotly
          data={pdata}
          layout={{
            hovermode: 'closest',
            height: height,
            width: width,
            margin: { t: marginTop, b: 0, r: 0, l: 0 },
            yaxis: {
              ...axisProps,
              range: [-RINK_HALF_WIDTH, RINK_HALF_WIDTH],
            },
            xaxis: {
              ...axisProps,
              range: [-RINK_HALF_LENGTH, RINK_HALF_LENGTH],
            },
            images: images,
          }}
          onClick={(data) => {
            const event = data.points[0].customdata[1];
            const eventSelected = selectedEvents.find(
              (se) => se.id == event.id
            );

            if (eventSelected) {
              setSelectedEvents(
                selectedEvents.filter((se) => se.id !== event.id)
              );
            } else {
              setSelectedEvents([...selectedEvents, event]);
            }
          }}
          onSelected={(data) => {
            const events = data.points.map((p) => p.customdata[1]);
            setSelectedEvents(events);
          }}
        />
      </Row>
    </Container>
  );
};
