import { useQuery } from "@apollo/client"
import { GET_BF_DRAFT_CAPITAL_FOR_TEAM } from "apollo/queries/bruinsfriendly.queries"
import { GET_CF_DRAFT_CAPITAL_FOR_TEAM } from "apollo/queries/capfriendly.queries"
import usePlaceholder from "components/Placeholder/usePlaceholder"


export default function useBFDraftCapital({
  teamSlug,
  bfTeamSlug,
  years,
  skip
}) {
  const { data, placeholder } = usePlaceholder(useQuery(GET_BF_DRAFT_CAPITAL_FOR_TEAM, {
    variables: {
      teamSlug,
      bfTeamSlug,
      years
    },
    skip
  }))
  
  return {
    data,
    picks: data?.bfDraftCapitalForTeam?.picks,
    bfTeam: data?.bfDraftCapitalForTeam?.bfTeam,
    placeholder
  }
}