import { useQuery } from "@apollo/client";
import { GET_PLAYER_SKATING_EVAL } from "apollo/queries/questionnaire.queries";
import { Column, Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { HighlightBox } from "components/bdd/warcomponents";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { roundToX } from "helpers/data";
import { dateToTimeAgo } from "helpers/helpers";
import { getRedGreenColor } from "helpers/tables";


const OTHER_KEYS = [
  "RinkNet ID",
  "Date Completed",
  "Skater Name",
  "Player Stride Comp",
]

const SECTIONS = [
  "Overall Average",
  "Agility/Change of Direction",
  "Backwards Thrusting/Straight Backward Movement",
  "Balance & Agility",
  "Crossovers",
  "Explosiveness/Speed Capabilities",
  "Lateral Movement/Pivoting",
  "Stride/Forward Glide Motion",
]

export default function SkatingEval({ rinknetId, year }) {

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_SKATING_EVAL, {
      variables: {
        rinknetId, year
      }
    })
  );

  const skatingEval = { ...data?.getPlayerSkatingEval };

  // remove weird unicode parse errors
  Object.keys(skatingEval).forEach(k => {
    skatingEval[k.replace('�', ' ')] = skatingEval[k];
  })

  const getGrade = (section, key) => {
    // key should be "Upside" or "Current"
    try {
      return roundToX(parseFloat(skatingEval?.[`${section} (${key})`]), 2);
    } catch { 
      return null 
    }
  }
  const getColor = (section, key) => {
    const grade = getGrade(section, key);
    if (!grade) return null
    return getRedGreenColor(grade, 0, 5);
  }

  if (!!data && Object.keys(skatingEval).length === 0) {
    return <Typography variant='body1'>
      <em>
        No skating evaluation found
      </em>
    </Typography>
  }
  return <Container>
    {placeholder ? placeholder
    : <Container>
      <Row columnGap={16}>
        <Typography variant='body1'>
          Completed {dateToTimeAgo(skatingEval['Completion time'])}
        </Typography>
        <Container>
          <Row columnGap={4}>
            <Typography variant='body1'>
              Player stride comp:
            </Typography>
            <Typography variant='body2'>
              {skatingEval['Player Stride Comp']}
            </Typography>
          </Row>
        </Container>
      </Row>
      <Row flexWrap justifyContent='space-between'>
        {SECTIONS.map(s => {
          const notesKey = s === 'Overall Average' ? 'Overall Thoughts' : s;
          return <Container key={s} width={300}>
            <Typography variant='h6' textAlign='center'>
              {s}
            </Typography>
            <Row columnGap={8} justifyContent='center'>
              <Container>
                <Column>
                  <Typography variant='body1'>
                    Current
                  </Typography>
                  <HighlightBox color={getColor(s, 'Current')} >
                    <Typography variant='body1'>
                      {getGrade(s, 'Current')}
                    </Typography>
                  </HighlightBox>
                </Column>
              </Container>
              <Container>
                <Column>
                  <Typography variant='body1'>
                    Upside
                  </Typography>
                  <HighlightBox color={getColor(s, 'Upside')} >
                    <Typography variant='body1'>
                      {getGrade(s, 'Upside')}
                    </Typography>
                  </HighlightBox>
                </Column>
              </Container>
            </Row>
            <Typography variant='body2'>
              <ul>
                {skatingEval[notesKey]?.split("*")?.slice(1)?.map((bull, i) => <li key={i}>{bull}</li>)}
              </ul>
            </Typography>
          </Container>
        })}
      </Row>
    </Container>}
  </Container>
}