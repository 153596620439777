import { Editor, Transforms, Element as SlateElement, Range } from 'slate';

export const isMentionActive = (editor) => {
  const [mention] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'mention',
  });
  return !!mention;
};

export const wrapMention = (editor) => {
  if (isMentionActive(editor)) {
    unwrapMention(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const mention = {
    type: 'mention',
    children: isCollapsed ? [{ text: '', bold: true }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [mention, { text: ' ' }]);
  } else {
    Transforms.wrapNodes(editor, mention, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapMention = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'mention',
  });
};

export const insertMention = (editor) => {
  if (editor.selection) {
    wrapMention(editor);
  }
};

export const getMentionEntities = (text) => {
  if (!text) return null;

  let mentions = [];

  const traverseChildren = (children) => {
    children.forEach((child) => {
      if (child.type === 'mention' && child.entityId) {
        mentions.push({
          entityId: child.entityId,
          entityType: child.entityType,
          entityLabel: child.entityLabel,
        });
      } else if (Array.isArray(child.children)) {
        traverseChildren(child.children);
      }
    });
  };

  traverseChildren(text);

  return mentions;
};
