export const withPlaylists = (editor) => {
  const { isInline, insertText, isElementReadOnly, isSelectable, isVoid, markableVoid } =
    editor;

  editor.isInline = (element) => {
    return element.type == 'playlist' || isInline(element);
  };

  editor.isElementReadOnly = (element) => {
    return element.type === 'playlist' || isElementReadOnly(element);
  };

  editor.isSelectable = (element) => element.type !== 'playlist' && isSelectable(element);
  editor.insertText = (text) => insertText(text);

  return editor;
};
