import { ModalContext, useModal } from "components/bdd/Modal"
import { useContext } from "react";
import { CreateTransactionModal } from "./CreateTransactionModal";
import { Typography } from "components/bdd/Typography";
import { Container } from "components/bdd/Layout";


export const useCreateTransactionModal = ({}) => {
  const { modal, renderModal, hideModal } = useContext(ModalContext);

  const handleRenderModal = ({
    proObjectiveSlug, 
    defaultTab='trade', 
    tradeFormProps,
    onCompleted,
    canSign=true,
    canTrade=true,
    defaultSigningBDDPlayerSlug
  }) => {

    renderModal({
      title: <Typography variant='h5'>Create Transaction</Typography>,
      body: (
        <Container minHeight={300} padding={4}>
          <CreateTransactionModal 
            proObjectiveSlug={proObjectiveSlug}
            defaultTab={defaultTab}
            tradeFormProps={tradeFormProps}
            onCompleted={(tx) => {
              !!onCompleted && onCompleted(tx);
              hideModal();
            }}
            canSign={canSign}
            canTrade={canTrade}
            defaultSigningBDDPlayerSlug={defaultSigningBDDPlayerSlug}
          />
        </Container>
      )
    });

  }

  return {
    modal,
    renderModal: handleRenderModal,
    hideModal
  }
}