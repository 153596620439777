import { gql } from '@apollo/client';

export const GET_FILE_UPLOAD_URL = gql`
  query GetFileUploadUrl(
    $destinationUrl: String!
    $folder: String!
    $contentType: String
  ) {
    fileUploadUrl(
      destinationUrl: $destinationUrl
      folder: $folder
      contentType: $contentType
    ) {
      response
      s3Key
      s3Url
    }
  }
`;
