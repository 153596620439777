import React, { useState, useCallback, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';

import { debounce } from '../../helpers/throttle_debounce';
import BDDSelect from '../bdd/bddselect';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';

const DEBOUNCE_MS = 300;

const DEFAULT_QUERY = gql`
  query SearchPlayers(
    $term: String!
    $n: Int
    $minScore: Float
    $resultTypes: [SearchResultTypes]
  ) {
    search(term: $term, n: $n, minScore: $minScore, resultTypes: $resultTypes) {
      score
      result {
        __typename
        ... on RinknetPlayer {
          id
          rFirstname: firstname
          rLastname: lastname
          posFDG
          currentteam
          currentteamnhlid
        }
        ... on BDDPlayerSlug {
          slug
          firstname
          lastname
          slUid
          rinknetId
          rinknetPlayer {
            id
            currentteam
            currentteamnhlid
            posFDG
          }
        }
      }
    }
  }
`;

const defaultResponseToResults = (data) =>
  data.search.map((s) => {
    if (s.result.__typename === 'RinknetPlayer') {
      return {
        label: `${s.result.rFirstname} ${s.result.rLastname}`,
        value: s.result.id,
        teamid: s.result.currentteamnhlid,
        subLabel: s.result.currentteam,
        type: 'player',
      };
    } else if (s.result.__typename === 'BDDPlayerSlug') {
      return {
        label: `${s.result.firstname} ${s.result.lastname}`,
        value: s.result.slug,
        teamid: s.result.rinknetPlayer.currentteamnhlid,
        subLabel: s.result.rinknetPlayer.currentteam,
        type: 'player',
      };
    }
  });

export default function PlayerSearch({
  searchSource = 'bdd',
  handleSelect,
  gqlQuery, // if supplied, will use this query instead of the default
  responseToResults = defaultResponseToResults, // for a custom query, need to specify accessing results
  variables = {},
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(null); // holds id(s) of selected
  // const [selectedOptions, setSelectedOptions] = useState(null) // holds options for selected
  const [latestResults, setLatestResults] = useState([]);
  var options = latestResults; // might have to add "selected" to options
  const resultTypes =
    searchSource === 'bdd'
      ? ['BDDPLAYERS']
      : searchSource === 'rinknet'
      ? ['RINKNETPLAYERS']
      : null;
  if (!resultTypes) throw Error(`Unknown searchSource ${searchSource}`);

  // If a custom query is supplied...
  var query;
  if (gqlQuery) {
    query = gqlQuery;
  } else {
    query = DEFAULT_QUERY;
    variables = {
      minScore: 0.25,
      resultTypes: resultTypes,
    };
  }

  const [getSearchResults, { data, error, loading }] = useLazyQuery(query);

  const logAndQuery = (t) => {
    getSearchResults(t);
  };
  const debouncedGetSearchResults = useCallback(debounce(logAndQuery, DEBOUNCE_MS), []);

  const handleChange = (_, val) => {
    handleSelect(val);
    setSelected(val);
  };

  const handleInputChange = (t, action) => {
    setSearchTerm(t);
    if (t.length) {
      debouncedGetSearchResults({
        variables: {
          ...variables,
          term: t,
        },
      });
    }
  };

  useEffect(() => {
    if (!loading && !!data) {
      setLatestResults(responseToResults(data));
    }
  }, [loading, data]);

  return (
    <StyledBDDSelect
      // defaultOnChange
      {...props}
      name="search"
      selectProps={{
        ...props,
        placeholder: 'Search for players',
        isSearchable: true,
        isLoading: loading || (searchTerm.length && !latestResults.length),
        filterOption: ({ label, value, data }) => true,
        onInputChange: handleInputChange,
        noOptionsMessage: (input) => (
          <Typography variant="body1">
            {input.inputValue.length ? 'No results found' : 'Search for a player'}
          </Typography>
        ),
      }}
      options={options}
      selectedValue={selected}
      onChange={handleChange}
    />
  );
}
