import styled from 'styled-components';
import { theme } from 'constants';

export const setSpacing = (value) =>
  value ? (value == 'auto' ? 'auto' : theme.spacing[value]) : value == 0 ? 0 : null;

export const Container = styled.div(
  ({
    backgroundColor,
    hoverBackgroundColor,
    position,
    width,
    minWidth,
    maxWidth,
    height,
    maxHeight,
    minHeight,
    marginTop,
    marginLeft,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    overflow,
    overflowX,
    overflowY,
    flexGrow,
    flexBasis,
    flexShrink,
    alignSelf,
    variant,
    hoverStyles={}
  }) => ({
    position: position || 'relative',
    backgroundColor: backgroundColor || null,
    width: width,
    minWidth: minWidth,
    maxWidth: maxWidth,
    height: height,
    maxHeight: maxHeight,
    minHeight: minHeight,
    overflowX: overflowX,
    overflowY: overflowY,
    overflow: overflow,
    marginTop: setSpacing(marginTop),
    marginLeft: setSpacing(marginLeft),
    padding: setSpacing(padding),
    paddingTop: setSpacing(paddingTop),
    paddingBottom: setSpacing(paddingBottom),
    paddingLeft: setSpacing(paddingLeft),
    paddingRight: setSpacing(paddingRight),
    flexGrow: flexGrow ? 1 : null,
    flexBasis: flexBasis,
    flexShrink: flexShrink ? 1 : null,
    alignSelf: alignSelf,
    ...(variant == 'button'
      ? {
          cursor: 'pointer',
          userSelect: 'none',
        }
      : {}),
    '&:hover': {
      backgroundColor: hoverBackgroundColor || null,
      ...hoverStyles
    },
  })
);
