import { toastBddApiError } from "components/bdd/bddtoasts";
import { useBDDTradeForm } from "../BDDTrades/useBDDTradeForm";
import { useBFTradeMutations } from "./useBFTradeMutations";
import { tradeAssetsToPicks, tradeAssetsToPlayers } from "../BDDTrades/helpers";
import { useState } from "react";
import BDDDatePicker from "components/bdd/bdddatepicker";
import { formatDateGQL, parseDateNoTimezone } from "helpers/helpers";


export const useCreateUpdateBFTrade = ({
  isUpdate,
  tradeId,
  defaultTeams, // ['nhl.bos', 'nhl.ana']
  defaultAssets, // [{ fromTeamSlug, toTeamSlug, assetType, assetId | bfSlug }]
  defaultDate,
  transactionId, // if creating this through a transaction, can link to it to mark it as completed
  onSubmit
}) => {
  const [date, setDate] = useState(!!defaultDate ? parseDateNoTimezone(defaultDate) : new Date());
  const datePicker = (
    <BDDDatePicker
      placeholderText="Select trade date"
      value={date} 
      selected={date}
      onChange={(d) => setDate(d)}
    />
  )

  const { createBFTrade, createLoading, updateBFTrade, updateLoading } = useBFTradeMutations({
    onCreate: (bfTrade) => !!onSubmit && onSubmit(bfTrade),
    onUpdate: (bfTrade) => !!onSubmit && onSubmit(bfTrade),
  });

  const handleSubmit = (teams, assets) => {
    if (isUpdate) {
      if (!tradeId) {
        throw new Error('Must supply tradeId to update');
      }
      updateBFTrade({
        variables: {
          id: tradeId,
          trade: {
            tradedPlayers: tradeAssetsToPlayers(assets),
            tradedPicks: tradeAssetsToPicks(assets),
          },
          date: formatDateGQL(date),
        }
      })
    } else {
      createBFTrade({
        variables: {
          trade: {
            tradedPlayers: tradeAssetsToPlayers(assets),
            tradedPicks: tradeAssetsToPicks(assets),
          },
          date: formatDateGQL(date),
          transactionId
        }
      })
    }
  }

  const { form, submitButton, teams, setTeams, assets, setAssets } = useBDDTradeForm({
    handleSubmit,
    defaultTeams,
    defaultAssets,
    submitting: createLoading || updateLoading
  });

  return {
    form,
    datePicker,
    submitButton,
    date,
    setDate,
    teams,
    setTeams,
    assets,
    setAssets
  }
}