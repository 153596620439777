import { useQuery } from '@apollo/client';
import { GET_BF_PLAYER_AND_CONTRACTS } from 'apollo/queries/bruinsfriendly.queries';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { IRIcon, LTIRIcon, WaiverExemptIcon } from './BFBadges';
import BFPlayerContractBreakdown from './BFPlayerContractBreakdown';

const statusToIcon = {
  LongTermInjuredReserve: <LTIRIcon size="20px" />,
  InjuredReserve: <IRIcon size="20px" />,
};

export const renderBFStatus = (status, abbreviate = false) => {
  if (!status) return null;
  if (status === 'Nhl') return 'NHL';
  if (abbreviate) {
    var str = status
      .split(/(?=[A-Z])/)
      .map((s) => s[0])
      .join('');
  } else {
    str = status.split(/(?=[A-Z])/).join(' ');
  }
  return (
    <Row gap={2}>
      {statusToIcon[status]}
      <div>{str}</div>
    </Row>
  );
};

export default function BFPlayerBreakdown({ bfPlayerSlug }) {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_PLAYER_AND_CONTRACTS, {
      variables: {
        bfPlayerSlug,
      },
    })
  );

  const bfPlayer = data?.bfPlayer;
  const allContracts = data?.bfPlayer?.contracts;
  const pastContracts = allContracts?.filter((c) => !c.isActive && !c.isFuture);
  const activeContract = allContracts?.find((c) => c.isActive);
  const futureContract = allContracts?.find((c) => c.isFuture);

  if (placeholder) return placeholder;

  return (
    <Container>
      <Column gap={4}>
        <Container>
          <Typography variant="subtitle1">Active Contract</Typography>
          <BFPlayerContractBreakdown bfPlayer={bfPlayer} contract={activeContract} />
        </Container>

        {!!futureContract && (
          <Container>
            <Typography variant="subtitle1">Future Contract</Typography>
            <BFPlayerContractBreakdown bfPlayer={bfPlayer} contract={futureContract} />
          </Container>
        )}
        <Container>
          <Typography variant="subtitle1">Contract History</Typography>
          <Column gap={4}>
            {pastContracts.map((c) => (
              <BFPlayerContractBreakdown bfPlayer={bfPlayer} key={c.id} contract={c} />
            ))}
            {pastContracts.length === 0 && (
              <Typography variant="body2" textAlign="center">
                No past contracts
              </Typography>
            )}
          </Column>
        </Container>
      </Column>
    </Container>
  );
}
