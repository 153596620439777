import { validate } from 'graphql'
import React, { useEffect, useState } from 'react'

export default function BDDEditInline({
    // value,
    name,
    defaultValue,
    trackValueChanges=false, // if true, will update value if default value changes
    options,
    type='string',
    activeClassName,
    staticClassName,
    // type='string',
    minLength=1,
    maxLength=null,
    validate,
    onChange, // called with (name, ret)
    editingElement='input',
    staticElement='span',
    allowEdit=true,
    editingProps={},
    addQuotes=false,
    emptyValue='none'
}) {
    // If default is undefined, or an empty string, replace with emptyValue (default="none")
    const defaultVal = typeof(defaultValue) === 'undefined' || 
        (typeof(defaultValue) === 'string' && !defaultValue.length) 
          ? emptyValue 
          : defaultValue;
    const [value, setValue] = useState(defaultVal)
    const [valOnEdit, setValOnEdit] = useState(defaultValue)
    const [editing, setEditing] = useState(false)

    if (trackValueChanges) {
        useEffect(() => {
            if (value != defaultVal) {
                setValue(defaultVal)
            }
        }, [defaultVal])
    }

    if (type === 'boolean' && !options) {
        options = [true, false]
        editingElement = 'select'
    }

    const handleChange = ev => {
        setValue(ev.target.value)
    }

    const handleValidate = val => {
        if (editingElement === 'input') {
            if (!!minLength && val.length < minLength) return false
            if (!!maxLength && val.length > maxLength) return false
        }
        if (validate) return validate(val)
        return true
    }

    const handleBlur = ev => {
        setEditing(false)
        if (value === valOnEdit) {
            return
        }
        if (handleValidate(ev.target.value)) {
            const ret = type === 'boolean' ? ev.target.value === 'true' 
                : type === 'number' ? Number(ev.target.value)
                : ev.target.value
            onChange(name, ret)
        } else {
            setValue(valOnEdit)
        }
    }

    const handleKeyPress = ev => {
        if (ev.keyCode === 13) {
          ev.target.blur(); 
        }
     }

    if (editingElement === 'select') {
        if (!options || !options.length) throw Error('Prop "options" required when editingElement==select')
        else if (typeof options[0] !== 'object' && options[0] != null) {
            var editingChildren = <>{options.map(o => <option key={o} value={o}>{`${o}`}</option>)}</>
        } else {
            var editingChildren = <>{options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}</>
        }
    } else {
        var editingChildren = null
    }

    if (editing) return React.createElement(editingElement, {
            autoFocus: true, 
            type: type === 'number' ? 'number' : undefined,
            className: activeClassName, 
            value: value, 
            onChange: handleChange,
            onKeyDown: ev => handleKeyPress(ev),
            onBlur: handleBlur,
            ...editingProps
        }, editingChildren)

    const handleStaticClick = ev => {
        if (allowEdit) {
            setEditing(true)
            setValOnEdit(value)
        }
    }

    return React.createElement(staticElement, { 
        className: staticClassName,
        title: 'Click to edit',
        onClick: handleStaticClick }, addQuotes ? <>{`"${value}"`}</> : <>{`${value}`}</>)
}