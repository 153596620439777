import styled from 'styled-components';
import { theme } from 'constants/theme';
import BDDErrorBoundary from '../bdderrorboundary';
import { Container } from '.';

export const Grid = styled.div(
  ({
    gridTemplateColumns,
    gridTemplateAreas,
    columnGap,
    rowGap,
    alignItems,
    showGridLines,
  }) => ({
    display: 'grid',
    gridTemplateColumns,
    gridTemplateAreas,
    width: '100%',
    columnGap: columnGap ? theme.spacing[columnGap] : 0,
    rowGap: rowGap ? theme.spacing[rowGap] : 0,
    alignItems,
    ...(showGridLines
      ? {
          columnGap: 1,
          rowGap: 1,
          backgroundColor: theme.colors.light.border,
        }
      : {}),
  })
);

export const GridAreaContainer = styled(Container)(({ area, showGridLines }) => ({
  gridArea: area,
  minWidth: 0,
  ...(showGridLines ? { backgroundColor: theme.colors.light.background } : {}),
}));

export const GridArea = ({ area, showGridLines, children, ...rest }) => (
  <BDDErrorBoundary>
    <GridAreaContainer area={area} showGridLines={showGridLines} {...rest}>
      {children}
    </GridAreaContainer>
  </BDDErrorBoundary>
);
