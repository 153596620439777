import { theme } from 'constants/theme';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { useFilterContext } from './context';

export const FilterTag = ({ name, label }) => {
  return (
    <Container key={name} padding={1} backgroundColor={theme.colors.light.secondary}>
      <Row columnGap={1}>
        <Typography variant="label">{name}:</Typography>
        <Typography variant="stat">{label}</Typography>
      </Row>
    </Container>
  );
};
