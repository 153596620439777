import { gql } from "@apollo/client";

export const QuestionnaireFragment = gql`fragment questionnaireFragment on PlayerQuestionnaire {
  id
  name
  year
  data
  requiresLinking
}`;

export const QuestionnaireLinkFragment = gql`fragment questionnaireLinkFragment on QuestionnaireLink {
  year
  rinknetId
  questionnaireId
  questionnaireIdColumn
}`;

export const GET_QUESTIONNAIRE = gql`query GetQuestionnaire($rinknetId: ID!, $year: Int) {
  playerQuestionnaire(rinknetId: $rinknetId, year: $year) {
    ...questionnaireFragment
  } 
} ${QuestionnaireFragment}`;

export const GET_QUESTIONNAIRES = gql`query GetQuestionnaires($rinknetId: ID!) {
  playerQuestionnaires(rinknetId: $rinknetId) {
    ...questionnaireFragment
  } 
} ${QuestionnaireFragment}`;

export const GET_PLAYER_QUESTIONNAIRE_LINKS = gql`query GetPlayerQuestionnaireLinks($rinknetIds: [ID!], $year: Int) {
  getPlayerQuestionnaireLinks(rinknetIds: $rinknetIds, year: $year) {
    ...questionnaireLinkFragment
  }
} ${QuestionnaireLinkFragment}`


export const GET_POTENTIAL_QUESTIONNAIRE_LINKS = gql`query GetPotentialQuestionnaireLinks($rinknetId: ID!, $year: Int) {
  potentialQuestionnaireLinks(rinknetId: $rinknetId, year: $year) {
    questionnaire {
      ...questionnaireFragment
    }
    rinknetName
    similarity
  } 
} ${QuestionnaireFragment}`;


export const CREATE_OR_UPDATE_QUESTIONNAIRE_LINK = gql`
  mutation CreateOrUpdateQuestionnaireLink(
    $rinknetId: ID!, 
    $year: Int!, 
    $questionnaireId: ID!, 
    $questionnaireIdColumn: String
  ) {
    createOrUpdateQuestionnaireLink(
      input: {
        rinknetId: $rinknetId,
        year: $year,
        questionnaireId: $questionnaireId,
        questionnaireIdColumn: $questionnaireIdColumn
      }
    ) {
      questionnaireLink {
        ...questionnaireLinkFragment
      }
    }
  } ${QuestionnaireLinkFragment}`;

export const DELETE_QUESTIONNAIRE_LINK = gql`
  mutation DeleteQuestionnaireLink(
    $rinknetId: ID!
  ) {
    deleteQuestionnaireLink(
      rinknetId: $rinknetId,
    ) {
      rinknetId
    }
  }`;


export const GET_PLAYERS_WITH_SKATING_EVALS = gql`
  query GetPlayersWithSkatingEvals(
    $rinknetIds: [ID]
    $year: Int
  ) {
    getPlayersWithSkatingEvals(
      year: $year
      rinknetIds: $rinknetIds
    )
  }
`


export const GET_PLAYER_SKATING_EVAL = gql`
  query GetPlayerSkatingEval(
    $rinknetId: ID!
    $year: Int
  ) {
    getPlayerSkatingEval(
      rinknetId: $rinknetId
      year: $year
    )
  }
`