import { gql } from '@apollo/client';

export const PlayerListFragment = gql`
  fragment playerListFragment on PlayerLists {
    id
    parentListId
    name
    description
    isMyList
    isActive
    isPublic
    isPublicEdit
    isCheckpoint
    isWatched
    isEditable
    pinPriority
    draftListYear
    isPrimaryDraftList
    idType
    listType
    length
    tierLength
    leagues
    lastModified
    lastModifiedUser {
      id
      username
      name
    }
    user {
      id
      username
      name
    }
    accessList {
      userId
      user {
        id
        username
        name
      }
      canEdit
      canView
    }
    dateCreated
    extras
  }
`;
export const PlayerListPlayersFragment = gql`
  fragment playerListPlayersFragment on PlayerListPlayers {
    id
    listId
    listNumber
    idType
    playerId
    firstname
    lastname
  }
`;

const PlayerListPlayersWithSlugsFragment = gql`
  fragment playerListPlayersWithSlugsFragment on PlayerListPlayers {
    id
    listId
    listNumber
    idType
    playerId
    firstname
    lastname
    rinknetPlayer {
      id
      firstname
      lastname
      position
      bddPlayerSlug {
        slug
      }
    }
  }
`;

const PlayerListTierFragment = gql`
  fragment playerListTierFragment on PlayerListTiers {
    id
    title
    description
    startsWithListNumber
    endsWithListNumber
  }
`;

export const buildPlayerListQuery = (playerFields, includeEPStats) => {
  const q = `query GetPlayerList($id: ID!, $loadBddNotes: Boolean, $loadReports: Boolean) {
    playerList(id: $id, loadBddNotes: $loadBddNotes, loadReports: $loadReports) {
        ...playerListFragment
        players {
            ...playerListPlayersFragment
            ${!!playerFields ? playerFields : ''}
            notes {
                id
                note
                requiresFollowUp
                user { id username }
            }
        }
        tiers {
            ...playerListTierFragment
        }
        tierOrderedPlayers {
            tier {
              id
              title
            }
            players {
              listNumber
              firstname
              lastname
              playerId
            }
        }
        checkpoints {
            id
            name
            description
            dateCreated
        }
        accessList {
            userId
            user { id username }
            canEdit
            canView
        }
        ${!!includeEPStats ? 'epStatsJson' : ''}
    }
  }`;

  return gql`
    ${q}
    ${PlayerListFragment}
    ${PlayerListPlayersFragment}
    ${PlayerListTierFragment}
  `;
};

export const buildPlayersFromListQuery = (playerFields) => gql`
  query GetPlayerFromLists($listIds: [ID!]) {
    playerLists(listIds: $listIds, loadPlayers: true) {
      id
      idType
      listType
      leagues
      name
      user {
        id
        username
        name
      }
      tierOrderedPlayers {
        tier {
          id
          title
        }
        players {
          listNumber
          firstname
          lastname
          playerId
        }
      }
      description
      players {
        ...playerListPlayersFragment
        ${playerFields || ''}
      }
    }
  }
  ${PlayerListPlayersFragment}
`;

export const GET_PLAYER_LIST = buildPlayerListQuery();
// gql`query GetPlayerList($id: ID!) {
//     playerList(id: $id) {
//         ...playerListFragment
//         tierOrderedPlayers {
//             id
//             tier {
//                 id
//             }
//             players {
//                 id
//                 playerId
//             }
//         }
//         players {
//             ...playerListPlayersFragment
//         }
//         tiers {
//             ...playerListTierFragment
//         }
//     }
// } ${PlayerListFragment} ${PlayerListPlayersFragment} ${PlayerListTierFragment}`

export const GET_PLAYER_LISTS = gql`
  query GetPlayerLists(
    $listTypes: [PlayerListTypes!]
    $includePublic: Boolean
    $includeArchived: Boolean
    $includeAllLists: Boolean
    $pinsOnly: Boolean
    $searchTerm: String
    $listIds: [ID!]
    $idType: String
    $includeCheckpoints: Boolean
  ) {
    playerLists(
      listTypes: $listTypes
      includePublic: $includePublic
      includeArchived: $includeArchived
      includeAllLists: $includeAllLists
      pinsOnly: $pinsOnly
      searchTerm: $searchTerm
      listIds: $listIds
      idType: $idType
      includeCheckpoints: $includeCheckpoints
    ) {
      ...playerListFragment
    }
  }
  ${PlayerListFragment}
`;

export const GET_PLAYER_LIST_POSITIONS = gql`
  query GetPlayerListPositions(
    $slugs: [ID!]
    $onlyRinknetLists: Boolean
    $rinknetLists: [ID!]
    $bddLists: [ID!]
    $includeCheckpoints: Boolean
  ) {
    playerListPositions(
      slugs: $slugs
      onlyRinknetLists: $onlyRinknetLists
      rinknetLists: $rinknetLists
      bddLists: $bddLists
      includeCheckpoints: $includeCheckpoints
    ) {
      listPositions {
        rinknetId
        bddPlayerSlug
        listId
        listNumber
      }
      lists {
        ... on PlayerLists {
          id
          name
          description
          length
          parentListId
          isCheckpoint
        }

        ... on RinknetDraftLists {
          id
          description
          name
          scoutFirstName
          scoutLastName
          length
        }
      }
    }
  }
`;

export const GET_PLAYER_WATCH_LIST_POSITIONS = gql`
  query GetPlayerWatchListPositions($slugs: [ID!]) {
    playerWatchListPositions(slugs: $slugs) {
      listPositions {
        rinknetId
        bddPlayerSlug
        listId
        listNumber
      }
      lists {
        ... on PlayerLists {
          id
          name
          description
          length
          parentListId
          isCheckpoint
        }
      }
    }
  }
`;

export const GET_PLAYER_DEVELOPMENT_LIST = gql`
  query GetPlayerDevelopmentList {
    playerDevelopmentList {
      id
    }
  }
`;

export const buildPlayerDraftListQuery = (playerFields) => gql`
  query GetPlayerDraftList($year: Int!) {
    playerDraftList(year: $year) {
      id
      idType
      listType
      leagues
      name
      user {
        id
        username
        name
      }
      description
      players {
        ...playerListPlayersFragment
        ${playerFields || ''}
      }
    }
  }
${PlayerListPlayersFragment}`;

export const GET_PLAYER_LISTS_W_PLAYERS = gql`
  query GetPlayerListsWithPlayers(
    $listTypes: [PlayerListTypes!]
    $includePublic: Boolean
    $listIds: [ID!]
    $idType: String
  ) {
    playerLists(
      listTypes: $listTypes
      includePublic: $includePublic
      listIds: $listIds
      idType: $idType
      loadPlayers: true
    ) {
      ...playerListFragment
      tiers {
        ...playerListTierFragment
      }
      players {
        ...playerListPlayersFragment
      }
    }
  }
  ${PlayerListFragment}
  ${PlayerListPlayersFragment}
  ${PlayerListTierFragment}
`;

export const GET_PLAYER_LISTS_W_PLAYERS_W_SLUGS = gql`
  query GetPlayerListsWithPlayers(
    $listTypes: [PlayerListTypes!]
    $includePublic: Boolean
    $listIds: [ID!]
    $idType: String
  ) {
    playerLists(
      listTypes: $listTypes
      includePublic: $includePublic
      listIds: $listIds
      idType: $idType
      loadPlayers: true
    ) {
      ...playerListFragment
      tiers {
        ...playerListTierFragment
      }
      players {
        ...playerListPlayersWithSlugsFragment
      }
    }
  }
  ${PlayerListFragment}
  ${PlayerListPlayersWithSlugsFragment}
  ${PlayerListTierFragment}
`;

export const UPDATE_PLAYER_LIST = gql`
  mutation UpdatePlayerList(
    $input: UpdatePlayerListInput!
    $playersToAdd: [PlayerListInsertion!]
    $playersToRemove: [ID!]
    $playersToReorder: [PlayerListReorder!]
    $playersToInsertFromForeign: [PlayerListInsertFromForeign!]
    $tiersToAdd: [CreatePlayerListTier!]
    $tiersToUpdate: [UpdatePlayerListTier!]
    $tiersToRemove: [ID!]
    $playerNotesToAdd: [CreatePlayerNote!]
    $playerNotesToRemove: [ID!]
    $checkpointToAdd: CreateCheckpointInput
    $checkpointsToRemove: [ID!]
    $revertToCheckpoint: ID
    $listToDuplicate: ID
    $setUserAccessList: [SetUserAccessListInput!]
    $addWatch: Boolean
    $removeWatch: Boolean
  ) {
    updatePlayerList(
      input: $input
      playersToAdd: $playersToAdd
      playersToRemove: $playersToRemove
      playersToReorder: $playersToReorder
      playersToInsertFromForeign: $playersToInsertFromForeign
      tiersToAdd: $tiersToAdd
      tiersToUpdate: $tiersToUpdate
      tiersToRemove: $tiersToRemove
      playerNotesToAdd: $playerNotesToAdd
      playerNotesToRemove: $playerNotesToRemove
      checkpointToAdd: $checkpointToAdd
      checkpointsToRemove: $checkpointsToRemove
      revertToCheckpoint: $revertToCheckpoint
      listToDuplicate: $listToDuplicate
      setUserAccessList: $setUserAccessList
      addWatch: $addWatch
      removeWatch: $removeWatch
    ) {
      playerList {
        ...playerListFragment
        tiers {
          ...playerListTierFragment
        }
        players {
          ...playerListPlayersFragment
          notes {
            id
            note
            requiresFollowUp
            user {
              id
              username
            }
          }
        }
        checkpoints {
          id
          name
          description
          dateCreated
        }
        accessList {
          userId
          user {
            id
            username
          }
          canEdit
          canView
        }
      }
    }
  }
  ${PlayerListFragment}
  ${PlayerListPlayersFragment}
  ${PlayerListTierFragment}
`;

export const CREATE_PLAYER_LIST = gql`
  mutation CreatePlayerList(
    $input: CreatePlayerListInput!
    $overrideExistingList: Boolean
  ) {
    createPlayerList(input: $input, overrideExistingList: $overrideExistingList) {
      playerList {
        ...playerListFragment
      }
    }
  }
  ${PlayerListFragment}
`;

export const DELETE_PLAYER_LIST = gql`
  mutation DeletePlayerList($id: ID!) {
    deletePlayerList(id: $id) {
      id
    }
  }
`;

export const GET_PLAYER_LIST_W_EP_DATA = gql`
  query PlayerListWithEPData($listId: ID!) {
    playerListEpData(listId: $listId) {
      stats
      rankings
    }
  }
`;

export const GET_EP_DATA = gql`
  query GetEPData($rinknetIds: [ID]!) {
    epData(rinknetIds: $rinknetIds) {
      stats
      rankings
    }
  }
`;

export const UPDATE_PLAYER_LIST_NOTE = gql`
  mutation UpdatePlayerListNote($input: UpdatePlayerListNoteInput!) {
    updatePlayerListNote(input: $input) {
      playerListNote {
        id
        note
        requiresFollowUp
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_PLAYER_LIST_CHANGES = gql`
  query PlayerListChanges($id: ID!) {
    playerList(id: $id) {
      id
      changes {
        id
        dateCreated
        changeType
        payload
        user {
          username
        }
      }
    }
  }
`;

export const QUERY_USERS_AND_PLAYER_LIST = gql`
  query UsersAndPlayerList($id: ID!) {
    playerList(id: $id) {
      id
      name
      isMyList
      isPublic
      isPublicEdit
      accessList {
        userId
        user {
          id
          username
        }
        canEdit
        canView
      }
    }
    users {
      id
      username
    }
  }
`;

export const UPDATE_PLAYER_LIST_WATCH = gql`
  mutation UpdatePlayerListWatch($id: ID!) {
    updatePlayerListWatch(id: $id) {
      listWatch {
        listId
        userId
        lastViewed
      }
    }
  }
`;

export const buildRinknetDraftListQuery = ({ includePlayerData }) => gql`
  query GetRinknetDraftLists($searchTerm: String, $listIds: [ID!]) {
    rinknetDraftLists(searchTerm: $searchTerm, listIds: $listIds, loadPlayerData: ${
      includePlayerData ? 'true' : 'false'
    }) {
      id
      description
      date
      scoutFirstName
      scoutLastName
      nhlSeason
      ${
        !includePlayerData
          ? ''
          : `
          length
          leagues {
            name
            shorthand
            count
          }
          players {
          ranking
          firstName
          lastName
          rinknetPlayer {
            id
            realId
            firstname
            lastname
            birthdate
            age
            currentteam
            currentleagueabbr
            position
            heightStr
            weight
            height
            heightFt
            posFDG
            eliteprospectsid
            handedness
            agentname
            collegecommitmentteam
            agent {
              id
              title
              firstname
              lastname
              companyname
              email
              phone
              cellphone
            }
          }
        }`
      }
    }
  }
`;

const RinknetListFragment = gql`
  fragment rinknetListFragment on RinknetDraftLists {
    id
    description
    date
    scoutFirstName
    scoutLastName
    length
    nhlSeason
  }
`;

export const GET_RINKNET_DRAFT_LISTS = gql`
  query GetRinknetDraftLists($searchTerm: String) {
    rinknetDraftLists(searchTerm: $searchTerm) {
      ...rinknetListFragment
    }
  }
  ${RinknetListFragment}
`;

export const GET_RINKNET_DRAFT_LISTS_W_LEAGUES = gql`
  query GetRinknetDraftLists($searchTerm: String) {
    rinknetDraftLists(searchTerm: $searchTerm) {
      ...rinknetListFragment
      leagues {
        name
        shorthand
        count
      }
    }
  }
  ${RinknetListFragment}
`;

export const GET_RINKNET_DRAFT_LIST = gql`
  query GetRinknetDraftList($id: ID!) {
    rinknetDraftList(id: $id) {
      id
      description
      date
      scoutFirstName
      scoutLastName
      nhlSeason
      leagues {
        name
        shorthand
        count
      }
      players {
        ranking
        firstName
        lastName
        rinknetPlayer {
          id
          realId
          firstname
          lastname
          birthdate
          age
          currentteam
          currentleagueabbr
          position
          heightStr
          weight
          height
          heightFt
          posFDG
          eliteprospectsid
          handedness
          agentname
          collegecommitmentteam
          bddPlayerSlug {
            slug
            rinknetId
            slUid
            cfSlug
            bfSlug
            bfCapHit {
              id
              hasPastContract
              hasActiveContract
              hasFutureContract
              currentAav
              currentRemainingTerm
              currentCapHit
              futureAav
              futureRemainingTerm
              futureCapHit
              retainedPct
              retainedDetails
              retainedCapHit
              finalCapHit
            }
            slPlayerMaster {
              slUid
              defaultLeagueSlug
            }
          }
          agent {
            id
            title
            firstname
            lastname
            companyname
            email
            phone
            cellphone
          }
        }
      }
    }
  }
`;

export const GET_LIST_AGGREGATION = gql`
  query GetListAggregation($slugToList: GenericScalar!) {
    listAggregation(slugToList: $slugToList) {
      aggregatedRanking
      choiceToWinRate
    }
  }
`;

export const GET_SIM_DRAFT_LISTS = gql`
  query GetSimDraftLists($draftYear: Int!) {
    simDraftLists(draftYear: $draftYear) {
      rinknetListId
      bddListId
      startingAtPick
    }
  }
`;
