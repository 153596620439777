import useToggle from 'components/bdd/Toggle/useToggle';
import { useEffect } from 'react';
import { useEPStatsContext } from '../context';
import Icon from 'components/bdd/Icon';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { theme } from 'constants';
import { Row } from 'components/bdd/Layout';
import { IconButton } from 'components/bdd/Button';

export const ZScoreToggle = ({}) => {
  const { setUseZScore } = useEPStatsContext((state) => state.actions);
  const { pageInfo } = useEPStatsContext((state) => state.results);

  const { toggled: useZScore, toggleComponent: zScoreToggle } = useToggle({
    label: (
      <Row columnGap={1}>
        {'Format by z-score'}
        {pageInfo && pageInfo.totalRows >= pageInfo.limit && (
          <IconButton
            tooltipPlacement={'top'}
            tooltip={
              'Sorting by z-score will not work with paginated queries. Try increasing the page limit or adding more filters'
            }
            icon={<ExclamationTriangle />}
            color={theme.colors.states.danger}
          />
        )}
      </Row>
    ),
  });

  useEffect(() => {
    setUseZScore(useZScore);
  }, [useZScore]);

  return zScoreToggle;
};
