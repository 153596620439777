import React, { useMemo } from 'react'
import styled from 'styled-components'
import { theme } from 'constants'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Instagram, Twitter } from 'react-bootstrap-icons'


const Header = styled.div(() => ({
  ...theme.typography.h2,
  fontSize: '1.1em',
  textAlign: 'center'
}))

const ResponseCell = styled(Col)(() => ({
  borderBottom: '1px solid #333'
}))

const Key = styled.div(() => ({
  ...theme.typography.subtitle1
}))
const Value = styled.div(() => ({
  fontSize: '0.8em'
}))

export default function Questionnaire({
  questionnaire
}) {
  const parsed = useMemo(() => !!questionnaire ? JSON.parse(questionnaire.data) : null, [questionnaire])

  return <Container fluid>
  <Header>{questionnaire.name} Questionnaire</Header>

  <Row>
    {Object.keys(parsed).filter(key => key !== 'RinkNet ID').map(key => <ResponseCell key={key} md={6} sm={12}>
      <Key>{key}</Key>
      <Value>
        {key === 'Twitter' ? <a target='_blank' href={`https://twitter.com/${parsed[key]}`}>
          <Twitter/>
          {' '}
          {parsed[key]}
        </a> : key === 'Instagram' ? <a target='_blank' href={`https://instagram.com/${parsed[key]}`}>
          <Instagram/>
          {' '}
          {parsed[key]}
        </a> : <> {parsed[key]} </>}
      </Value>
    </ResponseCell>)}
  </Row>

</Container>
}