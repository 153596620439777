import { useQuery } from '@apollo/client';
import { buildBddPlayerQuery } from 'apollo/queries/players.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GameReport } from 'components/PlayerDevelopment/components/feed/GameReports';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { useSLStatContext } from 'components/SLStats/hooks';
import useReports from 'components/ScoutingV2/components/reports/useReports';
import { LinkButton } from 'components/bdd/Button';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Divider } from 'components/bdd/Divider';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { NULL_FILTER_VALUE } from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { useOnScreenRef } from 'components/bdd/hooks';
import { BDDLoader } from 'components/bdd/bddloader';
import { theme } from 'constants';
import { usePlayersFromLists } from 'components/Lists/hooks';
import { ByGameStatTable, HeadlineStatTable, PlayerHighlightStats } from 'components/Notifications/components/Body/TargetedPlayers';
import { sortWithNaN } from 'helpers/tables';
import { TooltipSpan } from 'components/reports';

const DEFAULT_INDEX = 'league_slug,season,game_type,slug,Player,player_id,position';

const BY_GAME_INDEX = `game_date,game_uid,team_slug,opp_team_shorthand,${DEFAULT_INDEX}`;

const RINKNET_FIELDS = `
  id
  realId
  firstname
  lastname
  birthdate age
  currentteam
  currentteamnhlid
  currentleagueabbr
  currentleagueid
  position
  heightStr
  weight
  height 
  heightFt
  posFDG
  eliteprospectsid
  handedness
  agentname
  collegecommitmentteam
`;

const BDD_FIELDS = `
  slug
  rinknetPlayer {
    ${RINKNET_FIELDS}
  }`;


const statOptions = [
  {
    label: 'Skaters',
    value: 'skaters',
  },
  {
    label: 'Goalies',
    value: 'goalies',
  },
];

const skaterSortOptions = [
  { value: 'list-position', label: 'List Position', tooltip: 'Sort by position in list (includes tiers when ascending)' },
  { value: 'points', label: 'Points', tooltip: 'Sort by recent points' },
  { value: 'pbr', label: 'PBR', tooltip: 'Sort by recent PBR' },
];

const goalieSortOptions = [
  { value: 'list-position', label: 'List Position' },
  { value: 'gbr', label: 'GBR' },
];


export const ListRecencyView = ({ 
  targetLists, // [{ id, listType }]
  bddPlayerSlugs,
  rinknetIds,
  startDate,
  endDate,
  rinknetGameIds,
  defaultStatTarget='skaters'
}) => {
  const { selectedValue: selectedTarget, buttonGroup } = useButtonGroup({
    options: statOptions,
    initialSelectedValue: defaultStatTarget,
  });

  const defaultSort = {
    value: 'list-position',
    sortDesc: false,
  };

  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const sortSet = (
    <SortSet
      selected={selectedSort}
      sortOptions={selectedTarget == 'skaters' ? skaterSortOptions : goalieSortOptions}
      onChange={setSelectedSort}
    />
  );

  useEffect(() => {
    setSelectedSort(defaultSort);
  }, [selectedTarget]);

  const { data: bddPlayerData, placeholder } = usePlaceholder(
    useQuery(buildBddPlayerQuery(BDD_FIELDS), {
      variables: {
        slugs: bddPlayerSlugs,
      },
    })
  );

  const { data: reportData } = useReports({
    limit: 100,
    useSearchParams: false,
    defaultFilters: {
      game_id: rinknetGameIds,
      rinknetIds,
      startDate: startDate,
      endDate: endDate,
    },
  });

  const { listsWithPlayers: playerListOptions } = usePlayersFromLists({
    lists: targetLists,
  });

  const { buttonGroup: playerListGroup, selectedOption: selectedPlayerList } =
    useButtonGroup({
      variant: 'link',
      options: playerListOptions || [],
    });

  const defaultFilters = {
    slugs: bddPlayerSlugs,
    season: [getCurrentSeason()],
    strength: NULL_FILTER_VALUE,
    norm: selectedTarget == 'skaters' ? NULL_FILTER_VALUE : 60,
    index: DEFAULT_INDEX,
    statReport:
      selectedTarget == 'skaters' ? 'targetedSkatersSummary' : 'targetedGoaliesSummary',
    metastats: { norm: selectedTarget == 'skaters' ? '20' : '60' },
  };

  const seasonStatContext = useSLStatContext({
    defaults: { target: selectedTarget, filters: defaultFilters },
  });

  const recentGamesStatContext = useSLStatContext({
    defaults: {
      target: selectedTarget,
      filters: {
        ...defaultFilters,
        strict_start_date: startDate,
        strict_end_date: endDate,
      },
    },
  });

  const { stats: recentGameStats } = recentGamesStatContext;

  // when sorting by list position, we use tierOrderedPlayers
  // OTHERWISE, we throw everyone in the same "tier"
  const tOP = !selectedPlayerList
    ? null
    : selectedSort.value === 'list-position' && selectedSort.sortDesc == false  
    ? selectedPlayerList?.tierOrderedPlayers
    : [{
      tier: { title: 'All' },
      players: selectedPlayerList?.players
    }];


  const tierOrderedPlayers =
    !bddPlayerData
    ? []
    : tOP?.map((tp) => {
        let bddPlayers = tp.players
          .map((p) => ({
            ...bddPlayerData?.bddPlayers.find(
              (bdd) => bdd.slug == p.playerId || bdd.rinknetPlayer.realId == p.playerId
            ),
            listNumber: p.listNumber
          }))
          .filter((p) => !!p.slug)
          .filter((p) =>
            selectedTarget == 'skaters'
              ? p.rinknetPlayer?.posFDG != 'G'
              : p.rinknetPlayer?.posFDG == 'G'
          );

        if (selectedSort.value == 'pbr' && !!recentGameStats) {
          bddPlayers = bddPlayers.sort(
            (a, b) => sortWithNaN(
              recentGameStats.find((s) => s.slug == a.slug)?.['PBR'],
              recentGameStats.find((s) => s.slug == b.slug)?.['PBR']
            )
          );
        }

        if (selectedSort.value == 'points' && !!recentGameStats) {
          bddPlayers = bddPlayers.sort(
            (a, b) => sortWithNaN(
              recentGameStats.find((s) => s.slug == a.slug)?.['P'],
              recentGameStats.find((s) => s.slug == b.slug)?.['P']
            )
          )
        }

        if (selectedSort.sortDesc) {
          bddPlayers = bddPlayers.reverse();
        }

        return {
          tier: tp.tier,
          bddPlayers,
        };
      })
      ?.filter((tp) => tp.bddPlayers.length > 0);

  if (!!placeholder) return placeholder;
  if (!bddPlayerData || !selectedPlayerList) return <b>Waiting for data...</b>
  return (
    <Container height="100%" overflow="auto">
      <Column gap={3}>
        <Container>{playerListGroup}</Container>
        <Container>
          <Row columnGap={1} rowGap={1} flexWrap justifyContent="space-between">
            {sortSet}
            {buttonGroup}
          </Row>
        </Container>
        <Column gap={3}>
          {tierOrderedPlayers.length > 0 ? (
            tierOrderedPlayers.map((tp) => (
              <Container key={`tier-${tp.tier?.title}`}>
                {tp.tier && (
                  <Container paddingBottom={3}>
                    <Border onlyBottom 
                      borderColor={theme.colors.teams.bos.primary}
                      style={{ boxShadow: `0 5px 5px ${theme.colors.teams.bos.primary}` }}
                    >
                      <Typography variant="body1">{tp.tier.title}</Typography>
                    </Border>
                  </Container>
                )}
                <Column gap={3}>
                  {tp.bddPlayers
                    .map((bdd) => (
                      <PlayerListCard
                        key={bdd.slug}
                        startDate={startDate}
                        endDate={endDate}
                        bddPlayer={bdd}
                        seasonStatContext={seasonStatContext}
                        recentGamesStatContext={recentGamesStatContext}
                        reportData={reportData}
                        selectedTarget={selectedTarget}
                      />
                    ))
                    .filter((p) => !!p)}
                </Column>
              </Container>
            ))
          ) : (
            <Typography variant="body1">
              No players available for selected target
            </Typography>
          )}
        </Column>
      </Column>
    </Container>
  );
};

const PlayerListCard = ({
  startDate,
  endDate,
  bddPlayer,
  selectedTarget,
  seasonStatContext,
  recentGamesStatContext,
  reportData,
}) => {
  const { ref, isVisible } = useOnScreenRef();
  const [displayGameBreakdown, setDisplayGameBreakdown] = useState(false);
  const [displayAllReports, setDisplayAllReports] = useState(false);

  const rinknetPlayer = bddPlayer.rinknetPlayer;

  const byGameStatFilters = {
    slug: bddPlayer.slug,
    strength: NULL_FILTER_VALUE,
    norm: selectedTarget == 'skaters' ? NULL_FILTER_VALUE : 60,
    index: BY_GAME_INDEX,
    metastats: { norm: selectedTarget == 'skaters' ? '20' : '60' },
    statReport:
      selectedTarget == 'skaters' ? 'targetedSkatersSummary' : 'targetedGoaliesSummary',
    strict_start_date: startDate,
    strict_end_date: endDate,
  };

  const byGameStatContext = useSLStatContext({
    defaults: {
      target: selectedTarget,
      filters: byGameStatFilters,
    },
    skip: !displayGameBreakdown,
  });

  const playerHover = (
    <PlayerHover playerSlug={bddPlayer.slug} rinknetId={rinknetPlayer.realId}>
      <LinkButton justifyContent="start">
        <Row columnGap={1}>
          <Typography variant='subtitle1'>{bddPlayer.listNumber}.</Typography>
          {rinknetPlayer.currentteamnhlid && (
            <img src={getNHLTeamLogoUrl(rinknetPlayer.currentteamnhlid)} height="20px" />
          )}
          <Typography variant="subtitle1" noWrap>
            {rinknetPlayer.firstname} {rinknetPlayer.lastname}
          </Typography>
        </Row>
      </LinkButton>
    </PlayerHover>
  );

  const allReports = reportData?.allReports.data.filter(
    (r) => r.playerrinknetid == rinknetPlayer.realId
  );

  let filteredReports = [];
  if (!displayAllReports && allReports?.length > 0) {
    filteredReports.push(allReports[0]);
  } else {
    filteredReports = allReports || [];
  }

  return (
    <Border ref={ref}>
      {isVisible ? (
        <Container padding={2}>
          <Row columnGap={5} rowGap={1} alignItems="start" flexWrap>
            <Container width="20%">
              <Column>
                {playerHover}
                <PlayerHighlightStats
                  bddPlayer={bddPlayer}
                  seasonStatContext={seasonStatContext}
                  recentGamesStatContext={recentGamesStatContext}
                  target={selectedTarget}
                />
              </Column>
            </Container>
            <Container width={'75%'} overflow="auto">
              <HeadlineStatTable
                bddPlayer={bddPlayer}
                seasonStatContext={seasonStatContext}
                recentGamesStatContext={recentGamesStatContext}
              />
            </Container>
            <Container width="100%" paddingTop={1}>
              <Row columnGap={4}>
                <Container
                  variant="button"
                  onClick={() => setDisplayGameBreakdown(!displayGameBreakdown)}
                >
                  <Row columnGap={2}>
                    <Typography variant="body1" noWrap>
                      {!displayGameBreakdown
                        ? 'Show game breakdown'
                        : 'Hide game breakdown'}
                    </Typography>
                    <Icon icon={displayGameBreakdown ? <ChevronUp /> : <ChevronDown />} />
                  </Row>
                </Container>
                {allReports?.length > 1 && (
                  <Container
                    variant="button"
                    onClick={() => setDisplayAllReports(!displayAllReports)}
                  >
                    <Row columnGap={2}>
                      <Typography variant="body1" noWrap>
                        {!displayAllReports
                          ? `Show all ${allReports.length} reports`
                          : 'Show first report'}
                      </Typography>
                      <Icon icon={displayAllReports ? <ChevronUp /> : <ChevronDown />} />
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
            {displayGameBreakdown && (
              <ByGameStatTable byGameStatContext={byGameStatContext} />
            )}
            {allReports?.length > 0 && <Divider />}
            <Container>
              <Row columnGap={2} rowGap={2} flexWrap minWidth={300}>
                {filteredReports.map((r) => (
                  <GameReport
                    key={`report-${r.author}-${r.id}`}
                    report={r}
                    showGameInfo
                  />
                ))}
              </Row>
            </Container>
          </Row>
        </Container>
      ) : (
        <Container height={100}>
          <Typography variant="subtitle1" noWrap>
            {rinknetPlayer.firstname} {rinknetPlayer.lastname}
          </Typography>
        </Container>
      )}
    </Border>
  );
};

const SortSet = ({ selected, sortOptions, onChange }) => {
  const handleChange = (s) => {
    // default to sort descending except when switching to list sort
    const sortDesc = s.value == selected.value 
      ? !selected.sortDesc
      : selected.value != 'list-position' && s.value == 'list-position'
      ? false
      : true;
    onChange({
      value: s.value,
      sortDesc,
    })
  }
  return (
    <Container>
      <Row columnGap={2} rowGap={2} flexWrap>
        <Typography variant="body1">Sort:</Typography>
        {sortOptions.map((s) => (
          <Container
            key={s.value}
            variant="button"
            onClick={() => {
              handleChange(s)
            }}
          >
            <Typography variant="body2">
              <Row columnGap={1}>
                <TooltipSpan content={s.tooltip}>
                  <Typography variant={s.value == selected.value ? 'body1' : 'body2'}>
                    {s.label}
                  </Typography>
                </TooltipSpan>
                {s.value == selected.value && (
                  <Icon icon={selected.sortDesc ? <ChevronUp /> : <ChevronDown />} />
                )}
              </Row>
            </Typography>
          </Container>
        ))}
      </Row>
    </Container>
  );
};
