import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';

export const ScoutingObjectivesFragment = gql`
  fragment scoutingObjectivesFragment on ScoutingObjectives {
    id
    slug
    name
    description
    objectiveType
    targetPlayerFilters
    isActive
    isPinned
    scoutingObjectiveLists {
      listId
      scoutingRegion
      scoutingListType
      playerList {
        id
        name
        description
      }
    }
    createdAt
    createdBy {
      id
      username
    }
    canManage
    extras
    draftEligibilityYear
    accessList {
      user {
        username
      }
      userId
      scoutingRegionSlugs
      canManage
    }
  }
`;

export const GET_SCOUTING_OBJECTIVES = gql`
  query GetScoutingObjectives($activeOnly: Boolean, $pinsOnly: Boolean) {
    scoutingObjectives(activeOnly: $activeOnly, pinsOnly: $pinsOnly) {
      ...scoutingObjectivesFragment
    }
  }
  ${ScoutingObjectivesFragment}
`;

export const GET_SCOUTING_OBJECTIVE = gql`
  query GetScoutingObjective($slug: ID!) {
    scoutingObjective(slug: $slug) {
      ...scoutingObjectivesFragment
    }
  }
  ${ScoutingObjectivesFragment}
`;

export const CREATE_SCOUTING_OBJECTIVE = gql`
  mutation CreateScoutingObjective($input: CreateScoutingObjectiveInput!) {
    createScoutingObjective(input: $input) {
      scoutingObjective {
        ...scoutingObjectivesFragment
      }
    }
  }
  ${ScoutingObjectivesFragment}
`;

export const UPDATE_SCOUTING_OBJECTIVE = gql`
  mutation UpdateScoutingObjective($input: UpdateScoutingObjectiveInput!) {
    updateScoutingObjective(input: $input) {
      scoutingObjective {
        ...scoutingObjectivesFragment
      }
    }
  }
  ${ScoutingObjectivesFragment}
`;

export const DELETE_SCOUTING_OBJECTIVE = gql`
  mutation UpdateScoutingObjective($slug: ID!) {
    updateScoutingObjective(slug: $slug) {
      slug
    }
  }
`;

export const GET_SCOUTING_REGIONS = gql`
  query GetScoutingRegions($scoutingObjectiveSlug: ID) {
    scoutingRegions(scoutingObjectiveSlug: $scoutingObjectiveSlug) {
      slug
      name
      leagues {
        leagueid: id_
        bddLeagueSlug
        name
        shorthand
        priority
      }
    }
  }
`;

export const GET_SCOUTING_REPORT_COUNTS = gql`
  query GetScoutingReportCounts($startDate: Date!, $draftEligibleYear: Int) {
    reportCountsByRegion: reportCounts(
      entity: "REGION"
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      scoutingRegionSlug: entityId
      scoutingRegionName: entityName
      totalCount
      gameCount
      uniqueGameCount
      playerCount
    }
    reportCountsByLeague: reportCounts(
      entity: "LEAGUE"
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      totalCount
      gameCount
      uniqueGameCount
      playerCount
      leagueid: entityId
      leaguename: entityName
    }
    reportCountsByTeam: reportCounts(
      entity: "TEAM"
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      totalCount
      gameCount
      uniqueGameCount
      playerCount
      teamid: entityId
      longname: entityName
    }
  }
`;

export const ScoutingReportCountsFragment = gql`
  fragment scoutingReportCountsFragment on ReportCounts {
    entityId
    entityName
    totalCount
    gameCount
    uniqueGameCount
    playerCount
  }
`;
export const GET_REPORT_COUNTS = gql`
  query GetReportCounts(
    $entity: String!
    $entityId: Int
    $startDate: Date!
    $draftEligibleYear: Int
  ) {
    reportCounts(
      entity: $entity
      entityId: $entityId
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      ...scoutingReportCountsFragment
    }
  }
  ${ScoutingReportCountsFragment}
`;

export const GET_UPCOMING_SCOUT_GAME_COUNTS = gql`
  query GetUpcomingScoutGameCounts($entity: String!, $entityId: Int) {
    upcomingScoutGameCounts(entity: $entity, entityId: $entityId) {
      entityId
      entityName
      totalCount
      uniqueGameCount
      nextScheduledView
    }
  }
`;

export const GET_UPCOMING_SCOUT_GAME_COUNTS_BY_SCOUT = gql`
  query GetUpcomingScoutGameCountsByScout($entity: String!, $entityId: Int) {
    upcomingScoutGameCountsByScout(entity: $entity, entityId: $entityId) {
      gameCounts {
        entityId
        entityName
        totalCount
        scoutId
        uniqueGameCount
        nextScheduledView
      }
      scouts {
        id
        firstname
        lastname
        initials
      }
    }
  }
`;

export const GET_REPORT_COUNTS_BY_LIST_TYPE = gql`
  query GetReportCountsByListType(
    $scoutingObjectiveSlug: ID!
    $entity: String!
    $entityId: ID!
    $startDate: Date!
    $draftEligibleYear: Int
  ) {
    reportCountsByListType(
      scoutingObjectiveSlug: $scoutingObjectiveSlug
      entity: $entity
      entityId: $entityId
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      entity
      entityId
      listType
      totalPlayers
      reportCounts {
        ...scoutingReportCountsFragment
      }
    }
  }
  ${ScoutingReportCountsFragment}
`;

export const GET_REPORT_COUNTS_BY_SCOUT = gql`
  query GetReportCounts(
    $entity: String!
    $entityId: Int
    $startDate: Date!
    $draftEligibleYear: Int
  ) {
    reportCountsByScout(
      entity: $entity
      entityId: $entityId
      startDate: $startDate
      draftEligibleYear: $draftEligibleYear
    ) {
      reportCounts {
        entityId
        entityName
        totalCount
        playerCount
        gameCount
        lastViewed
        scoutId
      }
      scouts {
        id
        firstname
        lastname
        initials
      }
    }
  }
`;

export const buildRinknetScheduleQuery = ({
  includePlayers,
  includeScouts,
  includeGameInfo,
  includeReports,
}) => gql`
  query GetRinknetSchedules($entities: [RinknetSchedulesInput], $limit: Int) {
    rinknetSchedules(entities: $entities, limit: $limit, loadScouts: ${!!includeScouts}, loadReports: ${!!includeReports}) {
      entityType
      entityId
      games {
        date
        gameId
        hometeamname
        awayteamname
        gameType
        leaguename
        leagueid
        ${
          !includeScouts
            ? ''
            : `scouts {
                scoutId
                scout {
                  firstname
                  lastname
                }
              }`
        }
        ${
          !includePlayers
            ? ''
            : `players {
                edges {
                  node {
                    firstname
                    lastname
                    realId
                  }
                }
              }`
        }
        ${
          !includeGameInfo
            ? ''
            : `hometeamid
              hometeamshortname
              awayteamshortname
              awayteamscore
              hometeamscore
              leagueshorthand`
        }
        ${
          !includeReports
            ? ''
            : `rinknetReports {
              author
              playerreportid
              scoutid
              playerrinknetid
              scoutid
              notes
              ratings
              position
            }`
        }
      }
    }
  }
`;

export const buildBDDScheduleQuery = ({
  includePlayers,
  includeScouts,
  includeReports,
  includeReportCounts,
  includeStats,
  includeNHLGame,
  includeLiveNHLData,
  includeDataChecks,
}) => gql`
  query GetBDDSchedule($entities: [BDDScheduleInput], $limit: Int) {
    bddSchedules(entities: $entities,
      limit: $limit, 
      loadScouts: ${!!includeScouts},
      loadReports: ${!!includeReports || !!includeReportCounts},
      loadPlayers: ${!!includePlayers},
      loadStats: ${!!includeStats}
      loadLiveNhlData: ${!!includeLiveNHLData}) {
        entityType
        entityId
        bddGames {
          id
          rinknetGameId
          slGameUid
          date
          season
          homeTeam {
            id
            shorthand
            longname
            nhlid
            slTeamSlug
          }
          awayTeam {
            id
            shorthand
            longname
            nhlid
            slTeamSlug
          }
          rinknetGame {
            date
          }
          gameType
          bddLeagueSlug
          homeTeamScore
          awayTeamScore
          homeTeamSoScore
          awayTeamSoScore
          status
          scoreType
          ${includeStats ? 'epStats' : ''}
          ${includeReports || includeReportCounts ? 'countReports' : ''}
          ${
            includeNHLGame
              ? `
            nhlGame {
              id
              gameDate
              league
              season
              gameType
              venueName
              isCompleted
              broadcasts
              homeLeagueRecord
              awayLeagueRecord
              homeTeam {
                slug
                nhlid
                longname
                shorthand
                nhlid
              }
              awayTeam {
                slug
                nhlid
                longname
                shorthand
                nhlid
              }
              ${includeLiveNHLData ? 'liveData' : ''}
            }
          `
              : ''
          }
          ${
            includePlayers
              ? `countPlayers 
                rinknetPlayersMapping 
                homeTeamRinknetPlayersMapping 
                awayTeamRinknetPlayersMapping`
              : ''
          }
          ${includeScouts ? 'countScouts rinknetScoutsMapping' : ''}
          ${
            !includeReports
              ? ''
              : `scoutingReports {
                __typename
                ... on RinknetReports {
                  author
                  gameid
                  playerreportid
                  scoutid
                  playerrinknetid
                  scoutid
                  notes
                  ratings
                  position
                  skillcategoryratings
                }
              }`
          }
          ${!includeDataChecks ? '' : `slDataProcessed slIngameDataProcessed`}
      }
      ${
        !includePlayers
          ? ''
          : `rinknetPlayers {
            id
            realId
            firstname
            lastname
          }`
      }
      ${
        !includeScouts
          ? ''
          : `rinknetScouts {
            id
            firstname
            lastname
          }`
      }
    }
  }
`;

export const GET_ISSUES_BY_SCOUTING_OBJECTIVE = gql`
  query GetBddIssuesByScoutingObjective(
    $limit: Int!
    $slug: ID!
    $issueTypes: [String]
    $myIssues: Boolean
    $closedIssues: Boolean
    $minExpectedPick: Int
    $firstYearDraft: Boolean
    $filters: JSONString
    $offset: Int
    $search: String
    $sort: [BDDSortV2]
  ) {
    bddIssuesByScoutingObjective(
      limit: $limit
      slug: $slug
      issueTypes: $issueTypes
      myIssues: $myIssues
      closedIssues: $closedIssues
      minExpectedPick: $minExpectedPick
      firstYearDraft: $firstYearDraft
      filters: $filters
      offset: $offset
      search: $search
      sort: $sort
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        id
        parentType
        issueDetails
        issueType
        status
        targetType
        targetId
        priority
        createdDate
        createdByUserId
        lastModifiedByUser {
          firstname
          lastname
        }
        createdByUser {
          firstname
          lastname
        }
        comments {
          comment
        }
        lastModifiedDate
        assigneeUserId
        assigneeUser {
          username
        }
        source
        bddPlayer {
          id
          fullName
          rinknetId
          slug
          rinknetPlayer {
            id
            realId
            firstname
            lastname
            birthdate
            age
            currentteam
            currentleagueabbr
            currentleagueid
            currentteamid
            position
            heightStr
            weight
            height
            heightFt
            posFDG
            eliteprospectsid
            handedness
            agentname
            collegecommitmentteam
            agent {
              id
              title
              firstname
              lastname
              companyname
              email
              phone
              cellphone
            }
          }
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const GET_PLAYER_SCOUT_GRADE_SUMMARY = gql`
  query GetPlayerScoutGradeSummary($rinknetIds: [ID!], $startDate: Date) {
    scoutReportSummary(rinknetIds: $rinknetIds, startDate: $startDate) {
      draftRoundOrder
      scouts {
        userid
        firstname
        lastname
        bddUser {
          username
          id
        }
      }
      summary {
        scoutId
        rinknetId
        lastViewedDate
        summary
        values
        reportCount
        listPosition
        listLength
      }
    }
  }
`;