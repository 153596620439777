import React, { useState } from 'react';
import { CommentForm, FileAttachmentGroup, TextEditor } from '.';
import { dateToTimeAgo } from 'helpers/helpers';
import { TimelineItem } from 'components/bdd/Timeline';
import { CommentReadonlyForm } from './CommentReadonlyForm';

export const CommentTimelineItem = ({
  user,
  comment,
  dateCreated,
  onSubmit,
  lastItem,
  requiresPermission,
  includeFileUpload,
  files,
  header,
  isPinned,
}) => {
  const [editMode, setEditMode] = useState(false);

  const editNote = (
    <CommentForm
      requiresPermission={requiresPermission}
      existingNote={{ comment, files }}
      onSubmit={(values) => {
        onSubmit(values);
        setEditMode(false);
      }}
      onCancel={() => setEditMode(false)}
      includeFileUpload={includeFileUpload}
    />
  );

  const readOnlyNote = (
    <CommentReadonlyForm
      userId={user.id}
      isPinned={isPinned}
      header={
        header || (
          <>
            <b>{user.username}</b> left a note {dateToTimeAgo(dateCreated)}
          </>
        )
      }
      comment={comment}
      files={files}
      onEdit={() => setEditMode(true)}
      requiresPermission={requiresPermission}
      onArchive={() => onSubmit({ isArchived: true })}
      onPin={() => onSubmit({ isPinned: !isPinned })}
    />
  );

  return (
    <TimelineItem
      item={editMode ? editNote : readOnlyNote}
      lastItem={lastItem}
    />
  );
};
